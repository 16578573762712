import { Drawer, notification, Popover, Tooltip } from 'antd';
import {
  Box, Divider,
  HStack,
  Pressable,
  Text,
  View,
  VStack
} from 'native-base';
import React, { useContext, useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Fontisto from 'react-native-vector-icons/Fontisto';
import { BUTTON_TYPE, CONSENT_CONST, getInActiveContactError, MLOV_CATEGORY, PERSON_TYPES, PREFERRED_CHANNEL_CODE, RIGHT_SIDE_CONTAINER_CODE } from '../../../../../constants';
import { COMMON_ACTION_CODES, PATIENT_QUICK_PROFILE_PARENT_CODES } from '../../../../../constants/ActionConst';
import { Colors } from '../../../../../styles';
import {
  checkAbilityAccess,
  checkAccountConfigAbilityAccess,
  getEHRName,
  getOpenInHintUrl,
  getOrgAbilites,
  getPatientEHRUrl
} from '../../../../../utils/capabilityUtils';
import { getCaslAbility, getPatientLocationUuidFromPracticeLocations, isActiveContact, isConsentRequiredAndGiven, isContactConsentRequired, isMasterAccount, isPhysicianRole } from '../../../../../utils/commonUtils';
import { EventBus } from '../../../../../utils/EventBus';
import { getPreferredChannel, getToolTipMessage } from '../../../../../utils/PersonActionsUtils';
import EmailDrawerCommon from '../../../../common/EmailPopupView/EmailDrawerCommon';
import OutboundCallSmsView from '../../../../common/OutboundCallSmsView/OutboundCallSmsView';
import CallActionSvg from '../../../../common/Svg/PersonActionSvgIcons/CallActionSvg';
import ChatActionSvg from '../../../../common/Svg/PersonActionSvgIcons/ChatActionSvg';
import EmailActionSvg from '../../../../common/Svg/PersonActionSvgIcons/EmailActionSvg';
import ExternalLinkAction from '../../../../common/Svg/PersonActionSvgIcons/ExternalLinkAction';
import SmsActionSvg from '../../../../common/Svg/PersonActionSvgIcons/SmsActionSvg';
import VideoActionSvg from '../../../../common/Svg/PersonActionSvgIcons/VideoActionSvg';
import StartPreferredicon from '../../../../common/Svg/PersonActionSvgIcons/StartPreferredIcon';
import ViewEhrActionBtn from '../../../../common/Svg/PersonActionSvgIcons/ViewEhrActionBtn';
import { ComingSoonModal } from '../../../../RightSideContainer/ContentManagement/EmailTemplates/ComingSoonModal';
import { IContact } from '../../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { isAutomationAllowed, isCallsAllowed, isPatientDetailViewAllowed, USER_ACCESS_PERMISSION } from '../../../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import { PERSON_ACTION_CODES } from './ActionConst';
import { IPersonAction, PersonActionPopoverProps } from './interfaces';
import './PersonActionPopover.css';
import PersonActionPopoverHelper from './PersonActionPopoverHelper';
import { styles, antdStyles } from './PersonActionPopoverStyle';
import { getActionObjListByPersonType } from './PersonActionUtils';
import {patientNotLoggedInError} from '../../../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationConst';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import { FHIR_RESOURCE } from '../../../../../constants/FhirConstant';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {getEhrConfig} from '../../../MiddleContainer/CareTimeline/CareTimelineUtils';
import {useLazyQuery} from '@apollo/client';
import ContactsQueries from '../../../../../services/Contacts/ContactsQueries';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../utils/mlovUtils';
import {IMlov} from '../../../../../Interfaces';
import { isSendProviderRequestFormAllowed } from '../../../../RightSideContainer/Forms/FormsUtils';
import {isAffiliatePatient} from '../../../../RightSideContainer/Contacts/ContactsUtils';

import {getActionEventAndResourceCodeByOption} from '../../../../RightSideContainer/Contacts/ContactsUtils';
import FoldPermitCan from '../../../../CommonComponents/FoldPermitCan/FoldPermitCan';
import useEHRCapabilities from '../../../../../screens/BusinessStudio/useEHRCapabilities';
import InsightsReferralOrdersView from '../../../../SideCar/components/InsightsReferralOrdersView';
import AddSquareSvg from '../../../../../assets/Icons/AddSquareSvg';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { getFormDataFromLeadData } from '../../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { testID, TestIdentifiers } from '../../../../../testUtils';

export const PersonActionPopover = (
  props: PersonActionPopoverProps
): JSX.Element => {
  const {hintPatientData, personData, parentCode, showChats,isSizeLess, skipActions, isEmployerDrillDown} = props;
  const [stateData, setStateData] = useState({
    visible: false,
    preferredChannel: '',
    openDrawerCode: '',
  });
  const context = useContext(CommonDataContext);
  const userSettings = context.userSettings;
  
  const [selectedView, setSelectedView] = useState<string>('');
  const [formattedContactData, setFormattedContactData] = useState<any>({});
  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);
  const isReferralEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_REFERRAL_ENABLED)
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const contactActiveStatus = isActiveContact(props.personData);
  const phoneNumber = props.personData?.phoneNumber || '';
  const email = props.personData?.email || ''
  const contactInfo = props.personData
  const isLoggedIn = contactInfo?.additionalAttributes?.loggedInTime
  const ehrPatientId = props?.personData?.patient?.patientId || '';
  const patientLocationId =
    props?.personData?.contactPracticeLocations?.[0]?.accountLocation?.uuid ||
    props?.locationId;
  const isPhysician = isPhysicianRole()
  const [isOpen, setIsOpen] = React.useState(false);
  const isPatientMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.PATIENT_MESSAGING_ENABLED);
  const isMasterAccountFlag = isMasterAccount();
  const isSidecarContext = context.sidecarContext?.isSidecar;
  const showAutomationTab = isAutomationAllowed();
  const sendProviderRequestFormAllowed = !props?.personData?.contactType?.contactType.isDefault && isSendProviderRequestFormAllowed(userSettings);
  const isCallAllowed = isCallsAllowed();
  const locationUuid = getPatientLocationUuidFromPracticeLocations(props?.personData?.contactPracticeLocations);
  const isPatientDetailViewAllowedConfig = isPatientDetailViewAllowed();
  const canUpdatePatient = checkAccountConfigAbilityAccess(FHIR_RESOURCE.PATIENT, 'canUpdate')?.isEnabled && isPatientDetailViewAllowedConfig;
  let personActionList = getActionObjListByPersonType(props.personType, canUpdatePatient, skipActions, sendProviderRequestFormAllowed);
  if (!showAutomationTab) {
    personActionList = personActionList.filter(item => item.actionCode !== PERSON_ACTION_CODES.CREATE_AUTOMATION)
  }
  if (isMasterAccountFlag) {
    personActionList = personActionList.filter(item => (item.actionCode !== PERSON_ACTION_CODES.CREATE_TASK
      && item.actionCode !== PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT))
  }

  if(isEmployerDrillDown) {
    personActionList = personActionList.filter(item => item.actionCode !== PERSON_ACTION_CODES.EDIT)
  }

  const [hintConfig, setHintConfig] = useState({
    hideConvertToPatient: false,
  });

  const shouldHideConvertToPatient = () => {
    if (hintConfig?.hideConvertToPatient) {
      return hintConfig?.hideConvertToPatient;
    } else if (personData?.contactType?.contactType?.code === PERSON_TYPES.LEAD) {
      return false;
    } else if (personData?.contactType?.contactType?.isDefault) {
      return false;
    }
    return !personData?.contactType?.contactType?.can_convert_to_patient;
  }

  if (shouldHideConvertToPatient()) {
    personActionList = personActionList.filter(
      (item) => item.actionCode !== PERSON_ACTION_CODES.CONVERT_TO_CUSTOMER
    );
  }

  if (!personData?.isActive) {
    personActionList = personActionList.filter(
      (item) => item.actionCode !== PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT
    );
  }

  const commonData = useContext(CommonDataContext);
  const ehrCapabilities = useEHRCapabilities({locationId: patientLocationId});
  const currentEHR: any = getEHRName(patientLocationId, '');
  const ehrConfig = getEhrConfig(currentEHR);
  const isAthena = ehrConfig.isAthena;
  const isConsentRequired = isContactConsentRequired();
  const [getContactConsentByContactUuid] = useLazyQuery(
    ContactsQueries.GET_CONTACT_CONSENT_BY_CONTACT_UUID,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const consentMlovList: IMlov[] =
    getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.CONSENT) || [];
  const callConsentId = getMlovIdFromCode(
    consentMlovList,
    CONSENT_CONST.consentToCall
  );

  const isConsentForCallGivenByContact = async () => {
    if (!isConsentRequired) {
      return true;
    }
    const contactConsentResponse = await getContactConsentByContactUuid({
      variables: {
        contactUuid: personData.uuid,
      },
    });
    const contactConsents: {consentId: string}[] =
      contactConsentResponse?.data?.contactConsents || [];
    if (isAthena) {
      return !!contactConsents.find((item) => item.consentId === callConsentId);
    }
    return contactConsents.length > 0;
  };

  const isCurrentMenuLast = (
    index: number,
    personActionList: IPersonAction[]
  ): boolean => {
    return index < personActionList.length - 1;
  };
  const hintUrl = getOpenInHintUrl(
    props.hintPatientData?.hintPatientId || '',
    patientLocationId
  );
  const handleOpenInHint = () => {
    window.open(hintUrl, '_blank', 'noopener,noreferrer');
  };
  const openInEhrUrl = getPatientEHRUrl(ehrPatientId, patientLocationId, '');
  const openInEHR = () => {
    window.open(openInEhrUrl, '_blank', 'noopener,noreferrer');
  };
  const [comingSoonModal, setComingSoonModal] = useState(false);
  const isPatientLoggedIn = contactInfo?.additionalAttributes?.loggedInTime ?  true : false
  const consentCheck = isConsentRequiredAndGiven(personData)

  const handleEHRBtnClick = () => {
    if (openInEhrUrl) {
      openInEHR();
    } else {
      setComingSoonModal(true);
    }
    setIsOpen(!isOpen);
  };

  const showOpenInHint =
    (hintPatientData?.hintPatientId && hintPatientData.openInHint) || false;

  const handleEdit = (actionCode?: string) => {
    if (props.onActionPerformed) {
      let newContactData: any = personData;
      if (actionCode === COMMON_ACTION_CODES.CONSENT_WARNING) {
        newContactData = {...personData, showConsentError: true};
      }
      props.onActionPerformed(COMMON_ACTION_CODES.EDIT, newContactData);
    }
  };
  const handleActions = (actionCode: string, rowData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.CALL:
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent('CLOUD_CALL', {
          contactData: props.personData ? { ...contactInfo,fromNumber: props.personData?.phoneNumber || ''} : {},
          fromNumber: rowData.fromNumber,
          toNumber: props.personData?.phoneNumber,
        });
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
    }
  };

  const handleSMS = (data?: any) => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.SMS, contactInfo);
    }
  };
  const handleEmail = () => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.MAIL, contactInfo);
    }
  };

  const handleVideoCallAction = () => {
    if (!contactActiveStatus) {
      const message = getInActiveContactError(contactInfo);
      notification.error({
        message,
      });
      return;
    }
    if (email || phoneNumber) {
      handleCall();
    } else {
      handleEdit?.();
    }
  };

  const handleCall = async () => {
    const isConsentGivenForCall = await isConsentForCallGivenByContact();
    if (!isConsentGivenForCall) {
      handleEdit(COMMON_ACTION_CODES.CONSENT_WARNING);
      return;
    }
    if (props.onActionPerformed) {
      props.onActionPerformed(PERSON_ACTION_CODES.CREATE_MEETING, contactInfo);
    }
  }
  const filterActionList = (actionCode: string) => {
    if (
      isAffiliatePatient(props.personData?.additionalAttributes) &&
      actionCode === PERSON_ACTION_CODES.EDIT
    ) {
      return true;
    }
    if (actionCode === PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT && (!isPhysician && !isSizeLess)) {
      return true
    }
     else {
      return false
    }
  }
  const topViewActionList = [
    {
      name: 'call',
      code: 'CALL',
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.CALL,
      view: !props.hideVideoCallingButton && isCallAllowed ? (
        <View style={styles.topViewActionContainer}>
          <OutboundCallSmsView
            isHideSms={true}
            showOnlyCallNumbers={true}
            visible={stateData.visible}
            selectedContactData={props.personData}
            content={
              <Tooltip
                title={
                  !contactActiveStatus
                    ? getInActiveContactError(props.personData)
                    : getToolTipMessage(
                        'CALL',
                        personData?.phoneNumber || '',
                        personData?.email || '',
                        personData as IContact
                      )
                }
              >
                <Pressable
                  onPress={() => {
                    if (!isCallAllowed) {
                      return;
                    }
                    if (contactActiveStatus) {
                      if (consentCheck) {
                        if (personData?.phoneNumber) {
                          setStateData((prev) => {
                            return {
                              ...prev,
                              visible: true,
                            };
                          });
                        } else {
                          handleEdit();
                        }
                      } else {
                        handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
                      }
                    } else {
                      const message = getInActiveContactError(
                        personData?.phoneNumber
                      );
                      notification.error({
                        message,
                      });
                    }
                  }}
                  style={styles.topViewActionPressable}
                  {...testID(`PersonAction_${TestIdentifiers.personAction.call}`)}
                >
                  <View>
                    <View style={styles.topViewActionIcon}>
                      <CallActionSvg
                        isActive={props.personData?.phoneNumber ? true : false}
                      />
                    </View>
                    {getPreferredChannel(personData as IContact) ===
                      PREFERRED_CHANNEL_CODE.VOICE && (
                      <View style={[styles.preferredIcon]}>
                        <StartPreferredicon />
                      </View>
                    )}
                  </View>
                  <Text
                    fontSize={12}
                    fontWeight={500}
                    color={
                      props.personData?.phoneNumber ? '#667085' : '#D0D5DD'
                    }
                  >
                    Call
                  </Text>
                </Pressable>
              </Tooltip>
            }
            handleAction={(actionCode: string, rawData?: any) => {
              handleActions(actionCode, rawData);
            }}
          />
        </View>
      ) : <></>,
    },
    {
      name: 'chat',
      code: 'CHAT',
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.INBOX,
      view:  (
          <View style={styles.topViewActionContainer}>
            <Tooltip
              title={
                isPatientLoggedIn ?
                !contactActiveStatus
                  ? getInActiveContactError(contactInfo)
                  : 'Chat with patient' : patientNotLoggedInError
              }
            >
              {showChats && (
                <Pressable
                  onPress={() => {
                    if (contactActiveStatus && isPatientLoggedIn) {
                      if (props.onActionPerformed) {
                        props.onActionPerformed(PERSON_ACTION_CODES.CHAT, contactInfo);
                      }
                    }
                  }}
                  {...testID(`PersonAction_${TestIdentifiers.personAction.chat}`)}
                  style={styles.topViewActionPressable}
                >
                  <View style={styles.topViewActionIcon}>
                    <ChatActionSvg isActive={isPatientLoggedIn} />
                  </View>
                  <Text fontSize={12} fontWeight={500} color={isPatientLoggedIn ? '#667085' : '#D0D5DD'}>
                    Chats
                  </Text>
                </Pressable>
              )}
            </Tooltip>
          </View>
        ),
    },
    {
      name: 'meet',
      code: 'MEET',
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.CALL,
      view: !props.hideVideoCallingButton ? (
        <View style={styles.topViewActionContainer}>
          <Tooltip
            title={
              !contactActiveStatus
                ? getInActiveContactError(contactInfo)
                : getToolTipMessage(
                    'VCALL',
                    phoneNumber,
                    email,
                    props.personData as IContact
                  )
            }
          >
            <Pressable
              onPress={() => {
                handleVideoCallAction();
              }}
              {...testID(`PersonAction_${TestIdentifiers.personAction.meet}`)}
              style={styles.topViewActionPressable}
            >
              <View style={styles.topViewActionIcon}>
                <VideoActionSvg
                  isActive={(email || phoneNumber) ? true : false}
                />
              </View>
              <Text
                size={'xsNormal'}
                color={(email || phoneNumber) ? Colors.FoldPixel.GRAY250 : '#D0D5DD'}
              >
                Meet
              </Text>
            </Pressable>
          </Tooltip>
        </View>
      ) : <></>,
    },
  ];

  // if(isEFaxMessagingEnabled){
  //   topViewActionList?.push(
  //     {
  //     name: 'referrals',
  //     code: 'Referrals',
  //     actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
  //     resourceCode: MAIN_MENU_CODES.CALL,
  //     view: !props.hideVideoCallingButton ? (
  //       <View style={styles.topViewActionContainer}>
  //         <Tooltip
  //           title={
  //             'Create Referral'
  //           }
  //         >
  //           <Pressable
  //             onPress={() => {
  //               const formattedContactaData = getFormDataFromLeadData(
  //                 props.personData,
  //                 context
  //               );
  //               setFormattedContactData(formattedContactaData)
  //               setDrawerVisible(true)
  //             }}
  //             style={styles.topViewActionPressable}
  //           >
  //             <View style={styles.topViewActionIcon}>
  //               <AddSquareSvg/>
  //             </View>
  //             <Text
  //               size={'xsNormal'}
  //               color={(email || phoneNumber) ? Colors.FoldPixel.GRAY250 : '#D0D5DD'}
  //             >
  //               Referral
  //             </Text>
  //           </Pressable>
  //         </Tooltip>
  //       </View>
  //     ) : <></>,
  //   })
  // }

  const filterActionTopView = (action:string)=> {
    if (action == 'CHAT' && !isPatientMessagingEnabled) {
      return false
    } else if (action == 'CHAT' && !(parentCode !== PATIENT_QUICK_PROFILE_PARENT_CODES.MESSAGING &&
      contactInfo?.patient?.id &&
      contactInfo?.uuid &&
      !isLoggedIn)) {
        return false
      }else {
        return true
      }
  }

  const canShowTopViewActions = () => {
    if (isSidecarContext) {
      return false;
    }
    return true
  }
const popoverOptionView  = (singleActionObj: IPersonAction, index: number)=> {
  return <Pressable
  key={index}
  onPress={() => {
    setIsOpen(!isOpen);
    props.onActionPerformed(
      singleActionObj.actionCode,
      props.personData
    );
  }}
  {...testID(singleActionObj.actionCode)}
>
  <HStack
    style={[
      styles.mainMenuContent,
      index === 0 && styles.firstMenu,
      !props.hintPatientData?.openInHint &&
        !isCurrentMenuLast(index, personActionList) &&
        styles.lastMenu,
    ]}
  >
    <PersonActionPopoverHelper
      actionCode={singleActionObj.actionCode}
    />
    <Text
      style={[styles.mainMenuTxt, {color: Colors.FoldPixel.GRAY400}]}
      size={'smRegular'}
    >
      {singleActionObj.displayString}
    </Text>
  </HStack>
  {(props.hintPatientData?.openInHint ||
    isCurrentMenuLast(index, personActionList)) && (
    <Divider my={2} style={styles.dividerStyle} />
  )}
</Pressable>
}
  const content = (
    <View>
      {canShowTopViewActions() && (
      <View
        style={styles.topViewActionContainer2}
      >
        {topViewActionList?.map((actionView, index:number) => {
          if (!filterActionTopView(actionView.code)) {
            return <></>;
          }
          return (
            <>
            <>
              {actionView?.resourceCode &&
              <FoldPermitCan 
              key={index}
              resource={actionView?.resourceCode}
              action={actionView.actionEvent}
              component={actionView?.view}
              /> }
              {!actionView.resourceCode && actionView?.view}
            </>
              {index != (isEFaxMessagingEnabled ? 3 : 2) && <Divider
                orientation={'vertical'}
                style={styles.dividerStyle2}
              />}

            </>
          );
        })}
        {false && (
          <View style={styles.topViewActionContainer}>
            <Tooltip
              title={
                !contactActiveStatus
                  ? getInActiveContactError(contactInfo)
                  : getToolTipMessage(
                      'SMS',
                      phoneNumber,
                      email,
                      contactInfo as IContact
                    )
              }
            >
              <Pressable
                onPress={() => {
                  if (contactActiveStatus) {
                // done this change intentionally on prod for palmetto consent warning issue
                // time: 1st March 2023
                 if (consentCheck) {
                      if (personData?.phoneNumber) {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            visible: true,
                          };
                        });
                      } else {
                        handleEdit?.();
                      }
                    } else {
                      handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
                    }
                  } else {
                    const message = getInActiveContactError(contactInfo);
                    notification.error({
                      message,
                    });
                  }
                }}
                style={styles.topViewActionPressable2}
              >
                <View style={styles.topViewActionIcon}>
                  <SmsActionSvg isActive={phoneNumber ? true : false} />
                  {getPreferredChannel(props.personData) ===
                    PREFERRED_CHANNEL_CODE.SMS && (
                    <View style={[styles.preferredIcon]}>
                      <StartPreferredicon />
                    </View>
                  )}
                </View>
                <Text
                  fontSize={12}
                  fontWeight={500}
                  color={phoneNumber ? '#667085' : '#D0D5DD'}
                >
                  SMS
                </Text>
              </Pressable>
            </Tooltip>
            <Divider
              orientation={'vertical'}
              style={styles.dividerStyle2}
            />
          </View>
        )}
        {false && (
          <View style={styles.topViewActionContainer}>
            <Tooltip
              title={
                !contactActiveStatus
                  ? getInActiveContactError(contactInfo)
                  : getToolTipMessage(
                      'EMAIL',
                      phoneNumber,
                      email,
                      contactInfo as IContact
                    )
              }
            >
              <Pressable
                onPress={() => {
                  if (contactActiveStatus) {
                    if (consentCheck) {
                      if (email) {
                        handleEmail();
                      } else {
                        handleEdit?.();
                      }
                    } else {
                      handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
                    }
                  } else {
                    const message = getInActiveContactError(contactInfo);
                    notification.error({
                      message,
                    });
                  }
                }}
                style={{
                  height: 36,
                  alignItems: 'center',
                  paddingLeft: !props.hideVideoCallingButton ? 4 : 16,
                  paddingRight: 16,
                }}
              >
                <View>
                  <View style={styles.topViewActionIcon}>
                    <EmailActionSvg isActive={email ? true : false} />
                  </View>
                  {getPreferredChannel(contactInfo) ===
                    PREFERRED_CHANNEL_CODE.EMAIL && (
                    <View style={[styles.preferredIcon]}>
                      <StartPreferredicon />
                    </View>
                  )}
                </View>
                <Text
                  fontSize={12}
                  fontWeight={500}
                  color={email ? '#667085' : '#D0D5DD'}
                >
                  Email
                </Text>
              </Pressable>
            </Tooltip>
          </View>
        )}
      </View>
      )}
      <VStack style={styles.mainMenuContainer}>
        {personActionList.map((singleActionObj, index: number) => {
          if (filterActionList(singleActionObj?.actionCode)) {
            return <></>;
          }
          const eventAndResource = getActionEventAndResourceCodeByOption(singleActionObj?.actionCode)
          return (
            <>
          {eventAndResource?.resourceCode &&  
          <FoldPermitCan 
          key={index}
          resource={eventAndResource.resourceCode}
          action={eventAndResource.actionEvent}
          component={popoverOptionView(singleActionObj,index,)}
          />
          }
          {
            !eventAndResource?.resourceCode && popoverOptionView(singleActionObj,index)
          }
          </>
          );
        })}
        {showOpenInHint && (
          <>
            <Pressable
              onPress={() => {
                handleOpenInHint();
              }}
            >
              <HStack style={[styles.mainMenuContent]}>
                <View style={styles.topViewActionIcon}>
                  <ExternalLinkAction isActive={true} />
                </View>
                <Text
                  style={[styles.mainMenuTxt, {color: '#667085'}]}
                  size={'smMedium'}
                >
                  Open in Hint
                </Text>
              </HStack>
            </Pressable>
            <Divider mt={2} style={styles.dividerStyle} />
          </>
        )}
        {/* remove open in EHR from popover as it take out to person action :: ABHAY */}
        {/* As we are showing View In EHR button in quick actions, we don't need to show it here as well */}
        {/* {(openInEhrUrl ||
          window.location.host === 'demo.sandbox.foldhealth.io') &&
          !isPhysician && (
            <Pressable
              onPress={() => {
                handleEHRBtnClick();
              }}
              mt={showOpenInHint ? 2 : 0}
              mb={2}
            >
              <HStack style={[styles.mainMenuContent]}>
                <View style={ {width: 20, height: 20}}>
                  <ViewEhrActionBtn isActive={true} />
                </View>
                <Text
                  style={[styles.mainMenuTxt, {color: '#667085'}]}
                  size={'smMedium'}
                >
                  Open in EHR
                </Text>
              </HStack>
            </Pressable>
          )} */}
      </VStack>
    </View>
  );
  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };

  return (
    <Box>
      <Popover
        overlayClassName='personActionPopover'
        overlayInnerStyle={antdStyles.popoverStyleContent}
        overlayStyle={antdStyles.popoverStyleOverlay}
        overlay={antdStyles.popoverStyleOverlay}
        style={antdStyles.popoverStyle}
        placement={'bottomLeft'}
        trigger="click"
        visible={isOpen}
        onVisibleChange={handleVisibleChange}
        content={content}
      >
        <Pressable
          style={styles.triggerBtn}
          onPress={() => {
            setIsOpen(!isOpen);
          }}
        >
          <Fontisto name="more-v-a" size={18} color={Colors.Custom.foldIconColor} />
        </Pressable>

        {/* <ModalActionBtn
          btnText="actions"
          btnStype={BUTTON_TYPE.PRIMARY}
          onClickAction={() => {
            setIsOpen(!isOpen);
          }}
          rightIcon={<AntIcon name="down" style={[styles.icons]} />}
        /> */}
      </Popover>

      <ComingSoonModal
        isOpen={comingSoonModal}
        onModalClose={() => setComingSoonModal(false)}
        headerText="Info"
        bodyText="This will navigate you to underlying EHR in production environment."
      />
      {selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
        <EmailDrawerCommon
          onMailCompleteAction={(actionCode: any) => {
            if (actionCode == COMMON_ACTION_CODES.RESET) {
              setSelectedView('');
            }
          }}
          contactData={{
            uuid: contactInfo?.uuid,
            name: contactInfo?.name,
            email: contactInfo?.email,
            accountId: contactInfo?.accountId,
            id: contactInfo?.id,
          }}
        />
      )}

      <Drawer
        title={
          <ModalActionTitle
            title={'Referrals'}
            titleSize={24}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: isSidecarContext ? 'back' : 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  setDrawerVisible(false)
                },
              }
            ]}
          />
        }
        visible={isDrawerVisible}
        onClose={() => setDrawerVisible(false)}
        width={720}
        style={antdStyles.drawerStyle}
      >
        <InsightsReferralOrdersView
          formattedContactData={formattedContactData}
          contactData={props.personData}
          onBack={() => {
            setDrawerVisible(true);
          }}
        />
      </Drawer>
    </Box>
  );
};
