import {useLazyQuery} from "@apollo/client";
import {GET_MEASURE_STEWARDS_AND_MEASURE_IDENTIFIERS} from "../../../services/Task/TaskQueries";
import {ICategory, IMeasureIdentifier, IMeasureSteward, IMeasureStewardsAndMeasureIdentifiersResponse} from "../interfaces";
import {useState, useEffect} from "react";
import {CARESTUDIO_APOLLO_CONTEXT} from "../../../constants/Configs";

const useCareGapAlert = () => {
  const [careGapAlertState, setCareGapAlertState] = useState<{
    measureIdentifiers: IMeasureIdentifier[];
    measureStewards: IMeasureSteward[];
    loading: boolean;
  }>({
    measureIdentifiers: [],
    measureStewards: [],
    loading: false,
  });
  const [getMeasureStewardsAndMeasureIdentifiers] = useLazyQuery(GET_MEASURE_STEWARDS_AND_MEASURE_IDENTIFIERS);
  const getMeasureStewardsAndMeasureIdentifiersData = async (params?: IMeasureStewardsAndMeasureIdentifiersResponse) => {
    try {
      setCareGapAlertState((prev) => ({...prev, loading: true}));
      const response: IMeasureStewardsAndMeasureIdentifiersResponse = await getMeasureStewardsAndMeasureIdentifiers({
        context: {service: CARESTUDIO_APOLLO_CONTEXT}, 
        variables: {params}
      });

      const measureIdentifiers: IMeasureIdentifier[] = [];
      const measureStewards: IMeasureSteward[] = [];

      response?.data?.getMeasureStewardsAndMeasureIdentifiers?.measureStewardAndMeasureIdentifiers.forEach((measureStewardAndMeasureIdentifier) => {
        measureIdentifiers.push({...measureStewardAndMeasureIdentifier.measureIdentifiers});
      });
      response?.data?.getMeasureStewardsAndMeasureIdentifiers?.measureStewards.forEach((measureSteward) => {
        measureStewards.push({...measureSteward, id: measureSteward.id, measureStewardName: measureSteward.name});
      });

      setCareGapAlertState((prev) => ({
        ...prev, 
        measureIdentifiers, 
        measureStewards, 
        loading: false
      }));
    } catch (error) {
      setCareGapAlertState((prev) => ({
        ...prev,
        loading: false
      }));
    }
  }
  useEffect(() => {
    getMeasureStewardsAndMeasureIdentifiersData();
  }, []);
  return {
    careGapAlertState,
    loading: careGapAlertState.loading,
  }
}

export default useCareGapAlert;
