import {StyleSheet} from 'react-native';
import { Colors } from '../../styles/Colors';

// NOTE: Changes made in this styles file will also affect outreach view in care management section in patient profile (PersonOmniView)

export const styles = StyleSheet.create({
  insightsPatientNotesView: {
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: Colors.FoldPixel.GRAY150,
    backgroundColor: Colors.Custom.White,
  },
  height100p: {
    height: '100%',
  },
  p8Height100p: {
    padding: 8,
    height: '100%',
  },
  centerContent: {
    display: 'flex',
    height: '100%', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  scrollViewStyles: {
    height: undefined, 
    overflow: 'scroll', 
    backgroundColor: Colors.FoldPixel.CONVERSATIONBGCOLOR
  },
  insightPatientView: {
    paddingTop: '16px',
    paddingRight: '16px',
    paddingLeft: '10px',
    paddingBottom: '0px'
  },
  checkboxContainer: {
    marginLeft: 2,
    color: Colors.Custom.mainPrimaryPurple
  },
  checkboxText: {
    marginLeft: 8,
    fontSize: 14,
    fontWeight: '400',
    color: Colors.Custom.Gray500,
    paddingTop:1
  },
  mt8: {
    marginTop: 8,
  },
  insightsPatientNotesViewHeader: {
    marginBottom: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 12,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY300,
  },
  textColorWhite: {
    color: Colors.Custom.White,
  },
  saveButtonText: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.White,
  },
  buttonTextDisabled: {
    color: Colors.FoldPixel.GRAY150,
  },
  dashboardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingLeft: 6,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tabButton: {
    backgroundColor: Colors.Custom.Transparent,
    borderWidth: 0.5,
    borderColor: Colors.Custom.Transparent,
    padding: '6px',
    borderRadius: 4
  },
  tabButtonActive: {
    backgroundColor: Colors.Custom.White,
    borderWidth: 0.5,
    borderColor: Colors.Custom.shadeGray,
    padding: '6px',
    borderRadius: 4
  },
  tabButtonText: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.FoldPixel.GRAY250,
  },
  tabButtonTextActive: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.Custom.Gray700,
  },
  detailedViewContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  }
});

export const outreachCommunicationModeSelectorStyles = styles;

export const reactStyles: Record<string, React.CSSProperties> = {
  w100p: {
    width: '100%',
  },
  datePicker: {
    height: '32px',
    fontSize: 14,
    width: 188,
    color: Colors.FoldPixel.GRAY400
  },
  skeletonInput: {
    height: 20,
    marginTop: 4,
    width: 500,
    borderRadius: 4
  },
  journeyDurationInput: {
    width: '80px', 
    borderWidth: 0, 
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end'
  },
};
