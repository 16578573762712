import { GoalOperator } from "./interface";

export const GOAL_ENTITY_TYPE_CODES = {
  VITAL: 'VITAL',
  ACTIVITY: 'ACTIVITY',
  LAB_ANALYTE: 'LAB_ANALYTE',
  SOCIAL: 'SOCIAL',
  FORM: 'FORM',
  OTHER: 'OTHER',
}
export const GOAL_ENTITY_TYPES = [
  { code: GOAL_ENTITY_TYPE_CODES.VITAL, value: 'Vital' },
  { code: GOAL_ENTITY_TYPE_CODES.ACTIVITY, value: 'Activity' },
  { code: GOAL_ENTITY_TYPE_CODES.LAB_ANALYTE, value: 'Lab result' },
  { code: GOAL_ENTITY_TYPE_CODES.FORM, value: 'Assessment' },
  { code: GOAL_ENTITY_TYPE_CODES.OTHER, value: 'Other' },
];

export const GOAL_OPERATOR_LIST = [
  { code: GoalOperator.gte, value: '>=' },
  { code: GoalOperator.lte, value: '<=' },
  { code: GoalOperator.gt, value: '>' },
  { code: GoalOperator.lt, value: '<' },
  { code: GoalOperator.eq, value: '=' },
  { code: GoalOperator.between, value: 'between' },
];

export const GOAL_TARGET_MEASURE_FREQUENCY_TYPES = [
  { id: 'daily', code: 'DAILY', value: 'Daily' },
  { id: 'weekly', code: 'WEEKLY', value: 'Weekly' },
  { id: 'monthly', code: 'MONTHLY', value: 'Monthly' },
  // { id: 'onEveryIncomingValue', code: 'ON_EVERY_INCOMING_VALUE', value: 'On Every Incoming Value' },
];

export const GOAL_TYPES = [
  { id: 'absolute', code: 'ABSOLUTE', value: 'Absolute' },
  { id: 'relative', code: 'RELATIVE', value: 'Relative' },
];

