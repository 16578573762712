import {useMutation} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../../constants/Configs';
import {useContactCareProgramContext} from '../../../ContactCareProgram.context';
import {useSelectedStepInCareProgram} from './useSelectedStepInCareProgram';
import {PERFORM_ACTION_ON_CARE_PROGRAM} from '../../../../../../../services/CareProgram/CareProgramQueries';
import {StepAction} from '../../../hooks/useCareProgramStepActions';
import {ADDITIONAL_ATTRIBUTE_KEY} from '../../../../../../common/MemebersView/constant';
import {ContactCareProgramReducerAction} from '../../../reducer';
import {useCareProgramAdditionalAttribute} from './useCareProgramAdditionalAttribute';

export const useDischargeRefferals = () => {
  const {state} = useContactCareProgramContext();
  const {selectedStep} = useSelectedStepInCareProgram();
  const {handleUpdateCareProgram} = useCareProgramAdditionalAttribute();
  const referralOrderIds =
    selectedStep?.careProgramStepAdditionalAttributes?.find(
      (attribute) =>
        attribute.attributeKey === ADDITIONAL_ATTRIBUTE_KEY.REFERRAL_ORDER_IDS
    )?.attributeValue?.valueJson?.referralOrderIds as string[];

  // API calls
  const [performCareProgramAction] = useMutation(
    PERFORM_ACTION_ON_CARE_PROGRAM,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const handleDischargeRefferalsActivity = async (
    referralOrderIds: string[]
  ) => {
    const payload = {
      params: {
        actionTypeCode: StepAction.SAVE_REFFERAL,
        careProgramParams: {
          contactCareProgramId: state.contactCareProgramDetails?.id,
          contactCareProgramStepId: selectedStep?.id,
        },
        refferalIds: referralOrderIds,
      },
    };

    const actionResponse = await performCareProgramAction({
      variables: payload,
    });
    return actionResponse;
  };

  const handleSaveReferralOrder = (
    referralOrderId?: string,
    isReferralDeleted?: boolean
  ) => {
    if (referralOrderId) {
      const formattedReferralOrderIds = !referralOrderIds
        ? [referralOrderId]
        : isReferralDeleted
        ? referralOrderIds.filter((id) => id !== referralOrderId)
        : [...new Set([...referralOrderIds, referralOrderId])];
      const attributeData = {
        attributeKey: ADDITIONAL_ATTRIBUTE_KEY.REFERRAL_ORDER_IDS,
        attributeValueDataTypeCode: 'json',
        attributeValue: {
          valueJson: {
            referralOrderIds: formattedReferralOrderIds,
          },
        },
        isDeleted: false,
        subjectId: selectedStep?.id,
        subjectTypeCode: 'CARE_PROGRAM_STEP',
      };
      handleUpdateCareProgram(
        ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STEP_ADDITIONAL_ATTRIBUTES,
        attributeData,
        true
      );
      handleDischargeRefferalsActivity(formattedReferralOrderIds);
    }
  };

  return {
    referralOrderIds,
    handleSaveReferralOrder,
  };
};
