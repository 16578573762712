import {useIntl} from "react-intl";
import {Pressable, View} from "react-native"
import {Text} from "native-base"
import {Colors} from "../../../../styles";
import {useEffect, useState} from "react";
import {IAddCustomFieldContainer, IAddCustomFieldContainerState, ICustomFieldData, ICustomFieldViewLoading, ICustomToast, IFormattedCustomFieldsData} from "./interface";
import {SelectCustomField} from "./SelectCustomField";
import {COMMON_ACTION_CODES} from "../../../../constants/ActionConst";
import {deleteCustomAttributeValue, getCustomFieldsForEntity} from "./CustomFieldService";
import {useToast} from "../../../Toast/ToastProvider";
import {ToastType} from "../../../../utils/commonViewUtils";
import {getFormattedCustomAttributesValue} from "./CustomFieldUtils";
import {CustomFieldsValueView} from "./CustomFieldsValueView";
import {v4 as uuidV4} from 'uuid';
import {ModalActionSubTitle} from "../../../common/ModalActionTitle/ModalActionSubTitle";
import {CUSTOM_ATTRIBUTE_SELECT_TYPE, CUSTOM_FIELD_TOAST_ERROR_DURATION, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG} from "./CustomFieldConst";
import {Tooltip, notification} from "antd";
import { testID, TestIdentifiers } from "../../../../testUtils";

export const AddCustomFieldContainer = (props: IAddCustomFieldContainer) => {
  const intl = useIntl();
  const toast = useToast();
  const [stateData, setStateData] = useState<IAddCustomFieldContainerState>({
    showSelectCustomField: false,
    customAttributesValue: [],
    loading: {} as ICustomFieldViewLoading
  });

  const onShowSelectCustomFieldChange = (value: boolean) => {
    setStateData((prev)=> {
      return {
        ...prev,
        showSelectCustomField: value,
      }
    })
  }

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const getCustomFieldsDataForEntity = async () => {
    const params = {
      entityId: props?.entityId
    }
    try {
      if (props?.entityId) {
        const response = await getCustomFieldsForEntity(params);
        if (response?.data?.length) {
          const customFields = response?.data;
          const formattedData = getFormattedCustomAttributesValue(customFields);
          setStateData((prev)=> {
            return {
              ...prev,
              customAttributesValue: formattedData
            }
          })
        } else {
          setStateData((prev)=> {
            return {
              ...prev,
              customAttributesValue: [],
            }
          })
        }
      }
    } catch (error: any) {
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  }

  const deleteCustomField = async (id: string, tempId: string) => {
    setStateData((prev)=> {
      return {
        ...prev,
        loading: {[id]: true}
      }
    })
    const params = {
      id: id
    }
    try {
      if (id) {
        const response = await deleteCustomAttributeValue(params);
        if (response?.data?.id) {
          const updatedList = stateData?.customAttributesValue?.filter((item)=> item?.id !== response?.data?.id);
          setStateData((prev)=> {
            return {
              ...prev,
              customAttributesValue: updatedList,
            }
          })
          notification.success({
            message: intl.formatMessage({id: 'deletedSuccessfully'}),
            duration: 4.0,
            placement: 'topRight'
          });
        }
        setStateData((prev)=> {
          return {
            ...prev,
            loading: {[id]: false}
          }
        })
      } else if (tempId) {
        const updatedList = stateData?.customAttributesValue?.filter((item)=> item?.tempId !== tempId);
        setStateData((prev)=> {
          return {
            ...prev,
            customAttributesValue: updatedList,
            loading: {[id]: false}
          }
        })
      }
    } catch (error: any) {
      setStateData((prev)=> {
        return {
          ...prev,
          loading: {[id]: false}
        }
      })
      notification.error({
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: 4.0,
        placement: 'topRight'
      });
    }
  }

  const addNewFieldValue = (newFieldData: ICustomFieldData[]) => {
    const formattedNewFieldData: IFormattedCustomFieldsData[] = newFieldData?.map((newField) => {
      return {
        customAttribute: {
          datatype: newField?.type,
          id: newField?.id,
          label: newField?.title,
        },
        customAttributeId: newField?.id,
        entityId: props?.entityId || '',
        entityType: props?.entityType,
        id: '',
        tempId: uuidV4(),
        isDeleted: false,
        value: '',
        dataSource: props?.dataSource
      }
    })
    const formattedData = getFormattedCustomAttributesValue(formattedNewFieldData);
    setStateData((prev)=> {
      return {
        ...prev,
        customAttributesValue: [...stateData?.customAttributesValue , ...formattedData],
        showSelectCustomField: false,
      }
    })
  }

  const onFieldValueChange = (newFieldData: ICustomFieldData) => {
    let updatedList: IFormattedCustomFieldsData[] = [];
    if (newFieldData?.id) {
      updatedList = stateData?.customAttributesValue?.map((item: IFormattedCustomFieldsData) =>
        (item?.id === newFieldData?.id || item.tempId === newFieldData?.id)
          ? {
            ...item,
            value: newFieldData?.fieldValue || '',
          }
          : item
      );
    }
    setStateData((prev)=> {
      return {
        ...prev,
        customAttributesValue: updatedList,
      }
    })
  }

  const onActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CUSTOM_FIELD_ADDED:
        addNewFieldValue(actionData);
        break;
      case COMMON_ACTION_CODES.CUSTOM_FIELD_VALUE_CHANGE:
        onFieldValueChange(actionData);
        break;
      case COMMON_ACTION_CODES.DELETE:
        deleteCustomField(actionData?.id, actionData?.tempId);
        break;
      default:
        break;
    }
  };

  const resetCustomField = () => {
    const updatedList = stateData?.customAttributesValue?.filter((item: IFormattedCustomFieldsData) => item?.id) || [];
    setStateData((prev)=> {
      return {
        ...prev,
        customAttributesValue: updatedList,
      }
    })
  }

  useEffect(()=> {
    getCustomFieldsDataForEntity();
  },[])

  useEffect(() => {
    props?.onDetailChange?.(stateData?.customAttributesValue);
  }, [stateData?.customAttributesValue]);

  useEffect(() => {
    resetCustomField();
  },[props?.contactTypeId])

  return (
      <>
        <View>
          {props?.showTitle && <View
            style={{
              marginBottom: stateData?.customAttributesValue?.length ? 0 : 14,
            }}
          >
            <ModalActionSubTitle
              marginTop={8}
              subTitle={'Custom Fields'}
              hideDivider={stateData?.customAttributesValue?.length ? false : true}
            />
          </View>}
          {stateData?.customAttributesValue?.length > 0 && <View>
            <CustomFieldsValueView
              customFieldsValue={stateData?.customAttributesValue}
              onActionPerformed={onActionPerformed}
              customFieldsError={props?.customFieldsError}
              loading={stateData?.loading}
            />
          </View>}
          {stateData?.showSelectCustomField && <View style={{
            marginBottom: 14,
          }}>
            <SelectCustomField
              onActionPerformed={onActionPerformed}
              entityType={props?.entityType}
              contactTypeId={props?.contactTypeId}
              customAttributesValue={stateData?.customAttributesValue}
              customAttributeSelectType={CUSTOM_ATTRIBUTE_SELECT_TYPE.MULTI_SELECT}
              placeholder={intl.formatMessage({id: 'selectCustomField'})}
            />
          </View>}
          <Tooltip
            title={!props?.contactTypeId && intl.formatMessage({id: 'selectContactType'})}
            placement={'top'}
            destroyTooltipOnHide={true}
          >
            {props?.showAddMore && <Pressable style={{
                opacity: props?.contactTypeId ? 1 : 0.5,
                width: 150,
              }}
              disabled={!props?.contactTypeId}
              onPress={()=> {
                onShowSelectCustomFieldChange(true);
              }}
              {...testID(TestIdentifiers.addCustomField)}
            >
              <Text
                size={'smRegular'}
                style={{
                  color: Colors.FoldPixel.PRIMARY300
                }}
              >
                {"+"} {intl.formatMessage({id: 'addCustomField'})}
              </Text>
            </Pressable>}
          </Tooltip>
        </View>
      </>
  )
}
