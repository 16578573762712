import {useState} from 'react';
import {HStack, View, Pressable, Tooltip} from 'native-base';
import TitleSubtitleView from '../../TitleSubtitleView/TitleSubtitleView';
import {Colors} from '../../../../styles';
import PatientDashboard from './PatientDashboard/PatientDashboard';
import ScheduleManager from './ScheduleManager/SceduleManager';
import CloseSideBarSvg from '../../../../assets/Icons/CloseSideBarSvg';
import {DayOptimizerProvider} from '../../../../context-provider/DayOptimizerContext/context';
import './styles.css';
import { testID, TestIdentifiers } from '../../../../testUtils';

const DayOptimizer = () => {
  const [isScheduleManagerVisible, setIsScheduleManagerVisible] =
    useState(true);

  return (
    <View backgroundColor={Colors.FoldPixel.GRAY50} height="100vh">
      <View>
        <TitleSubtitleView
          titleLabelId="appInfoTitle"
          subtitleLabelId="appInfoTitleSubtitle"
        />
      </View>
      <HStack
        flex={1}
        justifyContent={'space-between'}
        padding={'12px'}
        alignItems={'stretch'}
        width="100%"
      >
        <DayOptimizerProvider>
          <PatientDashboard
            isScheduleManagerVisible={isScheduleManagerVisible}
          />
        </DayOptimizerProvider>
        <ScheduleManager
          isScheduleManagerVisible={isScheduleManagerVisible}
          setIsScheduleManagerVisible={setIsScheduleManagerVisible}
        />
        {!isScheduleManagerVisible ? (
          <Tooltip label={'Expand'} placement={'left'}>
            <Pressable
              marginTop={'14px'}
              alignSelf={'baseline'}
              style={{
                padding: '4px',
              }}
              _hover={{
                backgroundColor: Colors.FoldPixel.GRAY100,
                borderRadius: 4,
              }}
              onPress={() => setIsScheduleManagerVisible(true)}
              {...testID(TestIdentifiers.DO_calendarPanelOpenBtn)}
            >
              <CloseSideBarSvg />
            </Pressable>
          </Tooltip>
        ) : (
          <></>
        )}
      </HStack>
    </View>
  );
};

export default DayOptimizer;
