import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  HStack,
  View,
  VStack,
  Text,
  useMediaQuery,
  useToast,
  Box,
  Pressable,
  Divider,
  Skeleton,
} from 'native-base';
import { Dimensions } from 'react-native';
import { Collapse, Input, Popover } from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useIntl } from 'react-intl';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import {
  FormContext,
  FormViewType,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import { v4 as uuidv4 } from 'uuid';
import { Colors } from '../../../../../../../styles';
import SectionLoadingIndicator from '../../CustomWrapper/SectionLoadingIndicator/SectionLoadingIndicator';
import { Drawer } from 'antd';
import { DATE_FORMATS, IPAD_MINI_WIDTH, IPAD_WIDTH, MLOV_CATEGORY, SMALL_WINDOW_1700 } from '../../../../../../../constants';
import { cloneDeep } from 'lodash';
import { DetailViewCollapse } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import { CUSTOM_COMPONENT_STYLE } from '../../constants';
import NoInterventionSvg from '../../../../../../common/Svg/CarePlanSvgs/NoInterventionSvg';
import InterventionRow from '../../../../../../common/Intervention/InterventionRow';
import SideMenu from '../../../../../Workflow/FlowComponent/StateNodes/SideMenu';
import { getNodeMetadata } from '../../../../../Journeys/AddOrUpdateJourney/JourneyMetadataService';
import { FlowType } from '../../../../../../../context/WorkflowContext';
import { ToastType, showToast } from '../../../../../../../utils/commonViewUtils';
import { IUserInputField } from '../../../../../Workflow/FlowComponent/StateNodes/FlowNodeInterface';
import { IInterventionType, IIntervention, IInterventionComponent, IInterventionList } from '../interfaces';
import { IFormValidationOutput } from '../../CustomWrapper/interfaces';
import { FormError } from '../../CustomWrapper/CustomComponentHelper';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';
import CarePlanInterventionQueries from '../../../../../../../services/Visit/CarePlanInterventionQueries';
import { InterventionLinkTask } from '../../../../../../common/Intervention/InterventionLinkTask';
import { getDueDateInDays, getInterventionIcon, getPriorityIcon, mapValues, reverseGetMetadata, } from './AddOrUpdateInterventionHelper';
import { DEFAULT_IMMEDIATE_DURATION, DEFAULT_IMMEDIATE_DURATION_UNIT, EntityType, INTERNAL_TASK_INTERVENTION_TYPE, InterventionActions, InterventionStatus, InterventionTypeValues, TaskType, interventionType, statusConfig } from './InterventionConstants';
import { addTimeToDate, getDateDifference, getDateObjectFromAPIFormatToMyFormat, getMomentObj, isCurrentDay, isPastDay } from '../../../../../../../utils/DateUtils';
import { styles } from '../../../../Analytics/style';
import ThreeDotsSvg from '../../../../../../common/Svg/ThreeDotsSvg';
import CustomConfirmationModal from '../../../../../../common/CustomConfirmationModal/CustomConfirmationModal';
import { CaretDownOutlined } from '@ant-design/icons';
import { CARE_PLAN_ITEM_STATUS_CODES } from '../../../../../../../constants/MlovConst';
import { MlovQueries } from '../../../../../../../services';
import { getMlovIdFromCode, getMlovObjectFromCode } from '../../../../../../../utils/mlovUtils';
import { IMlov } from '../../../../../../../Interfaces';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import CrossIconSvg from '../../../../../../common/Svg/CrossIconSvg';
import { isInvalidUserInput } from '../../../../../Workflow/FlowComponent/StateNodes/FlowNodeHelper';

const AddOrUpdateIntervention = (props: IFormComponentProps) => {
  const { Panel } = Collapse;
  const intl = useIntl();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isSidecarContext = !!contextData.sidecarContext?.isSidecar;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const componentRef = useRef();
  const carePlanDetailsStartDateTime = props?.options?.carePlanDetailsStartDateTime;
  const {TextArea} = Input;

  const toast = useToast();

  const initialDuration = {
    unit: 'day',
    value: 1,
  }
  const [componentState, setComponentState] =
    useState<IInterventionComponent>({
      loading: true,
      formError: contextData.formError,
      collapsed: contextData.defaultCollapse,
      showSavingIndicator: false,
      editModalVisible: false,
      editModalCollapse: false,
      selectedItemToMap: undefined,
      activePanels: [],
      mlovMap: new Map(),
    });
    const initialState =  {
      title: '',
      interventionTitle: '',
      trigger: initialDuration,
      type: ''
    }
  const statusOptions = [
    { value: InterventionStatus.COMPLETED, label: 'Complete', color: Colors.Custom.CompletedTaskFontColor }
  ];
  const [interventionDetails, setInterventionDetails] = useState<IIntervention>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);
  const [interventionTitle, setInterventionTitle] = useState<string>('');
  const [selectedIntervention, setSelectedIntervention] = useState<string | undefined>('');
  const [interventionTypeNodes, setInterventionTypeNodes] = useState<any[]>([]);
  const [userInputFieldList, setUserInputFieldList] = useState<any[]>();
  const [selectedInterventionTypeNodes, setSelectedInterventionTypeNode] = useState<any>();
  const [selectedDuration, setSelectedDuration] = useState<any>(initialDuration);
  const [openActionPopoverId, setOpenActionPopoverId] = useState<string | null>(null);
  const [selectedInterventionForDelete, setSelectedInterventionForDelete] = useState<IIntervention | undefined>();
  const [selectedInterventionForComplete, setSelectedInterventionForComplete] = useState<IIntervention | undefined>();
  const [selectedInterventionForLinkTask, setSelectedInterventionForLinkTask] = useState<IIntervention | undefined>();
  const [openStatusPopoverId, setOpenStatusPopoverId] = useState<string | null>(null);
  const [confirmationReason, setConfirmationReason] = useState<string | undefined>();
  const [nodeMetaDataCount, setNodeMetaDataCount] = useState<number>(0);

  const handleActionPopoverVisibility = (id: string, isVisible: boolean) => {
    setOpenActionPopoverId(isVisible ? id : null);
  };

  const handleStatusPopoverVisibility = (id: string, isVisible: boolean) => {
    setOpenStatusPopoverId(isVisible ? id : null);
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700
  const [showInterventionRowView, setShowInterventionRowView] = useState<boolean>(false);
  const [disableIntervention, setDisableIntervention] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(true);
  const [metdataError, setMetdataError] = useState<boolean>(false);

  const [componentValue, setComponentValue] = useState<IInterventionList>(
    props.component?.selectedValue ||
    props.component.defaultComponentValue
    || { interventions: [] }
  );

  const isCarePlanPublished = (): boolean => {
    return !!(interventionDetails?.startDateTime || carePlanDetailsStartDateTime);
  }

  const getDurationFromPublishedDate = (duration: any): { unit: string, value: number } => {
    if (isCarePlanPublished()) {
      const currentDateObj = getMomentObj(new Date());
      const publishedDateObj = getMomentObj(interventionDetails?.startDateTime || carePlanDetailsStartDateTime);
      const newExecuteAfterTime = Math.ceil(getDateDifference(publishedDateObj, currentDateObj, duration?.unit, true));
      return { unit: duration?.unit, value: newExecuteAfterTime + parseInt(duration?.value) };
    }
    return duration;
  }

  const getDurationInfoMessage = (duration: any) => {
    const isCarePlanPublishedFlag = isCarePlanPublished();
    let newDuration;
    if (isCarePlanPublishedFlag) {
      newDuration = getDurationFromPublishedDate(duration);
    } else {
      newDuration = duration;
    }
    if (newDuration.unit === 'immediate' || newDuration.unit === 'second') {
      return isCarePlanPublishedFlag ?
        `Will execute immediately (${getMomentObj(carePlanDetailsStartDateTime).format(DATE_FORMATS.CALENDAR_LIB_FORMAT)})` :
        'Will execute immediately after care plan is started';
    }
    return(isCarePlanPublishedFlag ? (
      `Will execute in ${duration.value} ${duration.unit}${duration.value > 1 ? 's' : ''} (${getMomentObj(addTimeToDate(carePlanDetailsStartDateTime, newDuration.value, newDuration.unit)).format(DATE_FORMATS.CALENDAR_LIB_FORMAT)})`
    ) : (
      `Will execute ${newDuration.value} ${newDuration.unit}${newDuration.value > 1 ? 's' : ''} after care plan is started`
    ))
  }

  const [durationInfoMsg, setDurationInfoMsg] = useState<string>(getDurationInfoMessage(initialDuration))
  const isWidgetView = props.options.componentView === FormViewType.widget;

  const resetUserInputFields = (fieldList: IUserInputField[]) => {
    if (!fieldList) return [];

    return fieldList.map(field => ({
      ...field,
      value: field.defaultValue || '',
      displayValue: field.defaultValue || '',
      ...(field.type === 'object' && {
        value: undefined
      })
    }));
  };

  const onInterventionTypeChange = (interventionEntityType: string) => {
    const typeIndex = interventionType.findIndex((item) => item.code === interventionEntityType || item.value === interventionEntityType)
    const newSelectedIntervention = interventionType[typeIndex]?.type
    setSelectedIntervention(interventionType[typeIndex].value)
    if (newSelectedIntervention) {
      let selectedInterventionTypeData: any
      if (interventionTypeNodes) {
        selectedInterventionTypeData = interventionTypeNodes.find(obj => obj[newSelectedIntervention]);
      }
      // if (!isEdit) {
      //   // const resetFields = resetUserInputFields(
      //   //   selectedInterventionTypeData[newSelectedIntervention].userInputFieldList
      //   // );
      //   // selectedInterventionTypeData[newSelectedIntervention].userInputFieldList = resetFields;
      // }
      setSelectedInterventionTypeNode(selectedInterventionTypeData[newSelectedIntervention])
      setUserInputFieldList(selectedInterventionTypeData[newSelectedIntervention]?.userInputFieldList)
    }
    setIsVisible(true)
  }

  const [getAccountMlovList] = useLazyQuery(MlovQueries.GET_ACCOUNT_MLOVS_BY_CATEGORY, {
    fetchPolicy: 'no-cache',
    context: {
      headers: contextData.headers,
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const fetchMetaData = async () => {
    try {
      const tenantId = contextData?.tenantId;
      if (!tenantId) {
        return;
      }
      const mlovData = await getAccountMlovList({
        variables: {
          categories: [MLOV_CATEGORY.CARE_PLAN_ITEM_STATUS],
        },
      });
      const carePlanItemStatus: IMlov[] = (mlovData?.data?.accountMlovsDeprecated || []).filter((mlov: any) => mlov?.category?.category === MLOV_CATEGORY.CARE_PLAN_ITEM_STATUS && (!mlov.tenantId || mlov.tenantId === tenantId));

      setComponentState((prev)=>{
        const map = prev.mlovMap;
        map.set(MLOV_CATEGORY.CARE_PLAN_ITEM_STATUS, carePlanItemStatus);
        return {
          ...prev,
          mlovMap: map,
        }
      });
    } catch (error) {

      setComponentState((prev) => ({
        ...prev,
        formError: FormError.configurationDataAPIFail,
      }));
    }
  };

  const onInterventionTitleChange = (title: string) => {
    setInterventionTitle(title);
    setInterventionDetails(prev => {
      if (prev?.metadata && prev.metadata.hasOwnProperty('title')) {
        return {
          ...prev,
          metadata: {
            ...prev.metadata,
            title: title
          }
        };
      } else {
        return prev;
      }
    });
  }

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({ id: 'apiErrorMsg' }), ToastType.error);
  };

  const onDurationChange = (duration: any) => {
    const newDuration = {
      ...selectedDuration,
      ...duration
    }
    if (newDuration.unit === 'immediate' || (selectedDuration.unit === 'immediate' && newDuration.unit !== 'immediate')) {
      newDuration.value = 1;
    }
    setSelectedDuration(newDuration)
    setDurationInfoMsg(getDurationInfoMessage(newDuration));
  }

  useEffect(() => {
    getNodeMetaData()
    fetchMetaData();
  }, [])

  const getNodeMetaData = () => {
    getNodeMetadata(
      FlowType.careJourney,
      (response) => {
        const nodeMap = response?.nodeMasterData?.nodeMap;
        const interventionNodes = getInterventionNodesList(interventionType, nodeMap);
        setInterventionTypeNodes((prev) => {
          const newNodes = [...prev, ...interventionNodes];
          return newNodes;
        })
        setNodeMetaDataCount(prev => prev+1);
      },
      () => {
        showErrorToast();
      }
    );
    getNodeMetadata(
      FlowType.patients,
      (response) => {
        const nodeMap = response?.nodeMasterData?.nodeMap;
        const interventionNodes = getInterventionNodesList([INTERNAL_TASK_INTERVENTION_TYPE], nodeMap);
        setInterventionTypeNodes((prev) => {
          const newNodes = [...prev, ...interventionNodes];
          return newNodes;
        })
        setNodeMetaDataCount(prev => prev+1);
      },
      () => {
        showErrorToast();
      }
    )
  };

  useEffect(() => {
    if(nodeMetaDataCount === 2){
      setComponentState((prev) => ({
        ...prev,
        loading: false,
      }));
    } else {
      setComponentState((prev) => ({
        ...prev,
        loading: true,
      }));
    }
  }, [nodeMetaDataCount])

  const getInterventionNodesList = (taskType: IInterventionType[], allTaskType: any) => {
    const tasktypeData: any = [];
    taskType.forEach(task => {
      if (allTaskType.hasOwnProperty(task.type)) {
        const taskObject: any = {};
        taskObject[task.type] = allTaskType[task.type];
        const filteredUserInputFieldList = taskObject[task.type].userInputFieldList.filter((item: any) => task.userInputFieldListKeys.includes(item.key));
        taskObject[task.type].userInputFieldList = []
        taskObject[task.type].userInputFieldList.push(...filteredUserInputFieldList)
        tasktypeData.push(taskObject);
      }
    });
    return tasktypeData;
  }

  const addNewItem = (item: IIntervention) => {
    setComponentValue((prev) => {
      prev.interventions.splice(0, 0, item);
      return {
        ...prev,
      }
    });
  };

  useEffect(() => {
    const value = cloneDeep(componentValue);
    if (contextData.isBuilderMode) {
      props.component.defaultComponentValue = value;
    }
    props.onChange(value);
  }, [componentValue]);

  const onRowCancel = () => {
    setInterventionTitle('')
    setSelectedDuration(initialDuration)
    setDurationInfoMsg(getDurationInfoMessage(initialDuration));
    setSelectedIntervention('')
    setShowInterventionRowView(false)
    setShowPopup(true)
  }

  const onTaskEdit = (type: string, intervention?: IIntervention, isVisibleDrawer=true) => {
    const interventionObject = intervention || interventionDetails;
    setIsVisible(isVisibleDrawer)
    // getNodeMetaData()
    const typeIndex = interventionType.findIndex(
      (item) => item.code === type || item.value === type
    )
    const newSelectedIntervention = interventionType[typeIndex]?.type
    let selectedInterventionTypeData
    if (newSelectedIntervention) {
      if (interventionTypeNodes) {
        selectedInterventionTypeData = interventionTypeNodes.find(obj => obj[newSelectedIntervention]);
      }
      const reverseMetadata = reverseGetMetadata(interventionObject?.metadata || {})
      const newNode = selectedInterventionTypeData[newSelectedIntervention]
      const mappedValues = mapValues(reverseMetadata, newNode.userInputFieldList);
      newNode.userInputFieldList = []
      newNode.userInputFieldList = mappedValues
      setSelectedInterventionTypeNode(newNode)
      setUserInputFieldList(newNode.userInputFieldList)
    }
  }

  const openDrawer = () => {
    setIsVisible(true)
  }
  const callPostInterventionApi = async (intervention: IIntervention) => {
    if (intervention.trigger?.unit === 'immediate') {
      intervention.trigger = DEFAULT_IMMEDIATE_DURATION;
    }
    try {
      const { statusId, status, ...interventionWithoutStatus } = intervention; // Remove status
      await addOrUpdateTemplate({
        variables: {
          params: interventionWithoutStatus
        }
      });
    } catch (error) {
      showErrorToast();
    }
  }

  const getInterventionId = () => {
    if (contextData.isBuilderMode) {
      return uuidv4();
    }
    return Boolean(props.options?.contactCarePlanId)
      ? interventionDetails?.id 
      : uuidv4();
  };

  const onEditIntervention = (
    recurrence?:
      | {
          frequency: any;
          duration: any;
          displayValue?: string;
        }
      | undefined
  ) => {
    // when builder mode on create new intervention every add or update
    const intervention: IIntervention = {
      id: getInterventionId(),
      title: interventionTitle,
      type: interventionDetails?.type,
      isDeleted: false,
      trigger: getDurationFromPublishedDate(selectedDuration),
      metadata: interventionDetails?.metadata
    }
    if (recurrence) {
      intervention.frequency = recurrence.frequency
      intervention.duration = recurrence.duration
      intervention.metadata.recurrenceDisplay = recurrence.displayValue
    } else {
      intervention.metadata.recurrenceDisplay = ''
      intervention.frequency = undefined
      intervention.duration = undefined
    }
    if (interventionDetails?.workflowStatus) {
      intervention.workflowStatus = interventionDetails?.workflowStatus
    }
    if (interventionDetails?.statusId) {
      intervention.statusId = interventionDetails?.statusId
    }
    if (interventionDetails?.status) {
      intervention.status = interventionDetails?.status
    }
    setComponentValue((prev) => {
      const index = prev.interventions.findIndex(
        (item) => item.id === interventionDetails?.id
      );
      prev.interventions.splice(index, 1);
      prev.interventions.splice(index, 0, intervention)
      return {
        ...prev,
      }
    });
    callPostInterventionApi(intervention);
    if (interventionDetails?.startDateTime) {
      intervention.startDateTime = interventionDetails?.startDateTime;
    }
    setInterventionDetails(intervention);
    setIsEdit(false);
    setShowPopup(true);
  }

  const onCreateNewIntervention = (
    recurrence?:
      | {
          frequency: any;
          duration: any;
          displayValue?: string;
        }
      | undefined
  ) => {
    const type = getInterventionEntityType()
    const intervention: IIntervention = {
      id: uuidv4(),
      title: interventionTitle,
      type: type,
      isDeleted: false,
      trigger: getDurationFromPublishedDate(selectedDuration),
      metadata: {
        ...getInterventionMetadata(userInputFieldList),
        recurrenceDisplay: recurrence?.displayValue || '',
      },
      frequency: recurrence?.frequency,
      duration: recurrence?.duration,
    };

    if (!userInputFieldList?.length) {
      setMetdataError(true)
      return
    }
    const finalInterventionObj = {...intervention, ...recurrence};
    setInterventionDetails(finalInterventionObj);
    if (intervention) {
      callPostInterventionApi(intervention);
      addNewItem({...intervention, ...recurrence});
    }
    setShowPopup(true);
  }

  const getDurationFromCurrentDate = (duration: any) => {
    if (isCarePlanPublished()) { // this intervention is part of published care-plan
      const currentDateObj = getMomentObj(new Date());
      const publishedDateObj = getMomentObj(interventionDetails?.startDateTime || carePlanDetailsStartDateTime).toDate();
      const executeDateObj1 = getMomentObj(addTimeToDate(publishedDateObj, duration.value, duration.unit));
      const newExecuteAfterTime = Math.floor(getDateDifference(currentDateObj, executeDateObj1, duration.unit, true));
      return {
        unit: duration.unit,
        value: newExecuteAfterTime <= 0 ? 1 : newExecuteAfterTime,
      };
    }
    // care-plan is not published
    if (duration.unit === DEFAULT_IMMEDIATE_DURATION_UNIT) {
      return { unit: 'immediate', value: 1 };
    }
    return duration;
  }

  const getInterventionRowView = (fullWidth?: boolean): JSX.Element => {
    return (
      <View
        flex={1}
        style={styles.width100}
      >
        <InterventionRow
          dropdownPlacement={'bottomRight'}
          recurrence={{
            frequency: interventionDetails?.frequency,
            duration: interventionDetails?.duration,
            displayValue: interventionDetails?.metadata?.recurrenceDisplay
          }}
          metdataError={metdataError}
          onInterventionTitleChange={onInterventionTitleChange}
          onInterventionTypeChange={onInterventionTypeChange}
          onDurationChange={onDurationChange}
          selectedIntervention={selectedIntervention}
          interventionTitle={interventionTitle}
          selectedDuration={selectedDuration}
          onCancel={onRowCancel}
          openDrawer={openDrawer}
          disableIntervention={disableIntervention}
          onTaskEdit={onTaskEdit}
          disableSaveButton={isSaveButtonDisabled}
          onSaveIntervention={(recurrence) => {
            if (isEdit) {
              onEditIntervention(recurrence)
            } else {
              onCreateNewIntervention(recurrence);
            }
            setShowInterventionRowView(false)
            setDisableIntervention(false)
          }
          }
          durationInfoMessage={durationInfoMsg}
        />
      </View>
    );
  };

  const renderInterventionItems = (taskList: any[], headerText: string, showTopBorder?: boolean) => {
    if (!taskList?.length) {
      return (<></>);
    }
    return (
      <>
        <Text style={{
          paddingHorizontal: 14,
          paddingTop: 4,
          color: Colors.FoldPixel.GRAY300,
          borderTopWidth: showTopBorder ? 1 : 0,
          borderTopColor: Colors.Custom.Gray200,
          backgroundColor: '#F6F7F8',
        }}>{headerText}</Text>
        {taskList?.map((item: IIntervention) => {
          if (!item.isDeleted) {
            if (!item.metadata?.priority) {
              item.metadata.priority = 'urgent'; // medium priority is default
            }
            const isCompleted = item.status?.code === InterventionStatus.COMPLETED;
            const isStatusUpdateDisabled = !isCarePlanPublished() || isCompleted || !isWidgetView;
            const isPastStartDate = item.startDateTime ? isPastDay(getMomentObj(item.startDateTime)) || isCurrentDay(getMomentObj(item.startDateTime)) : false;
            const dueDateDateText = item.startDateTime ? getDueDateInDays(item.startDateTime, item) : '';
            return (
              showInterventionRowView && isEdit && interventionDetails?.id === item.id ? getInterventionRowView(true) :
              <>
                <HStack justifyContent={'space-between'} flex={1} borderTopWidth={1} borderTopColor={Colors.Custom.Gray300} paddingX={4}>
                  <HStack alignSelf={'flex-start'} justifyItems={'flex-start'} marginY={2} flex={1}>
                    <Box >
                      {item.type && getInterventionIcon(item.type)}
                    </Box>
                    <VStack justifyContent={'center'} width={'100%'}>
                      <Text size={'smMedium'} style={styles.fontSize14FontWeight500ColorGray600}>{item.title}</Text>
                        <HStack alignItems={'center'} space={2} flex={1}>
                          <Text size={'smRegular'} style={styles.fontSize14ColorGray400}>
                            {item.metadata?.priority &&
                              getPriorityIcon(item.metadata?.priority)}
                            {(item.startDateTime || !!item.metadata?.recurrenceDisplay) &&
                              (<Text size={'smRegular'} style={styles.fontSize14ColorGray400} paddingLeft={1}> • </Text>)}
                            {item?.startDateTime && (
                              <Text size={'smRegular'} style={styles.fontSize14ColorGray400}>
                                {isPastStartDate ? 'Started on ' : 'Starts on '}
                                {getDateObjectFromAPIFormatToMyFormat(
                                  item.startDateTime,
                                  DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
                                )}
                              </Text>
                            )}
                            {item?.startDateTime && item?.metadata.recurrenceDisplay &&
                              (<Text size={'smRegular'} style={styles.fontSize14ColorGray400} paddingLeft={1}> • </Text>)}
                            {!!item.metadata?.recurrenceDisplay && (
                              <Text size={'smRegular'} style={styles.fontSize14ColorGray400}>
                                Occurs {item.metadata?.recurrenceDisplay}
                              </Text>
                            )}
                            {item.startDateTime && dueDateDateText && (
                                <>
                                  <Text size={'smRegular'} style={styles.fontSize14ColorGray400}> • </Text>
                                  <Text
                                    size={'smRegular'}
                                    color={Colors.Custom.AppointmentStatusPending}
                                    fontSize={14}
                                  >
                                    {dueDateDateText}
                                  </Text>
                                </>
                              )}
                          </Text>
                        </HStack>
                      {isCompleted && (item.comments?.length || 0) > 0 && (
                        <Text size={'smRegular'} style={styles.fontSize14ColorGray400}>
                          {item.comments?.[0]?.comment}
                        </Text>
                      )}
                    </VStack>
                  </HStack>
                  <HStack alignItems={'center'} display={'flex-end'} space={1}>
                    {isCarePlanPublished() && isWidgetView && (
                      <Pressable
                        onPress={() => setSelectedInterventionForLinkTask(item)}
                      >
                        <Feather
                          name="arrow-up-right"
                          style={{fontWeight: '100'}}
                          color={Colors.FoldPixel.GRAY300}
                          size={16}
                        />
                      </Pressable>
                    )}
                    {isCarePlanPublished() && isWidgetView && <View style={styles.viewStyles4}/>}
                    {<Popover
                      showArrow={false}
                      open={openStatusPopoverId === item.id && !isStatusUpdateDisabled}
                      onOpenChange={(visible) => {
                        // Only allow opening if not completed
                        if (item.status?.code === InterventionStatus.COMPLETED) {
                          return;
                        }
                        handleStatusPopoverVisibility(item?.id || '', visible);
                      }}
                      content={
                        <VStack space={2} style={{ padding: '2px', minWidth: 110 }}>
                          {statusOptions.map((option) => (
                            <Pressable
                              key={option.value}
                              onPress={() => {
                                if(option.value === InterventionStatus.COMPLETED) {
                                  setInterventionDetails(item);
                                  setSelectedInterventionForComplete(item);
                                } else {
                                  onActionPerformed(InterventionActions.STATUS_CHANGE, item)
                                }
                                handleStatusPopoverVisibility(item?.id || '', false);
                              }}
                              style={{
                                padding: '8px 16px',
                              }}
                              >
                              <Text
                                color={Colors.Custom.Gray500}
                                fontSize='sm'
                              >
                                {option.label}
                              </Text>
                            </Pressable>
                          ))}
                        </VStack>
                      }
                      trigger="click"
                      placement="bottom"
                    >
                      <Pressable
                        onPress={() => handleStatusPopoverVisibility(item?.id || '', true)}
                        disabled={item?.statusId === InterventionStatus.COMPLETED || !isCarePlanPublished()}
                      >
                        <HStack
                          style={[
                            styles.statusContainer,
                            {
                              backgroundColor: item?.status?.code ?
                                statusConfig[item?.status?.code as keyof typeof statusConfig]?.backgroundColor :
                                statusConfig[InterventionStatus.NOT_STARTED]?.backgroundColor,
                              minWidth: isStatusUpdateDisabled ? 100 : 120,
                            }
                          ]}
                        >
                          <Text
                            style={{
                              color: item?.status?.code ?
                                statusConfig[item?.status?.code as keyof typeof statusConfig]?.textColor :
                                statusConfig[InterventionStatus.NOT_STARTED]?.textColor
                            }}
                          >
                            {item?.status?.code ?
                              statusConfig[item?.status?.code as keyof typeof statusConfig]?.label :
                              statusConfig[InterventionStatus.NOT_STARTED]?.label
                            }
                          </Text>
                          {!isStatusUpdateDisabled && (
                            <View
                              style={styles.viewStyles3}
                              backgroundColor={item?.status?.code ?
                                statusConfig[item?.status?.code as keyof typeof statusConfig]?.textColor :
                                statusConfig[InterventionStatus.NOT_STARTED]?.textColor}
                            />
                          )}
                          {!isStatusUpdateDisabled && (
                            <CaretDownOutlined
                            style={{
                              color: item?.status?.code ?
                                statusConfig[item?.status?.code as keyof typeof statusConfig]?.textColor :
                                statusConfig[InterventionStatus.NOT_STARTED]?.textColor
                            }}
                            />
                          )}
                        </HStack>
                      </Pressable>
                    </Popover>}
                    {(!isWidgetView && item?.status?.code !== InterventionStatus.COMPLETED) && <View style={styles.viewStyles4}/>}
                    {(!isWidgetView && item?.status?.code !== InterventionStatus.COMPLETED) && <Popover
                      showArrow={false}
                      open={openActionPopoverId === item.id}
                      onOpenChange={(visible) => handleActionPopoverVisibility(item?.id || '', visible)}
                      content={
                        <VStack space={2} style={{ padding: '8px 0', minWidth: 180 }}>
                          {(item?.status?.code !== InterventionStatus.IN_PROGRESS ) && <Pressable
                            disabled={!showPopup}
                            onPress={() => {
                              onActionPerformed(InterventionActions.EDIT, item);
                              setOpenActionPopoverId(null);
                            }}
                            style={{
                              padding: '8px 16px',
                              opacity: !showPopup ? 0.5 : 1,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Feather
                              name="edit-2"
                              color={!showPopup ? Colors.Custom.Gray300 : Colors.Custom.Gray500}
                              size={16}
                              style={{ marginRight: 8 }}
                            />
                            <Text color={Colors.Custom.Gray700}>Edit</Text>
                          </Pressable>}

                          {(!isWidgetView && item?.status?.code !== InterventionStatus.IN_PROGRESS) && <Divider style={{ margin: '4px 0' }} />}

                          <Pressable
                            disabled={!showPopup}
                            onPress={() => {
                              setSelectedInterventionForDelete(item);
                              setOpenActionPopoverId(null);
                            }}
                            style={{
                              padding: '8px 16px',
                              opacity: !showPopup ? 0.5 : 1,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Feather
                              name="trash-2"
                              color={!showPopup ? Colors.Custom.Gray300 : Colors.Custom.ErrorColor}
                              size={16}
                              style={{ marginRight: 8 }}
                            />
                            <Text color={Colors.Custom.ErrorColor}>Delete</Text>
                          </Pressable>
                        </VStack>
                      }
                      trigger="click"
                      placement="bottomRight"
                    >
                      <Pressable
                        onPress={() => handleActionPopoverVisibility(item?.id || '', true)}
                      >
                        <ThreeDotsSvg
                          color={Colors.Custom.Gray500}
                          style={{
                            cursor: 'pointer',
                            width: 20,
                            height: 20
                          }}
                        />
                      </Pressable>
                    </Popover>}
                  </HStack>
                </HStack>
                {selectedInterventionForDelete?.id === item.id && (
                  <CustomConfirmationModal
                    isOpen={selectedInterventionForDelete?.id === item.id}
                    headerText={intl.formatMessage({ id: 'deleteInterventionTitle' })}
                    message={intl.formatMessage({ id: 'deleteInterventionConfirmation' })}
                    customOkBtnText={intl.formatMessage({ id: 'delete' })}
                    customCancelBtnText={intl.formatMessage({ id: 'cancel' })}
                    modalSubContent={<View style={{marginBottom: 8}}>
                    <TextArea
                      placeholder="Please add any specific reason"
                      maxLength={150}
                      onChange={(e) =>
                        setConfirmationReason(e?.target?.value)
                      }
                      style={{
                        marginTop: 8,
                        borderRadius: 4,
                        padding: 8,
                        height: 90,
                        resize: 'none',
                      }}
                      showCount
                    />
                  </View>}
                    onConfirm={() => {
                      onActionPerformed(InterventionActions.DELETE, item);
                      setSelectedInterventionForDelete(undefined);
                      setConfirmationReason(undefined);
                    }}
                    onCancel={() => {
                      setSelectedInterventionForDelete(undefined);
                      setConfirmationReason(undefined);
                    }}
                  />
                )}
                {selectedInterventionForComplete?.id === item.id && (
                  <CustomConfirmationModal
                    isOpen={selectedInterventionForComplete?.id === item.id}
                    headerText={intl.formatMessage({ id: 'completeInterventionTitle' })}
                    message={intl.formatMessage({ id: 'completeInterventionConfirmation' })}
                    customOkBtnText={intl.formatMessage({ id: 'complete' })}
                    customCancelBtnText={intl.formatMessage({ id: 'cancel' })}
                    onConfirm={() => {
                      if (interventionDetails) {
                        onActionPerformed(InterventionActions.STATUS_CHANGE, interventionDetails);
                      }
                      setSelectedInterventionForComplete(undefined);
                      setConfirmationReason(undefined);
                    }}
                    onCancel={() => {
                      setSelectedInterventionForComplete(undefined);
                      setConfirmationReason(undefined);
                    }}
                    modalSubContent={<View style={{marginBottom: 8}}>
                    <TextArea
                      placeholder="Please add any specific reason"
                      maxLength={150}
                      onChange={(e) =>
                        setConfirmationReason(e?.target?.value)
                      }
                      style={{
                        marginTop: 8,
                        borderRadius: 4,
                        padding: 8,
                        height: 90,
                        resize: 'none',
                      }}
                      showCount
                    />
                  </View>}
                  />
                )}
                <Drawer
                  open={selectedInterventionForLinkTask?.id === item.id}
                  onClose={() => setSelectedInterventionForLinkTask(undefined)}
                  title={
                  <Stack direction='row' space={8} style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <Text style={styles.fontSize24FontWeight500ColorGray800}>
                    {'Linked Task(s)'}
                    </Text>
                    <Stack direction='row' space={12} style={styles.alignItemsCenter}>
                      <Pressable key={'CrossButton'} onPress={() => setSelectedInterventionForLinkTask(undefined)}>
                        <CrossIconSvg size={18} />
                      </Pressable>
                    </Stack>
                  </Stack>
                  }
                  width={800}
                >
                  <InterventionLinkTask
                    interventionId={item.carePlanInterventionId || ''}
                    patientId={`${contextData.patientId}`}
                    accountLocationUuid={`${contextData.locationId}`}
                  />
                </Drawer>
              </>
            );
          }
        })
        }
      </>
    )
  }

  const getInterventionDetailsView = (): JSX.Element => {
    const notProviderTaskList = componentValue.interventions.filter((item: IIntervention) => {
      return item?.type && item.type !== TaskType.ProviderTask && !item?.isDeleted
    });
    const providerTaskList = componentValue.interventions.filter((item: IIntervention) => {
      return item?.type && item.type === TaskType.ProviderTask && !item?.isDeleted
    });
    return (
      <View
        flex={1}
        style={styles.width100padding15}
      >
        {notProviderTaskList.length > 0 && renderInterventionItems(notProviderTaskList, 'For Patient')}
        {providerTaskList.length > 0 && renderInterventionItems(providerTaskList, 'For Internal Users', true)}
      </View>
    );
  };

  const onActionPerformed = (action: InterventionActions, intervention: IIntervention) => {
    setOpenStatusPopoverId(null);
    setOpenActionPopoverId(null);
    switch (action) {
      case InterventionActions.EDIT:
        setShowPopup(false)
        setIsSaveButtonDisabled(false);
        updateIntervention(intervention);
        setInterventionDetails(intervention)
        onTaskEdit(intervention?.type || '', intervention, false);
        break;
      case InterventionActions.DELETE:
        deleteIntervention(intervention);
        break;
      case InterventionActions.STATUS_CHANGE:
        changeInterventionStatus(intervention);
        break;
    }
  };

  const validateData = (currentData: IInterventionList): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return { isValid: true, message: '' };
    }
    setComponentState((prev) => ({ ...prev, showErrors: true }));
    const isValid = true;
    return { isValid: isValid && isSaveButtonDisabled, message: !isValid ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };

  props.validateRef.current = validateData;

  const [addOrUpdateTemplate] = useMutation(CarePlanInterventionQueries.ADD_OR_UPDATE_INTERVENTION, {
    fetchPolicy: 'no-cache',
    context: {
      Headers: contextData.headers,
      service: CARESTUDIO_APOLLO_CONTEXT,
    }
  })

  const [addOrUpdateContactIntervention] = useMutation(CarePlanInterventionQueries.ADD_OR_UPDATE_CONTACT_INTERVENTION, {
    fetchPolicy: 'no-cache',
    context: {
      Headers: contextData.headers,
      service: CARESTUDIO_APOLLO_CONTEXT,
    }
  })

  const updateIntervention = async (intervention: IIntervention) => {
    setIsEdit(true)
    setDisableIntervention(true)
    setShowInterventionRowView(true)
    setInterventionTitle(intervention.title)
    setSelectedIntervention(intervention?.type)
    setSelectedDuration(getDurationFromCurrentDate(intervention.trigger))
    setDurationInfoMsg(getDurationInfoMessage(getDurationFromCurrentDate(intervention.trigger)))
    setInterventionDetails(intervention)
  }

  const removeLocally = (intervention: any) => {
    const index = componentValue.interventions.findIndex(
      (item) => item.id === intervention.id
    );
    if (index !== -1) {
      if (contextData.isBuilderMode) {
        setComponentValue(prev => ({
          ...prev,
          interventions: prev.interventions.filter(item => item.id !== intervention.id)
        }));
      } else {
        if (componentValue.interventions[index]?.id) {
          componentValue.interventions[index].isDeleted = true
        }
        setComponentValue((prev) => ({
          ...prev,
          interventions: [...componentValue.interventions],
        }));
      }
    }
    if (componentValue.interventions?.length === 0) {
      setShowInterventionRowView(false)
    }
    setInterventionDetails(initialState)
  }

  const deleteIntervention = async (intervention: any) => {
    // if (intervention.carePlanInterventionId) {
    //   deleteContactIntervention(intervention);
    //   return;
    // }
    removeLocally(intervention);
  };

  const changeInterventionStatus = async (intervention: IIntervention) => {
    const carePlanItemStatusList = componentState.mlovMap.get(MLOV_CATEGORY.CARE_PLAN_ITEM_STATUS) || [];
    const completedStatus = getMlovObjectFromCode(CARE_PLAN_ITEM_STATUS_CODES.COMPLETED, carePlanItemStatusList);
    const reason = confirmationReason || '';
    const updatedIntervention = {
      id: intervention.carePlanInterventionId,
      statusId: completedStatus?.id,
      isDeleted: false,
      contactId: contextData.contactId,
      carePlanId: contextData.contactCarePlanId,
      interventionId: intervention.interventionId,
      ...(reason && {comments: [{
        comment: reason,
        id: uuidv4(),
      }]}),
    };
    try {
      await addOrUpdateContactIntervention({
        variables: {
          params: [updatedIntervention]
        }
      });
      setComponentValue((prev) => {
        const index = prev.interventions.findIndex(
          (item) => item.id === intervention.id
        );
        if (index !== -1) {
          prev.interventions[index] = {
            ...prev.interventions[index],
            ...updatedIntervention,
            status: {
              code: CARE_PLAN_ITEM_STATUS_CODES.COMPLETED,
              value: completedStatus?.value || ''
            }
          };
        }
        return {
          ...prev,
        };
      });
    } catch (error) {
    } finally {
    }
  };

  const deleteContactIntervention = async (intervention: IIntervention) => {
    const reason = confirmationReason || '';
    const updatedIntervention = {
      id: intervention.carePlanInterventionId,
      isDeleted: true,
      contactId: contextData.contactId,
      carePlanId: contextData.contactCarePlanId,
      interventionId: intervention.interventionId,
      ...(reason && {comments: [{
        comment: reason,
        id: uuidv4(),
      }]}),
    };
    try {
      await addOrUpdateContactIntervention({
        variables: {
          params: [updatedIntervention]
        }
      });
      setComponentValue((prev) => {
        const index = prev.interventions.findIndex(
          (item) => item.id === intervention.id
        );
        if (index !== -1) {
          prev.interventions[index] = {
            ...prev.interventions[index],
            ...updatedIntervention,
            isDeleted: true,
          };
        }
        return {
          ...prev,
        };
      });
      removeLocally(intervention);
    } catch (error) {
      return false;
    } finally {
    }
  };

  const getDetailView = (): JSX.Element => {
    return (
      <VStack alignItems={'center'}>
        {componentValue.interventions.length === 0 && !showInterventionRowView ? <NoInterventionSvg titleId="noIntervention" /> :
          <HStack space={2} alignItems="center" w={'100%'}>
            {componentState.showSavingIndicator && <SectionLoadingIndicator />}
            {showInterventionRowView && !isEdit && getInterventionRowView(true)}
          </HStack>}
      </VStack>
    );
  };

  const getInterventionDetails = (): JSX.Element => {
    return (
      <VStack paddingBottom={2} alignItems={'center'}>
        <HStack space={2} alignItems="center" w={'100%'}>
          {getInterventionDetailsView()}
        </HStack>
      </VStack>
    );
  };

  const showInterventionRow = () => {
    setIsSaveButtonDisabled(true);
    if (!isWidgetView) {
      setDisableIntervention(false)
      setShowInterventionRowView(true)
      setInterventionTitle('')
      setSelectedDuration(initialDuration)
      setSelectedIntervention('')
    }
    setIsEdit(false)
    setInterventionDetails(initialState)
  }

  const getInterventionEntityType = () => {
    const typeIndex = interventionType.findIndex((item) => item.value === selectedIntervention)
    switch (interventionType[typeIndex]?.type) {
      case InterventionTypeValues.CareJourneyAddTaskForContact: return EntityType.MemberTask
      case InterventionTypeValues.SendForms: return EntityType.Form
      case InterventionTypeValues.CareJourneyContentV3: return EntityType.EducationContent
      case InterventionTypeValues.CareJourneyVital: return EntityType.MeasureVital
      case InterventionTypeValues.CareJourneyAddTask: return EntityType.ProviderTask
      case InterventionTypeValues.SendAppointmentBookingLink: return EntityType.AppointmentLink
    }
  }

  const getInterventionMetadata = (selectedInterventionTypeNodes: any) => {
    const result: { [key: string]: string } = {};
    selectedInterventionTypeNodes.forEach((item: any) => {
      result[item.key] = item.value;
    });
    return result
  }

  const onSave = (data: any) => {
    const newUserInputFieldList: IUserInputField[] =
      data.userInputFieldMap.out.userInputFieldList || [];
    const titleObj = newUserInputFieldList.find((item: any) => ['patientFacingTitle', 'title'].includes(item.key));
    let title = titleObj?.value;
    if (!title) {
      const educationContent = newUserInputFieldList?.find(
        (item) => item.key === EntityType.EducationContent
      );
      if (educationContent) {
        title = educationContent?.value?.title || '';
      }
    } 
    if (Boolean(title)) {
      setInterventionTitle(title)
    }
    setUserInputFieldList(newUserInputFieldList)
    setInterventionDetails(prev => {
      if (!prev) return undefined;
      return {
        ...prev,
        metadata: {
          ...prev.metadata,
          ...getInterventionMetadata(newUserInputFieldList)
        }
      }
    });
    setIsVisible(false);
    setIsSaveButtonDisabled(false);
  };

  const handleClose = () => {
    const isInvalid = userInputFieldList?.some((item: IUserInputField) => isInvalidUserInput(item, userInputFieldList));
    if (isInvalid) {
      setIsSaveButtonDisabled(true);
    }

    setIsVisible(false);
  };

  return (
    <View ref={componentRef}>
      <div className='page-break'>
        <DetailViewCollapse
          collapsible='disabled'
          hideArrow
          btnList={!isWidgetView ? [
            {
              icon: <View style={styles.flexDirectionRow}>
                <AntIcon
                  name="plus"
                  style={{ color: Colors.Custom.foldIconColor, borderWidth: 0, marginRight: contextData.isBuilderMode ? 5 : 0 }}
                  size={20}
                />
                {(!contextData.isBuilderMode && contextData.componentView === 'detail' && !componentValue.interventions?.[0]?.startDateTime) && <View style={styles.viewStyles2} />}
              </View>
              ,
              onIconBtnClick: showInterventionRow,
              tooltipLabel: 'Add',
              placement: 'top right',
              isCustomButton: false,
            },
          ] : []}
          textLocalId={props.component.label}
          toggled={!componentState.collapsed}
          headerStyle={{ marginTop: 3 }}
          extraTextStyles={
            isPatientNote ? { fontWeight: 'bold', fontSize: 16 } : {}
          }
          extraPanelStyles={
            isPatientNote
              ? { borderColor: '#D0D5DD', ...CUSTOM_COMPONENT_STYLE }
              : {}
          }
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              collapsed: !prev.collapsed,
            }));
          }}
        >
          <VStack>
            {!componentState.formError &&
              getDetailView()}
            {componentState.loading
            ? <View padding={2}>
              <Skeleton.Text lines={3} />
              </View>
            :componentValue.interventions?.length > 0 && getInterventionDetails()}
          </VStack>
        </DetailViewCollapse>
      </div>
      <Drawer
        destroyOnClose
        placement="right"
        open={isVisible}
        closable
        width={
         isSidecarContext ? '100%' : (isIPadScreen || isIPadMiniScreen ? '70%' : smallWindow ? '60%' : '50%')
        }
        headerStyle={{ display: 'none' }}
      >
        <SideMenu
          isCareProgramContext={true}
          userInputFieldMap={{
            out: {
              userInputFieldList: userInputFieldList?.length ? userInputFieldList : selectedInterventionTypeNodes?.userInputFieldList,
            },
          }}
          nodeType={selectedInterventionTypeNodes || ''}
          isOpen={true}
          title={selectedInterventionTypeNodes && selectedInterventionTypeNodes.displayName || ''}
          onClose={handleClose}
          onSave={onSave}
        />
      </Drawer>
    </View>
  );
};

export default AddOrUpdateIntervention;
