import React from 'react';
import { Path, Svg } from 'react-native-svg';

const DownArrowSvg = () => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path d="M8 9L10 7H6L8 9Z" fill="#6F7A90" stroke="#6F7A90" stroke-linecap="round" stroke-linejoin="round" />
    </Svg> 
  );
};

export default React.memo(DownArrowSvg);
