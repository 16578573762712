import { Drawer, Image } from 'antd';
import { Skeleton, View, VStack, Text, Button } from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import ReactPlayer from 'react-player';
import { BUTTON_TYPE } from '../../../constants';
import { Colors } from '../../../styles';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import { getMediaTypeFromMimeType, MEDIA_TYPES } from './DocumentViewerHelper';
import { CommonDataContext } from '../../../context/CommonDataContext';
import CommonService from '../../../services/CommonService/CommonService';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';

interface IProps {
  isOpen?: boolean;
  drawerWidth?: string;

  fileName: string;
  fileType: string;
  fileUrl: string;

  onClose: () => void;
  mediaPlayerHeight?: string;
  mediaPlayerWidth?: string;
  base64Data?: string;
  patientId?: string;
  uniqueResourceIdentifier?: string;
}

const MODAL_ACTION_TITLE_HEIGHT = 60;

export function DocumentViewer(props: IProps) {
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData?.sidecarContext?.isSidecar;
  const [ showSummary, setShowSummary ] = useState<boolean>(false);
  const [ summary, setSummary ] = useState<string>('');

  const isDocumentSummaryEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_DOCUMENT_SUMMARY_ENABLED) && props?.base64Data && props?.patientId && props?.uniqueResourceIdentifier;
  const { height: windowHeight } = Dimensions.get('window');
  const documentViewerContainerHeight = windowHeight - MODAL_ACTION_TITLE_HEIGHT - 100;

  const mediaType = getMediaTypeFromMimeType(props.fileType || '');
  const canShowDocument = !!(mediaType && props.fileUrl);

  const getSummary = async (documentResourceId: string, source: string) => {
    const commonService = CommonService.getCommonServiceInstance();
    const llmflowService = commonService.llmService;

    const maxRetries = 10;

    for (let attempt = 1; attempt<=maxRetries; attempt++) {
      
      try {
        const response = await llmflowService.post(
          `/ocr/resource/summary`, {
            documentResourceId: documentResourceId,
            source: source,
            patientId: props?.patientId
          }
        );
        const isComplete = response?.data?.isComplete;
        const summary = response.data.summaryString;
        if (isComplete) {
          return summary;
        } else if (attempt < maxRetries) {
          throw new Error('Processing not complete')
        } else {
          return 'Error in fetching the summary';
        }

      } catch (error){
        console.log(`checkDocumentProcessStatus: API call to llm failing /ocr/resource/status`);
        if (attempt < maxRetries) {
          await new Promise(resolve => setTimeout(resolve, 30000));
        } else {

          return 'Error in fetching the summary';
        }
      }
    }
  }

  const generateSummary = async (hardRefresh: string) => {
    setSummary('');
    setShowSummary(true);
    getSummary(props?.uniqueResourceIdentifier || '', props?.base64Data || '').then(
      (summary: string) => {setSummary(summary)}
    )
    return;

  }

  return (
    <>
      {
        showSummary &&
        <Drawer
          open={showSummary}
          width={'70vw'}
          title={
          <ModalActionTitle
            title={'Summary'}
            titleColor={''}
            buttonList={[
              {
                show: false,
                id: 1,
                btnText: 'Regenerate Summary',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  generateSummary('true')
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'back',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  setShowSummary(false);
                },
              },
            ]}
          />}
          onClose={() => {
            if (typeof props.onClose === 'function') {
              props?.onClose();
            }
          }
        }>
          {summary==='' && (
            <VStack>
              <Skeleton.Text my={3} />
              <Skeleton />
            </VStack>
          )}
          {summary!=='' && (<Text> {summary}</Text>)}
        </Drawer>
      }
      {
        <Drawer
          open={props.isOpen || false}
          width={isSideCarContext ? '100%' : (props.drawerWidth || '70vw')}
          onClose={props.onClose}
          closable={false}
          title={
            <>
              <ModalActionTitle
                title={props.fileName}
                titleColor={''}
                buttonList={[
                  {
                    show: isDocumentSummaryEnabled,
                    id: 1,
                    btnText: 'Generate Summary',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: () => {
                      generateSummary('false');
                    },
                  },
                  {
                    show: true,
                    id: 2,
                    btnText: isSideCarContext ? 'back' : 'close',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: props.onClose,
                  }
                ]}
              />
            </>
          }
        >
          <View
            alignItems={'center'}
            justifyContent={'center'}
            height={documentViewerContainerHeight}
            width={'full'}
          >
            {
              canShowDocument &&
              (mediaType === MEDIA_TYPES.VIDEO || mediaType === MEDIA_TYPES.AUDIO) &&
              <ReactPlayer
                width={props?.mediaPlayerWidth || 'full'}
                height={props?.mediaPlayerHeight|| documentViewerContainerHeight}
                controls={true}
                url={props.fileUrl}
              />
            }
            {
              canShowDocument &&
              (mediaType === MEDIA_TYPES.PDF || mediaType === MEDIA_TYPES.WORD_DOC) &&
              <iframe width={'100%'} height={documentViewerContainerHeight} src={props.fileUrl} allow="fullscreen"></iframe>
            }
            {
              canShowDocument &&
              mediaType === MEDIA_TYPES.IMAGE &&
              <img src={props.fileUrl} style={{maxHeight: documentViewerContainerHeight}} />
            }
          </View>
        </Drawer>
      }

      {/* {
        canShowDocument &&
        mediaType === MEDIA_TYPES.IMAGE &&
        <Image
          preview={{
            closeIcon: false,
            visible: true,
            src: props.fileUrl,
            scaleStep: 0.2,
            onVisibleChange: (value) => {
              props.onClose();
            },
          }}
        />
      } */}
    </>
  );
}
