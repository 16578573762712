import { useLazyQuery, useMutation } from '@apollo/client';
import { useToast } from 'native-base';
import { useIntl } from 'react-intl';
import { ToastType } from '../../../../../utils/commonViewUtils';
import { showToast } from '../../../../../utils/commonViewUtils';
import ContactCareProgram from '../../../../../services/ContactCareProgram/ContactCareProgram';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { getComplexityValue } from '../../../CareManagementView/CareManagementUtils';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { useContext } from 'react';
import { IAddOrUpdateCareProgramAttributesPayload } from '../../interface';
import { IAwvTypeChangeResult, IComplexityChangeResult } from '../components/Cadence/interfaces';

export const useKeyPointActions = () => {
  const toast = useToast();
  const intl = useIntl();
  const commonDataContext = useContext(CommonDataContext);

  const [getcontactCareProgramData] = useLazyQuery(
    ContactCareProgram.GET_CONTACT_CARE_PROGRAM_DATA,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache'
    }
  );


  const [updateContactCareProgramComplexity] = useMutation(
    ContactCareProgram.UPDATE_CONTACT_CARE_PROGRAM_COMPLEXITY,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const [updateContactCareProgramAwvType] = useMutation(
    ContactCareProgram.UPDATE_CONTACT_CARE_PROGRAM_AWV_TYPE,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const handleComplexityChange = async (
    contactCareProgramId: string,
    contactId: string,
    complexity: string,
    statusId?: string
  ): Promise<IComplexityChangeResult> => {
    try {
      await updateContactCareProgramComplexity({
        variables: {
          params: {
            id: contactCareProgramId,
            contactId: contactId,
            complexity: complexity,
            statusId: statusId
          }
        }
      });
      const response = await getcontactCareProgramData({
        variables: { id: contactCareProgramId }
      });
      const complexityValue = getComplexityValue(
        response?.data?.contactCarePrograms?.[0]?.complexity || '',
        commonDataContext.CARE_STUDIO_MLOV
      );

      showToast(
        toast,
        intl.formatMessage({ id: 'complexityUpdateSuccess' }),
        ToastType.success
      );
      return {
        success: true,
        complexity: complexityValue
      };

    } catch (error) {
      showToast(
        toast,
      intl.formatMessage({ id: 'careProgramComplexityUpdateErrorMessage' }),
      ToastType.error
    );
    return {
      success: false
    };
  }
};


  const handleAwvTypeChange = async (
    contactCareProgramId: string,
    awvType: string,
    awvTypeCode: string
  ): Promise<IAwvTypeChangeResult> => {
    try {
      const params: IAddOrUpdateCareProgramAttributesPayload = {
        careProgramAttributes: [{
          contactCareProgramId: contactCareProgramId,
          subjectId: contactCareProgramId,
          subjectTypeCode: "CARE_PROGRAM_STEP",
          additionalAttributes: [{
            attributeKey: "awvType",
            attributeValue: {
              valueString: awvTypeCode
            },
            attributeValueDataTypeCode: "string",
            isDeleted: false
          }]
        }]
      };
      const response = await updateContactCareProgramAwvType({
        variables: { params }
      });
      showToast(
        toast,
        intl.formatMessage({ id: 'awvTypeUpdateSuccess' }),
        ToastType.success
      );
      return {
        success: true,
        awvType: awvType
      };
    } catch (error) {
      showToast(
        toast,
        intl.formatMessage({ id: 'awvTypeUpdateErrorMessage' }),
        ToastType.error
      );
      return {
        success: false
      };
    }
  };

  return {
    handleComplexityChange,
    handleAwvTypeChange
  };
};
