import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import Svg, {ClipPath, G, Path, Rect, Defs} from 'react-native-svg';

const OtherGoalSvg = (props: ICommonSvgProps) => {
  const {customStrokeColor, size} = props;
  const strokeColor = customStrokeColor || '#6F7A90';

  return (
    <Svg
      width={size ? size : '16'}
      height={size ? size : '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <G clipPath="url(#clip0_1914_19647)">
        <Path
          d="M7.99998 1.33301C11.6819 1.33301 14.6666 4.31778 14.6666 7.99967C14.6666 11.6816 11.6819 14.6663 7.99998 14.6663C4.31808 14.6663 1.33331 11.6816 1.33331 7.99967C1.33331 6.13475 2.09907 4.44867 3.33331 3.23872M3.33331 7.99967C3.33331 10.577 5.42265 12.6663 7.99998 12.6663C10.5773 12.6663 12.6666 10.577 12.6666 7.99967C12.6666 5.42235 10.5773 3.33301 7.99998 3.33301M7.99998 10.6663C9.47274 10.6663 10.6666 9.47243 10.6666 7.99967C10.6666 6.52692 9.47274 5.33301 7.99998 5.33301"
          stroke={strokeColor}
          strokeLinecap="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_1914_19647">
          <Rect width="16" height="16" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default React.memo(OtherGoalSvg);
