import React from 'react';
import SideMenu from '../../../../../RightSideContainer/Workflow/FlowComponent/StateNodes/SideMenu';
import {
  EDUCATION_CONTENT_NODE_DETAILS,
  NODE_INPUT_KEYS,
} from '../../../../../SideCar/constants';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {
  ICondition,
  IUserInputField,
} from '../../../../../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeInterface';
import {useMutation} from '@apollo/client';
import {SEND_EDUCATION_CONTENT} from '../../../../../../services/CareJourney/CareJourneyQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {notification} from 'antd';
import {useIntl} from 'react-intl';

const SendEdicationContent = (props: {
  contactId: string;
  onClose: () => void;
  typeNodes: any
  isSidecarContext?: boolean;
}) => {
  const mlovData = React.useContext(CommonDataContext);
  const intl = useIntl();

  const taskPriorityMlovs = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_PRIORITY
  );


  const [sendPatientEducation, {loading: sendPatientEducationLoading}] =
    useMutation(SEND_EDUCATION_CONTENT, {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onCompleted: (data) => {
        notification.success({
          message: intl.formatMessage({id: 'contentSentSuccessfully'}),
          duration: 3.0,
          placement: 'topRight',
        });
      },
      onError: (error: any) => {
        notification.error({
          message: intl.formatMessage({id: 'apiErrorMsg'}),
          duration: 3.0,
          placement: 'topRight',
        });
      },
    });

  const handleSendOfEducationContent = ({
    userInputFieldMap,
    conditionList,
  }: {
    userInputFieldMap: {out: {userInputFieldList: IUserInputField[]}};
    conditionList: {
      [key: string]: ICondition[];
    }[];
  }) => {
    const paramsForEducationContent = {
      contactId: props?.contactId,
      articleId: null,
      communicationMode: '',
      taskPriorityId: '',
      taskPatientFacingTitle: '',
      emailTemplateId: null,
      emailTemplateCategoryCode: '',
      emailTemplateSubject: '',
      smsBody: '',
    };

    userInputFieldMap?.out?.userInputFieldList.forEach((field) => {
      switch (field.key) {
        case NODE_INPUT_KEYS.EDUCATION_CONTENT:
          paramsForEducationContent.articleId = field.value.id;
          break;
        case NODE_INPUT_KEYS.PRIORITY:
          paramsForEducationContent.taskPriorityId = getMlovIdFromCode(
            taskPriorityMlovs,
            field.value
          );
          break;
        case NODE_INPUT_KEYS.PATIENT_FACING_TITLE:
          paramsForEducationContent.taskPatientFacingTitle = field.value;
          break;
        case NODE_INPUT_KEYS.COMMUNICATION_VIA_NODE:
          paramsForEducationContent.communicationMode = field.value;
          break;
        case NODE_INPUT_KEYS.EMAIL_TEMPLATE:
          paramsForEducationContent.emailTemplateId =
            field?.value?.template?.id || '';
          paramsForEducationContent.emailTemplateCategoryCode =
            field?.value?.category?.code || '';
          paramsForEducationContent.emailTemplateSubject =
            field?.value?.subject || '';
          paramsForEducationContent.smsBody = field.value.sms?.body || '';
          break;
        default:
          break;
      }
    });
    sendPatientEducation({
      variables: {params: paramsForEducationContent},
    }).finally(() => {
      props.onClose();
    });
  };

  return (
    <>
       <SideMenu
          hideSideMenuTopBar={true}
          userInputFieldMap={{
            out: {
              userInputFieldList: props.typeNodes?.userInputFieldList || [],
            },
          }}
          nodeType={props.typeNodes || ''}
          isOpen={true}
          title={(props.typeNodes && props.typeNodes.displayName) || ''}
          onClose={() => {
            props.onClose();
          }}
          onSave={handleSendOfEducationContent}
          isContinueButtonLoading={sendPatientEducationLoading}
          primaryButtonText={'Send'}
          encloseSideMenuInDrawer={true}
          width={props.isSidecarContext ? '100%' : '40%'}
        />
    </>
  );
};

export default SendEdicationContent;
