import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { GET_CARE_PROGRAM_STEP_LAST_ACTIVITY_LOGS } from '../../../../../../../services/ContactCareProgram/ContactCareProgram';
import { DATE_FORMATS } from '../../../../../../../constants/StringConst';
import { getDateStrFromFormat, isSameDay } from '../../../../../../../utils/DateUtils';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';
import { getMlovObjectFromId, getMlovValueFromId } from '../../../../../../../utils/mlovUtils';
import useCareProgramStepStatus from '../../../hooks/useCareProgramStepStatus';
import {getUserUUID, replaceHashValueToString} from '../../../../../../../utils/commonUtils';
import {StepAction} from '../../../hooks/useCareProgramStepActions';
import {CARE_PROGRAM_STEP_STATUS_CODES, CARE_PROGRAM_STEP_TYPE} from '../../../../../../../constants/MlovConst';
import {DUMMY_OUTREACH_STEP_ID} from '../../../ContactCareProgramView.utils';
import { IActivityLog, IContactCareProgramStep, IMlov } from '../../../../interface';
import { GetContactOutreachActivities } from '../../../../../../../services/ContactOutreach/ContactOutreachQueries';
import { useIntl } from 'react-intl';
import { OUTREACH_STEP_TYPES } from '../../../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import { useContactCareProgramContext } from '../../../ContactCareProgram.context';
import { useCareProgramContentHeaderAction } from '../../useCareProgramContentHeaderAction';

export enum ActivityActionTypes {
  SEND_TO_PATIENT = 'SEND_TO_PATIENT',
  AUTO_SAVE = 'AUTO_SAVE',
  STATUS_UPDATE = 'STATUS_UPDATE',
  FORM_RESPONSE_ACCEPTED = 'FORM_RESPONSE_ACCEPTED',
  REVIEWED = 'REVIEWED',
  SIGN_REFFERAL = 'SIGN_REFFERAL',
  SAVE_REFFERAL = 'SAVE_REFFERAL',
}

const StatusActionTextMap = {
  [CARE_PROGRAM_STEP_STATUS_CODES.SKIP] : 'Skipped',
  [CARE_PROGRAM_STEP_STATUS_CODES.DONE] : 'Reviewed',
  [ActivityActionTypes.FORM_RESPONSE_ACCEPTED] : 'Accepted',
  [ActivityActionTypes.SEND_TO_PATIENT] : 'Sent on',
  [ActivityActionTypes.SIGN_REFFERAL] : 'Last edited',
  [ActivityActionTypes.SAVE_REFFERAL] : 'Last edited',
}

const formatStatusActionText = (statusCode: string) => {
  if (!statusCode) return undefined;
  return StatusActionTextMap[statusCode as keyof typeof StatusActionTextMap];
};

const BY = 'by';
const ON = 'on';

export const useContentHeaderSubtitle = (params: {
  selectedStep: IContactCareProgramStep;
  userMap: Map<string, string>;
}) => {
  const intl = useIntl();
  const {selectedStep, userMap} = params;
  const [activityLogData, setActivityLogData] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const {careProgramStepStatusList} = useCareProgramStepStatus();
  const {state} = useContactCareProgramContext();
  const {contactCareProgramDetails} = state;
  const loggedInUserId = getUserUUID();
  const [getCareProgramStepLastActivityLogs] = useLazyQuery(
    GET_CARE_PROGRAM_STEP_LAST_ACTIVITY_LOGS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );
  const { hideLastEditedByInSubStep } = useCareProgramContentHeaderAction()
  const [getOutreachActivity] = useLazyQuery(GetContactOutreachActivities, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const getUserName = (userId: string) => {
    const isLoggedInUser = userId === loggedInUserId;
    const actionUser = isLoggedInUser
      ? 'You'
      : userMap?.get(userId) || 'Automation';
    return actionUser;
  }

  const getActivityLogString = (
    log: IActivityLog,
    hideLastEditedByInSubStep?: boolean
  ) => {

    const statusId = log.data?.newData?.careProgramStepStatusId;
    const statusMlovObject = getMlovObjectFromId(
      statusId as string,
      careProgramStepStatusList,
    );

    const actionUser = getUserName(log?.performedById);

    const isTodayDay = isSameDay(new Date(), new Date(log.timestamp));
    const timestamp = isTodayDay
      ? 'Today'
      : getDateStrFromFormat(
          log.timestamp,
          DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
        );
    let activityLogDisplayString: string | null = null;
    if (log) {
      switch (log.actionCode) {
        case StepAction.AUTO_SAVE:
          if (!hideLastEditedByInSubStep) {
            activityLogDisplayString = `Last edited ${BY} ${actionUser}`;
          }
          break;
        case ActivityActionTypes.STATUS_UPDATE:
          const statusActionText = formatStatusActionText(
            statusMlovObject?.code as string
          );
          if (statusActionText) {
            activityLogDisplayString = `${statusActionText} ${BY} ${actionUser}`;
          }
          break;
        case ActivityActionTypes.SEND_TO_PATIENT:
          activityLogDisplayString = `Sent to patient ${BY} ${actionUser}`;
          break;
        case ActivityActionTypes.FORM_RESPONSE_ACCEPTED:
          activityLogDisplayString = `Accepted ${BY} ${actionUser}`;
          break;
        case ActivityActionTypes.SIGN_REFFERAL:
        case ActivityActionTypes.SAVE_REFFERAL:
          activityLogDisplayString = `Last edited ${BY} ${actionUser}`;
          break;
        default:
          activityLogDisplayString = null;
          break;
      }
    }
    if (activityLogDisplayString) {
      if (isTodayDay) {
        activityLogDisplayString = `${activityLogDisplayString}, ${timestamp}`;
      } else {
        activityLogDisplayString = `${activityLogDisplayString} ${ON} ${timestamp}`;
      }
    }
    return activityLogDisplayString;
  };

  const fetchActivityLogs = async () => {
    const stepId = selectedStep?.id;
    if (!stepId || stepId === DUMMY_OUTREACH_STEP_ID) {
      setActivityLogData([]);
      return;
    }

    setLoading(true);
    try {
      const response = await getCareProgramStepLastActivityLogs({
        variables: {
          stepId: stepId,
        },
      });

      const logs = response?.data?.getActivityLogs?.activityLogs as IActivityLog[];
      const activityLogStringList = logs?.map((log) => getActivityLogString(log, hideLastEditedByInSubStep)).filter((log) => log !== null);
      setActivityLogData(activityLogStringList as string[]);
    } catch (error) {
      setActivityLogData([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchOutreachActivityLogs = async () => {
    setLoading(true);
    try {
      const outreachActivityResponse = await getOutreachActivity({
        variables: {
          whereCondition: getOutreachActivityWhereCondition(),
          limit: 1,
          offset: 0,
        },
      });
      const latestOutreachActivity = outreachActivityResponse?.data?.ContactOutreachActivities?.[0];
      const userName = getUserName(latestOutreachActivity?.createdBy);
      if (userName) {
        const subtitle = replaceHashValueToString(
          {userName: userName},
          intl.formatMessage({id: 'lastUpdatedBy'})
        )
        if (subtitle) {
          setActivityLogData([subtitle]);
        }
      }
    } catch (error) {
      setActivityLogData([]);
    } finally {
      setLoading(false);
    }
  };

  const getOutreachActivityWhereCondition = () => {
    const whereCondition = {
      contactId: {_eq: contactCareProgramDetails?.contactId},
      careProgramTypeId: {
        _eq: contactCareProgramDetails?.payerCareProgram?.careProgramTypeId,
      },
      ...(contactCareProgramDetails?.id && {
        careProgramSteps: {
          contactCareProgram: {
            id: {
              _eq: contactCareProgramDetails?.id,
            },
          },
        },
      }),
    };
    return whereCondition;
  }

  useEffect(() => {
    const stepTypeCode = selectedStep?.careProgramStepType?.code;
    if (stepTypeCode && OUTREACH_STEP_TYPES.includes(stepTypeCode)) {
      fetchOutreachActivityLogs();
    } else {
      fetchActivityLogs();
    }
  }, [selectedStep]);

  return {
    activityLogData,
    loading,
  };
}
