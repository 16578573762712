import React from 'react';
import {Svg, Path} from 'react-native-svg';
import {ICommonSvgProps} from '../interfaces';

interface IStopTimerSvgProps extends ICommonSvgProps {
  color?: string;
}

const StopTimerSvg = (props: IStopTimerSvgProps) => {
  return (
    <Svg width="20" height="22" viewBox="0 0 20 22" fill="none">
      <Path
        d="M1.235 3.076C1.00083 3.22235 0.929646 3.53083 1.076 3.765C1.22235 3.99917 1.53083 4.07035 1.765 3.924L1.235 3.076ZM5.76502 1.424C5.99919 1.27765 6.07038 0.96917 5.92402 0.735002C5.77767 0.500833 5.46919 0.429646 5.23502 0.576L5.76502 1.424ZM18.235 3.924C18.4692 4.07035 18.7776 3.99917 18.924 3.765C19.0704 3.53083 18.9992 3.22236 18.765 3.076L18.235 3.924ZM14.765 0.576001C14.5308 0.429646 14.2224 0.500832 14.076 0.735001C13.9296 0.969169 14.0008 1.27764 14.235 1.424L14.765 0.576001ZM7 8.5C6.72386 8.5 6.5 8.72386 6.5 9C6.5 9.27614 6.72386 9.5 7 9.5V8.5ZM13 9L13.3536 9.35355C13.4966 9.21055 13.5393 8.9955 13.4619 8.80866C13.3845 8.62182 13.2022 8.5 13 8.5V9ZM7 15L6.64645 14.6464C6.50345 14.7894 6.46067 15.0045 6.53806 15.1913C6.61545 15.3782 6.79777 15.5 7 15.5V15ZM13 15.5C13.2761 15.5 13.5 15.2761 13.5 15C13.5 14.7239 13.2761 14.5 13 14.5V15.5ZM18.5 12C18.5 16.6944 14.6944 20.5 10 20.5V21.5C15.2467 21.5 19.5 17.2467 19.5 12H18.5ZM10 20.5C5.30558 20.5 1.5 16.6944 1.5 12H0.5C0.5 17.2467 4.75329 21.5 10 21.5V20.5ZM1.5 12C1.5 7.30558 5.30558 3.5 10 3.5V2.5C4.75329 2.5 0.5 6.75329 0.5 12H1.5ZM10 3.5C14.6944 3.5 18.5 7.30558 18.5 12H19.5C19.5 6.75329 15.2467 2.5 10 2.5V3.5ZM1.765 3.924L5.76502 1.424L5.23502 0.576L1.235 3.076L1.765 3.924ZM18.765 3.076L14.765 0.576001L14.235 1.424L18.235 3.924L18.765 3.076ZM7 9.5H13V8.5H7V9.5ZM12.6464 8.64645L6.64645 14.6464L7.35355 15.3536L13.3536 9.35355L12.6464 8.64645ZM7 15.5H13V14.5H7V15.5Z"
        fill={props.color || "#D92D20"}
      />
    </Svg>
  );
};

export default React.memo(StopTimerSvg);
