import {useIntl} from 'react-intl';
import CustomConfirmationModal from '../../../../../../common/CustomConfirmationModal/CustomConfirmationModal';
import {StyleSheet, View} from 'react-native';
import WarningSvgV2 from '../../../../../../common/Svg/WarningSvgV2';
import {Colors} from '../../../../../../../styles';
import {Text} from 'native-base';
import {EventBus} from '../../../../../../../utils/EventBus';
import {CARE_PROGRAM_EVENT_CODES} from '../../../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import React from 'react';
import { replaceHashValueToString } from '../../../../../../../utils/commonUtils';
import StopTimerSvg from '../../../../../../common/Svg/DayOptimization/StopTimerSvg';
import CustomModal from '../../../../../../common/CustomConfirmationModal/CustomModal';
const GenerateBillActionView = React.memo((props: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onTimeLog: () => void;
  totalMinutes: number;
  contactCareProgramId?: string;
  onIgnore?: () => Promise<void>;
}) => {
  const intl = useIntl();

  const handleIgnore = async () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CARE_PROGRAM_EVENT_CODES.SAVE_RECORDED_TIME, {
      contactCareProgramId: props.contactCareProgramId,
    });

    if (props.onIgnore) {
      await props.onIgnore();
    }
    props.onClose();
  };

  const handleAddOutreach = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CARE_PROGRAM_EVENT_CODES.RECORD_AS_OUTREACH, {
      syncCode: 'record-as-outreach',
    });
    props.onTimeLog();
  };

  const getModalContent = () => {
    return (
      <View style={styles.confirmationModalContent}>
        <Text style={styles.confirmationModalText}>
          {replaceHashValueToString(
            {totalMinutes: props.totalMinutes.toString()},
            intl.formatMessage({id: 'unloggedTimeConfirmationMessage'})
          )}
        </Text>
      </View>
    )
  }

  const getStopTimerSvg = () => {
    return (
      <View
        style={{
          backgroundColor: Colors.Custom.purpleBackgroundContainer,
          borderRadius: 8,
          padding: 6,
          borderWidth: 1,
          borderColor: Colors.Custom.mainPrimaryPurple,
          marginBottom: 9,
        }}
      >
        <StopTimerSvg color={Colors.Custom.mainPrimaryPurple} />
      </View>
    )
  }

  return (
  <CustomModal
    width={'26%'}
    isOpen={props.isOpen}
    headerText={intl.formatMessage({id: 'unloggedTimeConfirmationTitle'})}
    modalContent={getModalContent()}
    customIcon={getStopTimerSvg()}
    onClose={handleIgnore}
    onAction={handleAddOutreach}
    customOkBtnOnPress={props.onConfirm}
    customActionBtnText={intl.formatMessage({id: 'addAsOutreach'})}
    customOkBtnText={intl.formatMessage({id: 'stopAndLogTime'})}
  />
);
}
);

export default GenerateBillActionView;

const styles = StyleSheet.create({
  confirmationModalContent: {
    marginTop: 2,
  },
  confirmationModalText: {
    color: Colors.Custom.Gray400,
    fontSize: 14,
    lineHeight: 16.8,
    fontWeight: '400',
    textAlign: 'left',
  },
});
