import { useState, useCallback, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LeadQueries } from '../../../../../../services';
import { getUserUUID } from '../../../../../../utils/commonUtils';
import { DayOptimizerContext } from '../../../../../../context-provider/DayOptimizerContext/context';
import { useToast } from 'native-base';
import { showToast, ToastType } from '../../../../../../utils/commonViewUtils';
import { IContactScore } from '../../interfaces';
import { IContactScoreAudit } from '../../../../MemebersView/interface';
import { getContactTypeId } from '../../../../../../utils/mlovUtils';
import { useIntl } from 'react-intl';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { fetchCareManagerAllowedLocations } from '../../../../../../services/Stripe/StripeServices';
import { USER_ACCESS_PERMISSION } from '../../../../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../../../SideMenuBar/SideBarConst';

const usePatientAdd = () => {
  const intl = useIntl();
  
  const [contactsData, setContactsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSearchText, setCurrentSearchText] = useState('');
  const contactTypeId = getContactTypeId('CUSTOMER');
  const { state: dayOptimizerState } = useContext(DayOptimizerContext);
  const { selectedUser } = dayOptimizerState?.patientDashboard;
  const loggedInUserUuid = getUserUUID();
  const userUuid = selectedUser?.uuid ? selectedUser?.uuid : loggedInUserUuid;
  const toast = useToast();
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const [searchContacts] = useLazyQuery(
    LeadQueries.searchDOAddToPatientContacts,
    {
      onCompleted: (data: any) => {
        let contacts = [];
        if (data?.searchContacts?.contacts?.length) {
          contacts = data?.searchContacts?.contacts
            .filter((contactItem: any) => contactItem?.uuid)
            .map((contact: any) => {
              return {
                label: contact.name,
                value: contact?.uuid,
                key: contact?.uuid,
                contactData: contact,
                rafScore: contact?.contactScore?.hccV28,
                patientId: contact?.id,
                isScheduled: contact?.dayOptimizerUserSchedules?.length > 0,
                contactScore: (contact?.contactScore || {}) as IContactScore,
                contactScoreAudit: (contact?.contactScoreAudit || []) as IContactScoreAudit[],
                patient: contact?.patient,
                practiceLocationAccountId: contact?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
                contactPriorityRank: contact?.contactPriorityNumber?.rank
              };
            });
        }
        setContactsData(contacts);
        setLoading(false);
      },
      onError: () => {
        setContactsData([]);
        setLoading(false);
      },
    }
  );

  const [addPatientToDayOptimizer] = useMutation(LeadQueries.addPatientToDayOptimizer, {
    onCompleted: (data) => {
      showToast(
        toast,
        intl.formatMessage({id: 'patientAddedToDayOptimizer'}),
        ToastType.success,
        3000
      );
      searchPatientContacts(currentSearchText);
    },
    onError: (error) => {
      showToast(
        toast,
        intl.formatMessage({id: 'errorAddingPatientToDayOptimizer'}),
        ToastType.error,
        3000
      );
      setLoading(false);
    }
  });

  async function canAddContactToCareManager(careManagerUuid: string) {
    try {
      const response = await fetchCareManagerAllowedLocations(
        careManagerUuid,
        'USER',
        `${USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}/${MAIN_MENU_CODES.CONSUMER}`
      );
      return response?.data?.allowedAccountLocationIds || [];
    } catch (error) {
      console.error('Error checking location permissions:', error);
      return [];
    }
  }

  const fetchInitialContacts = useCallback(() => {
    setLoading(true);
    searchContacts({
      variables: {
        params: {
          limit: 15,
          offset: 0,
          isActive: true,
          searchString: '',
          orderBy: {
            updated_at: 'desc',
          },
          contactTypeIds: [contactTypeId],
          dayOptimizerUserScheduleSelectFilters : {
            date: new Date().toISOString()?.split('T')[0],
            userUuids: [userUuid]
          }
        },
      },
    });
  }, [searchContacts]);

  const searchPatientContacts = useCallback(async (searchText: string) => {
    const trimmedSearchText = searchText?.trim?.();
    setCurrentSearchText(trimmedSearchText);
    setLoading(true);
    let allowedLocations = [];
    if(isMsoEnabled) {
      allowedLocations = await canAddContactToCareManager(userUuid);
    }
    searchContacts({
      variables: {
        params: {
          limit: 10,
          offset: 0,
          isActive: true,
          searchString: trimmedSearchText,
          dayOptimizerUserScheduleSelectFilters: {
            date: new Date().toISOString()?.split('T')[0],
            userUuids: [userUuid]
          },
          ...(isMsoEnabled && {accountLocationIds: allowedLocations}),
          contactTypeIds: [contactTypeId],
          orderBy: {
            updated_at: 'desc',
          },
        },
      },
    });
  }, [searchContacts, userUuid]);

  const addPatient = useCallback((contactUuid: string, date: string) => {
    setLoading(true);
    addPatientToDayOptimizer({
      variables: {
        params: {
          contactUuid,
          date,
          userUuid
        }
      }
    });
  }, [addPatientToDayOptimizer, userUuid]);

  return {
    contactsData,
    loading,
    fetchInitialContacts,
    searchPatientContacts,
    addPatient
  };
};

export default usePatientAdd;
