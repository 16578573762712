import { Month, ReccurenceType } from "./interface";

export const RECCURENCE_TYPE_LIST = [
  {label: 'Daily', value: ReccurenceType.DAILY, key: ReccurenceType.DAILY},
  {label: 'Weekly', value: ReccurenceType.WEEKLY, key: ReccurenceType.WEEKLY},
  {
    label: 'Monthly',
    value: ReccurenceType.MONTHLY,
    key: ReccurenceType.MONTHLY,
  },
  {label: 'Yearly', value: ReccurenceType.YEARLY, key: ReccurenceType.YEARLY},
];

export const CADENCE_RECCURENCE_TYPE_LIST = [
  {label: 'Day', value: ReccurenceType.DAILY, key: ReccurenceType.DAILY},
  {label: 'Week', value: ReccurenceType.WEEKLY, key: ReccurenceType.WEEKLY},
  {
    label: 'Month',
    value: ReccurenceType.MONTHLY,
    key: ReccurenceType.MONTHLY,
  },
  {label: 'Year', value: ReccurenceType.YEARLY, key: ReccurenceType.YEARLY},
];

export const MONTH_DROPDOWN_LIST = Object.keys(Month).map((key) => ({
  key: Month[key as keyof typeof Month],
  value: Month[key as keyof typeof Month],
  label: Month[key as keyof typeof Month],
}));


export const API_VERSION = 'BreakVersion'