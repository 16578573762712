import { useMutation, useLazyQuery } from '@apollo/client';
import {CreateOrUpdateUserPreference, GetUserPreferencesByTypeId} from '../../../services/UserPreferences/UserPreferencesQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';

interface GetPreferencesParams {
  userId: string;
  userPreferencesTypeId: string;
}

interface SavePreferencesParams {
  userId: string;
  userPreferencesTypeId: string;
  preferencesJson: any;
  id?: string;
  preferencesModelVersion?: string;
}

export const useUserPreferences = () => {
  const [getPreferencesQuery, {loading: isLoading}] = useLazyQuery(GetUserPreferencesByTypeId, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [updatePreference, { loading: isUpdating }] = useMutation(CreateOrUpdateUserPreference, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const getUserPreferences = async ({ userId, userPreferencesTypeId }: GetPreferencesParams) => {
    try {
      const { data } = await getPreferencesQuery({
        variables: {
          userId,
          userPreferencesTypeId,
        },
      });
      return data?.userPreferences;
    } catch {
    }
  };

  const saveUserPreferences = async ({
    userId,
    userPreferencesTypeId,
    preferencesJson,
    id,
    preferencesModelVersion = 'v1'
  }: SavePreferencesParams) => {
    try {
      const preferenceObject: any = {
        userId,
        userPreferencesTypeId,
        preferencesJson: typeof preferencesJson === 'string'
          ? preferencesJson
          : JSON.stringify(preferencesJson),
        preferencesModelVersion,
      };

      if (id) {
        preferenceObject['id'] = id;
      }

      const { data } = await updatePreference({
        variables: {
          object: preferenceObject,
        },
      });
      return data?.createUserPreference;
    } catch {
    }
  };

  return {
    getUserPreferences,
    saveUserPreferences,
    isUpdating,
    isLoading,
  };
};
