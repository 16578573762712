import {Checkbox, InputNumber, Select, Switch} from 'antd';
import {
  FormControl,
  HStack,
  Text,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {FREQUENCY_CODES} from '../../../constants/MlovConst';
import {DateTimeDurationInput} from '../DateTimeDurationInput/DateTimeDurationInput';
import {Colors} from '../../../styles';
import {CaretDownOutlined} from '@ant-design/icons';
import {isFrequencyValueValid, isPeriodUnitValueValid, isPeriodValueValid} from './utils';
import Stack from '../LayoutComponents/Stack';
import {styles} from '../../RightSideContainer/Forms/Analytics/style';

interface IInterventionRecurrenceProps {
  repeat: boolean;
  repeatInterval: number;
  repeatType: string;
  repeatTypeValue: number;
  endType: string;
  endTypeValue: number;
}

export interface IFrequencyObjectState {
  code: {
    value: string,
    isValid: boolean,
  };
  frequency: {
    value: any;
    isValid: boolean;
  };
  period: {
    value: any;
    isValid: boolean;
  };
  periodUnit: {
    value: any;
    isValid: boolean;
  };
  endValue: {
    value: string;
    isValid: boolean;
  };
  endUnit: {
    value: string;
    isValid: boolean;
  };

  displayValue?: string;
}

const frequencyPeriodUnitCodes = {
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

const possibleValueList = [
  {
    value: 'day',
    displayName: 'day',
  },
  {
    value: 'week',
    displayName: 'week',
  },
];

const frequencyPeriodUnitValuesByCode = {
  [frequencyPeriodUnitCodes.HOUR]: 'Hour',
  [frequencyPeriodUnitCodes.DAY]: 'Day',
  [frequencyPeriodUnitCodes.WEEK]: 'Week',
  [frequencyPeriodUnitCodes.MONTH]: 'Month',
  [frequencyPeriodUnitCodes.YEAR]: 'Year',
};

const frequencyTypeCodes = {
  NEVER: FREQUENCY_CODES.NEVER,
  CUSTOM: 'CUSTOM',
};

const InterventionRecurrence = (props: {
  frequency?: number;
  period?: number;
  periodUnit?: string;
  endValue?: number;
  endUnit?: string;
  repeat: boolean;
  onRepeatChange: (repeat: boolean) => void;
  onChange: (data?: IFrequencyObjectState) => void;
}) => {
  const [showErrors, setShowErrors] = React.useState<boolean>(false);
  const propFrequency = props?.frequency || 1;
  const propPeriod = props?.period || 1;
  const propPeriodUnit = props?.periodUnit || 'day';
  const propEndValue = props?.endValue || 1;
  const propEndUnit = props?.endUnit || 'day';




  const frequencyObjectInitValue: IFrequencyObjectState = {
    code: {
      value: frequencyTypeCodes.CUSTOM,
      isValid: true,
    },
    frequency: {
      value: propFrequency,
      isValid: isFrequencyValueValid(propFrequency),
    },
    period: {
      value: propPeriod,
      isValid: isPeriodValueValid(propPeriod),
    },
    periodUnit: {
      value: propPeriodUnit,
      isValid: isPeriodUnitValueValid(propPeriodUnit),
    },
    endValue: {
      value: `${propEndValue}`,
      isValid: isPeriodValueValid(propEndValue),
    },
    endUnit: {
      value: propEndUnit,
      isValid: isPeriodUnitValueValid(propEndUnit)
    },
  };

  const [frequencyObjectState, setFrequencyObjectState] =
    useState<IFrequencyObjectState>(frequencyObjectInitValue);




  useEffect(() => {
    props.onChange(frequencyObjectState);
  }, [frequencyObjectState]);


  return (
    <Stack
      direction='row'
      space={4}
      style={{
        maxWidth: '100%',
        paddingHorizontal: 16,
        flex: 1,
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Text>
      <Switch
        checked={props.repeat}
        title="Repeat"
        size='small'
        style={{
          backgroundColor: props.repeat ? Colors.Custom.mainPrimaryPurple : undefined,
          marginRight: 4
        }}
        onChange={(checked) => {
          props.onRepeatChange(checked);
          if (!checked) {
            props.onChange(undefined);
          }
        }}
      />
      <Text style={styles.recurrenceText}>Repeat</Text>
      {props.repeat && (
        <>
          <>
            <FormControl
              isInvalid={!frequencyObjectState.frequency.isValid}
              alignSelf={'stretch'}
              maxW={'10%'}
              style={{
                marginRight: 4
              }}
            >
              <InputNumber
                value={frequencyObjectState.frequency.value}
                placeholder="eg. 3"
                controls={false}
                min={1}
                className={
                  !frequencyObjectState.frequency.isValid
                    ? 'field-error'
                    : undefined
                }
                onChange={(frequency) => {
                  setFrequencyObjectState((prevFrequencyObjectState) => ({
                    ...prevFrequencyObjectState,
                    frequency: {
                      value: frequency,
                      isValid: isFrequencyValueValid(frequency),
                    },
                  }));
                }}
                style={{width: '100%', borderRadius: 6, height: '100%'}}
              />
            </FormControl>
            <Text style={styles.recurrenceText}>times every</Text>
            <FormControl 
            maxW={'20%'}
              isInvalid={
                !frequencyObjectState.period.isValid ||
                !frequencyObjectState.periodUnit.isValid
              }
              flex={1}
              style={{
                marginRight: 4
              }}
            >
              <DateTimeDurationInput
                durationUnits={possibleValueList.map((unit: any) => ({
                  id: unit.value,
                  value: unit.displayName,
                }))}
                borderColor={
                  frequencyObjectState.period.isValid &&
                  frequencyObjectState.periodUnit.isValid
                    ? Colors.Custom.Gray300
                    : Colors.Custom.ErrorColor
                }
                borderWidth={1}
                inputFieldWidth={50}
                suffixIcon={<CaretDownOutlined />}
                value={frequencyObjectState.period.value}
                selectedDurationUnitId={frequencyObjectState.periodUnit.value}
                onValueChange={(value) => {
                  setFrequencyObjectState((prevFrequencyObjectState) => ({
                    ...prevFrequencyObjectState,
                    period: {
                      value: value,
                      isValid: isPeriodValueValid(value),
                    },
                  }));
                }}
                onDurationUnitChange={(id) => {
                  setFrequencyObjectState((prevFrequencyObjectState) => {
                    const periodUnit = id;
                    const newState = {
                      ...prevFrequencyObjectState,
                      periodUnit: {
                        value: periodUnit,
                        isValid: isPeriodUnitValueValid(periodUnit),
                      },
                    };
                    return newState;
                  });
                }}
              />
            </FormControl>
            <Text style={styles.recurrenceText}>and ends in</Text>
            <FormControl 
              maxW={'20%'}
              isInvalid={
                !frequencyObjectState.endUnit.isValid ||
                !frequencyObjectState.endValue.isValid
              }
              flex={1}
              style={{
                marginRight: 4
              }}
            >
              <DateTimeDurationInput
                durationUnits={possibleValueList.map((unit: any) => ({
                  id: unit.value,
                  value: unit.displayName,
                }))}
                borderColor={
                  frequencyObjectState.endUnit.isValid &&
                  frequencyObjectState.endValue.isValid
                    ? Colors.Custom.Gray300
                    : Colors.Custom.ErrorColor
                }
                borderWidth={1}
                inputFieldWidth={50}
                suffixIcon={<CaretDownOutlined />}
                value={frequencyObjectState.endValue.value}
                selectedDurationUnitId={frequencyObjectState.endUnit.value}
                onValueChange={(value) => {
                  setFrequencyObjectState((prevObjectState) => ({
                    ...prevObjectState,
                    endValue: {
                      ...prevObjectState.endValue,
                      value: value,
                      isValid: isPeriodValueValid(value),
                    },
                  }));
                }}
                onDurationUnitChange={(id) => {
                  setFrequencyObjectState((prevFrequencyObjectState) => {
                    return {
                      ...prevFrequencyObjectState,
                      endUnit: {
                        value: id,
                        isValid: isPeriodUnitValueValid(id),
                      },
                    };
                  });
                }}
              />
            </FormControl>
          </>
        </>
      )}
      </Text>
    </Stack>
  );
};

export default InterventionRecurrence;
