import React from 'react';
import {useDOEventRibbon} from './useDOEventRibbon';
import './styles.css';
import Divider from '../../../../../CustomDrawer/Divider';
import EventContainer from './EventContainer';
import {isSameDay} from '../../../../../../../utils/DateUtils';
import {Pressable, Text, View} from 'native-base';
import {Colors} from '../../../../../../../styles/Colors';
import {styles} from './styles';
import DownArrowFilledSvg from '../../../../../../../assets/Icons/DownArrowFilledSvg';
import {Drawer} from 'antd';
import {ModalActionTitle} from '../../../../../ModalActionTitle/ModalActionTitle';
import {v4} from 'uuid';
import CustomDrawer from '../../../../../CustomDrawer/CustomDrawer';
import { testID, TestIdentifiers } from '../../../../../../../testUtils';

const EventsRibbon = () => {
  const {
    selectedDate,
    eventsList,
    ribbonOpen,
    showAll,
    newNotification,
    toogleRibbonOpen,
    toggleShowAll,
    onActionPerformed,
    isDrawerOpen,
    setIsDrawerOpen,
    selectedEvent,
    loadingStatus,
  } = useDOEventRibbon();

  if (!isSameDay(selectedDate, new Date())) {
    return <></>;
  }

  const visibleEvents = () => {
    if (!ribbonOpen) return eventsList?.slice(0, 1);
    if (showAll) return eventsList;
    return eventsList?.slice(0, 5);
  };

  return (
    <div className="day-op-events-ribbon">
      {visibleEvents()?.map((event, index) => (
        <React.Fragment key={`event-ribbon-${event.id}`}>
          <EventContainer
            event={event}
            isFirst={index === 0}
            newNotification={newNotification}
            toogleRibbonOpen={toogleRibbonOpen}
            ribbonOpen={ribbonOpen}
            totalEvents={eventsList?.length}
            loadingStatus={loadingStatus}
            onActionPerformed={onActionPerformed}
          />
          <Divider marginHorizontal={0} width={'100%'} height={0.5} customStyles={{marginBottom: '0.5px'}} />
        </React.Fragment>
      ))}

      {ribbonOpen && eventsList?.length > 5 && !showAll && (
        <div style={{padding: '4px 0px'}}>
          <Pressable
            onPress={toggleShowAll}
            width={'90px'}
            marginBottom={'4px'}
            justifyContent={'center'}
            flexDirection={'row'}
            borderRadius={4}
            padding={'4px'}
            gap={2}
            marginLeft={'20px'}
            alignItems={'center'}
            _hover={{
              backgroundColor: Colors.FoldPixel.PRIMARY100,
            }}
            {...testID(TestIdentifiers.DO_showMoreEventsBtn)}
          >
            <Text style={styles.showMoreText}>View more</Text>
            <DownArrowFilledSvg
              width={'6px'}
              height={'6px'}
              customStrokeColor={Colors.Custom.Primary300}
            />
          </Pressable>
          <Divider marginHorizontal={0} width={'100%'} height={0.5} />
        </div>
      )}

      {/* <CustomDrawer
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        title={'TCM Discharge Summary'}
        customization={{
          width: '500px',
        }}
      >
        <Text>Hello</Text>
      </CustomDrawer> */}
    </div>
  );
};

export default EventsRibbon;
