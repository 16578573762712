export const TASK_TABS = {
  ASSIGNED: 'my_tasks',
  TASK_POOL: 'team_tasks',
  CREATED: 'created_tasks',
  MENTIONED: 'mentions_tasks'
}

export const TASK_MODULE_CODE = 'TASK';
export const TASK_EVENTS = {
  TASK_ADDED: 'TASK_ADDED',
  TASK_UPDATED: 'TASK_UPDATED',
  SUB_TASK_ADDED: 'SUB_TASK_ADDED',
  SUB_TASK_UPDATED: 'SUB_TASK_UPDATED',
  SUB_TASK_DELETED: 'SUB_TASK_DELETED',
  TASK_CATEGORY_CHANGED: 'TASK_CATEGORY_CHANGED',
  TASK_DELETED: 'TASK_DELETED',
  ON_TASK_DRAG_FROM_SOURCE: 'ON_TASK_DRAG_FROM_SOURCE',
  ON_TASK_DROP_TO_DESTINATION: 'ON_TASK_DROP_TO_DESTINATION',
  REFRESH_TOTAL_COUNT: 'REFRESH_TOTAL_COUNT',
  ON_TASK_ACTION: 'ON_TASK_ACTION',
  OPEN_TASK_DETAIL: 'OPEN_TASK_DETAIL',
  ON_MULTI_TASK_STATE_CHANGE: 'ON_MULTI_TASK_STATE_CHANGE',
  REFRESH_TASKS: 'REFRESH_TASKS',
  ALERT_TYPE_TASK_ADDED: 'ALERT_TYPE_TASK_ADDED',
  ALERT_TYPE_TASK_UPDATED: 'ALERT_TYPE_TASK_UPDATED'
}

export enum TaskField {
  status, priority, assignee, isDeleted
}

export enum FetchTaskState {
  resetAndFetchFromPagination, fetchAndAppendToList, refetchSamePageInBg, refetchSamePageInBgAndApplyNewChanges
}
