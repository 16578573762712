import {Checkbox, DatePicker, Drawer} from 'antd';
import moment from 'moment';
import {Box, HStack, Text, View, VStack, Checkbox as CheckboxNativeBase, Stack, Divider} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {BUTTON_TYPE, DATE_FORMATS, MLOV_CATEGORY} from '../../../../../constants';
import {TASK_ENTITY_TYPE_CODES, TASK_STATUS, TASK_STATUS_CODES, TASK_SUBJECT_TYPE} from '../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { IMlov } from '../../../../../Interfaces/CommonInterfaces';
import {Colors} from '../../../../../styles';
import { getDateStrFromFormat, getMomentObj } from '../../../../../utils/DateUtils';
import {getCareStudioMlovListByCategoryCode, getMlovId, getMlovListByCategory, getMlovObjectFromCode, getMlovValue} from '../../../../../utils/mlovUtils';
import { dateRangeStatusUuid, dateRangeStatusValue, IFilterByDueDate } from '../../../../common/CareDashboard/CareDashboardTopBar/FilterDrawerView';
import {IFilterByTaskPriority} from '../../../../common/CareDashboard/CareDashboardTopBar/interfaces';
import {PropertyType} from '../../../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import './filterDrawer.css';
import { isEnableCareProgram, isRenderTasksCompactView } from '../../../../../utils/commonUtils';
import { useIntl } from 'react-intl';
import { FlatList, Pressable, StyleSheet } from 'react-native';
import CrossIcon from '../../../../common/Svg/CrossIcon';
import FoldButtonV2, { ButtonType } from '../../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import { FilterWrapperComponent } from '../../../../common/CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import { filterTaskEntityTypes } from '../../../../TaskView/taskUtils';

const FilterDrawer = (props: any) => {
  const {
    isShowFilterDrawer,
    handleShowFilterDrawer,
    handleSubmit,
    propertyDetails,
    filterData,
    showTaskStatus = true,
    showTaskEntityTypes = true,
    showFilterAsDropDown = false,
    showFilterInline = false,
  } = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const isRenderCompactView = isRenderTasksCompactView();
  //For Due Date
  const allStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );
  const allStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );
  const overdueStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'overdue'
  );
  const overdueStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'overdue'
  );
  const todayStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'today'
  );
  const todayStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'today'
  );
  const tomorrowStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'tomorrow'
  );
  const tomorrowStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'tomorrow'
  );
  const laterStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'later'
  );
  const laterStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'later'
  );
  const allDueDateUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );
  const taskStatusMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_STATUS);
  const acceptedStatusMlov = getMlovObjectFromCode(TASK_STATUS.ACCEPTED, taskStatusMlovs);
  const completedStatusMlov = getMlovObjectFromCode(TASK_STATUS.COMPLETED, taskStatusMlovs);
  const missedStatusMlov = getMlovObjectFromCode(TASK_STATUS_CODES.MISSED,taskStatusMlovs);

  const [filter, setFilter] = useState<any>({
    priorityTypeList: [] as IFilterByTaskPriority[],
    taskEntityTypeList: [],
    statusTypeList: [] as IMlov[],
    dueDateTypeList: [] as IFilterByDueDate[],
    selectedStatusCodes: [acceptedStatusMlov?.code, missedStatusMlov?.code],
    selectedDueDateCodes: [],
    formData: {
      selectedPriorityTypes: [],
      selectedTaskEntityTypes: [],
      selectedStatusTypes: [],
      selectedTaskEntityTypesIds: [],
      selectedPriorityTypesIds: [],
      selectedStatusTypesIds: [],
      selectedStatusList: showTaskStatus ? [{...acceptedStatusMlov, value: 'Not Completed'}, {...missedStatusMlov, value: 'missed'}] : [],
      selectedDueDate: allStatusValue,
      selectedDueDateUuid: allStatusUuid,
      start: getDateStrFromFormat(
        new Date(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      ),
      end: getDateStrFromFormat(
        new Date(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      ),
    },
  });
  const [filterCount, setFilterCount] = useState<number | null>();
  const GLOBAL_PADDING = '24px';

  const initialTaskEntityTypes =
    getMlovListByCategory(MLOV_CATEGORY.TASK_ENTITY_TYPE) || [];
    const filteredTasksEntityTypes = filterTaskEntityTypes(initialTaskEntityTypes);
    const initialPriorityType =
    getMlovListByCategory(MLOV_CATEGORY.TASK_PRIORITY) || [];
  const initialStatusType = [
    {
      ...completedStatusMlov,
    } as IMlov,
    {
      ...acceptedStatusMlov,
      value: 'Pending'
    } as IMlov,
    {
      ...missedStatusMlov,
    } as IMlov
  ];

  const initialDueDateType = [
    {
      id: 1,
      dueDateType: overdueStatusValue,
      dueDateTypeUuid: overdueStatusUuid,
      label: overdueStatusValue,
    },
    {
      id: 2,
      dueDateType: todayStatusValue,
      dueDateTypeUuid: todayStatusUuid,
      label: todayStatusValue,
    },
    {
      id: 3,
      dueDateType: tomorrowStatusValue,
      dueDateTypeUuid: tomorrowStatusUuid,
      label: tomorrowStatusValue,
    },
    {
      id: 4,
      dueDateType: laterStatusValue,
      dueDateTypeUuid: laterStatusUuid,
      label: laterStatusValue,
    },
    {
      id: 5,
      dueDateType: dateRangeStatusValue,
      dueDateTypeUuid: dateRangeStatusUuid,
      label: dateRangeStatusValue,
    },
  ];

  useEffect(() => {
    setFilter((prev: any) => ({
      ...filterData,
      priorityTypeList: initialPriorityType,
      taskEntityTypeList: filteredTasksEntityTypes,
      statusTypeList: initialStatusType,
      dueDateTypeList: initialDueDateType,
      formData: {
        ...prev?.formData,
        ...filterData?.formData
      }
    }));
  }, []);

  useEffect(() => {
    const {
      selectedStatusTypesIds,
      selectedPriorityTypesIds,
      selectedTaskEntityTypesIds,
      selectedDueDate,
      selectedDueDateUuid,
      selectedStatusList
    } = filter.formData;
    let totalFilterCount = 0;
    if (selectedStatusTypesIds?.length > 0) {
      totalFilterCount++;
    }
    if (selectedPriorityTypesIds?.length > 0) {
      totalFilterCount++;
    }
    if (selectedTaskEntityTypesIds?.length > 0) {
      totalFilterCount++;
    }
    if (selectedDueDate?.length && !selectedDueDateUuid?.includes(allDueDateUuid)) {
      totalFilterCount++;
    }
    if (selectedStatusList?.length > 0){
      totalFilterCount++;
    }
    if (totalFilterCount === 0) {
      setFilterCount(null)
    } else {
      setFilterCount(totalFilterCount)
    }
  }, [
    filter.formData.selectedStatusTypesIds,
    filter.formData.selectedPriorityTypesIds,
    filter.formData.selectedTaskEntityTypesIds,
    filter.formData.selectedDueDate,
    filter.formData.selectedStatusList
  ]);

  const renderTaskStatus = () => {
    if (showFilterAsDropDown) {
      return (
        <FilterWrapperComponent
          isMultiSelect
          showFilterAsDropDown={isRenderCompactView}
          dropdownMarginLeft={isRenderCompactView ? 0 : undefined}
          maxNumberOfAppliedFiltersToshow={3}
          maxWidth={'80%'}
          noJustify={isRenderCompactView}
          marginBottom={isRenderCompactView ? 8 : 0}
          label={"Status"}
          value={filter.selectedStatusCodes.map((statusCode: string) => filter.statusTypeList.find((status: IMlov) => status.code === statusCode)?.value)}
        >
          {filter.statusTypeList.length ? (
            <View style={styles.paddedLeft}>
              {filter.statusTypeList.map((mlov: IMlov) => {
                return (
                  <CheckboxNativeBase
                    key={mlov.id}
                    value={mlov.code}
                    style={styles.checkbox}
                    alignItems={'center'}
                    isChecked={filter.selectedStatusCodes?.includes(mlov.code)}
                    onChange={(isChecked) => {
                      setFilter((filter: any) => {
                        const selectedStatusCodes =
                          filter.selectedStatusCodes || [];
                        if (isChecked) {
                          if (!selectedStatusCodes.includes(mlov.code)) {
                            selectedStatusCodes.push(mlov.code);
                          }
                        } else {
                          if (selectedStatusCodes.includes(mlov.code)) {
                            selectedStatusCodes.splice(
                              selectedStatusCodes.indexOf(mlov.code),
                              1
                            );
                          }
                        }

                        const selectedStatusList: IMlov[] = [];
                        selectedStatusCodes?.length &&
                          filter.statusTypeList?.forEach?.((status: IMlov) => {
                            if (
                              selectedStatusCodes.indexOf?.(status.code) !==
                              -1
                            ) {
                              selectedStatusList.push(status);
                            }
                          });

                        return {
                          ...filter,
                          selectedStatusCodes: [...selectedStatusCodes],
                          formData: {
                            ...filter.formData,
                            selectedStatusList: [...selectedStatusList],
                          },
                        };
                      });
                    }}
                  >
                    <Text
                      size={'smMedium'}
                      color={Colors.Custom.Gray700}
                      style={styles.checkboxText}
                    >
                      {mlov.value}
                    </Text>
                  </CheckboxNativeBase>
                );
              })}
            </View>
          ) : undefined}
        </FilterWrapperComponent>
      )
    }
    return (
      <VStack>
        <View style={styles.filterHeader}>
          <Text fontSize={18} fontWeight={700}>
            Filter by Task Status
          </Text>
        </View>
        <VStack style={styles.filterContent}>
          {filter.statusTypeList.length && (
            <View style={styles.paddedLeft}>
              {filter.statusTypeList.map((mlov: IMlov) => {
                return (
                  <View key={mlov.id} style={styles.checkboxContainer}>
                  <CheckboxNativeBase
                    value={mlov.code}
                    style={styles.smallCheckbox}
                    alignItems={'center'}
                    isChecked={filter.selectedStatusCodes?.includes(
                      mlov.code
                    )}
                    onChange={(isChecked) => {
                      setFilter((filter: any) => {
                        const selectedStatusCodes =
                          filter.selectedStatusCodes || [];
                        if (isChecked) {
                          if (!selectedStatusCodes.includes(mlov.code)) {
                            selectedStatusCodes.push(mlov.code);
                          }
                        } else {
                          if (selectedStatusCodes.includes(mlov.code)) {
                            selectedStatusCodes.splice(
                              selectedStatusCodes.indexOf(mlov.code),
                              1
                            );
                          }
                        }

                        const selectedStatusList: IMlov[] = [];
                        selectedStatusCodes?.length &&
                          filter.statusTypeList?.forEach?.((status: IMlov) => {
                            if (
                              selectedStatusCodes.indexOf?.(status.code) !==
                              -1
                            ) {
                              selectedStatusList.push(status);
                            }
                          });

                        return {
                          ...filter,
                          selectedStatusCodes: [...selectedStatusCodes],
                          formData: {
                            ...filter.formData,
                            selectedStatusList: [...selectedStatusList],
                          },
                        };
                      });
                    }}
                  />
                    <Text
                      size={'smMedium'}
                      color={Colors.Custom.Gray700}
                      style={styles.checkboxText}
                    >
                      {mlov.value}
                    </Text>
                  </View>
                );
              })}
            </View>
          )}
        </VStack>
      </VStack>
    );
  }

  const renderPriority = () => {
    if (showFilterAsDropDown) {
      return (
        <FilterWrapperComponent
          isMultiSelect
          showFilterAsDropDown={isRenderCompactView}
          dropdownMarginLeft={isRenderCompactView ? 0 : undefined}
          maxNumberOfAppliedFiltersToshow={3}
          maxWidth={'80%'}
          noJustify={isRenderCompactView}
          marginBottom={isRenderCompactView ? 8 : 0}
          label={"Priority"}
          value={filter?.formData?.selectedPriorityTypesIds?.map((priorityId: string) => filter.priorityTypeList.find((priority: IMlov) => priority.id === priorityId)?.value)}
        >
          {filter.priorityTypeList.length ? (
            <View style={styles.paddedLeft}>
              {filter.priorityTypeList.map((mlov: IMlov) => {
                return (
                  <CheckboxNativeBase
                    key={mlov.id}
                    value={mlov.code}
                    style={styles.checkbox}
                    alignItems={'center'}
                    isChecked={filter.formData?.selectedPriorityTypesIds?.includes(mlov.id)}
                    onChange={(isChecked) => {
                      const selectedPriorityTypesIds = filter.formData?.selectedPriorityTypesIds || [];
                      const selectedPriorityTypes: any = [];

                      if (isChecked) {
                        if (!selectedPriorityTypesIds.includes(mlov.id)) {
                          selectedPriorityTypesIds.push(mlov.id);
                        }
                      } else {
                        if (selectedPriorityTypesIds.includes(mlov.id)) {
                          selectedPriorityTypesIds.splice(selectedPriorityTypesIds.indexOf(mlov.id), 1);
                        }
                      }

                      selectedPriorityTypesIds?.forEach((x: any) => {
                        filter.priorityTypeList.forEach((priorityType: any) => {
                          if (x === priorityType.id) {
                            selectedPriorityTypes.push(priorityType);
                          }
                        });
                      });

                      setFilter((prev: any) => {
                        return {
                          ...prev,
                          formData: {
                            ...prev.formData,
                            selectedPriorityTypesIds: selectedPriorityTypesIds || [],
                            selectedPriorityTypes: selectedPriorityTypes || [],
                          },
                        };
                      });
                    }}
                  >
                    <Text
                      size={'smMedium'}
                      color={Colors.Custom.Gray700}
                      style={styles.checkboxText}
                    >
                      {mlov.value}
                    </Text>
                  </CheckboxNativeBase>
                );
              })}
            </View>
          ) : undefined}
        </FilterWrapperComponent>
      );
    }
    return (
      <VStack space={2} mb={2}>
        <Box
          bgColor={Colors.Custom.Gray50}
          borderWidth={1}
          borderColor={Colors.Custom.Gray200}
          borderRadius={8}
          px={5}
          py={2}
        >
          <Text fontSize={18} fontWeight={700}>
            Filter by Task Priority
          </Text>
        </Box>
        <VStack ml={2}>
          {filter.priorityTypeList.length && (
            <Checkbox.Group
              className={'filterDrawerCheckbox'}
              value={filter.formData.selectedPriorityTypesIds}
              onChange={(checkedValues) => {
                const selectedPriorityTypes: any = [];
                checkedValues?.forEach((x: any) => {
                  filter.priorityTypeList.forEach((priorityType: any) => {
                    if (x === priorityType.id) {
                      selectedPriorityTypes.push(priorityType);
                    }
                  });
                });
                setFilter((prev: any) => {
                  return {
                    ...prev,
                    formData: {
                      ...prev.formData,
                      selectedPriorityTypesIds: checkedValues || [],
                      selectedPriorityTypes: selectedPriorityTypes || [],
                    },
                  };
                });
              }}
            >
              {filter.priorityTypeList.map((priorityType: any) => {
                return (
                  !priorityType?.isDeleted && (
                    <HStack m={2}>
                      <Checkbox value={priorityType.id}/>
                      <Text
                        ml={2}
                        color={Colors.Custom.IATextColor}
                        fontSize={14}
                        fontWeight={500}
                      >
                        {priorityType?.value}
                      </Text>
                    </HStack>
                  )
                );

              })}
            </Checkbox.Group>
          )}
        </VStack>
      </VStack>
    )
  }

  const renderTaskTypes = () => {
    if (showFilterAsDropDown) {
      return (
        <FilterWrapperComponent
          isMultiSelect
          showFilterAsDropDown={isRenderCompactView}
          dropdownMarginLeft={isRenderCompactView ? 0 : undefined}
          maxNumberOfAppliedFiltersToshow={3}
          maxWidth={'80%'}
          noJustify={isRenderCompactView}
          marginBottom={isRenderCompactView ? 8 : 0}
          label={"Task Types"}
          value={filter?.formData?.selectedTaskEntityTypesIds?.map((itemId: string) => filter.taskEntityTypeList.find((item: IMlov) => item.id === itemId)?.value)}
        >
          {filter.taskEntityTypeList.length ? (
            <View style={styles.paddedLeft}>
              <FlatList
                data={filter.taskEntityTypeList}
                numColumns={2}
                style={styles.flatList}
                renderItem={({item}) => (
                  <View style={styles.flatListItem}>
                    <CheckboxNativeBase
                      key={item.id}
                      value={item.code}
                      style={styles.checkbox}
                      alignItems={'center'}
                      isChecked={filter.formData?.selectedTaskEntityTypesIds?.includes(item.id)}
                      onChange={(isChecked) => {
                        const selectedTaskEntityTypesIds = filter.formData?.selectedTaskEntityTypesIds || [];
                        const selectedTaskEntityTypes: any = [];

                        if (isChecked) {
                          if (!selectedTaskEntityTypesIds.includes(item.id)) {
                            selectedTaskEntityTypesIds.push(item.id);
                          }
                        } else {
                          if (selectedTaskEntityTypesIds.includes(item.id)) {
                            selectedTaskEntityTypesIds.splice(selectedTaskEntityTypesIds.indexOf(item.id), 1);
                          }
                        }

                        selectedTaskEntityTypesIds?.forEach((x: any) => {
                          filter.taskEntityTypeList.forEach((item: any) => {
                            if (x === item.id) {
                              selectedTaskEntityTypes.push(item);
                            }
                          });
                        });

                        setFilter((prev: any) => {
                          return {
                            ...prev,
                            formData: {
                              ...prev.formData,
                              selectedTaskEntityTypesIds: selectedTaskEntityTypesIds,
                              selectedTaskEntityTypes: selectedTaskEntityTypes,
                            },
                          };
                        });
                      }}
                    >
                      <Text
                        size={'smMedium'}
                        color={Colors.Custom.Gray700}
                        style={styles.checkboxText}
                      >
                        {item.value}
                      </Text>
                    </CheckboxNativeBase>
                  </View>
                )}
                keyExtractor={(item: any) => item.id}
              />
            </View>
          ) : undefined}
        </FilterWrapperComponent>
      );
    }
    return (
      <VStack space={2} mb={2}>
        <Box
          bgColor={Colors.Custom.Gray50}
          borderWidth={1}
          borderColor={Colors.Custom.Gray200}
          borderRadius={8}
          px={5}
          py={2}
        >
          <Text fontSize={18} fontWeight={700}>
            Filter by Task Types
          </Text>
        </Box>
        <VStack ml={2}>
          {filter.taskEntityTypeList.length && (
            <Checkbox.Group
              className={'filterDrawerCheckbox'}
              value={filter.formData.selectedTaskEntityTypesIds}
              onChange={(checkedValues: any) => {
                const selectedTaskEntityTypes: any = [];
                checkedValues?.forEach((x: any) => {
                  filter.taskEntityTypeList.forEach((taskType: any) => {
                    if (x === taskType.id) {
                      selectedTaskEntityTypes.push(taskType);
                    }
                  });
                });

                setFilter((prev: any) => {
                  return {
                    ...prev,
                    formData: {
                      ...prev.formData,
                      selectedTaskEntityTypesIds: checkedValues,
                      selectedTaskEntityTypes: selectedTaskEntityTypes,
                    },
                  };
                });
              }}
            >
              {filter.taskEntityTypeList.map((taskType: any) => {
                return (
                  <HStack m={2}>
                    <Checkbox value={taskType.id} />
                    <Text
                      ml={2}
                      color={Colors.Custom.IATextColor}
                      fontSize={14}
                      fontWeight={500}
                    >
                      {taskType?.value}
                    </Text>
                  </HStack>
                );
              })}
            </Checkbox.Group>
          )}
        </VStack>
      </VStack>
    );
  }

  const renderDueDate = () => {
    if (showFilterAsDropDown) {
      return (
        <FilterWrapperComponent
          isMultiSelect
          showFilterAsDropDown={isRenderCompactView}
          dropdownMarginLeft={isRenderCompactView ? 0 : undefined}
          maxNumberOfAppliedFiltersToshow={4}
          maxWidth={'80%'}
          noJustify={isRenderCompactView}
          marginBottom={isRenderCompactView ? 8 : 0}
          label={"Due Date"}
          popoverMaxHeight={300}
          value={filter.formData?.selectedDueDate == 'All' ? [] : filter.formData?.selectedDueDate as string[]}
        >
          {filter.dueDateTypeList?.length > 0 && (
            <View style={styles.paddedLeft}>
              {filter.dueDateTypeList.map((type: IFilterByDueDate) => {
                return (
                  <CheckboxNativeBase
                    key={type.dueDateTypeUuid}
                    value={type.label}
                    style={styles.checkboxStyle}
                    alignItems={'center'}
                    isChecked={filter.selectedDueDateCodes?.includes(
                      type.dueDateTypeUuid
                    )}
                    onChange={(isChecked) => {
                      if (type.dueDateTypeUuid === dateRangeStatusUuid) {
                        if (isChecked) {
                          setFilter((filter: any) => {
                            return {
                              ...filter,
                              selectedDueDateCodes: [type.dueDateTypeUuid],
                              formData: {
                                ...filter.formData,
                                selectedDueDate: [type.label],
                                selectedDueDateUuid: [type.dueDateTypeUuid],
                              },
                            };
                          });
                        } else {
                          setFilter((filter: any) => {
                            return {
                              ...filter,
                              selectedDueDateCodes: [],
                              formData: {
                                ...filter.formData,
                                selectedDueDate: [],
                                selectedDueDateUuid: [],
                              },
                            };
                          });
                        }
                      } else {
                        setFilter((filter: any) => {
                          let selectedDueDateCodes =
                            filter.selectedDueDateCodes || [];
                          if (isChecked) {
                            if (
                              !selectedDueDateCodes.includes(
                                type.dueDateTypeUuid
                              )
                            ) {
                              if (
                                selectedDueDateCodes.includes(
                                  dateRangeStatusUuid
                                )
                              ) {
                                selectedDueDateCodes = [type.dueDateTypeUuid];
                              } else {
                                if (Array.isArray(selectedDueDateCodes)) {
                                  selectedDueDateCodes.push(
                                    type.dueDateTypeUuid
                                  );
                                } else {
                                  selectedDueDateCodes = [
                                    type.dueDateTypeUuid,
                                  ];
                                }
                              }
                            }
                          } else {
                            if (
                              selectedDueDateCodes.includes(
                                type.dueDateTypeUuid
                              )
                            ) {
                              selectedDueDateCodes.splice(
                                selectedDueDateCodes.indexOf(
                                  type.dueDateTypeUuid
                                ),
                                1
                              );
                            }
                          }

                          const selectedDueDateType: string[] = [];
                          selectedDueDateCodes?.length &&
                            filter.dueDateTypeList?.forEach?.(
                              (dueDateType: IFilterByDueDate) => {
                                if (
                                  selectedDueDateCodes.indexOf?.(
                                    dueDateType.dueDateTypeUuid
                                  ) !== -1
                                ) {
                                  selectedDueDateType.push(dueDateType.label);
                                }
                              }
                            );
                          if (
                            selectedDueDateCodes.length > 1 &&
                            selectedDueDateCodes.includes(allDueDateUuid)
                          ) {
                            selectedDueDateCodes =
                              selectedDueDateCodes.filter(
                                (code: any) => code !== allDueDateUuid
                              );
                          }
                          if (selectedDueDateCodes.length === 0) {
                            selectedDueDateCodes.push(allDueDateUuid);
                          }

                          return {
                            ...filter,
                            selectedDueDateCodes: [...selectedDueDateCodes],
                            formData: {
                              ...filter.formData,
                              selectedDueDate: [...selectedDueDateType],
                              selectedDueDateUuid: [...selectedDueDateCodes],
                            },
                          };
                        });
                      }
                    }}
                  >
                    <Text
                      size={'smMedium'}
                      color={Colors.Custom.Gray700}
                      style={styles.checkboxText}
                    >
                      {type.dueDateType}
                    </Text>
                  </CheckboxNativeBase>
                );
              })}
            </View>
          )}

          {filter.formData?.selectedDueDateUuid?.includes('date_range') && (
              <HStack>
                <VStack space={1}>
                  <Text fontSize={14} color={'gray.700'} fontWeight={500} ml={3}>
                    From Date
                  </Text>
                  <DatePicker
                    style={reactStyles.datePicker}
                    disabledDate={(date) => {
                      const currentDate = getMomentObj(new Date()).add(
                        14,
                        'days'
                      );
                      return date && date.valueOf() > currentDate.valueOf();
                    }}
                    value={
                      filter.formData?.start
                      ? moment(
                        filter.formData.start,
                        DATE_FORMATS.DISPLAY_DATE_FORMAT
                      )
                      : undefined
                    }
                    // disabledDate={(current: moment.Moment) => {
                    //   return current && isPastDay(current);
                    // }}
                    format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                    onChange={(dates: any, formatStrings: string) => {
                      if (formatStrings && formatStrings.length > 1) {
                        setFilter((prev: any) => {
                          return {
                            ...prev,
                            formData: {
                              ...prev.formData,
                              start: formatStrings,
                              end: filter.formData.end,
                            },
                          };
                        });
                      }
                    }}
                  />
                </VStack>
                <VStack space={1} style={styles.toDateContainer}>
                  <Text fontSize={14} color={'gray.700'} fontWeight={500} ml={3}>
                    To Date
                  </Text>
                  <DatePicker
                    style={reactStyles.datePicker}
                    disabledDate={(date) => {
                      const currentDate = getMomentObj(new Date()).add(
                        14,
                        'days'
                      );
                      return date && date.valueOf() > currentDate.valueOf();
                    }}
                    value={
                      filter.formData?.end
                        ? moment(
                          filter.formData.end,
                          DATE_FORMATS.DISPLAY_DATE_FORMAT
                        )
                        : undefined
                    }
                    // disabledDate={(current: moment.Moment) => {
                    //   return current && isPastDay(current);
                    // }}
                    format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                    onChange={(dates: any, formatStrings: string) => {
                      if (formatStrings && formatStrings.length > 1) {
                        setFilter((prev: any) => {
                          return {
                            ...prev,
                            formData: {
                              ...prev.formData,
                              end: formatStrings,
                              start: filter.formData.start,
                            },
                          };
                        });
                      }
                    }}
                  />
                </VStack>
              </HStack>
            )}
        </ FilterWrapperComponent>
      )
    }
    return (
      <VStack>
        <View style={styles.filterByDueDateContainer}>
          <Text fontSize={18} fontWeight={700}>
            Filter by Due Date
          </Text>
        </View>
        <VStack style={styles.dueDateListContainer}>
          {!!filter.dueDateTypeList.length && (
            <View style={styles.paddedLeft}>
              {filter.dueDateTypeList.map((type: IFilterByDueDate) => {
                return (
                  <View key={type.dueDateTypeUuid} style={styles.checkboxRow}>
                  <CheckboxNativeBase
                    value={type.label}
                    style={styles.checkbox2}
                    alignItems={'center'}
                    isChecked={filter.selectedDueDateCodes?.includes(
                      type.dueDateTypeUuid
                    )}
                    onChange={(isChecked) => {
                      if (type.dueDateTypeUuid === dateRangeStatusUuid) {
                        if (isChecked) {
                          setFilter((filter: any) => {
                            return {
                              ...filter,
                              selectedDueDateCodes: [type.dueDateTypeUuid],
                              formData: {
                                ...filter.formData,
                                selectedDueDate: [type.label],
                                selectedDueDateUuid: [type.dueDateTypeUuid],
                              },
                            };
                          });
                        } else {
                          setFilter((filter: any) => {
                            return {
                              ...filter,
                              selectedDueDateCodes: [],
                              formData: {
                                ...filter.formData,
                                selectedDueDate: [],
                                selectedDueDateUuid: [],
                              },
                            };
                          });
                        }
                      } else {
                        setFilter((filter: any) => {
                          let selectedDueDateCodes =
                            filter.selectedDueDateCodes || [];
                          if (isChecked) {
                            if (
                              !selectedDueDateCodes.includes(
                                type.dueDateTypeUuid
                              )
                            ) {
                              if (
                                selectedDueDateCodes.includes(
                                  dateRangeStatusUuid
                                )
                              ) {
                                selectedDueDateCodes = [type.dueDateTypeUuid];
                              } else {
                                if (Array.isArray(selectedDueDateCodes)) {
                                  selectedDueDateCodes.push(
                                    type.dueDateTypeUuid
                                  );
                                } else {
                                  selectedDueDateCodes = [
                                    type.dueDateTypeUuid,
                                  ];
                                }
                              }
                            }
                          } else {
                            if (
                              selectedDueDateCodes.includes(
                                type.dueDateTypeUuid
                              )
                            ) {
                              selectedDueDateCodes.splice(
                                selectedDueDateCodes.indexOf(
                                  type.dueDateTypeUuid
                                ),
                                1
                              );
                            }
                          }

                          const selectedDueDateType: string[] = [];
                          selectedDueDateCodes?.length &&
                            filter.dueDateTypeList?.forEach?.(
                              (dueDateType: IFilterByDueDate) => {
                                if (
                                  selectedDueDateCodes.indexOf?.(
                                    dueDateType.dueDateTypeUuid
                                  ) !== -1
                                ) {
                                  selectedDueDateType.push(dueDateType.label);
                                }
                              }
                            );
                          if (
                            selectedDueDateCodes.length > 1 &&
                            selectedDueDateCodes.includes(allDueDateUuid)
                          ) {
                            selectedDueDateCodes =
                              selectedDueDateCodes.filter(
                                (code: any) => code !== allDueDateUuid
                              );
                          }
                          if (selectedDueDateCodes.length === 0) {
                            selectedDueDateCodes.push(allDueDateUuid);
                          }

                          return {
                            ...filter,
                            selectedDueDateCodes: [...selectedDueDateCodes],
                            formData: {
                              ...filter.formData,
                              selectedDueDate: [...selectedDueDateType],
                              selectedDueDateUuid: [...selectedDueDateCodes],
                            },
                          };
                        });
                      }
                    }}
                  />
                    <Text
                      size={'smMedium'}
                      color={Colors.Custom.IATextColor}
                      fontSize={14}
                      fontWeight={500}
                      style={styles.checkboxLabel}
                    >
                      {type.dueDateType}
                    </Text>
                  </View>
                );
              })}
            </View>
          )}
        </VStack>

        {(filter.formData.selectedDueDateUuid.includes('date_range')) && (
            <VStack style={styles.dateRangeContainer}>
              <VStack space={1}>
                <Text fontSize={14} color={'gray.700'} fontWeight={500} ml={3}>
                  From Date
                </Text>
                <DatePicker
                  style={reactStyles.datePicker1}
                  value={
                    filter.formData.start
                      ? moment(
                        filter.formData.start,
                        DATE_FORMATS.DISPLAY_DATE_FORMAT
                      )
                      : undefined
                  }
                  format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                  onChange={(dates: any, formatStrings: string) => {
                    if (formatStrings && formatStrings.length > 1) {
                      setFilter((prev: any) => {
                        return {
                          ...prev,
                          formData: {
                            ...prev.formData,
                            start: formatStrings,
                            end: filter.formData.end,
                          },
                        };
                      });
                    }
                  }}
                />
              </VStack>
              <VStack space={1} marginTop={6}>
                <Text fontSize={14} color={'gray.700'} fontWeight={500} ml={3}>
                  To Date
                </Text>
                <DatePicker
                  style={reactStyles.datePicker1}
                  value={
                    filter.formData.end
                      ? moment(
                        filter.formData.end,
                        DATE_FORMATS.DISPLAY_DATE_FORMAT
                      )
                      : undefined
                  }
                  format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                  onChange={(dates: any, formatStrings: string) => {
                    if (formatStrings && formatStrings.length > 1) {
                      setFilter((prev: any) => {
                        return {
                          ...prev,
                          formData: {
                            ...prev.formData,
                            end: formatStrings,
                            start: filter.formData.start,
                          },
                        };
                      });
                    }
                  }}
                />
              </VStack>
            </VStack>
          )}
      </VStack>
    );
  }

  const renderMainView = () => {
    return (
      <View>
        {showTaskStatus ? renderTaskStatus() : <></>}
        {propertyDetails?.propertyType === PropertyType.priority ? <></> : renderPriority()}
        {showTaskEntityTypes ? renderTaskTypes() : <></>}
        {renderDueDate()}
      </View>
    );
  }

  const onClearAll = () => {
    const updatedFilters = {
      ...filter,
      selectedDueDateCodes: [],
      selectedStatusCodes: [] as IMlov[],
      formData: {
        ...(filter.formData || {}),
        selectedPriorityTypes: [],
        selectedTaskEntityTypes: [],
        selectedPriorityTypesIds: [],
        selectedTaskEntityTypesIds: [],
        selectedDueDate: allStatusValue,
        selectedDueDateUuid: allStatusUuid,
        selectedStatusList: [],
      },
    };
    setFilter(updatedFilters);
    handleSubmit(updatedFilters, 0);
  }

  const onClose = () => {
    handleShowFilterDrawer(false);
  }

  const onApplyFilter = () => {
    handleSubmit(filter, filterCount);
  }


  if (showFilterInline) {
    return (
      <Stack direction={'column'} style={styles.inlineFilterContainer}>
        <Stack direction={'column'}>
          <Stack direction={'row'} style={styles.filterHeaderContainer}>
            <Text style={styles.filterHeaderText}>
              {intl.formatMessage({ id: 'filters' })}
            </Text>
            <View style={styles.flexFiller}></View>
            <FoldButtonV2
              key={'clearAll'}
              label={'Clear All'}
              buttonType={ButtonType.secondary}
              onPress={onClearAll}
            />
            <FoldButtonV2
              key={'apply'}
              label={intl.formatMessage({ id: 'apply' })}
              buttonType={ButtonType.secondary}
              onPress={onApplyFilter}
            />
            <View style={styles.divider}></View>
            <Pressable onPress={onClose}>
              <CrossIcon />
            </Pressable>
          </Stack>
          <Divider style={styles.headerDivider} />
        </Stack>
        <View style={styles.mainViewContainer}>
          {renderMainView()}
        </View>
      </Stack>
    )
  }

  return (
    <Drawer
      width={isRenderCompactView ? '100%' : 500}
      mask={!isRenderCompactView}
      open={isShowFilterDrawer}
      onClose={onClose}
      closable={false}
      bodyStyle={{
        paddingLeft: GLOBAL_PADDING,
        paddingRight: GLOBAL_PADDING,
      }}
      contentWrapperStyle={{
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
      }}
      title={ <ModalActionTitle
        title="Task Filters"
        buttonList={[
          {
            show: isRenderCompactView,
            btnText: 'Back',
            textColor: Colors.Custom.Danger800,
            variant: BUTTON_TYPE.SECONDARY,
            onClick: onClose,
          },
          {
            show: true,
            btnText: 'Clear All',
            textColor: Colors.Custom.Danger800,
            variant: BUTTON_TYPE.SECONDARY,
            onClick: onClearAll,
          },
          {
            show: true,
            btnText: 'Apply',
            variant: BUTTON_TYPE.PRIMARY,
            textColor: Colors.Custom.FilterBorderColor,
            onClick: onApplyFilter,
          },
        ]}
      />}
    >
     {renderMainView()}
    </Drawer>
  );
};

const styles = StyleSheet.create({
  paddedLeft: {
    paddingLeft: 16,
  },
  checkbox: {
    marginVertical: 10,
  },
  checkboxText: {
    marginLeft: 8,
  },
  filterHeader: {
    backgroundColor: Colors.Custom.Gray50,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 18,
    maxWidth: 240,
  },
  filterContent: {
    marginTop: 12,
    marginBottom: 8,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  smallCheckbox: {
    marginVertical: 10,
    borderRadius: 2,
    borderWidth: 1,
    width: 15,
    height: 15,
  },
  flatList: {
    flex: 1,
  },
  flatListItem: {
    flex: 1,
    minWidth: 140,
  },
  checkboxStyle: {marginVertical: 10},
  datePicker: {
    height: 44,
    width: 125,
    borderColor: Colors.Custom.Gray300,
    borderRadius: 8,
    fontWeight: 400,
    fontSize: 16,
  },
  toDateContainer: {marginLeft: 4},
  filterByDueDateContainer: {
    backgroundColor: Colors.Custom.Gray50,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 18,
    maxWidth: 240,
  },
  dueDateListContainer: {marginTop: 12},
  checkboxRow: {flexDirection: 'row', alignItems: 'center'},
  checkbox2: {
    marginVertical: 10,
    borderRadius: 2,
    borderWidth: 1,
    width: 15,
    height: 15,
  },
  checkboxLabel: {marginLeft: 8},
  dateRangeContainer: {marginTop: 24},
  datePickerLarge: {
    height: 44,
    width: 335,
    borderColor: Colors.Custom.Gray300,
    borderRadius: 8,
    fontWeight: 400,
    fontSize: 16,
  },
  toDateContainerLarge: {marginTop: 6},
  inlineFilterContainer: {
    width: '100%',
    paddingVertical: 6,
  },
  filterHeaderContainer: {
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 8,
    marginTop: 8
  },
  filterHeaderText: {
    fontSize: 14,
    fontWeight: '600',
    color: Colors?.Custom?.MonochromeBlack,
  },
  flexFiller: {
    flex: 1,
  },
  divider: {
    width: 1,
    height: 20,
    backgroundColor: Colors.Custom.Gray200,
    marginHorizontal: 8,
  },
  headerDivider: {
    marginTop: 6,
    marginBottom: 12,
  },
  mainViewContainer: {
    paddingHorizontal: 16,
  },
});

export const reactStyles : Record<string, React.CSSProperties> ={
  datePicker: {
    height: '44px',
    width: 125,
    borderColor: Colors.Custom.Gray300,
    borderRadius: 8,
    fontWeight: 400,
    fontSize: 16,
  },
  datePicker1: {
    height: '44px',
    width: 335,
    borderColor: Colors.Custom.Gray300,
    borderRadius: 8,
    fontWeight: 400,
    fontSize: 16,
  }
};

export default FilterDrawer;
