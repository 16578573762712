import React from 'react';
import {
  Form, DatePicker,
  InputNumber,
  Skeleton,
  Table
} from 'antd';
import { ScrollView, View } from 'react-native';
import { Text } from 'native-base';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import { DATE_FORMATS } from '../../../../../../../constants/StringConst';
import { antStyle, styles } from './ClinicalParametersStyles';
import { useIntl } from 'react-intl';
import './ClinicalParametersView.css';
import { getMomentObj } from '../../../../../../../utils/DateUtils';
import {
  CHRONIC_CONDITIONS_TABLE_COLUMNS,
  IClinicalParametersFormProps
} from './interface';
import { useClinicalParametersView } from './useClinicalParametersView';
import { testID, TestIdentifiers } from '../../../../../../../testUtils';

const ClinicalParametersView: React.FC<IClinicalParametersFormProps> = (props) => {
  const intl = useIntl();
  const {
    componentValue,
    componentState,
    validateData,
  } = useClinicalParametersView({
    component: props?.component,
    onChange: props?.onChange,
    contactCareProgramId: props?.contactCareProgramId || '',
    componentSelectedValue: props?.component?.selectedValue,
    isDisabled: props.disableForm
  });

  props.validateRef.current = validateData;

  return (
    <ScrollView style={styles.scrollView}>
      {
        componentState.loadingStatus === 'loading' ? (
          <div className='flex flex-col'>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </div>
        ) :
          <Form layout='vertical'>
            <View style={{ marginTop: 20 }}>
              <View>
                <Text style={styles.sectionTitle}>
                  {intl.formatMessage({ id: 'chronicConditions' })}
                </Text>
              </View>

              <Table
                dataSource={componentValue.clinicalParameters?.chronicConditions?.data?.resources?.[0]?.conditions?.entry
                  ?.map((condition: any) => ({
                    key: condition.resource.id,
                    condition: condition.resource.code.coding[0].display || condition.resource.code.text,
                    diagnosedDate: getMomentObj(condition.resource.onsetDateTime)?.format(DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT),
                    firstDiagnosedDate: getMomentObj(condition.resource.onsetDateTime)
                  }))
                  ?.sort((dateA: any, dateB: any) => dateB.firstDiagnosedDate - dateA.firstDiagnosedDate)}
                columns={CHRONIC_CONDITIONS_TABLE_COLUMNS}
                pagination={false}
                size='small'
              />
            </View>

            <View style={{ marginTop: 20 }}>
              <View>
                <Text style={styles.sectionTitle}>
                  {intl.formatMessage({ id: 'recentHospitalization' })}
                </Text>
              </View>
              <Stack direction='row' space={10} style={{ ...styles.threeColumnGrid, flex: 1 }}>

                <Form.Item
                  label={
                    <Text style={styles.label}>
                      {intl.formatMessage({ id: 'admissionDate' })}
                    </Text>
                  }
                  style={{ ...antStyle.formMultiItem, flex: 1 }}
                >
                  <DatePicker
                    className='adt-event-date-picker'
                    style={{ ...antStyle.datePicker, flex: 1 }}
                    placeholder={intl.formatMessage({ id: 'selectDatePlaceholder' })}
                    data-testId = 'admissionDate'
                    disabled={true}
                    format={DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT}
                    showTime={false}
                    allowClear
                    inputReadOnly={true}
                    value={
                      componentValue.clinicalParameters.lastAdmissionDate?.value
                        ? getMomentObj(
                          componentValue.clinicalParameters.lastAdmissionDate?.value
                        )
                        : undefined
                    }
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={styles.label}>
                      {intl.formatMessage({ id: 'dischargeDate' })}
                    </Text>
                  }
                  style={{ ...antStyle.formMultiItem, flex: 1 }}
                >
                  <DatePicker
                    className='adt-event-date-picker'
                    style={{ ...antStyle.datePicker, flex: 1 }}
                    placeholder={intl.formatMessage({ id: 'selectDatePlaceholder' })}
                    data-testId = 'dischargeDate'
                    disabled={true}
                    format={DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT}
                    showTime={false}
                    allowClear
                    inputReadOnly={true}
                    value={
                      componentValue.clinicalParameters?.lastDischargeDate?.value
                        ? getMomentObj(
                          componentValue.clinicalParameters?.lastDischargeDate?.value
                        )
                        : undefined
                    }
                  />
                </Form.Item>
              </Stack>
            </View>
            <View style={{ marginTop: 20 }} >
              <View>
                <Text style={styles.sectionTitle}>
                  {intl.formatMessage({ id: 'officeVisits' })}
                </Text>
              </View>
              <Stack direction='row' space={10} style={{ ...styles.threeColumnGrid, flex: 1 }}>
                <Form.Item
                  label={
                    <Text style={styles.label}>
                      {intl.formatMessage({ id: 'lastOfficeVisitDate' })}
                    </Text>
                  }
                  style={{ ...antStyle.formMultiItem, flex: 1 }}
                >
                  <DatePicker
                    className='adt-event-date-picker'
                    style={{ ...antStyle.datePicker, flex: 1 }}
                    placeholder={intl.formatMessage({ id: 'selectDatePlaceholder' })}
                    data-testId = 'lastOfficeVisitDate'
                    disabled={true}
                    format={DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT}
                    showTime={false}
                    allowClear
                    inputReadOnly={true}
                    value={
                      componentValue.clinicalParameters.lastOfficeVisitDate?.value
                        ? getMomentObj(
                          componentValue.clinicalParameters.lastOfficeVisitDate?.value
                        )
                        : undefined
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Text style={styles.label}>
                      {intl.formatMessage({ id: 'nextOfficeVisitDate' })}
                    </Text>
                  }
                  style={{ ...antStyle.formMultiItem, flex: 1 }}
                >
                  <DatePicker
                    className='adt-event-date-picker'
                    style={{ ...antStyle.datePicker, flex: 1 }}
                    placeholder={intl.formatMessage({ id: 'selectDatePlaceholder' })}
                    data-testId = 'nextOfficeVisitDate'
                    disabled={true}
                    format={DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT}
                    showTime={false}
                    allowClear
                    inputReadOnly={true}
                    value={
                      componentValue.clinicalParameters?.futureAppointment?.value !== undefined
                        ? getMomentObj(
                          componentValue.clinicalParameters?.futureAppointment?.value
                        )
                        : undefined
                    }
                  />
                </Form.Item>
              </Stack>
            </View>

            <View style={{ marginTop: 20 }}>
              <View>
                <Text style={styles.sectionTitle}>
                  {intl.formatMessage({ id: 'emergencyCare' })}
                </Text>
              </View>

              <Form.Item
                label={
                  <Text style={styles.label}>
                    {intl.formatMessage({ id: 'lastERVisitDate' })}
                  </Text>
                }
                style={antStyle.formMultiItem}
              >
                <DatePicker
                  className='adt-event-date-picker'
                  style={antStyle.datePicker}
                  placeholder={intl.formatMessage({ id: 'selectDatePlaceholder' })}
                  data-testId = 'lastERVisitDate'
                  disabled={true}
                  format={DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT}
                  showTime={false}
                  allowClear
                  inputReadOnly={true}
                  value={
                    componentValue.clinicalParameters.lastERVisitDate?.value
                      ? getMomentObj(
                        componentValue.clinicalParameters.lastERVisitDate.value
                      )
                      : undefined
                  }
                />
              </Form.Item>
            </View>

            <View style={{ marginTop: 20 }}>
              <View>
                <Text style={styles.sectionTitle}>
                  {intl.formatMessage({ id: 'utilizationSummary' })}
                </Text>
              </View>

              <Stack direction='row' style={styles.threeColumnGrid}>
                <Form.Item
                  label={
                    <Text style={styles.label}>
                      {intl.formatMessage({ id: 'totalAdmissionsForLast12Months' })}
                    </Text>
                  }
                  style={antStyle.formMultiItem}
                >
                  <InputNumber
                    className='adt-event-form-input-number'
                    value={componentValue.clinicalParameters?.totalAdmissionsForLast12Months?.value}
                    placeholder={intl.formatMessage({ id: 'enterTotalAdmissions' })}
                    {...testID(TestIdentifiers.enterTotalAdmissions)}
                    style={{ ...antStyle.inputNumber, width: '100%' }}
                    min={0}
                    precision={0}
                    disabled={true}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={styles.label}>
                      {intl.formatMessage({ id: 'totalOutpatientVisitsForLast12Months' })}
                    </Text>
                  }
                  style={antStyle.formMultiItem}
                >
                  <InputNumber
                    className='adt-event-form-input-number'
                    value={componentValue.clinicalParameters?.totalOutpatientVisitsForLast12Months?.value}
                    placeholder={intl.formatMessage({ id: 'enterTotalOutpatientVisits' })}
                    {...testID(TestIdentifiers.enterTotalOutpatientVisits)}
                    style={{ ...antStyle.inputNumber, width: '100%' }}
                    min={0}
                    precision={0}
                    disabled={true}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={styles.label}>
                      {intl.formatMessage({ id: 'totalERVisitsForLast12Months' })}
                    </Text>
                  }
                  style={antStyle.formMultiItem}
                >
                  <InputNumber
                    className='adt-event-form-input-number'
                    value={componentValue.clinicalParameters?.totalERVisitsForLast12Months?.value}
                    placeholder={intl.formatMessage({ id: 'enterTotalERVisits' })}
                    {...testID(TestIdentifiers.enterTotalERVisits)}
                    style={{ ...antStyle.inputNumber, width: '100%' }}
                    min={0}
                    precision={0}
                    disabled={true}
                  />
                </Form.Item>
              </Stack>
            </View>
          </Form>
      }
    </ScrollView>
  );
};

export default React.memo(ClinicalParametersView);
