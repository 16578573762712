import {gql} from '@apollo/client';

const GetSingleGroupConversations = gql`
 query GetGroupConversations($limit: Int!, $offset: Int!, $loginUserId: bigint!, $loginUserUuid: uuid!, $whereCondition: conversations_bool_exp) {
  conversations(limit: $limit, offset: $offset, order_by: {lastActivityAt: desc_nulls_last}, where: $whereCondition) {
    contactId
    accountId
    id
    uuid
    displayId
    inboxId
    status
    additionalAttributes
    assigneeId
    createdAt
    updatedAt
    lastActivityAt
    workflowCommunicationLogResponseId
    blockReasonCode
    practiceLocationUuid
    isActive
    conversationUser {
      email
      name
    }
    conversationInbox {
      id
      channelType
      name
      isDeleted
      channelTwilioSms {
        phoneNumber
      }
      inboxMembers(order_by: {createdAt: desc_nulls_last}) {
        isDeleted
        userId
        user {
          id
          uuid
          name
          accountUsers {
            accountId
            userId
            isActive
            isDeleted
          }
        }
      }
    }
    unreadMessages: unreadMsg(args: {useruuid: $loginUserUuid}, where: {_or: {senderType: {_is_null: false}, senderId: {_neq: $loginUserId}}}) {
      id
    }
    conversationMessages(limit: 1, order_by: {createdAt: desc}) {
      senderType
      senderId
      content
      createdAt
      updatedAt
      messageType
      id
      contentAttributes
      messageAttachments {
        extension
        externalUrl
        fileType
        id
        updatedAt
        activeStorageAttachments {
          activeStorageBlob {
            filename
            contentType
          }
        }
      }
      senderUser {
        id
        uuid
        email
        name
      }
      senderContact {
        id
        uuid
        email
        name
      }
    }
    conversationContact {
      contactPracticeLocations(where: {practiceLocationUuid: {_is_null: false}}) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        accountLocation {
          uuid
        }
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      isActive
      contactTagging {
        labels {
          title
          color
          id
          description
        }
      }
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      personAddress {
        cityId
        stateId
        zipcodeId
        cities {
          name
          id
        }
        zipcodes {
          code
          id
        }
        states {
          name
          id
        }
        id
      }
      accountId
      uuid
      phoneNumber
      pubsubToken
      updatedAt
      notes {
        contactId
        content
        userId
        id
      }
      name
      email
      id
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        chosenName
        pronounId
        firstName
        birthDate
        id
        bloodGroup {
          value
          code
          id
        }
        gender {
          value
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      createdAt
      personContact {
        personContactId
        value
        id
        personContactType {
          accountId
          value
          id
          categoryId
        }
        typeId
        rank
      }
      contactConsents {
        consentId
      }
      lastActivityAt
      identifier
    }
    contactInbox {
      inbox {
        id
        name
      }
    }
    mentions {
      userId
      conversationId
    }
    assignedUser {
      id
      name
    }
    groupConversation {
      id
      name
      ownerId
      ownerTypeId
      isDefaultGroup
      groupType {
        code
        description
        value
      }
      groupConversationSetting {
        canSendMsgByUserTypeId
        editGroupInfoByUserTypeId
      }
      groupMembers_aggregate(
        where: {isDeleted: {_eq: false}}
        distinct_on: [groupUserId]
      ) {
        aggregate {
          count
        }
      }
      groupMembers {
        id
        groupUserId
        groupUserTypeId
        groupUserType {
          code
          value
        }
        contact {
          accountId
          id
          email
          name
          uuid
          contactType {
            contactType {
              code
            }
          }
          phoneNumber
          patient {
            patientId
            patientUuid
          }
          person {
            birthDate
            gender {
              value
              code
            }
            genderId
            birthSexId
            sexAtBirth {
              value
              code
              id
            }
          }
          contactConsents {
            consentId
          }
        }
        user {
          id
          name
          uuid
          userRoles {
            userRole {
              userRole {
                code
                value
              }
            }
          }
          accountUsers {
            isActive
            userId
          }
        }
      }
    }
  }
}
`;

const GetSingleNonCallGroupConversations = gql`
  query GetGroupConversations(
    $limit: Int!
    $offset: Int!
    $loginUserId: bigint!
    $loginUserUuid: uuid!
    $loginUserIntId: Int!
    $whereCondition: conversations_bool_exp
    $whereConversationConditions: messages_bool_exp!
  ) {
    conversations(
      limit: $limit
      offset: $offset
      order_by: {lastActivityAt: desc_nulls_last}
      where: $whereCondition
    ) {
      contactId
      accountId
      id
      uuid
      displayId
      inboxId
      status
      additionalAttributes
      assigneeId
      createdAt
      updatedAt
      lastActivityAt
      workflowCommunicationLogResponseId
      blockReasonCode
      practiceLocationUuid
      isActive
      conversationUser {
        email
        name
      }
      conversationInbox {
        id
        channelType
        name
        isDeleted
        channelTwilioSms {
          phoneNumber
        }
        inboxMembers(order_by: {createdAt: desc_nulls_last}) {
          isDeleted
          userId
          user {
            id
            uuid
            name
            accountUsers {
              accountId
              userId
              isActive
              isDeleted
            }
          }
        }
      }
      unreadMessages: unreadMsg(
        args: {useruuid: $loginUserUuid}
        where: {
          _or: {senderType: {_is_null: false}, senderId: {_neq: $loginUserId}}
        }
      ) {
        id
      }
      unreadMsgCount: unreadMsgNew(
        args: {
          useruuid: $loginUserUuid
          userid: $loginUserIntId
          messagetype: "{-1}"
          messageconditionin: false
        }
      )
      conversationMessages(
        limit: 1
        order_by: {createdAt: desc}
        where: $whereConversationConditions
      ) {
        senderType
        senderId
        content
        createdAt
        updatedAt
        messageType
        id
        contentAttributes
        messageAttachments {
          extension
          externalUrl
          fileType
          id
          updatedAt
          activeStorageAttachments {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
        senderUser {
          id
          uuid
          email
          name
        }
        senderContact {
          id
          uuid
          email
          name
        }
      }
      conversationContact {
        contactPracticeLocations(
          where: {practiceLocationUuid: {_is_null: false}}
        ) {
          uuid
          practiceLocationUuid
          id
          contactUuid
          practiceLocation {
            name
            practiceCity {
              name
              id
            }
          }
        }
        isActive
        contactTagging {
          labels {
            title
            color
            id
            description
          }
        }
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        personAddress {
          cityId
          stateId
          zipcodeId
          cities {
            name
            id
          }
          zipcodes {
            code
            id
          }
          states {
            name
            id
          }
          id
        }
        accountId
        uuid
        phoneNumber
        pubsubToken
        updatedAt
        notes {
          contactId
          content
          userId
          id
        }
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        personContact {
          personContactId
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
          rank
        }
        contactConsents {
          consentId
        }
        lastActivityAt
        identifier
      }
      contactInbox {
        inbox {
          id
          name
        }
      }
      mentions {
        userId
        conversationId
      }
      assignedUser {
        id
        name
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupType {
          code
          description
          value
        }
        groupConversationSetting {
          canSendMsgByUserTypeId
          editGroupInfoByUserTypeId
        }
        groupMembers_aggregate(
          where: {isDeleted: {_eq: false}}
          distinct_on: [groupUserId]
        ) {
          aggregate {
            count
          }
        }
        groupMembers {
          id
          groupUserId
          groupUserTypeId
          groupUserType {
            code
            value
          }
          contact {
            accountId
            id
            email
            name
            uuid
            contactType {
              contactType {
                code
              }
            }
            phoneNumber
            patient {
              patientId
              patientUuid
            }
            person {
              birthDate
              gender {
                value
                code
              }
              genderId
              birthSexId
              sexAtBirth {
                value
                code
                id
              }
            }
            contactConsents {
              consentId
            }
          }
          user {
            id
            name
            uuid
            userRoles {
              userRole {
                userRole {
                  code
                  value
                }
              }
            }
            accountUsers {
              isActive
              userId
            }
          }
        }
      }
    }
  }
`;

const GetGroupConversations = gql`
  query GetGroupConversations(
    $limit: Int!
    $offset: Int!
    $loginUserId: bigint!
    $loginUserUuid: uuid!
    $whereCondition: conversations_bool_exp
  ) {
    conversations(
      limit: $limit
      offset: $offset
      order_by: {lastActivityAt: desc_nulls_last}
      where: $whereCondition
    ) {
      contactId
      accountId
      id
      uuid
      displayId
      status
      additionalAttributes
      assigneeId
      createdAt
      updatedAt
      blockReasonCode
      practiceLocationUuid
      isActive
      conversationUser {
        email
        name
      }
      conversationInbox {
        id
        channelType
        isDeleted
        inboxMembers(order_by: {createdAt: desc_nulls_last}) {
          isDeleted
          userId
          user {
            id
            uuid
            name
            accountUsers {
              accountId
              userId
              isActive
              isDeleted
            }
          }
        }
      }
      unreadMessages: unreadMsg(
        args: {useruuid: $loginUserUuid}
        where: {
          _or: {senderType: {_is_null: false}, senderId: {_neq: $loginUserId}}
        }
      ) {
        id
      }
      conversationMessages(limit: 1, order_by: {createdAt: desc}) {
        senderType
        senderId
        content
        createdAt
        updatedAt
        messageType
        id
        contentAttributes
        messageAttachments {
          extension
          externalUrl
          fileType
          id
          updatedAt
          activeStorageAttachments {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
        senderUser {
          id
          uuid
          email
          name
        }
        senderContact {
          id
          uuid
          email
          name
        }
      }
      conversationContact {
        contactPracticeLocations(
          where: {practiceLocationUuid: {_is_null: false}}
        ) {
          uuid
          practiceLocationUuid
          id
          contactUuid
          practiceLocation {
            name
            practiceCity {
              name
              id
            }
          }
        }
        isActive
        contactTagging {
          labels {
            title
            color
            id
            description
          }
        }
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        personAddress {
          cityId
          stateId
          zipcodeId

          cities {
            name
            id
          }
          zipcodes {
            code
            id
          }
          states {
            name
            id
          }
          id
        }
        accountId
        uuid
        phoneNumber
        pubsubToken
        updatedAt
        notes {
          contactId
          content
          userId
          id
        }
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        personContact {
          personContactId
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
          rank
        }
        contactConsents {
          consentId
        }
        lastActivityAt
        identifier
      }
      contactInbox {
        inbox {
          id
          name
        }
      }
      mentions {
        userId
        conversationId
      }
      assignedUser {
        id
        name
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupType {
          code
          description
          value
        }
        groupConversationSetting {
          canSendMsgByUserTypeId
          editGroupInfoByUserTypeId
        }
        groupMembers_aggregate(
          where: {isDeleted: {_eq: false}}
          distinct_on: [groupUserId]
        ) {
          aggregate {
            count
          }
        }
        groupMembers(where: {
          _or: [
            {
              groupUserType: {
                code: {
                  _eq: "PATIENT"
                }
              }
            },
            {
              groupUserType: {
                code: {
                  _eq: "USER"
                }
              },
              groupUserId: {
                _eq: $loginUserUuid
              }
            }
          ]

        }
        ) {
          id
          groupUserId
          groupUserTypeId
          groupUserType {
            code
          }
          contact {
            id
            name
            uuid
            contactType {
              contactType {
                code
              }
            }
            patient {
              patientId
              patientUuid
            }
          }
        }
      }
    }
  }
`;

const GetGroupWelcomeMessage = gql`
  query getGroupWelcomeMessage($groupTypeCode: String!) {
    groupWelcomeMessages(
      where: {groupTypeData: {code: {_eq: $groupTypeCode}}}
    ) {
      id
      groupType
      accountUuid
      messageText
      createdAt
      updatedAt
      groupTypeData {
        code
      }
      createdByUser {
        id
        uuid
        name
        email
      }
      updatedByUser {
        id
        uuid
        name
        email
      }
    }
  }
`;

const GetSelectedConversationData = gql`
  query getSelectedConversation($conversationUuid: uuid!) {
    conversations(where: {uuid: {_eq: $conversationUuid}}) {
      uuid
      assigneeId
      status
      assignedUser {
        id
        name
      }
      createdAt
      updatedAt
      blockReasonCode
      practiceLocationUuid
      isActive
      conversationMessages(limit: 1, order_by: {createdAt: desc}) {
        content
        senderUser {
          id
          name
        }
        messageAttachments {
          id
        }
        updatedAt
      }
      groupConversation {
        name
        groupMembers {
          id
          groupUserId
          groupUserType {
            code
          }
          contact {
            accountId
            id
            email
            name
            uuid
            contactType {
              contactType {
                code
              }
            }
            phoneNumber
            patient {
              patientId
              patientUuid
            }
            person {
              birthDate
              gender {
                value
                code
              }
              genderId
              birthSexId
              sexAtBirth {
                value
                code
                id
              }
            }
            contactConsents {
              consentId
            }
          }
          user {
            id
            name
            uuid
            userRoles {
              userRole {
                userRole {
                  code
                  value
                }
              }
            }
            accountUsers {
              isActive
              userId
            }
          }
        }
      }
    }
  }
`;

const CreateGroupWelcomeMessage = gql`
  mutation CreateGroupWelcomeMessage(
    $welcomeMsgData: group_welcome_messages_insert_input!
  ) {
    createGroupWelcomeMessage(object: $welcomeMsgData) {
      id
    }
  }
`;

const UpdateGroupWelcomeMessage = gql`
  mutation updateGroupWelcomeMessage(
    $id: uuid!
    $welcomeMsgData: group_welcome_messages_set_input!
  ) {
    updateGroupWelcomeMessage(_set: $welcomeMsgData, pk_columns: {id: $id}) {
      id
    }
  }
`;

export default {
  GetGroupConversations,
  CreateGroupWelcomeMessage,
  GetGroupWelcomeMessage,
  UpdateGroupWelcomeMessage,
  GetSingleGroupConversations,
  GetSelectedConversationData,
  GetSingleNonCallGroupConversations,
};
