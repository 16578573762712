import React, {useMemo} from 'react';
import {Progress, Skeleton, Tooltip} from 'antd';
import {Colors} from '../../../../styles';
import {
  getStepViewConfig,
  getSubstepIcon,
} from './ContactCareProgramView.utils';

import {getMlovObjectFromId} from '../../../../utils/mlovUtils';
import useCareProgramStepStatus from './hooks/useCareProgramStepStatus';
import {testID} from '../../../../testUtils';
import {IContactCareProgramStep, IMlov} from '../interface';
import {useContactCareProgramContext} from './ContactCareProgram.context';
import SideBarRightView from './components/SideBarRightView';
import {useSelectedCareProgramStatus} from './hooks/useSelectedCareProgramStatus';
import { OUTREACH_STEP_TYPES } from '../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import { useStepOutreachStatus } from './MainContentView/components/hooks/useStepOutreachStatus';
import { CARE_PROGRAM_STEP_STATUS_CODES } from '../../../../constants/MlovConst';
import { getStepStatus } from '../../../common/CareProgramPanel/CareProgramUtils';
import { replaceHashValueToString } from '../../../../utils/commonUtils';
import { useIntl } from 'react-intl';
import { Text } from 'native-base';

const SideBarStep = (props: {
  isActiveStep: boolean;
  onSelectStep: () => void;
  step: IContactCareProgramStep;
}) => {
  const {isActiveStep, onSelectStep, step} = props;
  const intl = useIntl();
  const {id, title, subSteps, careProgramStepAdditionalAttributes, careProgramStepType, isRequired} = step;
  const {
    state: {contactCareProgramDetails, stepProgressMap},
  } = useContactCareProgramContext();
  const {programStatusCode} = useSelectedCareProgramStatus();

  const statusId = useMemo(
    () =>
      contactCareProgramDetails?.stepsLog?.find(
        (step) => step.careProgramStepId === id
      )?.careProgramStepStatusId,
    [contactCareProgramDetails?.stepsLog, id]
  );

  const {careProgramStepStatusList} = useCareProgramStepStatus();

  const statusMlov = getMlovObjectFromId(
    statusId as string,
    careProgramStepStatusList
  );


  const isOutreachStep = OUTREACH_STEP_TYPES.includes(careProgramStepType?.code);
  const shouldShowRequiredIndicator = isRequired && (!isOutreachStep);
  const { completionStatus, loading } = useStepOutreachStatus(
    contactCareProgramDetails?.contactId || '',
    id,
    contactCareProgramDetails?.payerCareProgram?.careProgramTypeId || '',
    contactCareProgramDetails?.id || ''
  );


  const SubstepIcon = getSubstepIcon(getStepStatus(completionStatus, isOutreachStep, statusMlov));
  const status = statusMlov?.value;

  const isReadOnly = careProgramStepAdditionalAttributes.some(
    (attribute) =>
      attribute.attributeKey === 'readOnly' &&
      attribute.attributeValue.valueBoolean
  );

  const stepDisplayConfig = getStepViewConfig({
    step,
    stepStatusCode: statusMlov?.code as string,
    programStatusCode,
  });

  if (isOutreachStep && loading) {
    return (
      <div
        className={`sidebar-item-collapsible sidebar-item-child ${
          isActiveStep ? 'active-step' : 'cursor-pointer'
        }`}
      >
        {isActiveStep && <div className={`active-step-indicator`} />}
        <Skeleton.Input active style={{width: 100, height: 10, borderRadius: 4, marginTop: 2}} />
      </div>
    );
  }

  return (
    <div
      className={`sidebar-item-collapsible sidebar-item-child ${
        isActiveStep ? 'active-step' : 'cursor-pointer'
      }`}
      onClick={onSelectStep}
      {...testID(`${title}`)}
    >
      {isActiveStep && <div className={`active-step-indicator`} />}
      {SubstepIcon && (
        <div>
          <Tooltip title={status}>
            <SubstepIcon />
          </Tooltip>
        </div>
      )}
      <div className="sidebar-step-content-container">
        <div className="flex flex-col flex-1">
          <div
            className="step-title"
            style={styles.stepTitle}
          >
            {stepDisplayConfig.displayTitle}
            {shouldShowRequiredIndicator && <Text color={Colors.Custom.AppointmentStatusRed} fontSize={18} marginLeft={1}>•</Text>}
            {isOutreachStep && completionStatus.total > 0 && (
              <Tooltip title={replaceHashValueToString({completed: completionStatus.completed.toString(), total: completionStatus.total.toString()},intl.formatMessage({id: 'activeOutreachCompletedMsg'}))}>  
              <div style={styles.rateContainer}>
                <span style={styles.rateText}>
                  {completionStatus.completed}/{completionStatus.total}
                </span>
              </div>
              </Tooltip>
            )}
          </div>
          {stepDisplayConfig.showProgress && (
            <Progress
              percent={stepProgressMap?.get(id) || 0}
              showInfo={false}
              strokeColor={Colors?.Custom?.SuccessColor}
              size="small"
            />
          )}
        </div>
        <SideBarRightView
          step={step}
          stepStatusId={statusId as string}
          stepLogs={contactCareProgramDetails?.stepsLog || []}
        />
      </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  rateContainer: {
    marginLeft: 'auto', 
    backgroundColor: Colors.FoldPixel.GRAY50,
    padding: '2px 6px',
    borderRadius: 4,
    borderWidth: '0.5px',
    borderColor: Colors.FoldPixel.GRAY100,
    borderStyle: 'solid',
  },
  rateText: {
    fontSize: 12,
    color: Colors.FoldPixel.GRAY300,
    fontWeight: '500',
  },
  stepTitle: {
    display: 'flex', 
    alignItems: 'center'
  }
};

export default React.memo(SideBarStep);
