import {Divider, Spinner, Text} from 'native-base';
import Stack from '../../../common/LayoutComponents/Stack';
import {Colors} from '../../../../styles';
import {Pressable, ViewStyle} from 'react-native';
import {getFormattedDate} from '../../../../utils/DateUtils';
import {DISPLAY_DATE_FORMAT} from '../../../../constants';
import {IHieRequest} from './PendingHieRequests/interface';
import {useContext} from 'react';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {StyleSheet} from 'react-native';
import React from 'react';

interface AvailableHieDataViewProps {
  hieRequests: IHieRequest[];
  onReviewClick: () => void;
  hieRequestsLoading: boolean;
}

export const AvailableHieDataView = (props: AvailableHieDataViewProps) => {
  const {hieRequests, onReviewClick, hieRequestsLoading} = props;
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;

  const stackStyles = React.useMemo<ViewStyle>(() => {
    return {
      justifyContent: 'space-between',
      paddingHorizontal: isSidecarContext ? 10 : 16,
      alignItems: 'center',
      marginTop: isSidecarContext ? 6 : 8,
      marginBottom: isSidecarContext ? 8 : 0,
    };
  }, [isSidecarContext]);

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        style={stackStyles}
      >
        <Stack direction="column">
          <Text
            my={isSidecarContext ? 0 : 2}
            size={'smRegular'}
            color={Colors.FoldPixel.GRAY400}
          >
            {isSidecarContext ? 'Data Import from HIE' : 'New data from HIE'}
            {isSidecarContext && (
              <Text
                style={styles.newDataTag}
              >
                {'New'}
              </Text>
            )}
          </Text>
          {hieRequests.length > 0 ? (
            <Text
              fontSize={isSidecarContext ? 14 : 14}
              color={
                isSidecarContext
                  ? Colors.FoldPixel.GRAY300
                  : Colors.FoldPixel.GRAY400
              }
            >
              {`Last Synced At: ${getFormattedDate(
                hieRequests?.[0]?.syncDate,
                DISPLAY_DATE_FORMAT
              )} • ${hieRequests.length} items`}
            </Text>
          ) : (
            <></>
          )}

          {(!hieRequests || hieRequests.length === 0) && !hieRequestsLoading ? (
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY300}>{'No new data reported from HIE'}</Text>
          ) : (
            <></>
          )}
        </Stack>

        {hieRequests.length > 0 && !hieRequestsLoading ? (
          <Pressable onPress={onReviewClick}>
            <Text fontSize={14} color={Colors.FoldPixel.PRIMARY300}>
              {'Reconcile'}
            </Text>
          </Pressable>
        ) : (
          <></>
        )}

        {hieRequestsLoading && <Spinner />}
      </Stack>
      {!isSidecarContext && <Divider marginTop={4} />}
    </Stack>
  );
};

const styles = StyleSheet.create({
  newDataTag: {
    backgroundColor: Colors.Custom.PrimaryColor100,
    color: Colors.Custom.Primary300,
    fontSize: 12,
    marginLeft: 8,
    paddingHorizontal: 4,
    paddingVertical: 2,
    borderRadius: 4,
    fontWeight: '500',
  },
});
