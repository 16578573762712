
import React from 'react';
import CarePlan from "../../../../../PersonOmniView/MiddleContainer/CarePlan/CarePlan";
import {useSelectedStepInCareProgram} from './hooks/useSelectedStepInCareProgram';
import { useContactCareProgramContext } from '../../ContactCareProgram.context';


const CarePlanStepView: React.FC = React.memo(() => {
  const {accountLocationUuid, personData} = useSelectedStepInCareProgram();
  const {state} = useContactCareProgramContext();
  return (
    <CarePlan
      accountLocationUuid={accountLocationUuid ?? ''}
      contactUuid={personData?.uuid ?? ''}
      contactName={personData?.name ?? ''}
      contactId={personData?.id ?? -1}
      personData={personData}
      careProgramStatusId={state.contactCareProgramDetails?.statusId ?? ''}
    />
  );
});

export default CarePlanStepView;
