import {HStack, Text, VStack, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {FHIR_RESOURCE} from '../../../constants/FhirConstant';
import {getPatientDetailsFromHint} from '../../../services/CommonService/AidBoxService';
import {Colors} from '../../../styles';
import {getAccountConfigResourceAbilities, getResourceAbilities} from '../../../utils/capabilityUtils';
import {getFormattedDate} from '../../../utils/DateUtils';
import {capitalizeText} from '../ContactRelationView/ContactRelationUtils';
import {useContactLocationUuid} from '../MemebersView/customHook/useContactLocationUuid';
import MembershipStatusSvg from '../Svg/SideMenu/MembershipStatusSvg';

interface IHintPlanDetailView {
  id: number
  isEmployerReportDrillDown: boolean;
  locationId: string | undefined
  sideCarDashboardEnabled?: boolean
}

const HintPlanDetailView = (props: IHintPlanDetailView) => {
  const [hintPatientDetails, setHintPatientDetails] = useState({
    plan: '',
    status: '',
    endDate: '',
    startDate: '',
    loading: false,
    locationId: props?.locationId,
  });

  const {fetchContactLocationUuid} = useContactLocationUuid(props?.id, true);

  const getContactLocationId = async (contactId: number) => {
    const response = await fetchContactLocationUuid(contactId);
    setHintPatientDetails((prev) => {
      return {
        ...prev,
        locationId: response,
      };
    });
  };

  const hintCapability = getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;
  useEffect(() => {
    if(!hintPatientDetails?.locationId) {
      getContactLocationId(props?.id)
    } else {
    // if (openInHint) {
      setHintPatientDetails(prev => {
        return {
          ...prev,
          loading: true
        }
      })
      getPatientDetailsFromHint(props.id, hintPatientDetails?.locationId)
        .then((response) => {
          const data = response.data;
          let activeMembership = data.memberships.find((item: any) => {
            return item.status === 'active';
          });

          activeMembership = activeMembership
            ? activeMembership
            : data.memberships.length
            ? data.memberships[0]
            : undefined;
          setHintPatientDetails((prev) => {
            return {
              ...prev,
              plan: activeMembership?.plan.name || '',
              status: data.membership_status || '',
              endDate: activeMembership?.end_date || '',
              startDate: activeMembership?.start_date || '',
              loading: false
            };
          });
        })
        .catch((error) => {

          setHintPatientDetails(prev => {
            return {
              ...prev,
              plan: '',
              status: '',
              endDate: '',
              loading: false
            }
          })
        });
    // }
    }
  }, [props.id, hintPatientDetails?.locationId]);
  const getDefaultViewForHintPatientDetails = () => {
    return <>{hintPatientDetails.status ||
      hintPatientDetails.plan ||
      hintPatientDetails.endDate ? (
      <HStack style={{maxWidth: '90%', flexWrap: 'wrap'}}>
        {hintPatientDetails.status ? (
          <View
            style={{marginRight: 16}}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <Text
              style={{
                color: Colors.Custom.Gray500,
              }}
            >
              {'Membership Status: '}
            </Text>
            <Text
              style={{
                color:
                  hintPatientDetails.status == 'active'
                    ? Colors.success[500]
                    : Colors.error[500],
              }}
            >
              {capitalizeText(hintPatientDetails.status)}
            </Text>
          </View>
        ) : (
          <></>
        )}
        {hintPatientDetails.plan ? (
          <Text
            style={{
              color: Colors.Custom.Gray500,
            }}
          >
            {'Plan: '}
            {hintPatientDetails.plan}
          </Text>
        ) : (
          <></>
        )}
        {hintPatientDetails.endDate ? (
          <Text
            style={{
              color: Colors.Custom.Gray500,
              marginRight: 16,
            }}
          >
            {'Exp: '}
            {hintPatientDetails.endDate}
          </Text>
        ) : (
          <></>
        )}
      </HStack>
    ) : (
      null
    )}
    </>;
  }

  const getDefaultViewForHintPatientDetailsForPatientInfoBannerSideCarDashboard = () => {
    return <>{true
      ? (
        <HStack style={{ maxWidth: '100%', flexWrap: 'wrap' }}>
          {hintPatientDetails.status ||
            hintPatientDetails.plan ||
            hintPatientDetails.endDate ? (
            <View
              style={{ marginRight: 16 }}
              flexDirection={'row'}
              alignItems={'center'}
            >
              <MembershipStatusSvg height='16' width='16' />
              <Text
                style={{
                  color:
                    hintPatientDetails.status == 'active'
                      ? Colors.success[500]
                      : Colors.error[500],
                }}
                marginLeft={1}
              >
              {capitalizeText(hintPatientDetails.status) || 'Active'}
            </Text>
          </View>
        ) : (
          <></>
        )}
        { hintPatientDetails.plan ? (
          <Text
            style={{
              color: Colors.FoldPixel.GRAY250,
            }}
            size={'smRegular'}
          >
            • {hintPatientDetails.plan}
          </Text>
        ) : (
          <></>
        )}
        {hintPatientDetails.endDate ? (
          <Text
            style={{
              color: Colors.FoldPixel.GRAY250,
              marginRight: 16,
            }}
            size={'smRegular'}
          >
            {'Exp: '}
            {hintPatientDetails.endDate}
          </Text>
        ) : (
          <></>
        )}
      </HStack>
    ) : (
      null
    )}
    </>;
  }

  const getEmployerDrillDownViewForHintPatientDetails = () => {
    return <>{hintPatientDetails.status ||
      hintPatientDetails.plan ? (
      <VStack>
        {hintPatientDetails.status ? (
          <HStack
            style={{marginRight: 16}}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <Text
              style={{
                color: Colors.Custom.Gray500,
              }}
              size={'xsMedium'}
            >
              {'Membership Status: '}
            </Text>
            <Text
              size={'xsMedium'}
              style={{
                color:
                  hintPatientDetails.status == 'active'
                    ? Colors.success[500]
                    : Colors.error[500],
              }}
            >
              {capitalizeText(hintPatientDetails.status)}
            </Text>
          </HStack>
        ) : (
          <></>
        )}
        {hintPatientDetails.plan ? (
          <HStack>
            <Text
              style={{
                color: Colors.Custom.Gray500,
              }}
              size={'xsMedium'}
            >
              {'Plan: '}
              {hintPatientDetails.plan}
            </Text>
          </HStack>
        ) : (
          <></>
        )}
        {hintPatientDetails.endDate || hintPatientDetails?.startDate ? (
          <VStack>
            <Text size={'xsMedium'} color={Colors.Custom.Gray400} >
              Membership: Start Date - {getFormattedDate(hintPatientDetails?.startDate,'MM/DD/YYYY')}
            </Text>
            <Text size={'xsMedium'} color={Colors.Custom.Gray400} >
              End Date -  {hintPatientDetails.endDate ? getFormattedDate(hintPatientDetails.endDate,'MM/DD/YYYY') : 'Not set'}
            </Text>
          </VStack>
        ) : (
          <></>
        )}
      </VStack>
    ) : (
      null
    )}
    </>;
  }

  const showHintPatientDetails = () => {
    let hintPatientDetailView = props?.sideCarDashboardEnabled ? getDefaultViewForHintPatientDetailsForPatientInfoBannerSideCarDashboard() : getDefaultViewForHintPatientDetails();
    if (props.isEmployerReportDrillDown) {
      hintPatientDetailView = getEmployerDrillDownViewForHintPatientDetails();
    }
    return hintPatientDetailView;
  };
  return showHintPatientDetails();

}
export default HintPlanDetailView;
