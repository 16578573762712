import {HStack, View, VStack, Text} from 'native-base';
import {Colors} from '../../../../../../styles';
import {DisplayCardAvatar} from '../../../../../common/DisplayCard/DisplayCardAvatar';
import {GROUP_MEMBER_TYPE} from '../../../../../../constants';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import { ICardForLocationAndUser } from '../interfaces';

const CardForLocationAndUser = (props: ICardForLocationAndUser) => {
const { name, uuid, isSearchComponent, profileUrl, ehrName, showAvatar} = props;

  return (
    <>
      <HStack
        width={'100%'}
        marginY={isWeb() && !isSearchComponent ? 2 : 0}
        alignItems="flex-start"
        paddingY={2}
        paddingX={4}
        style={
            { ...(!isSearchComponent ? { borderRadius: 8, borderWidth: 1, borderColor: Colors.Custom.Gray200 } : undefined) }
        }
      >
        {showAvatar && (
        <View>
          <DisplayCardAvatar
            avatarStyle={{
              avatarSize: '12',
              width: 48,
              height: 48,
            }}
            userData={{
              userType: GROUP_MEMBER_TYPE.USER,
              userId: uuid,
              name: name,
              userName: name,
              imgSrc: profileUrl || '',
            }}
            isLetterAvatarShow
          />
        </View>)}
        <VStack
          alignSelf="stretch"
          flex={1}
          marginLeft={2}
          justifyContent={'flex-start'}
        >
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY400}>
            {name}
          </Text>
          {ehrName && (
            <Text size={'xsNormal'} color={Colors.FoldPixel.GRAY250}>
              in {ehrName}
            </Text>
          )}
        </VStack>
      </HStack>
    </>
  );
};
export default CardForLocationAndUser;
