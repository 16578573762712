import React from 'react';
import {ElementConfig, RPAConfig} from './interfaces';
import {RPAConst} from './RPAConst';

export const AddMedicationAthenaButtonFinderData: ElementConfig = {
  type: 'button',
  identifiers: [
    {
      type: 'data-view-context',
      value: 'add-diagnoses-and-orders',
    },
    {
      type: 'xpath',
      value:
        '/html[1]/body[1]/div[4]/div[1]/div[3]/div[6]/div[3]/div[4]/div[1]/div[1]/div[1]/div[6]/div[1]/div[1]/div[1]/h3[1]/div[1]/div[1]/div[1]/button[1]',
    },
  ],
};

export const AddMedicationElationButtonFinderData: ElementConfig = {
  type: 'button',
  identifiers: [
    {
      type: 'css',
      value:
        'body.el8-focus-disabled.no_search:nth-child(2) div.el8ActionsMenuDialog.hidden.jqmID5:nth-child(12) ul.el8WriteSeeMenuList > li.meds_order_trigger',
    },
    {
      type: 'xpath',
      value: "//li[contains(text(),'Prescription Form (Rx/OTC/CS)')]",
    },
  ],
};

export const MedicationConfigElation: RPAConfig = {
  actionCode: RPAConst.ADD_MEDICATION_ELATION,
  description: 'Add a medication to the EHR',
  steps: [
    {
      type: 'find',
      element: {
        type: 'button',
        identifiers: [
          {
            type: 'css',
            value:
              'body.el8-focus-disabled.no_search:nth-child(2) div.el8ActionsMenuDialog.hidden.jqmID5:nth-child(12) ul.el8WriteSeeMenuList > li.meds_order_trigger',
          },
          {
            type: 'xpath',
            value: "//li[contains(text(),'Prescription Form (Rx/OTC/CS)')]",
          },
        ],
      },
    },
    {
      type: 'click',
      element: {
        type: 'button',
        identifiers: [
          {
            type: 'css',
            value:
              'body.el8-focus-disabled.no_search:nth-child(2) div.el8ActionsMenuDialog.hidden.jqmID5:nth-child(12) ul.el8WriteSeeMenuList > li.meds_order_trigger',
          },
          {
            type: 'xpath',
            value: "//li[contains(text(),'Prescription Form (Rx/OTC/CS)')]",
          },
        ],
      },
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {type: 'data-testid', value: 'prescription-form-medication-input'},
        ],
      },
      value: '{medicationName}',
    },
    {
      type: 'search_result',
      element: {
        type: 'search_result',
        identifiers: [
          {
            type: 'data-testid',
            value: 'prescription-form-medication-input-results-list',
          },
        ],
      },
      filterValue: 'Manage Rx Templates...',
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {type: 'data-testid', value: 'prescription-form-sig-input'},
        ],
      },
      value: '{sig}',
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {type: 'data-testid', value: 'prescription-form-quantity-input'},
        ],
      },
      value: '{qty}',
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {type: 'data-testid', value: 'prescription-form-unit-input'},
        ],
      },
      value: '{unit}',
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {type: 'data-testid', value: 'prescription-form-refills-input'},
        ],
      },
      value: '{riffils}',
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {type: 'data-testid', value: 'prescription-form-days-supply-input'},
        ],
      },
      value: '{daysSupply}',
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          // {
          //   type: 'xpath',
          //   value: '/html[1]/body[1]/div[42]/div[1]/div[2]/form[1]/div[1]/div[2]/div[1]/div[7]/span[1]/span[1]/span[1]/div[1]/input[1]',
          // },
          {type: 'data-testid', value: 'prescription-form-diagnosis-input'},
        ],
      },
      value: '{diagnosisCode}',
    },
    {
      type: 'search_result',
      element: {
        type: 'search_result',
        identifiers: [
          {
            type: 'data-testid',
            value: 'prescription-form-diagnosis-input-results-list',
          },
        ],
      },
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {
            type: 'data-testid',
            value: 'prescription-form-instructions-to-pharmacy-input',
          },
        ],
      },
      value: '{instructionToPharmacy}',
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {type: 'data-testid', value: 'prescription-form-start-date'},
        ],
      },
      value: '{doNotFillBeforeDate}',
    },
    {
      type: 'checkbox',
      element: {
        type: 'checkbox',
        identifiers: [{type: 'checkbox', value: '.Checkbox__control'}],
      },
    },
    {
      type: 'radio',
      element: {
        type: 'radio',
        identifiers: [{type: 'radio', value: `aria-label="Temporary"`}],
      },
    },
    // {
    //   type: "wait",
    //   condition: {
    //     type: "element",
    //     identifiers: [{ type: "css", value: ".search-results" }],
    //     timeout: 3000
    //   }
    // },
    {
      type: 'click',
      element: {
        type: 'button',
        identifiers: [
          {
            type: 'data-testid',
            value: 'prescription-form-save-as-draft-button',
          },
        ],
      },
    },
  ],
};

export const MedicationConfigAthena: RPAConfig = {
  actionCode: RPAConst.ADD_MEDICATION_ATHENA,
  description: 'Add a medication to the EHR',
  steps: [
    {
      type: 'find',
      element: {
        type: 'button',
        identifiers: [
          {
            type: 'data-view-context',
            value: 'add-diagnoses-and-orders',
          },
          {
            type: 'xpath',
            value:
              '/html[1]/body[1]/div[4]/div[1]/div[3]/div[6]/div[3]/div[4]/div[1]/div[1]/div[1]/div[6]/div[1]/div[1]/div[1]/h3[1]/div[1]/div[1]/div[1]/button[1]',
          },
        ],
      },
    },
    {
      type: 'click',
      element: {
        type: 'add-diagnoses-and-orders-button',
        identifiers: [
          {
            type: 'data-view-context',
            value: 'add-diagnoses-and-orders',
          },
          {
            type: 'xpath',
            value:
              '/html[1]/body[1]/div[4]/div[1]/div[3]/div[6]/div[3]/div[4]/div[1]/div[1]/div[1]/div[6]/div[1]/div[1]/div[1]/h3[1]/div[1]/div[1]/div[1]/button[1]',
          },
        ],
      },
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {
            type: 'class',
            value:
              '.search-pane.add-diagnoses-and-orders-dialog .search-pane-content .search-widget .search-header .search-input',
          },
          {
            type: 'css',
            value:
              'div.popup-overlay.overlay-open:nth-child(1) div.popup.anchored-popup.extends-right.anchored-popup-offset-none.search-plan-popup.popup-open div.content div.search-pane.add-diagnoses-and-orders-dialog div.search-pane-content div.search-widget div.search-header > input.search-input',
          },
        ],
      },
      value: '{ordersName}',
    },
    {
      type: 'search_result',
      element: {
        type: 'search_orders_medication_athena',
        identifiers: [
          {type: 'li-data-id', value: 'li[data-id="search-source-2"]'},
        ],
      },
    },
    {
      type: 'click',
      element: {
        type: 'li-a/p-athena',
        identifiers: [
          {
            type: 'li-data-id',
            value: 'li[data-section-id="assessment_and_plan"]',
          },
        ],
        textContent: 'A/P',
      },
      timeOut: 4000,
    },
    {
      type: 'search_result',
      element: {
        type: 'search_a/p_diagnosis_athena',
        identifiers: [{type: 'li-data-id', value: 'ul.orders.encounter-list'}],
        orderType: 'prescription',
      },
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {
            type: 'class',
            value:
              'input[name="DosageQuantity"].autostart.required.for-provider',
          },
        ],
      },
      value: '{dosageQuantity}',
      timeOut: 4000,
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {
            type: 'class',
            value:
              'input[name="Quantity"].autostart.required.for-provider.missing.cloi_1848_intake_required_fix_enabled',
          },
        ],
      },
      value: '{qty}',
    },
  ],
};

export const LabInternalNoteConfigAthena: RPAConfig = {
  actionCode: RPAConst.ADD_LAB_INTERNAL_NOTE_ATHENA,
  description: 'Add a lab internal note to the EHR',
  steps: [
    {
      type: 'find',
      element: {
        type: 'button',
        identifiers: [
          {
            type: 'li-data-id',
            value: `li[data-chart-section-id="results"]`,
          },
        ],
      },
    },
    {
      type: 'click',
      element: {
        type: 'button',
        identifiers: [
          {
            type: 'li-data-id',
            value: `li[data-chart-section-id="results"]`,
          },
        ],
      },
    },
    {
      type: 'search_result',
      element: {
        type: 'search_lab_results_for_add_note_athena',
        identifiers: [
          {
            type: 'li-data-id',
            value: 'ul.autostart.accordion-container.encounter-list.labs',
          },
        ],
      },
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {type: 'class', value: `div.rich-text-input[contenteditable="true"]`},
        ],
      },
      value: '{internalNote}',
      timeOut: 3000,
    },
  ],
};

export const AddDiagnosesConfigAthena: RPAConfig = {
  actionCode: RPAConst.ADD_DIAGNOSES_ATHENA,
  description: 'Add diagnoses to the EHR',
  steps: [
    {
      type: 'find',
      element: {
        type: 'button',
        identifiers: [
          {
            type: 'data-view-context',
            value: 'add-diagnoses-and-orders',
          },
          {
            type: 'xpath',
            value:
              '/html[1]/body[1]/div[4]/div[1]/div[3]/div[6]/div[3]/div[4]/div[1]/div[1]/div[1]/div[6]/div[1]/div[1]/div[1]/h3[1]/div[1]/div[1]/div[1]/button[1]',
          },
        ],
      },
    },
    {
      type: 'click',
      element: {
        type: 'add-diagnoses-and-orders-button',
        identifiers: [
          {
            type: 'data-view-context',
            value: 'add-diagnoses-and-orders',
          },
          {
            type: 'xpath',
            value:
              '/html[1]/body[1]/div[4]/div[1]/div[3]/div[6]/div[3]/div[4]/div[1]/div[1]/div[1]/div[6]/div[1]/div[1]/div[1]/h3[1]/div[1]/div[1]/div[1]/button[1]',
          },
        ],
      },
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {
            type: 'class',
            value:
              '.search-pane.add-diagnoses-and-orders-dialog .search-pane-content .search-widget .search-header .search-input',
          },
          {
            type: 'css',
            value:
              'div.popup-overlay.overlay-open:nth-child(1) div.popup.anchored-popup.extends-right.anchored-popup-offset-none.search-plan-popup.popup-open div.content div.search-pane.add-diagnoses-and-orders-dialog div.search-pane-content div.search-widget div.search-header > input.search-input',
          },
        ],
      },
      value: '{diagnosesName}',
    },
    {
      type: 'search_result',
      element: {
        type: 'search_diagnoses_athena',
        identifiers: [
          {type: 'li-data-id', value: 'li[data-id="search-source-1"]'},
        ],
      },
    },
    {
      type: 'click',
      element: {
        type: 'li-a/p-athena',
        identifiers: [
          {
            type: 'li-data-id',
            value: 'li[data-section-id="assessment_and_plan"]',
          },
        ],
        textContent: 'A/P',
      },
      timeOut: 4000,
    },
  ],
};

export const AddDiagnosesConfigElation: RPAConfig = {
  actionCode: RPAConst.ADD_DIAGNOSES_ELATION,
  description: 'Add a diagnoses to the EHR',
  steps: [
    {
      type: 'find',
      element: {
        type: 'button',
        identifiers: [
          {
            type: 'xpath',
            value: "//button[contains(text(),'+ Add Problem')]",
          },
          {
            type: 'css',
            value:
              'body.el8-focus-disabled.no_search:nth-child(2) div.pageContainer:nth-child(6) div.ebs-container-fluid:nth-child(3) div.ebs-row:nth-child(18) div.ebs-isolate:nth-child(1) div.el8LHS.ebs-col-xs-3 div.ref-board-container.el8LHSContainer ol.ref-board-body.el8LHSContent.ebs-list-unstyled:nth-child(4) li.el8LHSSection:nth-child(3) div:nth-child(3) span.problem-buttons:nth-child(5) div.el8LHSButtonContainer p:nth-child(1) > button.el8button.add-problem-trigger.el8ItemButton.el8Show.ebs-btn.ebs-btn-default',
          },
        ],
      },
    },
    {
      type: 'click',
      element: {
        type: 'button',
        identifiers: [
          {
            type: 'xpath',
            value: "//button[contains(text(),'+ Add Problem')]",
          },
          {
            type: 'css',
            value:
              'body.el8-focus-disabled.no_search:nth-child(2) div.pageContainer:nth-child(6) div.ebs-container-fluid:nth-child(3) div.ebs-row:nth-child(18) div.ebs-isolate:nth-child(1) div.el8LHS.ebs-col-xs-3 div.ref-board-container.el8LHSContainer ol.ref-board-body.el8LHSContent.ebs-list-unstyled:nth-child(4) li.el8LHSSection:nth-child(3) div:nth-child(3) span.problem-buttons:nth-child(5) div.el8LHSButtonContainer p:nth-child(1) > button.el8button.add-problem-trigger.el8ItemButton.el8Show.ebs-btn.ebs-btn-default',
          },
        ],
      },
    },
    {
      type: 'input',
      element: {
        type: 'inputElationDiagnosesName',
        identifiers: [
          {type: 'class', value: 'input.info-field.mr-input-field[name="query"]'},
        ],
      },
      value: '{diagnosesName}',
    },
    {
      type: 'search_result',
      element: {
        type: 'search_diagnoses_elation',
        identifiers: [
          {
            type: 'xpath',
            value: `//div[@class="mr-dropdown-item mr-result-item el8MRItem" and @role="listitem" and @_mr_index="0"]`,
          },
          // {
          //   type: 'css',
          //   value: `body.el8-focus-disabled.no_search:nth-child(2) div.mr-dropdown.el8ActionsMenuDialog:nth-child(68) div.mr-dropdown-content.el8MRContainer > div.mr-results.mr-matches.padb10`,
          // },
          {
            type: 'class',
            value: '.mr-results',
          },
        ],
        childIdentifiers: [
          {
            type: 'class',
            value: '.mr-dropdown-item.mr-result-item',
          },
        ],
      },
      timeOut: 4000,
    },
    {
      type: 'click',
      element: {
        type: 'buttonSaveDiagnosisClick',
        identifiers: [
          {
            type: 'class',
            value: 'button.el8PrimaryButton[name="save"]',
          },
        ],
      },
      timeOut: 3000
    },
  ],
};

export const AddImagingOrderConfigElation: RPAConfig = {
  actionCode: RPAConst.ADD_IMAGING_ORDER_ELATION,
  description: 'Add a imaging order to the EHR',
  steps: [
    {
      type: 'find',
      element: {
        type: 'button',
        identifiers: [
          {
            type: 'class',
            value:
              'imaging_order_trigger',
          },
          {
            type: 'xpath',
            value: "//li[contains(text(),'Imaging Order')]",
          },
          {
            type: 'css',
            value:
              'body.el8-focus-disabled.no_search:nth-child(2) div.el8ActionsMenuDialog.hidden.jqmID3:nth-child(8) ul.el8WriteSeeMenuList > li.imaging_order_trigger',
          }
        ],
      },
    },
    {
      type: 'click',
      element: {
        type: 'button',
        identifiers: [
          {
            type: 'class',
            value:
              'imaging_order_trigger',
          },
          {
            type: 'xpath',
            value: "//li[contains(text(),'Imaging Order')]",
          },
          {
            type: 'css',
            value:
              'body.el8-focus-disabled.no_search:nth-child(2) div.el8ActionsMenuDialog.hidden.jqmID3:nth-child(8) ul.el8WriteSeeMenuList > li.imaging_order_trigger',
          }
        ],
      },
    },
    {
      type: 'input',
      element: {
        type: 'inputElationImagingName',
        identifiers: [
          {type: 'xpath', value: `//textarea[@name='test' and @_infotext='tests to be completed...']`},
        ],
      },
      value: '{imagingName}',
    },
    {
      type: 'search_result',
      element: {
        type: 'search_imaging_name_elation',
        identifiers: [
          {
            type: 'class',
            value: '.mr-results',
          },
        ],
        childIdentifiers: [
          {
            type: 'class',
            value: '.mr-dropdown-item mr-result-item el8MRItem mr-selected',
          },
        ],
      },
      timeOut: 4000,
    },
    {
      type: 'input',
      element: {
        type: 'input',
        identifiers: [
          {type: 'xpath', value: `//textarea[@name='clinicalReason']`},
        ],
      },
      value: '{clinicalReason}',
    },
    {
      type: 'click',
      element: {
        type: 'centerListElation',
        identifiers: [
          {
            type: 'xpath',
            value: `//select[@name='testCenterCompany']`,
          },
        ],
        textContent: '...Other',
      },
      timeOut: 3000,
    },
    // {
    //   type: 'click',
    //   element: {
    //     type: 'button',
    //     identifiers: [
    //       {
    //         type: 'xpath',
    //         value: `//a[@class='order-form-details-trigger el8SmallTextLink']`,
    //       },
    //     ],
    //   },
    //   timeOut: 1000
    // },
    {
      type: 'click',
      element: {
        type: 'buttonPhysicianClick',
        identifiers: [
          {
            type: 'xpath',
            value: `//button[@id='imaging-order-save-btn']`,
          },
        ],
      },
      timeOut: 3000
    },
    {
      type: 'click',
      element: {
        type: 'buttonPhysicianClick',
        identifiers: [
          {
            type: 'xpath',
            value: `//button[text()='Select']`,
          },
        ],
      },
      timeOut: 3000
    },
  ],
};


export const AddSuccessToastFinderForAddImagingOrder: ElementConfig = {
  type: 'button',
  identifiers: [
    {
      type: 'id',
      value: "notify-message",
    },
  ],
};