import { useContext, useEffect, useState } from "react";
import { useContactCareProgramContext } from "../../../ContactCareProgram.context";
import { ISendClaimCondition } from "./interface";
import { SEND_CLAIM_CONDITION_CODE_MAP, SEND_CLAIM_CONDITION_CODES, SEND_CLAIM_CONDITION_MAP } from "./SendClaimConditionConsts";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../../../../constants/Configs";
import { CHECK_REQUIRED_STEPS_COMPLETED_WITH_EXCLUSIONS, GET_CARE_PROGRAM_CONSENT } from "../../../../../../../services/ContactCareProgram/ContactCareProgram";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CARE_PROGRAM_STEP_TYPE } from "../../../../../../common/MemebersView/constant";
import { GET_CARE_TEAM_MEMBERS_BY_MEMBER_TYPE } from "../../../../../../../services/CareTeam/CareTeamQueries";
import { BILLING_ACTIVITY_STATUS, CARE_TEAM_MEMBER_TYPE, MLOV_CATEGORY } from "../../../../../../../constants/MlovConst";
import { GET_EXTERNAL_USER_MAPS_BY_USER_ID } from "../../../../../../../services/Location/UserPracticeLocationQueries";
import { getAllPatientConditions, getInsuranceFieldData, getPractitioners, getPractitionersByConditions } from "../../../../../../../services/CommonService/AidBoxService";
import { usePersonOmniViewContext } from "../../../../../../PersonOmniView/PersonOmniView.context";
import { getEHRName } from "../../../../../../../utils/capabilityUtils";
import { EHRName } from "../../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces";
import { getAccountId } from "../../../../../../../utils/commonUtils";
import { FHIR_CODE_SYSTEMS } from "../../../../../../../constants/FhirConstant";
import { getMlovIdFromCode, getMlovListFromCategory } from "../../../../../../../utils/mlovUtils";
import { CommonDataContext } from "../../../../../../../context/CommonDataContext";
import { GET_CARE_PROGRAM_BILLABLE_CODES } from "../../../../../CareManagementBilling/BillingQueries";
import { CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE } from "../../../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst";

interface IExternalUserMap {
  userUuid: string;
  externalUserId: string;
  locationGroupId: string;
}

const CONDITION_STATUS = {
  INCOMPLETE: 'incomplete',
  LOADING: 'loading',
  COMPLETED: 'completed'
} as const;

export const useSendClaimToEhrCheck = () => {

  const contextData = useContext(CommonDataContext);
  const {formattedData} = usePersonOmniViewContext();
  const accountId = getAccountId();
  const patientId = formattedData?.patientId;
  const accountLocationUuid = formattedData?.accountLocationUuid || '';
  const ehrName = getEHRName(accountLocationUuid, '');

  const {state} = useContactCareProgramContext();
  const {contactCareProgramDetails} = state;
  const programTypeCode = contactCareProgramDetails?.payerCareProgram?.careProgramType?.code as string;

  // insert status as loading for all the steps
  const initialConditionState = {} as any;
  const conditionArray = new Set(SEND_CLAIM_CONDITION_MAP?.[programTypeCode] || SEND_CLAIM_CONDITION_MAP?.['DEFAULT'] || []);
  if (ehrName !== EHRName.ELATION) {
    conditionArray.add(SEND_CLAIM_CONDITION_CODE_MAP.ADD_PRIMARY_INSURANCE_DETAILS);
    conditionArray.add(SEND_CLAIM_CONDITION_CODE_MAP.SPECIFY_ICD_DIAGNOSIS);
  }

  conditionArray.forEach(condition => {

    initialConditionState[condition.code] = {
      ...condition,
      status: CONDITION_STATUS.LOADING
    }
  });

  const [sendClaimConditionsState, setSendClaimConditionsState] = useState<ISendClaimCondition>(initialConditionState as ISendClaimCondition)
  const [conditionCompletedCount, setConditionCompletedCount] = useState<number>(0);

  const contactId = state.contactCareProgramDetails?.contactId;

  const consentSubjectCode = CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT_CARE_PROGRAM

  const billableActivityStatusMlov = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CONTACT_CARE_PROGRAM_BILLABLE_ACTIVITY_STATUS) || [];
  const pendingStatusId = getMlovIdFromCode(billableActivityStatusMlov, BILLING_ACTIVITY_STATUS.PENDING);

  const formatPatientIdWithAccountPrefix = (patientId: string | number, accountId: string | number) => {
    return `${accountId}-${patientId}`;
  }

  const getState = (conditionResult: boolean): typeof CONDITION_STATUS[keyof typeof CONDITION_STATUS] => {
    return conditionResult ? CONDITION_STATUS.COMPLETED : CONDITION_STATUS.INCOMPLETE;
  }

  const dispatch = (code: string, status: typeof CONDITION_STATUS[keyof typeof CONDITION_STATUS]) => {

    setSendClaimConditionsState(prevState => ({
      ...prevState,
      [code]: {
        ...prevState[code],
        status
      }
    }));

    if (status === CONDITION_STATUS.COMPLETED) {
      setConditionCompletedCount(prevState => prevState + 1);
    }
  }
  const [checkRequiredStepsCompletedWithExclusions] = useMutation(
    CHECK_REQUIRED_STEPS_COMPLETED_WITH_EXCLUSIONS,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );

  const [getCareTeamMembersByMemberType] = useLazyQuery(GET_CARE_TEAM_MEMBERS_BY_MEMBER_TYPE, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getExternalUserMapsByUserUuid] = useLazyQuery(GET_EXTERNAL_USER_MAPS_BY_USER_ID, {
    fetchPolicy: 'no-cache',
  });

  const [getBillAttributeAndCodes] = useLazyQuery(GET_CARE_PROGRAM_BILLABLE_CODES, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [getCareProgramConsent] = useLazyQuery(GET_CARE_PROGRAM_CONSENT, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const contactCareProgramId = contactCareProgramDetails?.id;

  const checkIfCareProgramConsentIsPresent = async () => {
    try {
      const response = await getCareProgramConsent({
        variables: {
          subjectId: contactCareProgramId,
          subjectTypeCode: consentSubjectCode
        }
      });
      return !!response.data.careProgramConsents?.[0]?.hasConsent;
    } catch (error) {
      return false;
    }
  }

  const checkIfLocationIdInExternalMaps = (externalUserMaps: IExternalUserMap[], supervisingProviderIdMaps: {resource: {id: string}}[]) => {
    const externalUserIds = externalUserMaps.map(map => map.externalUserId);
    const supervisingProviderIds = supervisingProviderIdMaps.map(provider => provider.resource.id);
    return externalUserIds.some(userId => supervisingProviderIds.includes(userId));
  }


  const checkIfBillableActivityIsPresent = async () => {
    try {
      const response = await getBillAttributeAndCodes({
        variables: {
          where: {
            isDeleted: {
              _eq: false
            },
            statusId: {
              _in: [pendingStatusId]
            },
            contactCareProgramId: {
              _eq: state.contactCareProgramDetails?.id
            }
          }
        }
      });
      return response.data.contactCareProgramBillableActivities.length > 0;
    } catch (error) {
      return false;
    }
  }
  const checkIfBillingPractionerHasExternalMap = async (userId: string) => {
    if (!userId) return false;
    try {
      const response = await getExternalUserMapsByUserUuid({
        variables: {
          userUuid: userId
        }
      });
      let isValid = response.data.externalUserMaps.length > 0;
      if (isValid && ehrName===EHRName.ATHENA) {
        const externalUserMaps = response.data.externalUserMaps as IExternalUserMap[];

        // extra check for athena
        const practitioners = await getPractitionersByConditions({
          conditions: [{key: 'supervising-provider', value: 'true'}],
          locationId: accountLocationUuid
        });
        isValid = checkIfLocationIdInExternalMaps(externalUserMaps, practitioners.data.entry);
        return isValid;

      } else {
        return isValid;
      }
    } catch (error) {
      return false;
    }
  }

  const checkIfIcd10CodeIsPresent = async () => {
    try {
      const response = await getAllPatientConditions(formatPatientIdWithAccountPrefix(patientId, accountId), accountLocationUuid);
      const isValid = response.data.entry.some((entry: any) => entry.resource.code?.coding?.some((coding: any) => coding.system === FHIR_CODE_SYSTEMS.ICD_10));
      return isValid;
    } catch (error) {
      return false;
    }
  }

  const checkIfAllMandatoryStepsExceptBillingAreCompleted = async () => {
    try {
      const response = await checkRequiredStepsCompletedWithExclusions({
        variables: {
        id: contactCareProgramId,
        excludeStepTypeCodes: [CARE_PROGRAM_STEP_TYPE.BILLING]
      }
    });
      return !!response.data.areAllRequiredStepsCompleted?.areAllRequiredStepsCompleted as boolean;
    } catch (error) {

      return false;
    }
  }

  const checkIfInsuranceFieldDataIsPresent = async () => {
    try {
      const response = await getInsuranceFieldData(patientId, accountLocationUuid);
      return response.data.entry.length > 0;
    } catch (error) {
      return false;
    }
  }

  const checkIfBillingPractitionerIsAdded = async () => {
    try {
      const response = await getCareTeamMembersByMemberType({
        variables: {
          contactId,
          memberType: CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER
        }
      });
      const isValid = response.data.careTeams.length > 0;
      if (isValid) {
        const userId = response.data.careTeams?.[0]?.careTeamMemberType?.[0]?.userId as string;
        return userId;
      }
      return isValid;
    } catch (error) {
      return false;
    }
  }

  const executeAllConditions = async () => {
    checkIfAllMandatoryStepsExceptBillingAreCompleted().then((isAllCompleted) => {
      dispatch(SEND_CLAIM_CONDITION_CODES.COMPLETE_MANDATORY_STEPS, getState(isAllCompleted));
    });

    checkIfBillingPractitionerIsAdded().then((userId) => {
      dispatch(SEND_CLAIM_CONDITION_CODES.ADD_BILLING_PRACTITIONER, getState(!!userId));

      if (!userId) {
        dispatch(SEND_CLAIM_CONDITION_CODES.ADD_EXTERNAL_BILLING_PRACTITIONER, CONDITION_STATUS.INCOMPLETE);
      } else {
        checkIfBillingPractionerHasExternalMap(userId).then((isBillingPractionerHasExternalMap) => {
          dispatch(SEND_CLAIM_CONDITION_CODES.ADD_EXTERNAL_BILLING_PRACTITIONER, getState(isBillingPractionerHasExternalMap));
        });
      }
    });
    if (SEND_CLAIM_CONDITION_CODES.ADD_PRIMARY_INSURANCE_DETAILS in sendClaimConditionsState) {
      checkIfInsuranceFieldDataIsPresent().then((isInsuranceFieldDataPresent) => {
        dispatch(SEND_CLAIM_CONDITION_CODES.ADD_PRIMARY_INSURANCE_DETAILS, getState(isInsuranceFieldDataPresent));
      });
    }

    if (SEND_CLAIM_CONDITION_CODES.SPECIFY_ICD_DIAGNOSIS in sendClaimConditionsState) {
      checkIfIcd10CodeIsPresent().then((isIcd10CodePresent) => {
        dispatch(SEND_CLAIM_CONDITION_CODES.SPECIFY_ICD_DIAGNOSIS, getState(isIcd10CodePresent));
      });
    }

    if (SEND_CLAIM_CONDITION_CODES.ADD_TOTAL_BILLABLE_TIME in sendClaimConditionsState) {
      checkIfBillableActivityIsPresent().then((isBillableActivityPresent) => {
        dispatch(SEND_CLAIM_CONDITION_CODES.ADD_TOTAL_BILLABLE_TIME, getState(isBillableActivityPresent));
      });
    }

    if(SEND_CLAIM_CONDITION_CODES.GIVE_CARE_PROGRAM_CONSENT in sendClaimConditionsState) {
      checkIfCareProgramConsentIsPresent().then((isCareProgramConsentPresent) => {
        dispatch(SEND_CLAIM_CONDITION_CODES.GIVE_CARE_PROGRAM_CONSENT, getState(isCareProgramConsentPresent));
      });
    }
  }


  useEffect(() => {
    executeAllConditions();
  }, []);

  return {
    sendClaimConditionsState,
    areAllConditionsComplete: conditionCompletedCount >= conditionArray?.size,
  }
}
