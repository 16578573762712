import { useQuery } from '@apollo/client';
import { Select } from 'antd';

import { HStack, Radio, Skeleton, Text, View } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import Feather from 'react-native-vector-icons/Feather';
import { IMlov, ISearchFieldProps, IUser } from '../../../Interfaces';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { MLOV_CATEGORY, MLOV_CODES, USER_ROLE_CODES } from '../../../constants/MlovConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { ScheduleEventQueries, UserQueries } from '../../../services';
import { Colors } from '../../../styles';
import { getAccountUUID, getBooleanFeatureFlag } from '../../../utils/commonUtils';
import {
  getMlovListFromCategory,
  getMlovValueFromId
} from '../../../utils/mlovUtils';
import { AppointmentAvailabilityCode } from '../../RightSideContainer/AccountSettings/AppointmentTypes/constants';
import { IAppointmentType, ILocationGroupData } from '../CalendarWidget/BookingWorkflows/BookAppointment/Interfaces';
import { USER_SELECTION_CODE } from '../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentConstant';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';
import { GET_LOCATION_GROUPS } from '../../../services/Appointment/AppointmentQueries';
import { filter } from 'lodash';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { WorkflowContext } from '../../../context/WorkflowContext';
import useAddRoleAndPermission from '../../RightSideContainer/UserAccess/CustomHook/useCreateRoleAndPermissions';
const { Option } = Select;

export interface IAttachAppointmentDetails {
  appointmentTypes: IAppointmentType[];
  selectedAppointment: IAppointmentType;
  accountUsers: IUser[];
  selectedUsers: IUser[];
  userIds: string[];
  appointmentRoleTypeUsers: IUser[];
  selectedUserType: string;
  infoMessage: string;
  locationGroups: {
    id: string;
    groupName: string;
  }[];
  filterAppointmentTypes: IAppointmentType[];
  filterUsers: IUser[];
  selectedLocationGroupId: string | undefined;
}

const AppointmentTypeSearch = (props: ISearchFieldProps) => {
  const { value, isShowError, onChange, accountLocationUUID, singleToLocationGroupId } = props;
  const accountId = getAccountUUID();
  const commonContextData = useContext(CommonDataContext);
  const isHideInternalLabel = !!props?.isHideInternalLabel;
  const isHideUser = !!props?.isHideUser;
  const optional = !!props?.optional;
  const CARE_TEAM_BASED_APPOINTMENT_MESSAGE = 'Selected appointment type can only be scheduled with the member\'s care team';
  const PROVIDER_BASED_APPOINTMENT_MESSAGE = 'Selected appointment type has users configured which cannot be changed';
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonContextData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const workflowContext = useContext(WorkflowContext);
  const locationIdListAutomation = workflowContext?.locationIds || [];
  const commonData = useContext(CommonDataContext);
  const accountLocationListWithEHR = commonData?.accountLocationListWithEHR || [];
  const locationGroupIdListAutomation = accountLocationListWithEHR
  ?.filter((item) => locationIdListAutomation.includes(item.uuid)) 
  .map((item) => item.locationGroupId) 
  .filter((locationGroupId, index, self) => locationGroupId && self.indexOf(locationGroupId) === index); 
  const headers = {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  };
  const locationGroupIdListByPatientContext = accountLocationListWithEHR
  ?.filter((item) => item.uuid === accountLocationUUID) 
  .map((item) => item.locationGroupId) 
  .filter((locationGroupId, index, self) => locationGroupId && self.indexOf(locationGroupId) === index); 
  const userRolesMlovs =
    getMlovListFromCategory(commonContextData.MLOV, MLOV_CATEGORY.USER_ROLES) ||
    [];

  const [accountUserRoles, setAccountUserRoles] = useState<
    {value: string; label: string}[]
  >([]);

  const {getAccountRoles} = useAddRoleAndPermission({
    fetchRoleCategory: [
      MLOV_CATEGORY.USER_ROLES,
      MLOV_CATEGORY.GLOBAL_USER_ROLES,
    ],
  });

  const getAccountRoleValueFromId = (accountRoles: {value: string; label: string}[], roleId: string) => {
    const role = accountRoles.find(accountRole => accountRole.value === roleId);
    return role ? role.label : undefined;
  }

  const fetchAllAccountRole = async () => {
    const accountRolesResponse = await getAccountRoles();
    setAccountUserRoles(
      (accountRolesResponse || []).map(
        (accountRole: {id: string; roleName: string}) => {
          return {
            value: accountRole?.id,
            label: accountRole.roleName,
          };
        }
      )
    );
  };

  useEffect(() => {
    fetchAllAccountRole();
  }, []);

  const [appointmentData, setAppointmentData] =
    useState<IAttachAppointmentDetails>({
      appointmentTypes: [],
      selectedAppointment: value?.id ? {id: value?.id, eventName: value?.eventName} as IAppointmentType :  {} as IAppointmentType,
      accountUsers: [],
      selectedUsers: [],
      userIds: [],
      appointmentRoleTypeUsers: [],
      selectedUserType: value?.userSelection ?? USER_SELECTION_CODE.ALL_USER ,
      infoMessage: '',
      locationGroups: [],
      filterAppointmentTypes: [],
      filterUsers: [],
      selectedLocationGroupId: undefined
    });

  const getDefaultAppointmentDetails = (
    appointmentTypeList: IAppointmentType[],
    locationGroupId?: string
  ) => {
    const appointmentTypeId = value?.id;
    const appointmentUsers: string | undefined = value?.appointmentUsers;
    let appointmentType: IAppointmentType = {} as IAppointmentType;
    let userIdList: string[] = [];

    const filterAppointmentTypes = (appointmentTypeList || []).filter((appointment) => {
      return appointment.locationGroupId === locationGroupId;
    });
    if (appointmentTypeId) {
      appointmentType = (filterAppointmentTypes || []).find((appointment) => {
        return appointment?.id === appointmentTypeId;
      }) || ({} as IAppointmentType);
    } else {
      appointmentType = filterAppointmentTypes?.length ? filterAppointmentTypes[0] : ({} as IAppointmentType);
    }
    if (appointmentUsers) {
      userIdList = appointmentUsers?.split(',');
    } else if (appointmentType) {
      userIdList = getUserIds(filterUniqueUsers(getAccountUserOfSelectedRole(appointmentType)))
    }
    return {
      appointmentType: appointmentType,
      userIdList: userIdList,
      filterAppointmentTypes: filterAppointmentTypes
    };
  };
  const filterUniqueUsers = (users: IUser[]) => {
    const uniqueUsers: IUser[] = [];
    users.forEach((user) => {
      if (!uniqueUsers.find((uniqueUser) => uniqueUser.id === user.id)) {
        const isAnyActive = (user?.accountUsers||[]).some((accountUser)=>{ return accountUser?.isActive})
        if(isAnyActive){
          uniqueUsers.push(user);
        }
      }
    });
    return uniqueUsers;
  }
  const setDefaultAppointmentType = (
    appointmentTypeList: IAppointmentType[],
    locationGroupId?: string
  ) => {
    const defaultAppointmentData = getDefaultAppointmentDetails(appointmentTypeList, locationGroupId);
    let userSelectionType = USER_SELECTION_CODE.CUSTOM;
    userSelectionType = appointmentData?.selectedUserType ?? USER_SELECTION_CODE.ALL_USER;

    if (
      defaultAppointmentData?.appointmentType?.id &&
      (defaultAppointmentData?.userIdList?.length || defaultAppointmentData?.appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM)
    ) {
      const appointmentType = defaultAppointmentData?.appointmentType;
      const isCareTeamBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM;
      const isProviderBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER;
      if (isCareTeamBased || isProviderBased) {
        handleCustomAppointmentTypes(appointmentType, appointmentTypeList, locationGroupId, defaultAppointmentData?.filterAppointmentTypes);
        return;
      } else if (appointmentType?.appointmentTypeGroup?.length) {
        const userList: IUser[] = getAccountUserOfSelectedRole(appointmentType);
        // if (userList?.length && value?.userSelection != 'CUSTOM') {
        //   userSelectionType = USER_SELECTION_CODE.ALL_USER;
        // }
        setAppointmentData((prev) => {
          return {
            ...prev,
            selectedAppointment: appointmentType,
            appointmentRoleTypeUsers: filterUniqueUsers(userList),
            userIds: defaultAppointmentData?.userIdList,
            selectedUserType: userSelectionType,
            infoMessage: '',
            appointmentTypes: appointmentTypeList,
            filterAppointmentTypes: defaultAppointmentData?.filterAppointmentTypes,
            selectedLocationGroupId: singleToLocationGroupId ? singleToLocationGroupId : locationGroupId
          };
        });
        handleCallback(
          appointmentData?.selectedAppointment,
          defaultAppointmentData?.userIdList,
          userSelectionType
        );
        return;
      }
    } else {
      setAppointmentData((prev) => {
        return {
          ...prev,
          selectedAppointment: prev?.selectedAppointment,
          appointmentRoleTypeUsers: [],
          userIds: [],
          selectedUserType: userSelectionType,
          infoMessage: '',
          appointmentTypes: appointmentTypeList,
          filterAppointmentTypes: defaultAppointmentData?.filterAppointmentTypes || [],
          locationGroupId: locationGroupId
        };
      });
      handleCallback(
        appointmentData?.selectedAppointment,
        undefined,
        userSelectionType
      );
    }
  };

  const calendarUsersData = useQuery(
    UserQueries.GET_USERS_FOR_CALENDAR_WITH_LOCATION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        accountId: accountId,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
      onCompleted: (data: any) => {
        setAppointmentData((prev) => {
          return {
            ...prev,
            accountUsers: data?.users,
          };
        });
      },
      onError: (error) => {

      },
    }
  );

  const locationsGroupData = useQuery(
    GET_LOCATION_GROUPS,
    {
      skip: calendarUsersData.loading,
      fetchPolicy: 'no-cache',
      onCompleted: (data: ILocationGroupData) => {
        if (!data || !data.locationGroups) {
          return;
        }      
        let resultLocationGroups = data.locationGroups;     
        if (props?.isAutomationView && isMsoEnabled) {
          resultLocationGroups = data.locationGroups.filter((item) => 
            locationGroupIdListAutomation?.includes(item?.id)
          );
        } else if (accountLocationUUID && isMultiTenancyEnabled) {
          resultLocationGroups = data.locationGroups.filter((item) => 
            locationGroupIdListByPatientContext?.includes(item?.id)
          );
        }      
        setAppointmentData((prev) => ({
          ...prev,
          locationGroups: resultLocationGroups,
        }));
      },
      onError(error) {
        setAppointmentData((prev) => {
          return {
            ...prev,
            locationGroups: []
          };
        });
      },
    }
  );

  const getLocationGroupIdMatchWithAppointmentType = (appointmentTypeList: IAppointmentType[]): string | undefined => {
    const locationGroup = (appointmentData?.locationGroups || []).find((locationGroup) => {
      const locationGroupId = locationGroup?.id;
      const isMatchWithLocationGroup = appointmentTypeList.some((appointmentType) => {
        return appointmentType?.locationGroupId === locationGroupId;
      });
      return isMatchWithLocationGroup;
    });
    return locationGroup?.id;
  };


  const appointmentTypeAPIData = useQuery(
    props.isShowAllAppointmentType ?  ScheduleEventQueries.GET_ALL_APPOINTMENT_TYPES : ScheduleEventQueries.GET_ALL_PATIENT_FACING_APPOINTMENT_TYPES ,
    {
      skip: calendarUsersData.loading || locationsGroupData.loading,
      variables: {
        searchString: `%%`,
        categoryCodes: [MLOV_CODES.ONE_ON_ONE_APPOINTMENT],
      },
      fetchPolicy: 'no-cache',
      ...headers,
      onCompleted: (data: any) => {
        let locationGroupId = singleToLocationGroupId ? singleToLocationGroupId : value?.locationGroupId || undefined;
        const appointmentTypeId = value?.id || undefined;
        if (appointmentTypeId && !locationGroupId) {
           (data?.appointmentTypes || []).forEach((appointmentType: any) => {
             if (appointmentType?.id === appointmentTypeId) {
               locationGroupId = appointmentType?.locationGroupId;
             }
           });
        }
        if (!locationGroupId) {
          locationGroupId = getLocationGroupIdMatchWithAppointmentType(data?.appointmentTypes);
        }
        setDefaultAppointmentType(data?.appointmentTypes, locationGroupId);
      },
      onError(error) {

      },
    }
  );

  const getUserIds = (userList: IUser[]) => {
    const userIds: string[] = [];
    (userList || []).forEach((user) => {
      userIds.push(user.uuid);
    });
    return userIds;
  };

  const handleCustomAppointmentTypes = (
    appointmentType: IAppointmentType | undefined,
    appointmentTypes: IAppointmentType[],
    locationGroupId?: string,
    filterAppointmentTypes?: IAppointmentType[]
  ) => {
    const isCareTeamBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM;
    const isProviderBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER;
    if (isCareTeamBased) {
      setAppointmentData((prev) => {
        return {
          ...prev,
          selectedAppointment: appointmentType,
          appointmentRoleTypeUsers: [],
          userIds: [],
          infoMessage: CARE_TEAM_BASED_APPOINTMENT_MESSAGE,
          locationGroupId: locationGroupId,
          filterAppointmentTypes: filterAppointmentTypes || [],
          appointmentTypes: appointmentTypes || []
        };
      });
      handleCallback(
        appointmentType,
        [],
        USER_SELECTION_CODE.CUSTOM
      );
    } else if (isProviderBased) {
      const userIds = appointmentType.userPool?.userPoolUsers?.map(item => item.userId) || [];
      setAppointmentData((prev) => {
        return {
          ...prev,
          selectedAppointment: appointmentType,
          appointmentRoleTypeUsers: appointmentData.accountUsers.filter(item => userIds.includes(item.uuid)),
          userIds: userIds,
          infoMessage: PROVIDER_BASED_APPOINTMENT_MESSAGE,
          locationGroupId: locationGroupId,
          filterAppointmentTypes: filterAppointmentTypes || [],
          appointmentTypes: appointmentTypes || []
        };
      });
      handleCallback(
        appointmentType,
        userIds,
        USER_SELECTION_CODE.CUSTOM
      );
    }
  }

  const onAppointmentTypeChange = (
    appointmentType: IAppointmentType|undefined,
    filterAppointmentTypes?: IAppointmentType[],
    locationGroupId?: string
  ) => {
    const isCareTeamBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM;
    const isProviderBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER;

    if (isCareTeamBased || isProviderBased) {
      handleCustomAppointmentTypes(
        appointmentType,
        appointmentData.appointmentTypes,
        locationGroupId || appointmentData.selectedLocationGroupId,
        filterAppointmentTypes || appointmentData.filterAppointmentTypes
      );
    } else if (appointmentType?.appointmentTypeGroup?.length) {
      const userList: IUser[] = getAccountUserOfSelectedRole(appointmentType);

      const userIds =
        appointmentData.selectedUserType === USER_SELECTION_CODE.ALL_USER
          ? getUserIds(userList)
          : [];
      setAppointmentData((prev) => {
        return {
          ...prev,
          selectedAppointment: appointmentType,
          appointmentRoleTypeUsers: filterUniqueUsers(userList),
          userIds,
          infoMessage: '',
        };
      });
      handleCallback(
        appointmentType,
        userIds,
        appointmentData.selectedUserType
      );
    } else {
      handleCallback(
        undefined,
        undefined,
        appointmentData.selectedUserType
      );
    }
  };
  const getNestedUserRoles = (userRoles: any[]) => {
    if (userRoles?.length) {
      const data = userRoles.map((role) => role.userRole?.id);
      return data;
    }
    return [];
  };
  const getAccountUserRoleIds = (userRoles: {value: string; label: string}[]) => {
    if (userRoles?.length) {
      const data = userRoles.map((role) => role.value);
      return data;
    }
    return [];
  };
  const getAccountUserOfSelectedRole = (appointmentType: IAppointmentType) => {
    const locationGroupId = appointmentType?.locationGroupId;
    const roleIds = (appointmentType?.appointmentTypeGroup || []).map(
      (group) => group.accountRoleId
    );

    const userList: IUser[] = [];
    const accountRoleIds = getAccountUserRoleIds(accountUserRoles);
    roleIds.forEach((roleId) => {
      appointmentData.accountUsers.forEach((user) => {
        const isMatchWithLocationGroup = (user?.userPracticeLocations || [])?.some((userPracticeLocation) => {
          return userPracticeLocation?.accountLocation?.locationGroupId === locationGroupId;
        });
        const filteredUserRoles = (user?.userRoles || []).filter(userRole => {
          const userRoleId = userRole?.userRole?.id;
          return accountRoleIds.includes(userRoleId as string);
        });
        const userRoleIds = getNestedUserRoles(filteredUserRoles);
        if (userRoleIds.includes(roleId as string) && isMatchWithLocationGroup) {
          userList.push(user);
        }
      });
    });
    return userList;

  };

  const compareUserRoleWithAppointmentRole = (
    user: IUser,
    allRoles: IMlov[]
  ) => {
    // ========= FUTURE REFERENCE =========
    // return (user.userRoles || []).some((selectedRole: any) => {
    //   const userRoleId = selectedRole.userRole?.userRole?.id;
    //   return role.some((appointmentRole) => {
    //     return userRoleId === appointmentRole.id;
    //   });
    // });
    return (user.userRoles || []).filter((selectedRole: any) => {
      const userRoleId = selectedRole.userRole?.userRole?.id;

      const roleIdsList = allRoles.map((appointmentRole) => appointmentRole.id);

      return roleIdsList.includes(userRoleId);
    });
  };

  const componentLoading = appointmentTypeAPIData.loading || calendarUsersData.loading || locationsGroupData.loading;

  const onUserSelectHandler = (userIds: any) => {
    setAppointmentData((prev) => {
      return {
        ...prev,
        userIds,
      };
    });
    handleCallback(
      appointmentData.selectedAppointment,
      userIds,
      appointmentData.selectedUserType
    );
  };

  const handleUserSelectionType = (userSelectionType: string) => {
    const userList =
      userSelectionType === USER_SELECTION_CODE.CUSTOM
        ? []
        : getAccountUserOfSelectedRole(appointmentData.selectedAppointment);
    const userIds: any[] = [];
    if (userList?.length) {
      userList.forEach((user) => {
        userIds.push(user.uuid);
      });
    }
    setAppointmentData((prev) => {
      return {
        ...prev,
        userIds,
        selectedUserType: userSelectionType,
      };
    });
    handleCallback(
      appointmentData.selectedAppointment,
      userIds,
      userSelectionType
    );
  };

  const handleCallback = (
    appointmentType: IAppointmentType| undefined,
    userIds?: string[] | undefined,
    userSelectionType?: string | undefined
  ) => {
    const isCareTeamBased = appointmentType?.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM;
    if (!appointmentType?.id || (!userIds?.length && !isCareTeamBased && !isHideUser)) {
      if (appointmentType?.id) {
        onChange({
          id: appointmentType?.id,
          eventName: appointmentType?.eventName,
          userSelection: userSelectionType
        });
      }
      if(!appointmentType){
        setAppointmentData((prev) => {
          return {
            ...prev,
            selectedAppointment: {} as IAppointmentType
          }
        })
      }
      return;
    }
    let roleDetails: any = {};
    if (
      appointmentType?.appointmentTypeGroup?.length &&
      appointmentType?.appointmentTypeGroup[0]?.accountRoleId
    ) {
      const roleId = appointmentType?.appointmentTypeGroup[0].accountRoleId;
      roleDetails = {
        roleId,
        roleName: getAccountRoleValueFromId(accountUserRoles, roleId),
      };
    }
    const responseValue = {
      id: appointmentType?.id,
      eventName: appointmentType?.eventName,
      description: appointmentType?.description,
      appointmentUsers: userIds?.toString(),
      userSelection: userSelectionType,
      roleId: roleDetails?.roleId || undefined,
      roleName: roleDetails?.roleName || undefined,
      availabilityTypeCode: appointmentType?.availabilityTypeCode
    };
    onChange(responseValue);
  };

  const isCareTeamBasedAppointmentType = appointmentData.selectedAppointment.availabilityTypeCode === AppointmentAvailabilityCode.CARE_TEAM;
  const isProviderBasedAppointmentType = appointmentData.selectedAppointment.availabilityTypeCode === AppointmentAvailabilityCode.PROVIDER;
  const isLocationGroupDisabled = appointmentData?.locationGroups?.length == 1 || appointmentData?.locationGroups?.length == 0 || !!singleToLocationGroupId;

  return (
    <>
      {componentLoading && (
        <View>
          <Skeleton rounded="md" />
        </View>
      )}
      {!componentLoading && (
        <>
          <HStack>
            { !isHideInternalLabel && (appointmentData?.locationGroups?.length !== 1 ) && isMultiTenancyEnabled && 
              <HStack flex={2.4}>
                <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                  {'EHR Instance'}
                  {!optional && <Text color="error.500">*</Text>}
                </Text>
              </HStack>
            }
            {isMultiTenancyEnabled && (appointmentData?.locationGroups?.length !== 1 ) &&
            <HStack flex={7} space={2}>
              <View marginY={1} flex={1}>
                <Select
                  allowClear={false}
                  value={appointmentData?.selectedLocationGroupId}
                  showSearch={true}
                  disabled={isLocationGroupDisabled}
                  placeholder={'Select EHR Instance'}
                  status={props.isShowError && !appointmentData?.selectedLocationGroupId ? 'error' : ''}
                  filterOption={(input, option: any) => {
                    return (
                      option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={(itemValue) => {
                    // set selected location group and appointment types
                    const selectedLocationGroupId = itemValue;
                    let selectedAppointmentType: IAppointmentType | null = null;
                    const filterAppointmentTypes = appointmentData.appointmentTypes.filter((appointmentType) => {
                      return appointmentType.locationGroupId === selectedLocationGroupId;
                    });
                    if (filterAppointmentTypes.length > 0) {
                      selectedAppointmentType = filterAppointmentTypes[0];
                    }
                    setAppointmentData((prev) => {
                      return {
                        ...prev,
                        selectedLocationGroupId: selectedLocationGroupId,
                        filterAppointmentTypes: filterAppointmentTypes,
                        selectedAppointment: selectedAppointmentType || {} as IAppointmentType,
                      };
                    });
                    if (selectedAppointmentType) {
                      onAppointmentTypeChange(selectedAppointmentType, filterAppointmentTypes, selectedLocationGroupId);
                    } else {
                      onChange(undefined);
                    }
                  }}
                >
                  {(appointmentData?.locationGroups || []).map((locationGroup) => {
                    return (
                      <Option value={locationGroup.id} title={locationGroup.groupName} key={locationGroup.id}>
                        {locationGroup.groupName}
                      </Option>
                    );
                  })}

                </Select>
              </View>
            </HStack>
            }
          </HStack>
          <HStack>
           { !isHideInternalLabel &&  <HStack flex={2.4}>
              <Text size={'mdMedium'} color={Colors.FoldPixel.GRAY400} flex={2} marginTop={1}>
                {'Appointment Type'}
                {!optional && <Text color="error.500">*</Text>}
              </Text>
            </HStack>}
            <HStack flex={7}>
              <View marginY={1} flex={1}>
              <Select
                suffixIcon={ props.isAutomationView?
                  <CaretDownOutlined
                    style={{
                      color: Colors.FoldPixel.GRAY300
                    }}
                  /> :
                  <DownOutlined/>
                }
                allowClear={props.allowClearAppointmentType}
                onClear={() => {
                  onAppointmentTypeChange(undefined);
                }}
                value={appointmentData?.selectedAppointment?.id}
                showSearch={true}
                placeholder={'Select Appointment Type'}
                status={props.isShowError && !appointmentData?.selectedAppointment?.id ? 'error' : ''}
                filterOption={(input, option: any) => {
                  return (
                    option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={(itemValue) => {
                  let appointmentValue;
                  (appointmentData?.appointmentTypes || []).some((appointment) => {
                    if (appointment.id === itemValue) {
                      appointmentValue = appointment;
                    }
                  });

                  onAppointmentTypeChange(appointmentValue);

                }}
              >
                {(appointmentData?.filterAppointmentTypes || []).map((data) => {
                  return (
                    <Option value={data.id} title={data.eventName} key={data.id}>{data.eventName}</Option>
                  );
                })}

              </Select>
                {/* <ModalActionSelect
                  isRequired={!optional}
                  selectedValue={appointmentData?.selectedAppointment?.id || ''}
                  placeholder="Select Appointment Type"
                  endIcon={<CheckIcon size="5" />}
                  onValueChange={(value: string) => {
                    const selectedAppointment: IAppointmentType =
                      appointmentData.appointmentTypes.find((type) => {
                        return type.id === value;
                      }) || ({} as IAppointmentType);
                    onAppointmentTypeChange(selectedAppointment);
                  }}
                  data={appointmentData.appointmentTypes}
                  selectItemProps={{
                    key: 'id',
                    label: 'eventName',
                    value: 'id',
                  }}
                  customStyle={{ flex: 1 }}
                /> */}
              </View>
            </HStack>
          </HStack>
          {!!appointmentData.infoMessage && (
            <HStack>
              <HStack flex={2.4} />
              <HStack flex={7} alignItems={'flex-start'} space={2}>
                <Feather name="info" size={16} color={Colors.Custom.Gray500} style={{marginTop: 2}} />
                <Text fontSize={12} color={Colors.Custom.Gray500} width="full">
                  {appointmentData.infoMessage}
                </Text>
              </HStack>
            </HStack>
          )}
          { !isHideUser && !isCareTeamBasedAppointmentType && !isProviderBasedAppointmentType &&
          <View style={{ marginTop: 18 }}>
            <HStack>
              <HStack flex={2.4}>
                <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                  {'Users'}
                  {<Text color="error.500">*</Text>}
                </Text>
              </HStack>
              <HStack flex={7}>
                <View marginY={1} flex={1}>
                  <Radio.Group
                    name="userSelection"
                    defaultValue={USER_SELECTION_CODE.ALL_USER}
                    value={appointmentData.selectedUserType}
                    onChange={handleUserSelectionType}
                  >
                    <HStack space={2}>
                      <Radio value={USER_SELECTION_CODE.ALL_USER} marginY={1}>
                        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY300} marginLeft={2}>All</Text>
                      </Radio>

                      <Radio
                        value={USER_SELECTION_CODE.CUSTOM}
                        marginY={1}
                        marginLeft={1}
                      >
                        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY300} marginLeft={2}>Custom</Text>
                      </Radio>
                    </HStack>
                  </Radio.Group>
                </View>
              </HStack>
            </HStack>
          </View> }
          {(appointmentData.selectedUserType === USER_SELECTION_CODE.CUSTOM || isProviderBasedAppointmentType) && !isHideUser && !isCareTeamBasedAppointmentType && (
            <View style={{ marginTop: 18 }}>
              <HStack>
                <HStack flex={2.4}>
                  {isProviderBasedAppointmentType && (
                    <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                      {'Users'}
                      {<Text color="error.500">*</Text>}
                    </Text>
                  )}
                </HStack>
                <HStack flex={7}>
                  <View marginY={1} flex={1}>
                    <Select
                      suffixIcon={ props.isAutomationView?
                        <CaretDownOutlined
                          style={{
                            color: Colors.FoldPixel.GRAY300
                          }}
                        /> :
                        <DownOutlined/>
                      }
                      mode={props.allowUserMultiSelect ? 'multiple' : undefined}
                      disabled={!appointmentData?.selectedAppointment?.id || isProviderBasedAppointmentType}
                      value={
                        props.allowUserMultiSelect
                          ? appointmentData.userIds
                          : appointmentData.userIds?.length > 0
                            ? appointmentData.userIds[0]
                            : undefined
                      }
                      allowClear
                      status={isShowError && (!appointmentData?.userIds || !appointmentData?.userIds?.length) ? 'error' : ''}
                      style={{ width: '100%', height: 'auto' }}
                      placeholder="Please select user"
                      filterOption={(input: string, option: any) => {
                        return (option?.children || '').toLowerCase().includes((input||'').toLowerCase());
                      }}
                      onChange={(value) => {
                        const data = Array.isArray(value) ? value : [value];
                        setAppointmentData((prev) => {
                          return {
                            ...prev,
                            userIds: data,
                          };
                        });
                        onUserSelectHandler(data);
                      }}
                    >
                      {appointmentData.appointmentRoleTypeUsers.map(
                        (user: IUser) => {
                          return <Option key={user.uuid}>{user.name}</Option>;
                        }
                      )}
                    </Select>
                  </View>
                </HStack>
              </HStack>
            </View>
          )}
        </>
      )}
    </>
  );
};

export default AppointmentTypeSearch;
