import {Text, View} from 'native-base';
import {Colors} from '../../../../../styles';
import {isEmptyObject} from '../../../../../utils/commonUtils';
import {getSexAtBirthFromResponse} from '../../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateQueryUtils';
import {IObservation} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/interfaces';
import {Extension, ExtensionData, IVitals} from '../interfaces';
import {ISingleVisitHistory} from './VisitHistoryView/VisitHistoryView';
import { getFormattedVitalValue } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/AddOrUpdateVitalsHelper';
import { Vital, getVitalConfig } from '../../../../../utils/VitalUtils';
import { IVitalData } from '../../../../../Interfaces';
import { EXTENSION_URLS } from '../../PatientNotes/components/AddOrUpdateTemplate/constant';

export const getTypeForVisitHistory = (item: any) => {
  if (item.resource.type[0].text) {
    return item.resource.type[0].text;
  } else {
    return item.resource.type[0].coding[0].display;
  }
};

export const getDoctorForVisitHistory = (item: any) => {
  const resource = item.resource;
  const participantsArray = resource?.participant;
  const doctorType = participantsArray[0]?.type?.length
    ? participantsArray[0]?.type[0]?.text
    : undefined;
  return {
    doctor: participantsArray[0]?.individual?.display,
    doctorType: doctorType,
  };
};

export const getFormattedVisitHistory = (response: any) => {
  const data = response.entry;
  const formattedData: ISingleVisitHistory[] = [];
  if (data) {
    data.forEach((item: any) => {
      const resource = item?.resource;
      let reason = 'No reason found';
      let location = '--';
      let doctor = '--';
      let doctorType = '';

      if (resource['participant']) {
        doctor = getDoctorForVisitHistory(item).doctor;
        doctorType = getDoctorForVisitHistory(item).doctorType;
      }

      if (Object.keys(item.resource).includes('reasonCode')) {
        reason = item.resource?.reasonCode[0]?.coding[0]?.display;
      }
      if (resource['location']) {
        location = resource?.location[0]?.location?.display;
      }

      formattedData.push({
        start: item.resource.period.start,
        end: item.resource.period.end,
        reason: reason,
        type: getTypeForVisitHistory(item),
        doctor,
        doctorType,
        location: location,
        encounterId: resource.id,
      });
    });
  }
  return formattedData;
};

export const getFormattedSocialHistory = (response: any) => {
  const entry = response.data.entry;
  const formattedSocialHistory: any[] = [];
  if (response && entry) {
    entry.forEach((item: any) => {
      const valueString = item.resource.valueString;
      const code = item.resource.code.text;
      const issued = item.resource.issued;
      const status = item.resource.status;
      formattedSocialHistory.push({
        valueString,
        code,
        issued,
        status,
      });
    });
  }
  return formattedSocialHistory;
};

export const capitalize = (word: string) => {
  if (!word) {
    return '';
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getStatusElementByStatus = (status: string) => {
  let statusElement: JSX.Element = <></>;
  let bgColor: string | undefined = '';
  let fontColor: string | undefined = '';

  switch (status) {
    case 'active':
    case 'completed':
      bgColor = Colors.success['50'];
      fontColor = Colors.success['700'];
      statusElement = (
        <View
          style={{
            backgroundColor: bgColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 16,
            width: 'fit-content',
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <Text
            style={{
              color: fontColor,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            numberOfLines={1}
          >
            <View
              style={{
                height: 6,
                width: 6,
                borderRadius: 50,
                backgroundColor: fontColor,
                marginRight: 7,
              }}
            />
            {capitalize(status)}
          </Text>
        </View>
      );
      break;
    case 'inactive':
    case 'controlled':
      bgColor = Colors.Custom.Gray100;
      fontColor = Colors.Custom.Gray700;
      statusElement = (
        <View
          style={{
            backgroundColor: bgColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 16,
            width: 'fit-content',
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <Text style={{color: fontColor}}>{capitalize(status)}</Text>
        </View>
      );
      break;
    case 'resolved':
      bgColor = Colors.Custom.Gray100;
      fontColor = Colors.Custom.Gray700;
      statusElement = (
        <View
          style={{
            backgroundColor: bgColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 16,
            width: 'fit-content',
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <Text style={{color: fontColor}}>{capitalize(status)}</Text>
        </View>
      );
      break;
    case 'stopped':
      bgColor = Colors.Custom.Gray100;
      fontColor = Colors.Custom.Gray700;
      statusElement = (
        <View
          style={{
            backgroundColor: bgColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 16,
            width: 'fit-content',

            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <Text style={{color: fontColor}}>{capitalize(status)}</Text>
        </View>
      );
      break;
    default:
      bgColor = Colors.Custom.Gray100;
      fontColor = Colors.Custom.Gray700;
      statusElement = (
        <View style={{}}>
          <Text style={{color: fontColor}}>{capitalize(status || '--')}</Text>
        </View>
      );
      break;
  }

  return statusElement;
};

// const getCodeForExternalSystemAndCode() {
//   var system = {
//     "http://loinc.org" : {
//       "29463-7": "WEIGHT",
//       "59576-9": "BODY_MASS_INDEX",
//       "8480-6": "SYSTOLIC_BLOOD_PRESSURE",
//       "8462-4": "DIASTOLIC_BLOOD_PRESSURE_ACTIVE",
//       "3150-0": "INHALED_OXYGEN_CONCENTRATION",
//       "3151-8": "INHALED_OXYGEN_FLOW_RATE",
//       "56086-2": "WAIST_CIRCUMFERENCE",
//       "8310-5": "BODY_TEMPERATURE",
//       "85354-9": "BLOOD_PRESSURE",
//       "2708-6": "OXYGEN_SATURATION_ARTERIAL",
//       "59408-5": "OXYGEN_SATURATION",
//       "8302-2": "HEIGHT",
//       "8867-4": "PULSE",
//       "9279-1": "RESPIRATION_RATE",
//     },
//     "": {

//     }
//   }
// }

export const getFormattedVitalData = (data: IObservation[], vitalList: IVitalData[], startDate?:string, endDate?:string) => {
  const array: IVitals[] = [];
  data.forEach((entity: any) => {
    const documentDataData = entity?.extension?.find((ext: any) => ext.url === EXTENSION_URLS.documentDate);
    const documentData = documentDataData?.valueString || '';
    if(startDate){
      if(new Date(documentData || entity?.issued || entity.effectiveDateTime) < new Date(startDate)) {
        return;
      }
    }
    if(endDate){
      if(new Date(documentData || entity?.issued || entity.effectiveDateTime) > new Date(endDate)) {
        return;
      }
    }
    if (Object.keys(entity).includes('valueQuantity')) {
      const vitalConfig = getVitalConfig(
        entity?.code?.coding?.[0]?.code as Vital,
        vitalList
      );
      let value = getFormattedVitalValue(entity, vitalConfig, false, true)
      value = value?.toString();
      if (value) {
        array.push({
          date: documentData || entity.issued || entity.effectiveDateTime || entity.effectivePeriod?.end,
          text: entity.code?.coding[0]?.code || entity.code?.coding[0]?.display,
          value: value || '',
          unit: entity.valueQuantity.unit,
        });
      }
    }
    if (Object.keys(entity).includes('component')) {
      const componentArray = entity.component;
      componentArray.forEach((c: any) => {
        if (c.valueQuantity?.value || c.valueQuantity?.value === 0) {
          array.push({
            date: documentData || entity.issued || entity.effectiveDateTime || entity.effectivePeriod?.end,
            text: c.code?.coding[0]?.code,
            value: c.valueQuantity?.value,
            unit: c.valueQuantity?.unit,
            effectivePeriod: entity.effectivePeriod,
            subtext: c?.code?.text,
          });
        }
      });
    }
  });
  return array;
};

export const groupVitalDataByVital = (vitalData: IVitals[]) => {
  return vitalData?.reduce((group: any, currentObject) => {
    const {text} = currentObject;
    group[text] = group[text] ?? [];
    group[text].push(currentObject);
    return group;
  }, {});
};

export const getDataByVital = (vital: string, groupObject: any) => {
  return groupObject[vital] || [];
};

export const sortByDate = (array: IVitals[]) => {
  return (
    array &&
    array.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    })
  );
};

export const getVitalDataArray = (
  vitalString: string,
  vitalData: IVitals[]
) => {
  return sortByDate(
    getDataByVital(vitalString, groupVitalDataByVital(vitalData))
  );
};

export const getFormattedDiagnosticReport = (response: any) => {
  const data = response.data.entry;
  const formattedData: any[] = [];
  if (data) {
    data.forEach((item: any) => {
      const entity = item.resource;
      let text = entity.code.text;
      const coding = entity.code.coding;
      if (coding) {
        if (coding[0].system && coding[0]?.system?.includes('loinc')) {
          const code = coding[0].code;
          text += ` (LOINC: ${code})`;
        }
      }
      if (!entity.result) {
        return;
      }

      formattedData.push({
        date: entity.issued,
        text: text,
        result: entity.result.map(
          (item: {display: string; reference: string; id?: string}) => {
            return {
              display: item.display,
              reference: item.id
                ? item.id
                : item.reference.split('/')[
                    item.reference.split('/').length - 1
                  ],
            };
          }
        ),
      });
    });
  }

  return formattedData.sort(function (a, b) {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });
};

export const getFormattedPatientDetails = (data: any) => {
  let city = '--';
  if (data.address) {
    city = data?.address[0]?.city;
  }
  const birthDate = data?.birthDate;

  const telecomArray = data?.telecom;
  const bloodGroup = data?.bloodGroup;

  let phone = '--';
  telecomArray.forEach((item: any) => {
    if (item.system === 'phone') {
      phone = item.value;
    }
  });

  const age =
    new Date(Date.now()).getFullYear() - new Date(birthDate).getFullYear();

  const name = data?.name;
  const firstName = name[0]?.given[0];
  const lastName = name[0]?.family;
  const fullName = `${firstName} ${lastName}`;
  const sexAtBirth = getSexAtBirthFromResponse(data);

  return {
    fullName,
    phone,
    birthDate,
    age,
    city,
    sexAtBirth,
    bloodGroup
  };
};

export const getMedicationFormattedTableData = (listData: any) => {
  const tempFormattedList: any = [];
  listData.map((item: any) => {
    let name = item?.medication?.CodeableConcept?.text;
    const codingArray = item?.medication?.CodeableConcept?.coding;
    if (codingArray) {
      if (codingArray[0].system.includes('rxnorm')) {
        const code = codingArray[0].code;
        name += ` (RXNORM: ${code})`;
      }
    }

    const tempObj = {
      id: item.id,
      name: name,
      date: item.authoredOn,
      status: item.status,
    };
    tempFormattedList.push(tempObj);
  });
  return tempFormattedList;
};

export const getExtensionData = (extension: Extension[]): ExtensionData => {
  if (!extension || extension?.length === 0) {
    return {
      ethnicity: '',
      sexualOrientation: '',
      race: '',
      created: '',
    };
  }
  return {
    ethnicity: getEthnicity(extension),
    race: getRace(extension),
    sexualOrientation: getSexualOrientation(extension),
    created: getCreatedDate(extension),
  };
};

const getEthnicity = (extension: Extension[]) => {
  const ethnicityObj = extension.find((item) =>
    item.url?.includes('us-core-ethnicity')
  );
  if (isEmptyObject(ethnicityObj)) {
    return '';
  }
  if (ethnicityObj?.extension) {
    const result = ethnicityObj.extension.find((item) => item.url == 'text');
    return result?.valueString || '';
  }
  return ethnicityObj?.valueString || '';
};

const getRace = (extension: Extension[]) => {
  const raceObject = extension.find((item) => item.url?.includes('race'));
  if (isEmptyObject(raceObject)) {
    return '';
  }
  if (raceObject?.extension) {
    const result = raceObject.extension.find((item) => item.url == 'text');
    return result?.valueString || '';
  }
  return raceObject?.valueString || '';
};

const getSexualOrientation = (extension: Extension[]) => {
  const orientationObject = extension.find((item) => {
    return item.url?.includes('sexual');
  });
  if (isEmptyObject(orientationObject)) {
    return '';
  }
  if (orientationObject?.extension) {
    const result = orientationObject.extension.find(
      (item) => item.url == 'text'
    );
    return result?.valueString || '';
  }
  return orientationObject?.valueString || '';
};

const getCreatedDate = (extension: Extension[]) => {
  const createdDateObject = extension.find((item) => {
    return item.url?.includes('created');
  });
  if (isEmptyObject(createdDateObject)) {
    return '';
  }
  return createdDateObject?.valueString || '';
};

export const getUSLocaleDate = (dateString: string) => {
  const result = new Date(dateString).toLocaleDateString('en-US', {
    month: '2-digit',
    year: '2-digit',
    day: '2-digit',
  });

  if (result === 'Invalid Date') {
    return dateString;
  }
  return result;
};
