import React, { useState } from 'react';
import { Typography, Divider, Tag } from 'antd';
import { DATE_FORMATS } from '../../../constants';
import { getAgeValue, getDateStrFromFormat } from '../../../utils/DateUtils';
import { Colors } from '../../../styles';
import { HStack, View, VStack, Text as NativeText } from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { ProviderPreviewProps } from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/interface';
import { useIntl } from 'react-intl';
import { getUserUUID } from '../../../utils/commonUtils';
import { useQuery } from '@apollo/client';
import { GET_USER_BY_UUID_LIST } from '../../../services/User/UserQueries';
import ArrowRightDiagonalSvg2024 from '../../common/Svg/ArrowRightDiagonalSvg2024';

const { Text } = Typography;

const InsightsReferralPreviewSection = ({ contactData, note, provider, reason, details }: ProviderPreviewProps) => {
  const userUUID = getUserUUID();
  const [senderName, setSenderName] = useState(details?.sender?.name || '');

  const { loading, data } = useQuery(GET_USER_BY_UUID_LIST, {
    variables: {
      uuidList: [userUUID],
    },
    skip: !!details?.sender?.name,
    onCompleted: (data: any) => {
      const fetchedName = data?.users?.[0]?.name || '';
      setSenderName(fetchedName);
    },
  });

  const intl = useIntl();
  return (
    <>
    <VStack space={4}>
      <View style={{ maxWidth: 'fit-content' }}>
       <Tag style={{borderRadius: 4}} color={details?.status !== 'DRAFT' ? '#E8FDF3' : '#F6F7F8'} >
         <NativeText fontSize={14} fontWeight={400} style={{ color: details?.status !== 'DRAFT' ? '#12B76A' : '#6F7A90' }}  > {details?.status =='DRAFT' ?'Saved as Draft'  :'Signed & Referred' }</NativeText>
      </Tag>
     </View>
      <View>
        <NativeText fontSize={14} fontWeight={400} style={{ color: Colors.FoldPixel.GRAY250 }}>Patient</NativeText>
        <NativeText fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY400}>
          {contactData?.name}, {contactData?.person?.sexAtBirth?.value} • {getAgeValue(contactData?.person?.birthDate, '')} ({getDateStrFromFormat(
            contactData?.person?.birthDate,
            DATE_FORMATS?.DISPLAY_BIRTH_DATE_FORMAT
          )})
        </NativeText>
      </View>
      <HStack width={'80%'} justifyContent={'space-between'} alignItems={'center'}>
        <View>
          <NativeText fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY250}>{intl.formatMessage({ id: 'referredBy' })}</NativeText>
          <NativeText fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY400}> { senderName } </NativeText>
        </View>
        <ArrowRightDiagonalSvg2024/>
        <View>
          <NativeText fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY250}>{intl.formatMessage({ id: 'referredTo' })}</NativeText>
          <NativeText fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY400}>{provider?.name}</NativeText>
        </View>
      </HStack>
      { details?.reasonForReferral &&
              <View>
                <NativeText fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY250}>Reason</NativeText>
                <NativeText fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY400}>{reason || details?.reasonForReferral}</NativeText>
              </View>
        }
      </VStack>
      <View>
  {note && (
    <>
      <Divider />
      <View>
        <NativeText fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY250}>{intl.formatMessage({ id: 'additionalNote' })}</NativeText>
        <NativeText fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY400}>{note}</NativeText>
      </View>
    </>
  )}
      </View>
    </>
  );
};

export default InsightsReferralPreviewSection;
