import {DATE_FORMATS} from '../../../../../../../constants';
import { APPOINTMENT_STATUS_CODES } from '../../../../../../../constants/MlovConst';
import { IMlov } from '../../../../../../../Interfaces';
import {IAppointment} from '../../../../../../../services/Appointment/AppointmentInterface';
import {getDateStrFromFormat} from '../../../../../../../utils/DateUtils';
import {AttributeKey} from '../../../../../ContactCareProgram/interface';
import {IContactCareProgramStep} from '../../../../../ContactCareProgram/interface';

export const getAppointmentDateAndTime = (appointment: IAppointment) => {
  const appointmentDate = getDateStrFromFormat(
    appointment?.startDateTime,
    DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
  );
  const startTime = getDateStrFromFormat(
    appointment?.startDateTime,
    DATE_FORMATS.TIME_SELECT_FORMAT
  );
  const endTime = getDateStrFromFormat(
    appointment?.endDateTime,
    DATE_FORMATS.TIME_SELECT_FORMAT
  );
  return {
    appointmentDate,
    startTime,
    endTime,
  };
};

export const getPrimaryUserFromAppointment = (
  appointment: IAppointment,
  primaryUserId: string
) => {
  return appointment?.participants?.find(
    (participant: any) => participant?.participantTypeId === primaryUserId
  )?.user;
};

export const getAppointmentIdFromSelectedStep = (
  selectedStep?: IContactCareProgramStep
) => {
  if (selectedStep) {
    const appointmentId =
      selectedStep?.careProgramStepAdditionalAttributes?.find(
        (attribute) => attribute?.attributeKey === AttributeKey.APPOINTMENT_ID
      )?.attributeValue?.valueString;
    return appointmentId || '';
  }
  return '';
};


export const getAppointmentAttributeIdFromSelectedStep = (
  selectedStep?: IContactCareProgramStep
) => {
  if (selectedStep) {
    const appointmentAttributeId =
      selectedStep?.careProgramStepAdditionalAttributes?.find(
        (attribute) => attribute?.attributeKey === AttributeKey.APPOINTMENT_ID
      )?.id;
    return appointmentAttributeId || '';
  }
  return '';
};


export const getUpcomingAppointmentStatusIds = (appointmentStatusList: IMlov[]) => {
  const upcomingApointmentStatusCodes = [
    APPOINTMENT_STATUS_CODES.PROPOSED,
    APPOINTMENT_STATUS_CODES.PENDING,
    APPOINTMENT_STATUS_CODES.BOOKED,
  ];
  const appointmentStatusIds = appointmentStatusList
    ?.filter((item) => upcomingApointmentStatusCodes.includes(item.code))
    .map((item) => item.id);
  return appointmentStatusIds;
}
