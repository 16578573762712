import { Colors } from '../../../styles/Colors';
import { Collapse, Select as AntSelect, List, Modal as AntModal, Button as AntButton } from 'antd';
import { useIntl } from 'react-intl';
import {
  Box,
  HStack,
  Text,
  View,
  Pressable,
  Button,
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
const { Panel } = Collapse;
import CommonService from '../../../services/CommonService/CommonService';
import DiagnosisSurfacingSvg from '../../common/Svg/DiagnosisSurfacingSvg';
import HardRefreshIconSvg from '../../common/Svg/HardRefreshSvg';
import UnitySvg from '../../common/Svg/UnitySvg';
import './Styles.css';
import { CommonDataContext } from '../../../context/CommonDataContext';
import BaseService from '../../../services/CommonService/BaseService';
import { fhirAddOrUpdate } from '../../../services/CommonService/AidBoxService';
import { FHIR_RESOURCE } from '../../../constants/FhirConstant';
import { LLMSkeletonLoader } from './LLMSkeletonLoader';
import { ILeftContainerProps } from './interfaces';
import { SearchCode } from '../../common/PAMISearch/PAMISearchHelper';
import TrashBinIcon2024 from '../../../assets/Icons/TrashBinIcon2024';
import DangerCircleSvg from '../../../assets/Icons/DangerCircleSvg';
import { TestIdentifiers, testID } from '../../../testUtils';
import { StyleSheet } from 'react-native';

interface Coding {
  code: string;
  system?: string;
  display?: string;
}

interface Code {
  coding: Coding[];
}
interface Resource {
  id: string;
  code: Code;
  clinicalStatus: Code;
}
interface CheckboxValue {
  snomed: string;
  name: string;
}

interface Entry {
  resource: Resource;
}
interface Problem {
  name?: string;
  snomed: string;
  category?: string;
  evidence?: string;
}

const DiagnosisSurfacingConstants = {
  problemListItem: 'problem-list-item',
  clinicalStatus: 'Active',
  resourceType: 'Condition',
  promiseOkStatus: 'fulfilled',
  loaderMsg1: 'Analyzing Diagnosis Surfacing',
  surfacingTitle: 'Diagnoses Surfacing'
}

export const DiagnosisSurfacing = (props: any) => {
  const [filteredProblems, setFilteredProblems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [pendingCheckboxValue, setPendingCheckboxValue] = useState<CheckboxValue | null>(null);
  const [modalAction, setModalAction] = useState<string>('');
  const [cachedId, setCachedId] = useState<string>('');
  const patientId = props?.personData?.patientId || props?.personData?.patientUuid
  const locationId = props?.personData?.accountLocationUuid


  const handleCheckboxChange = (value: CheckboxValue, action: string) => {
    setModalAction(action)
    setPendingCheckboxValue(value);
    setShowWarning(true);
  };

  const handleConfirm = async () => {
    try {
      setShowWarning(false);
      if (pendingCheckboxValue && modalAction == 'add') {
        const currentDate = new Date().toISOString().split('T')[0];
        const fhirData = {
          resourceType: DiagnosisSurfacingConstants.resourceType,
          clinicalStatus: {
            coding: [{ code: 'active', display: DiagnosisSurfacingConstants.clinicalStatus }]
          },
          subject: { reference: `Patient/${patientId}` },
          note: [
            {
              text: 'Unity AI'
            }
          ],
          onsetDateTime: currentDate,
          code: {
            text: pendingCheckboxValue.name,
            coding: [{
              display: pendingCheckboxValue.name,
              system: SearchCode?.SNOMED,
              code: pendingCheckboxValue?.snomed
            }]
          },
          category: [{ coding: [{ code: DiagnosisSurfacingConstants.problemListItem }] }]
        };

        fhirAddOrUpdate(
          FHIR_RESOURCE.CONDITION,
          '',
          fhirData,
          { patientId, locationId },
          (response) => {
            setPendingCheckboxValue(null);
            alreadyAttachedConditions()
          },
          (error) => {
          }
        );
      }
      else {
        setShowWarning(false);
        const urlToRejectCodes = '/snomed/reject'
        const payload = {
          name: pendingCheckboxValue?.name,
          snomed: pendingCheckboxValue?.snomed,
          contactUuId: props?.contactUUID,
          cachedId: cachedId
        }
        await llmflowService.post(urlToRejectCodes, payload)
        setPendingCheckboxValue(null);
        alreadyAttachedConditions()
      }
    } catch (error) {

    }
  };

  const handleCancel = () => {
    setShowWarning(false);
    setPendingCheckboxValue(null);
  };

  const [isActive, setIsActive] = useState(false);
  const commonService = CommonService.getCommonServiceInstance();
  const llmflowService = commonService.llmService;
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;

  useEffect(() => {
    alreadyAttachedConditions()
  }, []);

  const alreadyAttachedConditions = async (isCached = true) => {
    try {
      setLoading(true);
      const { axios } = BaseService.getSharedInstance();
      const payloadForProblems = {
        contactUuid: props?.contactUUID,
        isCatched: isCached
      };
      const urlToFetchAssociateConditions = `/integration/api/patient/Condition?patient=${patientId}&category=problem-list-item&location=${locationId}`;
      const urlToFetchAllProblems = `/problem`;
      try {
        const [conditionResponse, problemsResponse] = await Promise.allSettled([
          axios.get(urlToFetchAssociateConditions),
          llmflowService.post(urlToFetchAllProblems, payloadForProblems)
        ]);

        let responseForProblems: { data: { response: Problem[], cachedId: string } } = { data: { response: [], cachedId: '' } };
        let cachedId = ''
        if (problemsResponse?.status == 'fulfilled') {
          responseForProblems = problemsResponse.value;
          cachedId = responseForProblems?.data?.cachedId
          setCachedId(cachedId)
        }
        const urlToFetchRejectedCodes = `/snomed/rejection/ids?contactUuId=${props.contactUUID}&cachedId=${cachedId}`;
        const rejectedCodesResponse = await llmflowService.get(urlToFetchRejectedCodes)
        const rejectedCodes: string[] = rejectedCodesResponse?.data?.data?.rejectedCodes ? rejectedCodesResponse?.data?.data?.rejectedCodes : [];

        const entries: Entry[] = conditionResponse?.status == 'fulfilled' && conditionResponse?.value?.data?.resource?.data?.entry ? conditionResponse?.value?.data?.resource?.data?.entry : [];

        const snomedCodes: string[] = entries
          ?.flatMap(entry =>
            entry?.resource?.clinicalStatus?.coding?.some(status => status?.code === 'Active')
              ? entry?.resource?.code?.coding
                .filter(code => code?.system === SearchCode.SNOMED)
                .map(code => code?.code)
              : []
          )
          .filter((code): code is string => Boolean(code));

        const updatedRejectedCodes = await Promise.all(
          responseForProblems?.data?.response?.map(async (code) => {
            try {
              let url = `/integration/api/codes?limit=25&offset=0&searchType=Problem&searchString=${code?.snomed}`
              if (locationId) {
                url += `&location=${locationId}`;
              }
              const response = await axios.get(url);
              if (response?.data?.codeable?.length > 0) {
                return null;
              } else {
                return code?.snomed;
              }
            } catch {
              return code?.snomed;
            }
          })
        );
        const finalRejectedCodes = [...rejectedCodes, ...updatedRejectedCodes.filter(code => code !== null)];
        let filteredProblems: Problem[] = [];
        if (responseForProblems?.data?.response?.length) {
          const data: Problem[] = responseForProblems?.data?.response;
          filteredProblems = data.filter(problem =>
            !snomedCodes.includes(String(problem?.snomed)) &&
            !finalRejectedCodes.includes(String(problem?.snomed))
          );
        }
        setFilteredProblems(filteredProblems);
      } catch (error) {
      } finally {
        setLoading(false);
        setIsActive(false)
      }
    } catch (error) {

      setLoading(false);
    }
  };

  const loaderTemeplateValues = [
    intl.formatMessage({ id: DiagnosisSurfacingConstants?.loaderMsg1 }),
    intl.formatMessage({ id: DiagnosisSurfacingConstants?.loaderMsg1 })
  ];

  return (
    <Box
      mb={2}
      shadow='0px 4px 8px -2px #10182819, 0px 2px 4px -2px #101828f'
      borderRadius={8}
      borderColor={Colors.Custom.BorderColor}
      borderWidth={1}
    >
      <Collapse
        className='llm-collapse'
        defaultActiveKey={[1]}
        collapsible='header'
        style={{
          borderWidth: 0.5,
          ...(!isSidecarContext && {
            borderColor: Colors.Custom.ContainerBGColor
          }),
        }}
        expandIconPosition='end'
      >
        <Panel
          header={
            <HStack alignItems={'center'} space={3}>
             <span {...testID(TestIdentifiers.llm.diagnosysSurfacing.diagnosysIcon)}><DiagnosisSurfacingSvg /></span>
              <Text
                color={Colors.Custom.ProfileViewTextColor}
                fontSize={20}
                fontWeight={600}
                {...testID(TestIdentifiers.llm.diagnosysSurfacing.diagnosysTitle)}
              >
                {intl.formatMessage({ id: DiagnosisSurfacingConstants.surfacingTitle })}
              </Text>
            </HStack>
          }
          key={'1'}
        >
          <div className='llm-content'>
            {
              loading ? (<View padding={4} height={200} justifyContent={'center'}>
                <LLMSkeletonLoader texts={loaderTemeplateValues} iconKey={'diagnosis' + 'skeleton'}/>
              </View>) :
                (<View>
                  <div style={reactStyles.div}>
                    <List
                      dataSource={filteredProblems}
                      renderItem={item => (
                        <List.Item key={item?.snomedCode} style={reactStyles.listItemStyle}>
                        <div style={reactStyles.listItemStyle1}>
                          <span>{item?.name}</span>
                          <div style={reactStyles.text}>
                            Source: {item?.evidence}
                          </div>
                        </div>
                        <div style={reactStyles.flexAlignItemsCenter}>
                          <AntButton
                            type={selectedValues.includes(item?.snomedCode) ? 'primary' : 'default'}
                            onClick={() => handleCheckboxChange(item, 'add')}
                            style={reactStyles.buttonStyle}
                            {...testID(TestIdentifiers.llm.diagnosysSurfacing.diagnosysAddBtn)}
                          >
                            Add
                          </AntButton>
                          <AntButton
                            type='default'
                            onClick={() => handleCheckboxChange(item, 'reject')}
                            {...testID(TestIdentifiers.llm.diagnosysSurfacing.diagnosysRemoveBtn)}
                          >
                            Reject
                          </AntButton>
                        </div>
                      </List.Item>
                      )}
                    />
                  </div>
                  {showWarning && (
                    <AntModal
                      visible={showWarning}
                      onOk={handleConfirm}
                      onCancel={handleCancel}
                      footer={null}
                      centered
                      bodyStyle={{ padding: '20px' }}
                      width={320}
                    >
                      <View style={{ alignItems: 'center', marginBottom: 8 }}>
                        {modalAction === 'reject' ? <TrashBinIcon2024 color={'#D92D20'} height={24} width={24} /> : <DangerCircleSvg />}
                      </View>
                      <View style={{ alignItems: 'center', marginBottom: 16 }}>
                        <Text textAlign={'center'} fontWeight={600}>
                          {modalAction === 'reject'
                            ? 'Are you sure you want to reject this item?'
                            : 'Confirm adding this diagnosis?'}
                        </Text>
                        {modalAction !== 'reject' && (
                          <Text textAlign={'center'} color={Colors.Custom.messageTimeColor} style={{ marginTop: 8 }}>
                            This will add the given diagnosis to the patient's chart as a diagnosis.
                          </Text>
                        )}
                      </View>
                      <HStack space={4} alignItems={'center'} justifyContent={'center'}>
                        <Button
                          variant='outline'
                          style={{
                            backgroundColor: Colors.Custom.MonochromeWhite,
                            borderColor: Colors.FoldPixel.GRAY300,
                          }}
                          _text={{
                            color: Colors.FoldPixel.GRAY300,
                            fontWeight: '500',
                          }}
                          _hover={{
                            backgroundColor: Colors.FoldPixel.GRAY400
                          }}
                          width={131}
                          onPress={handleCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          colorScheme='custom'
                          style={{
                            backgroundColor: modalAction === 'reject' ? Colors.Custom.Red100 : Colors.Custom.Primary300,
                            borderRadius: 4,
                            borderColor: modalAction === 'reject' ? Colors.Custom.crossIconColor : Colors.Custom.crossIconColor
                          }}
                          _text={{
                            color: modalAction === 'reject' ? Colors.FoldPixel.STATUS_ERROR : Colors.Custom.ConversationBgColor,
                            fontWeight: '500',
                          }}
                          width={131}
                          onPress={handleConfirm}
                        >
                          {modalAction === 'reject' ? 'Reject' : 'Add'}
                        </Button>
                      </HStack>
                    </AntModal>
                  )}

                </View>)}
            <HStack
              space={2}
              style={styles.hStack}
            >
              <View
                style={styles.view}
                {...testID(TestIdentifiers.llm.diagnosysSurfacing.diagnosysPoweredByUnity)}
              >
                <UnitySvg iconKey={'diagnosis' + 'UnitySvg'} />
                <Text
                  marginLeft={2}
                  size={'xsNormal'}
                  fontWeight={600}
                  noOfLines={1}
                  style={styles.text}
                >
                  {intl.formatMessage({ id: 'poweredByUnity' })}
                </Text>

                <Text
                  marginLeft={2}
                  size={'xsNormal'}
                  fontWeight={550}
                  noOfLines={1}
                  style={styles.text1}
                >
                  {intl.formatMessage({ id: 'alpha' })}
                </Text>
              </View>
              <Pressable
                flexDirection={'row'}
                alignItems={'center'}
                onPress={() => {
                  setIsActive(true);
                  alreadyAttachedConditions(false);
                }}
              >
                <HardRefreshIconSvg isActive={isActive} iconKey={'diagnosis'} />
              </Pressable>
            </HStack>
          </div>
        </Panel>
      </Collapse>
    </Box>
  );
};

const styles = StyleSheet.create({
  hStack: {
    flexDirection: 'row',
    paddingHorizontal: 12,
    justifyContent: 'space-between',
    paddingVertical: 5,
    borderBottomLeftRadius: 9,
    borderBottomRightRadius: 9,
  },
  view: { 
    flexDirection: 'row', 
    alignItems: 'center', 
    flex: 1 
  },
  text: {
    backgroundImage:
      'linear-gradient(237.73deg, #1E9DAE 0.27%, #D478FF 102.44%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textAlign: 'center',
  },
  text1: {
    color: Colors.FoldPixel.GRAY200,
    fontFamily: 'Courier',
    textAlign: 'center',
  }
});

const reactStyles: Record<string, React.CSSProperties> = {
  div: { 
    maxHeight: '200px', 
    overflowY: 'scroll', 
    padding: '10px' 
  },
  listItemStyle: { 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'flex-start' 
  },
  listItemStyle1: { 
    flex: '1', 
    display: 'flex', 
    flexDirection: 'column' 
  },
  text: { 
    fontSize: '12px', 
    color: 'grey' 
  },
  flexAlignItemsCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  bottomStyle: {
    marginRight: '8px'
  }
};
