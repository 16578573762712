import React from "react";
import Svg, {Defs, G, Path} from "react-native-svg";

const  MedicationPilll = () => {
  return (
    <Svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <G clipPath="url(#clip0_922_1235)">
        <Path
          stroke="#6F7A90"
          strokeLinecap="round"
          d="M11.204 4.796s-.36 1.87-2.45 3.96c-2.088 2.088-3.958 2.448-3.958 2.448m8.543 2.136a4.53 4.53 0 01-6.407 0L2.66 9.068A4.53 4.53 0 019.068 2.66l4.271 4.272a4.53 4.53 0 010 6.408z"
        ></Path>
      </G>
      <Defs>
        <clipPath id="clip0_922_1235">
          <Path fill="#fff" d="M0 0H16V16H0z"></Path>
        </clipPath>
      </Defs>
    </Svg>
  );
}

export default MedicationPilll;
