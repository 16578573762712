import React, { useState } from 'react';
import { Table, Button, Spin, Empty } from 'antd';
import { LeftOutlined, RightOutlined, MoreOutlined } from '@ant-design/icons';
import styles from './BillingTable.module.css';
import { useContactCareProgramBilling } from './useContactCareProgramBilling';
import { IContactCareProgramBillableActivity } from './BillingInterface';
import { getDateObject, getFormattedDate } from '../../../../../../../utils/DateUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../../../constants';
import { useContactPcpAndCareManager } from '../../../../../../CustomHooks/useContactPcpAndCareManager';
import { usePersonOmniViewContext } from '../../../../../../PersonOmniView/PersonOmniView.context';
import { SortOrder } from 'antd/es/table/interface';
import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import { Colors } from '../../../../../../../styles';
import { TableRowSelection } from 'antd/es/table/interface';
import { useCPEventHandlers } from '../../../../useCPEventhandler';
import { BILLING_STATUS, CARE_PROGRAM_EVENT_CODES } from '../../../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import { useCareProgramEditability } from '../../../hooks/useCareProgramEditability';
import { testID, TestIdentifiers } from '../../../../../../../testUtils';

interface BillingTableProps {
  selectedDate: string;
  selectedRowKeys: string[];
  selectedRows: IContactCareProgramBillableActivity[];
}

const CCMBillingTable: React.FC = () => {
  // States
  const [componentState, setComponentState] = useState<BillingTableProps>({
    selectedDate: new Date().toISOString(),
    selectedRowKeys: [],
    selectedRows: [],
  });

  // Hooks
  const { hookState: { attributesAndCodes, totalBilling }, attributeAndCodesLoading } = useContactCareProgramBilling({
    selectedDate: componentState.selectedDate,
  });
  const {formattedData} = usePersonOmniViewContext();
  const {isAllowedToTakeActions} = useCareProgramEditability();
  const contactUuid = formattedData?.contactData?.uuid || ''
  const { pcpName, careManagerName, loading: pcpLoading } = useContactPcpAndCareManager(contactUuid);
  const loading = attributeAndCodesLoading || pcpLoading;
  const { sync } = useCPEventHandlers({
    syncCode: 'billing-activity-selection',
    listenCode: 'billing-activity-selection'
  });

  const columns = [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="Service Date"
        />
      ),
      dataIndex: 'timestamp',
      key: 'timestamp',
      sorter: (a: IContactCareProgramBillableActivity, b: IContactCareProgramBillableActivity) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
      render: (text: string) => {
        return <span>{getFormattedDate(text, DISPLAY_DATE_FORMAT)}</span>
      }
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="Time Spent"
        />
      ),
      dataIndex: 'durationInMinutes',
      key: 'durationInMinutes',
      render: (text: number) => {
        return <span>{text} minutes</span>
      }
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="Amount"
        />
      ),
      dataIndex: 'totalDollars',
      key: 'totalDollars',
      sorter: (a: IContactCareProgramBillableActivity, b: IContactCareProgramBillableActivity) => (a.totalDollars || 0) - (b.totalDollars || 0),
      render: (text: number) => {
        return <span>${text}</span>
      }
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='Provider'
        />
      ),
      dataIndex: 'provider',
      key: 'provider',
      render: (text: string) => (
        <>
          <span>{text}</span>
          <span >{pcpName || '-'}</span>
        </>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='Care Manager'
        />
      ),
      dataIndex: 'careManager',
      key: 'careManager',
      sorter: (a: any, b: any) => {
        const nameA = `${a.careManager}${careManagerName}`.toLowerCase();
        const nameB = `${b.careManager}${careManagerName}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      sortDirections: ['ascend', 'descend'] as SortOrder[],
      render: (text: string) => (
        <>
          <span>{text}</span>
          <span >{careManagerName}</span>
        </>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='CPT Code'
        />
      ),
      dataIndex: 'cptCodes',
      key: 'cptCodes',
      render: (text: string[]) => {
        return <span>{text.join(', ')}</span>
      }
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='Status'
        />
      ),
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        return <span>{text}</span>
      }
    }
  ];

  const rowSelection: TableRowSelection<IContactCareProgramBillableActivity> = {
    type: 'checkbox',
    selectedRowKeys: componentState.selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: IContactCareProgramBillableActivity[]) => {
      if (selectedRowKeys.length !== attributesAndCodes.length) {
        setComponentState({
          ...componentState,
          selectedRowKeys: selectedRowKeys.map(key => key.toString()),
          selectedRows: selectedRows,
        });
        sync(CARE_PROGRAM_EVENT_CODES.BILLING_SELECTION_CHANGED, {
          selectedIds: selectedRowKeys.map(key => key.toString())
        });
      }
    },
    onSelect: (record: IContactCareProgramBillableActivity, selected: boolean, selectedRows: IContactCareProgramBillableActivity[]) => {
      const newSelectedKeys = selected
        ? [...componentState.selectedRowKeys, record.id]
        : componentState.selectedRowKeys.filter(key => key !== record.id);

      setComponentState({
        ...componentState,
        selectedRowKeys: newSelectedKeys,
        selectedRows: selectedRows,
      });
      sync(CARE_PROGRAM_EVENT_CODES.BILLING_SELECTION_CHANGED, {
        selectedIds: newSelectedKeys
      });
    },
    onSelectAll: (selected: boolean) => {
      const selectableRecords = attributesAndCodes.filter(item => item.status !== BILLING_STATUS.BILLED);
      const newKeys = selected ? selectableRecords.map(item => item.id) : [];
      setComponentState({
        ...componentState,
        selectedRowKeys: newKeys,
        selectedRows: selected ? selectableRecords : [],
      });
      sync(CARE_PROGRAM_EVENT_CODES.BILLING_SELECTION_CHANGED, {
        selectedIds: newKeys
      });
    },
    getCheckboxProps: (record: IContactCareProgramBillableActivity) => ({
      disabled: record.status === BILLING_STATUS.BILLED,
    style: {
      display: record.status === BILLING_STATUS.BILLED ? 'none' : ''
    },
    }),
    preserveSelectedRowKeys: false,
    hideSelectAll: attributesAndCodes?.every(record => record.status === BILLING_STATUS.BILLED),
    checkStrictly: true
  };

  return (
    <section className={styles.billingContainer}>
      <header className={styles.billingHeader}>
        <h2 className={styles.billingTitle} {...testID(TestIdentifiers.monthlyBill)}>Total Billing this month : ${totalBilling}</h2>
        <div className={styles.monthSelector}>
          <Button
            icon={<LeftOutlined style={{fontSize: '12px'}} />}
            className={styles.monthButton}
            aria-label="Previous month"
            disabled={loading}
            onClick={() => {
              const date = getDateObject(componentState.selectedDate);
              date.setMonth(date.getMonth() - 1);
              setComponentState({
                ...componentState,
                selectedDate: date.toISOString(),
              });
            }}
          />
          <span className={styles.currentMonth}>{getFormattedDate(componentState.selectedDate, 'MMM YYYY')}</span>
          <Button
            icon={<RightOutlined style={{fontSize: '12px'}} />}
            className={styles.monthButton}
            aria-label="Next month"
            disabled={loading}
            onClick={() => {
              const date = getDateObject(componentState.selectedDate);
              date.setMonth(date.getMonth() + 1);
              setComponentState({
                ...componentState,
                selectedDate: date.toISOString(),
              });
            }}
          />
        </div>
      </header>
      <Table
        className={styles.billingTable}
        columns={columns}
        dataSource={attributesAndCodes}
        pagination={false}
        scroll={{x: true}}
        loading={{
          spinning: loading,
          indicator: (
            <div style={{marginTop: 100}}>
              <Spin size="small" />
            </div>
          ),
        }}
        rowSelection={isAllowedToTakeActions ? rowSelection : undefined}
        rowKey={(record) => record.id}
        locale={{
          emptyText: loading ? (
            <></>
          ) : (
            <div className={styles.emptyStateContainer}>
              <Empty />
            </div>
          ),
        }}
        style={{minHeight: loading ? '300px' : 'auto'}}
      />
    </section>
  );
};

export default CCMBillingTable;
