import {View, Text, Pressable} from 'react-native';
import React, { useContext, useState } from 'react';
import {FormControl, Input, Spacer, Stack} from 'native-base';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {ModalActionAntSelect} from '../../../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {GoalOperator, IGoalTarget, IGoalType} from './interface';
import {GOAL_ENTITY_TYPES, GOAL_OPERATOR_LIST, GOAL_TARGET_MEASURE_FREQUENCY_TYPES, GOAL_TYPES} from './GoalConstants';
import GoalEntitySearch from '../GoalEntitySearch/GoalEntitySearch';
import {IGoalEntityResult} from '../GoalEntitySearch/interface';
import {Colors} from '../../../../../../../styles';
import CrossIconSvg from '../../../../../../common/Svg/CrossIconSvg';
import VitalInput from '../../Vitals/VitalInput/VitalInput';
import {Vital, VitalInputType} from '../../../../../../../utils/VitalUtils';
import {getVitalListFromCapability} from '../../../../../../../utils/capabilityUtils';
import {VitalType} from '../../../../../../TaskCard/VitalCaptureInput/VitalCaptureInput';
import { IVitalData } from '../../../../../../../Interfaces';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import { IFormCommonData } from '../../CustomWrapper/CustomWrapper';
import { getDefaultGoalValue } from './GoalUtils';
import { useGoalLastRecordedValue } from './useGoalLastRecordedValue';
import { getFormattedDate } from '../../../../../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils';
import { DISPLAY_DATE_FORMAT, MLOV_CATEGORY } from '../../../../../../../constants';
import { styles } from '../../../../formStyle';
import { InputNumber, Select, Input as AntInput } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { getMlovListFromCategory } from '../../../../../../../utils/mlovUtils';
import { testID, TestIdentifiers } from '../../../../../../../testUtils';

interface IGoalTargetViewProps {
  goalTarget: IGoalTarget;
  disabled: boolean;
  showErrors?: boolean;
  duration?: number;
  durationUnitId?: string;
  onDurationChange?: (duration: number, durationUnitId: string) => void;
  metaData: {
    goalEntityTypeIds: {[index: string]: string};
    patientId: string;
    patientContactUuid: string;
    locationId: string;
  };
  onChange: (item: IGoalTarget) => void;
  goalTypeHandleObject?: {
    goalType: IGoalType;
    onGoalTypeChange: (item: IGoalType) => void;
  };
}

const GoalTargetView = (props: IGoalTargetViewProps) => {
  // Constants
  const {goalTarget, disabled, metaData, showErrors, onChange, goalTypeHandleObject, duration, durationUnitId, onDurationChange} = props;
  const {goalEntityTypeIds, patientContactUuid, patientId, locationId} = metaData;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const mlovData = useContext(CommonDataContext);
  const durationUnits =
    getMlovListFromCategory(mlovData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_DURATION);
  const isOtherGoalType = !!(goalTarget.entityTypeId === goalEntityTypeIds.OTHER) 

  const goalEntityTypes = GOAL_ENTITY_TYPES.map((item) => {
    return {
      id: goalEntityTypeIds[item.code],
      code: item.code,
      value: item.value,
    }
  });
  const currentEntityTypeCode = goalEntityTypes.find(item => item.id === goalTarget.entityTypeId)?.code;
  const measurableEntityTypeIds = [
    goalEntityTypeIds.ACTIVITY,
    goalEntityTypeIds.LAB_ANALYTE,
    goalEntityTypeIds.VITAL,
    goalEntityTypeIds.FORM
  ];

  const {lastRecordedValue, loading: lastRecordedValueLoading, lastRecordedDate} = useGoalLastRecordedValue({
    patientId: patientId,
    locationId: locationId,
    goalEntityTypeIds: goalEntityTypeIds,
    goalTarget: goalTarget,
    patientContactUuid: patientContactUuid,
  });
  const [componentState, setComponentState] = useState({
    inputValue: 1,
    selectedUnit: durationUnits.find((item) => item.code === 'MONTH')?.value,
    // initalValueForBloodPressure: lastRecordedValue ? Number(lastRecordedValue) : 0,
  });

  // Other methods
  const getVitalList = (): IVitalData[] => {
    const vitals = contextData.capabilities?.abilities?.allowedVitalList || [];
    return vitals.filter(item => !item.isHidden);
  };

  const selectAfter = (
    <Select
      value={durationUnitId}
      onChange={(unit: string) => {
        onDurationChange?.(duration || 0, unit || '');
      }}
      suffixIcon={<CaretDownOutlined style={{color: '#6B7280'}} />}
      style={{
        width: '25%',
        // height: 36,
        backgroundColor: 'transparent',
        // borderRadius: 4,
        borderWidth: 0,
      }}
      dropdownStyle={{
        zIndex: 1000,
      }}
      bordered={false}
      size="large"
    >
      {durationUnits.map((unit) => (
        <Select.Option key={unit.id} value={unit.id}>
          {unit.value}
        </Select.Option>
      ))}
    </Select>
  );

  const renderVitalInput = (
    selectedValue?: number | string,
    onChange?: (value?: string | undefined) => void,
    hideUnit?: boolean,
  ) => {
    if (goalTarget.entityTypeId === goalEntityTypeIds.VITAL) {
      const vitals = getVitalList();
      const vitalConfig = vitals.find((item) => {
        return item.loinc === goalTarget.entityCode;
      });
      const defaultValue = getDefaultGoalValue(selectedValue, goalTarget.entityCode, vitalConfig);
      const referenceRange = vitalConfig?.range || [];
      let vitalType = VitalType.normal;
      if (goalTarget.entityCode === Vital.bloodPressure) {
        vitalType = VitalType.bloodPressure;
      } else if (goalTarget.entityCode === Vital.height) {
        vitalType = VitalType.heightWithFeetAndInches;
      }
      return (
        <VitalInput
          showErrors={showErrors && !(selectedValue || selectedValue === 0)}
          vital={{
            value:
              selectedValue || selectedValue === 0 ? `${selectedValue}` : '',
            min: referenceRange?.[0]?.min || 0,
            max: referenceRange?.[0]?.max || 1000,
          }}
          defaultValue={{
            vital1: defaultValue?.vital1,
            vital2: defaultValue?.vital2,
          }}
          decimalPrecision={vitalConfig?.allowedDecimalPlaces || 0}
          inputType={vitalConfig?.inputType || VitalInputType.numeric}
          hideUnit={hideUnit}
          label={''}
          unit={hideUnit ? '' : vitalConfig?.unit}
          displayUnit={hideUnit ? '' : vitalConfig?.displayUnit}
          disabled={disabled}
          type={vitalType}
          possibleValues={vitalConfig?.possibleValues || []}
          format={vitalConfig?.format}
          loinc={vitalConfig?.loinc}
          useInputGroup={true}
          onChange={(value) => {
            onChange?.(value);
          }}
        />
      );
    } else {
      const defaultValue = { vital1: selectedValue || selectedValue === 0 ? `${selectedValue}` : '' };
      return (
        <VitalInput
          showErrors={showErrors && !(selectedValue || selectedValue === 0)}
          vital={{
            value:
              selectedValue || selectedValue === 0 ? `${selectedValue}` : '',
            min: -10000,
            max: 10000,
          }}
          defaultValue={{
            vital1: defaultValue.vital1,
            vital2: undefined,
          }}
          decimalPrecision={4}
          inputType={VitalInputType.numeric}
          label={''}
          hideUnit={hideUnit}
          unit={goalTarget.measure?.unit}
          displayUnit={goalTarget.measure?.unit}
          disabled={disabled}
          type={VitalType.normal}
          possibleValues={[]}
          onChange={(value) => {
            onChange?.(value);
          }}
        />
      );
    }
  };

  const getEntityTitle = () => {
    const entityTypeId = goalTarget?.entityTypeId;
    if (measurableEntityTypeIds.includes(entityTypeId || '')) {
      const entityType = goalEntityTypes?.find(
        (type) => type.id === entityTypeId
      );
      return entityType ? entityType.value : '';
    }
    return '';
  };
  const entity = getEntityTitle();

  return (
    <View>
      <Stack
        space={4}
        direction={'row'}
        style={styles.alignItemsCenterFlex1}
      >
        <FormControl isInvalid={showErrors && !goalTarget.entityTypeId} isDisabled={false} flex={1}>
          <FormControl.Label isRequired={true}>
            <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId="entityType" />
          </FormControl.Label>
          <ModalActionAntSelect
            showSearch={false}
            value={goalTarget.entityTypeId}
            accessibilityLabel="Select Category"
            placeholder="Select Category"
            isDisabled={disabled}
            isInvalid={showErrors && !goalTarget.entityTypeId}
            onChange={(itemValue: any) => {
              goalTarget.entityTypeId = itemValue;
              goalTarget.measure = {
                targetType: 'measure',
                targetValue: {},
                operator: GoalOperator.eq,
                unit: '',
                frequency: {
                  code: goalTarget.measure?.frequency?.code || ''
                }
              };
              goalTarget.entityCode = undefined;
              goalTarget.formId = undefined;
              goalTarget.title = '';
              onChange(goalTarget);
            }}
            hideCustomIcon
            data={goalEntityTypes}
            optionProps={{
              key: 'id',
              label: 'value',
              value: 'id',
            }}
            extraStyle={{flex: 1}}
            filterOption={(input: string, option: any) => {
              return (option?.children || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          />
        </FormControl>
        <FormControl isInvalid={showErrors && ((!goalTarget.entityCode && !goalTarget.formId) || !goalTarget.title)} isDisabled={false} flex={1}>
          {goalTarget.entityTypeId !== goalEntityTypeIds.OTHER && (
            <>
              <FormControl.Label isRequired={true}>
                <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={`Select ${entity}`} />
              </FormControl.Label>
              <GoalEntitySearch
                value={
                  goalTarget.entityCode
                    ? {code: goalTarget.entityCode, display: goalTarget.title}
                    : goalTarget.formId
                    ? {formId: goalTarget.formId, display: goalTarget.title}
                    : undefined
                }
                placeholder="Type 2 characters to search"
                isShowError={!!showErrors && ((!goalTarget.entityCode && !goalTarget.formId) || !goalTarget.title)}
                additionalAPIParams={
                  goalTarget.entityTypeId === goalEntityTypeIds.OTHER
                    ? undefined
                    : {categoryCodes: currentEntityTypeCode ? [currentEntityTypeCode] : []}
                }
                onChange={(data: IGoalEntityResult) => {
                  if (data) {
                    goalTarget.entityCode = data.code || '';
                    goalTarget.title = data.display || '';
                    if (data.formId) {
                      goalTarget.formId = data.formId || '';
                    }
                  } else {
                    goalTarget.entityCode = undefined;
                    goalTarget.title = '';
                    goalTarget.formId = undefined;
                  }
                  goalTarget.unit = data?.unit ? data?.unit : goalTarget.unit;
                  goalTarget.measure = {
                    targetType: 'measure',
                    targetValue: {},
                    operator: GoalOperator.eq,
                    unit: goalTarget.unit,
                    frequency: {
                      code: goalTarget.measure?.frequency?.code || 'DAILY'
                    }
                  };
                  onChange(goalTarget);
                }}
              />
            </>
          )}
        </FormControl>
      </Stack>
      {/* {lastRecordedValue && (
        <Text
          style={styles.text6}
        >{`Last Recorded: ${lastRecordedValue} ${
          lastRecordedDate
            ? `(${getFormattedDate(lastRecordedDate, DISPLAY_DATE_FORMAT)})`
            : ''
        }`}</Text>
      )} */}
      { /*measurableEntityTypeIds.includes(goalTarget.entityTypeId || '') &&*/
        !goalTarget?.measure?.targetValue && (
          <View style={styles.marginTop8}>
            <Pressable
              onPress={() => {
                goalTarget.measure = {
                  targetType: 'measure',
                  targetValue: {},
                  operator: GoalOperator.eq,
                  unit: goalTarget.unit,
                  frequency: {
                    code: goalTarget.measure?.frequency?.code || 'DAILY'
                  }
                };
                onChange(goalTarget);
              }}
            >
              <Text style={styles.text7}>
                {'Set Target'}
              </Text>
            </Pressable>
          </View>
        )}
      {/*measurableEntityTypeIds.includes(goalTarget.entityTypeId || '') &&*/
        !!goalTarget.measure?.targetValue && (
          <Stack direction={'column'}>
            <Stack
              direction={contextData.isBuilderMode ? 'column' : 'row'}
              space={3}
              flex={1}
              marginTop={2}
              style={{
                alignItems: 'center',
              }}
            >
              {<FormControl isDisabled={false} flex={0.5}>
                <FormControl.Label>
                  <DisplayText
                    size={'smRegular'}
                    extraStyles={{
                      color: Colors.FoldPixel.GRAY250,
                    }}
                    textLocalId="currentValue"
                  />
                </FormControl.Label>
                  <Input
                    // addonAfter={'mmHg'}
                    value={lastRecordedValue}
                    // onChange={(newValue: number | null) => {
                    //   const value = newValue || 0;
                    //   setComponentState((prevState) => ({
                    //     ...prevState,
                    //     initalValueForBloodPressure: value,
                    //   }));
                    // }}
                    isDisabled
                    placeholder={lastRecordedValueLoading ? 'Loading...' : 'No initial value found'}
                    {...testID(TestIdentifiers.currentValue)}
                    // status={
                    //   showErrors && !componentState.initalValueForBloodPressure
                    //     ? 'error'
                    //     : undefined
                    // }
                    style={{width: '100%', height: '100%', borderRadius: 4}}
                    // controls={true}
                    size="large"
                    width={'100%'}
                    // bordered={false}
                  />
              </FormControl>}
              <FormControl
                isDisabled={false}
                flex={0.5}
                style={{
                  borderRadius: 8,
                  height: 'auto',
                  minHeight: 40,
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'visible',
                }}
              >
                <FormControl.Label isRequired={goalTarget.entityTypeId !== goalEntityTypeIds.OTHER}>
                  <DisplayText
                    size={'smRegular'}
                    extraStyles={{color: Colors.FoldPixel.GRAY250}}
                    textLocalId="targetValue"
                  />
                </FormControl.Label>
                <Stack
                  direction="row"
                  space={3}
                  style={[
                    styles.stackStyles1,
                    {
                      flex: 1,
                    },
                  ]}
                  flex={0.5}
                >
                  {/* <View style={styles.minWidth40MarginBotton2}>
              <Text>{goalTarget.entityTypeId === goalEntityTypeIds.FORM ? 'Score' : goalTarget.title}</Text>
            </View> */}
            <Stack direction="row" space={0} style={styles.alignItemsCenterFlex1}>
              <Stack
                direction={
                  [Vital.height, Vital.bloodPressure].includes(goalTarget.entityCode as Vital) && false &&
                  goalTarget.measure?.operator === GoalOperator.between ?
                  'column' : 'row'
                }
                style={[Vital.height, Vital.bloodPressure].includes(goalTarget.entityCode as Vital) && false &&
                  goalTarget.measure?.operator === GoalOperator.between ? {} : { alignItems: 'center' }}
                space={2}
              >
                <View>
                  <ModalActionAntSelect
                    value={goalTarget.measure?.operator}
                    accessibilityLabel="Select Operator"
                    placeholder="Operator"
                    onChange={(itemValue: any) => {
                      if (goalTarget.measure) {
                        goalTarget.measure.operator = itemValue;
                      }
                      onChange(goalTarget);
                    }}
                    hideCustomIcon
                    size="middle"
                    data={GOAL_OPERATOR_LIST}
                    isInvalid={showErrors && !goalTarget.measure?.operator}
                    optionProps={{
                      key: 'code',
                      label: 'value',
                      value: 'code',
                    }}
                    marginTop={'5px'}
                    customStyle={{flex: 1, width: 102, height: 40, marginBottom: 0}}
                    filterOption={(input: string, option: any) => {
                      return (option?.children || '')
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  />
                </View>
                <View>
                  {goalTarget?.measure?.operator !== GoalOperator.between &&
                    renderVitalInput(
                      goalTarget?.measure?.targetValue?.value,
                      (value) => {
                        if (goalTarget.measure) {
                          goalTarget.measure.targetValue = {
                            low: undefined,
                            high: undefined,
                            value: value,
                          };
                        }
                        onChange(goalTarget);
                      },
                      !!(goalTarget.entityTypeId === goalEntityTypeIds.OTHER)
                    )}
                  {goalTarget.measure?.operator === GoalOperator.between && (
                    <Stack
                      direction="row"
                      space={0.5}
                      style={styles.alignItemsCenter}
                    >
                      {renderVitalInput(
                        goalTarget?.measure?.targetValue?.low,
                        (value) => {
                          if (goalTarget.measure?.targetValue) {
                            goalTarget.measure.targetValue.low = value;
                            goalTarget.measure.targetValue.value = undefined;
                          }
                          onChange(goalTarget);
                        },
                        true
                      )}
                      <Text style={styles.fontSize12ColorGray400}>
                        {'-'}
                      </Text>
                      {renderVitalInput(
                        goalTarget?.measure?.targetValue?.high,
                        (value) => {
                          if (goalTarget.measure?.targetValue) {
                            goalTarget.measure.targetValue.high = value;
                            goalTarget.measure.targetValue.value = undefined;
                          }
                          onChange(goalTarget);
                        },
                        !!(goalTarget.entityTypeId === goalEntityTypeIds.OTHER)
                      )}
                    </Stack>
                  )}
                </View>
                {isOtherGoalType && (
                  <View style={{width: 100}}>
                    <Input
                      _focus={{borderColor: Colors.Custom.Gray200}}
                      placeholder="Unit"
                      value={goalTarget?.measure?.unit || ''}
                      onChangeText={(text: string) => {
                        if (goalTarget?.measure) {
                          goalTarget.measure.unit = text;
                       }
                        onChange(goalTarget);
                      }}
                      style={{
                        height: 40,
                        borderRadius: 4,
                        borderColor: Colors.Custom.Gray200,
                      }}
                    />
                  </View>
                )}
              </Stack>
              {/* <View>
                <Pressable
                  onPress={() => {
                    if (goalTarget.measure) {
                      goalTarget.measure.targetValue = undefined;
                    }
                    onChange(goalTarget);
                  }}
                >
                  <CrossIconSvg size={16} />
                </Pressable>
              </View> */}
            </Stack>
            </Stack>
              </FormControl>
            </Stack>
            <Stack
              space={4}
              direction={'row'}
              style={{
                flex: 1,
                alignItems: 'stretch',
                marginTop: 8,
              }}
            >
              <FormControl isDisabled={false} flex={1} isRequired={goalTarget.entityTypeId !== goalEntityTypeIds.OTHER}>
                <FormControl.Label>
                  <DisplayText
                    size={'smRegular'}
                    extraStyles={{color: Colors.FoldPixel.GRAY250}}
                    textLocalId="duration"
                  />
                </FormControl.Label>
                <Stack
                  direction={'row'}
                  space={3}
                  flex={1}
                  style={{
                    height: 40,
                    borderWidth: 1,
                    borderRadius: 8,
                    paddingHorizontal: 4,
                    borderColor: showErrors && !duration ? Colors.Custom.ErrorColor : Colors.Custom.Gray200,
                  }}
                >
                  <AntInput.Group compact>
                  <InputNumber
                    // addonAfter={selectAfter}
                    min={0}
                    value={duration}
                    onChange={(newValue: number | null) => {
                      onDurationChange?.(newValue || 0, durationUnitId || '');
                    }}
                    placeholder={'Select Duration'}
                    {...testID(TestIdentifiers.selectDuration)}
                    status={
                      showErrors && !duration
                        ? 'error'
                        : undefined
                    }
                    style={{borderRadius: 8, width: '75%', paddingTop: 4}}
                    controls={true}
                    // size="large"
                    bordered={false}
                  />
                  {selectAfter}
                  </AntInput.Group>
                </Stack>
              </FormControl>
              <FormControl
                isDisabled={false}
                flex={1}
                isRequired={goalTarget.entityTypeId !== goalEntityTypeIds.OTHER}
                isInvalid={
                  showErrors &&
                  !goalTarget.measure?.frequency?.code &&
                  goalTarget.entityTypeId !== goalEntityTypeIds.OTHER
                }
              >
                <FormControl.Label>
                  <DisplayText
                    size={'smRegular'}
                    extraStyles={{color: Colors.FoldPixel.GRAY250}}
                    textLocalId="frequency"
                  />
                </FormControl.Label>
                <ModalActionAntSelect
                  isRequired={goalTarget.entityTypeId !== goalEntityTypeIds.OTHER}
                  allowClear={true}
                  showSearch={false}
                  isInvalid={
                    showErrors &&
                    !goalTarget.measure?.frequency?.code &&
                    goalTarget.entityTypeId !== goalEntityTypeIds.OTHER
                  }
                  value={goalTarget.measure?.frequency?.code || undefined}
                  accessibilityLabel="Frequency"
                  placeholder="Select Frequency"
                  onChange={(itemValue: any) => {
                    if (goalTarget?.measure) {
                      goalTarget.measure.frequency = itemValue ? {
                        code: itemValue,
                      } : undefined;
                    }
                    onChange(goalTarget);
                  }}
                  hideCustomIcon
                  data={GOAL_TARGET_MEASURE_FREQUENCY_TYPES}
                  optionProps={{
                    key: 'id',
                    label: 'value',
                    value: 'code',
                  }}
                  extraStyle={{flex: 1}}
                />
              </FormControl>
            </Stack>
          </Stack>
        )}
    </View>
  );
};

export default GoalTargetView;
