import {View, StyleSheet} from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {IFormResponse} from '../../RightSideContainer/Forms/FHFormio/CustomComponents/ClinicalAssessment/interfaces';
import HomeMonitoringView from '../../RightSideContainer/Forms/FHFormio/CustomComponents/HomeMonitoringView/HomeMonitoringView';
import AddOrUpdateVitals from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/AddOrUpdateVitals';
import { CapabilityResource, VITAL_INTAKE_COMPONENT } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { CommonDataContext } from '../../../context/CommonDataContext';
import LabReports from '../../RightSideContainer/Forms/FHFormio/CustomComponents/LabReports/LabReports';
import { useCarePlanGoalsApi } from '../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/CarePlanGoalsHook';
import { Colors } from '../../../styles';
import { getCapability, getEnabledFormComponents } from '../../../utils/capabilityUtils';
import useFormOptions from '../../RightSideContainer/ContactCareProgram/ContactCareProgramView/hooks/useFormOptions';
import { FormViewType } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import useEHRCapabilities from '../../../screens/BusinessStudio/useEHRCapabilities';
import FHForm from '../../RightSideContainer/Forms/FHFormio/FHForm/FHForm';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { useLazyQuery } from '@apollo/client';
import ContactCareProgram from '../../../services/ContactCareProgram/ContactCareProgram';
import { isEnableCareProgram } from '../../../utils/commonUtils';
import { PROGRAM_FILTER_CODE } from '../../common/MemebersView/constant';
import { CONTACT_CARE_PROGRAM_STATUS_CODES } from '../../../constants/MlovConst';

const InsightsCareBoardView = (props: {
  contactData?: IContact;
  formattedContactData?: IContact;
  isSidecarContext?: boolean;
}) => {
  const {contactData, formattedContactData} = props;
  const mlovData = useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const patientId =
    contactData?.patient?.patientId || contactData?.patient?.patientUuid;

  const accountLocationUuid =
    contactData?.contactPracticeLocations &&
    contactData?.contactPracticeLocations?.length > 0
      ? contactData.contactPracticeLocations[0]?.accountLocation?.uuid
      : '';
  // const ehrCapabilitiesForObservations = mlovData.ehrCapabilities.find((item: { resourceName: any; }) => item.resourceName && [CapabilityResource.observation].includes(item.resourceName));

  const [careProgramId, setCareProgramId] = useState<string | undefined>(undefined);
  const [getContactCarePrograms] = useLazyQuery(
    ContactCareProgram.GET_CONTACT_CARE_PROGRAM_ID,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const fetchCareProgramId = async () => {
    try {
      const response = await getContactCarePrograms({
        variables: {
          contactId: contactData?.uuid,
          codes: [PROGRAM_FILTER_CODE.CCM],
          statusCodes: [CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED, CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED, CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED]
        },
      });
      const fetchedCareProgramId = response?.data?.contactCarePrograms?.[0]?.id;
      return fetchedCareProgramId;
    } catch (error) {
    }
  };

  useEffect(() => {
    const getCareProgramId = async () => {
      const id = await fetchCareProgramId();
      setCareProgramId(id);
    };
    getCareProgramId();
  }, []);

  const ehrCapabilitiesForObservations = getCapability(
    CapabilityResource.observation,
    '',
    accountLocationUuid
  );

  const [fetchedGoals, goalsLoading, fetchGoals] = useCarePlanGoalsApi(careProgramId);

  const {intakeOptions} = useFormOptions({
    accountLocationUuid,
    patientId,
    contactId: contactData?.uuid,
    contactUuid: contactData?.uuid,
    backgroundSaveEnabled: true,
    componentView: FormViewType.compact
  });
  const ehrCapabilities = useEHRCapabilities({locationId: accountLocationUuid});
  const [enabledVitalComponents] = React.useState(getEnabledFormComponents(VITAL_INTAKE_COMPONENT, ehrCapabilities));

  useEffect(() => {
    if (isCareProgramEnabled && careProgramId) {
      fetchGoals(contactData?.uuid);
    }
  }, [careProgramId])

  return (
    <>
      <View style={[styles.container, styles.containerBgColor]}>
        <HomeMonitoringView
          contactId={`${contactData?.uuid}`}
          patientId={patientId || ''}
          locationId={accountLocationUuid || ''}
          defaultCollapse={false}
          component={{
            label: 'Home Monitoring',
          }}
          isSidecar={props?.isSidecarContext}
          goals={fetchedGoals}
        />
      </View>
      {props?.isSidecarContext ? (
        <View style={[styles.container, styles.containerBgColor]}>
          <AddOrUpdateVitals
            validateRef={undefined}
            disabled={true}
            component={{
              label: 'Vitals',
            }}
            options={{
              capabilities: ehrCapabilitiesForObservations,
              contactId: `${contactData?.uuid}`,
              patientId: patientId || '',
              locationId: accountLocationUuid || '',
              isSidecar: true,
              goals: fetchedGoals,
            }}
            onChange={() => {}}
          />
        </View>
      ) : (
        <View style={styles.container}>
          <FHForm
            optionData={intakeOptions}
            components={enabledVitalComponents}
          />
        </View>
      )}
      <View style={[props.isSidecarContext ? styles.container : styles.container1, styles.containerBgColor]}>
        <LabReports
          contactId={contactData?.uuid}
          accountLocationId={accountLocationUuid || ''}
          patientId={patientId}
          ccmDate={''}
          isHeaderView={!props?.isSidecarContext}
          relevantCodes={[]}
          isSideCar={props?.isSidecarContext}
          goals={fetchedGoals}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    marginHorizontal: 12,
    overflow: 'hidden',
    marginTop: 20
  },
  container1: {
    borderRadius: 8,
    marginHorizontal: 12,
    overflow: 'hidden',
    marginTop: 4
  },
  containerBgColor: {
    backgroundColor: Colors.Custom.White,
  },
});

export default InsightsCareBoardView;
