import {Path, Svg} from 'react-native-svg';

const ReferralOrderCreateSvg = (props: { width?: number, height?: number }) => {
  return (
    <Svg width={props?.width || 16} height={props?.height || 14} viewBox="0 0 16 14" fill="none">
      <Path d="M14.0996 8.09958C14.7981 7.3355 14.7981 6.16459 14.0996 5.4005L10.3358 1.28386C10.0279 0.94708 9.46675 1.16492 9.46675 1.62124V3.20837C9.46675 3.76066 9.01996 4.20287 8.46845 4.23215C5.60681 4.38406 1.35333 5.44533 0.741176 12.2447C0.696181 12.7445 1.34319 12.9475 1.64402 12.5458C3.68927 9.81535 5.41168 9.36161 8.4654 9.30024C9.01757 9.28915 9.46675 9.73942 9.46675 10.2917V11.8788C9.46675 12.3352 10.0279 12.553 10.3358 12.2162L14.0996 8.09958Z" stroke="#6F7A90" stroke-linecap="round" stroke-linejoin="round" />
    </Svg>
  );
};

export default ReferralOrderCreateSvg;