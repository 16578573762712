import {useEffect, useState} from 'react';
import {USER_ACCESS_PERMISSION} from '../../../../UserAccess/UserAccessPermission';
import {usePermissions} from '../../../../../CustomHooks/usePermissions';
import {MAIN_MENU_CODES} from '../../../../../SideMenuBar/SideBarConst';
import {getAllowedUserPracticeLocationUuids} from '../../../../../../utils/commonUtils';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {GROUP_TYPE_CODES} from '../../../../../../constants/MlovConst';

export const useCheckConversationLocationAccess = (params: {
  conversationPracticeLocationUuid: string;
  conversationUuid: string;
  isGroupConversation: boolean;
  isActiveConversation?: boolean;
  groupTypeCode?: string;
}) => {
  const {
    conversationPracticeLocationUuid,
    conversationUuid,
    isGroupConversation,
    isActiveConversation,
    groupTypeCode,
  } = params;

  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );

  const {check} = usePermissions();

  const conversationActiveStatus =
    isActiveConversation !== undefined ? isActiveConversation : true;
  const permissionWiseLocationData = check(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CONSUMER,
    ''
  );
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX
  );
  const [accessToConversation, setAccessToConversation] = useState(false);

  const canViewPatientProfile = () => {
    if (
      !isCommunicationLocationHandlingEnabled ||
      groupTypeCode !== GROUP_TYPE_CODES.PRIVATE
    ) {
      setAccessToConversation(true);
      return;
    }
    let isPatientOfUserLocation = false;
    if (permissionWiseLocationData?.hasAccess !== true) {
      setAccessToConversation(false);
      return;
    }
    if (conversationPracticeLocationUuid && !!isGroupConversation) {
      isPatientOfUserLocation =
        allowedUserPracticeLocationUuids?.includes(
          conversationPracticeLocationUuid
        ) && conversationActiveStatus;
    } else {
      isPatientOfUserLocation = true;
    }
    setAccessToConversation(isPatientOfUserLocation);
  };

  useEffect(() => {
    canViewPatientProfile();
  }, [conversationUuid]);

  return {
    accessToConversation,
  };
};
