import React, {useEffect, useState} from 'react';
import Stack from '../../../common/LayoutComponents/Stack';
import {base64ToBlob} from '../../../../utils/commonUtils';

const CareProgramDocumentView = (props: {attachmentData: any}) => {
  const [state, setState] = useState({
    fileUrl: '',
    fileName: '',
    fileType: '',
  });

  const handleDocumentView = async () => {
    const documentData = props.attachmentData;
    const blob = base64ToBlob(documentData?.data);
    const fileUrl = URL.createObjectURL(blob);

    setState((prev) => {
      return {
        ...prev,
        fileUrl,
        fileName: documentData?.title,
        fileType: documentData?.contentType,
      };
    });
  };

  useEffect(() => {
    if (props?.attachmentData) {
      handleDocumentView();
      return () => {
        if (state.fileUrl) {
          URL.revokeObjectURL(state.fileUrl);
        }
      };
    }
  }, [props?.attachmentData]);

  return (
    <Stack direction="column" style={{flex: 1, height: '100%'}}>
      {state.fileUrl && (
        <iframe 
          width={'100%'} 
          height={'100%'} 
          src={state.fileUrl} 
          title={state.fileName}
          name={state.fileName}
        ></iframe>
      )}
    </Stack>
  );
};

export default CareProgramDocumentView;
