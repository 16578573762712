import { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LeadQueries } from '../../../../../../services';
import { getMlovListFromCategory, getMlovIdFromCode, getMlovId, getContactTypeId } from '../../../../../../utils/mlovUtils';
import { PatientData, UseDayOptimizerResult, IRibbonResize, IRibbonRemovePatientEventData, ISortChangeEvent } from '../../interfaces';
import { CARE_PROGRAM_TYPE_CODES, CARE_TEAM, CONTACT_CARE_PROGRAM_STATUS_CODES, MLOV_CATEGORY, MLOV_CODES, TASK_ALERT_CATEGORY_CODES, USER_ROLE_CODES } from '../../../../../../constants/MlovConst';
import { IContactScore } from '../../interfaces';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { DayOptimizerContext } from '../../../../../../context-provider/DayOptimizerContext/context';
import { DayOptimizerBulkActions, DayOptimizerMenuItemKeys, formatDateForApi, formatDateForApiFromDate, removeBulkPatientsFromList, removePatientFromList } from '../../DayOptimizerHelper';
import { getUserId, getUserUUID } from '../../../../../../utils/commonUtils';
import { showToast, ToastType } from '../../../../../../utils/commonViewUtils';
import { IContactScoreAudit, IGridColumn } from '../../../../MemebersView/interface';
import { getAgeValue, getNextDayFromDate } from '../../../../../../utils/DateUtils';
import { USER_ACCESS_PERMISSION } from '../../../../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../../../SideMenuBar/SideBarConst';
import { usePermissions } from '../../../../../CustomHooks/usePermissions';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import { useGridConfigurations } from '../../../../MemebersView/customHook/useGridConfigurations';
import { GRID_CONFIGURATION_DATA } from '../../../../../../context-provider/DayOptimizerContext/actionTypes';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { GET_CARE_TEAM_MEMBER_PCP_TYPE } from '../../../../../../services/CareTeam/CareTeamQueries';
import { GET_SELECTED_USERS } from '../../../../../../services/Location/UserPracticeLocationQueries';
import { IUser } from '../../../../../../Interfaces/CommonInterfaces';
import React from 'react';
import { EventBus } from '../../../../../../utils/EventBus';
import { CONVERSATION_ACTION_CODES } from '../../../../../../constants/ActionConst';
import { useIntl } from 'react-intl';
import { useCustomToast } from '../../../../../Toast/ToastProvider';
import { FlowType } from '../../../../../../context/WorkflowContext';
import { getNodeMetadata } from '../../../../../RightSideContainer/Journeys/AddOrUpdateJourney/JourneyMetadataService';
import { EDUCATION_CONTENT_NODE_DETAILS } from '../../../../../SideCar/constants';
import { setFieldsVisibilityFromPreviousNode } from '../../../../../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeHelper';
import { IUserInputField } from '../../../../../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeInterface';
import { DAY_OPTIMIZER_TABLE_CODE, DAY_OPTIMIZER_ENTITY_CODE, DayOptimizerColumnCode, DAY_OPTIMIZER_RULE_SCORE_FACTOR_DESCRIPTION, FACTOR_TO_ACTION_MAP, careProgramTypeArray, DAY_OPTIMIZER_RULE_SCORE_FACTOR_DESCRIPTION_FOR_QA_ENV, DayOptimizerActionStatus, DAY_OPTIMIZER_EVENTS, DayOptimizerViewAction, QA_ENVIRONMENT } from '../../DayOptimizerConstants';
import { GetContactTags } from '../../../../../../services/Lead/LeadQueries';
import { GET_ACTIVE_CARE_PROGRAMS_BY_CARE_PROGRAM_TYPE_ID, GET_ALLOWED_CARE_PROGRAM_TYPES_FOR_CONTACTS } from '../../../../../../services/ContactCareProgram/ContactCareProgram';
import { RIGHT_SIDE_CONTAINER_CODE } from '../../../../../../constants';
import { IConversationData, INewConversationResponse } from '../../../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import ConversationsQueries from '../../../../../../services/Conversations/ConversationsQueries';
import { evnName } from '../../../../../../constants/BaseUrlConst';
import { GET_TASK_COUNTS } from '../../../../../../services/Task/TaskQueries';

interface IUseDOPatientListingParams {
  onViewAction: (action: DayOptimizerViewAction, selectedPatient?: PatientData) => void;
}

const useDOPatientListing = (params: IUseDOPatientListingParams): UseDayOptimizerResult => {
  const intl = useIntl();
  const commonContextData = useContext(CommonDataContext);
  const careTeamList = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.PRIMARY_CARE_TEAM) || [];
  const careProgramTypes = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_TYPES) || [];
  const careTeamTypeId = getMlovIdFromCode(careTeamList, CARE_TEAM.CODE);
  const [patients, setPatients] = useState<PatientData[]>([]);
  const [patientsCareTeams, setPatientsCareTeams] = useState<any[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<PatientData | null>(null);
  const [selectedConversation, setSelectedConversation] = useState<IConversationData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState<boolean>(false);
  const [isPcpLoading, setIsPcpLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [prevDate, setPrevDate] = useState<Date | null>(null);
  const [prevUserUuid, setPrevUserUuid] = useState<string>('');
  const [filterStatusId, setFilterStatusId] = useState<string>();
  const [ribbonResize, setRibbonResize] = useState<IRibbonResize>({ count: 0, open: false });

  const [loadingActions, setLoadingActions] = useState<{ actionCode: string, contactId: string }[]>([]);

  const accountUsersRef = useRef<Map<string, IUser>>(new Map());
  const totalCountRef = useRef<number>(0);
  const toast = useCustomToast();
  const [activeDrawer, setActiveDrawer] = useState<DayOptimizerMenuItemKeys | string | null>(null);
  const [selectedBulkActionMembers, setSelectedBulkActionMembers] = useState<Map<string, PatientData>>(new Map());
  const { check } = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.HOME);
  const currentUserAllowedLocations = permissionConfig?.allowedLocationIds || [];

  const { selectedDate, columnShuffleDropDownList, gridConfigurationData, filter } = useContext(DayOptimizerContext).state.patientDashboard;
  const dispatch = useContext(DayOptimizerContext).dispatch;
  const { state: dayOptimizerState } = useContext(DayOptimizerContext);
  const commonData = useContext(CommonDataContext);
  const formattedDate = selectedDate ? formatDateForApi(selectedDate.toISOString()) : '';
  const { selectedUser } = dayOptimizerState?.patientDashboard;
  const loggedInUserUuid = getUserUUID();
  const loggedInUserId = getUserId();
  const userUuids = selectedUser?.uuid ? selectedUser?.uuid : loggedInUserUuid;
  const limit = 20;
  const minLimit = 15;
  const checked_DO_Id = getMlovId(commonData.MLOV, MLOV_CATEGORY.USER_DAY_OPTIMIZER_SCHEDULE_STATUS, MLOV_CODES.DAY_OPTIMIZER_DONE);
  const unChecked_DO_TodoId = getMlovId(commonData.MLOV, MLOV_CATEGORY.USER_DAY_OPTIMIZER_SCHEDULE_STATUS, MLOV_CODES.DAY_OPTIMIZER_TODO);
  const { getGridConfigurationDataFromAPI, putGridConfigurationData, postGridConfigurationData } = useGridConfigurations();
  const [typeNodes, setTypeNodes] = React.useState<any>();
  const pcpColumn = columnShuffleDropDownList?.find((column: any) => column?.columnCode === DayOptimizerColumnCode.PCP)
  const tagsColumn = columnShuffleDropDownList?.find((column: any) => column?.columnCode === DayOptimizerColumnCode.TAGS)
  const contactTypeId = getContactTypeId('CUSTOMER');

  const isPcpColumnVisible = React.useMemo(() => {
    const isPcpColumnVisible = pcpColumn?.isVisible === true;
    return isPcpColumnVisible;
  }, [pcpColumn]);

  const isTagsColumnVisible = React.useMemo(() => {
    return tagsColumn?.isVisible === true;
  }, [tagsColumn]);

  const updatePatients = async (contactUuids?: string[]) => {
    if (patients && contactUuids?.length) {
      setIsLoading(true);

      try {
        const resp = await fetchPatientsDetails(contactUuids);
        setNewPatients(resp?.data, true);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  }

  const processPatients = async (resp: any, isUpdate = false): Promise<{ formattedPatients: PatientData[], newPatients: PatientData[] }> => {
    const patientList = resp?.searchContacts?.contacts || [];
    const processedPatients: PatientData[] = [];

    for (const contact of patientList) {
      const userDayOptimizerSchedule = contact?.dayOptimizerUserSchedules || [];
      const firstSchedule = userDayOptimizerSchedule[0] || {};
      const markAsCompleteStatus = firstSchedule?.statusId === checked_DO_Id ? true : firstSchedule?.statusId === unChecked_DO_TodoId ? false : null;
      const dayOptimizerRuleScoreLog = contact?.dayOptimizerUserSchedules?.[0]?.dayOptimizerRuleScoreLog;
      processedPatients.push({
        key: contact?.id,
        priority: firstSchedule?.dayOptimizerRuleScoreLog?.score > -1 ? firstSchedule?.dayOptimizerRuleScoreLog?.score : '-', // this comes under score column on qa env only
        patientId: contact?.id,
        name: contact?.name,
        firstName: contact?.person?.firstName,
        email: contact?.email,
        dayOptimizerId: firstSchedule?.id,
        uuid: contact?.uuid,
        clientId: contact?.patient?.patientId,
        patientUuid: contact?.patient?.patientUuid,
        birthDate: contact?.person?.birthDate,
        gender: contact?.person?.gender?.code,
        age: contact?.person?.birthDate && getAgeValue(contact?.person?.birthDate, '', true),
        practiceLocation: contact?.contactPracticeLocations?.[0]?.practiceLocation?.name,
        rafScore: contact?.contactScore?.hccV28,
        contactScore: (contact?.contactScore || {}) as IContactScore,
        contactScoreAudit: (contact?.contactScoreAudit || []) as IContactScoreAudit[],
        practiceLocationAccountId: contact?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
        contactType: contact?.contactType?.contactType,
        patient: contact?.patient,
        reasonForPrioritisation: undefined,
        reasonsQA: undefined,
        actions: undefined,
        actionLoading: true,
        actionCode: undefined,
        phoneNumber: contact?.phoneNumber,
        alertsCount: undefined,
        alertsLoading: true,
        markAsCompleteStatus: markAsCompleteStatus ?? false,
        pcpUserLoading: isUpdate ? false : true,
        careProgramId: undefined,
        dayOptimizerRuleScoreLog: dayOptimizerRuleScoreLog,
        eligiblePrograms: undefined,
        contactPriorityRank: contact?.contactPriorityNumber?.rank
      });
    }

    const existingPatientIds = new Set(patients.map(patient => patient.patientId));
    const newPatients = processedPatients.filter((patient: any) => !existingPatientIds.has(patient.patientId));

    if (isPcpColumnVisible && !isUpdate) {
      getPcpUsersData(newPatients);
    }

    if (isTagsColumnVisible) {
      getContactTagsData(newPatients);
    }

    const patientsToUpdate = isUpdate ? processedPatients : newPatients;
    if (patientsToUpdate?.length) {
      updateDayOptimizerActionsAndReasons(patientsToUpdate);
      updateDayOptimizerAlerts(patientsToUpdate);
      updateEligibleCarePrograms(patientsToUpdate);
    }
    return { formattedPatients: processedPatients, newPatients };
  }

  const getAlertDisplayCategoryId = () => {
    try {
      const taskAlertDisplayCategoryMlovs = getMlovListFromCategory(
        commonData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.TASK_ALERT_DISPLAY_CATEGORIES
      ) || [];
      const alertDisplayCategoryId = getMlovIdFromCode(
        taskAlertDisplayCategoryMlovs,
        TASK_ALERT_CATEGORY_CODES.PENDING
      );
      return alertDisplayCategoryId;
    } catch (error) {
      return null;
    }
  };

  const [getSelectedAccountUsers] = useLazyQuery(GET_SELECTED_USERS, {
    fetchPolicy: 'no-cache',
  });

  const [getCareTeamMemberPcpType] = useLazyQuery(
    GET_CARE_TEAM_MEMBER_PCP_TYPE,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const [getContactTags] = useLazyQuery(GetContactTags, {
    fetchPolicy: 'no-cache',
  });

  const [GetConversationByConversationId] = useLazyQuery<INewConversationResponse>(
    ConversationsQueries.GetConversationByConversationId,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const filterPatientFromTable = (contactId: string) => {
    const { updatedList } = removePatientFromList(contactId, patients);
    if (updatedList) {
      setPatients(() => updatedList);
    }
  }

  const filterBulkPatientFromTable = (uuids: string[]) => {
    const { updatedList } = removeBulkPatientsFromList(uuids, patients);
    if (updatedList) {
      setPatients(() => updatedList);
    }
  }

  const handleMovePatientToDateError = () => {
    showToast(
      toast,
      intl.formatMessage({ id: 'errorInMovingMember' }),
      ToastType.error,
      3000,
      true
    );
  }

  const movePatientToDateHandler = async (record: any, moveToDate: Date) => {
    try {
      const date = formatDateForApiFromDate(moveToDate);
      await updatePatientDate(record?.dayOptimizerId, date);
      showToast(
        toast,
        intl.formatMessage({ id: 'memberMovedSuccessfully' }),
        ToastType.success,
        3000,
        true
      );
      filterPatientFromTable(record?.patientId);
      setOffset((offset) => (offset - 1));
    } catch {
      handleMovePatientToDateError();
    }
  }

  const moveBulkPatientsToDateHandler = async (moveToDate: Date) => {
    try {
      const date = formatDateForApiFromDate(moveToDate);
      const users = Array.from(selectedBulkActionMembers.values());
      const dayOptimizerIds = users.map((patient) => patient?.dayOptimizerId);
      await bulkUpdatePatientDate(dayOptimizerIds as string[], date);
      // const isUpdated = await new Promise((resolve) => setTimeout(() => resolve(true), 300));
      showToast(
        toast,
        intl.formatMessage({ id: 'membersMovedSuccessfully' }),
        ToastType.success,
        3000,
        true
      );
      filterBulkPatientFromTable(users.map((patient) => patient?.uuid));
      setOffset((offset) => (offset - selectedBulkActionMembers.size));
      setSelectedBulkActionMembers(new Map());
    } catch {
      handleMovePatientToDateError();
    }
  }

  const fetchAccountUsers = async (userUuids: string[]) => {
    const removeExistingUsers = userUuids?.map((userUuid: string, index: number) => {
      if (accountUsersRef.current?.has(userUuid)) {
        return '';
      }
      return userUuid;
    });

    const filteredUserUuids = removeExistingUsers?.filter((userUuid: string) => userUuid);

    if (filteredUserUuids?.length === 0) return;

    const accountData = await getSelectedAccountUsers({
      variables: {
        userUuids: filteredUserUuids,
        roleCode: USER_ROLE_CODES.EMPLOYER
      }
    });
    const accountUsers: IUser[] = accountData?.data?.users || [];

    accountUsers?.forEach((user: IUser) => {
      if (!accountUsersRef.current?.has(user.uuid)) {
        accountUsersRef.current?.set(user.uuid, user);
      }
    });
  }

  const mapCareTeamMemberToUser = (careTeams: any, newPatients: any[]) => {
    const pcpUsers = new Map<string, IUser>();
    careTeams?.forEach((careTeam: any) => {
      return careTeam?.careTeamMemberType?.forEach((careTeamMember: any) => {
        const pcpUser = accountUsersRef.current?.get(careTeamMember?.userId);
        if (pcpUser) {
          pcpUsers.set(careTeam?.contactId, pcpUser);
        }
      })
    })
    const mappedPcpPatients = newPatients?.map((patient: any) => {
      const pcpUser = pcpUsers.get(patient?.uuid);
      if (pcpUser) {
        patient.pcpUser = pcpUser;
      } else {
        patient.pcpUser = null;
      }
      return patient;
    })

    setPatients(prevPatients => {
      return prevPatients?.map((patient: PatientData) => {
        const mappedPatient: any = mappedPcpPatients?.find((p: any) => p?.uuid === patient?.uuid);
        if (mappedPatient) {
          const updatedPatient = {
            ...patient,
            pcpUser: mappedPatient.pcpUser,
            pcpUserLoading: false
          }
          return updatedPatient;
        } else {
          return patient;
        }
      });
    });
  }

  const getPcpUsersData = async (patientsList = patients) => {
    const patientsUuids = patientsList?.map((patient: any) => patient?.uuid)
    if (patientsUuids?.length === 0) return;
    const careTeamMemberPcpTypeResponse = await getCareTeamMemberPcpType({
      variables: {
        contactIds: patientsUuids,
        careTeamTypeId: careTeamTypeId,
      }
    })
    const careTeams = careTeamMemberPcpTypeResponse?.data?.careTeams;
    const pcpUserUuids = careTeams?.map((careTeam: any) => {
      const pcpUserUuidList = careTeam?.careTeamMemberType?.map((careTeamMember: any) => {
        return careTeamMember?.userId
      })
      return pcpUserUuidList?.[0];
    });
    const uniquePcpUserUuids = [...new Set(pcpUserUuids)] as string[];
    await fetchAccountUsers(uniquePcpUserUuids || []);
    mapCareTeamMemberToUser(careTeams, patientsList);
  }

  const getContactTagsData = async (patientsList: PatientData[]) => {
    try {
      const contactIds = patientsList?.map((patient: any) => patient?.patientId);
      const contactTagsResponse = await getContactTags({ variables: { contactIds: contactIds } });
      const contactTags = contactTagsResponse?.data?.labelTaggings;

      if (contactTags?.length > 0) {
        setPatients((patients) => {
          return patients.map((item: any) => {
            const tags = item.tags || contactTags.filter((tag: any) => tag.taggableId === item.patientId) || []
            return {
              ...item,
              tags: tags,
            };
          });
        })
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const fetchColumnConfiguration = useCallback(async () => {
    const columnResponse = await getGridConfigurationDataFromAPI(DAY_OPTIMIZER_TABLE_CODE, DAY_OPTIMIZER_ENTITY_CODE);
    if (columnResponse?.gridColumns && Array.isArray(columnResponse?.gridColumns)) {
      const sortedGridColumns = [...columnResponse?.gridColumns].sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0));
      dispatch({ type: GRID_CONFIGURATION_DATA, payload: { ...columnResponse, gridColumns: sortedGridColumns } });
      return columnResponse;
    } else {
      showToast(
        toast,
        'Error in fetching column configuration!',
        ToastType.error,
        3000,
        true
      );
      console.warn('Invalid column configuration received:', columnResponse);
      return {};
    }
  }, []);

  const [getActiveCareProgramsByCareProgramTypeId] = useLazyQuery(GET_ACTIVE_CARE_PROGRAMS_BY_CARE_PROGRAM_TYPE_ID,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    }
  );

  const generateReasonAndAction = (factor: string, value: any, contact: any, contactCarePrograms: any[]) => {
    const description = DAY_OPTIMIZER_RULE_SCORE_FACTOR_DESCRIPTION[factor as keyof typeof DAY_OPTIMIZER_RULE_SCORE_FACTOR_DESCRIPTION];
    let careProgramId = null;
    let finalDescription = '';
    let action = intl.formatMessage({ id: 'viewPatient' });

    const actionType = getActionCode([[factor, value]]).toUpperCase();

    const careProgram = contactCarePrograms?.find(program => program.contactId === contact.uuid);
    if (actionType === CARE_PROGRAM_TYPE_CODES.CCM && ['ACTIVE_CCM_CONTACT_CARE_PROGRAM_WITH_STATUS_NEW_AND_IN_PROGRESS'].includes(factor)) {
      const isInProgress = careProgram?.status?.code === CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS;
      finalDescription = isInProgress
        ? `${contact?.firstName || 'Patient'} has a follow-up scheduled for CCM.`
        : `${contact?.firstName || 'Patient'} meets CCM eligibility and has consented to enrollment.`;
      action = isInProgress ? 'Follow-up' : 'Review CCM';
    } else if (description) {
      const variables = description?.getVariables(contact, careProgram);
      finalDescription = description?.template as string;

      Object.entries(variables)?.forEach(([key, value]) => {
        finalDescription = finalDescription?.replace(`{{${key}}}`, String(value));
      });

      if ('action' in description) {
        action = description?.action;
      }
    }

    if (careProgramTypeArray.includes(actionType)) {
      const contactPrograms = contactCarePrograms?.filter((program: any) =>
        program.contactId === contact.uuid &&
        careProgramTypeArray.includes(program?.payerCareProgram?.careProgramType?.code)
      );

      if (contactPrograms?.length !== 0) {
        const matchingProgram = contactPrograms?.find(program =>
          program?.payerCareProgram?.careProgramType?.code === actionType
        );
        if (matchingProgram) {
          careProgramId = matchingProgram?.id;
        }
      }
    }

    return {
      reason: finalDescription,
      action,
      careProgramId
    };
  };

  const generateReasonAndActionForQAEnv = (factor: string, value: any, contact: any, contactCarePrograms: any[]) => {
    const description = DAY_OPTIMIZER_RULE_SCORE_FACTOR_DESCRIPTION_FOR_QA_ENV[factor as keyof typeof DAY_OPTIMIZER_RULE_SCORE_FACTOR_DESCRIPTION_FOR_QA_ENV] || factor;
    const score = value?.score;
    let action;
    let careProgramId = null;
    let finalDescription = '';

    if (typeof description === 'object') {
      const careProgram = contactCarePrograms?.find(program => program.contactId === contact.uuid);
      const variables = description?.getVariables(contact, careProgram);
      finalDescription = description?.template as string;

      Object.entries(variables)?.forEach(([key, value]) => {
        finalDescription = finalDescription?.replace(`{{${key}}}`, String(value));
      });

      if ('action' in description) {
        action = description.action;
      }
    } else {
      finalDescription = description as string;
    }

    if (!action) {
      const actionType = getActionCode([[factor, value]]).toUpperCase();

      if (careProgramTypeArray.includes(actionType)) {
        const contactPrograms = contactCarePrograms?.filter((program: any) =>
          program.contactId === contact.uuid &&
          careProgramTypeArray.includes(program?.payerCareProgram?.careProgramType?.code)
        );

        if (contactPrograms?.length === 0) {
          action = `Initiate ${actionType}`;
        } else {
          const program = contactPrograms?.[0];
          const programStatus = program?.status?.code;
          careProgramId = program?.id;

          if (programStatus === CONTACT_CARE_PROGRAM_STATUS_CODES.NEW) {
            action = `Review ${actionType}`;
          } else if (programStatus === CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS) {
            action = 'Follow-up';
          } else {
            action = `Review ${actionType}`;
          }
        }
      } else {
        action = intl.formatMessage({ id: 'viewPatient' });
      }
    }

    return {
      reason: `(${score}) - ${finalDescription}`,
      action,
      careProgramId
    };
  };

  const updateDayOptimizerActionsAndReasons = async (contacts: any[]) => {
    const contactIds = contacts.map(contact => contact?.uuid);

    const careProgramStatusMlovs =
      getMlovListFromCategory(
        commonData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.CONTACT_CARE_PROGRAM_STATUS
      ) || [];

    const activeCareProgramIds = careProgramStatusMlovs
      .filter(status => [CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS, CONTACT_CARE_PROGRAM_STATUS_CODES.NEW].includes(status.code))
      .map(status => status.id);

    const contactCareProgramsResponse = await getActiveCareProgramsByCareProgramTypeId({
      variables: {
        contactId: contactIds,
        statusIds: activeCareProgramIds
      }
    });

    const contactCarePrograms = contactCareProgramsResponse?.data?.contactCarePrograms || [];

    const newPatientsData = contacts?.map(contact => {
      const dayOptimizerRuleScoreLog = contact?.dayOptimizerRuleScoreLog;
      if (!dayOptimizerRuleScoreLog) {
        return {
          patientId: contact?.patientId,
          actions: intl.formatMessage({ id: 'viewPatient' }),
          careProgramId: undefined,
          actionCode: '',
          reasonForPrioritisation: [],
          reasonsQA: [],
          actionLoading: false
        };
      }

      let reasonsQA;
      if (evnName() === QA_ENVIRONMENT) {
        const mergedScoreFactors = {
          ...dayOptimizerRuleScoreLog?.scoreByRuleFactors?.mainScoreByRuleFactors?.scoresByFactor,
          ...dayOptimizerRuleScoreLog?.scoreByRuleFactors?.genericScoreByRuleFactors?.scoresByFactor
        };
        const sortedFactorsQA = Object.entries(mergedScoreFactors || {})
          .filter(([_, value]) => (value as any)?.score > 0)
          .sort((a, b) => (b[1] as any)?.score - (a[1] as any)?.score);

        reasonsQA = sortedFactorsQA?.map(([factor, value]) =>
          generateReasonAndActionForQAEnv(factor, value, contact, contactCarePrograms).reason
        );
        reasonsQA = reasonsQA?.filter(reason => reason !== "");
      }


      const sortedFactors = Object.entries(dayOptimizerRuleScoreLog?.scoreByRuleFactors?.mainScoreByRuleFactors?.scoresByFactor || {})
        .filter(([_, value]) => (value as any)?.score > 0)
        .sort((a, b) => (b[1] as any)?.score - (a[1] as any)?.score);

      if (!sortedFactors?.length) {
        return {
          patientId: contact?.patientId,
          actions: intl.formatMessage({ id: 'viewPatient' }),
          careProgramId: undefined,
          actionCode: '',
          reasonForPrioritisation: [],
          reasonsQA: reasonsQA,
          actionLoading: false
        };
      }

      const topFactor = sortedFactors[0];
      const { reason, action, careProgramId } = generateReasonAndAction(topFactor[0], topFactor[1], contact, contactCarePrograms);

      const reasons = sortedFactors?.map(([factor, value]) =>
        generateReasonAndAction(factor, value, contact, contactCarePrograms).reason
      ).filter(reason => reason !== "");

      return {
        patientId: contact?.patientId,
        actions: action,
        careProgramId,
        actionCode: getActionCode([topFactor]),
        reasonForPrioritisation: reasons,
        reasonsQA,
        actionLoading: false
      };
    });

    setPatients(prevPatients =>
      prevPatients.map(patient => {
        const updatedPatient = newPatientsData.find(up => up.patientId === patient.patientId);
        return updatedPatient ? { ...patient, ...updatedPatient } : { ...patient, actionLoading: false };
      })
    );
  }
  const [getTaskCount] = useLazyQuery(GET_TASK_COUNTS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onCompleted: (apiResponse) => {
      try {
        const filterAggregate = apiResponse?.getTaskAggregateByFilters?.filterAggregate || [];
        setPatients(prevPatients =>
          prevPatients?.map(patient => {
            const patientCount = filterAggregate?.find((item: any) => item?.filterKey === patient?.uuid);
            if (patientCount) {
              return {
                ...patient,
                alertsCount: patientCount.aggregate || 0,
                alertsLoading: false
              };
            }
            return patient;
          })
        );
      } catch (error) {
        console.error('Error processing task count response:', error);
        showErrorToast('Failed to process task counts');
      }
    }
  });

  const updateDayOptimizerAlerts = async (contacts: any[]) => {
    try {
      const alertDisplayCategoryId = await getAlertDisplayCategoryId();

      const requestPayload = contacts?.map(contact => ({
        filterKey: contact?.uuid,
        filters: {
          assigneeIds: [userUuids],
          includeAlerts: true,
          includeOnlyAlerts: true,
          contactIds: [contact?.uuid],
          taskAlertDisplayCategoryIds: [alertDisplayCategoryId]
        }
      }));

      getTaskCount({
        variables: {
          params: {
            filterList: requestPayload
          }
        },
      });
    } catch (error) {
      console.error('Error updating day optimizer alerts:', error);
      showErrorToast('Failed to update alerts');
    }
  }

  const [getEligibleCarePrograms] = useLazyQuery(GET_ALLOWED_CARE_PROGRAM_TYPES_FOR_CONTACTS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onCompleted: (apiResponse) => {
      try {
        const allowedPrograms = apiResponse?.getAllowedCareProgramTypesForContacts?.allowedCareProgramTypesForContacts || [];

        setPatients(prevPatients =>
          prevPatients?.map(patient => {
            const patientPrograms = allowedPrograms.find(
              (item: any) => item?.contactId === patient?.uuid
            );

            if (patientPrograms) {
              const eligibleProgramCodes = patientPrograms?.allowedCareProgramTypes?.map(
                (program: any) => program.careProgramTypeCode
              );

              const matchedPrograms = eligibleProgramCodes?.map((code: string) => {
                const matchedProgram = careProgramTypes.find(
                  (programType: any) => programType.code === code
                );
                return matchedProgram?.value || code;
              });

              return {
                ...patient,
                eligiblePrograms: matchedPrograms || []
              };
            }
            return patient;
          })
        );
      } catch (error) {
        console.error('Error processing care program response:', error);
        showErrorToast('Failed to process care programs');
      }
    }
  });

  const updateEligibleCarePrograms = async (contacts: any[]) => {
    try {
      const contactIds = contacts.map(contact => contact?.uuid);

      getEligibleCarePrograms({
        variables: {
          params: {
            contactIds
          }
        },
      });
    } catch (error) {
      console.error('Error updating eligible care programs:', error);
      showErrorToast('Failed to update care programs');
    }
  }

  const getActionCode = (sortedFactors: [string, any][]) => {
    if (sortedFactors.length === 0) return '';
    const topFactor = sortedFactors[0][0];
    return Object.entries(FACTOR_TO_ACTION_MAP).find(([_, factors]) => factors.includes(topFactor))?.[0] || '';
  };


  const [getPatients] = useLazyQuery(LeadQueries.searchDayOptimizerPatients, {
    fetchPolicy: 'no-cache',
    onCompleted: async (resp: any) => {
      try {
        const { formattedPatients, newPatients } = await processPatients(resp);

        setPatients(prevPatients => addNewPatientsToState(prevPatients, newPatients, true));

        setOffset(prevOffset => prevOffset + formattedPatients.length);

        totalCountRef.current = resp?.searchContacts?.contactAggregate?.aggregate?.count || 0;
        setHasMore(totalCountRef.current > offset + formattedPatients.length);
        setIsLoading(false);
      } catch (error) {
        console.error('Error processing patients:', error);
        showErrorToast('Failed to process patients');
        setIsLoading(false);
      }
    },
    onError: (error) => {
      console.error('Error fetching patients:', error);
      showErrorToast('Failed to fetch patients');
      setIsLoading(false);
    }
  });

  const showErrorToast = (message: string) => {
    showToast(
      toast,
      message,
      ToastType.error,
      3000,
      true
    );
  }

  const sortPatientsBasedOnName = (orderType: string, patientsList?: PatientData[]) => {
    if (patientsList) {
      return patientsList.sort((a, b) => {
        return orderType === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
      });
    } else {
      setPatients((prevPatients) => {
        return prevPatients.sort((a, b) => {
          return orderType === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        });
      });
    }
  }

  const sortPatientsBasedOnPriority = (orderType: string, patientsList?: PatientData[]) => {
    if (patientsList) {
      return patientsList.sort((a, b) => {
        const priorityA = a.contactPriorityRank || 0;
        const priorityB = b.contactPriorityRank || 0;
        return orderType === 'asc' ? priorityA - priorityB : priorityB - priorityA;
      });
    } else {
      setPatients((prevPatients) => {
        return prevPatients.sort((a, b) => {
          const priorityA = a.contactPriorityRank || 0;
          const priorityB = b.contactPriorityRank || 0;
          return orderType === 'asc' ? priorityA - priorityB : priorityB - priorityA;
        });
      });
    }
  }

  const sortPatientsBasedOnRAFScore = (orderType: string, patientsList?: PatientData[]) => {
    if (patientsList) {
      return patientsList.sort((a, b) => {
        const rafScoreA = parseInt(a.rafScore || '0');
        const rafScoreB = parseInt(b.rafScore || '0');
        return orderType === 'asc' ? rafScoreA - rafScoreB : rafScoreB - rafScoreA;
      });
    } else {
      setPatients((prevPatients) => {
        return prevPatients.sort((a, b) => {
          const rafScoreA = parseInt(a.rafScore || '0');
          const rafScoreB = parseInt(b.rafScore || '0');
          return orderType === 'asc' ? rafScoreA - rafScoreB : rafScoreB - rafScoreA;
        });
      });
    }
  }

  const sortPatients = (patientsList?: PatientData[]) => {
    const orderType = gridConfigurationData.currentSortingValue || 'desc';
    const columnId = gridConfigurationData.currentSortingOrderColumnId;
    const column = gridConfigurationData.gridColumns?.find((column: any) => column.id === columnId) || {};
    const columnCode = column?.columnCode;

    switch (columnCode) {
      case DayOptimizerColumnCode.NAME:
        return sortPatientsBasedOnName(orderType, patientsList);
      case DayOptimizerColumnCode.PRIORITY:
        return sortPatientsBasedOnPriority(orderType, patientsList);
      case DayOptimizerColumnCode.RAF_SCORE:
        return sortPatientsBasedOnRAFScore(orderType, patientsList);
      default:
        return sortPatientsBasedOnRAFScore(orderType, patientsList);
    }
  }

  const addNewPatientsToState = (prevPatients: PatientData[], newPatients: PatientData[], preventSorting = false) => {
    const newList = [...prevPatients, ...newPatients]
    const uniqueList = Array.from(new Set(newList.map(patient => patient.uuid)))
      .map(uuid => {
        return newList.find(patient => patient.uuid === uuid)
      });
    if (preventSorting) {
      return uniqueList as PatientData[] || prevPatients;
    }
    const sortedList = sortPatients(uniqueList as PatientData[] || []);
    return sortedList || prevPatients;
  }

  const setNewPatients = async (resp: any, updatePatients = false) => {
    const { formattedPatients, newPatients } = await processPatients(resp, updatePatients);
    if (formattedPatients?.length) {
      if (updatePatients) {
        setPatients((prevPatients) => {
          return prevPatients.map((patient: PatientData) => {
            const contactUuid = patient?.uuid;
            const updatePatient = formattedPatients.find((contact: any) => contact?.uuid === contactUuid);
            if (updatePatient) {
              return { ...patient, ...updatePatient };
            }
            return patient;
          });
        });
      } else {
        setPatients((prevPatients) => {
          return addNewPatientsToState(prevPatients, newPatients);
        });
      }
    }
  }

  const [getPatientsDetails] = useLazyQuery(LeadQueries.searchDayOptimizerPatients, {
    fetchPolicy: 'no-cache',
  });

  const getOrderByValueFromConfig = (columnResponse: any) => {
    const defaultOrderBy = [{
      dayOptimizerUserSchedules: { dayOptimizerRuleScoreLog: { score: { sort: 'desc', nulls: 'last' } } }
    }];

    const getColumnCode = (id: string): string | undefined =>
      columnResponse?.gridColumns?.find((column: any) => column.id === id)?.columnCode;

    const currentColumnCode = columnResponse?.currentSortingOrderColumnId &&
      getColumnCode(columnResponse.currentSortingOrderColumnId);
    if (currentColumnCode) {
      return getOrderByValue(currentColumnCode, columnResponse.currentSortingValue);
    }
    const defaultColumnCode = columnResponse?.defaultSortingOrderColumnId &&
      getColumnCode(columnResponse.defaultSortingOrderColumnId);
    if (defaultColumnCode) {
      return getOrderByValue(defaultColumnCode, columnResponse.defaultSortingValue);
    }
    return defaultOrderBy;
  }

  const fetchPatients = async (formattedDate?: string, reset = false, offsetNumber?: number, orderBy?: any) => {
    let offsetStateNumber = 0;

    setOffset((prev) => {
      offsetStateNumber = prev
      return prev
    });

    let isLoadingState = false;
    setIsLoading((prev) => {
      isLoadingState = prev
      return prev
    });

    if (isLoadingState) {
      return;
    }

    const offset = offsetNumber !== undefined ? offsetNumber : offsetStateNumber

    if ((!hasMore && totalCountRef.current > offset) && offset !== 0) {
      return;
    }
    if (reset) {
      setPatients([]);
      setOffset(0);
    }

    setIsLoading(true);
    let columnResponse = gridConfigurationData;
    if (Object.keys(gridConfigurationData)?.length === 0) columnResponse = await fetchColumnConfiguration();
    const orderByValue = orderBy || getOrderByValueFromConfig(columnResponse);
    const updatedOrderByValue = [...orderByValue, {
      "id": "asc"
    }]
    getPatients({
      variables: {
        params: {
          limit: limit,
          offset: reset ? 0 : offset,
          dayOptimizerUserScheduleSelectFilters: {
            date: formattedDate,
            userUuids: [userUuids],
            ...(filter?.statusId && {
              statusIds: [filter?.statusId]
            })
          },
          dayOptimizerUserScheduleFilters: {
            date: formattedDate,
            userUuids: [userUuids],
            ...(filter?.statusId && {
              statusIds: [filter?.statusId]
            })
          },
          orderBy: updatedOrderByValue,
          contactTypeIds: [contactTypeId]
        }
      },
    });
  };

  const getOrderByValue = (columnCode: string, order: string) => {
    const defaultOrderBy = [
      { dayOptimizerUserSchedules: { dayOptimizerRuleScoreLog: { score: { sort: 'desc', nulls: 'last' } } } },
      { contactPriorityNumber: { rank: 'desc' } }
    ];
    if (!order) {
      return defaultOrderBy;
    }

    switch (columnCode) {
      case DayOptimizerColumnCode.PRIORITY:
        return [{ contactPriorityNumber: { rank:  order } }];
      case DayOptimizerColumnCode.RAF_SCORE:
        return [{ contactScore: { hccV28: { sort: order, nulls: 'last' } } }];
      case DayOptimizerColumnCode.NAME:
        return [{ name: order }];
      default:
        return defaultOrderBy;
    }
  };

  const handleSortChange = (columnCode: string, order: string) => {
    const columnCodeMapping: { [key: string]: string } = {
      'rafScore': DayOptimizerColumnCode.RAF_SCORE,
      'name': DayOptimizerColumnCode.NAME,
      'priority': DayOptimizerColumnCode.PRIORITY,
      'score': DayOptimizerColumnCode.SCORE
    };

    const updatedColumnCode = columnCodeMapping[columnCode.toLowerCase()] || columnCode;

    const orderBy = getOrderByValue(updatedColumnCode, order);
    fetchPatients(formattedDate, true, 0, orderBy);
    handleApplyForColumnFilter(updatedColumnCode, order);
  };

  const handleApplyForColumnFilter = async (columnCode: string, order: string) => {
    try {
      const columnToUpdate = columnShuffleDropDownList?.find((column: any) => column?.columnCode === columnCode);
      if (gridConfigurationData?.accountUuid && gridConfigurationData?.userUuid) {
        if (columnToUpdate) {
          const data = {
            gridSortColumnId: columnToUpdate?.id,
            gridSortingValue: order
          }
          const response = await putGridConfigurationData(gridConfigurationData?.id, undefined, data);
          dispatch({ type: GRID_CONFIGURATION_DATA, payload: response });
        }
      } else {
        let sortingData = {}
        if (columnToUpdate) {
          sortingData = {
            gridSortColumnCode: columnToUpdate?.columnCode,
            gridSortingValue: order
          }
        }
        const response = await postGridConfigurationData(
          DAY_OPTIMIZER_TABLE_CODE,
          DAY_OPTIMIZER_ENTITY_CODE,
          columnShuffleDropDownList as IGridColumn[],
          sortingData as { gridSortColumnCode: string; gridSortingValue: string }
        );
        dispatch({ type: GRID_CONFIGURATION_DATA, payload: response });
      }
    } catch (error) {
      console.error('Error applying column filter:', error);
    }
  };

  const [updatePatientInDayOptimizer] = useMutation(LeadQueries.addPatientToDayOptimizer);
  const [bulkUpdateDateForDayOptimizerSchedulesInput] = useMutation(LeadQueries.bulkUpdateDateForDayOptimizerSchedulesInput);

  const updatePatientDate = useCallback(async (dayOptimizerId: string, date: string) => { // date is in format yyyy-mm-dd
    const response = await updatePatientInDayOptimizer({
      variables: {
        params: {
          date: date,
          id: dayOptimizerId
        }
      }
    });
    const isUpdated = response?.data?.addOrUpdateUserDayOptimizerSchedule?.id ? true : false;
    return isUpdated;
  }, []);

  const bulkUpdatePatientDate = useCallback(async (dayOptimizerIds: string[], date: string) => { // date is in format yyyy-mm-dd
    const response = await bulkUpdateDateForDayOptimizerSchedulesInput({
      variables: {
        params: {
          date: date,
          scheduleIds: dayOptimizerIds,
          userUuid: userUuids
        }
      }
    });
    const isUpdated = response?.data?.bulkUpdateDateForDayOptimizerSchedules?.success ? true : false;
    return isUpdated;
  }, [userUuids]);

  const updatePatientStatus = async (dayOptimizerId: string, isChecked: boolean,) => {
    try {
      const statusId = checked_DO_Id;
      const response = await updatePatientInDayOptimizer({
        variables: {
          params: {
            statusId,
            id: dayOptimizerId
          }
        }
      });
      const isUpdated = response?.data?.addOrUpdateUserDayOptimizerSchedule?.id ? true : false;
      setPatients((prev) => {
        const udpatedPatientList = prev.map((patient) => {
          if (patient.dayOptimizerId === dayOptimizerId) {
            patient.markAsCompleteStatus = isUpdated
          }
          return patient;
        })
        return udpatedPatientList
      })

      showToast(
        toast,
        intl.formatMessage({ id: 'patientStatusUpdated' }),
        ToastType.success,
        3000,
        true
      );

      return isUpdated;
    } catch (error) {
      showToast(
        toast,
        intl.formatMessage({ id: 'errorInUpdatingPatientStatus' }),
        ToastType.error,
        3000,
        true
      );
      return false;
    }
  }

  const commonActionOnRecord = (action: DayOptimizerMenuItemKeys | string, record: any) => {
    setSelectedPatient(record);
    setActiveDrawer(action);
  }

  const commonActionOnClose = () => {
    setSelectedPatient(null);
    setActiveDrawer(null);
  }

  let urlPath = ""

  const onMenuItemActionPerformed = async (
    action: DayOptimizerMenuItemKeys,
    record: any,
    popover?: { open: boolean, closePopover: () => void }
  ) => {
    switch (action) {
      case DayOptimizerMenuItemKeys.ADD_RELATIVES:
      case DayOptimizerMenuItemKeys.EDIT_PATIENT:
      case DayOptimizerMenuItemKeys.ADD_TASK:
      case DayOptimizerMenuItemKeys.CONTACT_TAG_VIEW:
      case DayOptimizerMenuItemKeys.RUN_AUTOMATION:
      case DayOptimizerMenuItemKeys.SEND_ASSESSMENT:
      case DayOptimizerMenuItemKeys.SEND_EDUCATION:
      case DayOptimizerMenuItemKeys.ADD_FAMILY_MEMBERS:
        if (record) {
          commonActionOnRecord(action, record);
        } else {
          commonActionOnClose();
        }
        break;
      case DayOptimizerMenuItemKeys.SCHEDULE_APPOINTMENT:
        if (record) {
          commonActionOnRecord(action, record);
        } else {
          commonActionOnClose();
          const eventBus = EventBus.getEventBusInstance();
          eventBus.broadcastEvent(CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT, userUuids);
        }
        break;
      case DayOptimizerMenuItemKeys.ADD_TO_TODAY:
        setLoadingActions([{ actionCode: DayOptimizerMenuItemKeys.ADD_TO_TODAY, contactId: record.patientId }]);
        await movePatientToDateHandler(record, new Date());
        setLoadingActions([]);
        break;
      case DayOptimizerMenuItemKeys.MOVE_TO_NEXT_DAY:
        setLoadingActions([{ actionCode: DayOptimizerMenuItemKeys.MOVE_TO_NEXT_DAY, contactId: record.patientId }]);
        await movePatientToDateHandler(record, getNextDayFromDate(selectedDate, 1));
        setLoadingActions([]);
        break;
      case DayOptimizerMenuItemKeys.INITIATE_PROTOCOL_AWV:
        urlPath = `/members/patient/${record?.patientId}/careManagement/awv`;
        window.open(`${origin}/#${urlPath}`, '_blank', 'noopener,noreferrer');
        break;
      case DayOptimizerMenuItemKeys.INITIATE_PROTOCOL_TCM:
        urlPath = `/members/patient/${record?.patientId}/careManagement/tcm`;
        window.open(`${origin}/#${urlPath}`, '_blank', 'noopener,noreferrer');
        break;
      case DayOptimizerMenuItemKeys.INITIATE_PROTOCOL_CCM:
        urlPath = `/members/patient/${record?.patientId}/careManagement/ccm`;
        window.open(`${origin}/#${urlPath}`, '_blank', 'noopener,noreferrer');
        break;
      case DayOptimizerMenuItemKeys.MARK_AS_COMPLETE:
        if (popover) {
          popover.closePopover();
        }
        const markAsCompleteStatus = !record.markAsCompleteStatus;
        setPatients(prevPatients =>
          prevPatients.map(patient =>
            patient.patientId === record.patientId
              ? { ...patient, markAsCompleteStatus: !patient.markAsCompleteStatus }
              : patient
          )
        );
        const isUpdated = await updatePatientStatus(record?.dayOptimizerId, markAsCompleteStatus);
        if (!isUpdated) {
          setPatients(prevPatients =>
            prevPatients.map(patient =>
              patient.patientId === record.patientId
                ? { ...patient, markAsCompleteStatus: !patient.markAsCompleteStatus }
                : patient
            )
          );
        }
        break;
      default:
        break;
    }
  }

  const handleOnCreateNewEmailConversation = useCallback(async (conversationId: number) => {
    if (!conversationId) {
      return;
    }
    try {
      const response = await GetConversationByConversationId({
        variables: {
          conversationId: conversationId,
          loginUserIntId: loggedInUserId,
          loginUserUuid: loggedInUserUuid,
        },
      });
      const conversations = response.data?.conversations || [];
      if (conversations.length > 0) {
        const selectedConversationData = conversations[0];
        setActiveDrawer(RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER);
        setSelectedConversation(selectedConversationData)
      }
    } catch (error) {
      setActiveDrawer(null);
    }
  }, []);

  const onDayOptimizerActions = async (action: string, record: any) => {
    commonActionOnRecord(action, record);
    switch (action) {
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER:
      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP:
      case CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT:
        if (record) {
          commonActionOnRecord(action, record);
        } else {
          commonActionOnClose();
        }
        break;
      case RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER:
        if (record) {
          if (typeof record === 'number') {
            handleOnCreateNewEmailConversation(record);
          } else {
            setSelectedConversation(record);
            setActiveDrawer(action);
          }
        } else {
          setSelectedConversation(null);
          setActiveDrawer(action);
          setSelectedPatient(null);
        }
        break;
      case DayOptimizerBulkActions.TOOGLE_MEMBER_SELECTION:
        if (record) {
          setSelectedBulkActionMembers(prev => {
            const newMap = new Map(prev);
            if (newMap.has(record.uuid)) {
              newMap.delete(record.uuid);
            } else {
              newMap.set(record.uuid, record);
            }
            return newMap;
          });
        }
        break;
      case DayOptimizerBulkActions.DESELECT_ALL_MEMBERS:
        setSelectedBulkActionMembers(new Map());
        break;
      case DayOptimizerBulkActions.SELECT_ALL_MEMBERS:
        setSelectedBulkActionMembers((prev) => {
          const newMap = new Map(prev);
          patients.forEach(patient => {
            newMap.set(patient.uuid, patient);
          });
          return newMap;
        });
        break;
      case DayOptimizerBulkActions.RUN_AUTOMATION:
        setActiveDrawer(DayOptimizerBulkActions.RUN_AUTOMATION);
        break;
      case RIGHT_SIDE_CONTAINER_CODE.ASSIGN_CARE_MANAGER_VIEW:
        if (record === DayOptimizerActionStatus.OPEN) {
          setActiveDrawer(action);
        } else if (record?.actionStatus === DayOptimizerActionStatus.SUCCESS) {
          setActiveDrawer(null);
          const selectedCareManagerUuid = record?.selectedCareManager?.uuid;
          if (selectedCareManagerUuid !== userUuids) {
            filterBulkPatientFromTable(Array.from(selectedBulkActionMembers.values()).map((patient) => patient.uuid));
            setOffset((offset) => (offset - selectedBulkActionMembers.size));
          }
          setSelectedBulkActionMembers(new Map());
        } else {
          setActiveDrawer(null);
        }
        break;
      case DayOptimizerBulkActions.MOVE_TO_NEXT_DAY:
        await moveBulkPatientsToDateHandler(getNextDayFromDate(selectedDate, 1));
        break;
      case DayOptimizerBulkActions.MOVE_TO_TODAY:
        await moveBulkPatientsToDateHandler(new Date());
        break;
    }
  }

  const getNodeList = (
    taskType: typeof EDUCATION_CONTENT_NODE_DETAILS,
    allTaskType: any
  ) => {
    const tasktypeData: any = [];
    if (allTaskType.hasOwnProperty(taskType.type)) {
      const taskObject: any = {};
      taskObject[taskType.type] = allTaskType[taskType.type];
      const filteredUserInputFieldList = taskObject[
        taskType.type
      ].userInputFieldList.filter((item: any) =>
        taskType.userInputFieldListKeys.includes(item.key)
      );
      taskObject[taskType.type].userInputFieldList = [];
      taskObject[taskType.type].userInputFieldList.push(
        ...filteredUserInputFieldList
      );
      tasktypeData.push(taskObject);
    }
    return tasktypeData;
  };

  const getNodeMetaData = () => {
    setIsLoading(true);
    getNodeMetadata(
      FlowType.careJourney,
      (response) => {
        const nodeMap = response?.nodeMasterData?.nodeMap;
        const typeNodes = getNodeList(EDUCATION_CONTENT_NODE_DETAILS, nodeMap);
        const nodeType = 'CareJourneyContentV3';
        if (nodeType) {
          let typeData: any;
          if (typeNodes) {
            typeData = typeNodes.find((obj: any) => obj[nodeType]);
          }
          const userInputFieldList = typeData[nodeType].userInputFieldList;
          const { inputFields: updatedInputFieldList } =
            setFieldsVisibilityFromPreviousNode(
              userInputFieldList,
              nodeMap,
              undefined,
              FlowType.careJourney
            );

          const userInputFieldListCopy = updatedInputFieldList.map(
            (userInputField: IUserInputField) => {
              return {
                ...userInputField,
                value:
                  userInputField.value == undefined
                    ? userInputField.defaultValue
                    : userInputField.value,
              };
            }
          );
          typeData[nodeType].userInputFieldList = userInputFieldListCopy;
          setTypeNodes(typeData[nodeType]);
        }
      },
      () => {
        setTypeNodes([]);
      }
    );
  };


  const onRibbonResizeEvent = (event: IRibbonResize) => {
    setRibbonResize(event);
  }

  const onSortChangeEvent = (event: ISortChangeEvent) => {
    handleSortChange(event?.columnCode, '');
  }

  const fetchPatientsDetails = async (uuids: string[]) => {
    try {
      const dayOpPatientDetailsResponse = await getPatientsDetails({
        variables: {
          params: {
            uuids: uuids,
          }
        },
      });
      return dayOpPatientDetailsResponse;
    } catch (error) {
      return null;
    }
  }

  const onRibbonRemovePatient = async (event: IRibbonRemovePatientEventData) => {
    if (event?.contactUuid) {
      // check if the patient is already in list of not
      const patientIndex = patients.findIndex((patient) => patient.uuid === event?.contactUuid);
      if (patientIndex !== -1) {
        filterBulkPatientFromTable([event?.contactUuid]);
        setOffset((offset) => (offset - 1));
      }
    }
  }

  const onRibbonAddPatient = async (event: any) => {
    if (event?.searchContacts?.contacts.length) {
      setNewPatients(event)
    }
  }

  const fetchTableData = () => {
    if (prevDate !== selectedDate || JSON.stringify(prevUserUuid) !== JSON.stringify(userUuids) || filter?.statusId !== filterStatusId) {
      setPatients([]);
      setSelectedBulkActionMembers(new Map());
      setIsLoading(false);
      fetchPatients(formattedDate, true, 0);
      setPrevDate(selectedDate);
      setPrevUserUuid(userUuids);
      setFilterStatusId(filter?.statusId);
      setSelectedPatient(null);
      params?.onViewAction(DayOptimizerViewAction.CLEAR_VIEW);
    } else {
      setIsLoading(false);
      fetchPatients(formattedDate, false, 0);
    }
  }

  useEffect(() => {
    getNodeMetaData();
    fetchColumnConfiguration();
  }, []);

  useEffect(() => {
    fetchTableData();
  }, [formattedDate, selectedUser?.uuid, filter?.statusId]);

  useEffect(() => {
    if (isPcpColumnVisible) {
      getPcpUsersData(patients);
    }
  }, [isPcpColumnVisible]);

  useEffect(() => {
    if (isTagsColumnVisible) {
      getContactTagsData(patients);
    }
  }, [isTagsColumnVisible]);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      DAY_OPTIMIZER_EVENTS.ON_SORT_CHANGE,
      onSortChangeEvent
    );
    return () => {
      eventBus.removeEventListener(onSortChangeEvent);
    }
  }, [userUuids, columnShuffleDropDownList]);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      DAY_OPTIMIZER_EVENTS.ON_RIBBON_RESIZE,
      onRibbonResizeEvent
    );

    eventBus.addEventListener(
      DAY_OPTIMIZER_EVENTS.REMOVE_PATIENT,
      onRibbonRemovePatient
    );

    eventBus.addEventListener(
      DAY_OPTIMIZER_EVENTS.ADD_PATIENT,
      onRibbonAddPatient
    );

    return () => {
      eventBus.removeEventListener(onRibbonResizeEvent);
      eventBus.removeEventListener(onRibbonRemovePatient);
      eventBus.removeEventListener(onRibbonAddPatient);
    }
  }, [patients])

  return {
    ribbonResize,
    patients,
    isLoading,
    isPcpLoading,
    hasMore,
    selectedDate,
    fetchPatients: fetchPatients,
    formattedDate,
    updatePatientStatus,
    columnShuffleDropDownList,
    selectedPatient,
    onMenuItemActionPerformed,
    onDayOptimizerActions,
    selectedConversation,
    activeDrawer,
    updatePatients,
    handleSortChange,
    isLoadingSkeleton,
    currentSortingValue: gridConfigurationData?.currentSortingValue,
    currentSortingOrderColumnId: gridConfigurationData?.currentSortingOrderColumnId,
    loadingActions,
    typeNodes,
    selectedBulkActionMembers,
  };
};

export default useDOPatientListing;
