import { TASK_ENTITY_TYPE_CODES } from '../../constants/MlovConst';
import { TASK_SUBJECT_TYPE } from '../../constants/MlovConst';
import {ITask} from '../common/CareDashboard/CareDashboardInterfaces';
import {TASK_COMPLETED_STATUS_CODE} from '../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';

export const checkIfTaskIsCompleted = (task: ITask) => {
  return task?.status?.code === TASK_COMPLETED_STATUS_CODE;
};

export const shouldWrapWithTag = (text:string) => {
  return !/^(<[a-zA-Z]+[a-zA-Z0-9]*.*<\/[a-zA-Z]+[a-zA-Z0-9]*>|<!--.*-->)$/sg.test(text);
}

export const filterTaskEntityTypes = (taskEntityTypes: any[]) => 
  taskEntityTypes.filter((taskEntityType) => 
    !([TASK_ENTITY_TYPE_CODES.OUTREACH, TASK_SUBJECT_TYPE.PATIENT_CASE, TASK_SUBJECT_TYPE.PATIENT_REFERRAL].includes(taskEntityType.code))
  );
