import DocumentMedicineSvg from '../../../../../Svg/DayOptimization/DocumentMedicineSvg';
import NewCloseSvg from '../../../../../Svg/NewCloseSvg';
import CalenderXSvg from '../../../../../Svg/DayOptimization/CalenderXSvg';
import SleepingSvg from '../../../../../Svg/DayOptimization/SleepingSvg';
import {getDateStrFromFormatWithTimezone} from '../../../../../../../utils/DateUtils';
import {DATE_FORMATS} from '../../../../../../../constants';
import {dayOpRibbonActionCommonStyledButton} from './common';
import React from 'react';
import {
  ADT_EVENT_CODES,
  DO_RIBBON_ACTION_LOADING_STATUS,
  DO_RIBBON_ACTIONS,
} from '../../../DayOptimizerConstants';
import {IEventContainerProps} from '../../../interfaces';
import {Colors} from '../../../../../../../styles/Colors';
import {Spinner} from 'native-base';
import { TestIdentifiers } from '../../../../../../../testUtils';

const useEventContainer = (props: IEventContainerProps) => {
  const preNotificationRef = React.useRef<number>(-1);
  const [showNotification, setShowNotification] =
    React.useState<boolean>(false);

  const turnOnNotification = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 1300);
  };

  React.useEffect(() => {
    if (preNotificationRef.current !== props?.newNotification) {
      turnOnNotification();
    }
    preNotificationRef.current = props?.newNotification;
  }, [props?.newNotification]);

  const getRibbonIcon = () => {
    switch (props?.event.eventCode) {
      case ADT_EVENT_CODES.DISCHARGE:
        return <DocumentMedicineSvg />;
      case ADT_EVENT_CODES.TRANSFER:
        return <CalenderXSvg />;
      case ADT_EVENT_CODES.ADMISSION:
        return <SleepingSvg />;
    }
  };

  const getRibbonDescription = () => {
    const admissionDate = getDateStrFromFormatWithTimezone(
      props?.event?.payload?.admissionDate,
      undefined,
      DATE_FORMATS.MM_DD_YY
    );
    const dischargeDate = getDateStrFromFormatWithTimezone(
      props?.event?.payload?.dischargeDate,
      undefined,
      DATE_FORMATS.MM_DD_YY
    );
    switch (props?.event.eventCode) {
      case ADT_EVENT_CODES.ADMISSION:
        return `New Admission: ${props?.event?.payload?.contactName} admitted to ${props?.event?.payload?.facility} on ${admissionDate}`;
      case ADT_EVENT_CODES.DISCHARGE:
        return `New Discharge: ${props?.event?.payload?.contactName} discharged from ${props?.event?.payload?.facility} on ${dischargeDate}`;
      case ADT_EVENT_CODES.TRANSFER:
        return `New Transfer: ${props?.event?.payload?.contactName} transferred to ${props?.event?.payload?.destination} on ${dischargeDate}`;
    }
  };

  const closeButton = {
    showText: false,
    content: <NewCloseSvg height={16} width={16} />,
    hideContainerWhileLoading: true,
    isLoading: props?.loadingStatus?.some(
      (status) =>
        status.eventId === props?.event?.id &&
        status.status === DO_RIBBON_ACTION_LOADING_STATUS.MARK_AS_READ
    ),
    onPress: () => {
      props?.onActionPerformed(DO_RIBBON_ACTIONS.MARK_AS_READ, props?.event);
    },
    testId: TestIdentifiers.DO_eventMarkAsReadBtn
  };

  const getRibbonActionButtonsList = () => {
    const buttonList = [];
    if (
      props?.loadingStatus?.some(
        (status) =>
          status.eventId === props?.event?.id &&
          status.status === DO_RIBBON_ACTION_LOADING_STATUS.VALIDATION
      )
    ) {
      buttonList.push(<Spinner />);
      buttonList.push(dayOpRibbonActionCommonStyledButton(closeButton));
      return buttonList;
    }

    if (!props?.event?.hidePrimaryActionButton) {
      if (props?.event?.eventCode === ADT_EVENT_CODES.DISCHARGE) {
        buttonList.push(
          dayOpRibbonActionCommonStyledButton({
            text: 'Add to List',
            customHoverStyles: {
              backgroundColor: Colors.FoldPixel.PRIMARY100,
            },
            isLoading: props?.loadingStatus?.some(
              (status) =>
                status.eventId === props?.event?.id &&
                status.status === DO_RIBBON_ACTION_LOADING_STATUS.ADD_TO_LIST
            ),
            onPress: () => {
              props?.onActionPerformed(
                DO_RIBBON_ACTIONS.ADD_TO_LIST,
                props?.event
              );
            },
            testId: TestIdentifiers.DO_eventAddToTodayListBtn
          })
        );
      } else {
        buttonList.push(
          dayOpRibbonActionCommonStyledButton({
            text: 'Remove from List',
            customHoverStyles: {
              backgroundColor: Colors.FoldPixel.PRIMARY100,
            },
            isLoading: props?.loadingStatus?.some(
              (status) =>
                status.eventId === props?.event?.id &&
                status.status === DO_RIBBON_ACTION_LOADING_STATUS.REMOVE_PATIENT
            ),
            onPress: () => {
              props?.onActionPerformed(
                DO_RIBBON_ACTIONS.REMOVE_PATIENT,
                props?.event
              );
            },
            testId: TestIdentifiers.DO_eventRemoveFromTodayListBtn
          })
        );
      }
    }

    /*  buttonList.push(
      <Tooltip label="View Details" placement="top">
        {dayOpRibbonActionCommonStyledButton({
          showText: false,
          content: (
            <EyeOpenSvg
              width={16}
              height={16}
              customStrokeColor={Colors.FoldPixel.GRAY300}
            />
          ),
          onPress: () => {
            props?.onActionPerformed(
              DO_RIBBON_ACTIONS.VIEW_EVENT,
              props?.event
            );
          },
        })}
      </Tooltip>
    ); */

    buttonList.push(dayOpRibbonActionCommonStyledButton(closeButton));

    return buttonList;
  };

  return {
    showNotification,
    getRibbonIcon,
    getRibbonDescription,
    getRibbonActionButtonsList,
  };
};

export default useEventContainer;
