import { useLazyQuery, useMutation } from '@apollo/client';
import {
  FIELD_TO_ATTRIBUTE_KEY_MAP,
  IComponentState,
  IDischargeDetailsComponentValue,
  IDiagnosisCodes,
  IDischargeDetailsParams,
  Value,
  DischargeDisposition,
} from './interface';
import { GET_ADDITIONAL_ATTRIBUTES_DATA_BY_CONTACT_CARE_PROGRAM_ID } from '../../../../../../../services/ContactCareProgram/ContactCareProgram';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';
import {
  calculateLengthOfStay,
  getFormattedDiagnosisCodes,
  getSelectedDischargeDispositionOption,
} from './DischargeDetailsFormUtils';
import { getFormttedAdditionalAttributes } from './DischargeDetailsFormUtils';
import { ADDITIONAL_ATTRIBUTE_KEY } from '../../../../../../common/MemebersView/constant';
import {
  ADMISSION_TYPE,
  CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE,
} from '../../../../FormsConstants';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ADD_OR_UPDATE_STEP_ADDITIONAL_ATTRIBUTES } from '../../../../../../../services/CareProgram/CareProgramQueries';
import { IAddOrUpdateCareProgramAttributesPayload } from '../../../../../ContactCareProgram/interface';
import debounce from 'lodash/debounce';
import { cloneDeep } from 'lodash';
import { IFormCommonData } from '../../CustomWrapper/CustomWrapper';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import { CARE_PROGRAM_EVENTS, useCPEventHandlers } from '../../../../../ContactCareProgram/useCPEventhandler';
import { ContactCareProgramReducerAction } from '../../../../../ContactCareProgram/ContactCareProgramView/reducer';
import { CARE_PROGRAM_EVENT_CODES } from '../../../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import { TAdditionalAttributeValueDataTypeCodes } from '../../../../../../../services/ContactCareProgram/interface';


export const useDischargeDetailsView = (params: IDischargeDetailsParams) => {
  const {
    contactCareProgramId,
    componentSelectedValue,
    component,
    onChange,
    handleUpdateCareProgram
  } = params;

  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const complexity = contextData?.careProgramData?.complexity;
  const contactId = contextData?.contactId

  const { sync } = useCPEventHandlers({
    syncCode: `complexity-sync-${contactCareProgramId}`,
    listenCode: `complexity-listen-${contactCareProgramId}`,
    onSync: (eventType: string, event: any) => {
      if (eventType === CARE_PROGRAM_EVENT_CODES.COMPLEXITY_CHANGED) {
        setComponentState((prev) => ({
          ...prev,
          complexity: event.complexity || ''
        }))
      }
    }
  });

  const [componentValue, setComponentValue] =
    useState<IDischargeDetailsComponentValue>(
      component?.selectedValue || {
        dischargeDetails: {},
      }
    );

  const [componentState, setComponentState] = useState<IComponentState>({
    loadingStatus: 'loaded',
    diagnosisCodesLoading: false,
    sources: {},
    primaryDiagnosisOptions: [],
    dischargeDiagnosisOptions: [],
    complexity: complexity ||'',
  });


  const [getCareProgramAdditionalAttributes] = useLazyQuery(
    GET_ADDITIONAL_ATTRIBUTES_DATA_BY_CONTACT_CARE_PROGRAM_ID,
    {
      variables: {
        contactCareProgramId,
      },
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [addOrUpdateCareProgramAttributes] = useMutation(
    ADD_OR_UPDATE_STEP_ADDITIONAL_ATTRIBUTES,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const debouncedMutation = useCallback(
    debounce(async (attributeCode: string, value: any, attributeValueDataTypeCode?: TAdditionalAttributeValueDataTypeCodes) => {
      const attributeKey = FIELD_TO_ATTRIBUTE_KEY_MAP[attributeCode];
      if (!attributeKey) {
        return;
      }

      const params: IAddOrUpdateCareProgramAttributesPayload = {
        careProgramAttributes: [
          {
            contactCareProgramId: contactCareProgramId,
            subjectId: contactCareProgramId,
            subjectTypeCode: 'CARE_PROGRAM',
            additionalAttributes: [
              {
                attributeKey: attributeKey,
                attributeValueDataTypeCode: attributeValueDataTypeCode || 'string',
                attributeValue: {
                  valueString: value,
                },
                isDeleted: false,
              },
            ],
          },
        ],
      };

      await addOrUpdateCareProgramAttributes({
        variables: { params },
      });
    }, 500),
    [contactCareProgramId, addOrUpdateCareProgramAttributes]
  );

  const addOrUpdateCareProgramAttributesMutation = (
    attributeCode: string,
    value: any,
    attributeValueDataTypeCode?: TAdditionalAttributeValueDataTypeCodes
  ) => {
    debouncedMutation(attributeCode, value, attributeValueDataTypeCode);
  };

  const fetchCareProgramAdditionalAttributes = async () => {
    const response = await getCareProgramAdditionalAttributes();
    const careProgramAdditionalAttributes =
      response?.data?.contactCarePrograms?.[0]?.additionalAttributes;
    if (careProgramAdditionalAttributes) {
      const formattedAdditionalAttributes = getFormttedAdditionalAttributes(
        careProgramAdditionalAttributes
      );
      const lengthOfStay = calculateLengthOfStay(
        formattedAdditionalAttributes?.[ADDITIONAL_ATTRIBUTE_KEY.ADMITTED_DATE]
          ?.value,
        formattedAdditionalAttributes?.[ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE]
          ?.value,
        'hour'
      );
      setComponentValue((prev) => {
        return {
          ...prev,
          dischargeDetails: {
            ...prev.dischargeDetails,
            hospitalName: {
              value:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.FACILITY
                ]?.value || '',
              source:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.FACILITY
                ]?.source || CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            primaryDiagnosis: {
              value:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.PRIMARY_DIAGNOSIS_CODE
                ]?.value || [],
              source:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.PRIMARY_DIAGNOSIS_CODE
                ]?.source || CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            admissionDate: {
              value:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.ADMITTED_DATE
                ]?.value || '',
              source:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.ADMITTED_DATE
                ]?.source || CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            dischargeDate: {
              value:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE
                ]?.value || '',
              source:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE
                ]?.source || CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            admissionType: {
              value:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.ADMISSION_TYPE
                ]?.value || ADMISSION_TYPE.OBSERVATION,
              source:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.ADMISSION_TYPE
                ]?.source || CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            lengthOfStay: {
              value: lengthOfStay,
              source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            lengthOfStayUnit: {
              value: 'Hours',
              source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            dischargeDisposition: {
              value:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DISPOSITION
                ]?.value || '',
              source:
                formattedAdditionalAttributes?.[
                  ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DISPOSITION
                ]?.source || CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
            },
            selectedDischargeDispostionOption: getSelectedDischargeDispositionOption(formattedAdditionalAttributes?.[
              ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DISPOSITION
            ]?.value || '') as DischargeDisposition
          },
        };
      });
      setComponentState((prev) => ({
        ...prev,
        loadingStatus: 'loaded',
      }));
    }
  };

  const updateDischargeDetails = (
    field: keyof IDischargeDetailsComponentValue['dischargeDetails'],
    value: any,
    source: string = CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD
  ) => {
    const attributeValueDataTypeCode = (field === 'admissionDate' || field === 'dischargeDate' )? 'dateTime' : 'string';
    setComponentValue((prev) => {
      const updatedDischargeDetails = {
        ...prev.dischargeDetails,
        [field]: {
          value,
          source,
        },
      };
      if (
        (field === 'admissionDate' || field === 'dischargeDate') &&
        updatedDischargeDetails.admissionDate.value &&
        updatedDischargeDetails.dischargeDate.value
      ) {
        const lengthOfStay = calculateLengthOfStay(
          updatedDischargeDetails.admissionDate.value,
          updatedDischargeDetails.dischargeDate.value,
          'hour'
        );
        updatedDischargeDetails.lengthOfStay = {
          value: lengthOfStay,
          source: CARE_PROGRAM_ADDITIONAL_ATTRIBUTES_SOURCE.FOLD,
        };
      }
      return {
        ...prev,
        dischargeDetails: updatedDischargeDetails,
      };
    });
    addOrUpdateCareProgramAttributesMutation(field, value, attributeValueDataTypeCode);
  };

  const fetchInitialData = async () => {
    if (contactCareProgramId && !componentSelectedValue?.dischargeDetails) {
      fetchCareProgramAdditionalAttributes();
    } else {
      setComponentState((prev) => ({
        ...prev,
        loadingStatus: 'loaded',
      }));
    }
    const diagnosisCodes = await getFormattedDiagnosisCodes();
    setComponentState((prev) => ({
      ...prev,
      primaryDiagnosisOptions: diagnosisCodes,
      dischargeDiagnosisOptions: diagnosisCodes,
    }));
  };
  


  const onSearchDiagnosisCodes = async (value: string, diagnosisCodes: keyof IDiagnosisCodes) => {
    try {
      setComponentState((prev: IComponentState) => ({
        ...prev,
        diagnosisCodesLoading: true,
        [diagnosisCodes]: []
      }));

      const results = await getFormattedDiagnosisCodes(value);
      
      setComponentState((prev: IComponentState) => ({
        ...prev,
        diagnosisCodesLoading: false,
        [diagnosisCodes]: results
      }));
    } catch (error) {
      setComponentState(prev => ({
        ...prev,
        diagnosisCodesLoading: false
      }));
    }
  };

  const debouncedSearchDiagnosisCodes = useCallback(
    debounce((value: string, diagnosisCodes: keyof IDiagnosisCodes) => 
      onSearchDiagnosisCodes(value, diagnosisCodes), 
    500),
    []
  );

  const validateData = () => {
    // Add your validation logic here
    return {
      isValid: true,
      message: '',
    };
  };

  const handleRadioChange = (e: any) => {
    const value = e.target.value === componentValue?.dischargeDetails?.admissionType?.value ? undefined : e.target.value;
    updateDischargeDetails('admissionType', value);
  };

  const handleDispostionChange = (value: string) => {
    setComponentValue((prev) => {
      return {
        ...prev,
        dischargeDetails: {
          ...prev.dischargeDetails,
          selectedDischargeDispostionOption: value as DischargeDisposition
        }
      }
    })
    if (value !== Value.OTHER) {
      updateDischargeDetails('dischargeDisposition', value)
    } else {
      updateDischargeDetails('dischargeDisposition', '')
    }
  }

  const updateComplexity = (value: string) => {
    setComponentState((prev) => ({
      ...prev,
      complexity: value
    }))
  }

  const handleComplexityChange = (complexityCode: string) => {
    updateComplexity(complexityCode);
    handleUpdateCareProgram(ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_COMPLEXITY, complexityCode)
    sync(CARE_PROGRAM_EVENTS.COMPLEXITY_CHANGED as keyof typeof CARE_PROGRAM_EVENTS, {
      complexity: complexityCode,
      contactCareProgramId
    });
  }

  useEffect(() => {
    fetchInitialData();
  }, [contactCareProgramId]);

  useEffect(() => {
    if (componentState.loadingStatus === 'loaded') {
      const list = componentValue.dischargeDetails;
      onChange(cloneDeep({ dischargeDetails: list }));
    }
  }, [componentValue, componentState.loadingStatus]);

  return {
    componentValue,
    componentState,
    sync,
    handleRadioChange,
    updateDischargeDetails,
    validateData,
    addOrUpdateCareProgramAttributesMutation,
    debouncedSearchDiagnosisCodes,
    onSearchDiagnosisCodes,
    handleDispostionChange,
    handleComplexityChange
  };
};
