import {Popover, Select, Tooltip, Upload, notification} from 'antd';
import {
  FormControl,
  HStack,
  Pressable,
  Skeleton,
  Text,
  VStack,
  View
} from 'native-base';
import {useContext, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {StyleSheet} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {v4 as uuidv4} from 'uuid';
import TrashBin2Icon from '../../../../assets/Icons/TrashBin2Icon';
import {
  BUTTON_TYPE,
  DISPLAY_DATE_FORMAT,
  IS_SAVE_CLICK,
  PERSON_TYPES,
  RIGHT_SIDE_CONTAINER_CODE,
  TASK_ATTACHMENT_SOURCE,
  TASK_TYPES,
  getInActiveContactError
} from '../../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
} from '../../../../constants/ActionConst';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import {MLOV_CATEGORY, TASK_STATUS} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {Colors} from '../../../../styles';
import {
  getMomentObj,
  getMomentObjectWithDateStringAndFormat
} from '../../../../utils/DateUtils';
import {
  getBooleanFeatureFlag,
  getUserData,
  getUserEmployerId,
  getUserUUID,
  isActiveContact,
  isEmployerRole
} from '../../../../utils/commonUtils';
import {getContactTypeId, getMlovIdFromCode, getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {withMiniContactViewHOC} from '../../../MiniContactViewHOC';
import {UPLOAD_ACCEPTED_FILE_TYPES} from '../../../PersonOmniView/LeftContainer/OtherDetails/PatientDocuments/UploadPatientDocument';
import {IMediaLibraryData} from '../../../RightSideContainer/ContentManagement/MediaLibrary/interfaces';
import {MediaSelectionModal} from '../../../RightSideContainer/ContentManagement/PatientEducation/MediaSelectionModal';
import MessagingContactDetailsDrawer from '../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import {MAIN_MENU_CODES} from '../../../SideMenuBar/SideBarConst';
import '../../../common/CalendarWidget/BookingWorkflows/ScheduleSuggestor/components/ScheduleRuleMemberList/styles.scss';
import {ParticipantType} from '../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import UserAutoComplete, {
  IUserSearch,
} from '../../CalendarWidget/UserAutoComplete/UserAutoComplete';
import {IStateForHandlingPoolAfterTaskAssigneeChange, IStateForHandlingSubTaskAssigneeAfterPoolChange, ITask} from '../../CareDashboard/CareDashboardInterfaces';
import {TASK_ASSIGNEE_TYPE_CODES} from '../../CareDashboard/CareDashboardTable/CareDashboardTableHelper';
import {CARE_GAPS_TASK_STATUS_CODES_LIST, TASK_STATUS_VALUES} from '../../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import {IUserPatientSearchItem} from '../../CustomComponents/CustomUserPatientSearch/CustomUserPatientSearch.native';
import {DisplayText} from '../../DisplayText/DisplayText';
import {ModalActionAntSelect} from '../../ModalActionCommonComponent/ModalActionAntSelect';
import {ModalActionDatePicker} from '../../ModalActionCommonComponent/ModalActionDatePicker';
import {ModalActionInput} from '../../ModalActionCommonComponent/ModalActionInput';
import '../../ModalActionCommonComponent/ModalActionStyle.css';
import PatientSearchAndSelect from '../../PatientSearch/PatientSearchAndSelect';
import {RichTextEditor, TEXT_ONLY_MODULES} from '../../RichTextEditor/RichTextEditor';
import ArrowDownSvg from '../../Svg/ArrowDownSvg';
import ArrowRightSvg from '../../Svg/ArrowRightSvg';
import MediaSelectFolder from '../../Svg/MediaSelectFolder';
import PlusIcon from '../../Svg/PlusSvg';
import AddOrUpdateCommentForTask from '../AddEditComment/AddOrUpdateCommentForTask';
import {TASK_VIEW_TABS, TaskViewTabsCode} from '../AddEditComment/TaskCommentsHelper';
import {ADD_COMMENT_CONST, ADD_SUBTASK_CONST, manageAttachmentsListData, uniqueArray} from '../AddTaskUtils';
import {IAddTaskViewProps, ISubTasks, ITaskActions, ITaskCommentsList, ITaskPool, ITaskViewComponentState} from '../interfaces';
import AddSubTasks from './AddSubTasks';
import AttachmentsItemList from './AttachmentsItemList';
import {DiagnosisGapHccCodeTitleView} from './DiagnosisGapHccCodeTitleView';
import {TaskDrawerActionWithSelect} from './TaskDrawerActionWithSelect';
import {testID} from '../../../../testUtils';
const {Option} = Select;


const styles = StyleSheet.create({
  divider: {
    width: 1.5,
    marginHorizontal: 8,
    // backgroundColor: Colors.Custom.Gray400,
  },
  rightButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  hStackSpaceAround: {
    justifyContent: 'space-around',
  },
  halfWidthView: {
    width: '49%',
    paddingRight: 1,
  },
  halfWidthView2: {
    width: '24%',
    paddingRight: 1,
  },
  segmentedStyle: {
    marginTop: 2,
    marginBottom: -4,
    width: 'fit-content',
    maxWidth: '35%',
    height: 32,
    borderRadius: 4,
  },
  addAutomationContainer: {
    marginTop: 5,
    marginBottom: 2,
    alignItems: 'center',
  },
  modalButtonGroup: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalButton: {
    margin: 3,
  },
  fullWidthView: {
    width: '100%'
  },
  labelContainer: {
    marginBottom: 8,
  },
  labelText: {
    color: Colors.Custom.alertsDescriptionColor,
    fontWeight: '500',
    fontSize: 14,
  },
  labelSpacing: {
    marginBottom: 12,
  },
  descriptionLabel: {
    color: Colors.Custom.alertsDescriptionColor,
    fontWeight: '500',
    fontSize: 14,
  },
  richTextEditorContainer: {
    flexDirection: 'row',
  },
  richTextEditorWrapper: {
    flex: 1,
  },
  richTextEditor: {
    borderRadius: 4,
    borderColor: Colors.FoldPixel.GRAY150,
  },
  richTextEditorNoBorder: {
    borderRadius: 0,
  },
  attachmentButton: {
    position: 'absolute',
    top: 3,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  attachmentButtonSidecar: {
    right: 16,
  },
  attachmentButtonWeb: {
    right: 20,
  },
});


const AddOrUpdateAlertForm = (props: IAddTaskViewProps) => {
  //destruct props
  const {
    loading,
    value,
    task,
    taskPriorityMlov,
    onAddTask,
    comments,
    defaultAssignee,
    handleChange,
    onCancel,
    accountUsers,
    taskPools,
    handleSubTaskChange,
    handleCommentChange,
    subTasks,
    subTaskLoading,
    fileList,
    fetchAllTypeTask,
    linkPatientNote,
    allowedAccountLocations,
    measureStewardsAndMeasureIdentifiers,
    alertType
  } = props;
  const targetRef: any = useRef();
  const editTask: ITask | undefined = task;
  const isEditTask = !!editTask?.id;
  const subTaskRef: any = useRef();
  const fileListWithoutDeleted = fileList?.filter(item => !item.isDeleted)
  const employerId = getUserEmployerId();
  const mlovData = useContext(CommonDataContext);
  const intl = useIntl();
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const taskStatusMlov: ITaskActions[] = (getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS
  ) || []).filter(status => CARE_GAPS_TASK_STATUS_CODES_LIST.includes(status.code)).map(status => ({
    key: status.id,
    value: status.value,
    code: status.code,
  }));
  const taskStatusMlov1 = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS
  ) || [];
  const isTaskRejected = task?.statusId === getMlovIdFromCode(taskStatusMlov1, TASK_STATUS.REJECTED);
  const careGapCategories: {
    key: string;
    value: string;
    label: string;
  }[] = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.QUALITY_MEASURE_CATEGORY
  ).map(category => ({
    key: category.id,
    value: category.value,
    label: category.value,
  }));
  const leadContactTypeId = getContactTypeId('LEAD');
  const contactTypeId = getContactTypeId('CUSTOMER');
  const isEmployer = isEmployerRole();
  const isSelectedAssigneeAndDefaultAssigneeSame = value.assignee?.value === defaultAssignee?.value
  const addEvidencePlaceholder = alertType === TASK_TYPES.DIAGNOSIS_GAPS ? 'pleaseEnterEvidenceForMeasure' : 'addEvidencePlaceholder' ;
  const [taskPoolData, setTaskPoolData] = useState<ITaskPool>({
    assigneeChangedDueToChangeInTaskPool: false,
  });
  const [selectedViewCode, setSelectedViewCode] = useState<{
    code: string;
    task?: ITask;
  }>({
    code: '',
  });
  const isSidecarContext = !!mlovData?.sidecarContext?.isSidecar;
  const [selectedRowContactData, setSelectedRowContactData]: any = useState({});
  const defaultTab = TASK_VIEW_TABS?.find((tab) => tab?.code === TaskViewTabsCode?.ACTIVITY)
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [expandSubtask, setExpandSubtask] = useState(true);
  const [expandEvidence, setExpandEvidence] = useState(!!editTask?.attachments?.length);
  
  const [stateForHandlingPoolAfterTaskAssigneeChange, setStateForHandlingPoolAfterTaskAssigneeChange] = useState<IStateForHandlingPoolAfterTaskAssigneeChange>({
    showAlertOfEmptyingTaskPool: false,
    closeSelect: false,
    temporaryData: {}
  })
  const [componentState, setComponentState] = useState<ITaskViewComponentState>({
    showMediaDrawer: false,
    showAttachmentsPopover: false,
    selectedMedia: {} as IMediaLibraryData
  })
  const currentUserData = getUserData();
  const currentUserId = getUserUUID();
  const currentUser = {
    value: currentUserId,
    label: currentUserData.name,
    key: currentUserId,
    type: ParticipantType.staff,
    details: currentUserData,
  }
  const scrollToComment = () => {
    if (props?.showCommentError && !isSidecarContext) {
      targetRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }
  const scrollToElement = () => {
    if (task?.scrollTo === 'subtask' && subTaskRef?.current && !isSidecarContext) {
      subTaskRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    else if (targetRef?.current && !isSidecarContext) {
      targetRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  const isAssigneeTypePatientOrProspect = () => {
    const isMember = (
      value.assignee?.type === ParticipantType.patient ||
      value.assignee?.type === ParticipantType.leads ||
      value.assignee?.type === TASK_ASSIGNEE_TYPE_CODES.Contact
    );
    return isMember
  };


  const handleAssigneeChange = (assignee: IUserPatientSearchItem, type?: string) => {
    if (isEmployer) {
      handleChange({
        ...value,
        assignee: assignee as IUserPatientSearchItem,
      });
    }
    else {
      setTaskPoolData(prev => {
        return {
          ...prev,
          assigneeChangedDueToChangeInTaskPool: false
        }
      });
      if (type !== ParticipantType.staff) {
        handleChange({
          ...value,
          taskDisplayCategoryId: '',
        });
        setTaskPoolData(prev => {
          return {
            ...prev,
            selectedPool: undefined,
            selectedPoolUsers: [],
            defaultAssignee: undefined,
          }
        });
      }
      handleChange({
        ...value,
        assignee: assignee,
        ...(type === ParticipantType.staff && !(taskPoolData?.selectedPool?.userPoolUsers?.some((user: any) => {if (user.userId === assignee?.key) return true}))) && {
          taskPool: undefined
        },
        ...(type && type !== ParticipantType.staff && {
          customer: undefined,
          selectedContact: undefined,
          taskPool: undefined,
        }),
      });
    }
  }

  const getDefaultDate = (dateValue: string, fallback: any = null) => {
    const momentObject = getMomentObjectWithDateStringAndFormat(
      dateValue,
      DISPLAY_DATE_FORMAT
    );
    return momentObject.isValid() ? momentObject : fallback;
  };

  const getDefaultDueDate = () => getDefaultDate(value.dueDate, getMomentObj(new Date()));
  const getDefaultEvaluationDate = () => getDefaultDate(value.evaluationDate || '', null);

  useEffect(() => {
    if (task?.isAutoScroll) {
      setTimeout(() => {
        scrollToElement();
      }, 1000);
    }
  }, [taskPools, accountUsers]);

  useEffect(() => {
    if (props?.showCommentError) {
      const timeoutId = setTimeout(() => {
        scrollToComment();
      }, 1000);
      props?.handleCommentErrorAction && props?.handleCommentErrorAction(IS_SAVE_CLICK, false);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [props?.isNeedToScrollDown]);
  
  const renderTaskStatus = () => (
    <TaskDrawerActionWithSelect
      defaultTaskStatus={TASK_STATUS_VALUES.OPEN}
      actions={taskStatusMlov}
      task={task || {} as ITask}
      value={value}
      onComplete={(statusId?: string) => {
        if (statusId) {
          handleChange({...value, statusId: statusId })
        }
        fetchAllTypeTask?.();
        onCancel?.();
        backBtn?.()
      }}
      linkPatientNote={linkPatientNote}
      linkedCarePlanId={props.linkedCarePlanId}
      disableTaskStatusChange={!isEditTask}
      isRequired={true}
    />
  )
  const renderAccountLocations = () => (
    <>
      <ModalActionAntSelect
        className={'custom-task-style'}
        leftMargin={'0'}
        showSearch={false}
        disabled={isLinkPatientNote()}
        labelInValue={true}
        filterOption={false}
        value={value?.selectedAccountLocations?.[0]?.practiceLocation?.name}
        isRequired={true}
        isInvalid={value.showErrors}
        label={'Location'}
        placeholder={'Location'}
        onChange={(data: any) => {
          const selectedLocations = allowedAccountLocations?.filter((location: any) => location?.uuid === data?.key);
          setTaskPoolData(prev => {
            return {
              ...prev,
              selectedPool: undefined,
              selectedPoolUsers: [],
              defaultAssignee: undefined,
            }
          });
          handleChange({
            ...value,
            ...((value?.selectedAccountLocations && value?.selectedAccountLocations?.length > 0) && {
              assignee: undefined,
              customer: undefined,
              taskPool: undefined,
              selectedContact: undefined,
            }),
            selectedAccountLocations: selectedLocations,
          });
          subTasks?.forEach((subTaskItem) => {
            const updateAssignee = {
              ...subTaskItem,
              assignee: undefined,
            };
            handleSubTaskChange?.(
              ADD_SUBTASK_CONST.CHANGE_ASSIGNEE,
              updateAssignee
            );
          })
        }}
        data={allowedAccountLocations}
        optionProps={{
          key: 'uuid',
          value: 'name',
          label: 'name',
        }}
        extraStyle={{flex: 1, fontColor: Colors.Custom.alertsDescriptionColor}}
        customStyle={{backgroundColor: Colors.Custom.White, borderRadius: 4, borderColor: Colors.Custom.alertsDescriptionColor, height: 40}}
        selectStyle={{fontColor: Colors.FoldPixel.GRAY400}}
        isSidecarTaskLabelSelect={isSidecarContext}
      />
    </>
  );

  const renderTitle = () => {
    if (alertType === TASK_TYPES.DIAGNOSIS_GAPS) {
      return (
        <>
          <DiagnosisGapHccCodeTitleView
            value={value}
            handleChange={handleChange}
          />
        </>
      )
    } else {
      return (
        <ModalActionInput
          maxLength={300}
          fieldIs={'input'}
          label={'Name'}
          placeholder={intl.formatMessage({id: 'careGapNamePlaceholder'})}
          leftMargin={'0'}
          isRequired={true}
          isInvalid={value.showErrors && value.title.trim().length === 0}
          value={value.title}
          onChangeText={(text: any) => {
            handleChange({...value, title: text});
          }}
          extraStyle={{flex: 1, fontColor: Colors.Custom.alertsDescriptionColor}}
          borderRadius={4}
          borderColor={Colors.FoldPixel.GRAY150}
        />
      )
    }
  };
  const renderDescription = () => (
    <View>
      <FormControl isRequired={true}>
        <HStack alignItems={'center'} flex={1}>
          <FormControl.Label marginLeft={0} flex={1}>
            <DisplayText
              size={'smMedium'}
              extraStyles={{
                color: Colors.Custom.alertsDescriptionColor,
                fontWeight: 500,
                fontSize: 14,
              }}
              textLocalId={'Description'}
            />
          </FormControl.Label>
        </HStack>
        <VStack>
          <View flex={1}>
            <RichTextEditor
              style={{borderRadius: 4, borderColor: Colors.FoldPixel.GRAY150, height: 100}}
              modules={TEXT_ONLY_MODULES}
              placeholder={intl.formatMessage({id: 'pleaseEnterDescriptionForMeasure'})}
              valueStr={value.description}
              onChangesValue={(data: any) => {
                handleChange({
                  ...value,
                  description: data,
                });
              }}
            />
          </View>
        </VStack>
      </FormControl>
    </View>
  );
  const renderEvidence = () => {
    return (
    !expandEvidence && !value?.evidence ? (
      <Pressable
        onPress={() => setExpandEvidence(true)}
      >
        <HStack>
          <PlusIcon />
          <Text
            cursor={'pointer'}
            size="smMedium"
            fontWeight={'400'}
            color={Colors.Custom.mainPrimaryPurple}
          >
            {intl.formatMessage({id: 'addEvidence'})}
          </Text>
        </HStack>
      </Pressable>
    ) : (
      <View>
      <FormControl isRequired={false}>
        <HStack alignItems={'center'} flex={1}>
          <FormControl.Label marginLeft={0} flex={1}>
            <DisplayText
              size={'smMedium'}
              extraStyles={{
                color: Colors.Custom.alertsDescriptionColor,
                fontWeight: 500,
                fontSize: 14,
              }}
              textLocalId={'evidence'}
            />
          </FormControl.Label>
        </HStack>
        <VStack>
          <View flex={1}>
            <RichTextEditor
              placeholder={intl.formatMessage({id: addEvidencePlaceholder})}
              style={{borderRadius: fileListWithoutDeleted?.length ? 0 : 4, borderColor: Colors.FoldPixel.GRAY150, height: 100}}
              modules={TEXT_ONLY_MODULES}
              valueStr={value.evidence}
              hideBottomBorder={
                fileListWithoutDeleted?.length ? true : false
              }
              onChangesValue={(data: any) => {
                handleChange({
                  ...value,
                  evidence: data,
                });
              }}
            />
            <Popover
              overlayInnerStyle={{
                // bottom: 3,
                borderRadius: 12,
                padding: 0,
                width: 200,
              }}
              trigger="click"
              placement="bottomLeft"
              open={componentState.showAttachmentsPopover}
              onOpenChange={(newOpen: boolean) => {
                if (!newOpen) {
                  setComponentState((prev) => {
                    return {
                      ...prev,
                      showAttachmentsPopover: newOpen,
                    };
                  });
                }
              }}
              showArrow={false}
              overlayStyle={{borderRadius: 4}}
              content={
                <VStack space={3}>
                  <Pressable
                    onPress={() =>
                      setComponentState((prev) => {
                        return {
                          ...prev,
                          showMediaDrawer: true,
                          showAttachmentsPopover: false,
                        };
                      })
                    }
                  >
                    <HStack space={3} alignItems={'center'}>
                      <MediaSelectFolder />
                      <Text fontWeight={400}>
                        {intl.formatMessage({id: 'selectFromMedia'})}
                      </Text>
                    </HStack>
                  </Pressable>
                  <Upload
                    multiple={true}
                    beforeUpload={() => false}
                    maxCount={10}
                    onChange={(info) => {
                      setComponentState((prev) => {
                        return {
                          ...prev,
                          showAttachmentsPopover: false,
                        };
                      });
                      const tempFileList = [...info.fileList];
                      if (fileList?.length) {
                        handleChange({
                          ...value,
                          fileList: uniqueArray([
                            ...(fileList || []),
                            ...tempFileList,
                          ]),
                        });
                      } else {
                        handleChange({
                          ...value,
                          fileList: tempFileList,
                        });
                      }
                    }}
                    fileList={[]}
                    className="msg-attachment"
                    accept={UPLOAD_ACCEPTED_FILE_TYPES}
                  >
                    <HStack space={3} alignItems={'center'}>
                      <Feather
                        name="upload"
                        size={18}
                        color={Colors.Custom.Gray500}
                      />
                      <Text fontWeight={400}>
                        {intl.formatMessage({id: 'uploadNew'})}
                      </Text>
                    </HStack>
                  </Upload>
                </VStack>
              }
            >
              <Pressable
                onPress={() =>
                  setComponentState((prev) => {
                    return {
                      ...prev,
                      showAttachmentsPopover: true,
                    };
                  })
                }
                position={'absolute'}
                top={3}
                right={isSidecarContext ? '16px' : '20px'}
                flexDir={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
              >
                <Tooltip
                  title={intl.formatMessage({
                    id: 'taskAttachmentUploadSupportAndSize',
                  })}
                >
                  <Icon
                    name="paperclip"
                    size={18}
                    color={Colors.FoldPixel.GRAY400}
                    style={{marginRight: 4}}
                  />
                </Tooltip>
                <Pressable onPress={() => setExpandEvidence(false)}>
                  <TrashBin2Icon color={Colors.FoldPixel.GRAY400} size={26}/>
                </Pressable>
              </Pressable>
            </Popover>
          </View>
          {(fileListWithoutDeleted?.length || 0) > 0 && (
            <AttachmentsItemList
              handleChange={handleChange}
              value={value}
              fileList={manageAttachmentsListData(fileList || [])}
            />
          )}
        </VStack>
      </FormControl>
      </View>
    )
  )
};

  const handleAddSubtasks = () => {
    const newSubTask = {
      id: '',
      tempId: uuidv4(),
      title: '',
      description: '',
      assigneeId: value.assignee?.value,
      assignee: value.assignee,
      assignedById: '',
      startDateTime: '',
      endDateTime: getDefaultDueDate().toString() || '',
      statusId: '',
      priorityId: '',
      isEscalated: false,
      contactId: null,
      userPoolId: null,
      isChecked: false,
    } as ISubTasks;

    handleSubTaskChange?.(ADD_SUBTASK_CONST.ADD_NEW, [newSubTask])
  }


  const handleAddLocalComment = (comment: string) => {
    const newComment: ITaskCommentsList = {
      id: '',
      taskId: '',
      isModified: false,
      createdOn: new Date().toISOString(),
      createdBy: currentUser.details.uuid,
      isDeleted: false,
      tempId: uuidv4(),
      userName: currentUser.details.available_name,
      comment: comment
    }

    handleCommentChange?.(ADD_COMMENT_CONST.ADD_NEW, newComment)
  }
  const handleDeleteLocalComment = (comment: ITaskCommentsList) => {
    handleCommentChange?.(ADD_COMMENT_CONST.DELETE, comment)
  }

  const renderSubTasks = () => {
    return (
      <>
        {alertType === TASK_TYPES.DIAGNOSIS_GAPS && !isAssigneeTypePatientOrProspect() && !subTasks?.length && handleAddSubtasks()}
        <Pressable
          key={task?.id}
          disabled={isAssigneeTypePatientOrProspect()}
          width={'250px'}
          mt={2}
          onPress={() => {
            if (!isAssigneeTypePatientOrProspect()) {
              handleAddSubtasks();
            } else {
              alert(intl.formatMessage({id: 'subTaskAddErrorMsg'}));
            }
          }}
        >
          <HStack>
            <PlusIcon />
            <Text
              cursor={'pointer'}
              size="smMedium"
              fontWeight={'400'}
              color={Colors.Custom.mainPrimaryPurple}
            >
              {intl.formatMessage({
                id: alertType === TASK_TYPES.DIAGNOSIS_GAPS ? 'addDiagnosisCodes' : 'addSubTasks',
              })}
            </Text>
          </HStack>
        </Pressable>
      </>
    );
  };


  const getRestrictPatientOrLeadSelectionTo = () => {
    if (
      subTasks?.length ||
      !props?.restrictPatientOrLeadSelectionTo?.value?.length ||
      !props?.restrictPatientOrLeadSelectionTo?.contactData?.patient
    ) {
      return undefined;
    }
    return props?.restrictPatientOrLeadSelectionTo;
  };

  const isPatientAndLeadSearchDisabled = (): boolean => {
    const isCarePlanTask = !!props.linkedCarePlanId
    const isNoteContextTask = linkPatientNote && linkPatientNote?.resourceId ? true : false;
    if (isNoteContextTask || isCarePlanTask) {
      return true;
    }
    return (subTasks?.length || !!props?.restrictPatientOrLeadSelectionTo) ? true : false;
  }

  const renderAssigneeTo = () => (
    <>
      <View style={styles.fullWidthView}>
        {isEmployer ? (
          <UserAutoComplete
            // isDisabled={props.isAssigneeDisabled}
            labelText={'assignee'}
            isShowError={value.showErrors}
            selectedValue={value.assignee as IUserSearch}
            excludeOtherEmployers={true}
            onChange={(selectedUser) => {
              handleChange({
                ...value,
                assignee: selectedUser as IUserPatientSearchItem,
              });
            }}
            usersData={isEmployer ? taskPoolData?.accountUsers : []}
          />
        ) : (
          <PatientSearchAndSelect
            className={'custom-task-style'}
            accountLocationUuids={value?.selectedAccountLocations?.map((location) => location?.uuid)}
            resourceType={MAIN_MENU_CODES.TASKS}
            isDisabled={isMultiTenancyEnabled && !value?.selectedAccountLocations?.length}
            isRequired={true}
            showUserPendingTasksCount={true}
            isDefaultSearch={true}
            isInvalid={value.showErrors && !value.assignee?.value}
            closeSelect={stateForHandlingPoolAfterTaskAssigneeChange?.closeSelect}
            setCloseSelect={() => {setStateForHandlingPoolAfterTaskAssigneeChange((prev) => {return {...prev, closeSelect: false}})}}
            labelColor={Colors.Custom.alertsDescriptionColor}
            showCurrentUserAtTop={taskPoolData?.selectedPool?.id ? false : true}
            label={'Assign to'}
            isProspect={true}
            allowClear={false}
            disableLeadsSearch={true}
            disablePatientSearch={isPatientAndLeadSearchDisabled()}
            showEmail
            showPhoneNumber
            // isDisabled={props.isAssigneeDisabled}
            customStyle={{borderRadius: 4, backgroundColor: Colors.Custom.White}}
            showErrorBorder
            showError={value.showErrors && !value.assignee?.value}
            placeholder="Search Staff or Member"
            value={value.assignee}
            {...(isSidecarContext && {dropdownPopupMinWidth: 400})}
            showPatientProfileLink={
              !!value.assignee?.value &&
              isAssigneeTypePatientOrProspect() &&
              !isSelectedAssigneeAndDefaultAssigneeSame
            }
            onPatientProfileIconClick={() => {
              if (!isActiveContact(value?.assignee?.details)) {
                const message = getInActiveContactError(value?.selectedContact);
                notification.error({
                  message,
                  placement: 'top'
                });
                return;
              }
              if (isEmployer) {
                setSelectedRowContactData(
                  value.assignee?.details as IUserPatientSearchItem
                );
                setSelectedViewCode({
                  code: RIGHT_SIDE_CONTAINER_CODE.CONTACT_DETAILS_VIEW,
                });
              } else if (
                value.assignee?.details?.contactType?.contactType?.id ===
                contactTypeId ||
                value.assignee?.details?.contactType?.contactType?.code ===
                PERSON_TYPES.CUSTOMER
              ) {
                props?.openContactInWindowOrOpenContactIdDrawer?.(value.assignee?.details?.id);
              } else if (
                value.assignee?.details?.contactType?.contactType?.id ===
                leadContactTypeId
              ) {
                props?.navigateOrOpenLeadInWindow?.(value.assignee?.details?.id);
              } else {
                props?.navigateOrOpenLeadInWindow?.(value.assignee?.details?.id);
              }
            }}
            poolSelected={props?.value?.taskPool?.key ? true : false}
            selectedPoolUsers={taskPoolData?.selectedPoolUsers}
            defaultAssigneeInSelectedPool={taskPoolData?.defaultAssignee}
            onChange={(participants: any, data: any) => {
              const contactIdValue =
                participants?.label?.props?.contactData?.uuid ||
                participants?.value;
              const contactIdKey = contactIdValue || participants?.key;
              const assignee = {
                key: contactIdKey,
                value: contactIdValue,
                label: participants?.label?.props?.contactData?.name,
                type: data?.type,
                details: participants?.label?.props?.contactData,
              }
              data?.type !== ParticipantType.staff || !taskPoolData?.selectedPool?.id || taskPoolData?.selectedPool.userPoolUsers?.some((user) => user?.userId === contactIdKey) ? handleAssigneeChange(assignee, data?.type) :
                setStateForHandlingPoolAfterTaskAssigneeChange({
                  showAlertOfEmptyingTaskPool: true,
                  temporaryData: {
                    assignee: assignee,
                    type: data?.type
                  }
                })
            }}
            employerId={employerId}
            restrictPatientOrLeadSelectionTo={getRestrictPatientOrLeadSelectionTo()}
            handleOnClear={true}
            isSidecarTaskLabelSelect={isSidecarContext}
          />
        )}
      </View>
    </>
  );
  const renderDueDate = () => (
    <>
      <ModalActionDatePicker
        label={'dueDate'}
        labelStyles={{color: Colors.Custom.alertsDescriptionColor}}
        leftMargin={'0'}
        format={DISPLAY_DATE_FORMAT}
        onChange={(data: any, dateString) => {
          handleChange({
            ...value,
            dueDate: dateString,
          });
        }}
        value={getDefaultDueDate()}
        extraStyle={{flex: 1}}
        customStyle={{flex: 1, heigh: 40, borderRadius: 4, borderColor: Colors.FoldPixel.GRAY150, marginBottom: -1, marginTop: 0}}
        allowClear={false}
        isSidecarTaskLabelSelect={isSidecarContext}
      />
    </>
  );
  const renderEvidenceDate = () => (
    <>
      <ModalActionDatePicker
        isRequired={false}
        label={'evidenceDate'}
        labelStyles={{color: Colors.Custom.alertsDescriptionColor}}
        leftMargin={'0'}
        format={DISPLAY_DATE_FORMAT}
        onChange={(data: any, dateString) => {
          handleChange({
            ...value,
            evaluationDate: dateString,
          });
        }}
        extraStyle={{flex: 1}}
        value={getDefaultEvaluationDate()}
        customStyle={{flex: 1, heigh: 40, borderRadius: 4, borderColor: Colors.FoldPixel.GRAY150, marginBottom: -1, marginTop: 0}}
        allowClear={false}
        isSidecarTaskLabelSelect={isSidecarContext}
      />
    </>
  );
  const renderPriority = () => (
    <ModalActionAntSelect
      className={'custom-task-style'}
      allowClear={true}
      leftMargin={'0'}
      showSearch={false}
      labelInValue={true}
      filterOption={false}
      value={value.priority}
      isRequired={true}
      isInvalid={value.showErrors && !value.priority}
      label={'severity'}
      placeholder={'Select care gap severity'}
      onChange={(data: any) => {
        handleChange({
          ...value,
          priority: data,
        });
      }}
      data={taskPriorityMlov}
      optionProps={{
        key: 'id',
        value: 'code',
        label: 'value',
      }}
      extraStyle={{flex: 1, fontColor: Colors.Custom.alertsDescriptionColor}}
      customStyle={{backgroundColor: Colors.Custom.White, borderRadius: 4, borderColor: Colors.Custom.alertsDescriptionColor, height: 40}}
      selectStyle={{fontColor: Colors.FoldPixel.GRAY400}}
      isSidecarTaskLabelSelect={isSidecarContext}
    />
  );

  const renderMeasures = (): JSX.Element => (
    <ModalActionInput 
      maxLength={300}
      fieldIs={'input'}
      label={'Measure Identifier'}
      placeholder={'Enter Measure Identifier'}
      leftMargin={'0'} 
      value={value.measureIdentifiers?.value}
      onChangeText={(data: string) => {
        handleChange({
          ...value,
          measureIdentifiers: {
              key: value.measureIdentifiers?.key || '',
              value: data,
              label: data
            },
        });
      }}
      inputStyle={{placeholderTextColor: Colors.FoldPixel.GRAY200}}
      borderRadius={4}
      extraStyle={{flex: 1, fontColor: Colors.Custom.alertsDescriptionColor, Height: '40px', placeHolderFontWeight: 500, placeHolderFontsize: 13, labelBottomMargin: 1}}
      borderColor={Colors.FoldPixel.GRAY150}
    />
  );
  const renderMeasuresSteward = () => (
    <ModalActionAntSelect
      className={'custom-task-style'}
      allowClear={true}
      leftMargin={'0'}
      showSearch={false}
      labelInValue={true}
      filterOption={false}
      value={value.measureSteward?.key ? value.measureSteward : undefined}
      label={'measureSteward'}
      placeholder={'Select Measure Steward'}
      onChange={(data: any) => {
        handleChange({
          ...value,
          measureSteward: data,
        });
      }}
      data={measureStewardsAndMeasureIdentifiers?.measureStewards}
      optionProps={{
        key: 'id',
        value: 'measureStewardName',
        label: 'measureStewardName',
      }}
      extraStyle={{flex: 1, fontColor: Colors.Custom.alertsDescriptionColor}}
      customStyle={{backgroundColor: Colors.Custom.White, borderRadius: 4, borderColor: Colors.Custom.alertsDescriptionColor, height: 40}}
      selectStyle={{fontColor: Colors.FoldPixel.GRAY400}}
      isSidecarTaskLabelSelect={isSidecarContext}
    />
  );

  const isLinkPatientNote = () => {
    return !!linkPatientNote && !!linkPatientNote?.resourceId;
  }
  const renderCategory = () => (
    <>
      <ModalActionAntSelect
      className={'custom-task-style'}
      allowClear={true}
      leftMargin={'0'}
      showSearch={false}
      labelInValue={true}
      filterOption={false}
      value={value.careGapCategoryId}
      isRequired={false}
      label={'category'}
      placeholder={'Select Category'}
      onChange={(selectedItem: any) => {
          if (selectedItem.value) {
            handleChange({
              ...value,
              careGapCategoryId: selectedItem.value,
            });
          }
        }}
        data={careGapCategories}
        optionProps={{
          key: 'code',
          value: 'value',
          label: 'label',
        }}
        extraStyle={{flex: 1, fontColor: Colors.Custom.alertsDescriptionColor}}
        customStyle={{borderRadius: 4}}
      />
    </>
  );

  const backBtn = () => ({
    show:
      (selectedViewCode.code === COMMON_ACTION_CODES.PREVIEW)
        ? false
        : true,
    id: 1,
    btnText: isSidecarContext ? 'back' : 'cancel',
    textColor: Colors.Custom.mainSecondaryBrown,
    variant: BUTTON_TYPE.SECONDARY,
    isTransBtn: false,
    onClick: () => {
      onCancel?.();
    },
  });

  return (
    <View>
      <View mx={isSidecarContext ? 0 : 4} mt={isSidecarContext ? 0 : 4}>
        <VStack>
          {loading ? (
            <>
              <Skeleton.Text />
              <Skeleton.Text my={2} />
              <Skeleton.Text />
            </>
          ) : (
            <>
              {selectedViewCode.code !== COMMON_ACTION_CODES.PREVIEW ? (
                <>
                  <VStack mx={0} space={4}>
                    {!(isEditTask && alertType === TASK_TYPES.DIAGNOSIS_GAPS && !isTaskRejected) && (
                      <View style={styles.fullWidthView}>
                      {renderTaskStatus()}
                    </View>
                    )}
                    {isMultiTenancyEnabled && renderAccountLocations()}
                    {renderTitle()}
                    {alertType !== TASK_TYPES.DIAGNOSIS_GAPS && renderDescription()}
                    <HStack space={2} style={styles.hStackSpaceAround}>
                        <View style={styles.fullWidthView}>
                          {renderEvidence()}
                        </View>
                      </HStack>
                    <VStack>
                      {(subTasks || [])?.length > 0 && (
                        <>
                          {alertType !== TASK_TYPES.DIAGNOSIS_GAPS && <HStack alignItems={'center'} ml={1}>
                            <DisplayText
                              size={'mdSemibold'}
                              extraStyles={{
                                fontWeight: 600,
                                fontSize: 16,
                              }}
                              textLocalId={'subTasks'}
                            />
                            <Pressable
                              ml={2}
                              key={task?.id}
                              onPress={() => {
                                setExpandSubtask(!expandSubtask);
                              }}
                            >
                              <View size={18}>
                                {expandSubtask ? (
                                  <ArrowDownSvg customStrokeColor="black"></ArrowDownSvg>
                                ) : (
                                  <ArrowRightSvg customStrokeColor="black"></ArrowRightSvg>
                                )}
                              </View>
                            </Pressable>
                            <Text
                              fontWeight={600}
                              fontSize={14}
                              color={Colors.Custom.Gray500}
                              ml={5}
                            >
                              {`${subTasks?.filter((item) => item.isChecked)?.length
                                }/${subTasks?.length}`}
                            </Text>
                          </HStack>}
                          {alertType === TASK_TYPES.DIAGNOSIS_GAPS && <FormControl
                            isRequired={true}
                            isInvalid={false}
                            {...testID('DiagnosisCode')}
                          >
                            <FormControl.Label marginLeft={0} {...testID('DiagnosisCode')}>
                             <DisplayText
                              size={'smMedium'}
                              extraStyles={{
                                color: Colors.Custom.alertsDescriptionColor,
                                fontWeight: 400,
                                fontSize: 14,
                              }}
                              textLocalId={'Diagnosis Code'}
                            />
                            </FormControl.Label>
                          </FormControl>
                           }
                          {expandSubtask && (
                            <>
                              {subTasks?.map((data, index) => {
                                return (
                                  <View
                                    key={(data?.id || data?.tempId || '') + index}
                                    ref={
                                      (data?.id || data?.tempId) ===
                                        task?.subTaskIdToScroll
                                        ? subTaskRef
                                        : undefined
                                    }
                                  >
                                    <AddSubTasks
                                      key={index}
                                      subTaskItem={data}
                                      handleSubTaskChange={handleSubTaskChange}
                                      isInvalid={value.showErrors && !data?.assignee?.key && data?.title?.trim()?.length > 0}
                                      showErrorBorder={value.showErrors && !data?.assignee?.key}
                                      isEmployer={isEmployer}
                                      taskPoolData={taskPoolData}
                                      value={props?.value}
                                      employerId={employerId}
                                      subTaskLoading={subTaskLoading}
                                      isEditTask={isEditTask}
                                      emptyTaskPoolField={() => {
                                        setTaskPoolData(prev => {
                                          return {
                                            ...prev,
                                            selectedPool: undefined,
                                            selectedPoolUsers: [],
                                            defaultAssignee: undefined,
                                          }
                                        });
                                        handleChange({...value, taskPool: undefined});
                                      }
                                      }
                                      task={task}
                                      alertType={alertType}
                                      hideDeleteIcon={alertType === TASK_TYPES.DIAGNOSIS_GAPS && subTasks?.length === 1}
                                    />
                                  </View>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}

                      {!isAssigneeTypePatientOrProspect() ? (
                        renderSubTasks()
                      ) : (
                        <></>
                      )}
                    </VStack>
                    {alertType === TASK_TYPES.DIAGNOSIS_GAPS && <>
                      <HStack space={2} style={styles.hStackSpaceAround}>
                        <View style={styles.halfWidthView}>
                          {renderPriority()}
                        </View>
                        <View style={styles.halfWidthView}>
                        {renderDueDate()}
                        </View>
                      </HStack>
                    </>}
                    {alertType !== TASK_TYPES.DIAGNOSIS_GAPS && <>
                      <HStack space={2} style={styles.hStackSpaceAround}>
                        <View style={styles.fullWidthView}>
                          {renderPriority()}
                        </View>
                      </HStack>
                      <HStack space={2} style={styles.hStackSpaceAround}>
                        <View style={styles.halfWidthView}>
                          {renderEvidenceDate()}
                        </View>
                        <View style={styles.halfWidthView}>
                          {renderDueDate()}
                        </View>
                      </HStack>
                      <HStack space={2} style={styles.hStackSpaceAround}>
                        <View style={styles.fullWidthView}>
                          {renderMeasuresSteward()}
                        </View>
                      </HStack>
                      <HStack space={2} style={styles.hStackSpaceAround}>
                        <View style={styles.halfWidthView}>
                          {renderMeasures()}
                        </View>
                        <View style={styles.halfWidthView}>
                          {renderCategory()}
                        </View>
                      </HStack>
                    </>}
                    {accountUsers?.length > 0 && selectedTab?.code === TaskViewTabsCode?.ACTIVITY && editTask?.id && (
                      <View ref={targetRef}>
                        <AddOrUpdateCommentForTask
                          accountUsers={accountUsers}
                          comments={comments}
                          handleDeleteLocalComment={handleDeleteLocalComment}
                          handleAddLocalComment={handleAddLocalComment}
                          task={task}
                          onCommentChange={props?.onCommentChange}
                          isSaveClick={props?.isSaveClick}
                          handleCommentErrorAction={props?.handleCommentErrorAction}
                          onCommentButtonClick={props?.onCommentButtonClick}
                          showCommentError={props?.showCommentError}
                          isNeedToScrollDown={props?.isNeedToScrollDown}
                          showEditCommentError={props?.showEditCommentError}
                          defaultComment={props?.value?.localCommentText}
                        />
                      </View>
                    )}
                    {selectedViewCode.code ===
                      RIGHT_SIDE_CONTAINER_CODE.CONTACT_DETAILS_VIEW && (
                        <MessagingContactDetailsDrawer
                          contactData={selectedRowContactData}
                          isDrawerVisible={true}
                          contactType={
                            selectedRowContactData?.contactType?.contactType
                              ?.code || PERSON_TYPES.CUSTOMER
                          }
                          onSideBarActionPerformed={(action?: any) => {
                            if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
                              setSelectedViewCode({
                                code: '',
                              });
                            }
                          }}
                          borderLessView={true}
                        />
                      )}
                  </VStack>
                </>
              ) : null}
            </>
          )}
        </VStack>
        {componentState?.showMediaDrawer && (
          <MediaSelectionModal
            isOpen={true}
            onClose={() => {
              setComponentState((prev: any) => {
                return {
                  ...prev,
                  showMediaDrawer: false,
                };
              });
            }}
            onSelect={(mediaData) => {
              setComponentState((prev: any) => {
                return {
                  ...prev,
                  showMediaDrawer: false,
                };
              });
              const tempFileList = [
                {
                  ...mediaData,
                  source: TASK_ATTACHMENT_SOURCE.MEDIA,
                  originFileObj: mediaData,
                },
              ];
              if (fileList?.length) {
                handleChange({
                  ...value,
                  fileList: uniqueArray([...(fileList || []), ...tempFileList]),
                });
              } else {
                handleChange({
                  ...value,
                  fileList: tempFileList,
                });
              }
            }}
          />
        )}
      </View>

    </View>
  );
};

export default withMiniContactViewHOC(AddOrUpdateAlertForm);
