import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { StyleSheet } from 'react-native';
import {
  Box,
  Button,
  Circle,
  // Drawer,
  HStack,
  Icon,
  Pressable,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  useToast,
  View,
  VStack,
  Skeleton,
  Stack,
} from 'native-base';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Calendar,
  momentLocalizer,
  SlotInfo,
  View as CalendarViewType,
} from 'react-big-calendar';
import { Drawer, Tooltip as AntdTooltip, Modal } from 'antd';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useIntl } from 'react-intl';
import { Dimensions, StyleProp, TextStyle } from 'react-native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  DISPLAY_DATE_FORMAT,
  GROUP_MEMBER_TYPE,
  MLOV_CATEGORY,
} from '../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import {
  APPOINTMENT_PARTICIPANT_STATUS_CODES,
  APPOINTMENT_STATUS_CODES,
  AVAILABILITY_TYPE_CODES,
  MLOV_CODES,
  SQS_EXECUTION_STATUS_CODES,
  USER_PREFERENCES_CODES,
  USER_ROLE_CODES,
} from '../../../constants/MlovConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { ICalendarWidgetMetaState, ILocation, IUser, IExecutionLog } from '../../../Interfaces/CommonInterfaces';
import {
  AppointmentQueries,
  MDMQueries,
  ScheduleEventQueries,
  ScheduleQueries,
} from '../../../services';
import { Colors } from '../../../styles';
import {
  getAccountUUID,
  getBooleanFeatureFlag,
  getInitialsFromFullName,
  getUserData,
  getUserEmail,
  // getUserFullName,
  getUserUUID,
  isAllowedAllLocationToUser,
  isAppointmentTypesGroupEnable,
  isNonPractitionerUser,
  isZoomEnabled,
} from '../../../utils/commonUtils';
import {
  getCalendarMonthHeader,
  getCalendarStartDateTime,
  getCalendarWeekHeader,
  getCurrentNextHour,
  getCurrentTimeZone,
  getDateObject,
  getDateRangeForCalendar,
  getDateStrFromFormat,
  getDiffInMinutes,
  getDisplayDateFormatted,
  getEndOfDay,
  getFormattedCurrentTimeZone,
  getMomentObj,
  getNextCalendarDate,
  isBetweenDate,
  isCurrentDay,
  isFutureDate,
  isFutureDay,
  isDayAndDateInPast,
  getMidDayDateObject,
} from '../../../utils/DateUtils';
import {
  getMlovCodeFromId,
  getMlovId,
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovValueFromId,
} from '../../../utils/mlovUtils';
import FHDrawerAlert from '../FHDrawerAlert/FHDrawerAlert';
import PageBodyContainer from '../PageBodyContainer/PageBodyContainer';
import { joinZoomMeeting } from '../ZoomView/ZoomServices';
import { AppointmentType } from './BookingWorkflows/AppointmentTypeSelection/AppointmentTypeEnum';
import { getAppointmentTimeWithTimeZone } from './BookingWorkflows/BookAppointment/BookAppointmentHelper';
import {
  TIMESLOT_STEP_IN_MINUTES,
  canSelectSlot,
  getLocationCode,
  getPrimaryContactName,
  isAppointmentBookForSelectedUser,
  isFutureMeeting,
  isPastMeeting,
  isCurrentTimeSlotAvailableToBook,
  getContactNameById,
} from './BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import AppointmentDetail from './BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail';
import BookingWorkflow from './BookingWorkflows/BookingWorkflow';
import CalendarTopBar, {
  CalendarTopBarActions,
} from './CalendarTopBar/CalendarTopBar';
import { CalendarView } from './CalendarWidgetEnums';
import {
  CalendarSlot,
  IAppointmentAggregate,
  IAppointmentDetail,
  IAvailabilitySlot,
  ICalendarAppointmentTypeAndStatusFilter,
  ICalendarEvent,
  ICalendarFilterOption,
  ICalendarLocationFilter,
  ICalendarResource,
  ICalendarUserFilter,
  ICalendarWidgetState,
} from './CalendarWidgetInterfaces';
import './styles.scss';
import { AvailabilityEventComponent } from './AvailabilityEventComponent';
import {
  IAddOrUpdateAvailability,
  IAvailabilityCalendarData,
  IComponentAllState,
  ICallAddUpdateUserFilterPreference,
  IUserFilterPreferenceDataForCalendar
} from '../../RightSideContainer/AccountSettings/PracticeAvailabilityNew/PracticeAvailabilityInterfaces';
import {
  createAvailabilityCalendarGroupData,
  getDefaultTimezone,
} from '../../RightSideContainer/AccountSettings/PracticeAvailabilityNew/PracticeAvailabilityHelper';
import UserPracticeLocationQueries from '../../../services/Location/UserPracticeLocationQueries';
import { IAccountLocation } from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import { ITimezone } from '../../../services/Location/interfaces';
import { AddOrUpdateAvailabilityDrawer } from '../../RightSideContainer/AccountSettings/PracticeAvailabilityNew/AddOrUpdateAvailabilityDrawer';
import messages from '../../../i18n/messages';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import { capitalize, isArray } from 'lodash';
import VirtualAppointmentIcon from '../../../assets/images/Avatar/VirtualAppointmentIcon';
import AtClinicAppointmentIcon from '../../../assets/images/Avatar/AtClinicAppointmentIcon';
import { BlockSlotIcon } from '../../../assets/images/Avatar/BlockSlotIcon';
import { CancelAndDeclineIcon } from '../../../assets/images/Avatar/CancelAndDeclineIcon';
import { getDataForAppointmentStatusUpdate } from './BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import { LoadAppointmentDetailsDrawer } from './BookingWorkflows/Booking/AppointmentBooking/LoadAppointmentDetailsDrawer';
import { getAppointmentDateTime } from './BookingWorkflows/BookAppointment/BookAppointmentHelper';
import { CreateOrUpdateUserPreference, GetUserPreferencesByTypeId } from '../../../services/UserPreferences/UserPreferencesQueries';
import UserAutoComplete, { IUserSearch, userAutoCompleteTypeCodes } from './UserAutoComplete/UserAutoComplete';
import { getCalenderEventCardStyles } from './CalendarWidgetUtils';
import { defaultAppointmentTypeColor } from '../../RightSideContainer/AccountSettings/AppointmentTypes/AppointmentTypeConst';
import { FilterWrapperComponent } from '../CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import _ from 'lodash';
import CalenderMemberViewDrawer from './CalenderMemberViewDrawer';
import ReccuringAppointmentIcon from '../../../assets/Icons/ReccuringAppointmentIcon';
import { isZoomMeetingId } from '../../../services/HmsMeetingServices';
import { GET_RESOURCE_EXECUTION_LOG_BY_RESOURCE_ID } from '../../../services/Appointment/AppointmentQueries';
import { FoldButton } from '../../CommonComponents/FoldButton/FoldButton';
import { DisplayText } from '../DisplayText/DisplayText';
import { EventBus } from '../../../utils/EventBus';
import { WEB_SOCKET_EVENT_CODE } from '../../../constants/WebSocketConst';
import { isWeb } from '../../../utils/platformCheckUtils';
import { IAppointmentType } from '../../RightSideContainer/AccountSettings/AppointmentTypes/Interfaces';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import { CONVERSATION_ACTION_CODES } from '../../../constants/ActionConst';
import { IAppointment } from '../../../services/Appointment/AppointmentInterface';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import { IContact } from '../../RightSideContainer/TeamInbox/Conversations/interfaces';

interface IProps {
  calendarMetaData: ICalendarWidgetMetaState;
  getScheduleAccessUsersLoading: boolean;
  calendarWidgetMetaDataLoading: boolean;
  filterPreferenceData?: IUserFilterPreferenceDataForCalendar;
  isDayOptimizerCalender?: boolean;
}

const CURRENT_TIMEZONE = getFormattedCurrentTimeZone();
const WeekHeader = (props: any) => {
  const weekHeader = getCalendarWeekHeader(props.date);

  return (
    <VStack padding={2}>
      <Text
        size={'smMedium'}
        style={{
          color: moment().format('DD') == weekHeader.date ? '#825ac7' : '',
        }}
        textTransform="uppercase"
      >
        {`${weekHeader.weekday}•${weekHeader.date}`}
      </Text>
    </VStack>
  );
};

const ResourceHeader = (props: any) => {
  return (
    <Text
      style={{ paddingVertical: 4 }}
      fontSize={16}
      fontWeight={500}
      color={Colors.FoldPixel.PRIMARY300}
      textAlign="center"
    >
      {props.label}
    </Text>
  );
};
const MonthHeader = (props: any) => {
  const monthHeader = getCalendarMonthHeader(props.date);
  return (
    <View padding={1}>
      <Text textAlign="center" textTransform="uppercase">
        {monthHeader.header}
      </Text>
    </View>
  );
};

const CalendarWidget = (props: IProps | any) => {
  const AVAILABILITY_DAY_EVENT_INDEX = 2;
  const SHORE_MORE_EVENT_INDEX = 11;
  const commonContextData = useContext(CommonDataContext);
  const appointmentStatusList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];
  const [showExecutionError, setShowExecutionError] = useState(false);
  const executionLogStatusList = getMlovListFromCategory(
    commonContextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.SQS_EXECUTION_STATUS
  ) || [];
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonContextData?.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const isAllowedAllLocation = isAllowedAllLocationToUser(commonContextData?.currentUserRoles || []);
  const canHideAddNewForAvailability = isMultiTenancyEnabled && !isAllowedAllLocation;

  const executionLogStatus = {
    PENDING: getMlovIdFromCode(executionLogStatusList, SQS_EXECUTION_STATUS_CODES.PENDING),
    IN_QUEUE: getMlovIdFromCode(executionLogStatusList, SQS_EXECUTION_STATUS_CODES.IN_QUEUE),
  }

  const filteringAppointmentStatusList = appointmentStatusList.filter(
    (item) => {
      return ![
        APPOINTMENT_STATUS_CODES.ARRIVED,
        APPOINTMENT_STATUS_CODES.FULFILLED,
        APPOINTMENT_STATUS_CODES.ENTERED_IN_ERROR,
        APPOINTMENT_STATUS_CODES.WAIT_LIST,
      ].includes(item.code);
    }
  );
  const APPOINTMENT_STATUS_CANCELLED = getMlovIdFromCode(
    appointmentStatusList,
    APPOINTMENT_STATUS_CODES.CANCELLED
  );
  const APPOINTMENT_STATUS_CHECKED_OUT = getMlovIdFromCode(
    appointmentStatusList,
    APPOINTMENT_STATUS_CODES.CHECKED_OUT
  );
  const APPOINTMENT_STATUS_DECLINED = getMlovIdFromCode(
    appointmentStatusList,
    APPOINTMENT_STATUS_CODES.DECLINED
  );
  const APPOINTMENT_STATUS_PENDING = getMlovIdFromCode(
    appointmentStatusList,
    APPOINTMENT_STATUS_CODES.PENDING
  );
  const APPOINTMENT_STATUS_BOOKED = getMlovIdFromCode(
    appointmentStatusList,
    APPOINTMENT_STATUS_CODES.BOOKED
  );
  const APPOINTMENT_STATUS_NO_SHOW = getMlovIdFromCode(
    appointmentStatusList,
    APPOINTMENT_STATUS_CODES.NO_SHOW
  );

  const ACCEPTED_PARTICIPANT_CODE = getMlovId(
    commonContextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
    APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
  );

  const toast = useToast();
  const userUUID = getUserUUID();
  const userData = getUserData();
  //const userFullName = getUserFullName();
  const localizer = momentLocalizer(moment);
  const userEmail = getUserEmail();
  const accountUUID = getAccountUUID();
  const intl = useIntl();
  const calendarMetaData = props.calendarMetaData || null;

  const screenHeight = Dimensions.get('window').height;

  // const userSettings = commonContextData.userSettings;
  // const isAtClinicScheduleDisabled = (userSettings['is_at_clinic_schedule_disabled']?.value === 'True') || false;

  const [bookAppointment] = useMutation(AppointmentQueries.BOOK_APPOINTMENT, {
    onCompleted: (data) => {
      refetchAppointments(
        calendarWidgetState.currentView,
        calendarWidgetState.currentDate
      );
      showToast(
        toast,
        intl.formatMessage({id: 'appointmentBookingSuccess'}),
        ToastType.success,
      );
    },
    onError: (error) => {
      refetchAppointments(
        calendarWidgetState.currentView,
        calendarWidgetState.currentDate
      );

    },
  });

  const [componentState, setComponentState] = useState<IComponentAllState>({
    searchUserText: '',
    selectedPracticeLocation: '',
    dayViewUserOrderPreference: props?.filterPreferenceData?.dayViewUserOrderPreference || [],
    calendarFiltersPreferenceId: props?.filterPreferenceData?.calendarFiltersPreferenceId || ''
  });

  const availabilityTypes =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.AVAILABILITY_TYPE
    ) || [];
  const practiceScheduleAvailabilityTypeId = getMlovIdFromCode(
    availabilityTypes,
    AVAILABILITY_TYPE_CODES.PRACTICE_SCHEDULE
  );
  const defaultAvailability: IAddOrUpdateAvailability = {
    isDeleted: false,
    localId: '',
    typeId: practiceScheduleAvailabilityTypeId,
  };

  const getTimezoneOfAccountAndLocationsQuery = useQuery(
    UserPracticeLocationQueries.GetTimezoneOfAccountAndLocations,
    {
      variables: { tenantId: accountUUID, roleCode: USER_ROLE_CODES.EMPLOYER },
      fetchPolicy: 'no-cache',
      onCompleted: async (res) => {
        let accountLocations: IAccountLocation[] = [];
        let timezones: ITimezone[] = [];
        let accountUsers: IUser[] = [];

        accountLocations = res?.accountLocations?.length
          ? res.accountLocations
          : [];
        timezones = res?.timezones?.length ? res.timezones : [];
        accountUsers = res?.users?.length ? res.users : [];

        accountUsers = accountUsers?.filter((item) => {
          let roles = '';
          item.userRoles?.forEach((element: any) => {
            roles += (element?.userRole?.customRoleCode || element?.userRole?.userRole?.code);
          });
          if (!roles.includes('WORKFLOW') && !roles.includes('CUSTOMER_SUCCESS') ) {
            return item;
          }
        });

        const defaultTimezone = getDefaultTimezone(
          res?.accountTimezones?.[0]?.timezone,
          res?.timezones
        );

        setComponentState((prev) => {
          const availabilityCalendarData: IAvailabilityCalendarData[] =
            createAvailabilityCalendarGroupData({
              practiceAvailabilities: prev.practiceAvailabilities,
              timezones,
              accountLocations,
              accountUsers,
              isUserSchedule: true,
            });
          return {
            ...prev,
            availabilityCalendarData,
            accountLocations,
            accountUsers,
            timezones,
            defaultTimezone,
          };
        });
      },
    }
  );

  const [getAvailabilitiesAPI, { loading: isGetAvailabilitiesAPILoading }] =
    useLazyQuery(ScheduleQueries.GET_PRACTICE_SCHEDULE, {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      variables: {
        whereCondition: {
          isDeleted: { _eq: false },
          typeId: { _eq: practiceScheduleAvailabilityTypeId },
        },
      },
      onCompleted: (response) => {
        let availabilityCalendarData: IAvailabilityCalendarData[] = [];
        setComponentState((prev) => {
          availabilityCalendarData = createAvailabilityCalendarGroupData({
            practiceAvailabilities: response?.schedules || [],
            timezones: prev.timezones || [],
            accountLocations: prev.accountLocations,
            accountUsers: prev.accountUsers,
            isUserSchedule: true,
          });
          return {
            ...prev,
            practiceAvailabilities: response?.schedules?.length
              ? response.schedules
              : [],
            availabilityCalendarData,
          };
        });
        editAvailabilitiesByLocationOrUser(availabilityCalendarData);
      },
      onError: (error) => {

        setComponentState((prev) => ({
          ...prev,
          practiceAvailabilities: [],
        }));
      },
    });

  const USER_WISE_COLOR_CLASS: {
    [key: string]: { textColor: string; className: string };
  } = {
    '0': {
      textColor: '#D27634',
      className: 'dashboard-event-0-user',
    },
    '1': {
      textColor: '#7B49D2',
      className: 'dashboard-event-1-user',
    },
    '2': {
      textColor: '#D14789',
      className: 'dashboard-event-2-user',
    },
    '3': {
      textColor: '#40ADA7',
      className: 'dashboard-event-3-user',
    },
    '4': {
      textColor: '#85D1CD',
      className: 'dashboard-event-4-user',
    },
    '5': {
      textColor: '#00A110',
      className: 'dashboard-event-5-user',
    },
    '6': {
      textColor: '#FFDC4B',
      className: 'dashboard-event-6-user',
    },
    '7': {
      textColor: '#3385FF',
      className: 'dashboard-event-7-user',
    },
    '8': {
      textColor: '#6D6BE1',
      className: 'dashboard-event-8-user',
    },
    '9': {
      textColor: '#FF9078',
      className: 'dashboard-event-9-user',
    },
    '10': {
      textColor: '#74E16B',
      className: 'dashboard-event-10-user',
    },
    '11': {
      textColor: '#636363',
      className: 'dashboard-event-11-user',
    },
  };

  const [bookAppointmentMeta, setBookAppointmentMeta] = useState<any>({
    selectedUser: {} as IUser,
    selectedLocation: {} as ILocation,
  });

  const scheduleLocationTypeList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE
    ) || [];
  const [calendarMemberModal, setCalendarMemberModal] = useState<{
    open: boolean;
    selectedDate?: string;
  }>({
    open: false,
    selectedDate: undefined,
  });
  const [calendarWidgetState, setCalendarWidgetState] =
    useState<ICalendarWidgetState>({
      currentView: props?.filterPreferenceData?.currentView || CalendarView.week,
      currentDate: new Date(),
      selectedEvent: undefined,
      selectedMonthEvent: undefined,
      drawerCurrentView: CalendarView.day,
      modalOpen: false,
      events: [],
      monthViewEvents: [],
      showMonthViewModal: false,
      appointmentType: undefined,
      slotSelected: undefined,
      isNonPractitionerUser: isNonPractitionerUser(),
      accountLocations: calendarMetaData.accountLocations,
      accountUsers: calendarMetaData.accountUsers,
      userLocationMap: calendarMetaData.userLocationMap,
      locationUserMap: calendarMetaData.locationUserMap,
      isPracticeAvailabilityView: false,
      availabilityEvent: [],
      uniqueAvailabilityUsers: [],
      selectedEventUserId: undefined,
      selectedAvailabilityEvent: {},
      appointments: [],
      disAllowToRedirectAvailableSlot: false,
    });

  const [getResourceExecution, resourceExecutionQuery] = useLazyQuery<{
    message_execution_log: IExecutionLog[];
  }>(GET_RESOURCE_EXECUTION_LOG_BY_RESOURCE_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const loggedInUserId = getUserUUID();
  const mlovData = useContext(CommonDataContext);
  const allowAppointmentTypesGroup = isAppointmentTypesGroupEnable(mlovData.userSettings);
  const userPreferencesTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCES_TYPE,
    USER_PREFERENCES_CODES.USER_CALENDAR_DAY_VIEW_USER_ORDER
  );

  const [
    addUpdatePreferenceQuery,
    { loading: isAddUpdatePreferenceQueryLoading },
  ] = useMutation(CreateOrUpdateUserPreference, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });


  const callAddUpdateUserFilterPreference = async (args: ICallAddUpdateUserFilterPreference) => {
    const filterData: Omit<IUserFilterPreferenceDataForCalendar, 'dayViewUserOrderPreference' | 'calendarFiltersPreferenceIs'> = {
      selectedLocationIds: args.selectedLocations?.map((location) => location?.uuid),
      selectedUserIds: args.selectedUsers?.map((user) => user?.uuid),
      currentView: args.currentView,
      isPracticeAvailabilityView: args.isPracticeAvailabilityView,
      selectedAppointmentTypes: args.selectedAppointmentTypes || [],
      selectedAppointmentStatus: args.selectedAppointmentStatus || [],
    }
    const userList = args?.dayViewUserOrderPreference;
    const addUpdateFilterPreferenceObject: any = {
      userId: loggedInUserId,
      userPreferencesTypeId: userPreferencesTypeId,
      preferencesJson: JSON.stringify({ filterData: filterData, userList: userList}),
      preferencesModelVersion: 'v1',
    };

    if (componentState.calendarFiltersPreferenceId) {
      addUpdateFilterPreferenceObject.id =
        componentState.calendarFiltersPreferenceId;
    }

    await addUpdatePreferenceQuery({
      variables: {
        object: addUpdateFilterPreferenceObject,
      },
      onCompleted: (response) => {
        if (!componentState.calendarFiltersPreferenceId) {
          setComponentState((prev) => ({
            ...prev,
            calendarFiltersPreferenceId: response?.createUserPreference?.id,
          }))
        }
      },
      onError: (error: any) => {
      }
    });

  };


  const findUserFromList = (userId: string, userList: IUser[]): IUser => {
    const selectedUser = (userList || []).find((user) => {
      return user?.uuid === userId;
    });
    if (selectedUser?.uuid) {
      return selectedUser;
    } else {
      return userList?.length ? userList[0] : ({} as IUser);
    }
  };

  const getFormattedUserLocations = () => {
    const locations: IAccountLocation[] = [];
    (calendarMetaData.accountLocations || []).forEach(
      (selectedLocation: any) => {
          const location: IAccountLocation = {} as IAccountLocation;
          location['uuid'] = selectedLocation.uuid;
          location['practiceLocation'] = {
            id: selectedLocation.uuid,
            uuid: selectedLocation.uuid,
            name: selectedLocation.name,
          };
          locations.push(location);
      }
    );
    return locations;
  };

  const setDefaultUserFilter = (
    userId?: string,
    locationIds?: string[],
    view?: string,
  ): ICalendarUserFilter => {
    const userFilterData: ICalendarUserFilter = {} as ICalendarUserFilter;
    let userList: IUser[] = [];
    if (locationIds?.length) {
      userList = getUserListByLocationIdList(locationIds);
    } else {
      if (calendarWidgetState?.accountLocations?.length > 0) {
        userList = calendarWidgetState.accountUsers || [];
      } else {
        const user = getUserDetailByUserId(userUUID);
        userList = user ? [user] : calendarWidgetState.accountUsers || [];
      }
    }
    const currentView = view || calendarWidgetState.currentView;
    userFilterData.accountUsers = currentView === CalendarView.day ? userList : (calendarWidgetState.accountUsers || []);
    userFilterData.enabledDropDown =
      currentView === CalendarView.day
        ? false
        : userList?.length
          ? true
          : false;
    userFilterData.selectedUser = [findUserFromList(userId || userUUID, userList)];
    return userFilterData;
  };

  const GutterHeader = (props: any) => {
    return (
      <View
        padding={1}
        marginTop={
          calendarWidgetState.currentView === CalendarView.week ? 6 : 0
        }
      >
        <Tooltip label={CURRENT_TIMEZONE} placement="top">
          <Text textAlign="center" textTransform="uppercase">
            {CURRENT_TIMEZONE.length > 4
              ? `${CURRENT_TIMEZONE.substring(0, 3)}...`
              : CURRENT_TIMEZONE}
          </Text>
        </Tooltip>
      </View>
    );
  };

  const setDefaultLocationFilter = (
    users: IUser[]
  ): ICalendarLocationFilter => {
    const locationFilter: ICalendarLocationFilter =
      {} as ICalendarLocationFilter;
    const locationList = getLocationListByUserIdList(users) || [];
    locationFilter.accountLocations = locationList;
    locationFilter.enabledDropDown = locationList?.length > 0;
    locationFilter.selectedLocation = [];
    return locationFilter;
  };

  const getLocationListByUserId = (userId: string): ILocation[] => {
    return (
      (calendarWidgetState.userLocationMap?.get(userId) as ILocation[]) || []
    );
  };

  const getLocationListByUserIdList = (users: IUser[]): ILocation[] => {
    const locations = [] as ILocation[];
    users.forEach((item)=> {
      const data = calendarWidgetState?.userLocationMap?.get?.(item.uuid || '');
      if (data) {
        data.forEach((location) => {
          if (!locations.find(item => item.uuid === location.uuid)) {
            locations.push(location);
          }
        })
      }
    })
    return locations;
  };

  const getUserListByLocationId = (locationId: string): IUser[] => {
    return (
      (calendarWidgetState?.locationUserMap?.get(locationId) as IUser[]) || []
    );
  };

  const getUserListByLocationIdList = (locationIds: string[]): IUser[] => {
    const users = [] as IUser[];
    locationIds.forEach((item)=>{
      const data = calendarWidgetState?.locationUserMap?.get(item);
      if (data) {
        data.forEach((user) => {
          if (!users.find(item => item.uuid === user.uuid)) {
            users.push(user)
          }
        })
      }
    })
    return users;
  };

  const getUserDetailByUserId = (userId: string): IUser => {
    return (calendarWidgetState.accountUsers || []).find((user) => {
      return user.id === userId;
    }) as IUser;
  };

  const getLocationDetailByLocationId = (locationId: string): ILocation[] => {
    return (calendarWidgetState.accountLocations || []).filter((location) => {
      return location.id === locationId;
    }) as ILocation[];
  };

  const isDefaultLocationSelected = (location: ILocation[]): boolean => {
    // If no location is selected then consider that all locations are selected
    if (!location?.length) {
      return true;
    }
    return calendarWidgetState?.accountLocations?.length === location?.length;
  };

  const isDefaultUserSelected = (selectedUsers: IUser[]): boolean => {
    return selectedUsers?.length > 0 && calendarWidgetState?.accountUsers?.length === selectedUsers.length;
  };

  const getResourceMapData = (
    currentSelectedUsers?: IUser[],
    currentView?: string,
    userOrderPreference?: string[]
  ): ICalendarResource[] => {
    const resourceList: ICalendarResource[] = [];
    const selectedUsers = currentSelectedUsers || calendarFilter.userFilter?.selectedUser || [];
    let userUnFormattedList: IUser[] = [];

    if (currentView === CalendarView.day && !props?.isDayOptimizerCalender) {
      let userList = [];
      const selectedLocation = calendarFilter.locationFilter?.selectedLocation || [];
      if (isDefaultLocationSelected(selectedLocation)) {
        userList = calendarWidgetState?.accountUsers || [];
      } else {
        userList = getUserListByLocationIdList(selectedLocation.map(item => item.uuid));
      }
      userUnFormattedList = userList;

      const userOrderPreferenceList =
        userOrderPreference || componentState.dayViewUserOrderPreference || [];

      userUnFormattedList = userUnFormattedList.sort((a, b) => {
        const aIndex = userOrderPreferenceList.indexOf(a.uuid);
        const bIndex = userOrderPreferenceList.indexOf(b.uuid);

        if (aIndex === bIndex) {
          return 0;
        }
        if (aIndex === -1) {
          return 1;
        }
        if (bIndex === -1) {
          return 1;
        }
        return aIndex - bIndex;
      });

      // const userAutoCompleteKey = new Date().toISOString();

      userUnFormattedList = userUnFormattedList.map((user) => {
        return {
          ...user,
          name: (
            <View width={'full'}>
              <UserAutoComplete
                enableSorting={true}
                // key={`${userAutoCompleteKey}${user.uuid}`}
                // minWidth={isSmallScreen ? 180 : 165}
                // key={user.uuid}
                selectedUserUUID={user.uuid}
                allowClear={false}
                onChange={(selectedUser: IUserSearch) => {
                  const oldUserId = user.uuid;
                  const newUserId = selectedUser.value;

                  const orderedUserList = userUnFormattedList?.map(
                    (userUnFormattedUser) => userUnFormattedUser.uuid
                  );

                  const oldUserIdIndex = orderedUserList.indexOf(oldUserId);
                  const newUserIdIndex = orderedUserList.indexOf(newUserId);

                  orderedUserList[oldUserIdIndex] = newUserId;
                  orderedUserList[newUserIdIndex] = oldUserId;
                  setComponentState((prev) => ({
                    ...prev,
                    dayViewUserOrderPreference: orderedUserList
                  }))
                  setResourceMap(
                    getResourceMapData(
                      calendarFilter?.userFilter?.selectedUser,
                      CalendarView.day,
                      orderedUserList
                    )
                  );
                  updateUserFilterPreferenceWithArgument({dayViewUserOrderPreference: orderedUserList, currentView: CalendarView.day})
                }}
                usersData={userUnFormattedList}
                backgroundColor={'white'}
              />
            </View>
          ),
        };
      }) as any;
    } else {
      userUnFormattedList = selectedUsers;
    }
    (userUnFormattedList || []).forEach((user: any) => {
      const resourceFormatUserData = {
        resourceId: user?.uuid,
        resourceTitle: user?.name,
      };
      resourceList.push(resourceFormatUserData);
    });
    return resourceList;
  };

    const getInitialCalendarFilterState = (): ICalendarFilterOption => {
      const userPreferenceFilterData = props?.filterPreferenceData;
      let selectedLocationData: ILocation[] = [];
      userPreferenceFilterData?.selectedLocationIds?.forEach((locationId: string) => {
          const locationData = calendarMetaData.accountLocations?.find(
            (location: ILocation) => location?.uuid === locationId
          );
          if (locationData) {
            selectedLocationData.push(locationData);
          }
        }
      );
      const selectedUsers = getSelectedUsersFromUserPreference();
      let locationList: ILocation[] = [];
      if (calendarWidgetState.currentView === CalendarView.day && !props?.isDayOptimizerCalender) {
        locationList = calendarWidgetState.accountLocations || [];
      } else {
        locationList = getLocationListByUserIdList(selectedUsers);
      }
      const userList =
        userPreferenceFilterData?.selectedLocationIds?.length > 0
          ? getUserListByLocationIdList(
              userPreferenceFilterData?.selectedLocationIds
            )
          : calendarMetaData.accountUsers;
        // below is to handle inactivated user or practice location removed for user
        selectedLocationData = (locationList || [])?.filter((item) => {
          return selectedLocationData?.some(
            (location) => location?.uuid === item.uuid
          );
        });

      return {
        locationFilter: {
          accountLocations: locationList,
          selectedLocation: selectedLocationData,
          enabledDropDown: locationList?.length > 0,
        },
        userFilter: {
          accountUsers: userList,
          selectedUser: selectedUsers,
          enabledDropDown:
            userPreferenceFilterData?.currentView !== CalendarView.day,
        },
      };
    }

  const getInitialAppointmentTypes = () => {
    const selectedTypes = new Set(
      props?.filterPreferenceData?.selectedAppointmentTypes
    );
      const filteredTypes = (
        props?.calendarMetaData?.appointmentTypes || []
    )?.flatMap((type: IAppointmentType) => (
            (!!type?.id && selectedTypes.has(type?.id)) ? [type?.id] : []
        ));
    return filteredTypes || [];
  };

  const getSelectedUsersFromUserPreference = () => {
    const selectedUserDataInPreference: IUser[] = calendarMetaData?.accountUsers?.filter((user: IUser) => props?.filterPreferenceData?.selectedUserIds?.includes(user?.uuid));
    const selectedUsers =
    selectedUserDataInPreference?.length > 0
      ? selectedUserDataInPreference
      : calendarMetaData.accountUsers.length
      ? [calendarMetaData.accountUsers[0]]
      : [];
    return selectedUsers;
  }

  const [appointmentTypeAndStatusFilter, setAppointmentTypeAndStatusFilter] = useState<ICalendarAppointmentTypeAndStatusFilter>({
    appointmentTypes: calendarMetaData?.appointmentTypes || [],
    selectedAppointmentTypes: getInitialAppointmentTypes() || [],
    selectedAppointmentStatus: props?.filterPreferenceData?.selectedAppointmentStatus || [],
  })

  const [calendarFilter, setCalendarFilter] = useState<ICalendarFilterOption>(
    getInitialCalendarFilterState()
  );

  const [resourceMap, setResourceMap] = useState<ICalendarResource[]>(
    getResourceMapData(
      getSelectedUsersFromUserPreference(),
      props?.filterPreferenceData?.currentView,
      props?.filterPreferenceData?.dayViewUserOrderPreference
    )
  );

  const [joinMeetingConfirmation, setJoinMeetingConfirmation] = useState<{
    visible: boolean;
    selectedAppointment?: IAppointmentDetail;
    header: string;
    message: string;
  }>({
    visible: false,
    header: '',
    message: '',
    selectedAppointment: undefined,
  });

  const [getAppointmentAggregate, getAppointmentAggregateIsLoading] = useLazyQuery(
    AppointmentQueries.GET_APPOINTMENT_AGGREGATE,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT }
    }
  )

  const [getContactNameByIds] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_NAME_BY_UUIDS,
    {
      fetchPolicy: 'no-cache',
    }
  );


  const [getBookedAppointments, bookedAppointmentQuery] = useLazyQuery(
    AppointmentQueries.GET_BRIEF_BOOKED_APPOINTMENTS_BY_LOCATION,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      onCompleted: async (data: any) => {
        if (data && data.appointments && data.appointments.length > 0) {
          const indexMap: any = {};
          const appointmentCountMap: Record<string, {
            total: number;
            blocked: number;
            group: number;
            single: number;
          }> = {};
          const appointments = (data?.appointments || []).sort(
            (
              appointment1: IAppointmentDetail,
              appointment2: IAppointmentDetail
            ) => {
              const date1: any = new Date(appointment1.startDateTime);
              const date2: any = new Date(appointment2.startDateTime);
              return date1 - date2;
            }
          );

          const contactIds = appointments.map((appointment: IAppointmentDetail) => appointment.contactId)?.filter((item: string) => item);
          let contacts: IContact[] = [];
          if (contactIds?.length > 0) {
            const contactsData = await getContactNameByIds({
              variables: {
                contactUuidList: contactIds
              }
            });
            contacts = contactsData?.data?.contacts || [];
          }

          const updatedEvents: ICalendarEvent[] = appointments
            .map(
              (
                appointment: IAppointmentDetail,
                index: number
              ): ICalendarEvent => {
                const startDateStr = moment(appointment.startDateTime).format(
                  'YYYY/MM/DD'
                );

                if (!appointmentCountMap[startDateStr]) {
                  appointmentCountMap[startDateStr] = {
                    total: 1,
                    blocked: appointment?.isBlockedInterval ? 1 : 0,
                    group: appointment?.appointmentType?.category?.code == MLOV_CODES.GROUP_APPOINTMENT ? 1 : 0,
                    single: appointment?.appointmentType?.category?.code == MLOV_CODES.ONE_ON_ONE_APPOINTMENT ? 1 : 0,
                  };
                } else {
                  appointmentCountMap[startDateStr].total = appointmentCountMap[startDateStr].total + 1;
                  appointmentCountMap[startDateStr].blocked = appointment?.isBlockedInterval ? appointmentCountMap[startDateStr].blocked + 1 : appointmentCountMap[startDateStr].blocked;
                  appointmentCountMap[startDateStr].group = appointment?.appointmentType?.category?.code == MLOV_CODES.GROUP_APPOINTMENT ? appointmentCountMap[startDateStr].group + 1 : appointmentCountMap[startDateStr].group;
                  appointmentCountMap[startDateStr].single = appointment?.appointmentType?.category?.code == MLOV_CODES.ONE_ON_ONE_APPOINTMENT ? appointmentCountMap[startDateStr].single + 1 : appointmentCountMap[startDateStr].single;
                }

                if (!indexMap[startDateStr]) {
                  indexMap[startDateStr] = 0;
                }
                indexMap[startDateStr] = indexMap[startDateStr] + 1;

                appointment.blockedContactName = getContactNameById(appointment?.contactId, contacts);

                return {
                  id: appointment.id,
                  title: getAppointmentName(
                    appointment,
                    indexMap[startDateStr]
                  ),
                  dayIndex: indexMap[startDateStr],
                  start: getDateObject(appointment.startDateTime),
                  end: getDateObject(appointment.endDateTime),
                  statusCode: getMlovCodeFromId(
                    appointmentStatusList,
                    appointment.statusId
                  ),
                  detail: appointment,
                  primaryContactName: getPrimaryContactName(appointment),
                  locationCode: getLocationCode(
                    appointment,
                    scheduleLocationTypeList
                  ),
                  contactId: appointment?.contactId,
                  resourceId: getAppointmentUserId(appointment),
                };
              }
            )
          // .filter((appointmentData: ICalendarEvent) => {
          //   if (
          //     appointmentData?.dayIndex &&
          //     calendarWidgetState.currentView === 'month'
          //     // &&
          //     // calendarWidgetState.drawerCurrentView !== 'day'
          //   ) {
          //     if (appointmentData?.dayIndex <= 4) {
          //       if (appointmentData?.dayIndex == 4) {
          //         const startDateStr = moment(appointmentData.start).format(
          //           'YYYY/MM/DD'
          //         );
          //         appointmentData.title = (
          //           <Pressable
          //             onPress={() => showMoreButton(appointmentData.start)}
          //           >
          //             {' '}
          //             <Text color={Colors.secondary[800]}>
          //               {' '}
          //               {'+' + (indexMap[startDateStr] - 3) + ' More'}{' '}
          //             </Text>
          //           </Pressable>
          //         );
          //       }
          //       return true;
          //     }
          //     return false;
          //   }
          //   return true;
          // });

          // if (calendarWidgetState.currentView === 'month') {
          //   appointments.reduce((prevValue:ICalendarEvent[], {
          //     appointment,
          //     index
          //   }: {
          //     appointment: IAppointmentDetail,
          //     index: number
          //   }) => {
          //     const
          //     const startDateStr = moment(appointment.startDateTime).format(
          //       'YYYY/MM/DD'
          //     );
          //     if (!indexMap[startDateStr]) {
          //       indexMap[startDateStr] = 0;
          //     }
          //     indexMap[startDateStr] = indexMap[startDateStr] + 1;

          //       const prevTotalAppointment = prevValue?.[0]?.total || 0;
          //       const prevGroupAppointment = prevValue?.[1]?.total || 0;
          //       const prevBlockedAppointment = prevValue?.[2]?.total || 0;

          //       const totalAppointMents = {id: "Total",
          //       title: `${prevTotalAppointment} Appointments`,
          //       dayIndex: indexMap[startDateStr],
          //       start: getDateObject(appointment.startDateTime),
          //       end: getDateObject(appointment.endDateTime),
          //       statusCode: getMlovCodeFromId(
          //         appointmentStatusList,
          //         appointment.statusId
          //       ),
          //       detail: appointment,
          //       primaryContactName: getPrimaryContactName(appointment),
          //       locationCode: getLocationCode(
          //         appointment,
          //         scheduleLocationTypeList
          //       ),
          //       resourceId: getAppointmentUserId(appointment),}


          //       return({
          //         totalAppointMents:prevValue.totalAppointMents+1,
          //         groupAppointMents:appointment?.appointmentType?.category?.code==MLOV_CODES.GROUP_APPOINTMENT?prevValue.groupAppointMents+1 : prevValue.groupAppointMents,
          //         blocked:appointment?.isBlockedInterval ? prevValue.blocked+1:prevValue.blocked
          //       })

          //   }, [])
          // }

          // const monthViewEvents: any = [];
          // const groupedAppointments: any = {};
          // updatedEvents.forEach((appointment: any) => {
          //   const startDateTime = getDisplayDateFormatted(
          //     appointment?.detail?.startDateTime
          //   );
          //   const endDateTime = getDisplayDateFormatted(
          //     appointment?.detail?.endDateTime
          //   );
          //   const isDateSame = moment(startDateTime).isSame(endDateTime);
          //   if (isDateSame) {
          //     if (
          //       !(startDateTime in groupedAppointments) &&
          //       !(endDateTime in groupedAppointments)
          //     ) {
          //       const tempArr = [];
          //       tempArr.push(appointment);
          //       groupedAppointments[startDateTime] = tempArr;
          //     } else {
          //       groupedAppointments[startDateTime].push(appointment);
          //     }
          //   }
          // });

          // Object.keys(groupedAppointments).map((date) => {
          //   const appointmentEvent: any = {
          //     title: '',
          //     start: getDateObject(date),
          //     end: getDateObject(date),
          //     virtualAppointments: [],
          //     clinicAppointments: [],
          //   };

          //   groupedAppointments[date]?.forEach(
          //     (appointment: IAppointmentDetail) => {
          //       if (appointment?.detail?.reference?.meetingInfo?.id) {
          //         // virtual['title'] = getAppointmentName(appointment)
          //         appointmentEvent?.virtualAppointments.push(appointment);
          //       } else {
          //         appointmentEvent?.clinicAppointments.push(appointment);
          //         // clinic['title'] = getAppointmentName(appointment)
          //       }
          //     }
          //   );
          //   monthViewEvents.push(appointmentEvent);
          //   appointmentEvent.title = getTitleForMonthView(
          //     appointmentEvent.virtualAppointments,
          //     appointmentEvent.clinicAppointments
          //   );
          // });
          setCalendarWidgetState((prev) => ({
            ...prev,
            events: updatedEvents,
            appointments: appointments
            // monthViewEvents: monthViewEvents,
          }));
        } else {
          setCalendarWidgetState((prev) => ({
            ...prev,
            events: [],
            // monthViewEvents: [],
          }));
        }
      },
      onError: (error: any) => {

      },
    }
  );

  const setAppointmentMeta = (data: {resourceId: string}) => {
    setCalendarWidgetState((prev) => ({
      ...prev,
      appointmentType: AppointmentType.bookAppointment,
    }));
    let selectedLocation = {} as ILocation;
    if (
      !isDefaultLocationSelected(
        calendarFilter.locationFilter.selectedLocation
      )
    ) {
      selectedLocation =
        calendarFilter.locationFilter.selectedLocation[0];
    }
    let selectedUser: any | undefined = undefined;
    if (
      calendarWidgetState.currentView !== CalendarView.month
    ) {
      selectedUser = getUserDetailByUserId(data?.resourceId);
    } else {
      selectedUser = calendarFilter.userFilter.selectedUser[0];
    }
    setBookAppointmentMeta({
      selectedUser: selectedUser,
      selectedLocation: selectedLocation,
    });
  }

  const onCreateNewAppointment = (data: any) => {
    setAppointmentMeta(data);
    handleSlotSelect(data, true);
  }


  const DayHeader = (props: any) => {
    const dateHeader = getCalendarWeekHeader(props.date).date;
    const isPastDay = moment(props.date).isBefore(moment());
    const isToday = moment(props.date).isSame(moment(), 'day');

    return (
      <HStack alignItems={'center'} justifyContent={'space-between'} style={{
        paddingHorizontal: 12,
        paddingTop: 4
      }}>
        <View>
          <Pressable onPress={(event) => {
              onCreateNewAppointment({
                "slots": [
                  props.date
                ],
                "start": props.date,
                "end": props.date,
                "action": "click",
              })
              event.stopPropagation();
            }} style={{
            padding: 4,
            borderRadius: 50,
            backgroundColor: Colors.Custom.Gray100,
            zIndex:999
          }}>
            {!isPastDay || isToday? <FeatherIcon  name='plus' color={Colors.Custom.Gray600} size={12} /> : null}
          </Pressable>
        </View>
        <View style={{
          backgroundColor: isToday ? Colors.Custom.PurpleColor : '',
          borderRadius: 100,
          width: 32,
          height: 32,
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Text fontSize={16} color={isToday ? Colors.Custom.IconColorWhite : isPastDay ? Colors.Custom.Gray500 : ''}>
            {dateHeader}
          </Text>
        </View>

      </HStack>
    );
  };


  const getUserNameById = (userId: string) => {
    let userName = '';
    (calendarWidgetState.accountUsers || []).some((user) => {
      if (user?.uuid === userId) {
        userName = user?.name;
        return true;
      }
    });
    return userName;
  };

  const createUniqueSlotAvailableUsers = (availability: any) => {
    const uniqueUserIds: string[] = [];
    Object.keys(availability).forEach((dateString) => {
      const dayWiseSlots = availability[dateString] || [];
      dayWiseSlots.forEach((slot: IAvailabilitySlot, index: number): any => {
        if (slot?.userId && !uniqueUserIds?.includes(slot.userId)) {
          uniqueUserIds.push(slot.userId);
        }
      });
    });

    setCalendarWidgetState((prev) => ({
      ...prev,
      uniqueAvailabilityUsers: uniqueUserIds,
    }));
    return uniqueUserIds;
  };

  const refreshCalendar = () => {
    refetchAppointments(
      calendarWidgetState.currentView,
      calendarWidgetState.currentDate
    );

  }

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.REFRESH_CALENDAR,
      refreshCalendar
    );

    return () => {
      eventBus.removeEventListener(refreshCalendar);
    };

  },[])

  const renderAvailabilityCount = (
    avatarSize: number,
    fontSize: number,
    fontWeight: number,
    count: number
  ) => {
    return (
      <Circle
        justifyContent={'center'}
        size={avatarSize}
        backgroundColor={'#636363'}
        borderColor={'white'}
      >
        <Text
          style={{ color: 'white' }}
          fontSize={fontSize}
          fontWeight={fontWeight}
        >
          {`+ ${count}`}
        </Text>
      </Circle>
    );
  };

  const [getUserPracticeAvailability, userPracticeAvailabilityQuery] =
    useLazyQuery(AppointmentQueries.GET_USER_PRACTICE_AVAILABILITY, {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      onCompleted: (data: any) => {
        if (
          data &&
          data?.getUserAvailibility &&
          data?.getUserAvailibility?.availability
        ) {
          const indexMap: any = {};
          let availabilityEvents: any[] = [];
          const availability = data?.getUserAvailibility?.availability;
          if (Object.keys(availability)?.length) {
            const uniqueUserIds = createUniqueSlotAvailableUsers(availability);
            Object.keys(availability).forEach((dateString) => {
              const dayWiseSlots = availability[dateString] || [];
              const updatedEvents = (dayWiseSlots || []).map(
                (slot: IAvailabilitySlot) => {
                  const startDateStr = (slot.startDateTime || '')
                    .concat('#')
                    .concat(slot.endDateTime || '');
                  if (!indexMap[startDateStr]) {
                    indexMap[startDateStr] = 0;
                  }
                  indexMap[startDateStr] = indexMap[startDateStr] + 1;
                  return {
                    id: (slot?.userId || '')
                      .concat(' ' + (slot?.locationId || ''))
                      .concat(dateString),
                    title: getPracticeAvailabilityTitle(
                      slot,
                      indexMap[startDateStr],
                      uniqueUserIds
                    ),
                    dayIndex: indexMap[startDateStr],
                    start: getDateObject(slot?.startDateTime),
                    end: getDateObject(slot?.endDateTime),
                    detail: slot,
                    primaryContactName: getUserNameById(slot?.userId),
                    locationName: getLocationNameForAvailability(
                      slot?.locationId
                    ),
                    locationCode: slot?.locationId,
                    userId: slot?.userId || '',
                    startDateTime: slot?.startDateTime,
                    endDateTime: slot?.endDateTime,
                    isShowMoreEvent:
                      indexMap[startDateStr] === AVAILABILITY_DAY_EVENT_INDEX,
                  };
                }
              );
              availabilityEvents = availabilityEvents.concat(updatedEvents);
            });
            const eventList = (availabilityEvents || []).filter(
              (appointmentData: any) => {
                if (
                  appointmentData?.dayIndex &&
                  calendarWidgetState.currentView === 'week'
                ) {
                  if (
                    appointmentData?.dayIndex <= AVAILABILITY_DAY_EVENT_INDEX
                  ) {
                    if (
                      appointmentData?.dayIndex == AVAILABILITY_DAY_EVENT_INDEX
                    ) {
                      const startDateStr = (appointmentData.startDateTime || '')
                        .concat('#')
                        .concat(appointmentData.endDateTime || '');
                      appointmentData.title = (
                        <AvailabilityEventComponent
                          primaryContactName={indexMap[startDateStr] - 1}
                          userWiseStyle={
                            USER_WISE_COLOR_CLASS[SHORE_MORE_EVENT_INDEX]
                          }
                        />
                      );
                    }
                    return true;
                  }
                  return false;
                }
                return true;
              }
            );
            setCalendarWidgetState((prev) => ({
              ...prev,
              availabilityEvent: eventList,
            }));
          }
        }
      },
      onError: (error: any) => {

        setCalendarWidgetState((prev) => ({
          ...prev,
          availabilityEvent: [],
        }));
      },
    });

  const getAppointmentUserId = (appointment: IAppointmentDetail) => {
    const userIds: string[] = [];
    (appointment.participants || []).forEach((participant) => {
      if (participant?.user) {
        userIds.push(participant.user.uuid);
      }
    });
    return userIds;
  };

  const showMoreButton = (formatedStartDate: any) => {
    setCalendarWidgetState((prev) => ({
      ...prev,
      events: [],
      currentDate: formatedStartDate,
      currentView: CalendarView.day,
    }));
    refetchAppointments(calendarWidgetState.currentView, formatedStartDate);
  };

  const showDayViewOfAvailability = (formattedStartDate: any) => {
    setCalendarWidgetState((prev) => ({
      ...prev,
      availabilityEvent: [],
      currentDate: formattedStartDate,
      currentView: CalendarView.day,
    }));
    refetchUserAvailability(formattedStartDate);
  };

  const showStrikeThrough = (
    appointment: IAppointmentDetail
  ): StyleProp<TextStyle> => {
    let styleProp: StyleProp<TextStyle> = {};
    if (
      appointment.statusId === APPOINTMENT_STATUS_CANCELLED ||
      appointment.statusId === APPOINTMENT_STATUS_DECLINED
    ) {
      styleProp = {
        textDecorationLine: 'line-through',
        textDecorationStyle: 'solid',
      };
    }
    return styleProp;
  };

  const getAppointmentCancelledText = (appointment: IAppointmentDetail) => {
    if (appointment.statusId === APPOINTMENT_STATUS_CANCELLED) {
      return 'Cancelled';
    } else if (appointment.statusId === APPOINTMENT_STATUS_DECLINED) {
      return 'Declined';
    }
    return '';
  };

  const getDiffInMin = (dateTimeDifference: any) => {
    return `${dateTimeDifference / 60000}min`;
  };

  const getAppointmentTimeInMinutes = (
    startDateTime: string,
    endDateTime: string
  ) => {
    const startDateTimeInDateFormat: any = new Date(startDateTime);
    const endDateTimeInDateFormat: any = new Date(endDateTime);
    if (startDateTimeInDateFormat > endDateTimeInDateFormat) {
      const diff = Math.abs(
        startDateTimeInDateFormat - endDateTimeInDateFormat
      );
      return getDiffInMin(diff);
    }
    const diff = Math.abs(endDateTimeInDateFormat - startDateTimeInDateFormat);
    return getDiffInMin(diff);
  };

  const getTitleForMonthView = (
    virtualAppointments: any,
    clinicAppointments: any
  ) => {
    const isFullWidthForVirtual = clinicAppointments
      ? clinicAppointments?.length === 0
      : false;
    const isFullWidthForClinic = virtualAppointments
      ? virtualAppointments?.length === 0
      : false;
    return (
      <HStack p={1} justifyContent="space-between">
        {virtualAppointments && virtualAppointments?.length ? (
          <VStack
            background={'#F6EBFE'}
            width={`${isFullWidthForVirtual ? '100%' : '48%'}`}
            height={'80px'}
            borderRadius={'8px'}
            alignItems="center"
            justifyContent={'center'}
          >
            <View mb={1}>
              <Icon as={FeatherIcon} name="video" size={4} />
            </View>

            <View
              background={'#F2F4F7'}
              borderRadius="full"
              height={'20px'}
              width={'20px'}
              alignItems="center"
              justifyContent={'center'}
            >
              <Text>{virtualAppointments?.length}</Text>
            </View>
          </VStack>
        ) : null}
        {clinicAppointments && clinicAppointments.length ? (
          <VStack
            background={'#F5F7FF'}
            width={`${isFullWidthForClinic ? '100%' : '48%'}`}
            height={'80px'}
            borderRadius={'8px'}
            alignItems="center"
            justifyContent={'center'}
          >
            <View mb={1}>
              <Icon as={FeatherIcon} name="users" size={4} />
            </View>

            <View
              background={'#F2F4F7'}
              borderRadius="full"
              height={'20px'}
              width={'20px'}
              alignItems="center"
              justifyContent={'center'}
            >
              <Text>{clinicAppointments.length}</Text>
            </View>
          </VStack>
        ) : null}
      </HStack>
    );
  };

  const isCancelOrDeclineAppointment = (appointment: IAppointmentDetail) => {
    return (
      appointment.statusId === APPOINTMENT_STATUS_CANCELLED ||
      appointment.statusId === APPOINTMENT_STATUS_DECLINED
    );
  };
  const getMonthViewEvent = (
    appointment: IAppointmentDetail,
    index?: number
  ): JSX.Element => {
    const primaryContactName = getPrimaryContactName(appointment);
    const locationCode = getLocationCode(appointment, scheduleLocationTypeList);
    if (index == 4) {
      return <></>;
    }
    const tooltipTitle = getTooltipTextForAppointment(appointment);
    if (appointment?.isBlockedInterval) {
      const blockUserName = appointment?.participants?.[0].user?.name;

      let accountLocationName = '';
      if (appointment.accountLocationId) {
        accountLocationName =
          calendarFilter.locationFilter?.accountLocations?.find((location) => {
            return location.uuid === appointment.accountLocationId;
          })?.name || '';
        if (accountLocationName) {
          accountLocationName = `(${accountLocationName})`;
        }
      } else if (appointment.isVirtualLocation) {
        accountLocationName = `(Virtual)`;
      }
      return (
        <div title={tooltipTitle as string}>
          <div style={{ height: '100%' }} className="block-interval">
            <HStack flex={1} space={1} alignItems={'center'}>
              <HStack flex={0.69} alignItems={'center'} space={1}>
                <View>
                  <BlockSlotIcon color={Colors.Custom.black25} />
                </View>
                <HStack flex={1}>
                  <Text
                    isTruncated
                    maxW={'90%'}
                    fontSize={'12px'}
                    fontWeight={500}
                  >
                    {appointment.name}
                  </Text>
                  {false && ( // kept for future reference
                    <Text
                      isTruncated
                      maxW={'80%'}
                      style={[{ fontSize: 11 }, showStrikeThrough(appointment)]}
                    >
                      {accountLocationName}
                    </Text>
                  )}
                </HStack>
              </HStack>
              <HStack
                flex={0.29}
                justifyContent={'flex-end'}
                justifyItems={'flex-end'}
              >
                <Text
                  fontSize={'12px'}
                  fontWeight={500}
                  color={Colors.Custom.Gray400}
                  justifyContent={'flex-end'}
                >
                  {getDateStrFromFormat(
                    appointment.startDateTime,
                    DATE_FORMATS.DISPLAY_TIME_FORMAT
                  )}
                </Text>
              </HStack>
            </HStack>
          </div>
        </div>
      );
    }
    return (
      <AntdTooltip title={tooltipTitle} color={Colors.Custom.Gray700}>
        <HStack flex={1} space={1} height={'100%'}>
          <HStack flex={0.69}>
            {isCancelOrDeclineAppointment(appointment) && (
              <CancelAndDeclineIcon color={Colors.Custom.black25} />
            )}
            {!isCancelOrDeclineAppointment(appointment) &&
              locationCode === 'VIRTUAL' && (
                <View marginTop={1}>
                  <VirtualAppointmentIcon color={Colors.Custom.black25} />
                </View>
              )}
            {!isCancelOrDeclineAppointment(appointment) &&
              locationCode !== 'VIRTUAL' && (
                <AtClinicAppointmentIcon color={Colors.Custom.black25} />
              )}
            <Text fontSize={'12px'} fontWeight={500} marginLeft={2}>
              {primaryContactName && primaryContactName?.length > 13
                ? primaryContactName?.slice(0, 13).concat('...')
                : primaryContactName}
            </Text>
          </HStack>
          <HStack
            flex={0.29}
            justifyContent={'flex-end'}
            justifyItems={'flex-end'}
          >
            <Text
              fontSize={'12px'}
              fontWeight={500}
              color={'#AAAAAA'}
              justifyContent={'flex-end'}
            >
              {getDateStrFromFormat(
                appointment.startDateTime,
                DATE_FORMATS.DISPLAY_TIME_FORMAT
              )}
            </Text>
          </HStack>
        </HStack>
      </AntdTooltip>
    );
  };

  const acceptAppointment = (appointment: IAppointmentDetail) => {
    const data = getDataForAppointmentStatusUpdate(
      appointment,
      APPOINTMENT_STATUS_BOOKED
    );
    bookAppointment({
      variables: {
        data: data,
      },
    });
  };

  const getMonthAppointmentName = (value: number, type: 'total' | 'group' | 'block' | 'single', dateString: string) => {
    const color = moment(dateString).isBefore(moment()) ? Colors.Custom.Gray500 : '';
    switch (type) {
      case 'total':
        return <Pressable onPress={()=> {setCalendarMemberModal({open: true, selectedDate: dateString});}}> <HStack alignItems={'center'} paddingLeft={2}> <Text color={color} fontSize={22}>{`${value}`}</Text></HStack></Pressable>;
      case 'block':
        return <Pressable onPress={()=> {setCalendarMemberModal({open: true, selectedDate: dateString});}}><HStack alignItems={'center'} paddingLeft={2}> <MaterialCommunityIcons color={color} size={16} name='block-helper' style={{ marginRight: 6 }} /> <Text color={color} fontSize={12}>{`${value} Blocked`}</Text></HStack></Pressable>;
      case 'group':
        return <Pressable onPress={()=> {setCalendarMemberModal({open: true, selectedDate: dateString});}}><HStack  alignItems={'center'} paddingLeft={2}> <FeatherIcon color={color} size={16} name='users' style={{ marginRight: 6 }} /> <Text color={color} fontSize={12}>{`${value} Group Events`}</Text></HStack> </Pressable> ;
      case 'single':
        return <Pressable onPress={()=> {setCalendarMemberModal({open: true, selectedDate: dateString});}}><HStack alignItems={'center'} paddingLeft={2}> <FeatherIcon color={color} size={16} name='user' style={{ marginRight: 6 }} /> <Text color={color} fontSize={12}>{`${value} Appointments`}</Text></HStack></Pressable>;
      default:
        return null
    }
  }

  const renderGroupAppoinmentParticipantsCount = (appointment: IAppointmentDetail, textColor: string) => {
    const isGroupAppointment = appointment?.appointmentType?.category?.code === MLOV_CODES.GROUP_SESSION;
    const isRsvpEnabled = appointment?.isRsvpEnabled;

    if (!isGroupAppointment) {
      return <></>;
    }

    const totalInvites = appointment?.participants?.filter(
      (item) => item.contactId
    );
    const totalInvitesContactId = appointment?.participants
      ?.filter((item) => item.contactId)
      ?.map((item) => item.contactId);
    const totalInvitesUnique = totalInvitesContactId?.filter(
      (item, index) => totalInvitesContactId.indexOf(item) == index
    );
    const totalAccepted = totalInvites?.filter(
      (item) => item.statusId == ACCEPTED_PARTICIPANT_CODE
    );

    return (
      <HStack alignItems={'center'}>
        <FeatherIcon
          name={isRsvpEnabled ? "user-check" : "users"}
          color={Colors.Custom.PurpleColor}
          size={12}
        />
        <Text
          color={textColor}
          style={[
            showStrikeThrough(appointment),
            {
              marginLeft: 4,
            },
          ]}
          fontSize={12}
        >
          {isRsvpEnabled
            ? `${totalAccepted?.length}/${totalInvitesUnique?.length} Accepted`
            : `${totalInvitesUnique?.length} Added`}
        </Text>
      </HStack>
    );
  }

  const getAppointmentName = (
    appointment: IAppointmentDetail,
    index?: number
  ): JSX.Element => {
    if (calendarWidgetState.currentView === 'month') {
      return getMonthViewEvent(appointment, index);
    }
    const isPastAppointment = isDayAndDateInPast(appointment.endDateTime);
    const isGroupAppointment = appointment?.appointmentType?.category?.code === MLOV_CODES.GROUP_SESSION;

    const primaryContactName = getPrimaryContactName(appointment, isGroupAppointment);
    const locationCode = getLocationCode(appointment, scheduleLocationTypeList);
    const appointmentStatus =  getMlovValueFromId(
      appointmentStatusList,
      appointment.statusId
    );
    const isCheckedOut = appointment.statusId == APPOINTMENT_STATUS_CHECKED_OUT
    const duration = getDiffInMinutes(
      appointment.startDateTime,
      appointment.endDateTime
    );
    const isSmallDurationAppointment = duration <= 20;
    const textColor = isPastAppointment ? Colors.Custom.Gray400 : '#000';
    const tooltipTitle = getTooltipTextForAppointment(appointment);
    const appointmentType = appointment?.appointmentType?.eventName;
    const isRecurrentAppointment = appointment?.isRecurrentAppointment && !!appointment?.seriesId || false
    if (primaryContactName && !appointment.isBlockedInterval) {
      const reasonForVisit = appointment?.reasonForVisit?.displayName;
      return (
        <AntdTooltip title={tooltipTitle} color={Colors.Custom.Gray700}>
          <HStack space={1} alignItems={'flex-start'}>
            <VStack flex={1}>
              <>
                <HStack alignItems={'center'}>
                  {isRecurrentAppointment && (
                    <ReccuringAppointmentIcon color={Colors.Custom.black25} />
                  )}
                  <Text
                    ml={isRecurrentAppointment ? 1 : undefined}
                    fontSize="xs"
                    fontWeight={600}
                    color={textColor}
                    style={showStrikeThrough(appointment)}
                    maxW={'80%'}
                    isTruncated
                  >
                    {isGroupAppointment ? 'Group Event ' : primaryContactName}
                  </Text>
                </HStack>
                {isGroupAppointment && (
                  <HStack alignItems={'center'}>
                    <Text
                      fontSize="xs"
                      color={textColor}
                      style={showStrikeThrough(appointment)}
                      maxW={'80%'}
                      isTruncated
                    >
                      {primaryContactName}
                    </Text>
                  </HStack>
                )}
              </>
              {!isSmallDurationAppointment && (
                <>
                  {!isSmallDurationAppointment && reasonForVisit && (
                    <HStack space={0.5}>
                      <Text
                        noOfLines={1}
                        fontSize="xs"
                        color={textColor}
                        style={showStrikeThrough(appointment)}
                        maxW={'80%'}
                        isTruncated
                      >
                        {reasonForVisit}
                      </Text>
                    </HStack>
                  )}
                  {!isGroupAppointment && (
                    <HStack width={'full'} alignItems="center">
                      <Text
                        style={showStrikeThrough(appointment)}
                        color={textColor}
                        fontSize="xs"
                      >
                        {appointmentStatus}
                      </Text>
                    </HStack>
                  )}
                </>
              )}
              {renderGroupAppoinmentParticipantsCount(appointment, textColor)}
            </VStack>
            <HStack>
              {locationCode !== 'VIRTUAL' ? (
                <AtClinicAppointmentIcon color={Colors.Custom.black25} />
              ) : null}
              {locationCode === 'VIRTUAL' && (
                <Icon
                  as={FeatherIcon}
                  onPress={() => {
                    if (
                      !isCheckedOut &&
                      appointment.reference &&
                      appointment?.reference?.meetingInfo &&
                      appointment?.reference?.meetingInfo?.id
                    ) {
                      const showJoinMeeting = isAppointmentBookForSelectedUser(
                        userUUID,
                        appointment
                      );
                      if (showJoinMeeting) {
                        onJoinMeetingClicked(appointment);
                      }
                    }
                  }}
                  name="video"
                  size={4}
                  mr={1}
                  color={Colors.Custom.black25}
                />
              )}
            </HStack>
          </HStack>
        </AntdTooltip>
      );
    } else if (appointment.isBlockedInterval) {
      let accountLocationName = '';
      if (appointment.accountLocationId) {
        accountLocationName =
          calendarFilter.locationFilter?.accountLocations?.find((location) => {
            return location.uuid === appointment.accountLocationId;
          })?.name || '';
        if (accountLocationName) {
          accountLocationName = `(${accountLocationName})`;
        }
      } else if (appointment.isVirtualLocation) {
        accountLocationName = `(Virtual)`;
      }

      return (
        <VStack>
          {appointment?.blockedContactName && (
            <Text
              isTruncated
              maxW={'90%'}
              fontSize={'12px'}
              color={textColor}
              fontWeight={600}
            >
              {appointment?.blockedContactName}
            </Text>
          )}
          <Text
            fontSize="xs"
            fontWeight={appointment?.blockedContactName ? 500 : 600}
            color={textColor}
            maxW={'95%'}
            isTruncated
          >
            {isRecurrentAppointment && (
              <Box mr={2}>
                <ReccuringAppointmentIcon color={Colors.Custom.black25} />
              </Box>
            )}
            {appointment?.name || 'Blocked'}
          </Text>

          {accountLocationName && (
            <Text
              style={[{ fontSize: 12 }, showStrikeThrough(appointment)]}
              color={textColor}
            >
              {accountLocationName}
            </Text>
          )}
        </VStack>
      );
    } else {
      return (
        <AntdTooltip title={tooltipTitle} color={Colors.Custom.Gray700}>
          <View height={'100%'}>
            <Text
              fontSize="xs"
              fontWeight={600}
              color={textColor}
              style={showStrikeThrough(appointment)}
            >
              {appointment.name}
            </Text>
          </View>
        </AntdTooltip>
      );
    }
  };

  function getTooltipTextForAppointment(appointment: IAppointmentDetail) {
    const isGroupAppointment = appointment?.appointmentType?.category?.code === MLOV_CODES.GROUP_SESSION;

    let appointmentTimeTitle = '';
    if (appointment.startDateTime) {
      const startDateTime = getMomentObj(appointment.startDateTime);
      let durationInMin = 0;
      if (appointment.endDateTime) {
        const endDateTime = getMomentObj(appointment.endDateTime);
        durationInMin = endDateTime.diff(startDateTime, 'minutes');
      }

      if (appointment.isBlockedInterval) {
        appointmentTimeTitle = `${getAppointmentDateTime(appointment)}`;
      } else {
        const startDateTimeStr = startDateTime.format('hh:mm A');
        appointmentTimeTitle = `${startDateTimeStr}${durationInMin ? ' • ' + durationInMin + ' min' : ''
          }`;
      }
    }

    let tooltipTitle: string | JSX.Element = '';
    const primaryContactName = getPrimaryContactName(appointment);

    if (appointment.isBlockedInterval) {
      let accountLocationName = '';
      if (appointment?.accountLocationId) {
        const accountLocationId = appointment?.accountLocationId;
        const accountLocationObj =
          calendarFilter.locationFilter?.accountLocations?.find(
            ({ uuid }) => uuid === accountLocationId
          );
        if (accountLocationObj?.name) {
          accountLocationName = `${accountLocationObj?.name}`;
        }
      } else if (appointment.isVirtualLocation) {
        accountLocationName = `Virtual`;
      }
      if (accountLocationName) {
        tooltipTitle = accountLocationName;
      }
      if (appointmentTimeTitle) {
        if (tooltipTitle) {
          tooltipTitle += ' • ';
        }
        tooltipTitle += appointmentTimeTitle;
      }
      return tooltipTitle;
    } else {
      if (primaryContactName) {
        tooltipTitle = primaryContactName;
      }
      if (appointmentTimeTitle) {
        if (tooltipTitle) {
          tooltipTitle += ' • ';
        }
        tooltipTitle += appointmentTimeTitle;
      }
      tooltipTitle = (
        <Text color={'white'} fontSize={12} textAlign={'center'}>
          {tooltipTitle}
        </Text>
      );

      const reasonForVisit = appointment?.reasonForVisit?.displayName;
      if (reasonForVisit) {
        // tooltipTitle += `\n---\nVisit Reason: ${resonForVisit}`;
        tooltipTitle = (
          <VStack alignItems={'center'} justifyContent={'center'}>
            {tooltipTitle}
            <Text color={'white'} fontSize={12} textAlign={'center'}>
              {'------'}
            </Text>
            <Text
              color={'white'}
              fontSize={12}
              textAlign={'center'}
            >{`Visit Reason: ${reasonForVisit}`}</Text>
          </VStack>
        );
      }
    }
    if(isGroupAppointment){
      tooltipTitle = appointment?.name +' • ' + appointmentTimeTitle;
    }

    // return tooltipTitle;
    return <View>{tooltipTitle}</View>;
  }

  const getLocationNameForAvailability = (locationId: string | undefined) => {
    let locationName = '';
    if (!locationId) {
      locationName = 'Virtual';
    } else {
      (calendarWidgetState.accountLocations || []).some((location) => {
        if (location?.uuid === locationId) {
          locationName = location?.name;
          return true;
        }
      });
    }
    return locationName;
  };

  const getUserClassAndStyleObject = (
    selectedUserId: string,
    dayIndex: number,
    uniqueUserIds?: string[]
  ): { textColor: string; className: string } => {
    if (
      calendarWidgetState.currentView === 'week' &&
      dayIndex == AVAILABILITY_DAY_EVENT_INDEX
    ) {
      return USER_WISE_COLOR_CLASS[SHORE_MORE_EVENT_INDEX];
    }
    const index: number = (
      uniqueUserIds ||
      calendarWidgetState.uniqueAvailabilityUsers ||
      []
    ).findIndex((userId) => {
      return userId === selectedUserId;
    });
    let userIndexValue = '';
    if (index >= 0) {
      userIndexValue = String(index > 10 ? index % 10 : index);
      return USER_WISE_COLOR_CLASS[userIndexValue];
    }
    return { textColor: '', className: '' };
  };

  const getUserAvailabilityEventClass = (
    selectedUserId: string,
    dayIndex: number
  ) => {
    const userWiseStyleObj = getUserClassAndStyleObject(
      selectedUserId,
      dayIndex
    );
    return userWiseStyleObj?.className;
  };

  const getPracticeAvailabilityTitle = (
    slot: IAvailabilitySlot,
    dayIndex: number,
    uniqueUserIds?: string[]
  ): JSX.Element => {
    const primaryContactName = getUserNameById(slot?.userId || '');
    const locationName = getLocationNameForAvailability(slot?.locationId);
    const userWiseStyle = getUserClassAndStyleObject(
      slot.userId,
      dayIndex,
      uniqueUserIds
    );
    const isDefaultLocation = isDefaultLocationSelected(
      calendarFilter.locationFilter.selectedLocation
    );

    return (
      <AvailabilityEventComponent
        locationName={locationName}
        primaryContactName={primaryContactName}
        userWiseStyle={userWiseStyle}
        isDefaultLocationSelected={isDefaultLocation}
      />
    );
  };

  const onJoinMeetingClicked = (appointment: IAppointmentDetail) => {
    const isFutureAppointment = isFutureMeeting(appointment);
    const isPastAppointment = isPastMeeting(appointment);
    if (isFutureAppointment || isPastAppointment) {
      setJoinMeetingConfirmation({
        visible: true,
        selectedAppointment: appointment,
        header: intl.formatMessage({
          id: isFutureAppointment
            ? 'joinFutureAppointment'
            : 'joinPastAppointment',
        }),
        message: intl.formatMessage({
          id: isFutureAppointment
            ? 'joinFutureAppointmentMsg'
            : 'joinPastAppointmentMsg',
        }),
      });
    } else {
      joinMeeting(appointment);
    }
  };

  const isZoomEnabledForAccount = isZoomEnabled();
  const joinMeeting = async (appointment: IAppointmentDetail) => {
    const meetingId = appointment.reference?.meetingInfo?.id;
    const isZoomMeeting = await isZoomMeetingId({meetingId});
    const appointmentId = appointment.id;
    joinZoomMeeting(
      meetingId,
      userEmail,
      appointmentId,
      accountUUID,
      userUUID,
      userData.name,
      isZoomMeeting
    );
    resetJoinMeetingData();
  };

  const resetJoinMeetingData = () => {
    setJoinMeetingConfirmation({
      visible: false,
      selectedAppointment: undefined,
      message: '',
      header: '',
    });
  };

  const handleSlotSelect = (selectInfo: SlotInfo, isSlotManuallySelected?: boolean) => {
    if (canSelectSlot(calendarWidgetState, selectInfo)) {
      showToast(
        toast,
        intl.formatMessage({id: 'appointmentPastTimeInfo'}),
        ToastType.info,
      );
      return;
    }

    const selected: CalendarSlot = {
      start: selectInfo.start,
      end: selectInfo.end,
      slots: selectInfo.slots,
      action: selectInfo.action,
      view: calendarWidgetState.currentView,
    };
    setCalendarWidgetState((prev) => ({
      ...prev,
      modalOpen: true,
      slotSelected: selected,
      disAllowToRedirectAvailableSlot: isSlotManuallySelected || false,
    }));
  };
  const refetchAppointments = async (currentView: string, currentDate: Date) => {
    const userIdList: string[] = calendarFilter.userFilter?.selectedUser.map((item) => item.uuid) || [];
    const locationIdList: string[] = calendarFilter.locationFilter?.selectedLocation?.map((item) => item.uuid) || [];
    const dateRange = getDateRangeForCalendar(currentView, currentDate);
    const appointmentStatusIds = appointmentStatusList.map(
      (status) => status.id
    );

    if (currentView === CalendarView.month) {
      setCalendarWidgetState((prev) => ({
        ...prev,
        events: [],
      }));
      const startDate = moment(dateRange.endDate) >= moment(dateRange.startDate) ? dateRange.startDate : dateRange.endDate;
      const endDate = moment(dateRange.endDate) >= moment(dateRange.startDate) ? dateRange.endDate : dateRange.startDate;

      const range = new Array(moment(endDate).diff(moment(startDate), 'days') + 1).fill(1).map((item, index) => {
        return (moment(dateRange.startDate)?.add(index, 'day')?.format('yyyy-MM-DD'))
      });

      const variables: any = {
        "startDateTime": dateRange.startDate, // mandatory
        "endDateTime": dateRange.endDate, // mandatory
        "userIds": calendarFilter.userFilter.selectedUser?.map((item)=> item.uuid),  // list of users is mandatory
        "timezone": getCurrentTimeZone(), // mandatory
        ...(locationIdList.length && { "accountLocationIds": locationIdList }),
        "groupBy": "date", // possible values - 'date' | 'userDateLocation'
      }

      if (appointmentTypeAndStatusFilter.selectedAppointmentTypes?.length) {
        variables["appointmentTypeIds"] = appointmentTypeAndStatusFilter.selectedAppointmentTypes;
      }

      if (appointmentTypeAndStatusFilter.selectedAppointmentStatus?.length) {
        variables["statusIds"] = appointmentTypeAndStatusFilter?.selectedAppointmentStatus;
      }
      await getAppointmentAggregate({
        variables: {
          params: variables
        }
      }).then((resp) => {
        const appointments = resp?.data?.appointments || [];
        const dateWiseAppointments: Record<string, IAppointmentAggregate> = {};
        appointments.forEach((item: IAppointmentAggregate) => {
          dateWiseAppointments[item.date] = item;
        })
        const updatedEvents = range.map((date: string) => {
          const item = dateWiseAppointments[date];
          return (item ? [{
            id: `appointment ${item.date}`,
            title: getMonthAppointmentName(item.aggregate.appointments + item.aggregate.groupAppointments,
              'total',
              item.date
            ),
            start: getMidDayDateObject(item.date),
            end: getMidDayDateObject(item.date),
            statusCode: "",
            locationCode: "",
            primaryContactName: ""
          }, {
            id: `single ${item.date}`,
            title: getMonthAppointmentName(item.aggregate.appointments,
              'single',
              item.date
            ),
            start: getMidDayDateObject(item.date),
            end: getMidDayDateObject(item.date),
            statusCode: "",
            locationCode: "",
            primaryContactName: ""
          }, {
            id: `group ${item.date}`,
            title: getMonthAppointmentName(item.aggregate.groupAppointments,
              'group',
              item.date
            ),
            start: getMidDayDateObject(item.date),
            end: getMidDayDateObject(item.date),
            statusCode: "",
            locationCode: "",
            primaryContactName: ""
          }] as ICalendarEvent[] : [{
            id: `appointment ${date}`,
            title: getMonthAppointmentName(0,
              'total',
              date
            ),
            start: getMidDayDateObject(date),
            end: getMidDayDateObject(date),
            statusCode: "",
            locationCode: "",
            primaryContactName: ""
          },
          {
            id: `single ${date}`,
            title: getMonthAppointmentName(0,
              'single',
              date
            ),
            start: getMidDayDateObject(date),
            end: getMidDayDateObject(date),
            statusCode: "",
            locationCode: "",
            primaryContactName: ""
          }, {
            id: `group ${date}`,
            title: getMonthAppointmentName(0,
              'group',
              date
            ),
            start: getMidDayDateObject(date),
            end: getMidDayDateObject(date),
            statusCode: "",
            locationCode: "",
            primaryContactName: ""
          }] as ICalendarEvent[])
        }
        ).flat()
        setCalendarWidgetState((prev) => ({
          ...prev,
          events: updatedEvents,
        }));
      });
    } else {
      getBookedAppointments({
        variables: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          userIds: userIdList,
          statusIds: appointmentTypeAndStatusFilter?.selectedAppointmentStatus?.length ? appointmentTypeAndStatusFilter?.selectedAppointmentStatus : appointmentStatusIds,
          accountLocationIds: locationIdList,
          appointmentTypeIds: appointmentTypeAndStatusFilter.selectedAppointmentTypes || [],
        }
      });
    }
  };

  const showAppointmentBookingToast = (message: string) => {
    showToast(
      toast,
      message,
      ToastType.success,
    );
  };

  const resetDataAfterModalDismiss = () => {
    setCalendarWidgetState((prev) => ({
      ...prev,
      modalOpen: !calendarWidgetState.modalOpen,
      selectedEvent: undefined,
      appointmentType: undefined,
      slotSelected: undefined,
    }));
  };

  const refetchUserAvailability = (currentDate?: any, currentView?: string) => {
    const userIdList: string[] = [];
    const locationIdList: string[] = [];
    let dayDate: any = '';

    if (currentView === 'day' || calendarWidgetState.currentView === 'day') {
      dayDate = getNextCalendarDate(
        'day',
        currentDate || calendarWidgetState.currentDate
      );
    } else {
      dayDate = currentDate || calendarWidgetState.currentDate;
    }
    const selectedLocations = calendarFilter.locationFilter?.selectedLocation || [];
    const selectedUsers = calendarFilter.userFilter.selectedUser || [];
    const dateRange = getDateRangeForCalendar(
      currentView || calendarWidgetState.currentView,
      dayDate
    );
    selectedUsers.forEach((item) => {
      userIdList.push(item.uuid);
    });
    const locations = selectedLocations?.length ? selectedLocations : (calendarFilter.locationFilter?.accountLocations || []);
    locations.forEach((item) => {
      locationIdList.push(item.uuid);
    });

    getUserPracticeAvailability({
      variables: {
        data: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          userIds: userIdList,
          locationIds: locationIdList,
          timezone: getCurrentTimeZone(),
          isVirtualLocation: isDefaultLocationSelected(selectedLocations),
        },
      },
    });
  };

  useEffect(() => {
    if (calendarWidgetState.isPracticeAvailabilityView) {
      refetchUserAvailability();
    } else {
      refetchAppointments(
        calendarWidgetState.currentView,
        calendarWidgetState.currentDate
      );
    }
  }, [
    calendarFilter,
    appointmentTypeAndStatusFilter.selectedAppointmentStatus,
    appointmentTypeAndStatusFilter.selectedAppointmentTypes
  ]);

  useEffect(() => {
    if (calendarWidgetState.isPracticeAvailabilityView) {
      refetchUserAvailability();
    }
  }, [calendarWidgetState.isPracticeAvailabilityView]);


  const onAppointmentCreated = (selectedUserUuid: string) => {
    resetDataAfterModalDismiss();
    refetchAppointments(
      calendarWidgetState.currentView,
      calendarWidgetState.currentDate
    );
  }

  useEffect(() => {
    if (props?.isDayOptimizerCalender) {
      onViewChangeHandler(CalendarView.day)
    }

    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT,
      onAppointmentCreated
    );

    return () => {
      eventBus.removeEventListener(onAppointmentCreated);
    }
  }, [props?.isDayOptimizerCalender]);

  const calendarWidgetStyle = React.useMemo<React.CSSProperties>(
    () => ({
      height: props?.isDayOptimizerCalender ? screenHeight - 190 : screenHeight - 140,
    }),
    [props?.isDayOptimizerCalender, screenHeight]
  );

  const handlePracticeAvailabilityTurnedOff = () => {
    const defaultUserFilter = setDefaultUserFilter(undefined, undefined, CalendarView.week);
    defaultUserFilter.selectedUser = [findUserFromList(
      userUUID || '',
      defaultUserFilter.accountUsers
    )];
    const defaultLocationFilter = setDefaultLocationFilter(defaultUserFilter.selectedUser || []);
    setCalendarFilter((prev) => {
      return {
        ...prev,
        locationFilter: defaultLocationFilter,
        userFilter: defaultUserFilter,
      };
    });
    updateUserFilterPreferenceWithArgument({
      selectedLocations: defaultLocationFilter.selectedLocation,
      selectedUsers: defaultUserFilter.selectedUser,
    });
    setResourceMap(getResourceMapData(defaultUserFilter.selectedUser, CalendarView.week));
  }

  const onViewChangeHandler = (view: string, isPracticeAvailabilityView?: boolean) => {
    if (calendarWidgetState.isPracticeAvailabilityView) {
      setCalendarWidgetState((prev) => ({
        ...prev,
        availabilityEvent: [],
        currentView: view as CalendarView,
      }));
      updateUserFilterPreferenceWithArgument({
        currentView: view as CalendarView,
        ...(typeof isPracticeAvailabilityView === 'boolean' && {
          isPracticeAvailabilityView: isPracticeAvailabilityView,
        }),
      });
      refetchUserAvailability(calendarWidgetState.currentDate, view);
    } else {
      setCalendarWidgetState((prev) => ({
        ...prev,
        events: [],
        currentView: view as CalendarView,
      }));
      if (view === CalendarView.day) {
        setDayViewWiseFilter(view, undefined, isPracticeAvailabilityView);
      } else {
        const defaultUserFilter = setDefaultUserFilter(undefined, undefined, view);
        const userUnFormattedList = defaultUserFilter?.accountUsers || [];
        if (isDefaultUserSelected(calendarFilter.userFilter?.selectedUser)) {
          defaultUserFilter.selectedUser = userUnFormattedList;
        } else {
          defaultUserFilter.selectedUser = calendarFilter.userFilter?.selectedUser;
        }
        if (view === CalendarView.week && defaultUserFilter.selectedUser.length > 1) {
          const user = findUserFromList(userUUID, defaultUserFilter.selectedUser);
          defaultUserFilter.selectedUser = user?.uuid ? [user] : [defaultUserFilter.selectedUser[0]];
        }
        const defaultLocationFilter = setDefaultLocationFilter(
          defaultUserFilter?.selectedUser || []
        )
        setCalendarFilter((prev) => {
          return {
            ...prev,
            locationFilter: defaultLocationFilter,
            userFilter: defaultUserFilter,
          };
        });
        updateUserFilterPreferenceWithArgument({
          selectedLocations: defaultLocationFilter.selectedLocation,
          selectedUsers: defaultUserFilter.selectedUser,
          currentView: view as CalendarView,
          ...(typeof isPracticeAvailabilityView === 'boolean' && {
            isPracticeAvailabilityView: isPracticeAvailabilityView,
          }),
        });
        refetchAppointments(view, calendarWidgetState.currentDate);
        setResourceMap(getResourceMapData(defaultUserFilter.selectedUser));
      }
    }
  };

  const updateUserFilterPreferenceWithArgument =(updatedFields: Partial<ICallAddUpdateUserFilterPreference>) => {
    const argument: ICallAddUpdateUserFilterPreference = {
      selectedLocations: calendarFilter.locationFilter.selectedLocation,
      selectedUsers: calendarFilter.userFilter.selectedUser,
      currentView: calendarWidgetState.currentView,
      selectedAppointmentTypes:
        appointmentTypeAndStatusFilter?.selectedAppointmentTypes,
      selectedAppointmentStatus:
        appointmentTypeAndStatusFilter?.selectedAppointmentStatus,
      isPracticeAvailabilityView:
        calendarWidgetState?.isPracticeAvailabilityView,
      dayViewUserOrderPreference: componentState?.dayViewUserOrderPreference,
      ...updatedFields,
    };
    callAddUpdateUserFilterPreference(argument);
  }

  const onParticipantChangeHandler = (participantIds: string[]) => {
    const userFilter = calendarFilter.userFilter;
    let selectedUserIds = participantIds;
    if (!participantIds?.length) {
      selectedUserIds = [userUUID];
    }
    let selectedUsers = calendarMetaData.accountUsers.filter((item: IUser) => selectedUserIds.includes(item.uuid));
    if (!selectedUsers.length && calendarMetaData.accountUsers.length) {
      selectedUsers = [calendarMetaData.accountUsers[0]];
    }
    userFilter.selectedUser = selectedUsers;
    if (isDefaultUserSelected(selectedUsers)) {
      userFilter.accountUsers = calendarMetaData.accountUsers || [];
    }

    const locationFilter = calendarFilter.locationFilter;
    const locationList = getLocationListByUserIdList(selectedUsers);

    locationFilter.accountLocations = locationList?.length ? locationList : [];
    locationFilter.selectedLocation = locationFilter.accountLocations.filter((item) => {
      return locationFilter.selectedLocation.some((location) => location.uuid === item.uuid);
    });

    setCalendarFilter((prev) => {
      return {
        ...prev,
        userFilter: userFilter,
        locationFilter: locationFilter,
      };
    });
    setBookAppointmentMeta({
      selectedUser: userFilter?.selectedUser,
      selectedLocation: locationFilter?.selectedLocation,
    });
    setResourceMap(getResourceMapData(selectedUsers));
    // Change to month view if we select multiple providers
    if(participantIds?.length > 1 ){
      setCalendarWidgetState((prev) => {
        return {
          ...prev,
          ...(!calendarWidgetState.isPracticeAvailabilityView && {currentView: CalendarView.month}),
          events: [],
          appointments: [],
        }
      })
    }
    updateUserFilterPreferenceWithArgument({
      selectedLocations: locationFilter?.selectedLocation,
      selectedUsers: userFilter?.selectedUser,
      ...(participantIds?.length > 1 && !calendarWidgetState.isPracticeAvailabilityView && {currentView: CalendarView.month})
    })
  };

  const onLocationChangeHandler = (locationIds: string[]) => {
    const userFilter = calendarFilter.userFilter;
    const selectedUsers: IUser[] = userFilter.selectedUser || [];
    const locationFilter = calendarFilter.locationFilter;
    const selectedLocations = calendarFilter.locationFilter?.accountLocations?.filter((item)=> locationIds.includes(item.uuid)) || [];
    locationFilter.selectedLocation = selectedLocations;
    if (
      !calendarWidgetState.isPracticeAvailabilityView &&
      calendarWidgetState.currentView === CalendarView.day
    ) {
      setDayViewWiseFilter(CalendarView.day, selectedLocations);
    } else {
      if (isDefaultLocationSelected(selectedLocations)) {
        const filterUsers = setDefaultUserFilter(
          userUUID,
          locationIds
        );
        filterUsers.selectedUser = selectedUsers.length ? selectedUsers : (calendarWidgetState.accountUsers?.length ? [calendarWidgetState.accountUsers[0]] : []);
        setCalendarFilter((prev) => {
          return {
            ...prev,
            userFilter: filterUsers,
            locationFilter: locationFilter,
          };
        });
        updateUserFilterPreferenceWithArgument({
          selectedUsers: userFilter?.selectedUser,
          selectedLocations: locationFilter?.selectedLocation,
        });
      } else {
        if (
          calendarWidgetState.isPracticeAvailabilityView &&
          isDefaultUserSelected(selectedUsers)
        ) {
          setCalendarFilter((prev) => {
            return {
              ...prev,
              locationFilter: locationFilter,
            };
          });
          updateUserFilterPreferenceWithArgument({
            selectedLocations: locationFilter.selectedLocation,
          });
        } else {
          const userList = getUserListByLocationIdList(locationIds);
          const filterUsers = userList?.length ? userList : [];
          userFilter.accountUsers = filterUsers;
          userFilter.selectedUser = filterUsers.filter((item) => {
            return selectedUsers.some((user) => user.uuid === item.uuid);
          });
          setCalendarFilter((prev) => {
            return {
              ...prev,
              userFilter: userFilter,
              locationFilter: locationFilter,
            };
          });
          updateUserFilterPreferenceWithArgument({
            selectedUsers: userFilter?.selectedUser,
            selectedLocations: locationFilter.selectedLocation,
          });
        }
      }
      setResourceMap(getResourceMapData(userFilter.selectedUser));
    }
  };

  const isSlotAvailable = (slot: Date, resourceId: string) => {
    const filterEvent = (calendarWidgetState.events || []).filter(
      (event: any) => {
        return event?.resourceId === resourceId;
      }
    );
    if (!filterEvent || !filterEvent?.length) {
      return true;
    }
    slot.setSeconds(slot.getSeconds() + 1);
    const isSlotBooked = (filterEvent || []).some((event: any) => {
      return isBetweenDate(
        slot,
        getDateObject(event?.detail?.startDateTime),
        getDateObject(event?.detail?.endDateTime),
        '[]'
      );
    });
    return !isSlotBooked;
  };

  const setDayViewWiseFilter = (currentView: string, selectedLocations?: ILocation[], isPracticeAvailabilityView?: boolean) => {
    const userFilter = calendarFilter.userFilter;
    const locationFilter = calendarFilter.locationFilter;
    let userList: IUser[] = [];
    const selectedLocation = selectedLocations || locationFilter.selectedLocation;
    if (props?.isDayOptimizerCalender) {
      userList = calendarWidgetState.accountUsers.filter((user) => {
        return props?.filterPreferenceData?.selectedUserIds?.includes(user.uuid);
      });
    } else if (isDefaultLocationSelected(selectedLocation)) {
      userList = calendarWidgetState.accountUsers;
    }
    switch (currentView) {
      case CalendarView.day:
        if (!userList?.length && !props?.isDayOptimizerCalender) {
          userList = getUserListByLocationIdList(selectedLocation.map(item => item.uuid));
        }
        userFilter.enabledDropDown = false;
        userFilter.accountUsers = userList;
        userFilter.selectedUser = userList;

        if (props?.isDayOptimizerCalender) {
          const locationList = getLocationListByUserIdList(userList);
          locationFilter.selectedLocation = locationList?.length ? locationList : [];
        } else {
          locationFilter.accountLocations = calendarWidgetState.accountLocations || [];
        }
        locationFilter.enabledDropDown = locationFilter.accountLocations?.length > 0;
        locationFilter.selectedLocation = selectedLocation;
        break;
    }
    setCalendarFilter((prev) => ({
      ...prev,
      userFilter: userFilter,
      locationFilter: locationFilter,
    }));
    updateUserFilterPreferenceWithArgument({
      selectedLocations: locationFilter?.selectedLocation,
      selectedUsers: userFilter?.selectedUser,
      currentView: currentView as CalendarView,
      ...(typeof isPracticeAvailabilityView === 'boolean' && {
        isPracticeAvailabilityView: isPracticeAvailabilityView,
      }),
    });
    setResourceMap(getResourceMapData(userFilter.selectedUser, currentView));
  };

  const editAvailabilitiesByLocationOrUser = (
    availabilityCalendarData?: IAvailabilityCalendarData[]
  ) => {
    const availabilityCalendarDataObject = (
      availabilityCalendarData ||
      componentState.availabilityCalendarData ||
      []
    ).find((data) => {
      const id = data['userId'];
      if (id && id === calendarWidgetState?.selectedEventUserId) {
        return true;
      }
    });

    if (availabilityCalendarDataObject) {
      setComponentState((prev) => ({
        ...prev,
        isAddOrUpdateDrawerOpen: true,
        currentAvailabilitiesByDaysOfWeek: JSON.parse(
          JSON.stringify(
            availabilityCalendarDataObject.availabilitiesByDaysOfWeek
          )
        ),
        currentOverriddenAvailabilitiesByDateRangeKey: JSON.parse(
          JSON.stringify(
            availabilityCalendarDataObject.overriddenAvailabilitiesByDateRangeKey
          )
        ),
        selectedUserData: availabilityCalendarDataObject.userData,
      }));
    }
  };

  const onAvailabilitySlotHandler = (userId: string, eventData: any) => {
    setCalendarWidgetState((prev) => {
      return {
        ...prev,
        selectedEventUserId: userId,
        selectedAvailabilityEvent: eventData,
      };
    });
    getAvailabilitiesAPI();
  };

  const getSelectedEventDate = () => {
    const selectedEvent =
      calendarWidgetState?.selectedAvailabilityEvent?.detail;
    let selectedDate = '';
    if (selectedEvent) {
      selectedDate = selectedEvent.endDateTime;
    } else {
      selectedDate = calendarWidgetState?.selectedAvailabilityEvent?.start;
    }
    return selectedDate;
  };

  const getLocationId = () => {
    const selectedEvent =
      calendarWidgetState?.selectedAvailabilityEvent?.detail;
    let locationId = '';
    if (selectedEvent) {
      locationId =
        selectedEvent && selectedEvent?.locationId
          ? selectedEvent?.locationId
          : null;
    } else {
      const selectedLocation = calendarFilter.locationFilter?.selectedLocation;
      if (isDefaultLocationSelected(selectedLocation)) {
        const location = (
          calendarFilter.locationFilter.accountLocations || []
        ).find((location) => {
          return location.uuid !== selectedLocation?.[0]?.id;
        });
        locationId = location?.id || '';
      } else {
        locationId = selectedLocation?.[0].id;
      }
    }
    return locationId;
  };

  // const [getLastBookedAppointmentData, latestBookedAppointmentQuery] = useLazyQuery(
  //   AppointmentQueries.GET_APPOINTMENT_BY_ID,
  //   {
  //     fetchPolicy: 'no-cache',
  //     context: {service: CARESTUDIO_APOLLO_CONTEXT},
  //     onCompleted: (data: any) => {
  //       if (data &&
  //         data.appointments &&
  //         data.appointments.length &&
  //         data.appointments[0].id) {
  //         const appointment = data.appointments[0];
  //         const updatedEvents = {
  //           id: appointment.id,
  //           title: getAppointmentName(appointment),
  //           start: getDateObject(appointment.startDateTime),
  //           end: getDateObject(appointment.endDateTime),
  //           statusCode: getMlovCodeFromId(
  //             appointmentStatusList,
  //             appointment.statusId
  //           ),
  //           detail: appointment,
  //           primaryContactName: getPrimaryContactName(appointment),
  //           locationCode: getLocationCode(appointment, scheduleLocationTypeList),
  //           resourceId: getAppointmentUserId(appointment),
  //         };
  //         setCalendarWidgetState((prev) => ({
  //           ...prev,
  //           selectedEvent: updatedEvents,
  //         }));
  //         setTimeout(() => {
  //           if(!latestBookedAppointmentQuery.loading){
  //             setCalendarWidgetState((prev) => ({
  //               ...prev,
  //               modalOpen: !calendarWidgetState.modalOpen,
  //               selectedEvent: updatedEvents,
  //             }));
  //             setAppointmentDetailsPopupOpen(true)
  //           }
  //         }, 1000)
  //       } else {
  //         setCalendarWidgetState((prev) => ({
  //           ...prev,
  //           events: [],
  //         }));
  //       }
  //     },
  //     onError: (error: any) => {
  //
  //     },
  //   }
  // );
  // const getAppointmentData = (appointmentId: any) => {
  //   getLastBookedAppointmentData({
  //     variables: { appointmentId: appointmentId },
  //   })
  // }
  const handleOnPressForDrawerCalendar = (action: CalendarTopBarActions) => {
    if (action === CalendarTopBarActions.bookAppointment) {
      setCalendarWidgetState((prev) => ({
        ...prev,
        appointmentType: AppointmentType.bookAppointment,
      }));
    } else if (action === CalendarTopBarActions.blockTime) {
      setCalendarWidgetState((prev) => ({
        ...prev,
        appointmentType: AppointmentType.blockTime,
      }));
    } else if (action === CalendarTopBarActions.groupAppointment) {
      setCalendarWidgetState((prev) => ({
        ...prev,
        appointmentType: AppointmentType.groupAppointment,
      }));
    } else if (action === CalendarTopBarActions.service) {
      setCalendarWidgetState((prev) => ({
        ...prev,
        appointmentType: AppointmentType.service,
      }));
    }
    handleSlotSelect({
      start: getCurrentNextHour(),
      end: getEndOfDay(),
      slots: [],
      action: 'click',
    });
  };

  const getAccountUsers = () => {
    if (props?.isDayOptimizerCalender) {
      return calendarWidgetState.accountUsers;
    }
    if (!calendarWidgetState.isPracticeAvailabilityView) {
      return calendarFilter.userFilter.accountUsers.filter((user: any) => {
        if (user?.key) {
          return user?.key !== 'ALL_USER';
        }
        return true;
      });
    }
    return calendarFilter.userFilter.accountUsers?.filter(
      (user) => !!user?.uuid
    );
  };

  const isAppointmentExecutionStatusIsCompleted = async (appointmentId: string): Promise<boolean> => {
    const executionLog = await getResourceExecution({
      variables: {
        resourceId: appointmentId,
      },
    });
    const appointmentLog = executionLog?.data?.message_execution_log?.[0];
    return ![
      executionLogStatus.IN_QUEUE,
      executionLogStatus.PENDING,
    ].includes(appointmentLog?.statusId as string);
  }

  //REQUIRED FOR FUTURE USE
  // ${
  //   calendarWidgetState.currentView === 'week' &&
  //   `calendar-week-view ${
  //     resourceMap && resourceMap?.length > 2
  //       ? 'adjusted-week-view'
  //       : ''
  //   }`
  // } ${
  //   calendarWidgetState.currentView === 'month' &&
  //   'calendar-month-view'
  // } ${
  //   calendarWidgetState.currentView === 'day' &&
  //   `calendar-day-view ${
  //     resourceMap && resourceMap?.length > 7
  //       ? 'fixed-width-day-view'
  //       : ''
  //   }`
  // }
  return <>
      {calendarWidgetState.modalOpen &&
        (calendarWidgetState.slotSelected ||
          calendarWidgetState.selectedEvent) &&
        calendarWidgetState.appointmentType && (
          <BookingWorkflow
            bookAppointmentMeta={bookAppointmentMeta}
            isOpen={calendarWidgetState.modalOpen}
            appointmentType={calendarWidgetState.appointmentType}
            event={calendarWidgetState.selectedEvent}
            onClose={(data:IAppointmentDetail) => {
              if(data?.isRecurrentAppointment){
                showAppointmentBookingToast(
                  'Series creation in progress'
                );
              } else {
              showAppointmentBookingToast(
                calendarWidgetState.appointmentType ===
                  AppointmentType.blockTime
                  ? 'Your time is blocked successfully!'
                  : 'Appointment booked successfully!'
              );
              }

              resetDataAfterModalDismiss();
              refetchAppointments(
                calendarWidgetState.currentView,
                calendarWidgetState.currentDate
              );
              // if (calendarWidgetState.appointmentType === AppointmentType.blockTime) {
              //   resetDataAfterModalDismiss();
              //   refetchAppointments(
              //     calendarWidgetState.currentView,
              //     calendarWidgetState.currentDate
              //   );
              // } else {
              //   getAppointmentData(appointmentId)
              // }
            }}
            onCancel={() => {
              resetDataAfterModalDismiss();
            }}
            onStatusChange={() => {
              refetchAppointments(
                calendarWidgetState.currentView,
                calendarWidgetState.currentDate
              );
            }}
            slotSelected={calendarWidgetState.slotSelected}
            disAllowToRedirectAvailableSlot={calendarWidgetState.disAllowToRedirectAvailableSlot}
          />
        )}

      {/* <AppointmentDetail
        event={calendarWidgetState.selectedEvent}
        isVisible={
          calendarWidgetState.modalOpen &&
          calendarWidgetState.appointmentType ===
            AppointmentType.appointmentDetail &&
          calendarWidgetState.selectedEvent
        }
        onStatusChange={() => {
          refetchAppointments(
            calendarWidgetState.currentView,
            calendarWidgetState.currentDate
          );
        }}
        onClose={(refreshCalendar?: boolean) => {
          resetDataAfterModalDismiss();
          if (refreshCalendar) {
            refetchAppointments(
              calendarWidgetState.currentView,
              calendarWidgetState.currentDate
            );
          }
        }}
      /> */}

      {calendarMemberModal.open && (
        <CalenderMemberViewDrawer
          selectedUsers={calendarFilter.userFilter.selectedUser}
          selectedLocations={calendarFilter.locationFilter.selectedLocation}
          selectedAppointmentTypes={appointmentTypeAndStatusFilter.selectedAppointmentTypes}
          selectedAppointmentStatus={appointmentTypeAndStatusFilter.selectedAppointmentStatus}
          selectedDate={calendarMemberModal.selectedDate || getMomentObj(new Date()).format('YYYY-MM-DD')}
          accountLocations={calendarFilter.locationFilter.accountLocations}
          onClose={() => {
            setCalendarMemberModal({open: false, selectedDate: undefined});
          }}
        />
      )}
      {calendarWidgetState.modalOpen &&
        calendarWidgetState.appointmentType ===
          AppointmentType.appointmentDetail &&
        calendarWidgetState.selectedEvent?.detail?.id && (
          <LoadAppointmentDetailsDrawer
            key={calendarWidgetState.selectedEvent?.detail?.id}
            appointmentDetail={calendarWidgetState.selectedEvent?.detail}
            appointmentId={calendarWidgetState.selectedEvent.detail.id}
            onStatusChange={() => {
              refetchAppointments(
                calendarWidgetState.currentView,
                calendarWidgetState.currentDate
              );
            }}
            onClose={(refreshCalendar) => {
              resetDataAfterModalDismiss();
              if (refreshCalendar) {
                refetchAppointments(
                  calendarWidgetState.currentView,
                  calendarWidgetState.currentDate
                );
              }
            }}
          />
        )}

      <View>
        <CalendarTopBar
          isDayOptimizerCalender={props?.isDayOptimizerCalender || false}
          refetchAppointments={() => {
            refetchAppointments(
              calendarWidgetState.currentView,
              calendarWidgetState.currentDate
            );
          }}
          appointmentTypes={appointmentTypeAndStatusFilter.appointmentTypes || []}
          appointmentStatusList={filteringAppointmentStatusList}
          selectedUserUUIDList={calendarFilter.userFilter?.selectedUser || []}
          enabledUserDropdown={calendarFilter.userFilter.enabledDropDown}
          accountUsers={getAccountUsers()}
          userLocations={calendarFilter.locationFilter.accountLocations || []}
          enabledLocationDropdown={
            calendarFilter.locationFilter.enabledDropDown
          }
          selectedLocationUUIDList={
            calendarFilter.locationFilter?.selectedLocation || []
          }
          currentDate={calendarWidgetState.currentDate}
          currentView={calendarWidgetState.currentView}
          onViewChange={(view: string) => {
            onViewChangeHandler(view);
          }}
          onDateChange={(date: Date) => {
            setCalendarWidgetState((prev) => ({...prev, currentDate: date}));
            if (calendarWidgetState.isPracticeAvailabilityView) {
              setCalendarWidgetState((prev) => ({
                ...prev,
                availabilityEvent: [],
              }));
              refetchUserAvailability(date);
            } else {
              refetchAppointments(calendarWidgetState.currentView, date);
            }
          }}
          onAction={(action: CalendarTopBarActions) => {
            if (action === CalendarTopBarActions.bookAppointment) {
              if (calendarWidgetState.currentView === CalendarView.day) {
                setAppointmentMeta({resourceId: loggedInUserId})
              } else {
                setAppointmentMeta({resourceId: resourceMap?.[0]?.resourceId});
              }
            } else if (action === CalendarTopBarActions.blockTime) {
              setCalendarWidgetState((prev) => ({
                ...prev,
                appointmentType: AppointmentType.blockTime,
              }));
            } else if (action === CalendarTopBarActions.groupAppointment) {
              setCalendarWidgetState((prev) => ({
                ...prev,
                appointmentType: AppointmentType.groupAppointment,
              }));
            } else if (action === CalendarTopBarActions.service) {
              setCalendarWidgetState((prev) => ({
                ...prev,
                appointmentType: AppointmentType.service,
              }));
            } else if (action === CalendarTopBarActions.groupSessionAppointment) {
              setCalendarWidgetState((prev) => ({
                ...prev,
                appointmentType: AppointmentType.groupSessionAppointment,
              }));
            }
            handleSlotSelect({
              start: getCurrentNextHour(),
              end: getEndOfDay(),
              slots: [],
              action: 'click',
            });
          }}
          onParticipantSelect={(participant) => {
            onParticipantChangeHandler(participant);
          }}
          onAppointmentStatusSelect={(appointmentStatus: string[]) => {
            setAppointmentTypeAndStatusFilter(
              (prev: ICalendarAppointmentTypeAndStatusFilter) => ({
                ...prev,
                selectedAppointmentStatus: appointmentStatus,
              })
            );
            updateUserFilterPreferenceWithArgument({
              selectedAppointmentStatus: appointmentStatus,
            });
          }}
          selectedAppointmentStatus={
            appointmentTypeAndStatusFilter.selectedAppointmentStatus
          }
          onAppointmentTypeSelect={(appointmentTypes: string[]) => {
            setAppointmentTypeAndStatusFilter(
              (prev: ICalendarAppointmentTypeAndStatusFilter) => ({
                ...prev,
                selectedAppointmentTypes: appointmentTypes,
              })
            );
            updateUserFilterPreferenceWithArgument({
              selectedAppointmentTypes: appointmentTypes,
            });
          }}
          onLocationSelect={(location) => {
            onLocationChangeHandler(location);
          }}
          isPracticeAvailabilityView={
            calendarWidgetState.isPracticeAvailabilityView
          }
          selectedAppointmentTypes={
            appointmentTypeAndStatusFilter.selectedAppointmentTypes
          }
          onPracticeAvailabilityHandler={() => {
            const isPracticeAvailabilityTurnedOn = !calendarWidgetState.isPracticeAvailabilityView;
            onViewChangeHandler(CalendarView.week, isPracticeAvailabilityTurnedOn);
            setCalendarWidgetState((prev) => {
              return {
                ...prev,
                isPracticeAvailabilityView: isPracticeAvailabilityTurnedOn,
              };
            });
            if(!isPracticeAvailabilityTurnedOn) {
              handlePracticeAvailabilityTurnedOff();
            }
          }}
          onHideCalenderView={props?.onHideCalendarView}
        />
        <PageBodyContainer>
          {!calendarWidgetState.isPracticeAvailabilityView && (
            <Box style={{paddingBottom: 0}}>
              {(bookedAppointmentQuery.loading ||
                isGetAvailabilitiesAPILoading ||
                isAddUpdatePreferenceQueryLoading ||
                getAppointmentAggregateIsLoading.loading ||
                getTimezoneOfAccountAndLocationsQuery.loading) && (
                <View
                  zIndex={10}
                  position="absolute"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  top="50%"
                  left="50%"
                >
                  <Spinner size="lg" />
                </View>
              )}
              <View
                style={{
                  // borderRadius: 16,
                  borderWidth: 1,
                  borderColor: Colors.Custom.BorderColor,
                  paddingHorizontal: 4,
                  backgroundColor: '#fff',
                  ...(props?.isDayOptimizerCalender ? {
                      paddingHorizontal: 0,
                      borderWidth: 0,
                    }
                  : {})
                }}
                opacity={bookedAppointmentQuery.loading ? 0.5 : 1.0}
              >
                <Calendar
                  step={TIMESLOT_STEP_IN_MINUTES}
                  resourceIdAccessor="resourceId"
                  resourceTitleAccessor="resourceTitle"
                  resources={resourceMap}
                  selectable
                  showAllEvents
                  showMultiDayTimes
                  // min={props?.isDayOptimizerCalender ? new Date(new Date().setHours(8, 0, 0)) : undefined}
                  // max={props?.isDayOptimizerCalender ? new Date(new Date().setHours(17, 0, 0)) : undefined}
                  localizer={localizer}
                  events={
                    // calendarWidgetState.currentView === 'month'
                    //   ? calendarWidgetState.monthViewEvents
                    //   :
                    calendarWidgetState.events
                  }
                  defaultView={props?.isDayOptimizerCalender ? 'day' : 'week'}
                  defaultDate={new Date()}
                  date={calendarWidgetState.currentDate}
                  view={calendarWidgetState.currentView}
                  dayLayoutAlgorithm={'no-overlap'}
                  views={['month', 'week', 'day']}
                  toolbar={false}
                  formats={{timeGutterFormat: 'h a'}}
                  className={`dashboard-calendar dashboard-calendar-${calendarWidgetState.currentView}-view`}
                  tooltipAccessor={null as any}
                  eventPropGetter={(event) => {
                    const isBlockedInterval = event?.detail?.isBlockedInterval;
                    const isPendingappointment =
                      event?.statusCode === APPOINTMENT_STATUS_CODES.PENDING;
                    return {
                      style:
                        calendarWidgetState.currentView !== 'month'
                          ? getCalenderEventCardStyles(event)
                          : event?.dayIndex &&
                            event?.dayIndex < 4 &&
                            isPendingappointment
                          ? {
                              backgroundImage: `repeating-linear-gradient(135deg, #D0D5DD 0, #D0D5DD 5px, ${
                                event?.detail?.appointmentType
                                  ?.appointmentCardProperties
                                  ?.bgColorSecondary ||
                                defaultAppointmentTypeColor?.bgColorSecondary
                              } 0, ${
                                event?.detail?.appointmentType
                                  ?.appointmentCardProperties
                                  ?.bgColorSecondary ||
                                defaultAppointmentTypeColor?.bgColorSecondary
                              } 14px)`,
                            }
                          : {},
                      className:
                        calendarWidgetState.currentView === 'month'
                          ? `dashboard-month-event${
                              moment(event?.start).isBefore(moment())
                                ? ' is-past-slot'
                                : ''
                            }`
                          : `dashboard-event-styles${
                              isBlockedInterval
                                ? '-blocked-interval'
                                : isPendingappointment
                                ? '-pending-appointment'
                                : ''
                            }`,
                    };
                  }}
                  components={{
                    week: {
                      header: WeekHeader,
                    },
                    month: {
                      header: MonthHeader,
                      dateHeader: DayHeader,
                    },
                    resourceHeader: ResourceHeader,
                    timeGutterHeader: GutterHeader,
                    timeGutterWrapper: (props: any) => {
                      return (
                        <div className="rbc-time-gutter rbc-time-column">
                          {props?.slotMetrics?.groups?.map?.((slot: any) => {
                            return (
                              <div className="rbc-timeslot-group">
                                <div className="rbc-time-slot">
                                  <span className="rbc-label">
                                    {moment(slot[0]).format('hh A')}
                                  </span>
                                </div>
                                <div className="rbc-time-slot"></div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    },
                    timeSlotWrapper: (props: any) => {
                      let slotClass = '';
                      let isAvailableSlot = true;
                      let locationName = '';
                      let showLocationName = false;
                      const isPreviousTime = moment(props?.value).isBefore(
                        moment()
                      );
                      const timeText = `Book at ${moment(props?.value).format(
                        'hh:mm a'
                      )}`;
                      if (isPreviousTime) {
                        slotClass = `rbc-time-slot timeslot-center ${
                          isCurrentTimeSlotAvailableToBook(props?.value)
                            ? 'isAvailableSlot'
                            : 'isPastSlot'
                        }`;
                      } else {
                        locationName =
                          calendarFilter?.locationFilter?.selectedLocation?.[0]
                            ?.name;

                        showLocationName = locationName !== 'All locations';

                        isAvailableSlot = isSlotAvailable(
                          props?.value,
                          props?.resource
                        );
                      }
                      if (!isPreviousTime && isAvailableSlot) {
                        slotClass =
                          'rbc-time-slot isAvailableSlot timeslot-center';
                      } else if (!isPreviousTime && !isAvailableSlot) {
                        slotClass =
                          'rbc-time-slot isBookedSlot timeslot-center';
                      }
                      return (
                        <>
                          {!isAvailableSlot && <></>}
                          {slotClass && slotClass?.length && (
                            <div className={slotClass}>
                              {isAvailableSlot && (
                                <div className="timeSlot">
                                  <span
                                    style={{
                                      width: '100%',

                                      fontSize: 14,

                                      fontWeight: '600',
                                    }}
                                  >
                                    {timeText}
                                  </span>
                                  {showLocationName ? (
                                    <span
                                      style={{
                                        width: '100%',
                                        fontSize: 12,
                                        fontWeight: '300',
                                      }}
                                    >
                                      {locationName || '-'}
                                    </span>
                                  ) : null}
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      );
                    },
                  }}
                  scrollToTime={getCalendarStartDateTime()}
                  style={calendarWidgetStyle}
                  onSelectSlot={(data: any) => {
                    if (
                      calendarWidgetState.currentView !== CalendarView.month
                    ) {
                      onCreateNewAppointment(data);
                    }
                  }}
                  onNavigate={(date: Date) => {
                    setCalendarWidgetState((prev) => ({
                      ...prev,
                      currentDate: date,
                    }));
                    if (
                      calendarWidgetState.currentView === CalendarView.month ||
                      calendarWidgetState.currentView === CalendarView.week
                    ) {
                      return;
                    }
                    if (calendarWidgetState.isPracticeAvailabilityView) {
                      refetchUserAvailability(date);
                    } else {
                      refetchAppointments(
                        calendarWidgetState.currentView,
                        date
                      );
                    }
                  }}
                  onView={(view: CalendarViewType) => {
                    //
                  }}
                  onSelectEvent={async (data: any) => {
                    // if (calendarWidgetState.currentView === 'month') {
                    // setCalendarWidgetState((prev) => ({
                    //   ...prev,
                    //   selectedMonthEvent: data,
                    //   drawerCurrentView: 'day',
                    //   modalOpen: false,
                    //   appointmentType: AppointmentType.appointmentDetail,
                    //   showMonthViewModal: true,
                    // }));
                    // } else {
                    const appointment:IAppointmentDetail = data?.detail;
                    const isRecurrentAppointment =
                      appointment?.isRecurrentAppointment &&
                      !!appointment?.seriesId;

                    if (isRecurrentAppointment) {
                      const isProcessed =
                        await isAppointmentExecutionStatusIsCompleted(
                          appointment?.id
                        );
                      if (!isProcessed) {
                        setShowExecutionError(true);
                        return;
                      }
                    }
                    setCalendarWidgetState((prev) => ({
                      ...prev,
                      selectedEvent: data,
                      modalOpen: true,
                      appointmentType: AppointmentType.appointmentDetail,
                      monthViewModal: false,
                    }));
                    // }
                  }}
                  onSelecting={() => {
                    return true;
                  }}
                  dayPropGetter={(day) => {
                    const currentDateTime = moment();
                    const currentWeekStartDateTime = moment().startOf('week');
                    const currentWeekEndDateTime = moment().endOf('week');
                    const classObj = {
                      className: `${
                        moment(day).isBefore(currentDateTime)
                          ? 'isPastDate '
                          : ''
                      }`,
                    };

                    if (
                      !moment(day).isBetween(
                        currentWeekStartDateTime,
                        currentWeekEndDateTime,
                        undefined,
                        '[]'
                      )
                    ) {
                      classObj.className = classObj.className + ' next-week';
                      return classObj;
                    }
                    return classObj;
                  }}
                  endAccessor={({end}) => {
                    // const endTimeHour = new Date(end.getTime())?.getHours();
                    // const endTimeMins = new Date(end.getTime())?.getMinutes();
                    // if (endTimeHour === 23 && endTimeMins > 30) {
                    return end ? new Date(end.getTime() - 1) : end;
                    // } else {
                    //   return new Date(end.getTime());
                    // }
                  }}
                />
              </View>
            </Box>
          )}
          {calendarWidgetState.isPracticeAvailabilityView && (
            <Box style={{paddingVertical: 16, paddingBottom: 0}}>
              {(userPracticeAvailabilityQuery.loading ||
                isGetAvailabilitiesAPILoading ||
                getTimezoneOfAccountAndLocationsQuery.loading) && (
                <View
                  zIndex={10}
                  position="absolute"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  top="50%"
                  left="50%"
                >
                  <Spinner size="lg" />
                </View>
              )}
              <View
                style={{
                  borderWidth: 1,
                  borderColor: Colors.Custom.BorderColor,
                  paddingTop: 8,
                  paddingHorizontal: 4,
                  backgroundColor: '#fff',
                  ...(props.isDayOptimizerCalender ? {overflow: 'scroll'} : {})
                }}
                opacity={
                  userPracticeAvailabilityQuery.loading ||
                  isGetAvailabilitiesAPILoading ||
                  getTimezoneOfAccountAndLocationsQuery.loading
                    ? 0.2
                    : 1.0
                }
              >
                <Calendar
                  step={TIMESLOT_STEP_IN_MINUTES}
                  resourceIdAccessor="resourceId"
                  resourceTitleAccessor="resourceTitle"
                  selectable
                  showAllEvents
                  showMultiDayTimes
                  // min={props?.isDayOptimizerCalender ? new Date(new Date().setHours(8, 0, 0)) : undefined}
                  // max={props?.isDayOptimizerCalender ? new Date(new Date().setHours(17, 0, 0)) : undefined}
                  localizer={localizer}
                  dayLayoutAlgorithm={'no-overlap'}
                  events={calendarWidgetState.availabilityEvent}
                  defaultView="week"
                  view={calendarWidgetState.currentView}
                  views={['week', 'day']}
                  defaultDate={new Date()}
                  date={calendarWidgetState.currentDate}
                  toolbar={false}
                  formats={{timeGutterFormat: 'h a'}}
                  className={`calendar-practice-availability-view`}
                  tooltipAccessor={(event) => {
                    const tooltipTitle = `${event?.primaryContactName} ( ${event.locationName})`;
                    return tooltipTitle;
                  }}
                  eventPropGetter={(event) => {
                    return {
                      className: getUserAvailabilityEventClass(
                        event.userId,
                        event?.dayIndex
                      ),
                    };
                  }}
                  components={{
                    week: {
                      header: WeekHeader,
                    },
                    month: {
                      header: MonthHeader,
                    },
                    resourceHeader: ResourceHeader,
                    timeGutterHeader: GutterHeader,
                    timeGutterWrapper: (props: any) => {
                      return (
                        <div className="rbc-time-gutter rbc-time-column">
                          {props?.slotMetrics?.groups?.map?.((slot: any) => {
                            return (
                              <div className="rbc-timeslot-group">
                                <div className="rbc-time-slot">
                                  <span className="rbc-label">
                                    {moment(slot[0]).format('hh A')}
                                  </span>
                                </div>
                                <div className="rbc-time-slot"></div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    },
                    timeSlotWrapper: (props: any) => {
                      if (canHideAddNewForAvailability) {
                        return null;
                      }
                      const slotClass = `rbc-time-slot timeslot-center ${
                        isCurrentTimeSlotAvailableToBook(props?.value)
                          ? 'isAvailableSlot'
                          : 'isPastSlot'
                      }`;
                      const timeText = `Configure user schedule for ${moment(
                        props?.value
                      ).format(DISPLAY_DATE_FORMAT)}`;
                      const locationName =
                        calendarFilter?.locationFilter?.selectedLocation?.[0]
                          ?.name;
                      const showLocationName = locationName !== 'All locations';
                      return (
                        <div className={slotClass}>
                          <div className="timeSlot">
                            <span
                              style={{
                                width: '100%',
                                fontSize: 14,
                                fontWeight: '600',
                              }}
                            >
                              {timeText}
                            </span>
                            {showLocationName ? (
                              <span
                                style={{
                                  width: '100%',
                                  fontSize: 12,
                                  fontWeight: '300',
                                }}
                              >
                                {locationName || '-'}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      );
                    },
                  }}
                  //scrollToTime={getCalendarStartDateTime()}
                  style={calendarWidgetStyle}
                  onSelectSlot={(data: any) => {
                    if (canHideAddNewForAvailability) {
                      return;
                    }
                    if (data?.isShowMoreEvent) {
                      showDayViewOfAvailability(data?.startDateTime);
                      return;
                    }
                    const userId =
                      calendarFilter?.userFilter?.selectedUser?.[0]?.uuid;
                    onAvailabilitySlotHandler(userId, data);
                  }}
                  onNavigate={(date: Date) => {
                    //
                  }}
                  onView={(view: CalendarViewType) => {
                    setCalendarWidgetState((prev) => ({
                      ...prev,
                      availabilityEvent: [],
                      currentView: view as CalendarView,
                    }));
                    refetchUserAvailability(calendarWidgetState.currentDate);
                  }}
                  onSelectEvent={(data: any) => {
                    if (canHideAddNewForAvailability) {
                      return;
                    }
                    if (data?.isShowMoreEvent) {
                      showDayViewOfAvailability(data?.startDateTime);
                      return;
                    }
                    onAvailabilitySlotHandler(data?.userId, data);
                  }}
                  onSelecting={() => {
                    return true;
                  }}
                  dayPropGetter={(day) => {
                    return {
                      className: moment(day).isBefore(moment())
                        ? 'isPastDate'
                        : '',
                    };
                  }}
                />
              </View>
            </Box>
          )}
        </PageBodyContainer>
      </View>
      <FHDrawerAlert
        isOpen={joinMeetingConfirmation.visible}
        header={joinMeetingConfirmation.header}
        message={joinMeetingConfirmation.message}
        closeModal={() => {
          resetJoinMeetingData();
        }}
        buttonActions={[
          {
            textLocalId: 'Close',
            buttonProps: {
              colorScheme: 'muted',
              variant: 'outline',
            },
            onPress: () => {
              resetJoinMeetingData();
            },
          },
          {
            textLocalId: 'Join',
            textColor: 'white',
            buttonProps: {
              colorScheme: 'primary',
            },
            onPress: () => {
              if (joinMeetingConfirmation.selectedAppointment) {
                joinMeeting(joinMeetingConfirmation.selectedAppointment);
              }
            },
          },
        ]}
      />
      {/* FUTURE USE*/}
      {/* <Drawer
        open={calendarWidgetState.showMonthViewModal}
        maskClosable
        onClose={() =>
          setCalendarWidgetState((prev: any) => ({
            ...prev,
            showMonthViewModal: false,
          }))
        }
        width={'70%'}
        title={
          <HStack
            alignItems={'center'}
            justifyContent="space-between"
            p={4}
            pb={2.5}
          >
            <Text fontSize={'30px'} fontWeight={'700'} color={'#4F2D90'}>
              Appointment
            </Text>
            <Button
              p={1.5}
              startIcon={
                <Icon
                  as={FeatherIcon}
                  size="sm"
                  name="plus"
                  color={'hsla(9, 81%, 30%, 1)'}
                />
              }
              borderRadius={'full'}
              style={{
                backgroundColor: Colors.secondary['100'],
                borderColor: Colors.secondary['200'],
                borderWidth: 1,
              }}
              onPress={() => {
                handleOnPressForDrawerCalendar(
                  CalendarTopBarActions.bookAppointment
                );
              }}
            >
              <Text
                color={Colors.secondary['800']}
                size={'xsBold'}
                style={{fontSize: 14}}
                textAlign="center"
              >
                Schedule
              </Text>
            </Button>
          </HStack>
        }
      >
        {calendarWidgetState.selectedMonthEvent &&
          (calendarWidgetState.selectedMonthEvent?.virtualAppointments?.length >
            0 ||
            calendarWidgetState.selectedMonthEvent?.clinicAppointments?.length >
              0) && (
            <>
              <Box
                backgroundColor={'hsl(273, 82%, 98%)'}
                borderBottomColor={'hsla(261, 52%, 37%, 1)'}
                borderBottomWidth={5}
                borderBottomStyle="solid"
                borderRadius={10}
                width={'100%'}
                p={3}
                mb={4}
              >
                <Text color="#825AC7">
                  {moment(
                    calendarWidgetState.selectedMonthEvent
                      ?.clinicAppointments?.[0]?.start ||
                      calendarWidgetState.selectedMonthEvent
                        ?.virtualAppointments?.[0]?.start
                  ).format('ddd, MMMM DD, YYYY')}
                </Text>
              </Box>
              <Calendar
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                resources={resourceMap}
                dayLayoutAlgorithm={'overlap'}
                date={
                  calendarWidgetState.selectedMonthEvent
                    ?.clinicAppointments?.[0]?.start ||
                  calendarWidgetState.selectedMonthEvent
                    ?.virtualAppointments?.[0]?.start
                }
                localizer={localizer}
                view={'day'}
                views={['day']}
                toolbar={false}
                formats={{timeGutterFormat: 'h a'}}
                defaultView="day"
                events={[
                  ...(calendarWidgetState.selectedMonthEvent
                    ?.clinicAppointments || []),
                  ...(calendarWidgetState.selectedMonthEvent
                    ?.virtualAppointments || []),
                ]}
                tooltipAccessor={(event) => {
                  const tooltipTitle = event.primaryContactName;
                  return tooltipTitle;
                }}
                onSelectEvent={(data: any) => {
                  setCalendarWidgetState((prev) => ({
                    ...prev,
                    selectedEvent: data,
                    modalOpen: true,
                    appointmentType: AppointmentType.appointmentDetail,
                    monthViewModal: false,
                  }));
                }}
                onSelecting={() => {
                  return true;
                }}
                eventPropGetter={(event) => {
                  return {
                    className: `dashboard-event-${
                      event.locationCode
                    } dashboard-event-status-${event.statusCode?.toUpperCase()}
                        `,
                  };
                }}
                className={`dashboard-calendar calendar-drawer-day-view ${
                  resourceMap && resourceMap?.length > 7
                    ? 'fixed-width-day-view'
                    : ''
                }`}
              />
            </>
          )}
      </Drawer> */}

      {
        showExecutionError && (
          <Modal
          key={`${showExecutionError}`}
          open={showExecutionError}
          footer={null}
          closable
          closeIcon={<></>}
        >
          <VStack>
            <Text fontWeight={'bold'} fontSize={18}>
              {intl.formatMessage({id: 'appointmentUpdateInProgress'})}
            </Text>
            <HStack space={2} mt={4}>
              <Spacer />
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => setShowExecutionError(false),
                }}
                customProps={{
                  btnText: (
                    <DisplayText
                      textLocalId={'ok'}
                      size={'smBold'}
                      extraStyles={{
                        color: Colors.Custom.mainSecondaryBrown,
                      }}
                    />
                  ),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </HStack>
          </VStack>
        </Modal>
        )
      }

      {componentState.isAddOrUpdateDrawerOpen &&
        componentState.timezones &&
        componentState.accountLocations &&
        componentState.defaultTimezone &&
        componentState.currentAvailabilitiesByDaysOfWeek &&
        defaultAvailability && (
          <AddOrUpdateAvailabilityDrawer
            accountLocations={getFormattedUserLocations()}
            availabilitiesByDaysOfWeek={
              componentState.currentAvailabilitiesByDaysOfWeek
            }
            overriddenAvailabilitiesByDateRangeKey={
              componentState.currentOverriddenAvailabilitiesByDateRangeKey
            }
            defaultTimezone={componentState.defaultTimezone}
            isUserSchedule={true}
            selectedUserData={componentState.selectedUserData}
            timezones={componentState.timezones}
            isOpen={componentState.isAddOrUpdateDrawerOpen}
            defaultAvailability={{
              ...defaultAvailability,
              userId: componentState.selectedUserData?.uuid,
            }}
            onClose={() => {
              setComponentState((prev) => ({
                ...prev,
                isAddOrUpdateDrawerOpen: false,
                currentAvailabilitiesByDaysOfWeek: undefined,
                selectedUserData: undefined,
              }));
            }}
            onSave={(response: {
              isSuccess: boolean;
              message?: string;
              updateRecords?: boolean;
            }) => {
              setComponentState((prev) => ({
                ...prev,
                isAddOrUpdateDrawerOpen: false,
                currentAvailabilitiesByDaysOfWeek: undefined,
                selectedUserData: undefined,
              }));
              showToast(
                toast,
                response.message || '',
                response.isSuccess ? ToastType.success : ToastType.error
              );
              userPracticeAvailabilityQuery.refetch();
            }}
            availabilityCalendar={{
              isOverrideAvailability: true,
              locationId: getLocationId(),
              selectedDate: getSelectedEventDate(),
            }}
          />
        )}
    </>
};

export default CalendarWidget;

const availabilityStyles = StyleSheet.create({
  textStyle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});
