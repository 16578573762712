import {useCallback, useContext, useEffect, useState} from 'react';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {ElementConfig} from './interfaces';
import {SIDECAR_EVENT_CODES} from '../../../sidecar/common/constants';
import {EventBus} from '../../../utils/EventBus';
import {getCurrentEHR} from '../../PersonOmniView/MiddleContainer/PatientNotes/PatientNotesHelper';
import {EHRName} from '../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import {broadcastEventToSidecarActionsScreen} from '../../../sidecar/common/SideCardCommonUtils';

export const useEhrContextFinder = (args: {contextUiConfig: ElementConfig, locationGroupId?: string}) => {
  const {contextUiConfig, locationGroupId} = args;
  const commonData = useContext(CommonDataContext);
  const isPatientSidecarContext = commonData.sidecarContext?.isPatient;
  const currentEHR = getCurrentEHR('', locationGroupId || '');
  const isAthena = currentEHR === EHRName.ATHENA;
  const isElation = currentEHR === EHRName.ELATION;
  const [contextFinderState, setContextFinderState] = useState({
    elementFound: false,
  });
  const foundElementListener = useCallback((eventData) => {
    if (eventData?.elementData === 'ELEMENT_FOUND') {
      setContextFinderState((prev) => {
        return {
          ...prev,
          elementFound: true,
        };
      });
    } else {
      setContextFinderState((prev) => {
        return {
          ...prev,
          elementFound: false,
        };
      });
    }
  }, []);
  useEffect(() => {
    if (isPatientSidecarContext) {
      //check if element found
      let elementStep: ElementConfig = {} as ElementConfig;
      if (isAthena) {
        elementStep = contextUiConfig;
      } else if (isElation) {
        elementStep = contextUiConfig;
      }

      broadcastEventToSidecarActionsScreen(
        '',
        SIDECAR_EVENT_CODES.CHECK_ELEMENTS,
        elementStep
      );
    }

    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      SIDECAR_EVENT_CODES.CHECK_ELEMENTS_CALLBACK,
      foundElementListener
    );

    return () => {
      eventBus.removeEventListenerByEventName(
        SIDECAR_EVENT_CODES.CHECK_ELEMENTS_CALLBACK,
        foundElementListener
      );
    };
  }, []);

  return {contextFinderState}
};
