import { Drawer, notification } from 'antd';
import { Center, Divider, HStack, Text, View, VStack, Checkbox } from 'native-base';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BUTTON_TYPE, DATE_FORMATS, PHONE_NUMBER_MASK } from '../../../constants';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { IEhrCapability } from '../../../Interfaces';
import { Colors } from '../../../styles';
import { getEnabledFormComponents } from '../../../utils/capabilityUtils';
import { getAccountUUID, getEHRCapability, getFoldAccessToken, getUserName, isBrowserIncompatibleForPrint, numericStringMask } from '../../../utils/commonUtils';
import { getCurrentTimeZoneAbbr, getDateStrFromFormat } from '../../../utils/DateUtils';
import { FHForm } from '../../RightSideContainer/Forms/FHFormio';
import { CapabilityResource, componentKeys, HISTORY_COMPONENT_LIST, IFormComponentConfig, INTAKE_COMPONENT_LIST, VITAL_INTAKE_COMPONENT } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { FormViewType } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import { PrintContactContent } from '../PrintContactContent/PrintContactContent';
import useEHRCapabilities from '../../../screens/BusinessStudio/useEHRCapabilities';

interface IProps {
  formattedContactData: any;
  isOpen: boolean;
  isSidecarContext?: boolean;
  onClose: () => void;
}

const sectionDisplayValueByComponentKey = {
  [componentKeys.VITALS]: 'Vitals (Most recent)',
  [componentKeys.CONDITIONS]: 'Active Problems',
  [componentKeys.ALLERGIES]: 'Allergies',
  [componentKeys.MEDICATIONS]: 'Medications',
  [componentKeys.IMMUNIZATIONS]: 'Immunizations',
  [componentKeys.SURGICAL_HISTORY]: 'Surgical History',
  [componentKeys.FAMILY_HISTORY]: 'Family History',
  [componentKeys.SOCIAL_HISTORY]: 'Social History',
};

interface ISection {
  componentKey: string;
  displayValue: string;
  isPrimary: boolean;
  sections?: Array<ISection>;
}

interface IComponentState {
  loading: boolean;
  formLoadingStatus: {[index: string]: boolean},
  sections?: Array<ISection>;
  selectedComponentKeys: {[index: string]: boolean},

  isPrintDrawerOpen?: boolean;
  printViewProps?: {
    enablePatientDemographics?: boolean;
    vitalComponents?: IFormComponentConfig[];
    pamiComponents?: IFormComponentConfig[];
    historyComponents?: IFormComponentConfig[];
    selectedVitalLoincList?: { [index: string]: boolean};
  };
}

export function PrintContactClinicalProfileSectionSelector(props: IProps) {
  const [componentState, setComponentState] = useState<IComponentState>({
    loading: false,
    selectedComponentKeys: {},
    formLoadingStatus: {},
  });

  const isIncompatibleBrowser = isBrowserIncompatibleForPrint();

  const contextData = useContext(CommonDataContext);

  const foldAccessToken = getFoldAccessToken();
  const accountUUID = getAccountUUID();
  const contactUUID = props.formattedContactData?.contactUUID;
  const patientId = props.formattedContactData?.patientId || props.formattedContactData?.patientUuid;
  const accountLocationUuid = props.formattedContactData?.accountLocationUuid || '';
  const ehrCapabilities = useEHRCapabilities({locationId: accountLocationUuid});

  const updateLoadingStatus = useCallback((componentKey: string, isLoading: boolean) => {
    if (componentKey) {
      setComponentState((prev) => {
        const formLoadingStatus = {
          ...prev.formLoadingStatus,
          [componentKey]: isLoading
        };
        const isAnyFormLoading = !!(Object.values(formLoadingStatus || {}).find(isFormLoading => isFormLoading));
        return {
          ...prev,
          loading: isAnyFormLoading,
          formLoadingStatus: {
            ...formLoadingStatus,
          }
        };
      });
    }
  }, []);

  const [intakeOptions] = useState({
    foldAccessToken,
    patientId,
    accountUUID,
    contactId: contactUUID,
    backgroundSaveEnabled: false,
    componentView: FormViewType.compact,
    ehrCapabilities,
    accountLocationUuid,
    updateLoadingStatus,
  });

  function getVitalSection(componentList: IFormComponentConfig[]): ISection | undefined {
    if (!componentList?.length) {
      return undefined;
    }
    // const enabledVitals = getEnabledVitals(ehrCapabilities);
    let allowedVitalList = getEHRCapability(ehrCapabilities, [CapabilityResource.observation])?.abilities?.allowedVitalList || [];
    allowedVitalList = allowedVitalList.filter(item => !item.isHidden);

    const section: ISection = {
      displayValue: sectionDisplayValueByComponentKey[componentKeys.VITALS],
      isPrimary: true,
      componentKey: componentKeys.VITALS,
      sections: allowedVitalList?.map((vital) => {
        return {
          componentKey: vital.loinc,
          displayValue: vital.foldDisplay || vital.display || '',
          isPrimary: false,
        };
      }) || [],
    };

    return section;
  }

  function getPamiComponentSections(componentList: IFormComponentConfig[]): ISection | undefined {
    if (!componentList?.length) {
      return undefined;
    }

    const section: ISection = {
      displayValue: 'PAMI',
      isPrimary: true,
      componentKey: 'PAMI',
      sections: componentList.map((component) => {
        return {
          componentKey: component.key,
          displayValue: component.label,
          isPrimary: false,
        };
      }) || [],
    };

    return section;
  }

  function getHistoryComponentSections(componentList: IFormComponentConfig[]): ISection | undefined {
    if (!componentList?.length) {
      return undefined;
    }

    const section: ISection = {
      displayValue: 'History',
      isPrimary: true,
      componentKey: 'History',
      sections: componentList.map((component) => {
        return {
          componentKey: component.key,
          displayValue: component.label,
          isPrimary: false,
        };
      }) || [],
    };

    return section;
  }

  function onChangeSectionSelection(section: ISection, isChecked: boolean, changeChildSectionSelection: boolean, unCheckParentSection?: () => void) {
    setComponentState((prev) => {
      const selectedComponentKeys = { ...prev.selectedComponentKeys };
      selectedComponentKeys[section.componentKey] = isChecked;
      if (changeChildSectionSelection && section.sections?.length) {
        section.sections.forEach(subSection => (selectedComponentKeys[subSection.componentKey] = isChecked));
      }
      return { ...prev, selectedComponentKeys }
    });

    if (!isChecked) {
      unCheckParentSection?.();
    }
  }

  function renderSection(section: ISection, marginOffset: number, unCheckParentSection?: () => void) {
    return (
      <VStack key={section.componentKey} space={2}>
        <HStack space={3} alignItems={'center'}>
          <Checkbox
            key={section.displayValue}
            isChecked={componentState.selectedComponentKeys[section.componentKey]}
            value={section.displayValue}
            onChange={(isChecked) => {
              // const isChecked = event?.target?.checked || false;
              onChangeSectionSelection(section, isChecked, true, unCheckParentSection);
            }}
          >
            <Text
              size={section.isPrimary ? 'smMedium': 'smRegular'}
              fontWeight={section.isPrimary ? 'bold' : 'normal'}
              color={section.isPrimary? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY300 }
              style={{marginLeft: 8}}
            >
              {section.displayValue}
            </Text>
          </Checkbox>
        </HStack>
        {
          !!section.sections?.length &&
          <View paddingLeft={marginOffset + 6} marginTop={2}>
            {
              renderSections(section.sections, marginOffset + 6, () => {
                onChangeSectionSelection(section, false, false, unCheckParentSection);
              })
            }
          </View>
        }
        {
          section.isPrimary &&
          <Divider marginY={3}/>
        }
      </VStack>
    );
  }

  function renderSections(sections: Array<ISection> | undefined, marginOffset: number, unCheckParentSection?: () => void) {
    if (!sections?.length) {
      return <></>;
    }
    return (
      <VStack space={3}>
        {
          sections?.map((section) => renderSection(section, marginOffset, unCheckParentSection))
        }
      </VStack>
    );
  }

  function filterSelectedComponents(components: IFormComponentConfig[]) {
    return components?.filter((component) => {
      return (componentState.selectedComponentKeys[component.key]);
    });
  }

  const renderPrintedByInfo = () => {
    const userName = getUserName();
    const downloadedTime = getDateStrFromFormat(new Date(), DATE_FORMATS.MESSAGE_DATE_FORMAT);
    const timezoneAbbr = getCurrentTimeZoneAbbr();
    if(userName && downloadedTime) {
      return (
        <div className="page-break">
          <Divider my={2} backgroundColor="black" />
          <HStack alignItems="center" my={2}>
            <Text fontSize={16}>{'Printed By '}</Text>
            <Text fontSize={16} fontWeight={600}>{userName}</Text>
            <Text fontSize={16}>{' on '}</Text>
            <Text fontSize={16} fontWeight={600}>{`${downloadedTime} (${timezoneAbbr})`}.</Text>
          </HStack>
        </div>
      );
    }
  }

  function isAnyComponentSelected() {
    const selectedComponent = Object.values(componentState.selectedComponentKeys || {})?.find(isSelected => isSelected);
    return selectedComponent;
  }

  const onClosePrintDrawerAction = () => {
    setComponentState((prev) => {
      return {
        ...prev,
        isPrintDrawerOpen: false,
      };
    });
    return;
  };


  function onClickPrint() {
    if (!isAnyComponentSelected()) {
      notification.destroy();
      notification.info({
        message: 'No section is selected. Please select at least one section to proceed further',
      });
      return;
    }
    const selectedVitalLoincList: {[index: string]: boolean} = {};
    let allowedVitalList = getEHRCapability(ehrCapabilities, [CapabilityResource.observation])?.abilities?.allowedVitalList || [];
    allowedVitalList = allowedVitalList.filter(item => !item.isHidden);
    if (allowedVitalList?.length) {
      const selectedVitals = allowedVitalList.filter((vital) => {
        return (componentState.selectedComponentKeys[vital.loinc]);
      });
      selectedVitals.forEach((vital) => {
        selectedVitalLoincList[vital.loinc] = true;
      });
    }
    const vitalComponents = Object.keys(selectedVitalLoincList)?.length ? VITAL_INTAKE_COMPONENT : [];
    const pamiComponents = filterSelectedComponents(INTAKE_COMPONENT_LIST);
    const historyComponents = filterSelectedComponents(HISTORY_COMPONENT_LIST);

    if (vitalComponents?.[0]?.key) {
      (vitalComponents[0] as any).enabledVitals = selectedVitalLoincList;
    }

    setComponentState((prev) => {
      return {
        ...prev,
        isPrintDrawerOpen: true,
        printViewProps: {
          enablePatientDemographics: componentState.selectedComponentKeys['PAT_DEMOGRAPHICS'],
          selectedVitalLoincList,
          vitalComponents,
          pamiComponents,
          historyComponents,
        }
      };
    });
  }

  useEffect(() => {
    const enabledVitalComponents = getEnabledFormComponents(VITAL_INTAKE_COMPONENT, ehrCapabilities);
    const enabledIntakeComponents = getEnabledFormComponents(INTAKE_COMPONENT_LIST, ehrCapabilities);
    const enabledHistoryComponents = getEnabledFormComponents(HISTORY_COMPONENT_LIST, ehrCapabilities);

    const vitalSection = getVitalSection(enabledVitalComponents);
    const pamiSection = getPamiComponentSections(enabledIntakeComponents);
    const historySection = getHistoryComponentSections(enabledHistoryComponents);

    const sections: Array<ISection> =
      [
        {
          displayValue: 'Patient Demographics',
          isPrimary: true,
          componentKey: 'PAT_DEMOGRAPHICS',
        },
        vitalSection,
        pamiSection,
        historySection,
      ].filter(section => !!section) as Array<ISection>;

    setComponentState((prev) => {
      return {
        ...prev,
        sections: sections,
      };
    });
  }, []);

  return (
    <Drawer
      open={props.isOpen || false}
      width={props.isSidecarContext ? '100%' : 700}
      onClose={props.onClose}
      closable={false}
      title={
        <>
          <ModalActionTitle
            title={'Print Profile'}
            titleColor={''}
            isHeadNotSticky
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'close',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  props.onClose?.();
                },
              },
              {
                show: !isIncompatibleBrowser,
                id: 1,
                btnText: 'printPreview',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  onClickPrint();
                },
              }
            ]}
          />
        </>
      }
    >
      {
        !isIncompatibleBrowser &&
        <VStack space={2}>
          <View marginBottom={6}>
            <Text color={Colors.Custom.Gray500}>{'Select the section(s) or sub section(s) to add into print or download list'}</Text>
          </View>

          {
            !!componentState?.sections?.length &&
            <VStack>
              { renderSections(componentState.sections, 0) }
            </VStack>
          }
          {
            !componentState?.sections?.length &&
            <View minHeight={200}>
              <Center>No sections available</Center>
            </View>
          }
        </VStack>
      }
      {
        isIncompatibleBrowser &&
        <Center
          borderColor={Colors.Custom.Gray300}
          borderRadius={'10px'}
          borderWidth={1}
          height={'200px'}
          padding={'10px'}
        >
          <Text fontWeight={200} fontSize="lg" textAlign="center">
            {'This browser version is not supported for print. Please update it to the latest version'}
          </Text>
        </Center>
      }

      {
        componentState.isPrintDrawerOpen &&
        <PrintContactContent
          formattedContactData={props.formattedContactData}
          isOpen={componentState.isPrintDrawerOpen}
          showPreviewInDrawer={true}
          isLoading={componentState.loading}
          enablePatientDemographics={componentState.printViewProps?.enablePatientDemographics}

          onClose={onClosePrintDrawerAction}
        >
          {
            !!componentState.printViewProps?.vitalComponents?.length && (
            <div className="note-preview-styles custom-form-styles">
              <FHForm
                optionData={intakeOptions}
                components={componentState.printViewProps.vitalComponents}
                fetchDataForPreview={true}
                isPreviewMode={true}
              />
            </div>
          )}
          {
            !!componentState.printViewProps?.pamiComponents?.length && (
            <div className="note-preview-styles custom-form-styles">
              <FHForm
                optionData={intakeOptions}
                components={componentState.printViewProps.pamiComponents}
                isPreviewMode={true}
              />
            </div>
          )}
          {
            !!componentState.printViewProps?.historyComponents?.length && (
            <div className="note-preview-styles custom-form-styles">
              <FHForm
                optionData={intakeOptions}
                components={componentState.printViewProps.historyComponents}
                isPreviewMode={true}
              />
            </div>
          )}
          {renderPrintedByInfo()}
        </PrintContactContent>
      }
    </Drawer>
  );
}
