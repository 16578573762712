import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { HStack, Pressable, Spinner, View, VStack } from 'native-base';
import { useNavigate } from 'react-router-dom';
import { getAccountUUID, getUserUUID } from '../../utils/commonUtils';
import { CommonDataContext } from '../../context/CommonDataContext';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../constants/Configs';
import { FormsQueries } from '../../services';
import { getWorkFlowFormListByEventEntity, getWorkFlowListByEventEntity } from '../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { redirectToNewTab } from '../SideCar/SidecarUtils';
import { FormStatus } from '../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingEnums';
import { isPathValidForUserRolePermission } from '../RightSideContainer/UserAccess/UserAccessPermission';
import CollapsePanelWithSwitch from './CollapsePanelWithSwitch';
import PatientCareJourneyDetail from '../RightSideContainer/Journeys/JourneysOfCare/PatientCareJourneyDetail/PatientCareJourneyDetail';
import { CONFIG_CODES } from '../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../utils/configUtils';
import UserPracticeLocationQueries from '../../services/Location/UserPracticeLocationQueries';
import { useWorkflowListForTasks } from './useWorkflowListForTasks';

const WorkFlowListForTasks = (props: {
  contactIds?: any;
  tenantId?: string;
  isScheduledBack?: boolean;
  isDetailsPage?: boolean;
  workflowlist?: any;
  addUrl?: string;
  title?: string;
  subtitle?: string;
  entityEventList?: any;
  onValueChanage?: (eventEntityList: any) => void;
  backgroundColor?: string;
  selectedLocations?: any
}) => {
  const [workflowEventEntityAllList, setWorkFlowEventEntityAllList] = useState(
    [] as any
  );
  const [isLoading, setIsLoading] = useState(false);
  const tenantId = props.tenantId || getAccountUUID();
  const [journeyDetailState, setJourneyDetailState] = useState<{
    isJourneyOpen: boolean;
    flowType:string,
    selectedJourneyWorkflowId: any;
  }>({
    isJourneyOpen: false,
    flowType:'',
    selectedJourneyWorkflowId: '',
  });
  const [ userPracticeLocations, setUserPracticeLocations] = useState<string[]>([])

  const commonContext = useContext(CommonDataContext);
  const isSidecarContext = commonContext.sidecarContext?.isSidecar;
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const userUuid = getUserUUID();
  const { getAccountLocationUuids } = useWorkflowListForTasks({})
  const [getUserPracticeLocations] = useLazyQuery(
    UserPracticeLocationQueries.GetMyPracticeLocations,
    {
      fetchPolicy: 'no-cache',
      variables: {
        userUuid: userUuid,
        accountUuid: tenantId,
      },
    }
  );

  const getUserPracticeLocation = async () => {
    const response = await getUserPracticeLocations()
    const data = response?.data;
    const uuidList: string[] = []

    if (data?.userPracticeLocations?.length > 0) {
      data.userPracticeLocations.forEach((object: any) => {
        if(object?.accountLocations[0] && object?.accountLocations[0]?.uuid)
        uuidList.push(object?.accountLocations[0]?.uuid)
      })
    }
    setUserPracticeLocations(uuidList)
    return uuidList
  };

  useEffect(() => {
    if (props.isScheduledBack && props?.workflowlist?.length > 0) {
      setWorkFlowEventEntityAllList(props.workflowlist);
    } else {
      if (isMsoEnabled) {
        const uuids = getAccountLocationUuids() || []
        const locationsList = uuids?.filter((location) => location == props?.selectedLocations?.[0]?.uuid) || []
        getAllEntityEventsList(locationsList)
      } else {
        getAllEntityEventsList()
      }
    }
  }, [props.contactIds, props?.selectedLocations]);

  useEffect(() => {
    if (!props.isDetailsPage) {
      props.onValueChanage && props.onValueChanage(workflowEventEntityAllList);
    }
  }, [workflowEventEntityAllList]);

  const headers = {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  };

  const [getFormAggregate] = useLazyQuery(
    FormsQueries.GET_FORMS_AGGREGATE_FOR_CONTACT,
    {
      fetchPolicy: 'no-cache',
      ...headers,
    }
  );

  const getAllEntityEventsList = (locationIds?: string[]) => {
    setIsLoading(true);
    getWorkFlowListByEventEntity(
      props.entityEventList,
      tenantId,
      isMsoEnabled,
      locationIds || [],
      async (dataList: any) => {
        dataList = dataList || [];
        const finalList = dataList.map((data: any) => {
          return {
            name: data.name,
            description: data.description,
            entity: data.entity,
            eventType: data.eventType,
            isEnabled: data.isEnabled,
            workflowMasterId: data.workflowMasterId,
            flowType: data.flowType
          };
        });

        if (dataList.length === 0) {
          setIsLoading(false);
        }
        if (
          props.workflowlist &&
          props.workflowlist.length > 0 &&
          !props.isDetailsPage &&
          !props.isScheduledBack
        ) {
          for (let i = 0; i < updateWorkFlowList(finalList).length; i++) {
            await getFormlistByEntityEvents(
              updateWorkFlowList(finalList)[i],
              updateWorkFlowList(finalList).length
            );
          }
        } else if (
          (props.workflowlist && props.workflowlist.length > 0) ||
          (props.workflowlist &&
            props.workflowlist.length > 0 &&
            (props.isDetailsPage || props.isScheduledBack))
        ) {
          for (let i = 0; i < updateWorkFlowList(finalList).length; i++) {
            await getFormlistByEntityEvents(
              updateWorkFlowList(finalList)[i],
              updateWorkFlowList(finalList).length
            );
          }
        } else {
          for (let i = 0; i < finalList.length; i++) {
            await getFormlistByEntityEvents(finalList[i], finalList.length);
          }
        }

        Promise.all([allFormIds, formDataList]).then(async (response) => {
          const finalWorkFlowAndFormList = await getFormAggregateData(
            response[0],
            response[1]
          );
          setWorkFlowEventEntityAllList(finalWorkFlowAndFormList);
          setIsLoading(false);
        });
      },
      (error: any) => {
        setIsLoading(false);
        setWorkFlowEventEntityAllList([]);
      }
    );
  };
  const navigate = useNavigate();

  const onSheduleFormClicked = () => {
    if (isSidecarContext) {
      redirectToNewTab(props.addUrl || '');
    } else {
      navigate(props.addUrl || '');
    }
  };

  const updateWorkFlowList = (data: any) => {
    const newList = data;
    data &&
      data.map((workflowList: any, index: number) => {
        props.workflowlist &&
          props.workflowlist.map((propsList: any) => {
            if (workflowList.workflowMasterId === propsList.workflowMasterId) {
              const replaceItem = {
                name: workflowList.name,
                description: workflowList.description,
                entity: workflowList.entity,
                eventType: workflowList.eventType,
                workflowMasterId: workflowList.workflowMasterId,
                isEnabled: propsList.isEnabled,
                flowType: propsList.flowType,
                formNodeList: propsList.formNodeList,
              };
              if (index !== -1) {
                newList[index] = replaceItem;
              }
            }
          });
      });
    return newList;
  };

  const formDataList: any = [];
  const allFormIds: any = [];
  const getFormlistByEntityEvents = async (data: any, length: number) => {
    await getWorkFlowFormListByEventEntity(
      data.workflowMasterId,
      data.entity,
      data.eventType,
      (dataList: any) => {
        dataList = dataList || [];
        dataList &&
          dataList.map((formList: any) => {
            const workflowMasterId = formList.workflowMasterId;
            const formLists: any = [];
            formList.graph &&
              formList.graph.nodes.map((nodelist: any) => {
                if (nodelist.data.nodeType === 'SendForms') {
                  nodelist.data.metaData.userInputFieldList.map(
                    (formList: any) => {
                      if (formList.key === 'formList') {
                        formList.value = formList?.value || [];
                        formList.value.map((form: any) => {
                          const list = {
                            id: form.id,
                            name: form.name,
                            isEnabled: true,
                            formStatus: null,
                            nodeId: nodelist.id,
                          };
                          allFormIds.push(form.id);
                          formLists.push(list);
                        });
                      }
                    }
                  );
                }
              });
            const finalFormData = {
              name: data.name,
              description: data.description,
              entity: data.entity,
              eventType: data.eventType,
              isEnabled: data.isEnabled,
              flowType: data.flowType,
              workflowMasterId: workflowMasterId,
              formNodeList: formLists,
            };
            formDataList.push(finalFormData);
            // if (formDataList.length === length) {
            //   getFormAggregateData(allFormIds, formDataList);
            //   setIsLoading(false);
            // }
          });
      },
      () => {
        //
      }
    );
  };

  const getFormAggregateData = async (formIds: any, formDataList: any) => {
    const finalFormDataList = formDataList;
    await getFormAggregate({
      variables: {
        filter: {
          id: {
            in: formIds,
          },
        },
      },
    }).then((result: any) => {
      result?.data?.formAggregate?.forms?.map((forms: any) => {
        finalFormDataList.map((finalFormList: any, index: number) => {
          finalFormList.formNodeList.map((formList: any, index1: number) => {
            if (formList.id === forms.id) {
              finalFormDataList[index].formNodeList[index1].formStatus =
                forms?.formStatus || FormStatus.pending;
            }
          });
        });
      });
    });
    return finalFormDataList;
  };

  return (
    <View style={{
      backgroundColor: props?.backgroundColor || '#fafafa',
      borderRadius: 8,
      }}
      >
      <HStack
        flex={1}
        space={0}
        justifyContent="space-between"
        marginBottom={1}
        alignItems="center"
      >
        {!props.isDetailsPage && isPathValidForUserRolePermission(props?.addUrl || '') && (
          <Pressable
            onPress={() => {
              onSheduleFormClicked();
            }}
          >
          </Pressable>
        )}
      </HStack>
      <VStack flex={1} marginBottom={2}>
        {isLoading ? (
          <Spinner
            size="lg"
            position="absolute"
            alignSelf="center"
            top="50%"
            left="50%"
          />
        ) : (
          workflowEventEntityAllList &&
          workflowEventEntityAllList.map((data: any, index: number) => (
            <CollapsePanelWithSwitch
              key={index}
              data={data}
              index={index}
              workflowEventEntityAllList={workflowEventEntityAllList}
              entityEventList={props.entityEventList}
              onValueChanage={(eventEntityList: any) => {
                setWorkFlowEventEntityAllList(eventEntityList);
                props.onValueChanage && props.onValueChanage(eventEntityList);
              }}
              isDetailsPage={props.isDetailsPage}
              showAutomation={(show: any, workflowMasterId: any, flowType: any) =>
                setJourneyDetailState({
                  isJourneyOpen: show,
                  flowType,
                  selectedJourneyWorkflowId: workflowMasterId,
                })
              }
            />
          ))
        )}
      </VStack>
      {journeyDetailState.isJourneyOpen &&
        <PatientCareJourneyDetail
          title={'Automation details'}
          visible={
            journeyDetailState.isJourneyOpen
          }
          flowType={journeyDetailState.flowType}
          patientCareJourney={{
            id: '',
            careJourneyId: journeyDetailState.selectedJourneyWorkflowId,
            careJourneyVersionId: journeyDetailState.selectedJourneyWorkflowId,
            workflowConfig: null,
            careJourney: {
              title: ' ', // pass white space
              duration: 0,
              description: ' ', // pass white space
              durationUnitId: ''
            },
          }}
          showJourneyTab={true}
          showActivityTab={false}
          onClose={() => {
            setJourneyDetailState((prev) => ({
              ...prev,
              selectedJourneyWorkflowId: '',
              isJourneyOpen: false,
            }));
          }}
        />
      }
    </View>
  );
};

export default WorkFlowListForTasks;
