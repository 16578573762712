import {Modal, ModalProps} from 'antd';
import {View, Text, TextStyle, TextProps, Pressable, StyleSheet} from 'react-native';
import {Colors} from '../../../styles';
import './style.css';
import {useIntl} from 'react-intl';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import Feather from 'react-native-vector-icons/Feather';
import {HStack, VStack} from 'native-base';

interface ICustomModalProps {
  customActionBtnText?: string;
  customActionBtnStyle?: any;
  customActionBtnTextStyle?: any;
  customTextHeaderStyle?: TextStyle;
  customTextHeaderProps?: TextProps;
  customTextContentStyle?: TextStyle;
  customTextContentProps?: TextProps;
  customOkBtnText?: string;
  customOkBtnStyle?: any;
  customOkBtnTextStyle?: any;
  customModalProps?: ModalProps;
  customOkBtnOnPress?: () => void;
  width?: string | number;
  isOpen?: boolean;
  isClosable?: boolean;
  customIcon?: JSX.Element;
  headerText: string;
  message?: string;
  onClose?: () => void;
  onAction?: () => void;
  modalContent?: JSX.Element;
  disabled?: boolean;
  subtitle?: string;
  customActionBtnContainerStyle?: any;
  hideActionBtn?: boolean;
}

const CustomModal = (props: ICustomModalProps) => {
  const intl = useIntl();

  return (
    <Modal
      className="custom-modal"
      width={props.width || 310}
      open={props?.isOpen || false}
      closable={false}
      {...(props.customModalProps || {})}
      footer={null}
    >
      <View style={{flex: 1}} >
        {/* Header Row */}
        <HStack space={2} alignItems="center" flex={1}>
          {props?.customIcon}
          <VStack alignItems="flex-start" width={props?.customIcon ? "80%" : "90%"}>
            <Text
              style={[
                styles.headerText,
                props?.customTextHeaderStyle || {},
              ]}
              {...(props.customTextHeaderProps || {})}
            >
              {intl.formatMessage({id: props.headerText || ''})}
            </Text>
            {props.subtitle && (
              <Text
                style={styles.subtitle}
              >
                {intl.formatMessage({id: props.subtitle})}
              </Text>
            )}
            {props?.modalContent ? (
              props.modalContent
            ) : props?.message ? (
              <View style={styles.modalContent}>
                <Text
                  style={[
                    styles.modalContentText,
                    props?.customTextContentStyle || {},
                  ]}
                  {...(props.customTextContentProps || {})}
                >
                  {intl.formatMessage({id: props?.message})}
                </Text>
              </View>
            ) : null}
          </VStack>

          <VStack alignItems="flex-end" marginBottom={9} >
          <Pressable onPress={props.onClose}>
            <Feather name="x" size={20} color={Colors.Custom.Gray400} />
          </Pressable>
          </VStack>
        </HStack>

        {/* Action Buttons */}
        <View
          style={[styles.actionButtons, props?.customActionBtnContainerStyle || {}]}
        >
          {!props?.hideActionBtn && 
            <FoldButton
              customProps={{
                btnText: props.customActionBtnText || 'Action',
              }}
              nativeProps={{
                disabled: props.disabled,
                onPress: props.onAction,
                backgroundColor: 'transparent',
                borderWidth: 0.5,
                height: '32px',
                borderColor: Colors.FoldPixel.GRAY200,
                borderRadius: 4,
                _text: {
                  fontSize: 14,
                  lineHeight: 16.8,
                  color: Colors.FoldPixel.GRAY300,
                  ...props.customActionBtnTextStyle,
                },
                paddingX: 4,
                ...props.customActionBtnStyle,
              }}
            />
          }

          <FoldButton
            customProps={{
              btnText: props.customOkBtnText || 'Confirm',
            }}
            nativeProps={{
              disabled: props.disabled,
              onPress: props.customOkBtnOnPress,
              backgroundColor: Colors.Custom.Red100,
              borderWidth: 0.5,
              height: '32px',
              borderColor: Colors.FoldPixel.STATUS_ERROR,
              borderRadius: 4,
              _text: {
                fontSize: 14,
                lineHeight: 16.8,
                color: Colors.FoldPixel.STATUS_ERROR,
              },
              paddingX: 4,
              marginLeft: 4,
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContent: {
    marginTop: 2,
  },
  subtitle: {
    color: Colors.FoldPixel.GRAY300,
    fontSize: 14,
    lineHeight: 16.8,
    textAlign: 'left',
  },
  headerText: {
    color: Colors.FoldPixel.GRAY400,
    fontSize: 16,
    lineHeight: 19.2,
    fontWeight: '500',
    textAlign: 'left',
  },
  modalContentText: {
    color: Colors.FoldPixel.GRAY250,
    fontSize: 14,
    lineHeight: 16.8,
    fontWeight: '400',
    textAlign: 'left',
  },
  actionButtons:{
    marginTop: 14,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  }
});

export default CustomModal;
