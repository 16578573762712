import React, {useEffect, useState} from 'react';
import {FlatList, HStack, Pressable, Spacer, Stack, Text, View, VStack} from 'native-base';
import {Collapse, Drawer, Empty, Tooltip} from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import {RightOutlined} from '@ant-design/icons';
import {useIntl} from 'react-intl';
import {getDateStrFromFormat} from '../../../../../../utils/DateUtils';
import {tooltipInnerOverlayStyle} from '../CustomWrapper/CustomComponentHelper';
import PamiIconSvg from '../../../../../common/Svg/PamiIconSvg';
import {FORM_CATEGORY_TYPES} from '../../../FormsConstants';
import {formatFormsResponse, getTrimmedDataForTrendView} from './ClinicalAssessmentHelper';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {useLazyQuery} from '@apollo/client';
import {FormsQueries} from '../../../../../../services';
import {IFormResponse} from './interfaces';
import {BUTTON_TYPE, DATE_FORMATS} from '../../../../../../constants';
import FormComponentLoader from '../CustomWrapper/FormComponentLoader/FormComponentLoader';
import { ClinicalSectionListItem } from '../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionListItem/ClinicalSectionListItem';
import { converGraphDataToVitalGraphData } from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils';
import { GraphsTypes } from '../HomeMonitoringView/interface';
import { Colors } from '../../../../../../styles';
import { ModalActionTitle } from '../../../../../common/ModalActionTitle/ModalActionTitle';
import { ClinicalAssessmentDrillDownView } from './ClinicalAssessmentDrillDownView';
import { GRAPH_TYPES } from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/Activities';
import { reactStyles, styles } from '../../../Analytics/style';
import { antdStyles } from '../../../../Contacts/TeamMembers/InviteUserDrawer/InviteUserDrawerStyles';

interface IClinicalAssessmentPanelViewState {
  showGraphs: boolean;
  loading: boolean;
  collapsed: boolean;
  activePanels: string[];
  forms: IFormResponse[];
  relevantCodes: string[];
}

interface IClinicalAssessmentPanelViewProps {
  contactId: string;
  relevantCodes?: string[];
  ccmDate: string | undefined;
}

const SURVEYS_PANEL_KEY = 'surveys';

const ClinicalAssessmentPanelView = (
  props: IClinicalAssessmentPanelViewProps
) => {
  const [componentState, setComponentState] =
    useState<IClinicalAssessmentPanelViewState>({
      loading: false,
      collapsed: true,
      showGraphs: false,
      activePanels: [''],
      forms: [],
      relevantCodes: props.relevantCodes || [],
    });

  const intl = useIntl();
  const contactId = props.contactId;

  const {Panel} = Collapse;

  const [getPatientForms] = useLazyQuery(
    FormsQueries.GET_FORM_RESPONSES_SCORE_BY_FORM_CATEGORY,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  useEffect(() => {
    getPatientFormsData(contactId);
  }, []);

  const getPatientFormsData = (contactId: string) => {
    const params = {
      contactId: contactId,
      category: FORM_CATEGORY_TYPES.ASSESSMENT_FORM_CATEGORY,
    };
    setComponentState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

      getPatientForms({
        variables: params,
      })
        .then((response) => {
          handleResponse(response?.data?.forms);
        })
        .catch((error) => {

          setComponentState((prev) => {
            return {
              ...prev,
              loading: false,
            };
          });
        });

  };

  const handleResponse = (formResponses: IFormResponse[]) => {
    let forms = formatFormsResponse(formResponses, props.ccmDate);
    forms = forms.filter((form) => form.lastSubmittedOn);
    setComponentState((prev) => {
      return {
        ...prev,
        forms,
        loading: false,
        activePanels:
          forms?.length > 0 ? [SURVEYS_PANEL_KEY] : prev.activePanels,
      };
    });
  };

  const getDetailView = (isCompact: boolean, isGraph: boolean) => {
    if (isCompact) {
      return componentState?.forms?.map((form, index) => {
        if(form?.graphData?.length > 0) {
          return (
            <ClinicalSectionListItem
              key={`${'forms'}_${index}`}
              title={form.title}
              lastRecordedOn={getDateStrFromFormat(
                form.lastSubmittedOn,
                DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
              )}
              highlightValue={form.averageFormScore}
              code={GRAPH_TYPES.Form}
              valueType={'Avg Score'}
              vitalGraphData={converGraphDataToVitalGraphData(
                getTrimmedDataForTrendView(form),
                GraphsTypes.LINE
              )}
            />
          );
        }
      });
    }
  };

  const renderDrillDownView = () => {
    return (
      <Drawer
        width="35%"
        open={componentState.showGraphs}
        title={
          <ModalActionTitle
            isHeadNotSticky
            title={'Surveys/Assessments'}
            buttonList={[
              {
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    showGraphs: false,
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: 'close',
                }),
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
              },
            ]}
          />
        }
        onClose={() => {
          setComponentState((prev) => ({...prev, showGraphs: false}));
        }}
      >
        <ClinicalAssessmentDrillDownView forms={componentState.forms}/>
      </Drawer>
    );
  };

  return (
    <Collapse
      onChange={(activePanels) => {
        setComponentState((prev) => ({
          ...prev,
          activePanels: activePanels as string[],
        }));
      }}
      expandIconPosition="end"
      expandIcon={({isActive}) => {
        const tooltipText = isActive
          ? intl.formatMessage({id: 'collapse'})
          : intl.formatMessage({id: 'expand'});
        return (
          <Tooltip
            title={tooltipText}
            placement="bottom"
            showArrow={false}
            overlayStyle={{paddingTop: '12px'}}
            overlayInnerStyle={tooltipInnerOverlayStyle}
          >
            <RightOutlined rotate={isActive ? 90 : 0} />
          </Tooltip>
        );
      }}
      style={antdStyles.collapse}
    >
      <Panel
        forceRender
        key={SURVEYS_PANEL_KEY}
        header={
          <HStack paddingRight={4} justifyContent="space-between">
            <HStack alignItems={'center'} space={2} marginTop={0}>
              <Text
                size={'lgMedium'}
                color={Colors.FoldPixel.GRAY300}
                fontWeight={500}
              >
                {intl.formatMessage({id: 'surveyAssessments'})}
              </Text>
            </HStack>
            <View>
              <Tooltip title={'Graph View'} placement="bottom" key={'graphView'} showArrow={false} overlayStyle={{ paddingTop: '3px' }} overlayInnerStyle={tooltipInnerOverlayStyle}>
                <Pressable
                  onPress={() =>  setComponentState((prev) => ({...prev, showGraphs: true}))}
                  style={styles.pressable1}
                  rounded="full"
                >
                  <Feather
                    name={'bar-chart-2'}
                    size={20}
                    color={Colors.Custom.foldIconColor}
                  />
                </Pressable>
              </Tooltip>
            </View>
          </HStack>
        }
      >
        <VStack style={styles.vStackStyle1}>
          {componentState.loading && (
            <View>
              <FormComponentLoader />
            </View>
          )}

          {!componentState?.loading && componentState?.forms?.length === 0 && (
            <Empty />
          )}

          {!componentState?.loading &&
            componentState?.forms?.length > 0 &&
            getDetailView(true, false)}
          {renderDrillDownView()}
        </VStack>
      </Panel>
    </Collapse>
  );
};

export default ClinicalAssessmentPanelView;
