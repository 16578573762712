import { TASK_ALERT_CATEGORY_CODES, TASK_PRIORITY_CODES } from '../../constants/MlovConst';
import { IMlov } from '../../Interfaces';
import {getCurrentSubdomain} from '../../screens/MainScreen/MainScreenHelper';
import { getLoggedInAccountInfoForSidecar } from '../../sidecar/common/SideCardCommonUtils';
import { getMlovIdFromCode } from '../../utils/mlovUtils';
import { ALERTS_ACTIONS_MENU, ALERT_ACTION_CODES, ALERT_SEVERITY_NAMES, CATEGORY_CODES_OF_ALERT_ACTIONS } from './constants';

export const redirectToNewTab = async (path: string) => {
  const loggedInUserAccountInfo = await getLoggedInAccountInfoForSidecar();
  const subdomain = loggedInUserAccountInfo?.subdomain;
  if (subdomain) {
    const redirectPath = `https://${subdomain}/#${path}`;
    window.open(redirectPath, '_blank', 'noopener,noreferrer');
  }
};

export const getSidecarDrawerWidth = (selectedAction: string, totalWidth: number) => {
  return totalWidth * 0.35;
  // if (selectedAction === SIDE_CAR_ACTIONS.INSIGHTS || selectedAction === SIDE_CAR_ACTIONS.SHOW_MESSAGE || selectedAction === SIDE_CAR_ACTIONS.USER_MISMATCH) {
  //   return totalWidth * 0.35;
  // }
  // return totalWidth * 0.70;
}

export const getViewInEHRLink = (patientId: string, ehrUrl: string): string => {
  const stringToReplace = '#PATIENT_ID#';
  return (patientId && ehrUrl.replace(stringToReplace, patientId)) || '';
};

export const getFilteredActionsMenuForAlerts = (
  displayCategoryCode: string,
  taskTypeActionsMlovList: IMlov[],
  taskTypeActionIds: string[],
  isAthena?: boolean,
  isDiagnosysGap?: boolean
) => {
  const isPendingCategory =
    displayCategoryCode === TASK_ALERT_CATEGORY_CODES.PENDING;
    const isSnoozedCategory =
    displayCategoryCode === TASK_ALERT_CATEGORY_CODES.SNOOZED;
  const actionCategoryToFilter = isPendingCategory
    ? ''
    : CATEGORY_CODES_OF_ALERT_ACTIONS.STATUS_ACTIONS;

  const actionsMenuToShow = ALERTS_ACTIONS_MENU.filter((action) => {

    if (action.code === ALERT_ACTION_CODES.MOVE_TO_PENDING && isSnoozedCategory) {
      return true;
    }
    if(isDiagnosysGap && (action.code === ALERT_ACTION_CODES.RESOLVE || action.code === ALERT_ACTION_CODES.ADD_IMAGING_ORDER)) {
      return false;
    }
    if (action.code === ALERT_ACTION_CODES.REVIEW_DIAGNOSIS_GAP) {
      return isDiagnosysGap;
    }
    if (isPendingCategory && action.code === ALERT_ACTION_CODES.MOVE_TO_PENDING) {
      return false;
    }
    if (action.categoryCode === actionCategoryToFilter) {
      if (
        action.code === ALERT_ACTION_CODES.SNOOZE ||
        action.code === ALERT_ACTION_CODES.DISMISS ||
        action.code === ALERT_ACTION_CODES.RESOLVE
      ) {
        return true;
      }
      return false;
    }
    if (isAthena && action.code === ALERT_ACTION_CODES.ADD_TO_NOTE_IN_EHR) {
      return true;
    }

    if (!isAthena && action.code === ALERT_ACTION_CODES.ADD_IMAGING_ORDER) {
      return true;
    }

    const actionId = getMlovIdFromCode(taskTypeActionsMlovList, action.code);
    if (
      action.categoryCode === CATEGORY_CODES_OF_ALERT_ACTIONS.MANAGEMENT_ACTIONS
    ) {
      return taskTypeActionIds.includes(actionId);
    }

    return true;
  });
  return actionsMenuToShow
};

export const getSeverityNamesFromPriorityCodes = (code: string | string[]) => {
  const getSeverityName = (singleCode: string) => {
    switch (singleCode) {
      case TASK_PRIORITY_CODES.HIGH:
        return ALERT_SEVERITY_NAMES.SEVERE;
      case TASK_PRIORITY_CODES.MEDIUM:
        return ALERT_SEVERITY_NAMES.MODERATE;
      case TASK_PRIORITY_CODES.LOW:
        return ALERT_SEVERITY_NAMES.ROUTINE;
    }
  };

  if (Array.isArray(code)) {
    return code.map(getSeverityName);
  } else {
    return getSeverityName(code);
  }
};
