import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {Colors} from '../../../styles';
import {TaskPanelType} from '../../TaskCard/TaskEnum';
import {
  TEXT_ONLY_MODULES_WITHOUT_CLEAN,
} from '../../common/RichTextEditor/RichTextEditor';
import CommunicationModeSelector from './CommunicationModeSelector';
import {Checkbox, HStack, Pressable, Spinner, Stack} from 'native-base';
import {DatePicker, Select, Skeleton, Tooltip} from 'antd';
import {useIntl} from 'react-intl';
import {reactStyles, styles} from '../styles';
import useOutreachStepOutComes from '../../common/CareProgramStepOutreach/useOutreachStepOutComes';
import ContactOutreachActivities from './ContactOutreachActivities/ContactOutreachActivities';
import ChevronDownSvg from '../../common/Svg/ChevronDownSvg';
import CalenderNavSvg from '../../common/Svg/ConversationAllSvg/CalenderNavSvg';
import {DATE_FORMATS} from '../../../constants/StringConst';
import moment, { Moment } from 'moment';
import {HiddenFields, useContactOutreachActivity} from './ContactOutreachActivities/useContactOureachActivity';
import {AddOrUpdateContactOutreachActivityInput} from '../../RightSideContainer/ContactCareProgram/interface';
import CustomTextAreaField from '../../RightSideContainer/Forms/FHFormio/CustomComponents/TextAreaComponent/CustomTextAreaField';
import { getCurrentTimeZone, getMomentObj } from '../../../utils/DateUtils';
import { ModalActionTimePicker } from '../../common/ModalActionCommonComponent/ModalActionTimePicker';
import { getMlovIdFromCode } from '../../../utils/mlovUtils';
import { CARE_PROGRAM_TYPES } from '../../../constants/MlovConst';
import Feather from 'react-native-vector-icons/Feather';
import { getEhrConfig } from '../../../utils/capabilityUtils';
import { testID, TestIdentifiers } from '../../../testUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { USER_SEARCH_TYPE } from '../../RightSideContainer/constants';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { CustomUserSearch } from '../../common/CustomUserSearch/CustomUserSearch';
import { CUSTOM_USER_SEARCH_MODE } from '../../common/CustomUserSearch/interface';

/* import ReactDOMServer from 'react-dom/server';
import { Quill } from 'react-quill';
import { ArrowDownOutlined } from '@ant-design/icons';
const icons = Quill.import('ui/icons');
icons['bold'] = ReactDOMServer.renderToString(<ArrowDownOutlined />); */

const InsightsPatientNotesView = (props: {
  contactData?: IContact;
  formattedContactData?: IContact;
  onTaskPanelChanged?: (taskPanelType: TaskPanelType) => void;
  hiddenFields?: HiddenFields[];
  isCareManagement?: boolean;
  customOnSaveHandler?: (outreachData: AddOrUpdateContactOutreachActivityInput) => Promise<any>;
  onSaveSuccess?: (response: any) => Promise<void>;
  selectStepWhenEditingOldActivity?: (stepId: string) => void;
  careProgramTypeId?: string;
  contactCareProgramId?: string;
  contactCareProgramAssigneeId?: string;
  macroTemplatesEnabled?: boolean;
  isDisabled?: boolean;
}) => {
  const intl = useIntl();
  const {categoryWiseList} = useOutreachStepOutComes();
  const commonData = useContext(CommonDataContext);

  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const selectUsersRef = React.useRef<any>();


  const ehrConfig = getEhrConfig(
    props?.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
    ''
  );
  const isAthena = ehrConfig.isAthena;
  const isFold = ehrConfig.isFold;

  const {
    data,
    careProgramTypesMlov,
    saveButtonDisabled,
    outreachCommunicationModes,
    saveButtonLoading,
    activeMode,
    setActiveMode,
    setData,
    validateForm,
    saveActivity,
    resetData,
    outreachActivities,
    showMoreActivity,
    loading,
    hideCareProgramType,
    isCareManagement,
    isUpdate,
    fetchMoreActivities,
    handleCareProgramTypeChange,
    handleEditOutreachActivity,
    deleteOutreachActivity,
    userDataLoading,
    isECMCareProgram
  } = useContactOutreachActivity({
    contactUuid: props?.contactData?.uuid || '',
    hiddenFields: props?.hiddenFields,
    isCareManagement: props?.isCareManagement,
    isFold: ehrConfig.isFold,
    customOnSaveHandler: props?.customOnSaveHandler,
    selectStepWhenEditingOldActivity: props?.selectStepWhenEditingOldActivity,
    careProgramTypeId: props?.careProgramTypeId,
    contactCareProgramId: props?.contactCareProgramId,
    contactCareProgramAssigneeId: props?.contactCareProgramAssigneeId,
    onSaveSuccess: props?.onSaveSuccess
  });

  const currentActivity = useMemo(() =>
    outreachActivities.find(activity => activity.id === data?.id),
    [outreachActivities, data?.id]
  );

  const isAutomatedOutreach = currentActivity?.isAutomatedOutreach || false;

  const getOutreachDuration = () => {
   const duration = data?.durationInSeconds ?
   moment().startOf('day')
     .add(Math.floor(data.durationInSeconds / 3600), 'hours')
     .add(
        data.durationInSeconds < 60 ? 1 :
         Math.floor((data.durationInSeconds % 3600) / 60) +
         (((data.durationInSeconds % 3600) % 60) >= 30 ? 1 : 0),
       'minutes'
     ) :
   moment().startOf('day').add(1, 'minutes')
   return duration
  }

  const handleModeChange = useCallback(
    (mode) => {
      setActiveMode(mode.code);
      setData((prev) => ({...prev, communicationModeId: mode.id}));
    },
    [setActiveMode, setData]
  );
  const ccmCareProgramTypeId = getMlovIdFromCode(careProgramTypesMlov, CARE_PROGRAM_TYPES.CCM);
  const isCCMProgram = data?.careProgramTypeId ? data?.careProgramTypeId === ccmCareProgramTypeId : false;

  return (
    <div className="day-op">
      <View style={styles.insightPatientView}>
        <View style={styles.insightsPatientNotesViewHeader}>
          {!hideCareProgramType ? (
            <View>
              <Select
                className="day-op custom-select-box"
                placeholder="AWV"
                suffixIcon={<ChevronDownSvg />}
                value={
                  data?.careProgramTypeId?.length
                    ? data?.careProgramTypeId
                    : undefined
                }
                onSelect={handleCareProgramTypeChange}
              >
                {careProgramTypesMlov.map((mode) => (
                  <Select.Option key={mode.id} value={mode.id}>
                    {mode.value}
                  </Select.Option>
                ))}
              </Select>
            </View>
          ) : (
            <View />
          )}
          <HStack space={2} alignItems="center">
            <DatePicker
              suffixIcon={
                <CalenderNavSvg
                  width={16}
                  height={16}
                  customStrokeColor={Colors.FoldPixel.GRAY300}
                />
              }
              value={
                data?.outreachDateTime
                  ? moment(data?.outreachDateTime)
                  : undefined
              }
              className="day-op custom-antd-input-box"
              showTime={{use12Hours: true}}
              allowClear={false}
              style={reactStyles.datePicker}
              onChange={(date) => {
                if (date) {
                  setData({...data, outreachDateTime: date?.toISOString()});
                }
              }}
              format={DATE_FORMATS.MM_DD_YY_COMMA_HH_MM_A_DATE_TIME_FORMAT}
              disabled={(isAutomatedOutreach && isUpdate) || props.isDisabled}
            />
           {isCCMProgram && (
 +              loading ? (
                <Skeleton.Input
                  active
                  style={{
                    width: 135,
                    borderRadius: 4
                  }}
                />
              ) : (
                <ModalActionTimePicker
                  label={''}
                  customStyle={{width: 135, height: 32, borderRadius: 4}}
                  isHideOkButton={true}
                  isHideCancelButton={true}
                value={getOutreachDuration() ?? moment().startOf('day').add(1, 'minutes')}
                  format={DATE_FORMATS.HOURS_MINUTES_FORMAT}
                  minuteStep={1}
                  showSecond={false}
                  onSelect={(date: Moment) => {
                  const seconds = (date.hours() * 3600) + (date.minutes() * 60);
                    setData({...data, durationInSeconds: seconds});
                  }}
                disabled={(isAutomatedOutreach && isUpdate) || props.isDisabled}
                />
              )
            )}
          </HStack>
        </View>

        <View style={styles.insightsPatientNotesView}>
          <CommunicationModeSelector
            mode={outreachCommunicationModes}
            activeMode={activeMode}
            onModeChange={handleModeChange}
            disabled={isAutomatedOutreach && isUpdate}
          />
          <CustomTextAreaField
            value={data?.note}
            macroTemplatesEnabled={props?.macroTemplatesEnabled}
            isRTE={true}
            disabled={props.isDisabled}
            placeholder={intl.formatMessage({id: 'addNote'})}
            textModules={TEXT_ONLY_MODULES_WITHOUT_CLEAN}
            onValueChange={(text) => {
              const trimedData = text?.trim();
              const newData = {...data, note: trimedData};
              setData(newData);
              validateForm(newData);
            }}
          />
          {/* <Pressable
            onPress={() => {}}
            position={'absolute'}
            bottom={'12.5px'}
            left={'15px'}
            flexDir={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <Tooltip
              title={intl.formatMessage({
                id: 'taskAttachmentUploadSupportAndSize',
              })}
            >
              <PaperClipSvg />
            </Tooltip>
          </Pressable> */}
        </View>

        <View style={styles.mt8}>
          <Select
            className="day-op custom-select-box"
            placeholder={intl.formatMessage({id: 'selectOutreachOutcome'})}
            value={data?.outcomeId?.length ? data?.outcomeId : undefined}
            style={reactStyles.w100p}
            suffixIcon={<ChevronDownSvg />}
            disabled={(isAutomatedOutreach && isUpdate) || props.isDisabled}
            onSelect={(value) => {
              const newData = {...data, outcomeId: value};
              setData(newData);
              validateForm(newData);
            }}
          >
            {Object.keys(categoryWiseList).map((key) => {
              const optionList = categoryWiseList[key];
              return (
                <Select.OptGroup key={key} label={key}>
                  {optionList.map((option) => (
                    <Select.Option key={option.value} value={option.value} data-testId={option?.label}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              );
            })}
          </Select>
          {isECMCareProgram && (
            <Stack
              direction="row"
              alignItems="center"
              space={0.5}
              style={{marginVertical: 8, flex: 1}}
            >
              {userDataLoading ? (
                <Skeleton.Input
                  active
                  size={'small'}
                  style={reactStyles.skeletonInput}
                />
              ) : (
                <CustomUserSearch
                  userSelectRef={selectUsersRef}
                  isRequired={false}
                  className={'cm-outreach custom-select-box'}
                  placeholder={intl.formatMessage({id: 'searchAndSelectUser'})}
                  disabledUsers={[]}
                  hideTooltip={true}
                  allowClear={true}
                  formControlLabelStyles={{
                    paddingBottom: 4,
                  }}
                  customLabel={<></>}
                  mode={CUSTOM_USER_SEARCH_MODE.SINGLE_SELECT}
                  selectedUsersData={
                    data?.conductedByUser ? [data?.conductedByUser] : []
                  }
                  onChange={(selectedUsersUuids, selectedInternalUsers) => {
                    if (selectedInternalUsers) {
                      setData((prev) => ({
                        ...prev,
                        conductedByUser: selectedInternalUsers?.[0],
                      }));
                    }
                    if (selectUsersRef.current) {
                      selectUsersRef?.current?.blur();
                    }
                  }}
                  userSearchType={
                    isMsoEnabled
                      ? USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED
                      : USER_SEARCH_TYPE.DEFAULT
                  }
                />
              )}
            </Stack>
          )}
        </View>
        {isCCMProgram && (
          <Stack style={[styles.mt8, styles.checkboxContainer]} direction="row" alignItems="center" space={0.5}>
            <Checkbox
              defaultIsChecked={true}
              value="addToBill"
              backgroundColor="transparent"
              borderColor={Colors.Custom.mainPrimaryPurple}
              borderWidth={1}
              isDisabled={isUpdate}
              colorScheme={Colors.Custom.mainPrimaryPurple}
              style={{
                padding: 2,
              }}
              isChecked={data?.isBillable}
              onChange={(isChecked: boolean) => {
                const newData = {...data, isBillable: isChecked};
                setData((prev) => ({...prev, isBillable: isChecked}));
                validateForm(newData);
              }}
              _checked={{
                value: 'addToBill',
                backgroundColor: Colors.Custom.mainPrimaryPurple,
                borderColor: Colors.Custom.mainPrimaryPurple,
              }}
              _unchecked={{
                value: 'addToBill',
                backgroundColor: 'transparent',
                borderColor: Colors.Custom.mainPrimaryPurple,
              }}
              _icon={{
                color: Colors.Custom.White,
                size: '14px',
                strokeWidth: '0.5',
              }}
            >
              <Text style={styles.checkboxText}>
                {intl.formatMessage({id: 'addToBill'})}
              </Text>
            </Checkbox>
            <Tooltip title={intl.formatMessage({id: 'addToBillTooltip'})} placement='right' >
              <Feather name="info" size={12} color={Colors.Custom.Gray500} />
            </Tooltip>
          </Stack>
        )}
        {!isFold && (
          <Stack style={[styles.mt8, styles.checkboxContainer]} direction="row" alignItems="center" space={0.5}>
            <Checkbox
              defaultIsChecked={true}
              value="syncToEhr"
              backgroundColor="transparent"
              borderColor={Colors.Custom.mainPrimaryPurple}
              borderWidth={1}
              colorScheme={Colors.Custom.mainPrimaryPurple}
              style={{
                padding: 2,
              }}
              isDisabled={isUpdate || props.isDisabled}
              isChecked={data?.syncToEhr}
              onChange={(isChecked: boolean) => {
                const newData = {...data, syncToEhr: isChecked};
                setData((prev) => ({...prev, syncToEhr: isChecked}));
                validateForm(newData);
              }}
              _checked={{
                value: 'syncToEhr',
                backgroundColor: Colors.Custom.mainPrimaryPurple,
                borderColor: Colors.Custom.mainPrimaryPurple,
              }}
              _unchecked={{
                value: 'syncToEhr',
                backgroundColor: 'transparent',
                borderColor: Colors.Custom.mainPrimaryPurple,
              }}
              _icon={{
                color: Colors.Custom.White,
                size: '14px',
                strokeWidth: '0.5',
              }}
            >
              <Text style={styles.checkboxText}>
                {intl.formatMessage({id: 'syncToEhr'})}
              </Text>
            </Checkbox>
            <Tooltip title={intl.formatMessage({id: isAthena ? 'sendToEhrTooltipAthena' : 'sendToEhrTooltipElation'})} placement='right'>
              <Feather name="info" size={12} color={Colors.Custom.Gray500} />
            </Tooltip>
          </Stack>
        )}

        <View style={styles.buttonContainer}>
          <Pressable
            borderRadius={4}
            borderWidth={0.5}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={4}
            borderColor={
              saveButtonDisabled || props.isDisabled
                ? Colors.FoldPixel.TRANSPARENT
                : Colors.FoldPixel.PRIMARY300
            }
            padding={'8px'}
            isDisabled={saveButtonDisabled || props.isDisabled}
            backgroundColor={
              saveButtonDisabled || props.isDisabled
                ? Colors.FoldPixel.TRANSPARENT
                : Colors.FoldPixel.PRIMARY300
            }
            onPress={saveActivity}
          >
            {saveButtonLoading ? (
              <Spinner height={'16px'} width={'16px'} />
            ) : (
              <></>
            )}
            <Text
              style={StyleSheet.flatten([
                styles.saveButtonText,
                saveButtonDisabled || props.isDisabled ? styles.buttonTextDisabled : {},
              ])}
              {...testID(TestIdentifiers.addButton)}
            >
              {intl.formatMessage({id: isUpdate ? 'update' : 'save'})}
            </Text>
          </Pressable>

          <Pressable
            borderRadius={4}
            borderWidth={0.5}
            borderColor={Colors.FoldPixel.GRAY250}
            padding={'8px'}
            backgroundColor={Colors.Custom.White}
            onPress={resetData}
            disabled={props.isDisabled}
          >
            <Text style={styles.buttonText} {...testID(TestIdentifiers.cancelBtn)}>
              {intl.formatMessage({id: 'cancel'})}
            </Text>
          </Pressable>
        </View>

        <ContactOutreachActivities
          outreachActivities={outreachActivities}
          loading={loading}
          showMoreActivity={showMoreActivity}
          isDisabled={props.isDisabled}
          fetchMoreActivities={fetchMoreActivities}
          handleEditOutreachActivity={handleEditOutreachActivity}
          deleteOutreachActivity={deleteOutreachActivity}
          isCCMProgram={isCCMProgram}
          isBilled={data?.isBillable}
        />
      </View>
    </div>
  );
};

export default InsightsPatientNotesView;
