import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../../styles/Colors';

export const styles = StyleSheet.create({
  text14gray300fw400: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.FoldPixel.GRAY300,
    lineHeight: 16.8,
  },

  text14primary300fw400: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.FoldPixel.PRIMARY300,
    lineHeight: 16.8,
  },
  text14Gray300fw400: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.Custom.GRAY350,
    lineHeight: 16.8,
  },
  showMoreText: {
    fontSize: 12,
    fontWeight: '400',
    color: Colors.FoldPixel.PRIMARY300,
  },
  eventContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }
});

export const reactStyles: Record<string, React.CSSProperties> = {
  w0p5: {
    width: 0.5,
  },
};
