import {HStack, Text} from 'native-base';
import {StyleSheet} from 'react-native';
import {IPatientHIEStatus, IPatientHieResponse} from './Interface';
import {useEffect, useState} from 'react';
import {getPatientHieStatus} from '../../../services/Patient/PatientHieStatus';
import {Button} from 'antd';
import PatientHIEStatusDetailModal from './PatientHIEStatusDetailModal';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../styles';

const PatientHIEStatusView = (props: IPatientHIEStatus) => {
  const {patientData, sideCarDashboardEnabled} = props;
  const [patientHIESyncStatus, setPatientHIESyncStatus] = useState<IPatientHieResponse>({} as IPatientHieResponse);
  const [patientHIESyncModal, setPatientHIESyncModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const patientHieStatus = async (patientId: string) => {
    const patientHieStatusResp = await getPatientHieStatus(patientId);
    if (
      patientHieStatusResp &&
      patientHieStatusResp?.data &&
      !patientHieStatusResp?.data?.patientRegistered
    ) {
      setLoading(false);
      setPatientHIESyncStatus(patientHieStatusResp.data);
    } else {
      setLoading(true);
      setPatientHIESyncStatus(patientHieStatusResp.data);
    }
  };

  useEffect(() => {
    if (patientData?.patientId) {
      patientHieStatus(patientData?.patientId);
    }
  }, [patientData?.patientId]);
  const onActionPerfomed = (code: string, data?: any) => {
    if (code === COMMON_ACTION_CODES.CLOSE) {
      setPatientHIESyncModal(false);
    }
  };

  return loading ? (
    <></>
  ) : (
      <HStack 
      style={[patientHIEStatusStyles.patientHIEStatusContainer, {
        width: sideCarDashboardEnabled ? 'full' : 'auto',
        marginHorizontal: sideCarDashboardEnabled ? '-16px' : '0',
        backgroundColor: sideCarDashboardEnabled ? Colors.Custom.Red100 : 'transparent'
      }]}>
        <HStack>
        <Button
          type="text"
          danger
          onClick={() => {
            setPatientHIESyncModal(true);
          }}
          style={{color: Colors.Custom.Danger700}}
        >
          <Feather
            name="alert-triangle"
            size={16}
            color={Colors.FoldPixel.STATUS_ERROR}
          />
          <Text style={patientHIEStatusStyles.patientHIEStatusText}>
            Sync Error
          </Text>
        </Button>
      </HStack>
      <PatientHIEStatusDetailModal
        onActionPerfomed={onActionPerfomed}
        isOpen={patientHIESyncModal}
        patientHieResponseData={patientHIESyncStatus}
      ></PatientHIEStatusDetailModal>
    </HStack>
  );
};

export default PatientHIEStatusView;

const patientHIEStatusStyles = StyleSheet.create({
  patientHIEStatusContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '28px'
  },
  patientHIEStatusText: {
    marginLeft: 5,
    fontWeight: '400',
    fontSize: 12,
    color: Colors.FoldPixel.STATUS_ERROR
  }
});
