import React, {useCallback, useEffect, useMemo} from 'react';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  CreateContactOutreachActivity,
  GetContactOutreachActivities,
} from '../../../../services/ContactOutreach/ContactOutreachQueries';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../../../constants';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import {
  CARE_PROGRAM_OUTREACH_MODE_CODES,
  CARE_PROGRAM_TYPES,
} from '../../../../constants/MlovConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { PatientNotesLogModeOfContact } from '../../constants';
import { IFormattedContactOutreachActivity } from './interfaces';
import CareProgramStepOutreachUtils from '../../../common/CareProgramStepOutreach/CareProgramStepOutreachUtils';
import {GET_USERS_WITH_IDS} from '../../../../services/User/UserQueries';
import {getUserData, getUserUUID} from '../../../../utils/commonUtils';
import {formatOutreachData} from './ContactOutreachActivitiesHelper';
import {AddOrUpdateContactOutreachActivityInput} from '../../../RightSideContainer/ContactCareProgram/interface';
import {getMomentObj} from '../../../../utils/DateUtils';
import useOutreachStepOutComes from '../../../common/CareProgramStepOutreach/useOutreachStepOutComes';
import useOutreachStatus from '../../../common/CareProgramStepOutreach/useOutreachStatus';
import { useActiveCareProgramTypes } from '../../../RightSideContainer/ContactCareProgram/ContactCareProgramView/hooks/useActiveCareProgramTypes';
import UseTimeLogForBilling from '../../../CustomHooks/UseTimeLogForBilling';
import { ADDITIONAL_ATTRIBUTE_KEY } from '../../../common/MemebersView/constant';
import { EventBus } from '../../../../utils/EventBus';
import { CARE_PROGRAM_EVENTS } from '../../../RightSideContainer/ContactCareProgram/useCPEventhandler';
import { CARE_PROGRAM_EVENT_CODES } from '../../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import { IInternalUsers } from '../../../common/CustomUserSearch/interface';
import { useCareProgramDashboardData } from '../../../RightSideContainer/CareManagementView/CareManagementViewHooks/useCareProgramDashboardData';
import { ADDITIONAL_DATA_CODE } from '../../../RightSideContainer/CareManagementBilling/CareManagementConstants';
import { IKeyPointData } from '../../../RightSideContainer/CareManagementView/CareManagementViewHooks/useCareProgramAdditionalData';
import InboxQueries from '../../../../services/Inbox/InboxQueries';

export type HiddenFields = 'communicationModeId' | 'outcomeId' | 'careProgramTypeId';
export interface IUseContactOutreachActivity {
  contactUuid: string;
  hiddenFields?: HiddenFields[];
  isCareManagement?: boolean;
  selectStepWhenEditingOldActivity?: (stepId: string) => void;
  customOnSaveHandler?: (outreachData: AddOrUpdateContactOutreachActivityInput) => Promise<any>;
  onSaveSuccess?: (response: any) => Promise<void>;
  careProgramTypeId?: string;
  contactCareProgramId?: string;
  isFold?: boolean;
  contactCareProgramAssigneeId?: string;
}

export interface IContactOutreachActivityData {
  statusId: string;
  typeId: string;
  communicationModeId: string;
  outcomeId: string;
  note: string;
  outreachDateTime: string;
  careProgramTypeId: string;
  contactId: string;
  outreachActivities: IFormattedContactOutreachActivity[];
  offset: number;
  loading: boolean;
  showMoreLoading: boolean;
  totalActivityCount: number;
  showMoreActivity: boolean;
  id?: string;
  isBillable?: boolean;
  durationInSeconds?: number;
  syncToEhr?: boolean;
  contactCareProgramId?: string;
  conductedByUser?: IInternalUsers;
}

export const useContactOutreachActivity = (
  params: IUseContactOutreachActivity
) => {
  const LIMIT = 10;
  const mlovData = React.useContext(CommonDataContext);
  const isCareManagement = Boolean(params.isCareManagement);
  const loggedInUserUuid = getUserUUID();
  const {categoryWiseList, outreachStepOutComes} = useOutreachStepOutComes();
  const { careProgramTypesMlov, activeCareProgramData, getContactCareProgramId, getContactCareProgramAssigneeId } = useActiveCareProgramTypes({ contactUuid: params.contactUuid });
  const { previousRecordedTime } = UseTimeLogForBilling({
    contactCareProgramId: params.contactCareProgramId
  });
  const [stopwatchSeconds, setStopwatchSeconds] = React.useState(0);
  const [addOrUpdateContactOutreachActivity] = useMutation(
    CreateContactOutreachActivity,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );
  const [getInternalUsersDataByUUIDs] = useLazyQuery(
    InboxQueries.GetInternalUsersByUUIDs,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const {getPatientAdditionalData} = useCareProgramDashboardData();

  const [getUserByIds] = useLazyQuery(GET_USERS_WITH_IDS, {
    fetchPolicy: 'no-cache',
  });

  const outreachStatus = useOutreachStatus();

  const outreachModeMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_OUTREACH_MODE
    ) || [];
  
  const careProgramTypeList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_TYPES
  ) || [];

  const manualOutreachModeType = outreachModeMlov?.find((mlov) => {
    if (mlov.code === CARE_PROGRAM_OUTREACH_MODE_CODES.MANUAL) return true;
  });


  const outreachCommunicationModes = useMemo(
    () =>
      getMlovListFromCategory(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.CONTACT_OUTREACH_COMMUNICATION_MODE
      ) || [],
    [mlovData.CARE_STUDIO_MLOV]
  );

  const defaultData = React.useRef<IContactOutreachActivityData>({
    statusId: outreachStatus?.pending || '',
    typeId: manualOutreachModeType?.id || '',
    communicationModeId: outreachCommunicationModes[0]?.id || '',
    outcomeId: '',
    note: '',
    outreachDateTime: getMomentObj(new Date()).toISOString(),
    careProgramTypeId: params.careProgramTypeId || careProgramTypesMlov[0]?.id || '',
    contactId: params.contactUuid,
    outreachActivities: [],
    offset: 0,
    loading: false,
    showMoreLoading: false,
    totalActivityCount: 0,
    showMoreActivity: false,
    id: undefined,
    isBillable: true,
    durationInSeconds: previousRecordedTime?.minutes ? previousRecordedTime?.minutes * 60 + previousRecordedTime?.seconds : 60,
    syncToEhr: !params?.isFold,
    contactCareProgramId: params.contactCareProgramId || getContactCareProgramId(params.careProgramTypeId || careProgramTypesMlov[0]?.id) || '',
  });

  const [data, setData] = React.useState<IContactOutreachActivityData>(
    defaultData.current
  );
  const [userDataLoading, setUserDataLoading] = React.useState(false);

  const isCCMProgram = useMemo(() =>
  {
    const ccmCareProgramTypeId = getMlovIdFromCode(careProgramTypesMlov, CARE_PROGRAM_TYPES.CCM);
    return data?.careProgramTypeId ? data?.careProgramTypeId === ccmCareProgramTypeId : false;
  },
    [data?.careProgramTypeId, careProgramTypesMlov]
  );

  const isECMCareProgram = useMemo(() => {
    const ecmCareProgramTypeId = getMlovIdFromCode(
      careProgramTypeList,
      CARE_PROGRAM_TYPES.ECM
    );
    return data?.careProgramTypeId
      ? data?.careProgramTypeId === ecmCareProgramTypeId
      : false;
  }, [data?.careProgramTypeId, careProgramTypeList]);

  const resetToInitialState = () => {
    setData({
      ...defaultData.current,
      careProgramTypeId: params.careProgramTypeId || careProgramTypesMlov[0]?.id,
      contactId: params.contactUuid,
      contactCareProgramId: params.contactCareProgramId || getContactCareProgramId(params.careProgramTypeId || careProgramTypesMlov[0]?.id) || '',
    });
    setActiveMode(PatientNotesLogModeOfContact.IN_PERSON);
    clearDataAndFetchActivities();
  };

  const getDefaultConductedByUser = async () => {
    try {
      setUserDataLoading(true);
      let defaultConductedByUserId =
        params.contactCareProgramAssigneeId ||
        getContactCareProgramAssigneeId(
          params.careProgramTypeId || careProgramTypesMlov[0]?.id
        );
      if (!defaultConductedByUserId) {
        const careManagerData: IKeyPointData[] =
          (await getPatientAdditionalData({
            contactUuid: params.contactUuid,
            additionalDataCodes: [ADDITIONAL_DATA_CODE.CARE_MANAGER],
          })) as IKeyPointData[];
        defaultConductedByUserId =
          careManagerData[0]?.referenceData?.userId || loggedInUserUuid;
      }
      const internalUsersData: any = await getInternalUsersDataByUUIDs({
        variables: {
          userUuids: [defaultConductedByUserId],
        },
      });
      setData((prev) => {
        return {
          ...prev,
          conductedByUser: internalUsersData.data?.accountUsers[0],
        };
      });
      setUserDataLoading(false);
    } catch (error) {
      setUserDataLoading(false);
    }
    return null;
  };

  useEffect(() => {
    if (isECMCareProgram) {
      getDefaultConductedByUser();
    }
  }, [isECMCareProgram]);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();

    const handleOutreachEvent = async (event: any) => {
      if (event.syncCode === 'record-as-outreach') {
        resetToInitialState();
      }
    };
    eventBus.addEventListener(
      CARE_PROGRAM_EVENTS.RECORD_AS_OUTREACH,
      handleOutreachEvent
    );
    return () => {
      eventBus.removeEventListener(handleOutreachEvent);
    };
  }, []);

  useEffect(() => {
    if (data.careProgramTypeId) {
      clearDataAndFetchActivities();
    }
  }, [data.careProgramTypeId]);

  useEffect(() => {
    if (careProgramTypesMlov.length > 0 && !data?.careProgramTypeId) {
      setData(prev => ({
        ...prev,
        careProgramTypeId: careProgramTypesMlov[0]?.id,
        contactCareProgramId: getContactCareProgramId(careProgramTypesMlov[0]?.id) || '',
      }));
    }
  }, [careProgramTypesMlov.length]);

  useEffect(() => {
    if (previousRecordedTime?.minutes) {
      setData(prev => ({
        ...prev,
        durationInSeconds: previousRecordedTime?.minutes * 60 + previousRecordedTime?.seconds
      }));
    }
  }, [previousRecordedTime]);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    const handleUpdateRecordedTime = (data: { seconds: number }) => {
      setData(prev => ({
        ...prev,
        durationInSeconds: data.seconds
      }));
      setStopwatchSeconds(data.seconds);
    };

    eventBus.addEventListener(CARE_PROGRAM_EVENTS.UPDATE_RECORDED_TIME, handleUpdateRecordedTime);
    return () => {
      eventBus.removeEventListener(handleUpdateRecordedTime);
    };
  }, [stopwatchSeconds]);

  const clearDataAndFetchActivities = () => {
    setData((prev) => {
      return {
        ...prev,
        outreachActivities: [],
      };
    });
    fetchActivities(0);
  };

  const [activeMode, setActiveMode] = React.useState<
    PatientNotesLogModeOfContact | string
  >(
    outreachCommunicationModes[0]?.code || PatientNotesLogModeOfContact.GENERAL
  );

  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);
  const userData = getUserData();

  const validateForm = (newData: IContactOutreachActivityData) => {
    let valid: boolean;
    if (newData?.note?.length && newData?.outcomeId?.length) {
      valid = true;
    } else {
      valid = false;
    }

    return valid;
  };

  const [getOutreachActivity] = useLazyQuery(GetContactOutreachActivities, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const resetData = () => {
    setData((prev) => {
      return {
        ...defaultData.current,
        careProgramTypeId: prev.careProgramTypeId,
        contactCareProgramId: prev.contactCareProgramId,
        outreachActivities: prev.outreachActivities,
        outreachDateTime: getMomentObj(new Date()).toISOString(),
        communicationModeId: outreachCommunicationModes[0]?.id || '',
      };
    });
    setActiveMode(outreachCommunicationModes[0]?.code || PatientNotesLogModeOfContact.GENERAL);
  };

  const fetchMoreActivities = () => {
    fetchActivities();
  };

  const getOutreachActivityWhereCondition = () => {
    const whereCondition = {
      contactId: {_eq: params.contactUuid},
      careProgramTypeId: {
        _eq: params.careProgramTypeId || data.careProgramTypeId,
      },
      ...(params.contactCareProgramId && {
        careProgramSteps: {
          contactCareProgram: {
            id: {
              _eq: params.contactCareProgramId,
            },
          },
        },
      }),
    };
    return whereCondition;
  }

  const fetchActivities = async (offset?: number) => {
    try {
      setData((prev) => ({
        ...prev,
        loading: true,
      }));
      const currentOffset = offset !== undefined ? offset : data.offset;
      const outreachActivityResponse = await getOutreachActivity({
        variables: {
          whereCondition: getOutreachActivityWhereCondition(),
          limit: LIMIT,
          offset: currentOffset,
        },
      });

      const availableUsers =
        outreachActivityResponse?.data?.ContactOutreachActivities?.map(
          (item: any) => item.createdBy
        );

      const totalCount =
        outreachActivityResponse?.data?.aggregateContactOutreachActivity
          ?.aggregate?.count;

      const userResponse = availableUsers?.length
        ? await getUserByIds({
            variables: {
              userIds: availableUsers,
            },
          })
        : undefined;

      const userMap = CareProgramStepOutreachUtils.getUserMapByUUID(
        userResponse?.data?.users || [],
        userData
      );

      const formattedData = formatOutreachData(
        mlovData,
        outreachActivityResponse?.data?.ContactOutreachActivities,
        userMap
      );

      setData((prev) => {
        const updatedActivities = [
          ...prev.outreachActivities,
          ...formattedData,
        ];
        return {
          ...prev,
          outreachActivities: updatedActivities,
          offset: currentOffset + LIMIT,
          loading: false,
          showMoreActivity:
            totalCount > LIMIT && updatedActivities.length < totalCount,
        };
      });
    } catch (error) {
      setData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
     }
  };



  const saveActivity = async () => {
    setSaveButtonLoading(true);
    try {
      const payload: AddOrUpdateContactOutreachActivityInput = {
        id: data.id || undefined,
        statusId: data.statusId,
        typeId: data.typeId,
        communicationModeId: data.communicationModeId,
        outcomeId: data.outcomeId,
        note: data.note,
        outreachDateTime: data.outreachDateTime,
        careProgramTypeId: data.careProgramTypeId,
        contactId: params.contactUuid,
        performedBy: data.conductedByUser?.user?.uuid || loggedInUserUuid,
        additionalAttributes: [
          {
            attributeKey: ADDITIONAL_ATTRIBUTE_KEY.SYNC_TO_EHR,
            attributeValue: {
              valueBoolean: data.syncToEhr || false
            },
            attributeValueDataTypeCode: 'boolean',
            isDeleted: false
          },
        ],
        careProgramStepPayload: {
          contactCareProgramId: data.contactCareProgramId || '',
        }
      };
      if (isCCMProgram) {
        payload.additionalAttributes = payload.additionalAttributes?.concat([{
          attributeKey: ADDITIONAL_ATTRIBUTE_KEY.IS_BILLABLE,
          attributeValue: {
            valueBoolean: data.isBillable || false
          },
          attributeValueDataTypeCode: 'boolean',
          isDeleted: false
        },
        {
          attributeKey: ADDITIONAL_ATTRIBUTE_KEY.DURATION_IN_SECONDS,
          attributeValue: {
            valueNumeric: data.durationInSeconds
          },
          attributeValueDataTypeCode: 'numeric',
          isDeleted: false
        }]);
      }
      const eventBus = EventBus.getEventBusInstance();
      const response = params.customOnSaveHandler && isCareManagement
        ? await params.customOnSaveHandler(payload)
        : await addOrUpdateContactOutreachActivity({
            variables: {
              data: payload,
            },
          });
        setSaveButtonLoading(false);

      if (response?.data?.addOrUpdateContactOutreachActivity?.id) {
        eventBus.broadcastEvent(CARE_PROGRAM_EVENTS.RESET_TIMER, {syncCode: CARE_PROGRAM_EVENT_CODES.RESET_TIMER});
        resetData();
        clearDataAndFetchActivities();
      }
      await params.onSaveSuccess?.(response);
    } catch (error) {
      setSaveButtonLoading(false);
    }
  };


  const deleteOutreachActivity = async () => {
    const payload: AddOrUpdateContactOutreachActivityInput = {
      id: data.id || undefined,
      statusId: data.statusId,
      typeId: data.typeId,
      communicationModeId: data.communicationModeId,
      outcomeId: data.outcomeId,
      note: data.note,
      outreachDateTime: data.outreachDateTime,
      careProgramTypeId: data.careProgramTypeId,
      contactId: params.contactUuid,
      isDeleted: true,
    };

    const response =  await addOrUpdateContactOutreachActivity({
          variables: {
            data: payload,
          },
        });

    if (response?.data?.addOrUpdateContactOutreachActivity?.id) {
      resetData();
      clearDataAndFetchActivities();
    }
  };

  const handleCareProgramTypeChange = useCallback((value: string) => {
    setData((prev) => {
      return {...prev, careProgramTypeId: value};
    });
  }, []);

  // Note: This is done to prefill the form for outreach when editing an old activity
  const handleEditOutreachActivity = useCallback(
    (outreachActivity: IFormattedContactOutreachActivity) => {
      const communicationMode = outreachCommunicationModes.find(
        (mlov) => mlov.code === outreachActivity?.communicationMode
      );

      const isBillable = outreachActivity?.outreachData?.additionalAttributes?.find(
        (attr: any) => attr.attributeKey === ADDITIONAL_ATTRIBUTE_KEY.IS_BILLABLE
      )?.attributeValue?.valueBoolean ?? true;

      const syncToEhr = outreachActivity?.outreachData?.additionalAttributes?.find(
        (attr: any) => attr.attributeKey === ADDITIONAL_ATTRIBUTE_KEY.SYNC_TO_EHR
      )?.attributeValue?.valueBoolean ?? false;

      const durationInSeconds = outreachActivity?.outreachData?.additionalAttributes?.find(
        (attr: any) => attr.attributeKey === ADDITIONAL_ATTRIBUTE_KEY.DURATION_IN_SECONDS
      )?.attributeValue?.valueNumeric ?? 0;

      setData((prev) => ({
        ...prev,
        outreachDateTime: outreachActivity.outreachData.outreachDateTime,
        communicationModeId: communicationMode?.id as string,
        outcomeId: outreachActivity?.outcomeMlov?.id,
        note: outreachActivity.note,
        id: outreachActivity.id,
        isBillable,
        durationInSeconds,
        syncToEhr
      }));
      setActiveMode(communicationMode?.code as PatientNotesLogModeOfContact);
      // Note: This is done to select the relevant step in the care management view sidebar
      if (params.selectStepWhenEditingOldActivity) {
        params.selectStepWhenEditingOldActivity(outreachActivity.resourceId);
      }
    },
    [params.selectStepWhenEditingOldActivity]
  );

  return {
    data,
    careProgramTypesMlov,
    outreachCommunicationModes,
    saveButtonDisabled: validateForm(data) === false,
    saveButtonLoading,
    activeMode,
    userDataLoading,
    isECMCareProgram,
    setActiveMode,
    fetchActivities,
    setData,
    saveActivity,
    validateForm,
    resetData,
    outreachActivities: data.outreachActivities,
    fetchMoreActivities,
    loading: data.loading,
    showMoreActivity: data.showMoreActivity,
    handleCareProgramTypeChange,
    hideCareProgramType: params.hiddenFields?.includes('careProgramTypeId'),
    isCareManagement,
    handleEditOutreachActivity,
    isUpdate: Boolean(data.id),
    clearDataAndFetchActivities,
    deleteOutreachActivity
  };
};
