import {CONTACT_CARE_PROGRAM_STATUS_CODES, CONTACT_CARE_PROGRAM_STATUS_VALUES} from '../../../../constants/MlovConst';
import {
  IStepsLog
} from '../../../../services/ContactCareProgram/interface';
import {getStepsForCalculatingNextActiveStep} from '../../CareManagementView/StepUtil';
import {
  IContactCareProgramState
} from '../interface';
import {
  getInitSelectedStepAndCollapseIds,
  getNewAdditionalAttributes,
} from './ContactCareProgramView.utils';

export enum ContactCareProgramReducerAction {
  SET_ACTIVE_STEP_ID = 'SET_ACTIVE_STEP_ID',
  SET_SELECTED_STEP_ID = 'SET_SELECTED_STEP_ID',
  SET_CONTACT_CARE_PROGRAM_DETAILS = 'SET_CONTACT_CARE_PROGRAM_DETAILS',
  SET_STATUS = 'SET_STATUS',
  EXPAND_SIDEBAR_STEP = 'EXPAND_SIDEBAR_STEP',
  COLLAPSE_SIDEBAR_STEP = 'COLLAPSE_SIDEBAR_STEP',
  SET_GROUPED_STEPS = 'SET_GROUPED_STEPS',
  ADD_SUB_STEP = 'ADD_SUB_STEP',
  REMOVE_SUB_STEP = 'REMOVE_SUB_STEP',
  SET_INIT_DATA = 'SET_INIT_DATA',
  UPDATE_STEP_STATUS = 'UPDATE_STEP_STATUS',
  SET_STEP_PROGRESS_MAP = 'SET_STEP_PROGRESS_MAP',
  UPDATE_CARE_PROGRAM_STATUS = 'UPDATE_CARE_PROGRAM_STATUS',
  UPDATE_CARE_PROGRAM_ASSIGNEE = 'UPDATE_CARE_PROGRAM_ASSIGNEE',
  UPDATE_CARE_PROGRAM_STEP_ADDITIONAL_ATTRIBUTES = 'UPDATE_CARE_PROGRAM_STEP_ADDITIONAL_ATTRIBUTES',
  SHOW_COMPLETED_VIEW = 'SHOW_COMPLETED_VIEW',
  UPDATE_CARE_PROGRAM_COMPLEXITY = 'UPDATE_CARE_PROGRAM_COMPLEXITY',
  SET_FORM_SCORE = 'SET_FORM_SCORE',
}

export interface IContactCareProgramReducerAction {
  type: keyof typeof ContactCareProgramReducerAction;
  payload: any;
}

export enum CustomCareProgramStatus {
  COMPLETED = 'COMPLETED',
}

export type ContactCareProgramReducer = (
  state: IContactCareProgramState,
  action: IContactCareProgramReducerAction
) => IContactCareProgramState;

export const contactCareProgramReducer = (
  state: IContactCareProgramState,
  action: IContactCareProgramReducerAction
) => {
  switch (action.type) {
    case ContactCareProgramReducerAction.SET_ACTIVE_STEP_ID:
      return {...state, activeStepId: action.payload};
    // MAIN VIEW DEPENDENT STATE
    case ContactCareProgramReducerAction.SHOW_COMPLETED_VIEW:
      return {...state, selectedStepId: CustomCareProgramStatus.COMPLETED};
    case ContactCareProgramReducerAction.SET_SELECTED_STEP_ID:
      return {...state, selectedStepId: action.payload};
    case ContactCareProgramReducerAction.SET_CONTACT_CARE_PROGRAM_DETAILS:
      return {...state, contactCareProgramDetails: action.payload};
    case ContactCareProgramReducerAction.SET_STATUS:
      return {...state, status: action.payload};
    case ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STATUS:
      const newStatusId = action?.payload?.newStatusId || action.payload;
      const statusCode = action?.payload?.statusCode || '';
      const statusValue = statusCode ? CONTACT_CARE_PROGRAM_STATUS_VALUES[statusCode] : '';
      return {
        ...state,
        contactCareProgramDetails: {
          ...state.contactCareProgramDetails,
          statusId: newStatusId,
          ...(statusCode ? {status: {code: statusCode, value: statusValue}} : {}),
        } as IContactCareProgramState['contactCareProgramDetails'],
      };
    case ContactCareProgramReducerAction.EXPAND_SIDEBAR_STEP:
      return {
        ...state,
        sideBarExpandedStepKeysSet: new Set([
          ...state.sideBarExpandedStepKeysSet,
          action.payload,
        ]),
      };
    case ContactCareProgramReducerAction.COLLAPSE_SIDEBAR_STEP:
      return {
        ...state,
        sideBarExpandedStepKeysSet: new Set(
          [...state.sideBarExpandedStepKeysSet].filter(
            (key) => key !== action.payload
          )
        ),
      };
    case ContactCareProgramReducerAction.SET_GROUPED_STEPS:
      return {...state, groupedSteps: action.payload};
    case ContactCareProgramReducerAction.SET_INIT_DATA:
      const newState = action.payload as IContactCareProgramState;
      return {
        ...state,
        ...newState,
      };

    case ContactCareProgramReducerAction.UPDATE_STEP_STATUS:
      const {newStepLogStatusId, id, statusesToCalculateNextStep, skipMoveToNextStep} =
        action.payload;
      // Find the existing step log entry
      const existingStepLogEntry =
        state?.contactCareProgramDetails?.stepsLog?.find(
          (item) => item.careProgramStepId === id
        );
      if (existingStepLogEntry) {
        // Create a new step log entry with new status
        const newStepLogEntry: IStepsLog = {
          ...existingStepLogEntry,
          careProgramStepStatusId: newStepLogStatusId,
        };
        // Update the steps log with the new step log entry
        const newStepsLog = state?.contactCareProgramDetails?.stepsLog?.map(
          (item) => (item.careProgramStepId === id ? newStepLogEntry : item)
        );

        // MOVE TO NEXT STEP
        let initSelectedStepId: string | undefined;
        let expandStepIds: string[] | undefined;

        if (!skipMoveToNextStep) {

          const stepsForCalculatingNextActiveStep = getStepsForCalculatingNextActiveStep(
            state?.groupedSteps || [],
            id
          );

          const nextStepConfig = getInitSelectedStepAndCollapseIds(
            stepsForCalculatingNextActiveStep,
            newStepsLog || [],
            statusesToCalculateNextStep
          );

          initSelectedStepId = nextStepConfig.initSelectedStepId;
          expandStepIds = nextStepConfig.expandStepIds;
        }

        // Return the new state with the updated steps log
        return {
          ...state,
          contactCareProgramDetails: {
            ...state.contactCareProgramDetails,
            stepsLog: newStepsLog,
          },
          ...(initSelectedStepId ? {selectedStepId: initSelectedStepId} : {}),
          ...(expandStepIds?.length
            ? {sideBarExpandedStepKeysSet: new Set(expandStepIds)}
            : {}),
        };
      }
      return state;
    case ContactCareProgramReducerAction.SET_STEP_PROGRESS_MAP:
      const {id: stepId, progress} = action.payload;
      return {
        ...state,
        stepProgressMap: state?.stepProgressMap?.set(stepId, progress),
      };

    case ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_ASSIGNEE:
      return {
        ...state,
        contactCareProgramDetails: {
          ...state.contactCareProgramDetails,
          assigneeId: action.payload,
        },
      };
    case ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STEP_ADDITIONAL_ATTRIBUTES:
      const {additionalAttribute, subjectTypeCode} = action.payload;
      let newSteps = state?.contactCareProgramDetails?.contactCareProgramSteps;
      if (additionalAttribute) {
        switch (subjectTypeCode) {
          case 'CARE_PROGRAM_STEP':
            newSteps =
              state?.contactCareProgramDetails?.contactCareProgramSteps?.map(
                (step) => {
                  if (step.id === additionalAttribute.subjectId) {
                    return {
                      ...step,
                      careProgramStepAdditionalAttributes:
                        getNewAdditionalAttributes(
                          step.careProgramStepAdditionalAttributes,
                          additionalAttribute
                        ),
                    };
                  }
                  return step;
                }
              );
            break;
        }
        return {
          ...state,
          contactCareProgramDetails: {
            ...state.contactCareProgramDetails,
            contactCareProgramSteps: newSteps,
          },
        };
      }

      return state;

    case ContactCareProgramReducerAction.ADD_SUB_STEP:
      // Push in groupedSteps
      const newGroupedSteps = state?.groupedSteps?.map((groupedStep) => {
        if (groupedStep.id === action.payload.parentStepId) {
          return {
            ...groupedStep,
            subSteps: [...(groupedStep.subSteps || []), action.payload],
          };
        }
        return groupedStep;
      });
      // push in contactCareProgramSteps
      const newContactCareProgramSteps = [
        ...(state?.contactCareProgramDetails?.contactCareProgramSteps || []),
        action.payload,
      ];
      return {
        ...state,
        groupedSteps: newGroupedSteps,
        contactCareProgramDetails: {
          ...state.contactCareProgramDetails,
          contactCareProgramSteps: newContactCareProgramSteps,
        },
      } as IContactCareProgramState;

    case ContactCareProgramReducerAction.REMOVE_SUB_STEP:
      const newFilteredGroupedSteps = state?.groupedSteps?.map((groupedStep) => {
        if (groupedStep.id === action?.payload?.parentStepId) {
          const filteredSteps = groupedStep.subSteps?.filter(subStep => subStep.id !== action.payload.id) || [];
          return {
            ...groupedStep,
            subSteps: filteredSteps,
          };
        }
        return groupedStep;
      });
      const newFilteredContactCareProgramSteps = state?.contactCareProgramDetails?.contactCareProgramSteps?.filter(step => step.id !== action.payload.id) || [];
      return {
        ...state,
        groupedSteps: newFilteredGroupedSteps,
        contactCareProgramDetails: {
          ...state.contactCareProgramDetails,
          contactCareProgramSteps: newFilteredContactCareProgramSteps,
        },
      } as IContactCareProgramState;

    case ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_COMPLEXITY:
      return {
        ...state,
        contactCareProgramDetails: {
          ...state.contactCareProgramDetails,
          complexity: action.payload,
        },
      };
    case ContactCareProgramReducerAction.SET_FORM_SCORE: {
      const payload = action.payload;
      const scores = payload.scores;
      return {
        ...state,
        formScoreMap: state?.formScoreMap?.set(payload.id, scores),
      };
    }
    default:
      return state;
  }
};
