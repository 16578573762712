import React, { useEffect, useContext, useState } from 'react';
import {Collapse, Divider} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {
  Pressable,
  ScrollView,
  Text,
  useMediaQuery,
  View,
  VStack,
} from 'native-base';
import {Dimensions} from 'react-native';
import {
  CARE_PROGRAM_WIDGET_ALLOWED_CONTACT_TYPE_CODES,
  GROUP_MEMBER_TYPE,
  MLOV_CATEGORY,
  PARENT_CODE,
  PERSON_TYPES,
  SMALL_WINDOW_1400
} from '../../../../../constants';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES, PATIENT_QUICK_PROFILE_PARENT_CODES} from '../../../../../constants/ActionConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {Colors} from '../../../../../styles';
import {
  getSecondaryValue,
  isEmployerRole,
  IS_FEATURE_FLAG_ENABLED,
  isEnableCareProgram,
  isInsuranceEnable,
  isMasterAccount,
  getPatientLocationUuidFromPracticeLocations,
  getCaslAbility,
  isSideCarEnabledForPatientDashboard,
  isMemberStatusEnabled,
} from '../../../../../utils/commonUtils';
import {EventBus} from '../../../../../utils/EventBus';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../utils/mlovUtils';
import ContactNotesView from '../../../../common/ContactNotesView/ContactNotesView';
import {ContactRelationView} from '../../../../common/ContactRelationView';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import GroupListPanel from '../../../../common/GroupList/GroupListPanel';
// import { TrackedDataView } from '../../../../PersonOmniView/LeftContainer/OtherDetails/TrackedDataView.web';
import {PersonActions} from '../../../../common/PersonActions/PersonActions';
import PrivateMessageView from '../../../../common/PrivateMessageView/PrivateMessageView';
import SubscriptionView from '../../../../common/SubscriptionView/SubscriptionView';
import TaskIconOther from '../../../../common/Svg/TaskIconOther';
import ContactDetailView from '../../../../PersonOmniView/LeftContainer/OtherDetails/ContactDetailView';
import {DevicePropertiesView} from '../../../../PersonOmniView/LeftContainer/OtherDetails/DevicePropertiesView.web';
import {PatientAppointmentDataView} from '../../../../PersonOmniView/LeftContainer/OtherDetails/PatientAppointmentDataView';
import StickyNotes from '../../../../PersonOmniView/LeftContainer/OtherDetails/StickyNotes';
import {TagDataListView} from '../../../../PersonOmniView/LeftContainer/OtherDetails/TagDataListView';
import EntityEventsListView from '../../../../PersonOmniView/LeftContainer/PamiDetail/EntityEventsListView';
import {PERSON_ACTION_CODES} from '../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import CareTeamView from '../../../../PersonOmniView/RightContainer/CareTeamView/CareTeamView';
import {MemberGroupList} from '../../../../PersonOmniView/RightContainer/MemberGroupList';
import {MAIN_MENU_CODES, USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE} from '../../../../SideMenuBar/SideBarConst';
import ConversationActionView from '../ConversationActionView/ConversationActionView';
import {
  IContact,
  IContactType,
  IConversationData,
  ICustomerDetailsProps,
} from '../interfaces';
import {isGroupConversation} from '../MessagingWindow/MessagingUtils';
import ContactTimelineView from './ContactDetailsTabs/ActivityTimeLine/ContactTimelineView/ContactTimelineView';
import {DetailsPanelReadOnly} from './DetailsPanelReadOnly';
import {reactStyles, styles} from './MessagingContactDetailsStyles';
import ProfileDetailView from './ProfileDetailView';
import {TaskDetailPanel} from './TaskDetailPanel';
import TaskPanel from './TaskPanel';
import { useNavigate } from 'react-router-dom';
import CareJourneyDetailsView from '../../../../common/CareJourneyDeatilsView/CareJourneyDetailsView';
import { isPatientDetailViewAllowed, USER_ACCESS_PERMISSION } from '../../../UserAccess/UserAccessPermission';
import { TaskPanelType } from '../../../../TaskCard/TaskEnum';
import { getDeceasedDate } from '../../../../../services/CommonService/AidBoxService';
import { usePrevious } from 'react-use';
import { checkAbilityAccess, checkAccountConfigAbilityAccess, getEhrConfig } from '../../../../../utils/capabilityUtils';
import { getOrgAbilites } from '../../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../../constants/FhirConstant';
import { useIntl } from 'react-intl';
import InsuranceView from '../../../../Insurance';
import CareProgramPanel from '../../../../common/CareProgramPanel/CareProgramPanel';
import Stack from '../../../../common/LayoutComponents/Stack';
import NoteSvgIcon from '../../../../common/Svg/NoteSvgIcon';

import FoldPermitCan from '../../../../CommonComponents/FoldPermitCan/FoldPermitCan';
import { SidecarInsightsDrawer } from '../../../../SideCar/SidecarInsightsDrawer';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { testID, TestIdentifiers } from '../../../../../testUtils';


interface customerDetailsProps {
  currentCustomer: ICustomerDetailsProps;
  contactData: IContact;
  conversationData?: IConversationData;
  onActionPerformed?: (
    actionCode: string,
    selectedData: unknown,
    isRank2?: boolean
  ) => {};
  isPatientDetailView?: boolean;
  contactType?: IContactType;
  borderLessView?: boolean;
  height?: number;
  extra?: any;
  showEditProfileOnPatientInfoBanner?: boolean;
  parentCode: string;
  hintExtraDetail?: any;
  showDetailProfile?: boolean;
  btnTextVisibility?: any;
  showCloseDrawerIcon?: boolean;
  patientHieSyncCall?: boolean;
  isEmployerDrillDown?: boolean;
}
// {
//   showEhrBtn?: boolean;
//   showMeetBtn?: boolean;
//   showChatBtn?: boolean;
//   showPhoneBtn?: boolean;
//   shouldShowBtn?: boolean;
//   showAppointmentBtn?: boolean;
//   showChartBtn?: boolean;
//   showSmsBtn?: boolean;
//   showEmailBtn?:boolean;
// };
export const DetailsPanel = (props: customerDetailsProps) => {
  const {height} = Dimensions.get('window');
  const {Panel} = Collapse;
  const commonData = useContext(CommonDataContext);
  const userSettings = commonData.userSettings;
  const allowInsurance = isInsuranceEnable(userSettings);
  const isMasterAccountFlag = isMasterAccount();
  const {contactData, conversationData, borderLessView, parentCode, contactType} = props;
  const intl = useIntl();
  const eventBus = EventBus.getEventBusInstance();
  const sideCarDashboardEnabled = isSideCarEnabledForPatientDashboard(userSettings);
  const memberStatusEnabled = isMemberStatusEnabled();
  const navigate = useNavigate();
  const isPatientDetailViewAllowedConfig = isPatientDetailViewAllowed();

  const isGroupMessage = () => {
    if (conversationData) {
      return isGroupConversation(conversationData);
    }
    return false;
  };
  const [stateData, setStateData] = useState({
    showSubscriptionData: contactData?.uuid ? true : false,
  });

  const [activeTab, setActiveTab] = useState(isEmployerRole() ? 2 : 0);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const [isSmallWindow] = useMediaQuery([
    { maxWidth: SMALL_WINDOW_1400 },
  ]);
  const isSmallScreen = isSmallWindow && !isSideCarContext;

  const mlovData = useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const [shouldShowApointment, showAppointment] = useState(false);
  const [shouldShowAddNotes, showAddNotes] = useState(false);
  const [deceasedDate, setDeceasedDate] = useState('');
  const showPatientTaskPanel = contactData?.patient !== null;
  const patientLocationUuid = getPatientLocationUuidFromPracticeLocations(contactData?.contactPracticeLocations);
  const isHIEEnabled = isAccountConfigEnabled(CONFIG_CODES.HIE_ENABLE);

  const canPatientUpdate = checkAccountConfigAbilityAccess(FHIR_RESOURCE.PATIENT, 'canUpdate')?.isEnabled;

  const onGroupListActionPerformed = (actionCode: string, actionData: any) => {
    if (props?.onActionPerformed) {
      props?.onActionPerformed(actionCode, actionData);
    }
  };
  const isCompactView = props.parentCode === PATIENT_QUICK_PROFILE_PARENT_CODES.MESSAGING;

  const getGroupListElemWithoutConversation = () => {
    let groupListElem = <View></View>;
    if (!contactData?.uuid) {
      return groupListElem;
    }
    const groupMemberTypeList =
      getMlovListFromCategory(mlovData.MLOV, MLOV_CATEGORY.GroupMemberType) ||
      [];
    const contactTypeId = getMlovIdFromCode(
      groupMemberTypeList,
      GROUP_MEMBER_TYPE.PATIENT
    );
    const groupMemberData = {
      groupUserId: contactData?.uuid,
      groupUserTypeId: contactTypeId,
    };
    groupListElem = (
      <GroupListPanel
        isDefaultClose={false}
        groupMemberData={groupMemberData as any}
        onGroupListActionPerformed={onGroupListActionPerformed}
        parentCode={PARENT_CODE.MESSAGING_DETAIL_PANEL}
        style={styles.groupListPanel}
        shouldDisableLimit={true}
        contactData={contactData}
        conversationData={conversationData}
      />
    );

    return groupListElem;
  };

  const handleEdit = (actionCode: string) => {
    if (props?.contactType?.code === PERSON_TYPES.CUSTOMER && !canPatientUpdate){
      return;
    }
    if (props.onActionPerformed) {
      let newContactData = contactData;
      if (actionCode === COMMON_ACTION_CODES.CONSENT_WARNING) {
        newContactData = {...contactData, showConsentError: true};
      }
      props.onActionPerformed(COMMON_ACTION_CODES.EDIT, newContactData);
    }
  };

  const handleEmail = (isRank2: boolean) => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.MAIL, contactData, isRank2);
    }
  };
  const handleSMS = (data: any) => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.SMS, contactData);
    }
  };
  const handleCloudCall = (
    actionCode: string,
    contactData: IContact,
    fromNumber: string,
    toNumber?: string
  ) => {
    if (actionCode === COMMON_ACTION_CODES.CLOUD_CALL) {
      eventBus.broadcastEvent('CLOUD_CALL', {
        contactData: contactData,
        fromNumber: fromNumber,
        toNumber: toNumber,
      });
    }
  };
  const ehrConfig = getEhrConfig(patientLocationUuid, '');
  const handleCall = () => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.CALL, contactData);
    }
  };
  const canShowMembership = () => {
    return IS_FEATURE_FLAG_ENABLED('ENABLE_MEMBERSHIP');
  };



  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        showSubscriptionData: contactData?.uuid ? true : false,
      };
    });
  }, [props.contactData]);

  const renderTaskContent = () => {
    if (!conversationData) {
      return;
    }
    return (
      <VStack style={styles.panelBox}>
        <View
          style={[
            {
              backgroundColor: '#ffffff',
              marginHorizontal: 24,
              borderRadius: 8,
              // @ts-expect-error: Let's ignore a compile error like this unreachable code
              boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
              paddingHorizontal: 16,
              paddingVertical: 8,
            },
          ]}
        >
          <TaskDetailPanel
            contactData={contactData}
            conversationData={conversationData}
          />
        </View>
      </VStack>
    );
  };

  const renderCarePrograms = () => {
    const showCareProgramWidget = isEnableCareProgram(mlovData.userSettings) && CARE_PROGRAM_WIDGET_ALLOWED_CONTACT_TYPE_CODES.includes(contactData?.contactType?.contactType?.code);
    const contactId = contactData.uuid;
    if (!showCareProgramWidget || !contactId) {
      return <></>;
    }
    return (
      <VStack style={styles.panelBox}>
        <Stack direction='row' style={styles.careProgramStack}>
          <View style={styles.marginRight}>
            <NoteSvgIcon />
          </View>
          <Text size={'lgMedium'} color={Colors.FoldPixel.GRAY300}>
            {'Care Programs'}
          </Text>
        </Stack>
        <View style={styles.careProgramContent}>
          <CareProgramPanel
            contactId={contactId}
            contact={contactData}
            contactType={contactType || {} as IContactType}
            locationId={patientLocationUuid}
          />
        </View>
      </VStack>
    );
  };

  const renderConversationDetails = () => {
    return (
      <VStack style={styles.panelBox}>
        <View style={styles.conversationDetailsContainer}>
          <DetailsPanelReadOnly contactData={contactData} conversationUuid={conversationData?.uuid} channelType={conversationData?.conversationInbox?.channelType}/>
        </View>
      </VStack>
    );
  };

  const privateMessage = () => {
    return (
      <PrivateMessageView
        isDefaultClose={false}
        conversationData={conversationData?.id}
        style={{
          marginBottom: 10,
          borderWidth: borderLessView ? 0 : 1,
        }}
      />
    );
  };

  const appointmentData = () => {
    return (
      <PatientAppointmentDataView
        style={styles.appointmentDataView}
        taggableType={'Contact'}
        contactId={contactData.id}
        contactData={contactData}
        shouldShowApointment={shouldShowApointment}
        onClose={() => {
          showAppointment(false);
        }}
        borderLessView={props?.borderLessView}
        showMore
      />
    );
  };
  const stickyNotesData = () => {
    return (
      <StickyNotes
        contactId={contactData?.id}
        contactUuid={contactData?.uuid}
        titleText={intl.formatMessage({id: 'stickyNote'})}
      />
    );
  };
  const contactNotesData = () => {
    return (
      <ContactNotesView
        isDefaultClose={false}
        contactId={contactData.id}
        style={styles.contactNotesView}
        shouldShowAddNotes={shouldShowAddNotes}
        onClose={() => {
          showAddNotes(false);
        }}
      />
    );
  };


  const getMemberGroupList = () => {
    return <MemberGroupList contactId={contactData?.id} />;
  };
  const getCareTeamView = () => {
    const CONTACT_TYPE_CUSTOMER = PERSON_TYPES.CUSTOMER;
    if (props.contactType?.code === CONTACT_TYPE_CUSTOMER) {
    return (
      <CareTeamView
        isDefaultClose={false}
        style={styles.createTeamView}
        contactData={contactData}
      />
    );
  }
  return <></>;
  };

  const getContactRelationView = () => {
    return (
      <ContactRelationView
        isDefaultClose={false}
        contactData={contactData}
        style={styles.marginBottom10AndWidth0}
        isCompactView={isCompactView}
      />
    );
  };

  const getInsuranceData = () => {
    return (
      <InsuranceView
        contactId={contactData?.id}
        patientId={contactData?.patient?.patientId}
        contactUuid={contactData?.uuid}
        patientLocationUuid={patientLocationUuid}
      />
    )
  }

  const getSubscriptionData = () => {
    return (
      <View>
        <SubscriptionView
          contactUuid={contactData.uuid || ''}
          isDefaultClose={false}
          style={styles.marginBottom10AndWidth0}
          stripeCusId={contactData?.contactAdditionalInfos?.stripeCustomerId}
        />
      </View>
    );
  };


  const getConversationInfoData = () => {
    return (
      <View style={styles.conversationInfoContainer}>
        <View style={styles.conversationInfoPadding}>
          <View style={styles.rowSpaceBetween}>
            <View style={styles.rowAlignCenter}>
              <View style={styles.iconMargin}>
                <TaskIconOther />
              </View>
              <DisplayText
                size={'lgMedium'}
                extraStyles={{color: Colors.FoldPixel.GRAY300 || ''}}
                textLocalId={'inboxDetails'}
              />
            </View>
          </View>
        </View>
        {renderConversationDetails()}
      </View>
    );
  };


  const renderBottomBox = () => {
    switch (activeTab) {
      case 0:
        return (
          <View>
            {stickyNotesData()}
            {showPatientTaskPanel && !isMasterAccountFlag &&
             <FoldPermitCan
             resource={MAIN_MENU_CODES.TASKS}
             action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
             component={<TaskPanel
              key={TaskPanelType.PATIENT}
              contactData={contactData}
              showDetailProfile={props.showDetailProfile}
              panelType={TaskPanelType.PATIENT}
            />}
             />
            }
            {!isMasterAccountFlag &&
            <FoldPermitCan
            resource={MAIN_MENU_CODES.TASKS}
            action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
            component={<TaskPanel
              key={TaskPanelType.INTERNAL}
              panelType={TaskPanelType.INTERNAL}
              contactData={contactData}
              showDetailProfile={props.showDetailProfile}
              />}
            />
          }
            <View>
              {!isGroupMessage() &&
                conversationData &&
                getConversationInfoData()}
            </View>
            <FoldPermitCan
            resource={MAIN_MENU_CODES.CALENDAR}
            action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
            component={appointmentData()}
            />
            {/* <CareTeam contactUuid={contactData.uuid || ''} /> */}
            {conversationData?.id ? privateMessage() : null}
            {contactNotesData()}
            {/* FOR FUTURE PURPOSE */}
            {props?.contactType?.code === 'CUSTOMER' && (
              <CareJourneyDetailsView contactData={contactData} />
            )}
            {getContactRelationView()}
            {getMemberGroupList()}
            {getCareTeamView()}
            {canShowMembership() && stateData.showSubscriptionData
              ? getSubscriptionData()
              : null}
            {!conversationData ? (
              <EntityEventsListView patient={contactData} />
            ) : null}
            {/* <RelatedConversationView
              style={ {marginBottom: 10}}
              contactData={contactData}
              conversationData={conversationData}
            /> */}
            {/* <TrackedDataView contactData={contactData} /> */}
            {!isGroupMessage() && conversationData && false && ( //due to absence of data hide the device info. May useful in future
              <DevicePropertiesView
                isDefaultClose={false}
                style={styles.marginBottom10AndWidth0}
                conversationData={conversationData}
              />
            )}
            {(allowInsurance) &&  contactData?.patient !== null &&  getInsuranceData()}
          </View>
        );
      case 1:
        return <View>{getGroupListElemWithoutConversation()}</View>;
      case 2:
        return (
          <View>
            <ContactDetailView
              hintExtraDetail={props.hintExtraDetail}
              isDefaultClose={false}
              contactData={contactData}
              onlyShowInnerContent={true}
              handleEmail={handleEmail}
              onActionPerformed={props?.onActionPerformed}
              handleCloudCall={handleCloudCall}
              handleEdit={handleEdit}
              navigate={navigate}
              contactType={props?.contactType}
            />
          </View>
        );
      case 3:
        return <ContactTimelineView contactData={contactData} />;
      default:
        return null;
    }
  };

  const shouldShowCommunication = () => {
    if (USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.USER.includes(
      MAIN_MENU_CODES.INBOX
    )) {
      return true;
    } else {
      return false
    }
  }

  const containerHeight = height - (props.patientHieSyncCall && isHIEEnabled ? 310 : 268);
  return (
    <Content className="common-collapse">
       <View style={[styles.container, borderLessView && styles.borderZero]}>
      {
        sideCarDashboardEnabled ?
          <SidecarInsightsDrawer
            contactId={contactData?.id.toString()}
            onClose={() => { }}
            isFoldView={false}
            selectedAction={''}
            isInsightsDrawerVisible={true}
            containerHeight={containerHeight}
            conversationData={conversationData}
            hideAlertFilterAndSearch={sideCarDashboardEnabled}
            parentCode={parentCode}
            showDetailProfile={props?.showDetailProfile}
            contactType={props?.contactType || contactData?.contactType?.contactType}
            sideCarDashboardEnabled={sideCarDashboardEnabled}
            patientProfile={true}
            showCharts={
              contactData?.contactType?.contactType?.code !=
                PERSON_TYPES.CUSTOMER || isPatientDetailViewAllowedConfig
                ? props.btnTextVisibility?.showCharts
                : false
            }
            patientHieSyncCall={props.patientHieSyncCall}
            showEditProfileOnPatientInfoBanner={
              props.showEditProfileOnPatientInfoBanner &&
              canPatientUpdate
            }
          />
         :
        <VStack style={{height: (props.height || height)}}>
          <View style={styles.headerContainer}>
            <View
              backgroundColor={'#fff'}
              style={styles.profileDetailContainer}>
              <ProfileDetailView
                hideFullNameTooltip={true}
                hintExtraDetail={props.hintExtraDetail}
                isBigBannerNameTruncate={'73%'}
                conversationData={conversationData}
                contactData={contactData}
                contactType={props?.contactType}
                handleEmail={handleEmail}
                handleSMS={handleSMS}
                handleCall={handleCall}
                handleCloudCall={handleCloudCall}
                handleEdit={handleEdit}
                showEditProfileOnPatientInfoBanner={
                  props.showEditProfileOnPatientInfoBanner &&
                  canPatientUpdate
                }
                showDetailProfile={props.showDetailProfile}
                onActionPerformed={(type: any, data: any) => {
                  if (type == PERSON_ACTION_CODES.CREATE_NOTES) {
                    showAddNotes(true);
                  }

                  if (type == PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT) {
                    showAppointment(true);
                  }
                  props?.onActionPerformed?.(type, data);
                }}
                parentCode={parentCode}
                showCloseDrawerIcon={props?.showCloseDrawerIcon}
                patientHieSyncCall={props.patientHieSyncCall}
              />
            </View>
            <View width={'full'} alignItems={'center'}>
              <View style={styles.divider} width={'100%'}></View>
            </View>
            {isEmployerRole() ? (
              <></>
            ) : (
              <>
                <TagDataListView
                  taggableType={'Contact'}
                  contactData={contactData}
                  conversationData={conversationData}
                  showMemberStatus={memberStatusEnabled && contactData?.contactType?.contactType?.code === PERSON_TYPES.CUSTOMER}
                  style={{marginBottom: 10}}
                />
                <View width={'full'} alignItems={'center'}>
                  <View style={styles.divider} width={'100%'}></View>
                </View>
                <View style={styles.personActionsContainer}>
                  <PersonActions
                    showPhoneCall={props.btnTextVisibility?.showPhoneBtn}
                    shouldShowEmail={props.btnTextVisibility?.showEmailBtn}
                    showVideoCall={props.btnTextVisibility?.showMeetBtn}
                    showAppointment={
                      props.btnTextVisibility?.showAppointmentBtn
                    }
                    showNotes={props.btnTextVisibility?.showNotes}
                    showSms={props.btnTextVisibility?.showSmsBtn}
                    showShowInEhr={props.btnTextVisibility?.showEhrBtn}
                    showCharts={
                      contactData?.contactType?.contactType?.code !=
                        PERSON_TYPES.CUSTOMER || isPatientDetailViewAllowedConfig
                        ? props.btnTextVisibility?.showCharts
                        : false
                    }
                    showViewProfile={
                      contactData?.contactType?.contactType?.code !=
                        PERSON_TYPES.CUSTOMER || isPatientDetailViewAllowedConfig
                        ? props.btnTextVisibility?.showViewProfile
                        : false
                    }
                    contactInfo={props?.contactData}
                    email={
                      props?.contactData?.email ||
                      getSecondaryValue(contactData?.personContact, 'email') ||
                      ''
                    }
                    phoneNumber={
                      props?.contactData?.phoneNumber ||
                      getSecondaryValue(contactData?.personContact, 'phone') ||
                      ''
                    }
                    extra={props?.extra}
                    handleEdit={handleEdit}
                    parentCode={parentCode}
                    onPersonActionPerformed={(code: string, data: any) => {
                      props.onActionPerformed?.(code, data);
                    }}
                    showEditProfileOnPersonAction={
                      !props.showEditProfileOnPatientInfoBanner &&
                      canPatientUpdate
                    }
                    onEditDetails={() => {
                      props.onActionPerformed?.(
                        COMMON_ACTION_CODES.EDIT,
                        contactData
                      );
                    }}
                    isEmployerDrillDown={props?.isEmployerDrillDown}
                  />
                </View>
                <View width={'full'} alignItems={'center'}>
                  <View style={styles.divider} width={'100%'}></View>
                </View>
              </>
            )}
            <View style={styles.tabContainer}>
              <View style={styles.tabsWrapper}>
                {!isEmployerRole() ? (
                  <>
                    {' '}
                    <Pressable
                      onPress={() => {
                        setActiveTab(0);
                      }}
                      {...testID(TestIdentifiers.summary)}
                      style={[styles.tabButton, activeTab == 0 && styles.activeTabButton]}
                    >
                      <Text
                        color={
                          activeTab == 0 ? '#825AC7' : Colors.FoldPixel.GRAY300
                        }
                        size={'smRegular'}
                      >
                        Summary
                      </Text>
                    </Pressable>{' '}
                    {shouldShowCommunication() &&
                    <FoldPermitCan
                    resource={MAIN_MENU_CODES.INBOX}
                    action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
                    component={<Pressable
                      onPress={() => {
                        setActiveTab(1);
                      }}
                      {...testID(TestIdentifiers.communication)}
                      style={[styles.tabButton, activeTab == 1 && styles.activeTabButton]}
                    >
                      <Text
                        color={
                          activeTab == 1 ? '#825AC7' : Colors.FoldPixel.GRAY300
                        }
                        size={'smRegular'}
                      >
                        Communication
                      </Text>
                    </Pressable>}
                    />
                    }
                  </>
                ) : (
                  <></>
                )}

                <Pressable
                  onPress={() => {
                    // eventBus.broadcastEvent(WINDOW_EVENT_CODES.PATIENT_UPDATES, { fromSection: 'PERSON_OMNI_VIEW' });
                    setActiveTab(2);
                  }}
                  {...testID(TestIdentifiers.profile)}
                  style={[
                    styles.tabButton,
                    (activeTab == 2 || isEmployerRole()) && styles.activeTabButton
                  ]}
                >
                  <Text
                    color={
                      activeTab == 2 || isEmployerRole()
                        ? '#825AC7'
                        : Colors.FoldPixel.GRAY300
                    }
                    size={'smRegular'}
                  >
                    Profile
                  </Text>
                </Pressable>

                {contactData?.patient?.id && !isEmployerRole() ? (
                  <Pressable
                    onPress={() => {
                      setActiveTab(3);
                    }}
                    {...testID(TestIdentifiers.crmActivity)}
                    style={[styles.tabButton, activeTab == 3 && styles.activeTabButton3]}
                  >
                    <Text
                      color={activeTab == 3 ? '#825AC7' : Colors.FoldPixel.GRAY300}
                      size={'smRegular'}
                    >
                      CRM Activity
                    </Text>
                  </Pressable>
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>
          <ScrollView style={styles.scrollView}>
            <VStack>
              <VStack style={styles.innerVStack}>
                <View style={styles.bottomContainer}>
                  {renderBottomBox()}
                </View>
              </VStack>

              <View>
                {!isGroupMessage() && false && (
                  <Collapse
                    defaultActiveKey={['Conversation_Details_1']}
                    expandIconPosition={'right'}
                    style={reactStyles.collapse}
                  >
                    <Panel
                      header={
                        <DisplayText
                          size={'lgMedium'}
                          extraStyles={{color: Colors.Custom.Gray900}}
                          textLocalId={'conversationDetails'}
                        />
                      }
                      key={'Conversation_Details_1'}
                    >
                      <VStack style={styles.panelBox}>
                        <DetailsPanelReadOnly contactData={contactData} />
                      </VStack>
                    </Panel>
                  </Collapse>
                )}
              </View>
            </VStack>
          </ScrollView>
        </VStack>
      }
      </View>
    </Content>
  );
};
