import { useLazyQuery } from "@apollo/client";
import { GET_CARE_PLANS_BY_CONTACT_ID } from "../../../../../services/CarePlan/CarePlanQueries";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../../constants/Configs";
import { useContext, useState } from "react";
import { CommonDataContext } from "../../../../../context/CommonDataContext";
import { isEnableCareProgram } from "../../../../../utils/commonUtils";
import { useParams } from "react-router-dom";

export const useCarePlanGoalsApi = (careProgramId?: string): [any, boolean, (contactUUID: any) => void] => {
  const mlovData = useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const [getCarePlanGoals] = useLazyQuery(GET_CARE_PLANS_BY_CONTACT_ID, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });
  const [goals, setGoals] = useState<any[]>([]);
  const [goalsLoading, setGoalsLoading] = useState(false);
  const fetchGoals = async (contactUUID: any) => {
    try {
      if(!isCareProgramEnabled) {
        setGoals([]);
        setGoalsLoading(false);
      } else {
        if(!goalsLoading) {
          setGoalsLoading(true);
          const response = await getCarePlanGoals({
            variables: {
                contactId: contactUUID,
                contactCareProgramId: careProgramId || null
              },
          });
          setGoals(response?.data?.getContactCarePlans?.carePlans?.[0]?.goals || []);
          setGoalsLoading(false);
        }
      }
    } catch(error) {
      setGoals([]);
      setGoalsLoading(false);
    }
  }

  return [goals, goalsLoading, fetchGoals]
}