import React from 'react';
import { DayOptimizerContext } from '../../../../../../../context-provider/DayOptimizerContext/context';
import { isSameDay } from '../../../../../../../utils/DateUtils';
import { EventBus } from '../../../../../../../utils/EventBus';
import { ADT_EVENT_CODES, DAY_OPTIMIZER_EVENTS, DO_RIBBON_ACTION_LOADING_STATUS, DO_RIBBON_ACTIONS } from '../../../DayOptimizerConstants';
import { IRibbonResize, IADTEvent, IRibbonRemovePatientEventData, IRibbonAddPatientEventData, ILoadingStatusState } from '../../../interfaces';
import { getDOEventsByUserId } from '../../../../../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { getAccountUUID } from '../../../../../../../utils/commonUtils';
import { getUserUUID } from '../../../../../../../utils/commonUtils';
import LeadQueries from '../../../../../../../services/Lead/LeadQueries';
import { showToast, ToastType } from '../../../../../../../utils/commonViewUtils';
import { useToast } from 'native-base';
import { useLazyQuery, useMutation } from '@apollo/client';
import { WEB_SOCKET_EVENT_CODE } from '../../../../../../../constants/WebSocketConst';
import BaseService from '../../../../../../../services/CommonService/BaseService';
import { useIntl } from 'react-intl';
import { formatDateForApi, formatDateForApiFromDate } from '../../../DayOptimizerHelper';
import { getContactTypeId } from '../../../../../../../utils/mlovUtils';

export const useDOEventRibbon = () => {
  // # Context
  const { selectedDate, columnShuffleDropDownList, gridConfigurationData, filter } = React.useContext(DayOptimizerContext).state.patientDashboard;

  // # State
  const [ribbonOpen, setRibbonOpen] = React.useState(false);
  const [newNotification, setNewNotification] = React.useState<number>(0);
  const [eventsList, setEventsList] = React.useState<IADTEvent[]>([]);
  const [showAll, setShowAll] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState<any>(null);
  const [loadingStatus, setLoadingStatus] = React.useState<ILoadingStatusState[] | null>(null);

  // # Hooks
  const toast = useToast();
  const intl = useIntl();

  // # Variables
  const userId = getUserUUID();
  const accountId = getAccountUUID();
  const axios = BaseService.getSharedInstance().axios;
  const eventBus = EventBus.getEventBusInstance();
  const loggedInUserUuid = getUserUUID();
  const contactTypeId = getContactTypeId('CUSTOMER');


  // # API
  const markEventsAsRead = async (params: {
    eventIds: string[],
    accountId: string,
    userId: string
  }) => {
    const queryString = LeadQueries.MarkEventsAsRead;
    const data = {
      query: queryString,
      variables: { params },
    };

    const response = await axios.post('/crm-communication/graphql', data);
    return response.data;
  };

  const [updatePatientInDayOptimizer] = useMutation(LeadQueries.addPatientToDayOptimizer);
  const [getPatientsDetails] = useLazyQuery(LeadQueries.searchDayOptimizerPatients, {
    fetchPolicy: 'no-cache',
  });

  // # Functions

  const removeLoadingStatus = (eventId: string) => {
    setLoadingStatus((prev: ILoadingStatusState[] | null) => {
      const newLoadingStatus = prev?.filter((status) => status?.eventId !== eventId);
      return newLoadingStatus || null;
    });
  }

  const addLoadingStatus = (eventId: string, status: DO_RIBBON_ACTION_LOADING_STATUS) => {
    setLoadingStatus((prev) => {
      const existingStatusIndex = prev?.findIndex(status => status.eventId === eventId) || -1;
      const newLoadingStatus = existingStatusIndex !== -1
        ? prev?.map((item, index) =>
          index === existingStatusIndex ? { ...item, status: status } : item
        )
        : [...(prev || []), { eventId, status }];
      return newLoadingStatus || null;
    });
  }

  const fetchEvents = async () => {
    try {
      const events = await getDOEventsByUserId({
        userId,
        accountId,
        isRead: false,
      });
      if (events?.length) {
        const eventIds = events.map((event: IADTEvent) => event?.id);
        const loadingStatus = eventIds.map((eventId: string) => ({
          eventId,
          status: DO_RIBBON_ACTION_LOADING_STATUS.VALIDATION
        }));
        setLoadingStatus(loadingStatus);
        setEventsList(events as IADTEvent[]);
        checkMultipleContactsExistance(events);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const toogleRibbonOpen = () => {
    setRibbonOpen((prev) => !prev);
    setShowAll(false);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const onRibbonResize = () => {
    const data: IRibbonResize = {
      open: ribbonOpen,
      count: eventsList.length
    };
    eventBus.broadcastEvent(DAY_OPTIMIZER_EVENTS.ON_RIBBON_RESIZE, data);
  };

  const handleMarkAsRead = (event: IADTEvent, isShowToast = true) => {
    markEventsAsRead({
      eventIds: [event.id || ""],
      accountId,
      userId
    }).then((response: any) => {
      if (response?.data?.markEventsAsRead?.success) {
        if (isShowToast) {
          showToast(
            toast,
            intl.formatMessage({ id: 'eventDismissed' }),
            ToastType.success,
            3000
          );
        }
        setEventsList((prevEvents) =>
          prevEvents.filter((e) => {
            if (e?.id === event?.id) {
              return false;
            }
            return true;
          })
        );
      } else {
        if (isShowToast) {
          showToast(
            toast,
            intl.formatMessage({ id: 'couldNotDismissEvent' }),
            ToastType.error,
            3000
          );
        }
        removeLoadingStatus(event?.id);
      }
    }).catch((error: any) => {
      if (isShowToast) {
        showToast(
          toast,
          intl.formatMessage({ id: 'couldNotDismissEvent' }),
          ToastType.error,
          3000
        );
      }
      removeLoadingStatus(event?.id);
    })
  }

  const fetchDayOptimizerPatients = async (uuids: string[]) => {
    const formattedDate = formatDateForApi(new Date().toISOString());
    const dayOpPatientDetailsResponse = await getPatientsDetails({
      variables: {
        params: {
          uuids: uuids,
          dayOptimizerUserScheduleSelectFilters: {
            date: formattedDate,
            userUuids: [loggedInUserUuid],
            ...(filter?.statusId && {
              statusIds: [filter?.statusId]
            })
          },
          dayOptimizerUserScheduleFilters: {
            date: formattedDate,
            userUuids: [loggedInUserUuid],
            ...(filter?.statusId && {
              statusIds: [filter?.statusId]
            })
          },
          contactTypeIds: [contactTypeId]
        }
      },
    });
    return dayOpPatientDetailsResponse;
  }

  const fetchPatientsDetails = async (uuids: string[]) => {
    try {
      const dayOpPatientDetailsResponse = await getPatientsDetails({
        variables: {
          params: {
            uuids: uuids,
          }
        },
      });
      return dayOpPatientDetailsResponse;
    } catch (error) {
      return null;
    }
  }

  const onRibbonRemovePatientEvent = async (event: IADTEvent, data: IRibbonRemovePatientEventData) => {
    try {
      const dayOpPatientDetailsResponse = await fetchPatientsDetails([data.contactUuid]);
      const userDayOptimizerSchedule = dayOpPatientDetailsResponse?.data?.searchContacts?.contacts[0]?.dayOptimizerUserSchedules || [];
      const firstSchedule = userDayOptimizerSchedule?.[0] || {};
      const dayOptimizerId = firstSchedule?.id;

      const response = await updatePatientInDayOptimizer({
        variables: {
          params: {
            id: dayOptimizerId,
            isDeleted: data.isDeleted
          }
        }
      });
      if (response) {
        showToast(
          toast,
          intl.formatMessage({ id: 'memberRemovedFromDayOptimizerSuccessfully' }),
          ToastType.success,
          3000,
          true
        );
        eventBus.broadcastEvent(DAY_OPTIMIZER_EVENTS.REMOVE_PATIENT, data);
        handleMarkAsRead(event, false);
      } else {
        showToast(
          toast,
          intl.formatMessage({ id: 'couldNotRemoveMemberFromDayOptimizer' }),
          ToastType.error,
          3000,
          true
        );
      }
    } catch (error) {
      showToast(
        toast,
        intl.formatMessage({ id: 'couldNotRemoveMemberFromDayOptimizer' }),
        ToastType.error,
        3000,
        true
      );
    } finally {
      removeLoadingStatus(event?.id);
    }
  }

  const onRibbonAddPatientEvent = async (event: IADTEvent, data: IRibbonAddPatientEventData) => {
    try {
      const response = await updatePatientInDayOptimizer({
        variables: {
          params: {
            contactUuid: data.contactUuid,
            date: data.date,
            userUuid: loggedInUserUuid
          }
        }
      });
      if (response) {
        showToast(
          toast,
          intl.formatMessage({ id: 'memberAddedToDayOptimizerSuccessfully' }),
          ToastType.success,
          3000,
          true
        );
        const dayOpPatientDetailsResponse = await fetchPatientsDetails([data.contactUuid]);
        if (dayOpPatientDetailsResponse) {
          eventBus.broadcastEvent(DAY_OPTIMIZER_EVENTS.ADD_PATIENT, dayOpPatientDetailsResponse?.data);
        }
        handleMarkAsRead(event, false);
      } else {
        showToast(
          toast,
          intl.formatMessage({ id: 'couldNotAddMemberToDayOptimizer' }),
          ToastType.error,
          3000,
          true
        );
      }
    } catch (error) {
      showToast(
        toast,
        intl.formatMessage({ id: 'couldNotAddMemberToDayOptimizer' }),
        ToastType.error,
        3000,
        true
      );
    } finally {
      removeLoadingStatus(event?.id);
    }
  }

  const checkMultipleContactsExistance = async (eventsList: IADTEvent[]) => {
    const contactUuids = eventsList.map((event) => event?.payload?.contactUuid);
    const uniqueContactUuids = [...new Set(contactUuids)];
    const dayOpPatientDetailsResponse = await fetchDayOptimizerPatients(uniqueContactUuids);
    if (dayOpPatientDetailsResponse) {
      const contacts = dayOpPatientDetailsResponse?.data?.searchContacts?.contacts;
      const newEventsList = eventsList.map((event: any) => {
        const contactUuid = event?.payload?.contactUuid;
        const contact = contacts?.find((contact: any) => contact?.uuid === contactUuid);
        if (contact) {
          if (event?.eventCode === ADT_EVENT_CODES.DISCHARGE) {
            return { ...event, hidePrimaryActionButton: true };
          }
        } else {
          if (event?.eventCode === ADT_EVENT_CODES.ADMISSION) {
            return { ...event, hidePrimaryActionButton: true };
          }
        }

        return event;
      });
      setEventsList(newEventsList);
      const newLoadingStatus = loadingStatus?.filter((status) => {
        if (eventsList.find((event) => event?.id === status?.eventId)) {
          return false;
        }
        return true;
      });
      setLoadingStatus(newLoadingStatus || null);
    }
  }

  const checkSingleContactExistance = async (contactUuid: string, event: IADTEvent) => {
    const dayOpPatientDetailsResponse = await fetchDayOptimizerPatients([contactUuid]);
    if (dayOpPatientDetailsResponse) {
      const contacts = dayOpPatientDetailsResponse?.data?.searchContacts?.contacts;
      const contact = contacts?.find((contact: any) => contact?.uuid === contactUuid);
      if (contact) {
        if (event?.eventCode === ADT_EVENT_CODES.DISCHARGE) {
          setEventsList((prevEvents) => {
            const updatedEvents = prevEvents.map((eventItem) => {
              if (eventItem?.id === event?.id) {
                return { ...eventItem, hidePrimaryActionButton: true };
              }
              return eventItem;
            });
            return updatedEvents;
          });
        }
      } else {
        if (event?.eventCode === ADT_EVENT_CODES.ADMISSION) {
          setEventsList((prevEvents) => {
            const updatedEvents = prevEvents.map((eventItem) => {
              if (eventItem?.id === event?.id) {
                return { ...eventItem, hidePrimaryActionButton: true };
              }
              return eventItem;
            });
            return updatedEvents;
          });
        }
      }

      removeLoadingStatus(event?.id);
    }
  }

  // # Actions
  const onActionPerformed = (action: DO_RIBBON_ACTIONS, event: IADTEvent) => {
    const date = formatDateForApiFromDate(new Date());

    switch (action) {
      case DO_RIBBON_ACTIONS.ADD_TO_LIST:
        addLoadingStatus(event?.id, DO_RIBBON_ACTION_LOADING_STATUS.ADD_TO_LIST);
        onRibbonAddPatientEvent(event, {
          contactUuid: event?.payload?.contactUuid,
          date: date,
        });
        break;
      case DO_RIBBON_ACTIONS.REMOVE_PATIENT:
        addLoadingStatus(event?.id, DO_RIBBON_ACTION_LOADING_STATUS.REMOVE_PATIENT);
        onRibbonRemovePatientEvent(event, {
          contactUuid: event?.payload?.contactUuid,
          isDeleted: true,
        });
        break;
      case DO_RIBBON_ACTIONS.VIEW_EVENT:
        setSelectedEvent(event);
        setIsDrawerOpen(true);
        break;
      case DO_RIBBON_ACTIONS.MARK_AS_READ:
        addLoadingStatus(event?.id, DO_RIBBON_ACTION_LOADING_STATUS.MARK_AS_READ);
        handleMarkAsRead(event);
        break;
      default:
        break;
    }
  };

  // # Listeners
  const onADTEventOccure = (event: any) => {
    const eventData: IADTEvent = event.data;
    const contactUuid = eventData?.payload?.contactUuid;
    if (eventData) {
      addLoadingStatus(eventData?.id, DO_RIBBON_ACTION_LOADING_STATUS.VALIDATION);
      setEventsList((prevEvents) => [eventData, ...prevEvents]);
      checkSingleContactExistance(contactUuid, eventData);
      setNewNotification((prev) => prev + 1);
    }
  };

  // # UseEffect
  React.useEffect(() => {
    if (isSameDay(selectedDate, new Date())) {
      fetchEvents();
    } else {
      setEventsList([]);
    }
  }, [selectedDate]);

  React.useEffect(() => {
    onRibbonResize();
  }, [ribbonOpen, eventsList.length, showAll]);

  React.useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.ADT_EVENT_CODE,
      onADTEventOccure
    );
    return () => {
      eventBus.removeEventListener(onADTEventOccure);
    };
  }, []);

  return {
    selectedDate,
    eventsList,
    ribbonOpen,
    newNotification,
    toogleRibbonOpen,
    showAll,
    toggleShowAll,
    onActionPerformed,
    isDrawerOpen,
    setIsDrawerOpen,
    selectedEvent,
    loadingStatus,
  };
};
