import {View} from 'native-base';
import React, {useState} from 'react';
import {CONTACT_NOTES_VIEW} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {Colors} from '../../../styles/Colors';
import {CommonCollapse} from '../../RightSideContainer/Contacts/TeamMembers/LeftTeamContainer/CommonCollapse';
import {DisplayText} from '../DisplayText/DisplayText';
import CalenderIcon from '../Svg/CalenderIcon';
import PrivateMessageListView from './PrivateMessageListView';
import PrivateMessageReadOnly from './PrivateMessageReadOnly';
import {styles} from './PrivateMessageViewStyles';

interface IContacNotesView {
  conversationData: any;
  style?: any;
  isDefaultClose?: boolean;
}

const PrivateMessageView = (props: IContacNotesView) => {
  const {conversationData} = props;
  const [selectedView, setSelectedView] = useState(
    CONTACT_NOTES_VIEW.NOTES_LIST_VIEW
  );
  const [selectedData, setSelectedData] = useState({} as any);
  const onViewChangeActionPerformed = (
    actionCode: string,
    rowData?: any
  ): any => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ROW_CLICKED:
        setSelectedData(rowData);
        setSelectedView(CONTACT_NOTES_VIEW.NOTE_READ_ONLY_VIEW);
        break;
      case COMMON_ACTION_CODES.ADD:
        setSelectedData(rowData);
        setSelectedView(CONTACT_NOTES_VIEW.ADD_NOTE);
        break;
      case COMMON_ACTION_CODES.CANCEL:
        setSelectedData(rowData);
        setSelectedView(CONTACT_NOTES_VIEW.NOTES_LIST_VIEW);
        break;
      default:
        setSelectedData(rowData);
        setSelectedView(CONTACT_NOTES_VIEW.NOTES_LIST_VIEW);
        break;
    }
  };
  const getLeadViewFromSelectedCode = () => {
    switch (selectedView) {
      case CONTACT_NOTES_VIEW.NOTES_LIST_VIEW:
      case CONTACT_NOTES_VIEW.NOTE_READ_ONLY_VIEW:
      case CONTACT_NOTES_VIEW.ADD_NOTE:
        return (
          <>
            <View
              style={{
                backgroundColor: Colors.Custom.Gray50,
              }}
            >
              <View style={{padding: 24}}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 9,
                    }}
                  >
                    <View style={{marginRight: 10}}>
                      <CalenderIcon />
                    </View>
                    <DisplayText
                      size={'lgMedium'}
                      extraStyles={{color: Colors.FoldPixel.GRAY300 || ''}}
                      textLocalId={'privateMessage'}
                    />
                  </View>
                </View>
              </View>
              <View
                style={[
                  {
                    backgroundColor: '#ffffff',
                    marginHorizontal: 24,
                    borderRadius: 8,
                    // @ts-expect-error: Let's ignore a compile error like this unreachable code
                    boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
                    paddingHorizontal: 16,
                    paddingVertical: 8,
                  },
                ]}
              >
                <PrivateMessageListView
                  onMessageListActionPerform={onViewChangeActionPerformed}
                  conversationData={conversationData}
                />
              </View>
            </View>
            {selectedView === CONTACT_NOTES_VIEW.NOTE_READ_ONLY_VIEW && (
              <PrivateMessageReadOnly
                onDeleteCompleteAction={onViewChangeActionPerformed}
                selectedNote={selectedData}
              />
            )}
          </>
        );
    }
  };
  return <>{getLeadViewFromSelectedCode()}</>;
};
export default PrivateMessageView;
