import {ApolloCache, DefaultContext, MutationFunctionOptions, OperationVariables} from '@apollo/client';
import {IMlov} from '../../../../../Interfaces';
import {USER_ROLE_CODES} from '../../../../../constants/MlovConst';
import {FormComponents} from '../../../../PublicPages/PublicForm/PublicFormHelper';
import {IBarrier} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Barriers/interfaces';
import {componentKeys} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {IGoal} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Goals/AddOrUpdateGoals/interface';
import {IMedicationStatement} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Medications/interfaces';
import { IIntervention } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/interfaces';
import {forEachExtensiveFormComponent} from '../../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {IFormSelectedValue} from '../../PatientNotes/components/AddOrUpdateTemplate/interfaces';
import {COMPONENTS_TO_MERGE, TASK_RESOURCE_CODE_FOR_CARE_PLAN} from '../CarePlanConstants';
import {
  CarePlanValidationErrors,
  ICarePlanDetailsState,
  ICarePlanPayLoad,
  ICarePlanValidationError,
} from '../interfaces';
import {v4 as uuidv4} from 'uuid';
import {getDefaultStartAndEndDateForNoteTask} from '../../../../../utils/DateUtils';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import { updateGoalsWithContactIds } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Goals/AddOrUpdateGoals/GoalUtils';
import {carePlanStatusOrder, InterventionStatus} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/AddOrUpdateIntervention/InterventionConstants';

export const getPostDataForCarePlan = (
  componentState: ICarePlanDetailsState,
  submitData: any,
  contactId: string,
  isDraft: boolean,
  manualSourceTypeId: string,
): ICarePlanPayLoad => {
  const selectedFormIds = componentState.selectedFormIds;
  const formIds = componentState.formIds.length > 0 ? componentState.formIds : selectedFormIds;

  const title =
    componentState.title ||
    getCarePlanTitle(formIds, componentState.formNamesMap);
  const components = componentState.components;

  const formChanges = submitData?.data;

  let componentsWithValues = getComponentsMappedWithFormValues(
    components,
    formChanges,
    contactId,
  );

  if (isDraft) {
    componentsWithValues = removeDeletedFormValues(componentsWithValues);
  }

  return {
    statusId: componentState.statusId || '',
    title,
    contactId,
    components: componentsWithValues,
    selectedFormIds: selectedFormIds,
    ...(formIds.length > 0 && {formIds: formIds}),
    ...(componentState?.id && {carePlanId: componentState.id}),
    sourceTypeId: manualSourceTypeId,
  };
};

export const setComponentsSelectedValues = (components: any[], formChanges: any) => {
  forEachExtensiveFormComponent(components || [], (component) => {
    if (formChanges && formChanges.hasOwnProperty(component.key)) {
      component.selectedValue = formChanges[component.key];
    }
  })
  return components
}

const getComponentsMappedWithFormValues = (
  components: any[],
  formChanges: any,
  contactId: string,
): IFormSelectedValue[] => {
  const formSelectedValues: IFormSelectedValue[] = [];
  forEachExtensiveFormComponent(components || [], (component) => {
    if (formChanges && formChanges.hasOwnProperty(component.key)) {
      component.selectedValue = formChanges[component.key];
    }
    if ((component.type === componentKeys.BARRIERS)) {
      component.selectedValue.barriers =
        component.selectedValue?.barriers?.map((barrier: IBarrier) => {
          if (!barrier.id) {
            barrier.id = uuidv4();
          }
          return barrier;
        });
    }

    if ((component.type === componentKeys.INTERVENTION)) {
      component.selectedValue.interventions =
        component.selectedValue?.interventions?.map((intervention: IIntervention) => {
          if (!intervention.id) {
            intervention.id = uuidv4();
          }
          intervention.interventionId = intervention.id;
          delete intervention.temporaryId;
          return intervention;
        });
    }


    if ((component.type === componentKeys.GOALS)) {
      component.selectedValue.goals = component.selectedValue?.goals?.filter((goal: IGoal) => typeof goal === 'object').map(
        (goal: IGoal) => {
          if (!goal.id) {
            goal.id = uuidv4();
          }
          goal.contactRules?.forEach((contactRule) => {
            contactRule.entityId = goal.id || '';
            if (contactId) {
              contactRule.contactId = contactId;
              if (contactRule.rule) {
                contactRule.rule.contactUuid = contactId;
              }
            }
          })
          goal.goalTargets = goal.goalTargets?.map((goalTarget) => {
            if (!goalTarget.id) {
              goalTarget.id = uuidv4();
            }
            return goalTarget;
          })
          return goal;
        }
      );
    }
  });

  return components;
};


export const setDefaultComponentValues = (
  components: any[]
): IFormSelectedValue[] => {
  forEachExtensiveFormComponent(components || [], (component) => {
    if(!component.selectedValue && component.defaultComponentValue){
      component.selectedValue = component.defaultComponentValue;
    }
  });

  return components;
};

export const removeDeletedFormValues = (
  components: any[],
): IFormSelectedValue[] => {
  forEachExtensiveFormComponent(components || [], (component) => {

    if (component.type === componentKeys.BARRIERS) {
      if (!component.selectedValue) {
        component.selectedValue = { barriers: [] };
      } else if (!component.selectedValue.barriers) {
        component.selectedValue.barriers = [];
      } else {
        component.selectedValue.barriers =
          component?.selectedValue?.barriers?.filter((barrier: IBarrier) => !barrier?.isDeleted);
      }
    }
    if (component.type === componentKeys.GOALS) {
      if (!component.selectedValue) {
        component.selectedValue = { goals: [] };
      }
      component.selectedValue.goals =
        component.selectedValue?.goals?.filter((goal: IGoal) => !goal?.isDeleted) || [];
    }
  });

  return components;
};

export const getCarePlanTitle = (
  selectedFormIds: string[],
  formIdMap: {
    [key: string]: string;
  }
) => {
  const titleList: string[] = [];
  selectedFormIds.forEach((formId) => {
    const name = formIdMap[formId];
    if (name) {
      titleList.push(name);
    }
  });

  return titleList.join(', ')
};

export const getMergedFormComponents = (
  oldComponents: any[],
  newComponents: any[]
) => {
  const oldComponentTypes = oldComponents.map((item) => item.type);

  newComponents.forEach((item) => {
    if (
      oldComponentTypes.includes(item.type) &&
      COMPONENTS_TO_MERGE.includes(item.type)
    ) {
      const matched = oldComponents.find(
        (component) => component.type === item.type
      );
      if (matched) {
        mergeComponents(matched, item);
      }
    } else {
      oldComponents.push(item);
    }
  });
  return oldComponents.filter((item) => item.key !== 'submit');
};

export const mergeComponents = (oldComponent: any, newComponent: any) => {
  switch (oldComponent.type) {
    case FormComponents.SYMPTOM_MONITORING:
    case FormComponents.MEDICATION_MANAGEMENT:
    case FormComponents.DIET:
    case FormComponents.EXERCISE:
    case FormComponents.HABITS:
    case FormComponents.HOME_MONITORING:
    case FormComponents.LAB_MONITORING:
    case FormComponents.HEALTH_MAINTENANCE:
      const oldComponentTypes = oldComponent.components.map(
        (item: any) => item.type
      );

      newComponent.components.forEach((item: any) => {
        if (
          oldComponentTypes.includes(item.type) &&
          COMPONENTS_TO_MERGE.includes(item.type)
        ) {
          const matched = oldComponent.components.find(
            (component: any) => component.type === item.type
          );
          if (matched) {
            mergeComponents(matched, item);
          }
        } else {
          oldComponent.components.push(item);
        }
      });
      break;

    case FormComponents.GOALS:
      const oldGoals: IGoal[] = oldComponent.selectedValue?.goals || oldComponent.defaultComponentValue?.goals || [];
      const oldGoalTitles = oldGoals.map((item) => item.title);
      const newGoals: IGoal[] = newComponent.defaultComponentValue?.goals || [];

      const nonDuplicateGoals = newGoals.filter((item) => {
        return !oldGoalTitles.includes(item.title);
      });

      const updatedGoals = oldGoals.concat(nonDuplicateGoals);

      if (oldComponent.selectedValue) {
        oldComponent.selectedValue.goals = updatedGoals;
      } else {
        oldComponent.defaultComponentValue.goals = updatedGoals;
      }
      break;

    case FormComponents.BARRIERS:
      const oldBarriers: IBarrier[] = oldComponent.selectedValue?.barriers || oldComponent.defaultComponentValue?.barriers || [];
      const oldBarrierTitles = oldBarriers.map((item) => item.title);
      const newBarriers: IBarrier[] =
        newComponent.defaultComponentValue?.barriers || [];

      const nonDuplicateBarriers = newBarriers.filter((item) => {
        return !oldBarrierTitles.includes(item.title);
      });

      const updatedBarriers = oldBarriers.concat(nonDuplicateBarriers);

      if (oldComponent.selectedValue) {
        oldComponent.selectedValue.barriers = updatedBarriers;
      } else {
        oldComponent.defaultComponentValue.barriers = updatedBarriers;
      }
      break;

    case FormComponents.MEDICATIONS:
      const oldMedications: IMedicationStatement[] = oldComponent.selectedValue?.medicationStatements || oldComponent.defaultComponentValue?.medicationStatements || [];
      const oldMedicationTitles = oldMedications.map((item) => item.name);
      const newMedications: IMedicationStatement[] =
        newComponent.defaultComponentValue?.medicationStatements || [];

      const nonDuplicateMedicationStatements = newMedications.filter((item) => {
        return !oldMedicationTitles?.includes(item.name);
      });
      if (!oldComponent.defaultComponentValue) {
        oldComponent.defaultComponentValue = {
          medicationStatements: [],
        };
      }

      const updatedMedications = oldMedications.concat(nonDuplicateMedicationStatements);

      if (oldComponent.selectedValue) {
        oldComponent.selectedValue.medicationStatements = updatedMedications;
      } else {
        oldComponent.defaultComponentValue.medicationStatements =
          updatedMedications;
      }
      break;

    case FormComponents.INTERVENTION:
      const oldInterventions: IIntervention[] =
        oldComponent.selectedValue?.interventions ||
        oldComponent.defaultComponentValue?.interventions ||
        [];
      const newInterventions: IIntervention[] =
        newComponent.defaultComponentValue?.interventions || [];

      const updatedInterventions = oldInterventions.concat(newInterventions);

      if (oldComponent.selectedValue) {
        oldComponent.selectedValue.interventions = updatedInterventions;
      } else {
        oldComponent.defaultComponentValue.interventions =
          updatedInterventions;
      }
      break;


  }
};

export const validateCarePlanPostData = (
  componentState: ICarePlanDetailsState,
  isEditCarePlan: boolean
): ICarePlanValidationError[] | undefined => {
  const formIds = componentState.selectedFormIds;

  if (isEditCarePlan) {
    return;
  }

  if (formIds.length === 0) {
    return [
      {
        errorCode: CarePlanValidationErrors.CARE_PLAN_TEMPLATES_MISSING,
        errorMessage: 'Please select care plan template',
      },
    ];
  }
};

export const getProviderSearchWhereCondition = (searchValue: string,excludedUuid: string | undefined, showAllRolesOfUsers?: boolean) => {
  const where: any = {
    accountUsers: {},
  };
  if (!showAllRolesOfUsers) {
    where.userRoles = {
      userRole: {userRole: {code: {_in: [USER_ROLE_CODES.PHYSICIAN]}}},
    };
  }
  const excludeConditions: any[] = [
    {
      userRoles: {
        userRole: {
          userRole: {
            code: {
              _in: [
                USER_ROLE_CODES.WORFLOW_USER,
                USER_ROLE_CODES.CUSTOMER_SUCCESS,
                USER_ROLE_CODES.EMPLOYER
              ],
            },
          },
        },
      },
    }
  ];

  if (excludedUuid) {
    excludeConditions.push({
      uuid: { _eq: excludedUuid }
    });
  }

  where._not = {
    _or: excludeConditions
  };
  where.accountUsers.isActive = {_eq: true};
  where.name = {_ilike: searchValue ? `%${searchValue}%` : `%%`};
  where.isDeleted = {_eq: false}

  return where;
};

export const createTaskForReviewer = async (params: {
  carePlanId: string;
  reviewerId: string;
  taskPromise: (
    options?:
      | MutationFunctionOptions<
          any,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined
  ) => Promise<any>;
  title: string;
  loginUserId: string;
  contactUuid: string;
  priorityId: string;
  statusId: string;
}): Promise<string | undefined> => {
  const {carePlanId, reviewerId, title, loginUserId,contactUuid, statusId, priorityId, taskPromise} = params;
  const taskDefaultTime = getDefaultStartAndEndDateForNoteTask();
  const addTaskVariables = {
    title: title,
    assigneeId: reviewerId,
    assignedById: loginUserId,
    contactId: contactUuid,
    priorityId: priorityId,
    startDateTime: taskDefaultTime.startDateTime,
    endDateTime: taskDefaultTime.endDateTime,
    assigneeTypeCode: 'USER',
    statusId: statusId,
    contextData: {
      carePlanId: carePlanId,
      resourceType: TASK_RESOURCE_CODE_FOR_CARE_PLAN,
    },
  };
  try {
    const taskResponse = await taskPromise({
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: {
        data: addTaskVariables,
      },
    });
    return taskResponse?.data?.addOrUpdateTask?.id;
  } catch (error) {
    return undefined;
  }

};

export const getSubmittedResponseFromComponents = (components: any[], contactId: string) => {
  const submittedData: any = {};
  forEachExtensiveFormComponent(components, (component) => {
    if (component.hasOwnProperty('selectedValue')) {
      if (component.type === FormComponents.GOALS) {
        updateGoalsWithContactIds(component.selectedValue.goals, contactId);
      }
      submittedData[component.key] = component.selectedValue;
    }
  });

  return {
    data: submittedData,
  };
};




export const updateReviewTaskStatus = async (params: {
  taskId?: string;
  statusId: string;
  taskPromise: (
    options?:
      | MutationFunctionOptions<
          any,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined
  ) => Promise<any>;
}): Promise<{
  success: boolean;
}> => {
  const {taskId, statusId, taskPromise} = params;
  const updateTaskVariables = {
    id: taskId,
    statusId: statusId,
  };

  if (!taskId) {
    return {success: false};
  }

  try {
   const res = await taskPromise({
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: {
        params: {
          id: taskId,
          data: {
            ...(statusId && { statusId: statusId }),
          }
        },
      },
    });
    return {success: true};
  } catch (error) {
    return {success: false};
  }
}


export const sortCarePlanComponentsByStatus = (component: any) => {
  if (!component || component?.length === 0) {
    return component;
  }
  const sortedItems = component?.sort((firstItem: any, secondItem: any) => {
    return (
      (carePlanStatusOrder[firstItem?.status?.code as InterventionStatus] || 0) -
      (carePlanStatusOrder[secondItem?.status?.code as InterventionStatus] || 0)
    );
  });
  return sortedItems;
};