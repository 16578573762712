import {useCallback, useEffect} from 'react';
import {IuseSideCarPatientContextEventHook} from './interface';
import {EventBus} from '../../../../utils/EventBus';
import {
  CONVERSATION_LOCAL_EVENT_CODES,
  SUPPORTED_EVENT_CODE,
  WEB_SOCKET_EVENT_CODE,
} from '../../../../constants/WebSocketConst';
import {useLazyQuery} from '@apollo/client';
import {
  IConversationData,
  INewConversationResponse,
  MESSAGE_TYPE,
} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import ConversationsQueriesV2 from '../../../../services/Conversations/V2/ConversationsQueriesV2';
import {
  getAccountId,
  getUserId,
  getUserUUID,
} from '../../../../utils/commonUtils';
import {
  MESSAGE_RECEIVED_DATA_ACTION,
  getFormattedReceivedMessageData,
} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationList/ConversationListEventHook/ConversationListEventHookUtils';
import {
  getUniqueConversationListByUuid,
  isConversationPresentByConversationUuid,
} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationList/ConversationListUtils';
import {
  getMlovIdCodeObj,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../../constants';
import {addOrUpdateConversationInConversationList} from './SideCarPatientContextHookUtils';
import {ILocalEventFormattedData} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationList/ConversationListEventHook/interface';
import ConversationsQueries from '../../../../services/Conversations/ConversationsQueries';
import {localBroadcastEvent} from '../../../../utils/CustomEventHandler';
import {CHANNEL_TYPE_CODE} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {setSearchParamsForSelectedConversation} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationSidebarUtils';
import {PATIENT_CONTEXT_MODULE_CODE} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MsgConst';

export const useSideCarPatientContextEventHook = (
  params: IuseSideCarPatientContextEventHook
) => {
  const {
    conversationListState,
    setConversationListState,
    commonData,
    contactUuid,
    contactId,
  } = params;
  const userUuid = getUserUUID();
  const userId = getUserId();
  const accountId = getAccountId();
  const groupTypeList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.GroupType
  );
  const groupTypeIdCodeObj = getMlovIdCodeObj(groupTypeList);

  const [GetConversationContactByUUIDandContactUuid] =
    useLazyQuery<INewConversationResponse>(
      ConversationsQueriesV2.GetConversationContactByUUIDandContactUuid,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );

  const [GetConversationByUUIDandContactUuid] =
    useLazyQuery<INewConversationResponse>(
      ConversationsQueriesV2.GetConversationByUUIDandContactUuid,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );

  const [getUnreadMessagesDataById] = useLazyQuery<INewConversationResponse>(
    ConversationsQueries.GetUnreadMessagesById,
    {
      fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const onMsgMarkAsUnreadUpdate = async (
    conversationList: IConversationData[],
    receivedEventData: any
  ) => {
    const conversationUuid = receivedEventData?.uuid;
    const conversationId =
      receivedEventData?.displayId || receivedEventData?.id;

    const conversationRespData = await getUnreadMessagesDataById({
      variables: {
        conversationId: conversationId,
        loginUserId: userId,
        loginUserIdInt: userId,
        loginUserUuid: userUuid,
      },
    });
    if (
      conversationRespData &&
      conversationRespData.data?.conversations &&
      conversationRespData?.data?.conversations?.length > 0
    ) {
      const finalConversationList = [...conversationList];
      const receivedMessageConversationIndex = conversationList?.findIndex(
        (item) => item?.uuid === conversationUuid
      );
      const updatedConversation =
        conversationList[receivedMessageConversationIndex];
      if (updatedConversation?.uuid === conversationUuid) {
        const finalUpdatedConversation = {
          ...updatedConversation,
          unreadMessages:
            conversationRespData.data?.conversations?.[0]?.unreadMessages || [],
          unreadMsgCount:
            conversationRespData.data?.conversations?.[0]?.unreadMsgCount || 0,
        };
        finalConversationList[receivedMessageConversationIndex] =
          finalUpdatedConversation;
      }
      setConversationListState((prev) => {
        return {
          ...prev,
          conversationList: [...finalConversationList],
        };
      });
    }
  };

  const getConversationContactByUUIDandContactUuid = async (
    conversationUuid: string
  ) => {
    const newConversationData =
      await GetConversationContactByUUIDandContactUuid({
        variables: {
          conversationUuid: conversationUuid,
          contactUuid: contactUuid,
        },
      });
    return newConversationData?.data;
  };

  const getConversationByUUIDandContactUuid = async (
    conversationUuid: string
  ) => {
    const newConversationData = await GetConversationByUUIDandContactUuid({
      variables: {
        conversationUuid: conversationUuid,
        contactUuid: contactUuid,
        loginUserIntId: userId,
        loginUserUuid: userUuid,
      },
    });
    return newConversationData?.data;
  };

  const checkIsPatientContextConversation = async (
    receivedEventContactId: number,
    receivedEventAccountId: number
  ) => {
    if (receivedEventContactId && receivedEventAccountId) {
      return (
        receivedEventContactId === contactId &&
        receivedEventAccountId === accountId
      );
    }
    return false;
  };

  const updateConversationDataInMatchedConversation = (
    receivedMessageData: any
  ) => {
    const formattedReceivedMessageData = getFormattedReceivedMessageData(
      receivedMessageData,
      groupTypeIdCodeObj
    );
    const conversationUuid = formattedReceivedMessageData?.conversationUuid;
    const finalConversationList = getUniqueConversationListByUuid(
      conversationListState.conversationList
    );
    let finalUpdatedConversationList = [...finalConversationList];
    if (conversationUuid) {
      const finalConversationList = getUniqueConversationListByUuid(
        conversationListState.conversationList
      );
      if (
        isConversationPresentByConversationUuid(
          finalConversationList,
          conversationUuid
        )
      ) {
        const messageReceivedDataAction: any = [
          MESSAGE_RECEIVED_DATA_ACTION.MESSAGE_UPDATE,
        ];
        if (
          formattedReceivedMessageData?.messageType === MESSAGE_TYPE.activity &&
          formattedReceivedMessageData?.eventCode ===
            WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED
        ) {
          messageReceivedDataAction.push(
            MESSAGE_RECEIVED_DATA_ACTION.ASSIGNEE_UPDATE
          );
        }
        if (
          formattedReceivedMessageData?.messageType === MESSAGE_TYPE.activity &&
          (formattedReceivedMessageData?.eventCode ===
            WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED ||
            formattedReceivedMessageData?.eventCode ===
              WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED)
        ) {
          messageReceivedDataAction.push(
            MESSAGE_RECEIVED_DATA_ACTION.CONVERSATION_STATUS_UPDATE
          );
        }
        finalUpdatedConversationList =
          addOrUpdateConversationInConversationList({
            conversationList: finalConversationList,
            selectedConversation: conversationListState?.selectedConversation,
            receivedMessageData: formattedReceivedMessageData,
            messageReceivedDataAction,
            userId: Number(userId),
          }) || [...finalConversationList];
      } else {
        getNewConversationAndUpdateInConversationList(conversationUuid);
      }
      setConversationListState((prev) => {
        return {
          ...prev,
          conversationList: finalUpdatedConversationList,
        };
      });
    }
  };

  const getNewConversationAndUpdateInConversationList = async (
    conversationUuid: string
  ) => {
    if (conversationUuid) {
      const conversationResponse = await getConversationByUUIDandContactUuid(
        conversationUuid
      );
      const newConversationData = conversationResponse?.conversations?.[0];
      if (newConversationData?.conversationContact?.uuid === contactUuid) {
        if (
          isConversationPresentByConversationUuid(
            conversationListState.conversationList,
            conversationUuid
          )
        ) {
          const finalConversationList = getUniqueConversationListByUuid(
            conversationListState.conversationList
          );
           if (finalConversationList) {
            const receivedMessageConversationIndex =
              finalConversationList.findIndex(
                (item) => item?.uuid === conversationUuid
              );
            if (receivedMessageConversationIndex !== -1) {
              finalConversationList[receivedMessageConversationIndex] = newConversationData;
              setConversationListState((prev) => ({
                ...prev,
                conversationList: finalConversationList,
              }));
            }
          }
        } else {
          setConversationListState((prev) => {
            const updatedConversationList = [
              newConversationData,
              ...prev?.conversationList,
            ];
            const finalConversationList = getUniqueConversationListByUuid(
              updatedConversationList
            );
            return {
              ...prev,
              conversationList: finalConversationList,
            };
          });
        }
      }
    }
  };

  const onLocalGroupConversationCreatedEvent = async (
    receivedEventData: ILocalEventFormattedData
  ) => {
    const conversationUuid = receivedEventData?.conversation?.uuid;
    if (conversationUuid) {
      getNewConversationAndUpdateInConversationList(conversationUuid);
    }
  };

  const updateUserLastSeenAtMessageAndConversation = (data: any) => {
    const {selectedConversation} = data;
    localBroadcastEvent(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_MSG_READ_ACTION_BUS,
      {
        selectedConversation: selectedConversation,
        selectedInboxTypeCode: CHANNEL_TYPE_CODE.CHANNEL_ALL,
      }
    );
    const eventBus = EventBus.getEventBusInstance();
    setSearchParamsForSelectedConversation({
      selectedConversation,
    }).then(() => {
      eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.CONVERSATION_CHANGED, {});
    });
    setConversationListState((prev) => {
      const updatedConversationList = [...prev.conversationList];
      const selectedConversationIndex = updatedConversationList?.findIndex(
        (item) => item?.uuid == selectedConversation?.uuid
      );
      if (selectedConversationIndex !== -1) {
        const updatedConversation = {
          ...updatedConversationList[selectedConversationIndex],
          unreadMessages: [],
          unreadMsgCount: 0,
        };
        updatedConversationList[selectedConversationIndex] =
          updatedConversation;
      }
      return {
        ...prev,
        conversationList: updatedConversationList,
      };
    });
  };

  const onLocalConversationLastSeenUpdateListenerFn = (
    receivedEventData: any
  ) => {
    const finalConversationList = getUniqueConversationListByUuid(
      conversationListState.conversationList
    );
    onMsgMarkAsUnreadUpdate(finalConversationList, receivedEventData);
  };

  const onLocalNewMessageReceivedListenerFn = (receivedEventData: any) => {
    const conversationUuid = receivedEventData?.conversation?.uuid;
    setConversationListState((prevState) => {
      const conversationIndex = prevState.conversationList?.findIndex(
        (item) => item?.uuid === conversationUuid
      );
      const updatedConversationList = [...prevState.conversationList];
      const selectedConversation = updatedConversationList[conversationIndex];
      if (conversationIndex !== -1 && selectedConversation?.status === 1) {
        const updatedConversation = {
          ...updatedConversationList[conversationIndex],
          status: 0,
        };
        updatedConversationList[conversationIndex] = updatedConversation;
      }
      return {
        ...prevState,
        conversationList: updatedConversationList,
      };
    });
  };

  const handleConversationEventInPatientContext = useCallback(
    async (receivedEventData: any) => {
      if (receivedEventData?.data && Object.keys(receivedEventData)?.length) {
        receivedEventData = receivedEventData?.data;
      }
      if (receivedEventData?.conversationData) {
        receivedEventData.conversation = receivedEventData?.conversationData;
      }
      const receivedEventContactId =
        receivedEventData?.conversation?.contact_id ||
        receivedEventData?.conversation?.contactId ||
        receivedEventData?.contactId;
      const receivedEventAccountId =
        receivedEventData?.account_id ||
        receivedEventData?.accountId ||
        receivedEventData?.conversation?.accountId;

      const eventCode =
        receivedEventData?.eventCode ||
        receivedEventData?.conversation?.eventCode;
      const isPatientContextConversation =
        await checkIsPatientContextConversation(
          receivedEventContactId,
          receivedEventAccountId
        );
      if (isPatientContextConversation) {
        switch (eventCode) {
          //Web socket events
          case WEB_SOCKET_EVENT_CODE.MESSAGE_CREATED:
          case WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED:
          case WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED:
          case WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED:
          case WEB_SOCKET_EVENT_CODE.GROUP_MEMBER_ADDED:
          case WEB_SOCKET_EVENT_CODE.GROUP_MEMBER_REMOVED:
            updateConversationDataInMatchedConversation(receivedEventData);
            break;

          //Local events
          case CONVERSATION_LOCAL_EVENT_CODES.LOCAL_GROUP_CONVERSATION_CREATED:
            onLocalGroupConversationCreatedEvent(receivedEventData);
            break;

          case CONVERSATION_LOCAL_EVENT_CODES.CONVERSATION_LAST_SEEN_UPDATE:
            onLocalConversationLastSeenUpdateListenerFn(receivedEventData);
            break;

          case CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_MSG_READ:
            updateUserLastSeenAtMessageAndConversation(receivedEventData);
            break;

          case CONVERSATION_LOCAL_EVENT_CODES.NEW_MESSAGE_CREATED:
            onLocalNewMessageReceivedListenerFn(receivedEventData);
            break;
        }
      }
    },
    [conversationListState]
  );

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.removeEventListener(handleConversationEventInPatientContext);

    // web socket event
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.MESSAGE_CREATED,
      handleConversationEventInPatientContext,
      {moduleCode: PATIENT_CONTEXT_MODULE_CODE}
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED,
      handleConversationEventInPatientContext,
      {moduleCode: PATIENT_CONTEXT_MODULE_CODE}
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED,
      handleConversationEventInPatientContext,
      {moduleCode: PATIENT_CONTEXT_MODULE_CODE}
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED,
      handleConversationEventInPatientContext,
      {moduleCode: PATIENT_CONTEXT_MODULE_CODE}
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.GROUP_MEMBER_ADDED,
      handleConversationEventInPatientContext,
      {moduleCode: PATIENT_CONTEXT_MODULE_CODE}
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.GROUP_MEMBER_REMOVED,
      handleConversationEventInPatientContext,
      {moduleCode: PATIENT_CONTEXT_MODULE_CODE}
    );

    // local event
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_GROUP_CONVERSATION_CREATED,
      handleConversationEventInPatientContext,
      {moduleCode: PATIENT_CONTEXT_MODULE_CODE}
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.CONVERSATION_LAST_SEEN_UPDATE,
      handleConversationEventInPatientContext,
      {moduleCode: PATIENT_CONTEXT_MODULE_CODE}
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_MSG_READ,
      handleConversationEventInPatientContext,
      {moduleCode: PATIENT_CONTEXT_MODULE_CODE}
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.NEW_MESSAGE_CREATED,
      handleConversationEventInPatientContext,
      {moduleCode: PATIENT_CONTEXT_MODULE_CODE}
    );

    return () => {
      eventBus.removeEventListenerByEventName(WEB_SOCKET_EVENT_CODE.MESSAGE_CREATED, handleConversationEventInPatientContext,  {moduleCode: PATIENT_CONTEXT_MODULE_CODE});
      eventBus.removeEventListenerByEventName(WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED, handleConversationEventInPatientContext,  {moduleCode: PATIENT_CONTEXT_MODULE_CODE});
      eventBus.removeEventListenerByEventName(WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED, handleConversationEventInPatientContext,  {moduleCode: PATIENT_CONTEXT_MODULE_CODE});
      eventBus.removeEventListenerByEventName(WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED, handleConversationEventInPatientContext,  {moduleCode: PATIENT_CONTEXT_MODULE_CODE});
      eventBus.removeEventListenerByEventName(WEB_SOCKET_EVENT_CODE.GROUP_MEMBER_ADDED, handleConversationEventInPatientContext,  {moduleCode: PATIENT_CONTEXT_MODULE_CODE});
      eventBus.removeEventListenerByEventName(WEB_SOCKET_EVENT_CODE.GROUP_MEMBER_REMOVED, handleConversationEventInPatientContext,  {moduleCode: PATIENT_CONTEXT_MODULE_CODE});
      eventBus.removeEventListenerByEventName(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_GROUP_CONVERSATION_CREATED, handleConversationEventInPatientContext,  {moduleCode: PATIENT_CONTEXT_MODULE_CODE});
      eventBus.removeEventListenerByEventName(CONVERSATION_LOCAL_EVENT_CODES.CONVERSATION_LAST_SEEN_UPDATE, handleConversationEventInPatientContext,  {moduleCode: PATIENT_CONTEXT_MODULE_CODE});
      eventBus.removeEventListenerByEventName(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_MSG_READ, handleConversationEventInPatientContext,  {moduleCode: PATIENT_CONTEXT_MODULE_CODE});
      eventBus.removeEventListenerByEventName(CONVERSATION_LOCAL_EVENT_CODES.NEW_MESSAGE_CREATED, handleConversationEventInPatientContext,  {moduleCode: PATIENT_CONTEXT_MODULE_CODE});
    };
  }, [conversationListState]);
};
