import {Pressable, Spinner} from 'native-base';
import {Colors} from '../../../../../../../styles/Colors';
import {styles} from './styles';
import {Text} from 'react-native';
import { testID } from '../../../../../../../testUtils';

export const dayOpRibbonActionCommonStyledButton = ({
  text,
  content,
  onPress,
  customHoverStyles,
  showText = true,
  isLoading = false,
  hideContainerWhileLoading = false,
  testId
}: {
  showText?: boolean;
  content?: JSX.Element;
  hideContainerWhileLoading?: boolean;
  text?: string;
  onPress: () => void;
  customHoverStyles?: {
    backgroundColor: string;
  };
  isLoading?: boolean;
  testId?: string;
}) => {
  return (
    <Pressable
      style={{
        borderRadius: 4,
        padding: 4,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      isDisabled={isLoading}
      _hover={{
        backgroundColor: Colors.FoldPixel.GRAY50,
        ...(customHoverStyles ? customHoverStyles : {}),
      }}
      onPress={onPress}
      {...(testId ? testID(testId) : {})}
    >
      {showText ? (
        <Text style={styles.text14primary300fw400}>{text}</Text>
      ) : hideContainerWhileLoading && isLoading ? (
        <></>
      ) : (
        content
      )}

      {isLoading && (
        <Spinner marginLeft={hideContainerWhileLoading && isLoading ? 0 : 2} />
      )}
    </Pressable>
  );
};
