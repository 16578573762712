import { Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Colors } from '../../../styles';
import { Divider, HStack, Input, Text, VStack, FormControl, Stack } from 'native-base';
import { FoldButton } from '../../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE } from '../../../constants';
import { useIntl } from 'react-intl';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { DateTimeDurationInput } from '../DateTimeDurationInput/DateTimeDurationInput';
import { CaretDownOutlined } from '@ant-design/icons';
import EditIconSvg from '../Svg/InterventionSvg/EditIconSvg';
import { interventionType, interventiondurationMlovs, INTERVENTION_IMMEDIATE_CODE, DEFAULT_IMMEDIATE_DURATION_UNIT } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/AddOrUpdateIntervention/InterventionConstants';
import { getInterventionIcon, getInterventionType } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/AddOrUpdateIntervention/AddOrUpdateInterventionHelper';
import { IInterventionType } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/interfaces';
import InterventionRecurrence, {IFrequencyObjectState} from './InterventionRecurrence';
import {frequencyPeriodUnitValuesByCode} from '../../RightSideContainer/Workflow/UserInputFields/FrequencyUserInputField/FrequencyUserInputField';
import Feather from 'react-native-vector-icons/Feather';

interface IInterventionRowProps {
    disabled?: boolean;
    isInvalid?: boolean;
    onInterventionTypeChange: (value: string) => void
    onInterventionTitleChange: (value: string) => void
    onCancel: () => void
    onSaveIntervention: (recurrence?: {
     frequency: any | undefined;
     duration: any | undefined;
     displayValue?: string;
    }) => void
    onTaskEdit: (value: string) => void
    onDurationChange: (value: any) => void
    interventionTitle?: string
    selectedDuration: any
    metdataError: boolean
    selectedIntervention: string | undefined
    disableIntervention: boolean
    recurrence?: {
        frequency: any | undefined;
        duration: any | undefined;
        displayValue?: string;
    }
    durationInfoMessage?: any;
    disableSaveButton?: boolean;
    openDrawer: () => void;
    dropdownPlacement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
}

interface IInterventionTypeState {
    loading: boolean;
    selectedInterventionType: string | undefined;
    interventionTitle: string;
    selectedDuration: { id: string, value: string };
}

const InterventionRow = (props: IInterventionRowProps) => {
    const [openType, setOpenType] = useState(false)
    const {
        onInterventionTypeChange,
        onInterventionTitleChange,
        onDurationChange,
        onCancel,
        onSaveIntervention,
        onTaskEdit,
        metdataError,
        disableIntervention,
        interventionTitle,
        selectedDuration,
        selectedIntervention,
        disableSaveButton,
        openDrawer
    } = props;
    const initialDuration = {
        id: 'day',
        value: '1'
    }
    const [componentState, setComponentState] = useState<IInterventionTypeState>({
        loading: false,
        selectedInterventionType: '',
        selectedDuration: initialDuration,
        interventionTitle: '',
    });
    const [interventionRecurrence, setInterventionRecurrence] = useState<{
      frequencyObject: IFrequencyObjectState | undefined;
      displayValue: string;
    }>({
      frequencyObject: {
        code: {
          value: props?.recurrence?.frequency?.code || '',
          isValid: true,
        },
        frequency: {
          value: props?.recurrence?.frequency?.times || 1,
          isValid: true,
        },
        period: {
          value: props?.recurrence?.frequency?.per?.value || 1,
          isValid: true,
        },
        periodUnit: {
          value: props?.recurrence?.frequency?.per?.unit || '',
          isValid: true,
        },
        endValue: {
          value: props?.recurrence?.duration?.value || '',
          isValid: true,
        },
        endUnit: {
          value: props?.recurrence?.duration?.unit || '',
          isValid: true,
        },
      },
      displayValue: props.recurrence?.displayValue || '',
    });
    const [recurrenceEnabled, setRecurrenceEnabled] = useState(
      !!(props?.recurrence?.frequency && Object.keys(props?.recurrence?.frequency ?? {}).length > 0)
    );
    const [errors, setErrors] = useState<{ interventionType: boolean, executionAfter: boolean, interventionTitle: boolean, recurrence: boolean }>({
        interventionType: false,
        executionAfter: false,
        interventionTitle: false,
        recurrence: false
    });
    const commonData = useContext(CommonDataContext);
    const [isInterventionError, setIsInterventionError] = useState('')
    const intl = useIntl();

    useEffect(() => {
      setComponentState((prev) => ({
        ...prev,
        interventionTitle: interventionTitle || '',
        selectedDuration:
          selectedDuration === DEFAULT_IMMEDIATE_DURATION_UNIT
            ? {unit: INTERVENTION_IMMEDIATE_CODE, value: 10}
            : selectedDuration,
      }));

    }, [interventionTitle, selectedIntervention])

    useEffect(() => {
      const selectedInterventionType: IInterventionType | undefined = getInterventionType(selectedIntervention)
      setComponentState((prev) => ({
          ...prev,
          selectedInterventionType: selectedInterventionType?.value || selectedIntervention
      }))
    }, [selectedIntervention])

    useEffect(() => {
        if (metdataError) {
            handleSave(metdataError)
        }
    }, [metdataError])

    function getDisplayValue(frequencyObject: IFrequencyObjectState) {
        if (isFormValid(frequencyObject)) {
          const isFrequencyCodeValue = frequencyObject.code.value;
          const frequencyValue = frequencyObject.frequency.value;
          const periodValue = frequencyObject.period.value;
          const periodUnitValue = frequencyObject.periodUnit.value;

          const frequencyDisplayValue = (frequencyValue === 1) ? 'once' : `${frequencyValue} times`;
          const periodDisplayValue= (periodValue === 1) ? 'every' : `every ${periodValue}`;
          let periodUnitDisplayValue= (frequencyPeriodUnitValuesByCode[periodUnitValue] || periodUnitValue || '').toLowerCase();
          let endsValue = '';
            if (frequencyObject.endValue.value && frequencyObject.endUnit.value) {
              const endValue = frequencyObject.endValue.value;
              const endUnit = frequencyObject.endUnit.value.toLowerCase();
              endsValue = `for ${endValue} ${endUnit}${Number(endValue) > 1 ? 's' : ''}`;
            }
          if (periodValue > 1) {periodUnitDisplayValue += 's'}
          return `${frequencyDisplayValue} ${periodDisplayValue} ${periodUnitDisplayValue} ${endsValue}`;

        } else {
          return '';
        }
      }

    function isFormValid(frequencyObject?: IFrequencyObjectState) {
        if (!frequencyObject) {
            return false;
        }
      const isFrequencyCodeValid = frequencyObject.code.isValid;
      const isFrequencyValid = frequencyObject.frequency.isValid;
      const isPeriodValid = frequencyObject.period.isValid;
      const isPeriodUnitValid = frequencyObject.periodUnit.isValid;

      return isFrequencyValid && isPeriodValid && isPeriodUnitValid;
    }



    const formatRecurence = (frequencyObjectState?: IFrequencyObjectState) => {
      if (!frequencyObjectState) {
        return {
          frequency: undefined,
          duration: undefined,
        };
      }
      const isFrequencyCodeValue = frequencyObjectState.code.value;
      const frequencyValue = frequencyObjectState.frequency.value;
      const periodValue = frequencyObjectState.period.value;
      const periodUnitValue = frequencyObjectState.periodUnit.value;

      const value = {
        code: isFrequencyCodeValue,
        times: frequencyValue,
        per: {
          value: periodValue,
          unit: periodUnitValue,
        },
      };
      const end = {
        value: frequencyObjectState.endValue.value,
        unit: frequencyObjectState.endUnit.value,
      };
      return {
        frequency: value,
        duration: end,
      };
    };

    const handleSave = (metaDataError: boolean) => {
        const isValid = recurrenceEnabled ? isFormValid(interventionRecurrence.frequencyObject) : true;
        const reccurence =  formatRecurence(interventionRecurrence.frequencyObject)
        const newErrors = {
            interventionType: componentState.selectedInterventionType && componentState.selectedInterventionType.length > 1 ? false : true,
            executionAfter: Number(componentState.selectedDuration.value) > 0 ? false : true,
            interventionTitle: componentState.interventionTitle.length > 1 ? false : true,
            recurrence: !isValid
        };

        setErrors(newErrors);

        if (!newErrors.interventionType && !newErrors.executionAfter && !newErrors.interventionTitle && !metaDataError && newErrors.recurrence === false) {
            onSaveIntervention(recurrenceEnabled ? {...reccurence, displayValue: interventionRecurrence.displayValue} : undefined);
        } else {
            if (metaDataError) {
                setIsInterventionError('Type details are mandatory')
            } else {
                Object.values(newErrors).some((error) => error !== null) ? setIsInterventionError('All fields are mandatory') : setIsInterventionError('');
            }
        }
    };

    return (
      <VStack
        style={{
          borderBottomWidth: 1,
          width: '100%',
          borderColor: Colors.FoldPixel.GRAY150,
          alignSelf: 'center',
        }}
      >
        <HStack
          w={'100%'}
          style={{padding: 15, flex: 1, justifyContent: 'space-between'}}
        >
          <FormControl width={'20%'} isInvalid={errors.interventionType}>
            <FormControl.Label isRequired={true}><Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Type</Text></FormControl.Label>
            <Select
              value={componentState.selectedInterventionType || undefined}
              placeholder={'Select Intervention Type'}
              disabled={disableIntervention}
              open={openType && !disableIntervention}
              onChange={() => setOpenType(!openType)}
              onClick={() => {
                if (!disableIntervention) {
                  setOpenType(true);
                }
                if (
                  !disableIntervention &&
                  componentState.selectedInterventionType
                ) {
                  onTaskEdit(componentState.selectedInterventionType);
                }
              }}
              onBlur={() => setOpenType(!openType)}
              suffixIcon={
                <CaretDownOutlined onClick={() => setOpenType(!openType)} />
              }
              onSelect={(value: any) => {
                if (
                  componentState.selectedInterventionType &&
                  value !==
                    getInterventionType(componentState.selectedInterventionType)
                      ?.code
                ) {
                  setOpenType(false);
                  setComponentState((prev) => ({
                    ...prev,
                    selectedInterventionType: value,
                  }));
                  setErrors((prev) => ({
                    ...prev,
                    interventionType: false,
                  }));
                }
                onInterventionTypeChange(value);
              }}
              className={
                errors.interventionType ? 'field-error' : 'intervention-type'
              }
            >
              {interventionType.map((item: any, index: any) => (
                <>
                  {item.code === 'internalTask' && <Divider />}
                  <Select.Option key={index} value={item?.code}>
                    <HStack alignItems={'center'} h={'35px'}>
                      {getInterventionIcon(item?.code)}
                      <Text marginLeft={-3} textOverflow={'ellipsis'}>
                        {item.value}
                      </Text>
                    </HStack>
                  </Select.Option>
                </>
              ))}
            </Select>
          </FormControl>
          <FormControl
            style={{width: '60%', paddingLeft: 15, paddingRight: 15}}
            isInvalid={errors.interventionTitle}
          >
            <FormControl.Label isRequired={true}> <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Title</Text> </FormControl.Label>
            <Stack direction={'row'} style={{alignItems: 'center', paddingRight: 4, borderWidth: 0.5, borderColor: Colors.FoldPixel.GRAY200, borderRadius: 4}}>
              <Input
                value={interventionTitle}
                width={'100%'}
                borderWidth={0}
                onChangeText={(text: string) => {
                  setComponentState((prev) => ({
                    ...prev,
                    interventionTitle: text,
                  }));
                  onInterventionTitleChange(text);
                }}
                height={38}
                background={'#FFFFFF'}
                borderRadius={4}
                textAlign={'left'}
                fontSize={14}
                fontWeight={500}
                lineHeight={20}
                paddingX={0}
                placeholder="Enter the Intervention Title"
                // _hover={{backgroundColor: '#fff', overflow: 'hidden'}}
              />
              <Feather
                name="arrow-up-right"
                style={{fontWeight: '100', width: 16, height: 16}}
                color={Colors.FoldPixel.GRAY300}
                size={16}
                onPress={() => {
                  if (componentState.selectedInterventionType) {
                    openDrawer();
                  }
                }}
              />
            </Stack>
          </FormControl>
          <FormControl width={'20%'} isInvalid={errors.executionAfter}>
            <FormControl.Label isRequired={true}>
              <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Execute After</Text>
            </FormControl.Label>
            <DateTimeDurationInput
              durationUnits={interventiondurationMlovs}
              borderColor={
                errors.executionAfter
                  ? Colors.Custom.ErrorColor
                  : Colors.Custom.Gray300
              }
              borderWidth={1}
              inputFieldWidth={50}
              suffixIcon={<CaretDownOutlined />}
              hideInputField={selectedDuration.unit === INTERVENTION_IMMEDIATE_CODE || selectedDuration.unit === DEFAULT_IMMEDIATE_DURATION_UNIT}
              value={selectedDuration.value}
              placeholder={'Enter the time'}
              selectedDurationUnitId={selectedDuration.unit === DEFAULT_IMMEDIATE_DURATION_UNIT ? INTERVENTION_IMMEDIATE_CODE : selectedDuration.unit}
              onValueChange={(value) => {
                onDurationChange({value: parseInt(value)});
              }}
              onDurationUnitChange={(id) => {
                onDurationChange({ unit: id});
              }}
              dropdownPlacement={props?.dropdownPlacement}
            />
          </FormControl>
        </HStack>
        <InterventionRecurrence
          repeat={recurrenceEnabled}
          onChange={(data: IFrequencyObjectState | undefined) => {
            const displayValue = data ? getDisplayValue(data) : '';
            setInterventionRecurrence({
              frequencyObject: data,
              displayValue,
            });
          }}
          onRepeatChange={(value: boolean) => {
            setRecurrenceEnabled(value);
          }}
          frequency={interventionRecurrence.frequencyObject?.frequency?.value}
          period={interventionRecurrence.frequencyObject?.period?.value}
          periodUnit={interventionRecurrence.frequencyObject?.periodUnit?.value}
          endValue={
            interventionRecurrence.frequencyObject?.endValue?.value
              ? parseInt(
                  interventionRecurrence?.frequencyObject?.endValue?.value as string
                )
              : undefined
          }
          endUnit={interventionRecurrence.frequencyObject?.endUnit?.value}
        />

        {isInterventionError && (
          <Text
            style={{
              color: Colors.Custom.ErrorColor,
              fontWeight: '500',
              paddingLeft: 15,
            }}
          >
            {isInterventionError}
          </Text>
        )}
        <Stack
          direction="row"
          style={{justifyContent: 'flex-end', alignItems: 'center'}}
          padding={15}
        >
          <HStack space={2} style={{marginRight: 'auto'}}>
            {props?.durationInfoMessage && (
              <Text color={Colors.Custom.InterventionDurationInfoColor}>
                {props?.durationInfoMessage}
              </Text>
            )}
            {recurrenceEnabled && props?.durationInfoMessage && interventionRecurrence.displayValue && (
              <Text color={Colors.Custom.Gray400}>•</Text>
            )}
            {recurrenceEnabled && interventionRecurrence.displayValue && (
              <Text color={Colors.Custom.Gray400}>
                Occurs {interventionRecurrence.displayValue}
              </Text>
            )}
          </HStack>
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.SECONDARY,
              style: {margin: 3},
              onPress: onCancel,
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'cancel',
              }),
              withRightBorder: false,
            }}
          />
          {disableSaveButton ? <></> :
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              style: {margin: 3},
              onPress: () => handleSave(false),
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'save',
              }),
              withRightBorder: false,
              }}
            />
          }
        </Stack>
      </VStack>
    );
};

export default InterventionRow;
