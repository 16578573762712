import {Colors} from '../../../styles/Colors';
import {Collapse, Table, Select as AntSelect,Input} from 'antd';
import React, { useMemo } from 'react';
import './Styles.css';
import {
  Box,
  HStack,
  Text,
  View,
  Badge,
  Skeleton,
  VStack,
  Pressable,
  TextArea,
  Tooltip,
  Spinner,
  useToast
} from 'native-base';
import CommonService from '../../../services/CommonService/CommonService';
import {useEffect, useState, useContext} from 'react';
import {getDisplayDateFormatted} from '../../../utils/DateUtils';
import SirenSvg from '../../common/Svg/SirenSvg';
import {FeedbackReasonItem, FeedbackReasonState, SelectedTemplate, Template} from './interfaces';
import HardRefreshIconSvg from '../../common/Svg/HardRefreshSvg';
import UnitySvg from '../../common/Svg/UnitySvg';
import {LLMSkeletonLoader} from './LLMSkeletonLoader';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useIntl} from 'react-intl';
import { CommonDataContext } from '../../../context/CommonDataContext';

import NoDataFoundWithCustomIcon from '../../common/NoDataFound/NoDataFoundWithCustomIcon';
import InfoCircleSvg from '../../common/Svg/InfoCircleSvg';
import { getUserUUID } from '../../../utils/commonUtils';
import DislikeSvg from '../../common/Svg/DislikeSvg';
import LikeSvg from '../../common/Svg/LikeSvg';
import CheckSvg from '../../common/Svg/CheckSvg';
import CloseSvg from '../../common/Svg/CloseSvg';
import { formatTimestamp } from '../../../utils/llmUtils';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import { testID, TestIdentifiers } from '../../../testUtils';
import { StyleSheet } from 'react-native';

const {Option} = AntSelect;

export const AlertTable = (props: {contactUUID: any}) => {
  const {Panel} = Collapse;
  const toast = useToast();
  const [loadingDisabledFlag, setLoadingDisabledFlag] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [lastSync, setLastSync] = useState<string>('');
  const [defaultTemplate, setDefaultTemplate] = useState<{
    templateCode: string;
    templateStr: string;
    templateName: string;
  }>({
    templateCode: '',
    templateStr: '',
    templateName: '',
  });
  const [stateData, setStateData] = useState<{
    data: any[];
    loading: boolean;
  }>({
    data: [],
    loading: true,
  });
  const [templateList, setTemplateList] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] =
    useState<SelectedTemplate | null>(null);
  const [resultID, setResultID] = useState('');
  const userUUID = getUserUUID();
  const [reasonCode, setReasonsCode] = useState<FeedbackReasonState>({
    all: [],
    thumpsUp: [],
    thumpsDown: [],
  });

  const [showFeedbackModal, setShowFeedbackModal] = useState<{
    loading :  boolean,
     show : boolean
  }>({
    loading : false,
    show : false
  });

  const [feedback, setFeedback] = useState<{
    score: any;
    comment: string;
  }>({
    score: undefined,
    comment: '',
  });

  const [selectedTag, setSelectedTag] = useState<{
    index: string | number;
    selected: boolean;
    shortCode: string;
    textInputReason: string;
  }>({
    index: -1,
    selected: false,
    shortCode: '',
    textInputReason: '',
  });
  useEffect(() => {
    getFeedBackStatus(resultID, userUUID);
  }, [resultID,reasonCode.all]);
  const commonService = CommonService.getCommonServiceInstance();
  const llmflowService = commonService.llmService;
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const lastSyncMessage = `Last Synced: ${lastSync}`
  const fetchData = (
    templateCode: any,
    templateStr: any,
    isCached: boolean
  ) => {
    llmflowService
      .post('/unity/abnormalities', {
        contactUuid: props.contactUUID,
        templateCode: templateCode,
        templateStr: templateStr,
        isCatched: isCached,
      })
      .then((result: any) => {
        setStateData({
          data: Array.isArray(result?.data?.list)
            ? result?.data?.list
            : Array.isArray(result?.data?.list?.alerts)
            ? result?.data?.list?.alerts
            : [],
          loading: false,
        });
        setLastSync(formatTimestamp(result?.data?.syncDate))
        setIsActive(false);
        setLoadingDisabledFlag(false);
        setShowFeedbackModal( (prev)=>({...prev, show:false}));
        setResultID(result?.data?.id);
        getFeedBackStatus(result?.data?.id, userUUID);
        setSelectedTag({
          index: -1,
          selected: false,
          shortCode: '',
          textInputReason: '',
        });
      })
      .catch((error: any) => {
        setIsActive(false);

        setLoadingDisabledFlag(false);
        setStateData({
          data: [],
          loading: false,
        });
      });
  };

  const fetchTemplates = () => {
    llmflowService
      .get('/abnormalities/template', {
        contactUuid: props.contactUUID,
      })
      .then((result: any) => {
        setSelectedTemplate((prev) => {
          return {
            ...prev,
            templateName: result?.data?.templateList?.[0]?.templateName || '',
          };
        });
        setDefaultTemplate({
          templateCode: result?.data?.templateList?.[0]?.templateCode,
          templateStr: result?.data?.templateList?.[0]?.templateStr,
          templateName: result?.data?.templateList?.[0]?.templateName,
        });
        fetchData(
          result?.data?.templateList?.[0]?.templateCode,
          result?.data?.templateList?.[0]?.templateStr,
          true
        );
        setTemplateList(result?.data?.templateList);
      })
      .catch((error: any) => {

        setLoadingDisabledFlag(false);
        setStateData({
          data: [],
          loading: false,
        });
      });
  };

  const columns = [
    {
      title: 'Alert',
      dataIndex: 'alert name',
      key: 'alert_name',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
    },
  ];

  const loaderTemeplateValues = [
    intl.formatMessage({id: 'monitoringVitalSigns'}),
    intl.formatMessage({id: 'analyzingImagingLabResults'}),
    intl.formatMessage({id: 'synthesizingLearnings'}),
    intl.formatMessage({id: 'detectingAlerts'}),
  ];

  const fetchReasonCodes = () => {
    llmflowService
      .get(`/v1/feedback/reason_code`)
      .then((result: any) => {
        const filteredReasonCodeThumbDown = result?.data?.reason_codes?.filter(
          (reason: any) => !reason.reaction_code || reason.reaction_code === null
        );
        const filteredReasonCodeThumbup = result?.data?.reason_codes?.filter(
          (reason: any) => reason.reaction_code || reason.reaction_code === null
        );
        setReasonsCode({ all: result?.data?.reason_codes,thumpsUp :filteredReasonCodeThumbup ,thumpsDown:filteredReasonCodeThumbDown});

      })
      .catch((error: any) => {

      });
  };

  const postFeedback = () => {
    llmflowService
      .post(`/v1/feedback`, {
        user_id: userUUID,
        result_id: resultID,
        score: feedback.score,
        comment:
          selectedTag.shortCode === 'Other'
            ? selectedTag.textInputReason
            : selectedTag.shortCode,
      })
      .then((result: any) => {
        setShowFeedbackModal({loading : false,show :false});
      })
      .catch((error: any) => {
        setShowFeedbackModal({loading : false,show :false});
        showToast(
          toast,
          'Unable to save the Feedback',
          ToastType.error,
          1000
        );
        getFeedBackStatus(resultID,userUUID)
      });
  };

  const getFeedBackStatus = (resultID: any, userID: any) => {
    resultID  &&
    llmflowService
      .get(`v1/feedback/status`, {
        params: {
          result_id: resultID,
          user_id: userID,
        },
      })
      .then((result: any) => {
        setFeedback({
          comment: result?.data?.data?.comment,
          score: result?.data?.data?.score,
        });
        const isShortCodePresent = reasonCode.all.some(
          (item) => item.short_code === result?.data?.data?.comment
        );
        if (result?.data?.data?.score !== -1 && reasonCode.all.length) {
          if (isShortCodePresent) {
            setSelectedTag((prev) => ({
              ...prev,
              index: result?.data?.data?.comment,
              selected: true,
              shortCode: result?.data?.data?.comment,
              textInputReason: '',
            }));
          } else if (result?.data?.data?.comment){
              setSelectedTag((prev) => ({
                ...prev,
                index: 'Other',
                selected: true,
                shortCode: 'Other',
                textInputReason: result?.data?.data?.comment,
              }));
          }
        } else {
          setSelectedTag({
            index: -1,
            selected: false,
            shortCode: '',
            textInputReason: '',
          });
        }
      });
  };

  const renderTags = (reasonCode: FeedbackReasonItem, index: string) => {
    const isSelected = selectedTag.index === index && selectedTag.selected;

    const textStyle = useMemo(() => {
      return isSelected ? [styles.tagText, styles.selectedTagText] : styles.tagText;
    }, [isSelected]);

    return (
          <Pressable
            key={reasonCode.id}
            onPress={() => {
              setSelectedTag((prev) => {
                return {
                  ...prev,
                  index: index,
                  selected: !isSelected,
                  shortCode: !isSelected? reasonCode.short_code :'',
                };
              })
            }}
          >
            <View 
              style={styles.tagContainer}
              backgroundColor={
                isSelected
                ? Colors.FoldPixel.PRIMARY100
                : Colors.FoldPixel.GRAY50
              }
            >
              <Text style={textStyle}>
                {reasonCode.short_code}
              </Text>
              {isSelected && (
                <Pressable
                  onPress={() => {
                    setSelectedTag((prev) => {
                      return {
                        ...prev,
                        previndex:undefined,
                        selected: false,
                        shortCode: '',
                      };
                    });
                  }}
                >
                  <AntIcon color={isSelected ? Colors.FoldPixel.PRIMARY300 : Colors.Custom.Gray500} name='close' />
                </Pressable>
              )}
            </View>
          </Pressable>
    );
  };

  useEffect(() => {
    fetchReasonCodes();
  }, []);


  useEffect(() => {
    fetchTemplates();
  }, []);

  const antdStyles: Record<string, React.CSSProperties> = {
    collapse: {
      borderWidth: 0.5,
      borderColor: Colors.Custom.ContainerBGColor,
      padding: 0,
    },
    select: {
      borderColor: 'transparent',
    },
  };
  return (
    <Box
      mb={2}
      shadow='0px 4px 8px -2px #10182819, 0px 2px 4px -2px #101828f'
      borderRadius={8}
      borderColor={Colors.Custom.BorderColor}
      borderWidth={1}
    >
      <Collapse
        className='llm-collapse'
        defaultActiveKey={[1]}
        collapsible='header'
        style={antdStyles.collapse}
        expandIconPosition='end'
      >
        <Panel
          forceRender
          extra={
            <div className='llm-panel'>
              <View minWidth={100}>
                <AntSelect
                  placement='bottomRight'
                  style={antdStyles.select}
                  loading={loadingDisabledFlag}
                  allowClear={false}
                  value={selectedTemplate?.templateName}
                  disabled={loadingDisabledFlag}
                  onChange={(result: any) => {
                    setLoadingDisabledFlag(true);
                    setStateData({
                      data: [],
                      loading: true,
                    });
                    const templates = templateList.filter(
                      (template) => template.templateName === result
                    );
                    const templateStr =
                      templates.length > 0 ? templates?.[0].templateStr : '';
                    const templateName =
                      templates.length > 0 ? templates?.[0].templateName : '';
                    setSelectedTemplate((prev) => {
                      return {
                        ...prev,
                        templateName: result,
                      };
                    });
                    fetchData(result, templateStr, true);
                  }}
                  {...testID(TestIdentifiers.llm.alerts.alertsDropDown)}
                >
                  {templateList.map((template: any) => {
                    return (
                      <Option
                        value={template.templateCode}
                        key={template.templateCode}
                        title={template.templateName}
                      >
                        {template.templateName}
                      </Option>
                    );
                  })}
                </AntSelect>
              </View>
            </div>
          }
          header={
            <HStack alignItems={'center'} space={3}>
              <Text mt={1} {...testID(TestIdentifiers.llm.alerts.alertsTitle)} size={'lgMedium'} color={Colors.FoldPixel.GRAY300}>
                {intl.formatMessage({id: 'alerts'})}
              </Text>
              {stateData?.data?.length && stateData?.data?.length > 0 ? (
                <Badge
                  fontSize={12}
                  fontWeight={500}
                  backgroundColor={Colors.Custom.Gray100}
                  _text={{
                    color: Colors.FoldPixel.GRAY200,
                  }}
                  alignSelf='center'
                  rounded='sm'
                  justifyContent={'center'}
                  paddingX={2}
                  paddingY={1}
                >
                  {stateData.data.length}
                </Badge>
              ) : null}
            </HStack>
          }
          key={'1'}
        >
          <div className='llm-content'>
            <View padding={4}>
              {stateData.loading ? (
                <View padding={4} height={200} justifyContent={'center'}>
                  <LLMSkeletonLoader texts={loaderTemeplateValues} iconKey={'alerttable' + 'skeleton'}/>
                </View>
              ) : (
                <View justifyContent={'center'}>
                  {stateData.data.length > 0 ? (
                    <VStack flex={1}>
                      <HStack
                        flex={1}
                        padding={1}
                        style={styles.headerContainer}
                      >
                        <View
                          flex={0.7}
                          padding={2}
                          fontWeight={600}
                          color={Colors.Custom.Gray500}
                        >
                          {intl.formatMessage({id: 'alert'})}
                        </View>
                        <View
                          flex={0.3}
                          padding={2}
                          fontWeight={600}
                          color={Colors.Custom.Gray500}
                        >
                          {intl.formatMessage({id: 'value'})}
                        </View>
                      </HStack>
                      {stateData.data.map((item: any) => (
                        <HStack flex={1} key={item.id} padding={1}>
                          <VStack flex={0.7} padding={2}>
                            <View>{item.alert_name || item['alert name']}</View>
                            <View
                              fontWeight={400}
                              fontSize={12}
                              color={Colors.FoldPixel.GRAY300}
                            >
                              {getDisplayDateFormatted(item.timestamp)}
                            </View>
                          </VStack>
                          <View flex={0.3} padding={2}>
                            {item.value}
                          </View>
                        </HStack>
                      ))}
                    </VStack>
                  ) : (
                    <NoDataFoundWithCustomIcon
                      icon={<InfoCircleSvg></InfoCircleSvg>}
                      displayString={intl.formatMessage({id: 'noDataFound'})}
                    ></NoDataFoundWithCustomIcon>
                  )}
                </View>
              )}
            </View>
            <HStack
              space={2}
              style={styles.footerContainer}
            >
              <View
                style={styles.poweredByUnityContainer}
                {...testID(TestIdentifiers.llm.alerts.alertsPoweredByUnity)}
              >
                <UnitySvg iconKey={'alerttable' + 'UnitySvg'} />
                <Text
                  marginLeft={2}
                  size={'xsNormal'}
                  fontWeight={600}
                  noOfLines={1}
                  style={styles.poweredByUnityText}
                >
                  {intl.formatMessage({id: 'poweredByUnity'})}
                </Text>

                <Text
                  marginLeft={2}
                  size={'xsNormal'}
                  fontWeight={550}
                  noOfLines={1}
                  style={styles.alphaText}
                >
                  {intl.formatMessage({id: 'alpha'})}
                </Text>
              </View>
              {!stateData.loading && stateData.data.length > 0 && (
                <>
                  <Tooltip
                    label={intl.formatMessage({id: 'dislike'})}
                    placement='top'
                    hasArrow
                  >
                    <Pressable
                      flexDirection={'row'}
                      alignItems={'center'}
                      onPress={() => {
                        setFeedback({score: 0, comment: ''});
                        setShowFeedbackModal((prev) => ({...prev, show: true}));
                      }}
                      {...testID(TestIdentifiers.llm.alerts.alertsThumpsDown)}
                    >
                      <DislikeSvg
                        isActive={feedback.score === 0 ? true : false}
                        keyy={'alerttable'}
                      />
                    </Pressable>
                  </Tooltip>
                  <Tooltip
                    label={intl.formatMessage({id: 'like'})}
                    placement='top'
                    hasArrow
                  >
                    <Pressable
                      flexDirection={'row'}
                      alignItems={'center'}
                      onPress={() => {
                        setFeedback({score: 1, comment: ''});
                        setShowFeedbackModal((prev) => ({...prev, show: true}));
                      }}
                      {...testID(TestIdentifiers.llm.alerts.alertsThumbsUp)}
                    >
                      <LikeSvg isActive={feedback.score === 1 ? true : false} keyy={'alerttable'} />
                    </Pressable>
                  </Tooltip>
                </>
              )}
              <Tooltip
                isDisabled={!lastSync}
                label={lastSyncMessage}
                placement='top right'
                hasArrow
              >
                <Pressable
                  flexDirection={'row'}
                  alignItems={'center'}
                  onPress={() => {
                    setIsActive(true);
                    setLoadingDisabledFlag(true);
                    const data = {
                      templateName:
                        selectedTemplate?.templateName ||
                        defaultTemplate?.templateCode,
                      templateStr:
                        templateList.find(
                          (item) =>
                            item?.templateName == selectedTemplate?.templateName
                        )?.templateStr || defaultTemplate?.templateStr,
                      isCached: false,
                    };
                    fetchData(
                      data.templateName,
                      data.templateStr,
                      data.isCached
                    );
                    setStateData({
                      data: [],
                      loading: true,
                    });
                    setSelectedTemplate((prev) => {
                      return {
                        ...prev,
                        templateName: selectedTemplate?.templateName || '',
                      };
                    });
                  }}
                  {...testID(TestIdentifiers.llm.alerts.alertsHardRefresh)}
                >
                  <HardRefreshIconSvg isActive={isActive} iconKey={'alerttable'}/>
                </Pressable>
              </Tooltip>
            </HStack>
            {!stateData.loading && showFeedbackModal.show && reasonCode.all && (
              <VStack style={styles.feedbackModalContainer}>
                <HStack padding={2} flex={1}>
                  <Text style={styles.feedbackText}>
                    {feedback.score === 0 ? 'Help us to Improve.' : null}
                    {feedback.score === 1 ? 'Thanks! Any more feedback?' : null}
                  </Text>
                  <Pressable
                    marginLeft={2}
                    onPress={() => {
                      postFeedback();
                      setShowFeedbackModal((prev) => ({
                        ...prev,
                        loading: true,
                      }));
                    }}
                    flex={0.1}
                  >
                    {showFeedbackModal.loading ? (
                      <Spinner></Spinner>
                    ) : (
                      <CheckSvg></CheckSvg>
                    )}
                  </Pressable>
                  <Pressable
                    marginLeft={2}
                    flex={0.1}
                    onPress={() => {
                      setShowFeedbackModal((prev) => ({...prev, show: false}));
                      setFeedback({score: undefined, comment: ''});
                      getFeedBackStatus(resultID, userUUID);
                    }}
                  >
                    <CloseSvg></CloseSvg>
                  </Pressable>
                </HStack>
                <View
                  flexWrap={'wrap'}
                  flexDir={'row'}
                  paddingX={2}
                  paddingBottom={selectedTag.shortCode === 'Other' ? 0 : 2}
                >
                  <>
                    {feedback.score == 0 &&
                      reasonCode.thumpsDown.map((name: FeedbackReasonItem, index: number) =>
                        renderTags(name, name.short_code)
                      )}
                    {feedback.score == 1 &&
                      reasonCode.thumpsUp.map((name: FeedbackReasonItem, index: number) =>
                        renderTags(name, name.short_code)
                      )}
                  </>
                </View>

                {selectedTag.shortCode === 'Other' && selectedTag.index && (
                  <HStack flex={1} padding={2}>
                    <TextArea
                      value={selectedTag.textInputReason}
                      _focus={{
                        borderColor: Colors.Custom.Gray300,
                      }}
                      borderColor={Colors.Custom.Gray300}
                      placeholderTextColor={Colors.Custom.Gray300}
                      width={'100%'}
                      numberOfLines={5}
                      onChangeText={(input) => {
                        setSelectedTag((prev: any) => {
                          return {
                            ...prev,
                            textInputReason: input,
                          };
                        });
                      }}
                      placeholder='Provide Additional Feedback'
                    />
                  </HStack>
                )}
              </VStack>
            )}
          </div>
        </Panel>
      </Collapse>
    </Box>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    borderBottomWidth: 0.5,
    borderBottomColor: Colors.FoldPixel.GRAY200,
    borderStyle: 'solid',
  },
  timestampText: {
    fontWeight: '400',
    fontSize: 12,
    color: Colors.FoldPixel.GRAY300,
  },
  footerContainer: {
    flexDirection: 'row',
    paddingHorizontal: 12,
    display: 'flex',
    justifyContent: 'space-between',
    paddingVertical: 5,
    borderBottomLeftRadius: 9,
    borderBottomRightRadius: 9,
  },
  poweredByUnityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  feedbackModalContainer: {
    borderColor: Colors.Custom.Gray200,
    borderWidth: 1,
    borderRadius: 12,
    flex: 1,
    margin: 3,
  },
  feedbackText: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 14.4,
    color: Colors.FoldPixel.GRAY300,
    flex: 2,
  },
  tagsContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingHorizontal: 2,
    paddingBottom: 2,
  },
  tagsContainerWithOther: {
    paddingBottom: 0,
  },
  tagContainer: {
    marginTop: 4,
    borderRadius: 4,
    paddingHorizontal: 4,
    // backgroundColor: Colors.FoldPixel.GRAY50,
    marginRight: 4,
    borderWidth: 0.1,
    borderColor: Colors.FoldPixel.GRAY50,
    flexDirection: 'row',
    alignItems: 'center',
  },
  tagText: {
    color: Colors.Custom.Gray500,
    fontSize: 14,
    marginRight: 5,
  },
  selectedTagText: {
    color: Colors.FoldPixel.PRIMARY300,
  },
  poweredByUnityText: {
    backgroundImage: 'linear-gradient(237.73deg, #1E9DAE 0.27%, #D478FF 102.44%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textAlign: 'center' as const,
  },
  alphaText: {
    color: Colors.FoldPixel.GRAY200,
    fontFamily: 'Courier',
    textAlign: 'center' as const,
  },
});
