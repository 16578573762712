import {useLazyQuery} from '@apollo/client';
import {Drawer, notification, Popover, Tooltip} from 'antd';
import {Divider, HStack, Text, View} from 'native-base';
import {useContext, useRef, useState} from 'react';
import {Pressable} from 'react-native';
import {
  getInActiveContactError,
  PERSON_TYPES,
  RIGHT_SIDE_CONTAINER_CODE,
} from '../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  QUICK_ACTION_CODES,
} from '../../constants/ActionConst';
import {CommonDataContext} from '../../context/CommonDataContext';
import {Colors} from '../../styles';
import {
  canCommunicateWithInactivePatient,
  getLocationGroupIdFromLocationId,
  getUserData,
  getUserFullName,
  getUserId,
  getUserUUID,
  isActiveContact,
  isConsentRequiredAndGiven,
  isSideCarEnabledForPatientDashboard,
} from '../../utils/commonUtils';
import {EventBus} from '../../utils/EventBus';
import {getToolTipMessage} from '../../utils/PersonActionsUtils';
import {getFormDataFromLeadData} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import CreateSmsConversationDrawer from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {patientNotLoggedInError} from '../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationConst';
import {
  IContact,
  IConversationData,
  INewConversationResponse,
} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {SMS_INBOX_ACTION_CODES} from '../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import {
  MAIN_MENU_CODES,
  USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE,
} from '../SideMenuBar/SideBarConst';
import InstantChat from '../common/ChatDrawer/InstantChat';
import MeetingView from '../common/MeetingView/MeetingView';
import OutboundCallSmsView from '../common/OutboundCallSmsView/OutboundCallSmsView';
import {isAccountConfigEnabled} from '../../utils/configUtils';
import {CONFIG_CODES} from '../../constants/AccountConfigConst';
import {EmailDrawerCommonV2} from '../common/EmailDrawerCommonV2';
import InstantChatView from '../common/ChatDrawer/InstantChatView';
import ConversationsQueries from '../../services/Conversations/ConversationsQueries';
import ChatRoundActionSvg from '../common/Svg/PersonActionSvgIcons/ChatRoundActionSvg';

import {isCallsAllowed, USER_ACCESS_PERMISSION} from '../RightSideContainer/UserAccess/UserAccessPermission';
import FoldPermitCan from '../CommonComponents/FoldPermitCan/FoldPermitCan';
import PhoneActionSvg from '../common/Svg/PersonActionSvgIcons/PhoneActionSvg';
import {withMiniContactViewHOC} from '../MiniContactViewHOC';
import {testID} from '../../testUtils';
import MenuHorizontalIcon from '../common/Svg/MenuHorizontalIcon';
import {IConversationChannelList} from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/Interfaces';
import {DISPLAY_CONVERSATION_SIDEBAR_ICON} from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationSidebarUtils';
import {useIntl} from 'react-intl';
import CrossIcon from '../common/Svg/CrossIcon';
import Stack from '../common/LayoutComponents/Stack';
import AddOrUpdateTask from '../common/AddTask/AddOrUpdateTask';
import { TaskPanelType } from '../TaskCard/TaskEnum';
import { ParticipantType } from '../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { IUserPatientSearchItem } from '../TaskModule/TaskInterfaces';
import ResetPasswordModal from '../common/PersonActions/ResetPasswordModal';
import AutomationWorkflowEmployee from '../RightSideContainer/MembersManagement/AutomationWorkflowEmployee/AutomationWorkflowEmployee';
import ContactSendForm from '../common/ContactSendForm/ContactSendForm';
import {getPatientEHRUrl} from '../../utils/capabilityUtils';

interface SideCarPersonActionsProps {
  shouldShowEmail?: boolean;
  email?: any;
  showVideoCall: boolean;
  phoneNumber?: any;
  showSms?: boolean;
  contactInfo?: IContact | undefined;
  showPhoneCall?: boolean;
  handleEdit?: any;
  showAssignJourney: boolean;
  onPersonActionPerformed?: (actionCode: string, rawData?: any) => void;
  handleCreateCarePlanClick?: () => void;
  shouldShowCloseButton?: boolean;
  handleCloseClick?: () => void;
  taskPanelType?: TaskPanelType;
  sendResetPasswordInstructions?: (contactData: any) => void;
}

const SideCarPersonActionsComponent = (props: SideCarPersonActionsProps) => {
  const {
    shouldShowEmail,
    email,
    showVideoCall,
    phoneNumber,
    showSms,
    contactInfo,
    showPhoneCall,
    handleEdit,
    showAssignJourney,
    onPersonActionPerformed,
  } = props;
  const personActionRef: any = useRef();
  const userUUID = getUserUUID();
  const userId = getUserId();
  const intl = useIntl();
  const isCallAllowed = isCallsAllowed();
  const isLoggedIn = contactInfo?.additionalAttributes?.loggedInTime;
  const [selectedView, setSelectedView] = useState<string>('');
  const isPatientMessagingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.PATIENT_MESSAGING_ENABLED
  );
  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(
    CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR
  );

  const isReferralEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_REFERRAL_ENABLED)

  const [stateData, setStateData] = useState({
    visible: false,
    preferredChannel: '',
    openDrawerCode: '',
    selectedConversation: {} as IConversationData,
    moreActionPopup: false,
    showResetPasswordModal: false,
    showAutomationDrawer: false,
    showSendFormDrawer: false
  });
  const commonData = useContext(CommonDataContext);
  const userSettings = commonData.userSettings;
  const sideCarDashboardEnabled = isSideCarEnabledForPatientDashboard(userSettings);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const locationId =
    contactInfo?.contactPracticeLocations?.[0]?.accountLocation?.uuid;
  const locationGroupId =
    locationId &&
    getLocationGroupIdFromLocationId(
      commonData?.accountLocationListWithEHR,
      locationId
    );
  const openInEhrUrl = getPatientEHRUrl(
    contactInfo?.patient?.patientId,
    '',
    locationGroupId
  );
  const showViewInEHRBtn = !isSidecarContext && !!openInEhrUrl;
  const contactActiveStatus = isActiveContact(contactInfo);
  const formattedContactData = getFormDataFromLeadData(
    contactInfo || {},
    commonData
  );
  const patientUuid = props?.contactInfo?.uuid;
  const consentCheck = isConsentRequiredAndGiven(contactInfo);
  const userFullName = getUserFullName();
  const currentUserData = getUserData();

  const [GetConversationByConversationId] =
    useLazyQuery<INewConversationResponse>(
      ConversationsQueries.GetConversationByConversationId,
      {
        fetchPolicy: 'no-cache',
      }
    );

  const handleOnCreateNewEmailConversation = async (conversationId: string) => {
    if (!conversationId) {
      return;
    }
    try {
      const response = await GetConversationByConversationId({
        variables: {
          conversationId: Number(conversationId),
          loginUserIntId: userId,
          loginUserUuid: userUUID,
        },
      });
      const conversations = response.data?.conversations || [];
      if (conversations.length > 0) {
        const selectedConversationData = conversations[0];
        setStateData((prev) => {
          return {
            ...prev,
            selectedConversation: selectedConversationData,
          };
        });
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER);
      }
    } catch (error) {
      setStateData((prev) => {
        return {
          ...prev,
          selectedConversationData: {},
        };
      });
    }
  };

  const handleMoreActionVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        moreActionPopup: visible,
      };
    });
  };

  const handleActions = (actionCode: string, rowData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.CALL:
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent('CLOUD_CALL', {
          contactData: contactInfo ?? {fromNumber: phoneNumber},
          fromNumber: rowData.fromNumber,
          toNumber: phoneNumber,
        });
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
      default:
        break;
    }
  };
  const isPatientLoggedIn = contactInfo?.additionalAttributes?.loggedInTime
    ? true
    : false;

  const shouldShowChat = () => {
    if (isSidecarContext && showOnlyInternalChatInSidecar) {
      return false;
    }
    if (
      USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.USER.includes(
        MAIN_MENU_CODES.INBOX
      )
    ) {
      return (
        contactInfo?.patient?.id &&
        contactInfo?.uuid &&
        isLoggedIn &&
        isPatientMessagingEnabled
      );
    } else {
      return false;
    }
  };

  const canStartAction = (actionCode: string) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.START_SMS:
        return showSms;
      case CONVERSATION_ACTION_CODES.START_EMAIL:
        return shouldShowEmail;
      case CONVERSATION_ACTION_CODES.START_VIDEO_MEET:
        return showVideoCall;
      case QUICK_ACTION_CODES.ADD_CARE_JOURNEY:
        return showAssignJourney;
      case QUICK_ACTION_CODES.ADD_REFERRAL_ORDER:
          return true;
      case QUICK_ACTION_CODES.RESET_PASSWORD:
        return true;
      case QUICK_ACTION_CODES.SEND_ASSESMENT:
        return true;
      case QUICK_ACTION_CODES.RUN_AUTOMATION:
        return true;
      default:
        return true;
    }
  };

  const performAction = (actionCode: string) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.START_SMS:
        if (canCommunicateWithInactivePatient(contactInfo)) {
          if (consentCheck) {
            if (phoneNumber) {
              setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER);
            } else {
              handleEdit?.();
            }
          } else {
            handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
          }
        } else {
          const message = getInActiveContactError(contactInfo);
          notification.error({
            message,
          });
        }
        break;
      case CONVERSATION_ACTION_CODES.START_EMAIL:
        if (canCommunicateWithInactivePatient(contactInfo)) {
          if (consentCheck) {
            if (email) {
              setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP);
            } else {
              handleEdit?.();
            }
          } else {
            handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
          }
        } else {
          const message = getInActiveContactError(contactInfo);
          notification.error({
            message,
          });
        }
        break;
      case CONVERSATION_ACTION_CODES.START_VIDEO_MEET:
        if (contactActiveStatus) {
          if (consentCheck) {
            if (email || phoneNumber) {
              setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW);
            } else {
              handleEdit?.();
            }
          } else {
            handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
          }
        } else {
          const message = getInActiveContactError(contactInfo);
          notification.error({
            message,
          });
        }
        break;
      case QUICK_ACTION_CODES.ADD_CARE_JOURNEY:
        handleMoreActionVisibleChange(false);
        onPersonActionPerformed?.(QUICK_ACTION_CODES.ADD_CARE_JOURNEY);
        break;
      case QUICK_ACTION_CODES.ADD_REFERRAL_ORDER:
        handleMoreActionVisibleChange(false);
        onPersonActionPerformed?.(QUICK_ACTION_CODES.ADD_REFERRAL_ORDER);
        break;
      case QUICK_ACTION_CODES.RESET_PASSWORD:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showResetPasswordModal: true}));
        break;
      case QUICK_ACTION_CODES.SEND_ASSESMENT:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showSendFormDrawer: true}));
        break;
      case QUICK_ACTION_CODES.RUN_AUTOMATION:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showAutomationDrawer: true}));
        break;
      case QUICK_ACTION_CODES.CREATE_CARE_PLAN:
        handleMoreActionVisibleChange(false);
        props?.handleCreateCarePlanClick && props?.handleCreateCarePlanClick();
        break;
      case QUICK_ACTION_CODES.ADD_NEW_TASK:
        handleMoreActionVisibleChange(false);
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK);
        break;
      case QUICK_ACTION_CODES.VIEW_IN_EHR:
        handleMoreActionVisibleChange(false);
        window.open(openInEhrUrl, '_blank');
        break;
      default:
        break;
    }
  };

  const getActionDividerLine = () => {
    return (
      <Divider
        orientation={'vertical'}
        alignSelf={'center'}
        style={{
          height: 20,
          width: 1,
          marginTop: -16,
          backgroundColor: Colors.Custom.Gray200,
        }}
      />
    );
  };

  const getAssignee = (): IUserPatientSearchItem => {
    const isPatientPanel = props?.taskPanelType === TaskPanelType.PATIENT;
    const patientName = props?.contactInfo?.name || '';
    return {
      value: isPatientPanel ? patientUuid : userUUID,
      label: isPatientPanel ? patientName : userFullName,
      key: isPatientPanel ? patientUuid : userUUID,
      type: isPatientPanel ? ParticipantType.patient : ParticipantType.staff,
      details: isPatientPanel ? props?.contactInfo : currentUserData,
    };
  };

  const getNewTaskMember = () => {
    const data = {
      value: formattedContactData?.contactUUID,
      label: formattedContactData?.name,
      key: formattedContactData?.contactUUID,
      type: formattedContactData.contactType?.code === PERSON_TYPES.LEAD ? ParticipantType.leads : ParticipantType.patient,
      contactData: formattedContactData?.contactData,
      data: formattedContactData?.contactData,
    };
    return data;
  }

  const getDefaultMember = () => {
    const patientName = props?.contactInfo?.name || '';
    return {
      value:  patientUuid,
      label: patientName,
      key:  patientUuid,
      type: ParticipantType.patient,
      details: props?.contactInfo,
    };
  }
  const SIDE_CAR_PATIENT_ACTION_LIST: IConversationChannelList[] = [
    {
      key: CONVERSATION_ACTION_CODES.START_SMS,
      title: 'SMS',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.START_EMAIL,
      title: 'Email',
      path: '',
    },
    {
      key: QUICK_ACTION_CODES.ADD_NEW_TASK,
      title: 'Add Task',
      path: '',
    },
    {
      key: CONVERSATION_ACTION_CODES.START_VIDEO_MEET,
      title: 'Video',
      path: '',
    },
    {
      key: QUICK_ACTION_CODES.ADD_CARE_JOURNEY,
      title: 'Assign Journey',
      path: '',
    },
    ...(isReferralEnabled ? [{
      key: QUICK_ACTION_CODES.ADD_REFERRAL_ORDER,
      title: 'Referral Orders',
      path: '',
    }] : []),
    ...(showViewInEHRBtn ? [{
      key: QUICK_ACTION_CODES.VIEW_IN_EHR,
      title: 'View in EHR',
      path: '',
    }] : []),
  ];

  return (
    <View
      style={{
        maxWidth: '100%',
      }}
    >
      <HStack
        ref={personActionRef}
        justifyContent={'space-between'}
        style={{width: '100%'}}
      >
          <HStack justifyContent={'flex-start'} alignItems={'center'} space={2}>
          {showPhoneCall && isCallAllowed && (
            <>
              <FoldPermitCan
                resource={MAIN_MENU_CODES.CALL_LOGS}
                action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
                component={
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <OutboundCallSmsView
                      showOnlyCallNumbers={true}
                      isHideSms={true}
                      visible={stateData.visible}
                      selectedContactData={contactInfo}
                      content={
                        <Tooltip
                          title={
                            !contactActiveStatus
                              ? getInActiveContactError(contactInfo)
                              : getToolTipMessage(
                                  'CALL',
                                  phoneNumber,
                                  email,
                                  props.contactInfo as IContact
                                )
                          }
                        >
                          <Pressable
                            onPress={() => {
                              if (contactActiveStatus) {
                                if (consentCheck) {
                                  if (phoneNumber) {
                                    setStateData((prev) => {
                                      return {
                                        ...prev,
                                        visible: true,
                                      };
                                    });
                                  } else {
                                    handleEdit?.();
                                  }
                                } else {
                                  handleEdit?.(
                                    COMMON_ACTION_CODES.CONSENT_WARNING
                                  );
                                }
                              } else {
                                const message =
                                  getInActiveContactError(contactInfo);
                                notification.error({
                                  message,
                                });
                              }
                            }}
                            style={{
                              height: 36,
                              alignItems: 'center',
                              paddingLeft: 4,
                              paddingRight: 0,
                            }}
                          >
                            <View
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              <PhoneActionSvg
                                width={24}
                                height={24}
                                isActive={phoneNumber ? true : false}
                              />
                            </View>
                          </Pressable>
                        </Tooltip>
                      }
                      handleAction={(actionCode: string, rawData?: any) => {
                        handleActions(actionCode, rawData);
                      }}
                      onClose={() => {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            visible: false,
                          };
                        });
                      }}
                    />
                  </View>
                }
              />
              {getActionDividerLine()}
            </>
          )}
          {shouldShowChat() && (
            <>
              <FoldPermitCan
                resource={MAIN_MENU_CODES.INBOX}
                action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
                component={
                  shouldShowChat() ? (
                    <>
                      <View
                        style={{flexDirection: 'row', alignItems: 'center'}}
                      >
                        <Tooltip
                          title={
                            isPatientLoggedIn
                              ? !contactActiveStatus
                                ? getInActiveContactError(contactInfo)
                                : 'Chat with patient'
                              : patientNotLoggedInError
                          }
                        >
                          <Pressable
                            onPress={() => {
                              if (contactActiveStatus && isPatientLoggedIn) {
                                setSelectedView(
                                  CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT
                                );
                              }
                            }}
                            style={{
                              height: 36,
                              alignItems: 'center',
                              paddingLeft: 4,
                              paddingRight: 0,
                            }}
                          >
                            <View
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              <ChatRoundActionSvg
                                width={24}
                                height={24}
                                isActive={isPatientLoggedIn}
                              />
                            </View>
                          </Pressable>
                        </Tooltip>
                      </View>
                    </>
                  ) : (
                    <></>
                  )
                }
              />
              {getActionDividerLine()}
            </>
          )}
          <Popover
            overlayInnerStyle={{padding: 0, borderRadius: 12}}
            overlayStyle={{padding: 0}}
            overlay={{padding: 0}}
            style={{padding: 0, borderRadius: 12}}
            placement={'bottomLeft'}
            trigger="click"
            visible={stateData.moreActionPopup}
            onVisibleChange={handleMoreActionVisibleChange}
            content={
              <View width={180}>
                {SIDE_CAR_PATIENT_ACTION_LIST.map(
                  (tabItem: IConversationChannelList, index) => {
                    return (
                      <FoldPermitCan
                        resource={MAIN_MENU_CODES.INBOX}
                        action={
                          USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code
                        }
                        component={
                          canStartAction(tabItem.key) ? (
                            <Pressable
                              key={`SideCarActionList_${index}`}
                              onPress={() => {
                                performAction(tabItem.key);
                              }}
                              style={{
                                padding: 8,
                                backgroundColor: 'white',
                              }}
                              {...testID(tabItem.code || '')}
                            >
                              <HStack flex={1}>
                                <View
                                  justifyItems={'center'}
                                  alignItems={'center'}
                                  alignSelf={'center'}
                                  justifySelf={'center'}
                                  height={'16px'}
                                  width={'16px'}
                                  {...testID('image')}
                                >
                                  {DISPLAY_CONVERSATION_SIDEBAR_ICON(
                                    tabItem?.key,
                                    Colors.Custom.Gray500
                                  )}
                                </View>
                                <Text
                                  size={'smRegular'}
                                  flex={0.9}
                                  style={{
                                    color: Colors.FoldPixel.GRAY400,
                                    paddingStart: 8,
                                  }}
                                  {...testID(tabItem.title || '')}
                                >
                                  {intl.formatMessage({id: tabItem.title})}
                                </Text>
                              </HStack>
                            </Pressable>
                          ) : (
                            <></>
                          )
                        }
                      ></FoldPermitCan>
                    );
                  }
                )}
              </View>
            }
          >
            <Pressable
              onPress={() => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    moreActionPopup: true,
                  };
                });
              }}
              style={{marginRight: props?.shouldShowCloseButton ? 0 : 16, alignItems: 'center', height: 40}}
              {...testID('MoreBtnClick')}
            >
              <Tooltip title={'More'}>
                <MenuHorizontalIcon width={24} height={24}/>
              </Tooltip>
            </Pressable>
          </Popover>

          {props?.shouldShowCloseButton &&
           <Stack direction='row'>
              {getActionDividerLine()}
              <Pressable
                onPress={props.handleCloseClick}
                style={{marginRight: 4, marginLeft: 4, height: 44}}
                {...testID('CloseBtnClick')}
              >
                <CrossIcon size={32}/>
              </Pressable>
            </Stack>
          }


          {selectedView === RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW && (
            <MeetingView
              personData={formattedContactData}
              onPersonActionPerformed={(actionCode: any) => {
                if (actionCode == COMMON_ACTION_CODES.CANCEL) {
                  setSelectedView('');
                }
              }}
            />
          )}

          {selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
            <EmailDrawerCommonV2
              isOpen={
                selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP
              }
              onClose={() => {
                setSelectedView('');
              }}
              onEmailSent={({msgData}) => {
                setSelectedView('');
                handleOnCreateNewEmailConversation(msgData?.conversationId);
              }}
              contactIds={contactInfo?.id ? [contactInfo?.id] : []}
            />
          )}
          {selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
            <CreateSmsConversationDrawer
              isDrawerVisible={true}
              selectedInbox={{} as any}
              onCreateSmsConversationActionPerformed={(
                actionCode: string,
                actionData: any
              ) => {
                // setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
                if (
                  actionCode == SMS_INBOX_ACTION_CODES.DRAWER_CLOSE &&
                  !actionData
                ) {
                  setSelectedView('');
                } else {
                  setSelectedView(
                    RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER
                  );
                  setStateData((prev) => {
                    return {
                      ...prev,
                      selectedConversation:
                        actionData?.selectedInboxConversationData
                          ?.conversations?.[0],
                    };
                  });
                }
              }}
              selectedPatient={contactInfo}
            />
          )}
          {selectedView === CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT &&
            contactInfo?.uuid &&
            contactInfo?.patient?.id && (
              <InstantChat
                contactData={contactInfo}
                contactUuid={contactInfo?.uuid}
                isDrawerVisible={
                  selectedView === CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT
                }
                onActionPerformed={(actionCode) => {
                  if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                    setSelectedView('');
                  }
                }}
              />
            )}

          {selectedView ===
            RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER && (
            <InstantChatView
              selectedConversation={stateData.selectedConversation}
              contactData={{} as any}
              isLoading={false}
              isDrawerVisible={true}
              isInstantChatView={true}
              instantChatFromNotifications={true}
              onActionPerformed={(actionCode: any, actionData: any) => {
                if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                  setSelectedView('');
                } else if (
                  actionCode === CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT
                ) {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      selectedConversation: actionData,
                    };
                  });
                }
              }}
            />
          )}

          {selectedView === RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK &&
          <AddOrUpdateTask
            assignee={getAssignee()}
            restrictPatientOrLeadSelectionTo={getNewTaskMember() as any}
            isVisible={selectedView === RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK}
            onComplete={(data: any) => {
              setSelectedView('');
            }}
            onCancel={() => {
              setSelectedView('');
            }}
            member={getDefaultMember()}
          />}

          {stateData.showResetPasswordModal && (
            <ResetPasswordModal
              isOpen={stateData.showResetPasswordModal}
              onClose={() => {
                setStateData(prev => ({...prev, showResetPasswordModal: false}));
              }}
              contactInfo={contactInfo}
              onConfirm={() => {
                props.sendResetPasswordInstructions?.(contactInfo);
                setStateData(prev => ({...prev, showResetPasswordModal: false}));
              }}
            />
          )}
          {stateData.showAutomationDrawer && (
          <Drawer
            width={700}
            visible={true}
            closeIcon={null}
            closable
            onClose={() => {
              setStateData(prev => ({...prev, showAutomationDrawer: false}));
            }}
            title={<></>}
            maskClosable={true}
          >
            <AutomationWorkflowEmployee
              type="patient"
              initData={{selectedItems: [contactInfo]}}
              onModalClose={() => {
                setStateData(prev => ({...prev, showAutomationDrawer: false}));
              }}
            />
          </Drawer>
        )}
        {stateData.showSendFormDrawer && (
              <ContactSendForm
                isVisible={stateData.showSendFormDrawer}
                assignmentData={{
                  patientId: formattedContactData.patientUuid || '',
                  contactId: formattedContactData.contactUUID || '',
                  assignedById: userUUID,
                  patientEmail: formattedContactData.email || '',
                  patientFirstName: formattedContactData.firstName || '',
                  patientContactUUID: formattedContactData.contactUUID || '',
                  contactTypeCode: formattedContactData?.contactType?.code || '',
                }}
                onActionComplete={() => {
                  setStateData(prev => ({...prev, showSendFormDrawer: false}));
                }}
                onClose={() => {
                  setStateData(prev => ({...prev, showSendFormDrawer: false}));
              }}
            />
          )}
        </HStack>
      </HStack>
    </View>
  );
};

export const SidecarPersonActions = withMiniContactViewHOC(
  SideCarPersonActionsComponent
);
