import { Spinner,View } from 'native-base';
import {
  DragEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactFlow,{
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  Edge,
  EdgeChange,
  MarkerType,
  MiniMap,
  Node,
  NodeChange,
  NodeTypes,
  ReactFlowInstance,
  ReactFlowProvider,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  getIncomers,
  useReactFlow
} from 'reactflow';
import 'reactflow/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import { FlowType,WorkflowContext } from '../../../../context/WorkflowContext';
import { Colors } from '../../../../styles';
import { getAccountUUID,reArrangeOptionNodeNode } from '../../../../utils/commonUtils';
import { generateUniqueKey } from '../../AccountSettings/AppointmentTypes/AppointmentTypesHelper';
import { IWorkflowRender } from '../Workflow/AddOrUpdateWorkflow/IWorkflow';
import { getWorkflowByMasterId } from '../Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {
  ConditionState,
  GroupState,
  LambdaState,
  UserActionState
} from './StateNodes';
import NewOutputModelV2 from './StateNodes/NewOutputModelV2';
import './styles.css';
import useAutoLayout from './useAutoLayout';
import useUndoRedo from './useUndoRedo';
import { styles } from '../workflowStyles';
import { isInvalidUserInput } from './StateNodes/FlowNodeHelper';
import { IUserInputField } from './StateNodes/FlowNodeInterface';
import { cloneDeep } from 'lodash';
// import NewOutputModelV2 from './StateNodes/NewOutputModelV2';

const onDragOver = (event: DragEvent) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
};

export const getId = (node: string): string => {
  return `${uuidv4()}`;
};

const nodeTypes: NodeTypes = {
  group: GroupState,
  Task: LambdaState,
  Map: LambdaState,
  Pass: LambdaState,
  If: LambdaState,
  Choice: UserActionState,
  Condition: ConditionState,
  MapSubflow: LambdaState,
  Parallel: LambdaState,
  Option: LambdaState,
};

const proOption = {
  account: 'paid-pro',
  hideAttribution: true,
};


const ALLOWED_FLOW_TYPE_MASTER_LIST = [
  FlowType.patients,
  FlowType.lab,
  FlowType.task,
  FlowType.scheduling,
  FlowType.careJourney,
  FlowType.communication,
  FlowType.journeys,
  FlowType.form,
  ] as const;
const FlowComponent = (props: IWorkflowRender) => {
  return <ReactFlowProvider> <FlowComponentChild {...props}></FlowComponentChild></ReactFlowProvider>
}

const FlowComponentChild = (props: IWorkflowRender) => {
  const [isShake, setIsShake]= useState(false)
  const [isAllowInterAction, onInterActionChange] = useState(true);
  const { undo, redo, canUndo, canRedo, takeSnapshot } = useUndoRedo();
  const [newNodeIdList, setNewNodeIdList] = useState<any>([])
  const { fitView } = useReactFlow();

  const [allowedFlowTypeList, setAllowedFlowTypeList] = useState(
    props.allowedFlowTypeList?.length && props.workflowMasterId
      ? props.allowedFlowTypeList
      : ALLOWED_FLOW_TYPE_MASTER_LIST
  );

  const accountUuId = getAccountUUID();
  const [loading, setLoading] = useState(false);
  const setNodes = (nodes: any, isEdit: boolean) => {
    props.setNodes(nodes, true);
  };
  const [updateCount, setUpdateCount] = useState(1);
  const isWorkFlowEditable =
    props.isWorkFlowEditable == undefined ? true : props.isWorkFlowEditable;
  const context = useContext(WorkflowContext);
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>();
  const reactFlowWrapper: any = useRef({});
  // const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isExecutionLog] = useState<any>(
    searchParams.get('isExecutionLog') || false
  );




  useEffect(()=>{
    if(!props.workflowMasterId && context.flowType == FlowType.MICRO_AUTOMATION && !props.isViewOnly){
      const masterList = ALLOWED_FLOW_TYPE_MASTER_LIST;
      const filterList = masterList.filter((flowType)=>{
          const isAnyInValid = props.nodes.some((node:any)=>{
            const nodeType = node?.data?.nodeType;
            const prevNode = getPreviousImmediateNode(node?.id)
            const nodeInfo = context.nodeMasterDataMap[nodeType];
            const flowTypeList =  nodeType === 'OnTaskAddOrUpdateProviderFilter' && prevNode?.data?.nodeType !== 'AllCondition' ? [ FlowType.task ] : (nodeInfo?.flowTypeList || []);
            if(nodeType === 'MicroAutomation' || flowTypeList.includes(FlowType.all)){
              return false;
            }
            if(!nodeInfo){
              return true
            }
            return !(flowTypeList).includes(flowType);
          });
          return !isAnyInValid;

      });
      setAllowedFlowTypeList(filterList)
      if (props.setAllowedFlowTypeList) {
        props.setAllowedFlowTypeList(filterList);
      }
    }
  }, [props.nodes?.length])




  const onDelete = (nodeId: string) => {
    if (!reactFlowInstance || props.isViewOnly) {
      return;
    }
    const nodeList = reactFlowInstance.toObject()?.nodes || [];
    const edgeList = reactFlowInstance.toObject()?.edges || [];
    const newNodeList = nodeList.filter((node) => {
      return node.id !== nodeId;
    });
    const newEdgeList = edgeList.filter((edge) => {
      return !(edge.source === nodeId || edge.target === nodeId);
    });
    setNodes(newNodeList, false);
    props.setEdges(newEdgeList);
    // takeSnapshot()

  };
  const getPositionForDefaultNode = () => {
    let isChanged = false;
    // let newDefaultNode: any = undefined;
    const elementsWrapper = props.nodes.map(function (node: any) {
      const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect
        ? reactFlowWrapper.current.getBoundingClientRect()
        : undefined;
      if (!reactFlowInstance || !reactFlowBounds) {
        return node;
      }
      let newNode: any = node;
      if (!node?.id) {
        isChanged = true;
        const position = {
          x: (reactFlowBounds.left + reactFlowBounds.width) / 8,
          y: 20,
        };
        const metaData: any = JSON.parse(JSON.stringify(node.metaData || {}));
        const nodeId = getId(node.data.nodeType);
        newNode = {
          id: nodeId,
          type: node.type,
          position: position,
          positionAbsolute: position,
          data: {
            nodeType: node?.data?.nodeType,
            isEdit: props?.isViewOnly ? false : node?.data?.isEdit,
            isStartNode: node?.data?.isStartNode,
            sourceHandle: node?.data?.sourceHandle,
            onSelect: onSelect,
            onDelete: onDelete,
            metaData: metaData,
            isWorkFlowEditable: isWorkFlowEditable,

            getOutputNodeList,
            getPreviousImmediateNode,
            setData: function (key: any, value: any) {
              if (this.metaData) {
                this.metaData[key] = value;
              }
              updateElementsOnNodeUpdate(nodeId, key, value);
            },
          },
        };
      } else if (
        (!node?.data?.onSelect ||
          !context.nodeMasterDataMap ||
          !context.nodeMetaData) &&
        node.type != 'bezier'
      ) {
        isChanged = true;
        newNode = {
          id: node.id,
          type: node?.type,
          position: node.position,

          positionAbsolute: node.position,
          data: {
            isEdit: props?.isViewOnly? false : node?.data?.isEdit,
            isStartNode: node?.data?.isStartNode,
            sourceHandle: node?.data?.sourceHandle,
            metaData: node?.data?.metaData || node.metaData || {},
            isWorkFlowEditable: isWorkFlowEditable,
            getOutputNodeList,
            getPreviousImmediateNode,
            setData: function (key: any, value: any) {
              if (this.metaData) {
                this.metaData[key] = value;
              }
              updateElementsOnNodeUpdate(node.id, key, value);
            },
            nodeType: node?.data?.nodeType,
            onSelect: onSelect,
            onDelete: onDelete,
          },
        };
      }
      return newNode;
    });

    if (isChanged) {
      props.setNodes(elementsWrapper);
    } else if (!isWorkFlowEditable) {
      // reactFlowInstance?.fitView();
    }
    return elementsWrapper;
  };
  const getNodeById = (nodeId: string | null) => {
    let nodeData;
    if (!nodeId) {
      return nodeData;
    }
    let nodeList = [];
    if (!reactFlowInstance) {
      nodeList = nodes;
    } else {
      nodeList = reactFlowInstance.toObject().nodes;
    }
    (nodeList || []).some((node: any) => {
      if (node.id == nodeId) {
        nodeData = node;
        return true;
      }
    });
    return nodeData;
  };

  const getParentOutputNodeList = (sourceNode: any) => {
    let nodeInstance: any;
    const outputNodeList: Array<string> = [];
    if (reactFlowInstance) {
      const nodeList = reactFlowInstance?.toObject()?.nodes;
      const edgeList = reactFlowInstance?.toObject()?.edges;
      nodeList.some((node) => {
        if (node.id === sourceNode.id) {
          nodeInstance = node;
          return true;
        }
      });
      while (nodeInstance && nodeInstance.type != 'Map' && nodeInstance.type !== 'MapSubflow') {
        const list = getIncomers(nodeInstance, nodeList, edgeList);
        nodeInstance = list.length ? list[0] : undefined;
        if (nodeInstance && Object.keys(nodeMasterDataMap).length > 0) {
          (
            nodeMasterDataMap[nodeInstance?.data?.nodeType]?.outputNodeList ||
            []
          ).forEach((nodeType: any) => {
            if (outputNodeList.indexOf(nodeType) == -1) {
              outputNodeList.push(nodeType);
            }
          });
        }
      }
      return outputNodeList;
    }
    return outputNodeList;
  };

  const getOutputNodeList = (
    nodeId: string,
    branchOutputNodeList: Array<string>,
    isNeedParentList?: boolean,
    mergeParentNodeOutputList = true
  ) => {
    const sourceNode: any = getNodeById(nodeId);
    const rootNodeType = sourceNode?.data?.rootNodeType;
    if (
      context.nodeMasterDataMap[rootNodeType]?.isOnlyParentOutputNodeListAllowed
    ) {
      const allowedSubflowOutputNodeList =
        context.nodeMasterDataMap[rootNodeType]?.allowedSubflowOutputNodeList ||
        [];

      const outputNodeList = JSON.parse(
        JSON.stringify(
          context.nodeMasterDataMap[rootNodeType]?.outputNodeList || []
        )
      );
      (branchOutputNodeList || []).forEach((nodeType) => {
        if (
          allowedSubflowOutputNodeList.indexOf(nodeType) != -1 &&
          outputNodeList.indexOf(nodeType) == -1
        ) {
          outputNodeList.push(nodeType);
        }
      });
    }
    if (!sourceNode) {
      return [];
    }
    let outputNodeList = JSON.parse(
      JSON.stringify(sourceNode.outputNodeList || [])
    );
    (branchOutputNodeList || []).forEach((branchNodeId) => {
      outputNodeList.push(branchNodeId);
    });
    let parentNodeOutputList: Array<string> = [];
    if (sourceNode.type !== 'mapState' && sourceNode.type !== 'MapSubflow' && mergeParentNodeOutputList) {
      parentNodeOutputList = getParentOutputNodeList(sourceNode);
      if (parentNodeOutputList.length) {
        parentNodeOutputList.forEach((nodeId: string) => {
          if (
            outputNodeList.indexOf(nodeId) == -1 &&
            nodeId != 'AllCondition' && nodeId != 'ReminderAfterCurrentTimeV2'
          ) {
            outputNodeList.push(nodeId);
          }
        });
      }
    }

    if (context.flowType == FlowType.MICRO_AUTOMATION) {
      parentNodeOutputList = parentNodeOutputList.filter((nodeType: string) => {
        if(nodeType === 'OnTaskAddOrUpdateProviderFilter'){
          return false
        }
        const nodeInfo = context.nodeMasterDataMap[nodeType];
        const flowTypeList = nodeInfo?.flowTypeList || [];
        if (!props.workflowMasterId) {
          const isAllowed = allowedFlowTypeList.some((allowedFlowType) => {
            return flowTypeList.includes(allowedFlowType);
          });
          return isAllowed;
        } else {
          const isNotAllowed = allowedFlowTypeList.some((allowedFlowType) => {
            return !flowTypeList.includes(allowedFlowType);
          });
          return !isNotAllowed;
        }
      });
      outputNodeList = outputNodeList.filter((nodeType: string) => {
        if(nodeType === 'OnTaskAddOrUpdateProviderFilter' && (sourceNode?.data?.nodeType !== 'AllCondition')){
          return props.workflowMasterId && allowedFlowTypeList.includes(FlowType.task) && allowedFlowTypeList?.length != 1 ? false : allowedFlowTypeList.includes(FlowType.task);
        }
        const nodeInfo = context.nodeMasterDataMap[nodeType];
        const flowTypeList = nodeInfo?.flowTypeList || [];
        if (!props.workflowMasterId) {
          const isAllowed = allowedFlowTypeList.some((allowedFlowType) => {
            return flowTypeList.includes(allowedFlowType);
          });
          return isAllowed;
        } else {
          const isNotAllowed = allowedFlowTypeList.some((allowedFlowType) => {
            return !flowTypeList.includes(allowedFlowType);
          });
          return !isNotAllowed;
        }
      });
    }


    return isNeedParentList
      ? {
        parentNodeOutputList: parentNodeOutputList || [],
        outputNodeList: outputNodeList || [],
      }
      : outputNodeList || [];
  };

  const getPreviousImmediateNode = (nodeId: string) => {
    let prevNodeId: any = undefined;
    edges.some((edge: any) => {
      if (edge.target === nodeId) {
        prevNodeId = edge.source;
        return !!prevNodeId;
      }
    });
    if (prevNodeId) {
      let prevImmediateNode: any = undefined;
      const nodeList = props.nodes;
      if (reactFlowInstance) {
        // nodeList = reactFlowInstance?.toObject()?.nodes;
      }
      nodeList.some((element: any) => {
        if (element.id === prevNodeId) {
          prevImmediateNode = element;
          return true;
        }
      });
      return prevImmediateNode;
    }
  };

  const onConnectValidate = (params: Connection | Edge) => {
    if(params.source == params.target){
      return false
    }
    
    const sourceNode: any = getNodeById(params.source);

    const targetNode: any = getNodeById(params.target);
    let targetPrevNode;
    if (params.target) {
      targetPrevNode = getPreviousImmediateNode(params.target);
    }
    const sourceNodeType = sourceNode?.data?.nodeType;
    const targetNodeType = targetNode?.data?.nodeType;

    if(targetPrevNode && ['BitwiseAND', 'BitwiseOR'].indexOf(targetNodeType) == -1){
        return false;
    }
    if (props.flowType === FlowType.careJourney) {
      return true;
    }
    let outputNodeList: Array<string> = [];
    if (params.source) {
      outputNodeList = getOutputNodeList(
        params.source,
        nodeMasterDataMap[sourceNodeType].outputNodeList
      );
    }
    const sourceHandleOutputNodeList = params?.sourceHandle ? (nodeMasterDataMap[params?.sourceHandle]?.outputNodeList || []) : []
    sourceHandleOutputNodeList.forEach((nodeType:string)=>{
      if(outputNodeList.indexOf(nodeType) == -1){
        outputNodeList.push(nodeType);
      }
    })
    return (
      targetNodeType &&
      outputNodeList.indexOf(targetNodeType) !== -1
    );
  };

  const onConnect = useCallback(
    (params: Connection | Edge) => {
      if (onConnectValidate(params)) {
        const edgeList = addEdge(
          {
            ...params,
            id: getId('edge'),
            type: 'bezier',
          },
          edges
        );
        if (edges.length != edgeList.length && props.isViewOnly) {
          return;
        }
        props.setEdges(edgeList);
      }
    },
    [props.edges]
  );

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      if (!isWorkFlowEditable) {
        return;
      }
      const nodeList = applyNodeChanges(changes, nodes);
      if (nodeList.length != nodes.length && props.isViewOnly) {
        return;
      }
      const isAllAdd = nodeList.some((node) => {
        return node?.data?.metaData?.naturalLanguageParseNodeList?.length
      });
      if (!isAllAdd) {

        const newNodeList = JSON.parse(JSON.stringify(nodeList));
        const newEdges = JSON.parse(JSON.stringify(edges));

        newNodeList.forEach((newNode: any) => {
          if (newNode?.data?.metaData?.isAddOptionNode) {
            newNode.data.metaData.isAddOptionNode = false;
            addOptionNode(newNode, newNodeList, newEdges);
          }
        });
        if (newNodeList.length !== nodeList.length) {
          setNodes(newNodeList, false)
          return props.setEdges(newEdges);
        }
      }
      return setNodes(nodeList, false);
    },

    [props.nodes]
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      const edgeList = applyEdgeChanges(changes, edges);
      if (edges.length != edgeList.length && props.isViewOnly) {
        return;
      }
      props.setEdges(edgeList);
    },
    [props.edges]
  );

  const onInit = (_reactFlowInstance: ReactFlowInstance) => {
    setReactFlowInstance(_reactFlowInstance);
  };

  const onNodeDragStop = () => {
    if (!reactFlowInstance) {
      return;
    }
    const nodes: any = reactFlowInstance.toObject().nodes;
    setNodes(nodes, false);
  };

  const updateElementsOnNodeUpdate = (nodeId: string, key: any, value: any) => {
    if (key === 'optionMap' && value) {
      const nodeList = reactFlowInstance ? reactFlowInstance.toObject()?.nodes : nodes;
      (nodeList || []).forEach((node: any) => {
        if (node.id === nodeId) {
          if (
            node?.handleBounds &&
            node?.handleBounds?.source?.length !==
            Object.keys(node?.data?.metaData?.optionMap || {}).length
          ) {
            setNodes(nodeList, true);
            setUpdateCount(updateCount + 1);
          }
        }
      });
    }
    if (key === 'optionMapValue' && value) {
      const nodeList = reactFlowInstance ? reactFlowInstance.toObject()?.nodes : nodes;
      (nodeList || []).forEach((node: any) => {
        if (node.id === nodeId) {
          node.data.metaData.optionMap = value
          setNodes(nodeList, true);
          if (!reactFlowInstance) {
            setUpdateCount(updateCount + 1);
          }
        }
      });
    }
    if (key === 'userInputFieldList' && value) {
      const nodeList = reactFlowInstance ? reactFlowInstance.toObject()?.nodes : nodes;

      (nodeList || []).forEach((node: any) => {
        if (node.id === nodeId && node.data.metaData) {
          node.data.metaData.userInputFieldList = value;
          setNodes(nodeList, true);
          if (!reactFlowInstance) {
            setUpdateCount(updateCount + 1);
          }
        }
      });
    }
    if (props.onNodeDataChange && key === 'userInputFieldList') {
      props.onNodeDataChange(nodeId, value);
    }
    onNodeDragStop();
  };

  const addReminderNode = (
    parentNode: any,
    parentParentNode: any,
    nodeList: Array<any>,
    edgeList: Array<any>,
    isSkipFitView?:boolean
  ) => {
    let nodeType: string = parentNode?.data?.nodeType;
    let possibleValueList = [{ value: 'out' }];
    if (nodeType === 'AllCondition') {
      nodeType = parentParentNode?.data?.nodeType;
      possibleValueList =
        nodeMasterDataMap[nodeType]?.optionInfo?.possibleValueList || [];
    }
    const newNodeType = 'ReminderAfterCurrentTimeV2';
    if (
      !nodeMasterDataMap[nodeType]?.outputNodeList ||
      !nodeMasterDataMap[nodeType]?.outputNodeList?.length ||
      nodeMasterDataMap[nodeType].outputNodeList.indexOf(newNodeType) == -1
    ) {
      return;
    }
    if (!nodeMasterDataMap[newNodeType]) {
      return;
    }
    let newPosition = {
      x: 0,
      y: 250,
    };
    if (reactFlowInstance) {
      newPosition = reactFlowInstance.project(newPosition);
    }
    let xPosition = parentNode.position.x;
    possibleValueList.forEach((sourceHandleData: any) => {
      if (!sourceHandleData?.isAutoAddReminderChildNode) {
        return;
      }
      const position = {
        x: xPosition,
        y: parentNode.position.y + newPosition.y,
      };
      xPosition = xPosition + 250;
      const nodeId = getId(newNodeType);
      const metaData: any = {};
      const newNode = {
        id: nodeId,
        type: nodeMasterDataMap[newNodeType]?.awsState || 'PassState',
        position: position,
        positionAbsolute: position,
        data: {
          isSkipFitView: isSkipFitView || false,
          nodeType: newNodeType,
          metaData: metaData,
          isWorkFlowEditable: isWorkFlowEditable,
          onSelect: onSelect,
          isAddedByFold: false,
          onDelete: onDelete,
          getOutputNodeList,
          getPreviousImmediateNode,
          setData: function (key: any, value: any) {
            if (this.metaData) {
              this.metaData[key] = value;
            }
            updateElementsOnNodeUpdate(nodeId, key, value);
          },
        },
      };
      const newNodeId = newNode.id;
      const connectingEdge: Edge = {
        id: getId('edge'),
        type: 'bezier',
        source: parentNode.id,
        sourceHandle: sourceHandleData.value || 'out',
        target: newNodeId,
        targetHandle: 'in',
      };
      edgeList.push(connectingEdge);
      nodeList.push(newNode);
    });
  };

  const addOptionNode = (
    parentNode: any,
    nodeList: Array<any>,
    edgeList: Array<any>,
    isSkipFitView?: boolean
  ) => {
    if (!parentNode || !parentNode?.data?.nodeType) {
      return;
    }
    const nodeType: string = parentNode.data.nodeType;
    const nodeMasterDataMap = context.nodeMasterDataMap;
    const newNodeType = 'AllCondition';
    if (
      !nodeMasterDataMap[nodeType] ||
      !nodeMasterDataMap[nodeType].optionInfo ||
      !nodeMasterDataMap[nodeType]?.optionInfo?.possibleValueList ||
      (nodeMasterDataMap[nodeType]?.outputNodeList || []).indexOf(
        newNodeType
      ) == -1
    ) {
      return;
    }

    if (!nodeMasterDataMap[newNodeType]) {
      return;
    }
    let newPosition = {
      x: 0,
      y: 250,
    };
    if (reactFlowInstance) {
      newPosition = reactFlowInstance.project(newPosition);
    }
    const position = {
      x: parentNode.position.x,
      y: parentNode.position.y + newPosition.y,
    };
    const nodeId = getId(newNodeType);
    const metaData: any = {};
    const newNode = {
      id: nodeId,
      type: nodeMasterDataMap[newNodeType]?.awsState || 'Condition',
      position: position,
      positionAbsolute: position,
      data: {
        isSkipFitView: isSkipFitView,
        nodeType: newNodeType,
        metaData: metaData,
        isWorkFlowEditable: isWorkFlowEditable,
        onSelect: onSelect,
        isAddedByFold: false,
        onDelete: onDelete,
        getOutputNodeList,
        getPreviousImmediateNode,
        setData: function (key: any, value: any) {
          if (this.metaData) {
            this.metaData[key] = value;
          }
          updateElementsOnNodeUpdate(nodeId, key, value);
        },
      },
    };
    const newNodeId = newNode.id;
    const connectingEdge: Edge = {
      id: getId('edge'),
      type: 'bezier',
      source: parentNode.id,
      sourceHandle: parentNode.sourceHandle || 'out',
      target: newNodeId,
      targetHandle: 'in',
    };
    edgeList.push(connectingEdge);
    nodeList.push(newNode);
    addReminderNode(newNode, parentNode, nodeList, edgeList, isSkipFitView);
  };

  const replaceNodeById = (currentNodeId: string, newNodeType: string) => {
    if (!reactFlowInstance) {
      return;
    }
    let nodes: any = JSON.parse(JSON.stringify(reactFlowInstance.toObject().nodes));
    let edges: any = JSON.parse(JSON.stringify(reactFlowInstance.toObject().edges));
    let currentNode: any;
    nodes = nodes.filter((nodeData: any) => {
      if (nodeData.id == currentNodeId) {
        currentNode = nodeData;
        return false;
      }
      return true;
    });
    let currentEdge: any;
    edges = edges.filter((edgeData: any) => {
      if (edgeData.target == currentNodeId) {
        currentEdge = edgeData;
        return false;
      }
      return true;
    });

    let newNodeId = '';
    // Node
    let newNode: any = {};
    const metaData: any = {};
    if (newNodeType) {
      const nodeId = getId(newNodeType);
      newNode = {
        id: nodeId,
        type: nodeMasterDataMap[newNodeType].awsState,
        position: currentNode?.position || { x: 0, y: 0 },
        positionAbsolute: currentNode?.positionAbsolute || { x: 0, y: 0 },
        data: {
          isEdit: true,
          nodeType: newNodeType,
          metaData: metaData,
          isWorkFlowEditable: isWorkFlowEditable,
          onSelect: onSelect,
          onDelete: onDelete,
          getOutputNodeList,
          getPreviousImmediateNode,
          setData: function (key: any, value: any) {
            if (this.metaData) {
              this.metaData[key] = value;
            }
            updateElementsOnNodeUpdate(nodeId, key, value);
          },
        },
      };
      newNodeId = newNode.id;
      const connectingEdge: Edge = {
        id: currentEdge.id,
        type: 'bezier',
        source: currentEdge.source,
        sourceHandle: currentEdge.sourceHandle || 'out',
        target: newNodeId,
        targetHandle: 'in',
      };
      const currentLength = nodes?.length;
      nodes.push(newNode);
      edges.push(connectingEdge);
      addOptionNode(newNode, nodes, edges);
      setNodes(nodes, false);
      props.setEdges(edges);
    }
  };


  const onSelect = (data: any, currentX = 0, currentY = 0) => {
    const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect
      ? reactFlowWrapper.current.getBoundingClientRect()
      : undefined;
    const focusOnSelectNextNode = context?.focusOnSelectNextNode;
    if (!reactFlowInstance) {
      return;
    }
    let shakeTimeoutId: NodeJS.Timeout;
    if(data.isFocusOnSelectNextNode){
      if(data?.isFocusOnSelectNextNode){
        if(isShake){
          setIsShake(false)
          shakeTimeoutId = setTimeout(()=>{
            setIsShake(true)
          },500)
        } else {
          setIsShake(true)
        }
      }
      if(props.setWorkflowContext){
        props.setWorkflowContext((oldValue :any)=>{
          return {...oldValue, focusOnSelectNextNode: data}
        });
      }
      // setFocusOnSelectNode(data)
      return () => {
        if (shakeTimeoutId) {
          clearTimeout(shakeTimeoutId);
        }
      };
    } else if(focusOnSelectNextNode) {
      if(!data.sourceId && focusOnSelectNextNode?.sourceId){
        data.sourceId = focusOnSelectNextNode.sourceId;
        data.sourceHandle = focusOnSelectNextNode.sourceHandle;
        data.metadata = focusOnSelectNextNode.metadata;
        props.setWorkflowContext((oldValue :any)=>{
          return {...oldValue, focusOnSelectNextNode: undefined}
        });
      }
    }
    const nodes: any = reactFlowInstance.toObject().nodes;
    (nodes || []).some((node: any) => {
      if (node.id === data.sourceId) {
        currentY = currentY ? currentY : node?.position?.y + 200;
        return true;
      }
    });
    currentX = currentX || 100;
    currentY = currentY || 100;
    const reactFlowBoundsLeft = reactFlowBounds ? reactFlowBounds?.left : 0;
    const reactFlowBoundsTop = reactFlowBounds ? reactFlowBounds?.top : 0;

    if (reactFlowInstance) {
      // const marginData = reactFlowInstance.project({x:100-reactFlowBounds.left,y:100-reactFlowBounds.top })
      const newPosition = reactFlowInstance.project({
        x: currentX - reactFlowBoundsLeft,
        y: currentY - reactFlowBoundsTop + 100,
      });

      let newNodeId = '';
      // Node
      let newNode: any = {};
      if (data.node) {
        const nodeId = getId(data.node);
        newNode = {
          id: nodeId,
          type: nodeMasterDataMap[data.node].awsState,
          position: newPosition,
          positionAbsolute: newPosition,
          data: {
            nodeType: data.node,
            metaData: data?.metaData || {},
            isEdit: data?.isEdit != undefined ? data.isEdit : true,
            isWorkFlowEditable: isWorkFlowEditable,
            onSelect: onSelect,
            onDelete: onDelete,
            ...data,
            getOutputNodeList,
            getPreviousImmediateNode,
            setData: function (key: any, value: any) {
              if (this.metaData) {
                this.metaData[key] = value;
              }
              updateElementsOnNodeUpdate(nodeId, key, value);
            },
          },
        };
        newNodeId = newNode.id;
        const connectingEdge: Edge = {
          id: getId('edge'),
          type: 'bezier',
          source: data.sourceId,
          sourceHandle: data.sourceHandle || 'out',
          target: newNodeId,
          targetHandle: 'in',
        };
        const nodes: any = reactFlowInstance.toObject().nodes;
        const edges: any = reactFlowInstance.toObject().edges;
        const currentLength = nodes?.length;
        nodes.push(newNode);
        if(connectingEdge.source){
          edges.push(connectingEdge);
        }
        if (data?.isEdit != false) {
          addOptionNode(newNode, nodes, edges);
        }
        const newNodeIdList:any = []
        nodes.forEach((node:any, index:number)=>{
          if(index >= currentLength){
            newNodeIdList.push(node?.id)
          }
        })
        setNewNodeIdList(newNodeIdList);
        setNodes(nodes, false);
        props.setEdges(edges);

      }
    }
    // takeSnapshot()
  };

  const nodeMasterDataMap = context.nodeMasterDataMap;
  useEffect(() => {
    if (
      props.nodes?.length === 1 &&
      reactFlowInstance &&
      !props.workflowMasterId
    ) {
      const newNode = props.nodes[0];
      const nodeInfo = nodeMasterDataMap[newNode?.data?.nodeType];
      if (
        newNode?.id &&
        nodeInfo?.outputNodeList &&
        nodeInfo?.outputNodeList?.indexOf('AllCondition') !== -1
      ) {
        const nodes: any = reactFlowInstance.toObject().nodes;
        const edges: any = reactFlowInstance.toObject().edges;
        addOptionNode(newNode, nodes, edges);
        setNodes(nodes, false);
        props.setEdges(edges);
      }
    }
  });
  const nodes = getPositionForDefaultNode();
  const edges = (props.edges || []).map((edge: any) => {
    return { ...edge };
  });



  const onDrop = (event: DragEvent) => {
    event.preventDefault();


    if (reactFlowInstance) {
      const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect
        ? reactFlowWrapper.current.getBoundingClientRect()
        : {};
      const nodeData = event.dataTransfer.getData('application/reactflow');
      const parsedData = JSON.parse(nodeData);
      const type = parsedData.type;
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left - 100,
        y: event.clientY - reactFlowBounds.top,
      });
      if (type === 'WORKFLOWS') {
        // --- Kept for reference ---
        setLoading(true);
        let triggerNodeId: any = undefined;
        props.nodes.some((element: any) => {
          if (element.data.nodeType === props.triggerCondition) {
            triggerNodeId = element.id;
            return true;
          }
        });

        getWorkflowByMasterId(
          parsedData.masterId,
          accountUuId,
          (response: any) => {
            if (response?.graph) {
              const idMap: any = {};

              const graph = response.graph;
              let newNodes = graph.nodes.map((element: any) => {
                if (element.data.nodeType === props.triggerCondition) {
                  idMap[element.id] = triggerNodeId;
                } else {
                  idMap[element.id] = generateUniqueKey();
                }
                element.id = idMap[element.id];
                element.data = element.data || {};

                element.data.setData = function (key: any, value: any) {
                  if (this.metaData) {
                    this.metaData[key] = value;
                  }
                  updateElementsOnNodeUpdate(element.id, key, value);
                };
                element.position = {
                  x: element.position.x + position.x,
                  y: element.position.y + position.y,
                };
                element.data.getOutputNodeList = getOutputNodeList;
                element.data.getPreviousImmediateNode =
                  getPreviousImmediateNode;
                element.data.isWorkFlowEditable = isWorkFlowEditable;
                element.data.onSelect = onSelect;
                return element;
              });

              newNodes = newNodes.filter((element: any) => {
                if (element.data.nodeType === props.triggerCondition) {
                  return false;
                } else {
                  return true;
                }
              });

              const newEdgeList = (graph?.edges || []).map((edge: any) => {
                edge.id = generateUniqueKey();
                edge.source = idMap[edge.source];
                edge.target = idMap[edge.target];
                return edge;
              });

              props.setEdges([...props.edges, ...newEdgeList], false);

              setNodes([...props.nodes, ...newNodes], false);
            }
            setLoading(false);
          },
          (error: any) => {

            setLoading(false);
          }
        );
      } else {
        const nodeId = getId(type);
        const newNode: Node = {
          id: nodeId,
          type: nodeMasterDataMap[type]
            ? nodeMasterDataMap[type].awsState
            : 'Pass',
          position,
          positionAbsolute: position,

          data: {
            isSkipFitView: true,
            metaData: type === 'WORKFLOWS' ? parsedData : {},
            nodeType: type,
            isWorkFlowEditable: isWorkFlowEditable,
            getOutputNodeList,
            getPreviousImmediateNode,
            setData: function (key: any, value: any) {
              if (this.metaData) {
                this.metaData[key] = value;
              }
              updateElementsOnNodeUpdate(nodeId, key, value);
            },
            onSelect: onSelect,
            onDelete: onDelete,
          },
        };
        const nodeList = reactFlowInstance.toObject()?.nodes || [];
        const edgeList = reactFlowInstance.toObject()?.edges || [];
        nodeList.push(newNode);
        addOptionNode(newNode, nodeList, edgeList, true);
        setNodes(nodeList, false);
        props.setEdges(edgeList);
      }

      // setLoading(true);
      // getWorkflowNodeMetaData(
      //   props.flowType,
      //   MetaDataFieldType.nodeInfo,
      //   type,
      //   (data: any) => {
      //     if (data) {
      //       nodeMasterDataMap[type].outputNodeList = data.outputNodeList || [];
      //       nodeMasterDataMap[type].userInputFieldList =
      //         data.userInputFieldList || [];
      //       nodeMasterDataMap[type].conditionInfo = data.conditionInfo;
      //       nodeMasterDataMap[type].inputParamList = data.inputParamList || [];
      //       if (context.setNodeMasterDataMap) {
      //         context.setNodeMasterDataMap(nodeMasterDataMap);
      //       }
      //       const nodeId = getId(type);
      //       const newNode: Node = {
      //         id: nodeId,
      //         type: nodeMasterDataMap[type].awsState,
      //         position,
      //         data: {
      //           metaData: {},
      //           nodeType: type,
      //           getOutputNodeList,
      //           getPreviousImmediateNode,
      //           isWorkFlowEditable: isWorkFlowEditable,
      //           setData: function (key: any, value: any) {
      //             if (this.metaData) {
      //               this.metaData[key] = value;
      //             }
      //             updateElementsOnNodeUpdate(nodeId, key, value);
      //           },
      //           onSelect: onSelect,
      //         },
      //       };
      //       props.setElements((es: Elements) => es.concat(newNode));
      //     }
      //     setLoading(false);
      //   },
      //   (error: any) => {
      //
      //     setLoading(false);
      //   }
      // );
    }
    // takeSnapshot()
  };

  const getSourceHandle = (nodeId: string) => {
    let sourceHandle = 'out';
    edges.some((edge: any) => {
      if (edge.target === nodeId) {
        sourceHandle = edge.sourceHandle;
        return true;
      }
    });
    return sourceHandle
  };

  const getCurrentNodes = () => {
    let rootNodeId: string;
    let rootNodeType: string;
    nodes.some((element: any) => {
      if (element?.data?.isStartNode) {
        rootNodeId = element.id;
        rootNodeType = element?.data?.nodeType;
        return true;
      }
    });
    const newElements = nodes.map((element: any) => {
      const newElement = JSON.parse(JSON.stringify(element));
      newElement.data = newElement.data || {};
      delete newElement.handleBounds;
      if(props.isViewOnly && newElement.data?.isEdit ){
        newElement.data.isEdit = false
      }
      newElement.data.isWorkFlowEditable = isWorkFlowEditable;
      newElement.data.rootNodeType = rootNodeType;
      newElement.data.isNewlyAdded = (newNodeIdList||[]).includes(element?.id);
      newElement.data.rootNodeId = rootNodeId;
      newElement.data.isViewOnly = !!props.isViewOnly;
      newElement.data.getOutputNodeList = getOutputNodeList;
      newElement.data.getPreviousImmediateNode = getPreviousImmediateNode;
      newElement.data.getSourceHandle = getSourceHandle;
      newElement.data.onSelect = onSelect;
      newElement.data.reactFlowInstance = reactFlowInstance;
      newElement.data.onDelete = onDelete;
      newElement.data.replaceNodeById = replaceNodeById;
      newElement.data.setIsEdit = function (isEdit: boolean) {
        if (!reactFlowInstance) {
          return;
        }
        const nodeList = reactFlowInstance.toObject()?.nodes || [];
        const newNodes = nodeList.map((element: any) => {
          if (element?.id === newElement?.id) {
            element.data.isEdit = isEdit;
          }
          return { ...element };
        });
        setNodes(newNodes, true);
      };

      newElement.data.setData = function (key: any, value: any) {
        if (this.metaData) {
          this.metaData[key] = value;
        }
        updateElementsOnNodeUpdate(newElement.id, key, value);
      };

      return newElement;
    });
    return newElements;
  };
  // For care journey, we will be hiding library similar to workflow
  const isShowLibList = false; //props.flowType ==  FlowType.careJourney ;

  const [allowedOutputNodeList, setAllowedOutputNodeList] = useState<Array<string>>([])


  useEffect(() => {
    const masterNodeTypeList: Array<string> = [];

    props.nodes.forEach((node:any)=>{


      (context?.nodeMasterDataMap?.[node?.data?.nodeType]?.optionInfo?.possibleValueList||[]).forEach((possibleValue:any)=>{
        (context?.nodeMasterDataMap?.[possibleValue?.nodeType]?.outputNodeList || [])?.forEach((nodeType:string)=>{
          if( !context?.nodeMasterDataMap[nodeType]?.isDeprecated &&  !masterNodeTypeList.includes(nodeType)){
            masterNodeTypeList.push(nodeType)
          }
        })
      });

      const outputNodeList =
      context?.nodeMasterDataMap?.[node?.data?.nodeType]?.outputNodeList || []
      outputNodeList.forEach((nodeType:string)=>{
        if( !context?.nodeMasterDataMap[nodeType]?.isDeprecated &&  !masterNodeTypeList.includes(nodeType)){
          masterNodeTypeList.push(nodeType)
        }
      })

    });


    setAllowedOutputNodeList(masterNodeTypeList);
  }, [props.nodes?.length]);


    // useEffect(()=>{
    //   if(props.nodes?.length < 3){
    //   fitView({duration:400, maxZoom:1, minZoom:1})
    //   if(reactFlowInstance  && props.nodes?.length <= 1){
    //     const viewPort:any = {y:0, x: 0.7, zoom:1}
    //     reactFlowInstance?.setViewport(viewPort)
    //   }
    // }

    // },[fitView, props.nodes])


    useEffect(() => {
      if (props.isViewOnly) {
        const timeoutId : NodeJS.Timeout = setTimeout(() => {
          if( updateCount < 3 && !window.location.href.includes('automation/execution/audit')){
            setUpdateCount((prev) => {
              return prev + 1;
            });
          }
          fitView({duration: 2000});
        }, 2000);
        return () => clearTimeout(timeoutId);
      }
    }, [ fitView, props.nodes?.length]);



  useEffect(()=>{
    let timeoutId: NodeJS.Timeout; 
    if(!loading && reactFlowInstance && !props.isViewOnly &&  props.nodes?.length < 5){
      timeoutId =  setTimeout(()=>{
        fitView({duration:400})
      }, 500)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };

  },[props.nodes?.length])


  useEffect(()=>{
    let timeoutId : NodeJS.Timeout;
    if(!props.isViewOnly){
      timeoutId = setTimeout(()=>{
        fitView({duration:400})
      }, 500)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  },[loading, reactFlowInstance, fitView])






  useAutoLayout({ direction: 'TB', isExecutionLog: isExecutionLog });


  const CATEGORY = {
    OPERATION: 'OPERATION',
    FILTER: 'FILTER',
    CONDITION: 'CONDITION'
  }

  const getEdgeColor = (edge: any) => {
    const node = (props.nodes || []).find((node: any) => {
      return node?.id == edge?.source
    })

    const nodeInfo = context.nodeMasterDataMap[node?.data?.nodeType]
    switch (nodeInfo?.category?.code) {
      case CATEGORY.FILTER: return Colors.FoldPixel.ACCENT_DARK_PINK;
      case CATEGORY.OPERATION: return Colors.FoldPixel.GRAY200;
      default: return Colors.FoldPixel.PRIMARY300;
    }

  }
  const [clickedEdgeId, setClickedEdgeId] = useState<string | null>(null);
  const handleEdgeClick = (event: React.MouseEvent, edge: Edge<any>) => {
    setClickedEdgeId(edge.id);
  };

  const currentEdges = edges.map((edge: { id: null; }) => {
    const edgeColor = getEdgeColor(edge);
    const focusColor = context?.focusOnSelectNextNode?.isFocusOnSelectNextNode
      ? Colors.FoldPixel.GRAY100
      : Colors.FoldPixel.GRAY200;

    const isClicked = (edge.id === clickedEdgeId);

    return {
      ...edge,
      type: 'bezier',
      style: {
        strokeWidth: 1,
        stroke: isClicked ? Colors.FoldPixel.GRAY400 : (focusColor || edgeColor),
      }
    };
  });

  const handleClick = () => {
    if (context?.focusOnSelectNextNode?.isFocusOnSelectNextNode) {
      props.setWorkflowContext((oldValue: any) => ({
        ...oldValue,
        focusOnSelectNextNode: undefined
      }));
    }
  };

  const edgeOptions = {
    type: 'bezier',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: context?.focusOnSelectNextNode?.isFocusOnSelectNextNode
        ? Colors.FoldPixel.GRAY100
        : Colors.FoldPixel.GRAY200,
      height: 20,
      width: 20
    }
  };

  useEffect(()=>{
    if(props.setWorkflowContext){
      props.setWorkflowContext((oldValue :any)=>{
        return {...oldValue, isAllowInterAction: isAllowInterAction}
      });
    }
  }, [isAllowInterAction])

  const isValidForm = (localUserInputFieldMap: any) => {
    const isInvalid = Object.keys(localUserInputFieldMap).some((key: string) => {
      const userInputFieldList = localUserInputFieldMap[key].userInputFieldList;
      return (userInputFieldList || []).some((userInputField: IUserInputField) => {
        return isInvalidUserInput(userInputField, userInputFieldList);
      });
    });
    if (isInvalid) {
      return false;
    }
    return true
  }

  const isWorkflowTreeValid = (nodeList: any) => {
    let isTreeValid = true
    nodeList.some((node: any) => {
      const nodeType = node?.data?.nodeType
      const nodeData = nodeMasterDataMap[nodeType]
      const userInputFieldList = node?.data?.metaData?.userInputFieldList || nodeData?.userInputFieldList;
      const inputFieldToCheck = JSON.parse(JSON.stringify({
        out: {
          userInputFieldList: cloneDeep(userInputFieldList)
        }
      }))
      if(nodeType != 'ReminderAfterCurrentTimeV2'){
        isTreeValid = isValidForm(inputFieldToCheck);
      }
      return !isTreeValid
    })
    return isTreeValid
  }


  useEffect(() => {
    const validTree = isWorkflowTreeValid(nodes)
    props?.onChangeTreeValidity && props?.onChangeTreeValidity(validTree)
  }, [nodes])
  
  return (
    <>
      <View flex={2} flexDirection="row" height="full" backgroundColor={'#ffffff'}>

        {

        !isExecutionLog && allowedOutputNodeList?.length && !props.isViewOnly && reactFlowInstance ? (
          <View flex={2.5} >
            {/* <FlowSideBar
              triggerCondition={props.triggerCondition}
              libNodeIdList={props.libNodeIdList}

            /> */}
            <div className={isShake ? 'shake' : ''}>
            <NewOutputModelV2

              getOutputNodeList={getOutputNodeList}
              onPress={(data:any)=>{
                onSelect(data,0,0)

              }}
              parentDisplayName={props.triggerCondition ? context?.nodeMasterDataMap?.[props.triggerCondition]?.displayName : ''}
              allOutputNodeList={allowedOutputNodeList}
            >
            </NewOutputModelV2>
            </div>
          </View>
        ) : (
          <></>
        )
        }
        {loading && (
          <View
            zIndex={10}
            position="absolute"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            top="50%"
            left="50%"
          >
            <Spinner size="lg" />
          </View>
        )}
        <View  flex={7.5} ref={reactFlowWrapper}
        paddingY={2}
        paddingLeft={props?.isViewOnly ? 0 : 2}
        paddingX={props?.isViewOnly ? 2 : 0}

        >

          <ReactFlow
            onBlur={()=>{setClickedEdgeId(null)}}
            onEdgeClick={handleEdgeClick}
            fitView={true}
            onClick={handleClick}
            key={(props.workflowMasterId || '') + updateCount}
            defaultEdgeOptions={edgeOptions}
            proOptions={proOption}
            nodes={getCurrentNodes()}
            edges={currentEdges}
            nodeTypes={nodeTypes}
            onConnect={onConnect}
            onNodeDragStop={onNodeDragStop}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onInit={onInit}
            onDrop={onDrop}
            onDragOver={onDragOver}
            style={{
              background: !isWorkFlowEditable ? '#F6F7F8' : '#F6F7F8',
              borderRadius: 12
            }}
          >
            {(isWorkFlowEditable || isExecutionLog) && (
              <Background
                variant={BackgroundVariant.Dots}
                gap={30}
                size={2}
                color={Colors.FoldPixel.GRAY300}
              />
            )}
            <Controls
              showInteractive={true}
              showFitView={true}
              onInteractiveChange={(value:any) => {
                onInterActionChange(value)

              }}
            />
            {isWorkFlowEditable && <MiniMap />}
          </ReactFlow>

        </View>

        {props.getTriggerElement && props.getTriggerElement()}
        {props.showGoalReviewModal &&
          props.showGoalReviewModal &&
          props.workflowGoalAndReview &&
          props.workflowGoalAndReview()}
      </View>

      {loading}
      </>

  );
};

export default FlowComponent;
