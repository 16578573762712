import {Divider, Pressable} from 'native-base';
import {DisplayText} from '../common/DisplayText/DisplayText';
import Stack from '../common/LayoutComponents/Stack';
import { FoldButton } from '../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE } from '../../constants';
import { useIntl } from 'react-intl';
import SidecarCreateAction from './SidecarCreateAction';
import { isAccountConfigEnabled } from '../../utils/configUtils';
import { CONFIG_CODES } from '../../constants/AccountConfigConst';
import { TaskPanelType } from '../TaskCard/TaskEnum';
import Close from '../../assets/svg/Close';
import { Colors } from '../../styles';
import { IContact } from '../RightSideContainer/TeamInbox/Conversations/interfaces';

interface SidecarTitleProps {
  paddingHorizontal?: number;
  paddingVertical?: number;
  onClose: () => void;
  titleId: string;
  actionButtonId?: string;
  actionButtonPress?: () => void;
  showCreateNewButton?: boolean;
  panelType?: TaskPanelType;
  patientUuid?: string;
  contactInfo?: IContact | undefined;
}

export const SidecarTitle = (props: SidecarTitleProps) => {
  const {
    onClose,
    paddingHorizontal,
    titleId,
    paddingVertical,
    actionButtonId,
    actionButtonPress,
    showCreateNewButton
  } = props;
  const intl = useIntl();
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: paddingHorizontal || 16,
          paddingVertical: paddingVertical || 0,
        }}
      >
        <DisplayText
          textLocalId={titleId}
          size={'mdSemibold'}
          extraStyles={{
            color: 'black',
            fontSize: 14,
            fontWeight: '500',
          }}
        />
        <Stack
          direction="row"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {actionButtonId && (
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  actionButtonPress?.();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: actionButtonId || '',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          )}
        {showCreateNewButton &&  (
          <SidecarCreateAction
            patientUuid={props.patientUuid}
            panelType={props.panelType}
            contactInfo={props?.contactInfo}
          />
        )}
        <Pressable paddingLeft={2} onPress={onClose}>
          <Close fill={Colors.Custom.Gray500} size={24} />
        </Pressable>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  );
};
