import { gql } from "@apollo/client";

export const GET_GOAL_ENTITIES = gql`
  query GetGoalEntities(
    $searchTerm: String!
    $limit: Int!
    $offset: Int!
    $categoryCodes: [GoalTargetEntityType]
  ) {
    getGoalTargetEntities(params: {searchTerm: $searchTerm, limit: $limit, offset: $offset, categoryCodes: $categoryCodes}) {
      results {
        code
        display
        formId
        system
        unit
      }
    }
  }
`;

export const GET_GOAL_TEMPLATES = gql`
  query GetGoalTemplates(
    $searchTerm: String,
    $limit: Int!,
    $offset: Int!
  ) {
    getGoalTemplates(params: {searchTerm: $searchTerm, limit: $limit, offset: $offset}) {
      results {
        id
        template
        title
        entityTypeId
      }
    }
  }
`;

const ADD_OR_UPDATE_GOAL_TEMPLATES = gql`
  mutation AddOrUpdateGoalTemplates($params: [AddOrUpdateGoalTemplateInput!]) {
    addOrUpdateGoalTemplates(params: $params) {
      id
    }
  }
`;

const ADD_OR_UPDATE_CONTACT_GOAL_STATUS = gql`
  mutation addOrUpdateContactGoals($params: [AddOrUpdateContactGoalInput]!) {
    addOrUpdateContactGoals(params: $params) {
      id
      statusId
    }
  }
`;

const ADD_OR_UPDATE_BARRIER_STATUS = gql`
  mutation addOrUpdateContactBarriers($params: [AddOrUpdateContactBarrierInput]!) {
    addOrUpdateContactBarriers(params: $params) {
      id
      statusId
    }
  }
`;

export default {
  GET_GOAL_ENTITIES,
  GET_GOAL_TEMPLATES,
  ADD_OR_UPDATE_GOAL_TEMPLATES,
  ADD_OR_UPDATE_CONTACT_GOAL_STATUS,
  ADD_OR_UPDATE_BARRIER_STATUS
}
