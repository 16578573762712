import React from 'react';
import {Circle, Defs, Path, Stop, Svg} from 'react-native-svg';
import { ICommonSvgProps } from './interfaces';

const InfoCircleSvg = (props: ICommonSvgProps) => {
  return (
    <svg
      width={props.size || "42"}
      height={props.size || "42"}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0002 30.583V19.083M21.0002 14.2913V13.333M40.1668 20.9997C40.1668 31.5851 31.5856 40.1663 21.0002 40.1663C10.4147 40.1663 1.8335 31.5851 1.8335 20.9997C1.8335 10.4142 10.4147 1.83301 21.0002 1.83301C31.5856 1.83301 40.1668 10.4142 40.1668 20.9997Z"
        stroke={props.customStrokeColor || "#8A94A8"}
        strokeWidth={props.strokeWidth || "1.91667"}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default InfoCircleSvg;
