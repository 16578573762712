import React from 'react';
import {Svg, Path} from 'react-native-svg';
import {ICommonSvgProps} from '../interfaces';
import {Colors} from '../../../../styles/Colors';

const AngleDownSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props?.width || 12}
      height={props?.height || 12}
      viewBox="0 0 12 12"
      fill="none"
    >
      <Path
        d="M9.5 4.5L6 7.5L2.5 4.5"
        stroke={props?.customStrokeColor || Colors.FoldPixel.GRAY300}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default React.memo(AngleDownSvg);
