import React from 'react';
import { View, Text } from 'react-native';
import { svgStyles } from '../SvgStyles';
import { DisplayText } from '../../DisplayText/DisplayText';
import { Colors } from '../../../../styles';
import Svg, { Circle, Defs, LinearGradient, Path, RadialGradient, Stop } from 'react-native-svg';

const NoBarrierSvg = (props: {titleId?: string; fillColor?: string}) => {

  return (
    <View style={svgStyles.container}>
      <Svg
        width="102"
        height="102"
        viewBox="0 0 102 102"
        fill="none"
      >
        <Circle
          cx="51"
          cy="51"
          r="50"
          stroke="url(#paint0_radial_1271_42047)"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="1.46 1.46"
        />
        <Circle
          cx="51.0007"
          cy="50.9997"
          r="42.6667"
          stroke="url(#paint1_radial_1271_42047)"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="1.46 1.46"
        />
        <Path
          d="M15.8545 51.1016C15.8545 31.7716 31.5245 16.1016 50.8545 16.1016C70.1845 16.1016 85.8545 31.7716 85.8545 51.1016C85.8545 70.4315 70.1845 86.1016 50.8545 86.1016C31.5245 86.1016 15.8545 70.4315 15.8545 51.1016Z"
          fill="url(#paint2_linear_1271_42047)"
        />
        <Path
          d="M50.9999 45.3299V51.7188M52.9902 37.3578L54.9404 39.0197C55.4317 39.4384 56.0417 39.6915 56.6852 39.7429L59.2392 39.9464C60.7408 40.0662 61.9335 41.2582 62.0534 42.7598L62.2564 45.3143C62.3078 45.9577 62.5618 46.5687 62.9805 47.06L64.6417 49.0096C65.6187 50.1561 65.6189 51.8426 64.6418 52.9891L62.9807 54.939C62.562 55.4303 62.3083 56.0415 62.2569 56.685L62.0526 59.239C61.9327 60.7406 60.7419 61.9333 59.2403 62.0531L56.6853 62.257C56.0419 62.3083 55.4313 62.5608 54.9399 62.9795L52.9902 64.6414C51.8437 65.6185 50.1562 65.6187 49.0096 64.6416L47.0599 62.9796C46.5686 62.5609 45.958 62.308 45.3145 62.2567L42.7596 62.0531C41.258 61.9333 40.0671 60.7409 39.9473 59.2393L39.7429 56.6851C39.6916 56.0416 39.4379 55.431 39.0192 54.9397L37.358 52.9892C36.381 51.8427 36.3805 50.1567 37.3576 49.0102L39.0202 47.0597C39.4389 46.5684 39.6902 45.9577 39.7415 45.3143L39.9459 42.7601C40.0657 41.2585 41.26 40.0661 42.7616 39.9463L45.3137 39.7427C45.9572 39.6914 46.5681 39.4384 47.0594 39.0197L49.0098 37.3578C50.1563 36.3807 51.8437 36.3807 52.9902 37.3578ZM51.0795 56.5104V56.6701L50.9204 56.6695V56.5104H51.0795Z"
          stroke="#A7AFBE"
          stroke-width="1.59722"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Defs>
          <RadialGradient
            id="paint0_radial_1271_42047"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(51.0001 73.6082) rotate(-90.639) scale(78.4672)"
          >
            <Stop stop-color="#A1AABA" />
            <Stop offset="1" stop-color="#E4E7EC" />
          </RadialGradient>
          <RadialGradient
            id="paint1_radial_1271_42047"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(51.0007 70.292) rotate(-90.639) scale(66.9587)"
          >
            <Stop stop-color="#A1AABA" />
            <Stop offset="1" stop-color="#E4E7EC" />
          </RadialGradient>
          <LinearGradient
            id="paint2_linear_1271_42047"
            x1="26.0002"
            y1="24"
            x2="74.0002"
            y2="84.75"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stop-color="#F9FAFC" />
            <Stop offset="1" stop-color="#F1F2F3" />
          </LinearGradient>
        </Defs>
      </Svg>
      {props.titleId && (
        <Text style={svgStyles.svgText}>
          <DisplayText textLocalId={props.titleId} textType="Text" extraStyles={{color: Colors.FoldPixel.GRAY300}} />
        </Text>
      )}
    </View>
  );
};

export default NoBarrierSvg;
