import { useMutation } from '@apollo/client';
import { Checkbox,Popover,Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Content } from 'antd/lib/layout/layout';
import parse from 'html-react-parser';
import moment from 'moment';
import { Divider,HStack,Spinner,Text,useToast,View,VStack } from 'native-base';
import React,{ useContext,useEffect,useMemo,useRef,useState } from 'react';
import { useIntl } from 'react-intl';
import { CHAT_DELIVERY_STATUS,COPY_MESSAGE_CODE,DATE_FORMATS } from '../../../../constants';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { SUPPORTED_EVENT_CODE } from '../../../../constants/WebSocketConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { TaskQueries } from '../../../../services';
import { Colors } from '../../../../styles';
import { testID,TestIdentifiers } from '../../../../testUtils';
import {
  getAccountUUID,
  getInboxTypeCode,
  getPrimaryGenderCode,
  getUserData,
  getUserFullName,
  getUserName,
  getUserUUID,
  isEFaxChannelInboxType,
  isEmailChannelInboxType,
  isHTMLString,
  isSMSChannelInboxType,
  removeScriptsAndStyles,
} from '../../../../utils/commonUtils';
import { showToast,ToastType } from '../../../../utils/commonViewUtils';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { EventBus } from '../../../../utils/EventBus';
import { getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { ISipNumberList,IUserList } from '../../../RightSideContainer/TeamInbox/CallLogs/interface';
import { MessageCommunicationTypes } from '../../../RightSideContainer/TeamInbox/Conversations/CommunicationTypes';
import { ICommunicationType } from '../../../RightSideContainer/TeamInbox/Conversations/CommunicationTypes/interfaces';
import { CHANNEL_TYPE,CHANNEL_TYPE_CODE } from '../../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {
  IConversationData,
  IConversationMessage,
  IConversationMessageContentAttributes,
  IMessageBoxData,
  IMsgAttachment,
  ISearchMessageData,
  ISenderContact,
  ISenderUser,
  MESSAGE_TYPE,
  onAddCommunicationType,
} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import CreateNoteFromMessage from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/CreateNoteFromMessage/CreateNoteFromMessage';
import CustomViewer from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/CustomEditor/CustomViewer';
import { ICopiedMessageItem } from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/interface';
import { MESSAGE_CUSTOM_CARD_KEY,MESSAGE_CUSTOM_CARD_TYPE } from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import { getContactDataAndType,isBroadCastAndPrivateGroup,isGroupConversation,isSMSNotSent } from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import ReplyMessageView from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/ReplyMessageView';
import ActionMsg from '../../ActionMsg/ActionMsg';
import SectionMsgHeader from '../../ActionMsg/SectionMsgHeader';
import { AddNoteView } from '../../AddNoteView';
import { IUserSearch } from '../../CareDashboard/CareDashboardWidget/UserAutoComplete';
import { DisplayCardAvatar } from '../../DisplayCard/DisplayCardAvatar';
import { EmailHtml } from '../../EmailPopupView/EmailHtml';
import { MessageActionID } from '../../MessageAction/MessageActionView';
import ScheduleMessageSvg from '../../Svg/ConversationAllSvg/ScheduleMessageSvg';
import MenuHorizontalIcon from '../../Svg/MenuHorizontalIcon';
import ReadMsgSvg from '../../Svg/ReadMsgSvg';
import SendMesgSvg from '../../Svg/SendMesgSvg';
import StatusErrorSvg from '../../Svg/StatusErrorSvg';
import MsgAttachment from '../MsgAttachment/MsgAttachment';
import EFaxMessageBox from './EFaxMessageBox';
import { IReplyMessageObject } from './interfaces';
import './Message-box.css';
import { CUSTOM_VIEWER_LINK_ATTRIBUTES,MESSAGE_BOX_AVATAR_STYLE } from './MessageBoxConstant';
import { cssProperties,getConditionalStyles,styles } from './MessageBoxStyles';
import { getDisplayText,getSVG } from './MessageBoxTextIconUtils';
import {
  detectURLs,
  getArticleFromMessageText,
  getParentMessageData,
  getSenderUserData,
  goToMessages,
  showMessageOption,
} from './MessageBoxUtils';
import { unReadMessageEntriesInMessageLastSeen } from './MessageReadInfo/MessageReadInfoService';
import MessageTaskCountView from './MessageTaskCountView';
import './MessageViewer.css';
import MissedCallActionMessage from './MessagingCustomCard/CallLogCard/MissedCallActionMessage';
import MessagingCustomCard from './MessagingCustomCard/MessagingCustomCard';

export type MessageBoxProps = {
  navigation: any;
  id?: string | number;
  uuid?: string;
  messageType: number;
  position?: 'left' | 'right';
  text?: string;
  dateStr?: string;
  senderFullName?: string;
  attachments?: IMsgAttachment[];
  senderType: string;
  senderUser?: ISenderUser;
  senderContact?: ISenderContact;
  showModal?: any;
  onActionPerformed?: (actionCode?: string) => void;
  onReplyCallback?: (object: IReplyMessageObject) => void;
  private?: boolean;
  conversation: IConversationData;
  selectedInboxTypeCode?: string;
  conversationMessage?: IConversationMessage;
  parentMessage?: IMessageBoxData;
  onParentMsgClick?: (parentMsg: IMessageBoxData) => void;
  onPressMore?: () => void;
  messageAction?: any;
  item?: any;
  onMessageAction?: (
    actionId: MessageActionID,
    data?: any,
    replyObject?: IReplyMessageObject,
    extraData?: any
  ) => void;
  replyObject?: IReplyMessageObject;
  isSeen: boolean;
  isSeenByUsers?: string[];
  isReadOnlyView?: boolean;
  handleCopyClick?: (data: ICopiedMessageItem) => void
  isCopyModeStateOnCode?: string;
  onAddCommunicationType?: onAddCommunicationType
  taskCount?: number;
  onViewTaskAction?: (messageUuid: string) => void;
  searchMessage?: ISearchMessageData;
  parentMessageUuid?: string;
  clickMessageUuid?: string;
  clickEventId?: string;
  resetClickedMessageUuid?: () => void
  onRedirectToMentionChange?: () => void;
  redirectToMention?: boolean;
  isDisplaySectionMsgHeader: boolean;
  groupMemberDataLoading?: boolean;
  userList?: IUserList[];
  sipNumberList?: ISipNumberList[];
  messageContentAttributes?: any;
} & {type?: 'text'; text?: string};

const MessageBox: React.FC<MessageBoxProps> = (props) => {
  const isComponentMounted = useRef(false);
  const {
    id,
    uuid,
    navigation,
    messageType,
    position = 'left',
    text = '',
    dateStr = '',
    senderFullName = '',
    attachments = [],
    conversation,
    selectedInboxTypeCode,
    conversationMessage,
    showModal,
    onActionPerformed,
    messageAction,
    item,
    onMessageAction,
    replyObject,
    isSeen,
    taskCount,
    searchMessage,
    onRedirectToMentionChange,
    redirectToMention,
    isDisplaySectionMsgHeader
  } = props;
  const isRightAvatarShow = true;
  let mainContainerStyle: any;
  let positionCls;
  const intl = useIntl();
  const toast = useToast();
  const contactInfo = getContactDataAndType(
    conversation || ({} as IConversationData)
  );
  const accountUuid = getAccountUUID();
  const currentUserUUID = getUserUUID();
  const contactData = contactInfo.contactData;
  const genderCode = getPrimaryGenderCode(contactData);
  const currentUserData = getUserData();
  const inboxTypeCode =
    getInboxTypeCode(conversation?.conversationInbox?.channelType) ||
    selectedInboxTypeCode ||
    '';
  const isEFaxInbox = isEFaxChannelInboxType(conversation?.conversationInbox?.channelType);
  const showMessageInfoToAllUsers = isAccountConfigEnabled(
    CONFIG_CODES.SHOW_MESSAGE_INFO_TO_ALL_GROUP_USERS
  );
  const showConversationTaskCount = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_TASK_COUNT_ON_MESSAGE
  );
  const status = item.status;
  const userName = getUserName();
  const isAutoResponse =
    props.conversationMessage?.contentAttributes?.isAutoResponse || false;
  const isScheduleMessage =
    props.conversationMessage?.contentAttributes?.isScheduleMessage || false;
  const isSenderLoggedInUser =
    (props.senderType?.toLowerCase() == 'user' &&
      (props?.senderUser?.uuid == currentUserData?.uuid ||
        props?.senderUser?.id == currentUserData?.id)) ||
    showMessageInfoToAllUsers;

  const [showDrawer, setShowDrawer] = useState({
    showAddNoteModal: showModal.noteModal,
    showAddTaskModal: showModal.taskModal,
    showMessageReadInfoModal: showModal.messageReadInfo,
    messageData: showModal.messageData,
    visible: false,
    attachmentActionVisible: false,
    addClinicalNotesOpen: false,
    addClinicalNotesOpenText: '' as string,
  });
  // const timeoutRef = useRef<NodeJS.Timeout | null>( null);

  useEffect(() => {
    // Set ref to true when component is mounted
    isComponentMounted.current = true;

    // Clean up function to set ref to false when component unmounts
    return () => {
      isComponentMounted.current = false;
      // if(timeoutRef.current){
      //   clearTimeout(timeoutRef.current);
      //   timeoutRef.current= null;
      // }
    };
  }, []); 

  const isCheckBoxVisible = React.useCallback(() => {
    return (
      (props.isCopyModeStateOnCode === COPY_MESSAGE_CODE.COPY_MODE_ON ||
        props.isCopyModeStateOnCode === COPY_MESSAGE_CODE.COPY_MODE_COUNT) &&
      !(conversationMessage?.contentType == MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD ||
        conversationMessage?.contentType === MESSAGE_CUSTOM_CARD_KEY.CUSTOM_CARD)
    );
  }, [props.isCopyModeStateOnCode, conversationMessage?.contentType]);

  const conditionalStyles = React.useMemo(() => {
    return getConditionalStyles(position, isCheckBoxVisible());
  }, [position, isCheckBoxVisible]);

  const [stateData, setStateData] = useState({
    selectedUser: {
      key: currentUserUUID,
      value: currentUserUUID,
      label: getUserFullName(),
    } as IUserSearch,
    isMarkLoading: false as boolean,
    loading: false,
    resendLoading: false,
    searchMessage: props?.searchMessage || {} as ISearchMessageData,
  });
  const commonData = useContext(CommonDataContext);

  const messageObjectCallBack = React.useCallback(
    (attachmentId?: number) => {
      const tempObject: IReplyMessageObject = {
        id,
        messageType,
        position: position,
        text: text,
        dateStr: dateStr,
        conversationMessage,
        senderFullName: senderFullName,
        attachments: attachments,
        attachmentId: attachmentId || undefined,
        private: props?.private || false,
        uuid: props.uuid || '',
      };
      if (props.onReplyCallback) {
        props.onReplyCallback(tempObject);
      }
    },
    [
      id,
      messageType,
      position,
      text,
      dateStr,
      conversationMessage,
      senderFullName,
      attachments,
      props?.private,
      props.uuid,
      props.onReplyCallback,
    ]
  );

  const parentData = getParentMessageData(props?.parentMessage);

  const conversationTaggedItem = getMlovListFromCategory(
    commonData.MLOV,
    'ConversationTaggedItem'
  );

  const conversationUuid = conversation?.uuid || '';
  const itemId = conversationTaggedItem[0]?.id;

  const [CreateConversationTask] = useMutation(
    TaskQueries.CREATE_CONVERSATION_TAGGED_ITEM
  );

  useEffect(() => {
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION && !props?.clickEventId) {
      if (conversation?.conversationMessages[0]?.id === id && redirectToMention) {
        goToMessages(uuid || '');
        onRedirectToMentionChange?.();
      }
    }
    return () => {
      setShowDrawer(() => {
        return {
          showAddNoteModal: false as boolean,
          showAddTaskModal: false as boolean,
          showMessageReadInfoModal: false as boolean,
          messageData: {} as any,
          visible: false,
          attachmentActionVisible: false,
          addClinicalNotesOpen: false,
          addClinicalNotesOpenText: '' as string,
        };
      });
      setStateData(() => {
        return {
          selectedUser: {
            key: currentUserUUID,
            value: currentUserUUID,
            label: getUserFullName(),
          } as IUserSearch,
          isMarkLoading: false as boolean,
          communicationTypes:
            item?.communicationTypes || ([] as ICommunicationType[]),
          loading: false,
          resendLoading: false,
          searchMessage: {} as ISearchMessageData,
        };
      });
    };
  }, [])


  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (props.clickMessageUuid || props?.clickEventId) {
      const element = document.getElementById(
        props.clickMessageUuid || searchMessage?.uuid || ''
      ) as HTMLDivElement;
      if (!element) {
        return;
      }
      element.style.backgroundColor = Colors.Custom.Gray100;
      element.style.borderRadius = '16';
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
      timeout = setTimeout(() => {
        element.style.backgroundColor = 'transparent';
        element.style.borderRadius = '0';
        if (props.resetClickedMessageUuid) {
          props.resetClickedMessageUuid();
        }
      }, 2000);
    }

    return () => {
      if (timeout ) {
        clearTimeout(timeout);
      }
    };
  }, [props.clickMessageUuid, props?.clickEventId]);

  const onFormActionPerformed = (
    action: string,
    payload?: {addOrUpdateTask: {id: string}}
  ) => {
    const eventBus = EventBus.getEventBusInstance();
    switch (action) {
      case COMMON_ACTION_CODES.CANCEL:
        setShowDrawer({...showDrawer, showAddNoteModal: false});
        onActionPerformed && onActionPerformed();
        break;
      case COMMON_ACTION_CODES.COMPLETED:
        setShowDrawer({...showDrawer, showAddNoteModal: false});
        onActionPerformed && onActionPerformed();

        eventBus.broadcastEvent(
          'ADD_OR_UPDATE_NOTES',
          COMMON_ACTION_CODES.COMPLETED
        );
        break;
      case COMMON_ACTION_CODES.TASK_COMPLETE:
        setShowDrawer({...showDrawer, showAddTaskModal: false});
        onActionPerformed && onActionPerformed();
        CreateConversationTask({
          variables: {
            accountUuid: accountUuid,
            conversationUuid: conversationUuid,
            itemTypeId: itemId,
            itemId: payload?.addOrUpdateTask?.id || '',
            messageUuid: conversation?.conversationMessages?.[0]?.uuid,
          },
        }).then(() => {
          eventBus.broadcastEvent('NEW_TASK_ADDED', {});
        });
        break;
      case COMMON_ACTION_CODES.TASK_CANCEL:
        setShowDrawer({...showDrawer, showAddTaskModal: false});
        onActionPerformed && onActionPerformed();
        break;
      case COMMON_ACTION_CODES.READ_DRAWER_CANCEL:
        setShowDrawer({...showDrawer, showMessageReadInfoModal: false});
        onActionPerformed && onActionPerformed();
        break;
      default:
        return '';
    }
  };

  const setMessagesMarkAsReadUnread = async (item: any) => {
    if(!isComponentMounted.current){
      return;
    }
    setStateData((prev) => {
      return {
        ...prev,
        isMarkLoading: true,
      }
    })
    const updateLastSeenRes = await unReadMessageEntriesInMessageLastSeen(conversation.uuid, item?.messageData?.uuid);
    if(!isComponentMounted.current){
      return;
    }
    if (updateLastSeenRes?.id) {
      showToast(
        toast,
        intl.formatMessage({
          id: 'markAsUnreadSuccess',
        }),
        ToastType.success
      );
      //handle conversation unread count
      const eventBus = EventBus.getEventBusInstance();
      eventBus.broadcastEvent(
        SUPPORTED_EVENT_CODE.CONVERSATION_UNREAD_COUNT,
        {}
      );
      const eventConversationData = {
        ...conversation,
        eventCode: SUPPORTED_EVENT_CODE.CONVERSATION_LAST_SEEN_UPDATE
      }
      eventBus.broadcastEvent(
        SUPPORTED_EVENT_CODE.CONVERSATION_LAST_SEEN_UPDATE,
        eventConversationData
      );
    } else {
      showToast(
        toast,
        intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        ToastType.error
      );
    }
    setStateData((prev) => {
      return {
        ...prev,
        isMarkLoading: false,
      }
    })
  };



  const getActionItemView = (actionId: MessageActionID, actionData?: any) => {
    const isSenderLoggedInUser =
      props.senderType?.toLowerCase() == 'user' &&
      (props?.senderUser?.uuid == currentUserData?.uuid ||
        props?.senderUser?.id == currentUserData?.id);
    const isSenderIsUser = props.senderType?.toLowerCase() == 'user'
    if (
      !isSenderLoggedInUser &&
      (actionId == MessageActionID.edit ||
        actionId == MessageActionID.delete)
    ) {
      return null;
    }
    if (
      isBroadCastAndPrivateGroup(props.conversation) &&
      actionId === MessageActionID.assignType
    ) {
      return null;
    }
    if (
      (!isSenderIsUser || position === 'left') &&
      showMessageInfoToAllUsers &&
      actionId == MessageActionID.info
    ) {
      return null;
    }
    const onShowMessageOptionClick = () => {
      if (actionId === MessageActionID.markAsReadUnread) {
        setMessagesMarkAsReadUnread(item);
      }
      if (actionData) {
        onMessageAction?.(
          actionId,
          {...item, isAttachment: true, selectedAttachment: actionData?.id},
          replyObject
        );
        handleAttachmentPopover();
      } else {
        onMessageAction?.(actionId, item, replyObject);
        handleMorePopover();
      }
    }
    return (
      <div className='pressable' onClick={onShowMessageOptionClick}>
        {showMessageOption(
          actionId,
          conversation,
          position,
          contactInfo,
          attachments,
          isSenderLoggedInUser,
          text,
        ) ? (
          <></>
        ) : (
          <HStack padding={2}>
            <View zIndex={9999}>{getSVG(actionId)}</View>
            <Text
              size={'smRegular'}
              color={Colors.FoldPixel.GRAY400}
              style={styles.messageActionLabel}
              lineHeight={20}
              noOfLines={1}
              {...testID('MessageActionLable')}
            >
              {getDisplayText(actionId)}
            </Text>
          </HStack>
        )}
      </div>
    );
  };
  const getMessageSenderName = () => {
    let senderName = '';
    if (props.senderType?.toLowerCase() == 'user') {
      if (
        props?.senderUser?.uuid == currentUserData?.uuid ||
        props?.senderUser?.id == currentUserData?.id
      ) {
        senderName = 'You';
      } else {
        senderName = props?.senderUser?.name || props?.senderFullName || '';
      }
    } else {
      senderName = props?.senderContact?.name || props?.senderFullName || '';
    }
    if (isAutoResponse || messageType === MESSAGE_TYPE.automation_message) {
      senderName = 'Automated Message'
    }
    return senderName.length ? senderName : '';
  };

  const userData = getSenderUserData({
    senderType: props.senderType,
    senderUser: props.senderUser,
    senderContact: props.senderContact,
  });

  const displayCardAvatarUserData = React.useMemo(() => {
    return {
      userId: userData?.userId || -1,
      userType: userData?.userType as any,
      genderCode: genderCode as any,
      imgSrc: '',
      userName: getMessageSenderName(),
      isUserDeleted:
        props?.senderContact?.isDeleted || props?.senderUser?.isDeleted,
    };
  }, [
    userData,
    genderCode,
    getMessageSenderName,
    props?.senderContact?.isDeleted,
    props?.senderUser?.isDeleted,
  ]);

  const handleMorePopover = () => {
    if (!props.uuid) {
      return;
    }
    setShowDrawer({
      ...showDrawer,
      visible: !showDrawer.visible,
    });
  };

  const handleAttachmentPopover = () => {
    setShowDrawer({
      ...showDrawer,
      attachmentActionVisible: !showDrawer?.attachmentActionVisible,
    });
  };


  const isDisplayCallMsgHeader =
    (messageType === MESSAGE_CUSTOM_CARD_TYPE.INCOMING_COMPLETED_CALL_CARD) ||
    (messageType === MESSAGE_CUSTOM_CARD_TYPE.OUTGOING_COMPLETED_CALL_CARD) ||
    (messageType === MESSAGE_CUSTOM_CARD_TYPE.INCOMING_MISSED_CALL_CARD) ||
    (messageType === MESSAGE_CUSTOM_CARD_TYPE.OUTGOING_MISSED_CALL_CARD)


  const onRemoveCommunicationType = React.useCallback(
    (id: string) => {
      const currentTypes: ICommunicationType[] =
        item?.communicationTypes || [];
      const remainingTypes = currentTypes.filter((item) => item.id !== id);
      setStateData((prev) => {
        return {
          ...prev,
          communicationTypes: remainingTypes,
        };
      });
      if (props.onAddCommunicationType) {
        props.onAddCommunicationType({
          messageUuid: item.uuid,
          types: remainingTypes,
        });
      }
    },
    [item, stateData, setStateData, props.onAddCommunicationType]
  );

  const onCheckMessage = React.useCallback(
    (item: CheckboxChangeEvent) => {
      const tempData = {
        text: text,
        date: dateStr,
        name:
          getMessageSenderName()?.trim()?.toLocaleLowerCase() == 'you'
            ? userName
            : getMessageSenderName(),
        id: id,
        userId: userData?.userId,
        isChecked: item?.target?.checked,
        uuid: uuid,
      };
      props.handleCopyClick?.(tempData);
    },
    [
      text,
      dateStr,
      getMessageSenderName,
      userName,
      id,
      userData?.userId,
      uuid,
      props.handleCopyClick,
    ]
  );

  const onMessageTaskCountActionPerformed = React.useCallback(() => {
    if (props?.onViewTaskAction && conversationMessage?.uuid) {
      props?.onViewTaskAction(conversationMessage?.uuid);
    }
  }, [props?.onViewTaskAction, conversationMessage?.uuid]);

  const onReplyMessageClick = React.useCallback(() => {
    const element = goToMessages(parentData.uuid);
    if (!element && props.onParentMsgClick && props.parentMessage) {
      props.onParentMsgClick(props.parentMessage);
    }
  }, [parentData.uuid, props.onParentMsgClick, props.parentMessage]);

  const onClinicalNotesOpen = React.useCallback(
    (text?: string) => {
      setShowDrawer((prev) => {
        return {
          ...prev,
          addClinicalNotesOpen: true,
          addClinicalNotesOpenText: text || '',
        };
      });
    },
    [setShowDrawer]
  );

  const onCreateNoteDrawerClose = React.useCallback(() => {
    setShowDrawer((prev) => {
      return {
        ...prev,
        addClinicalNotesOpen: false,
        addClinicalNotesOpenText: '',
      };
    });
  }, [setShowDrawer]);

  const plainMsgContentElem = useMemo(() => {
    let title: any;
    let subTitle
    const url = detectURLs(text);
    const textUrlString = text
    if (url) {
      const articalInfo = getArticleFromMessageText(text);
      title = articalInfo[0] || '';
      subTitle = articalInfo[1] || '';
    }
    if (position == 'left') {
      return (
        <>
          {conversationMessage?.contentType ===
            MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD ||
            conversationMessage?.contentType ===
            MESSAGE_CUSTOM_CARD_KEY.CUSTOM_CARD ? (
            <MessagingCustomCard
              conversationMessage={conversationMessage}
              navigation={navigation}
            />
          ) : (
            (isEFaxChannelInboxType(conversation?.conversationInbox?.channelType) && !props?.private) ?
              <EFaxMessageBox
                messagePosition={position}
                conversationMessage={conversationMessage}
                conversationUuid={conversationUuid}
              />
            :
            <Content
              className={`${url ? 'link' : 'message-box-viewer'} ${props.private ? 'message-box-font-color' : ''
                }`}
              data-testid={TestIdentifiers.contentViewer}
            >
              <CustomViewer
                linkAttributes={CUSTOM_VIEWER_LINK_ATTRIBUTES}
                extendedAutolinks={true}
                initialValue={textUrlString}
              />
            </Content>
          )}
        </>
      );
    } else {
      return (
        <>
          {conversationMessage?.contentType ===
            MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD ||
            conversationMessage?.contentType ===
            MESSAGE_CUSTOM_CARD_KEY.CUSTOM_CARD ? (
            <MessagingCustomCard
              conversationMessage={conversationMessage}
              textColor={'white'}
              navigation={navigation}
            />
          ) : (
            (isEFaxChannelInboxType(conversation?.conversationInbox?.channelType) && !props?.private) ?
              <EFaxMessageBox
                messagePosition={position}
                conversationMessage={conversationMessage}
                conversationUuid={conversationUuid}
              />
            :
            <Content className="message-box-viewer message-box-font-color"
              data-testid={TestIdentifiers.contentViewer}>
              <CustomViewer
                linkAttributes={CUSTOM_VIEWER_LINK_ATTRIBUTES}
                extendedAutolinks={true}
                initialValue={textUrlString}
              />
            </Content>
          )}
        </>
      );
    }
  }, [text, conversationMessage?.contentAttributes]);


  const messageContentElem = useMemo(() => {
    if (isWeb() && inboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL) {
      let fullHtmlEmailBody: IConversationMessageContentAttributes = {} as any;
      try {
        if (typeof conversationMessage?.contentAttributes === 'object') {
          fullHtmlEmailBody = conversationMessage?.contentAttributes;
        } else {
          fullHtmlEmailBody = JSON.parse(conversationMessage?.contentAttributes);
        }
      } catch (error) {
        fullHtmlEmailBody = {} as any;
      }
      let fromEmail = '';
      if (fullHtmlEmailBody?.email?.from?.length) {
        fromEmail = fullHtmlEmailBody?.email?.from?.join(',');
      } else if (fullHtmlEmailBody.from?.length) {
        fromEmail = fullHtmlEmailBody.from;
      }

      let toEmail = '';
      if (fullHtmlEmailBody?.email?.to?.length) {
        toEmail = fullHtmlEmailBody?.email?.to?.join(',');
      } else if (fullHtmlEmailBody.to?.length) {
        if (typeof fullHtmlEmailBody.to === 'object') {
          toEmail = fullHtmlEmailBody?.to?.join(',');;
        } else {
          toEmail = fullHtmlEmailBody.to;
        }
      } else if (fullHtmlEmailBody?.To?.length) {
        if (typeof fullHtmlEmailBody.To === 'object') {
          toEmail = fullHtmlEmailBody?.To?.join(',');;
        } else {
          toEmail = fullHtmlEmailBody.To;
        }
      }

      if (toEmail || fromEmail) {
        let finalHtmlString = text;
        if (fullHtmlEmailBody?.email?.html_content?.full) {
          finalHtmlString = removeScriptsAndStyles(
            fullHtmlEmailBody?.email?.html_content?.full
          );
        } else if (fullHtmlEmailBody?.emailBody) {
          finalHtmlString = removeScriptsAndStyles(
            fullHtmlEmailBody?.emailBody
          );
        }
        const isHtml = isHTMLString(finalHtmlString);

        if (position == 'left') {
          return (
            <VStack {...testID(TestIdentifiers.messageContent)}>
              <VStack pb={2}>
                <HStack>
                  <VStack>
                    <Text {...testID('From')} fontSize={'md'}>From:</Text>
                  </VStack>
                  <VStack>
                    <Text {...testID('FromEmail')} ml={3}>{fromEmail}</Text>
                  </VStack>
                </HStack>
                <HStack>
                  <VStack>
                    <Text {...testID('To')} fontSize={'md'}>To:</Text>
                  </VStack>
                  <VStack ml={3}>
                    <Text {...testID('ToEmail')}>{toEmail}</Text>
                  </VStack>
                </HStack>
                <HStack>
                  <VStack>
                    <Text {...testID('Subject')} fontSize={'md'}>Subject:</Text>
                  </VStack>
                  <VStack ml={3}>
                    <Text
                      style={styles.emailSubjectContainer}
                      numberOfLines={1}
                      textOverflow={'ellipsis'}
                      {...testID('SubjectContent')}
                    >
                      {fullHtmlEmailBody?.subject || fullHtmlEmailBody?.email?.subject || ''}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
              <Divider />
              <HStack style={styles.w100} flex={1}>
                <EmailHtml html={finalHtmlString} />
              </HStack>
              <HStack>
                <View flex={1} {...testID(TestIdentifiers.messageContent)}>
                  <MsgAttachment
                    attachments={attachments}
                    position={position}
                    dateStr={''}
                    senderFullName={getMessageSenderName()}
                    messageAction={messageAction}
                    getActionItemView={getActionItemView}
                    attachmentActionVisible={
                      showDrawer?.attachmentActionVisible
                    }
                    handleAttachmentPopover={handleAttachmentPopover}
                    communicationTypes={item.communicationTypes || []}
                    loading={stateData.loading}
                    private={props?.private}
                  />
                </View>
              </HStack>
            </VStack>
          );
        } else {
          return (
            <VStack>
              <HStack {...testID(TestIdentifiers.messageContent)}>
                {isHtml ? (
                  <EmailHtml html={finalHtmlString}/>
                ) : (
                  <Content className="message-box-viewer message-box-font-color">
                    <HStack style={styles.w100} flex={1} {...testID('MessageHtmlString')}>
                      <EmailHtml html={finalHtmlString} />
                    </HStack>
                  </Content>
                )}
              </HStack>
              <HStack>
                <View flex={1} {...testID(TestIdentifiers.messageContent)}>
                  <MsgAttachment
                    attachments={attachments}
                    position={position}
                    dateStr={''}
                    senderFullName={getMessageSenderName()}
                    messageAction={messageAction}
                    getActionItemView={getActionItemView}
                    attachmentActionVisible={
                      showDrawer?.attachmentActionVisible
                    }
                    handleAttachmentPopover={handleAttachmentPopover}
                    communicationTypes={item.communicationTypes || []}
                    loading={stateData.loading}
                    private={props?.private}
                  />
                </View>
              </HStack>
            </VStack>
          );
        }
      } else {
        const isTextHtml = isHTMLString(text)
        const newText = isTextHtml ? text : text.replace(/\n/g, '<br>');
        return (
          <VStack>
            <HStack {...testID(TestIdentifiers.messageContent)}>
              {
                position == 'left' ?
                  (
                    <Content
                      className={`message-box-viewer ${props.private ? 'message-box-font-color' : ''
                        }`}
                    >
                      <HStack style={styles.w100} flex={1}>
                        <EmailHtml html={newText} />
                      </HStack>
                    </Content>
                  )
                  :
                  (
                    <Content className="message-box-viewer message-box-font-color">
                      <HStack style={styles.w100} flex={1}>
                        <EmailHtml html={newText} />
                      </HStack>
                    </Content>
                  )
              }

            </HStack>
            <HStack>
              <View flex={1} {...testID(TestIdentifiers.messageContent)}>
                <MsgAttachment
                  attachments={attachments}
                  position={position}
                  dateStr={''}
                  senderFullName={getMessageSenderName()}
                  messageAction={messageAction}
                  getActionItemView={getActionItemView}
                  attachmentActionVisible={showDrawer?.attachmentActionVisible}
                  handleAttachmentPopover={handleAttachmentPopover}
                  communicationTypes={item.communicationTypes || []}
                  loading={stateData.loading}
                  private={props?.private}
                />
              </View>
            </HStack>
          </VStack>
        );
      }
    } else if (
      conversationMessage?.contentType === 12 ||
      conversationMessage?.contentType === 'html'
    ) {
      return <View {...testID(TestIdentifiers.messageContent)}>{parse(text)}</View>;
    } else {
      return plainMsgContentElem;
    }
  }, [text, conversationMessage?.contentType, inboxTypeCode, attachments, conversationMessage?.contentAttributes] );

  const msgElemFromMsgType = useMemo((): JSX.Element => {
    let msgElem = <></>;

    if (position == 'left') {
      mainContainerStyle = {
        flexDirection: 'row',
        alignItems: 'flex-start',
      };
      positionCls = [
        showConversationTaskCount && taskCount
        ? isEFaxInbox ? styles.rceEfaxMboxWithTask : styles.rceMboxWithTask
        : isEFaxInbox ? styles.rceEfaxMbox : styles.rceMbox,
        styles.rceMboxLeft,
        {
          backgroundColor: props.private ? Colors.secondary['500'] : 'white',
        },
      ];
    } else {
      mainContainerStyle = {
        flexDirection: 'row-reverse',
        paddingRight: isCheckBoxVisible() ? isRightAvatarShow ? 60 : 30 : isRightAvatarShow ? 30 : 5,
        alignItems: 'flex-end',
        transition: 'all 0.3s ease-out',
      };
      positionCls = [
        showConversationTaskCount && taskCount
          ? isEFaxInbox ? styles.rceEfaxMboxWithTask : styles.rceMboxWithTask
          : isEFaxInbox ? styles.rceEfaxMbox : styles.rceMbox,
        styles.rceMboxRight,
        {
          backgroundColor: props.private
            ? Colors.secondary['500']
            : Colors.Custom.mainPrimaryPurple,
        },
      ];
    }
    if (messageType === 2) {
      msgElem = <ActionMsg msgString={text} dateStr={dateStr} />;
    } else if (isDisplayCallMsgHeader) {
      msgElem = <MissedCallActionMessage
        dateStr={dateStr}
        item={item}
        conversationMessage={conversationMessage}
        contactInfo={contactInfo}
        selectedConversation={conversation}
        userList={props?.userList}
        sipNumberList={props?.sipNumberList}
        onClinicalNotesOpen={onClinicalNotesOpen}
      />

    } else {
      msgElem = (
        <View
          style={[
            styles.mainContainer,
            mainContainerStyle,
          ]}
          alignItems={'flex-end'}
        >
          {position == 'left' ? (
            <View style={styles.displayCardAvatarContainer3}>
              <DisplayCardAvatar
                avatarStyle={MESSAGE_BOX_AVATAR_STYLE}
                isLetterAvatarShow={true}
                userData={displayCardAvatarUserData}
                hideOnlineIndicator
              />
            </View>
          ) : (
            <></>
          )}
          <View style={styles.rceContainerMbox} key={id}>
            <View style={conditionalStyles.messageSenderNameContainer} >
              <HStack alignItems={'center'} >
                <Text
                  size={'xsMedium'}
                  color={Colors.FoldPixel.GRAY300}
                  noOfLines={1}
                  {...testID('MessageSenderName')}
                >
                  {getMessageSenderName()}
                </Text>
              </HStack>

              <HStack style={styles.communicationTypeContainer}>
                {stateData.loading ? (
                  <Spinner {...testID(TestIdentifiers.lazyLoading)} />
                ) : (
                  <MessageCommunicationTypes
                    types={item.communicationTypes || []}
                    isMessageBox
                    messageUuid={item.uuid}
                    onRemoveCommunicationType={onRemoveCommunicationType}
                  />
                )}
                {(props?.conversationMessage?.isScheduleMessage ||
                  isScheduleMessage) && (
                    <View ml={2} height={'20px'} width={'20px'} {...testID('image')}>
                    <ScheduleMessageSvg
                      customStrokeColor={Colors.success[500]}
                      isEnabled={true}
                    />
                  </View>
                )}
                <View style={styles.messagePopoverContainer}>
                  {isWeb() && !props?.isReadOnlyView ? (
                    <>
                      <Popover
                        overlayInnerStyle={cssProperties.overlayInnerStyle}
                        content={
                          <>
                            {messageAction?.actionModel?.actionList?.map(
                              (action: MessageActionID) => {
                                if (
                                  conversationMessage?.contentType ===
                                    MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD ||
                                  conversationMessage?.contentType ===
                                    MESSAGE_CUSTOM_CARD_KEY.CUSTOM_CARD
                                ) {
                                  if (
                                    action !== MessageActionID.edit &&
                                    action !== MessageActionID.createTask
                                  ) {
                                    return getActionItemView(action);
                                  }
                                } else if (isSenderLoggedInUser) {
                                  if (
                                    action !== MessageActionID.markAsReadUnread
                                  ) {
                                    return getActionItemView(action);
                                  }
                                } else {
                                  return getActionItemView(action);
                                }
                              }
                            )}
                          </>
                        }
                        title=""
                        trigger="click"
                        placement={'bottomRight'}
                        visible={showDrawer.visible}
                        onVisibleChange={handleMorePopover}
                      >
                        {((props?.uuid &&
                          props.uuid?.length > 0) || status === CHAT_DELIVERY_STATUS.API_FAILED) &&
                        !stateData.isMarkLoading ? (
                          <View {...testID('image')}>
                            <MenuHorizontalIcon height={18} width={18}/>
                          </View>
                        ) : (
                          <Spinner {...testID(TestIdentifiers.lazyLoading)}/>
                        )}
                      </Popover>
                    </>
                  ) : null}
                </View>
              </HStack>
            </View>

            <View style={[styles.rceMboxClearPadding]}>
              <View style={styles.rceMboxBody}>
                <View style={[positionCls, {minHeight: 40, marginRight: 1}]}>
                  {(parentData?.uuid || props?.parentMessageUuid) ?  (
                    <div
                      className='pressable flex-1'
                      onClick={onReplyMessageClick}
                    >
                      <ReplyMessageView
                        isInMessageList={true}
                        selectedReply={parentData}
                        isDeletedMessage={!parentData?.uuid || (!parentData?.attachments?.length && !parentData?.text) }
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {messageContentElem}
                  {attachments &&
                  attachments.length &&
                  !isEmailChannelInboxType(inboxTypeCode) ? (
                    <View
                      style={styles.messageAttachmentContainer}
                      {...testID(TestIdentifiers.messageContent)}
                    >
                      <View flex={1}>
                        <MsgAttachment
                          isNestedMessageEle={true}
                          attachments={attachments}
                          position={position}
                          dateStr={dateStr}
                          messageObjectCallBack={messageObjectCallBack}
                          senderFullName={getMessageSenderName()}
                          messageAction={messageAction}
                          getActionItemView={(action: any, attachment: any) =>
                            getActionItemView(action, attachment)
                          }
                          attachmentActionVisible={
                            showDrawer?.attachmentActionVisible
                          }
                          handleAttachmentPopover={handleAttachmentPopover}
                          msgWithAttachment={text ? true : false}
                          communicationTypes={
                            item.communicationTypes || []
                          }
                          loading={stateData.loading}
                          private={props?.private}
                        />
                      </View>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
                {showConversationTaskCount && taskCount && taskCount > 0 && (
                  <MessageTaskCountView
                    taskCount={taskCount}
                    onActionPerformed={onMessageTaskCountActionPerformed}
                  />
                )}
              </View>
            </View>

            <HStack style={styles.editMessageLabelContainer}>
              {!isEmailChannelInboxType(inboxTypeCode) &&
              !isSMSChannelInboxType(inboxTypeCode) &&
              item?.messageData?.createdAt != item?.messageData?.updatedAt ? (
                <View style={styles.editMesssageLabelSubContainer}>
                  <Text
                    {...testID('EditedAgo')}
                    size={'xsMedium'}
                    color={Colors.Custom.messageTimeColor}
                    fontSize={12}
                    fontWeight={400}
                  >
                    {`${
                      isEFaxInbox ? 'Patient Context Edited On ' : 'Edited '
                    }`}
                    {moment(
                      item?.updatedDateStr ?? item?.messageData?.updatedAt
                    ).format(DATE_FORMATS.DISPLAY_TIME_FORMAT)}
                  </Text>
                </View>
              ) : (
                <Text
                  {...testID('DateString')}
                  size={'xsRegular'}
                  color={Colors.Custom.messageTimeColor}
                  fontSize={12}
                  fontWeight={400}
                >
                  {moment(
                    dateStr,
                    DATE_FORMATS.DISPLAY_TIME_FORMAT,
                    true
                  ).isValid()
                    ? dateStr
                    : moment(dateStr).format(DATE_FORMATS.DISPLAY_TIME_FORMAT)}
                </Text>
              )}
              {isSMSNotSent(status) && isSMSChannelInboxType(inboxTypeCode) && (
                <>
                  {stateData.resendLoading ? (
                    <Spinner {...testID(TestIdentifiers.lazyLoading)} />
                  ) : (
                    <>
                      {item?.errorData?.customMessage ||
                      item?.errorData?.message ? (
                        <HStack style={styles.resendBtnContainerStyle}>
                          <Tooltip
                            title={
                              item?.errorData?.customMessage ||
                              item?.errorData?.message
                            }
                            placement={'top'}
                          >
                            <HStack
                              alignContent={'center'}
                              alignItems={'center'}
                            >
                              <View {...testID('image')}>
                                <StatusErrorSvg />
                              </View>
                              <View>
                                <Text
                                  style={styles.failedMessageText}
                                  {...testID('Failed')}
                                >
                                  Failed
                                </Text>
                              </View>
                            </HStack>
                          </Tooltip>
                        </HStack>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
              {status === CHAT_DELIVERY_STATUS.API_FAILED && (
                <HStack style={styles.resendBtnContainerStyle}>
                  <StatusErrorSvg />
                  <Text
                    style={styles.failedMessageText}
                    {...testID('Failed')}
                  >
                    Failed
                  </Text>
                </HStack>
              )}

              {position === 'right' && (
                <>
                  {isSenderLoggedInUser && isGroupConversation(conversation) ? (
                    <HStack
                      style={styles.messageReadStatusContainer}
                      alignSelf={'flex-end'}
                      {...testID(isSeen ? 'MessageRead' : 'MessageUnread')}
                    >
                      {isSeen && !props.groupMemberDataLoading ? (
                        <ReadMsgSvg />
                      ) : (
                        <SendMesgSvg />
                      )}
                    </HStack>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </HStack>
          </View>

          {isRightAvatarShow && position == 'right' ? (
            <View style={conditionalStyles.displayCardAvatarContainer4}>
              <DisplayCardAvatar
                avatarStyle={MESSAGE_BOX_AVATAR_STYLE}
                isLetterAvatarShow={true}
                userData={displayCardAvatarUserData}
                hideOnlineIndicator
              />
            </View>
          ) : (
            <></>
          )}
          {isCheckBoxVisible() ? (
            <View
              style={[
                styles.copyCheckBoxStyle,
                {
                  alignSelf: 'center',
                },
              ]}
              {...testID(TestIdentifiers.checkbox)}
            >
              <Checkbox className="copy-checkbox" onChange={onCheckMessage} />
            </View>
          ) : (
            <></>
          )}
        </View>
      );
    }
    return msgElem;
  }, [
    text,
    props.isCopyModeStateOnCode,
    conversationMessage?.contentType,
    showDrawer.visible,
    status,
    inboxTypeCode,
    item?.errorData?.customMessage,
    item?.errorData?.message,
    attachments,
    stateData?.isMarkLoading,
    conversationMessage?.contentAttributes,
    isSeen,
    showDrawer?.attachmentActionVisible
  ]);



 

  const getReadMessageStatus = () => {
    return position === 'right' ? (
      <>
        {isSenderLoggedInUser && isGroupConversation(conversation) ? (
          <HStack
            style={styles.messageReadStatusContainer}
            {...testID(isSeen ? 'MessageRead' : 'MessageUnread')}
          >
            {isSeen && !props.groupMemberDataLoading ? (
              <ReadMsgSvg />
            ) : (
              <SendMesgSvg />
            )}
          </HStack>
        ) : (
          <></>
        )}
      </>
    ) : (
      <></>
    );
  };




  const getMsgElem = useMemo((): JSX.Element => {
    let msgTextElem = <></>;
    let msgAttachmentElem = <></>;
    if (text || conversation?.conversationInbox?.channelType === CHANNEL_TYPE.CHANNEL_EFAX ) {
      msgTextElem = msgElemFromMsgType;
    }
    if (attachments && attachments.length) {
      msgAttachmentElem = (
        <View style={conditionalStyles.messageAttachmentContainer}>
          {position == 'left' ? (
            <View style={styles.displayCardAvatarContainer}>
              <DisplayCardAvatar
                avatarStyle={MESSAGE_BOX_AVATAR_STYLE}
                isLetterAvatarShow={true}
                userData={displayCardAvatarUserData}
                hideOnlineIndicator
              />
            </View>
          ) : (
            <></>
          )}
          <View flex={1} flexDirection={'column'} {...testID(TestIdentifiers.messageContent)}>
            <MsgAttachment
              attachments={attachments}
              position={position}
              dateStr={dateStr}
              messageObjectCallBack={messageObjectCallBack}
              senderFullName={getMessageSenderName()}
              messageAction={messageAction}
              getActionItemView={getActionItemView}
              attachmentActionVisible={showDrawer?.attachmentActionVisible}
              handleAttachmentPopover={handleAttachmentPopover}
              communicationTypes={item.communicationTypes || []}
              loading={stateData.loading}
              private={props?.private}
              readMessageStatusView={getReadMessageStatus()}
            />
          </View>
          {isRightAvatarShow && position == 'right' ? (
            <View style={styles.displayCardAvatarContainer2}>
              <DisplayCardAvatar
                avatarStyle={MESSAGE_BOX_AVATAR_STYLE}
                isLetterAvatarShow={true}
                userData={displayCardAvatarUserData}
                hideOnlineIndicator
              />
            </View>
          ) : (
            <></>
          )}
        </View>
      );
    }
    if (messageType === 2) {
      return (
        <>
          {msgTextElem}
          {!text ? msgAttachmentElem : null}
        </>
      );
    } else if (isDisplayCallMsgHeader) {
      return (
        <>
          {msgTextElem}
          {!text ? msgAttachmentElem : null}
        </>
      );
    } else {
      return (
        <VStack>
          <HStack>{msgTextElem}</HStack>
          <HStack>{!text ? msgAttachmentElem : null}</HStack>
          {showDrawer.showAddNoteModal && id === showDrawer.messageData.id && (
            <AddNoteView
              selectedData={{content: text}}
              contactId={contactData?.id}
              onFormActionPerformed={onFormActionPerformed}
            />
          )}
        </VStack>
      );
    }
  }, [
    text,
    props.isCopyModeStateOnCode,
    conversationMessage?.contentType,
    showDrawer.visible,
    attachments,
    item?.errorData?.customMessage,
    item?.errorData?.message,
    stateData?.isMarkLoading,
    conversationMessage?.contentAttributes,
    isSeen,
    showDrawer?.attachmentActionVisible
  ]);

 

  return (
    <View nativeID={props.uuid}>
      {isDisplaySectionMsgHeader && (
        <SectionMsgHeader dateStr={dateStr} msgString={''} />
      )}
      {getMsgElem}
      {showDrawer.addClinicalNotesOpen && (
        <CreateNoteFromMessage
          conversationUUID={conversation?.uuid || ''}
          messageIds={[conversationMessage?.uuid]}
          isVisible={showDrawer.addClinicalNotesOpen}
          copiedMessages={showDrawer.addClinicalNotesOpenText}
          onClose={onCreateNoteDrawerClose}
          contactInfo={contactInfo}
        />
      )}
    </View>
  );
};

export default React.memo(MessageBox);
