import {
  Box,
  Divider,
  HStack,
  Icon,
  Spinner,
  Text,
  useToast,
  View,
  VStack
} from 'native-base';
import { useCallback, useContext, useMemo, useState } from 'react';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { Colors } from '../../styles';
import {getDateStrFromFormat} from '../../utils/DateUtils';
import AttachmentButton from '../common/CareDashboard/CareDashboardKanBan/AttachmentButton/AttachmentButton';
import Card from './Card.native';
import {IAttachment} from './CardInterfaces';
import {EntityType} from './TaskEnum';
import { ITask } from '../common/CareDashboard/CareDashboardInterfaces';
import TaskQueries from '../../services/Task/TaskQueries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../constants/Configs';
import { showToast, ToastType } from '../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import { BottomViewAction, getPriorityIcon, getSubTaskLength, getTaskTitle, isTaskWithType, renderResourceMapCountAndDeleteField } from './TaskCardHelper';
import { getCurrentUserRole, getUserUUID } from '../../utils/commonUtils';
import { IUserRoleCode } from '../../Interfaces';
import { MLOV_CATEGORY } from '../../constants/MlovConst';
import { BUTTON_TYPE, DATE_FORMATS } from '../../constants/StringConst';
import { getAssignedByType } from '../common/CareDashboard/CareDashboardTable/CareDashboardTableHelper';
import SubtasksSvg from '../common/Svg/SubtasksSvg';
import { LabelDataListView } from '../common/CareDashboard/CareDashboardWidget/LabelDataListView';
import { getCompletedTaskStatusId } from '../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { getMlovListFromCategory } from '../../utils/mlovUtils';
import { CommonDataContext } from '../../context/CommonDataContext';
import { Modal, notification, Tooltip, Checkbox} from 'antd';
import { isWeb } from '../../utils/platformCheckUtils';
import { FoldButton } from '../CommonComponents/FoldButton/FoldButton';
import { EventBus } from '../../utils/EventBus';
import { EVENT_NAMES } from '../../constants';
import { getDocumentRefByReferenceId } from '../../services/CommonService/OrderService';
import { FHIR_RESOURCE } from '../../constants/FhirConstant';
import { getResourceAbilities } from '../../utils/capabilityUtils';
import { DocStatus, NoteEntry } from '../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { SOURCE_MAP } from '../common/AddTask/AddTaskUtils';
import { StyleSheet } from 'react-native';
import React from 'react';

const styles = StyleSheet.create({
  taskTitle: {
    fontSize: 16,
  },
  dueDate: {
    fontSize: 13,
  },
  assignText: {
    fontSize: 14,
    color: Colors.FoldPixel.GRAY400
  },
  assigneeNamePatientTask: {
    fontSize: 14,
    color: Colors.Custom.Gray500,
  },
});

const TaskCard = (props: {
  task: ITask,
  title: string;
  disable: boolean;
  isChecked: boolean;
  attachments: IAttachment[];
  tag: EntityType;
  description?: JSX.Element;
  bottomView?: JSX.Element;
  hideCheckBox?: boolean;
  loading?: boolean;
  assigneeName?: string;
  data: any;
  hideDetails?: boolean;
  isPatientTask?: boolean;
  onPress?: () => void;
  onAssigneeClick?: () => void;
  onCheckBoxChange: (isSelected: boolean) => void;
  onAttachmentClick: (attachment: IAttachment) => void;
  onCheckBoxSelect?: (isSelected: boolean) => void;
  onDeleteTaskHandler?: (
    task: ITask,
    action: BottomViewAction,
    data: any
  ) => void;
  hideDeleteAction?: boolean,
  hideAssigneeNavigation?: boolean;
  handleDeleteOnCallback?: boolean;
  showDeleteField?: boolean;
  accountLocationUuid?: string;
}) => {
  const { task, accountLocationUuid} = props;
  const intl = useIntl();
  const toast = useToast();
  const {onCheckBoxChange, onAttachmentClick} = props;
  const mlovData = useContext(CommonDataContext);
  const [showDeleteConfirmation, setDeleteConfirmation] = useState(false);
  const [showLoaderOnCheckbox, setShowLoaderOnCheckbox] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateTask] = useMutation(TaskQueries.UPDATE_TASK);
  const loginUserId = getUserUUID();
  const currentUserRoles: IUserRoleCode[] = getCurrentUserRole();
  const assignedBy = getAssignedByType(task, intl);
  const taskStatusMlov =
  getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS
  ) || [];
  const [ getSubTaskByIds ] = useLazyQuery(TaskQueries.GET_SUB_TASK_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const patientLocationId =
    accountLocationUuid || task.taskLocations?.[0]?.locationId;
  // ehrConfig and visitNote configuration use for get visit note data
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    patientLocationId
  );
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;

  const onDeleteTaskConfirmation = () => {
    setDeleteLoading(true);
    updateTask({
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: {
        params: {
          id: props?.data?.id,
          data:{
            isDeleted:true
          }
        },
      },
      onCompleted: (data: any) => {
        showToast(
          toast,
          props?.data?.parentId ? intl.formatMessage({
            id: 'subtaskDeletedSuccess',
          }) : intl.formatMessage({
            id: 'taskDeletedMsg',
          }),
          ToastType.success,
          2000
        );
        setDeleteLoading(false);
        setDeleteConfirmation(false);
        if(isWeb()){
          const eventBus = EventBus.getEventBusInstance();
          eventBus.broadcastEvent(EVENT_NAMES.REFRESH_TASK,{});
        }
        if (props?.onDeleteTaskHandler && typeof props?.onDeleteTaskHandler == 'function') {
          props?.onDeleteTaskHandler(props?.data, BottomViewAction.deleteTask, undefined);
        }
        setDeleteConfirmation(false);
      },
      onError: () => {
        setDeleteLoading(false);
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error,
          1000
        );
        setDeleteConfirmation(false);
      },
    });
  };

  const isFormTask = isTaskWithType(props?.task || {} as ITask, EntityType.FORM);
  const subTaskLength = getSubTaskLength(task);

  const [getLinkNoteWithTask] = useLazyQuery(TaskQueries.GET_TASK_LINK_NOTE, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const getPatientNoteViewByResourceData = async (resourceId: any) => {
    const response = await getDocumentRefByReferenceId(`${resourceId}`, foldVisitNoteWithEncountersEnabled, props?.accountLocationUuid || task.taskLocations?.[0]?.locationId);
    const noteEntry: NoteEntry = { resource: response?.data } as NoteEntry;
    return noteEntry?.resource || {};
  }

  const isNoteStatusNotSigned = async (editTask: ITask): Promise<boolean> => {
    let noteStatusNotSigned = false;
    const isTaskLinkWithNote = editTask?.resourceMap && editTask.resourceMap?.notes || false;
    if (isTaskLinkWithNote) {
      const taskNoteResourceMapParam = { resourceId: editTask?.id, sourceTypeCode: SOURCE_MAP.NOTES };
      const resourceMapResponse = await getLinkNoteWithTask({ variables: taskNoteResourceMapParam });
      const resourceMappings = resourceMapResponse?.data?.resourceMappings || [];
      const noteId = resourceMappings?.length ? resourceMappings?.[0]?.sourceId : undefined;
      if (noteId) {
        try {
          const notesResponse  = await getPatientNoteViewByResourceData(noteId);
          noteStatusNotSigned = notesResponse && notesResponse?.docStatus === DocStatus.PRELIMINARY;
        } catch(error) {

          return false;
        }
      }
    }
    return noteStatusNotSigned;
  }

  const checkIfAllSubTasksAreCompleted = async (task: ITask,value: boolean) => {
    if (value) {
      const noteStatusNotSigned = await isNoteStatusNotSigned(task);
      if (noteStatusNotSigned) {
        notification.destroy();
        setShowLoaderOnCheckbox(false);
        notification.info({
          message: intl.formatMessage({
            id: 'completeTaskLinkNoteMessage',
          }),
          duration: 3.0,
          placement: 'top'
        });
        return;
      }
    }
    if (!task.subTasks || task.subTasks?.length === 0){
      onCheckBoxChange(value);
      return;
    }
    const response = await getSubTaskByIds({
      variables: {
        ids: task.subTasks?.map(task => task?.id),
      },
    });
    if (response?.data?.getTasks?.tasks?.length) {
      if (
        response?.data?.getTasks?.tasks?.every(
          (subTask: any) => subTask.statusId === completedStatusId
        )
      ) {
        onCheckBoxChange(value);
        return;
      } else {
        notification.destroy();
        setShowLoaderOnCheckbox(false)
        notification.info({
          message: intl.formatMessage({
            id: 'subTaskPendingMsg',
          }),
          duration: 3.0,
          placement: 'top'
        });
        return;
      }
    } else {
      notification.destroy();
      setShowLoaderOnCheckbox(false)
      notification.info({
        message: intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        duration: 3.0,
        placement: 'top'
      });
      return;
    }
  };

  const deleteModalButtons = [
    {
      textLocalId: 'cancel',
      buttonProps: {
        variant: BUTTON_TYPE.SECONDARY,
      },
      onPress: () => {
        setDeleteConfirmation(false);
      },
    },
    {
      textLocalId: 'delete',
      buttonProps: {
        variant: BUTTON_TYPE.PRIMARY,
        loading: deleteLoading,
      },
      loading: deleteLoading,
      onPress: async () => {
        onDeleteTaskConfirmation();
      },
    },
  ];

  const handleTaskDelete = async () => {
    if (props?.handleDeleteOnCallback) {
      if ((props?.onDeleteTaskHandler && typeof props?.onDeleteTaskHandler == 'function')) {
        props?.onDeleteTaskHandler(props?.data, BottomViewAction.deleteTask, undefined);
      }
      return;
    }
    if (task?.subTasks && task?.subTasks?.length > 0) {
      setDeleteLoading(true);
      const response = await getSubTaskByIds({
        variables: {
          ids: task.subTasks?.map((task) => task?.id),
        },
      });
      if (response?.data?.getTasks?.tasks?.length > 0) {
        if (isWeb()) {
          notification.info({
            message: intl.formatMessage({
              id: 'TaskNotDeletedMsgForTaskHasSubtask',
            }),
            duration: 3.0,
            placement: 'top'
          });
          setDeleteLoading(false);
          return;
        } else {
          showToast(
            toast,
            intl.formatMessage({id: 'TaskNotDeletedMsgForTaskHasSubtask'}),
            ToastType.info,
            1000
          );
          setDeleteLoading(false);
          return;
        }
      }
    } else {
      setDeleteConfirmation(true);
    }
  }

  const onCheckBoxChangeEvent = useCallback( async (event) => {
    const value = event.target.checked;
    if (props.onCheckBoxSelect) {
      props.onCheckBoxSelect(value);
      return
    }
    if (isFormTask && task?.status?.code === 'completed') {
      const message ='Form already filled can not change status of completed task'
      notification.destroy();
      notification.info({
        message: message,
        duration: 3.0,
        placement: 'top'
      });
      return;
    }
    if (!props.disable) {
      setShowLoaderOnCheckbox(true)
      await checkIfAllSubTasksAreCompleted(task, value);
    }
  }, [task, props.disable, props.onCheckBoxSelect, isFormTask, task]);

  const checkboxElement = useMemo(() => {
    if (props.loading || props.hideCheckBox) return null;
    return showLoaderOnCheckbox ? (
      <Spinner />
    ) : (
      <div onClick={(event) => event.stopPropagation()}>
        <Checkbox
          checked={props.isChecked}
          onChange={onCheckBoxChangeEvent}
        />
      </div>
    );
  }, [task, onCheckBoxChangeEvent, props.loading, props.hideCheckBox, props.isChecked]);

  const memoizedResourceMapCountAndDeleteField = useMemo(() => {
    return renderResourceMapCountAndDeleteField({
      task: props?.data || task,
      loginUserId: loginUserId,
      currentUserRoles: currentUserRoles || [],
      deleteLoading: deleteLoading,
      showDeleteField: props?.showDeleteField !== undefined ? props?.showDeleteField : true,
      handleTaskDelete,
      intl
    });
  }, [props?.data, task, loginUserId, currentUserRoles, deleteLoading, props?.showDeleteField, handleTaskDelete, intl]);

  const onMemberClick = useCallback((event) => {
    event.stopPropagation();
    props?.onAssigneeClick?.();
  }, [props?.onAssigneeClick]);

  return (
    <div 
      onClick={props?.onPress}
      style={taskCardWebStyles.card}>
      <div key={`Box_${task.id}`}>
        <div key={`HStack_${task.id}`} style={taskCardWebStyles.flexRow}>
          <div key={`VStack_${task.id}`} style={taskCardWebStyles.header}>
            <View flexDirection="row" wordBreak={'break-word'}>
              {task?.parentId && (
                <View size={18} marginRight={1}>
                  <SubtasksSvg customStrokeColor={Colors.Custom.PrimaryColor} />
                </View>
              )}
            <Text
              size={'smMedium'}
              color={Colors.FoldPixel.GRAY400}
            >
              { getTaskTitle(task) }
            </Text>
            </View>
            {subTaskLength > 0 && (
              <View flexDirection="row" mt={1}>
                <View size={18}>
                  <SubtasksSvg customStrokeColor={Colors.Custom.PrimaryColor} />
                </View>
                <Text
                  ml={1}
                  size={'smRegular'}
                  color={Colors.Custom.PrimaryColor}
                >
                  {`${subTaskLength} ${
                    subTaskLength > 1 ? 'Subtasks' : 'Subtask'
                  } `}
                </Text>
              </View>
            )}
            {task?.labels?.length ? (
              <LabelDataListView
                key={'Labels_' + task.id}
                hideAddMoreButton={true}
                tagList={task?.labels || []}
              />
            ) : null}
            {!!props?.data.endDateTime && (
              <Text
                size={'xsNormal'}
                fontWeight={400}
                color={Colors.FoldPixel.GRAY300}
              >
                {`Due Date: ${getDateStrFromFormat(
                  props?.data?.endDateTime || new Date(),
                  DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                ) || ''}`}
              </Text>
            )}
          </div>
          <div style={taskCardWebStyles.flexGrow} />
          <HStack justifyContent={'flex-end'} height={'22px'}>
            {props.loading && !props.hideCheckBox && <Spinner />}
            {!props.loading && (
              <View mx={2} alignSelf="center">
                {getPriorityIcon(props?.task?.priority, undefined, 0)}
              </View>
            )}
            {checkboxElement}
          </HStack>
        </div>
      </div>

      {props?.hideDetails && props?.hideDetails === true ? (
        null
      ) : (
        <View>
          <Box paddingY={2}>
            {(props.assigneeName || !props?.hideDeleteAction) && (
              <Divider marginY={2} />
            )}
            <VStack>
              {props.assigneeName && (
                <div className={props.hideAssigneeNavigation ? 'disabled': 'pressable'} onClick={onMemberClick}>
                  <HStack space={2} alignItems="center" flex={1} marginY={2}>
                      <Text
                      size={'smRegular'}
                      isTruncated
                      style={props?.isPatientTask ? styles.assigneeNamePatientTask : styles.assignText}>
                      {props?.isPatientTask ? `${intl.formatMessage({
                    id: 'assignedTo',
                  })}: ${props.assigneeName}` : props.assigneeName}
                    </Text>
                    {!props.hideAssigneeNavigation &&
                    <Icon
                      as={FeatherIcon}
                      name="external-link"
                      size="xs"
                      color={Colors.Custom.Gray500}
                    />
                    }
                  </HStack>
                </div>
              )}
              {!props?.isPatientTask && props?.task?.assigneeUser?.name && (
                <HStack maxW={'100%'}>
                  <Tooltip placement='top' title={
                    props?.task?.assigneeUser?.name
                  }>
                    <Text
                      isTruncated
                      size={'smRegular'}
                      color={Colors.FoldPixel.GRAY400}
                    >{`${intl.formatMessage({
                      id: 'assignedTo',
                    })}: ${props?.task?.assigneeUser?.name}`}</Text>
                    </Tooltip>
                </HStack>
              )}
              {!!assignedBy?.name && (
                <Text
                  size={'smRegular'}
                  style={styles.assignText}
                  color={Colors.FoldPixel.GRAY300}
                >{`Assigned by: ${assignedBy?.name}`}</Text>
              )}
            </VStack>
            {props.bottomView}
            {memoizedResourceMapCountAndDeleteField}
          </Box>
        </View>
      )}
      {isWeb() && showDeleteConfirmation && (
        <Modal
          open={showDeleteConfirmation}
          footer={null}
          closable={false}
        >
          <VStack>
            <Text
              mb={1}
              fontSize={'xl'}
              fontWeight={'bold'}
            >{`Delete Task?`}</Text>
            <Text my={1} fontSize={'md'}>
              {
                props?.data?.resourceMap?.notes ? intl.formatMessage({ id: 'deleteNoteLinkTaskConfirmation' }) : intl.formatMessage({ id: 'deleteTaskConfirmation' })
              }
            </Text>
            <HStack mt={5} justifyContent={'flex-end'}>
              {deleteModalButtons.map((button: any, index: number) => {
                return (
                  <FoldButton
                    key={index + button.btnText + task.id}
                    nativeProps={{
                      variant: button?.buttonProps?.variant,
                      onPress: button?.onPress,
                      ml: 2,
                      isLoading: button?.loading,
                      isDisabled: button?.loading
                    }}
                    customProps={{
                      btnText: intl.formatMessage({ id: button.textLocalId }),
                      withRightBorder: false,
                    }}
                  />
                );
              })}
            </HStack>
          </VStack>
        </Modal>
      )}
    </div>
  );
};

const taskCardWebStyles: Record<string, React.CSSProperties> = {
  card: {
    backgroundColor: Colors.Custom.White,
    border: '1px solid',
    overflow: 'hidden',
    borderRadius: 20,
    padding: 16,
    paddingRight: 12,
    paddingLeft: 12,
    borderColor: Colors.Custom.CardBorderColor,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
  },
  flexGrow: {
    flexGrow: 1,
  },
};


export default React.memo(TaskCard);
