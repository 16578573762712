import { useContext } from 'react';
import { CommonDataContext } from '../../context/CommonDataContext';
import { CONFIG_CODES } from '../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../utils/configUtils';
import { isLoginUserBusinessOwner } from '../../utils/commonUtils';

export const useWorkflowListForTasks = (params: any) => {
  const commonContext = useContext(CommonDataContext);
  const isBusinessOwner = isLoginUserBusinessOwner();
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);

  const getAccountLocationUuids = () => {
    if (isMsoEnabled && !isBusinessOwner) {
      return commonContext?.userAccountLocationUuids;
    } else {
      const idList = commonContext?.loggedInUserLocationDetails?.accountLocationIds;
      return idList;
    }
  };

  return {
    getAccountLocationUuids,
  };
};
