import React, {useContext, useState} from 'react';
import {Popover} from 'antd';
import {Pressable, View} from 'react-native';
import {Text} from 'native-base';
import {Colors} from '../../styles';
import {SIDE_CAR_CREATE_OPTION} from './constants';
import {useIntl} from 'react-intl';
import Stack from '../common/LayoutComponents/Stack';
import CreateChannelView from '../common/CreateChannelView/CreateChannelView';
import {CONVERSATION_TAB_CODES} from '../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES,
  QUICK_ACTION_CODES,
} from '../../constants/ActionConst';
import {SUPPORTED_EVENT_CODE} from '../../constants/WebSocketConst';
import {EventBus} from '../../utils/EventBus';
import CreateSmsConversationDrawer from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {SMS_INBOX_ACTION_CODES} from '../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import EmailDrawerCommonV2 from '../common/EmailDrawerCommonV2/EmailDrawerCommonV2';
import CreateEFaxConversationDrawer from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/CreateEFaxConversationDrawer';
import {getFormDataFromLeadData} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import MeetingView from '../common/MeetingView/MeetingView';
import {CommonDataContext} from '../../context/CommonDataContext';
import {
  getUserData,
  getUserFullName,
  getUserId,
  getUserUUID,
} from '../../utils/commonUtils';
import {ParticipantType} from '../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import AddOrUpdateTask from '../common/AddTask/AddOrUpdateTask';
import SelectPatientCallDrawer from '../RightSideContainer/CloudTelephony/ActiveNumbersTable/SelectPatientCallDrawer';
import {IContact} from '../common/CreateChannelView/interface';
import {
  IConversationData,
  INewConversationResponse,
} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import InstantChatView from '../common/ChatDrawer/InstantChatView';
import {useLazyQuery} from '@apollo/client';
import ConversationsQueries from '../../services/Conversations/ConversationsQueries';
import SideCarCreateNewSvg from '../common/Svg/SideCarCreateNewSvg';
import { isAccountConfigEnabled } from '../../utils/configUtils';
import { CONFIG_CODES } from '../../constants/AccountConfigConst';
import {IExtensionData} from '../RightSideContainer/CallConfiguration/interface';
import { TaskPanelType } from '../TaskCard/TaskEnum';
import { SidecarPatientDataContext } from './SidecarTaskDrawer';

interface ISidecarCreateActionProps {
  patientUuid?: string;
  panelType?: TaskPanelType;
  contactInfo?: IContact | undefined;
}

interface IAssignee {
  value: string | undefined;
  label: string | undefined;
  key: string | undefined;
  type: ParticipantType;
  details: IContact | undefined; 
}

const SidecarCreateAction = (props: ISidecarCreateActionProps) => {
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const isPatientContext = !!props?.patientUuid;
  const sidecarPatientContextData = useContext(SidecarPatientDataContext); 
  const currentUserUUID = getUserUUID();
  const currentUserData = getUserData();
  const userId = getUserId();

  const [state, setState] = useState({
    showCreateOptions: false,
    selectedActionCode: '',
    formDataFromLeadData: {} as any,
    selectedConversationData: {} as IConversationData,
  });
  const isEFaxMessagingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION
  );

  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(
    CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR
  );

  const SIDE_CAR_GLOBAL_ACTION = showOnlyInternalChatInSidecar
    ? SIDE_CAR_CREATE_OPTION.filter(
        (item) => item?.code === QUICK_ACTION_CODES.ADD_NEW_TASK
      )
    : SIDE_CAR_CREATE_OPTION.filter(
        (item) =>
          item?.code !== QUICK_ACTION_CODES.CREATE_EFAX ||
          isEFaxMessagingEnabled
      );

  const [GetConversationByConversationId] =
    useLazyQuery<INewConversationResponse>(
      ConversationsQueries.GetConversationByConversationId,
      {
        fetchPolicy: 'no-cache',
      }
    );

  const handleVisibleChange = (visible: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        showCreateOptions: visible,
      };
    });
  };

  const handleOnCreateNewEmailOrEFaxConversation = async (
    conversationId: string | number
  ) => {
    if (!conversationId) {
      return;
    }
    try {
      const response = await GetConversationByConversationId({
        variables: {
          conversationId: Number(conversationId),
          loginUserIntId: userId,
          loginUserUuid: currentUserUUID,
        },
      });
      const conversations = response.data?.conversations || [];
      if (conversations.length > 0) {
        const selectedConversationData = conversations[0];
        setState((prev) => {
          return {
            ...prev,
            selectedActionCode: CONVERSATION_ACTION_CODES.OPEN_INSTANT_CHAT,
            selectedConversationData: selectedConversationData,
          };
        });
      }
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          selectedConversationData: {} as IConversationData,
        };
      });
    }
  };

  const onConversationActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
      case SMS_INBOX_ACTION_CODES.DRAWER_CLOSE: {
        setState((prev) => {
          return {
            ...prev,
            selectedActionCode: '',
          };
        });
        break;
      }

      case GROUP_ACTION_CODES.GROUP_CREATE:
        setState((prev) => {
          return {
            ...prev,
            showCreateOptions: false,
            selectedActionCode: CONVERSATION_ACTION_CODES.OPEN_INSTANT_CHAT,
            selectedConversationData: actionData || ({} as IConversationData),
          };
        });
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.NEW_GROUP_CREATED, {
          conversations: actionData,
        });
        break;

      case COMMON_ACTION_CODES.CLOSE_MODAL: {
        setState((prev) => {
          return {
            ...prev,
            selectedConversationData: {} as any,
            selectedActionCode: '',
          };
        });
        break;
      }

      case COMMON_ACTION_CODES.ITEM_CLICKED: {
        setState((prev) => {
          return {
            ...prev,
            selectedConversationData: actionData,
          };
        });
        break;
      }
      case CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT: {
        setState((prev) => {
          return {
            ...prev,
            selectedConversationData: actionData,
          };
        });
        break;
      }

      case CONVERSATION_ACTION_CODES.OPEN_INSTANT_CHAT: {
        handleOnCreateNewEmailOrEFaxConversation(actionData);
        break;
      }
    }
  };

  const getAssignee = (): IAssignee => {
    const isPatientPanel = props?.panelType === TaskPanelType.PATIENT;  
    return {
      value: isPatientPanel ? props?.patientUuid : currentUserUUID,
      label: isPatientPanel ? sidecarPatientContextData?.name : getUserFullName(),
      key: isPatientPanel ? props?.patientUuid : currentUserUUID,
      type: isPatientPanel ? ParticipantType.patient : ParticipantType.staff,
      details: isPatientPanel ? sidecarPatientContextData : currentUserData,
    };
  };

  const getDefaultMember = () => {
    return {
      value:  props?.patientUuid,
      label: props?.contactInfo?.name || '' ,
      key: props?.patientUuid ,
      type: ParticipantType.patient,
      details: props?.contactInfo,
    };
  }

  const handleCloudCall = (
    actionCode: string,
    contactData: IContact,
    fromNumber: string,
    extensionData: IExtensionData,
  ) => {
    if (actionCode === COMMON_ACTION_CODES.CLOUD_CALL) {
      const eventBus = EventBus.getEventBusInstance();
      eventBus.broadcastEvent('CLOUD_CALL', {
        contactData: contactData,
        fromNumber: fromNumber,
        extensionData: extensionData
      });
    }
  };

  const getAddNewtaskElementView = () => {
    return (
      <AddOrUpdateTask
        assignee={getAssignee()}
        isVisible={state.selectedActionCode === QUICK_ACTION_CODES.ADD_NEW_TASK}
        onComplete={(data: any) => {
          onConversationActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
        }}
        onCancel={() => {
          onConversationActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
        }}
        member={isPatientContext  ? getDefaultMember() : undefined}
      />
    );
  };

  const getVoiceCallElementView = () => {
    return (
      <SelectPatientCallDrawer
        isDrawerVisible={
          state.selectedActionCode === QUICK_ACTION_CODES.CREATE_VOICE_MEETING
        }
        selectedRecord={null}
        handleCloudCall={handleCloudCall}
        onCreateCallConversationActionPerformed={onConversationActionPerformed}
      />
    );
  };

  const getCreateChatElementView = () => {
    return (
      <CreateChannelView
        isDrawerVisible={
          state.selectedActionCode === QUICK_ACTION_CODES.CREATE_CHAT
        }
        onCreateChannelActionPerformed={onConversationActionPerformed}
        selectedTabCode={CONVERSATION_TAB_CODES.PRIVATE}
      />
    );
  };

  const getCreateSMSElementView = () => {
    return (
      <CreateSmsConversationDrawer
        isDrawerVisible={
          state.selectedActionCode === QUICK_ACTION_CODES.CREATE_SMS
        }
        selectedInbox={{} as any}
        onCreateSmsConversationActionPerformed={(
          actionCode: string,
          actionData: any
        ) => {
          if (
            actionData?.selectedInboxConversationData?.conversations?.[0]?.id
          ) {
            setState((prev) => {
              return {
                ...prev,
                selectedActionCode: CONVERSATION_ACTION_CODES.OPEN_INSTANT_CHAT,
                selectedConversationData:
                  actionData.selectedInboxConversationData.conversations[0],
              };
            });
          } else {
            onConversationActionPerformed(actionCode);
          }
        }}
      />
    );
  };

  const getComposeEmailElementView = () => {
    return (
      <EmailDrawerCommonV2
        isOpen={state.selectedActionCode === QUICK_ACTION_CODES.CREATE_EMAIL}
        onEmailSent={({msgData}) => {
          handleOnCreateNewEmailOrEFaxConversation(msgData?.conversationId);
        }}
        onClose={() => {
          onConversationActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
        }}
      />
    );
  };

  const getSendEFaxElementView = () => {
    return (
      <CreateEFaxConversationDrawer
        visible={true}
        onActionPerformed={onConversationActionPerformed}
      />
    );
  };

  const getVideoMeetingElementView = () => {
    return (
      <MeetingView
        personData={state.formDataFromLeadData}
        isFromGlobal={true}
        selectedContact={(data) => {
          const formattedContactData = getFormDataFromLeadData(
            data.patientData || {},
            mlovData
          );
          setState((prev) => {
            return {
              ...prev,
              formDataFromLeadData: formattedContactData,
            };
          });
        }}
        onPersonActionPerformed={(actionCode: any) => {
          if (actionCode == COMMON_ACTION_CODES.CANCEL) {
            setState((prev) => {
              return {
                ...prev,
                selectedActionCode: '',
                formDataFromLeadData: {},
              };
            });
          } else {
            setState((prev) => {
              return {
                ...prev,
                formDataFromLeadData: {},
              };
            });
          }
        }}
      />
    );
  };

  const getInstantChatView = () => {
    return (
      <InstantChatView
        selectedConversation={state?.selectedConversationData}
        contactData={{} as any}
        isLoading={false}
        isDrawerVisible={true}
        isInstantChatView={true}
        instantChatFromNotifications={true}
        onActionPerformed={onConversationActionPerformed}
      />
    );
  };

  const getActionElementView = () => {
    switch (state.selectedActionCode) {
      case QUICK_ACTION_CODES.ADD_NEW_TASK:
        return getAddNewtaskElementView();

      case QUICK_ACTION_CODES.CREATE_VOICE_MEETING:
        return getVoiceCallElementView();

      case QUICK_ACTION_CODES.CREATE_CHAT:
        return getCreateChatElementView();

      case QUICK_ACTION_CODES.CREATE_SMS:
        return getCreateSMSElementView();

      case QUICK_ACTION_CODES.CREATE_EMAIL:
        return getComposeEmailElementView();

      case QUICK_ACTION_CODES.CREATE_EFAX:
        return getSendEFaxElementView();

      case QUICK_ACTION_CODES.CREATE_VIDEO_MEETING:
        return getVideoMeetingElementView();

      case CONVERSATION_ACTION_CODES.OPEN_INSTANT_CHAT:
        return getInstantChatView();
    }
  };

  const getContentView = () => {
    return (
      <View>
        {SIDE_CAR_GLOBAL_ACTION?.map((item) => {
          return (
            <Pressable
              onPress={() => {
                setState((prev) => {
                  return {
                    ...prev,
                    showCreateOptions: false,
                    selectedActionCode: item.code,
                  };
                });
              }}
            >
              <Stack
                direction="row"
                style={{
                  flex: 1,
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <View style={{alignSelf: 'center'}}>{item.icon}</View>
                <Text
                  style={{
                    paddingHorizontal: 8,
                    paddingVertical: 8,
                    color: Colors.Custom.Gray500,
                  }}
                >
                  {intl.formatMessage({id: item.key})}
                </Text>
              </Stack>
            </Pressable>
          );
        })}
      </View>
    );
  };

  return (
    <View>
      <Popover
        overlayInnerStyle={{
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          borderRadius: 16,
          width: 'auto',
        }}
        overlayClassName="sidecarCreateOption"
        className="popoverStyle"
        placement={'bottomLeft'}
        trigger="click"
        visible={state.showCreateOptions}
        onVisibleChange={handleVisibleChange}
        content={getContentView}
      >
        <Pressable
          style={{
            padding: 8,
            backgroundColor: Colors.Custom.mainPrimaryPurple,
            borderRadius: 4,
            height: 32,
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onPress={() => {
            setState((prev) => {
              return {
                ...prev,
                showCreateOptions: !prev.showCreateOptions,
              };
            });
          }}
        >
          <Stack
            direction="row"
            style={{justifyContent: 'center', alignItems: 'center'}}
          >
            <SideCarCreateNewSvg />
            <Text
              style={{
                color: Colors.Custom.ConversationBgColor,
                paddingLeft: 4,
                fontWeight: '500',
                fontSize: 14,
              }}
            >
              {intl.formatMessage({id: 'new'})}
            </Text>
          </Stack>
        </Pressable>
      </Popover>
      {getActionElementView()}
    </View>
  );
};

export default SidecarCreateAction;
