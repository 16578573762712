import {isArray} from 'lodash';
import {IEhrCapability, IKeyOperation} from '../../../Interfaces';
import {getCapability} from '../../../utils/capabilityUtils';
import {isValidEmail} from '../../../utils/commonUtils';
import {getEnabledVitals} from '../../../utils/VitalUtils';
import {QuestionnaireObject} from '../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/interfaces';
import {isValidPhoneNumber} from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateQueryUtils';
import {
  CommunicationField,
  DischargeDetailsFields,
  EmergencyContactField,
  isRequiredField,
} from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomComponentUtils';
import {CapabilityResource} from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {IObservation} from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/interfaces';
import {forEachExtensiveFormComponent} from '../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {
  IExtensiveComponentData,
  IFormComponentWithReferenceData,
} from './interface';
import { getVisibleComponents } from '../../PersonOmniView/MiddleContainer/CareTimeline/NoteUtils';


export enum FormComponents {
  VITALS = 'vitals',
  CHIEF_COMPLAINT = 'chiefComplaint',
  SUBJECTIVE_COMPLAINT = 'subjectiveComplaint',
  OBJECTIVE_FINDINGS = 'objectiveFindings',
  ASSESSMENT = 'assessment',
  PLAN = 'plan',
  TEXTFIELD = 'textField',
  TEXTAREA = 'textArea',
  EMAIL = 'email',
  ALLERGIES = 'allergies',
  SURGICAL_HISTORY = 'surgicalHistory',
  DIAGNOSIS = 'diagnosis',
  FAMILY_HISTORY = 'Family History',
  CONDITIONS = 'conditions',
  MEDICATIONS = 'medications',
  IMMUNIZATION = 'immunizations',
  PATIENT_ADDRESS = 'patientAddress',
  PATIENT_COMMUNICATION = 'patientCommunication',
  PATIENT_DEMOGRAPHICS = 'patientDemographics',
  EMERGENCY_CONTACT = 'emergencyContact',
  PATIENT_CONSENT = 'consentAndPreferredCommunication',
  CHECKBOX = 'checkbox',
  CURRENCY = 'currency',
  DATE = 'date',
  NUMBER = 'number',
  RADIO = 'radio',
  SELECT = 'select',
  PHONE_NUMBER = 'phoneNumber',
  SELECT_BOXES = 'selectBoxes',
  SIGNATURE = 'signature',
  PANEL = 'panel',
  PARAGRAPH = 'paragraph',
  COLUMNS = 'columns',
  IMAGE = 'image',
  FILE = 'file',
  CUSTOM_SURVEY = 'customsurvey',
  SOCIAL_HISTORY = 'socialHistory',
  RATING = 'rating',
  PAST_MEDICAL_HISTORY = 'pastMedicalHistory',
  PATIENT_IDENTIFIER = 'patientIdentifier',
  BARRIERS = 'barriers',
  INTERVENTION = 'intervention',
  GOALS = 'goals',
  SYMPTOM_MONITORING = 'symptomMonitoring',
  MEDICATION_MANAGEMENT = 'medicationManagement',
  DIET = 'diet',
  EXERCISE = 'exercise',
  HABITS = 'habits',
  HOME_MONITORING = 'homeMonitoring',
  LAB_MONITORING = 'labMonitoring',
  HEALTH_MAINTENANCE = 'healthMaintenance',
  NOTE_SUB_STATUS = 'noteSubStatus',
  NOTE_STATUS = 'noteStatus',
  DISCHARGE_DETAILS = 'dischargeDetails',
  CARE_PROGRAM_APPOINTMENTS = 'careProgramAppointments',
  CLINICAL_PARAMETERS = 'clinicalParameters',
  PATIENT_INFORMATION_VERIFICATION = 'patientInformationVerification',
  CUSTOM_TABLE = 'customTable',
}

export enum PatientField {
  firstName = 'firstName',
  lastName = 'lastName',
  birthDate = 'birthDate',
  gender = 'gender',
  birthSex = 'birthSex',
}

const getEHRCapability = (
  capabilityList: string[],
  formOptionData: any
): IEhrCapability | undefined => {
  if (formOptionData?.ehrCapabilities?.length) {
    const capabilities: IEhrCapability[] = formOptionData.ehrCapabilities;
    const matchingCapability = capabilities.find(
      (item) => item.resourceName && capabilityList.includes(item.resourceName)
    );
    if (matchingCapability) {
      return matchingCapability;
    }
  }
};

const isValidCommunicationField = (fieldKey: string, fieldValue: string) => {
  switch (fieldKey) {
    case EmergencyContactField.email:
    case CommunicationField.email:
      return isValidEmail(fieldValue);
    case EmergencyContactField.phoneNumber:
    case CommunicationField.phoneNumber:
      return isValidPhoneNumber(fieldValue).isValid;
    default:
      return true;
  }
};

const calculateCommunicationScore = (res: any, fields: string[]) => {
  let count = 0;
  fields?.forEach((key: string) => {
    if (key === PatientField.gender) {
      isValidTextValue(res?.['genderCode']) && count++;
    } else {
      if (typeof res?.[key] === 'object') {
        if (
          res?.[key] !== undefined ||
          res[key]?.code !== null ||
          res?.[key]?.code !== ''
        ) {
          if (isValidCommunicationField(key, res[key]?.code)) count++;
        }
      } else if (typeof res?.[key] === 'string') {
        if (isValidTextValue(res[key])) {
          if (isValidCommunicationField(key, res[key])) count++;
        }
      }
    }
  });
  return count;
};

const calculateConsentScore = (res: any, fields: string[]) => {
  let count = 0;
  res?.['consentIds']?.length && count++;
  return count;
};

const calulateCustomSurveyComponentScore = (res: any, fields: string[]) => {
  if (Object.keys(res).length === 0) return 0;
  let count = 0;
  fields?.forEach((key: string) => {
    // Multi select
    if (isArray(res?.[key]) && res?.[key]?.length > 0) {
      count++;
    // Single select
    } else if (!isArray(res?.[key]) && res?.[key]) {
      count++;
    }
  });
  return count;
};

const calculatePAMIScore = (res: any, fields: string[]) => {
  let allMandatoryFieldsFilled = true;
  fields.forEach((key: string) => {
    if (typeof res?.[key] === 'object') {
      if (
        res?.[key]?.length === 0 ||
        (!res?.[key]?.code && !res?.[key]?.coding?.length)
      ) {
        allMandatoryFieldsFilled = false;
      }
    } else if (typeof res?.[key] === 'string') {
      if (res[key] === '' || res[key] === null) {
        allMandatoryFieldsFilled = false;
      }
    }
  });
  return allMandatoryFieldsFilled;
};

const calculationSocialHistoryScore = (
  res: any,
  referenceData: any
) => {
  // here in case like elation we directly calculate the number of free text fields
  if (!!referenceData?.freeText) {
    return res?.total > 0 ? 1 : 0;
  }
  let count = 0;
  res?.questionnaire?.forEach((answer: QuestionnaireObject) => {
    if (answer?.item?.length && referenceData) {
      const enabledQuestionnaire = answer.item.some(item => Array.isArray(referenceData) && item.linkId && referenceData.includes(item.linkId));
      if (enabledQuestionnaire) {
        count += answer?.item?.length;
      }
    }
  });
  return count;
};
const calculatePatientInformationScore = (res: any) => {
  const { firstName, lastName, dateOfBirth, zipCode } = res?.patientInformation || {};
  return firstName && lastName && dateOfBirth && zipCode ? 1 : 0;
};

const calculateCareProgramAppointmentsScore = (res: any, referenceData: any) => {
  if (referenceData?.appointmentId || res?.appointmentId) {
    return 1;
  }
  return 0;
}

const calculateDischargeDetailsScore = (res: any, referenceData: string[]) => {
  let count = 0;
  if (res?.dischargeDetails) {
    for (const key in res?.dischargeDetails) {
      if (key !== 'lengthofstay' && key !== 'lengthOfStayUnit') {
        if (Array.isArray(res?.dischargeDetails[key]?.value)) {
          if (res?.dischargeDetails[key]?.value.length > 0) {
            count++;
          }
        } else {
          if (res?.dischargeDetails[key]?.value) {
            count++;
          }
        }
      }
    }
  }
  return count;
}
const isValidRatingValue = (value: string | number | undefined) => value !== undefined && value !== '' && value !== 0;
const isValidTextValue = (value: string) => value !== undefined && value !== '';
const checkPrefillDataForPAMI = (
  userFilled: any[],
  totalCount: number,
  referenceData: string[]
) => {
  // this is for the case when user has not filled any data but prefill data is available so we dont check for the prefill data and return the total count of feilds that are to be filled or mandatory
  if (userFilled.length === 0 && totalCount > 0) {
    return referenceData?.length ? 1 : 0;
  }
  return userFilled.length > 0 &&
    calculatePAMIScore(userFilled[0], referenceData)
    ? 1
    : 0;
};
export const getComponentKey = (key: string): FormComponents =>{
  const splittedKey = key?.split('_')?.[0];
  return splittedKey?.replace(/\d+$/, '') as FormComponents;
}
  

const formComponentValueMap: {
  [key in FormComponents]: (
    res: any,
    referenceData?: any | any[] | string[]
  ) => number;
} = {
  //HEALTH COMPONENTS
  [FormComponents.VITALS]: (res: any, referenceData?: any | any[]) => {
    let count = 0;
    referenceData?.forEach((key: string) => {
      //find the key in the form response array
      const found: IObservation = res?.observations?.find(
        (item: IObservation) => item?.loinc === key
      );
      if (found && found?.valueQuantity?.value !== '') {
        count++;
      }
    });
    return count;
  },
  [FormComponents.CHIEF_COMPLAINT]: (res: any) =>
    Object.keys(res).length !== 0 && res?.chiefComplaint?.displayName !== ''
      ? 1
      : 0,
  [FormComponents.SUBJECTIVE_COMPLAINT]: (res: any) =>
    isValidTextValue(res) ? 1 : 0,
  [FormComponents.OBJECTIVE_FINDINGS]: (res: any) =>
    isValidTextValue(res) ? 1 : 0,
  [FormComponents.IMMUNIZATION]: (res: any, referenceData) => {
    return checkPrefillDataForPAMI(
      res.immunizations,
      res?.total,
      referenceData
    );
  },
  [FormComponents.ALLERGIES]: (res: any, referenceData: string[]) => {
    return checkPrefillDataForPAMI(res.allergies, res?.total, referenceData);
  },
  [FormComponents.SURGICAL_HISTORY]: (res: any, referenceData: string[]) => {
    return checkPrefillDataForPAMI(
      res.surgicalHistory,
      res?.total,
      referenceData
    );
  },
  [FormComponents.ASSESSMENT]: (res: any) => (isValidTextValue(res) ? 1 : 0),
  [FormComponents.PLAN]: (res: any) => (isValidTextValue(res) ? 1 : 0),
  [FormComponents.DIAGNOSIS]: (res: any, referenceData: string[]) => {
    return checkPrefillDataForPAMI(res.conditions, res?.total, referenceData);
  },
  [FormComponents.CONDITIONS]: (res: any, referenceData: string[]) => {
    return checkPrefillDataForPAMI(res.conditions, res?.total, referenceData);
  },
  [FormComponents.MEDICATIONS]: (res: any, referenceData: string[]) => {
    return checkPrefillDataForPAMI(
      res.medicationStatements,
      res?.total,
      referenceData
    );
  },
  [FormComponents.PATIENT_DEMOGRAPHICS]: (
    res: any,
    referenceData?: any | any[]
  ) => calculateCommunicationScore(res, referenceData),
  [FormComponents.PATIENT_CONSENT]: (
    res: any,
    referenceData?: any | any[]
  ) => calculateConsentScore(res, referenceData),
  [FormComponents.PATIENT_ADDRESS]: (res: any, referenceData?: any) =>
    calculateCommunicationScore(res, referenceData),
  [FormComponents.PATIENT_COMMUNICATION]: (res: any, referenceData?: any) =>
    calculateCommunicationScore(res, referenceData),
  [FormComponents.EMERGENCY_CONTACT]: (res: any, referenceData?: any) =>
    calculateCommunicationScore(res, referenceData),
  [FormComponents.FAMILY_HISTORY]: (res: any) => {
    return checkPrefillDataForPAMI(res.history, res?.total, ['condition']);
  },
  [FormComponents.SOCIAL_HISTORY]: (
    res: any,
    referenceData: {
      value: string;
      isFreetext: boolean;
    }[]
  ) => calculationSocialHistoryScore(res, referenceData),
  [FormComponents.PAST_MEDICAL_HISTORY]: (res: any, referenceData: string[]) => {
    return checkPrefillDataForPAMI(res.pastMedicalHistories, res?.total, referenceData);
  },
  [FormComponents.BARRIERS]: (res: any) => {
    return (isValidTextValue(res) ? 1 : 0)
  },
  [FormComponents.INTERVENTION]: (res: any) => {
    return (isValidTextValue(res) ? 1 : 0)
  },
  [FormComponents.GOALS]: (res: any) => {
    return (isValidTextValue(res) ? 1 : 0)
  },
  //BASIC COMPONENTS
  [FormComponents.SELECT_BOXES]: (res: any) =>
    Object.keys(res).some((item) => res[item] === true) ? 1 : 0,
  [FormComponents.CHECKBOX]: (res: any) => (res ? 1 : 0),
  [FormComponents.TEXTFIELD]: (res: any) => (isValidTextValue(res) ? 1 : 0),
  [FormComponents.TEXTAREA]: (res: any) => (isValidTextValue(res) ? 1 : 0),
  [FormComponents.EMAIL]: (res: any) => (isValidEmail(res) ? 1 : 0),
  [FormComponents.CURRENCY]: (res: any) => (isValidTextValue(res) ? 1 : 0),
  [FormComponents.DATE]: (res: any) => (isValidTextValue(res) ? 1 : 0),
  [FormComponents.NUMBER]: (res: any) => (isValidTextValue(res) ? 1 : 0),
  [FormComponents.RADIO]: (res: any) => (isValidTextValue(res) ? 1 : 0),
  [FormComponents.SELECT]: (res: any) => (isValidTextValue(res) ? 1 : 0),
  [FormComponents.PHONE_NUMBER]: (res: any) => (isValidTextValue(res) ? 1 : 0),
  [FormComponents.SIGNATURE]: (res: any) => (isValidTextValue(res) ? 1 : 0),
  [FormComponents.FILE]: (res: any) => (res?.length > 0 ? 1 : 0),
  [FormComponents.CUSTOM_SURVEY]: (res: any, referenceData: string[]) =>
    calulateCustomSurveyComponentScore(res, referenceData),
  [FormComponents.CUSTOM_TABLE]: (res: any, referenceData: string[]) =>
    res?.length > 0 ? 1 : 0,
  //COMPONENTS NOT TO BE CALCULATED
  [FormComponents.PANEL]: () => 0,
  [FormComponents.PARAGRAPH]: () => 0,
  [FormComponents.IMAGE]: () => 0,
  [FormComponents.COLUMNS]: () => 0,
  [FormComponents.RATING]: (res: any) => (isValidRatingValue(res) ? 1 : 0),
  [FormComponents.PATIENT_IDENTIFIER]: (res: any) => {
    const isValid = Object.keys(res).length !== 0 &&
    res?.externalId &&
    res?.birthDate;
    return isValid ? 1 : 0;
  },
  [FormComponents.SYMPTOM_MONITORING]: () => 0,
  [FormComponents.MEDICATION_MANAGEMENT]: () => 0,
  [FormComponents.DIET]: () => 0,
  [FormComponents.EXERCISE]: () => 0,
  [FormComponents.HABITS]: () => 0,
  [FormComponents.HOME_MONITORING]: () => 0,
  [FormComponents.LAB_MONITORING]: () => 0,
  [FormComponents.HEALTH_MAINTENANCE]: () => 0,

  [FormComponents.NOTE_STATUS]: (res: any) => (isValidTextValue(res) ? 1 : 0),
  [FormComponents.NOTE_SUB_STATUS]: (res: any) => (isValidTextValue(res) ? 1 : 0),
  // ADT Event is a custom component and it is always valid and does not need to be validated
  [FormComponents.DISCHARGE_DETAILS]: (res: any, referenceData: string[])=> calculateDischargeDetailsScore(res, referenceData),
  [FormComponents.CARE_PROGRAM_APPOINTMENTS]: (res: any, referenceData: string[]) =>
    calculateCareProgramAppointmentsScore(res, referenceData),
  [FormComponents.CLINICAL_PARAMETERS]: () => 0,
  [FormComponents.PATIENT_INFORMATION_VERIFICATION]: (res: any) => calculatePatientInformationScore(res),
};

const getCommunicationReferenceData = (
  component: any,
  allAvailableFields: string[]
) =>
  allAvailableFields.filter((key) => {
    if (component?.enabledFields) {
      return component?.enabledFields[key];
    }
    return true;
  });

const getPAMIReferenceData = (keyAllowedOperations: IKeyOperation) => {
  if (!keyAllowedOperations) return [];
  const requiredFields: string[] = [];
  Object.keys(keyAllowedOperations)
    .map((key) => key)
    .map((key) => {
      if (keyAllowedOperations[key].isRequired === true) {
        requiredFields.push(key);
      }
    });
  return requiredFields.filter((key) => key !== 'code' && key !== 'medicationCodeableConcept' && key !== 'vaccineCode' );
};

const getDischargeDetailsReferenceData = (dischargeDetails: any, dischargeDetailsFields: string[]) => {
  const referenceData = dischargeDetailsFields.map(field => ({
    [field]: dischargeDetails?.[field] || ''
  }));
  return referenceData;
};

const getReferenceData = (
  component: any,
  formOptionData: any,
  questionnaireData?: QuestionnaireObject[]
) => {
  let data: any[] | string[] = [];
  const componentKey = getComponentKey(component?.key?.split('_')?.[0] || '');
  switch (componentKey) {
    case FormComponents.SOCIAL_HISTORY:
      const list: string[] | any[] = [];
      if (
        component.enabledQuestionnaires !== undefined &&
        !!Object.keys(component?.enabledQuestionnaires)?.length
      ) {
        Object.keys(component.enabledQuestionnaires).forEach((key) => {
          if (component.enabledQuestionnaires[key]) {
            const questionnaire = questionnaireData?.find(
              (questionnaire) => questionnaire?.id === key
            );
            questionnaire?.item.forEach((item) => list.push(item.linkId));
          }
        });
      } else {
        questionnaireData?.forEach((question) =>
          question.item.forEach((item) => list.push(item.linkId))
        );
      }
      data = list;
      break;
    case FormComponents.CUSTOM_SURVEY:
      data = component?.questions?.map(
        (question: {value: string; label: string}) => question?.value
      );
      break;
    case FormComponents.SURGICAL_HISTORY:
      data = getPAMIReferenceData(
        getEHRCapability([CapabilityResource.procedure], formOptionData)
          ?.abilities?.keyAllowedOperations || {}
      );
      break;
    case FormComponents.IMMUNIZATION:
      data = getPAMIReferenceData(
        getEHRCapability([CapabilityResource.immunization], formOptionData)
          ?.abilities?.keyAllowedOperations || {}
      );
      break;
    case FormComponents.DIAGNOSIS:
      data = getPAMIReferenceData(
        getEHRCapability([CapabilityResource.diagnosis], formOptionData)
          ?.abilities?.keyAllowedOperations || {}
      );
      break;
    case FormComponents.CONDITIONS:
      data = getPAMIReferenceData(
        getEHRCapability([CapabilityResource.condition], formOptionData)
          ?.abilities?.keyAllowedOperations || {}
      );
      break;
    case FormComponents.PAST_MEDICAL_HISTORY:
      data = getPAMIReferenceData(
        getEHRCapability([CapabilityResource.pastMedicalHistory], formOptionData)
          ?.abilities?.keyAllowedOperations || {}
      );
      break;
    case FormComponents.MEDICATIONS:
      data = getPAMIReferenceData(
        getEHRCapability(
          [CapabilityResource.medicationStatement],
          formOptionData
        )?.abilities?.keyAllowedOperations || {}
      );
      break;
    case FormComponents.ALLERGIES:
      data = getPAMIReferenceData(
        getEHRCapability(
          [CapabilityResource.allergyIntolerance],
          formOptionData
        )?.abilities?.keyAllowedOperations || {}
      );
      break;
    case FormComponents.VITALS:
      data = getEnabledVitals(
        getEHRCapability([CapabilityResource.observation], formOptionData),
        component?.enabledVitals
      );
      break;
    case FormComponents.PATIENT_DEMOGRAPHICS:
      const allAvailableDemographicFields = Object.keys(PatientField).map(
        (key) => key
      );
      data = getCommunicationReferenceData(
        component,
        allAvailableDemographicFields
      );
      break;
    case FormComponents.EMERGENCY_CONTACT:
      const allAvailableEmergencyFields = Object?.keys(
        EmergencyContactField
      ).map((key) => key);
      data = getCommunicationReferenceData(
        component,
        allAvailableEmergencyFields
      );
      break;
    case FormComponents.PATIENT_COMMUNICATION:
      const allAvailableCommunicationFields = Object.keys(
        CommunicationField
      ).map((key) => key);
      data = getCommunicationReferenceData(
        component,
        allAvailableCommunicationFields
      );
      break;
    case FormComponents.PATIENT_ADDRESS:
      const allAvailableAddressFields = Object.keys(CommunicationField)
        .map((key) => key)
        .filter(
          (key) =>
            key !== CommunicationField.email &&
            key !== CommunicationField.phoneNumber
        );
      data = getCommunicationReferenceData(
        component,
        allAvailableAddressFields
      );
      break;
    case FormComponents.CARE_PROGRAM_APPOINTMENTS:
      data = component?.selectedValue;
      break;
    case FormComponents.DISCHARGE_DETAILS:
      const dischargeDetailsFields = Object.keys(DischargeDetailsFields)
        .map((key) => key).filter((key) => key !== DischargeDetailsFields.lengthOfStayUnit && key !== DischargeDetailsFields.lengthOfStay);
      data = getDischargeDetailsReferenceData(component?.selectedValue?.dischargeDetails, dischargeDetailsFields);
      break;
    default:
      return undefined;
  }
  return data;
};

export const getPercentageScore = (
  componentData: IExtensiveComponentData,
  formResponse: any
) => {
  let totalScore = 0;
  const components = componentData?.components;
  const totalComponentsLength = componentData?.total;
  components.forEach((component) => {
    if (formResponse[component.key] !== undefined) {
      const currentScore = getProgressScoreOfComponent(component, formResponse[component.key]);
      if (currentScore) {
        totalScore += currentScore;
      }
    }
  });
  return +((totalScore / totalComponentsLength) * 100).toFixed(0);
};

export function getProgressScoreOfComponent(component: any, selectedValue: any) {
  if (!component) {
    return 0;
  }
  const componentKey = getComponentKey(component?.key?.split('_')?.[0] || '');
  const keyValidator =
    formComponentValueMap[
      componentKey as keyof typeof formComponentValueMap
    ];
  if (keyValidator) {
    const currentScore = keyValidator(selectedValue, component.referenceData);
    return currentScore;
  }
}

const componentToExclude = [
  FormComponents.PANEL,
  FormComponents.PARAGRAPH,
  FormComponents.COLUMNS,
  FormComponents.IMAGE,
  // For some cases the type is coming as 'column' instead of 'columns'
  'column'
];

export const getFormComponentsDataForProgress = (
  componentList: any[],
  formOptionData: {
    ehrCapabilities: IEhrCapability[];
  },
  questionnaireData?: QuestionnaireObject[],
  selectedFormData?: any
): IExtensiveComponentData => {
  const data: IFormComponentWithReferenceData[] = [];
  let totalComponetsLength = 0;
  let finalComponentList = componentList.filter(
    (component) => component.key !== 'submit'
  );
  if (selectedFormData) {
    finalComponentList = getVisibleComponents(finalComponentList, selectedFormData);
  }
  const isFreeTextSocialHX = (): boolean => {
    return !!getEHRCapability(
      [CapabilityResource.socialHistory],
      formOptionData
    )?.abilities?.keyAllowedOperations?.text;
  };
  forEachExtensiveFormComponent(finalComponentList, (component) => {
    // Don't consider the container component and add the length of the all available fields
    const extractedComponentKey = getComponentKey(component.key || component.type);
    if (!componentToExclude.includes(extractedComponentKey)) {
      const referenceData = getReferenceData(
        component,
        formOptionData,
        questionnaireData
      );
      if (referenceData && referenceData.length) {
        // totalComponetsLength += referenceData.length; // dont consider the container component and add the length of the all available fields
        // For following keys, even if multiple data is added, only 1 is added to total count
        if ([
          FormComponents.CONDITIONS,
          FormComponents.ALLERGIES,
          FormComponents.MEDICATIONS,
          FormComponents.IMMUNIZATION,
          FormComponents.FAMILY_HISTORY,
          FormComponents.SURGICAL_HISTORY,
          FormComponents.DIAGNOSIS,
          FormComponents.PAST_MEDICAL_HISTORY,
          FormComponents.BARRIERS,
          FormComponents.INTERVENTION
        ].includes(component.type)) {
          totalComponetsLength++;
        } else if([
          FormComponents.COLUMNS,
          FormComponents.PANEL
        ].includes(component.type)) {
          // pass, not to be considered for count
        } else {
          totalComponetsLength += referenceData.length;
        }
      } else {
        totalComponetsLength++; // add to the total components length
      }
      data.push({
        key: component.key,
        referenceData:
          getComponentKey(component.key) === FormComponents.SOCIAL_HISTORY &&
          isFreeTextSocialHX()
            ? {
                freeText: true,
              }
            : referenceData,
      });
    }
  });
  return {components: data, total: totalComponetsLength};
};

export const checkIfSocailHistoryComponentExist = (
  componets: any[]
): boolean => {
  let flag = false;
  forEachExtensiveFormComponent(componets, (component) => {
    if (component.key === FormComponents.SOCIAL_HISTORY) {
      flag = true;
    }
  });
  return flag;
};
