import {StyleSheet} from 'react-native';

export const TaskStyles = StyleSheet.create({
  buttonContainer: {
    marginBottom: 12,
    marginTop: 12,
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
