import { useLocation, useNavigate } from "react-router-dom";
import { CARE_PROGRAM_TYPE_CODES, CONTACT_CARE_PROGRAM_STATUS_CODES } from "../../../../constants/MlovConst";
import useCareProgramStatusAction, { CareProgramStatusAction } from "./hooks/useCareProgramStatusAction";
import { useCareProgramStatus } from "./hooks/useCareProgramStatus";
import { useCareProgramBillingCheck } from "./MainContentView/components/BillingView/useCareProgramBillingCheck";
import { useCareProgramEditability } from "./hooks/useCareProgramEditability";
import { getMlovCodeFromId } from "../../../../utils/mlovUtils";
import { useIntl } from "react-intl";
import { useCustomToast } from "../../../Toast/ToastProvider";
import React, { useCallback } from "react";
import { ContactCareProgramReducerAction } from "./reducer";
import { IMlov } from "../../../../Interfaces/CommonInterfaces";
import { ToastType } from "../../../../utils/commonViewUtils";
import { CareManagementThreeDotsMenuType } from "./consts";
import { canUpdateCareProgramStatus } from "../utils";

export const useContactCareProgramHeaderActions = (props: {
  statusId: string,
  careProgramId: string,
  contactId: string,
  careProgramTypeCode: string,
  dispatch: (action: ContactCareProgramReducerAction, payload: any) => void,
  careProgramStartDate: string,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const { careProgramStatus, careProgramStatusList } = useCareProgramStatus();
  const { checkBillingStatus } = useCareProgramBillingCheck();
  const toast = useCustomToast();
  const { isAllowedToTakeActions } = useCareProgramEditability();
  const statusCode = getMlovCodeFromId(careProgramStatusList, props.statusId);
  const { loadingAction, handleAction } = useCareProgramStatusAction();
  const [activeDrawerCode, setActiveDrawerCode] = React.useState<CareManagementThreeDotsMenuType | undefined>(undefined);
  const [isStatusPopoverOpen, setIsStatusPopoverOpen] = React.useState(false);
  const [isCompleteWarningPopup, setIsCompleteWarningPopup] = React.useState(false);

  const handleClose = useCallback(() => {
    try {
      const pathParts = window.location.hash.substring(1).split('/');
      const programIdIndex = pathParts.indexOf(props.careProgramId);
      if (programIdIndex !== -1 && props.careProgramId) {
        const newPath = '#' + pathParts.slice(0, programIdIndex).join('/');
        window.location.hash = newPath.substring(1);
      } else {
        navigate(-1);
      }
    } catch (error) {
      navigate(-1);
    }
  }, [navigate, props.careProgramId]);

  const canComplete = [CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS].includes(
    statusCode as string
  );

  const handleStatusChange = async (statusCode: string) => {
    let action;
    switch (statusCode) {
      case CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED:
        action = CareProgramStatusAction.COMPLETE;
        break;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS:
        action = CareProgramStatusAction.IN_PROGRESS;
        break;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.PAUSED:
        action = CareProgramStatusAction.PAUSED;
        break;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.FOLLOW_UP:
        action = CareProgramStatusAction.FOLLOW_UP;
        break;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.PCP_REVIEW:
        action = CareProgramStatusAction.PCP_REVIEW;
        break;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.ASSIGNED_TO_PCP:
        action = CareProgramStatusAction.ASSIGNED_TO_PCP;
        break;
    }
    if (action) {
      const newStatusId = await handleAction(action, {
        careProgramId: props?.careProgramId,
        contactId: props?.contactId,
      });
      if (newStatusId) {
        props.dispatch(
          ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STATUS,
          {newStatusId, statusCode: statusCode}
        );

        if (statusCode === CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED) {
          props.dispatch(ContactCareProgramReducerAction.SHOW_COMPLETED_VIEW, {});
        }
      }
    }
  };

  const redirectOneStepBack = () => {
    const pathname = location.pathname.split('/').slice(0, -1).join('/');
    navigate(pathname);
  }

  const handleMarkAsComplete = useCallback(async () => {
    if (props.careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.CCM || props.careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.ECM || props.careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.CHF) {
      const hasBilling = await checkBillingStatus(props.careProgramId);
      if (!hasBilling) {
        toast({
          message: intl.formatMessage({ id: 'pleaseGenerateBillBeforeCompleting' }),
          toastType: ToastType.error,
        });
        return;
      }
    }
    handleStatusChange(CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED)
  }, [handleAction, props.dispatch]);

  const handleCareProgramAssignee = (assigneeId?: string) => {
    if (assigneeId) {
      props.dispatch(
        ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_ASSIGNEE,
        assigneeId
      );
    }
  };

  const completeActionLabel = React.useMemo(() => {
    if (props.careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.AWV) {
      return 'Complete Visit';
    }
    return 'Complete Program';
  }, [props.careProgramTypeCode]);

  const getCareProgramUpdateStatusList = () => {
    if ([CARE_PROGRAM_TYPE_CODES.CCM, CARE_PROGRAM_TYPE_CODES.TCM, CARE_PROGRAM_TYPE_CODES.AWV].includes(props.careProgramTypeCode)) {
      const allowedStatusCodes = [
        CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS,
        CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED,
        CONTACT_CARE_PROGRAM_STATUS_CODES.FOLLOW_UP,
        CONTACT_CARE_PROGRAM_STATUS_CODES.PAUSED,
      ];

      switch (props.careProgramTypeCode) {
        case CARE_PROGRAM_TYPE_CODES.CCM:
          allowedStatusCodes.push(CONTACT_CARE_PROGRAM_STATUS_CODES.PCP_REVIEW);
          break;
        case CARE_PROGRAM_TYPE_CODES.AWV:
          allowedStatusCodes.push(CONTACT_CARE_PROGRAM_STATUS_CODES.ASSIGNED_TO_PCP);
          break;
      }

      return careProgramStatusList.filter((item) => {
        if (item.code === statusCode) {
          return false;
        }
        return allowedStatusCodes.includes(item.code);
      });
    }

    return careProgramStatusList.filter((item) => {
      if (
        [
          CONTACT_CARE_PROGRAM_STATUS_CODES.NEW,
          CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED,
          CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED,
        ].includes(item.code)
      ) {
        return false;
      } else if (item.code === statusCode) {
        return false;
      }
      return true;
    });
  };

  const canUpdateStatus = () => {
    return canUpdateCareProgramStatus(statusCode);
  }

  const onStatusChange = (item: IMlov) => {
    if (item.code === CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED) {
      handleMarkAsComplete();
    } else if (item.code === CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED) {
      setActiveDrawerCode(CareManagementThreeDotsMenuType.CLOSE_PROGRAM);
    } else {
      handleStatusChange(item.code);
    }
    setIsStatusPopoverOpen(false);
  };

  const onStatusPopoverOpenChange = (visible: boolean) => {
    setIsStatusPopoverOpen(() => visible);
  };

  return {
    completeActionLabel,
    activeDrawerCode,
    canComplete,
    loadingAction,
    statusCode,
    careProgramStatus,
    isAllowedToTakeActions,
    onStatusChange,
    setActiveDrawerCode,
    handleMarkAsComplete,
    handleClose,
    canUpdateStatus,
    handleCareProgramAssignee,
    getCareProgramUpdateStatusList,
    onStatusPopoverOpenChange,
    redirectOneStepBack,
    isStatusPopoverOpen,
    isCompleteWarningPopup,
    setIsCompleteWarningPopup
  }
}
