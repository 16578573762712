import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Button, FlatList, HStack, Pressable, Spacer, Spinner, Text, TextArea, View, VStack} from 'native-base';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {
  FormContext,
  FormViewType,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import {
  acceptIgnorePatientReportedRecords,
  deleteResource,
  fhirAddOrUpdate,
  getQuestionnaireWithHeaders,
  getResourceContentWithHeaders,
} from '../../../../../../../services/CommonService/AidBoxService';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {
  AnswerList,
  FHIRQuestionnaireObject,
  Question,
  QuestionnaireObject,
  Resource,
} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/interfaces';
import {getMomentObj} from '../../../../../../../utils/DateUtils';
import {FHIR_RESOURCE, PatientRecordActions, RESOURCE_BLOCK_MESSAGE_ID} from '../../../../../../../constants/FhirConstant';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import {
  ADD_UPDATE_EVENTS,
  componentKeys,
  FormError,
  getHeaderButtons,
  tooltipInnerOverlayStyle,
  tooltipOverlayStyle,
  tooltipPlacement,
  getSectionNoteDetails,
  getHeaderViewAllButton,
} from '../../CustomWrapper/CustomComponentHelper';
import StructuredSocialHXView from '../StructuredSocialHXView/StructuredSocialHXView';
import {
  IAddOrUpdateSocialHistoryState,
  ISocialHistoryComponentValue,
} from '../interfaces';
import {
  getFHIRQuestionnaireAnswerTemplate,
  getFHIRTemplate,
  getQuestionListFromQuestionnaire,
  getQuestionnaireIdFromObject,
  getRecordDataFormatted,
} from './AddOrUpdateSocialHistoryHelper';
import {DetailViewCollapse} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import {Colors} from '../../../../../../../styles';
import SectionLoadingIndicator from '../../CustomWrapper/SectionLoadingIndicator/SectionLoadingIndicator';
import {Collapse, Drawer, Tooltip} from 'antd';
import {EventBus} from '../../../../../../../utils/EventBus';
import CustomButton from '../../CustomWrapper/CustomButton/CustomButton';
import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import { EmptyStateSvg } from '../../../../../../common/Svg';
import { cloneDeep } from 'lodash';
import Feather from 'react-native-vector-icons/Feather';
import { ModalActionTitle } from '../../../../../../common/ModalActionTitle/ModalActionTitle';
import { BUTTON_TYPE, DISPLAY_DATE_FORMAT, HTTP_ERROR_CODE } from '../../../../../../../constants';
import { useIntl } from 'react-intl';
import { CUSTOM_COMPONENT_STYLE } from '../../constants';
import DetailPreview from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import { QUESTION_TYPES } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/QuestionnaireConsts';
import { getAnswerOfDateQuestion, getAnswerOfMultiSelectQuestion, getAnswerOfSingleSelectQuestion, getAnswerOfTextQuestion } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/QuestionnaireUtils';
import { RightOutlined } from '@ant-design/icons';
import SectionLevelDetails from '../../CustomWrapper/SectionLevelDetails/SectionLevelDetails';
import { IFormValidationOutput } from '../../CustomWrapper/interfaces';
import { FoldButton } from '../../../../../../CommonComponents/FoldButton/FoldButton';
import { getOrganizationCapabilites } from '../../../../../TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';
import { filterAndSkipDuplicates } from '../../CustomComponentUtils';
import { reactStyles } from '../../../../Analytics/style';
import { isSideCarWindow } from '../../../../../../../constants/BaseUrlConst';

const AddOrUpdateSocialHistory = (props: IFormComponentProps) => {
  const intl = useIntl()
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isSidecarContext = isSideCarWindow()
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const isPatientProfile = contextData.formContext === FormContext.patientProfile;
  const markAsReviewEnabled = false;
  const isAllowToShare = props?.component?.allowToShare != undefined &&  props?.component?.allowToShare === false ? false : true;
  const answerDateFormat = contextData.capabilities?.abilities?.keyAllowedOperations?.answer?.format;
  const [componentValue, setComponentValue] =
    useState<ISocialHistoryComponentValue>(
      props.component?.selectedValue || {questionnaire: []}
    );
  const [componentState, setComponentState] =
    useState<IAddOrUpdateSocialHistoryState>({
      questionnaireList: [],
      loading: false,
      formError: contextData.formError,
      collapsed: false,
      showSavingIndicator: false,
      editModalVisible: false,
      editModalCollapse: false,
      showPatientReportedRecords: false,
      patientReported: [],
      sectionNote: '',
      activePanels: [],
    });
  const [importFromChartLoading, setImportFromChartLoading] = useState<boolean>(false);

  const organizationCapability = getOrganizationCapabilites(
    contextData.organizationCapability,
    FHIR_RESOURCE.CLINICAL_DATA
  );
  const canUseContainedQuestionnaire = contextData?.capabilities?.abilities?.canUseContainedQuestionnaire;
  const { Panel } = Collapse;

  const validateData = (currentData: ISocialHistoryComponentValue): IFormValidationOutput => {
    return {isValid: true, message: ''};
  };

  const isCompactView = (): boolean => {
    return contextData.componentView === FormViewType.compact;
  };

  const handleAddAnswer = (
    data: AnswerList,
    currentQuestionnaire: FHIRQuestionnaireObject
  ) => {
    const list = componentValue.questionnaire;
    // Find questionnaire index from already answered questionnaire
    let mainIndex = list.findIndex(
      (item) => item.questionnaireId === currentQuestionnaire.id || item.questionnaire?.split('/')?.[1] === currentQuestionnaire.id
    );
    // If not found then push into list
    if (mainIndex === -1) {
      list.push({
        resourceType: 'QuestionnaireResponse',
        questionnaireId: currentQuestionnaire.id,
        questionnaire: `Questionnaire/${currentQuestionnaire.id}`,
        subject: {
          reference: `Patient/${contextData.patientId}`,
        },
        status: 'in-progress',
        authored: getMomentObj(new Date()).toISOString(),
        item: [],
      });
      mainIndex = list.length - 1;
    }
    // Find question index in that questionnaire
    const questionIndex = list[mainIndex].item.findIndex(
      (item) => item.linkId === data.linkId
    );
    // If not found then push into item object of that questionnaire else replace existing data with new one
    if (questionIndex === -1) {
      list[mainIndex].item.push(data);
    } else {
      if (data.answer?.[0]?.valueCoding || data.answer?.[0]?.valueString) {
        list[mainIndex].item[questionIndex] = data;
      } else {
        list[mainIndex].item.splice(questionIndex, 1);
      }
    }
    // Sort item of that questionnaire as per sequence in original questions in questionnaire to maintain question sequence
    list[mainIndex].item = list[mainIndex].item.sort((item1, item2) => {
      const index1 = currentQuestionnaire.item.findIndex(
        (item) => item.linkId === item1.linkId
      );
      const index2 = currentQuestionnaire.item.findIndex(
        (item) => item.linkId === item2.linkId
      );
      return index1 - index2;
    });
    // If all questions are answered in questionnaire then changes it's status to completed
    const allQuestionsAnswered =
      list[mainIndex].item.length === currentQuestionnaire.item.length;
    list[mainIndex].status = allQuestionsAnswered ? 'completed' : 'in-progress';
    onRecordChange(list);
    setComponentValue((prev) => ({
      ...prev,
      questionnaire: list,
    }));
  };

  const isFreeTextSocialHX = (): boolean => {
    return !!contextData.capabilities?.abilities?.keyAllowedOperations?.text;
  }

  const canAddNew = (): boolean => {
    if(isPatientProfile && organizationCapability?.canCreate === false){
      return false;
    }
    return (
      contextData.isBuilderMode ||
      ((contextData.capabilities?.abilities?.allowedOperations?.canCreate ||
        false) &&
        !props.disabled)
    );
  };

  const canUpdate = (): boolean => {
    if(isPatientProfile && organizationCapability?.canUpdate === false){
      return false;
    }

    return (
      (contextData.capabilities?.abilities?.allowedOperations?.canUpdate ||
        false) &&
      !props.disabled
    );
  };

  const onRecordChange = (records: QuestionnaireObject[]) => {
    if (contextData.backgroundSaveEnabled) {
      addOrUpdateRecord(records);
    }
  };
  const canDelete = (): boolean => {
    if(isPatientProfile && organizationCapability?.canDelete === false){
      return false;
    }

    return (
      contextData.capabilities?.abilities?.allowedOperations?.canDelete || false
    );
  };
  const canShowDeleteBtn = (itemId?: string): boolean => {
    if (isPatientForm || isPatientNote) {
      return !itemId;
    }
    return isFreeTextSocialHX() && (canDelete() || contextData.isBuilderMode);
  };
  const getDeleteFunction = (item: QuestionnaireObject, index: number) => {
    const isLocalDelete =
      (isPatientForm && !item.id) ||
      contextData.isBuilderMode ||
      !contextData.backgroundSaveEnabled;

    if (isLocalDelete) {
      onDelete(item, index, true);
    } else if (canDelete()) {
      onDelete(item, index, false);
    }
  };
  const removeFromLocal = (uniqueId?:string) => {
    const index = componentValue.questionnaire.findIndex(
      (item) => item?.id === uniqueId
    );
    componentValue.questionnaire.splice(index, 1);
    setComponentValue((prev) => ({
      ...prev,
      questionnaire: [...componentValue.questionnaire],
    }));
  }

  const removeLocalUnsavedEntries = () => {
    setComponentValue(prev => ({
      ...prev,
      questionnaire: prev.questionnaire.filter(item => !item.isLocalEntry)
    }))
  }

  const closeEditDrawer = () => {
    if (isFreeTextSocialHX()) {
      removeLocalUnsavedEntries();
    }
    setComponentState((prev) => ({...prev, editModalVisible: false}));
  }

  const onDelete = (
    data: QuestionnaireObject,
    index: number,
    isLocalDelete: boolean
  ) => {
    if (!isLocalDelete) {
      deleteResource(
        FHIR_RESOURCE.QUESTIONNAIRE_RESPONSE,
        `${data.id}`,
        !contextData.hasLoggedInContext,
        contextData.headers,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          if (response?.success) {
            removeFromLocal(data?.id);
            broadcastUpdateEvent();
          }
        },
        (error) => {

          setComponentState((prev) => ({
            ...prev,
            loading: false,
            showSavingIndicator: false,
            formError: FormError.existingDataAPIFail,
          }));
        },
      );
    } else {
     removeFromLocal(data?.id);
    }
  };
  const getHeaderRightView = (isCompact: boolean): JSX.Element | undefined => {
    if (!canAddNew()) {
      if (isCompact) {
        return getHeaderViewAllButton(() => {
          setComponentState((prev) => ({...prev, editModalVisible: true}));
        }, 'View all');
      } else {
        return;
      }
    }


    if (isCompact) {
      return getHeaderButtons(() => {
        setComponentState((prev) => ({...prev, editModalVisible: true}));
      });
    } else {
      let list = componentValue.questionnaire;
      if (!canUpdate()) {
        list = list.filter((item) => !item.id && item.text);
      }
      const disableRecordButton = !list.length;
      const canShowRecordButton = contextData.hasLoggedInContext && (canAddNew() || canUpdate()) && isFreeTextSocialHX();
      return (
        <HStack space={2} alignItems="center">
          <Spacer />
          {!canShowRecordButton && componentState.showSavingIndicator && <SectionLoadingIndicator />}
          {canShowRecordButton && (
            <View>
              <FoldButton
                nativeProps={{
                  variant:BUTTON_TYPE.PRIMARY,
                  isDisabled: disableRecordButton,
                  isLoading: componentState.showSavingIndicator,
                  isLoadingText: '...Saving',
                  onPress: () => {
                    let list = componentValue.questionnaire;
                    if (!canUpdate()) {
                      list = list.filter((item) => !item.id && item.text);
                    }
                    if (list.length) {
                      addOrUpdateRecord(list, true);
                    }
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'save'}),
                }}
              />
            </View>
          )}
        </HStack>
      );
    }
  };

  useEffect(() => {
    const isDataAvailable = isFreeTextSocialHX() ? !!componentValue.questionnaire.length : componentState.questionnaireList?.length > 0 && (!!componentValue.questionnaire.length || !!componentState.patientReported.length);
    contextData?.updateLoadingStatus?.(componentKeys.SOCIAL_HISTORY,  componentState.loading, isDataAvailable);
  }, [componentState.loading]);

  useEffect(() => {
    let list = componentValue.questionnaire;
    if (!canUpdate() && isFreeTextSocialHX()) {
      list = componentValue.questionnaire.filter((item) => {
        return !item.id && item.text;
      });
    }
    props.onChange(cloneDeep({questionnaire: list, total: componentValue.questionnaire.length}));
  }, [componentValue]);

  const getSectionAdditionalView = (isCompactView: boolean) => {
    const fieldCapabilities = contextData.capabilities?.abilities?.keyAllowedOperations;
    if (fieldCapabilities?.sectionNote) {
      return (
        <VStack paddingX={isCompactView ? 4 : 0} paddingTop={isCompactView ? 3 : 1} paddingBottom={isCompactView ? 2 : 0}>
          <SectionLevelDetails
            organizationCapabilites={organizationCapability}
            patientId={contextData.patientId || ''}
            accountLocationId={contextData.locationId}
            fieldCapabilities={fieldCapabilities}
            resource={FHIR_RESOURCE.QUESTIONNAIRE_RESPONSE}
            note={componentState.sectionNote || ''}
            onChange={(text) => setComponentState(prev => ({...prev, sectionNote: text}))}
          />
        </VStack>
      );
    }
    return;
  }

  const getDetailView = (isCompact: boolean, isPatientReported: boolean): JSX.Element => {
    if (!componentValue.questionnaire.length && isFreeTextSocialHX()) {
      return (isCompact ? (
        <>
        <View marginLeft={6}>
          <DisplayText
            extraStyles={{
              color: '#667085',
            }}
            textLocalId={'noSocialHistory'}
            textType="Text"
          />
        </View>
        {getSectionAdditionalView(true)}
        </>
      ) : (
        <VStack>
          <View marginLeft={2} marginBottom={2}>

          <EmptyStateSvg titleId="noSocialHistory" />
          </View>
          {!isCompact && canAddNew() && isFreeTextSocialHX() && (
            <View marginBottom={2}>
              {getAddNewSocialHXButton(true)}
            </View>
          )}
        </VStack>
      ))
    }

    return (
      <VStack space={2}>
        {isCompact && componentState.patientReported.length > 0 && (
          <HStack marginTop={2} marginLeft={4}>
            <CustomButton
                onPress={() => {
                  setComponentState((prev) => ({...prev, showPatientReportedRecords: true}));
                }}
                title= {'View Patient Reported Records'}
                />
            <Spacer />
          </HStack>
        )}
        {!isFreeTextSocialHX() && (
          <StructuredSocialHXView
            questionnaireList={componentState.questionnaireList}
            showSavingIndicator={componentState.showSavingIndicator}
            questionnaireAnswer={isPatientReported ? componentState.patientReported : componentValue.questionnaire}
            disabled={props.disabled}
            isReadOnly={isCompact || (!canAddNew() && !canUpdate()) || isPatientReported}
            onAnswerChange={handleAddAnswer}
            isCompact={isCompact}
            isPatientReportedRecordView={isPatientReported}
            onlyShowAnsweredQuestions={isPatientReported}
            config={{
              answerDateFormat
            }}
            onPatientRecordActionClick={(action, questionnaireAnswers) => {
              if (questionnaireAnswers) {
                handlePatientReportedActions(action, questionnaireAnswers);
              }
            }}
          />
        )}
        {isFreeTextSocialHX() && (
          <VStack padding={4} space={4}>
            {componentValue.questionnaire.map((item, index: number) => {
              const canShowInput = !isCompact && (canUpdate() || !item.id);
              return (
                <div className='page-break'>
                <View key={index}>
                  {!canShowInput && (
                    <HStack>
                      <Text>{item.text}</Text>
                      <Spacer />
                      {!props.disabled && canShowDeleteBtn(item.id) && (
                        <Pressable
                          marginTop={-1}
                          onPress={() => getDeleteFunction(item, index)}
                          borderWidth={0.3}
                          backgroundColor={Colors.Custom.Base50}
                          borderColor={Colors.Custom.ActionBtnBorder}
                          px={2}
                          py={1}
                          rounded="full"
                          shadow={2}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Feather
                            name={
                              canDelete() || contextData.isBuilderMode
                                ? 'trash-2'
                                : 'x'
                            }
                            size={20}
                            color={Colors.Custom.PurpleColor}
                          />
                        </Pressable>
                      )}
                    </HStack>
                  )}
                  {canShowInput && (
                    <TextArea
                      value={item.text}
                      onChangeText={(value) => {
                        setComponentValue((prev) => {
                          const list = prev.questionnaire;
                          list[index].text = value;
                          return {
                            questionnaire: list,
                          };
                        });
                      }}
                    />
                  )}
                </View>
                </div>
              );
            })}
            {!isCompact && canAddNew() && getAddNewSocialHXButton(false)}
          </VStack>
        )}
        {isCompact && getSectionAdditionalView(true)}
      </VStack>
    );
  };

  const getAddNewSocialHXButton = (aligneCenter: boolean) => {
    return  (
      <HStack>
        {aligneCenter && <Spacer />}
        <Button
          rounded="3xl"
          size="sm"
          variant="link"
          onPress={() => {
            componentValue.questionnaire.push({
              resourceType: 'QuestionnaireResponse',
              subject: {
                reference: `Patient/${contextData.patientId}`,
              },
              authored: getMomentObj(new Date()).toISOString(),
              text: '',
              item: [],
              ...(isFreeTextSocialHX() ? {isLocalEntry: true} : null),
            });
            setComponentValue((prev) => ({...prev, questionnaire: componentValue.questionnaire}));
          }}
        >
          + Add New Social History
        </Button>
        <Spacer />
      </HStack>
    );
  }

  const onAddOrUpdateError = (error: any) => {
    const errorMessage =
      error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED
        ? intl.formatMessage({
            id: RESOURCE_BLOCK_MESSAGE_ID,
          })
        : error?.response?.data?.message;
    setComponentState((prev) => ({
      ...prev,
      showSavingIndicator: false,
      ...(errorMessage && {customError: errorMessage}),
      ...(!errorMessage && {nonBlockingFormError: FormError.addUpdateFail}),
    }));
  };

  const addOrUpdateRecord = (records: QuestionnaireObject[], forceRefresh?: boolean) => {
    if (validateData({questionnaire: records})) {
      setComponentState((prev) => ({...prev, showSavingIndicator: true}));
      const fhirData = getFHIRTemplate({
        questionnaire: isFreeTextSocialHX()
          ? records?.map(({isLocalEntry, ...rest}) => rest)
          : records,
        patientId: contextData.patientId || '',
        tenantId: contextData.tenantId || '',
      });
      fhirAddOrUpdate(
        FHIR_RESOURCE.QUESTIONNAIRE_RESPONSE,
        undefined,
        fhirData,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          broadcastUpdateEvent();
          if (response?.data?.length) {
            updateDataFromAPIToList(response.data);
          }
          if (forceRefresh) {
            getExistingData(contextData.patientId || '');
          }
          setComponentState((prev) => ({
            ...prev,
            showSavingIndicator: false,
            nonBlockingFormError: undefined,
          }));
        },
        onAddOrUpdateError
      );
    }
  };

  const handlePatientReportedActions = (action: PatientRecordActions, record: QuestionnaireObject) => {
    setComponentState((prev) => ({...prev, showSavingIndicator: true}));
    const fhirData = getFHIRQuestionnaireAnswerTemplate(record, contextData.patientId || '');
    acceptIgnorePatientReportedRecords(
      FHIR_RESOURCE.QUESTIONNAIRE_RESPONSE,
      action,
      record.id,
      fhirData,
      {
        patientId: contextData.patientId,
        locationId: contextData.locationId,
      },
      (response) => {
        broadcastUpdateEvent();
        if (response?.data?.id || response?.data?.success) {
          updatePatientReportedDataFromAPIToList(response?.data, record, action);
        }
        setComponentState((prev) => ({
          ...prev,
          showSavingIndicator: false,
          nonBlockingFormError: undefined,
        }));
      },
      () => {
        setComponentState((prev) => ({
          ...prev,
          showSavingIndicator: false,
          nonBlockingFormError: FormError.addUpdateFail,
        }));
      }
    );
  }

  const updateDataFromAPIToList = (data: {value?: QuestionnaireObject}[]) => {
    setComponentValue((prev) => {
      const list = prev.questionnaire;
      list.forEach((questionnaire) => {
        if (!questionnaire.id) {
          const questionnaireId = getQuestionnaireIdFromObject(questionnaire);
          const matchingQuestionnaireResponse = data.find((item) => {
            if (item.value) {
              const dataQuestionnaireId = getQuestionnaireIdFromObject(
                item.value
              );
              return questionnaireId && questionnaireId === dataQuestionnaireId;
            }
          });
          if (matchingQuestionnaireResponse?.value?.id) {
            questionnaire.id = matchingQuestionnaireResponse.value.id;
          }
        }
      });
      return {
        ...prev,
        questionnaire: list,
      };
    });
  };

  const updatePatientReportedDataFromAPIToList = (data: any, record: QuestionnaireObject, action: PatientRecordActions) => {
    setComponentState((prev) => {
      const list = prev.patientReported;
      const index = list.findIndex(
        (value) => value.id === record.id
      );
      if (index > -1) {
        list.splice(index, 1);
      }
      return {
        ...prev,
        patientReported: list,
        showPatientReportedRecords: list.length > 0
      };
    });
    if (action === PatientRecordActions.accept && data?.id) {
      getExistingData(contextData.patientId || '');
    }
  };

  const getExistingData = (patientId: string) => {
    setComponentState((prev) => ({...prev, loading: true}));
    getResourceContentWithHeaders(
      FHIR_RESOURCE.QUESTIONNAIRE_RESPONSE,
      `patient=${patientId}`,
      !contextData.hasLoggedInContext,
      contextData.headers,
      (response) => {
        handleExistingDataResponse(response);
      },
      (error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          formError: FormError.existingDataAPIFail,
        }));
      },
      contextData.locationId,
      markAsReviewEnabled
    );
  };

  const handleExistingDataResponse = (response: any) => {
    if (!markAsReviewEnabled) {
      const entries: QuestionnaireObject[] = [];
      const sectionNote = getSectionNoteDetails(response.meta?.extension || []).sectionNote;
      for (const el of response?.entry || []) {
        if (el?.resource) {
          const entry = el.resource;
          entries.push(entry);
        }
      }
      setComponentValue((prev) => ({
        ...prev,
        questionnaire: [...entries],
      }));

      contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.SOCIAL_HISTORY, { questionnaire: [...entries] });
      setImportFromChartLoading(false);
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        sectionNote: sectionNote,
        activePanels: entries?.length ? [componentKeys.SOCIAL_HISTORY] : [],
        formError: !response?.entry
          ? FormError.existingDataAPIFail
          : prev.formError,
      }));
    } else {
      const patientReportedRecords = response?.patientReported?.data?.entry || [];
      const ehrRecords = response?.resource?.data?.entry || [];
      const sectionNote = getSectionNoteDetails(response?.resource?.data?.meta?.extension || []).sectionNote;
      let patientReportedFormattedRecords: QuestionnaireObject[] = [];
      let ehrFormattedRecords: QuestionnaireObject[] = [];
      if (patientReportedRecords || ehrRecords) {
        if (patientReportedRecords?.length) {
          patientReportedFormattedRecords = getRecordDataFormatted(patientReportedRecords);
        }
        if (ehrRecords?.length) {
          ehrFormattedRecords = getRecordDataFormatted(ehrRecords);
        }
      }
      setComponentValue((prev) => ({
        ...prev,
        questionnaire: [...ehrFormattedRecords],
      }));

      contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.SOCIAL_HISTORY, { questionnaire: [...ehrFormattedRecords] });
      setImportFromChartLoading(false);
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        sectionNote: sectionNote,
        ...(!patientReportedFormattedRecords.length && {showPatientReportedRecords: false}),
        patientReported: patientReportedFormattedRecords,
        activePanels: ehrRecords?.length || patientReportedFormattedRecords?.length ? [componentKeys.SOCIAL_HISTORY] : [],
        formError: !ehrRecords || !patientReportedRecords
          ? FormError.existingDataAPIFail
          : prev.formError,
      }));
    }
  }

  const setQuestionnaireList = (list: Resource[]) => {
    let itemList = list.map((item: any) => item.resource);
    const config = props.component.enabledQuestionnaires;
    if (config && Object.keys(config).length > 0) {
      itemList = itemList.filter((item: any) => {
        if (config.hasOwnProperty(item.id)) {
          return config[item.id];
        }
        return true;
      });
    }
    setComponentState((prev) => ({...prev, questionnaireList: itemList}));
  };

  const fetchQuestionnaireList = (canHideLoader: boolean) => {
    if (contextData.questionnaireList.length > 0) {
      setQuestionnaireList(contextData.questionnaireList);
      return;
    }
    setComponentState((prev) => ({...prev, loading: true}));
    getQuestionnaireWithHeaders(
      !contextData.hasLoggedInContext,
      contextData.headers,
      {
        locationId: contextData.locationId,
        ...(canUseContainedQuestionnaire && { patientId: contextData.patientId }),
      },
      (response) => {
        if (response?.data?.entry?.length) {
          setQuestionnaireList(response.data.entry || []);
        }
        setComponentState((prev) => ({
          ...prev,
          loading: canHideLoader ? false : prev.loading,
          formError: !response?.data?.entry
            ? FormError.configurationDataAPIFail
            : prev.formError,
        }));
      },
      (error) => {

        setComponentState((prev) => ({
          ...prev,
          formError: FormError.configurationDataAPIFail,
          loading: canHideLoader ? false : prev.loading,
        }));
      }
    );
  };

  const broadcastUpdateEvent = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(
      ADD_UPDATE_EVENTS.SOCIAL_HISTORY,
      contextData.componentView
    );
  };

  const onEventReceived = useCallback(
    (data) => {
      if (data != contextData.componentView && contextData?.patientId && !isPatientNote) {
        getExistingData(contextData.patientId);
      }
    },
    [contextData]
  );

  useEffect(() => {
    const patientId = contextData?.patientId;
    const canFetchExistingData =
      !!patientId &&
      !isPatientNote &&
      (!props.component.selectedValue ||
        (isPatientForm && !isPreviewMode && isFreeTextSocialHX()));
    if (contextData.formError) return;
    if (!componentState.questionnaireList?.length)
      fetchQuestionnaireList(!canFetchExistingData);
    if (contextData.isBuilderMode) return;
    if (canFetchExistingData) {
      getExistingData(patientId);
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      ADD_UPDATE_EVENTS.SOCIAL_HISTORY,
      onEventReceived
    );
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, []);

  const fetchImportData = () => {
    if (isPatientNote && !isPreviewMode && contextData.patientId) {
      setImportFromChartLoading(true);
      getExistingData(contextData.patientId);
    }
  };

  const isPreviewMode = contextData.isPreviewMode || false;
  props.validateRef.current = validateData;

  const getPreviewData = () => {
    if (!isPreviewMode) {
      return [];
    }
    const questionnaireList = componentState?.questionnaireList || [];
    const questionnaireAnswer = componentValue?.questionnaire || [];
    const data =
      questionnaireList.length > 0
        ? questionnaireList.map((questionnaire, index) => {
            const questionList = getQuestionListFromQuestionnaire(questionnaire);
            const currentQuestionnaireAnswers = questionnaireAnswer.find(
              (item) => {
                const questionnaireId = getQuestionnaireIdFromObject(item);
                return questionnaireId === questionnaire.id;
              }
            );
            const answers = currentQuestionnaireAnswers?.item || [];
            return {
              ...questionnaire,
              answers,
              questions: questionList,
            };
          }).filter((item) => !!(item?.questions?.length && item.answers?.length))
        : [];
        return data;
  };
  const getReadOnlyView = (question: string, answer: string) => {
    return (
      <VStack>
        <Text>
          {question}: {answer}
        </Text>
      </VStack>
    );
  };

  const data = getPreviewData();

  const getAnswer = (question: any, answers: AnswerList[]) => {
    switch (question.type) {
      case QUESTION_TYPES.CHOICE:
        if (question?.repeats) {
          const multiAnswer = getAnswerOfMultiSelectQuestion(question, answers);
          const finalAnswer = multiAnswer.map((answer) => answer.display).join(', ') || '-';
          return getReadOnlyView(question.text, finalAnswer);
        }
        const answer = getAnswerOfSingleSelectQuestion(question, answers);
        return getReadOnlyView(question.text, answer?.display || '-');
      case QUESTION_TYPES.TEXT:
      case QUESTION_TYPES.FREE_TEXT:
      case QUESTION_TYPES.STRING:
        const textAnswer = getAnswerOfTextQuestion(question, answers);
        return getReadOnlyView(question.text, textAnswer || '-');
      case QUESTION_TYPES.RADIO:
        const radioAnswer = getAnswerOfSingleSelectQuestion(
          question,
          answers
        );
        return getReadOnlyView(question.text, radioAnswer?.display || '-');
      case QUESTION_TYPES.MULTI_SELECT:
        const multiAnswer = getAnswerOfMultiSelectQuestion(question, answers);
        const finalAnswer =
          multiAnswer.map((answer) => answer.display).join(', ') || '-';
        return getReadOnlyView(question.text, finalAnswer);
      case QUESTION_TYPES.NUMERIC:
        const numericAnswer = getAnswerOfTextQuestion(question, answers);
        return getReadOnlyView(question.text, numericAnswer || '');
      case QUESTION_TYPES.DATE:
      case QUESTION_TYPES.DATE_NEW:
        const dateAnswer = getAnswerOfDateQuestion(question, answers);
        const formattedDate = dateAnswer ? getMomentObj(new Date(dateAnswer)).format(DISPLAY_DATE_FORMAT) : undefined;
        return getReadOnlyView(question.text, formattedDate || '-');
      default:
        return <></>;
    }
  };

  const isSocialHistoryEmpty = (!componentValue.questionnaire.length && !isFreeTextSocialHX());
  if (isPreviewMode) {
    if (isSocialHistoryEmpty) {
      return <></>;
    }
    return (
      <>
        {componentState.loading && (
          <View padding={4}>
            <FormComponentLoader />
          </View>
        )}
        {!isSocialHistoryEmpty &&!componentState.loading && !componentState.formError && !isFreeTextSocialHX() && (
          // <div className="page-break">
            <div className={` ${isAllowToShare ? '' : 'disallow-to-share'}`}>
            <DetailPreview titleLocalId={props.component.label}>
              <FlatList
                data={data}
                initialNumToRender={(data || [])?.length}
                renderItem={({item}) => (
                    <div className="page-break">
                      <VStack my={1}>
                        <Text fontWeight={600}>{item.name}</Text>
                        {item.questions.map((question) => {
                          return (
                            <VStack ml={2} key={question.id}>
                              {getAnswer(question, item.answers)}
                            </VStack>
                          );
                        })}
                      </VStack>
                    </div>
                )}
              />
            </DetailPreview>
            </div>
          // </div>
        )}
        {componentValue?.questionnaire?.length > 0 &&!componentState.loading && !componentState.formError && isFreeTextSocialHX() && (
          <DetailPreview titleLocalId={props.component.label}>
            <FlatList
              data={componentValue.questionnaire}
              initialNumToRender={(data || [])?.length}
              renderItem={({item}) => (
                  <div className="page-break">
                    <VStack my={1}>
                      <Text fontWeight={600}>{item.text}</Text>
                    </VStack>
                  </div>
              )}
            />
          </DetailPreview>
        )}
      </>
    );
  }
  return (
    <View>
      {isCompactView() ? (
        <VStack background={Colors.Custom.BackgroundColor}>
          <Collapse
            activeKey={componentState.activePanels}
            onChange={(activePanels) => {
              setComponentState((prev) => ({...prev, activePanels: activePanels as string[]}));
            }}
            expandIconPosition="end"
            expandIcon={({ isActive }) => {
              const tooltipText = isActive ? intl.formatMessage({id: 'collapse'}) : intl.formatMessage({id:'expand'});
              return (
                <Tooltip title={tooltipText} placement={tooltipPlacement} showArrow={false} overlayStyle={tooltipOverlayStyle} overlayInnerStyle={tooltipInnerOverlayStyle}>
                  <RightOutlined rotate={isActive ? 90 : 0} />
                </Tooltip>
              )
            }}
          style={reactStyles.collapseStyle3}>
            <Panel forceRender className='last-subheader'  key={componentKeys.SOCIAL_HISTORY} header={
              <HStack
                alignItems={'center'}
                marginRight={4}
                justifyContent="space-between"
              >
                <HStack alignItems={'center'} space={2}>
                  <Text
                    size={'mdNormal'}
                    color={Colors.FoldPixel.GRAY300}
                  >
                    {props.component.label}
                  </Text>
                </HStack>
                <View>{getHeaderRightView(isCompactView())}</View>
              </HStack>}>
              {componentState.loading && (
                <View padding={4}>
                  <FormComponentLoader />
                </View>
              )}
              {!componentState.loading && componentState.customError && (
                 <FormComponentError error={FormError.custom} customError={componentState.customError} />
               )}
              {!componentState.loading && componentState.formError && (
                <FormComponentError error={componentState.formError} />
              )}
              {!componentState.loading && componentState.nonBlockingFormError && (
                <FormComponentError error={componentState.nonBlockingFormError} />
              )}
              {!componentState.loading &&
                !componentState.formError &&
                getDetailView(isCompactView(), false)}
            </Panel>
          </Collapse>
        </VStack>
      ) : (
        <DetailViewCollapse
          btnList={[{
            icon: importFromChartLoading ? (
              <Spinner mr={1.5} />
            ) : (
              <Feather
                name="download"
                size={20}
                color={Colors.Custom.mainPrimaryPurple}
              />
            ),
            onIconBtnClick: fetchImportData,
            label: intl.formatMessage({id: 'importFromChart'}),
            tooltipLabel: intl.formatMessage({id: 'importFromChartOverrideInfo'}),
            isCustomButton: true,
            hide: !(isPatientNote && !isPreviewMode),
            disabled: importFromChartLoading,
          }]}
          textLocalId={props.component.label}
          toggled={!componentState.collapsed}
          headerRightView={getHeaderRightView(isCompactView())}
          headerStyle={{marginTop: 3}}
          extraTextStyles={
            isPatientNote ? {fontWeight: 'bold', fontSize: 16} : {}
          }
          extraPanelStyles={
            isPatientNote
              ? {borderColor: '#D0D5DD', ...CUSTOM_COMPONENT_STYLE}
              : {}
          }
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              collapsed: !prev.collapsed,
            }));
          }}
        >
          <VStack>
            {componentState.loading && (
              <View padding={4}>
                <FormComponentLoader />
              </View>
            )}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(isCompactView(), false)}
          </VStack>
        </DetailViewCollapse>
      )}
      <Drawer
        title={
          <>
          <ModalActionTitle
            isHeadNotSticky
            title={'Social History'}
            buttonList={[
              {
                onClick: () => {
                  closeEditDrawer()
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: 'close',
                }),
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
              },
            ]}
          />
          {getSectionAdditionalView(false)}
          </>
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          closeEditDrawer()
        }}
        open={componentState.editModalVisible}
        closable
        width={isSidecarContext ? '100%' : '50%'}
      >
        <DetailViewCollapse
          btnList={[]}
          textLocalId={props.component.label}
          toggled={!componentState.editModalCollapse}
          headerRightView={getHeaderRightView(false)}
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              editModalCollapse: !prev.editModalCollapse,
            }));
          }}
        >
          <VStack>
            {componentState.loading && (
              <View padding={4}>
                <FormComponentLoader />
              </View>
            )}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(false, false)}
          </VStack>
        </DetailViewCollapse>
      </Drawer>
      <Drawer
        title={
          <ModalActionTitle
            isHeadNotSticky
            title={'Patient Reported Social History'}
            buttonList={[
              {
                isDisabled: componentState.showSavingIndicator,
                isLoading: componentState.showSavingIndicator,
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    showPatientReportedRecords: false,
                    selectedVitalsForBulkActions:
                      componentState.patientReported.map(
                        (item) => item.id || ''
                      ),
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: componentState.showSavingIndicator ? 'Saving' : 'close',
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: componentState.showSavingIndicator
                  ? BUTTON_TYPE.TERTIARY
                  : BUTTON_TYPE.SECONDARY,
              },
            ]}
          />
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          setComponentState((prev) => ({
            ...prev,
            showPatientReportedRecords: false,
          }));
        }}
        open={componentState.showPatientReportedRecords}
        closable
        width={isSidecarContext ? '100%' : '50%'}
      >
        <VStack>
          {componentState.loading && <FormComponentLoader />}
          {!componentState.loading && componentState.nonBlockingFormError && (
            <FormComponentError error={componentState.nonBlockingFormError} />
          )}
          {!componentState.loading && componentState.customError && (
            <FormComponentError error={FormError.custom} customError={componentState.customError} />
          )}
          {!componentState.loading && componentState.formError && (
            <FormComponentError error={componentState.formError} />
          )}
          {!componentState.loading &&
            !componentState.formError &&
            getDetailView(false, true)}
        </VStack>
      </Drawer>
    </View>
  );
};

export default AddOrUpdateSocialHistory;
