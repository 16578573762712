import { useLazyQuery } from '@apollo/client';
import { Drawer } from 'antd';
import { DiagnosticReport, MedicationRequest, RequestGroup } from 'fhir/r4';
import {
  VStack,
  Box,
  Text,
  ScrollView,
  HStack,
  View,
  Skeleton,
  Divider,
  Spacer,
  Tooltip,
  Pressable,
  Spinner,
} from 'native-base';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import Feather from 'react-native-vector-icons/Feather';
import { BUTTON_TYPE, DATE_FORMATS, RIGHT_SIDE_CONTAINER_CODE } from '../../../../constants';
import { SUPPORTED_EVENT_CODE } from '../../../../constants/WebSocketConst';
import { getDiagnosticReportByPatientId, getMedicationRequestByPatientId, getRequestGroupByPatientId, updateMultipleDiagnosticReport } from '../../../../services/CommonService/OrderService';
import { GET_USERS_BY_USER_IDS_OR_EXT_USER_IDS } from '../../../../services/User/UserQueries';
import {Colors} from '../../../../styles';
import { getEhrConfig, getResourceAbilities, isDiagnosticReportEnabled, isMarkAsReviewEnabled, isNewHGFlowEnabled, isOrderTypeEnabled } from '../../../../utils/capabilityUtils';
import {
  getCurrentTimeZone,
  getDateStrFromFormat, getDateStrFromFormatWithTimezone,
} from '../../../../utils/DateUtils';
import { EventBus } from '../../../../utils/EventBus';
import { ModalActionTitle } from '../../../common/ModalActionTitle/ModalActionTitle';
import CustomButton from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomButton/CustomButton';
import AddOrUpdateOrders from '../../MiddleContainer/Orders/OrdersAndReports/AddOrUpdateOrders/AddOrUpdateOrders';
import { OrderType } from '../../MiddleContainer/Orders/OrdersAndReports/OrderConstants';
import OrderDetailsDrawer from '../../MiddleContainer/Orders/OrdersAndReports/OrderDetailsDrawer/OrderDetailsDrawer';
import { getOrderDateTime, hasReportFor, getOrderFromReport, getMedicationOrderName, getMedicationSubTitle, getOrderSubtitle, getOrderTypeFromOrder, getDocumentReferenceIdFromOrder, isPendingDraftOrder, getOrderName, ReportCategory, isPendingMedicationOrder, getRequesterId, isOrderAddedFromFold } from '../../MiddleContainer/Orders/OrdersAndReports/OrderUtils';
import PDFIcon from '../../MiddleContainer/OrdersTimeline/assets/PdfIcon';
import ResultIcon from '../../MiddleContainer/OrdersTimeline/assets/ResultIcon';
import {
  IPendingReportDetails,
  IReportAndResourceMap,
} from './OrdersInterface';
import {ReportResultView} from './RecentReport/ReportResultView';
import {
  getDiagnosticReportResponseFormatted,
  getDiagnosticReportsForServiceRequest,
  getReportResultForDisplay,
  getReviewedByReportDetails,
  getServiceRequestsFromOrder,
  isAllReportViewedBy,
  REPORT_TYPE,
} from './RecentReport/ReportUtils';
import { FHIR_RESOURCE } from '../../../../constants/FhirConstant';
import { getLabOrders, getReportForImaging } from './helper';
import ViewEhrActionBtn from '../../../common/Svg/PersonActionSvgIcons/ViewEhrActionBtn';
import EmailDrawerCommon from '../../../common/EmailPopupView/EmailDrawerCommon';
import CreateSmsConversationDrawer from '../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import MeetingView from '../../../common/MeetingView/MeetingView';
import { getFormDataFromLeadData } from '../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import InstantChat from '../../../common/ChatDrawer/InstantChat';
import { StyleSheet } from 'react-native';

export const PendingOrders = (props: {
  personData: any;
  unformmatedContactData: any;
  onListUpdate?: (isDataAvailable: boolean) => void;
}) => {
  const NEW_HG_FLOW = isNewHGFlowEnabled();
  const {personData} = props;
  const mlovData = useContext(CommonDataContext);
  const intl = useIntl();
  const accountLocationUuid = personData?.accountLocationUuid || '';
  // For athena only LAB Diagnostic reports are available, thus not added condition for RAD
  const isLabEnabled =
    isOrderTypeEnabled(OrderType.LAB, '', accountLocationUuid) ||
    isDiagnosticReportEnabled(OrderType.LAB, accountLocationUuid);
  const isRadEnabled =
    isOrderTypeEnabled(OrderType.RAD, '', accountLocationUuid) ||
    isDiagnosticReportEnabled(OrderType.RAD, accountLocationUuid);
  // As discussed, we are removing eRx, pending reports and draft orders from recent clinical events section
  const pendingReportsEnabled = false;
  const isMedEnabled = false
  // isOrderTypeEnabled(OrderType.MED, undefined, accountLocationUuid);
  const isLabRadEnabled = isLabEnabled || isRadEnabled;
  // If mark as review is disabled then we will not remove reviewed reports from the list and rename section from New Lab Report to Lab Reports
  const canShowMarkAsReview = isMarkAsReviewEnabled(accountLocationUuid);
  const ehrConfig = getEhrConfig(accountLocationUuid, '');

  const labOrderAbilitity = getResourceAbilities(
    FHIR_RESOURCE.DIAGNOSTIC_REPORT,
    '',
    accountLocationUuid
  );

  const [pendingOrders, setPendingOrders] =
    useState<IPendingReportDetails>({
      loading: false,
      userAPILoading: false,
      reportLoadingKey: undefined,
      showModal: false,
      showDetail: false,
      showImagingResultTable: false,
      showDraftOrders: false,
      showAddNewOrderModal: false,
      orders: [],
      activeOrders: [],
      draftOrders: [],
      reports: [],
      labReports: [],
      radReports: [],
      medications: [],
      selectedReport: {
        reportName: '',
        observation: [],
        resources: [],
        isReportReviewedByUser: false,
      },
      selectedOrder: {},
      reportResultViewLoading: false,
    });

    const contactDetails = personData;
    let formattedContactData: any = '';

    if (contactDetails) {
      contactDetails['uuid'] = personData?.contactUUID
      formattedContactData = getFormDataFromLeadData(contactDetails || {}, mlovData);
    }

    const [getUserList] = useLazyQuery(
      GET_USERS_BY_USER_IDS_OR_EXT_USER_IDS,
      {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          if (data?.accountUsers.length) {
            const userIdNameMap: any = {};
            data.accountUsers.forEach((item: any) => {
              if (item.user?.uuid) {
                userIdNameMap[item.user.uuid] = item.user.name;
              }
            });
            setPendingOrders((prev) => {
              const list = prev.medications;
              list.forEach((med) => {
                const userId = getRequesterId(med);
                if (userId && med.requester && userIdNameMap[userId]) {
                  med.requester.display = userIdNameMap[userId];
                }
              })
              return {
                ...prev,
                medications: [...list],
                userAPILoading: false,
              }
            });
          }
        },
        onError: () => {
          setPendingOrders((prev) => ({
            ...prev,
            userAPILoading: false,
          }));
        }
      }
    );

  const configureReportResult = async (
    selectedReport: IReportAndResourceMap,
    reportType: string,
    key: string,
    isImaging?: boolean
  ) => {
    setPendingOrders((prev: any) => {
      return {
        ...prev,
        reportLoadingKey: key,
      };
    });
    const {reportName, observationList} = await getReportResultForDisplay(
      selectedReport,
      reportType,
      pendingOrders.orders,
      { ehrConfig, patientId: personData?.patientId || personData?.patientUuid, locationId: accountLocationUuid },
    );
    const isDataAvailable = !!reportName && observationList.length > 0;
    setPendingOrders((prev: any) => {
      return {
        ...prev,
        ...(
          isImaging ? {showImagingResultTable: isDataAvailable} : {showModal: isDataAvailable}
        ),
        reportLoadingKey: undefined,
        selectedReport: {
          reportName,
          observation: observationList,
          resources: selectedReport?.resources || [],
          isReportReviewedByUser: isAllReportViewedBy(selectedReport)
        },
      };
    });
  };

  const fetchOrders = () => {
    const patientId = personData?.patientId || personData?.patientUuid;
    if (!pendingOrders.loading && patientId) {
      setPendingOrders((prev: any) => {
        return {
          ...prev,
          loading: true,
        };
      });

      Promise.all([
        isLabRadEnabled ? getRequestGroupByPatientId(patientId || '', accountLocationUuid) : Promise.resolve(),
        isLabRadEnabled ? getLabOrders(patientId || '', accountLocationUuid, labOrderAbilitity?.usePagination, canShowMarkAsReview, ReportCategory.LAB) : Promise.resolve([]),
        isMedEnabled ? getMedicationRequestByPatientId(patientId || '', accountLocationUuid) : Promise.resolve(),
        isRadEnabled ? getLabOrders(patientId || '', accountLocationUuid, labOrderAbilitity?.usePagination, canShowMarkAsReview, ReportCategory.IMAGING) : Promise.resolve([]),
      ]).then((responses) => {
        const list: RequestGroup[] = responses?.[0]?.data?.entry?.map((item: any) => item.resource) || [];
        let reports = responses?.[1] || [];
        let radReports = responses?.[3] || []
        const ordersWithoutReport = list.filter((order) => !hasReportFor(order, reports)).sort((order1, order2) => {
          const date1: any = new Date(getOrderDateTime(order1));
          const date2: any = new Date(getOrderDateTime(order2));
          return date1 - date2;
        });
        const reportWithDates: IReportAndResourceMap[] = [];
        const reportWithoutDates: IReportAndResourceMap[] = [];
        const radReportWithDates: IReportAndResourceMap[] = [];
        const radReportWithoutDates: IReportAndResourceMap[] = [];
        reports.forEach((report) => {
          if (report.effectiveDateTime) {
            reportWithDates.push(report);
          } else {
            reportWithoutDates.push(report);
          }
        });
        radReports?.forEach((report) => {
          if (report.effectiveDateTime) {
            radReportWithDates.push(report);
          } else {
            radReportWithoutDates.push(report);
          }
        });
        reports = reportWithDates.sort((report1, report2) => {
          const date1: any = new Date(report1.effectiveDateTime || new Date());
          const date2: any = new Date(report2.effectiveDateTime || new Date());
          return date2 - date1;
        });
        reports = reports.concat(reportWithoutDates);

        radReports = radReportWithDates?.sort((report1, report2) => {
          const date1: any = new Date(report1.effectiveDateTime || new Date());
          const date2: any = new Date(report2.effectiveDateTime || new Date());
          return date2 - date1;
        });
        radReports = radReports?.concat(radReportWithoutDates);

        const medicationRequests: MedicationRequest[] = [];
        const userUUIDs: string[] = [];
        if (isMedEnabled) {
          responses?.[2]?.data?.entry?.forEach((item: {resource: MedicationRequest}) => {
            const medOrder = item.resource;
            if (medOrder) {
              medicationRequests.push(medOrder);
              const userUUID = getRequesterId(medOrder);
              if (userUUID && !userUUIDs.includes(userUUID)) {
                userUUIDs.push(userUUID);
              }
            }
          });
          if (userUUIDs.length) {
            setPendingOrders((prev: any) => {
              return {
                ...prev,
                userAPILoading: true,
              };
            });
            getUserList({
              variables: {
                userIds: userUUIDs.filter((uuid) => uuid !== '-1'),
                externalUserIds: [],
              }
            });
          }
        }

        setPendingOrders((prev: any) => {
          return {
            ...prev,
            orders: list,
            activeOrders: ordersWithoutReport.filter((item) => item.status !== 'draft'),
            draftOrders: ordersWithoutReport.filter((item) => item.status === 'draft'),
            medications: medicationRequests,
            reports: reports,
            labReports: reports.filter((item) => item.orderType === ReportCategory.LAB),
            radReports: radReports,
            loading: false,
          };
        });
        props.onListUpdate?.(!!reports.length);
      }).catch((error) => {
        setPendingOrders((prev: any) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
    }
  }

  const renderReportButton = (report: IReportAndResourceMap, key: string, isImagingReport?: boolean): JSX.Element => {
    const latestReport = report.resources?.[0];
    const matchedOrder = getOrderFromReport(latestReport, pendingOrders.orders);
    if (isImagingReport) {
      return getInfoButton({
        report: latestReport,
        order: matchedOrder,
        isImagingReport: isImagingReport,
        documentTitle: `${report.resourceCode ? `${report.resourceCode}` : ``}${
          !!report.resourceCode ? ` ${report.display}` : report.display
        }`,
      });
    }
    switch (report?.type) {
      case REPORT_TYPE.BOTH:
        return (
          <>
            {getReportButton(report, REPORT_TYPE.MEDIA_REPORT, key, true)}
            {getReportButton(report, REPORT_TYPE.RESULT, key, false)}
            {!ehrConfig.isAthena && getInfoButton({
              report: latestReport,
              order: matchedOrder,
              isImagingReport: isImagingReport,
            })}
          </>
        );
      case REPORT_TYPE.MEDIA_REPORT:
        return (
          <>
            {getReportButton(report, REPORT_TYPE.MEDIA_REPORT, key, true)}
            {!ehrConfig.isAthena && getInfoButton({
              report: latestReport,
              order: matchedOrder,
              isImagingReport: isImagingReport,
            })}
          </>
        );
      case REPORT_TYPE.RESULT:
        return (
          <>
          {getReportButton(report, REPORT_TYPE.RESULT, key, true)}
          {!ehrConfig.isAthena && getInfoButton({
              report: latestReport,
              order: matchedOrder,
              isImagingReport: isImagingReport,
            })}
          </>
        );
      case REPORT_TYPE.FOLD_MEDIA_REPORT:
        return (
          <>
          {getReportButton(report, REPORT_TYPE.FOLD_MEDIA_REPORT, key, true)}
          {!ehrConfig.isAthena && getInfoButton({
              report: latestReport,
              order: matchedOrder,
              isImagingReport: isImagingReport,
            })}
          </>
        );
    }
    return  <></>
  };

  const getEditButton = (order: RequestGroup | MedicationRequest): JSX.Element => {
    const isLabRadOrder = order.resourceType === 'RequestGroup';
    const isMedOrder = order.resourceType === 'MedicationRequest';
    return (
      <Tooltip label="Edit Order" placement="top">
        <Pressable
          ml={2}
          onPress={() => {
            setPendingOrders((prev) => {
              return {
                ...prev,
                showAddNewOrderModal: true,
                selectedOrder: {
                  ...(isLabRadOrder && {order: order}),
                  ...(isMedOrder && {prescription: order}),
                  noteId: getDocumentReferenceIdFromOrder(order)
                }
              }
            });
          }}
        >
          <Feather
            name="edit-3"
            style={styles.icon}
          />
        </Pressable>
      </Tooltip>
    );
  };

  const getReportDataForImaging = async (report?: DiagnosticReport) => {
    if (report) {
      setPendingOrders((prev: any) => {
        return {
          ...prev,
          reportResultViewLoading: true,
        };
      });
      const supportingParams = {
        patientId: personData?.patientId || personData?.patientUuid,
        locationId: accountLocationUuid,
      };
      const reportForImaging = await getReportForImaging(
        report,
        canShowMarkAsReview ?? false,
        supportingParams
      );
      reportForImaging?.length > 0 &&
        !!reportForImaging?.[0]?.type &&
        configureReportResult?.(
          reportForImaging[0],
          reportForImaging[0].type,
          `${report?.code}_${report?.id}`,
          true
        );
        setPendingOrders((prev: any) => {
          return {
            ...prev,
            reportResultViewLoading: false,
          };
        });
    }
    return [];
  };

  const getInfoButton = (params: {
    report?: DiagnosticReport,
    order?: RequestGroup,
    action?: any,
    prescription?: MedicationRequest,
    isImagingReport?: boolean;
    documentTitle?: string
  }): JSX.Element => {
    return (
      <Tooltip label="View Details" placement="top">
        <Pressable
          ml={2}
          onPress={() => {
            if (params?.isImagingReport) {
              getReportDataForImaging(params.report);
            }
            setPendingOrders((prev) => {
              return {
                ...prev,
                showDetail: true,
                selectedOrder: {
                  report: params.report,
                  order: params.order,
                  action: params.action,
                  prescription: params.prescription,
                  reportType: params?.isImagingReport ? 'IMAGING' : 'LAB',
                  documentTitle: params?.documentTitle
                }
              }
            });
          }}
        >
          {params?.isImagingReport ? (
            <View style={styles.iconBtnView}>
              <ViewEhrActionBtn isActive={true} />
            </View>
          ) : (
            <Feather
              name="info"
              style={styles.icon}
            />
          )}
        </Pressable>
      </Tooltip>
    );
  };

  const getReportButton = (
    report: IReportAndResourceMap,
    reportType: string,
    key: string,
    isFirstAction: boolean,
  ): JSX.Element => {
    const isMediaReport = reportType === REPORT_TYPE.MEDIA_REPORT || reportType === REPORT_TYPE.FOLD_MEDIA_REPORT;
    if (key === pendingOrders.reportLoadingKey) {
      return <Spinner size={'sm'} />
    }
    return (
      <Tooltip label={isMediaReport ? 'View Report' : 'View Result'} placement='top'>
        <Pressable
          ml={isFirstAction ? 0 : 2}
          onPress={() => {
            configureReportResult(report, reportType, key);
          }}
        >
          {isMediaReport ? (
            <PDFIcon key={'pdf'} />
          ) : (
            <ResultIcon key={'result'} />
          )}
        </Pressable>
      </Tooltip>
    );
  };

  const onDraftOrderClose = () => {
    setPendingOrders((prev) => ({...prev, showDraftOrders: false}));
  }

  useEffect(fetchOrders, [personData?.patientId, personData?.patientUuid]);

  const onEventReceived = useCallback(
    (data) => {
      fetchOrders();
    },
    [personData?.patientId, personData?.patientUuid]
  );

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(SUPPORTED_EVENT_CODE.ORDER_CREATED, onEventReceived);
    eventBus.addEventListener(SUPPORTED_EVENT_CODE.REPORT_MARKED_AS_REVIEWED, onEventReceived);
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, []);

  const height = window.innerHeight - 124;

  const getReportName = (report: IReportAndResourceMap) => {
    const name = `${report.resourceCode && report.resourceCode !== report.display ? `${report.resourceCode} ` : ``}${report.display}`
    if (ehrConfig.isElation && name?.length === 0) {
      return report.resources?.[0].category?.[0]?.text + ' ' + getDateStrFromFormatWithTimezone(
        report.effectiveDateTime || '',
        getCurrentTimeZone(),
        DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT
      )
    }
    return name
  }

  const onCloseOfReportResultView = () => {
    setPendingOrders((prev) => {
      return {
        ...prev,
        showModal: false,
        showImagingResultTable: false,
        showDetail: false,
        selectedOrder: {},
        selectedReport: {
          reportName: '',
          observation: [],
          resources: [],
          isReportReviewedByUser: false,
        },
      };
    });
  };

  const reportResultView = (
    <ReportResultView
      unformmatedContactDetails={props.unformmatedContactData}
      contactData={personData}
      showModal={pendingOrders.showModal}
      reportName={pendingOrders.selectedReport.reportName}
      observations={pendingOrders.selectedReport.observation}
      resources={pendingOrders.selectedReport.resources as DiagnosticReport[]}
      onClose={onCloseOfReportResultView}
      onMarkedAsReviewed={fetchOrders}
      documentTitle={pendingOrders.selectedOrder.documentTitle}
      isImaging={pendingOrders?.selectedOrder?.reportType === ReportCategory.IMAGING}
      locationId={accountLocationUuid}
    />
  );

  return (
    <>
      <VStack>
        {isLabRadEnabled && (<>
          {((pendingOrders.reports?.length == 0 && pendingOrders.radReports.length === 0) || pendingOrders.loading) && (
            <Box p={4}>
              <Text size={'smRegular'} color={Colors.FoldPixel.GRAY400} marginBottom={2}>
                {intl.formatMessage({id: 'newReports'})}
              </Text>
              <View>
                {pendingOrders.loading && (
                  <VStack>
                    <Skeleton.Text my={3} />
                    <Skeleton />
                  </VStack>
                )}
                {!pendingOrders.loading &&
                  pendingOrders.reports?.length == 0 && (
                    <Text size={'smRegular'} color={Colors.FoldPixel.GRAY300}>
                      {intl.formatMessage({id: 'noDiagnosticReport'})}
                    </Text>
                  )}
              </View>
            </Box>
          )}
          {!pendingOrders.loading && pendingOrders.labReports.length > 0 && (
            <Box p={4}>
              <Text my={2} fontSize={16} fontWeight={700}>
                {intl.formatMessage({id: canShowMarkAsReview ? 'newLabReports' : 'labReports'})}
              </Text>
              {(pendingOrders.labReports || []).map((report, index) => {
                const key = `${report.code}_${index}`;
                return (
                  <HStack flex={1} mt={4} key={key}>
                    <VStack flex={6}>
                      <Text
                        fontSize={14}
                        fontWeight={400}
                        color={'#101828'}
                      >
                        {getReportName(report)}
                      </Text>
                      {!!report.effectiveDateTime && (
                      <HStack flex={1} space={1}>
                        <Text color={Colors.Custom.Gray400} fontSize={'12'}>
                          {`Issued on: ${getDateStrFromFormatWithTimezone(
                            report.effectiveDateTime || '',
                            getCurrentTimeZone(),
                            DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT
                          )}`}
                        </Text>
                      </HStack>
                      )}
                      {isAllReportViewedBy(report) && (
                        <>
                          <HStack flexWrap={'wrap'}>
                            <Text color={Colors.Custom.Gray400} fontSize={'12'} flexWrap={'wrap'}>
                              {`Reviewed By: ${getReviewedByReportDetails(report)?.reviewedByUserName || ''} on ${getDateStrFromFormat(getReviewedByReportDetails(report)?.reviewedDate || new Date(), DATE_FORMATS.CONVERSATION_DATE_PICKER)}`}
                            </Text>
                          </HStack>
                        </>
                      )}
                    </VStack>
                    <HStack alignItems={'baseline'} flex={2} justifyContent={'flex-end'} space={1} marginTop={1}>
                      {renderReportButton(report, key)}
                    </HStack>
                  </HStack>
                );
              })}
            </Box>
          )}
          {pendingOrders.labReports.length > 0 && pendingOrders.radReports.length > 0 && <Divider />}
          {!pendingOrders.loading && pendingOrders.radReports.length > 0 && (
            <Box p={4}>
              <Text my={2} size={'mdNormal'} color={Colors.FoldPixel.GRAY300}>
                {intl.formatMessage({id: canShowMarkAsReview ? 'newRadReports' : 'radReports'})}
              </Text>
              {(pendingOrders.radReports || []).map((report, index) => {
                const key = `${report.code}_${index}`;
                return (
                  <HStack flex={1} mt={4} key={key}>
                    <VStack flex={6}>
                      <Text
                        size={'smRegular'}
                        color= {Colors.FoldPixel.GRAY400}
                      >
                        {`${
                          report.resourceCode
                            ? `${report.resourceCode}`
                            : ``
                        }${!!report.resourceCode ? ` ${report.display}` : report.display}`}
                      </Text>
                      {!!report.effectiveDateTime && (
                      <HStack flex={1} space={1}>
                        <Text color={Colors.Custom.Gray400} fontSize={'12'}>
                          {`Issued on: ${getDateStrFromFormatWithTimezone(
                            report.effectiveDateTime || '',
                            getCurrentTimeZone(),
                            DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT
                          )}`}
                        </Text>
                      </HStack>
                      )}
                      {isAllReportViewedBy(report) && (
                        <>
                          <HStack flexWrap={'wrap'}>
                            <Text color={Colors.Custom.Gray400} fontSize={'12'} flexWrap={'wrap'}>
                              {`Reviewed By: ${getReviewedByReportDetails(report)?.reviewedByUserName || ''} on ${getDateStrFromFormat(getReviewedByReportDetails(report)?.reviewedDate || new Date(), DATE_FORMATS.CONVERSATION_DATE_PICKER)}`}
                            </Text>
                          </HStack>
                        </>
                      )}
                    </VStack>
                    <HStack alignItems={'baseline'} flex={2} justifyContent={'flex-end'} space={1} marginTop={1}>
                      {renderReportButton(report, key, true)}
                    </HStack>
                  </HStack>
                );
              })}
            </Box>
          )}
          {pendingReportsEnabled && (<View>
          <Divider />
          <Box p={4}>
            <Text my={2} fontSize={16} fontWeight={700}>
              {intl.formatMessage({id: 'pendingOrders'})}
            </Text>
            <View>
              {pendingOrders.loading && (
                <VStack>
                  <Skeleton.Text my={3} />
                  <Skeleton />
                </VStack>
              )}
              {!pendingOrders.loading && pendingOrders.activeOrders?.length == 0 && (
                <Text color="gray.300">
                  {intl.formatMessage({id: 'noPendingOrders'})}
                </Text>
              )}
              {!pendingOrders.loading && pendingOrders.activeOrders?.length > 0 && (
                <ScrollView>
                  {pendingOrders.activeOrders.map((order, index) => {
                    // return order.action?.map((action) => {
                      return (
                        <HStack key={`${order.id}_${index}`} alignItems="flex-start" my={1}>
                          <VStack py={1} flex={9}>
                            <Text>
                              {getOrderName(order)}
                            </Text>
                            <Text color={Colors.Custom.Gray400} fontSize="12">
                              {getOrderSubtitle(order)}
                            </Text>
                          </VStack>
                          <HStack flex={1} justifyContent={'flex-end'} space={1} alignItems={'baseline'} marginTop={1}>
                            {getInfoButton({ order: order })}
                          </HStack>
                        </HStack>
                      );
                    // });
                  })}
                </ScrollView>
              )}
              {!pendingOrders.loading && pendingOrders.draftOrders?.length > 0 && (
                <HStack marginBottom={2} marginTop={2} >
                  <CustomButton
                    onPress={() => {
                      setPendingOrders((prev) => ({
                        ...prev,
                        showDraftOrders: true,
                      }));
                    }}
                    title={'View Draft Orders'}
                  />
                  <Spacer />
              </HStack>
              )}
            </View>
          </Box>
          </View>
          )}
        </>)}
        {isLabRadEnabled && isMedEnabled && (<Divider />)}
        {isMedEnabled && (
          <Box p={4}>
            <Text my={2} fontSize={16} fontWeight={700}>
              {intl.formatMessage({id: 'recentErx'})}
            </Text>
            <View>
              {(pendingOrders.loading || pendingOrders.userAPILoading) && (
                <VStack>
                  <Skeleton.Text my={3} />
                  <Skeleton />
                </VStack>
              )}
              {!(pendingOrders.loading || pendingOrders.userAPILoading) && pendingOrders.medications?.length == 0 && (
                <Text color="gray.300">
                  {intl.formatMessage({id: 'noRecentErx'})}
                </Text>
              )}
              {!(pendingOrders.loading || pendingOrders.userAPILoading) && pendingOrders.medications?.length > 0 && (
                <ScrollView>
                  {pendingOrders.medications.map((order) => {
                    return (
                      <HStack key={order.id}>
                        <VStack flex={9} my={1}>
                          <Text>
                            {getMedicationOrderName(order)}
                          </Text>
                          <Text color={Colors.Custom.Gray400} fontSize="12">
                            {getMedicationSubTitle(order, true)}
                          </Text>
                        </VStack>
                        <HStack flex={1} justifyContent={'flex-end'} space={1}  alignItems={'baseline'} marginTop={1}>
                          {isPendingMedicationOrder(order as MedicationRequest) && getEditButton(order as MedicationRequest)}
                          {getInfoButton({ prescription: order })}
                        </HStack>
                      </HStack>
                    );
                  })}
                </ScrollView>
              )}
            </View>
          </Box>
        )}
      </VStack>
      {pendingOrders.showModal && (
        reportResultView
      )}
      {pendingOrders.showDetail && (
        <OrderDetailsDrawer
          isVisible={pendingOrders.showDetail}
          report={pendingOrders.selectedOrder.report}
          documentTitle={pendingOrders.selectedOrder.documentTitle}
          order={pendingOrders.selectedOrder.order}
          action={pendingOrders.selectedOrder.action}
          prescription={pendingOrders.selectedOrder.prescription}
          onClose={() => {
            onCloseOfReportResultView();
          }}
          reportResultViewLoading={pendingOrders?.reportResultViewLoading}
          reportType={pendingOrders.selectedOrder.reportType}
          showReportResultView={pendingOrders.showImagingResultTable}
          reportResultView={reportResultView}
          selectedReportResources={pendingOrders.selectedReport?.resources as DiagnosticReport[]}
          unformmatedContactDetails={props.unformmatedContactData}
          contactData={personData}
          onCommunicationActionPerformed={(actionCode: string) => {
            setPendingOrders((prev) => ({
              ...prev,
              actionCode: actionCode,
              showDetail: false,
            }))
          }}
        />
      )}
      {pendingOrders.showDraftOrders && (
       <Drawer
        headerStyle={{borderWidth: 0, marginBottom: 0}}
        destroyOnClose
        placement="right"
        onClose={onDraftOrderClose}
        visible={pendingOrders.showDraftOrders}
        closable={false}
        width={'40%'}
        title={
          <>
            <ModalActionTitle
              title={'Draft Orders'}
              titleColor={''}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: 'close',
                  size: 'sm',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: onDraftOrderClose,
                },
              ]}
            />
          </>
        }
      >
        <ScrollView height={height}>
          <Text marginY={2} color={'gray.500'}>These orders are not submitted to the facility yet.</Text>
          {pendingOrders.draftOrders.map((order, index) => {
            // return order.action?.map((action) => {
              return (
                <HStack my={1}>
                  <VStack py={1} flex={9}>
                    <Text key={`${order.id}_${index}`}>
                      {getOrderName(order)}
                    </Text>
                    <Text color={Colors.Custom.Gray400} fontSize="12">
                      {getOrderSubtitle(order)}
                    </Text>
                  </VStack>
                  <HStack flex={1} justifyContent={'flex-end'} space={1} alignItems={'baseline'} marginTop={1}>
                    {NEW_HG_FLOW && isPendingDraftOrder(order as RequestGroup) && getEditButton(order as RequestGroup)}
                    {getInfoButton({ order: order })}
                  </HStack>
                </HStack>
              );
            // });
          })}
        </ScrollView>
      </Drawer>
      )}
      {!pendingOrders.loading && pendingOrders.showAddNewOrderModal && pendingOrders.selectedOrder.order && (
        <AddOrUpdateOrders
          isVisible={pendingOrders.showAddNewOrderModal}
          isExternalOrder={isOrderAddedFromFold(pendingOrders.selectedOrder.order)}
          orderType={getOrderTypeFromOrder(pendingOrders.selectedOrder.order)}
          patientContactId={props.personData?.id || ''}
          documentReferenceId={pendingOrders.selectedOrder.noteId}
          unFormattedContactData={props.unformmatedContactData}
          personData={props.personData}
          selectedOrder={pendingOrders.selectedOrder.order}
          onClose={() => {
            fetchOrders();
            setPendingOrders((prev) => ({...prev, showAddNewOrderModal: false, showDraftOrders: false, selectedOrder: {}}));
          }}
        />
      )}
      {pendingOrders.actionCode ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
        <EmailDrawerCommon
          contactData={personData}
          category={{
            code: 'GENERAL',
            name: '',
          }}
          onMailCompleteAction={() => {
            onCloseOfReportResultView();
            setPendingOrders((prev) => {
              return {
                ...prev,
                actionCode: undefined,
              };
            });
          }}
        />
      )}

      {pendingOrders.actionCode ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
        <CreateSmsConversationDrawer
          isDrawerVisible={true}
          selectedInbox={{} as any}
          onCreateSmsConversationActionPerformed={(
            actionCode: string,
            actionData: any
          ) => {
            onCloseOfReportResultView();
            setPendingOrders((prev) => {
              return {
                ...prev,
                actionCode: undefined,
              };
            });
          }}
          selectedPatient={props.unformmatedContactData}
        />
      )}

      {pendingOrders.actionCode ===
        RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW && (
        <MeetingView
          personData={formattedContactData}
          onPersonActionPerformed={() => {
            onCloseOfReportResultView();
            setPendingOrders((prev) => {
              return {
                ...prev,
                actionCode: undefined,
              };
            });
          }}
        />
      )}

      {pendingOrders.actionCode ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_CHAT_DRAWER && (
        <InstantChat
          contactUuid={personData?.contactUUID}
          isDrawerVisible={true}
          contactData={personData}
          onActionPerformed={()=> {
            onCloseOfReportResultView();
            setPendingOrders((prev) => {
              return {
                ...prev,
                actionCode: undefined,
              };
            });
          }}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  icon: {
    fontSize: 16,
    color: Colors.Custom.Gray500,
    padding: 0,
  },
  iconBtnView: {
    width: 20,
    height: 20,
  }
})