import React, {useContext, useEffect, useState, useMemo} from 'react';
import Stack from '../../../../common/LayoutComponents/Stack';
import {forEachExtensiveFormComponent} from '../../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {CONTAINER_COMPONENTS} from '../CarePlanConstants';
import {FormComponents} from '../../../../PublicPages/PublicForm/PublicFormHelper';
import {isEqual} from 'lodash';
import {View, Text, StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';
import {getEHRCapability, hideGenderFieldsForPrintPreview, numericStringMask} from '../../../../../utils/commonUtils';
import {DATE_FORMATS, MLOV_CATEGORY} from '../../../../../constants';
import {getDateStrFromFormat} from '../../../../../utils/DateUtils';
import {getIconElementName} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Rating/Helper';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { GOAL_ENTITY_TYPE_CODES } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Goals/AddOrUpdateGoals/GoalConstants';
import { IGoal } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Goals/AddOrUpdateGoals/interface';
import { getGoalTargetText } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Goals/AddOrUpdateGoals/GoalUtils';
import { IVitalData } from '../../../../../Interfaces';
import { FHIR_RESOURCE } from '../../../../../constants/FhirConstant';
import { IIntervention } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/interfaces';
import { InterventionStatus, TaskStatus } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/AddOrUpdateIntervention/InterventionConstants';
import { getDueDateInDays, getPriorityIcon } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/AddOrUpdateIntervention/AddOrUpdateInterventionHelper';
import { ICarePlanDetail } from '../interfaces';
import { IMedicationStatement } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Medications/interfaces';
import { getMedicationStatusTextForAudit, getStartAndEndDateText } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CareplanMedications/MedicationUtils';
import { MedicationStatus } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CareplanMedications/interface';
import { NoCarePlan } from '../components/NoCarePlan';
import { Spinner } from 'native-base';
import { getDropdownSelectedValues } from './CarePlanDiffUtils';
import { EMPTY_VALUE, renderAllergyDiff, renderBarrierDiff, renderCellComponent, renderConditionDiff, renderCustomSurveyDiff, renderEmergencyContactDiff, renderFHXDiff, renderImageComponent, renderImmunizationDiff, renderInterventionComponent, renderMedicationDiff, renderPMHXDiff, renderPatientCommunicationDiff, renderPatientDemographicsDiff, renderRemovedAndNewAdded, renderSurgicalHXDiff, renderTextComponent, renderVitalDiff } from './CarePlanDiffUIUtils';
import { CapabilityResource } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { getEhrConfig } from '../../../../../utils/capabilityUtils';
import useEHRCapabilities from '../../../../../screens/BusinessStudio/useEHRCapabilities';

interface ICarePlanDiffProps {
  oldData: any[];
  newData: any[];
  components: any[];
  carePlanDetails?: ICarePlanDetail;
  locationId: string | undefined;
}

const CarePlanDiff = (props: ICarePlanDiffProps) => {
  // Constants
  const { oldData, newData, components, carePlanDetails, locationId } = props;
  const phoneNumberMask = `(###) ###-####`;
  const contextData = useContext(CommonDataContext);
  const ehrCapabilities = useEHRCapabilities({locationId: locationId});
  const allowedVitalList = getEHRCapability(ehrCapabilities, [CapabilityResource.observation])?.abilities?.allowedVitalList || [];
  const patientCapabilities = getEHRCapability(ehrCapabilities, [CapabilityResource.patient]);
  const hideGenderFields = hideGenderFieldsForPrintPreview(contextData.userSettings);
  const ehrConfig = getEhrConfig(locationId, '');
  const personGender = getMlovListFromCategory(
    contextData.MLOV,
    MLOV_CATEGORY.PERSON_GENDER
  )
  const goalEntityTypeList = getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV || {},
    MLOV_CATEGORY.GOAL_TARGET_ENTITY_TYPE
  ) || [];

  const durationUnits = getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV || {},
    MLOV_CATEGORY.CARE_PROGRAM_DURATION
  ) || [];

  const GOAL_ENTITY_TYPE_IDS = {
    VITAL: getMlovIdFromCode(goalEntityTypeList, GOAL_ENTITY_TYPE_CODES.VITAL),
    ACTIVITY: getMlovIdFromCode(goalEntityTypeList, GOAL_ENTITY_TYPE_CODES.ACTIVITY),
    LAB_ANALYTE: getMlovIdFromCode(goalEntityTypeList, GOAL_ENTITY_TYPE_CODES.LAB_ANALYTE),
    SOCIAL: getMlovIdFromCode(goalEntityTypeList, GOAL_ENTITY_TYPE_CODES.SOCIAL),
    FORM: getMlovIdFromCode(goalEntityTypeList, GOAL_ENTITY_TYPE_CODES.FORM),
    OTHER: getMlovIdFromCode(goalEntityTypeList, GOAL_ENTITY_TYPE_CODES.OTHER),
  }

  // States
  const [componentState, setComponentState] = useState<{
    flatOldComponent: {[index: string]: any};
    flatNewComponent: {[index: string]: any};
    filteredComponents: any[];
    dataProcessing: boolean;
  }>({
    flatOldComponent: {},
    flatNewComponent: {},
    filteredComponents: [],
    dataProcessing: true
  });

  // Lifecycle methods
  useEffect(() => {
    setComponentState((prev) => {
      return {
        ...prev,
        dataProcessing: true
      };
    });
    const flatOldData: {[index: string]: any} = {};
    const flatNewData: {[index: string]: any} = {};
    if (oldData) {
      forEachExtensiveFormComponent(oldData, (item) => {
        if (item.key) {
          flatOldData[item.key] = item;
        }
      });
    }
    forEachExtensiveFormComponent(newData, (item) => {
      if (item.key) {
        flatNewData[item.key] = item;
      }
    });
    const filteredComponents = getFilteredComponents(components, flatOldData, flatNewData);
    setComponentState((prev) => {
      return {
        ...prev,
        flatOldComponent: flatOldData,
        flatNewComponent: flatNewData,
        filteredComponents: filteredComponents,
      };
    });
    setTimeout(() => {
      setComponentState((prev) => {
        return {
          ...prev,
          dataProcessing: false
        };
      });
    }, 500)
  }, [oldData, newData]);

  // Other Methods
  const checkIfDataChanged = (component: any, oldValue: any, newValue: any) => {
    switch (component.type) {
      case FormComponents.INTERVENTION:
        const oldInterventionList: IIntervention[] = (oldValue?.selectedValue?.interventions || [])
          .filter((item: any) => !item.isDeleted);
        const newInterventionList: IIntervention[] = (newValue?.selectedValue?.interventions || [])
          .filter((item: any) => !item.isDeleted);
          const mapFn = (item: IIntervention) => {
            return {
              id: item.id,
              title: item.title
            }
          }
        const oldDataKeyForComparison = oldInterventionList.map(mapFn);
        const newDataKeyForComparison = newInterventionList.map(mapFn);
        return !(isEqual(oldDataKeyForComparison, newDataKeyForComparison) || (!oldValue?.selectedValue && !newValue?.selectedValue));

      case FormComponents.EMERGENCY_CONTACT:
        if (!oldValue || !oldValue.selectedValue || !Object.keys(oldValue.selectedValue).length) {
          oldValue = {selectedValue: {
            email: "",
            lastName: "",
            firstName: "",
            phoneNumber: ""
          }}
        }
        if (!newValue || !newValue.selectedValue || !Object.keys(newValue.selectedValue).length) {
          newValue = {selectedValue: {
            email: "",
            lastName: "",
            firstName: "",
            phoneNumber: ""
          }}
        }
        return !(isEqual(oldValue?.selectedValue, newValue?.selectedValue) || (!oldValue?.selectedValue && !newValue?.selectedValue))

      case FormComponents.VITALS:
        return !(isEqual(oldValue?.selectedValue?.observations || [], newValue?.selectedValue?.observations || []) || (!oldValue?.selectedValue && !newValue?.selectedValue))
      case FormComponents.CHIEF_COMPLAINT:
        return !(isEqual(oldValue?.selectedValue?.chiefComplaint?.displayName || '', newValue?.selectedValue?.chiefComplaint?.displayName || '') || (!oldValue?.selectedValue && !newValue?.selectedValue))
      default:
        return !(isEqual(oldValue?.selectedValue, newValue?.selectedValue) || (!oldValue?.selectedValue && !newValue?.selectedValue))
    }
  }

  const getFilteredComponents = (
    components: any[],
    flatOldData: {[index: string]: any},
    flatNewData: {[index: string]: any},
  ): any[] => {
    if (!components || components.length === 0) {
      return [];
    }
    components.forEach((component: any) => {
      const {key, components: innerComponents, columns} = component;
      const oldValue = flatOldData[key];
      const newValue = flatNewData[key];
      if (innerComponents || columns) {
        const innerHidden = getFilteredComponents(innerComponents || columns, flatOldData, flatNewData).every((item: any) => !item.isDataChanged);
        component.isDataChanged = !innerHidden;
      } else if (key && !checkIfDataChanged(component, oldValue, newValue)) {
        component.isDataChanged = false;
      } else {
        component.isDataChanged = true;
      }
    });
    return components;
  };

  const isNoDataForPreview = () => {
    return !componentState.filteredComponents.some((item) => item.isDataChanged);
  }

  const renderComponents = (components: any[], isParent: boolean): JSX.Element => {
    return (
      <Stack direction="column">
        {components.map((component) => {
          if (!component.isDataChanged || component.hidden) {
            return null;
          }
          if (component.columns && component.columns.length > 0) {
            component.columns.map((column: any) => {
              return (
                <Stack direction="column" space={16}>
                  {renderComponents(column.components, false)}
                </Stack>
              );
            });
          }
          if (component.components && component.components.length > 0) {
            const isContainer =
              component.type && CONTAINER_COMPONENTS.includes(component.type);

            const containerStyle = isContainer ? styles.containerStyle2 : {};
            // apply style if isContainer is true

            return (
              <Stack direction="column" style={containerStyle}>
                <Text style={styles.containerLabel}>{component.label}</Text>
                <View style={styles.separator} />
                <Stack space={16} direction="column" style={styles.commonStyle}>
                  {renderComponents(component.components, false)}
                </Stack>
              </Stack>
            );
          }
          const oldValue = componentState.flatOldComponent[component.key];
          const newValue = componentState.flatNewComponent[component.key];
          return getDiffDisplay(component, oldValue, newValue, isParent);
        })}
      </Stack>
    );
  };

  const getVitalList = (): IVitalData[] => {
    const vitals = ehrCapabilities?.find((item: any) => item.resourceName === FHIR_RESOURCE.OBSERVATION)?.abilities?.allowedVitalList || [];
    return vitals.filter((item: IVitalData) => !item.isHidden);
  };

  const renderGoal = (goal: IGoal) => {
    return (
      <Stack direction='column' space={4} style={styles.goalContainer}>
        <Text style={styles.goalTitle}>{goal.title}</Text>
        <Stack direction='column'>
          {goal.goalTargets.length > 0 &&
            goal.goalTargets.map((goalTarget) => {
              const finalText = getGoalTargetText(goalTarget, goal, {
                GOAL_ENTITY_TYPE_IDS,
                vitals: getVitalList(),
                durationUnits: durationUnits,
              });
              if (!finalText) {
                return <></>;
              }
              return <Text style={styles.goalTargetText}>
                {finalText}
              </Text>
            })}
        </Stack>
      </Stack>
    );
  }

  const renderInterventionSubtitle = (item: IIntervention) => {
    return null;
    /*
    KEPT FOR FUTURE REFERENCE

    const intervention = carePlanDetails?.interventions?.find((item) => item.id === item.id);
    item.status = item.status || intervention?.status;
    item.startDateTime = carePlanDetails?.startDateTime;
    return (
      <Stack direction="row">
        {item.status && (
          <Stack
            direction="row"
            space={8}
            style={ {alignItems: 'center'}}
          >
            {item.metadata?.priority &&
              getPriorityIcon(item.metadata.priority)}
              <View
                style={ [
                  {
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 7,
                    paddingRight: 7,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 30,
                  },
                  {backgroundColor: item.status === InterventionStatus.TO_DO
                    ? Colors.Custom.PendingTaskBackgroundColor
                    : Colors.Custom.CompletedTaskBackgroundColor + '40'},
                ]}
              >
                <Text style={ [{fontSize: 12}, {color: item.status === InterventionStatus.TO_DO
                  ? Colors.Custom.PendingTaskFontColor
                  : Colors.Custom.CompletedTaskFontColor}]}>{item.status === InterventionStatus.TO_DO ||
                    item.status === InterventionStatus.IN_PROGRESS
                      ? TaskStatus.Pending
                      : item.status === InterventionStatus.FAILED
                      ? TaskStatus.Missed
                      : TaskStatus.Completed}</Text>
              </View>
            {item.startDateTime &&
              item.status !== InterventionStatus.COMPLETED && (
                <Text
                  style={ {
                    color: Colors.Custom.AppointmentStatusPending,
                    fontSize: 12,
                  }}
                >
                  • {getDueDateInDays(item.startDateTime, item)}
                </Text>
              )}
          </Stack>
        )}
      </Stack>
    );
    */
  }

  const renderIntervention = (item: IIntervention) => {
    const intervention = carePlanDetails?.interventions?.find((item) => item.id === item.id);
    item.status = item.status || intervention?.status;
    item.workflowStatus = item.workflowStatus || intervention?.workflowStatus;
    item.statusId = item.statusId || intervention?.statusId;
    item.startDateTime = intervention?.startDateTime || carePlanDetails?.startDateTime;
    return (
      <Stack direction="column" space={8} style={styles.interventionContainer}>
        <Text>{item.title}</Text>
        {renderInterventionSubtitle(item)}
      </Stack>
    );
  }

  const getDiffDisplay = (
    component: any,
    oldValue?: any,
    newValue?: any,
    isTopLevelComponent?: boolean,
  ): JSX.Element | null => {
    let finalView = null;
    if (!checkIfDataChanged(component, oldValue, newValue)) {
      return finalView;
    }
    switch (component.type) {
      case FormComponents.TEXTAREA:
      case 'textarea':
      case 'textfield':
      case FormComponents.TEXTFIELD:
      case FormComponents.EMAIL:
      case FormComponents.ASSESSMENT:
      case FormComponents.PLAN:
      case FormComponents.OBJECTIVE_FINDINGS:
      case FormComponents.SUBJECTIVE_COMPLAINT:
      case FormComponents.NUMBER:
      case FormComponents.CURRENCY:
        const oldStr = `${oldValue?.selectedValue || EMPTY_VALUE}`;
        const newStr = `${newValue?.selectedValue || EMPTY_VALUE}`;
        finalView = renderTextComponent(component.label, oldStr, newStr, isTopLevelComponent);
        break;

      case FormComponents.PHONE_NUMBER:
        const oldPNStr = oldValue?.selectedValue
          ? numericStringMask(
              `${oldValue?.selectedValue || EMPTY_VALUE}`,
              phoneNumberMask
            )
          : EMPTY_VALUE;
        const newPNStr = newValue?.selectedValue
          ? numericStringMask(
              `${newValue?.selectedValue || EMPTY_VALUE}`,
              phoneNumberMask
            )
          : EMPTY_VALUE;
        finalView = renderTextComponent(component.label, oldPNStr, newPNStr, isTopLevelComponent);
        break;

      case FormComponents.CHIEF_COMPLAINT:
        const oldCCStr = `${
          oldValue?.selectedValue?.chiefComplaint?.displayName || EMPTY_VALUE
        }`;
        const newCCStr = `${
          newValue?.selectedValue?.chiefComplaint?.displayName || EMPTY_VALUE
        }`;
        finalView = renderTextComponent(component.label, oldCCStr, newCCStr, isTopLevelComponent);
        break;

      case FormComponents.CHECKBOX:
        const oldCBStr = oldValue?.selectedValue !== undefined ? `${oldValue?.selectedValue ? 'Yes' : 'No'}` : EMPTY_VALUE;
        const newCBStr = newValue?.selectedValue !== undefined ? `${newValue?.selectedValue ? 'Yes' : 'No'}` : EMPTY_VALUE;
        finalView = renderTextComponent(component.label, oldCBStr, newCBStr, isTopLevelComponent);
        break;

      case FormComponents.DATE:
        const oldDateStr = `${
          oldValue?.selectedValue
            ? getDateStrFromFormat(
                oldValue?.selectedValue,
                DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
              )
            : EMPTY_VALUE
        }`;
        const newDateStr = `${
          newValue?.selectedValue
            ? getDateStrFromFormat(
                newValue?.selectedValue,
                DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
              )
            : EMPTY_VALUE
        }`;
        finalView = renderTextComponent(
          component.label,
          oldDateStr,
          newDateStr,
          isTopLevelComponent
        );
        break;

      case FormComponents.RADIO:
      case FormComponents.SELECT:
      case FormComponents.SELECT_BOXES:
      case FormComponents.NOTE_SUB_STATUS:
      case FormComponents.NOTE_STATUS:
      case 'selectboxes':
        const values = (component?.values ?? component?.data?.values) || [];
        const oldSelectStr = getDropdownSelectedValues(
          oldValue?.selectedValue,
          values
        );
        const newSelectStr = getDropdownSelectedValues(
          newValue?.selectedValue,
          values
        );
        finalView = renderTextComponent(
          component.label,
          oldSelectStr,
          newSelectStr,
          isTopLevelComponent
        );
        break;

      case FormComponents.RATING:
        const oldRatingStr = oldValue?.selectedValue
          ? `${oldValue?.selectedValue} ${getIconElementName(
              component.ratingType
            )}`
          : EMPTY_VALUE;
        const newRatingStr = newValue?.selectedValue
          ? `${newValue?.selectedValue} ${getIconElementName(
              component.ratingType
            )}`
          : EMPTY_VALUE;
        finalView = renderTextComponent(
          component.label,
          oldRatingStr,
          newRatingStr,
          isTopLevelComponent
        );
        break;

      case FormComponents.SIGNATURE:
        const oldImageStr = oldValue?.selectedValue || EMPTY_VALUE;
        const newImageStr = newValue?.selectedValue || EMPTY_VALUE;
        finalView = renderImageComponent(
          component.label,
          oldImageStr,
          newImageStr,
          isTopLevelComponent
        );
        break;

      case FormComponents.FILE:
        const oldFileStr = (oldValue?.selectedValue || [])
          .map((file: any) => file.originalName)
          .join('\n');
        const newFileStr = (newValue?.selectedValue || [])
          .map((file: any) => file.originalName)
          .join('\n');
        finalView = renderTextComponent(
          component.label,
          oldFileStr,
          newFileStr,
          isTopLevelComponent
        );
        break;

      case FormComponents.CUSTOM_SURVEY:
        finalView = renderCustomSurveyDiff(component, oldValue, newValue, isTopLevelComponent);
        break;

      case FormComponents.GOALS:
        const oldGoalList: IGoal[] = (oldValue?.selectedValue?.goals || [])
          .filter((item: any) => !item.isDeleted);
        const newGoalList: IGoal[] = (newValue?.selectedValue?.goals || [])
          .filter((item: any) => !item.isDeleted);

        const oldGoals: {title: string, subTitle: string}[] = [];
        const newGoals: {title: string, subTitle: string}[] = [];

        const removedGoalList = oldGoalList.filter(
          (val) => {
            const isRemoved = !newGoalList.some((item) => item.id === val.id);
            if (!isRemoved) {
              const subtitleList: string[] = [];
              val.goalTargets.map((goalTarget) => {
                const finalText = getGoalTargetText(goalTarget, val, {
                  GOAL_ENTITY_TYPE_IDS,
                  vitals: getVitalList(),
                  durationUnits: durationUnits,
                });
                subtitleList.push(finalText);
              });
              oldGoals.push({
                title: val.title,
                subTitle: subtitleList.join('\n'),
              })
            }
            return isRemoved;
          }
        ).map((item) => renderGoal(item));

        const addedGoalList = newGoalList.filter(
          (val) => {
            const isAdded = !oldGoalList.some((item) => item.id === val.id);
            if (!isAdded) {
              const subtitleList: string[] = [];
              val.goalTargets.map((goalTarget) => {
                const finalText = getGoalTargetText(goalTarget, val, {
                  GOAL_ENTITY_TYPE_IDS,
                  vitals: getVitalList(),
                  durationUnits: durationUnits,
                });
                subtitleList.push(finalText);
              });
              const subTitle = subtitleList.join('\n');
              const oldIndex = oldGoals.findIndex((oldData) => oldData.title === val.title && oldData.subTitle === subTitle);
              if (oldIndex === -1) {
                newGoals.push({
                  title: val.title,
                  subTitle,
                });
              } else {
                oldGoals.splice(oldIndex, 1);
              }
            }
            return isAdded;
          }
        ).map((item) => renderGoal(item));

        const goalHasData = removedGoalList.length > 0 || addedGoalList.length > 0 || oldGoals.length > 0;

        if (isTopLevelComponent && goalHasData) {
          finalView = (
            <Stack
              direction="column"
              style={styles.containerStyle}
            >
              <Text style={styles.containerLabel}>
                {component.label}
              </Text>
              <View style={styles.separator} />
              <Stack direction="column" style={styles.commonStyle}>
                {renderRemovedAndNewAdded(removedGoalList, addedGoalList)}
                {oldGoals.map((item, index) => {
                  const newItem = newGoals[index];
                  return newItem ? renderCellComponent(item, newItem) : <></>;
                })}
              </Stack>
            </Stack>
          );
        } else {
          finalView = goalHasData ? (
            <Stack direction="column" space={8}>
              <Text style={[styles.titleStyle, styles.mainSectionTitleStyle]}>{component.label}</Text>
              <View style={styles.separator} />
              {renderRemovedAndNewAdded(removedGoalList, addedGoalList)}
              {oldGoals.map((item, index) => {
                const newItem = newGoals[index];
                return newItem ? renderCellComponent(item, newItem) : <></>;
              })}
            </Stack>
          ) : null;
        }
        break;


      case FormComponents.INTERVENTION:
        const oldInterventionList: IIntervention[] = (oldValue?.selectedValue?.interventions || [])
          .filter((item: any) => !item.isDeleted);
        const newInterventionList: IIntervention[] = (newValue?.selectedValue?.interventions || [])
          .filter((item: any) => !item.isDeleted);

        const oldIntervention: {title: string, status?: string, subTitleView?: JSX.Element}[] = [];
        const newIntervention: {title: string, status?: string, subTitleView?: JSX.Element}[] = [];

        const removedInterventionList = oldInterventionList.filter(
          (val) => {
            const isRemoved = !newInterventionList.some((item) => item.id === val.id);
            if (!isRemoved) {
              oldIntervention.push({
                title: val.title,
                status: val.status?.value,
                // subTitleView: renderInterventionSubtitle(val),
              })
            }
            return isRemoved;
          }
        ).map((item) => renderIntervention(item));

        const addedInterventionList = newInterventionList.filter(
          (val) => {
            const isAdded = !oldInterventionList.some((item) => item.id === val.id);
            if (!isAdded) {
              // add check for metadata fields
              const oldIndex = oldIntervention.findIndex((oldData) => oldData.title === val.title);
              if (oldIndex === -1) {
                newIntervention.push({
                  title: val.title,
                  status: val.status?.value,
                  // subTitleView: renderInterventionSubtitle(val),
                });
              } else {
                oldIntervention.splice(oldIndex, 1);
              }
            }
            return isAdded;
          }
        ).map((item) => renderIntervention(item));

        const interventionHasData = removedInterventionList.length > 0 || addedInterventionList.length > 0 || oldIntervention.length > 0;
        if (isTopLevelComponent && interventionHasData) {
          finalView = (
            <Stack
              direction="column"
              style={styles.containerStyle}
            >
              <Text style={styles.containerLabel}>
                {component.label}
              </Text>
              <View style={styles.separator} />
              <Stack direction="column" style={styles.commonStyle}>
                {renderRemovedAndNewAdded(removedInterventionList, addedInterventionList)}
              <Stack direction="column" space={16}>
                {oldIntervention.map((item, index) => {
                  const newItem = newIntervention[index];
                  return newItem ? renderInterventionComponent(item, newItem) : <></>;
                })}
              </Stack>
              </Stack>
            </Stack>
          );
        } else {
          finalView = interventionHasData ? (
            <Stack direction="column" space={8}>
              <Text style={[styles.titleStyle, styles.mainSectionTitleStyle]}>{component.label}</Text>
              <View style={styles.separator} />
              {renderRemovedAndNewAdded(removedInterventionList, addedInterventionList)}
              <Stack direction="column" space={16}>
                {oldIntervention.map((item, index) => {
                  const newItem = newIntervention[index];
                  return newItem ? renderInterventionComponent(item, newItem) : <></>;
                })}
              </Stack>
            </Stack>
          ) : null;
        }
        break;

      case FormComponents.BARRIERS:
        finalView = renderBarrierDiff(component, oldValue, newValue, isTopLevelComponent);
        break;

      case FormComponents.MEDICATIONS:
        finalView = renderMedicationDiff(component, oldValue, newValue, isTopLevelComponent);
        break;

      case FormComponents.ALLERGIES:
        finalView = renderAllergyDiff(component, oldValue, newValue, isTopLevelComponent);
        break;

      case FormComponents.CONDITIONS:
      case FormComponents.DIAGNOSIS:
        finalView = renderConditionDiff(component, oldValue, newValue, isTopLevelComponent);
        break;

      case FormComponents.IMMUNIZATION:
        finalView = renderImmunizationDiff(component, oldValue, newValue, isTopLevelComponent);
        break;

      case FormComponents.SURGICAL_HISTORY:
        finalView = renderSurgicalHXDiff(component, oldValue, newValue, isTopLevelComponent);
        break;

      case FormComponents.PAST_MEDICAL_HISTORY:
        finalView = renderPMHXDiff(component, oldValue, newValue, isTopLevelComponent);
        break;

      case FormComponents.FAMILY_HISTORY:
      case 'familyHistory':
        finalView = renderFHXDiff(component, oldValue, newValue, isTopLevelComponent);
        break;

      case FormComponents.VITALS:
        finalView = renderVitalDiff(component, oldValue, newValue, isTopLevelComponent, allowedVitalList);
        break;

      case FormComponents.PATIENT_ADDRESS:
      case FormComponents.PATIENT_COMMUNICATION:
        finalView = renderPatientCommunicationDiff(component, oldValue, newValue, isTopLevelComponent, component.type === FormComponents.PATIENT_ADDRESS);
        break;

      case FormComponents.PATIENT_DEMOGRAPHICS:
        finalView = renderPatientDemographicsDiff(component, oldValue, newValue, isTopLevelComponent, {
          hideGenderFields,
          capabilities: patientCapabilities,
          genderList: personGender || [],
        });
        break;

      case FormComponents.EMERGENCY_CONTACT:
        finalView = renderEmergencyContactDiff(component, oldValue, newValue, isTopLevelComponent);
        break;

      case FormComponents.PATIENT_CONSENT:
      case FormComponents.SOCIAL_HISTORY:
      // Non changing components
      case FormComponents.COLUMNS:
      case FormComponents.PANEL:
      case FormComponents.SYMPTOM_MONITORING:
      case FormComponents.MEDICATION_MANAGEMENT:
      case FormComponents.DIET:
      case FormComponents.EXERCISE:
      case FormComponents.HABITS:
      case FormComponents.HOME_MONITORING:
      case FormComponents.LAB_MONITORING:
      case FormComponents.HEALTH_MAINTENANCE:
      case FormComponents.PARAGRAPH:
      case FormComponents.IMAGE:
      case FormComponents.PATIENT_IDENTIFIER:
      default:
        finalView = null;
    }
    return finalView ? <View style={styles.marginBottom12}>{finalView}</View> : null;
  };

  return componentState.dataProcessing ? (
    <Spinner />
  ) :
  isNoDataForPreview() ? (
    <Stack direction="column" style={styles.stackColumn}>
      <NoCarePlan title="No updates were made to care plan" />
    </Stack>
  ) : (
    <Stack direction="column" space={16}>
      {renderComponents(componentState.filteredComponents, true)}
    </Stack>
  );
};

const styles = StyleSheet.create({
  titleStyle: { fontSize: 14, fontWeight: '500', color: Colors.FoldPixel.GRAY200 },
  mainSectionTitleStyle: {color: Colors.FoldPixel.GRAY200, textTransform: 'uppercase', fontSize: 12},
  containerStyle: {
    backgroundColor: Colors.FoldPixel.GRAY50,
    borderWidth: 0.5,
    borderRadius: 12,
    borderColor: Colors.FoldPixel.GRAY150,
  },
  containerStyle2: {
    backgroundColor: Colors.FoldPixel.GRAY50,
    borderWidth: 0.5,
    borderRadius: 12,
    marginBottom: 16,
    borderColor: Colors.FoldPixel.GRAY150,
  },
  containerLabel: {
    color: Colors.FoldPixel.GRAY400,
    fontSize: 12,
    textTransform: 'uppercase',
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  marginBottom12: {
    marginBottom: 12,
  },
  stackColumn: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 200,
  },
  separator: {
    height: 0.5,
    backgroundColor: Colors.FoldPixel.GRAY150,
    width: '100%',
  },
  commonStyle: {
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  goalContainer: {
    flex: 1,
  },
  goalTitle: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.Gray600,
  },
  goalTargetText: {
    fontSize: 12,
    color: Colors.Custom.Gray400,
  },
  interventionContainer: {
    justifyContent: 'center',
  },
  interventionSubtitleContainer: {
    alignItems: 'center',
  },
  interventionStatusContainer: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 7,
    paddingRight: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
  },
  interventionStatusText: {
    fontSize: 12,
  },
  interventionDueDate: {
    color: Colors.Custom.AppointmentStatusPending,
    fontSize: 12,
  },
})

export default CarePlanDiff;
