import {useMemo} from "react";
import {useContactCareProgramContext} from '../../../ContactCareProgram.context';

export const useSelectedStepInCareProgram = () => {
  const {
    state: {contactCareProgramDetails, selectedStepId, groupedSteps},
    accountLocationUuid,
    contactName,
    personData,
  } = useContactCareProgramContext();
  const selectedStep = useMemo(
    () =>
      contactCareProgramDetails?.contactCareProgramSteps?.find(
        (step) => step.id === selectedStepId
      ),
    [contactCareProgramDetails?.contactCareProgramSteps, selectedStepId]
  );

  return {selectedStep, accountLocationUuid, contactName, personData};
};
