import {View, Text, Pressable} from 'react-native';
import React from 'react';
import {Popover} from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import {styles} from '../EmailMessageBox/styles';
import {IEmailMessageActionsProps} from './interfaces';
import {getDisplayText, getSVG} from '../MessageBox/MessageBoxTextIconUtils';
import {EMAIL_MESSAGE_ACTIONS} from '../../EmailDrawerCommonV2/EmailDrawerCommonV2Const';
import {MessageActionID} from '../../MessageAction/MessageActionView';
import {getUserData} from '../../../../utils/commonUtils';
import { getChannelEmailInboxType } from '../../EmailDrawerCommonV2/EmailDrawerCommonV2Utils';
import { EMAIL_PLATFORMS } from '../../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/EmailInboxCreate/EmailInboxConst';
import MenuHorizontalIcon from '../../Svg/MenuHorizontalIcon';
import { testID } from '../../../../testUtils';

const EmailMessageActions = (props: IEmailMessageActionsProps) => {
  const [state, setState] = React.useState({
    isMoreActionOpen: false,
  });

  const currentUserData = getUserData();
  const isSenderLoggedInUser =
    props.messageData.messageData?.senderType?.toLowerCase() === 'user' &&
    (props?.messageData.messageData?.senderUser?.uuid ===
      currentUserData?.uuid ||
      props?.messageData.messageData.senderUser?.id === currentUserData?.id);

  const getMessageActionList = (): MessageActionID[] => {
    let messageActionsList = EMAIL_MESSAGE_ACTIONS;
    if (props.messageData.private) {
      return EMAIL_MESSAGE_ACTIONS.filter(
        (item) =>
          item === MessageActionID.assignType ||
          item === MessageActionID.createTask
      );
    }
    if (
      getChannelEmailInboxType(props?.channelData) === EMAIL_PLATFORMS.GMAIL
    ) {
      messageActionsList = messageActionsList.filter(
        (item) =>
          item !== MessageActionID.replyAll && item !== MessageActionID.forward
      );
    }
    if (isSenderLoggedInUser || !props.isLoggedInUserGroupMember) {
      messageActionsList = messageActionsList.filter(
        (item) => item !== MessageActionID.markAsReadUnread
      );
    }
    return messageActionsList;
  };

  const handleMoreActionOpen = (isMoreActionOpen: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        isMoreActionOpen: isMoreActionOpen,
      };
    });
  };
  return (
    <Popover
      overlayInnerStyle={{padding: 0, borderRadius: 12}}
      overlayStyle={{padding: 0}}
      overlay={{padding: 0}}
      style={{padding: 0, borderRadius: 12}}
      placement={'top'}
      trigger="click"
      open={state.isMoreActionOpen}
      onOpenChange={handleMoreActionOpen}
      content={
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 5,
          }}
        >
          {getMessageActionList().map((item) => {
            return (
              <Pressable
                style={styles.actionItemContainer}
                onPress={() => {
                  handleMoreActionOpen(false);
                  props.onActionPerformed(item);
                }}
              >
                <View
                  style={{
                    flex: 0.2,
                  }}
                >
                  {getSVG(item)}
                </View>
                <View
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    flex: 0.8,
                  }}
                >
                  <Text style={styles.actionLabel}>{getDisplayText(item)}</Text>
                </View>
              </Pressable>
            );
          })}
        </View>
      }
    >
      <Pressable
        onPress={() => {
          handleMoreActionOpen(true);
        }}
      >
        <View {...testID('image')}>
          <MenuHorizontalIcon height={18} width={18}/>
        </View>
      </Pressable>
    </Popover>
  );
};

export default EmailMessageActions;
