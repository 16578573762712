import { Tooltip } from 'antd';
import {Box, Button, Divider, HStack, Pressable, Spacer, Spinner, Stack, Text, View, VStack} from 'native-base';
import React from 'react';
import RenderHTML from 'react-native-render-html';
import TaskPriorityIcon from '../../assets/Icons/TaskPriorityIcon';
import {BLOOD_PRESSURE_LOINC, HEIGHT_LOINC} from '../../constants';
import { TASK_STATUS_CODES} from '../../constants/MlovConst';
import { IVitalData } from '../../Interfaces';
import {Colors} from '../../styles/Colors';
import {getDateStrFromFormat, getDiffInDays, getMomentObj} from '../../utils/DateUtils';
import {cmToFeetAndInches, convertVitalValues, inchesToFeetAndInches, isInchesUnit} from '../../utils/vitalUnitConversions';
import {ITask, ITaskActionParams} from '../common/CareDashboard/CareDashboardInterfaces';
import {EntityType} from './TaskEnum';
import { isAllowToDeleteTask } from './TaskHelper';
import VitalCaptureInput, {
  VitalType,
} from './VitalCaptureInput/VitalCaptureInput';
import AntDesign from 'react-native-vector-icons/AntDesign';
import FeatherIcon from 'react-native-vector-icons/Feather';
import NoteLinkIcon from '../../assets/Icons/NoteLinkIcon';
import { testID } from '../../testUtils/Utils';
import { TestIdentifiers } from '../../testUtils/TestIdentifiers';
import { isWeb } from '../../utils/platformCheckUtils';
import { TaskViewType } from '../TaskModule/TaskInterfaces';
import CarePlanIcon from '../../assets/Icons/CarePlanIcon';
import ArticleIcon from '../../assets/Icons/ArticleIcon';
import { StyleSheet } from 'react-native';

export enum BottomViewAction {
  openForm = 'OPEN_FORM',
  captureVital = 'CAPTURE_VITAL',
  captureLabTest = 'CAPTURE_LAB_TEST',
  openContent = 'OPEN_CONTENT',
  markAsComplete = 'MARK_AS_COMPLETE',
  markAsIncomplete = 'MARK_AS_INCOMPLETE',
  changeAssignee = 'CHANGE_ASSIGNEE',
  assignCareJourney = 'ASSIGN_CARE_JOURNEY',
  deleteTask = 'DELETE_TASK',
  markAsMissed = 'MARK_AS_MISSED',
  updatePriority = 'UPDATE_PRIORITY',
  refreshCount = 'REFRESH_COUNT',
  checkIfAllSubTasksAreCompleted = 'CHECK_SUBTASK_STATUS',
  checkNoteStatus = 'CHECK_NOTE_STATUS',
  checkCarePlanStatus = 'CHECK_CARE_PLAN_STATUS',
  viewTask = 'VIEW_TASK',
}

export enum descriptionDisplayModes {
  SHORT = 'CARD',
  VERBOSE = 'VERBOSE',
}

const descriptionTypes = {
  RICH_TEXT: 'RICH_TEXT',
  TEXT: 'TEXT',
};

const styles = StyleSheet.create({
  formButton: {
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  formButtonTimeline: {
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
  verticalDivider: {
    width: 1,
    height: 18,
  },
  sidecarBackground: {
    backgroundColor: Colors.FoldPixel.GRAY150,
  },
  defaultBackground: {
    backgroundColor: Colors.Custom.Gray300,
  },
  clockIcon: {
    marginRight: 5,
  },
  taskAttachmentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  }
});

export const getDescription = (
  task: ITask,
  patientName: string | undefined,
  displayMode = descriptionDisplayModes.SHORT,
  vitals: IVitalData[],
): JSX.Element => {
  const vitalCapturedData = getVitalCapturedDataForCompletedTask(task, vitals);
  const eventData = getTaskEventData(task);

  const assignedByUserName = task.assignedBy?.name || '';

  let taskDescription = null;

  if (task.description?.trim?.()?.length) {
    const entityType = task.referenceData?.entityType;
    const descriptionType =
      entityType === 'INSTRUCTION'
        ? descriptionTypes.RICH_TEXT
        : descriptionTypes.TEXT;

    if (descriptionType === descriptionTypes.RICH_TEXT) {
      if (displayMode === descriptionDisplayModes.SHORT) {
        taskDescription = (
          <Text style={{color: Colors.Custom.UnReadTost}} marginTop={2}>
            View Instruction
          </Text>
        );
      } else {
        taskDescription = (
          <RenderHTML
            source={{
              html: task.description || '',
            }}
            enableExperimentalMarginCollapsing={true}
            enableExperimentalBRCollapsing={true}
            baseStyle={{
              whiteSpace: 'normal',
              overflow: 'visible',
              width: '100%',
              color: Colors.FoldPixel.GRAY400,
              margin: 0,
              padding: 0,
            }}
          />
        );
      }
    } else {
      taskDescription = (
        <RenderHTML
          source={{
            html: task.description.replace(/<font/g,"<span").replace(/<\/font/g,"</span") || '',
          }}
          enableExperimentalMarginCollapsing={true}
          enableExperimentalBRCollapsing={true}
          baseStyle={{
            whiteSpace: 'normal',
            overflow: 'visible',
            width: '100%',
            color: Colors.FoldPixel.GRAY400,
            margin: 0,
            padding: 0,
          }}
        />
      );
    }
  } else {
    taskDescription = (
      null
    );
  }
  if(!vitalCapturedData && !taskDescription && !eventData && !task.userPool?.name) {
    return <></>;
  }
  return (
    <VStack width={'full'}>
      {vitalCapturedData && <Text color="gray.500">{vitalCapturedData}</Text>} 
      <VStack gap={4} marginBottom={2}>
        <Text fontSize={14} color={Colors.Custom.alertsDescriptionColor} fontWeight={400}>Description</Text>
        {taskDescription}
      </VStack>
      {eventData}
    </VStack>
  );
};

export const getVitalCapturedDataForCompletedTask = (task: ITask, vitalList: IVitalData[]): string => {
  let description = '';
  if (task.isCompleted && task.capturedData && task.capturedData.value) {
    if (task.referenceData?.entity?.loinc === HEIGHT_LOINC) {
      if (isInchesUnit(task.capturedData.valueUnit)) {
        const {feet, inches} = inchesToFeetAndInches(
          parseFloat(task.capturedData.value)
        );
        description += `${feet} ft ${inches} in`;
      } else {
        const {feet, inches} = cmToFeetAndInches(
          parseFloat(task.capturedData.value)
        );
        description += `${feet} ft ${inches} in`;
      }
    } else {
      const vitalConfig = vitalList.find(vital => vital.loinc === task.referenceData?.entity?.loinc);
      const unit = task.capturedData.valueUnit || vitalConfig?.unit;
      if (vitalConfig?.displayUnit && unit && vitalConfig?.displayUnit !== unit) {
        const value = convertVitalValues(task.capturedData.value, unit, vitalConfig?.displayUnit, vitalConfig.allowedDecimalPlaces || 0);
        description += `${value} ${vitalConfig.displayUnit}`;
      } else {
        description += task.capturedData.value;
        if (unit) {
          description += ` ${unit}`;
        }
      }
    }
  }
  return description;
};

const getTaskEventDataForCareJourney = (
  task: ITask,
  patientName: string | undefined
): JSX.Element | undefined => {
  if (task && task.referenceData && task.referenceData.careJourney) {
    let eventName = '';
    const careJourney = task.referenceData.careJourney;
    eventName = careJourney.title ? careJourney.title : '';
    return (
      <>
        {eventName && patientName && (
          <>
            <Text
              fontWeight="600"
              color={Colors.Custom.IATextColor}
              marginTop={2}
            >
              {eventName}
            </Text>

            <Text color="gray.500">
              {' purchased for '}
              <Text fontWeight="600" color={Colors.Custom.IATextColor}>
                {patientName ? patientName : ''}
              </Text>
            </Text>
          </>
        )}
      </>
    );
  }
};

const getTaskEventData = (task: ITask): JSX.Element | undefined => {
  if (
    task.referenceData &&
    task.referenceData.eventData &&
    task.referenceData.eventData.value
  ) {
    const eventData = task.referenceData.eventData;
    const event = eventData.value;
    let unit = '';
    let eventName = '';
    let reportedDate = '';
    if (task.referenceData.eventData.entity) {
      const entity = task.referenceData.eventData.entity;
      if (entity.units && entity.units.length > 0) {
        unit = ' ' + entity.units[0];
      }
      if (entity.name) {
        eventName = entity.name;
      }
      if (eventData.submitDate || eventData.observationDate) {
        reportedDate = getDateStrFromFormat(
          eventData.submitDate || eventData.observationDate,
          'Do MMM YYYY, hh:mm a'
        );
      }
    }
    return (
      <Text color="gray.500" marginTop={2}>
        Reported{' '}
        <Text fontWeight="600" color={Colors.Custom.IATextColor}>
          {event}
          {unit ? ' ' + unit : ''}
        </Text>
        {eventName && (
          <Text>
            {' '}
            for{' '}
            <Text fontWeight="600" color={Colors.Custom.IATextColor}>
              {eventName}
            </Text>
          </Text>
        )}
        {reportedDate ? ' on ' + reportedDate : ''}
      </Text>
    );
  }
};

export const isTaskWithType = (task: ITask, type: EntityType): boolean => {
  return (
    task.referenceData &&
    task.referenceData.entityType &&
    task.referenceData.entityType === type
  );
};

export const getCardTag = (task: ITask): EntityType => {
  if (task.parentId) {
    return EntityType.SUBTASK;
  }
  if (task.isEscalated) {
    return EntityType.TASK;
  }
  if (task.referenceData && task.referenceData.entityType) {
    switch (task.referenceData.entityType) {
      case 'MED':
        return EntityType.MED;
      case 'VITAL':
        return EntityType.VITAL;
      case 'LAB_TEST':
        return EntityType.LAB_TEST;
      case 'FORM':
        return EntityType.FORM;
      case 'EXERCISE':
        return EntityType.EXERCISE;
      case 'CONTENT':
        return EntityType.CONTENT;
      case 'INSTRUCTION':
        return EntityType.INSTRUCTION;
      case 'NUTRITION':
        return EntityType.NUTRITION;
      case 'IMMUNIZATION':
        return EntityType.IMMUNIZATION;
      case 'GOAL':
        return EntityType.GOAL;
      case 'TASK':
        return EntityType.TASK;
      case 'ESCALATION':
        return EntityType.TASK;
    }
  }
  return EntityType.TASK;
};

export const canHideCheckBox = (task: ITask, isWearableEnabled = false) => {
  if (isTaskWithType(task, EntityType.EXERCISE)) {
    return isWearableEnabled;
  }
  return (
    ((isTaskWithType(task, EntityType.FORM) || isTaskWithType(task, EntityType.VITAL)) && !task.isCompleted)
      ? true
      : false
  );
};

export const getBottomView = (
  task: ITask,
  loading: boolean,
  vitals: IVitalData[],
  actionBlock: (
    task: ITask,
    action: BottomViewAction,
    actionData?: any
  ) => void,
  isTimelineView?: boolean,
  useNewUI?: boolean,
  isKanbanView?: boolean,
  contactLocationUuid?: string
): JSX.Element | undefined => {
  if (isTaskWithType(task, EntityType.FORM)) {
    return (
      <View marginY={3} flex={1}>
        {!isKanbanView ? (
          <HStack alignItems={'center'} justifyContent={'space-between'}>           
              <HStack flex={.5} alignItems={'center'}>
                <CarePlanIcon />
                <Text marginLeft={1} marginBottom={2} color={Colors.FoldPixel.GRAY300} fontWeight={400} size={14} flex={1}>{task.title}</Text>
              </HStack>
          <Box
            borderRadius={4}
            borderWidth={1}
            borderColor={Colors.Custom.PrimaryColor200}
            backgroundColor={Colors.Custom.PrimaryColor100}
          >
          <Pressable
            isDisabled={task.isCompleted || false}
            style={isTimelineView ? styles.formButtonTimeline : styles.formButton}
            onPress={() => actionBlock(task, BottomViewAction.openForm)}
          >
            <Text
              fontSize={12}
              fontWeight={isTimelineView ? 700 : 500}
              color={Colors.Custom.PurpleColor}
            >
              {task.isCompleted ? 'Form Submitted' : 'Fill the form'}
            </Text>
          </Pressable>
          </Box>
        </HStack>
        ) : (
          <View
            flexDirection={'row'}
            alignItems={'flex-end'}
            alignSelf={'flex-end'}
          >
            <Box
              borderRadius={4}
              borderWidth={1}
              borderColor={Colors.Custom.PrimaryColor200}
              backgroundColor={Colors.Custom.PrimaryColor100}
            >
              <Pressable
                isDisabled={task.isCompleted || false}
                style={isTimelineView ? styles.formButtonTimeline : styles.formButton}
                onPress={() => actionBlock(task, BottomViewAction.openForm)}
                {...testID(TestIdentifiers.formBtn)}
              >
                <Text
                  fontSize={12}
                  fontWeight={isTimelineView ? 700 : 500}
                  color={Colors.Custom.PurpleColor}
                >
                  {task.isCompleted ? 'Form Submitted' : 'Fill the form'}
                </Text>
              </Pressable>
            </Box>
          </View>
        )}
      </View>
    );
  } else if (isTaskWithType(task, EntityType.VITAL)) {
    const referenceRange = getVitalRangeFromLoinc(
      vitals,
      task.referenceData.entity.loinc
    );
    const defaultRange = {min: 0, max: 400};
    if (referenceRange.length === 0) {
      referenceRange.push(defaultRange);
    }
    let locationId = '';
    if (contactLocationUuid) {
      locationId = contactLocationUuid;
    } else if (task?.taskLocations && task?.taskLocations.length > 0) {
      locationId = task?.taskLocations?.[0]?.locationId;
    }

    const vitalType = getVitalTypeFromTask(task);
    if (vitalType === VitalType.bloodPressure) {
      referenceRange.push(defaultRange);
    }
    const unit =
      task.referenceData.entity.units &&
      task.referenceData.entity.units.length > 0
        ? task.referenceData.entity.units[0]
        : '';
    return (
      <View
        alignSelf={'flex-start'}
        width={'100%'}
        borderTopWidth={useNewUI ? 1 : 0}
        borderColor={useNewUI ? Colors.Custom.Gray200 : undefined}
        pt={useNewUI ? 1 : undefined}
        mt={useNewUI ? 1 : undefined}
        mb={2}
      >
        {task.isCompleted ? (
          <View marginBottom={2}>
            <Text color={Colors.Custom.alertsDescriptionColor}>Vital Value</Text>
            <HStack justifyContent={'flex-start'}>
              <Text marginRight={2} color={Colors.FoldPixel.GRAY400}>{task.referenceData.entity.name}:</Text>
              <HStack> 
                <Text marginRight={1} color={Colors.FoldPixel.GRAY400} >{task.referenceData.value}</Text>
                <Text color={Colors.FoldPixel.GRAY400}>{task.referenceData.unit}</Text>
              </HStack>
            </HStack>
          </View>
        ) : (
          <VitalCaptureInput
            vital={{
              minValue: referenceRange[0].min,
              maxValue: referenceRange[0].max,
            }}
            vital1={
              vitalType === VitalType.bloodPressure
                ? {minValue: referenceRange[1].min, maxValue: referenceRange[1].max}
                : undefined
            }
            loinc={task.referenceData.entity.loinc}
            vitalType={vitalType}
            unit={unit}
            loading={loading}
            onComplete={(data) => {
              actionBlock(task, BottomViewAction.captureVital, data);
            }}
            isTimelineView={isTimelineView}
            isKanbanView={isKanbanView}
            locationId={locationId}
          />
        )}
      </View>
    );
  } else if (isTaskWithType(task, EntityType.CONTENT)) {
    return (
      <View marginTop={2} flex={1}>
        {!isKanbanView ? (
          <HStack justifyContent="space-between" alignItems="center">       
          <>
            <ArticleIcon />
            <Text marginLeft={1} marginBottom={2} color={Colors.FoldPixel.GRAY300} fontWeight={400} size={14} flex={1}>{task.referenceData.entity.title}</Text>
          </>      
        <Button
            borderRadius={4}
            borderWidth={1}
            borderColor={Colors.Custom.PrimaryColor200}
            backgroundColor={Colors.Custom.PrimaryColor100}
            isLoading={loading}
            onPress={() => {
              actionBlock(task, BottomViewAction.openContent);
            }}
            {...testID(TestIdentifiers.seeTheContentBtn)}
          >
            <Text
              fontSize={12}
              fontWeight={isTimelineView ? 700 : 500}
              color={Colors.Custom.PurpleColor}
            >
              {'See the content'}
            </Text>
        </Button>        
        </HStack>
        ) : (
          <View
            flexDirection={'row'}
            alignItems={'flex-end'}
            alignSelf={'flex-end'}
          >
            <Button
              borderRadius={4}
              borderWidth={1}
              borderColor={Colors.Custom.PrimaryColor200}
              backgroundColor={Colors.Custom.PrimaryColor100}
              isLoading={loading}
              onPress={() => {
                actionBlock(task, BottomViewAction.openContent);
              }}
              {...testID(TestIdentifiers.seeContent)}
            >
              <Text
                fontSize={12}
                fontWeight={isTimelineView ? 700 : 500}
                color={Colors.Custom.PurpleColor}
              >
                {'See the content'}
              </Text>
            </Button>
          </View>
        )}
      </View>
    );
  }
  return undefined;
};

export const getVitalRangeFromLoinc = (vitals: IVitalData[], loinc: string): any[] => {
  if (loinc === HEIGHT_LOINC) {
    return [{max: 12, min: 0}];
  }
  const matchedData = vitals.filter((vital) => {
    return vital.loinc === loinc;
  });
  if (
    matchedData.length > 0 &&
    matchedData[0].range &&
    matchedData[0].range.length > 0
  ) {
    return matchedData[0].range;
  }
  return [];
};

export const getVitalTypeFromTask = (task: ITask) => {
  if (task.referenceData?.entity?.loinc === BLOOD_PRESSURE_LOINC) {
    return VitalType.bloodPressure;
  } else if (task.referenceData?.entity?.loinc === HEIGHT_LOINC) {
    return VitalType.heightWithFeetAndInches;
  }
  return VitalType.normal;
};


export const getPriorityIcon = (
  priority?: {
    code: string;
    value: string;
    id?: string;
  },
  renderNotfound?: boolean,
  marginTop = 0
): JSX.Element => {
  if (priority) {
    return (
      <Tooltip title={priority.value + ' Priority'}>
        <HStack mt={marginTop}>
          <TaskPriorityIcon priorityCode={priority.code} />
        </HStack>
      </Tooltip>
    );
  }
  return renderNotfound ? (
    <Text size={'smMedium'} color={Colors.Custom.Gray900}>
      -
    </Text>
  ) : (
    <></>
  );
};

export const getStatusBadge = (
  status?: {
    code: string;
    value: string;
    id?: string;
  },
): JSX.Element => {
  if (status?.value) {
    let statusColor: {text: string, background: string } = {
      text: Colors.Custom.Gray500,
      background: Colors.Custom.Gray50
    };
    switch(status.code) {
      case TASK_STATUS_CODES.ACCEPTED:
        statusColor = {
          text: Colors.Custom.PendingTaskFontColor,
          background: Colors.Custom.PendingTaskBackgroundColor
        };
        break;
      case TASK_STATUS_CODES.COMPLETED:
        statusColor = {
          text: Colors.Custom.CompletedTaskFontColor,
          background: Colors.Custom.CompletedTaskBackgroundColor
        };
        break;
      case TASK_STATUS_CODES.MISSED:
        statusColor = {
          text: Colors.Custom.MissedTaskFontColor,
          background: Colors.Custom.MissedTaskBackgroundColor
        };
        break;
    }
    return (
      <View style={{backgroundColor: statusColor.background, borderRadius: 4, flexShrink: 1, paddingHorizontal: 8}}>
        <Text style={{ color: statusColor.text, textAlign: 'center' }}>{status.value}</Text>
      </View>
    );
  }
  return <></>;
};

export const getMissedTaskDaysMessage = (task: ITask) => {
  const isTaskMissed = task?.status?.code === TASK_STATUS_CODES.MISSED;
  let diffDays = 0;
  let diffDayMessage = '';
  if(isTaskMissed && task?.endDateTime){
    diffDays = getDiffInDays(getMomentObj(task?.endDateTime), getMomentObj(new Date()));
    if (diffDays > 0) {
      diffDayMessage = `${diffDays} ${diffDays === 1 ? 'day' : 'days'}`;
    }
  }
  return diffDayMessage;
}

export const renderResourceMapCountAndDeleteField = (params: ITaskActionParams): JSX.Element => {
  const { task, deleteLoading, handleTaskDelete, showDeleteField, taskViewType, isRenderCompactView } = params;
  const allowToDelete: boolean =  showDeleteField && isAllowToDeleteTask(task);
  const isAttachmentAdded = task?.attachments && task?.attachments?.length > 0 ? true : false;
  const isNoteContextTask = task?.resourceMap?.notes ? true : false;
  const isCarePlanContextTask = !!task?.resourceMap?.carePlan && task?.resourceMap?.carePlan > 0;
  const isTaskMissed = task?.status?.code === TASK_STATUS_CODES.MISSED;
  let diffDayMessage = '';
  if(isTaskMissed && task?.endDateTime){
    diffDayMessage = getMissedTaskDaysMessage(task);
  }
  const isTableView = taskViewType === TaskViewType.list;
  const hasContent = allowToDelete || isAttachmentAdded || isNoteContextTask || (isTaskMissed && !isTableView) || isCarePlanContextTask;
  if (hasContent) {
    return (
      <>
        {!!task?.subTasks?.length && isTableView && (
          <Divider
            orientation="vertical"
            style={StyleSheet.flatten([
              styles.verticalDivider,
              isRenderCompactView ? styles.sidecarBackground : styles.defaultBackground,
              { marginLeft: 2 }
            ])}
          />
        )}
        {(!isAttachmentAdded && !isNoteContextTask && !isTaskMissed) && showDeleteField && (
          <Divider marginY={2}></Divider>
        )}
        <Stack direction='row' flex={isTableView ? undefined : 1} space={1} marginLeft={1} marginY={showDeleteField ? 0 : 1}>
          {isTaskMissed && diffDayMessage && !isTableView && (
            <>
              <FeatherIcon
                name="clock"
                size={isRenderCompactView ? 16 : 18}
                style={styles.clockIcon}
                color={Colors.FoldPixel.GRAY300}
                {...testID(TestIdentifiers.clock)}
              />
              <Text size={'smMedium'} color={isRenderCompactView ? Colors.FoldPixel.GRAY300 : Colors.Custom.overdueTaskIconColor} justifyContent="center">
                {diffDayMessage}
              </Text>
            </>
          )}

          {isAttachmentAdded && (
            <>
              {(isTaskMissed && !isTableView) && (
                <Divider
                orientation="vertical"
                style={StyleSheet.flatten([
                  styles.verticalDivider,
                  isRenderCompactView ? styles.sidecarBackground : styles.defaultBackground,
                ])}
              />
              )}
              <View style={styles.taskAttachmentContainer}>
                <AntDesign
                  size={16}
                  name="paperclip"
                  color={Colors.FoldPixel.GRAY300}
                  key={`attachment_icon_${task.id}`}
                />
                <Text color={Colors.FoldPixel.GRAY300} marginLeft={1}>
                  {`${task?.attachments?.length}`}
                </Text>
              </View>
            </>
          )}

          {isNoteContextTask && (
            <>
              {(isAttachmentAdded || (isTaskMissed && !isTableView)) && (
                <Divider
                  orientation="vertical"
                  style={StyleSheet.flatten([
                    styles.verticalDivider,
                    isRenderCompactView ? styles.sidecarBackground : styles.defaultBackground,
                    { marginHorizontal: 2, marginTop: 2 }
                  ])}
                />
              )}
              {isWeb() ? (
                <Tooltip title="Linked Note">
                  <NoteLinkIcon key="noteIcon" width={isRenderCompactView ? 20 : 16} height={isRenderCompactView ? 20 : 16} />
                </Tooltip>
              ) : (
                <NoteLinkIcon />
              )}
            </>
          )}

          {isCarePlanContextTask && (
            <>
              {(isNoteContextTask || isAttachmentAdded || (isTaskMissed && !isTableView)) && (
                <Divider
                  orientation="vertical"
                  style={StyleSheet.flatten([
                    styles.verticalDivider,
                    isRenderCompactView ? styles.sidecarBackground : styles.defaultBackground,
                    { marginHorizontal: 2, marginTop: 2 }
                  ])}
                />
              )}
              {isWeb() ? (
                <Tooltip title="Linked Care Plan">
                  <CarePlanIcon />
                </Tooltip>
              ) : (
                <CarePlanIcon />
              )}
            </>
          )}

          <Spacer />

          {allowToDelete && (
            <Stack justifyContent="flex-end">
              {deleteLoading ? (
                <Spinner />
              ) : (
                <Pressable alignItems="end" justifyContent="center" onPress={handleTaskDelete} disabled={deleteLoading} {...testID(TestIdentifiers.deleteBtn)}>
                  <FeatherIcon
                    name="trash-2"
                    size={18}
                    color={Colors.Custom.Gray500}
                    onPress={handleTaskDelete}
                  />
                </Pressable>
              )}
            </Stack>
          )}
        </Stack>
        {isRenderCompactView && (
          <Divider
            orientation="vertical"
            style={StyleSheet.flatten([
              styles.verticalDivider,
              isRenderCompactView ? styles.sidecarBackground : styles.defaultBackground,
              { marginRight: 6 }
            ])}
          />
        )}
      </>
    )
  } else {
    return (
      <>
        {!!task?.subTasks?.length && isRenderCompactView && (
          <Divider
          orientation="vertical"
          style={StyleSheet.flatten([
            styles.verticalDivider,
            isRenderCompactView ? styles.sidecarBackground : styles.defaultBackground,
            { marginLeft: 4 }
          ])}
        />
        )}
      </>
    );
  }
}

export const canShowBottomView = (task: ITask) => {
  if (
    isTaskWithType(task, EntityType.FORM) ||
    isTaskWithType(task, EntityType.CONTENT) ||
    isTaskWithType(task, EntityType.VITAL)
  ) {
    return true;
  }
};

export const isTaskTableCheckBoxDisabled = (task: ITask) => {
  if (isTaskWithType(task, EntityType.VITAL) || isTaskWithType(task, EntityType.FORM)) {
    return true;
  }
  return false;
};

export const getTaskTitle = (task: ITask) => {
  if(isTaskWithType(task, EntityType.CONTENT)){
    return task?.patientFacingTitle || task?.title || task?.referenceData?.entity?.title
  }

  return task?.title;
}

export const getSubTaskLength = (task: ITask) => {
  return task?.subTasks?.length || 0;
};
