import React from 'react';
import {View, Text} from 'react-native';
import {Colors} from '../../../../../../styles';
import {StyleSheet} from 'react-native';
import {Skeleton, Stack} from 'native-base';
import {useContentHeaderSubtitle} from './hooks/useContentHeaderSubtitle';
import { IContactCareProgramStep, IFormScore } from '../../../interface';
import { RiskScoreDisplayPopoverListView } from '../../../../Forms/FormBuilderWidget/RiskScoreDisplay/RiskScoreDisplay';
import { Popover, Tag } from 'antd';
import { testID, TestIdentifiers } from '../../../../../../testUtils';
import '../styles.css'; 

interface StepWiseSubtitleViewProps {
  selectedStep: IContactCareProgramStep;
  userMap: Map<string, string>;
  scores: IFormScore[];
}

const FORM_SCORES = 'Form Scores:'
const StepWiseSubtitleView: React.FC<StepWiseSubtitleViewProps> = ({
  selectedStep,
  userMap,
  scores
}) => {
  const {activityLogData, loading: activityLogLoading} =
    useContentHeaderSubtitle({selectedStep, userMap});

  let activityLogCombinedString = activityLogData.join(' • ');
  if (activityLogLoading) {
    return <Skeleton.Text width={100} lines={0.5}></Skeleton.Text>;
  }

  if (!activityLogData || activityLogData.length === 0) {
    return null;
  }

  if (scores.length !== 0) {
    activityLogCombinedString = `${activityLogCombinedString} • ${FORM_SCORES}`;
  }

  return (
    <Stack direction={"row"} space={1} alignItems={"center"}>
      <Text style={styles.subtitle} {...testID(TestIdentifiers.activity)}>
        {activityLogCombinedString}
      </Text>
      {scores.length > 0 && (
        <Popover
          overlayClassName='zero-padding-popover'
          content={<RiskScoreDisplayPopoverListView formScores={scores} />}
          trigger="hover"
          placement="bottom"
        >
          <Tag style={{border: 'none', fontSize: 12, color: Colors.Custom.GRAY350, backgroundColor: Colors.Custom.Gray50, width: 'fit-content'}}>
            {`${scores.length} Score Groups`}
          </Tag>
        </Popover>
      )}
    </Stack>
  );
};

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 12,
    fontWeight: '400',
    color: Colors.Custom.Gray500,
  },
  tag: {
    borderRadius: 4,
    fontSize: '12px',
    backgroundColor: Colors.FoldPixel.GRAY50,
    color: Colors.FoldPixel.GRAY300,
    width: 'fit-content',
    border: 'none',
    padding: '2px 8px'
  }
});

export default StepWiseSubtitleView;
