import {gql} from '@apollo/client';

const GetConversationsByUUID = gql`
  query GetConversationsByUUID(
    $uuid: uuid!
    $loginUserUuid: uuid!
    $unreadMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    conversations(where: {uuid: {_eq: $uuid}}) {
      id
      contactId
      accountId
      uuid
      displayId
      status
      additionalAttributes
      assigneeId
      blockReasonCode
      isActive
      practiceLocationUuid
      unreadMsg(args: {useruuid: $loginUserUuid}, where: $unreadMessagesWhere, limit: 1) {
        id
        messageType
        messageMentions(where: {userId: {_eq: $loginUserUuid}}) {
          id
        }
      }
      unreadMsgCount: unreadMsgNew(
        args: $unreadMessagesWhereCount
      )
      conversationMessages {
        senderType
        senderUser {
          id
          name
        }
        senderContact {
          id
          name
        }
        messageMentions {
          id
          isRead
        }
        senderId
        contentType
        content
        createdAt
        updatedAt
        messageType
        id
        contentAttributes
        messageAttachments {
          id
          fileType
        }
      }
      conversationContact {
        isDeleted
        isActive
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        contactPracticeLocations{
          accountLocation {
            uuid
          }
          practiceLocationUuid
          accountLocation {
            uuid
          }
        }
        isActive
        accountId
        uuid
        source
        phoneNumber
        pubsubToken
        updatedAt
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
            code
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        personContact {
          personContactId
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
          rank
        }
        lastActivityAt
        identifier
      }
      assignedUser {
        id
        uuid
        email
        name
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupType {
          code
          description
          value
        }
        groupMembers {
          id
          groupUserId
          groupUserTypeId
          groupUserType {
            code
            value
          }
          contact {
            isActive
            contactType {
              contactType {
                code
                value
                id
              }
              id
            }
            accountId
            uuid
            source
            phoneNumber
            notes {
              contactId
              content
              userId
              id
            }
            name
            email
            id
            patient {
              patientId
              patientUuid
              annualVisitDate
              isWeeklyCheckin
              id
            }
            person {
              chosenName
              pronounId
              firstName
              birthDate
              id
              bloodGroup {
                value
                code
                id
              }
              gender {
                value
                code
              }
              genderId
              birthSexId
              sexAtBirth {
                value
                code
                id
              }
              contactId
              middleName
              lastName
            }
            createdAt
            personContact {
              personContactId
              value
              id
              personContactType {
                accountId
                value
                id
                categoryId
              }
              typeId
              rank
            }
            lastActivityAt
            identifier
          }
        }
      }
    }
  }
`;

const GetConversations = gql`
  query GetConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [conversations_order_by!]
    $whereCondition: conversations_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    conversations(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      contactId
      accountId
      id
      uuid
      displayId
      inboxId
      status
      additionalAttributes
      assigneeId
      createdAt
      lastActivityAt
      lastSmsAt
      lastMissCallAt
      blockReasonCode
      isActive
      practiceLocationUuid
      conversationInbox {
        id
        name
        channelType
        isDeleted
        channelEmail {
          forwardToEmail
          forwardRouteId
          smtpAddress
        }
        channelTwilioSms {
          phoneNumber
        }
        channelEfax {
          id
          efaxNumber
        }
        inboxMembers {
          user {
            uuid
            id
            name
          }
        }
      }
      unreadMsgCount: unreadMsgNew(
        args: $unreadMessagesWhereCount
      )
      conversationMessages (
        limit: 1,
        order_by: {
          createdAt: desc
        },
        where: $conversationMessagesWhere
      ) {
        senderType
        senderUser {
          id
          name
        }
        senderContact {
          id
          name
        }
        senderId
        contentType
        content
        createdAt
        updatedAt
        private
        messageType
        id
        contentAttributes
        messageAttachments(
          limit: 1,
          order_by: {
            createdAt: desc
          },
        ) {
          id
          activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
      }
      conversationContact {
        isDeleted
        isActive
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        contactPracticeLocations{
          practiceLocationUuid
          accountLocation {
            uuid
          }
        }
        isActive
        accountId
        uuid
        source
        phoneNumber
        pubsubToken
        updatedAt
        name
        email
        id
        additionalAttributes
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
            code
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        personContact {
          personContactId
          rank
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
        }
        contactConsents {
          consentId
        }
        lastActivityAt
        identifier
      }
      assignedUser {
        id
        uuid
        email
        name
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupType {
          code
          description
          value
        }
      }
      contactInbox {
        sourceId
      }
    }
  }
`;


const GetMissCallConversations = gql`
  query GetConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [conversations_order_by!]
    $whereCondition: conversations_bool_exp
    $unreadMessagesWhere: messages_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    conversations(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      contactId
      accountId
      id
      uuid
      displayId
      inboxId
      status
      additionalAttributes
      assigneeId
      createdAt
      lastActivityAt
      lastMissCallAt
      blockReasonCode
      isActive
      practiceLocationUuid
      conversationInbox {
        id
        name
        channelType
        isDeleted
        channelEmail {
          forwardToEmail
          forwardRouteId
          smtpAddress
        }
        channelTwilioSms {
          phoneNumber
        }
        channelEfax {
          id
          efaxNumber
        }
        inboxMembers {
          user {
            uuid
            id
            name
          }
        }
      }
      unreadMsgCount: unreadMsgNew(
        args: $unreadMessagesWhereCount
      )
      conversationMessages(where: $conversationMessagesWhere,limit: 1, order_by: {createdAt: desc}) {
        senderType
        senderId
        contentType
        content
        createdAt
        updatedAt
        messageType
        id
        contentAttributes
        messageAttachments {
          id
          fileType
        }
      }
      conversationContact {
        isDeleted
        isActive
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        contactPracticeLocations{
          practiceLocationUuid
          accountLocation {
            uuid
          }
        }
        additionalAttributes
        isActive
        accountId
        uuid
        source
        phoneNumber
        pubsubToken
        updatedAt
        notes {
          contactId
          content
          userId
          id
        }
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
            code
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        contactConsents {
          contactUuid
          consentId
        }
        personContact {
          personContactId
          rank
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
        }
        lastActivityAt
        identifier
      }
      assignedUser {
        id
        name
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupType {
          code
          description
          value
        }
      }
      contactInbox {
        sourceId
      }
    }
  }
`;


const GetMentionsConversations = gql`
query GetConversations(
    $limit: Int!,
    $offset: Int!,
    $orderByObject: [conversation_mentions_order_by!],
    $whereCondition: conversation_mentions_bool_exp,
  ) {
  conversationMentions(
    limit: $limit
    offset: $offset,
    order_by: $orderByObject,
    where: $whereCondition
  ) {
    id
    isRead
    conversationMessages: message {
      senderType
      senderUser {
        id
        name
      }
      senderId
      contentType
      content
      createdAt
      updatedAt
      messageType
      id
      contentAttributes
      private
      messageAttachments {
        id
        fileType
      }
    }
   	conversation {
      contactId
      accountId
      id
      uuid
      displayId
      inboxId
      status
      additionalAttributes
      assigneeId
      createdAt
      lastActivityAt
      blockReasonCode
      conversationUser {
        email
        name
        id
        uuid
      }
      conversationInbox {
        id
        name
        channelType
        isDeleted
        channelEmail {
          forwardToEmail
          forwardRouteId
          smtpAddress
        }
        channelTwilioSms{
          phoneNumber
        }
        channelEfax {
          id
          efaxNumber
        }
        inboxMembers {
          user {
            uuid
            id
            name
          }
        }
      }
      conversationContact {
        isDeleted
        isActive
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        contactPracticeLocations{
          practiceLocationUuid
          accountLocation {
            uuid
          }
        }
        isActive
        contactProfilePhotos {
          attachmentId
        }
        accountId
        uuid
        source
        phoneNumber
        pubsubToken
        updatedAt
        name
        email
        id
        additionalAttributes
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
            code
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        contactConsents {
          contactUuid
          consentId
        }
        createdAt
        personContact {
          personContactId
          rank
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
        }
        lastActivityAt
        identifier
      }
    assignedUser {
      id
      name
    }
    groupConversation {
      id
      name
      ownerId
      ownerTypeId
      groupType {
        code
        description
        value
      }
      groupMembers_aggregate(
        where: {isDeleted: {_eq: false}}
        distinct_on: [groupUserId]
      ) {
        aggregate {
          count
        }
      }
      groupMembers(limit: 3, order_by: {user: {name: asc}}) {
        groupUserId
        user {
          name
        }
      }
    }
    contactInbox {
        sourceId
    }
  }
  }
}
`;

const GetMentionsConversationsById = gql`
query GetConversations(
  $conversationId: Int!
) {
  conversationMentions(
    order_by: {
      message:{
        createdAt:desc
      }
    }
    where: {conversation:{
      displayId: {_eq: $conversationId}}}
  ) {
    id
    isRead
    conversationMessages: message {
      senderType
      senderUser {
        id
        name
      }
      senderId
      contentType
      content
      createdAt
      updatedAt
      messageType
      id
      contentAttributes
      private
      messageAttachments {
        id
        fileType
      }
    }
   	conversation {
    contactId
    accountId
    id
    uuid
    displayId
    inboxId
    status
    additionalAttributes
    assigneeId
    createdAt
    lastActivityAt
    blockReasonCode
    conversationUser {
      email
      name
      id
      uuid
    }
    conversationInbox {
      id
      name
      channelType
      isDeleted
      channelEmail {
        forwardToEmail
        forwardRouteId
        smtpAddress
      }
      channelTwilioSms{
        phoneNumber
      }
      channelEfax {
        id
        efaxNumber
      }
      inboxMembers {
        user {
          uuid
          id
          name
        }
      }
    }

    conversationContact {
      isDeleted
      isActive
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      contactPracticeLocations{
        practiceLocationUuid
        accountLocation {
          uuid
        }
      }
      isActive
      accountId
      uuid
      source
      phoneNumber
      pubsubToken
      updatedAt
      name
      email
      id
      additionalAttributes
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        chosenName
        pronounId
        firstName
        birthDate
        id
        bloodGroup {
          value
          code
          id
        }
        gender {
          value
          code
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      createdAt
      personContact {
        personContactId
        rank
        value
        id
        personContactType {
          accountId
          value
          id
          categoryId
        }
        typeId
      }
      lastActivityAt
      identifier
    }
    assignedUser {
      id
      name
    }
    groupConversation {
      id
      name
      ownerId
      ownerTypeId
      groupType {
        code
        description
        value
      }
      groupMembers {
        id
        groupUserId
        groupUserTypeId
        groupUserType {
          code
          value
        }
        contact {
          id
          name
        }
      }
    }
  }
  }
}
`;


const GetMentionsConversationsByConversationUuId = gql`
query GetConversations(
  $conversationUuid: uuid!
) {
  conversationMentions(
    order_by: {
      message:{
        createdAt:desc
      }
    }
    where: {
      conversation:{
        uuid: {
          _eq: $conversationUuid
        }
      }
    }
  ) {
    id
    isRead
    conversationMessages: message {
      senderType
      senderUser {
        id
        name
      }
      senderId
      contentType
      content
      createdAt
      updatedAt
      messageType
      id
      contentAttributes
      private
      messageAttachments {
        id
        fileType
      }
    }
   	conversation {
    contactId
    accountId
    id
    uuid
    displayId
    inboxId
    status
    additionalAttributes
    assigneeId
    createdAt
    lastActivityAt
    blockReasonCode
    conversationUser {
      email
      name
      id
      uuid
    }
    conversationInbox {
      id
      name
      channelType
      isDeleted
      channelEmail {
        forwardToEmail
        forwardRouteId
        smtpAddress
      }
      channelTwilioSms{
        phoneNumber
      }
      channelEfax {
        id
        efaxNumber
      }
      inboxMembers {
        user {
          uuid
          id
          name
        }
      }
    }

    conversationContact {
      isDeleted
      isActive
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      contactPracticeLocations{
        practiceLocationUuid
        accountLocation {
          uuid
        }
      }
      additionalAttributes
      isActive
      accountId
      uuid
      source
      phoneNumber
      pubsubToken
      updatedAt
      name
      email
      id
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        chosenName
        pronounId
        firstName
        birthDate
        id
        bloodGroup {
          value
          code
          id
        }
        gender {
          value
          code
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      createdAt
      personContact {
        personContactId
        rank
        value
        id
        personContactType {
          accountId
          value
          id
          categoryId
        }
        typeId
      }
      lastActivityAt
      identifier
    }
    assignedUser {
      id
      name
    }
    groupConversation {
      id
      name
      ownerId
      ownerTypeId
      groupType {
        code
        description
        value
      }
      groupMembers {
        id
        groupUserId
        groupUserTypeId
        groupUserType {
          code
          value
        }
        contact {
          id
          name
        }
        user {
          id
          uuid
          name
          email
        }
      }
    }
  }
  }
}
`;

const GetAssigneeConversationsById = gql`
query GetAssigneeConversationsById( $loginUserIntId: Int!, $loginUserId: bigint!, $loginUserUuid: uuid!, $conversationId: bigint!) {
  conversations(
    where: {
      id: {_eq: $conversationId}
    }
  ) {
    contactId
    accountId
    id
    uuid
    displayId
    inboxId
    status
    additionalAttributes
    assigneeId
    createdAt
    lastActivityAt
    blockReasonCode
    isActive
    practiceLocationUuid
    conversationInbox {
      id
      name
      channelType
      isDeleted
      channelEmail {
        forwardToEmail
        forwardRouteId
        smtpAddress
      }
      channelTwilioSms{
        phoneNumber
      }
      channelEfax {
        id
        efaxNumber
      }
      inboxMembers {
        user {
          uuid
          id
          name
        }
      }
    }
    unreadMsgCount: unreadMsgNew(
      args: {
        useruuid: $loginUserUuid,
        userid: $loginUserIntId,
        messagetype: "{-1}",
        messageconditionin: false
      }
    )
    conversationMessages(limit: 1, order_by: {createdAt: desc}) {
      senderType
      senderId
      contentType
      content
      createdAt
      updatedAt
      messageType
      id
      contentAttributes
      messageAttachments {
        id
        fileType
      }
    }
    conversationContact {
      isDeleted
      isActive
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      contactPracticeLocations{
        accountLocation {
          uuid
        }
        practiceLocationUuid
      }
      isActive
      contactProfilePhotos {
        attachmentId
      }
      accountId
      uuid
      source
      phoneNumber
      pubsubToken
      updatedAt
      name
      email
      id
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        chosenName
        pronounId
        firstName
        birthDate
        id
        bloodGroup {
          value
          code
          id
        }
        gender {
          value
          code
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      createdAt
      personContact {
        personContactId
        rank
        value
        id
        personContactType {
          accountId
          value
          id
          categoryId
        }
        typeId
      }
      lastActivityAt
      identifier
    }
    contactInbox {
      sourceId
      inbox {
        id
        name
      }
    }
    assignedUser {
      id
      name
    }
    groupConversation {
      id
      name
      ownerId
      ownerTypeId
      groupType {
        code
        description
        value
      }
      groupMembers {
        id
        groupUserId
        groupUserTypeId
        groupUserType {
          code
          value
        }
        contact {
          id
          name
        }
      }
    }
  }
}
`;

const GetGroupConversationByUuid = gql`
  query GetGroupConversationByUuid($id: uuid!) {
    groupConversation(id: $id) {
      id
      name
      ownerId
      ownerTypeId
      groupType {
        code
        description
        value
      }
      groupMembers_aggregate(
        where: {isDeleted: {_eq: false}}
        distinct_on: [groupUserId]
      ) {
        aggregate {
          count
        }
      }
      groupMembers {
        id
        groupUserId
        groupUserTypeId
        groupUserType {
          code
          value
        }
        contact {
          additionalAttributes
          isDeleted
          isActive
          contactPracticeLocations(order_by: {updatedAt: desc}) {
            accountLocation {
              uuid
              locationGroupId
            }
          }
          contactType {
            contactType {
              code
              value
              id
            }
            id
          }
          personAddress {
            cityId
            stateId
            zipcodeId
            cities {
              name
              id
            }
            zipcodes {
              code
              id
            }
            states {
              name
              id
            }
            id
          }
          accountId
          uuid
          phoneNumber
          pubsubToken
          updatedAt
          name
          email
          id
          patient {
            patientId
            patientUuid
            annualVisitDate
            isWeeklyCheckin
            id
          }
          person {
            chosenName
            pronounId
            firstName
            birthDate
            id
            bloodGroup {
              value
              code
              id
            }
            gender {
              value
              code
            }
            genderId
            birthSexId
            sexAtBirth {
              value
              code
              id
            }
            contactId
            middleName
            lastName
          }
          createdAt
          personContact {
            personContactId
            rank
            value
            id
            personContactType {
              accountId
              value
              id
              categoryId
            }
            typeId
          }
          lastActivityAt
          identifier
          contactConsents {
            consentId
          }
        }
        user {
          id
          uuid
          name
          email
          userRoles {
            userRole {
              userRole {
                code
                value
              }
            }
          }
          accountUsers {
            isActive
            userId
          }
        }
      }
    }
  }
`;

const GetConversationByContactId = gql`
  query GetConversationByContactId(
    $conversationContactId: bigint!
    $loginUserId: bigint!
    $loginUserIntId: Int!
    $loginUserUuid: uuid!
  ) {
    conversations(
      where: {conversationContact: {id: {_eq: $conversationContactId}}}
      order_by: {createdAt: desc}
      limit: 1
    ) {
      contactId
      accountId
      id
      uuid
      displayId
      status
      additionalAttributes
      assigneeId
      blockReasonCode
      isActive
      practiceLocationUuid
      unreadMsg(
        args: {useruuid: $loginUserUuid}
        where: {
          _or: {senderType: {_is_null: false}, senderId: {_neq: $loginUserId}}
        }
      ) {
        id
      }
      unreadMsgCount: unreadMsgNew(
        args: {
          useruuid: $loginUserUuid,
          userid: $loginUserIntId,
          messagetype: "{-1}",
          messageconditionin: false
        }
      )
      conversationInbox{
        id
        channelType
        isDeleted
        inboxMembers {
          user {
            uuid
            id
            name
          }
        }
      }
      conversationContact {
        isDeleted
        isActive
        contactTagging {
          labels {
            title
            color
            id
            description
          }
        }
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        contactPracticeLocations{
          accountLocation {
            uuid
          }
          practiceLocationUuid
        }
        personAddress {
          cityId
          stateId
          zipcodeId
          cities {
            name
            id
          }
          zipcodes {
            code
            id
          }
          states {
            name
            id
          }
          id
        }
        accountId
        uuid
        phoneNumber
        pubsubToken
        source
        updatedAt
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        personContact {
          personContactId
          rank
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
        }
        lastActivityAt
        identifier
      }
      contactInbox {
        sourceId
        inbox {
          id
          name
          channelTwilioSms{
            phoneNumber
          }
        }
      }
      conversationMessages(limit: 1, order_by: {createdAt: desc}) {
        senderType
        senderUser {
          id
          name
        }
        senderId
        content
        createdAt
        updatedAt
        messageType
        id
        private
        contentAttributes
        messageAttachments {
          extension
          externalUrl
          fileType
          id
          updatedAt
          activeStorageAttachments {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
        senderUser {
          id
          uuid
          email
          name
        }
        senderContact {
          id
          uuid
          email
          name
        }
      }
    }
  }
`;

const GetConversationById = gql`
  query GetConversationById(
    $conversationId: Int!
    $loginUserIntId: Int!
    $loginUserUuid: uuid!
  ) {
    conversations(where: {displayId: {_eq: $conversationId}}) {
      contactId
      accountId
      id
      uuid
      displayId
      status
      additionalAttributes
      assigneeId
      blockReasonCode
      isActive
      practiceLocationUuid
      conversationUser {
        email
        name
      }
      conversationInbox {
        id
        channelType
        isDeleted
        inboxMembers(order_by: {createdAt: desc_nulls_last}) {
          isDeleted
          userId
          user {
            id
            uuid
            name
            accountUsers {
              accountId
              userId
              isActive
              isDeleted
            }
          }
        }
        channelEmail {
          smtpAddress
        }
      }
      unreadMsgCount: unreadMsgNew(
        args: {
          useruuid: $loginUserUuid,
          userid: $loginUserIntId,
          messagetype: "{-1}",
          messageconditionin: false
        }
      )
      conversationContact {
        isDeleted
        contactPracticeLocations(
          where: {practiceLocationUuid: {_is_null: false}}
        ) {
          uuid
          practiceLocationUuid
          id
          contactUuid
          accountLocation {
            uuid
          }
          practiceLocation {
            name
            practiceCity {
              name
              id
            }
          }
        }
        contactDeactivationInfo {
          isActive
        }
        isActive
        contactTagging {
          labels {
            title
            color
            id
            description
          }
        }
        contactPreferredCommunicationChannel {
          preferredCommunicationModeId
          id
          preferredCommunicationModeMlov {
            code
            value
          }
        }
        contactProfilePhotos {
          attachmentId
        }
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        personAddress {
          cityId
          stateId
          zipcodeId
          cities {
            name
            id
          }
          zipcodes {
            code
            id
          }
          states {
            name
            id
          }
          id
        }
        source
        accountId
        uuid
        phoneNumber
        pubsubToken
        updatedAt
        notes {
          contactId
          content
          userId
          id
        }
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        personContact {
          rank
          personContactId
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
        }
        lastActivityAt
        identifier
      }
      contactInbox {
        sourceId
        inbox {
          id
          name
          channelTwilioSms{
            phoneNumber
          }
        }
      }
      conversationMessages(limit: 1, order_by: {createdAt: desc}) {
        content
        senderUser {
          id
          name
        }
        senderType
        senderContact {
          id
          uuid
          email
          name
        }
        createdAt
        updatedAt
        messageType
        id
        private
        contentAttributes
        messageAttachments {
          extension
          externalUrl
          fileType
          id
          updatedAt
          activeStorageAttachments {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupType {
          code
          description
          value
        }
        groupConversationSetting {
          canSendMsgByUserTypeId
          editGroupInfoByUserTypeId
        }
        groupMembers {
          id
          groupUserId
          groupUserTypeId
          groupUserType {
            code
            value
          }
          contact {
            isDeleted
            relations {
              relationType {
                code
                value
              }
            }
            contactPracticeLocations(
              where: {practiceLocationUuid: {_is_null: false}}
            ) {
              uuid
              practiceLocationUuid
              id
              accountLocation {
                uuid
              }
              contactUuid
              practiceLocation {
                name
                practiceCity {
                  name
                  id
                }
              }
            }
            contactDeactivationInfo {
              isActive
            }
            isActive
            contactTagging {
              labels {
                title
                color
                id
                description
              }
            }
            contactType {
              contactType {
                code
                value
                id
              }
              id
            }
            personAddress {
              cityId
              stateId
              zipcodeId
              cities {
                name
                id
              }
              zipcodes {
                code
                id
              }
              states {
                name
                id
              }
              id
            }
            contactProfilePhotos {
              attachmentId
            }
            accountId
            uuid
            phoneNumber
            pubsubToken
            updatedAt
            notes {
              contactId
              content
              userId
              id
            }
            name
            email
            id
            patient {
              patientId
              patientUuid
              annualVisitDate
              isWeeklyCheckin
              id
            }
            person {
              chosenName
              pronounId
              firstName
              birthDate
              id
              bloodGroup {
                value
                code
                id
              }
              gender {
                value
                code
              }
              genderId
              birthSexId
              sexAtBirth {
                value
                code
                id
              }
              contactId
              middleName
              lastName
            }
            createdAt
            personContact {
              personContactId
              value
              id
              personContactType {
                accountId
                value
                id
                categoryId
              }
              typeId
              rank
            }
            lastActivityAt
            identifier
          }
          user {
            id
            uuid
            name
            email
            accountUsers {
              isActive
              userId
            }
          }
        }
      }
    }
  }
`;

const GetConversationByConversationUuid = gql`
  query GetConversationByConversationUuid(
    $conversationUuid: uuid!
    $loginUserIntId: Int!
    $loginUserUuid: uuid!
    $messageWhereCondition: messages_bool_exp
  ) {
    conversations(where: {uuid: {_eq: $conversationUuid}}) {
      contactId
      accountId
      id
      uuid
      displayId
      inboxId
      status
      additionalAttributes
      assigneeId
      inboxId
      blockReasonCode
      isActive
      practiceLocationUuid
      conversationUser {
        email
        name
      }
      assignedUser {
        id
        uuid
        email
        name
      }
      lastMissCallAt
      conversationInbox {
        id
        channelType
        isDeleted
        channelTwilioSms {
          phoneNumber
        }
        inboxMembers(order_by: {createdAt: desc_nulls_last}) {
          isDeleted
          userId
          user {
            id
            uuid
            name
            accountUsers {
              accountId
              userId
              isActive
              isDeleted
            }
          }
        }
        channelEmail {
          forwardToEmail
          forwardRouteId
          smtpAddress
        }
      }
      unreadMsgCount: unreadMsgNew(
        args: {
          useruuid: $loginUserUuid,
          userid: $loginUserIntId,
          messagetype: "{-1}",
          messageconditionin: false
        }
      )
      conversationContact {
        isDeleted
        contactPracticeLocations(
          where: {practiceLocationUuid: {_is_null: false}}
        ) {
          uuid
          practiceLocationUuid
          id
          accountLocation {
            uuid
          }
          contactUuid
          accountLocation {
            uuid
          }
          practiceLocation {
            name
            practiceCity {
              name
              id
            }
          }
        }
        contactDeactivationInfo {
          isActive
        }
        isActive
        contactTagging {
          labels {
            title
            color
            id
            description
          }
        }
        contactPreferredCommunicationChannel {
          preferredCommunicationModeId
          id
          preferredCommunicationModeMlov {
            code
            value
          }
        }
        contactProfilePhotos {
          attachmentId
        }
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        personAddress {
          cityId
          stateId
          zipcodeId
          cities {
            name
            id
          }
          zipcodes {
            code
            id
          }
          states {
            name
            id
          }
          id
        }
        source
        accountId
        uuid
        phoneNumber
        pubsubToken
        updatedAt
        notes {
          contactId
          content
          userId
          id
        }
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        personContact {
          personContactId
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
          rank
        }
        lastActivityAt
        identifier
      }
      contactInbox {
        inbox {
          id
          name
          channelTwilioSms{
            phoneNumber
          }
        }
        sourceId
      }
      conversationMessages(limit: 1, order_by: {createdAt: desc}, where: $messageWhereCondition) {
        content
        senderUser {
          id
          name
        }
        senderType
        senderContact {
          id
          uuid
          email
          name
        }
        createdAt
        updatedAt
        messageType
        id
        private
        contentAttributes
        messageAttachments {
          extension
          externalUrl
          fileType
          id
          updatedAt
          activeStorageAttachments {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupType {
          code
          description
          value
        }
        groupConversationSetting {
          canSendMsgByUserTypeId
          editGroupInfoByUserTypeId
        }
        groupMembers {
          id
          groupUserId
          groupUserTypeId
          groupUserType {
            code
            value
          }
          contact {
            isDeleted
            relations {
              relationType {
                code
                value
              }
            }
            contactPracticeLocations(
              where: {practiceLocationUuid: {_is_null: false}}
            ) {
              uuid
              practiceLocationUuid
              id
              accountLocation {
                uuid
              }
              contactUuid
              practiceLocation {
                name
                practiceCity {
                  name
                  id
                }
              }
            }
            contactDeactivationInfo {
              isActive
            }
            isActive
            contactTagging {
              labels {
                title
                color
                id
                description
              }
            }
            contactType {
              contactType {
                code
                value
                id
              }
              id
            }
            personAddress {
              cityId
              stateId
              zipcodeId
              cities {
                name
                id
              }
              zipcodes {
                code
                id
              }
              states {
                name
                id
              }
              id
            }
            contactProfilePhotos {
              attachmentId
            }
            accountId
            uuid
            phoneNumber
            pubsubToken
            updatedAt
            notes {
              contactId
              content
              userId
              id
            }
            name
            email
            id
            patient {
              patientId
              patientUuid
              annualVisitDate
              isWeeklyCheckin
              id
            }
            person {
              chosenName
              pronounId
              firstName
              birthDate
              id
              bloodGroup {
                value
                code
                id
              }
              gender {
                value
                code
              }
              genderId
              birthSexId
              sexAtBirth {
                value
                code
                id
              }
              contactId
              middleName
              lastName
            }
            createdAt
            personContact {
              personContactId
              value
              id
              personContactType {
                accountId
                value
                id
                categoryId
              }
              typeId
              rank
            }
            lastActivityAt
            identifier
          }
          user {
            id
            uuid
            name
            email
            accountUsers {
              isActive
              userId
            }
          }
        }
      }
    }
  }
`;

const GetConversationByConversationUuidForSubtab = gql`
  query GetConversationByConversationUuidForSubtab(
    $conversationUuid: uuid!
    $loginUserIntId: Int!
    $loginUserUuid: uuid!
    $conversationMessagesWhere: messages_bool_exp
  ) {
    conversations(
      where: {
        uuid: {_eq: $conversationUuid}
        conversationMessages: $conversationMessagesWhere
      }
    ) {
      contactId
      accountId
      id
      uuid
      displayId
      inboxId
      status
      additionalAttributes
      assigneeId
      inboxId
      blockReasonCode
      isActive
      practiceLocationUuid
      conversationUser {
        email
        name
      }
      assignedUser {
        id
        uuid
        email
        name
      }
      conversationInbox {
        id
        channelType
        inboxMembers(order_by: {createdAt: desc_nulls_last}) {
          isDeleted
          userId
          user {
            id
            uuid
            name
            accountUsers {
              accountId
              userId
              isActive
              isDeleted
            }
          }
        }
      }
      unreadMsgCount: unreadMsgNew(
        args: {
          useruuid: $loginUserUuid
          userid: $loginUserIntId
          messagetype: "{-1}"
          messageconditionin: false
        }
      )
      conversationContact {
        isDeleted
        contactPracticeLocations(
          where: {practiceLocationUuid: {_is_null: false}}
        ) {
          uuid
          practiceLocationUuid
          id
          accountLocation {
            uuid
          }
          contactUuid
          practiceLocation {
            name
            practiceCity {
              name
              id
            }
          }
        }
        contactDeactivationInfo {
          isActive
        }
        isActive
        contactTagging {
          labels {
            title
            color
            id
            description
          }
        }
        contactPreferredCommunicationChannel {
          preferredCommunicationModeId
          id
          preferredCommunicationModeMlov {
            code
            value
          }
        }
        contactProfilePhotos {
          attachmentId
        }
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        personAddress {
          cityId
          stateId
          zipcodeId
          cities {
            name
            id
          }
          zipcodes {
            code
            id
          }
          states {
            name
            id
          }
          id
        }
        source
        accountId
        uuid
        phoneNumber
        pubsubToken
        updatedAt
        notes {
          contactId
          content
          userId
          id
        }
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        personContact {
          personContactId
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
          rank
        }
        lastActivityAt
        identifier
      }
      contactInbox {
        inbox {
          id
          name
          channelTwilioSms {
            phoneNumber
          }
        }
        sourceId
      }
      conversationMessages(
        limit: 1
        order_by: {createdAt: desc}
        where: $conversationMessagesWhere
      ) {
        content
        senderUser {
          id
          name
        }
        senderType
        senderContact {
          id
          uuid
          email
          name
        }
        createdAt
        updatedAt
        messageType
        id
        private
        contentAttributes
        messageAttachments {
          extension
          externalUrl
          fileType
          id
          updatedAt
          activeStorageAttachments {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupType {
          code
          description
          value
        }
        groupConversationSetting {
          canSendMsgByUserTypeId
          editGroupInfoByUserTypeId
        }
        groupMembers {
          id
          groupUserId
          groupUserTypeId
          groupUserType {
            code
            value
          }
          contact {
            isDeleted
            relations {
              relationType {
                code
                value
              }
            }
            contactPracticeLocations(
              where: {practiceLocationUuid: {_is_null: false}}
            ) {
              uuid
              practiceLocationUuid
              id
              accountLocation {
                uuid
              }
              contactUuid
              practiceLocation {
                name
                practiceCity {
                  name
                  id
                }
              }
            }
            contactDeactivationInfo {
              isActive
            }
            isActive
            contactTagging {
              labels {
                title
                color
                id
                description
              }
            }
            contactType {
              contactType {
                code
                value
                id
              }
              id
            }
            personAddress {
              cityId
              stateId
              zipcodeId
              cities {
                name
                id
              }
              zipcodes {
                code
                id
              }
              states {
                name
                id
              }
              id
            }
            contactProfilePhotos {
              attachmentId
            }
            accountId
            uuid
            phoneNumber
            pubsubToken
            updatedAt
            notes {
              contactId
              content
              userId
              id
            }
            name
            email
            id
            patient {
              patientId
              patientUuid
              annualVisitDate
              isWeeklyCheckin
              id
            }
            person {
              chosenName
              pronounId
              firstName
              birthDate
              id
              bloodGroup {
                value
                code
                id
              }
              gender {
                value
                code
              }
              genderId
              birthSexId
              sexAtBirth {
                value
                code
                id
              }
              contactId
              middleName
              lastName
            }
            createdAt
            personContact {
              personContactId
              value
              id
              personContactType {
                accountId
                value
                id
                categoryId
              }
              typeId
              rank
            }
            lastActivityAt
            identifier
          }
          user {
            id
            uuid
            name
            email
            accountUsers {
              isActive
              userId
            }
          }
        }
      }
    }
  }
`;

const GetConversationByConversationId = gql`
  query GetConversationById(
    $conversationId: bigint!
    $loginUserIntId: Int!
    $loginUserUuid: uuid!
  ) {
    conversations(where: {id: {_eq: $conversationId}}) {
      contactId
      accountId
      id
      uuid
      displayId
      inboxId
      status
      additionalAttributes
      assigneeId
      blockReasonCode
      isActive
      practiceLocationUuid
      conversationUser {
        email
        name
      }
      conversationInbox {
        id
        channelType
        isDeleted
        inboxMembers(order_by: {createdAt: desc_nulls_last}) {
          isDeleted
          userId
          user {
            id
            uuid
            name
            accountUsers {
              accountId
              userId
              isActive
              isDeleted
            }
          }
        }
        channelEmail {
          smtpAddress
        }
      }

      unreadMsgCount: unreadMsgNew(
        args: {
          useruuid: $loginUserUuid,
          userid: $loginUserIntId,
          messagetype: "{-1}",
          messageconditionin: false
        }
      )
      conversationContact {
        isDeleted
        contactPracticeLocations(
          where: {practiceLocationUuid: {_is_null: false}}
        ) {
          accountLocation {
            uuid
          }
          uuid
          practiceLocationUuid
          id
          contactUuid
          practiceLocation {
            name
            practiceCity {
              name
              id
            }
          }
        }
        contactDeactivationInfo {
          isActive
        }
        isActive
        contactTagging {
          labels {
            title
            color
            id
            description
          }
        }
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        contactPreferredCommunicationChannel {
          preferredCommunicationModeId
          id
          preferredCommunicationModeMlov {
            code
            value
          }
        }
        personAddress {
          cityId
          stateId
          zipcodeId
          cities {
            name
            id
          }
          zipcodes {
            code
            id
          }
          states {
            name
            id
          }
          id
        }
        contactProfilePhotos {
          attachmentId
        }
        accountId
        uuid
        source
        phoneNumber
        pubsubToken
        updatedAt
        notes {
          contactId
          content
          userId
          id
        }
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        personContact {
          personContactId
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
          rank
        }
        contactConsents {
          consentId
        }
        lastActivityAt
        identifier
      }
      contactInbox {
        sourceId
        inbox {
          id
          name
          channelTwilioSms{
            phoneNumber
          }
        }
      }
      conversationMessages(limit: 1, order_by: {createdAt: desc}) {
        senderType
        senderUser {
          id
          name
        }
        senderId
        content
        createdAt
        updatedAt
        messageType
        id
        private
        contentAttributes
        messageAttachments {
          extension
          externalUrl
          fileType
          id
          updatedAt
          activeStorageAttachments {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
        senderUser {
          id
          uuid
          email
          name
        }
        senderContact {
          id
          uuid
          email
          name
        }
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupType {
          code
          description
          value
        }
        groupConversationSetting {
          canSendMsgByUserTypeId
          editGroupInfoByUserTypeId
        }
        groupMembers {
          id
          groupUserId
          groupUserTypeId
          groupUserType {
            code
            value
          }
          contact {
            isDeleted
            relations {
              relationType {
                code
                value
              }
            }
            contactPracticeLocations(
              where: {practiceLocationUuid: {_is_null: false}}
            ) {
              uuid
              practiceLocationUuid
              id
              accountLocation {
                uuid
              }
              contactUuid
              practiceLocation {
                name
                practiceCity {
                  name
                  id
                }
              }
            }
            contactDeactivationInfo {
              isActive
            }
            isActive
            contactTagging {
              labels {
                title
                color
                id
                description
              }
            }
            contactType {
              contactType {
                code
                value
                id
              }
              id
            }
            personAddress {
              cityId
              stateId
              zipcodeId
              cities {
                name
                id
              }
              zipcodes {
                code
                id
              }
              states {
                name
                id
              }
              id
            }
            contactProfilePhotos {
              attachmentId
            }
            accountId
            uuid
            phoneNumber
            pubsubToken
            updatedAt
            notes {
              contactId
              content
              userId
              id
            }
            name
            email
            id
            patient {
              patientId
              patientUuid
              annualVisitDate
              isWeeklyCheckin
              id
            }
            person {
              chosenName
              pronounId
              firstName
              birthDate
              id
              bloodGroup {
                value
                code
                id
              }
              gender {
                value
                code
              }
              genderId
              birthSexId
              sexAtBirth {
                value
                code
                id
              }
              contactId
              middleName
              lastName
            }
            createdAt
            personContact {
              personContactId
              value
              id
              personContactType {
                accountId
                value
                id
                categoryId
              }
              typeId
              rank
            }
            contactConsents {
              consentId
            }
            lastActivityAt
            identifier
          }
          user {
            id
            uuid
            name
            email
            accountUsers {
              isActive
              userId
            }
          }
        }
      }
    }
  }
`;

const GetGroupMembersByGroupId = gql`
  query GetMembersById($groupId: uuid!) {
    groupMembers(where: {groupId: {_eq: $groupId}}) {
      id
      groupUserId
      groupUserTypeId
      groupUserType {
        code
        value
      }
      contact {
        isDeleted
        relations {
          relationType {
            code
            value
          }
        }
        contactPracticeLocations(
          where: {practiceLocationUuid: {_is_null: false}}
        ) {
          uuid
          practiceLocationUuid
          id
          contactUuid
          accountLocation {
            uuid
          }
          practiceLocation {
            name
            practiceCity {
              name
              id
            }
          }
        }
        contactDeactivationInfo {
          isActive
        }
        isActive
        contactTagging {
          labels {
            title
            color
            id
            description
          }
        }
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        contactPreferredCommunicationChannel {
          preferredCommunicationModeId
          id
          preferredCommunicationModeMlov {
            code
            value
          }
        }
        personAddress {
          cityId
          stateId
          zipcodeId
          cities {
            name
            id
          }
          zipcodes {
            code
            id
          }
          states {
            name
            id
          }
          id
        }
        accountId
        uuid
        phoneNumber
        pubsubToken
        updatedAt
        notes {
          contactId
          content
          userId
          id
        }
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        personContact {
          personContactId
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
          rank
        }
        contactConsents {
          consentId
        }
        lastActivityAt
        identifier
      }
      user {
        id
        uuid
        name
        email
        userRoles{
          userRole{
            userRole{
              value
            }
          }
        }
        accountUsers {
          isActive
          userId
        }
      }
    }
  }
`;

const GetConversationMessages = gql`
  query GetConversationMessages(
    $id: bigint!
    $loginUserId: bigint!
    $loginUserIntId: Int!
    $loginUserUuid: uuid!
    $limit: Int!
    $offset: Int!
  ) {
    conversation(id: $id) {
      unreadMessages: unreadMsg(
        args: {useruuid: $loginUserUuid}
        where: {
          _or: {senderType: {_is_null: false}, senderId: {_neq: $loginUserId}}
        }
        limit: 1
      ) {
        id
        messageType
        messageMentions(where: {userId: {_eq: $loginUserUuid}}) {
          id
        }
      }
      unreadMsgCount: unreadMsgNew(
        args: {
          useruuid: $loginUserUuid
          userid: $loginUserIntId
          messagetype: "{-1}"
          messageconditionin: false
        }
      )
      conversationInbox {
        isDeleted
        inboxMembers(where: {isDeleted: {_eq: false}}) {
          id
          user {
            id
            uuid
          }
        }
      }
      contactInbox {
        sourceId
      }
      conversationMessages(
        limit: $limit
        offset: $offset
        order_by: {createdAt: desc}
      ) {
        id
        uuid
        content
        messageMentions(
          where: {isRead: {_eq: false}, userId: {_eq: $loginUserUuid}}
        ) {
          id
          isRead
        }
        contentAttributes
        senderId
        senderType
        contentType
        createdAt
        updatedAt
        messageType
        status
        sourceId
        private
        isScheduleMessage
        isAutoResponse
        uuid
        parentMessageUuid
        parentMessage {
          id
        }
        senderContact {
          email
          name
          id
        }
        senderUser {
          email
          id
          name
          uuid
        }
        messageAttachments {
          id
          activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
      }
    }
  }
`;



const GetConversationLastMessages = gql`
query GetConversationLastMessages($id: bigint!, $limit: Int!) {
  conversation(id: $id) {
    lastMsg: conversationMessages(
      limit: $limit
      order_by: {createdAt: desc}
    ) {
      id
      uuid
    }
  }
}
`

const GetPrevConversationMentionMessages = gql`
  query GetConversationPrevMessages(
    $id: bigint!
    $limit: Int!
    $offset: Int!
    $dateTime: timestamp!
  ) {
    conversation(id: $id) {
      prevMsg: conversationMessages(
        limit: $limit
        offset: $offset
        where: {
          createdAt:{
            _lt: $dateTime
          }
        }
        order_by: {createdAt: desc}
      ) {
        id
        uuid
        content
        contentAttributes
        senderId
        senderType
        contentType
        createdAt
        updatedAt
        messageType
        status
        sourceId
        private
        uuid
        parentMessage {
          id
          content
          contentAttributes
          senderId
          senderType
          contentType
          createdAt
          messageType
          status
          sourceId
          private
          uuid
          senderContact {
            email
            name
            id
          }
          senderUser {
            email
            id
            name
          }
          messageAttachments {
            id
            activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
              activeStorageBlob {
                filename
                contentType
              }
            }
          }
        }
        senderContact {
          email
          name
          id
        }
        senderUser {
          email
          id
          name
        }
        messageAttachments {
          id
          activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
        parentMessage {
          id
          content
          contentAttributes
          senderId
          senderType
          contentType
          createdAt
          messageType
          status
          sourceId
          private
          senderContact {
            email
            name
            id
          }
          senderUser {
            email
            id
            name
          }
        }
      }
    }
  }
`;

const GetNextConversationMentionMessages = gql`
  query GetConversationNextMessages(
    $id: bigint!
    $limit: Int!
    $offset: Int!
    $dateTime: timestamp!
  ) {
    conversation(id: $id) {
      nextMsg: conversationMessages(
        limit: $limit
        offset: $offset
        where: {
          createdAt:{
            _gt: $dateTime
          }
        }
        order_by: {createdAt: asc}
      ) {
        id
        uuid
        content
        contentAttributes
        senderId
        senderType
        contentType
        createdAt
        updatedAt
        messageType
        status
        sourceId
        private
        uuid
        parentMessage {
          id
          content
          contentAttributes
          senderId
          senderType
          contentType
          createdAt
          messageType
          status
          sourceId
          private
          uuid
          senderContact {
            email
            name
            id
          }
          senderUser {
            email
            id
            name
          }
          messageAttachments {
            id
            activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
              activeStorageBlob {
                filename
                contentType
              }
            }
          }
        }
        senderContact {
          email
          name
          id
        }
        senderUser {
          email
          id
          name
        }
        messageAttachments {
          id
          activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
        parentMessage {
          id
          content
          contentAttributes
          senderId
          senderType
          contentType
          createdAt
          messageType
          status
          sourceId
          private
          senderContact {
            email
            name
            id
          }
          senderUser {
            email
            id
            name
          }
        }
      }
    }
  }
`;

const SearchConversations = gql`
  query GetConversations(
    $limit: Int!
    $offset: Int!
    $whereCondition: conversations_bool_exp!
  ) {
    conversations(
      limit: $limit,
      offset: $offset,
      where: $whereCondition,
      order_by: {
        lastActivityAt: desc_nulls_last
      },
      ) {
      contactId
      accountId
      id
      uuid
      inboxId
      displayId
      status
      additionalAttributes
      assigneeId
      lastMissCallAt
      blockReasonCode
      isActive
      practiceLocationUuid
      assignedUser {
        name
        id
      }
      createdAt
      conversationUser {
        email
        name
      }
      conversationInbox {
        id
        channelType
        isDeleted
        inboxMembers {
          user {
            uuid
            id
            name
          }
        }
        channelEmail {
          forwardToEmail
          forwardRouteId
          smtpAddress
        }
      }
      conversationMessages(
        where: {
          messageType: {
            _in: [0, 1]
          }
        },
        limit: 1,
        order_by: {
          createdAt: desc
        },
      ) {
        senderId
        senderType
        senderUser {
          id
          name
        }
        content
        createdAt
        updatedAt
        messageType
        id
        contentAttributes
        messageAttachments {
          extension
          externalUrl
          fileType
          id
          updatedAt
        }
      }
      conversationContact {
        isDeleted
        contactPracticeLocations(
          where: {practiceLocationUuid: {_is_null: false}}
        ) {
          uuid
          practiceLocationUuid
          id
          accountLocation {
            uuid
          }
          contactUuid
          practiceLocation {
            name
            practiceCity {
              name
              id
            }
          }
        }
        isActive
        contactTagging {
          labels {
            title
            color
            id
            description
          }
        }
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        contactPreferredCommunicationChannel {
          preferredCommunicationModeId
          id
          preferredCommunicationModeMlov {
            code
            value
          }
        }
        contactProfilePhotos {
          attachmentId
        }
        accountId
        uuid
        source
        phoneNumber
        pubsubToken
        updatedAt
        notes {
          contactId
          content
          userId
          id
        }
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          chosenName
          pronounId
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        additionalAttributes
        personContact {
          personContactId
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
          rank
        }
        contactConsents {
          consentId
        }
        lastActivityAt
        identifier
      }
      contactInbox {
        sourceId
        inbox {
          id
          name
          channelTwilioSms{
            phoneNumber
          }
        }
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupType {
          code
          description
          value
        }
        groupConversationSetting {
          canSendMsgByUserTypeId
          editGroupInfoByUserTypeId
        }
        groupMembers {
          id
          groupUserId
          groupUserTypeId
          groupUserType {
            code
            value
          }
          contact {
            isDeleted
            relations {
              relationType {
                code
                value
              }
            }
            contactPracticeLocations(
              where: {practiceLocationUuid: {_is_null: false}}
            ) {
              accountLocation {
                uuid
              }
              uuid
              practiceLocationUuid
              id
              contactUuid
              practiceLocation {
                name
                practiceCity {
                  name
                  id
                }
              }
            }
            contactDeactivationInfo {
              isActive
            }
            isActive
            contactTagging {
              labels {
                title
                color
                id
                description
              }
            }
            contactType {
              contactType {
                code
                value
                id
              }
              id
            }
            personAddress {
              cityId
              stateId
              zipcodeId
              cities {
                name
                id
              }
              zipcodes {
                code
                id
              }
              states {
                name
                id
              }
              id
            }
            contactProfilePhotos {
              attachmentId
            }
            accountId
            uuid
            phoneNumber
            pubsubToken
            updatedAt
            notes {
              contactId
              content
              userId
              id
            }
            name
            email
            id
            patient {
              patientId
              patientUuid
              annualVisitDate
              isWeeklyCheckin
              id
            }
            person {
              chosenName
              pronounId
              firstName
              birthDate
              id
              bloodGroup {
                value
                code
                id
              }
              gender {
                value
              }
              genderId
              birthSexId
              sexAtBirth {
                value
                code
                id
              }
              contactId
              middleName
              lastName
            }
            createdAt
            personContact {
              personContactId
              value
              id
              personContactType {
                accountId
                value
                id
                categoryId
              }
              typeId
              rank
            }
            lastActivityAt
            identifier
          }
          user {
            id
            uuid
            name
            email
          }
        }
      }
    }
  }
`;

const GetMentionsSearchConversations = gql`
query GetConversations($limit: Int!, $offset: Int!, $whereCondition: conversation_mentions_bool_exp) {
  conversationMentions(
    limit: $limit
    offset: $offset,
    where: $whereCondition
  ) {
    id
    isRead
    conversationMessages: message {
      senderType
      senderUser {
        id
        name
      }
      senderId
      contentType
      content
      createdAt
      updatedAt
      messageType
      id
      contentAttributes
      private
      messageAttachments {
        extension
        externalUrl
        fileType
        id
        updatedAt
        activeStorageAttachments {
          activeStorageBlob {
            filename
            contentType
          }
        }
      }
    }
   	conversation {
    contactId
    accountId
    id
    uuid
    displayId
    inboxId
    status
    additionalAttributes
    assigneeId
    createdAt
    lastActivityAt
    blockReasonCode
    conversationUser {
      email
      name
      id
      uuid
    }
    conversationInbox {
      id
      name
      channelType
      isDeleted
      channelEmail {
        forwardToEmail
        forwardRouteId
        smtpAddress
      }
      channelTwilioSms {
        phoneNumber
      }
      channelEfax {
        id
        efaxNumber
      }
      inboxMembers {
        user {
          uuid
          id
          name
        }
      }
    }
    contactInbox {
        sourceId
    }
    conversationContact {
      isDeleted
      isActive
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      contactPracticeLocations{
        practiceLocationUuid
        accountLocation {
          uuid
        }
      }
      additionalAttributes
      isActive
      accountId
      uuid
      source
      phoneNumber
      pubsubToken
      updatedAt
      name
      email
      id
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        chosenName
        pronounId
        firstName
        birthDate
        id
        gender {
          value
          code
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      createdAt
      personContact {
        personContactId
        value
        id
        personContactType {
          accountId
          value
          id
          categoryId
        }
        typeId
        rank
      }
      lastActivityAt
      identifier
    }
    assignedUser {
      id
      name
    }
    groupConversation {
      id
      name
      ownerId
      ownerTypeId
      groupType {
        code
        description
        value
      }
      groupMembers {
        id
        groupUserId
        groupUserTypeId
        groupUserType {
          code
          value
        }
        contact {
          id
          name
        }
      }
    }
  }
  }
}
`;

const GetRelatedConversation = gql`
  query GetRelatedConversation(
    $relatedConversationObj: conversations_bool_exp!
  ) {
    conversations(where: $relatedConversationObj) {
      id
      uuid
      accountId
      contactId
      isActive
      practiceLocationUuid
      conversationMessages(order_by: {createdAt: desc}) {
        content
        conversationId
        createdAt
      }
      createdAt
      lastActivityAt
    }
  }
`;

const UpdateLastSeenAtConversation = gql`
  mutation createOrUpdateLastSeenConversation(
    $userLastSeenData: conversation_lastseens_insert_input!
  ) {
    createConversationLastseen(
      on_conflict: {
        constraint: conversation_lastseens_conversation_uuid_lastseen_by_uuid_key
        update_columns: lastseenById
      }
      object: $userLastSeenData
    ) {
      id
    }
  }
`;

export const UpdateConversationStatus = gql`
  mutation UpdateConversationStatus($id: bigint!, $status: Int!) {
    updateConversation(pk_columns: {id: $id}, _set: {status: $status}) {
      status
      contactId
      conversationContact {
        email
        name
      }
    }
  }
`;
export const GetConversationUserById = gql`
  query GetConversationUserById($conversationId: bigint) {
    conversations(where: {id: {_eq: $conversationId}}) {
      contactId
      accountId
      id
      uuid
      displayId
      status
      additionalAttributes
      assigneeId
      isActive
      practiceLocationUuid
      assignedUser {
        id
        uuid
        name
        email
      }
    }
  }
`;

export const GetPrivateMessage = gql`
  query GetPrivateMessage($conversationId: Int!) {
    messages(
      where: {private: {_eq: true}, conversationId: {_eq: $conversationId}}
    ) {
      private
      senderId
      conversationId
      accountId
      senderType
      senderUser {
        email
        name
      }
      content
      createdAt
    }
  }
`;

const updateConversationLastActivityAt = gql`
  mutation updateConversationLastActivityAt(
    $conversationUuid: uuid!
    $lastActivityAt: timestamp!
  ) {
    updateConversations(
      where: {uuid: {_eq: $conversationUuid}}
      _set: {lastActivityAt: $lastActivityAt}
    ) {
      returning {
        id
        lastActivityAt
      }
    }
  }
`;
const getMessageDataId = gql`
  query getMessageData($messageId: bigint!) {
    message(id: $messageId) {
      id
      uuid
      createdAt
    }
  }
`;

export const GROUP_MEMBER_PUPSUB = gql`
  query GroupMemberPupsub($useerUuids: [uuid]!, $contactUuids: [uuid]!) {
    users(where: {uuid: {_in: $useerUuids}}) {
      pubsubToken
    }
    contacts(where: {uuid: {_in: $contactUuids}}) {
      id
      person {
        pubsubToken
      }
    }
  }
`;

export const USER_PUBSUB_TOKEN = gql`
  query GroupMemberPupsub($userIds: [bigint!]!) {
    users(where: {id: {_in: $userIds}}) {
      pubsubToken
    }
  }
`;

const createConversationMentions = gql`
  mutation createConversationMentions(
    $mentionDataList: [conversation_mentions_insert_input!]!
  ) {
    createConversationMentions(objects: $mentionDataList) {
      returning {
        id
        isRead
        userTypeId
        userId
        userType {
          code
        }
      }
    }
  }
`;

export const GET_LAST_SEEN_BY_CONVERSATION_ID = gql`
  query GetLastSeenByConversationId($conversationUuid: uuid!) {
    conversationLastseens(where: {conversationUuid: {_eq: $conversationUuid}}) {
      id
      lastseenById
      createdAt
      updatedAt
    }
  }
`;

export const GET_TASK_ITEM_IDS = gql`
  query GetTaskItemIds($conversationUuid: uuid!) {
    conversationTaggedItems(
      where: {conversationUuid: {_eq: $conversationUuid}}
    ) {
      itemId
    }
  }
`;

export const UpdateMessageParentUuid = gql`
  mutation UpdateMessageParentUuid($parentMessageUuid: uuid!, $id: bigint!) {
    updateMessages(
      where: {id: {_eq: $id}}
      _set: {parentMessageUuid: $parentMessageUuid}
    ) {
      returning {
        id
        parentMessage {
          content
        }
      }
    }
  }
`;

export const getDefaultConversations = gql`
  query getDefaultConversations(
    $groupWhereCondition: group_conversations_bool_exp!
  ) {
    groupConversations(where: $groupWhereCondition) {
      id
      name
      ownerId
      groupType {
        code
      }
      isDeleted
      isArchived
      groupMembers(where: {groupUserType: {code: {_eq: "USER"}}}) {
        groupUserId
        isDeleted
      }
    }
  }
`;

export const DeleteMessageAttachment = gql`
  mutation deleteMessageAttachment($id: bigint!, $messageId: Int!) {
    updateAttachments(
      where: {id: {_eq: $id}, messageId: {_eq: $messageId}}
      _set: {isDeleted: true}
    ) {
      returning {
        id
      }
    }
  }
`;

export const GetAttachmentsIds = gql`
  query MyQuery($id: bigint) {
    attachments(where: {message: {conversation: {id: {_eq: $id}}}}) {
      id
      messageId
    }
  }
`;

export const GetSingleMessageDataById = gql`
  query GetSingleMessageDataById($messageId: bigint!, $userUuid: uuid) {
    message(id: $messageId) {
      conversation {
        conversationMentions(
          where: {userId: {_eq: $userUuid}, message: {id: {_eq: $messageId}}}
        ) {
          userId
        }
        status
        groupConversation {
          groupType {
            code
          }
        }
      }
    }
  }
`;

export const getMemberByContact = gql`
  query getMemberByContact($where: contacts_bool_exp) {
    contacts(where: $where)  {
      id
      uuid
      name
      person  {
        firstName
        lastName
        birthDate
        gender {
          code
          value
        }
        sexAtBirth {
          code
          value
        }
      }
      contactType {
        contactType {
          value
          code
        }
      }
      patient {
        patientId
      }
    }
  }
  `
export const ReadMentionMessage = `
  mutation ReadMentionMessage($ids: [uuid!], $status: Boolean = true) {
    updateConversationMentions(
      _set: {isRead: $status}
      where: {id: {_in: $ids}}
    ) {
      returning {
        id
      }
    }
  }
`;

export const GetMessageByMentionId = gql`
  query GetMessageByMentionId($mentionId: uuid!) {
    messages(where: {messageMentions: {id: {_eq: $mentionId}}}) {
      id
        uuid
        content
        contentAttributes
        senderId
        senderType
        contentType
        createdAt
        updatedAt
        messageType
        status
        sourceId
        private
        uuid
        parentMessage {
          id
          content
          contentAttributes
          senderId
          senderType
          contentType
          createdAt
          messageType
          status
          sourceId
          private
          uuid
          senderContact {
            email
            name
            id
          }
          senderUser {
            email
            id
            name
          }
          messageAttachments {
            id
            activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
              activeStorageBlob {
                filename
                contentType
              }
            }
          }
        }
        senderContact {
          email
          name
          id
        }
        senderUser {
          email
          id
          name
        }
        messageAttachments {
          id
          activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
        parentMessage {
          id
          content
          contentAttributes
          senderId
          senderType
          contentType
          createdAt
          messageType
          status
          sourceId
          private
          senderContact {
            email
            name
            id
          }
          senderUser {
            email
            id
            name
          }
        }
    }
  }
`;

export const GetMessageByMessageUuid = gql`
  query GetMessageByMessageUuid($messageUuid: uuid!) {
    messages(where: {uuid: {_eq: $messageUuid}}) {
      id
      uuid
      content
      contentAttributes
      senderId
      senderType
      contentType
      createdAt
      updatedAt
      messageType
      status
      sourceId
      private
      uuid
      parentMessage {
        id
        content
        contentAttributes
        senderId
        senderType
        contentType
        createdAt
        messageType
        status
        sourceId
        private
        uuid
        senderContact {
          email
          name
          id
        }
        senderUser {
          email
          id
          name
        }
        messageAttachments {
          id
          activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
      }
      senderContact {
        email
        name
        id
      }
      senderUser {
        email
        id
        name
      }
      messageAttachments {
        id
        activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
          activeStorageBlob {
            filename
            contentType
          }
        }
      }
      parentMessage {
        id
        content
        contentAttributes
        senderId
        senderType
        contentType
        createdAt
        messageType
        status
        sourceId
        private
        senderContact {
          email
          name
          id
        }
        senderUser {
          email
          id
          name
        }
      }
    }
  }
`;

const GetScheduleMessageData = gql`
  query getScheduleMessageData($conversationUuid: uuid!) {
    messageSchedules(where:{conversationUuid:{_eq: $conversationUuid},isDeleted: {_eq: false}},order_by: {createdAt: desc}){
      id
      conversationUuid
      additionalAttributes
      content
      contentType
      inboxId
      private
      scheduledAt
      senderId
      senderType
      status
      updatedAt
      updatedBy
      workflowEventId
      channelType
      accountUser {
      user {
        name
        id
        uuid
      }
    }
    }
  }
`;

const GetUnreadMessagesById = gql`
  query GetConversationUnreadMessagesById(
    $conversationId: Int!
    $loginUserId: bigint!
    $loginUserIdInt: Int!
    $loginUserUuid: uuid!
  ) {
    conversations(where: {displayId: {_eq: $conversationId}}) {
      id
      uuid
      displayId
      unreadMessages: unreadMsg(
        args: {useruuid: $loginUserUuid}
        where: {
          _or: {senderType: {_is_null: false}, senderId: {_neq: $loginUserId}}
        }
      ) {
        id
        messageType
        messageMentions(where: {userId: {_eq: $loginUserUuid}}) {
          id
        }
      }
      unreadMsgCount: unreadMsgNew(
        args: {
          useruuid: $loginUserUuid,
          userid: $loginUserIdInt,
          messagetype: "{-1}",
          messageconditionin: false
        }
      )
    }
  }
`;
export const GetParentMessageById = gql`
  query GetParentMessageById($conversationId: Int!, $messageId: bigint!) {
    messages(
      where: {conversationId: {_eq: $conversationId}, id: {_eq: $messageId}}
    ) {
      id
      content
      contentAttributes
      senderId
      senderType
      contentType
      createdAt
      messageType
      status
      sourceId
      private
      uuid
      senderContact {
        email
        name
        id
      }
      senderUser {
        email
        id
        name
      }
      messageAttachments {
        id
        activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
          activeStorageBlob {
            filename
            contentType
          }
        }
      }
    }
  }
`;

export const getMessagesByMessageIds = gql`
  query GetMessagesByMessageIds($conversationId: Int!, $messageIds: [bigint!]) {
    messages(
      where: {conversationId: {_eq: $conversationId}, id: {_in: $messageIds}}
    ) {
      id
      content
      contentAttributes
      senderId
      senderType
      contentType
      createdAt
      updatedAt
      messageType
      status
      sourceId
      private
      uuid
      isDeleted
      senderContact {
        email
        name
        id
      }
      senderUser {
        email
        id
        name
      }
      messageAttachments {
        id
        activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
          activeStorageBlob {
            filename
            contentType
          }
        }
      }
    }
  }
`;
export const GET_SCHEDULE_AND_FAILED_MESSAGE_COUNT_BY_STATUS = gql`
  query GET_SCHEDULE_AND_FAILED_MESSAGE_COUNT_BY_STATUS(
    $conversationUuid: uuid!
    $scheduleMsgStatus: String!
    $failedMsgStatus: String!
  ) {
    scheduleMsgCount: messageSchedulesAggregate(
      where: {
        conversationUuid: {_eq: $conversationUuid}
        isDeleted: {_eq: false}
        status: {_eq: $scheduleMsgStatus}
      }
    ) {
      aggregate {
        count
      }
    }
    failedScheduleMsgCount: messageSchedulesAggregate(
      where: {
        conversationUuid: {_eq: $conversationUuid}
        isDeleted: {_eq: false}
        status: {_eq: $failedMsgStatus}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const UPDATE_SCHEDULED_MESSAGE = gql`
mutation UPDATE_SCHEDULED_MESSAGE($id: uuid!, $content: String!) {
  updateMessageSchedules(where: {id: {_eq: $id}}, _set: {content: $content}) {
    returning {
      id
      content
    }
  }
}`;

export const GetMentionsConversationsByMessageAndConversationId = gql`
  query GetMentionsConversationsByMessageAndConversationId(
    $conversationUuid: uuid!
    $userId: uuid!
    $messageUuid: uuid!
  ) {
    conversationMentions(
      where: {
        conversationUuid: {
          _eq: $conversationUuid
        },
        userId: {
          _eq: $userId
        },
        messageUuid: {
          _eq: $messageUuid
        }
      }
    ) {
      id
    }
  }
`;

export const GetIntenalChatConversations = gql`
  query GetConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [conversations_order_by!]
    $whereCondition: conversations_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    conversations(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      contactId
      accountId
      id
      uuid
      displayId
      inboxId
      status
      additionalAttributes
      assigneeId
      createdAt
      lastActivityAt
      lastSmsAt
      isActive
      practiceLocationUuid
      unreadMsgCount: unreadMsgNew(args: $unreadMessagesWhereCount)
      conversationMessages(
        limit: 1
        order_by: {createdAt: desc}
        where: $conversationMessagesWhere
      ) {
        senderType
        senderUser {
          id
          name
        }
        senderId
        contentType
        content
        createdAt
        updatedAt
        private
        messageType
        id
        contentAttributes
        messageAttachments(limit: 1, order_by: {createdAt: desc}) {
          id
          activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
            activeStorageBlob {
              filename
              contentType
            }
          }
        }
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupType {
          code
          description
          value
        }
        groupMembers_aggregate(
          where: {
            isDeleted: {
              _eq: false
            },
          },
          distinct_on: [groupUserId]
        ) {
          aggregate {
            count
          }
        }
        groupMembers(limit: 3, order_by: {user: {name: asc}}) {
          groupUserId
          user {
            name
          }
        }
      }
    }
  }
`;

export const GetMessageDataByMessageUuid = gql`
  query GetMessageDataByMessageUuid($messageUuid: uuid) {
    messages(where: {uuid: {_eq: $messageUuid}}) {
      id
      additionalAttributes
      contentAttributes
      content
      contentType
      createdAt
    }
  }
`;

export const UpdateEFaxMessage = gql`
  mutation UpdateMessages(
    $accountId: Int!
    $messageUuid: [uuid!]
    $contentAttribute: json
  ) {
    updateMessages(
      where: {accountId: {_eq: $accountId}, uuid: {_in: $messageUuid}}
      _set: {contentAttributes: $contentAttribute}
    ) {
      returning {
        id
        contentAttributes
        createdAt
        updatedAt
      }
    }
  }
`;

export const GetConversationAdditionalAttributes = gql`
  query GetConversationAdditionalAttributes($ids: [bigint!]) {
    conversations(where: {id: {_in: $ids}}) {
      id
      conversationInbox {
        channelType
      }
      additionalAttributes
    }
  }
`;

export const GetEmailConversations = gql`
  query GetConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [email_conversation_activities_order_by!]
    $whereCondition: email_conversation_activities_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    emailConversationActivities(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      conversations {
        contactId
        accountId
        id
        uuid
        displayId
        inboxId
        status
        additionalAttributes
        assigneeId
        createdAt
        lastActivityAt
        conversationInbox {
          id
          name
          channelType
          isDeleted
          channelEmail {
            forwardToEmail
            forwardRouteId
            smtpAddress
          }
          channelEfax {
            id
            efaxNumber
          }
          inboxMembers {
            user {
              uuid
              id
              name
            }
          }
        }
        unreadMsgCount: unreadMsgNew(args: $unreadMessagesWhereCount)
        conversationMessages(
          limit: 1
          order_by: {createdAt: desc}
          where: $conversationMessagesWhere
        ) {
          senderType
          senderUser {
            id
            name
          }
          senderContact {
            id
            name
          }
          senderId
          contentType
          content
          createdAt
          updatedAt
          private
          messageType
          id
          contentAttributes
          messageAttachments(limit: 1, order_by: {createdAt: desc}) {
            id
            activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
              activeStorageBlob {
                filename
                contentType
              }
            }
          }
        }
        conversationContact {
          isDeleted
          isActive
          contactType {
            contactType {
              code
              value
              id
            }
            id
          }
          contactPracticeLocations{
            practiceLocationUuid
            accountLocation {
              uuid
            }
          }
          isActive
          accountId
          uuid
          source
          phoneNumber
          pubsubToken
          updatedAt
          name
          email
          id
          additionalAttributes
          patient {
            patientId
            patientUuid
            annualVisitDate
            isWeeklyCheckin
            id
          }
          person {
            chosenName
            pronounId
            firstName
            birthDate
            id
            bloodGroup {
              value
              code
              id
            }
            gender {
              value
              code
            }
            genderId
            birthSexId
            sexAtBirth {
              value
              code
              id
            }
            contactId
            middleName
            lastName
          }
          createdAt
          personContact {
            personContactId
            rank
            value
            id
            personContactType {
              accountId
              value
              id
              categoryId
            }
            typeId
          }
          contactConsents {
            consentId
          }
          lastActivityAt
          identifier
        }
        assignedUser {
          id
          uuid
          email
          name
        }
        groupConversation {
          id
          name
          ownerId
          ownerTypeId
          groupType {
            code
            description
            value
          }
        }
      }
      lastOutgoingEmailAt
    }
  }
`;

export const GetEFaxConversations = gql`
  query GetEFaxConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [efax_conversation_activities_order_by!]
    $whereCondition: efax_conversation_activities_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    efaxConversationActivities(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      conversations {
        contactId
        accountId
        id
        uuid
        displayId
        inboxId
        status
        additionalAttributes
        assigneeId
        createdAt
        lastActivityAt
        conversationInbox {
          id
          name
          channelType
          isDeleted
          channelEmail {
            forwardToEmail
            forwardRouteId
            smtpAddress
          }
          channelEfax {
            id
            efaxNumber
          }
          inboxMembers {
            user {
              uuid
              id
              name
            }
          }
        }
        unreadMsgCount: unreadMsgNew(args: $unreadMessagesWhereCount)
        conversationMessages(
          limit: 1
          order_by: {createdAt: desc}
          where: $conversationMessagesWhere
        ) {
          senderType
          senderUser {
            id
            name
          }
          senderContact {
            id
            name
          }
          senderId
          contentType
          content
          createdAt
          updatedAt
          private
          messageType
          id
          contentAttributes
          messageAttachments(limit: 1, order_by: {createdAt: desc}) {
            id
            activeStorageAttachments(where: {recordType: {_eq: "Attachment"}}) {
              activeStorageBlob {
                filename
                contentType
              }
            }
          }
        }
        conversationContact {
          isDeleted
          isActive
          contactType {
            contactType {
              code
              value
              id
            }
            id
          }
          contactPracticeLocations(
            where: {practiceLocationUuid: {_is_null: false}}
          ) {
            practiceLocationUuid
          }
          isActive
          accountId
          uuid
          source
          phoneNumber
          pubsubToken
          updatedAt
          name
          email
          id
          additionalAttributes
          patient {
            patientId
            patientUuid
            annualVisitDate
            isWeeklyCheckin
            id
          }
          person {
            chosenName
            pronounId
            firstName
            birthDate
            id
            bloodGroup {
              value
              code
              id
            }
            gender {
              value
              code
            }
            genderId
            birthSexId
            sexAtBirth {
              value
              code
              id
            }
            contactId
            middleName
            lastName
          }
          createdAt
          personContact {
            personContactId
            rank
            value
            id
            personContactType {
              accountId
              value
              id
              categoryId
            }
            typeId
          }
          contactConsents {
            consentId
          }
          lastActivityAt
          identifier
        }
        assignedUser {
          id
          uuid
          email
          name
        }
      }
      lastOutgoingEFaxAt
    }
  }
`;

const SearchEmailConversations = gql`
  query GetConversations(
    $limit: Int!
    $offset: Int!
    $whereCondition: email_conversation_activities_bool_exp!
    $orderByObject: [email_conversation_activities_order_by!]
  ) {
    emailConversationActivities(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition,
    ) {
      conversations {
        contactId
        accountId
        id
        uuid
        inboxId
        displayId
        status
        additionalAttributes
        assigneeId
        assignedUser {
          name
          id
        }
        createdAt
        conversationUser {
          email
          name
        }
        conversationInbox {
          id
          channelType
          isDeleted
          inboxMembers {
            user {
              uuid
              id
              name
            }
          }
          channelEmail {
            forwardToEmail
            forwardRouteId
            smtpAddress
          }
        }
        conversationMessages(
          where: {messageType: {_in: [0, 1]}}
          limit: 1
          order_by: {createdAt: desc}
        ) {
          senderId
          senderType
          senderUser {
            id
            name
          }
          content
          createdAt
          updatedAt
          messageType
          id
          contentAttributes
          messageAttachments {
            extension
            externalUrl
            fileType
            id
            updatedAt
          }
        }
        conversationContact {
          isDeleted
          contactPracticeLocations(
            where: {practiceLocationUuid: {_is_null: false}}
          ) {
            uuid
            practiceLocationUuid
            id
            accountLocation {
              uuid
            }
            contactUuid
            practiceLocation {
              name
              practiceCity {
                name
                id
              }
            }
          }
          isActive
          contactTagging {
            labels {
              title
              color
              id
              description
            }
          }
          contactType {
            contactType {
              code
              value
              id
            }
            id
          }
          contactPreferredCommunicationChannel {
            preferredCommunicationModeId
            id
            preferredCommunicationModeMlov {
              code
              value
            }
          }
          contactProfilePhotos {
            attachmentId
          }
          accountId
          uuid
          source
          phoneNumber
          pubsubToken
          updatedAt
          notes {
            contactId
            content
            userId
            id
          }
          name
          email
          id
          patient {
            patientId
            patientUuid
            annualVisitDate
            isWeeklyCheckin
            id
          }
          person {
            chosenName
            pronounId
            firstName
            birthDate
            id
            bloodGroup {
              value
              code
              id
            }
            gender {
              value
            }
            genderId
            birthSexId
            sexAtBirth {
              value
              code
              id
            }
            contactId
            middleName
            lastName
          }
          createdAt
          additionalAttributes
          personContact {
            personContactId
            value
            id
            personContactType {
              accountId
              value
              id
              categoryId
            }
            typeId
            rank
          }
          contactConsents {
            consentId
          }
          lastActivityAt
          identifier
        }
        contactInbox {
          inbox {
            id
            name
            channelTwilioSms {
              phoneNumber
            }
          }
        }
        groupConversation {
          id
          name
          ownerId
          ownerTypeId
          groupType {
            code
            description
            value
          }
          groupConversationSetting {
            canSendMsgByUserTypeId
            editGroupInfoByUserTypeId
          }
          groupMembers {
            id
            groupUserId
            groupUserTypeId
            groupUserType {
              code
              value
            }
            contact {
              isDeleted
              relations {
                relationType {
                  code
                  value
                }
              }
              contactPracticeLocations(
                where: {practiceLocationUuid: {_is_null: false}}
              ) {
                uuid
                practiceLocationUuid
                id
                accountLocation {
                  uuid
                }
                contactUuid
                practiceLocation {
                  name
                  practiceCity {
                    name
                    id
                  }
                }
              }
              contactDeactivationInfo {
                isActive
              }
              isActive
              contactTagging {
                labels {
                  title
                  color
                  id
                  description
                }
              }
              contactType {
                contactType {
                  code
                  value
                  id
                }
                id
              }
              personAddress {
                cityId
                stateId
                zipcodeId
                cities {
                  name
                  id
                }
                zipcodes {
                  code
                  id
                }
                states {
                  name
                  id
                }
                id
              }
              contactProfilePhotos {
                attachmentId
              }
              accountId
              uuid
              phoneNumber
              pubsubToken
              updatedAt
              notes {
                contactId
                content
                userId
                id
              }
              name
              email
              id
              patient {
                patientId
                patientUuid
                annualVisitDate
                isWeeklyCheckin
                id
              }
              person {
                chosenName
                pronounId
                firstName
                birthDate
                id
                bloodGroup {
                  value
                  code
                  id
                }
                gender {
                  value
                }
                genderId
                birthSexId
                sexAtBirth {
                  value
                  code
                  id
                }
                contactId
                middleName
                lastName
              }
              createdAt
              personContact {
                personContactId
                value
                id
                personContactType {
                  accountId
                  value
                  id
                  categoryId
                }
                typeId
                rank
              }
              lastActivityAt
              identifier
            }
            user {
              id
              uuid
              name
              email
            }
          }
        }
      }
    }
  }
`;
const SearchEFaxConversations = gql`
  query SearchEFaxConversations(
    $limit: Int!
    $offset: Int!
    $whereCondition: efax_conversation_activities_bool_exp!
    $orderByObject: [efax_conversation_activities_order_by!]
  ) {
    efaxConversationActivities(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      conversations {
        contactId
        accountId
        id
        uuid
        inboxId
        displayId
        status
        additionalAttributes
        assigneeId
        assignedUser {
          name
          id
        }
        createdAt
        conversationUser {
          email
          name
        }
        conversationInbox {
          id
          channelType
          isDeleted
          inboxMembers {
            user {
              uuid
              id
              name
            }
          }
        }
        conversationMessages(
          where: {messageType: {_in: [0, 1]}}
          limit: 1
          order_by: {createdAt: desc}
        ) {
          senderId
          senderType
          senderUser {
            id
            name
          }
          content
          createdAt
          updatedAt
          messageType
          id
          contentAttributes
          messageAttachments {
            extension
            externalUrl
            fileType
            id
            updatedAt
          }
        }
        conversationContact {
          isDeleted
          contactPracticeLocations(
            where: {practiceLocationUuid: {_is_null: false}}
          ) {
            uuid
            practiceLocationUuid
            id
            accountLocation {
              uuid
            }
            contactUuid
            practiceLocation {
              name
              practiceCity {
                name
                id
              }
            }
          }
          isActive
          contactTagging {
            labels {
              title
              color
              id
              description
            }
          }
          contactType {
            contactType {
              code
              value
              id
            }
            id
          }
          contactPreferredCommunicationChannel {
            preferredCommunicationModeId
            id
            preferredCommunicationModeMlov {
              code
              value
            }
          }
          contactProfilePhotos {
            attachmentId
          }
          accountId
          uuid
          source
          phoneNumber
          pubsubToken
          updatedAt
          notes {
            contactId
            content
            userId
            id
          }
          name
          email
          id
          patient {
            patientId
            patientUuid
            annualVisitDate
            isWeeklyCheckin
            id
          }
          person {
            chosenName
            pronounId
            firstName
            birthDate
            id
            bloodGroup {
              value
              code
              id
            }
            gender {
              value
            }
            genderId
            birthSexId
            sexAtBirth {
              value
              code
              id
            }
            contactId
            middleName
            lastName
          }
          createdAt
          additionalAttributes
          personContact {
            personContactId
            value
            id
            personContactType {
              accountId
              value
              id
              categoryId
            }
            typeId
            rank
          }
          contactConsents {
            consentId
          }
          lastActivityAt
          identifier
        }
        contactInbox {
          inbox {
            id
            name
            channelTwilioSms {
              phoneNumber
            }
          }
        }
      }
      lastIncomingEFaxAt
    }
  }
`;

export const getMentionMessageDataIdData = `
  query getMentionMessageDataIdData($messageId: bigint!) {
    message(id: $messageId) {
      id
      uuid
      createdAt
    }
  }
`;

export const createConversationMentionsByMessage = `
  mutation createConversationMentionsByMessage(
    $mentionDataList: [conversation_mentions_insert_input!]!
  ) {
    createConversationMentions(objects: $mentionDataList) {
      returning {
        id
        isRead
        userTypeId
        userId
        userType {
          code
        }
      }
    }
  }
`;

export const AccountUserList = gql`
  query UserList($id: bigint!) {
    users(
      where: {
        accountUsers: {
          accountId: {
            _eq: $id
          }
        }
      }
    ) {
      name
      uuid
      userRoles {
        id
        userRoleId
        userRole {
          id
          customRoleCode
          roleName
          roleCategory {
            code
          }
          userRole {
            id
            code
            value
          }
        }
      }
    }
  }
`;

const GetBasicContactDetailsByUuid = gql`
  query GET_BASIC_CONTACT_DETAILS_BY_UUID(
    $accountId: Int!
    $contactUuid: uuid!
  ) {
    contacts (
      where: {
        accountId: {_eq: $accountId}
        uuid: {_eq: $contactUuid}
      }
    ){
      id
      uuid
      accountId
      email
      name
      phoneNumberCountryCode
      phoneNumber
      formattedPhoneNumber
      person {
        birthDate
        sexAtBirth {
          code
          value
        }
        pronounData {
          value
        }
      }
      isActive
      isDeleted
    }
  }
`;

const GetContactType = gql`
  query  GET_CONTACT_TYPE_BY_CONTACT_ID(
    $contactId: bigint!
  ) {
    contacts (
      where: {
        id: {_eq: $contactId}
      }
    ){
      id
      contactType {
        typeId
      }
    }
  }
`;

export default {
  GetConversations,
  GetMissCallConversations,
  GetMentionsConversations,
  GetMentionsConversationsById,
  GetConversationMessages,
  GetConversationLastMessages,
  GetConversationByContactId,
  GetConversationById,
  SearchConversations,
  GetMentionsSearchConversations,
  GetRelatedConversation,
  UpdateLastSeenAtConversation,
  UpdateConversationStatus,
  GetConversationUserById,
  GetPrivateMessage,
  GetConversationByConversationId,
  updateConversationLastActivityAt,
  getMessageDataId,
  createConversationMentions,
  GetGroupMembersByGroupId,
  GET_LAST_SEEN_BY_CONVERSATION_ID,
  GROUP_MEMBER_PUPSUB,
  UpdateMessageParentUuid,
  getDefaultConversations,
  DeleteMessageAttachment,
  GetAttachmentsIds,
  GetGroupConversationByUuid,
  GetSingleMessageDataById,
  USER_PUBSUB_TOKEN,
  GetPrevConversationMentionMessages,
  GetNextConversationMentionMessages,
  GetScheduleMessageData,
  getMemberByContact,
  GetAssigneeConversationsById,
  ReadMentionMessage,
  GetMessageByMentionId,
  GetConversationsByUUID,
  GetUnreadMessagesById,
  GetParentMessageById,
  getMessagesByMessageIds,
  GET_SCHEDULE_AND_FAILED_MESSAGE_COUNT_BY_STATUS,
  UPDATE_SCHEDULED_MESSAGE,
  GetConversationByConversationUuid,
  GetMentionsConversationsByMessageAndConversationId,
  GetIntenalChatConversations,
  GetMentionsConversationsByConversationUuId,
  GetMessageDataByMessageUuid,
  GetMessageByMessageUuid,
  UpdateEFaxMessage,
  GetConversationAdditionalAttributes,
  GetConversationByConversationUuidForSubtab,
  GetEmailConversations,
  SearchEmailConversations,
  getMentionMessageDataIdData,
  createConversationMentionsByMessage,
  AccountUserList,
  GetEFaxConversations,
  SearchEFaxConversations,
  GetBasicContactDetailsByUuid,
  GetContactType
};
