import {
  Divider,
  FlatList,
  HStack,
  Pressable,
  Skeleton,
  Spacer,
  Spinner,
  Text,
  useToast,
  View,
  VStack,
  Tooltip,
  IconButton,
  Icon,
  Checkbox
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useIntl } from 'react-intl';
import { notification, UploadFile, Tooltip as AntTooltip } from 'antd';
import debounce from 'lodash/debounce';
import { IDocument } from '../../../../services/Document/DocumentInterfaces';
import { IMediaLibraryData } from '../../../RightSideContainer/ContentManagement/MediaLibrary/interfaces';
import CareStudioService from '../../../../services/CommonService/CareStudioService';
import { CARESTUDIO_APOLLO_CONTEXT, PATIENT_EDUCATION_URL } from '../../../../constants/Configs';
import { DATE_FORMATS, SORT_BY, SORT_ORDER } from '../../../../constants';
import * as DocumentQueries from '../../../../services/Document/DocumentQueries';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { Colors } from '../../../../styles';
import { WORD_DOC_MIME_TYPES } from '../../../common/DocumentViewer/DocumentViewerHelper';
import { getBase64, UploadPatientDocument } from '../../LeftContainer/OtherDetails/PatientDocuments/UploadPatientDocument';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { NoTagsSvg } from '../../../common/Svg';
import FHActionPopover from '../../../common/FHActionPopover/FHActionPopover';
import { DocumentViewer } from '../../../common/DocumentViewer/DocumentViewer';
import { SearchBar } from '../../../common/SearchBar';
import SortIcon from '../../../common/Svg/SortIcon';
import PlusIcon from '../../../common/Svg/PlusSvg';
import { CARE_PLAN_SUB_CATEGORY, DOCUMENT_CATEGORY_CODES, MAX_DOC_TITLE_CHAR_LIMIT } from './DocumentCategoryConstants';
import RightArrowIconForDocuments from '../../../common/Svg/RightArrowIconForDocuments';
import { getDateObjectFromAPIFormatToMyFormat } from '../../../../utils/DateUtils';
import DocumentCategoryIcon from '../../../common/Svg/DocumentCategoryIcon';
import { IJourneyDetails } from './DocumentUtils';
import { IPersonData } from '../interfaces';
import { getEhrConfig, getResourceAbilities, isMarkAsReviewEnabled } from '../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../constants/FhirConstant';
import { ReportCategory } from '../Orders/OrdersAndReports/OrderUtils';
import { getFormDataFromLeadData } from '../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import useReports from '../../../TaskModule/CustomHooks/useReports';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { StyleSheet } from 'react-native';
import { IContact } from '../../../common/CreateChannelView/interface';

type IDocumentCategory = {
  id: string;
  isDefault: boolean;
  code: string;
  value: string;
  description?: string;
}
type IProps = {
  contactUuid?: string;
  category: IDocumentCategory;
  documentsCategories: IDocumentCategory[];
  contactName?: string;
  contactId?: number;
  personData?: IPersonData | IContact;
  unFormattedContactData?: any;
  onDocumentsSelected?: any;
  selectedDocuments?:any;
};

type SortingState = 'none' | 'asc' | 'desc';



export function DocumentsListForSidecar(props: IProps) {
  const mlovData = useContext(CommonDataContext);
  const ehrConfig = getEhrConfig(props?.personData?.accountLocationUuid, '');
  const [searchText, setSearchText] = useState('');
  const labOrderAbilitity = getResourceAbilities(FHIR_RESOURCE.DIAGNOSTIC_REPORT, '', props?.personData?.accountLocationUuid);
  const canShowMarkAsReview = isMarkAsReviewEnabled(props?.personData?.accountLocationUuid);
  const {
    fetchReports,
    loading,
    error,
    fetchAndHandleDataForImagingReport,
    fetchAndHandleDataForAllDoc,
    paginationState,
    setPaginationState,
    componentState,
    setComponentState,
    currentPage,
    setCurrentPage,
  } = useReports({
    patientId: props?.personData?.patientId,
    contactUuid: props?.personData?.contactUUID || '',
    accountLocationUuid: props?.personData?.accountLocationUuid || '',
    isPagination: labOrderAbilitity?.usePagination,
    canShowMarkAsReview: canShowMarkAsReview,
    type: ReportCategory?.IMAGING
  });

  const toast = useToast();


  const setInitialcomponentState = () => {
    setComponentState({
      documentList: [],
      isAddDocumentModalOpen: false,
      isFilterModalOpen: false,
      isDeleteConfirmationOpen: false,
      isLoading: true,
      deleteDocumentLoading: {},
      isFileLoading: {},
      isPreviewVisible: false,
      previewTitle: undefined,
      searchString: '',
      aggregateDocumentCount: 0,
      nameSort: 'none',
      dateSort: 'none',
      careJourneyIdsHavingDocuments: [],
      careJourneyDetailsHavingDocuments: [],
      filteredCareJourneyDetailsHavingDocuments: [],
      selectedJourney: undefined,
      isEFaxDrawerOpen: false,
      isEmailDrawerOpen: false,
      base64DataUploadedDoc: '',
      allDocMergedSortedList: [],
      imagingAllReports: [],
      isImagingReportDesc: false,
      isImagingReportAscByName: false,
      isAllDocDescByDate: false,
      isAllDocAscByName: false,
    }
    )
    setPaginationState({
      pageSize: 10,
      isLoading: false,
      offset: 0,
    })
  };

  const JOURNEY_DOCUMENT_RENDER = "journey_document_render";

  const sortStateMap: Record<SortingState, SortingState> = {
    none: 'asc',
    asc: 'desc',
    desc: 'none',
  };

  const contactDetails = props?.personData;
  let formattedContactData: any = '';
  if (contactDetails) {
    contactDetails.uuid = props?.personData?.contactUUID
    formattedContactData = getFormDataFromLeadData(contactDetails || {}, mlovData);
  }
  const isAllDocument = props?.category?.id === DOCUMENT_CATEGORY_CODES?.ALL_DOCUMENTS;
  const isImagingReport = props?.category?.code === DOCUMENT_CATEGORY_CODES?.IMAGING_REPORTS;
  const isAthenaAndAllDocumentSection = ehrConfig.isAthena && isAllDocument;
  const isAthenaAndImagingReportSection = ehrConfig.isAthena && isImagingReport;

  const careStudioInstance = CareStudioService.getCareStudioServiceInstance();

  const isAnyFileLoading = (obj: Record<string, boolean>) => {
    const value = Object.keys(obj).find(key => {
      const isLoading = obj[key]
      return isLoading
    })
    return !!value
  }

  const [getContactDocumentsQuery, documentsQuery] = useLazyQuery(
    DocumentQueries.GET_PATIENT_DOCUMENTS,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      onCompleted: (response) => {

        const filteredDocuments = response?.getDocuments?.documents?.filter((doc: any) =>
          doc.documentAttachments?.some((attachment: any) => attachment.attachment?.id)
        ) || [];
        const documentList = paginationState?.offset > 0
          ? [...componentState.documentList, ...filteredDocuments]
          : [...filteredDocuments];
        let filteredJourneys: undefined | IJourneyDetails[] = undefined;
        if (searchText) {
          if (componentState?.selectedJourney) {
            filteredJourneys = [];
          }
          else filteredJourneys = componentState?.careJourneyDetailsHavingDocuments?.filter((journey: any) => { return journey.title?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()) });
        }
        else filteredJourneys = componentState?.careJourneyDetailsHavingDocuments;
        setComponentState((prev) => {
          return ({
            ...prev,
            documentList: documentList,
            aggregateDocumentCount: response?.getDocuments?.aggregate?.total,
            ...(filteredJourneys && { filteredCareJourneyDetailsHavingDocuments: filteredJourneys }),
            isLoading: false,
          })
        });
        setPaginationState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      },
      onError: (error) => {
        setComponentState((prev) => ({ ...prev, isLoading: false }));
        setPaginationState((prev) => ({
          ...prev,
          isLoading: false
        }))
        showToast(
          toast,
          'Something went wrong. Please try again later',
          ToastType.error
        );
      },
    }
  );


  const isSkeletonLoading =
    componentState?.isLoading ||
    (documentsQuery?.loading && paginationState?.offset === 0)

  const isNoDocumentFound =
    !(
      componentState?.isLoading ||
      documentsQuery?.loading || loading
    ) &&
    componentState?.documentList?.length === 0 &&
    componentState?.filteredCareJourneyDetailsHavingDocuments?.length === 0;

  const isFlatListDataRender =
    !componentState?.isLoading &&
    !(documentsQuery?.loading && paginationState?.offset === 0) &&
    !loading &&
    (componentState?.documentList?.length > 0 ||
      componentState?.filteredCareJourneyDetailsHavingDocuments?.length > 0);

  function getFileIcon(document?: any) {
    if (document?.documentTypeObject?.code === DOCUMENT_CATEGORY_CODES?.CARE_PLANS) {
      return (
        <View
          padding={2}
          backgroundColor={Colors.Custom.Gray100}
          borderRadius={'50%'}
        >
          <Ionicons name="documents" size={26} color={Colors.Custom.Gray500} />
        </View>
      );
    }
    if (isBelongToPatientEducationContent(document)) {
      return (
        <Ionicons name="documents" size={26} color={Colors.Custom.Gray500} />
      );
    }
    if (!document?.documentAttachments || !document?.documentAttachments.length) {
      return <></>
    }
    if (document?.documentAttachments.length > 1) {
      return (
        <View
          padding={2}
          backgroundColor={Colors.Custom.Gray100}
          borderRadius={'50%'}
        >
          <Ionicons
            name="documents"
            size={26}
            color={Colors.Custom.Gray500}
          />
        </View>
      );
    }

    const documentType = document?.documentAttachments?.[0]?.attachment?.type;

    if (documentType === 'application/pdf') {
      return (
        <View
          padding={2}
          backgroundColor={Colors.FoldPixel.PRIMARY100}
          borderRadius={8}
          borderColor={Colors.Custom.Primary200}
        >
          <MaterialCommunityIcons
            name="file-pdf-box"
            size={32}
            color={Colors.FoldPixel.PRIMARY300}
          />
        </View>
      );
    } else if (documentType?.startsWith('image/')) {
      return (
        <View
        backgroundColor={Colors.FoldPixel.PRIMARY100}
        borderRadius={8}
        borderColor={Colors.Custom.Primary200}
        >
          <MaterialCommunityIcons
            name="file-image"
            size={26}
            color={Colors.FoldPixel.PRIMARY300}
          />
        </View>
      );
    } else if (documentType && WORD_DOC_MIME_TYPES.includes(documentType)) {
      return (
        <View
        backgroundColor={Colors.FoldPixel.PRIMARY100}
        borderRadius={8}
        borderColor={Colors.Custom.Primary200}
        >
          <MaterialCommunityIcons
            name="file-word"
            size={26}
            color={Colors.FoldPixel.PRIMARY300}
          />
        </View>
      );
    }
    return (
      <Ionicons name="document-text" size={26} color={Colors.Custom.Gray500} />
    );
  }

  const getFetchDocParamsQuery = (args?: { offset?: number, careJourneyId?: string, searchString?: string, nameSort?: string, dateSort?: string }) => {
    const paramsForQuery: any = {
      subjectId: props?.contactUuid,
      ...(props?.category?.id !== DOCUMENT_CATEGORY_CODES.ALL_DOCUMENTS && { documentTypeId: props?.category?.id }),
      limit: 10,
      offset: args?.offset ?? paginationState.offset,
      searchString: args?.searchString !== undefined ? `%${args?.searchString}%` : `%${searchText}%`,
      ...(!!args?.nameSort?.length ? args?.nameSort !== 'none' && {
        orderBy: {
          caption: args?.nameSort
        }
      } : componentState?.nameSort !== 'none' && {
        orderBy: {
          caption: componentState.nameSort
        }
      }),
      ...(!!args?.dateSort?.length ? args?.dateSort !== 'none' && {
        orderBy: {
          updatedOn: args?.dateSort
        }
      } : componentState?.dateSort !== 'none' && {
        orderBy: {
          updatedOn: componentState.dateSort
        }
      }),
      ...((args?.careJourneyId !== 'false' && (args?.careJourneyId || componentState?.selectedJourney?.id)) &&
        { patientCareJourneyId: args?.careJourneyId || componentState?.selectedJourney?.id }),

      ...((args?.careJourneyId !== 'false' && (args?.careJourneyId || componentState?.selectedJourney?.id)) ?
        { subCategory: [CARE_PLAN_SUB_CATEGORY.PATIENT_CARE_JOURNEY, CARE_PLAN_SUB_CATEGORY.SUPPORTING_JOURNEY_DOCUMENTS] } :
        props?.category?.code === DOCUMENT_CATEGORY_CODES?.CARE_PLANS &&
        { subCategory: [CARE_PLAN_SUB_CATEGORY.CARE_JOURNEY] })
    }
    return paramsForQuery;

  }

  async function fetchDocuments(args?: { offset?: number, careJourneyId?: string, searchString?: string, nameSort?: string, dateSort?: string }) {
    if (props?.contactUuid) {
      const paramsForQuery: any = getFetchDocParamsQuery({ offset: args?.offset, careJourneyId: args?.careJourneyId, searchString: args?.searchString, nameSort: args?.nameSort, dateSort: args?.dateSort })
      if (!paramsForQuery?.orderBy) {
        paramsForQuery.orderBy = {
          updatedOn: 'desc'
        }
      }
      await getContactDocumentsQuery({
        variables: {
          params: paramsForQuery
        }
      }
      );
    }
  }

  const getActions = () => {
    return [
      {
        code: 'VIEW',
        name: 'View',
      }
    ]
  }

  useEffect(() => {
    setComponentState((prev) => ({ ...prev, isLoading: true, documentList: [] }));

    if (isAthenaAndAllDocumentSection) {
      setCurrentPage(1);
      fetchAndHandleDataForAllDoc({
        currentPageOnUI: 1,
        sort: {
          by: SORT_BY.DATE,
          order: SORT_ORDER.DESC,
        },
        searchString: searchText,
      });
    } else if (isAthenaAndImagingReportSection) {
      setCurrentPage(1);
      fetchAndHandleDataForImagingReport({
        currentPageOnUI: 1,
        sort: {
          by: SORT_BY.DATE,
          order: SORT_ORDER.DESC
        },
        searchString: searchText,
      });
    } else {
      fetchDocuments({ searchString: searchText, offset: 0 });
    }
  }, [searchText]);

  useEffect(() => {
    setInitialcomponentState();
    if (isAthenaAndAllDocumentSection) {
      setSearchText('');
      setCurrentPage(1);
      fetchAndHandleDataForAllDoc({
        currentPageOnUI: 1,
        sort: {
          by: SORT_BY.DATE,
          order: !componentState.isAllDocDescByDate
            ? SORT_ORDER.DESC
            : SORT_ORDER.ASC,
        },
      });
    } else if (isAthenaAndImagingReportSection) {
      setSearchText('');
      setCurrentPage(1);
      fetchAndHandleDataForImagingReport({
        currentPageOnUI: 1,
        sort: {
          by: SORT_BY.DATE,
          order: !componentState.isImagingReportDesc
            ? SORT_ORDER.DESC
            : SORT_ORDER.ASC,
        },
      });
    } else {
      setSearchText('');
      fetchDocuments({
        offset: 0,
        careJourneyId: 'false',
        searchString: '',
        nameSort: 'none',
        dateSort: 'none',
      });
    }
  }, [props?.category?.id, props?.category?.code])


  async function downloadFileAsBlob(patientDocument: IDocument, file: any, downloadFile = false) {
    const attachmentId: string = (file as any).id;
    const service = careStudioInstance.careStudioAxiosService;
    setComponentState((prev) => {
      prev.isFileLoading[patientDocument?.id] = true;
      return { ...prev, isFileLoading: prev.isFileLoading };
    });

    try {
      if (!file.blobUrl || WORD_DOC_MIME_TYPES.includes(file.type)) {
        const response = await service.get(`/attachment/${attachmentId}/file?isPreview=${downloadFile === false}`, {
          responseType: 'blob',
        });

        file.blob = response.data;
        file.blobUrl = URL.createObjectURL(file.blob);
        file.preview = await getBase64(response.data);
      }

      if (downloadFile) {
        if (isWeb()) {
          const link = document.createElement('a');
          link.href = file.blobUrl;
          link.download = file.name;
          link.click();
        } else {
          showToast(toast, 'Unable to download file', ToastType.error);
        }
      }

      setComponentState((prev) => {
        prev.isFileLoading[patientDocument?.id] = false;
        return { ...prev, isFileLoading: prev.isFileLoading };
      });
    } catch (error) {

      showToast(toast, 'Error while opening file', ToastType.error);
      setComponentState((prev) => {
        prev.isFileLoading[patientDocument?.id] = false;
        return { ...prev, isFileLoading: prev.isFileLoading };
      });
    }
  }

  async function handlePreview(document: IDocument, file?: any) {
    if (componentState.isFileLoading[document?.id]) {
      return;
    }
    if (document?.referenceData?.entity?.slug) {
      const slug = document?.referenceData?.entity?.slug;
      const url = PATIENT_EDUCATION_URL + slug;
      window.open(url, '_blank', 'noopener,noreferrer');
    }
    else if (file) {
      let fileUrl = '';

      if ((file as any).id && (!(file as any).blobUrl || WORD_DOC_MIME_TYPES.includes(file.type as string))) {
        await downloadFileAsBlob(document, file);
      }

      const fileMediaData: IMediaLibraryData = {
        ...file,
        lastUpdated: file.lastModified?.toString() || '',
        mime: file.type as string,
        name: file.name,
        size: file.size as number,
      };

      if (file.type) {
        fileUrl = (file as any).blobUrl;
      }

      setComponentState((prev) => ({
        ...prev,
        isPreviewVisible: true,
        previewTitle: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
        ...(file.type && fileUrl &&
        {
          previewObj: {
            url: fileUrl || '',
            type: file.type as string,
            fileMediaData: fileMediaData,
          },
        }
        ),
      }));
    }
  }

  function handlePreviewCancel(value = false) {
    setComponentState((prev) => ({
      ...prev,
      previewObj: undefined,
      isPreviewVisible: value,
      previewTitle: undefined,
      previewImage: undefined,
    }));
  }

  const handleEndReached = () => {
    if (!paginationState.isLoading) {
      setPaginationState((prev) => {
        const newOffset = prev.offset + prev.pageSize;
        if (componentState.aggregateDocumentCount && (componentState.aggregateDocumentCount > newOffset)) {
          if (isAthenaAndAllDocumentSection) {
            setCurrentPage((prevPage) => prevPage + 1);
            fetchAndHandleDataForAllDoc({
              currentPageOnUI: currentPage + 1,
              sort: {
                by: SORT_BY.DATE,
                order: !componentState.isAllDocDescByDate
                  ? SORT_ORDER.DESC
                  : SORT_ORDER.ASC,
              },
            });
          } else if (isAthenaAndImagingReportSection) {
            setCurrentPage((prevPage) => prevPage + 1);
            fetchAndHandleDataForImagingReport({
              currentPageOnUI: currentPage + 1,
              sort: {
                by: SORT_BY.DATE,
                order: !componentState.isImagingReportDesc
                  ? SORT_ORDER.DESC
                  : SORT_ORDER.ASC,
              },
            });
          } else {
            fetchDocuments({ offset: newOffset });
          }
          return {
            ...prev,
            isLoading: true,
            offset: newOffset,
          }

        }
        else {
          return prev
        }
      })
    }
    setComponentState((prev) => ({ ...prev, isLoading: false }))
  };
  const intl = useIntl();
  const isBelongToPatientEducationContent = (document: any) => {
    return !!document?.referenceData?.entity?.slug;
  }
  const renderImagingDocumentItem = (documentObject: any) => {
    const document = documentObject?.item;
    const latestReport = document?.resources?.[0]
    const documentName = document?.display;
    return (
      <View
        backgroundColor={Colors.Custom.BackgroundColor}
        key={latestReport?.id}
        minHeight={70}
      >
        {
          <HStack
            space={1}
            paddingY={3}
            paddingLeft={3}
            paddingRight={1}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <View width={12} alignItems={'center'}>
              <Pressable
              >
                <DocumentCategoryIcon
                  categoryCode={DOCUMENT_CATEGORY_CODES.IMAGING_REPORTS}
                />
              </Pressable>
            </View>

            <View flex={1}>
              <Pressable
              >
                <VStack flex={2}>
                  <View width={'90%'} flex={1}>
                    <HStack alignItems={'center'}>
                      <Text
                        fontSize="xs"
                        color="gray.500"
                        isTruncated={true}
                        noOfLines={1}
                        marginLeft={1}
                      >
                        {document?.documentType?.value}
                      </Text>
                    </HStack>
                  </View>
                  <View width={'90%'} flex={1}>
                    <Text
                      fontSize={14}
                      fontWeight={500}
                      marginLeft={1}
                      isTruncated={true}
                      noOfLines={1}
                    >
                      {document?.caption || documentName}
                    </Text>
                  </View>
                  {!!(
                    document?.patientCareJourney?.title ||
                    document?.patientCareJourney?.careJourney?.title
                  ) && (
                      <View width={'90%'} flex={1}>
                        <HStack alignItems={'center'} space={1}>
                          <DocumentCategoryIcon
                            categoryCode={JOURNEY_DOCUMENT_RENDER}
                          />
                          <Text
                            fontSize="xs"
                            color="gray.500"
                            isTruncated={true}
                            noOfLines={1}
                          >
                            {document?.patientCareJourney?.title ||
                              document?.patientCareJourney?.careJourney?.title}
                          </Text>
                        </HStack>
                      </View>
                    )}
                </VStack>
              </Pressable>
            </View>
            <View marginRight={2}>
              {document?.effectiveDateTime && (
                <Text
                  fontSize="xs"
                  color="gray.500"
                  isTruncated={true}
                  noOfLines={1}
                  marginLeft={1}
                >
                  {getDateObjectFromAPIFormatToMyFormat(
                    document?.effectiveDateTime,
                    DATE_FORMATS.DOCUMENT_DATE_FORMAT
                  )}
                </Text>
              )}
            </View>
            <View width={6} alignItems={'center'}>
              <FHActionPopover
                actions={getActions()}
                onActionClick={(code, event) => {
                  const isAnyDocFileLoading = isAnyFileLoading(
                    componentState.isFileLoading
                  );
                  if (isAnyDocFileLoading) {
                    notification.destroy();
                    notification.info({
                      message: intl.formatMessage({ id: 'otherDocLoading' }),
                      duration: 3.0,
                      placement: 'top',
                    });
                  }
                }}
              />
            </View>
          </HStack>
        }
        <Divider />
      </View>
    );
  };
  const renderDocItem = (documentObject: any) => {
    if (isAthenaAndAllDocumentSection) {
      return renderAllDocItem(documentObject);
    } else if (isAthenaAndImagingReportSection) {
      return renderImagingDocumentItem(documentObject);
    } else {
      return renderDocumentItem(documentObject);
    }
  };

  const renderAllDocItem = (documentObject: any) => {
    const document = documentObject.item;
    if (
      document?.resources?.[0]?.category[0]?.coding?.[0]?.code === 'IMAGING'
    ) {
      return renderImagingDocumentItem(documentObject);
    } else {
      return renderDocumentItem(documentObject);
    }
  };

  const renderDocumentTitleTextElem = () => {
    return (
      <Text
        style={styles.documentTitleText}
        isTruncated={true}
        maxW={'100%'}
      >
        {props?.category?.value}
      </Text>
    );
  }

  const getTitleOfDocumentList = () => {
    const isLargeText = props?.category?.value.length > MAX_DOC_TITLE_CHAR_LIMIT;
    return (
      <HStack
        style={styles.documentListTitleContainer}
        maxW="100%"
      >
        <HStack maxWidth={'90%'}>
          <Pressable maxW={'100%'} onPress={() => {
            setComponentState((prev) => ({
              ...prev,
              selectedJourney: undefined,
              nameSort: 'none',
              dateSort: 'none',
              searchString: ''
            }));
            setSearchText('')
            setPaginationState((prev) => ({ ...prev, offset: 0 }));
            fetchDocuments({ careJourneyId: 'false', searchString: '', nameSort: 'none', dateSort: 'none', offset: 0 });
          }
          }
            flexDir={'row'}
          >
            {isLargeText ? (
              <Tooltip label={props?.category?.value}>
                {renderDocumentTitleTextElem()}
              </Tooltip>
            ) : (
              renderDocumentTitleTextElem()
            )}
            {componentState?.selectedJourney?.title &&
              <RightArrowIconForDocuments />
            }
          </Pressable>
          {componentState?.selectedJourney?.title &&
            <Tooltip label={componentState?.selectedJourney?.title}>
              <Text
                style={styles.selectedJourneyTitleText}
                isTruncated={true}
              >
                {componentState?.selectedJourney?.title}
              </Text>
            </Tooltip>
          }
        </HStack>
        <Spacer />
        <Pressable onPress={() => {
          setComponentState((prev) => ({
            ...prev,
            isAddDocumentModalOpen: true,
            selectedDocument: undefined,
          }));
        }}>
          <PlusIcon defaultColor={Colors?.Custom?.Gray500} />
        </Pressable>

        {/* Add button logic KB */}
      </HStack>
    )
  }

  const handleOnChangeOfSearchAction = (searchString: any) => {
    setSearchText(searchString);
  }

  const selectedCategoryIdForUpload = () => {
    const selectedCategoryId = componentState?.selectedDocument?.documentTypeId || props?.category?.id;
    if (selectedCategoryId === DOCUMENT_CATEGORY_CODES.ALL_DOCUMENTS) {
      return undefined;
    }
    return selectedCategoryId;
  }

  const renderDocumentItem = (documentObject: any) => {
    const document = documentObject.item;
    const documentName = document?.notes || document?.documentAttachments
        ?.map((documentAttachment: any) => documentAttachment?.attachment?.name)
        .filter((documentName: any) => !!documentName)
        .join(', ');
    const isSelected = props?.selectedDocuments.some((doc: any) => doc?.id == document.id);
    const toggleDocumentSelection = () => { props.onDocumentsSelected(document) };
    return (
      <View
        backgroundColor={Colors.Custom.BackgroundColor}
        key={document.id}
        minHeight={70}
      >
        <HStack space={1} paddingY={3} paddingLeft={3} paddingRight={1} alignItems={'center'} justifyContent={'center'}>
          <View width={6} alignItems={'center'}>
            <Checkbox
              isChecked={isSelected}
              onChange={toggleDocumentSelection}
              value={document.id.toString()}
              accessibilityLabel="Select Document"
              mt="2"
              colorScheme="purple"
            />
          </View>


          <View width={12} alignItems={'center'}>
            <Pressable onPress={toggleDocumentSelection}>
              {getFileIcon(document)}
            </Pressable>
          </View>
          <View flex={1}>
            <Pressable onPress={toggleDocumentSelection}>
              <VStack flex={2}>
                <View width={'90%'} flex={1}>
                  <HStack alignItems={'center'}>
                    <Text color={Colors.FoldPixel.GRAY250} fontSize="xs" isTruncated={true} noOfLines={1} marginLeft={1}>
                      {document?.documentType?.value}
                    </Text>
                  </HStack>
                </View>
                <View width={'90%'} flex={1}>
                  <Text color={Colors.FoldPixel.GRAY400} fontSize={14} fontWeight={500} marginLeft={1} isTruncated={true} noOfLines={1}>
                    {document?.caption || documentName}
                  </Text>
                </View>
                {!!(document?.patientCareJourney?.title || document?.patientCareJourney?.careJourney?.title) &&
                  <View width={'90%'} flex={1}>
                    <HStack alignItems={'center'} space={1}>
                      <DocumentCategoryIcon categoryCode={JOURNEY_DOCUMENT_RENDER} />
                      <Text fontSize="xs" color="gray.500" isTruncated={true} noOfLines={1}>
                        {document?.patientCareJourney?.title || document?.patientCareJourney?.careJourney?.title}
                      </Text>
                    </HStack>
                  </View>
                }
              </VStack>
            </Pressable>
          </View>
          <View marginRight={2}>
            {document?.updatedOn && (
              <Text fontSize="xs" color="gray.500" isTruncated={true} noOfLines={1} marginLeft={1}>
                {getDateObjectFromAPIFormatToMyFormat(document?.updatedOn, DATE_FORMATS.DOCUMENT_DATE_FORMAT)}
              </Text>
            )}
          </View>
          {componentState.isFileLoading[document?.id] && (
            <View width={8}>
              <Spinner size="sm" />
            </View>
          )}
          <View width={6} alignItems={'center'}>
            <AntTooltip title='View Document'>
              <IconButton
                width={8}
                colorScheme="gray"
                variant="ghost"
                onPress={() => {
                  const isAnyDocFileLoading = isAnyFileLoading(componentState.isFileLoading);
                  if (isAnyDocFileLoading) {
                    notification.destroy();
                    notification.info({
                      message: intl.formatMessage({ id: 'otherDocLoading' }),
                      duration: 3.0,
                      placement: 'top'
                    });
                  } else {
                    if (document?.referenceData?.entity?.slug) {
                      handlePreview(document);
                    }
                    else if (document?.documentAttachments?.length === 1 && document.documentAttachments[0]?.attachment) {
                      const file = {
                        id: document?.documentAttachments[0].attachment.id,
                        uid: document?.documentAttachments[0].attachment.id,
                        name: document?.documentAttachments[0].attachment.name,
                        type: document?.documentAttachments[0].attachment.type,
                        status: 'done',
                      } as any;
                      handlePreview(document, file);
                    } else {
                      setComponentState((prev) => ({
                        ...prev,
                        isAddDocumentModalOpen: true,
                        selectedDocument: document,
                      }));
                    }
                  }
                }}
                icon={
                  <Icon
                    as={AntIcon}
                    name="eye"
                    size={4}
                    color={Colors.Custom.Gray600}
                  />
                }
              />
            </AntTooltip>
          </View>
        </HStack>
        <Divider />
      </View>
    );
  };

  return (
    <>
      <VStack borderRadius={8} marginBottom={4} maxHeight={'100%'}>
        {getTitleOfDocumentList()}
        <HStack alignItems={'center'}>
          <SearchBar
            flex={1}
            searchText={searchText}
            placeholderText={`Search in ${componentState?.selectedJourney?.title || props?.category?.value
              }`}
            onChange={debounce(handleOnChangeOfSearchAction, 500)}
            borderRadius={0}
          />
        </HStack>
        {(componentState?.documentList?.length > 0) &&
          (
            <View>
              <HStack
                style={styles.hStackContainer}
              >
                <Text
                  style={styles.lastUpdatedText}
                >
                  {'File Name'}
                </Text>
                <Pressable
                  onPress={() => {
                    if (isAthenaAndImagingReportSection) {
                      fetchAndHandleDataForImagingReport({
                        currentPageOnUI: 1,
                        sort: {
                          by: SORT_BY.NAME,
                          order: componentState.isImagingReportAscByName
                            ? SORT_ORDER.DESC
                            : SORT_ORDER.ASC,
                        },
                        searchString: searchText,
                      });
                    }
                    else if (isAthenaAndAllDocumentSection) {
                      fetchAndHandleDataForAllDoc({
                        currentPageOnUI: 1,
                        sort: {
                          by: SORT_BY.NAME,
                          order: componentState.isAllDocAscByName
                            ? SORT_ORDER.DESC
                            : SORT_ORDER.ASC,
                        },
                        searchString: searchText,
                      });
                    }
                    else {
                      const nextSortOrder =
                        sortStateMap[componentState?.nameSort];
                      setPaginationState((prev) => {
                        return {
                          ...prev,
                          offset: 0,
                        };
                      });
                      setComponentState((prev) => ({
                        ...prev,
                        nameSort: nextSortOrder,
                        dateSort: 'none',
                      }));
                      fetchDocuments({
                        nameSort: nextSortOrder,
                        dateSort: 'none',
                        offset: 0,
                      });
                    }
                  }}
                  style={styles.sortIconPressable}
                >
                  <SortIcon sortMode={componentState?.nameSort} />
                </Pressable>
                <Spacer />
                <Text
                  style={styles.lastUpdatedText}
                >
                  {'Last Updated'}
                </Text>
                <Pressable
                  onPress={() => {
                    if (isAthenaAndImagingReportSection) {
                      fetchAndHandleDataForImagingReport({
                        currentPageOnUI: 1,
                        sort: {
                          by: SORT_BY.DATE,
                          order: !componentState.isImagingReportDesc
                            ? SORT_ORDER.DESC
                            : SORT_ORDER.ASC,
                        },
                        searchString: searchText,
                      });
                    } else if (isAthenaAndAllDocumentSection) {
                      fetchAndHandleDataForAllDoc({
                        currentPageOnUI: 1,
                        sort: {
                          by: SORT_BY.DATE,
                          order: !componentState.isAllDocDescByDate
                            ? SORT_ORDER.DESC
                            : SORT_ORDER.ASC,
                        },
                        searchString: searchText,
                      });
                      setPaginationState((prev) => ({ ...prev, offset: 0, pageSize: 10 }));
                    } else {
                      const nextSortOrder =
                        sortStateMap[componentState?.dateSort];
                      setPaginationState((prev) => {
                        return {
                          ...prev,
                          offset: 0,
                        };
                      });
                      setComponentState((prev) => ({
                        ...prev,
                        dateSort: nextSortOrder,
                        nameSort: 'none',
                      }));
                      fetchDocuments({
                        nameSort: 'none',
                        dateSort: nextSortOrder,
                        offset: 0,
                      });
                    }
                  }}
                  style={styles.sortIconPressable}
                >
                  <SortIcon sortMode={componentState?.dateSort} />
                </Pressable>
              </HStack>
              <Divider />
            </View>
          )}
        {isSkeletonLoading && <Skeleton.Text lines={3} />}
        {isNoDocumentFound && (
          <View backgroundColor={Colors.Custom.BackgroundColor}>
            <NoTagsSvg titleId="patientDocumentsNoDocumentsFound" />
          </View>
        )}

        {isFlatListDataRender && (
          <FlatList
            data={componentState?.documentList}
            keyExtractor={(item: any) => item?.id}
            renderItem={renderDocItem}
            onEndReached={(data) => {
              if (data?.distanceFromEnd < 70) {
                handleEndReached();
              }
            }}
            onEndReachedThreshold={0.1}
            ListFooterComponent={
              paginationState.isLoading ? (
                <Spinner size={'lg'} my={2} />
              ) : null
            }
            maxHeight={'100%'}
          />
        )}
      </VStack>
      {/* to view document */}
      {componentState.previewTitle &&
        componentState.previewObj?.type &&
        componentState.isPreviewVisible && (
          <DocumentViewer
            fileName={componentState.previewTitle}
            fileType={componentState.previewObj.type}
            onClose={handlePreviewCancel}
            fileUrl={componentState.previewObj.url}
            isOpen={true}
            base64Data={componentState.previewObj?.fileMediaData?.preview}
            uniqueResourceIdentifier={componentState.previewObj?.fileMediaData?.id}
            patientId={props?.personData?.patientId}
          />
        )}
      <>
        {/* to upload an document */}
        {props?.contactUuid && componentState.isAddDocumentModalOpen && (
          <UploadPatientDocument
            personData={props?.personData}
            contactId={props?.contactUuid}
            selectedCategoryId={selectedCategoryIdForUpload()}
            document={componentState.selectedDocument}
            isVisible={true}
            onCancel={() => {
              setComponentState((prev) => ({
                ...prev,
                isAddDocumentModalOpen: false,
                selectedDocument: undefined,
              }));
            }}
            documentsCategories={props?.documentsCategories.filter(
              (category) =>
                category.id !== DOCUMENT_CATEGORY_CODES.ALL_DOCUMENTS
            )}
            onUpload={(data: any, documentTypeId: any) => {
              setComponentState((prev) => {
                setPaginationState((prev) => {
                  return { ...prev, offset: 0 };
                });
                if (isAthenaAndAllDocumentSection) {
                  fetchAndHandleDataForAllDoc({
                    currentPageOnUI: 1,
                    sort: {
                      by: SORT_BY.DATE,
                      order: SORT_ORDER.DESC,
                    },
                  });
                } else if (isAthenaAndImagingReportSection) {
                  fetchAndHandleDataForImagingReport({
                    currentPageOnUI: 1,
                    sort: {
                      by: SORT_BY.DATE,
                      order: SORT_ORDER.DESC,
                    },
                  });
                } else {
                  fetchDocuments({ offset: 0 });
                }
                return {
                  ...prev,
                  isAddDocumentModalOpen: false,
                  selectedDocument: undefined,
                };
              });
              if (
                props?.category?.id !== DOCUMENT_CATEGORY_CODES.ALL_DOCUMENTS &&
                (documentTypeId !== props?.category?.id ||
                  !!componentState?.selectedJourney)
              ) {
                notification.destroy();
                notification.info({
                  message:
                    'The document is created but is not visible. View details.',
                  style: {
                    width: 500,
                    cursor: 'pointer',
                  },
                  onClick() {
                    notification.destroy();
                    setComponentState((prev) => ({
                      ...prev,
                      isAddDocumentModalOpen: true,
                      selectedDocument: {
                        ...data,
                        documentTypeId: documentTypeId,
                      },
                    }));
                  },
                  duration: 3000,
                });
              } else {
                showToast(
                  toast,
                  `Document ${!!componentState.selectedDocument?.id
                    ? 'updated'
                    : 'uploaded'
                  } successfully`,
                  ToastType.success
                );
              }
            }}
          />
        )}
      </>
    </>
  );
}

const styles = StyleSheet.create({
  sortIconPressable: {
    justifyContent: 'center',
    marginLeft: 2,
  },
  lastUpdatedText: {
    color: '#667085',
    size: 'smBold',
    lineHeight: 21,
  },
  hStackContainer: {
    borderLeftColor: Colors.Custom.primary300,
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderTopColor: Colors.Custom.gray150,
    borderBottomColor: Colors.Custom.gray150,
    paddingLeft: 16,
    paddingRight: 60,
    paddingVertical: 12,
  },
  fileNameContainer: {
    color: '#667085',
    size: 'smBold',
    lineHeight: 21,
  },
  documentTitleText: {
    color: Colors.FoldPixel.GRAY300,
    size: 'smDarkBold',
    lineHeight: 27,
  },
  documentListTitleContainer: {
    borderLeftColor: '#7F56D9',
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderTopColor: '#EEEEEE',
    borderBottomColor: '#EEEEEE',
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  selectedJourneyTitleText: {
    color: Colors?.Custom?.Gray500,
    size: 'mdBold',
    lineHeight: 27,
  },
});

export default DocumentsListForSidecar;
