import { IContactRule } from "../../ContactRules/interfaces";

export enum GoalOperator {
  gte = 'gte',
  lte = 'lte',
  gt = 'gt',
  lt = 'lt',
  eq = 'eq',
  between = 'between',
}

export type GoalTargetType = 'text' | 'number' | 'measure';


export enum GoalViewType {
  edit = 'EDIT', list = 'LIST', track = 'TRACK'
}

export interface IGoalTarget {
  id?: string;
  title: string;
  formId?: string;
  entityCode?: string;
  entityTypeId?: string;
  statusId?: string;
  unit?: string;
  measure?: {
    targetType: GoalTargetType;
    targetValue?: { high?: number | string; low?: number | string; value?: number | string };
    operator?: GoalOperator;
    unit?: string;
    frequency?: {
      code: string;
    }
  };
}

export enum IGoalType {
  ABSOLUTE = 'ABSOLUTE',
  RELATIVE = 'RELATIVE',
}

export interface IGoal {
  id?: string;
  title: string;
  contactId?: string;
  goalTargets: IGoalTarget[];
  isDeleted?: boolean;
  categoryId?: string;
  statusId?: string;
  unit?: string;
  contactRules?: IContactRule[];
  duration?: number;
  durationUnitId?: string;

  // view specific
  viewType?: GoalViewType;
  templateId?: string;
  temporaryTemplateId?: string;
  status? : {
    code : string;
  }
  isFirstEdit?: boolean;
  category?: {
    code?: string;
    value?: string;
  }
  goalType?: IGoalType;
  goalProgress?: number;
}


export interface IGoalTargetParams {
  patientId: string;
  patientContactUuid: string;
  locationId: string;
  goalTarget: IGoalTarget;
  goalEntityTypeIds: {[index: string]: string};
}

export interface IUsetGoalTargetState {
  lastRecordedValue: string;
  loading: boolean;
  lastRecordedDate: string;
}
