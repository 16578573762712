import React, {useContext} from 'react';
import {Popover} from 'antd';
import {Pressable, Spinner, TextArea, Text} from 'native-base';
import {useIntl} from 'react-intl';
import {View} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  CARE_PROGRAM_TYPE_CODES,
  MLOV_CATEGORY,
} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {IMlov} from '../../../../Interfaces/CommonInterfaces';
import {Colors} from '../../../../styles/Colors';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';
import CustomDrawer from '../../../common/CustomDrawer/CustomDrawer';
import Stack from '../../../common/LayoutComponents/Stack';
import MenuHorizontalIcon from '../../../common/Svg/MenuHorizontalIcon';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import RadioButton from '../../../CommonComponents/RadioButton';
import {useCustomToast} from '../../../Toast/ToastProvider';
import {useCareProgramCloseAction} from '../../CareManagementView/CareManagementViewHooks/useCareProgramCloseAction';
import {
  CareManagementThreeDotsMenuType,
  CareProgramReasonCodes,
} from './consts';
import {ICareManagementThreeDotsMenuProps} from '../interface';
import { useCadence } from './components/Cadence/useCadence';
import CustomModal from '../../../common/CustomConfirmationModal/CustomModal';
import { testID, TestIdentifiers } from '../../../../testUtils';

const CareManagementThreeDotsMenu = (
  props: ICareManagementThreeDotsMenuProps
) => {
  const {
    isUpcomingCareProgramAppointmentsExist,
    isCareProgramAppointmentsLoading
  } = useCadence({contactUuid: props?.contactId});
  const intl = useIntl();
  const {careProgramTypeCode} = props;
  const customToast = useCustomToast();
  const commonData = useContext(CommonDataContext);
  const CARE_PROGRAM_CLOSED_STATUS_REASON =
    getMlovListFromCategory(
      commonData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_CLOSED_STATUS_REASON
    ) || [];
  const {onCloseCareProgram, isLoading} = useCareProgramCloseAction();
  const [stateData, updateStateData] = React.useState<{
    popoverVisible: boolean;
    isFormValid: boolean;
    selectedReason: IMlov | null;
    note: string;
    activeDrawerCode: CareManagementThreeDotsMenuType | null;
    showCancelCareProgramWarning: boolean;
  }>({
    popoverVisible: false,
    isFormValid: false,
    selectedReason: null,
    note: '',
    activeDrawerCode: null,
    showCancelCareProgramWarning: false,
  });

  const getReasonesList = React.useCallback(() => {
    const reasonCategories = {
      [CARE_PROGRAM_TYPE_CODES.TCM]: MLOV_CATEGORY.TCM_CLOSED_STATUS_REASON,
      [CARE_PROGRAM_TYPE_CODES.CCM]: MLOV_CATEGORY.CCM_CLOSED_STATUS_REASON,
      [CARE_PROGRAM_TYPE_CODES.AWV]: MLOV_CATEGORY.AWV_CLOSED_STATUS_REASON,
    };

    const selectedCategory = reasonCategories[careProgramTypeCode];
    const closedStatusReasonList = selectedCategory
      ? getMlovListFromCategory(
          commonData.CARE_STUDIO_MLOV,
          selectedCategory
        ) || []
      : [];

    return closedStatusReasonList.concat(CARE_PROGRAM_CLOSED_STATUS_REASON);
  }, [careProgramTypeCode]);

  const checkFormValidation = React.useCallback(() => {
    const noteLength = stateData.note?.trim()?.length;
    if (
      stateData.selectedReason?.code === CareProgramReasonCodes.OTHER &&
      noteLength > 0
    ) {
      return true;
    } else if (
      stateData.selectedReason &&
      stateData.selectedReason?.code !== CareProgramReasonCodes.OTHER
    ) {
      return true;
    }
    return false;
  }, [stateData.selectedReason, stateData.note]);

  const isNoteValid = stateData.note?.trim()?.length > 0;

  React.useEffect(() => {
    updateStateData((prevState) => ({
      ...prevState,
      isFormValid: checkFormValidation(),
    }));
  }, [stateData.selectedReason, isNoteValid]);

  React.useEffect(() => {
    if (props?.activeDrawerCode) {
      updateStateData((prevState) => ({
        ...prevState,
        activeDrawerCode: props?.activeDrawerCode || null,
      }));
    }
  }, [props?.activeDrawerCode]);

  const checkUpcomingCareProgramAppointments = async () => {
    const isUpacomingAppointmentExist = await isUpcomingCareProgramAppointmentsExist(props.careProgramId);
    if (isUpacomingAppointmentExist) {
      updateStateData((prevState) => ({
        ...prevState,
        showCancelCareProgramWarning: true,
      }));
    }
    else {
      updateStateData((prevState) => ({
        ...prevState,
        showCancelCareProgramWarning: false,
        activeDrawerCode:
          CareManagementThreeDotsMenuType.CLOSE_PROGRAM,
      }));
    }
  }

  return (
    <>
      {!props.hidePopoverContainer && (
        <Popover
          overlayClassName="contact-popover"
          open={stateData.popoverVisible}
          trigger="click"
          onOpenChange={() =>
            updateStateData((prevState) => ({
              ...prevState,
              popoverVisible: !prevState.popoverVisible,
            }))
          }
          content={
            <>
              <Pressable
                onPress={() => {
                  checkUpcomingCareProgramAppointments();
                }}
              >
                <Stack
                  direction="row"
                  style={{
                    paddingVertical: 4,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <AntIcon
                    name="close"
                    color={Colors.Custom.crossIconColor}
                    size={12}
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: '500',
                      paddingLeft: 4,
                      color: Colors.Custom.crossIconColor,
                    }}
                    {...testID(TestIdentifiers.endProgram)}
                  >
                    {intl.formatMessage({id: 'endProgram'})}
                  </Text>
                  {isCareProgramAppointmentsLoading && <Spinner size="sm" />}
                </Stack>
              </Pressable>
            </>
          }
          placement={'bottom'}
          overlayInnerStyle={{
            padding: 8,
            borderRadius: 8,
          }}
        >
          <Pressable
            onPress={() => {
              updateStateData((prevState) => ({
                ...prevState,
                popoverVisible: !prevState.popoverVisible,
              }));
            }}
            style={{
              padding: 4,
              borderRadius: 4,
            }}
            _hover={{
              backgroundColor: Colors.FoldPixel.GRAY50,
            }}
          >
            <MenuHorizontalIcon width={20} height={20} />
          </Pressable>
        </Popover>
      )}

      {stateData.activeDrawerCode ===
      CareManagementThreeDotsMenuType.CLOSE_PROGRAM ? (
        <CustomDrawer
          headerButtons={[
            <FoldButton
              customProps={{
                btnText: intl.formatMessage({
                  id: 'save',
                }),
              }}
              nativeProps={{
                onPress: async () => {
                  try {
                    await onCloseCareProgram(
                      [props.careProgramId],
                      props.contactId,
                      stateData.selectedReason?.id || '',
                      stateData.selectedReason?.code ===
                        CareProgramReasonCodes.OTHER
                        ? stateData.note
                        : undefined
                    );
                    showToast(
                      customToast,
                      intl.formatMessage({id: 'careProgramClosedSuccessMsg'}),
                      ToastType.success,
                      3000,
                      true
                    );
                    props?.onSuccess?.(
                      CareManagementThreeDotsMenuType.CLOSE_PROGRAM
                    );
                  } catch (error) {
                    showToast(
                      customToast,
                      intl.formatMessage({id: 'apiErrorMsg'}),
                      ToastType.error,
                      3000,
                      true
                    );
                  } finally {
                    updateStateData((prevState) => ({
                      ...prevState,
                      activeDrawerCode: null,
                      popoverVisible: false,
                    }));
                  }
                },
                isDisabled: !stateData.isFormValid,
                isLoading: isLoading,
                backgroundColor: !stateData.isFormValid
                  ? Colors.FoldPixel.GRAY50
                  : Colors.Custom.Primary300,
                _text: {
                  fontSize: 14,
                  lineHeight: 16.8,
                  fontWeight: 'bold',
                  color: !stateData.isFormValid
                    ? Colors.FoldPixel.GRAY150
                    : '#fff',
                },
              }}
            />,
          ]}
          onClose={() => {
            updateStateData((prevState) => ({
              ...prevState,
              activeDrawerCode: null,
              popoverVisible: false,
            }));
            props?.onClose?.();
          }}
          customStylesClasses="no-body-padding no-header-padding"
          title="closingProgram"
          customization={{
            width: '626px',
          }}
          customHeaderContainerStyles={{
            borderBottomWidth: 0.5,
            borderBottomColor: Colors.FoldPixel.GRAY150,
            paddingHorizontal: 16,
            paddingVertical: 21,
          }}
          open
        >
          <View
            style={{
              padding: 16,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontWeight: '500',
                color: Colors.FoldPixel.GRAY400,
                marginBottom: 16,
              }}
            >
              {intl.formatMessage({id: 'pleaseAddReasonForClosingProgram'})}
            </Text>

            {getReasonesList().map((reason) => (
              <Pressable
                key={`${stateData.activeDrawerCode}-${reason.code}`}
                isDisabled={isLoading}
                onPress={() => {
                  updateStateData((prevState) => ({
                    ...prevState,
                    selectedReason: reason,
                    note: '',
                  }));
                }}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 16,
                }}
              >
                <RadioButton
                  isChecked={stateData.selectedReason?.code === reason.code}
                  onChange={() => {
                    updateStateData((prevState) => ({
                      ...prevState,
                      selectedReason: reason,
                      note: '',
                    }));
                  }}
                />
                <Text
                  size={'smRegular'}
                  style={{
                    color: Colors.FoldPixel.GRAY400,
                    paddingLeft: 8,
                  }}
                >
                  {reason.value}
                </Text>
              </Pressable>
            ))}
            {stateData.selectedReason?.code === CareProgramReasonCodes.OTHER ? (
              <Stack direction="column" style={{marginTop: 8}} space={4}>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '400',
                    color: Colors.FoldPixel.GRAY250,
                  }}
                >
                  Note
                </Text>

                <TextArea
                  value={stateData.note}
                  multiline
                  isDisabled={isLoading}
                  variant={'outline'}
                  color={Colors.FoldPixel.GRAY400}
                  fontWeight={'400'}
                  borderWidth={0.5}
                  borderColor={Colors.FoldPixel.GRAY250}
                  borderRadius={4}
                  focusable={false}
                  fontSize={14}
                  placeholder={'Enter Note'}
                  placeholderTextColor={Colors.Custom.AlertsDescriptionColor}
                  maxH={176}
                  autoComplete="off"
                  onChangeText={(plainString) => {
                    updateStateData((prevState) => ({
                      ...prevState,
                      note: plainString,
                    }));
                  }}
                />
              </Stack>
            ) : (
              <></>
            )}
          </View>
        </CustomDrawer>
      ) : (
        <></>
      )}

      <CustomModal
        width={'450px'}
        isOpen={stateData.showCancelCareProgramWarning}
        headerText={intl.formatMessage({id: 'upcomingAppointmentsExisit'})}
        subtitle={intl.formatMessage({id: 'cancelCareProgramWarningMsg'})}
        onClose={() => {
          updateStateData((prevState) => ({
            ...prevState,
            showCancelCareProgramWarning: false,
          }));
        }}
        customActionBtnContainerStyle={{
          justifyContent: 'flex-end',
          marginRight: 12
        }}
        customTextHeaderStyle={{
          marginBottom: 8
        }}
        onAction={() => {
          updateStateData((prevState) => ({
            ...prevState,
            showCancelCareProgramWarning: false,
          }));
        }}
        customOkBtnOnPress={() => {
          updateStateData((prevState) => ({
            ...prevState,
            showCancelCareProgramWarning: false,
            activeDrawerCode:
              CareManagementThreeDotsMenuType.CLOSE_PROGRAM,
          }));
        }}
        customOkBtnText={intl.formatMessage({id: 'proceed'})}
        customActionBtnText={intl.formatMessage({id: 'cancel'})}
      />
    </>
  );
};

export default CareManagementThreeDotsMenu;
