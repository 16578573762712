import { Menu, Popover } from 'antd';
import Picker from 'emoji-picker-react';
import {
  FormControl,
  HStack,
  IconButton,
  Input,
  Select, TextArea
} from 'native-base';
import { useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Colors } from '../../../../../styles';
import { getAccountMergeTagData } from '../../ContentManagementUtils';
import { AddOrUpdatePushNotificationBodyProps } from '../interfaces';
import { NOTIFICATION_BODY_LENGTH } from '../PushNotificationConsts';
import { PushNotificationPreview } from '../PushNotificationPreview';
import {
  getMenuItems,
  getParseBody,
  getSelectedMergeTag,
  insertAtIndex
} from '../PushNotificationsUtils';
import { styles } from './AddOrUpdatePushNotificationStyles';
import { InputStyle } from '../../../Contacts/Leads/LeadView/LeadViewStyle';

const {ErrorMessage, Label, HelperText} = FormControl;
const {Item} = Select;

const AddOrUpdatePushNotificationBody = (
  props: AddOrUpdatePushNotificationBodyProps
) => {
  const { notificationData, setNotificationData ,errors} =props;

  const [cursorPosition, setCursorPosition] = useState(0);
  const [titleCursorPosition, setTitleCursorPosition] = useState(0);



  const accountMergeTags = getAccountMergeTagData();

  const getMergeTags = () => {
    if (notificationData.category) {
      return {
        ...notificationData?.category?.mergeTags,
        global: accountMergeTags,
      };
    }
    return {
      global: accountMergeTags,
    };
  };

  const insertMergeTags = (mergeTagString: string, index: number) => {
    const body = notificationData.body || '';
    const insertedBody = insertAtIndex(body, index, mergeTagString);
    setNotificationData((prev:any) => {
      return {
        ...prev,
        body: insertedBody,
      };
    });
  };

  const insertEmojiToTitleText = (emojiString: string, index: number) => {
    const title = notificationData.subject || '';
    const insertedTitle = insertAtIndex(title, index, emojiString);
    setNotificationData((prev:any) => {
      return {
        ...prev,
        subject: insertedTitle,
      };
    });
  };

  const onMenuItemSelected = (info: any) => {
    const mergeTagString = getSelectedMergeTag(info.keyPath);
    insertMergeTags(mergeTagString, cursorPosition);
  };

  const popoverContent = () => {
    return (
      <Menu
        items={getMenuItems(getMergeTags())}
        selectable={false}
        onClick={(info) => {
          onMenuItemSelected(info);
        }}
      />
    );
  };

  const emojiPopoverContent = () => {
    return (
      <Picker
        onEmojiClick={(event, data) => {
          insertMergeTags(data.emoji, cursorPosition);
        }}
      />
    );
  };

  const titleEmojiPopoverContent = () => {
    return (
      <Picker
        onEmojiClick={(event, data) => {
          insertEmojiToTitleText(data.emoji, titleCursorPosition);
        }}
      />
    );
  };

  return (
    <>
        <FormControl
          style={styles.formContainer}
          isRequired
          isInvalid={errors.subject}
        >
          <HStack style={styles.mergeTagPopoverView}>
          {props.isHideLabel ?<></> :<Label>Title</Label>}
            <Popover
              content={titleEmojiPopoverContent}
              trigger={'hover'}
              style={{}}
            >
              <IconButton
                _hover={{
                  backgroundColor: Colors.primary['100'],
                }}
                style={{
                  marginBottom: 5,
                  marginLeft: 4,
                }}
                backgroundColor={Colors.primary['100']}
                icon={<AntIcon name="smileo" size={16} color="black" />}
              />
            </Popover>
          </HStack>
          <Input
            placeholder='Enter Notification Title'
            placeholderTextColor={props.isAutomationView ? Colors.FoldPixel.GRAY250 : undefined}
            fontSize={InputStyle.placeHolderFontsize}
            backgroundColor={props.isAutomationView? 'white' : undefined}
            _focus={{borderColor: props.isAutomationView ? Colors.FoldPixel.PRIMARY_FOCUS : undefined}}
            borderWidth={props.isAutomationView ? 0.5 : 1}
            onSelectionChange={(e) => {
              setTitleCursorPosition(e.nativeEvent.selection.start);
            }}
            value={notificationData?.subject || ''}
            onChangeText={(subject) => {
              setNotificationData((prev:any) => {
                return {
                  ...prev,
                  subject: subject,
                  template: {
                    ...prev?.template,
                    id: null
                  },
                  pushNotificationUpdatedAt : new Date()
                };
              });
            }}
          />
          {errors.subject && (
            <ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.subject}
            </ErrorMessage>
          )}
        </FormControl>

        <FormControl
          style={styles.formContainer}
          isRequired
          isInvalid={errors.body}
        >
          <HStack style={styles.mergeTagPopoverView}>
            {props.isHideLabel ?<></> : <Label>Body</Label>}
            <Popover content={popoverContent} trigger={'hover'}>
              <IconButton
                _hover={{
                  backgroundColor: Colors.primary['100'],
                }}
                backgroundColor={Colors.primary['100']}
                style={{
                  marginBottom: 5,
                  marginLeft: 4,
                }}
                icon={
                  <MaterialCommunityIcon
                    name="code-json"
                    size={16}
                    color="black"
                  />
                }
              />
            </Popover>
            <Popover content={emojiPopoverContent} trigger={'hover'} style={{}}>
              <IconButton
                _hover={{
                  backgroundColor: Colors.primary['100'],
                }}
                style={{
                  marginBottom: 5,
                  marginLeft: 4,
                }}
                backgroundColor={Colors.primary['100']}
                icon={<AntIcon name="smileo" size={16} color="black" />}
              />
            </Popover>
          </HStack>
          <TextArea
            maxLength={168}
            placeholder='Enter Notification Details'
            placeholderTextColor={props.isAutomationView ? Colors.FoldPixel.GRAY250 : undefined}
            fontSize={InputStyle.placeHolderFontsize}
            backgroundColor={props.isAutomationView? 'white' : undefined}
            borderWidth={props.isAutomationView ? 0.5 : 1}
            _focus={{borderColor : props.isAutomationView ? Colors.FoldPixel.PRIMARY_FOCUS : undefined}}
            onSelectionChange={(e) => {
              setCursorPosition(e.nativeEvent.selection.start);
            }}
            value={notificationData?.body || ''}
            onChangeText={(body) => {
              if (body.length <= 168) {
                setNotificationData((prev:any) => {
                  return {
                    ...prev,
                    body: body,
                    template: {
                      ...prev?.template,
                      id: null
                    },
                    pushNotificationUpdatedAt : new Date()
                  };
                });
              }}
            }
          />
          {(notificationData.body || '').length > NOTIFICATION_BODY_LENGTH && (
            <HelperText>{`You are exceeding the maximum character limit (${NOTIFICATION_BODY_LENGTH}) for notification body`}</HelperText>
          )}
          {errors.body && (
            <ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.body}
            </ErrorMessage>
          )}
        </FormControl>

        <PushNotificationPreview
          notificationBody={
            getParseBody({
              bodyString: notificationData.body || '',
              mergeTags: getMergeTags(),
            }) || 'Body'
          }
          notificationSubject={notificationData.subject || 'Title'}
        />
        </>
  );
};

export default AddOrUpdatePushNotificationBody;
