import React from "react";
import { ICommonSvgProps } from "../interfaces";
import Svg, { Path } from 'react-native-svg';

const ViewInEhr = (props: ICommonSvgProps)=> {
  const strokeColor = props.isActive ? '#667085' : props.customStrokeColor ? props.customStrokeColor : '#667085';
  return (
    <Svg width="17" height="16" viewBox="0 0 17 16" fill="none">
      <Path d="M4.78564 12L12.7856 4M12.7856 4H6.78564M12.7856 4V10" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round"/>
    </Svg>
  );
}

export default ViewInEhr;