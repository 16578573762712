import {View, Text, StyleSheet, Pressable} from 'react-native';
import React, {useState} from 'react';
import {Colors} from '../../styles';
import {FilterWrapperComponent} from '../common/CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import Stack from '../common/LayoutComponents/Stack';
import { Checkbox } from 'antd';

export interface ISelectedFilter {
  code: string;
  value: string;
}

export interface IMultiSelectFilterViewProps {
  label: string;
  selectedValues: ISelectedFilter[];
  listItems: ISelectedFilter[];
  disabled?: boolean;
  onChange?: (value: ISelectedFilter, isChecked: boolean) => void;
}

const MultiSelectFilterView = (props: IMultiSelectFilterViewProps) => {
  const {selectedValues, listItems, label, disabled, onChange} = props;
  const [showStatusPopover, setShowStatusPopover] = useState<boolean>(false);

  return (
    <FilterWrapperComponent
      label={label}
      value={selectedValues.map((value) => value.value)}
      filterTextStyle={styles.filterTextStyle}
      filterValueTextStyle={styles.filterValueTextStyle}
      contentMinHeight={35}
      onClose={() => setShowStatusPopover(false)}
      popoverStyle={{borderRadius: 8}}
      isControlledPopover={true}
      open={showStatusPopover}
      onOpenChange={setShowStatusPopover}
    >
      <View>
        {listItems.map((item, index) => (
          <Stack direction="row" key={`MultiSelectFilterView_${item.code}`} style={styles.alignCenter}>
            <Checkbox
              key={item.code}
              disabled={disabled}
              value={item.code}
              style={{
                marginTop: 10,
                marginBottom: 10,
                alignItems: 'center',
              }}
              checked={selectedValues.some((value) => value.code === item.code)}
              onChange={(event) => {
                const isChecked = event.target.checked;
                onChange?.(item, isChecked);
              }}
            >
              <Text style={styles.statusListStyle}>
                {item.value}
              </Text>
            </Checkbox>
          </Stack>
        ))}
      </View>
    </FilterWrapperComponent>
  );
};

const styles = StyleSheet.create({
  alignCenter: {
    alignItems: 'center',
  },
  filterTextStyle: {
    fontSize: 14,
    marginRight: 2,
    fontWeight: '500',
    color: Colors.Custom.Gray500,
    borderRadius: 4,
  },
  filterValueTextStyle: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.Gray700,
  },
  statusListStyle: {
    color: Colors.Custom.Gray500,
    padding: 4,
    paddingVertical: 8,
    fontSize: 14,
    fontWeight: '500',
  },
});

export default MultiSelectFilterView;
