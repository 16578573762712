import React, { useEffect } from 'react';
import {testID} from '../../../../testUtils';
import {
  ContactCareProgramComponentStatus,
  IContactCareProgram,
  IContactCareProgramStep
} from '../interface';
import Stack from '../../../common/LayoutComponents/Stack';
import {FlatList} from 'react-native';
import {useContactCareProgramContext} from './ContactCareProgram.context';
import {ContactCareProgramReducerAction} from './reducer';
import SideBarStep from './SideBarStep';
import SideBarCollapsibleStep from './SideBarCollapsibleStep';
import { CARE_PROGRAM_STEP_TYPE } from '../../../common/MemebersView/constant';
import { Skeleton} from 'antd';
import { CARE_PROGRAM_EVENTS } from '../useCPEventhandler';
import { EventBus } from '../../../../utils/EventBus';

const SidebarView: React.FC<{
  contactCareProgramDetails: IContactCareProgram;
  onStepClick: (stepId: string) => void;
}> = (props) => {
  // Constants
  const {contactCareProgramDetails, onStepClick} = props;
  const {dispatch, state} = useContactCareProgramContext();
  const isComponentLoading =
    state.status === ContactCareProgramComponentStatus.LOADING;
  const {sideBarExpandedStepKeysSet, groupedSteps, stepProgressMap} = state;

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();   
    const handleOutreachEvent = (event: any) => {
      if (event.syncCode === 'record-as-outreach') {
        const parentStep = groupedSteps.find(step => 
          step.subSteps?.some(subStep => 
            subStep.careProgramStepType?.code === CARE_PROGRAM_STEP_TYPE.OUTREACH
          )
        );

        if (parentStep) {
          if (!sideBarExpandedStepKeysSet.has(parentStep.id)) {
            dispatch?.({
              type: ContactCareProgramReducerAction.EXPAND_SIDEBAR_STEP,
              payload: parentStep.id,
            });
          }

          const outreachStep = parentStep.subSteps?.find(subStep => 
            subStep.careProgramStepType?.code === CARE_PROGRAM_STEP_TYPE.OUTREACH
          );

          if (outreachStep) {
            onStepClick(outreachStep.id);
          }
        }
      }
    };

    eventBus.addEventListener(
      CARE_PROGRAM_EVENTS.RECORD_AS_OUTREACH,
      handleOutreachEvent
    );

    return () => {
      eventBus.removeEventListener(handleOutreachEvent);
    };
  }, [groupedSteps, sideBarExpandedStepKeysSet, dispatch, onStepClick]);

  const onCollapsibleStepClick = (
    id: string,
    isExpanded: boolean
  ) => {
    dispatch?.({
      type: isExpanded
        ? ContactCareProgramReducerAction.COLLAPSE_SIDEBAR_STEP
        : ContactCareProgramReducerAction.EXPAND_SIDEBAR_STEP,
      payload: id,
    });
  };

  // States
  const renderStep = ({
    item,
    isChild,
  }: {
    item: IContactCareProgramStep;
    isChild?: boolean;
  }) => {
    const {id, title, subSteps, careProgramStepAdditionalAttributes} = item;
    
    if (isChild) {
      const isActiveStep = state?.selectedStepId === id;
      return (
        <SideBarStep
          isActiveStep={isActiveStep}
          onSelectStep={() => {
            onStepClick(id);
          }}
          step={item}
        />
      );
    }

    
    const subStepsArray = subSteps || [];
    const isExpanded = sideBarExpandedStepKeysSet.has(id);

    return (
      <Stack direction="column" {...testID(`sidebar-section-${title}-${id}`)}>
        <SideBarCollapsibleStep
          subStepsArray={subStepsArray}
          onClick={() => onCollapsibleStepClick(id, isExpanded)}
          isExpanded={isExpanded}
          id={id}
          title={title}
          step={item}
        />
        {subStepsArray.length > 0 && isExpanded && (
          <FlatList
            data={subStepsArray}
            renderItem={({item}) => renderStep({item, isChild: true})}
            keyExtractor={(item) => item.id}
          />
        )}
      </Stack>
    );
  };

  return (
    <div
      className="sidebar-view"
      {...testID('sidebar')}
      style={{
        paddingTop: isComponentLoading ? 8 : 0,
      }}
    >
      <FlatList
        style={{
          maxHeight: '90%',
        }}
        showsVerticalScrollIndicator={false}
        data={isComponentLoading ? new Array(10).fill({}) : groupedSteps}
        renderItem={
          isComponentLoading
            ? () => (
                <Skeleton.Input
                  active
                  style={{
                    width: '92%',
                    borderRadius: 8,
                    marginRight: 8,
                    marginLeft: 8,
                    marginBottom: 8,
                  }}
                />
              )
            : renderStep
        }
        keyExtractor={(item) => item.id}
      />
    </div>
  );
};

export default SidebarView;
