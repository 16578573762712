import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import Stack from '../../common/LayoutComponents/Stack';
import {Text, ScrollView, Pressable, View} from 'native-base';
import {Divider, Popover} from 'antd';
import {
  CARE_MANAGEMENT_ACTION_LIST,
  CARE_MANAGEMENT_PROGRAM_CODES,
} from '../CareManagementBilling/CareManagementConstants';
import {ICareManagementTab, ICareManagementTopBarView} from './interface';
import PlusSvg from '../../common/Svg/PlusSvg';
import {Colors} from '../../../styles/Colors';
import {enrollContactCareProgram} from './CareManagementUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovObjectFromCode,
} from '../../../utils/mlovUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {
  CARE_PLAN_SOURCE_TYPE_CODES,
  CONTACT_CARE_PROGRAM_SOURCE_CODES,
  MLOV_CATEGORY,
} from '../../../constants/MlovConst';
import {currentYear} from '../../../utils/DateUtils';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {useCustomToast} from '../../Toast/ToastProvider';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {antStyles, styles} from './CareManageMentViewStyles';
import FilterNewIconSvg from '../../common/Svg/FilterNewIconSvg';
import CustomConfirmationModal from '../../common/CustomConfirmationModal/CustomConfirmationModal';
import {getErrorMessage, replaceHashValueToString} from '../../../utils/commonUtils';
import WarningSvgV2 from '../../common/Svg/WarningSvgV2';
import CareProgramSelectionView from './CareProgramSelectionView';
import { testID } from '../../../testUtils/Utils';
import { TestIdentifiers } from '../../../testUtils';
import { getCareProgramNameByCode } from '../ContactCareProgram/ContactCareProgramView/ContactCareProgramView.utils';
import useCareProgramStatusAction, { CareProgramStatusAction } from '../ContactCareProgram/ContactCareProgramView/hooks/useCareProgramStatusAction';

const CareManagementTopBarView = (props: ICareManagementTopBarView) => {
  const [state, setState] = useState({
    showPopover: false,
    searchText: '',
  });
  const [eligibleProgramsList, setEligibleProgramsList] = useState<string[]>([]);
  const [confirmationModalState, setConfirmationModalState] = useState({
    showConfirmationModal: false,
    careProgramType: '',
  });
  const { handleAction } = useCareProgramStatusAction();
  const commonContextData = useContext(CommonDataContext);
  const navigate = useNavigate();
  const intl = useIntl();
  const customToast = useCustomToast();
  const careProgramTypesList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_TYPES
    ) || [];

  const contactCareProgramSourceList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CONTACT_CARE_PROGRAM_SOURCE
    ) || [];
  const carePlanSourceTypeMlovs = getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV || {},
      MLOV_CATEGORY.CARE_PLAN_SOURCE
    ) || [];
  const manualSourceTypeId = getMlovIdFromCode(
      carePlanSourceTypeMlovs,
      CARE_PLAN_SOURCE_TYPE_CODES.MANUAL
    );

  const enrollCareProgram = async (tabCode: string) => {
    try {
      props?.onCareProgramEnrollmentLoading(true);
      const careProgramTypeId = getMlovIdFromCode(
        careProgramTypesList,
        tabCode.toUpperCase()
      );
      const contactCareProgramSourceId = getMlovIdFromCode(
        contactCareProgramSourceList,
        CONTACT_CARE_PROGRAM_SOURCE_CODES.FILE_IMPORT
      );
      const contactCareProgramMlovData = getMlovObjectFromCode(
        tabCode.toUpperCase(),
        careProgramTypesList
      )
      setState(prev => ({...prev, showPopover: false}));
      const response = await enrollContactCareProgram({
        careProgramTypeId,
        contactId: props?.contactUuid,
        eligibilityDateTime: new Date(),
        membershipYear: currentYear(),
        sourceId: contactCareProgramSourceId,
        sourceTypeId: manualSourceTypeId,
        skipCarePlanCreate: props?.skipCarePlanCreate || false,
      });
      props?.onCareProgramEnrollmentLoading(false);
      const careProgramName = getCareProgramNameByCode(
        contactCareProgramMlovData?.code || '',
        contactCareProgramMlovData?.value || 'Care program'
      );
      showToast(
        customToast,
        replaceHashValueToString(
          {careProgramName: careProgramName},
          intl.formatMessage({id: 'careProgramSucess'})
        ),
        ToastType.success,
        3000,
        true
      );
      const formattedTabCode = tabCode?.toLowerCase();
      if (response?.id) {
        if (formattedTabCode === CARE_MANAGEMENT_PROGRAM_CODES.CCM) {
          try {
            await handleAction(CareProgramStatusAction.IN_PROGRESS, {
              careProgramId: response?.id,
              contactId: props?.contactUuid,
            });
          }
          catch (error) {
          }
        }
        const url = `/members/patient/${props?.contactId}/careManagement/${formattedTabCode}/${response?.id}`;
        if (props?.isCompactView) {
          props?.handleRefresh?.();
          window.open(`${origin}/#${url}`, '_blank', 'noopener,noreferrer');
        } else {
          navigate(url);
        }
      }
    } catch (e) {
      props?.onCareProgramEnrollmentLoading(false);
      setState(prev => ({...prev, showPopover: false}));
      const errorMessage = getErrorMessage(e);
      showToast(
        customToast,
        intl.formatMessage({id: errorMessage}),
        ToastType.error,
        3000,
        true
      );
    }
  };

  const handleCareManagementAction = (tabCode: string) => {
    const isExistingProgram = props?.checkExistingProgram(
      tabCode?.toUpperCase()
    );
    if (isExistingProgram) {
      setConfirmationModalState({
        showConfirmationModal: true,
        careProgramType: tabCode?.toUpperCase(),
      });
    } else {
      enrollCareProgram(tabCode);
    }
  };

  const handlePopoverChange = (visible: boolean) => {
    setState({showPopover: visible, searchText: ''});
  };

  const checkEligiblePrograms = async () => {
    if (props?.eligiblePrograms?.length) {
      return props.eligiblePrograms;
    }
    const allowedPrograms = await props?.getEligibleCarePrograms(props?.contactUuid);
    return allowedPrograms || [];
  };

  useEffect(() => {
    const getEligiblePrograms = async () => {
      const programs = await checkEligiblePrograms();
      setEligibleProgramsList(programs);
    };
    getEligiblePrograms();
  }, [props?.eligiblePrograms, props?.contactUuid]);

  const getPopoverContent = () => {
    return (
      <CareProgramSelectionView
        onProgramSelect={(program) => handleCareManagementAction(program.tabCode)}
        onClose={() => setState(prev => ({...prev, showPopover: false}))}
        searchText={state.searchText}
        onSearchChange={(text) => setState(prev => ({...prev, searchText: text}))}
        eligiblePrograms={eligibleProgramsList}
      />
    );
  };

  const getAllowedCareProgramTypes = () => {
    const careProgramTypes: ICareManagementTab[] = careProgramTypesList?.map(
      ({code, value}) => ({
        tabCode: code,
        tabTitle: code,
      })
    );

    const CARE_MANAGEMENT_PROGRAM_LIST: ICareManagementTab[] = [
      {
        tabCode: CARE_MANAGEMENT_PROGRAM_CODES.ALL,
        tabTitle: 'All'
      },
      ...(careProgramTypes || [])
    ];

    const allowedCareProgramTypes = props?.allowedCareProgramTypes?.map(item =>
      item?.toLowerCase()
    );
    return CARE_MANAGEMENT_PROGRAM_LIST.filter(item =>
      item.tabCode === CARE_MANAGEMENT_PROGRAM_CODES.ALL ||
      allowedCareProgramTypes?.includes(item.tabCode?.toLowerCase())
    );
  }

  return (
    <Stack direction="row" style={styles.topBarContainer}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        {getAllowedCareProgramTypes()?.map((item: ICareManagementTab) => (
          <Pressable
            key={item?.tabCode?.toLowerCase()}
            style={[
              styles.tab,
              props?.activeTabCode === item?.tabCode?.toLowerCase() && styles.selectedTab,
            ]}
            onPress={() => props?.onHeaderTabClick(item?.tabCode?.toLowerCase())}
            // testID={item.tabTitle}
            {...testID(item.tabTitle)}
          >
            <Text
              size={'smRegular'}
              style={[
                styles.tabText,
                props?.activeTabCode === item?.tabCode?.toLowerCase() && styles.selectedTabText,
              ]}
            >
              {item.tabTitle}
            </Text>
          </Pressable>
        ))}
      </ScrollView>
      <Stack direction="row" style={styles.mr8}>
        <View style={styles.filterButton}>
          <Pressable onPress={() => props?.onFilterClick()} {...testID(TestIdentifiers.filterButton)}>
            <FilterNewIconSvg isEnabled={true} />
          </Pressable>
        </View>
      {eligibleProgramsList?.length > 0 && (
        <>
        <Divider type="vertical" style={antStyles.dividerStyle} />
        <Popover
          overlayClassName="contact-popover"
          content={getPopoverContent()}
          trigger="click"
          placement="bottomRight"
          open={state.showPopover}
          onOpenChange={handlePopoverChange}
          align={{
            offset: [-5, -5],
          }}
          overlayInnerStyle={antStyles.popoverOverlayInnerStyle}
          showArrow={false}
        >
          <Pressable
            style={styles.addButton}
            onPress={() => setState(prev => ({...prev, showPopover: true}))}
            {...testID(TestIdentifiers.addNewBtn)}
          >
            <PlusSvg
              height={16}
              width={16}
              defaultColor={Colors.Custom.Gray500}
            />
            <Text style={styles.addButtonText}>New</Text>
          </Pressable>
        </Popover>
        </>
      )}
      </Stack>
      {confirmationModalState?.showConfirmationModal && (
        <CustomConfirmationModal
          isOpen={confirmationModalState?.showConfirmationModal}
          headerText={replaceHashValueToString(
            {careProgramType: confirmationModalState?.careProgramType},
            intl.formatMessage({id: 'activeTcmProgramExists'})
          )}
          message={replaceHashValueToString(
            {careProgramType: confirmationModalState?.careProgramType},
            intl.formatMessage({id: 'activeTcmProgramExistsMsg'})
          )}
          hideConfirmBtn
          customCancelBtnText={'Cancel'}
          width={350}
          customIcon={
            <WarningSvgV2 strokeColor={Colors.Custom.crossIconColor} />
          }
          onCancel={() => {
            setConfirmationModalState({
              showConfirmationModal: false,
              careProgramType: '',
            });
          }}
        />
      )}
    </Stack>
  );
};

export default React.memo(CareManagementTopBarView);
