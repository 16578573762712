import React, {useState} from 'react';
import {useToggle} from 'react-use';
import ReasonForVisitFreeText from '../../../../../../PublicPages/AppointmentBookingWidget/ReasonForVisitFreeText';
import {useAppointmentDataContext} from '../contexts/AppointmentDataContext';
import {useIntl} from 'react-intl';
import {debounce} from 'lodash';
import {FoldButton} from '../../../../../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE} from '../../../../../../../constants';
import {Colors} from '../../../../../../../styles';
import {Text, VStack} from 'native-base';
import ReasonForVisitIcon from '../../../../../../../assets/Icons/ReasonForVisitIcon';
import MemberInstructionIcon from '../../../../../../../assets/Icons/MemberInstructionIcon';
import {DisplayText} from '../../../../../DisplayText/DisplayText';
import {TaskCheckList} from '../../../../../AppointmentTaskCheckList/TaskCheckList';
import {IAppointmentTask} from '../../../../../../../services/Appointment/AppointmentInterface';


const StaffInstructionView = () => {
  const {appointmentBookingState, handleAppointmentDateChange, rescheduleData, handleChangeAppointmentbookingState} =
    useAppointmentDataContext();
    const [open, setOpen] = useState(
        appointmentBookingState?.tasks && appointmentBookingState?.tasks?.length > 0
    );
    const intl = useIntl();

  const handleDebouncedOnChange = (text: string) => {
    handleAppointmentDateChange({
      ...appointmentBookingState.reasonForVisit,
      reasonForVisit: text,
    });
  };

  if (!open) {
    return (
      <FoldButton
        nativeProps={{
          variant: BUTTON_TYPE.PRIMARY,
          style: {
            width: 'max-content',
            borderWidth: 0,
            paddingLeft: 4,
            // paddingRight: 4,
            minHeight: 'max-content',
          },
          _hover: {
            backgroundColor: Colors.FoldPixel.PRIMARY_20,
          },
          onPress: () => {
            setOpen((prev) => !prev);
          },
          leftIcon: (
            <MemberInstructionIcon />
          )
        }}
        customProps={{
          btnText: (
            <Text
              cursor={'pointer'}
              size="smRegular"
              color={Colors.FoldPixel.PRIMARY300}
            >
              {intl.formatMessage({
                id: 'instructionForStaff',
              })}
            </Text>
          ),
          withRightBorder: false,
        }}
      ></FoldButton>
    );
  }

  return (
    <>
      <VStack space={2}>
        <DisplayText
          textLocalId={'staffInstructionsAppointmentBookDrawerTitle'}
          extraStyles={{
            color: Colors.Custom.Gray700,
            fontWeight: 500,
            fontSize: 14,
          }}
        />

        <TaskCheckList
          tasks={appointmentBookingState?.tasks || []}
          appointmentId={rescheduleData?.id}
          appointmentTypeTasks={
            appointmentBookingState?.selectedAppointmentType?.tasks
          }
          canAddNewTasks={true}
          canDeleteTasks={true}
          canEditTitle={true}
          canEditStatus={false}
          showErrors={appointmentBookingState.errors?.showErrors}
          onChange={(tasks: IAppointmentTask[]) => {
            handleChangeAppointmentbookingState({
              tasks: tasks,
            });
          }}
        />
      </VStack>
    </>
  );
};

export default React.memo(StaffInstructionView);
