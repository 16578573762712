import {Pressable, View, Text} from 'react-native';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../styles/Colors';
import CareJourneySearch from '../common/CareJourneySearch/CareJourneySearch';
import Stack from '../common/LayoutComponents/Stack';
import {DisplayText} from '../common/DisplayText/DisplayText';
import {Button, DatePicker, Skeleton} from 'antd';
import {JourneyDurationInputForAssign} from '../RightSideContainer/Journeys/AddOrUpdateJourney/DetailsAndMembers/DetailsAndMembers';
import {BUTTON_TYPE, DISPLAY_DATE_FORMAT, MLOV_CATEGORY} from '../../constants';
import {IJourneyDurationData} from '../RightSideContainer/Journeys/AddOrUpdateJourney/DetailsAndMembers/Interfaces';
import {
  getDateObjFromDateStrAndFormat,
  getDateToMomentISOString,
  getJourneyStartDateWithOffset,
  getMomentObj,
  isPastDay,
  isTodayDate,
} from '../../utils/DateUtils';
import {
  getWorkFlowDataById,
  saveJourneyToWorkflow,
} from '../RightSideContainer/Journeys/AddOrUpdateJourney/JourneyMetadataService';
import {ApolloError, useLazyQuery, useMutation} from '@apollo/client';
import CareJourneyQueries from '../../services/CareJourney/CareJourneyQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../constants/Configs';
import {getAccountUUID, replaceHashValueToString} from '../../utils/commonUtils';
import {
  getCareStudioMlovListByCategoryCode,
  getMlovCodeFromId,
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../utils/mlovUtils';
import {
  DOCUMENT_SUBJECT_TYPE_CODES,
  DOCUMENT_TYPE_CODES,
  MLOV_CODES,
  PATIENT_CARE_JOURNEY_STATUS_CODE,
} from '../../constants/MlovConst';
import * as DocumentQueries from '../../services/Document/DocumentQueries';
import {
  getDocumentObj,
  getStartDateForCloneJourney,
  removeUnwantedKeyPatientDocumentObj,
} from '../RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/ProductFormUtils';
import {CommonDataContext} from '../../context/CommonDataContext';
import {IProductDetailForm} from '../RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import {ITaxonData} from '../RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/SearchComponents/interfaces';
import {
  getJourneyStartDate,
  JOURNEYS_STATUS_CODE,
} from '../RightSideContainer/Journeys/JourneysOfCare/PatientCareJourney/PatientCareJourneyHelper';
import {CustomUserSearch} from '../common/CustomUserSearch/CustomUserSearch';
import {USER_SEARCH_TYPE} from '../RightSideContainer/constants';
import {CUSTOM_USER_SEARCH_MODE, IInternalUsers} from '../common/CustomUserSearch/interface';
import SideCarBackSvg from '../common/Svg/SideCarBackSvg';
import {FoldButton} from '../CommonComponents/FoldButton/FoldButton';
import {IJourneyDetailForm} from '../RightSideContainer/Journeys/AddOrUpdateJourney/AddOrUpdateJourney';
import {JOURNEY_START_TYPES} from '../RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import {getCareJourneyEndDate} from '../RightSideContainer/Journeys/AddOrUpdateJourney/CareJourneyPreview/CareJourneyPreviewHelper';
import {IContact} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {Divider, Spinner} from 'native-base';
import AssignCareJourneySvg from '../common/Svg/AssignCareJourneySvg';
import {useToast} from '../Toast/ToastProvider';
import {showToast, ToastType} from '../../utils/commonViewUtils';
import AlertBanner from '../common/AlertBanner/AlertBanner';
import JourneyDetailTagInput from '../RightSideContainer/Journeys/AddOrUpdateJourney/DetailsAndMembers/JourneyDetailTagInput';
import PatientCareJourneyDetail from '../RightSideContainer/Journeys/JourneysOfCare/PatientCareJourneyDetail/PatientCareJourneyDetail';
import EyeOpenSvg from '../common/Svg/WidgetsSvg/EyeOpenSvg';
import CalenderNavSvg from '../common/Svg/ConversationAllSvg/CalenderNavSvg';
import AddCalenderNavSvg from '../common/Svg/ConversationAllSvg/AddCalenderNavSvg';
import { FlowType, IWorkflowContextData, WorkflowContext } from '../../context/WorkflowContext';
import { WorkflowRender } from '../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowRenderView';
import ViewCareJourneyFlow from './ViewCareJorurneyFlow';
import { isAccountConfigEnabled } from '../../utils/configUtils';
import { CONFIG_CODES } from '../../constants/AccountConfigConst';
import useAddRoleAndPermission from '../RightSideContainer/UserAccess/CustomHook/useCreateRoleAndPermissions';
import { reactStyles, styles } from './styles';

interface ISidecarAssignCareJourneyComponentState {
  nodes: any[];
  edges: any[];
  isLoadingJourneyForm: boolean;
  isLoadingAssignJourney: boolean;
  isJourneyAlreadyAssigned: boolean;
  selectedAccountUsers: IInternalUsers[];
  selectedJourney: any | null;
  showCareJourneyFlow: boolean;
  autoFocusCareJourneySearch: boolean;
}

interface IUserAndRoleMap {
  roleId: string,
  user: IInternalUsers[]
}

const SidecarAssignCareJourney = (props: {
  contactData?: IContact;
  onComplete: (record: any) => void;
  onBack: () => void;
}) => {
  const [showError, setShowError] = useState(false);
  const intl = useIntl();
  const toast = useToast();
  const accountUuid = getAccountUUID();
  const documentMlovCategoryId = getCareStudioMlovListByCategoryCode(
    MLOV_CATEGORY.DOCUMENT_TYPE
  )[0]?.categoryId;
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const mlovData = useContext(CommonDataContext);
  const documentSubjectTypeMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.DOCUMENT_SUBJECT_TYPE
    ) || [];
  const careJourneyDocumentSubjectTypeId = getMlovIdFromCode(
    documentSubjectTypeMlovs,
    DOCUMENT_SUBJECT_TYPE_CODES.CARE_JOURNEY
  );

  const {getAccountRoles} = useAddRoleAndPermission({
    fetchRoleCategory: [
      MLOV_CATEGORY.USER_ROLES,
      MLOV_CATEGORY.GLOBAL_USER_ROLES,
    ],
  });

  const roleList =
    getMlovListFromCategory(mlovData.MLOV, MLOV_CATEGORY.USER_ROLES) || [];
  const userRoleMlov = roleList.map((role) => {
    return {
      label: role.value,
      value: role.id,
    };
  });

  const journeyDuration =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_JOURNEY_DURATION
    ) || [];

  const monthsId = getMlovIdFromCode(journeyDuration, MLOV_CODES.MONTH);
  const patientJourneyStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS
    ) || [];
  const defaultDuration: IJourneyDurationData = {
    value: 6,
    unit: monthsId,
    unitList: journeyDuration,
  };
  const selectUsersRef = React.useRef<any>();

  const [workflowContextData, setWorkflowContextData] =
  useState<IWorkflowContextData>({
    nodeMasterDataMap: {},
    nodeMetaData: undefined,
    flowType: FlowType.careJourney,
    setNodeMasterDataMap: (data) => {
      setWorkflowContextData((prev) => ({...prev, nodeMasterDataMap: data}));
    },
  });

  const [patientCareJourney, setPatientCareJourney] =
    useState<IProductDetailForm>({
      name: '',
      careJourneyId: '',
      description: '',
      memberOutcome: '',
      businessOutcome: '',
      triggerEvents: [],
      duration: defaultDuration,
      careTeam: [],
      prevCareTeam: [],
      shipping_category_id: '',
      available_on: '',
      price: '',
      cost_currency: 'USD',
      compare_at_price: '',
      sku: '',
      startDate: '',
      taxons: [] as ITaxonData[],
      careJourneyLocations: undefined,
      patientCareJourneyLocations: [],
      recommendedCareJourney: undefined,
      patientRecommendedCareJourney: [],
      careJourneyDocuments: [],
      startType: '',
      startTypeId: '',
    });

  const [componentState, setComponentState] =
    useState<ISidecarAssignCareJourneyComponentState>({
      selectedJourney: null,
      showCareJourneyFlow: false,
      isJourneyAlreadyAssigned: false,
      isLoadingAssignJourney: false,
      isLoadingJourneyForm: false,
      nodes: [],
      edges: [],
      selectedAccountUsers: [],
      autoFocusCareJourneySearch: true,
    });

  const [accountUserRoles, setAccountUserRoles] = useState<
    {value: string; label: string}[]
  >([]);

  const selectedUsersAndRolesMap = React.useRef<IUserAndRoleMap[]>([])

  const [isJourneyAlreadyAssigned] = useLazyQuery(
    CareJourneyQueries.GET_PATIENT_CARE_JOURNEY_BY_CARE_JOURNEY_ID,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const [getCareJourneyById] = useLazyQuery(
    CareJourneyQueries.GET_CARE_JOURNEY_BY_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getDocumentCategories] = useLazyQuery(
    CareJourneyQueries.GET_DOCUMENT_CATEGORIES_BY_CATEGORY_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getCareJourneyDocuments] = useLazyQuery(
    DocumentQueries.GET_CARE_JOURNEY_DOCUMENTS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [assignCareJourney] = useMutation(
    CareJourneyQueries.ASSIGN_PATIENT_CARE_JOURNEY,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const resetCareJourneyData = () => {
    setPatientCareJourney((prev) => {
      return {
        ...prev,
        name: '',
        careJourneyId: '',
        description: '',
        memberOutcome: '',
        businessOutcome: '',
        triggerEvents: [],
        duration: defaultDuration,
        careTeam: [],
        prevCareTeam: [],
        shipping_category_id: '',
        available_on: '',
        price: '',
        cost_currency: 'USD',
        compare_at_price: '',
        sku: '',
        startDate: '',
        taxons: [] as ITaxonData[],
        careJourneyLocations: undefined,
        patientCareJourneyLocations: [],
        recommendedCareJourney: undefined,
        patientRecommendedCareJourney: [],
        careJourneyDocuments: [],
        startType: '',
        startTypeId: '',
      };
    });
  };

  const fetchAllAccountRole = async () => {
    const accountRolesResponse = await getAccountRoles();
    setAccountUserRoles((accountRolesResponse||[]).map((accountRole:{id:string, roleName:string})=>{
      return {
        value: accountRole?.id,
        label: accountRole.roleName
      }
    }))
  };

  useEffect(()=>{
    fetchAllAccountRole();
  }, [])

  const fetchCareJourneyData = async (careJourneyId: string) => {
    const response = await isJourneyAlreadyAssigned({
      variables: {
        patientId: props.contactData?.patient?.patientUuid,
        careJourneyId: careJourneyId,
      },
    });
    if (response.data?.patientCareJourneys?.length) {
      const isStoppedOrCompletedJourney = (response.data?.patientCareJourneys || []).every((journey: any) => {
        const patientCareJourneyStatusId = journey?.statusId;
        const code = getMlovCodeFromId(patientJourneyStatusList, patientCareJourneyStatusId);
        return PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED === code || PATIENT_CARE_JOURNEY_STATUS_CODE.COMPLETE === code;
      });
      if (!isStoppedOrCompletedJourney) {
        showToast(
          toast,
          intl.formatMessage({id: 'journeyAlreadyAssignMsg'}),
          ToastType.info,
          2000,
          true
        );
        setComponentState((prev) => {
          return {
            ...prev, 
            isLoadingJourneyForm: false,
            isJourneyAlreadyAssigned: true
          };
        });
      }
    }
    const apiSuccess = {
      patientCareJourney: true,
      careJourney: false,
      nodeMetaData: true,
      workflowData: false,
    };

    if (careJourneyId) {
      getCareJourneyData(careJourneyId, () => {
        apiSuccess.careJourney = true;
        if (
          apiSuccess.patientCareJourney &&
          apiSuccess.nodeMetaData &&
          apiSuccess.workflowData
        ) {
          setComponentState((prev) => {
            return {...prev, isLoadingJourneyForm: false};
          });
        }
      });

      getWorkflowById(careJourneyId, accountUuid, async () => {
        apiSuccess.workflowData = true;
        if (
          apiSuccess.patientCareJourney &&
          apiSuccess.nodeMetaData &&
          apiSuccess.careJourney
        ) {
          setComponentState((prev) => {
            return {...prev, isLoadingJourneyForm: false};
          });
        }
      });
    } else {
      apiSuccess.careJourney = true;
      apiSuccess.workflowData = true;
      if (
        apiSuccess.patientCareJourney &&
        apiSuccess.careJourney &&
        apiSuccess.nodeMetaData &&
        apiSuccess.workflowData
      ) {
        setComponentState((prev) => {
          return {...prev, isLoadingJourneyForm: false};
        });
      }
    }
  };

  const getCarePlanDocumentTypeId = async () => {
    const documentCategories = await getDocumentCategories({
      variables: {
        params: {
          categoryId: documentMlovCategoryId,
        },
      },
    });
    if (documentCategories?.data?.getDocumentCategories?.length) {
      const categories = documentCategories?.data?.getDocumentCategories;
      const carePlanDocumentCategory = categories.filter((category: any) => {
        return category?.code === DOCUMENT_TYPE_CODES?.CARE_PLAN;
      });
      if (carePlanDocumentCategory?.length) {
        return carePlanDocumentCategory[0]?.id;
      }
      return;
    }
    return;
  };

  const fetchCareJourneyDocuments = async (id: string) => {
    const carePlanDocumentTypeId = await getCarePlanDocumentTypeId();
    const careJourneyDocuments = await getCareJourneyDocuments({
      variables: {
        params: {
          subjectId: id,
          documentTypeId: carePlanDocumentTypeId,
          limit: 100,
          offset: 0,
          searchString: '',
          orderBy: {
            caption: 'desc',
          },
        },
      },
    });
    if (careJourneyDocuments?.data?.getDocuments?.documents?.length) {
      const documents =
        careJourneyDocuments?.data?.getDocuments?.documents || [];
      return documents;
    }
    return [];
  };

  const getCareJourneyData = (
    careJourneyId: string,
    completion: () => void
  ) => {
    getCareJourneyById({
      variables: {
        id: careJourneyId,
      },
    })
      .then(async (response: any) => {
        if (response && response.data && response.data.careJourney) {
          const careJourneyDocuments =
            (await fetchCareJourneyDocuments(careJourneyId)) || [];
          const formateCareJourneyDocuments = getDocumentObj(
            careJourneyDocuments,
            careJourneyId,
            careJourneyDocumentSubjectTypeId
          );
          const journeyData = response.data.careJourney;
          const careJourneyLocations = journeyData?.careJourneyLocations || [];
          const locationIds =
            journeyData?.careJourneyLocations?.map(
              (location: any) => location.locationId
            ) || [];
          const careJourneyRecommendedJourneys =
            journeyData?.careJourneyRecommendedCareJourneys || [];
          const RecommendedJourneyIds =
            journeyData?.careJourneyRecommendedCareJourneys?.map(
              (journey: any) => journey.packageId
            ) || [];
          const prevCareTeam = journeyData.careJourneyTeam;
          const newDuration = {
            value: journeyData.duration || defaultDuration.value,
            unit: journeyData.durationUnitId || defaultDuration.unit,
            unitList: defaultDuration.unitList,
          };
          setPatientCareJourney((prev) => {
            return {
              ...prev,
              name: journeyData.title,
              careJourneyId: careJourneyId,
              description: journeyData.description,
              memberOutcome: journeyData.memberOutcome,
              businessOutcome: journeyData.businessOutcome,
              statusId: journeyData.statusId,
              duration: newDuration,
              prevCareTeam: prevCareTeam,
              careTeam: journeyData.careJourneyTeam.map(
                (team: any) => team.roleId
              ),
              careJourneyTrackingResources:
                (journeyData?.careJourneyTrackingResources || [])?.map(
                  (careJourneyTrackingResource: any) => {
                    return {...careJourneyTrackingResource, id: undefined};
                  }
                ) || [],
              careJourneyLocations: {
                previousLocations: careJourneyLocations,
                locations: locationIds,
              },
              recommendedCareJourney: {
                previousRecommendedCareJourney: careJourneyRecommendedJourneys,
                recommendedCareJourney: RecommendedJourneyIds,
              },
              careJourneyDocuments: formateCareJourneyDocuments,
              startType: journeyData?.startType?.code || '',
              startDateTime: journeyData?.startDateTime,
              startTypeId: journeyData?.startTypeId,
            };
          });
        } else {
          showErrorToast();
        }
        completion();
      })
      .catch((error: ApolloError) => {
        showErrorToast();
        completion();
      });
  };

  const getWorkflowById = (
    careJourneyId: string,
    accountUuId: string,
    completion: () => void
  ) => {
    const paramsCareJourneyId = careJourneyId;
    getWorkFlowDataById(
      careJourneyId,
      accountUuId,
      async (response: any) => {
        let secondaryMasterId = response.secondaryMasterId;
        if (!secondaryMasterId && paramsCareJourneyId) {
          secondaryMasterId = paramsCareJourneyId;
        }
        setPatientCareJourney((prev) => {
          return {
            ...prev,
            secondaryMasterId: secondaryMasterId,
            triggerEvents: [response.triggerEvent],
          };
        });

        response?.nodes?.forEach((node: any) => {
          node.data.isEdit = false;
        });
        setComponentState((prev) => {
          return {
            ...prev,
            nodes: response.nodes,
            edges: response.edges,
          };
        });
        completion();
      },
      () => {
        showErrorToast();
        completion();
        setComponentState((prev) => {
          return {...prev, isLoadingJourneyForm: false};
        });
      }
    );
  };

  const getBackNavigationButton = () => {
    return (
      <Pressable
        style={{
          padding: 4,
        }}
        onPress={() => {
          props.onBack();
        }}
      >
        <SideCarBackSvg width={20} height={20} />
      </Pressable>
    );
  };

  const getCloseJourneyFlowViewButton = () => {
    return (
      <Pressable
        style={{
          padding: 4
        }}
        onPress={() => {
          setComponentState((prev: any) => {
            return {
              ...prev,
              showCareJourneyFlow: false,
              autoFocusCareJourneySearch: false,
            };
          });
        }}
      >
        <SideCarBackSvg width={20} height={20} />
      </Pressable>
    );
  };

  const showErrorToast = () => {
    showToast(
      toast,
      intl.formatMessage({id: 'apiErrorMsg'}),
      ToastType.error,
      2000,
      true
    );
  };

  const getCareTeamSelectedUsersUuids = (): IInternalUsers[] => {
    const selectedUsers = selectedUsersAndRolesMap?.current?.map(
      (roleMap): IInternalUsers => {
        return roleMap.user?.[0];
      }
    );
    const filteredUsers = selectedUsers?.filter((user) => {
      return user
    })
    return filteredUsers;
  };

  const onSubmit = () => {
    const journeyDetail: IJourneyDetailForm = {
      ...patientCareJourney,
      nodes: componentState.nodes,
      edges: componentState.edges,
    };
    setComponentState((prev) => {
      return {...prev, isLoadingAssignJourney: true};
    });
    saveJourneyToWorkflow(
      journeyDetail,
      (response) => {
        if (response?.workflowMasterId) {
          assignJourney(
            journeyDetail,
            patientCareJourney.careJourneyId || '',
            response.workflowMasterId,
            response.id
          );
        } else {
          setComponentState((prev) => {
            return {...prev, isLoadingAssignJourney: false};
          });
          showErrorToast();
        }
      },
      () => {
        setComponentState((prev) => {
          return {...prev, isLoadingAssignJourney: false};
        });
        showErrorToast();
      }
    );
  };

  const assignJourney = (
    data: IJourneyDetailForm,
    careJourneyId: string,
    careJourneyVersionId: string,
    workflowId: string
  ) => {
    const isFixedStartDate = data.startType === JOURNEY_START_TYPES.FIXED;
    let startDate = !!data.startDate
      ? getDateObjFromDateStrAndFormat(data.startDate, DISPLAY_DATE_FORMAT)
      : undefined;

    if (isFixedStartDate) {
      startDate = getMomentObj(data?.startDate as string).toDate();
    }

    if (isNaN(startDate as any)) {
      startDate = undefined;
    }
    const userList: any[] = [];
    const locationList: any[] = [];
    const documents =
      removeUnwantedKeyPatientDocumentObj(data?.careJourneyDocuments || []) ||
      [];
    const recommendCareJourneyList: any[] = [];
    (data.careTeam || []).forEach((userRole) => {
      const selectedUser = (data.careTeamUsers || []).find((item: any) => {
        if (item?.user) {
          const userRoleIds = item?.user?.userRoles?.map(
            (role: any) => role.userRole.id
          );
          const isRolePresent = userRoleIds?.includes(userRole) ?? false;
          return isRolePresent;
        }
        return false;
      });

      if (selectedUser) {
        userList.push({roleId: userRole, userId: selectedUser?.user.uuid});
      } else {
        userList.push({roleId: userRole, userId: undefined});
      }
    });
    data?.patientCareJourneyLocations?.forEach((location) => {
      locationList.push({locationId: location});
    });
    data?.patientRecommendedCareJourney?.forEach((journey) => {
      recommendCareJourneyList.push({packageId: journey});
    });
    const journeyStatus =
      startDate && startDate <= new Date()
        ? JOURNEYS_STATUS_CODE.ACTIVE
        : JOURNEYS_STATUS_CODE.ASSIGNED;
    const journeyStatusId = getMlovIdFromCode(
      patientJourneyStatusList,
      journeyStatus
    );
    const journeyStartAndEndDate = {startDate: '', endDate: ''};
    if (startDate) {
      if (isFixedStartDate) {
        journeyStartAndEndDate.startDate = getDateToMomentISOString(startDate);
      } else {
        if (isTodayDate(startDate)) {
          journeyStartAndEndDate.startDate = getDateToMomentISOString();
        } else {
          journeyStartAndEndDate.startDate =
            getJourneyStartDateWithOffset(startDate)?.toISOString();
        }
      }
      const endDate = getCareJourneyEndDate(
        new Date(journeyStartAndEndDate.startDate),
        data
      );
      journeyStartAndEndDate.endDate = getDateToMomentISOString(endDate);
    }
    const params: any = {
      title: data.name,
      description: data.description,
      careJourneyId,
      careJourneyVersionId,
      memberOutcome: data.memberOutcome,
      workflowId: workflowId,
      businessOutcome: data.businessOutcome,
      patientId: props.contactData?.patient?.patientUuid,
      duration: data?.duration?.value,
      durationUnitId: data?.duration?.unit,
      ...(journeyStatusId && {statusId: journeyStatusId}),
      startDateTime:
        data.startDateTime || journeyStartAndEndDate?.startDate || undefined,
      endDateTime: journeyStartAndEndDate?.endDate || undefined,
      patientCareJourneyTeam: {
        data: userList,
      },
      patientCareJourneyLocations: {
        data: locationList,
      },
      careJourneyTrackingResources: data?.careJourneyTrackingResources || [],
      patientRecommendedCareJourneys: {
        data: recommendCareJourneyList,
      },
      documents: documents,
      startTypeId: data.startTypeId,
    };
    if (data.updatedNodes && data.updatedNodes.length > 0) {
      params.workflowConfig = data.updatedNodes;
    }
    assignCareJourney({
      variables: {
        data: {
          operationCode: 'CREATE',
          data: params,
        },
      },
      onCompleted: () => {
        showToast(
          toast,
          intl.formatMessage({id: 'journeyAssignedSuccess'}),
          ToastType.success,
          2000,
          true
        );
        props.onComplete('');
        setComponentState((prev) => {
          return {...prev, isLoadingAssignJourney: false};
        });
      },
      onError: (error: any) => {
        showToast(
          toast,
          intl.formatMessage({id: 'journeyAssignedFailed'}),
          ToastType.error,
          2000,
          true
        );
        setComponentState((prev) => {
          return {...prev, isLoadingAssignJourney: false};
        });
      },
    });
  };

  const isAssignCareJourneyButtonEnabled = () => {
    if (
      patientCareJourney.name.length > 0 &&
      // getJourneyStartDate(patientCareJourney) &&
      patientCareJourney.duration?.value &&
      patientCareJourney.careTeamUsers &&
      patientCareJourney.careTeamUsers.length > 0 &&
      patientCareJourney?.careTeam &&
      patientCareJourney?.careTeam?.length > 0
    ) {
      const emptyRoleUserSelectBox = selectedUsersAndRolesMap?.current?.find(
        (roleMap) => {
          return roleMap.user.length === 0;
        }
      );
      if (emptyRoleUserSelectBox) {
        return false;
      }
      return true;
    }
    return false;
  };

  const redDotElement = (
    <span
      className="required-dot red-dot-4px"
      style={{
        backgroundColor: Colors.FoldPixel.STATUS_ERROR,
        width: '4px',
        height: '4px',
        margin: 0,
        marginLeft: '4px',
      }}
    ></span>
  );

  const searchCareJourneyUIElement = () => {
    return (
      <View style={{marginTop: 12}}>
        <Stack direction="column" space={4} style={{paddingHorizontal: 16}}>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <DisplayText
              size={'smRegular'}
              extraStyles={{
                color: Colors.FoldPixel.GRAY250,
              }}
              textLocalId={'careJourney'}
            />
            {redDotElement}
          </View>
          <CareJourneySearch
            placeHolder='Search and Select Care Journey'
            contactId={props?.contactData?.id}
            isShowError={showError}
            value={componentState?.selectedJourney}
            extraSelectProps={{
              autoFocus: componentState.autoFocusCareJourneySearch,
              defaultOpen: componentState.autoFocusCareJourneySearch,
              size: undefined
            }}
            onClear={() => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  selectedJourney: null,
                  isLoadingJourneyForm: false,
                  isJourneyAlreadyAssigned: false,
                };
              });
            }}
            onChange={(value: any) => {
              resetCareJourneyData();
              if (value.id) {
                setComponentState((prev) => {
                  return {
                    ...prev,
                    selectedJourney: value,
                    isLoadingJourneyForm: true,
                    isJourneyAlreadyAssigned: false,
                  };
                });
                fetchCareJourneyData(value.id);
              }
            }}
          />

          {componentState.selectedJourney && (
            <div
              className='pressable'
              style={{
                width: 'max-content',
                height: 'max-content',
              }}
              onClick={() => {
                setComponentState((prev: any) => {
                  return {
                    ...prev,
                    showCareJourneyFlow: true,
                  };
                });
              }}
            >
              <EyeOpenSvg
                width={12}
                height={12}
                customStrokeColor={Colors.FoldPixel.PRIMARY300}
              />
              <Text
                style={{
                  color: Colors.FoldPixel.PRIMARY300,
                  fontWeight: '400',
                  fontSize: 12,
                  lineHeight: 14.4,
                  marginLeft: 4
                }}
              >
                View Journey Design
              </Text>
            </div>
          )}
        </Stack>
      </View>
    );
  };

  const careJourneyFormUIElement = () => {
    return (
      <Stack direction="row" space={16} style={{paddingHorizontal: 16}}>
      <Stack direction="row" space={16} style={{flex: 1}}>
        <Stack direction="column" style={{flex: 0.5}} space={4}>
          <Stack direction="row" space={4} style={{alignItems: 'center'}}>
            <DisplayText
              size={'smRegular'}
              extraStyles={{
                color: Colors.FoldPixel.GRAY250,
              }}
              textLocalId={'startDate'}
            />
            {redDotElement}
          </Stack>
          <View
            style={{
              borderColor: '#8A94A8',
              padding: 2,
              height: 33,
              borderRadius: 4,
              borderWidth: 0.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
              }}
          >
            <DatePicker
              allowClear={false}
              disabled={patientCareJourney.startType === JOURNEY_START_TYPES.FIXED}
              value={
                getJourneyStartDate(patientCareJourney)
                  ? getMomentObj(getJourneyStartDate(patientCareJourney) ?? '')
                  : undefined
              }
              bordered={false}
              style={{
                width: '100%',
                outline: 'none',
                border: 'none'
              }}
              format={DISPLAY_DATE_FORMAT}
              onChange={(value, dateString) => {
                setPatientCareJourney((prev) => {
                  return {
                    ...prev,
                    startDate: dateString,
                  };
                });
              }}
              suffixIcon={
                <AddCalenderNavSvg
                  customStrokeColor={Colors.FoldPixel.GRAY300} width={16} height={16}
                />
              }
              disabledDate={(current: any) => {
                return current && isPastDay(current);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  const inputElement = e.target as HTMLInputElement;
                  if (inputElement.value === '') {
                    setPatientCareJourney((prev) => {
                      return {
                        ...prev,
                        startDate: undefined,
                      };
                    });
                  }
                }
              }}
              onFocus={(e) => {
                const target = e.target as HTMLElement;
                target.style.border = 'none';
                target.style.boxShadow = 'none';
              }}
              onMouseEnter={(e) => {
                const target = e.target as HTMLElement;
                target.style.border = 'none';
              }}
            />
          </View>
        </Stack>
        <Stack direction="column" style={{flex: 0.5}} space={4}>
          <View style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row'
          }}>
            <DisplayText
              size={'smRegular'}
              extraStyles={{
                color: Colors.FoldPixel.GRAY250,
              }}
              textLocalId={'duration'}
            />
            {redDotElement}
          </View>

          <View style={{height: 33, padding: 2, borderRadius: 4, borderWidth: 0.5, borderColor: '#8A94A8'}}>
            <JourneyDurationInputForAssign
              labelId={''}
              value={
                patientCareJourney.duration || ({} as IJourneyDurationData)
              }
              unitDropDownHeight={9}
              noBorder={true}
              customDropdownStyle={reactStyles.journeyDurationInput}
              onChange={(value) => {
                setPatientCareJourney((prev) => {
                  return {
                    ...prev,
                    duration: value,
                  };
                });
              }}
            />
          </View>
        </Stack>
        </Stack>
      </Stack>
    );
  };

  const userSearchUIElements = (careTeam: string[]) => {
    return (
      careTeam.length >= 1 && (
        <View style={{paddingHorizontal: 16}}>
          <Text
            style={{
              color: Colors.FoldPixel.GRAY300,
              fontSize: 14,
              fontWeight: '500',
              lineHeight: 16.8,
              marginBottom: 8,
            }}
          >
            {intl.formatMessage({id: 'selectCareTeamMembers'})}
          </Text>
          {careTeam?.map((role) => {
            const roleData = accountUserRoles.find((roleMlov) => {
              return roleMlov?.value === role;
            });
            const roleUserData = selectedUsersAndRolesMap?.current?.find((roleMap) => {
              return roleMap?.roleId === role
            });
            const user = roleUserData?.user
            return (
              <>
                {roleData ? (
                  <View
                    style={{marginBottom: 16}}
                    key={'UserSearch_Role_' + role}
                  >
                    <CustomUserSearch
                      userSelectRef={selectUsersRef}
                      isRequired={false}
                      className={'custom-select-box'}
                      placeholder={replaceHashValueToString(
                        {role: roleData?.label || ''},
                        intl.formatMessage({id: 'selectRoleUser'})
                      )}
                      disabledUsers={[]}
                      hideTooltip={true}
                      allowClear={true}
                      customLabel={
                        <>
                          <View
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row',
                            }}
                          >
                            <DisplayText
                              size={'smRegular'}
                              extraStyles={{
                                color: Colors.FoldPixel.GRAY250,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: 16.8,
                              }}
                              textLocalId={roleData?.label || ''}
                            />
                            {redDotElement}
                          </View>
                        </>
                      }
                      formControlLabelStyles={{
                        paddingBottom: 4,
                      }}
                      mode={CUSTOM_USER_SEARCH_MODE.SINGLE_SELECT}
                      selectedUsersData={user || []}
                      filterRoleTypes={role ? [role] : []}
                      onChange={(selectedUsersUuids, selectedInternalUsers) => {
                        if (selectedInternalUsers) {
                          selectedUsersAndRolesMap.current =
                            selectedUsersAndRolesMap?.current?.map(
                              (roleMap) => {
                                if (roleMap.roleId === role) {
                                  return {
                                    ...roleMap,
                                    user: selectedInternalUsers,
                                  };
                                }

                                return roleMap;
                              }
                            );

                          setPatientCareJourney((prev) => {
                            return {
                              ...prev,
                              careTeamUsers: getCareTeamSelectedUsersUuids(),
                            };
                          });
                        }
                        if (selectUsersRef.current) {
                          selectUsersRef?.current?.blur();
                        }
                      }}
                      userSearchType={
                        isMsoEnabled
                          ? USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED
                          : USER_SEARCH_TYPE.DEFAULT
                      }
                    />
                  </View>
                ) : null}
              </>
            );
          })}
        </View>
      )
    );
  };

  const addAndGetNewFilteredRoleMap = (roles: string[]): IUserAndRoleMap[] => {
    const newRoleMap = roles.map((roleId):IUserAndRoleMap=>{
      const existing = selectedUsersAndRolesMap.current?.find((roleMap)=>{
        return roleMap.roleId === roleId
      })

      if (existing) {
        return existing;
      }

      return {
        roleId: roleId,
        user: []
      }
    }) || [];

    const newFilteredRoleMap = newRoleMap.filter((roleMap)=>{
      return roles.includes(roleMap.roleId)
    })

    return newFilteredRoleMap;
  }

  const rolesSelectElement = () => {
    selectedUsersAndRolesMap.current = addAndGetNewFilteredRoleMap(patientCareJourney?.careTeam || []);

    return (
      <View style={{paddingHorizontal: 16, marginBottom: 8}}>
        <JourneyDetailTagInput
          isRequired={true}
          fullWidth={true}
          labelId={"careTeam"}
          responsive={true}
          descriptionId="journeyCareTeamDescription"
          titleId={'careTeamRoles'}
          placeholderId={'selectRequiredTeamForCareJourney'}
          value={patientCareJourney.careTeam || []}
          options={userRoleMlov}
          className={'custom-select-box'}
          onClear={() => {
            selectedUsersAndRolesMap.current = []
            setPatientCareJourney((prev) => {
              return {...prev, careTeam: []};
            });
          }}
          onChange={(value) => {
            setPatientCareJourney((prev) => {
              return {...prev, careTeam: value};
            });

            selectedUsersAndRolesMap.current = addAndGetNewFilteredRoleMap(value);
            setPatientCareJourney((prev) => {
              return {
                ...prev,
                careTeamUsers: getCareTeamSelectedUsersUuids(),
              };
            });
          }}
        />
      </View>
    );
  };

  const assignJourneyHeaderUIElement = () => {
    return (
      <Stack
        direction="row"
        style={{
          justifyContent: 'space-between',
          paddingHorizontal: 16,
          marginVertical: 6,
        }}
      >
        <Stack direction="row" space={8} style={{alignItems: 'center'}}>
          {getBackNavigationButton()}
          <DisplayText textLocalId={'assignJourney'} size={'mdSemibold'} />
        </Stack>
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            marginTop: 0,
            onPress: () => {
              onSubmit();
            },
            leftIcon: (
              <AssignCareJourneySvg
                customStrokeColor={
                  isAssignCareJourneyButtonEnabled()
                    ? Colors.Custom.mainPrimaryPurple
                    : Colors.Custom.GRAY150
                }
              />
            ),
            backgroundColor: isAssignCareJourneyButtonEnabled()
              ? Colors.Custom.PrimaryColor100
              : Colors.Custom.Gray50,
            borderWidth: isAssignCareJourneyButtonEnabled() ? 0.5 : 0,
            borderColor: Colors.Custom.PrimaryColor200,
            height: '32px',
            borderRadius: 4,
            isLoading: componentState.isLoadingAssignJourney,
            isDisabled: !isAssignCareJourneyButtonEnabled(),
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'assignJourney',
            }),
            withRightBorder: false,
          }}
        />
      </Stack>
    );
  };

  const careJourneyFlowHeaderUIElement = () => {
    return (
      <Stack
        direction="row"
        style={{
          justifyContent: 'space-between',
          paddingHorizontal: 16,
          marginVertical: 6,
        }}
      >
        <Stack direction="row" space={8} style={{alignItems: 'center'}}>
          {getCloseJourneyFlowViewButton()}
          <DisplayText
            textLocalId={
              componentState?.selectedJourney?.title || 'journeyFlow'
            }
            size={'mdSemibold'}
          />
        </Stack>
      </Stack>
    );
  };

  return (
    <>
      {componentState.showCareJourneyFlow
        ? careJourneyFlowHeaderUIElement()
        : assignJourneyHeaderUIElement()}
      <Divider />
      <View
        style={{
          flex: 1,
          overflow: 'scroll',
        }}
      >
        <Stack direction="column" style={styles.height100p}>
            <div className={componentState.showCareJourneyFlow ? 'height100p' : 'fh-display-none'}>
              <View style={styles.p8Height100p}>
                {componentState.isLoadingJourneyForm ? (
                  <View style={styles.centerContent}>
                    <Spinner />
                  </View>
                ) : (
                  <ViewCareJourneyFlow
                    workflowContextData={workflowContextData}
                    setWorkflowContextData={setWorkflowContextData}
                    nodes={componentState.nodes}
                    edges={componentState.edges}
                    containerStyles={styles.height100p}
                  />
                )}
              </View>
            </div>
            <div className={componentState.showCareJourneyFlow ? 'fh-display-none' : ''}>
              <View
                pointerEvents={
                  componentState.isLoadingAssignJourney ? 'none' : 'auto'
                }
              >
                <Stack direction="column">
                  {searchCareJourneyUIElement()}
                  {componentState.isLoadingJourneyForm ? (
                    <View style={{width: 'full', padding: 16}}>
                      <Skeleton active />
                    </View>
                  ) : (
                    <>
                      {(patientCareJourney.name.length > 0 && !componentState.isJourneyAlreadyAssigned) && (
                        <Stack direction="column" style={{marginTop: 16}}>
                          {careJourneyFormUIElement()}
                          <View
                            style={{
                              paddingHorizontal: 16,
                              paddingVertical: 8,
                              marginTop: 16,
                              marginBottom: 8,
                            }}
                          >
                            <Divider />
                          </View>
                          {rolesSelectElement()}
                          {userSearchUIElements(
                            patientCareJourney.careTeam || []
                          )}
                        </Stack>
                      )}
                    </>
                  )}
                </Stack>
              </View>
            </div>
        </Stack>
      </View>
    </>
  );
};

export default SidecarAssignCareJourney;
