import {Pressable, View} from 'react-native';
import { Colors } from '../../styles';
import { Divider, Skeleton, VStack } from 'native-base';
import { HStack } from 'native-base';
import CrossIcon from '../common/Svg/CrossIcon';
import { useCallback } from 'react';

export const SidecarDrawerLoadingSkeleton = (props: {handleCloseClick?: () => void, shouldShowCloseButton?: boolean}) => {
  const renderLoadingSkeleton = useCallback(() => {
    return (
      <View style={{flex: 1, padding: 12}}>
        <HStack space={2} alignItems="flex-start" marginBottom={3}>
          <Skeleton 
            h={16} 
            w={16} 
            rounded="full"
            startColor={Colors.FoldPixel.GRAY50}
            endColor={Colors.FoldPixel.GRAY100}
            isLoaded={false}
          />
          <VStack flex={1} space={2}>
            <Skeleton 
              h={6}
              w="40%"
              rounded="sm"
              startColor={Colors.FoldPixel.GRAY50}
              endColor={Colors.FoldPixel.GRAY100}
              isLoaded={false}
            />
            <HStack space={2}>
              <Skeleton 
                h={4}
                w={20}
                rounded="sm"
                startColor={Colors.FoldPixel.GRAY50}
                endColor={Colors.FoldPixel.GRAY100}
                isLoaded={false}
              />
              <Skeleton 
                h={4}
                w={24}
                rounded="sm"
                startColor={Colors.FoldPixel.GRAY50}
                endColor={Colors.FoldPixel.GRAY100}
                isLoaded={false}
              />
              <Skeleton 
                h={4}
                w={16}
                rounded="sm"
                startColor={Colors.FoldPixel.GRAY50}
                endColor={Colors.FoldPixel.GRAY100}
                isLoaded={false}
              />
            </HStack>
            <Skeleton 
              h={4}
              w="30%"
              rounded="sm"
              startColor={Colors.FoldPixel.GRAY50}
              endColor={Colors.FoldPixel.GRAY100}
              isLoaded={false}
            />
          </VStack>
          <HStack space={2} alignItems="flex-start">
            <Skeleton
              h={8}
              w={8}
              rounded="sm"
              startColor={Colors.FoldPixel.GRAY50}
              endColor={Colors.FoldPixel.GRAY100}
              isLoaded={false}
            />
            {props?.shouldShowCloseButton ? (
              <Pressable
                onPress={props.handleCloseClick}
                style={{ marginRight: 4, marginLeft: 4 }}
              >
                <CrossIcon size={30} />
              </Pressable>
            ) : <Skeleton
              h={8}
              w={8}
              rounded="sm"
              startColor={Colors.FoldPixel.GRAY50}
              endColor={Colors.FoldPixel.GRAY100}
              isLoaded={false}
            />
            }
          </HStack>
        </HStack>

        <Divider marginBottom={3}/>
        <HStack space={1} flexWrap="wrap" marginBottom={3}>
          {[100, ...Array(4).fill(70)].map((width, index) => (
            <Skeleton
              startColor={Colors.FoldPixel.GRAY50}
              endColor={Colors.FoldPixel.GRAY100}
              key={index} 
              width={width} 
              height={4} 
              borderRadius={12} 
            />
          ))}
        </HStack>
       
        <Divider marginBottom={3}/>
        <HStack space={0} marginBottom={3}>
          {[1,2,3,4,5].map((i, index) => (
            <>
              <Skeleton
                key={i}
                h={10}
                w="15%"
                rounded="sm"
                startColor={Colors.FoldPixel.GRAY50}
                endColor={Colors.FoldPixel.GRAY100}
                isLoaded={false}
                borderRadius={4}
              />
              {index < 4 && (
                <Divider orientation="vertical" mx={2} h={10} />
              )}
            </>
          ))}
        </HStack>
        <Divider marginBottom={3}/>
        {[1,2,3].map(i => (
          <Skeleton
            key={i}
            h={40}
            w="100%"
            rounded="md"
            startColor={Colors.FoldPixel.GRAY50}
            endColor={Colors.FoldPixel.GRAY100}
            mb={5}
            isLoaded={false}
            borderRadius={8}
          />
        ))}
      </View>
    );
  }, [props.shouldShowCloseButton]);

  return renderLoadingSkeleton();
};