enum FeatureFlags {
    IS_MULTI_TENANCY_ENABLED = 'is_multi_tenancy_enabled',
    IS_NEW_SETTING_TAB_ENABLED = 'is_open_new_setting_tab_enabled',
    HIDE_PROJECTED_REVENUE_IN_CONTRACT = 'hide_projected_revenue_in_contract',
    HIDE_RISK_LEVEL_IN_CONTRACT = 'hide_risk_level_in_contract',
    HIDE_PAYER_COLUMN_IN_CONTRACT = 'hide_payer_column_in_contract',
    SHOW_MANUAL_ADD_GAPS = 'show_manual_add_gaps'
}

export default FeatureFlags;
