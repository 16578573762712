import React from 'react';
import { DATE_FORMATS, DISPLAY_SLASH_DATE_FORMAT, GROUP_MEMBER_TYPE, TASK_ATTACHMENT_SOURCE, TASK_TYPES } from "../../../constants";
import { TASK_PRIORITY_CODES } from "../../../constants/MlovConst";
import { getDateToMomentISOString, getEndOfDay, getMomentObjFromFormat } from "../../../utils/DateUtils";
import { getCompletedTaskStatusId, getDefaultTaskStatusId } from "../CareDashboard/CareDashboardUtils/CareDashboardUtils";
import { IAddOrUpdateTaskState, ISubTasks, ITaskCommentsList } from "./interfaces"
import { ILabelTask, ITask } from '../CareDashboard/CareDashboardInterfaces';
import { removeDuplicates } from '../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/LeadListView/LeadListHelper';
import { isAndroid, isWeb } from '../../../utils/platformCheckUtils';
import {TASK_ASSIGNEE_TYPE_CODES} from '../CareDashboard/CareDashboardTable/CareDashboardTableHelper';

export const showInitialCommentsCount = 2;

export const ADD_SUBTASK_CONST = {
    ADD_NEW: 'ADD_NEW_SUBTASK',
    DELETE: 'DELETE_SUBTASK',
    UPDATE_TITLE: 'UPDATE_TITLE_SUBTASK',
    MARK_COMPLETE: 'MARK_COMPLETE',
    DUE_DATE: 'DUE_DATE',
    CHANGE_ASSIGNEE: 'CHANGE_ASSIGNEE',
    EVIDENCE: 'EVIDENCE',
    ATTACHMENTS: 'ATTACHMENTS',
}

export const ADD_COMMENT_CONST = {
  ADD_NEW: 'ADD_NEW_COMMENT',
  DELETE: 'DELETE'
}

export const RESOURCE_MAP = {
  TASK: 'TASK',
  FORM: 'FORM',
  MESSAGE: 'MESSAGE',
  APPOINTMENT: 'APPOINTMENT',
};

export const SOURCE_MAP = {
  MESSAGE: 'Message',
  NOTES: 'DocumentReference',
  CARE_PLAN: 'CarePlan'
};

export const getSubTasksVariables = (
  args: {
    taskData: IAddOrUpdateTaskState,
    taskStatusMlov: any,
    taskType?: string
  }
) => {
  const {taskData, taskStatusMlov, taskType} = args;
  const subTaskData = [] as ISubTasks[];
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const defaultStatusIdForTask = getDefaultTaskStatusId(taskStatusMlov);

  taskData?.subTasks?.map((subTaskItem: ISubTasks) => {
    if (subTaskItem.title?.length) {
      let startDateTime = getMomentObjFromFormat(
        taskData.dueDate,
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      ).toISOString();
      if (!isWeb()) {
        const dateWithoutHyphens = taskData.dueDate?.replace(/-/g, '/');
        startDateTime = getMomentObjFromFormat(
          dateWithoutHyphens,
          DISPLAY_SLASH_DATE_FORMAT
        ).toISOString();
      }
      const endDateTime = getEndOfDay(subTaskItem?.endDateTime)
      const endDate = getDateToMomentISOString(endDateTime);

      let taskStatusId = '';
      if (subTaskItem.isChecked) {
        taskStatusId = completedStatusId;
      } else {
        taskStatusId = defaultStatusIdForTask;
      }

      let item: any = {
        id: subTaskItem?.id || undefined,
        title: subTaskItem.title,
        description: '',
        // remove as of temporary taskData.assignee?.value || '',
        // assigneeId: subTaskItem?.assignee?.value || taskData.assignee?.value || '',
        assigneeTypeCode: TASK_ASSIGNEE_TYPE_CODES.CARE_TEAM,
        assignedById: taskData.assignedBy?.value || '',
        startDateTime: startDateTime,
        endDateTime: endDate,
        statusId: taskStatusId,
        priorityId: taskData.priority?.key,
        isEscalated:
          taskData.isImportant ||
          taskData.priority?.value !== TASK_PRIORITY_CODES.LOW,
        contactId: taskData.customer?.value || null,
        userPoolId: taskData.taskPool?.value || null,
      };
      if (taskType === TASK_TYPES.DIAGNOSIS_GAPS && (subTaskItem.taskDiagnosisGapDetail?.diagnosisCode || subTaskItem.taskDiagnosisGapDetail?.diagnosisSystemCode)) {
        item = {
          ...item,
          taskDiagnosisGapDetail: {
            ...(taskData.taskDiagnosisGapDetailId && {
              id: taskData.taskDiagnosisGapDetailId
            }),
            hccCategoryId: subTaskItem.taskDiagnosisGapDetail?.hccCategoryId,
            diagnosisCode: subTaskItem.taskDiagnosisGapDetail?.diagnosisCode,
            diagnosisSystemCode: subTaskItem.taskDiagnosisGapDetail?.diagnosisSystemCode,
            evidenceDescription: subTaskItem.taskDiagnosisGapDetail?.evidenceDescription,
          }
        }
      }
      subTaskData.push(item);
    }
  });
  return subTaskData;
};

export const getCommentsVariables = (
  taskData: IAddOrUpdateTaskState
) => {
  return taskData?.commentsList?.map((comment)=>{
    return {
      comment: comment.comment,
      isDeleted: false
    }
  })
};


export const getSingleSubTasksVariables = (
  taskData: IAddOrUpdateTaskState,
  subTask: ISubTasks,
  taskStatusMlov: any
) => {
  const subTaskData = [] as ISubTasks[];
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const defaultStatusIdForTask = getDefaultTaskStatusId(taskStatusMlov);

    if (subTask.title?.length) {
      let startDateTime = getMomentObjFromFormat(
        taskData.dueDate,
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      ).toISOString();
      if (!isWeb()) {
        const dateWithoutHyphens = taskData.dueDate?.replace(/-/g, '/');
        startDateTime = getMomentObjFromFormat(
          dateWithoutHyphens,
          DISPLAY_SLASH_DATE_FORMAT
        ).toISOString();
      }
      const endDate = subTask.endDateTime?.length
        ? getMomentObjFromFormat(
            subTask.endDateTime,
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          ).toISOString()
        : getEndOfDay(startDateTime).toISOString();

      let taskStatusId = '';
      if (subTask.isChecked) {
        taskStatusId = completedStatusId;
      } else {
        taskStatusId = defaultStatusIdForTask;
      }

      let item: any = {
        id: subTask?.id || undefined,
        title: subTask.title,
        description: '',
        assigneeId: subTask?.assignee?.value || taskData.assignee?.value || '',
        assignedById: taskData.assignedBy?.value || '',
        startDateTime: startDateTime,
        endDateTime: endDate,
        statusId: taskStatusId,
        priorityId: taskData.priority?.key,
        isEscalated:
          taskData.isImportant ||
          taskData.priority?.value !== TASK_PRIORITY_CODES.LOW,
        contactId: taskData.customer?.value || null,
        userPoolId: taskData.taskPool?.value || null,
      };
      if (subTask.taskDiagnosisGapDetail?.diagnosisCode || subTask.taskDiagnosisGapDetail?.diagnosisSystemCode) {
        item = {
          ...item,
          taskDiagnosisGapDetail: {
            ...(subTask.taskDiagnosisGapDetail?.id && {
              id: subTask.taskDiagnosisGapDetail?.id
            }),
            hccCategoryId: subTask.taskDiagnosisGapDetail?.hccCategoryId,
            diagnosisCode: subTask.taskDiagnosisGapDetail?.diagnosisCode,
            diagnosisSystemCode: subTask.taskDiagnosisGapDetail?.diagnosisSystemCode,
          }
        }
      }
      subTaskData.push(item);
    }
  return subTaskData;
};

export const getFormattedLabelsData = (
  taskLabels: ILabelTask[],
  isEditTask: boolean,
  selectedLabels?: string[],
) => {
  const labels = [] as any[];
  if (!isEditTask) {
    if (selectedLabels) {
      selectedLabels?.forEach((item: string) => {
        const labelsData = {
          labelId: item,
        } as any;
        labels.push(labelsData);
      });
    }
  } else {
    if (selectedLabels) {
      selectedLabels?.forEach((item: string) => {
        const labelsData = {
          labelId: item,
        } as any;
        labels.push(labelsData);
      });
      taskLabels?.length &&
        taskLabels?.forEach((initialTaskLabelsItem) => {
          let labelsData = {} as any;
          if (selectedLabels?.includes(initialTaskLabelsItem?.uuid || initialTaskLabelsItem?.labelId  || '')) {
            labelsData = {
              labelId: initialTaskLabelsItem?.labelId,
              id: initialTaskLabelsItem?.id,
              isDeleted: false,
            };
          } else {
            labelsData = {
              labelId: initialTaskLabelsItem?.labelId,
              id: initialTaskLabelsItem?.id,
              isDeleted: true,
            };
          }
          labels.push(labelsData);
        });
    } else if (!selectedLabels && taskLabels?.length) {
      taskLabels?.forEach((item) => {
        const labelsData = {
          labelId: item?.labelId,
          id: item?.id,
          isDeleted: false,
        } as any;
        labels.push(labelsData);
      });
    }
  }
  return removeDuplicates(labels, 'labelId');
};
export const getFormattedContent = (content: string): string => {
  return content.replace(/\n/g, '');
};

export const formatForMentionUsers = (
  accountUsers: any[]
) => {
  return (accountUsers || [])
    .map((user) => {
      return {
        ...user,
        groupUserId: user?.userId || user?.uuid,
        groupUserType: {
          code: user?.groupUserType?.code || GROUP_MEMBER_TYPE.USER,
          value: user?.groupUserType?.value || 'User',
        },
        user: {
          userRoles: user.userRoles,
          name: user.name,
          accountUsers: user?.accountUsers || [],
        },
      };
    });
};

export const getContentWithMentionText = (
  commentText: string,
  accountUsersList: any[]
) => {
  const replacedContent = commentText?.replace(
    mentionRegexForGetTaskComment,
    (match, type, uuid) => {
      const user = accountUsersList?.find((user) => {
        return user?.uuid === uuid
      })
      const isInactive = !user?.user?.accountUsers?.[0]?.isActive;
      if (user) {
        let content = '';
        const userName = user?.userName || user.name;
        const displayName = isInactive ? `${userName} (Inactive)` : userName;
        if (user?.groupUserType?.code === GROUP_MEMBER_TYPE.USER || !user?.groupUserType) {
          content = `[@${displayName}](mention://user/${uuid})`;
        } else if (
          user?.groupUserType?.code === GROUP_MEMBER_TYPE.PATIENT ||
          user?.groupUserType?.code === GROUP_MEMBER_TYPE.CONTACT
        ) {
          content = `[@${displayName}](mention://contact/${uuid})`;
        }
        return content;
      } else {
        return match;
      }
    }
  );
  return replacedContent;
};

export const getContentWithMentionTextForMobile = (
  commentText: string,
  accountUsersList: any[]
) => {
  const replacedContent = commentText?.replace(
    mentionRegexForGetTaskComment,
    (match, type, uuid) => {
      const user = accountUsersList.find((user) => user?.uuid === uuid);
      if (user) {
        const encodedName = encodeURIComponent(user.userName);
        let content = '';
        if (user?.groupUserType?.code === GROUP_MEMBER_TYPE.USER || !user?.groupUserType) {
          content = `[@${user?.userName || user?.name}](mention://user/${uuid}/user_uuid/${encodedName})`;
        } else if (
          user?.groupUserType?.code === GROUP_MEMBER_TYPE.PATIENT ||
          user?.groupUserType?.code === GROUP_MEMBER_TYPE.CONTACT
        ) {
          content = `[@${user.userName}](mention://contact/${uuid}/user_uuid/${encodedName})`;
        }
        return content;
      } else {
        return match;
      }
    }
  );
  return replacedContent;
};

export const mentionRegexForGetTaskComment = /@\[mention:\/\/(USER|CONTACT)\/([\w-]+)\]/g;
export const mentionPartPostPattern = /\[(@[^\]]+)\]\(mention:\/\/(user|contact)\/([\w-]+)\)/g;
export const mentionPatternForMobile = /\[@(.*?)\]\(mention:\/\/(user|contact)\/([\w-]+)\/user_uuid\/(.*?)\)/;
export const mentionPatternForMobileTaskComment = /\[@(.*?)\]\(mention:\/\/(user|contact)\/([\w-]+)\/user_uuid\/(.*?)\)/g;

export const uniqueArray = (originalArray: any[]) => {
  return originalArray?.filter(
    (obj, index, self) =>
      index === self?.findIndex((o) => o?.name === obj?.name)
  );
};

export const manageAttachmentsListData = (fileList: any[]) => {
  return fileList?.map((item: any) => {
    if (item?.taskDocumentAttachments?.name) {
      let uri = '';
      if (item?.source === TASK_ATTACHMENT_SOURCE.MEDIA) {
        uri = isAndroid()
        ? item?.fileInfo.uri
        : item?.fileInfo.uri.replace('file://', '');
      }
      return {
        ...item,
        name: item?.taskDocumentAttachments?.name,
        type: item?.taskDocumentAttachments?.type,
        uid: item.attachmentId,
        ...(uri && {uri: uri}),
      }
    } else {
      return item;
    }
  })
}

export const getSelectedAccountLocations = (
 args:{ isMultiTenancyEnabled: boolean,
  allowedAccountLocations: Array<any>,
  editTask?: { taskLocations?: Array<{ locationId: string }> ,id?: string}
}): Array<any>  => {
  const {allowedAccountLocations, isMultiTenancyEnabled, editTask} = args
  if (isMultiTenancyEnabled) {
    if (allowedAccountLocations.length === 1 && !!!editTask?.id) {
      return allowedAccountLocations;
    } else {
      return allowedAccountLocations?.filter((location) => {
        return editTask?.taskLocations?.[0]?.locationId === location?.uuid;
      });
    }
  } else {
    return [];
  }
}