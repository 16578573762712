import { CaretDownOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, InputNumber, Select, Space, Spin } from 'antd';
import {
  FormControl,
  HStack,
  Input,
  Text,
  View
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { ISearchFieldProps } from '../../../Interfaces';
import { Colors } from '../../../styles';
import { getAccountUUID, getBooleanFeatureFlag, getFormURL } from '../../../utils/commonUtils';
import EmailTemplateCreatorEditorRightPanel from '../../RightSideContainer/ContentManagement/CampaignManagement/EmailOrSmsCreatorForm/EmailTemplateCreatorEditorRightPanel';
import { ContentTypes } from '../../RightSideContainer/ContentManagement/ContentManagementConsts';
import {
  getTemplateCategories,
  getTemplateCategoryList,
  getTemplates
} from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import { ITemplateCategory } from '../../RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import { RichText } from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/RichText/RichText';
import ContentSearch, { ContentType } from '../ContentSearch/ContentSearch';
import FormSearch from '../FormSearch/FormSearch';
import DisplayTemplateById from './DisplayTemplateById';
import FormListConfigField from './FormListConfigField';
import { filteredFormsCategoryList } from '../../../utils/commonUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getUserPermissionByEntityAndActionCode, USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import { WorkflowContext } from '../../../context/WorkflowContext';
import { usePermissions } from '../../CustomHooks/usePermissions';
import FeatureFlags from '../../../constants/FeatureFlags.enums';

const { Option } = Select;

const TemplateSearch = (props: ISearchFieldProps) => {
  const { isShowError, onChange } = props;
  const commonData = useContext(CommonDataContext);
  const isEnabledMultiTenancy = getBooleanFeatureFlag(commonData?.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED)
  const isSideCarContext = !!commonData?.sidecarContext?.isSidecar;
  const {check} = usePermissions();
  const settingsPermissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, MAIN_MENU_CODES.SETTING);
  const contentManagementPermissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.CONTENT_MANAGEMENT);
  const workflowContext = useContext(WorkflowContext);
  const [value, setValue] = useState(props.value);
  const [loading, setLoading] = useState(false);
  const [templateDataList, setTemplateDataList] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState<ITemplateCategory[]>([]);
  const accountUuid = getAccountUUID();
  const intl = useIntl();
  const formInfoMessage = intl.formatMessage({ id: 'genericHealthComponentFormInfoMessageForLead' });
  const userInputFieldList = props?.userInputFieldList || [];
  let isSkipEmail = props?.skipEmailTemplate || false;
  userInputFieldList.some((userInputField: any) => {
    if (userInputField?.key == 'isSkipEmail') {
      if (userInputField?.value == 'TRUE') {
        isSkipEmail = true;
      }
      return true;
    }
  })

  const possibleValueList: {value: string, displayName: string} [] = [
      { "value": 'Minutes', "displayName": "Minutes" },
      { "value": 'Hours', "displayName": "Hours" },
      { "value": 'Days', "displayName": "Days" },
      { "value": 'Weeks', "displayName": "Weeks" },
      { "value": 'Months', "displayName": "Months" },
      { "value": 'Years', "displayName": "Years" }
    ];

  useEffect(()=>{
    if (props?.skipEmailTemplate) {
      onChange({isSkipSendEmail: true});
    } else if(props?.value && props?.value?.isSkipEmail !== isSkipEmail){
      onChange({...props?.value,isSkipEmail, isSkipSendEmail: false});
    } else {
      onChange({...props?.value, isSkipSendEmail: false});
    }
  },[isSkipEmail, props?.skipEmailTemplate])

  const setTemplateDefaultData = () => {
    if (value && value?.template?.id) {
      if (templateDataList.length === 0) {
        setTemplateDataList([
          {
            id: value?.template?.id,
            attributes: value?.template?.attributes,
          },
        ]);
        if (value?.category?.code) {
          searchTemplateList(value?.category?.code)
        }
      } else {
        const isPresent = templateDataList.some((templateData) => {
          return templateData.id === value?.template?.id;
        });
        if (!isPresent) {
          setTemplateDataList([
            {
              id: value?.template?.id,
              attributes: value?.template?.attributes,
            },
          ]);
        }
      }
    }
  };
  const setCategoryDefaultData = () => {
    if (value && value?.category?.id) {
      if (categoryList.length === 0) {
        setCategoryList([
          {
            id: value?.category?.id,
            name: value?.category?.name,
            code: value?.category?.code,
          },
        ]);
      } else {
        const isPresent = categoryList.some((category) => {
          return category.id === value?.category?.id;
        });
        if (!isPresent) {
          setCategoryList([
            {
              id: value?.category?.id,
              name: value?.category?.name,
              code: value?.category?.code,
            },
          ]);
        }
      }
    }
  };
  let DEFAULT_CATEGORY = props.defaultTemplateCategory || 'GENERAL';
  const GENERAL_CATEGORY_CODE = 'GENERAL';
  const PATIENT_FORM = 'PAT_FORM';
  const MULTIPLE_PATIENT_FORMS = 'MULTIPLE_PATIENT_FORMS';
  const PAT_CONTENT = 'PAT_CONTENT';

  const [searchParams] = useSearchParams();

  DEFAULT_CATEGORY =
    searchParams.get('DEFAULT_CATEGORY_CODE') || props.defaultTemplateCategory || 'GENERAL';
  const DEFAULT_TEMPLATE_ID = searchParams.get('DEFAULT_TEMPLATE_ID');
  const [isInit, setIsInit] = useState(false)
  useEffect(() => {

    setTemplateDefaultData();
    setCategoryDefaultData();
    setLoading(true);
    getTemplateCategories()
      .then((data) => {
        setLoading(false);
        return getTemplateCategoryList(data);
      })
      .then((list) => {

        const filteredCategories = filteredFormsCategoryList(list)
        DEFAULT_CATEGORY = value?.category?.code || DEFAULT_CATEGORY

        if (!value || DEFAULT_CATEGORY != value?.category?.code || !isInit) {
          setIsInit(true);
          filteredCategories.some((category) => {
            if (category.code === DEFAULT_CATEGORY) {
              setLoading(true);
              searchTemplateList(category.code, (templateList: any) => {
                setLoading(false);
                const isSet = templateList.some((template: any) => {
                  if (
                    (template?.attributes?.isDefault && !DEFAULT_TEMPLATE_ID) ||
                    (DEFAULT_TEMPLATE_ID && DEFAULT_TEMPLATE_ID == template?.id)
                  ) {
                    const attributes: any = {};

                    if (template.attributes) {
                      attributes.name = template.attributes.name;
                      attributes.isDefault = template.attributes.isDefault;
                      attributes.subject = template.attributes.subject;
                    }
                    if (!value?.category) {
                      setValue((value: any) => {
                        const newValue = {
                          category,
                          skipEmailTemplate: props?.skipEmailTemplate,
                          template: {
                            id: template.id,
                            attributes: attributes,
                          },
                          subject: template.attributes.subject,
                        };
                        onChange(newValue);
                        return newValue;
                      });
                    }
                    return true;
                  }
                });
                if (!isSet && !value?.category) {
                  setValue((value: any) => {
                    const newValue = {
                      skipEmailTemplate: props?.skipEmailTemplate,
                      category
                    };
                    onChange(newValue);
                    return newValue;
                  });
                }
              });
              return true;
            }
          });
        }

        setCategoryList(filteredCategories);

      })
      .catch((error) => {
        setLoading(false);
        setIsInit(true);
      });
  }, []);

  const [shouldAddEmailTemplate, setShouldAddEmailTemplate] = useState(false);

  const searchTemplateList = (categoryCode?: string, callback?: any) => {
    setTemplateDataList([]);
    setLoading(true);
    const templateCategoryCode = value?.category?.code;
    const path = `${ContentTypes.emails.path}?category=${categoryCode || templateCategoryCode
      }`;
    getTemplates(path).then((response) => {
      setLoading(false);
      setTemplateDataList(response.data || []);
      callback ? callback(response.data) : [];
    });
  };

  const getFromList = () => {
    let formList = props?.value?.formList;
    if (formList && formList.length) {
      return formList;
    }
    const userInputFieldList = props.userInputFieldList || [];
    userInputFieldList.some((userInputField: any) => {
      if (userInputField?.key === 'formList' && userInputField?.value?.length) {
        const newList = userInputField.value || [];
        newList.forEach((form: any) => {
          const formLink = getFormURL(form?.id, '', accountUuid);
          form.link = formLink;
        })
        formList = newList
        return true;
      }
    })
    return formList
  }

  const isFormListUserInputFieldPresent = userInputFieldList.some((userInputField:any)=>{ return userInputField?.key === 'formList'});
  const isAppointmentTypeUserInputFieldPresent = userInputFieldList.some((userInputField:any)=>{ return userInputField?.key === 'appointmentType'});
  const getEducationContent = () => {
    let educationContent = props?.value?.educationContent
    if (educationContent) {
      return educationContent
    }
    const userInputFieldList = props.userInputFieldList || [];
    userInputFieldList.some((userInputField: any) => {
      if (((userInputField?.key === 'entity') && userInputField?.display == 'Patient Education') ||   userInputField?.key === 'CONTENT_SEARCH' || userInputField?.key === 'educationContent') {
        educationContent = userInputField.value
        return true;
      }
    })
    return educationContent
  }

  const [isGeneralTextPresent, setIsGeneralTextPresent]=useState(false);
  const [isFormListTagPresent, setIsFormListTagPresent]=useState(false);
  const [isAppointmentLinkTagPresent, setIsAppointmentLinkTagPresent]=useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onApplyChanges = (data: any) => {
    if (data?.attributes?.category?.data?.attributes?.code === PAT_CONTENT) {
      setValue((value: any) => {
        const newValue = { ...value, template: { id: data.id, attributes: { name: data.attributes?.name, subject: data?.attributes?.subject } } };
        if (data?.attributes?.subject) {
          newValue.subject = data?.attributes?.subject;
        }
        onChange(newValue);
        return newValue;
      });
    } else if (
      (data?.attributes?.subject && value?.template?.attributes?.subject !== data?.attributes?.subject) ||
      (data?.attributes?.name && value?.template?.attributes?.name !== data?.attributes?.name)
    ) {
      setValue((prev: any) => {
        const newValue = {
          ...prev,
          subject: data?.attributes?.subject,
          template: {
            ...prev?.template,
            attributes: {
              ...prev?.template?.attributes,
              name: data?.attributes?.name
            }
          }
        };
        onChange(newValue);
        return newValue;
      });
    } else {
      setValue((prev: any) => {
        const newValue = { ...prev, subject: '' , template: undefined }
        onChange(newValue);
        return newValue;
      })
      setTemplateDataList((prev) => {
        return prev?.filter((template) => {
          return template.id !== data.id
        })
      })
    }
  };

  return (
    <>
    { props.skipEmailTemplate &&
      <>
      <View style={{ marginTop: 16 }}>
          <HStack>
            <HStack flex={2.4}>
              <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                {'Max Frequency'}
              </Text>
            </HStack>
            <HStack flex={7}>
              <View marginY={1} flex={1}>
                <HStack flex={1}>
                  <View paddingRight={1}>

                    <InputNumber

                      min={0}
                      style={{ height: 36, fontSize: 13, borderRadius: '10px' }}
                      value={props.value?.formFrequency?.count}
                      type="number"
                      placeholder='Number'
                      className={''}
                      onChange={(count) => {
                        const formFrequency = (props.value || {})?.formFrequency || {}

                        if (count == undefined) {
                          formFrequency.count = undefined
                          setValue({ ...(props.value || {}), formFrequency });
                        } else {
                          formFrequency.count = count;
                          setValue({ ...(props.value || {}), formFrequency });
                        }
                      }}
                      onBlur={() => {
                        props.onChange(value);
                      }}
                    />
                  </View>
                  <View flex={5} paddingRight={1}>
                    <Select
                      status={''}
                      allowClear={true}
                      onClear={() => {
                        const newValue = { ...value };
                        delete newValue?.formFrequency
                        props.onChange(newValue)
                      }}
                      value={props?.value?.formFrequency?.unit?.code}
                      showSearch={true}
                      filterOption={(input, option: any) => {
                        return (
                          option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      placeholder={'Select Unit'}
                      onChange={(itemValue) => {
                        let displayValue = itemValue;
                        possibleValueList.some((unit: any) => {
                          if (unit.value === itemValue) {
                            displayValue = unit.displayName;
                          }
                        });
                        const formFrequency = props?.value?.formFrequency || {}
                        const newFormFrequency = { ...formFrequency, unit: { code: itemValue, displayValue } };
                        const newValue = { ...(props.value || {}), formFrequency: newFormFrequency }
                        setValue(newValue);
                        props.onChange(newValue);
                      }}
                    >
                      {possibleValueList.map((data: any) => {
                        return (
                          <Option value={data.value} title={data.displayName} key={data.value}>{data.displayName}</Option>
                        );
                      })}

                    </Select>
                  </View>
                </HStack>
              </View>
            </HStack>
          </HStack>
        </View>
      </>
    }
    {!isSkipEmail &&
      <FormListConfigField isAppointmentLinkTagPresent={isAppointmentLinkTagPresent} templateType='Email' isFormListUserInputFieldPresent={isFormListUserInputFieldPresent} isAppointmentTypeUserInputFieldPresent={isAppointmentTypeUserInputFieldPresent} isFormListTagPresent={isFormListTagPresent} value={value} isShowError={false} isAutomationView={props?.isAutomationView} formLocations={props?.filterLocationIds} useExactMatchForFormLocations={props?.useExactMatchForFormLocations} onChange={(newValue:any)=>{
        setValue((value:any)=>{
          const newValueMerge = {...(value||{}), ...(newValue||{})}
          props.onChange(newValueMerge);
          return newValueMerge
        });

      }}></FormListConfigField>}
      {!props.isHideTemplateCategory && !isSkipEmail && <HStack>
        <HStack flex={2.4}>
          <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={2} marginTop={1}>
            {'Category'}
            {<Text color="error.500">*</Text>}
          </Text>
        </HStack>
        <HStack flex={7}>
          <View marginY={1} flex={1}>
            <Select
              suffixIcon={ props.isAutomationView?
                <CaretDownOutlined
                  style={{
                    color: Colors.FoldPixel.GRAY300
                  }}
                /> :
                <DownOutlined/>
              }
              size="large"
              showSearch
              loading={!isInit}
              disabled={!isInit}
              placeholder={'Select Category'}
              //allowClear
              status={props.isShowError && !value?.category?.name? 'error' : ''}
              value={value?.category ? value?.category?.name : undefined}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value: any[], data: any) => {
                if (data && data.value) {
                  setValue((oldValue: any) => {
                    const newValue = { category: data, subject: oldValue?.subject };
                    onChange(newValue);
                    setTemplateDataList([]);

                    searchTemplateList(data.code, (templateList: any) => {
                      templateList.some((template: any) => {
                        if (template?.attributes?.isDefault) {
                          setValue((value: any) => {
                            const newValue = {
                              ...value,
                              template: {
                                id: template.id,
                                attributes: {
                                  name: template?.attributes?.name,
                                  subject: template?.attributes?.subject,
                                },
                              },
                            };
                            if (template?.attributes?.subject) {
                              newValue.subject = template?.attributes?.subject;
                            }
                            onChange(newValue);
                            return newValue;
                          });
                          return true;
                        }
                      });
                    });
                    return newValue;
                  });
                }
              }}
              //loading={loading}
              //notFoundContent={loading && <Spin size="small" />}
              style={{ height: '40px' }}
            //className={isShowError && !value ? 'field-error' : ''}
            >
              {categoryList.map((category: any) => {
                return (
                  <Select.Option
                    key={category.code}
                    id={category.id}
                    value={category.name}
                    name={category.name}
                    code={category.code}
                  >
                    {category?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </View>
        </HStack>
      </HStack>}

      {shouldAddEmailTemplate && !isSkipEmail &&
        <EmailTemplateCreatorEditorRightPanel

          isTemplateCreate={true}
          isVisible={true}
          onCancel={() => {
            setShouldAddEmailTemplate(false);
            //getOrSetEmailTemplates();
          }}
          shouldHideDefault={true}
          shouldHideTitle={true}
          preSelectedCategory={value?.category?.name}
          shouldDisableChangeCategory={true}
          customOnSubmitFn={(resp: any) => {
            setShouldAddEmailTemplate(false);
            if (resp?.data?.id) {
              const data = resp?.data;
              const newTemplateDataList = [...templateDataList, { id: data.id, attributes: data.attributes, name: data.attributes?.name, subject: data?.attributes?.subject }];
              setTemplateDataList(newTemplateDataList);
              setValue((value: any) => {
                const newValue: any = {
                  ...value,
                };
                const template = data?.id
                  ? {
                    id: data?.id,
                    attributes: {
                      name: data.attributes?.name,
                      subject: data?.attributes?.subject,
                    },
                  }
                  : undefined;

                newValue.template = template;
                if (data?.attributes?.subject) {
                  newValue.subject = data?.attributes?.subject;
                } else {
                  newValue.subject = value.subject;
                }
                onChange(newValue);
                return newValue;
              });

            }
          }}
        />
      }

      {!isSkipEmail && <View style={{ marginTop: 16 }}>
        <HStack>
          <HStack flex={2.4}>
            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={2} marginTop={1}>
              {'Template'}
              {<Text color="error.500">*</Text>}
            </Text>
          </HStack>
          <HStack flex={7}>
            <View marginY={1} flex={1}>
              <Select
                suffixIcon={ props.isAutomationView?
                  <CaretDownOutlined
                    style={{
                      color: Colors.FoldPixel.GRAY300
                    }}
                  /> :
                  <DownOutlined/>
                }
                status={props.isShowError && !value?.template?.id ? 'error' : ''}
                notFoundContent={loading ? <Spin size="small" /> : <>No Result</>}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    {
                      !isSideCarContext && (
                        <>
                          <Divider style={{ margin: '8px 0' }} />
                          <Space style={{ padding: '0 8px 4px' }}>
                            {<Button type="text" icon={<PlusOutlined />} onClick={() => {
                              setShouldAddEmailTemplate(true);
                              setIsDropdownOpen(false);
                            }}>
                              Add new template
                            </Button>}
                          </Space>
                        </>
                      )
                    }
                  </>
                )}

                key={value?.category?.code}
                size="large"
                showSearch
                allowClear
                //filterOption={false}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (option?.name || '').toLowerCase().includes(input.toLowerCase())
                }
                }
                value={
                  value?.template?.attributes?.name
                    ? value?.template?.attributes?.name
                    : undefined
                }


                disabled={!value?.category?.code || !isInit}
                onChange={(value: any[], data: any) => {
                  setValue((value: any) => {
                    const newValue: any = {
                      ...value,
                    };
                    const template = data?.id
                      ? {
                        id: data?.id,
                        attributes: {
                          name: data.name,
                          subject: data?.subject,
                        },
                      }
                      : undefined;

                    newValue.template = template;
                    if(data?.subject){
                      newValue.subject = data?.subject;
                    }
                    onChange({...newValue});
                    return newValue;
                  });
                }}
                placeholder="Select Template"
                loading={loading || !isInit}
                // notFoundContent={loading && <Spin size="small" />}
                style={{ height: '40px' }}
                className={isShowError && !value ? 'field-error' : ''}
                open={isDropdownOpen}
                onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
              >
                {templateDataList.map((template) => {
                  return (
                    <Select.Option
                      key={template.id}
                      name={template?.attributes?.name}
                      subject={template?.attributes?.subject}
                      id={template.id}
                    >
                      {template?.attributes?.name}
                    </Select.Option>
                  );
                })}

              </Select>
            </View>
          </HStack>
        </HStack>
      </View>}
      {!isSkipEmail && (
        <>
          <View style={{ marginTop: 16 }}>
            <HStack>
              <HStack flex={2.4}>
                <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={2} marginTop={1}>
                  {'Subject'}
                  {<Text color="error.500">*</Text>}
                </Text>
              </HStack>
              <HStack flex={7}>
                <View marginY={1} flex={1}>
                  <FormControl isInvalid={props.isShowError && !value?.subject }>
                    <Input
                      backgroundColor={'white'}
                      placeholder='Enter Subject'
                      placeholderTextColor={Colors.FoldPixel.GRAY250}
                      borderWidth={props.isAutomationView ? 0.5 : 1}
                      borderColor={ props.isShowError && !value.subject  ? Colors.Custom.Gray200 :  Colors.Custom.Gray200}
                      borderRadius={props.isAutomationView ? 4 : 8}
                      _focus={{ borderColor: Colors.FoldPixel.PRIMARY_FOCUS}}
                      isDisabled={!isInit}
                      size={'md'}
                      value={value.subject}
                      onChangeText={(subject) => {
                        setValue((value: any) => {
                          const newValue = { ...value, subject };
                          props.onChange(newValue);
                          return newValue;
                        });
                      }}
                    />
                  </FormControl>
                </View>
              </HStack>
            </HStack>
          </View>
          {
            isGeneralTextPresent && <View style={{ marginTop: 16 }}>
              <HStack>
                <HStack flex={2.4}>
                  <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                    {'Message'}
                  </Text>
                </HStack>
                <HStack flex={7}>
                  <View marginY={1} flex={1}>
                    <FormControl
                      style={{ borderColor: Colors.Custom.Gray100, borderWidth: 1 }}
                      isInvalid={props.isShowError && !props.value?.message}
                    >
                      <RichText
                        valueStr={props.value?.message}
                        isEnterToSubmit={false}
                        onRichTextAction={() => {
                        }}
                        onChangesValue={(message: string) => {
                          setValue((value: any) => {
                            const newValue = { ...value, message };
                            props.onChange(newValue);
                            return newValue;
                          });
                        }}
                        isAutomationView = {true}
                      />
                    </FormControl>
                  </View>
                </HStack>
              </HStack>
            </View>
          }
        </>
      )}

      {(PAT_CONTENT === value?.category?.code && !props.isHideDefaultInput) && (
        <View style={{ marginTop: 16 }}>
          <HStack>
            <HStack flex={2.4}>
              <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                {'Content'}
                {<Text color="error.500">*</Text>}
              </Text>
            </HStack>
            <HStack flex={7}>
              <View marginY={1} flex={1}>
                <ContentSearch
                  value={props.value?.educationContent}
                  isShowError={props.isShowError}
                  type={ContentType.patientEducation}
                  onChange={(educationContent) => {
                    setValue((value: any) => {
                      const newValue = { ...value, educationContent };
                      props.onChange(newValue);
                      return newValue;
                    });
                  }}
                />
              </View>
            </HStack>
          </HStack>
        </View>
      )}
      {(PATIENT_FORM === value?.category?.code && !props.isHideDefaultInput) && (
        <View style={{ marginTop: 16 }}>
          <HStack>
            <HStack flex={2.4}>
              <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                {'Form'}
                {<Text color="error.500">*</Text>}
              </Text>
            </HStack>
            <HStack flex={7}>
              <View marginY={1} flex={1}>
                <FormSearch
                  value={props.value?.form}
                  isShowError={props.isShowError}
                  infoMessage={value?.form?.isHealthComponentIncluded ? formInfoMessage : undefined}
                  onChange={(form) => {
                    setValue((value: any) => {
                      const newValue = { ...value, form };
                      props.onChange(newValue);
                      return newValue;
                    });
                  }}
                  formLocations={workflowContext?.locationIntersections || []}
                  disableAddNewForm={isEnabledMultiTenancy}
                  disabledInlineFormEdit={isEnabledMultiTenancy}
                />
              </View>
            </HStack>
          </HStack>
          <View></View>
        </View>
      )}
      {!isSkipEmail && value?.template && value?.template.id && (
        <DisplayTemplateById
          form={props?.value?.form}
          formList={getFromList()}
          isGeneralTextPresent={(value:boolean)=>{
            setIsGeneralTextPresent(value);
          }}
          setIsAppointmentLinkTagPresent={setIsAppointmentLinkTagPresent}
          setIsFormListTagPresent={setIsFormListTagPresent}
          educationContent={getEducationContent()}
          message={props?.value?.message}
          templateId={value?.template.id}
          key={value?.template.id}
          templateCategories={categoryList}
          hideEdit={!!isSideCarContext ? (!settingsPermissionConfig.hasAccess || !contentManagementPermissionConfig.hasAccess) : false}
          onApplyChanges={onApplyChanges}
        ></DisplayTemplateById>
      )}
    </>
  );
};

export default TemplateSearch;
