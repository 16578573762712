import React from "react";
import {Svg, Path} from "react-native-svg";

interface ViewDetailPaneActionSvgProps {
  isActive?: boolean;
  width?: string | number;
  height?: string | number;
}

const ViewDetailPaneActionSvg = (props: ViewDetailPaneActionSvgProps) => {
  const { width = "16", height = "16" } = props;
  
  return (
    <Svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <Path d="M10.6666 3.99998C10.6666 5.47274 9.47272 6.66665 7.99996 6.66665C6.5272 6.66665 5.33329 5.47274 5.33329 3.99998C5.33329 2.52722 6.5272 1.33331 7.99996 1.33331C9.47272 1.33331 10.6666 2.52722 10.6666 3.99998Z" stroke={props.isActive ? "#8C5AE2" : "#8C5AE2"}/>
      <Path d="M13.3333 11.6666C13.3333 13.3235 13.3333 14.6666 7.99996 14.6666C2.66663 14.6666 2.66663 13.3235 2.66663 11.6666C2.66663 10.0098 5.05444 8.66665 7.99996 8.66665C10.9455 8.66665 13.3333 10.0098 13.3333 11.6666Z" stroke={props.isActive ? "#8C5AE2" : "#8C5AE2"}/>
    </Svg>
  );
};

export default ViewDetailPaneActionSvg;