import React from 'react';
import {Text, Stack} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../styles';

interface IRAFScoreComponentProps {
  hccScore?: number | null ;
  hccScoreDiff: number;
  formattedHccScoreDiff: string;
  arrowSize?: number;
  textSize?: string;
  height?: number;
}

const RafScoreDiff = (props: IRAFScoreComponentProps) => {
  const {hccScore, hccScoreDiff, formattedHccScoreDiff, arrowSize, textSize, height } = props;
  return hccScore !== null && hccScore !== undefined ? (
    <Stack
      direction={'row'}
      alignItems={'center'}
      backgroundColor={Colors.Custom.Gray100}
      borderRadius={4}
      marginLeft={2}
      height={height ?? null}
      cursor={'pointer'}
    >
      {hccScoreDiff !== 0 && (
        <Text
          wordBreak={'break-word'}
          size={textSize ?? 'smLight'}
          color={Colors.Custom.Gray400}
          alignSelf={'center'}
          margin={1}
        >
          {formattedHccScoreDiff}
        </Text>
      )}
      {hccScoreDiff > 0 && (
        <Feather name="arrow-up" size={arrowSize ?? 15} color={Colors.Custom.Gray400} />
      )}
      {hccScoreDiff < 0 && (
        <Feather name="arrow-down" size={arrowSize ?? 15} color={Colors.Custom.Gray400} />
      )}
    </Stack>
  ) : null;
};

export default RafScoreDiff;
