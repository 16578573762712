import { View, useMediaQuery, useToast } from 'native-base';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { styles } from './SingleTimelineStyles';
import { ICarePlanTimelineProps } from '../..';
import 'antd/dist/antd.css';
import { useLazyQuery } from '@apollo/client';
import { AppointmentQueries } from '../../../../../services';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { IAppointmentData } from '../../../../../services/Appointment/AppointmentInterface';
import AppointmentDetail from '../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail';
import { NoteEntry } from '../interfaces';
import { SignedNoteView } from '../../CareTimeline/SignedNoteView';
import { Drawer, Timeline, Tooltip } from 'antd';
import { INotesFormattedDataProps, IPatientNoteCardProps } from '../../interfaces';
import { getUserId, isPhysicianRole } from '../../../../../utils/commonUtils';
import { FHIR_RESOURCE } from '../../../../../constants/FhirConstant';
import { getResourceAbilities } from '../../../../../utils/capabilityUtils';
import { getFormattedNoteForCareTimeline, getSource } from '../PatientNotesHelper';
import { BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH, SMALL_WINDOW_1700 } from '../../../../../constants';
import { Dimensions } from 'react-native';
import { Colors } from '../../../../../styles';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import CareTimeLineCard from '../../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/CareTimeLineCard/CareTimeLineCard';
import { getResourceTypeIcon } from '../../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/FeedCollapse/FeedHeaderUtils';
import { isWeb } from '../../../../../utils/platformCheckUtils';
import { FEEDABLE_RESOURCES } from '../../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/FeedConst';
import ReadOnlyPatientNoteView from '../../CareTimeline/ReadOnlyPatientNoteView';
import AddOrUpdatePatientNote from '../../CareTimeline/AddOrUpdatePatientNote';
import EncounterView from '../../CareTimeline/components/EncounterView';
import { ORDER_SOURCE } from '../../Orders/OrdersAndReports/OrderUtils';
import { EXTENSION_URLS } from '../components/AddOrUpdateTemplate/constant';
import CareProgramDocumentView from '../../CareTimeline/CareProgramDocumentView';
const antdStyles: Record<string, React.CSSProperties> = {
  timelineItem: {
    marginLeft: 15,
  },
};
const containerStyles = StyleSheet.create({
  container: StyleSheet.flatten([
    styles.container, {
    width: '100%',
    marginTop: 7,
  }]),
});
const CarePlanTimeline = (props: ICarePlanTimelineProps) => {
  const mainRecord = props.fhirData?.mainRecord;

  const [appointmentTasksModalOpen, setAppointmentTasksModalOpen] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({} as any)
  const [cardProps, setCardProps] = useState<IPatientNoteCardProps[]>()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [careProgramDocumentData, setCareProgramDocumentData] = useState<any>({
    attachmentData: undefined,
    showDocumentView: false
  });
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);
  const userId = getUserId();
  const isPhysicianUser = isPhysicianRole();
  const toast = useToast();
  const { personData, unFormattedContactData, ehrConfig, ehrCapabilities, accountUserList, elationFormData, isLastIndex} = props;
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700

  const [getBookedAppointments] = useLazyQuery<IAppointmentData>(
    AppointmentQueries.GET_BOOKED_APPOINTMENTS_BY_ID,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const getBookAppointmentDetails = async (appointmentId: string) => {
    const appointmentRespData = await getBookedAppointments({
      variables: {
        id: appointmentId
      }
    });
    if (appointmentRespData?.data?.appointments[0]) {
      setAppointmentDetails(appointmentRespData?.data?.appointments[0])
      setAppointmentTasksModalOpen(true);
      setLoading(false);
    } else {
      showToast(toast, "Appointment Details not found", ToastType.error)
    }
  }
  const getAppointmentDetails = () => {
    return { detail: appointmentDetails };
  };

  const checkIsCareProgramDocument = (resourceData: any) => {
    const careProgramDocumentId =
      resourceData?.content?.[0]?.attachment?.extension?.find(
        (item: any) => item?.url === EXTENSION_URLS.careProgramDocument
      )?.valueString;
    const documentSource = getSource(resourceData?.extension);
    return !!careProgramDocumentId && documentSource === ORDER_SOURCE.FOLD;
  };

  const onTimelineHeaderClick = (resourceType: string, resourceData: any) => {
    if (resourceType === FHIR_RESOURCE.APPOINTMENT) {
      const appointmentId = resourceData.appointmentId;
      if (appointmentId) {
        setLoading(true);
        getBookAppointmentDetails(appointmentId);
      } else {
        showToast(
          toast,
          'Appointment details not found.',
          ToastType.info,
          1500
        );
      }


    } else if (resourceType === FHIR_RESOURCE.DOCUMENT_REFERENCE) {
      const isCareProgramDocument = checkIsCareProgramDocument(resourceData)  
      if (isCareProgramDocument) {
        setCareProgramDocumentData({
          attachmentData: resourceData?.content?.[0]?.attachment,
          showDocumentView: true
        })
      } else {
        const cardProps = getCardProps(resourceData)
        setCardProps(cardProps)
      }
      setIsDrawerOpen(true)
    }
  };

  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    personData?.accountLocationUuid
  );
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;

  const getCardProps = (documentReferenceData: any) => {
    const noteEntry: NoteEntry[] = [{ resource: documentReferenceData }];
    const formatResponseParam: INotesFormattedDataProps = {
      noteResponse: noteEntry || [],
      ehrConfig: props.ehrConfig,
      loginUserId: userId.toString(),
      contextData: props.commonData,
      accountUserList: props.accountUserList || [],
      elationFormData: props.elationFormData,
      additionalFlags: {
        foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled
      },
      linkTasks: [],
      resourceMapList: [],
    };
    const patientNotes = getFormattedNoteForCareTimeline(formatResponseParam)
    patientNotes.forEach(item => {
      item.isAllowToUnlock = false;
      item.hideCancel = true;
      item.isAllowToPrint = false;
    })
    return patientNotes;
  }

  const BADGE_INFO = {
    [FEEDABLE_RESOURCES.APPOINTMENT]: "Appointment",
    [FEEDABLE_RESOURCES.DOCUMENT_REFERENCE]: "Notes",
    [FEEDABLE_RESOURCES.DIAGNOSTIC_REPORT]: "Lab Report",
  }

  const data = mainRecord?.resourceData;

  const showClaimsOnUi = false
  const isStagingEnv = !!(window.location.host.includes('.staging.foldhealth.io'));
  const isSandboxEnv = !!(window.location.host.includes('.sandbox.foldhealth.io'));
  const isQAEnv = !!(window.location.host.includes('.qa.foldhealth.io'));
  let showTimeline = false;
  if(props.fhirData && (data.resourceType === FHIR_RESOURCE.CLAIM)
     && (showClaimsOnUi || isQAEnv || isStagingEnv || isSandboxEnv) || (data.resourceType != FHIR_RESOURCE.CLAIM)) {
    showTimeline = true
  }

  return showTimeline ? (
    <View style={containerStyles.container}>
      <Timeline.Item
        style={antdStyles.timelineItem}
        className={isLastIndex ? 'careplan-timeline-last-item' : 'careplan-timeline'}
        dot={<Tooltip title={BADGE_INFO[mainRecord?.display_type]}>
          <View
            style={{
              borderWidth: 0.5,
              backgroundColor: Colors.Custom.Gray50,
              borderRadius: 6,
              height: 24,
              width: 24,
              borderColor: Colors.FoldPixel.GRAY200,
              alignItems: 'center',
              justifyContent: 'center'
            }}
            backgroundColor={isWeb() ? 'none' : '#FFFFFF'}
          >
            {getResourceTypeIcon(data.resourceType, data)}
          </View>
        </Tooltip>
        }
        children={<CareTimeLineCard
          singleFeedData={mainRecord}
          firstEntryID={'Care Timeline'}
          onClick={onTimelineHeaderClick}
          loading={loading}
          ehrConfig={ehrConfig}
          personData={personData}
          unFormattedContactData={unFormattedContactData}
        />}
      />
      {
        appointmentTasksModalOpen &&
        <AppointmentDetail
          reasonsForVisitList={[appointmentDetails.reasonForVisit]}
          event={getAppointmentDetails()}
          isVisible={true}
          onClose={() => {
            setAppointmentTasksModalOpen(false);
          }}
        />
      }

      <Drawer
        destroyOnClose
        placement="right"
        open={isDrawerOpen}
        closable
        width={
          isIPadScreen || isIPadMiniScreen ? '70%' : smallWindow ? '60%' : '50%'
        }
        onClose={() => { 
          setIsDrawerOpen(false)
          setCareProgramDocumentData({
            attachmentData: undefined,
            showDocumentView: false
          })
         }}
        title={<View><ModalActionTitle
          title={`${careProgramDocumentData.showDocumentView ? 'Care Program Document' : 'Note Details'}`}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                setIsDrawerOpen(false);
                setCareProgramDocumentData({
                  attachmentData: undefined,
                  showDocumentView: false
                })
              },
            }
          ]}
        /></View>}
      >
        {careProgramDocumentData.showDocumentView ? (
          <CareProgramDocumentView
            attachmentData={careProgramDocumentData.attachmentData}
          />
        ) : (
          <>
            {!ehrConfig.isElation &&
              (cardProps?.[0]?.formId ? (
                <SignedNoteView
                  personData={personData}
                  unFormattedContactData={unFormattedContactData}
                  cardProps={cardProps?.[0] || ({} as IPatientNoteCardProps)}
                  ehrConfig={ehrConfig}
                  header={'header'}
                  footer={'footer'}
                  ehrCapabilities={ehrCapabilities}
                  accountUserList={accountUserList}
                  elationFormData={elationFormData}
                />
              ) : (
                <EncounterView
                  patientId={personData?.patientId || ''}
                  encounterDetails={
                    cardProps?.[0] || ({} as IPatientNoteCardProps)
                  }
                  handleGoBack={() => {
                    setIsDrawerOpen(false);
                  }}
                />
              ))}
            {ehrConfig.isElation &&
              (cardProps?.[0]?.isReadOnly ? (
                <ReadOnlyPatientNoteView
                  ehrCapabilities={ehrCapabilities}
                  cardData={cardProps?.[0] || ({} as IPatientNoteCardProps)}
                  handleGoBack={() => {
                    setIsDrawerOpen(false);
                  }}
                  locationId={personData?.accountLocationUuid}
                />
              ) : (
                <AddOrUpdatePatientNote
                  ehrCapabilities={ehrCapabilities}
                  personData={personData}
                  unFormattedContactData={unFormattedContactData}
                  noteData={cardProps?.[0] || ({} as IPatientNoteCardProps)}
                  ehrConfig={ehrConfig}
                  isNewNote={false}
                  isLinkAppointment={
                    cardProps?.[0]?.linkedAppointmentId ? true : false
                  }
                  isPhysicianUser={isPhysicianUser}
                  accountUserList={accountUserList || []}
                  elationFormData={elationFormData}
                  goBackHandler={() => {
                    setIsDrawerOpen(false);
                  }}
                />
              ))}
          </>
        )}
      </Drawer>
    </View>
  ) : (
    <></>
  );
};

export default CarePlanTimeline;
