import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import { lineHeight } from "jodit/esm/plugins/line-height/line-height";

export const XXL_CONFIG = {
  xxlLight: {
    fontSize: 30,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  xxlNormal: {
    fontSize: 30,
    fontFamily: 'Inter_18pt-Regular',
  },
  xxlMedium: {
    fontSize: 30,
    fontFamily: 'Inter_18pt-Medium',
  },
  xxlSemibold: {
    fontSize: 30,
    fontFamily: 'Inter_18pt-SemiBold',
    fontWeight: 600
  },
  xxlBold: {
    fontSize: 30,
    fontFamily: 'Inter_18pt-Bold',
  },
};

export const XSL_CONFIG = {
  xslLight: {
    fontSize: 24,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  xslNormal: {
    fontSize: 24,
    fontFamily: 'Inter_18pt-Regular',
  },
  xslMedium: {
    fontSize: 24,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: 500
  },
  xslSemibold: {
    fontSize: 24,
    fontFamily: 'Inter_18pt-SemiBold',
  },
  xslBold: {
    fontSize: 24,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight:600,
    lineHeight:21.6
  },
};
export const XL_CONFIG = {
  xlLight: {
    fontSize: 20,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  xlNormal: {
    fontSize: 20,
    fontFamily: 'Inter_18pt-Regular',
  },
  xlMedium: {
    fontSize: 20,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: 400
  },
  xlSemibold: {
    fontSize: 20,
    fontFamily: 'Inter_18pt-SemiBold',
  },
  xlBold: {
    fontSize: 20,
    fontFamily: 'Inter_18pt-Bold',
    fontWeight: 600,
  },
  xlBoldNew: {
    fontSize: 20,
    fontFamily: 'Inter_18pt-Bold',
    fontWeight: 700,
  },
};

export const LG_CONFIG = {
  lgLight: {
    fontSize: 18,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  lgNormal: {
    fontSize: 18,
    fontFamily: 'Inter_18pt-Regular',
  },
  lgMedium: {
    fontSize: 18,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: 500
  },
  lgSemibold: {
    fontSize: 18,
    fontFamily: 'Inter_18pt-SemiBold',
  },
  lgSemiboldNew: {
    fontSize: 18,
    fontFamily: 'Inter_18pt-SemiBold',
    fontWeight: 500
  },
  lgBold: {
    fontSize: 18,
    fontFamily: 'Inter_18pt-Bold',
    fontWeight: 600,
  },
};

export const MD_CONFIG = {
  mdLight: {
    fontSize: 16,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  mdLightNew: {
    fontSize: 16,
    fontWeight: 200,
    fontFamily: 'Inter_18pt-Regular',
  },
  mdNormal: {
    fontSize: 16,
    fontFamily: 'Inter_18pt-Regular',
    fontWeight: 400,
  },
  mdMedium: {
    fontSize: 16,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: 400,
  },
  mdMediumNew: {
    fontSize: 16,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: 500,
  },
  mdSemibold: {
    fontSize: 16,
    fontFamily: 'Inter_18pt-SemiBold',
    fontWeight: 500,
  },
  mdBold: {
    fontSize: 16,
    fontFamily: 'Inter_18pt-Bold',
    fontWeight: 600,
  },
  mdBoldNew: {
    fontSize: 16,
    fontFamily: 'Inter_18pt-Bold',
    fontWeight: 700,
  },
};

export const SM_CONFIG = {
  smLight: {
    fontSize: 14,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  smNormal: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Regular',
  },
  smRegular: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Regular',
    fontWeight: 400,
    LineHeight: 16.8
  },
  smMedium: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: 500,
  },
  smSemibold: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-SemiBold',
    fontWeight: 500,
  },
  smBold: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: 500,
    lineHeight: 16.8
  },
  smDarkBold: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: 600,
    lineHeight: 16.8
  },
};

export const SD_CONFIG = {
  sdLight: {
    fontSize: 15,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  sdNormal: {
    fontSize: 15,
    fontFamily: 'Inter_18pt-Regular',
  },
  sdRegular: {
    fontSize: 15,
    fontFamily: 'Inter_18pt-Regular',
    fontWeight: 400,
    LineHeight: 16.8
  },
  sdMedium: {
    fontSize: 15,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: 400,
  },
  sdSemibold: {
    fontSize: 15,
    fontFamily: 'Inter_18pt-SemiBold',
    fontWeight: 500,
  },
  sdBold: {
    fontSize: 15,
    fontFamily: 'Inter_18pt-Bold',
    fontWeight: 600,
    lineHeight: 16.8
  },
};

export const XS_CONFIG = {
  xsLight: {
    fontSize: 12,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  xsNormal: {
    fontSize: 12,
    fontFamily: 'Inter_18pt-Regular',
    fontWeight: 400,
  },
  xsMedium: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: 'Inter_18pt-Medium',
  },
  xsSemibold: {
    fontSize: 12,
    fontFamily: 'Inter_18pt-SemiBold',
    fontWeight: 500
  },
  xsBold: {
    fontSize: 12,
    fontFamily: 'Inter_18pt-Bold',
    fontWeight:600
  },
};

export const EXS_CONFIG = {
  exsLight: {
    fontSize: 10,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  exsNormal: {
    fontSize: 10,
    fontFamily: 'Inter_18pt-Regular',
  },
  exsMedium: {
    fontSize: 10,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: 500
  },
  exsSemibold: {
    fontSize: 10,
    fontFamily: 'Inter_18pt-SemiBold',
  },
  exsBold: {
    fontSize: 10,
    fontFamily: 'Inter_18pt-Bold',
  },
};

export const BUTTON_TEXT_CONFIG = {
  btLight: {
    fontSize: 14,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  btNormal: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Regular',
  },
  btMedium: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: 500
  },
  btSemibold: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-SemiBold',
  },
  btBold: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Bold',
  },
};

export const TABLE_HEADER_TEXT_CONFIG = {
  thLight: {
    fontSize: 14,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  thNormal: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Regular',
  },
  thMedium: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: '500'
  },
  thSemibold: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-SemiBold',
  },
  thBold: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Bold',
  },
};

export const DROPDOWN_OPTIONS_TEXT_CONFIG = {
  dtLight: {
    fontSize: 14,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  dtNormal: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Regular',
  },
  dtMedium: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: '500'
  },
  dtSemibold: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-SemiBold',
  },
  dtBold: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Bold',
  },
};

export const CUSTOM_TABS_TEXT_CONFIG = {
  ctLight: {
    fontSize: 14,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  ctNormal: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Regular',
    fontWeight: 400
  },
  ctMedium: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: '500'
  },
  ctSemibold: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-SemiBold',
  },
  ctBold: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Bold',
  },
};

export const INPUT_TEXT_CONFIG = {
  intLight: {
    fontSize: 14,
    fontWeight: 300,
    fontFamily: 'Inter_18pt-Regular',
  },
  intNormal: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Regular',
    fontWeight: 400
  },
  intMedium: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Medium',
    fontWeight: '500'
  },
  intSemibold: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-SemiBold',
  },
  intBold: {
    fontSize: 14,
    fontFamily: 'Inter_18pt-Bold',
  },
};



