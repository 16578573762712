import { gql } from "@apollo/client";

const ADD_OR_UPDATE_INTERVENTION =gql`
mutation AddOrUpdateIntervention($params: InterventionInput!) {
  addOrUpdateIntervention(params: $params) {
    type
    trigger
    title
    metadata
    id
    isDeleted
  }
}`;

const ADD_OR_UPDATE_CONTACT_INTERVENTION = gql`
  mutation AddOrUpdateContactIntervention($params: [AddOrUpdateContactInterventionInput]!) {
    addOrUpdateContactInterventions(params: $params) {
      id
      statusId
      startDateTime
      endDateTime
      comments {
        comment
      }
    }
  }
`;

export default {
    ADD_OR_UPDATE_INTERVENTION,
    ADD_OR_UPDATE_CONTACT_INTERVENTION
}
