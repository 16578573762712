import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';

export const styles = StyleSheet.create({
  fullNameTxt: {
    color: Colors.FoldPixel.GRAY400,
  },
  fullnameView: {
    alignItems: 'flex-start',
    flex: 0.7,
    overflow:'hidden',
  },
  profileStack: {
    // paddingTop: 0.2,
    // paddingBottom: 0.2,
    // backgroundColor: 'yellow',
    // flex: 0.2,
    alignItems: 'center',
  },
  avatarView: {
    marginRight: 10,
  },
  isActiveText: {
    fontSize: 12,
    color: Colors.error['600'],
  },
  ehrText: {
    fontSize: 12,
    borderColor: Colors.Custom?.Gray200,
    borderRadius: 20,
    color: Colors.FoldPixel.GRAY300,
    borderWidth: 1,
    paddingHorizontal: 8,
    marginLeft: 8,
    backgroundColor: Colors.Custom?.Gray100,
    paddingVertical: 3,
    fontEeight: 500
  },
});
