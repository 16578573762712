import {
  FormControl,
  HStack,
  IInputProps,
  Input,
  Text,
  TextArea,
  View,
} from 'native-base';
import {useState} from 'react';
import {Pressable, StyleProp, StyleSheet} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {Colors} from '../../../styles';
import {unmaskPhoneNumber} from '../../../utils/commonUtils';
import {ContactVerified} from '../ContactVerfied/ContactVerified';
import {DisplayText} from '../DisplayText/DisplayText';
import './ModalActionStyle.css'
import { InputStyle } from '../../RightSideContainer/Contacts/Leads/LeadView/LeadViewStyle';
import {useIntl} from 'react-intl';
import { withMiniContactViewHOC } from '../../MiniContactViewHOC';
import { TestIdentifiers, testID } from '../../../testUtils';
import {TextAreaProps} from '@uiw/react-md-editor/lib/components/TextArea/Textarea';

interface IModalActionInput extends IInputProps {
  marginTop?: string | number;
  label: string;
  placeholder?: string;
  isInvalid?: string | boolean | any;
  isDisabled?: boolean;
  value?: string | undefined;
  errors?: string | boolean;
  onChangeText: (value: any) => void;
  isRequired: boolean;
  inputWidth?: string | number;
  inputHeight?: string | number;
  extraStyle?: any;
  alert?: any;
  errorText?: string | boolean;
  borderRadius?: string | number;
  fieldIs: string;
  defaultValue?: any;
  leftMargin?: any;
  checkContent?: JSX.Element;
  ref?: any;
  isVerified?: boolean;
  maxLength?: number;
  valueType?: string;
  changeBackground?: boolean
  contactId?: number;
  subTitle?: string;
  navigateOrOpenContactIdDrawer?:(contactId?:string, additionalData?:any) => void;
  notAllowEmoji?: boolean;
  inputRightElement?: JSX.Element;
  borderColor?: string;
  textAreStyle?: StyleProp<TextAreaProps>;
}

export const ModalActionInputComponent = (props: IModalActionInput) => {
  const {
    marginTop,
    label,
    placeholder,
    isDisabled,
    isInvalid,
    value,
    errors,
    onChangeText,
    isRequired,
    extraStyle,
    alert,
    errorText,
    borderRadius,
    fieldIs,
    leftMargin,
    ref,
    isVerified,
    maxLength,
    valueType,
    changeBackground,
    contactId,
    subTitle,
    notAllowEmoji,
    borderColor,
    textAreStyle
  } = props;
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();
  const getBorderWidth = () => {
    if (extraStyle?.flex) {
      return '100%';
    } else {
      return 300;
    }
  };
  const intl = useIntl()
  const isContactCustomer = alert?.includes('Patient')
  const subTitleView = (subTitle?.length ? <Text size={'xsMedium'} width={'85%'} color={Colors.Custom.Gray400} >{intl.formatMessage({id: subTitle})}</Text> : <></>)
  return (
    <>
      <View
        mt={marginTop ? marginTop : ''}
        flex={extraStyle?.flex ? extraStyle.flex : ''}
      >
        <FormControl
          marginLeft={leftMargin ? parseInt(leftMargin, 10) : 0}
          isRequired={isRequired}
          isInvalid={errors || isInvalid}
          {...testID(isRequired ? TestIdentifiers.isRequiredInput : '')}
        >
          <HStack alignItems={'center'} marginBottom={extraStyle?.labelBottomMargin != undefined ? extraStyle?.labelBottomMargin : 0}>
            <FormControl.Label marginLeft={0} {...testID(label)}>
              {label && (
                <DisplayText
                  size={'smRegular'}
                  extraStyles={{
                    color: extraStyle?.fontColor || Colors.FoldPixel.GRAY250,
                    fontWeight: extraStyle?.fontWeight || 400,
                    fontSize: extraStyle?.fontSize || 14,
                  }}
                  textLocalId={label || ''}
                />
              )}
            </FormControl.Label>
            {isVerified && (
              <View mb={2} ml={-2}>
                <ContactVerified label="Verified " />
              </View>
            )}
          </HStack>
          {subTitle?.length ? subTitleView : <></>}
          {fieldIs === 'input' ? (
            <View flex={1} flexDirection={'row'} alignItems={'center'}>
              <Input
                ref={ref}
                _focus={{
                  borderColor: borderColor || Colors.Custom.Gray200,
                }}
                isDisabled={isDisabled}
                placeholderTextColor={InputStyle.placeholderTextColor}
                width={extraStyle?.width ? extraStyle.width : getBorderWidth()}
                height={extraStyle?.Height ? extraStyle.Height : 44}
                borderRadius={borderRadius || 8}
                borderColor={borderColor}
                placeholder={placeholder}
                fontWeight={extraStyle?.placeHolderFontWeight || undefined}
                fontSize={InputStyle.placeHolderFontsize}
                value={value ? value : inputValue}
                maxLength={maxLength}
                _light={{
                  backgroundColor: changeBackground ? '' : '',
                }}
                InputRightElement={props?.inputRightElement ? props.inputRightElement : <></>}
                onChangeText={(value) => {
                  if (valueType && valueType === 'PhoneNumber') {
                    const unmaskNumber = unmaskPhoneNumber(value);
                    if (unmaskNumber.length && !Number(unmaskNumber)) {
                      return;
                    } else {
                      setInputValue(unmaskNumber);
                      onChangeText(unmaskNumber);
                    }
                  } else {
                    if (notAllowEmoji) {
                      let updatedText = props?.value || ''; // previous value
                      const regex = /^[\p{L}\p{N}!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]*$/u;
                      if (regex.test(value) || value === '') {
                        updatedText = value;
                      }
                      setInputValue(updatedText);
                      onChangeText(updatedText);
                    } else {
                      setInputValue(value);
                      onChangeText(value);
                    }
                  }
                }}
                onBlur={(e) => {
                  props.onBlur?.(e);
                }}
                {...testID(label)}
              />
              {props.checkContent ? props.checkContent : <></>}
            </View>
          ) : (
            <TextArea
              placeholderTextColor={Colors.Custom.Gray300}
              width={extraStyle?.width ? extraStyle.width : getBorderWidth()}
              height={!props.textAreStyle ? (extraStyle?.Height ? extraStyle.Height : 44) : undefined}
              borderRadius={borderRadius || 8}
              isDisabled={isDisabled}
              fontWeight={extraStyle?.placeHolderFontWeight || undefined}
              fontSize={extraStyle?.placeHolderFontsize || undefined}
              placeholder={placeholder}
              maxLength={maxLength}
              value={value}
              style={props.textAreStyle}
              onChangeText={(value) => {
                onChangeText(value);
              }}
              {...testID(TestIdentifiers.textAreaField)}
            />
          )}
          {errors && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
              {...testID(TestIdentifiers.errorMessage)}
            >
              {errorText}
            </FormControl.ErrorMessage>
          )}
          {alert && (
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Text {...testID('AlertMesasgeText')} fontSize="xs" color="error.500" fontWeight="500">
                {alert}
              </Text>
              {contactId ? (
                <Pressable
                  onPress={() => {
                    if (isContactCustomer) {
                      props?.navigateOrOpenContactIdDrawer?.(contactId?.toString());
                    } else {
                      navigate(`/members/prospect/${contactId}`);
                    }
                  }}
                >
                         <Text
                            style={{
                              color: Colors.info['500'],
                              textDecorationLine: 'underline'
                            }}
                            fontWeight="500"
                            fontSize="xs"
                            {...testID('ViewProfile')}
                          >
                    (View Profile)
                  </Text>
                </Pressable>
              ) : (
                <></>
              )}
            </View>
          )}
        </FormControl>
      </View>
    </>
  );
};

export const ModalActionInput = withMiniContactViewHOC(ModalActionInputComponent);


const styles = StyleSheet.create({
  fromControl: {
    width: 300,
  },
});
