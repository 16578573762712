import { IInterventionMlovs } from "../../../../../../../Interfaces";
import { Colors } from "../../../../../../../styles/Colors";
import { IInterventionType } from "../interfaces";

export const INTERVENTION_IMMEDIATE_CODE = 'immediate';
export const INTERNAL_TASK_INTERVENTION_TYPE = {
  code: 'providerTask',
  value: 'Internal Task',
  type: 'WorkflowAddTaskV2',
  userInputFieldListKeys: [
      'title',
      'description',
      'sendEmailTo',
      'priority',
      'taskLabel'
  ]
};
export const interventionType: IInterventionType[] = [
    {
        code: 'memberTask',
        value: 'Send Task',
        type: 'CareJourneyAddTaskForContact',
        userInputFieldListKeys: [
            'title',
            'description',
            'priority',
            'taskLabel',
        ]
    },
    {
        code: 'form',
        value: 'Send Form',
        type: 'SendForms',
        userInputFieldListKeys: [
            'formList',
            'patientFacingTitle',
        ]
    },
    {
        code: 'educationContent',
        value: 'Send Educational Content',
        type: 'CareJourneyContentV3',
        userInputFieldListKeys: [
            'educationContent',
            'priority',
            'patientFacingTitle',
        ]
    },
    {
        code: 'measureVital',
        value: 'Measure Vital',
        type: 'CareJourneyVital',
        userInputFieldListKeys: [
            'entity',
            'note',
            'priority',
            'patientFacingTitle'
        ]
    },
    INTERNAL_TASK_INTERVENTION_TYPE,
]

export const interventiondurationMlovs: IInterventionMlovs[] = [
    {
        id: INTERVENTION_IMMEDIATE_CODE,
        value: "immediate"
    },
    {
        id: 'day',
        value: "day"
    },
    {
        id: 'week',
        value: "week"
    }
]

export enum InterventionActions {
    EDIT = 'EDIT',
    DELETE = 'DELETE',
    STATUS_CHANGE = 'STATUS_CHANGE',
    VIEW_TASKS = 'VIEW_TASKS'
}

export enum TaskType {
    ProviderTask = 'providerTask',
}

export enum EntityType {
    MemberTask = 'memberTask',
    Form = 'form',
    EducationContent = 'educationContent',
    MeasureVital = 'measureVital',
    ProviderTask = 'providerTask',
    AppointmentLink = 'appointmentLink',
}

export enum InterventionTypeValues {
    CareJourneyAddTaskForContact = 'CareJourneyAddTaskForContact',
    SendForms = 'SendForms',
    CareJourneyContentV3 = 'CareJourneyContentV3',
    CareJourneyVital = 'CareJourneyVital',
    CareJourneyAddTask = 'WorkflowAddTaskV2',
    SendAppointmentBookingLink = 'SendAppointmentBookingLink',
}

export enum InterventionStatus {
    TO_DO = 'to_do',
    IN_PROGRESS = 'in_progress',
    FAILED = 'failed',
    COMPLETED = 'completed',
    NOT_STARTED = 'not_started',
    OVERDUE = 'overdue',
}

export enum TaskStatus {
    Pending = 'Pending',
    Missed = 'Missed',
    Completed = 'Completed',
}

export enum InterventionPriority {
    High='asap',
    Medium='urgent',
    Low='routine'
}

export const statusConfig = {
  in_progress: {
    label: 'In Progress',
    backgroundColor: Colors.Custom.PendingTaskBackgroundColor,
    textColor: Colors.FoldPixel.STATUS_IN_PROGRESS,
  },
  overdue: {
    label: 'Overdue',
    backgroundColor: Colors?.Custom?.MissedTaskBackgroundColor,
    textColor: Colors.Custom.MissedTaskFontColor
  },
  completed: {
    label: 'Completed',
    backgroundColor: Colors.FoldPixel.STATUS_LIGHT_SUCCESS,
    textColor: Colors.FoldPixel.STATUS_DARK_SUCCESS
  },
  not_started: {
    label: 'Not Started',
    backgroundColor: Colors?.FoldPixel.GRAY50,
    textColor: Colors?.FoldPixel?.GRAY300,
    // dividerColor: Colors?.FoldPixel?.GRAY100
  }
};

export const DEFAULT_IMMEDIATE_DURATION_UNIT = 'second';
export const DEFAULT_IMMEDIATE_DURATION = {
  unit: DEFAULT_IMMEDIATE_DURATION_UNIT,
  value: 500,
};

export const carePlanStatusOrder: Record<InterventionStatus, number> = {
  [InterventionStatus.TO_DO]: 1,
  [InterventionStatus.NOT_STARTED]: 2,
  [InterventionStatus.IN_PROGRESS]: 3,
  [InterventionStatus.COMPLETED]: 4,
  [InterventionStatus.OVERDUE]: 5,
  [InterventionStatus.FAILED]: 6,
};
