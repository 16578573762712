import {useState} from 'react';
import ContactCareProgram from '../../../../../services/ContactCareProgram/ContactCareProgram';
import {useMutation} from '@apollo/client';
import {useCareProgramStatus} from './useCareProgramStatus';
import {useIntl} from 'react-intl';
import {useCustomToast} from '../../../../Toast/ToastProvider';
import {ToastType} from '../../../../../utils/commonViewUtils';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';

export enum CareProgramStatusAction {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
  PAUSED = 'PAUSED',
  FOLLOW_UP = 'FOLLOW_UP',
  PCP_REVIEW = 'PCP_REVIEW',
  ASSIGNED_TO_PCP = 'ASSIGNED_TO_PCP',
}

interface IParams {
  careProgramId: string;
  contactId: string;
}

const useCareProgramStatusAction = () => {
  const {careProgramStatus, careProgramStatusList} = useCareProgramStatus();
  const toast = useCustomToast();
  const intl = useIntl();

  const [updateContactCareProgram] = useMutation(
    ContactCareProgram.UPDATE_CARE_PROGRAM,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );
  const [areAllRequiredStepsCompleted] = useMutation(
    ContactCareProgram.ARE_ALL_STEPS_COMPLETED,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const [loadingAction, setLoadingAction] = useState<
    CareProgramStatusAction | undefined
  >(undefined);

  const onError = (error: any) => {
    setLoadingAction(undefined);
    toast({
      message: intl.formatMessage({id: 'apiErrorMsg'}),
      toastType: ToastType.error,
    });
  };

  const getPayload = (action: CareProgramStatusAction, param: IParams) => {
    let statusId;
    switch (action) {
      case CareProgramStatusAction.COMPLETE:
        statusId = careProgramStatus.completed;
        break;
      case CareProgramStatusAction.IN_PROGRESS:
        statusId = careProgramStatus.inProgress;
        break;
      case CareProgramStatusAction.PAUSED:
        statusId = careProgramStatus.paused;
        break;
      case CareProgramStatusAction.FOLLOW_UP:
        statusId = careProgramStatus.followUp;
        break;
      case CareProgramStatusAction.PCP_REVIEW:
        statusId = careProgramStatus.pcpReview;
        break;
      case CareProgramStatusAction.ASSIGNED_TO_PCP:
        statusId = careProgramStatus.assignedToPcp;
        break;
    }

    return {
      id: param.careProgramId,
      statusId,
      contactId: param.contactId,
    };
  };

  const makeApiRequest = async (action: CareProgramStatusAction, payload: any) => {
    try {
      setLoadingAction(action);
      if (action === CareProgramStatusAction.COMPLETE) {
        const response = await areAllRequiredStepsCompleted({variables: {contactCareProgramId: payload.id}});
        const areAllStepsCompleted = response.data?.areAllRequiredStepsCompleted?.areAllRequiredStepsCompleted;
        if (!areAllStepsCompleted) {
          toast({
            message: 'Please complete all required steps before completing the care program',
            toastType: ToastType.error,
          });
          setLoadingAction(undefined);
          return;
        }
      }
      await updateContactCareProgram({variables: {params: payload}});
      setLoadingAction(undefined);
      return payload.statusId;
    } catch (error) {
      onError(error);
      setLoadingAction(undefined);
    }
  };

  const handleAction = async (action: CareProgramStatusAction, params: IParams) => {
    let payload;
    switch (action) {
      case CareProgramStatusAction.COMPLETE:
        payload = getPayload(action, params);
        break;
      case CareProgramStatusAction.IN_PROGRESS:
        payload = getPayload(action, params);
        break;
      case CareProgramStatusAction.PAUSED:
        payload = getPayload(action, params);
        break;
      case CareProgramStatusAction.FOLLOW_UP:
        payload = getPayload(action, params);
        break;
      case CareProgramStatusAction.PCP_REVIEW:
        payload = getPayload(action, params);
        break;
      case CareProgramStatusAction.ASSIGNED_TO_PCP:
        payload = getPayload(action, params);
        break;
    }
    return makeApiRequest(action, payload);
  };

  return {
    handleAction,
    loadingAction,
  };
};

export default useCareProgramStatusAction;
