export const LOCAL_STORAGE_KEYS = {
  REQUESTED_USER_LOGIN_HASH: 'requestedUserLoginHash',
  REQUESTED_USER_LOGIN_HASH_VALID_TILL: 'requestedUserLoginHashValidTill',
  IS_SIDECAR_STAGING_ENV: 'isSidecarStagingEnv',
} as const;

export const SIDECAR_EVENT_CODES = {
  LOGIN_SUCCESSFUL: 'login/successful',
  LOGGED_OUT: 'loggedOut',
  CHECK_LOGIN_STATUS: 'checkLoginStatus',
  EXTERNAL_PATIENT_ID_DETECTED: 'externalPatientIdDetected',
  MODULE_ACTION_CODE_CHANGE: 'modalActionCodeChange',
  IN_PROGRESS_CALL: 'IN_PROGRESS_CALL',
  ADD_MEDICATION: 'ADD_MEDICATION',
  ADD_TO_NOTE_IN_EHR: 'ADD_TO_NOTE_IN_EHR',
  ADD_DIAGNOSES: 'ADD_DIAGNOSES',
  CHECK_ELEMENTS: 'CHECK_ELEMENTS',
  CHECK_ELEMENTS_CALLBACK: 'CHECK_ELEMENTS_CALLBACK',
  ADD_IMAGING_ORDER: 'ADD_IMAGING_ORDER',
  SIDECAR_DRAWER_CLOSE: 'sidecarDrawerClose',
  MODULE_ACTION_CLOSE: 'modalActionClose',
} as const;

