import { useLazyQuery } from "@apollo/client";
import { VStack, HStack, Text, Pressable, View, Spacer, useToast, Skeleton, Tooltip } from "native-base"
import { useEffect, useRef, useState, useMemo, useContext } from "react";
import { useIntl } from "react-intl";
import { StyleSheet, useWindowDimensions } from 'react-native';
import { MLOV_CATEGORY } from "../../../../constants";
import { showToast, ToastType } from "../../../../utils/commonViewUtils";
import { getCareStudioMlovListByCategoryCode } from "../../../../utils/mlovUtils";
import DocumentCategoryIcon from "../../../common/Svg/DocumentCategoryIcon";
import * as DocumentQueries from '../../../../services/Document/DocumentQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../constants/Configs";
import { getSortedCategories } from "./DocumentUtils";
import { DOCUMENT_CATEGORY_CODES } from "./DocumentCategoryConstants";
import { IPersonData } from "../interfaces";
import { DocumentsListForSidecar } from "./DocumentsListForSidecar";
import { Colors } from "../../../../styles";
import { CommonDataContext } from "../../../../context/CommonDataContext";
import { IContact } from "../../../common/CreateChannelView/interface";
import { testID } from "../../../../testUtils";
import { isEnableCareProgram } from "../../../../utils/commonUtils";

export type IDocumentCategory = {
    id: string;
    isDefault: boolean;
    code: string;
    value: string;
    description?: string;
}

type IDocumentComponentState = {
    selectedCategory: IDocumentCategory;
    loaderForGetCategories: boolean;
}

type IProps = {
    contactUuid?: string;
    contactName?: string;
    contactId: number;
    personData?: IPersonData | IContact;
    unFormattedContactData?: any;
    onDocumentsSelected?: any;
    selectedDocuments?: any;
    maxHeight?: any;
}

export const DocumentsForSideCar = (props: IProps) => {
    const mlovCategoryId = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.DOCUMENT_TYPE)?.[0]?.categoryId;
    const [documentsCategories, setDocumentsCategories] = useState<IDocumentCategory[]>([])
    const [documentComponentState, setDocumentComponentState] = useState<IDocumentComponentState>({
        selectedCategory: {
            code: '',
            id: '',
            isDefault: false,
            value: ''
        },
        loaderForGetCategories: false
    });

    const intl = useIntl();
    const textRef: any = useRef();
    const toast = useToast();
    const commonData = useContext(CommonDataContext);
    const isSidecarContext = commonData?.sidecarContext?.isSidecar;
    const {height} = useWindowDimensions();
    const isCareProgramEnabled = isEnableCareProgram(commonData?.userSettings);

    useEffect(() => {
        getDocumentCategories()
    }, [])

    const getDocumentCategories = async () => {
        setDocumentComponentState((prev) => {
            return {
                ...prev,
                loaderForGetCategories: true,
            }
        })
        await getDocumentCategoriesQuery()
    }

    const onCategoryClick = (category: IDocumentCategory) => {
        setDocumentComponentState((prev) => {
            return {
                ...prev,
                selectedCategory: category
            };
        });
    };

    const [getDocumentCategoriesQuery] = useLazyQuery(
        DocumentQueries.GET_DOCUMENT_CATEGORIES,
        {
            fetchPolicy: 'no-cache',
            context: { service: CARESTUDIO_APOLLO_CONTEXT },
            variables: {
                params: {
                    categoryId: mlovCategoryId
                }
            },
            onCompleted: (response) => {
                if (response?.getDocumentCategories) {
                    let sortedDocumentCategories = getSortedCategories(
                      response?.getDocumentCategories
                    );
                    if (!isCareProgramEnabled) {
                      sortedDocumentCategories =
                        sortedDocumentCategories.filter(
                          (category) =>
                            category.code !==
                            DOCUMENT_CATEGORY_CODES.CONSENT_FORMS
                        );
                    }
                    sortedDocumentCategories.unshift({
                        id: DOCUMENT_CATEGORY_CODES.ALL_DOCUMENTS,
                        isDefault: true,
                        code: '',
                        description: undefined,
                        value: "All Documents"
                    })
                    setDocumentsCategories(sortedDocumentCategories);
                    setDocumentComponentState((prev) => {
                        return {
                            ...prev,
                            selectedCategory: sortedDocumentCategories?.[0]
                        }
                    })
                }
                setDocumentComponentState((prev) => {
                    return {
                        ...prev,
                        loaderForGetCategories: false,
                    }
                })
            },
            onError: (error) => {
                setDocumentComponentState((prev) => {
                    return {
                        ...prev,
                        loaderForGetCategories: false,
                    }
                })
                showToast(
                    toast,
                    'Error in fetching document categories',
                    ToastType.error
                );
            },
        }
    );

    return (
        <HStack flex={1} backgroundColor={'white'} maxHeight={'100%'}>
            <VStack overflow={'scroll'} flex={0.3}>
                <HStack style={styles.categoryHeader}>
                    <Text style={styles.categoryHeaderText}>
                        {intl.formatMessage({ id: 'categories' })}
                    </Text>
                    <Spacer />
                </HStack>
                <VStack style={[styles.categoryList, props?.maxHeight && !isSidecarContext ? { maxHeight: props.maxHeight -52  } : isSidecarContext ? { maxHeight: height - 200 } : undefined]}>
                    {documentComponentState?.loaderForGetCategories && (
                        <Skeleton.Text lines={2} />
                    )}
                    {!documentComponentState?.loaderForGetCategories &&
                        documentsCategories?.map((category, index) => (
                            <Pressable
                                key={category.id}
                                onPress={() => onCategoryClick(category)}
                                style={{
                                    borderLeftWidth:
                                        documentComponentState?.selectedCategory?.id ===
                                            category?.id
                                            ? 5
                                            : 0,
                                    borderLeftColor: '#7F56D9',
                                    borderStyle: 'solid',
                                    borderTopWidth: !index ? 1 : 0,
                                    borderBottomWidth: 1,
                                    borderTopColor: '#EEEEEE',
                                    borderBottomColor: '#EEEEEE',
                                    paddingLeft: 12,
                                    paddingVertical: 10,
                                    backgroundColor:
                                        documentComponentState?.selectedCategory?.id ===
                                            category?.id
                                            ? '#f2f4f7'
                                            : '',
                                }}
                                _hover={{
                                    bg: '#7F56D920',
                                }}
                            >
                                <HStack space={2.5}>
                                    <DocumentCategoryIcon categoryCode={category?.code} />
                                    <Tooltip label={category?.value}>
                                        <Text
                                            onPress={() => onCategoryClick(category)}
                                            ref={textRef}
                                            style={{
                                                color:
                                                    documentComponentState
                                                        ?.selectedCategory?.id ===
                                                        category?.id
                                                        ? '#7F56D9'
                                                        : Colors.FoldPixel.GRAY400,
                                            }}
                                            size={'smMedium'}
                                            maxWidth={'80%'}
                                            isTruncated={true}
                                            {...testID(category?.value)}
                                        >
                                            {category?.value}
                                        </Text>
                                    </Tooltip>
                                    <Spacer />
                                </HStack>
                            </Pressable>
                        ))}
                </VStack>

            </VStack>
            <VStack style={isSidecarContext ? { maxHeight: height - 200 } : undefined} overflow={'scroll'} flex={0.7}>
                {documentComponentState?.loaderForGetCategories && (
                    <View>
                        <Skeleton size={10} rounded="full" />
                        <Skeleton.Text lines={2} />
                    </View>
                )}
                {!documentComponentState?.loaderForGetCategories && (
                    <DocumentsListForSidecar
                        key={documentComponentState?.selectedCategory?.code}
                        contactUuid={props?.contactUuid}
                        category={documentComponentState?.selectedCategory}
                        documentsCategories={documentsCategories}
                        contactName={props?.contactName}
                        contactId={props?.contactId}
                        personData={props?.personData}
                        unFormattedContactData={props?.unFormattedContactData}
                        onDocumentsSelected={props?.onDocumentsSelected}
                        selectedDocuments={props?.selectedDocuments}
                    />
                )}
            </VStack>
        </HStack>
    );

}

const styles = StyleSheet.create({
    categoryHeader: {
        borderLeftColor: '#7F56D9',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderTopColor: '#EEEEEE',
        borderBottomColor: '#EEEEEE',
        paddingHorizontal: 16,
        paddingVertical: 12,
    },
    categoryHeaderText: {
        color: Colors.FoldPixel.GRAY300,
        size: 'smDarkBold'
    },
    categoryList: {
        height: '100%',
        overflow: 'scroll',
    },
    categoryItem: {
        borderLeftColor: '#7F56D9',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderTopColor: '#EEEEEE',
        borderBottomColor: '#EEEEEE',
        paddingLeft: 12,
        paddingVertical: 10,
    },
    categoryText: {
        fontSize: 14,
        fontFamily: 'Manrope',
        fontWeight: '400',
    },
});

export default DocumentsForSideCar
