import React from 'react';
import {Circle, Svg} from 'react-native-svg';
import {ICommonSvgProps} from './interfaces';

function DotIconSvg(props: ICommonSvgProps) {
  return (
    <Svg width={props?.width || '8'} height={props?.width || '9'} viewBox="0 0 8 9" fill="none">
      <Circle cx="4" cy="4.5" r="3.5" fill={props?.customStrokeColor || "#D92D20"} stroke="white" />
    </Svg>
  );
}

export default React.memo(DotIconSvg);
