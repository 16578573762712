import {Divider, Text} from 'native-base';
import {Colors} from '../../../../../../styles';
import {Pressable, StyleSheet} from 'react-native';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {CadenceListView} from './CadenceListView';
import {AddReminderCadenceView} from './AddReminderCadenceView';
import BellSvg from '../../../../../../assets/Icons/BellSvg';
import {AddCalendarIconSvg} from '../../../../../common/Svg/AddCalendarIconSvg';
import AppointmentBooking from '../../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBooking';
import {ParticipantType} from '../../../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {IContact} from '../../../../TeamInbox/Conversations/interfaces';
import {IAppointmentDetail} from '../../../../../common/CalendarWidget/CalendarWidgetInterfaces';
import AppointmentDetail from '../../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail';
import {useCadence} from './useCadence';
import BlockTimeBooking from '../../../../../common/CalendarWidget/BookingWorkflows/Booking/BlockTimeBooking/BlockTimeBooking';
import {getUserUUID} from '../../../../../../utils/commonUtils';
import InfoIconSvg from '../../../../../common/Svg/InfoIconSvg';
import InfoCircleSvg from '../../../../../common/Svg/InfoCircleSvg';
import {Tooltip} from 'antd';
import { View } from 'react-native';
import { AddBlockIconSvg } from '../../../../../../assets/Icons/AddBlockIconSvg';

interface ICadenceViewProps {
  patientAccountLocationId: string;
  contactData: IContact;
  contactCareProgramId?: string;
}

export const CadenceView = (props: ICadenceViewProps) => {
  const {contactData} = props;

  const {
    appointments,
    isAppointmentListLoading,
    onComponentStateChange,
    isAddReminderCadenceVisible,
    selectedAppointment,
    isEditCadenceDrawerOpen,
    isAddCadenceDrawerOpen,
    fetchAppointments,
  } = useCadence({contactUuid: contactData?.uuid || '', isFetchCadence: true});

  const onAppointmentSelected = (appointment: IAppointmentDetail) => {
    onComponentStateChange({
      selectedAppointment: appointment,
      isEditCadenceDrawerOpen: true,
    });
  };

  const userId = getUserUUID();

  return (
    <>
      {isAddReminderCadenceVisible ? (
        <BlockTimeBooking
          slotSelected={{
            start: new Date(),
            end: new Date(),
            slots: [],
            action: '',
            view: '',
          }}
          contactId={contactData?.uuid}
          contactCareProgramId={props.contactCareProgramId}
          userUUID={userId}
          isVisible={isAddReminderCadenceVisible}
          onComplete={(data?: any) => {
            fetchAppointments();
            onComponentStateChange({isAddReminderCadenceVisible: false});
          }}
          onCancel={() => {
            onComponentStateChange({isAddReminderCadenceVisible: false});
          }}
        />
      ) : (
        <Stack direction="column">
          <Stack
            direction="row"
            style={{justifyContent: 'space-between', alignItems: 'center'}}
          >
            <Stack direction="row" style={{alignItems: 'center'}}>
              <Text style={styles.cadenceTitle}>{'Upcoming Schedule'}</Text>
              <Tooltip title="View the patient’s next 5 upcoming appointments or blocked times. You can also create a new entry here, in context of this program, to set reminders for follow-up tasks or other patient-related and non-related activities.">
                <View style={{marginTop: 2}}>
                  <InfoCircleSvg size={13} customStrokeColor={Colors.FoldPixel.GRAY300} strokeWidth={"3"}/>
                </View>
              </Tooltip>
            </Stack>
            <Stack
              direction="row"
              space={8}
              style={{alignItems: 'center', marginRight: 8}}
            >
              <Pressable
                onPress={() =>
                  onComponentStateChange({isAddReminderCadenceVisible: true})
                }
              >
                <AddBlockIconSvg />
              </Pressable>
              <Divider orientation="vertical" height={4} marginRight={1}/>
              <Pressable
                onPress={() =>
                  onComponentStateChange({isAddCadenceDrawerOpen: true})
                }
              >
                <AddCalendarIconSvg />
              </Pressable>
            </Stack>
          </Stack>
          <Divider />
          <CadenceListView
            contactUuid={contactData?.uuid || ''}
            onAppointmentSelected={onAppointmentSelected}
            appointments={appointments}
            isAppointmentListLoading={isAppointmentListLoading}
          />
        </Stack>
      )}
      {isAddCadenceDrawerOpen && (
        <AppointmentBooking
          defaultParticipants={[
            {
              label: contactData?.name || '',
              key: contactData?.uuid || '',
              value: contactData?.uuid || '',
              type: ParticipantType.patient,
            },
          ]}
          disablePatientProspectSearch={true}
          contactCareProgramId={props.contactCareProgramId}
          isVisible={true}
          onComplete={() => {
            onComponentStateChange({isAddCadenceDrawerOpen: false});
            fetchAppointments();
          }}
          onCancel={() => {
            onComponentStateChange({isAddCadenceDrawerOpen: false});
          }}
        />
      )}

      {isEditCadenceDrawerOpen && (
        <AppointmentDetail
          reasonsForVisitList={[selectedAppointment?.reasonForVisit]}
          event={{detail: selectedAppointment}}
          isVisible={isEditCadenceDrawerOpen}
          onStatusChange={() => {
            onComponentStateChange({isEditCadenceDrawerOpen: false});
            fetchAppointments();
          }}
          onClose={() => {
            onComponentStateChange({isEditCadenceDrawerOpen: false});
          }}
        />
      )}
    </>
  );
};

export const styles = StyleSheet.create({
  cadenceTitle: {
    color: Colors.Custom.GRAY350,
    fontSize: 14,
    fontWeight: '500',
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 12,
    marginRight: 6
  },
});
