import React, {useState, useEffect, useContext} from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  SectionList,
  Pressable,
  Dimensions,
} from 'react-native';
import {Skeleton, Spinner, Text} from 'native-base';
import Stack from '../../common/LayoutComponents/Stack';
import {
  ADDITIONAL_DATA_CODE,
  CARE_MANAGEMENT_CATEGORY_CODES,
  CONTACT_CARE_PROGRAM_FITER_ACTION_CODES,
} from '../CareManagementBilling/CareManagementConstants';
import {Colors} from '../../../styles';
import {useNavigate, useParams} from 'react-router-dom';
import {useLazyQuery} from '@apollo/client';
import ContactCareProgram from '../../../services/ContactCareProgram/ContactCareProgram';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {
  MLOV_CATEGORY,
} from '../../../constants/MlovConst';
import ContactCareProgramCard from './ContactCareProgramCard';
import {
  ICareManagementView,
  ICareProgramState,
  IFutureAppointmentData,
  ICareProgramDateFilter,
  ICareProgramFilter,
} from './interface';
import { IKeyPointData } from './CareManagementViewHooks/useCareProgramAdditionalData';
import {usePersonOmniViewContext} from '../../PersonOmniView/PersonOmniView.context';
import CareManagementTopBarView from './CareManagementTopBarView';
import {
  getGroupedCareProgramData,
  getWhereConditionForCareProgram,
  getCareProgramTypeIds,
} from './CareManagementUtils';
import CareManagementFilterView from './CareManagementFilterView';
import {
  ICareProgramAssignee,
  ICareProgramStatusData,
} from '../../PersonOmniView/MiddleContainer/CarePlan/interfaces';
import BulkTaskSelectSvg from '../../common/Svg/Task/BulkTaskSelectSvg';
import {Divider, Tooltip} from 'antd';
import {antStyles} from './CareManageMentViewStyles';
import CareProgramHeartCircleSvg from '../../common/Svg/CarePlanSvgs/CareProgramHeartCircleSvg';
import {useIntl} from 'react-intl';
import ContactCareProgramMultiSelectView from './ContactCareProgramMultiSelectView';
import { useCareProgramStatus } from '../ContactCareProgram/ContactCareProgramView/hooks/useCareProgramStatus';
import {IUser} from '../../../Interfaces';
import UserQueries from '../../../services/User/UserQueries';
import { useCareProgramDashboardData } from './CareManagementViewHooks/useCareProgramDashboardData';
import { testID, TestIdentifiers } from '../../../testUtils';
import { getAllowedCareProgramTypes } from '../../../utils/commonUtils';

const CareManagementView = (props: ICareManagementView) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const {patientId, code} = useParams();
  const id = props?.contactId || patientId;
  const [activeTabCode, setActiveTabCode] = useState(code?.toLowerCase() || 'all');
  const [careProgramShortList, setCareProgramShortList] = useState([]);
  if (!code && window.location.href.endsWith('/careManagement')) {
    navigate(`/members/patient/${id}/careManagement/all`, { replace: true });
  }
  const commonContextData = useContext(CommonDataContext);
  const {data: contactData, formattedData} = usePersonOmniViewContext();
  const {careProgramStatus, careProgramStatusList} = useCareProgramStatus();
  const careProgramTypesList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_TYPES
    ) || [];
  const allowedCareProgramTypes = getAllowedCareProgramTypes(commonContextData?.userSettings);

  const {height} = Dimensions.get('window');  
  const isSidecarContext = commonContextData?.sidecarContext?.isSidecar;
  const viewHeight = isSidecarContext ? height - 240 : height - 286;

  const [getUsersByUserUuidsAPI] = useLazyQuery<{
    accountUsers: {
      user: IUser;
    }[];
  }>(UserQueries.GetUsersByUserUuids, {
    fetchPolicy: 'no-cache',
  });


    
  const [careProgramState, setCareProgramState] = useState<ICareProgramState>({
    groupCareProgramList: [],
    pcpUserData: {},
    careManagerUserData: {},
    pcpFutureAppointmentData: {} as IFutureAppointmentData,
    careProgramLoading: true,
    patientAdditionalDataLoading: true,
    careProgramEnrollmentLoading: false,
    displayFilters: false,
    enableBulkSelection: false,
    selectedCareProgramList: [],
    careProgramUuids: [],
  });

  const [careProgramFilterState, setCareProgramFilterState] = useState({
    assigneeData: {} as ICareProgramAssignee,
    selectedStatus: {} as ICareProgramStatusData,
    selectedDateFilter: {} as ICareProgramDateFilter,
    selectedCarePrograms: [] as ICareProgramFilter[],
  });

  const [getContactCarePrograms] = useLazyQuery(
    ContactCareProgram.GET_CONTACT_CARE_PROGRAM_LIST_BY_CARE_PROGRAM_TYPE_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );
  
  const [getContactCareProgramsShortList] = useLazyQuery(
    ContactCareProgram.GET_CONTACT_CARE_PROGRAM_SHORT_LIST_BY_CARE_PROGRAM_TYPE_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const {getPatientAdditionalData, getEligibleCarePrograms} = useCareProgramDashboardData();

  

  const fetchContactCareProgramData = async (
    activeTabCode: string,
    assigneeId?: string,
    statusCode?: string,
    careProgramCodes?: string[]
  ) => {
    try {
      setCareProgramState((prev) => ({
        ...prev,
        groupCareProgramList: [],
        careProgramUuids: [],
        careProgramLoading: true,
      }));

      const careProgramTypeIds = activeTabCode === 'all' && careProgramCodes 
        ? careProgramCodes.map(code => getCareProgramTypeIds(code, careProgramTypesList)).flat()
        : getCareProgramTypeIds(activeTabCode, careProgramTypesList);
        
      const careProgramStatusId = statusCode
        ? getMlovIdFromCode(careProgramStatusList, statusCode)
        : '';
      const careProgramWhereCondition = getWhereConditionForCareProgram(
        props?.contactUuid,
        careProgramTypeIds,
        assigneeId || '',
        careProgramStatusId || '',
        careProgramFilterState?.selectedDateFilter
      );

      const careProgramWhereConditionShortList = getWhereConditionForCareProgram(
        props?.contactUuid,
        [],
        '',
        '',
        careProgramFilterState?.selectedDateFilter
      );

      const contactCareProgramResponse = await getContactCarePrograms({
        variables: {
          careProgramWhereCondition: careProgramWhereCondition,
        },
      });

      const contactCareProgramShortListResponse = await getContactCareProgramsShortList({
        variables: {
          careProgramWhereCondition: careProgramWhereConditionShortList,
        },
      });

      const groupCareProgramList = getGroupedCareProgramData(
        contactCareProgramResponse?.data?.contactCarePrograms,
        careProgramStatusList
      );

      const groupCareProgramShortList = getGroupedCareProgramData(
        contactCareProgramShortListResponse?.data?.contactCarePrograms,
        careProgramStatusList
      );

      
      const activeCareProgramUuids = contactCareProgramResponse?.data?.contactCarePrograms?.filter(
        (program: any) => [careProgramStatus.inProgress, careProgramStatus.newStatus, careProgramStatus.paused].includes(program?.statusId)
      )?.map((program: any) => program?.id) || [];

      setCareProgramState((prev) => ({
        ...prev,
        groupCareProgramList: groupCareProgramList,
        careProgramUuids: activeCareProgramUuids,
        careProgramLoading: false,
      }));

      setCareProgramShortList(groupCareProgramShortList);
    } catch (e) {
      setCareProgramState((prev) => ({
        ...prev,
        groupCareProgramList: [],
        careProgramUuids: [],
        careProgramLoading: false,
      }));
      setCareProgramShortList([]);
    }
  };

  const handlePatientAdditionalData = async () => {
    try {
      const accountLocationUuid = formattedData?.accountLocationUuid || '';
      const keyPoints: IKeyPointData[] = await getPatientAdditionalData({
        patientId: contactData?.patient?.patientId,
        contactUuid: contactData?.uuid,
        location: accountLocationUuid,
        additionalDataCodes: [
          ADDITIONAL_DATA_CODE.PCP_AND_CARE_MANAGER,
          ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT,
        ],
      })as IKeyPointData[];
      
  
      const futureAppointmentData = keyPoints.find(
        point => point.code === ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT
      )?.referenceData || {};

      const pcpUserDataResult = keyPoints?.find(  
        point => point.code === ADDITIONAL_DATA_CODE.PCP_AND_CARE_MANAGER
      );

      const pcpUserId = pcpUserDataResult?.referenceData?.userId

      let pcpUserData = pcpUserDataResult?.referenceData || {};

      if (pcpUserId) {
        const userResponse = await getUsersByUserUuidsAPI({
          variables: {
            userUuids: [pcpUserId]
          }
        });

        const userData = userResponse?.data?.accountUsers?.[0]?.user;
        pcpUserData = {
          ...pcpUserData,
          userData
        };
      }

      setCareProgramState((prev) => {
        return {
          ...prev,
          pcpUserData: pcpUserData || ({} as any),
          pcpFutureAppointmentData: futureAppointmentData,
          patientAdditionalDataLoading: false,
        };
      });
    } catch (e) {
      setCareProgramState((prev) => {
        return {
          ...prev,
          pcpUserData: {} as any,
          careManagerUserData: {} as any,
          patientAdditionalDataLoading: false,
        };
      });
    }
  };

  useEffect(() => {
    fetchContactCareProgramData(activeTabCode);
    handlePatientAdditionalData();
    return () => {
      setCareProgramState((prev) => {
        return {
          ...prev,
          groupCareProgramList: [],
          careProgramUuids: [],
          careProgramLoading: true,
          pcpUserData: {} as any,
          careManagerUserData: {} as any,
          patientAdditionalDataLoading: true,
        };
      });
    };
  }, [activeTabCode]);

  useEffect(() => {
    fetchContactCareProgramData(
      activeTabCode,
      careProgramFilterState?.assigneeData?.uuid,
      careProgramFilterState?.selectedStatus?.code,
      careProgramFilterState?.selectedCarePrograms?.map((program:any) => program?.tabCode),
    );
  }, [
    careProgramFilterState?.assigneeData?.uuid,
    careProgramFilterState?.selectedStatus,
    careProgramFilterState?.selectedDateFilter,
    careProgramFilterState?.selectedCarePrograms,
  ]);

  const onHeaderTabClick = (tabCode: string) => {
    onFilterActionPerformed(CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.CLEAR_ALL);
    setCareProgramState((prev) => {
      return {
        ...prev,
        displayFilters: false,
      };
    });
    fetchContactCareProgramData(tabCode);
    setActiveTabCode(tabCode);
    if (!props?.isCompactView) {
      navigate(`/members/patient/${id}/careManagement/${tabCode}`);
    }
  };

  const onCareProgramEnrollmentLoading = (value: boolean) => {
    setCareProgramState((prev) => {
      return {
        ...prev,
        careProgramEnrollmentLoading: value,
      };
    });
  };

  const onFilterActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.ASSIGNEE_FILTER_CHANGE:
        setCareProgramFilterState((prev) => {
          return {
            ...prev,
            assigneeData: actionData,
          };
        });
        break;

      case CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.STATUS_CHANGE:
        setCareProgramFilterState((prev) => {
          return {
            ...prev,
            selectedStatus: actionData,
          };
        });
        break;

      case CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.DATE_FILTER_CHANGE:
        setCareProgramFilterState((prev) => {
          return {
            ...prev,
            selectedDateFilter: actionData,
          };
        });
        break;
      case CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.CARE_PROGRAM_CHANGE:
        setCareProgramFilterState((prev) => ({
          ...prev,
          selectedCarePrograms: actionData || [],
        }));
        break;
      case CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.CLEAR_ALL:
        setCareProgramFilterState((prev) => {
          return {
            ...prev,
            selectedStatus: {} as ICareProgramStatusData,
            assigneeData: {} as ICareProgramAssignee,
            selectedDateFilter: {} as ICareProgramDateFilter,
            selectedCarePrograms: [] as ICareProgramFilter[],
          };
        });
        break;
    }
  };

  const handleBulkAction = (visible: boolean) => {
    setCareProgramState((prev) => {
      return {
        ...prev,
        enableBulkSelection: visible,
        selectedCareProgramList: [],
      };
    });
  };

  const handleSelectAll = () => {
    setCareProgramState((prev) => {
      return {
        ...prev,
        selectedCareProgramList: careProgramState.careProgramUuids,
      };
    });
  };

  const handleCheckBoxChange = (checked: boolean, careProgramId: string) => {
    setCareProgramState((prev) => {
      return {
        ...prev,
        selectedCareProgramList: checked
          ? [...new Set([...prev.selectedCareProgramList, careProgramId])]
          : prev.selectedCareProgramList.filter((id) => id !== careProgramId),
      };
    });
  };

  const checkBoxChecked = (careProgramId: string) => {
    return careProgramState?.selectedCareProgramList.includes(careProgramId);
  };

  const checkExistingProgram = (careProgramTypeCode: string) => {
    const careProgramTypeId = getMlovIdFromCode(careProgramTypesList, careProgramTypeCode);
    const isExistingProgram = careProgramShortList?.some(
      (section: any) =>
        ([CARE_MANAGEMENT_CATEGORY_CODES.ACTIVE, CARE_MANAGEMENT_CATEGORY_CODES.NEW, CARE_MANAGEMENT_CATEGORY_CODES.PAUSED].includes(section?.key)) &&
        section?.data?.some(
          (item: any) =>
            item?.payerCareProgram?.careProgramTypeId ===
            careProgramTypeId
        )
    ) || false;

    return isExistingProgram;
  };

  const checkFilteredData = () => {
    return (careProgramFilterState?.assigneeData?.uuid || 
      careProgramFilterState?.selectedStatus?.code ||
      careProgramFilterState?.selectedDateFilter?.programEndDate|| careProgramFilterState?.selectedDateFilter?.programStartDate);
  }

  const renderContactCareProgramCard = (contactCareProgram: any) => {
    return (
      <ContactCareProgramCard
        contactCareProgram={contactCareProgram?.item}
        isCompactView={props?.isCompactView}
        contactId={id}
        contactUuid={props?.contactUuid}
        pcpUserData={careProgramState?.pcpUserData}
        careManagerUserData={careProgramState?.careManagerUserData}
        pcpFutureAppointmentData={careProgramState?.pcpFutureAppointmentData}
        patientAdditionalDataLoading={
          careProgramState?.patientAdditionalDataLoading
        }
        enableBulkSelection={careProgramState.enableBulkSelection && false}
        onCheckBoxChange={handleCheckBoxChange}
        checked={checkBoxChecked(contactCareProgram?.item?.id)}
        onActionCloseCareProgram={handleRefresh}
        sectionKey={contactCareProgram?.section?.key}
      />
    );
  };

  const getCareManagementBodyElementView = () => {
    return (
      <View style={styles.bodyContainer}>
        {careProgramState?.groupCareProgramList?.length > 0 ? (
          <SafeAreaView style={styles.safeAreaContainer}>
            <SectionList
              keyboardShouldPersistTaps="handled"
              keyboardDismissMode="on-drag"
              sections={careProgramState?.groupCareProgramList}
              stickySectionHeadersEnabled={false}
              showsVerticalScrollIndicator={false}
              renderSectionHeader={({section}: any) =>
                section.data.length ? (
                  <Stack
                    direction="row"
                    style={{
                      alignItems: 'center',
                    }}
                  >
                    {/* 
                    // Remove bulk action for now
                    {[
                      CARE_MANAGEMENT_CATEGORY_CODES.NEW,
                      CARE_MANAGEMENT_CATEGORY_CODES.PAUSED,
                      CARE_MANAGEMENT_CATEGORY_CODES.ACTIVE,
                    ].includes(section.key) && (
                        <>
                          <Tooltip title={'Bulk Action'}>
                            <Pressable
                              onPress={() =>
                              handleBulkAction(
                                !careProgramState.enableBulkSelection
                              )
                            }
                            {...testID(TestIdentifiers.bulkActionBtn)}
                          >
                            <BulkTaskSelectSvg
                              height={16}
                              width={16}
                              customStrokeColor={Colors.Custom.Gray500}
                            />
                            </Pressable>
                          </Tooltip>
                          <Divider
                            type="vertical"
                            orientationMargin={8}
                            style={antStyles.dividerStyle}
                          />
                        </>
                      )} */}
                    <Text style={styles.sectionTitle} {...testID(section.key)}>{section.key}</Text>
                  </Stack>
                ) : null
              }
              renderItem={renderContactCareProgramCard}
              keyExtractor={(item: any) => item?.id}
            />
          </SafeAreaView>
        ) : (
          <Stack direction="column" style={styles.emptyStateContainer}>
            <CareProgramHeartCircleSvg />
            <Text style={styles.emptyStateText}>
                {checkFilteredData()
                ? intl.formatMessage({id: 'noResultsFound'})
                : intl.formatMessage({id: 'noCareProgramMsg'})
              }
            </Text>
          </Stack>
        )}
      </View>
    );
  };

  const handleRefresh = React.useCallback(() => {
    fetchContactCareProgramData(
      activeTabCode,
      careProgramFilterState?.assigneeData?.uuid,
      careProgramFilterState?.selectedStatus?.code
    );
  }, [
    activeTabCode,
    careProgramFilterState?.assigneeData?.uuid,
    careProgramFilterState?.selectedStatus?.code,
    fetchContactCareProgramData,
  ]);

  return (
    <View
      style={
        props?.isCompactView
          ? {
              flexDirection: 'row',
              justifyContent: 'center',
              borderBottomWidth: 0,
              borderBottomColor: '#e0e0e0',
              height: viewHeight,
            }
          : styles.outerContainer
      }
    >
      {careProgramState?.careProgramEnrollmentLoading ? (
        <Stack direction="row">
          <Spinner size={'sm'} />
        </Stack>
      ) : (
        <View style={props?.isCompactView ? styles.flex1 : styles.container}>
          <CareManagementTopBarView
            activeTabCode={activeTabCode}
            contactUuid={props?.contactUuid}
            isCompactView={props?.isCompactView}
            contactId={contactData?.id || props?.contactId}
            onHeaderTabClick={onHeaderTabClick}
            eligiblePrograms={props?.eligiblePrograms}
            getEligibleCarePrograms={getEligibleCarePrograms}
            onCareProgramEnrollmentLoading={onCareProgramEnrollmentLoading}
            checkExistingProgram={checkExistingProgram}
            handleRefresh={handleRefresh}
            onFilterClick={() => {
              setCareProgramState((prev) => {
                return {
                  ...prev,
                  displayFilters: !prev.displayFilters,
                };
              });
            }}
            allowedCareProgramTypes={allowedCareProgramTypes}
          />
          {careProgramState.displayFilters && (
            <CareManagementFilterView
              contactUuid={props?.contactUuid}
              onFilterActionPerformed={onFilterActionPerformed}
              assigneeFilterData={careProgramFilterState?.assigneeData}
              selectedStatusFilter={careProgramFilterState?.selectedStatus}
              selectedDateFilter={careProgramFilterState?.selectedDateFilter}
              selectedCarePrograms={careProgramFilterState?.selectedCarePrograms}
              showCareProgramFilter={activeTabCode === 'all'}
              activeTabCode={activeTabCode}
              allowedCareProgramTypes={allowedCareProgramTypes}
            />
          )}
          {careProgramState?.careProgramLoading ? (
            <Skeleton.Text lines={4} />
          ) : (
            getCareManagementBodyElementView()
          )}
        </View>
      )}

      {/* {careProgramState.enableBulkSelection && (
        <ContactCareProgramMultiSelectView
          contactId={props?.contactUuid}
          visible={careProgramState.enableBulkSelection}
          selectedCareProgramList={careProgramState.selectedCareProgramList}
          careProgramUuids={careProgramState.careProgramUuids}
          onClose={() => {
            handleBulkAction(false);
          }}
          onActionCloseCareProgram={handleRefresh}
          onSelectAll={handleSelectAll}
        />
      )} */}
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  container: {
    flex: 0.7,
  },
  flex1: {
    flex: 1,
  },
  bodyContainer: {
    flex: 1,
    padding: 16,
  },
  section: {
    marginBottom: 24,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY300,
    alignItems: 'center',
  },
  emptyStateContainer: {
    flex: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
    backgroundColor: Colors.Custom.ContainerBGColor,
  },
  emptyStateText: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.Custom.Gray400,
    textAlign: 'center',
    marginTop: 16,
  },
  safeAreaContainer: {
    flex: 1,
  },
});

export default CareManagementView;
