import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  grayText: {
    color: Colors.Custom.Gray500
  },
  bottomBorder: {
    borderBottomColor: Colors.primary[500],
    borderBottomWidth: 3,
  },

  panelBox: {
    paddingBottom: 2,
    paddingTop: 2,
  },

  detailsPanelBox: {
    paddingBottom: 10,
    paddingTop: 10,
  },

  panelBoxContents: {
    alignItem: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 5,
    flex:1
  },
  careProgramStack: {
    alignItems: 'center',
    margin: 24,
  },

  ticketPanel: {
    justifyContent: 'space-between',
    alignItem: 'center',
    padding: 15,
  },
  msgContactDetails: {
    height: 55,
  },
  listContainer: {
    paddingTop: 6,
    paddingBottom: 6,
    backgroundColor: Colors.Custom.Gray50,
  },
  callMsgEmailActionBtns: {
    padding: 5,
    marginLeft: 15,
    height: 30,
    width: 54,
    borderRadius: 15,
  },
  actionsBtns: {
    paddingLeft: 4,
    paddingRight: 4,
    height: 22,
    borderRadius: 15,
    color: Colors.Custom.IconColorWhite,
  },
  mainMenuContent: {
    paddingTop: 5,
    paddingBottom: 5,
    cursor: 'pointer',
  },
  lastMenu: {
    paddingBottom: 10,
  },
  firstMenu: {
    paddingTop: 10,
  },
  mainMenuTxt: {
    marginLeft: 5,
  },
  dividerStyle: {
    width: '100%',
  },
  btnStyle: {
    width: 32,
    height: 32,
    borderRadius: 18,
  },
  modalContainer: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
  modalFooter: {
    backgroundColor: Colors.Custom.BackgroundColor,
    display: 'flex',
    justifyContent: 'flex-end',
  },


  container: {
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: '#e4e7ec',
    overflow: 'hidden',
    backgroundColor: '#fff',
    borderRightWidth: 0,
  },
  headerContainer: {
    width: '100%',
    zIndex: 2,
    flexShrink: 0,
  },
  profileDetailContainer: {
    borderRadius: 8,
    paddingVertical: 24,
    paddingHorizontal: 24,
    borderBottomEndRadius: 0,
    borderBottomStartRadius: 0,
    paddingBottom: 24,
  },
  borderZero: {
    borderWidth: 0,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.Custom.Gray200,
  },
  personActionsContainer: {
    paddingHorizontal: 15,
    paddingVertical: 12,
    backgroundColor: '#ffffff',
  },
  tabContainer: {
    maxWidth: '100%',
    overflow: 'scroll',
  },
  tabsWrapper: {
    borderBottomWidth: 1,
    borderColor: '#e4e7ec',
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: 8,
  },
  tabText: {
    fontSize: 20, 
    fontWeight: '700'
  },
  tabButton: {
    paddingVertical: 16,
    paddingHorizontal: 8,
    backgroundColor: '#ffffff',
  },
  activeTabButton: {
    backgroundColor: '#FAF5FE',
    borderBottomColor: '#825AC7',
    borderBottomWidth: 2,
  },
  activeTabButton3: {
    backgroundColor: '#FAF5FE',
    borderBottomColor: '#825AC7',
    borderBottomWidth: 3,
  },
  bottomContainer: {
    backgroundColor: Colors.Custom.Gray50,
    paddingBottom: 20,
  }, 
  groupListPanel: {
    marginBottom: 10, 
    borderWidth: 0
  },
  careProgramContainer: {
    backgroundColor: '#ffffff',
    marginHorizontal: 24,
    borderRadius: 8,
    boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  careProgramHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 24,
  },
  careProgramContent: {
    marginHorizontal: 24,
    borderRadius: 8,
  },
  conversationDetailsContainer: {
    backgroundColor: '#ffffff',
    marginHorizontal: 24,
    borderRadius: 8,
    boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  appointmentDataView: {marginBottom: 10, borderWidth: 0},
  contactNotesView: {marginBottom: 10, borderWidth: 0},
  marginBottom10AndWidth0: {marginBottom: 10, borderWidth: 0},
  createTeamView: {marginBottom: 10},
  conversationInfoContainer: {
    backgroundColor: Colors.Custom.Gray50,
  },
  conversationInfoPadding: {
    padding: 24,
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowAlignCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 9,
  },
  iconMargin: {
    marginRight: 10,
  },
  displayTextColor: {
    color: Colors.primary['700'] || '',
  },
  scrollView: {
    flexGrow: 1,
  },
  innerVStack: {
    marginBottom: 0,
  },
  detailsPanelBox2: {margin: 24, marginBottom: 12},
  detailsPanelBox2Text: {marginLeft: 10, color:Colors.FoldPixel.GRAY300},
  detailsPanelBox2View: {borderBottomWidth: 1, borderBottomColor: Colors.Custom.Gray100, borderRadius: 8, paddingBottom: 10, marginTop: 20},
  contactRelationView: {marginBottom: 10, borderWidth: 0},
  contactRelationViewText: {marginLeft: 10},
  panelBoxContents2: {paddingVertical: 5},
  detailsPanelBox3: {
    // backgroundColor: Colors.Custom.Gray50,
    paddingBottom: 16,
  },
  modalHeader: {fontSize: 20, fontWeight: '500'}
});

export const reactStyles = StyleSheet.create({
  collapse: {
    marginBottom: 10,
  },
});
