import {View} from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import Stack from '../../common/LayoutComponents/Stack';
import {
  IS_FEATURE_FLAG_ENABLED,
  getAccountUUID,
  getPatientLocationUuidFromPracticeLocations,
  getUserId,
  healthMapName,
  isAlertComponentVisible,
  isDiagnosesComponentVisible,
  isHealthMapComponentVisible,
  isInsuranceEnable,
  isSummaryComponentVisible,
  showAssessmentInSideCar,
  showCareJourneyInSideCar,
} from '../../../utils/commonUtils';
import {Table, Tooltip} from 'antd';
import {LLMMap} from '../../PersonOmniView/LeftContainer/LLMMap';
import {LLMView} from '../../PersonOmniView/LeftContainer/llmView';
import {AlertTable} from '../../PersonOmniView/LeftContainer/AlertTable';
import {AssessmentIonSvg} from '../assets/images/AssessmentIconSvg';
import {Divider, Text} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {IContact, IContactPracticeLocations, IConversationData} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {useIntl} from 'react-intl';
import {Colors} from '../../../styles';
import {getColumns} from '../FormAssessmentHelper';
import PatientCareJourneyListView from '../../RightSideContainer/Journeys/JourneysOfCare/PatientCareJourney/PatientCareJourneyListView';
import {INSIGHTS_JOURNEYS_STATUSES} from '../constants';
import { IFormResponse } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/ClinicalAssessment/interfaces';
import { useHieRequests } from '../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/useHieRequests';
import { AvailableHieDataView } from '../../PersonOmniView/LeftContainer/RecentActivity/AvailableHieDataView';
import { PendingHieRequestsDrawer } from '../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/PendingHieRequestsDrawer';
import { VisitSummary } from '../../PersonOmniView/LeftContainer/RecentActivity/VisitSummary/VisitSummary';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { DiagnosisSurfacing } from '../../PersonOmniView/LeftContainer/DiagnosisSurfacing';
import { TaskPanelType } from '../../TaskCard/TaskEnum';
import TaskPanel from '../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/TaskPanel';
import StickyNotes from '../../PersonOmniView/LeftContainer/OtherDetails/StickyNotes';
import FoldPermitCan from '../../CommonComponents/FoldPermitCan/FoldPermitCan';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import {USER_ACCESS_PERMISSION} from '../../RightSideContainer/UserAccess/UserAccessPermission';
import {PatientAppointmentDataView} from '../../PersonOmniView/LeftContainer/OtherDetails/PatientAppointmentDataView';
import ContactNotesView from '../../common/ContactNotesView/ContactNotesView';
import CareJourneyDetailsView from '../../common/CareJourneyDeatilsView/CareJourneyDetailsView';
import {ContactRelationView} from '../../common/ContactRelationView';
import {styles} from '../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsStyles';
import {MemberGroupList} from '../../PersonOmniView/RightContainer/MemberGroupList';
import {PERSON_TYPES} from '../../../constants';
import CareTeamView from '../../PersonOmniView/RightContainer/CareTeamView/CareTeamView';
import SubscriptionView from '../../common/SubscriptionView/SubscriptionView';
import EntityEventsListView from '../../PersonOmniView/LeftContainer/PamiDetail/EntityEventsListView';
import {CommonDataContext} from '../../../context/CommonDataContext';
import InsuranceView from '../../Insurance';
import {getTenantForDemo} from '../../RightSideContainer/Analytics/EmployerAnalytics/tempDataFile';
const InsightsSummaryView = (props: {
  contactData?: IContact;
  formattedContactData?: IContact;
  forms: IFormResponse[];
  assignJourneyOptionClicked?: () => void;
  sideCarDashboardEnabled?: boolean;
  isCompactView?: boolean;
  conversationData?: IConversationData;
  currentView?: string;
}) => {
  const intl = useIntl();
  const showAssessment = showAssessmentInSideCar();
  const showCareJourney = showCareJourneyInSideCar();
  const commonData = useContext(CommonDataContext);
  const userSettings = commonData.userSettings;
  const allowInsurance = isInsuranceEnable(userSettings);
  const {contactData, isCompactView, conversationData} = props;
  const [stateData, setStateData] = useState({
    shouldShowApointment: false,
    shouldShowAddNotes: false,
    showSubscriptionData: contactData?.uuid ? true : false,
  })
  const isHIEEnabled = isAccountConfigEnabled(CONFIG_CODES.HIE_ENABLE) || false;
 
  const accountLocationUuid =
  contactData?.contactPracticeLocations &&
  contactData?.contactPracticeLocations?.length > 0
    ? contactData.contactPracticeLocations[0]?.accountLocation?.uuid
    : '';
 
  const [componentState, setComponentState] = useState<{
    showHieRequestsDrawer: boolean;
  }>({
    showHieRequestsDrawer: false,
  });
  const canShowMembership = () => {
    return IS_FEATURE_FLAG_ENABLED(CONFIG_CODES.ENABLE_MEMBERSHIP);
  };
  const patientLocationUuid = getPatientLocationUuidFromPracticeLocations(contactData?.contactPracticeLocations as IContactPracticeLocations[]);
 
  const {
    pendingHieRequests,
    fetchHieRequests,
  } = useHieRequests({
    patientUuid: contactData?.patient?.patientUuid,
    accountLocationUuid: accountLocationUuid || '',
    patientId: contactData?.patient?.patientId,
    contactUuid: contactData?.uuid,
    skip: true
  });
 
  const appointmentData = () => {
    return (
      <PatientAppointmentDataView
        style={{marginBottom: 10, borderWidth: 0}}
        taggableType={'Contact'}
        contactId={props?.contactData?.id as number}
        contactData={props?.contactData as IContact}
        shouldShowApointment={stateData?.shouldShowApointment}
        onClose={() => {
          setStateData((prev)=> {
            return {
              ...prev,
              shouldShowApointment: false
            }
          })
        }}
        sideCarDashboardEnabled={props.sideCarDashboardEnabled}
        showMore
      />
    );
  };
 
  const contactNotesData = () => {
    return (
      <ContactNotesView
        isDefaultClose={false}
        contactId={contactData?.id}
        style={{marginBottom: 10, borderWidth: 0}}
        shouldShowAddNotes={stateData?.shouldShowAddNotes}
        onClose={() => {
          setStateData((prev)=> {
            return {
              ...prev,
              shouldShowAddNotes: false
            }
          })
        }}
        sideCarDashboardEnabled={props?.sideCarDashboardEnabled}
      />
    );
  };
 
  const getContactRelationView = () => {
    return (
      <ContactRelationView
        isDefaultClose={false}
        contactData={contactData}
        style={styles.marginBottom10AndWidth0}
        isCompactView={isCompactView}
        sideCarDashboardEnabled={props?.sideCarDashboardEnabled}
      />
    );
  };
 
  const getMemberGroupList = () => {
    return <MemberGroupList contactId={contactData?.id} sideCarDashboardEnabled={props?.sideCarDashboardEnabled} />;
  };
 
  const getCareTeamView = () => {
    const CONTACT_TYPE_CUSTOMER = PERSON_TYPES.CUSTOMER;
    if (props.contactData?.contactType?.contactType?.code === CONTACT_TYPE_CUSTOMER) {
    return (
      <CareTeamView
        isDefaultClose={false}
        style={styles.createTeamView}
        contactData={contactData as IContact}
        sideCarDashboardEnabled={props?.sideCarDashboardEnabled}
      />
    );
    }
  }
 
  const getSubscriptionData = () => {
    return (
      <View>
        <SubscriptionView
          contactUuid={contactData?.uuid || ''}
          isDefaultClose={false}
          style={styles.marginBottom10AndWidth0}
          stripeCusId={contactData?.contactAdditionalInfos?.stripeCustomerId}
          sideCarDashboardEnabled={true}
        />
      </View>
    );
  };
 
  const getInsuranceData = () => {
    return (
      <InsuranceView
        contactId={contactData?.id}
        patientId={contactData?.patient?.patientId}
        contactUuid={contactData?.uuid}
        patientLocationUuid={patientLocationUuid}
        sideCarDashboardEnabled={true}
      />
    )
  }
 
 
  useEffect(()=> {
    if(contactData?.id && !pendingHieRequests.length){
      fetchHieRequests(false);
    }
  }, [contactData?.id])
 
  const accountUUID = getAccountUUID();
  const userId : number | string = getUserId();
  return (
    <Stack direction="column">
      {pendingHieRequests?.length > 0 && isHIEEnabled && (
        <View
          style={{
            backgroundColor: Colors.Custom.White,
            borderRadius: 8,
            padding: 8,
            marginHorizontal: 14,
            marginTop: 20
          }}
        >
          <AvailableHieDataView
            hieRequestsLoading={false}
            hieRequests={pendingHieRequests}
            onReviewClick={() => {
              setComponentState((prev) => ({
                ...prev,
                showHieRequestsDrawer: true,
              }));
            }}
          />
        </View>
      )}
 
      <Stack direction="column" style={{paddingHorizontal: 12, marginTop: 20}}>
        {(isHealthMapComponentVisible(accountUUID, userId) && !props.sideCarDashboardEnabled) && (
          <LLMMap contactUUID={props?.contactData?.uuid} widgetName ={healthMapName()}/>
        )}
        {(isSummaryComponentVisible(accountUUID, userId) && !props.sideCarDashboardEnabled) && (
          <LLMView contactUUID={props?.contactData?.uuid} />
        )}
        {isHIEEnabled && ['sidecar', 'dayOptimizer', 'patientListing'].includes(props?.currentView || '') && <VisitSummary
            personData={props?.formattedContactData} />
        }
 
      {props?.sideCarDashboardEnabled && <StickyNotes
        contactId={contactData?.id}
        contactUuid={contactData?.uuid}
        titleText={intl.formatMessage({id: 'stickyNote'})}
        sideCarDashboardEnabled={props?.sideCarDashboardEnabled}
      />}
 
        {/* {props.sideCarDashboardEnabled &&
          <TaskPanel
            sideCarDashboardEnabled={props?.sideCarDashboardEnabled}
            key={TaskPanelType.PATIENT}
            contactData={props.contactData as IContact}
            showDetailProfile={false}
            panelType={TaskPanelType.PATIENT}
          />
        }
 
        {props.sideCarDashboardEnabled &&
          <TaskPanel
          sideCarDashboardEnabled={props?.sideCarDashboardEnabled}
          key={TaskPanelType.INTERNAL}
          panelType={TaskPanelType.INTERNAL}
          contactData={props.contactData as IContact}
          showDetailProfile={false}
          />
        } */}
        
        {props.sideCarDashboardEnabled && <>
          <FoldPermitCan
            resource={MAIN_MENU_CODES.CALENDAR}
            action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
            component={appointmentData()}
          />
 
          {contactNotesData()}
          {props?.contactData?.contactType?.contactType?.code === 'CUSTOMER' && (
            <CareJourneyDetailsView contactData={contactData} sideCarDashboardEnabled={props?.sideCarDashboardEnabled} />
          )}
          {getContactRelationView()}
          {getMemberGroupList()}
          {getCareTeamView()}
          {canShowMembership() && stateData.showSubscriptionData && !getTenantForDemo()
            ? getSubscriptionData()
            : null}
          {!conversationData && !getTenantForDemo() ? (
                <EntityEventsListView patient={contactData} sideCarDashboardEnabled={props?.sideCarDashboardEnabled}/>
              ) : null}
          {(allowInsurance) &&  contactData?.patient !== null &&  getInsuranceData()}
        </>}
        {/*
        /* Commented for future use
        {isAlertComponentVisible(accountUUID, userId) && (
          <AlertTable contactUUID={props?.contactData?.uuid} />
        )} */}
        {/* {isDiagnosesComponentVisible(accountUUID, userId) && (
          <DiagnosisSurfacing personData={props?.formattedContactData} contactUUID={props?.contactData?.uuid} personType={'CUSTOMER'}/>
        )} */}
      </Stack>
 
      {showAssessment && !props.sideCarDashboardEnabled && <View
        style={{
          borderRadius: 8,
          backgroundColor: 'white',
          marginHorizontal: 12,
          overflow: 'hidden',
          marginBottom: 8,
        }}
      >
        <Stack
          direction="row"
          style={{
            alignItems: 'center',
            marginVertical: 16,
            marginLeft: 18,
            marginBottom: 10,
          }}
        >
          <AssessmentIonSvg />
          <Text
            color={Colors.FoldPixel.GRAY400}
            size={'lgMedium'}
            marginLeft={3}
          >
            {intl.formatMessage({id: 'assessments'})}
          </Text>
          <Tooltip
            title={intl.formatMessage({
              id: 'clinicalFormCategoryNote',
            })}
            placement="top"
          >
            <Feather
              name="info"
              style={{
                fontSize: 16,
                marginLeft: 12,
                marginTop: 4,
                color: Colors.Custom.Gray500,
              }}
            />
          </Tooltip>
        </Stack>
        <Divider />
        <Table
          columns={getColumns(intl)}
          rowKey={(row) => row.id}
          dataSource={props.forms}
          pagination={false}
          size="middle"
        />
      </View>}
 
      {showCareJourney && !props.sideCarDashboardEnabled && props?.formattedContactData && (
        <View
          style={{
            borderRadius: 8,
            backgroundColor: 'white',
            marginHorizontal: 12,
            overflow: 'hidden',
            marginBottom: 12,
          }}
        >
          <PatientCareJourneyListView
            personData={props?.formattedContactData}
            filterStatuses={INSIGHTS_JOURNEYS_STATUSES}
            assignJourneyOptionClicked={props?.assignJourneyOptionClicked}
            isCompactView={true}
          />
        </View>
      )}
      {componentState.showHieRequestsDrawer &&
        <PendingHieRequestsDrawer
          patientId={contactData?.patient?.patientId || ''}
          patientUuid={contactData?.patient?.patientUuid || ''}
          accountLocationId={accountLocationUuid || ''}
          contactUuid={contactData?.uuid}
          onClose={()=> {
            fetchHieRequests(false);
            setComponentState((prev) => ({...prev, showHieRequestsDrawer: false}));
          }}
          contactLocationId={
            contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid
          }
      />}
      {/* {
      <HomeMonitoringView
        patientId={patientId}
        locationId={accountLocationUuid || ''}
        contactId={contactId}
        component={{
          label: 'Home Monitoring',
        }}
        defaultCollapse={false}
      />
    } */}
    </Stack>
  );
};
 
export default InsightsSummaryView;