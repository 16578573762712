import {Checkbox, Tooltip} from "antd";
import {useContext,useEffect,useState} from "react";
import {Text,View} from "react-native";
import {CONSENT_CONST, MLOV_CATEGORY} from "../../../constants";
import {CommonDataContext} from "../../../context/CommonDataContext";
import {getEmailContactObjFromList,getPhoneContactObjFromList, isContactConsentRequired} from "../../../utils/commonUtils";
import {getMlovCodeFromId, getMlovListFromCategory} from "../../../utils/mlovUtils";
import {IContactConsent, IPersonData} from "../../PersonOmniView/PersonHeaderBar/interfaces";
import {ContactLinkedWithView} from "../ContactLinkedWith/ContactLinkedWithView";
import {INVITE_TYPE_VALUE,MEETING_INVITE_TYPE} from "./MeetingViewConst";
import {IContactConsents, IInviteVia, IInviteViaType} from "./interface";
import {showInvite} from "./meetingViewUtils";
import {useIntl} from "react-intl";
import {Colors} from "../../../styles";
import {IMlov} from "../../../Interfaces";
import {StyleSheet} from "react-native";

export const MeetingInviteVia = (props:
  {personData?: IPersonData,onInviteViaChange?: (inviteViaData: IInviteVia)=> void}
)=> {
  const {personData} = props;
  const contactList = personData?.contactData?.personContact || personData?.personContact || [];
  const contactId = personData?.id || '';
  const mlovData = useContext(CommonDataContext);
  const intl = useIntl();
  const personContactTypeList = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.PERSON_CONTACT_TYPE
  );
  const emailObj = getEmailContactObjFromList(
    contactList,
    personContactTypeList,
    1
  );
  const isConsentCheckRequired = isContactConsentRequired();
  const phoneObj = getPhoneContactObjFromList(
    contactList,
    personContactTypeList,
    1
  );
  const consentMlovList: IMlov[] = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.CONSENT
  ) || [];
  const [stateData, setStateData] = useState<IInviteVia>({
    sendEmail: false,
    sendSms: false,
    sendAppNotification: false,
    contactConsents: {} as IContactConsents
  })
  const patientLoggedInTime = personData?.contactData?.additionalAttributes?.loggedInTime;

  const getContactConsent = () => {
    const memberConsent = {
      consentToCall: false,
      consentToText: false,
      consentToEmail: false,
    };
    if (personData?.contactConsents?.length) {
      personData?.contactConsents?.forEach((contactConsent: IContactConsent) => {
        const consentCode = getMlovCodeFromId(consentMlovList, contactConsent?.consentId);
        switch (consentCode) {
          case CONSENT_CONST.consentToCall:
            memberConsent.consentToCall = true;
            break;
          case CONSENT_CONST.consentToEmail:
            memberConsent.consentToEmail = true;
            break;
          case CONSENT_CONST.consentToText:
            memberConsent.consentToText = true;
            break;
        }
      });
    }
    if (!isConsentCheckRequired) {
      return {
        consentToCall: true,
        consentToText: true,
        consentToEmail: true,
      };
    }
    return memberConsent;
  }

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        contactConsents: getContactConsent(),
      }
    })
  },[personData])

  useEffect(()=>{
    props?.onInviteViaChange?.(stateData);
  },[stateData])

  const isDisabled = (inviteType: string) => {
    switch (inviteType) {
      case INVITE_TYPE_VALUE.EMAIL:
        return !stateData?.contactConsents?.consentToEmail
      case INVITE_TYPE_VALUE.SMS:
        return !stateData?.contactConsents?.consentToText
      default:
        return false;
    }
  }


  const renderInvite = (inviteType: IInviteViaType) => {
    const inviteTypeCode = inviteType?.code;
    const shouldShowInvite = showInvite({
      inviteTypeCode,
      emailObj,
      phoneObj,
      patientLoggedInTime
    });
    return (
      <>
        {shouldShowInvite?.show && <> <View style={styles.checkboxContainer2}>
          <Tooltip
            title={isDisabled(inviteType?.code) ? `${inviteType?.code} ${intl.formatMessage({id: 'consentRequired'})}` : ''}
            placement={'top'}
            destroyTooltipOnHide={true}
          >
            <Checkbox
              className="copy-checkbox"
              disabled={isDisabled(inviteType?.code)}
              onChange={(e) => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    [inviteType.stateDataKey]: e.target.checked,
                  }
                })
              }}
            />
          </Tooltip>
          {<Text style={styles.marginLeft8}>
            {inviteType?.value}{" "}
            {shouldShowInvite?.value && <Text
              style={styles.checkboxText}>
              {`(${shouldShowInvite?.value})`}
            </Text>}
          </Text>}
        </View>
          {inviteType?.code !== INVITE_TYPE_VALUE.NOTIFICATION && <View style={styles.marginLeft30}>
            <ContactLinkedWithView
              linkedWith={inviteType?.code}
              contactData={{
                email: emailObj?.value,
                phoneNumber: phoneObj?.value,
                contactId: contactId,
              }}
            />
          </View>}
        </>}
      </>
    )
  }
  const showInviteVia = () => {
    return contactId && (emailObj?.id || phoneObj?.id || patientLoggedInTime)
  }
  return (<>
    {showInviteVia() && <View>
      <View style={styles.checkboxContainer} />
      <Text style={styles.checkboxText}>
        {intl.formatMessage({id: 'inviteVia'})}
      </Text>
      {MEETING_INVITE_TYPE.map((inviteType)=> {
        return (
          <>
            {renderInvite(inviteType)}
          </>
        )
      })}
      <View style={styles.checkboxContainer} />
    </View>}
  </>)
}

const styles = StyleSheet.create({
  checkboxContainer: {height: 1, backgroundColor: Colors.Custom.Gray200, marginVertical: 14},
  checkboxText: {
    fontSize: 14,
    lineHeight: 21,
    fontWeight: '400',
  },
  checkboxContainer2: {
    flexDirection: 'row', marginTop: 10, alignItems: 'center'
  },
  marginLeft8: {marginLeft: 8},
  marginLeft30: {marginLeft: 30},
});