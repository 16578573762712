import React from 'react';
import {isFutureDate, isPastDay} from '../../../utils/DateUtils';
import {getMomentObj} from '../../../utils/DateUtils';
import {DATE_FORMATS} from '../../../constants';
import FilterWrapperComponent from '../../common/CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import {getFormattedDate} from '../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils';
import {ICareManagementDateFilter} from './interface';
import {CONTACT_CARE_PROGRAM_FITER_ACTION_CODES} from '../CareManagementBilling/CareManagementConstants';
import Stack from '../../common/LayoutComponents/Stack';
import {Text} from 'native-base';
import {useIntl} from 'react-intl';
import {StyleSheet} from 'react-native';
import {DatePicker} from 'antd';
import {antStyles} from './CareManageMentViewStyles';
import {Colors} from '../../../styles';
import {DateRangeSectionProps} from './interface';
import {CareProgramDateType} from '../CareManagementBilling/CareManagementConstants';

const DateRangeSection = React.memo(({
  title,
  dateRange,
  onChange,
  disabledFromDate,
  disabledToDate,
}: DateRangeSectionProps) => {
  const intl = useIntl();

  const renderDatePicker = (
    dateType: CareProgramDateType,
    label: string,
    disabledDate?: (current: moment.Moment) => boolean,
    containerStyle?: any
  ) => (
    <Stack direction={'column'} style={containerStyle}>
      <Text style={styles.datePickerTextStyle}>
        {intl.formatMessage({id: label})}
      </Text>
      <DatePicker
        style={antStyles.datePickerStyle}
        data-testId='selectDate'
        value={dateRange[dateType] ? getMomentObj(dateRange[dateType]) : undefined}
        allowClear={false}
        format={DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT}
        onChange={(date, formatStrings) => onChange(dateType, date, formatStrings)}
        disabledDate={disabledDate}
      />
    </Stack>
  );
  
  return (
    <Stack direction={'column'}>
      <Text style={styles.dateTitleStyle}>{title}</Text>
      <Stack direction={'row'}>
        {renderDatePicker(
          CareProgramDateType.FROM_DATE,
          'from',
          disabledFromDate,
          styles.datePickerStyle
        )}
        {renderDatePicker(
          CareProgramDateType.TO_DATE,
          'to',
          disabledToDate,
          styles.datePickerStyle
        )}
      </Stack>
    </Stack>
  );
});

const CareManagementDateFilter = (props: ICareManagementDateFilter) => {
  const intl = useIntl();
  const {selectedDateFilter, onFilterActionPerformed} = props;

  const [stateDateFilter, setStateDateFilter] = React.useState({
    showDatePopover: false,
    programStartDate: {
      [CareProgramDateType.FROM_DATE]:
        selectedDateFilter?.programStartDate?.[CareProgramDateType.FROM_DATE] || '',
      [CareProgramDateType.TO_DATE]:
        selectedDateFilter?.programStartDate?.[CareProgramDateType.TO_DATE] || '',
    },
    programEndDate: {
      [CareProgramDateType.FROM_DATE]:
        selectedDateFilter?.programEndDate?.[CareProgramDateType.FROM_DATE] || '',
      [CareProgramDateType.TO_DATE]:
        selectedDateFilter?.programEndDate?.[CareProgramDateType.TO_DATE] || '',
    },
  });

  React.useEffect(() => {
    setStateDateFilter(prev => ({
      ...prev,
      programStartDate: {
        [CareProgramDateType.FROM_DATE]: selectedDateFilter?.programStartDate?.[CareProgramDateType.FROM_DATE] || '',
        [CareProgramDateType.TO_DATE]: selectedDateFilter?.programStartDate?.[CareProgramDateType.TO_DATE] || '',
      },
      programEndDate: {
        [CareProgramDateType.FROM_DATE]: selectedDateFilter?.programEndDate?.[CareProgramDateType.FROM_DATE] || '',
        [CareProgramDateType.TO_DATE]: selectedDateFilter?.programEndDate?.[CareProgramDateType.TO_DATE] || '',
      },
    }));
  }, [selectedDateFilter]);

  const handleDatePopoverChange = (visible: boolean) => {
    setStateDateFilter((prev) => ({
      ...prev,
      showDatePopover: visible,
    }));
    if (!visible) {
      onFilterActionPerformed(
        CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.DATE_FILTER_CHANGE,
        {
          programStartDate: stateDateFilter.programStartDate,
          programEndDate: stateDateFilter.programEndDate,
        }
      );
    }
  };

  const handleProgramStartDateChange = (
    type: CareProgramDateType,
    date: any,
    formatStrings: string
  ) => {
    if (formatStrings && formatStrings.length > 1) {
      if (type === CareProgramDateType.FROM_DATE) {
        const fromDate = getFormattedDate(
          formatStrings,
          DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
        );
        const toDate = getFormattedDate(
          formatStrings,
          DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
        );
        
        setStateDateFilter((prev) => ({
          ...prev,
          programStartDate: {
            ...prev.programStartDate,
            [CareProgramDateType.FROM_DATE]: fromDate,
            ...(!prev.programStartDate[CareProgramDateType.TO_DATE] && {
              [CareProgramDateType.TO_DATE]: toDate,
            }),
          },
        }));
      } else {
        const toDate = getFormattedDate(
          formatStrings,
          DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
        );
        const fromDate = isPastDay(getMomentObj(toDate))
          ? getFormattedDate(formatStrings, DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)
          : getFormattedDate(new Date(), DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT);
          
        setStateDateFilter((prev) => ({
          ...prev,
          programStartDate: {
            ...prev.programStartDate,
            [CareProgramDateType.TO_DATE]: toDate,
            ...(!prev.programStartDate[CareProgramDateType.FROM_DATE] && {
              [CareProgramDateType.FROM_DATE]: fromDate,
            }),
          },
        }));
      }
    }
  };

  const handleProgramEndDateChange = (
    type: CareProgramDateType,
    date: any,
    formatStrings: string
  ) => {
    if (formatStrings && formatStrings.length > 1) {
      if (type === CareProgramDateType.FROM_DATE) {
        const fromDate = getFormattedDate(
          formatStrings,
          DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
        );
        const toDate = getFormattedDate(
          formatStrings,
          DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
        );
        
        setStateDateFilter((prev) => ({
          ...prev,
          programEndDate: {
            ...prev.programEndDate,
            [CareProgramDateType.FROM_DATE]: fromDate,
            ...(!prev.programEndDate[CareProgramDateType.TO_DATE] && {
              [CareProgramDateType.TO_DATE]: toDate,
            }),
          },
        }));
      } else {
        const toDate = getFormattedDate(
          formatStrings,
          DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
        );
        const fromDate = isPastDay(getMomentObj(toDate))
          ? getFormattedDate(formatStrings, DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)
          : getFormattedDate(new Date(), DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT);
          
        setStateDateFilter((prev) => ({
          ...prev,
          programEndDate: {
            ...prev.programEndDate,
            [CareProgramDateType.TO_DATE]: toDate,
            ...(!prev.programEndDate[CareProgramDateType.FROM_DATE] && {
              [CareProgramDateType.FROM_DATE]: fromDate,
            }),
          },
        }));
      }
    }
  };

  const disableStartFromDate = (current: moment.Moment) => {
    return current && isFutureDate(current);
  };

  const disableStartToDate = (current: moment.Moment) => {
    if (stateDateFilter.programStartDate[CareProgramDateType.FROM_DATE]) {
      const fromDate = getMomentObj(
        stateDateFilter.programStartDate[CareProgramDateType.FROM_DATE]
      );
      return current && current.isBefore(fromDate, 'day');
    }
    return current && isPastDay(current);
  };

  const disableEndFromDate = (current: moment.Moment) => {
    return current && isFutureDate(current);
  };

  const disableEndToDate = (current: moment.Moment) => {
    if (stateDateFilter.programEndDate[CareProgramDateType.FROM_DATE]) {
      const fromDate = getMomentObj(
        stateDateFilter.programEndDate[CareProgramDateType.FROM_DATE]
      );
      return current && current.isBefore(fromDate, 'day');
    }
    return current && isPastDay(current);
  };

  return (
    <FilterWrapperComponent
      label="Date"
      value={[
        stateDateFilter.programStartDate[CareProgramDateType.FROM_DATE] &&
        stateDateFilter.programStartDate[CareProgramDateType.TO_DATE]
          ? `Start Date: ${
              stateDateFilter.programStartDate[CareProgramDateType.FROM_DATE]
            } - ${stateDateFilter.programStartDate[CareProgramDateType.TO_DATE]}`
          : '',
        stateDateFilter.programEndDate[CareProgramDateType.FROM_DATE] &&
        stateDateFilter.programEndDate[CareProgramDateType.TO_DATE]
          ? `End Date: ${
              stateDateFilter.programEndDate[CareProgramDateType.FROM_DATE]
            } - ${stateDateFilter.programEndDate[CareProgramDateType.TO_DATE]}`
          : '',
      ].filter(Boolean)}
      filterTextStyle={styles.filterTextStyle}
      filterValueTextStyle={styles.filterValueTextStyle}
      contentMinHeight={35}
      onClose={() => handleDatePopoverChange(false)}
      popoverStyle={{borderRadius: 8}}
      isControlledPopover={true}
      open={stateDateFilter.showDatePopover}
      onOpenChange={handleDatePopoverChange}
    >
      <Stack direction={'column'} space={4}>
        <DateRangeSection
          title="Start Date Range"
          dateRange={stateDateFilter.programStartDate}
          onChange={handleProgramStartDateChange}
          disabledFromDate={disableStartFromDate}
          disabledToDate={disableStartToDate}
        />
        <DateRangeSection
          title="End Date Range"
          dateRange={stateDateFilter.programEndDate}
          onChange={handleProgramEndDateChange}
          disabledFromDate={disableEndFromDate}
          disabledToDate={disableEndToDate}
        />
      </Stack>
    </FilterWrapperComponent>
  );
};

const styles = StyleSheet.create({
  filterTextStyle: {
    fontSize: 14,
    marginRight: 2,
    fontWeight: '500',
    color: Colors.Custom.Gray500,
    borderRadius: 4,
  },
  filterValueTextStyle: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.Gray700,
  },
  datePickerStyle: {
    marginTop: 4,
    padding: 4,
  },
  dateTitleStyle: {
    fontSize: 14,
    marginBottom: 2,
    fontWeight: '500',
    color: Colors.Custom.Gray500,
  },
  datePickerTextStyle: {
    fontSize: 14,
    marginLeft: 3,
    fontWeight: '500',
    color: Colors.Custom.Gray500,
  },
});

export default React.memo(CareManagementDateFilter);
