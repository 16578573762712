import {useLazyQuery} from '@apollo/client';
import {Drawer, notification, Popover, Tooltip} from 'antd';
import {Divider, HStack, Text, useToast, View} from 'native-base';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Pressable} from 'react-native';
import {
  BUTTON_TYPE,
  getInActiveContactError,
  PERSON_TYPES,
  RIGHT_SIDE_CONTAINER_CODE,
} from '../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  PATIENT_QUICK_PROFILE_PARENT_CODES,
  QUICK_ACTION_CODES,
} from '../../constants/ActionConst';
import {CommonDataContext} from '../../context/CommonDataContext';
import {Colors} from '../../styles';
import {
  canCommunicateWithInactivePatient,
  getLocationGroupIdFromLocationId,
  getSecondaryValue,
  getUserData,
  getUserFullName,
  getUserId,
  getUserUUID,
  isActiveContact,
  isConsentRequiredAndGiven,
  isSideCarEnabledForPatientDashboard,
} from '../../utils/commonUtils';
import {EventBus} from '../../utils/EventBus';
import {getToolTipMessage} from '../../utils/PersonActionsUtils';
import {getFormDataFromLeadData} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import CreateSmsConversationDrawer from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {patientNotLoggedInError} from '../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationConst';
import {
  IContact,
  IConversationData,
  INewConversationResponse,
} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {SMS_INBOX_ACTION_CODES} from '../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import {
  MAIN_MENU_CODES,
  USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE,
} from '../SideMenuBar/SideBarConst';
import InstantChat from '../common/ChatDrawer/InstantChat';
import MeetingView from '../common/MeetingView/MeetingView';
import OutboundCallSmsView from '../common/OutboundCallSmsView/OutboundCallSmsView';
import {isAccountConfigEnabled} from '../../utils/configUtils';
import {CONFIG_CODES} from '../../constants/AccountConfigConst';
import {EmailDrawerCommonV2} from '../common/EmailDrawerCommonV2';
import InstantChatView from '../common/ChatDrawer/InstantChatView';
import ConversationsQueries from '../../services/Conversations/ConversationsQueries';
import ChatRoundActionSvg from '../common/Svg/PersonActionSvgIcons/ChatRoundActionSvg';

import {isCallsAllowed, USER_ACCESS_PERMISSION} from '../RightSideContainer/UserAccess/UserAccessPermission';
import FoldPermitCan from '../CommonComponents/FoldPermitCan/FoldPermitCan';
import {withMiniContactViewHOC} from '../MiniContactViewHOC';
import {testID} from '../../testUtils';
import MenuHorizontalIcon from '../common/Svg/MenuHorizontalIcon';
import {IConversationChannelList} from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/Interfaces';
import {DISPLAY_CONVERSATION_SIDEBAR_ICON} from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationSidebarUtils';
import {useIntl} from 'react-intl';
import CrossIcon from '../common/Svg/CrossIcon';
import Stack from '../common/LayoutComponents/Stack';
import AddOrUpdateTask from '../common/AddTask/AddOrUpdateTask';
import { TaskPanelType } from '../TaskCard/TaskEnum';
import { ParticipantType } from '../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { IUserPatientSearchItem } from '../TaskModule/TaskInterfaces';
import ResetPasswordModal from '../common/PersonActions/ResetPasswordModal';
import AutomationWorkflowEmployee from '../RightSideContainer/MembersManagement/AutomationWorkflowEmployee/AutomationWorkflowEmployee';
import ContactSendForm from '../common/ContactSendForm/ContactSendForm';
import { AddOrUpdateLead } from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import PhoneActionSvgV1 from '../common/Svg/PersonActionSvgIcons/PhoneActionSvgV1';
import { getPatientEHRUrl } from '../../utils/capabilityUtils';
import AppointmentBooking from '../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBooking';
import { PrintContactClinicalProfileSectionSelector } from '../common/PrintContactClinicalProfileDrawer/PrintContactClinicalProfileSectionSelector';
import { AddContactRelationView } from '../common/AddContactRelationView';
import { ToastType, showToast } from '../../utils/commonViewUtils';
import { getContactTypeId } from '../../utils/mlovUtils';
import SendEdicationContent from '../common/AppDashboard/DayOptimizer/PatientDashboard/PatientListing/SendEducationContent';
import { FlowType } from '../../context/WorkflowContext';
import { getNodeMetadata } from '../RightSideContainer/Journeys/AddOrUpdateJourney/JourneyMetadataService';
import { EDUCATION_CONTENT_NODE_DETAILS } from './constants';
import { setFieldsVisibilityFromPreviousNode } from '../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeHelper';
import DayOptimizer from '../common/AppDashboard/DayOptimizer/DayOptimizer';
import { ModalActionTitle } from '../common/ModalActionTitle/ModalActionTitle';
import { redirectToNewTab } from './SidecarUtils';

interface SidecarPatientProfileActionsProps {
  shouldShowEmail?: boolean;
  email?: any;
  showVideoCall: boolean;
  phoneNumber?: any;
  showSms?: boolean;
  contactInfo?: IContact | undefined;
  showPhoneCall?: boolean;
  handleEdit?: any;
  showAssignJourney: boolean;
  onPersonActionPerformed?: (actionCode: string, rawData?: any) => void;
  handleCreateCarePlanClick?: () => void;
  shouldShowCloseButton?: boolean;
  handleCloseClick?: () => void;
  taskPanelType?: TaskPanelType;
  sendResetPasswordInstructions?: (contactData: any) => void;
  showDetailProfile?: boolean;
  showCharts?: boolean;
  parentCode?: string;
  navigateOrOpenContactIdDrawer?: (contactId: string, additionalData?: any) => void;
  currentView?: string;
  assignJourneyOptionClicked?: () => void;
  referralOrderOptionClicked?: () => void;
}

const SideCarPatientProfileActionsComponent = (props: SidecarPatientProfileActionsProps) => {
  const {
    shouldShowEmail,
    email,
    showVideoCall,
    phoneNumber,
    showSms,
    contactInfo,
    showPhoneCall,
    handleEdit,
    showAssignJourney,
    onPersonActionPerformed
  } = props;
  const personActionRef: any = useRef();
  const userUUID = getUserUUID();
  const userId = getUserId();
  const intl = useIntl();
  const isCallAllowed = isCallsAllowed();
  const isLoggedIn = contactInfo?.additionalAttributes?.loggedInTime;
  const [selectedView, setSelectedView] = useState<string>('');
  const isPatientMessagingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.PATIENT_MESSAGING_ENABLED
  );
  const [typeNodes, setTypeNodes] = React.useState<any>();
  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(
    CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR
  );

  const toast = useToast();

  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);

  const [stateData, setStateData] = useState({
    visible: false,
    preferredChannel: '',
    openDrawerCode: '',
    selectedConversation: {} as IConversationData,
    moreActionPopup: false,
    showResetPasswordModal: false,
    showAutomationDrawer: false,
    showSendFormDrawer: false,
    showEditPatientDrawer: false,
    showAppointmentBookingDrawer: false,
    showPrintClinicalProfileDrawer: false,
    showAddRelativesDrawer: false,
    showAddFamilyMembersDrawer: false,
    showSendPatientEducationDrawer: false,
    showDayOptimizerDrawer: false
  });
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const contactActiveStatus = isActiveContact(contactInfo);
  const formattedContactData = getFormDataFromLeadData(
    contactInfo || {},
    commonData
  );
  const patientUuid = props?.contactInfo?.uuid;
  const consentCheck = isConsentRequiredAndGiven(contactInfo);
  const userFullName = getUserFullName();
  const currentUserData = getUserData();

  const [GetConversationByConversationId] =
    useLazyQuery<INewConversationResponse>(
      ConversationsQueries.GetConversationByConversationId,
      {
        fetchPolicy: 'no-cache',
      }
    );

  const handleOnCreateNewEmailConversation = async (conversationId: string) => {
    if (!conversationId) {
      return;
    }
    try {
      const response = await GetConversationByConversationId({
        variables: {
          conversationId: Number(conversationId),
          loginUserIntId: userId,
          loginUserUuid: userUUID,
        },
      });
      const conversations = response.data?.conversations || [];
      if (conversations.length > 0) {
        const selectedConversationData = conversations[0];
        setStateData((prev) => {
          return {
            ...prev,
            selectedConversation: selectedConversationData,
          };
        });
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER);
      }
    } catch (error) {
      setStateData((prev) => {
        return {
          ...prev,
          selectedConversationData: {},
        };
      });
    }
  };

  const handleMoreActionVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        moreActionPopup: visible,
      };
    });
  };

  const handleActions = (actionCode: string, rowData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.CALL:
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent('CLOUD_CALL', {
          contactData: contactInfo ?? {fromNumber: phoneNumber},
          fromNumber: rowData.fromNumber,
          toNumber: phoneNumber,
        });
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
      default:
        break;
    }
  };
  const isPatientLoggedIn = contactInfo?.additionalAttributes?.loggedInTime
    ? true
    : false;

  const shouldShowChat = () => {
    if (isSidecarContext && showOnlyInternalChatInSidecar) {
      return false;
    }
    if (
      USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.USER.includes(
        MAIN_MENU_CODES.INBOX
      )
    ) {
      return (
        contactInfo?.patient?.id &&
        contactInfo?.uuid &&
        isLoggedIn &&
        isPatientMessagingEnabled
      );
    } else {
      return false;
    }
  };

  const canStartAction = (actionCode: string) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.START_SMS:
        return showSms;
      case CONVERSATION_ACTION_CODES.START_EMAIL:
        return shouldShowEmail;
      case CONVERSATION_ACTION_CODES.START_VIDEO_MEET:
        return showVideoCall;
      case QUICK_ACTION_CODES.ADD_CARE_JOURNEY:
        return showAssignJourney;
      case QUICK_ACTION_CODES.ADD_REFERRAL_ORDER:
          return true;
      case QUICK_ACTION_CODES.RESET_PASSWORD:
        return true;
      case QUICK_ACTION_CODES.SEND_ASSESMENT:
        return true;
      case QUICK_ACTION_CODES.RUN_AUTOMATION:
        return true;
      case QUICK_ACTION_CODES.SEND_PATIENT_EDUCATION:
        return true;
      case QUICK_ACTION_CODES.EDIT_PATIENT:
        return true;
      case QUICK_ACTION_CODES.DAY_OPTIMIZER:
        return true;
      case QUICK_ACTION_CODES.ADD_RELATIVES:
        return true;
      default:
        return true;
    }
  };

  const performAction = (actionCode: string) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.START_SMS:
        if (canCommunicateWithInactivePatient(contactInfo)) {
          if (consentCheck) {
            if (phoneNumber) {
              setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER);
            } else {
              handleEdit?.();
            }
          } else {
            handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
          }
        } else {
          const message = getInActiveContactError(contactInfo);
          notification.error({
            message,
          });
        }
        break;
      case CONVERSATION_ACTION_CODES.START_EMAIL:
        if (canCommunicateWithInactivePatient(contactInfo)) {
          if (consentCheck) {
            if (email) {
              setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP);
            } else {
              handleEdit?.();
            }
          } else {
            handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
          }
        } else {
          const message = getInActiveContactError(contactInfo);
          notification.error({
            message,
          });
        }
        break;
      case CONVERSATION_ACTION_CODES.START_VIDEO_MEET:
        if (contactActiveStatus) {
          if (consentCheck) {
            if (email || phoneNumber) {
              setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW);
            } else {
              handleEdit?.();
            }
          } else {
            handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
          }
        } else {
          const message = getInActiveContactError(contactInfo);
          notification.error({
            message,
          });
        }
        break;
      case QUICK_ACTION_CODES.ADD_CARE_JOURNEY:
        handleMoreActionVisibleChange(false);
        props?.assignJourneyOptionClicked?.();
        onPersonActionPerformed?.(QUICK_ACTION_CODES.ADD_CARE_JOURNEY);
        break;
      case QUICK_ACTION_CODES.ADD_REFERRAL_ORDER:
        handleMoreActionVisibleChange(false);
        props?.referralOrderOptionClicked?.();
        onPersonActionPerformed?.(QUICK_ACTION_CODES.ADD_REFERRAL_ORDER);
        break;
      case QUICK_ACTION_CODES.CREATE_REFERRAL_ORDER:
        handleMoreActionVisibleChange(false);
        props?.referralOrderOptionClicked?.();
        onPersonActionPerformed?.(QUICK_ACTION_CODES.CREATE_REFERRAL_ORDER);
        break;
      case QUICK_ACTION_CODES.RESET_PASSWORD:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showResetPasswordModal: true}));
        break;
      case QUICK_ACTION_CODES.SEND_ASSESMENT:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showSendFormDrawer: true}));
        break;
      case QUICK_ACTION_CODES.RUN_AUTOMATION:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showAutomationDrawer: true}));
        break;
      case QUICK_ACTION_CODES.EDIT_PATIENT:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showEditPatientDrawer: true}));
        break;
      case QUICK_ACTION_CODES.DAY_OPTIMIZER:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showDayOptimizerDrawer: true}));
        break;
      case QUICK_ACTION_CODES.CREATE_CARE_PLAN:
        handleMoreActionVisibleChange(false);
        props?.handleCreateCarePlanClick && props?.handleCreateCarePlanClick();
        break;
      case QUICK_ACTION_CODES.ADD_NEW_TASK:
        handleMoreActionVisibleChange(false);
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK);
        break;
      case QUICK_ACTION_CODES.VIEW_IN_EHR:
        handleMoreActionVisibleChange(false);
        openInEHR();
        break;
      case QUICK_ACTION_CODES.SCHEDULE_APPOINTMENT:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showAppointmentBookingDrawer: true}));
        break;
      case QUICK_ACTION_CODES.ADD_RELATIVES:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showAddRelativesDrawer: true}));
        break;
      case QUICK_ACTION_CODES.CLINICAL_PROFILE:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showPrintClinicalProfileDrawer: true}));
        break;
      case QUICK_ACTION_CODES.SEND_PATIENT_EDUCATION:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showSendPatientEducationDrawer: true}));
        break;
      default:
        break;
    }
  };

const getToolTip = (code: string, defaultMessage: string) => {
    switch (code) {
      case 'START_SMS':
        if (props?.phoneNumber) {
          return defaultMessage
        } else {
          return 'Phone Number not set, please add phone number to take any action'
        }
      case 'START_EMAIL':
        if (props?.email) {
          return defaultMessage
        } else {
          return 'Email not set, please add email to take any action'
        }
      case 'START_VIDEO_MEET':
          if(props?.email || props?.phoneNumber) {
            return defaultMessage
          } else {
            return 'Email or Phone Number not set, please add email or phone Number to take any action'
          }
      default:
        return defaultMessage
    }
  }

  const getActionDividerLine = (mt?: number) => {
    return (
      <Divider
        orientation={'vertical'}
        alignSelf={'center'}
        style={{
          height: 20,
          width: 1,
          marginTop: mt || -16,
          backgroundColor: Colors.Custom.Gray200,
        }}
      />
    );
  };

  const getAssignee = (): IUserPatientSearchItem => {
    const isPatientPanel = props?.taskPanelType === TaskPanelType.PATIENT;
    const patientName = props?.contactInfo?.name || '';
    return {
      value: isPatientPanel ? patientUuid : userUUID,
      label: isPatientPanel ? patientName : userFullName,
      key: isPatientPanel ? patientUuid : userUUID,
      type: isPatientPanel ? ParticipantType.patient : ParticipantType.staff,
      details: isPatientPanel ? props?.contactInfo : currentUserData,
    };
  };

  useEffect(() => {
    getNodeMetaData();
  }, []);

  const getNodeList = useCallback((
    taskType: typeof EDUCATION_CONTENT_NODE_DETAILS,
    allTaskType: any
  ) => {
    const tasktypeData: any = [];
    if (allTaskType.hasOwnProperty(taskType.type)) {
      const taskObject: any = {};
      taskObject[taskType.type] = allTaskType[taskType.type];
      const filteredUserInputFieldList = taskObject[
        taskType.type
      ].userInputFieldList.filter((item: any) =>
        taskType.userInputFieldListKeys.includes(item.key)
      );
      taskObject[taskType.type].userInputFieldList = [];
      taskObject[taskType.type].userInputFieldList.push(
        ...filteredUserInputFieldList
      );
      tasktypeData.push(taskObject);
    }
    return tasktypeData;
  }, []);

  const getNodeMetaData = useCallback(() => {
    getNodeMetadata(
      FlowType.careJourney,
      (response) => {
        const nodeMap = response?.nodeMasterData?.nodeMap;
        const typeNodes = getNodeList(EDUCATION_CONTENT_NODE_DETAILS, nodeMap);
        const nodeType = 'CareJourneyContentV3';
        if (nodeType) {
          let typeData: any;
          if (typeNodes) {
            typeData = typeNodes.find((obj: any) => obj[nodeType]);
          }
          const userInputFieldList = typeData[nodeType].userInputFieldList;
          const { inputFields: updatedInputFieldList } =
            setFieldsVisibilityFromPreviousNode(
              userInputFieldList,
              nodeMap,
              undefined,
              FlowType.careJourney
            );

          const userInputFieldListCopy = updatedInputFieldList.map(
            (userInputField: any) => {
              return {
                ...userInputField,
                value:
                  userInputField?.value == undefined
                    ? userInputField?.defaultValue
                    : userInputField?.value,
              };
            }
          );
          typeData[nodeType].userInputFieldList = userInputFieldListCopy;
          setTypeNodes(typeData[nodeType]);
        }
      },
      () => {
        setTypeNodes([]);
      }
    );
  }, [getNodeList]);

  const getNewTaskMember = () => {
    const data = {
      value: formattedContactData?.contactUUID,
      label: formattedContactData?.name,
      key: formattedContactData?.contactUUID,
      type: formattedContactData.contactType?.code === PERSON_TYPES.LEAD ? ParticipantType.leads : ParticipantType.patient,
      contactData: formattedContactData?.contactData,
      data: formattedContactData?.contactData,
    };
    return data;
  }

  const getDefaultMember = () => {
    const patientName = props?.contactInfo?.name || '';
    return {
      value:  patientUuid,
      label: patientName,
      key:  patientUuid,
      type: ParticipantType.patient,
      details: props?.contactInfo,
    };
  }
  const showChartsAction = props?.parentCode !== PATIENT_QUICK_PROFILE_PARENT_CODES.CONTACT_OMNI_VIEW &&
    props?.parentCode !== PATIENT_QUICK_PROFILE_PARENT_CODES.MESSAGING &&
    props?.contactInfo?.patient?.id &&
    props?.contactInfo?.uuid && 
    !(props?.showCharts === false);

  const isActionCreateReferral = props?.parentCode !== PATIENT_QUICK_PROFILE_PARENT_CODES.CONTACT_OMNI_VIEW &&
  props?.parentCode !== PATIENT_QUICK_PROFILE_PARENT_CODES.MESSAGING && isEFaxMessagingEnabled

  const ehrPatientId = contactInfo?.patient?.patientId || '';
  const locationId = contactInfo?.contactPracticeLocations?.[0]?.accountLocation?.uuid;

  const locationGroupId =
  locationId &&
  getLocationGroupIdFromLocationId(
    commonData?.accountLocationListWithEHR,
    locationId
  );
  
  const openInEhrUrl = getPatientEHRUrl(ehrPatientId, '', locationGroupId);
  const showViewInEHRBtn = !isSidecarContext && (!!openInEhrUrl || window?.location?.host === 'demo.sandbox.foldhealth.io');
  const openInEHR = () => {
    window.open(openInEhrUrl, '_blank', 'noopener,noreferrer');
  };

  const navigateTo = (actionCode: string, contactId: string) => {
    if(isSidecarContext) {
      actionCode = actionCode === 'CREATE_CHART' ? 'notes' : actionCode === 'REFERRAL_ORDER' ? 'referral' : actionCode;
      redirectToNewTab(`/members/patient/${contactId}/${actionCode}`)
    } else {
      props?.navigateOrOpenContactIdDrawer?.(contactId,{
        state: {actionType:actionCode},
      } );
    }
  }

  const PATIENT_ACTION_LIST: IConversationChannelList[] = [
    {
      key: QUICK_ACTION_CODES.VIEW_IN_EHR,
      title: 'EHR',
      path: '',
      description: 'View in EHR',
      isMainAction: true,
      disabled: false,
      isDeleted: !showViewInEHRBtn,
      allowedViews : ['dayOptimizer', 'patientProfile']
    },
    ...(showChartsAction ? [{
      key: QUICK_ACTION_CODES.CREATE_CHART,
      title: 'Charts',
      path: '',
      description: 'Create Chart',
      isMainAction: true,
      disabled: false,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar']
    }] : [{
      key: CONVERSATION_ACTION_CODES.START_VIDEO_MEET,
      title: 'Meet',
      path: '',
      description: 'Start Meeting',
      isMainAction: true,
      disabled: !email && !phoneNumber,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar', 'patientProfile']
    }]),
    {
      key: QUICK_ACTION_CODES.SCHEDULE_APPOINTMENT,
      title: 'Schedule',
      path: '',
      description: 'Schedule Appointment',
      isMainAction: true,
      disabled: false,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar', 'patientProfile']
    },
    {
      key: CONVERSATION_ACTION_CODES.START_SMS,
      title: 'SMS',
      path: '',
      description: 'Send SMS',
      isMainAction: true,
      disabled: !phoneNumber,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar', 'patientProfile']
    },
    ...(showChartsAction ? [{
      key: CONVERSATION_ACTION_CODES.START_VIDEO_MEET,
      title: 'Start Meeting',
      path: '',
      description: 'Start Meeting',
      isMainAction: false,
      disabled: !email && !phoneNumber,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar', 'patientProfile']
    }] : []),
    {
      key: CONVERSATION_ACTION_CODES.START_EMAIL,
      title: 'Send Email', 
      path: '',
      description: 'Send Email',
      isMainAction: false,
      disabled: !email,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar', 'patientProfile']
    },
    {
      key: QUICK_ACTION_CODES.ADD_CARE_JOURNEY,
      title: 'Assign Journey',
      path: '',
      description: 'Assign Journey',
      isMainAction: false,
      disabled: false,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar']
    },
    {
      key: QUICK_ACTION_CODES.SEND_ASSESMENT,
      title: 'Send Assessment',
      path: '',
      isMainAction: false,
      disabled: false,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar', 'patientProfile']
    },
    {
      key: QUICK_ACTION_CODES.SEND_PATIENT_EDUCATION,
      title: 'Send Education',
      path: '',
      isMainAction: false,
      disabled: false,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar', 'patientProfile']
    },
    {
      key: QUICK_ACTION_CODES.ADD_NEW_TASK,
      title: 'Add Task',
      path: '',
      isMainAction: false,
      disabled: false,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar', 'patientProfile']
    },
    ...(isActionCreateReferral ? [{
      key: QUICK_ACTION_CODES.CREATE_REFERRAL_ORDER,
      title: 'Create Referral',
      path: '',
      isMainAction: false,
      disabled: false,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar', 'patientProfile']
    }] : []),
    {
      key: QUICK_ACTION_CODES.RUN_AUTOMATION,
      title: 'Run Automation',
      path: '',
      isMainAction: false,
      disabled: false,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar', 'patientProfile']
    },
    {
      key: QUICK_ACTION_CODES.DAY_OPTIMIZER,
      title: 'Check Day Optimizer',
      path: '',
      isMainAction: false,
      disabled: false,
      isDeleted: true,
      allowedViews : ['sidecar']
    },
    {
      key: QUICK_ACTION_CODES.ADD_RELATIVES,
      title: 'Add Relatives',
      path: '',
      isMainAction: false,
      disabled: false,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar', 'patientProfile']
    },
    {
      key: QUICK_ACTION_CODES.CLINICAL_PROFILE,
      title: 'Print Clinical Profile',
      path: '',
      isMainAction: false,
      disabled: false,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'sidecar', 'patientProfile']
    },
    {
      key: QUICK_ACTION_CODES.EDIT_PATIENT,
      title: 'Edit Details',
      path: '',
      isMainAction: false,
      disabled: false,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'patientProfile']
    },
    {
      key: QUICK_ACTION_CODES.RESET_PASSWORD,
      title: 'Reset Password',
      path: '',
      isMainAction: false,
      disabled: false,
      isDeleted: false,
      allowedViews : ['dayOptimizer', 'patientProfile']
    },
  ];

  const checkAllowedViews = (action: IConversationChannelList) => {
    return action.allowedViews?.includes(props?.currentView || '') && action?.isDeleted == false;
  }

  return (
    <View style={{ width: '100%' }}>
      <HStack
        ref={personActionRef}
        justifyContent={'space-between'}
        alignItems={'center'} 
        paddingLeft={'12px'}
        paddingRight={'12px'}
        paddingTop={'8px'}
        paddingBottom={'8px'}
        style={{ width: '100%', flex: 1}}
      >
        <HStack 
          flex={1}
          justifyContent={'flex-start'} 
          alignItems={'center'} 
          space={2}
          style={{
            padding: '8px 12px'
          }}
        >
          {PATIENT_ACTION_LIST?.filter(
            action => 
              action.isMainAction && 
              checkAllowedViews(action) && 
              action.disabled == false
          )?.map((action, index, array) => (
            <React.Fragment key={action.key}>
              <View 
                height={'30px'} 
                style={{ 
                  alignItems: 'center', 
                  width: action.title.length * 8, 
                  flex: 1 
                }}
              >
                <Tooltip 
                  title={getToolTip(
                    action?.key, 
                    action?.description || action?.title
                  )}
                >
                  <Pressable 
                    onPress={() => {
                      if (action.key === QUICK_ACTION_CODES.CREATE_CHART && contactInfo?.id) {
                        navigateTo('CREATE_CHART', contactInfo?.id?.toString());
                      } else {
                        performAction(action.key)
                      }
                    }}
                    style={{ alignItems: 'center', width: '100%' }}
                  >
                    <View 
                      style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        justifyContent: 'center' 
                      }}
                    >
                      <View style={{ marginBottom: 4 }}>
                        {DISPLAY_CONVERSATION_SIDEBAR_ICON(
                          action.key,
                          action.disabled ? Colors.FoldPixel.GRAY150 : Colors.Custom.Gray500,
                          16
                        )}
                      </View>
                      <Text 
                        isTruncated 
                        style={{ 
                          color: action.disabled ? Colors.FoldPixel.GRAY150 : Colors.FoldPixel.GRAY250, 
                          fontSize: 12, 
                          lineHeight: 14.4 
                        }}
                      >
                        {action.title}
                      </Text>
                    </View>
                  </Pressable>
                </Tooltip>
              </View>
              {index < array.length - 1 && getActionDividerLine(0)}
            </React.Fragment>
          ))}
          {/* {shouldShowChat() && ( */}
            <>
            {getActionDividerLine()}
              <FoldPermitCan
                resource={MAIN_MENU_CODES.INBOX}
                action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
                component={
                  <View style={{ alignItems: 'center', flex: 1 }}>
                    <Tooltip
                      title={
                        isPatientLoggedIn
                          ? !contactActiveStatus
                            ? getInActiveContactError(contactInfo)
                            : 'Chat with patient'
                          : patientNotLoggedInError
                      }
                    >
                      <Pressable
                        onPress={() => {
                          if (contactActiveStatus && isPatientLoggedIn) {
                            setSelectedView(
                              CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT
                            );
                          }
                        }}
                        style={{
                          alignItems: 'center',
                        }}
                      >
                        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <View style={{ marginBottom: 4 }}>
                            <ChatRoundActionSvg
                              width={16}
                              height={16}
                              isActive={isPatientLoggedIn}
                            />
                          </View>
                          <Text isTruncated style={{ color: isPatientLoggedIn ? Colors.FoldPixel.GRAY250 : Colors.FoldPixel.GRAY150, fontSize: 12, lineHeight: 14.4 }}>Chat</Text>
                        </View>
                      </Pressable>
                    </Tooltip>
                   </View>
                }
              />
              {getActionDividerLine()}
            </>
          {/* )} */}

          {/* {showPhoneCall && isCallAllowed && ( */}
            <>
              <FoldPermitCan
                resource={MAIN_MENU_CODES.CALL_LOGS}
                action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
                component={
                  <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                    <OutboundCallSmsView
                      showOnlyCallNumbers={true}
                      isHideSms={true}
                      visible={stateData.visible}
                      selectedContactData={contactInfo}
                      content={
                        <View height={'30px'} style={{ alignItems: 'center', flex: 1 }}>
                        <Tooltip
                          title={
                            !contactActiveStatus
                              ? getInActiveContactError(contactInfo)
                              : getToolTipMessage(
                                'CALL',
                                phoneNumber,
                                email,
                                props.contactInfo as IContact
                              )
                          }
                        >
                          <Pressable
                            onPress={() => {
                              if (contactActiveStatus) {
                                if (consentCheck) {
                                  if (phoneNumber) {
                                    setStateData((prev) => {
                                      return {
                                        ...prev,
                                        visible: true,
                                      };
                                    });
                                  } else {
                                    handleEdit?.();
                                  }
                                } else {
                                  handleEdit?.(
                                    COMMON_ACTION_CODES.CONSENT_WARNING
                                  );
                                }
                              } else {
                                const message =
                                  getInActiveContactError(contactInfo);
                                notification.error({
                                  message,
                                });
                              }
                            }}
                            style={{
                              alignItems: 'center',
                            }}
                          >
                            <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                              <View style={{ marginBottom: 4 }}>
                                <PhoneActionSvgV1
                                  width={16}
                                  height={16}
                                  isActive={phoneNumber ? true : false}
                                />
                              </View>
                              <Text isTruncated style={{ color: phoneNumber ? Colors.FoldPixel.GRAY250 : Colors.FoldPixel.GRAY150, fontSize: 12, lineHeight: 14.4 }}>Call</Text>
                            </View>
                          </Pressable>
                        </Tooltip>
                      </View>
                      }
                      handleAction={(actionCode: string, rawData?: any) => {
                        handleActions(actionCode, rawData);
                      }}
                      onClose={() => {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            visible: false,
                          };
                        });
                      }}
                    />
                  </View>
                }
              />
              {getActionDividerLine()}
            </>
          {/* )} */}

          <Popover
            overlayInnerStyle={{ padding: 0, borderRadius: 12 }}
            overlayStyle={{ padding: 0 }}
            overlay={{ padding: 0 }}
            style={{ padding: 0, borderRadius: 12 }}
            placement={'bottomLeft'}
            trigger="click"
            visible={stateData.moreActionPopup}
            onVisibleChange={handleMoreActionVisibleChange}
            content={
              <View style={{ width: 180, flex: 1 }}>
                {PATIENT_ACTION_LIST?.filter(action => !action.isMainAction && checkAllowedViews(action))?.map(
                  (tabItem: IConversationChannelList, index) => {
                    return (
                      <FoldPermitCan
                        key={tabItem.key}
                        resource={MAIN_MENU_CODES.INBOX}
                        action={
                          USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code
                        }
                        component={
                          canStartAction(tabItem.key) ? (
                            <Pressable
                              onPress={() => {
                                if (tabItem.key === QUICK_ACTION_CODES.CREATE_REFERRAL_ORDER && contactInfo?.id && props?.currentView == 'patientProfile') {
                                  navigateTo('REFERRAL_ORDER', contactInfo?.id?.toString());
                                } else {
                                  performAction(tabItem.key);
                                }
                              }}
                              style={{
                                padding: 8,
                                backgroundColor: 'white',
                                width: '100%'
                              }}
                              {...testID(tabItem.code || '')}
                            >
                              <HStack flex={1} alignItems="center">
                                <View
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    height: 16,
                                    width: 16,
                                    marginRight: 8
                                  }}
                                  {...testID('image')}
                                >
                                  {DISPLAY_CONVERSATION_SIDEBAR_ICON(
                                    tabItem?.key,
                                    tabItem?.disabled ? Colors.FoldPixel.GRAY150 : Colors.Custom.Gray500
                                  )}
                                </View>
                                <Text
                                  size={'smRegular'}
                                  flex={1}
                                  style={{
                                    color: tabItem?.disabled ? Colors.FoldPixel.GRAY150 : Colors.FoldPixel.GRAY400
                                  }}
                                  {...testID(tabItem.title || '')}
                                >
                                  {intl.formatMessage({ id: tabItem.title })}
                                </Text>
                              </HStack>
                            </Pressable>
                          ) : (
                            <></>
                          )
                        }
                      />
                    );
                  }
                )}
              </View>
            }
          >
            <Pressable
              onPress={() => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    moreActionPopup: true,
                  };
                });
              }}
              style={{ 
                marginRight: props?.shouldShowCloseButton ? 0 : 8,
                alignItems: 'center',
                justifyContent: 'center',
                height: 40,
                width: 32
              }}
              {...testID('MoreBtnClick')}
            >
              <Tooltip title={'More'}>
                <MenuHorizontalIcon width={20} height={20} />
              </Tooltip>
            </Pressable>
          </Popover>

          {props?.shouldShowCloseButton &&
            <Stack direction='row' style={{ flex: 1 }}>
              {getActionDividerLine()}
              <Pressable
                onPress={props.handleCloseClick}
                style={{ 
                  marginRight: 4, 
                  marginLeft: 4,
                  height: 44,
                  width: 32,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                {...testID('CloseBtnClick')}
              >
                <CrossIcon size={32} />
              </Pressable>
            </Stack>
          }

        {selectedView === RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW && (
            <MeetingView
              personData={formattedContactData}
              onPersonActionPerformed={(actionCode: any) => {
                if (actionCode == COMMON_ACTION_CODES.CANCEL) {
                  setSelectedView('');
                }
              }}
            />
          )}
          {selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
            <EmailDrawerCommonV2
              isOpen={
                selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP
              }
              onClose={() => {
                setSelectedView('');
              }}
              onEmailSent={({msgData}) => {
                setSelectedView('');
                handleOnCreateNewEmailConversation(msgData?.conversationId);
              }}
              contactIds={contactInfo?.id ? [contactInfo?.id] : []}
            />
          )}
          {selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
            <CreateSmsConversationDrawer
              isDrawerVisible={true}
              selectedInbox={{} as any}
              onCreateSmsConversationActionPerformed={(
                actionCode: string,
                actionData: any
              ) => {
                if (
                  actionCode == SMS_INBOX_ACTION_CODES.DRAWER_CLOSE &&
                  !actionData
                ) {
                  setSelectedView('');
                } else {
                  setSelectedView(
                    RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER
                  );
                  setStateData((prev) => {
                    return {
                      ...prev,
                      selectedConversation:
                        actionData?.selectedInboxConversationData
                          ?.conversations?.[0],
                    };
                  });
                }
              }}
              selectedPatient={contactInfo}
            />
          )}
          {selectedView === CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT &&
            contactInfo?.uuid &&
            contactInfo?.patient?.id && (
              <InstantChat
                contactData={contactInfo}
                contactUuid={contactInfo?.uuid}
                isDrawerVisible={
                  selectedView === CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT
                }
                onActionPerformed={(actionCode) => {
                  if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                    setSelectedView('');
                  }
                }}
              />
            )}

          {selectedView ===
            RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER && (
            <InstantChatView
              selectedConversation={stateData.selectedConversation}
              contactData={{} as any}
              isLoading={false}
              isDrawerVisible={true}
              isInstantChatView={true}
              instantChatFromNotifications={true}
              onActionPerformed={(actionCode: any, actionData: any) => {
                if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                  setSelectedView('');
                } else if (
                  actionCode === CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT
                ) {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      selectedConversation: actionData,
                    };
                  });
                }
              }}
            />
          )}

          {selectedView === RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK &&
          <AddOrUpdateTask
            assignee={getAssignee()}
            restrictPatientOrLeadSelectionTo={getNewTaskMember() as any}
            isVisible={selectedView === RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK}
            onComplete={(data: any) => {
              setSelectedView('');
            }}
            onCancel={() => {
              setSelectedView('');
            }}
            member={getDefaultMember()}
          />}

          {stateData.showAddRelativesDrawer && (
            <AddContactRelationView
              contactData={contactInfo?.patient}
              contactId={contactInfo?.id}
              onFormCompleteAction={(actionCode: string) => {
                if (actionCode === COMMON_ACTION_CODES.EDIT) {
                  setStateData(prev => ({...prev, showAddFamilyMembersDrawer: true}));
                } else {
                  setStateData(prev => ({...prev, showAddFamilyMembersDrawer: false, showAddRelativesDrawer: true}));
                }
              }}
              onRelativeAdded={() => {
                showToast(
                  toast,
                  intl.formatMessage({ id: 'relativeAddedSuccessfully' }),
                  ToastType.success,
                  3000
                );
              }}
              isSideCarContext={isSidecarContext}
            />
          )}
          {stateData.showAddFamilyMembersDrawer && (
            <AddOrUpdateLead
              isShowAddContactTypeOption={true}
              personTypeUuid={getContactTypeId('CUSTOMER')}
              personType={'CUSTOMER'}
              singleLeadData={undefined}
              onFormCompleteAction={() => {
                setStateData(prev => ({...prev, showAddFamilyMembersDrawer: false}));
              }}
            />
          )}
          {stateData.showResetPasswordModal && (
            <ResetPasswordModal
              isOpen={stateData.showResetPasswordModal}
              onClose={() => {
                setStateData(prev => ({ ...prev, showResetPasswordModal: false }));
              }}
              contactInfo={contactInfo}
              onConfirm={() => {
                props.sendResetPasswordInstructions?.(contactInfo);
                setStateData(prev => ({ ...prev, showResetPasswordModal: false }));
              }}
            />
          )}
          {stateData.showAppointmentBookingDrawer && (
            <AppointmentBooking
              defaultParticipants={[
                {
                  label: formattedContactData?.name || '',
                  key: formattedContactData?.uuid || '',
                  value: formattedContactData?.uuid || '',
                  type: ParticipantType.patient,
                },
              ]}
              isVisible={true}
              onComplete={() => {
                setStateData(prev => ({ ...prev, showAppointmentBookingDrawer: false }));
              }}
              onCancel={() => {
                setStateData(prev => ({ ...prev, showAppointmentBookingDrawer: false }));
              }}
            />
          )}
          {stateData.showAutomationDrawer && (
            <Drawer
              width={isSidecarContext ? '100%' : 700}
              visible={true}
              closeIcon={null}
              closable
              onClose={() => {
                setStateData(prev => ({ ...prev, showAutomationDrawer: false }));
              }}
              title={<></>}
              maskClosable={true}
            >
              <AutomationWorkflowEmployee
                type="patient"
                initData={{ selectedItems: [contactInfo] }}
                onModalClose={() => {
                  setStateData(prev => ({ ...prev, showAutomationDrawer: false }));
                }}
              />
            </Drawer>
          )}
          {stateData.showSendFormDrawer && (
            <ContactSendForm
              isVisible={stateData.showSendFormDrawer}
              assignmentData={{
                patientId: formattedContactData.patientUuid || '',
                contactId: formattedContactData.contactUUID || '',
                assignedById: userUUID,
                patientEmail: formattedContactData.email || '',
                patientFirstName: formattedContactData.firstName || '',
                patientContactUUID: formattedContactData.contactUUID || '',
                contactTypeCode: formattedContactData?.contactType?.code || '',
              }}
              onActionComplete={() => {
                setStateData(prev => ({ ...prev, showSendFormDrawer: false }));
              }}
              onClose={() => {
                setStateData(prev => ({ ...prev, showSendFormDrawer: false }));
              }}
              onCancelAction={() => {
                setStateData(prev => ({ ...prev, showSendFormDrawer: false }));
              }}
            />
          )}
          {stateData.showSendPatientEducationDrawer && (
            <SendEdicationContent
              typeNodes={typeNodes}
              contactId={contactInfo?.uuid || ''}
              onClose={() => {
                setStateData(prev => ({ ...prev, showSendPatientEducationDrawer: false }));
              }}
              isSidecarContext={isSidecarContext}
            />
          )}
          {stateData.showEditPatientDrawer && (
            <AddOrUpdateLead
              singleLeadData={{
                ...contactInfo,
                id: contactInfo?.id,
              }}
              onFormCompleteAction={(actionCode: string) => {
                if (actionCode === COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
                   showToast(
                     toast,
                     intl.formatMessage({id: 'patientDetailsUpdatedSuccessfully'}),
                     ToastType.success,
                     3000
                   );
                }
                setStateData(prev => ({ ...prev, showEditPatientDrawer: false }));
              }}
              personType={contactInfo?.contactType?.contactType?.code}
              personTypeUuid={contactInfo?.contactType?.contactType?.id}
            />
          )}
          {stateData.showPrintClinicalProfileDrawer &&
            <PrintContactClinicalProfileSectionSelector
              formattedContactData={formattedContactData}
              isOpen={stateData.showPrintClinicalProfileDrawer}
              onClose={() => {
                setStateData(prev => ({ ...prev, showPrintClinicalProfileDrawer: false }));
              }}
              isSidecarContext={isSidecarContext}
            />
          }

          {stateData.showDayOptimizerDrawer && (
            <Drawer
              width={isSidecarContext ? '100%' : '50%'}
              visible={true}
              closeIcon={null}
              closable
              onClose={() => {
                setStateData(prev => ({ ...prev, showDayOptimizerDrawer: false }));
              }}
              title={
                <ModalActionTitle
                title={'Day Optimizer'}
                buttonList={[
                  {
                    show: true,
                    id: 1,
                    btnText: 'cancel',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: () => {
                      setStateData(prev => ({ ...prev, showDayOptimizerDrawer: false }));
                    },
                  }
                ]}
              />
              }
              maskClosable={true}
            >
            <DayOptimizer />
            </Drawer>
          )}
        </HStack>
      </HStack>
    </View>
  );
};

export const SidecarPatientProfileActions = withMiniContactViewHOC(
  SideCarPatientProfileActionsComponent
);