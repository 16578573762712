import React, { useEffect, useState } from 'react';
import {useIntl} from 'react-intl';
import {ICarePlanTemplate, ICarePlanTemplatesProps} from '../interfaces';
import {
  Badge,
  HStack,
  View,
  Text,
  Pressable,
  Spinner,
  Icon,
  Divider,
  Box,
  FlatList,
  VStack,
} from 'native-base';
import {Colors} from '../../../../../styles';
import Stack from '../../../../common/LayoutComponents/Stack';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { cloneDeep } from 'lodash';
import { Input } from 'antd';
import './CarePlanTemplates.css'
import FoldButtonV2, { ButtonType } from './FoldButtonV2';
import { testID } from '../../../../../testUtils';
import ClipboardWithContentInCenter from '../../../../common/Svg/ClipboardWithContentInCenter';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { StyleSheet } from 'react-native';
const SHOW_MORE_SIZE = 10;

const CarePlanTemplatesView = (props: ICarePlanTemplatesProps) => {
  const intl = useIntl();
  const [templateList, setTemplateList] = useState<ICarePlanTemplate[]>([]);
  const [filteredTemplateList, setFilteredTemplateList] = useState<ICarePlanTemplate[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(true);

  const {
    selectedTemplateIds,
    loadingTemplateIds,
    onCheckTemplate,
  } = props;

  useEffect(() => {
    const sortedTemplateList = props.templateList?.sort(
      (templateA, templateB) => {
        return (
          selectedTemplateIds.indexOf(templateB.id) -
          selectedTemplateIds.indexOf(templateA.id)
        );
      }
    );

    setFilteredTemplateList(sortedTemplateList);
  }, [props.templateList?.length]);

  useEffect(() => {
    if (filteredTemplateList?.length > SHOW_MORE_SIZE) {
      updateTemplateListByShowMoreVisibility(false);
    }
    else {
      setTemplateList(filteredTemplateList)
    }
  }, [filteredTemplateList?.length]);

  const updateTemplateListByShowMoreVisibility = (isShowMore: boolean) => {
    let updatedTemplateList: ICarePlanTemplate[] = [];
    if (isShowMore) {
      updatedTemplateList = cloneDeep(filteredTemplateList);
    } else {
      updatedTemplateList = cloneDeep(filteredTemplateList.slice(0, SHOW_MORE_SIZE));
    }
    setTemplateList(updatedTemplateList);
  };

  const onSearchTextChange = (searchText: string) => {
    setSearchText(searchText);
    const filteredTemplateList = props?.templateList?.filter((template) => {
      if (!searchText) {
        return true;
      }
      return template.name
        ?.toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase());
    });
    setFilteredTemplateList(filteredTemplateList);
  }

  return (
    <View
      borderBottomWidth={1}
      borderColor={Colors.Custom.Gray200}
      height={'350px'}
    >
      <HStack
        style={styles.container}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mt={1}
      >
        <DisplayText
          textLocalId={'selectCarePlanTemplates'}
          extraStyles={{
            fontSize: 14,
          }}
        />
        <HStack alignItems={'center'}>
          {!isSearchBarOpen && (
            <>
              <Pressable
                onPress={() => {
                  onSearchTextChange('');
                  setIsSearchBarOpen(true);
                }}
                {...testID('CarePlanTemplateSearchInputClearButton')}
                key={'CarePlanTemplateSearchInputClearButton123'}
              >
                <Icon
                  size={'5'}
                  color={'gray.400'}
                  as={<AntIcon name="search1" />}
                />
              </Pressable>
              <View
                key={'VerticalDividerSearchAndDone'}
                backgroundColor={Colors.Custom.Gray500}
                width={0.2}
                height={6}
                marginX={2}
              />
            </>
          )}
          <FoldButtonV2
            label={intl.formatMessage({id: 'done'})}
            buttonType={ButtonType.primary}            
            onPress={props?.onSubmitTemplates}
            {...testID('CarePlanTemplateSubmitButton')}
            isDisabled={selectedTemplateIds?.length === 0}
          />
        </HStack>
      </HStack>
      <VStack>
        {isSearchBarOpen && <Divider key={'CarePlanTemplateSearchInputDivider'} />}
        {isSearchBarOpen && (
          <Box mt={2} mx={2} key={'CarePlanTemplateSearchInput'}>
            <Input
              style={antdStyles.searchInput}
              onChange={(event)=> {
                onSearchTextChange(event?.target?.value)
              }}
              value={searchText}
              placeholder='Search Template'
              prefix={
                <Icon
                  size={'4'}
                  color={'gray.400'}
                  as={<AntIcon name="search1" />}
                />
              }
              suffix={(
                <Pressable
                  onPress={() => {
                    onSearchTextChange('');
                    setIsSearchBarOpen(false);
                  }}
                  {...testID('CarePlanTemplateSearchInputClearButton')}
                >
                  <Icon
                    size={'4'}
                    color={'gray.400'}
                    as={<AntIcon name="closecircleo" />}
                  />
                </Pressable>
              )}
              {...testID('CarePlanTemplateSearchInput')}
            />
          </Box>
        )}
        <Divider mt={2} />
        {selectedTemplateIds?.length ?
          <>
            <HStack mx={2} pt={2} pb={1} width={'100%'} flexWrap={'wrap'}>
              {selectedTemplateIds.map((selectedTemplateId) => {
                const selectedTemplate = props?.templateList?.find((template) => template?.id === selectedTemplateId);
                return (
                  selectedTemplate?.name &&
                  <Badge
                    flexDir={'row'}
                    key={selectedTemplate?.id}
                    borderRadius={4}
                    borderWidth={1}
                    borderColor={Colors.Custom.Gray200}
                    paddingY={2}
                    paddingX={2}
                    backgroundColor={Colors.FoldPixel.PRIMARY100}
                    mr={1}
                    mb={1}
                  >
                    <Box mr={1}>
                      <ClipboardWithContentInCenter customStrokeColor={Colors.Custom.Primary300} />
                    </Box>
                    <Text
                      color={Colors.Custom.Primary300}
                    >
                      {selectedTemplate?.name}
                    </Text>
                  </Badge>
                )
              })}
            </HStack>
            <Divider />
          </> :
          <></>
        }
      </VStack>
      <Text mx={2} my={1}>{intl.formatMessage({id: 'templates'})}</Text>
      <Divider />
      <Stack
        direction="column"
        style={styles.container2}
      >
        <FlatList
          height={'100%'}
          data={templateList}
          renderItem={({item: template}) => {
            const isSelected = selectedTemplateIds.includes(template.id);
            const isLoading = loadingTemplateIds.includes(template.id);

            const textColor = isSelected ?
              Colors.Custom.Primary300 :
              Colors.Custom.Gray500;

            return (
              <Pressable
                _hover={{
                  backgroundColor: Colors.Custom.Gray100
                }}
                borderRadius={4}
                backgroundColor={isSelected ? Colors.Custom.Primary50 : Colors.Custom.White}
                key={template.id}
                disabled={isLoading || isSelected || !!loadingTemplateIds.length}
                onPress={() => {
                  onCheckTemplate(template.id);
                }}
                my={0.5}
                {...testID('SingleCarePlanTemplate')}
              >
                <Box
                  flexDir={'row'}
                  key={template.id}
                  paddingY={1}
                  paddingX={2}
                  alignItems={'center'}
                >
                  <Box mr={1}>
                    <ClipboardWithContentInCenter customStrokeColor={textColor} />
                  </Box>
                  <Text color={textColor} {...testID(template?.name)}>
                    {template.name}
                  </Text>
                  {isLoading && <Spinner ml={0.5} size={'sm'} />}
                </Box>
              </Pressable>
            );
          }}
          keyExtractor={() => {
            return 'CarePlanTemplateList_' + Math.random();
          }}
          onEndReached={() => {
            updateTemplateListByShowMoreVisibility(true);
          }}
        />
        {templateList?.length === 0 ? <Text color={Colors.Custom.Gray300} marginTop={1}>{intl.formatMessage({id: 'noTemplateFound'})}</Text> : <></>}
      </Stack>
    </View>
  );
};

export default CarePlanTemplatesView;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 4,
    paddingVertical: 8,
    marginHorizontal: 4
  },
  container2: {
    flex: 1,
    flexWrap: 'wrap',
    paddingHorizontal: 4,
    paddingBottom: 12,
    paddingTop: 4,
    overflow: 'hidden',
    height: '100%',
    marginHorizontal: 4
  }
});

const antdStyles: Record<string, React.CSSProperties> = {
  searchInput: {
    borderRadius: 4,
    width: '100%',
    height: 32,
    color: Colors.Custom.Gray500,
    borderColor: Colors.Custom.Gray300,
    paddingRight: 6,
  }
}