import { gql } from '@apollo/client';

export const GET_APPOINTMENT_TYPES = gql`
  query GetAppointmentTypes($searchString: String!, $categoryCodes: [String!]) {
    appointmentTypes(
      where: {
        isDeleted: {_eq: false}
        eventName: {_ilike: $searchString}
        category: {code: {_in: $categoryCodes}}
      }
      order_by: {updatedOn: desc_nulls_last}
    ) {
      duration
      description
      eventName
      id
      endDate
      beginDate
      locationGroupId
      locationTypeId
      isShownToPatient
      appointmentCardProperties {
        bgColorPrimary
        bgColorSecondary
        bgColorPrimaryDisabled
        bgColorSecondaryDisabled
        id
      }
      isRsvpEnabled
      categoryId
      category {
        code
        value
      }
      locationType {
        value
        code
      }
      isPatientFacingLocationType
      tasks(where: {isDeleted: {_eq: false}}) {
        id
        title
        isCompleted
      }
      appointmentTypeGroup(
        order_by: {sequence: asc}
        where: {isDeleted: {_eq: false}}
      ) {
        duration
        userId
        roleId
        accountRoleId
        id
      }
      customFields(where: {isDeleted: {_eq: false}}) {
        id
        fieldTypeId
        isDeleted
        subjectId
        subjectTypeCode
        value
        valueDataTypeCode
      }
      shortName
      visitTypeId
      visitType {
        code
        value
      }
      appointmentTypeAvailability(where: {isDeleted: {_eq: false}}) {
        daysOfWeek
        startTime
        endTime
        id
        appointmentTypeId
        locationId
        isVirtualLocation
        typeId
        timezoneId
      }
      availabilityTypeCode
      restrictedUserPool {
       userPoolUsers(where: {isDeleted: {_eq: false}}) {
          isDefault
          id
          isDeleted
          userId
        }
        typeId
        name
        id
      }
      userPool {
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          isDefault
          id
          isDeleted
          userId
        }
        typeId
        name
        id
      }
      memberLimit
      appointmentTypeEmployer(where: {isDeleted: {_eq: false}}) {
        id
        employerId
      }
      appointmentTypePopulationGroup(where: {isDeleted: {_eq: false}}) {
        id
        groupId
      }
    }
  }
`;


export const GET_APPOINTMENT_TYPES_BY_LOCATION_GROUP = gql`
 query GetAppointmentTypesByLocationGroup($searchString: String!, $categoryCodes: [String!], $locationGroupIds: [uuid!]) {
    appointmentTypes(
      where: {
        isDeleted: {_eq: false}
        eventName: {_ilike: $searchString}
        category: {code: {_in: $categoryCodes}}
        locationGroupId: {
          _in: $locationGroupIds
        }
      }
      order_by: {updatedOn: desc_nulls_last}
    ) {
      duration
      description
      eventName
      id
      endDate
      beginDate
      locationGroupId
      locationTypeId
      isShownToPatient
      appointmentCardProperties {
        bgColorPrimary
        bgColorSecondary
        bgColorPrimaryDisabled
        bgColorSecondaryDisabled
        id
      }
      isRsvpEnabled
      categoryId
      category {
        code
        value
      }
      locationType {
        value
        code
      }
      isPatientFacingLocationType
      tasks(where: {isDeleted: {_eq: false}}) {
        id
        title
        isCompleted
      }
      appointmentTypeGroup(
        order_by: {sequence: asc}
        where: {isDeleted: {_eq: false}}
      ) {
        duration
        userId
        roleId
        accountRoleId
        id
      }
      customFields(where: {isDeleted: {_eq: false}}) {
        id
        fieldTypeId
        isDeleted
        subjectId
        subjectTypeCode
        value
        valueDataTypeCode
      }
      shortName
      visitTypeId
      visitType {
        code
        value
      }
      appointmentTypeAvailability(where: {isDeleted: {_eq: false}}) {
        daysOfWeek
        startTime
        endTime
        id
        appointmentTypeId
        locationId
        isVirtualLocation
        typeId
        timezoneId
      }
      availabilityTypeCode
      restrictedUserPool {
       userPoolUsers(where: {isDeleted: {_eq: false}}) {
          isDefault
          id
          isDeleted
          userId
        }
        typeId
        name
        id
      }
      userPool {
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          isDefault
          id
          isDeleted
          userId
        }
        typeId
        name
        id
      }
      memberLimit
      appointmentTypeEmployer(where: {isDeleted: {_eq: false}}) {
        id
        employerId
      }
      appointmentTypePopulationGroup(where: {isDeleted: {_eq: false}}) {
        id
        groupId
      }
    }
  }
`;

export const GET_PATIENT_FACING_APPOINTMENT_TYPES = gql`
  query GetAppointmentTypes($searchString: String!, $categoryCodes: [String!]) {
    appointmentTypes(
      where: {
        isDeleted: {_eq: false}
        eventName: {_ilike: $searchString}
        category: {code: {_in: $categoryCodes}}
        isShownToPatient: {_eq: true}
      }
      order_by: {createdOn: asc}
    ) {
      duration
      description
      eventName
      id
      endDate
      beginDate
      locationTypeId
      isShownToPatient
      isRsvpEnabled
      bookWithinDays
      categoryId
      locationType {
        value
        code
      }
      tasks(where: {isDeleted: {_eq: false}}) {
        id
        title
        isCompleted
      }
      isPatientFacingLocationType
      appointmentTypeGroup(
        order_by: {sequence: asc}
        where: {isDeleted: {_eq: false}}
      ) {
        duration
        userId
        roleId
        id
      }
    }
  }
`;

export const ADD_APPOINTMENT_TYPE = gql`
  mutation AddAppointmentType($data: appointment_type_insert_input!) {
    createAppointmentType(object: $data) {
      id
    }
  }
`;

export const UPDATE_APPOINTMENT_TYPE = gql`
  mutation UpdateAppointmentType(
    $id: uuid!
    $data: appointment_type_set_input!
    $group: [appointment_type_group_insert_input!]!
  ) {
    updateAppointmentType(pk_columns: {id: $id}, _set: $data, _inc: {}) {
      id
    }
    createAppointmentTypeGroups(
      objects: $group
      on_conflict: {
        constraint: appointment_type_group_pkey
        update_columns: [
          userId
          duration
          appointmentTypeId
          isDeleted
          sequence
          roleId
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_APPOINTMENT_TYPE = gql`
  mutation DeleteAppointmentType($id: uuid!) {
    updateAppointmentType(pk_columns: {id: $id}, _set: {isDeleted: true}) {
      isDeleted
    }
  }
`;

export const GET_DEFAULT_APPOINTMENT_TYPE = gql`
  query GetAppointmentTypes {
    appointmentTypes(
      where: {
        isDeleted: {_eq: false}
        isShownToPatient: {_eq: true}
        category: {code: {_in: "ONE"}}
        isDefault: {_eq: true}
      }
      limit: 1
      order_by: {createdOn: asc}
    ) {
      duration
      description
      eventName
      id
      endDate
      beginDate
      locationTypeId
      bookWithinDays
      isRsvpEnabled
      locationGroupId
      tasks(where: {isDeleted: {_eq: false}}) {
        id
        title
        isCompleted
      }
      locationType {
        value
        code
      }
      isPatientFacingLocationType
      appointmentTypeGroup(
        order_by: {sequence: asc}
        where: {isDeleted: {_eq: false}}
      ) {
        duration
        userId
        roleId
        id
      }
      customFields(where: {isDeleted: {_eq: false}}) {
        id
        fieldTypeId
        isDeleted
        subjectId
        subjectTypeCode
        value
        valueDataTypeCode
      }
      availabilityTypeCode
      userPool {
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          isDefault
          id
          isDeleted
          userId
        }
        typeId
        name
        id
      }
    }
  }
`;

export const GET_PATIENT_FACING_APPOINTMENT_TYPE_BY_ROLE_ID = gql`
  query GetAppointmentTypes($roleId: uuid!) {
    appointmentTypes(
      where: {
        appointmentTypeGroup: {roleId: {_in: [$roleId]}}
        isDeleted: {_eq: false}
        isShownToPatient: {_eq: true}
        category: {code: {_in: "ONE"}}
      }
      order_by: {createdOn: asc}
    ) {
      duration
      description
      eventName
      id
      endDate
      beginDate
      locationTypeId
      bookWithinDays
      isRsvpEnabled
      locationGroupId
      tasks(where: {isDeleted: {_eq: false}}) {
        id
        title
        isCompleted
      }
      locationType {
        value
        code
      }
      isPatientFacingLocationType
      appointmentTypeGroup(
        order_by: {sequence: asc}
        where: {isDeleted: {_eq: false}, roleId: {_in: [$roleId]}}
      ) {
        duration
        userId
        roleId
        id
      }
      customFields(where: {isDeleted: {_eq: false}}) {
        id
        fieldTypeId
        isDeleted
        subjectId
        subjectTypeCode
        value
        valueDataTypeCode
      }
      availabilityTypeCode
      userPool {
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          isDefault
          id
          isDeleted
          userId
        }
        typeId
        name
        id
      }
    }
  }
`;

export const GET_PATIENT_FACING_APPOINTMENT_TYPE_BY_APPOINTMENT_TYPE_ID = gql`
  query GetAppointmentTypes($id: uuid!) {
    appointmentTypes(
      where: {
        id: {_eq: $id}
        isDeleted: {_eq: false}
        isShownToPatient: {_eq: true}
        category: {code: {_in: "ONE"}}
      }
      order_by: {createdOn: asc}
    ) {
      duration
      description
      eventName
      id
      endDate
      beginDate
      locationTypeId
      bookWithinDays
      isRsvpEnabled
      locationGroupId
      tasks(where: {isDeleted: {_eq: false}}) {
        id
        title
        isCompleted
      }
      locationType {
        value
        code
      }
      isPatientFacingLocationType
      customFields(where: {isDeleted: {_eq: false}}) {
        id
        fieldTypeId
        isDeleted
        subjectId
        subjectTypeCode
        value
        valueDataTypeCode
      }
      appointmentTypeGroup(
        order_by: {sequence: asc}
        where: {isDeleted: {_eq: false}}
      ) {
        duration
        userId
        roleId
        id
      }
      availabilityTypeCode
      userPool {
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          isDefault
          id
          isDeleted
          userId
        }
        typeId
        name
        id
      }
    }
  }
`;

export const GET_APPOINTMENT_TYPES_BY_IDS = gql`
  query GetAppointmentTypesByIds($ids: [uuid!]) {
    appointmentTypes(
      where: {isDeleted: {_eq: false}, id: {_in: $ids}}
      order_by: {createdOn: asc}
    ) {
      id
      appointmentTypeGroup(
        order_by: {sequence: asc}
        where: {isDeleted: {_eq: false}}
      ) {
        roleId
        id
      }
    }
  }
`;

export const GET_ALL_PATIENT_FACING_APPOINTMENT_TYPES = gql`
  query GetAppointmentTypes($searchString: String!, $categoryCodes: [String!]) {
    appointmentTypes(
      where: {
        isDeleted: {_eq: false}
        eventName: {_ilike: $searchString}
        isShownToPatient: {_eq: true}
        category: {code: {_in: $categoryCodes}}
      }
      order_by: {createdOn: asc}
    ) {
      duration
      description
      eventName
      id
      endDate
      beginDate
      locationTypeId
      isShownToPatient
      isRsvpEnabled
      bookWithinDays
      categoryId
      locationGroupId
      tasks(where: {isDeleted: {_eq: false}}) {
        id
        title
        isCompleted
      }
      locationType {
        value
        code
      }
      appointmentTypeGroup(
        order_by: {sequence: asc}
        where: {isDeleted: {_eq: false}}
      ) {
        duration
        userId
        roleId
        accountRoleId
        id
      }
      availabilityTypeCode
      restrictedUserPool {
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
           isDefault
           id
           isDeleted
           userId
         }
         typeId
         name
         id
       }

      userPool {
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          isDefault
          id
          isDeleted
          userId
        }
        typeId
        name
        id
      }
    }
  }
`;

export const GET_ALL_APPOINTMENT_TYPES = gql`
  query GetAllAppointmentTypes($searchString: String!, $categoryCodes: [String!]) {
    appointmentTypes(
      where: {
        isDeleted: {_eq: false}
        eventName: {_ilike: $searchString}
        category: {code: {_in: $categoryCodes}}
      }
      order_by: {createdOn: asc}
    ) {
      duration
      description
      eventName
      id
      endDate
      beginDate
      locationTypeId
      isShownToPatient
      isRsvpEnabled
      bookWithinDays
      categoryId
      locationGroupId
      tasks(where: {isDeleted: {_eq: false}}) {
        id
        title
        isCompleted
      }
      locationType {
        value
        code
      }
      appointmentTypeGroup(
        order_by: {sequence: asc}
        where: {isDeleted: {_eq: false}}
      ) {
        duration
        userId
        roleId
        accountRoleId
        id
      }
      availabilityTypeCode
      userPool {
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          isDefault
          id
          isDeleted
          userId
        }
        typeId
        name
        id
      }
    }
  }
`;

export const UPDATE_APPOINTMENT_TYPE_TASKS = gql`
  mutation updateAppointmentTypeTasks($params: [appointment_tasks_insert_input!]!) {
    createAppointmentTasks(objects: $params, on_conflict: {
      constraint: appointment_tasks_pkey,
      update_columns: [title, isDeleted]
    }) {
      returning {
        id
        title
        isCompleted
        isDeleted
      }
    }
  }
`;


export const GET_APPOINTMENT_SHORTNAME_COUNT = gql`
  query getAppointmentTypeAggregateByName($searchString: String!) {
    aggregateAppointmentTypes(where: {shortName: {_eq: $searchString}, isDeleted: {_eq: false}}) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_APPOINTMENT_TYPE_NAME_COUNT = gql`
  query getAppointmentTypeAggregateByName($searchString: String!) {
    aggregateAppointmentTypes(where: {eventName: {_eq: $searchString}, isDeleted: {_eq: false}}) {
      aggregate {
        count
      }
    }
  }
`;

export const ADD_OR_UPDATE_APPOINTMENT_TYPE = gql`
  mutation addOrUpdateAppointmentType($data: AppointmentTypeInput!) {
    addOrUpdateAppointmentType(params: $data) {
      id
    }
  }
`;

export const GET_APPOINTMENT_TYPES_BY_RESTRICTED_USERS = gql`
  query GetAppointmentTypes($whereCondition: appointment_type_bool_exp) {
    appointmentTypes(
      where: $whereCondition
      order_by: {createdOn: asc}
    ) {
      eventName
    }
  }
`;

export const GET_ONE_TO_ONE_AND_GROUP_SESSION_APPOINTMENT_TYPES = gql`
  query GetAppointmentTypes($searchString: String!, $categoryCodes: [String!]) {
    appointmentTypes(
      where: {
        isDeleted: {_eq: false}
        eventName: {_ilike: $searchString},
        category: {code: {_in: $categoryCodes}}
      }
      order_by: {createdOn: asc}
    ) {
      duration
      description
      eventName
      id
      endDate
      beginDate
      locationTypeId
      isShownToPatient
      isRsvpEnabled
      bookWithinDays
      categoryId
      availabilityTypeCode
      locationGroupId
    }
  }
`;

export const GET_APPOINTMENT_TYPES_INCLUDING_DELETED = gql`
  query GetAppointmentTypes($appointmentTypeIds: [uuid!]) {
    appointmentTypes(
      where: {id: {_in: $appointmentTypeIds}}
    ) {
      eventName
      id
    }
  }
`;

export const GET_APPOINTMENT_TYPES_BASIC_DETAILS = gql`
  query GetAppointmentTypesBasicDetails {
    appointmentTypes(order_by: {eventName: asc}, where: {isDeleted: {_eq: false}}) {
      eventName
      id
    }
  }
`;

export const GET_APPOINTMENT_TYPES_BY_VISIT_TYPE = gql`
  query GetAppointmentTypes(
    $searchString: String!
    $categoryCodes: [String!]
    $visitType: String!
    $locationType: String!
  ) {
    appointmentTypes(
      where: {
        isDeleted: {_eq: false}
        eventName: {_ilike: $searchString}
        category: {code: {_in: $categoryCodes}}
        visitType: {code: {_eq: $visitType}}
        locationType: {
          code: {_in: [$locationType]}
        }
      }
      order_by: {updatedOn: desc_nulls_last}
    ) {
      duration
      description
      eventName
      id
      endDate
      beginDate
      locationGroupId
      locationTypeId
      isShownToPatient
      appointmentCardProperties {
        bgColorPrimary
        bgColorSecondary
        bgColorPrimaryDisabled
        bgColorSecondaryDisabled
        id
      }
      isRsvpEnabled
      categoryId
      category {
        code
        value
      }
      locationType {
        value
        code
      }
      isPatientFacingLocationType
      tasks(where: {isDeleted: {_eq: false}}) {
        id
        title
        isCompleted
      }
      appointmentTypeGroup(
        order_by: {sequence: asc}
        where: {isDeleted: {_eq: false}}
      ) {
        duration
        userId
        roleId
        accountRoleId
        id
      }
      customFields(where: {isDeleted: {_eq: false}}) {
        id
        fieldTypeId
        isDeleted
        subjectId
        subjectTypeCode
        value
        valueDataTypeCode
      }
      shortName
      visitTypeId
      visitType {
        code
        value
      }
      appointmentTypeAvailability(where: {isDeleted: {_eq: false}}) {
        daysOfWeek
        startTime
        endTime
        id
        appointmentTypeId
        locationId
        isVirtualLocation
        typeId
        timezoneId
      }
      availabilityTypeCode
      restrictedUserPool {
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          isDefault
          id
          isDeleted
          userId
        }
        typeId
        name
        id
      }
      userPool {
        userPoolUsers(where: {isDeleted: {_eq: false}}) {
          isDefault
          id
          isDeleted
          userId
        }
        typeId
        name
        id
      }
      memberLimit
      appointmentTypeEmployer(where: {isDeleted: {_eq: false}}) {
        id
        employerId
      }
      appointmentTypePopulationGroup(where: {isDeleted: {_eq: false}}) {
        id
        groupId
      }
    }
  }
`;

export const GET_APPOINTMENT_TYPES_BY_ID = gql`
query GetAppointmentTypesById($id: uuid!) {
  appointmentType(
   id: $id
  ) {
    id
    isPatientFacingLocationType
    locationTypeId
    locationType {
      code
    }
  }
}
`;


export default {
  ADD_APPOINTMENT_TYPE,
  GET_APPOINTMENT_TYPES,
  UPDATE_APPOINTMENT_TYPE,
  DELETE_APPOINTMENT_TYPE,
  GET_DEFAULT_APPOINTMENT_TYPE,
  GET_APPOINTMENT_TYPES_BY_IDS,
  GET_APPOINTMENT_TYPES_BY_ID,
  GET_PATIENT_FACING_APPOINTMENT_TYPE_BY_ROLE_ID,
  GET_PATIENT_FACING_APPOINTMENT_TYPE_BY_APPOINTMENT_TYPE_ID,
  GET_PATIENT_FACING_APPOINTMENT_TYPES,
  GET_ALL_PATIENT_FACING_APPOINTMENT_TYPES,
  GET_ALL_APPOINTMENT_TYPES,
  UPDATE_APPOINTMENT_TYPE_TASKS,
  GET_APPOINTMENT_SHORTNAME_COUNT,
  ADD_OR_UPDATE_APPOINTMENT_TYPE,
  GET_APPOINTMENT_TYPE_NAME_COUNT,
  GET_APPOINTMENT_TYPES_BY_RESTRICTED_USERS,
  GET_ONE_TO_ONE_AND_GROUP_SESSION_APPOINTMENT_TYPES,
  GET_APPOINTMENT_TYPES_INCLUDING_DELETED,
  GET_APPOINTMENT_TYPES_BASIC_DETAILS,
  GET_APPOINTMENT_TYPES_BY_LOCATION_GROUP,
  GET_APPOINTMENT_TYPES_BY_VISIT_TYPE,
};
