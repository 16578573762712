import React, {useCallback, useState} from 'react';
import Feather from 'react-native-vector-icons/Feather';
import {AddButtonAction, IContactCareProgramStep} from '../interface';
import {Text, View} from 'native-base';
import {CaretDownOutlined, CaretRightOutlined, CaretUpOutlined} from '@ant-design/icons';
import {canShowAddButtonWithAction} from './ContactCareProgramView.utils';
import {Colors} from '../../../../styles';
import SideBarAddActionView from './components/SideBarAddActionView';
import {usePersonOmniViewContext} from '../../../PersonOmniView/PersonOmniView.context';
import {useContactCareProgramContext} from './ContactCareProgram.context';
import {testID} from '../../../../testUtils';
import {useAddStepAction} from './MainContentView/components/hooks/useAddStepAction';
import {ContactCareProgramReducerAction} from './reducer';
import { CARE_PROGRAM_STEP_TYPE } from '../../../common/MemebersView/constant';
import { ATTRIBUTE_KEYS } from '../../CareManagementBilling/CareManagementConstants';

const SideBarCollapsibleStep = (props: {
  subStepsArray: IContactCareProgramStep[];
  onClick: () => void;
  isExpanded: boolean;
  id: string;
  title: string;
  step: IContactCareProgramStep;
}) => {
  const {subStepsArray, onClick, isExpanded, id, title, step} = props;

  const isOutreachParentStep = step.careProgramStepAdditionalAttributes?.some(
    attr =>
      attr.attributeKey === 'stepType' &&
      (attr.attributeValue?.valueString === CARE_PROGRAM_STEP_TYPE.OUTREACH ||
       attr.attributeValue?.valueString === CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY)
  );

  const minSubStepsAttr = step.careProgramStepAdditionalAttributes?.find(
    attr => attr.attributeKey === ATTRIBUTE_KEYS.MIN_SUB_STEPS_ATTR_KEY
  );

  const isRequired = (minSubStepsAttr?.attributeValue?.valueNumeric ?? 0) >= 1;
  const shouldShowRequiredIndicator = isOutreachParentStep && isRequired;
  const {formattedData: {accountLocationUuid: contactAccountLocationId, contactUUID}} = usePersonOmniViewContext();
  const {
    state,
    dispatch,
    refetchMasterData,
    scrollContentViewToTop,
  } = useContactCareProgramContext();
  const {
    addButtonAction,
    addActionView,
    onAddButtonClick,
    onAddComplete,
    onCancel,
  } = useAddStepAction({
    isExpanded,
    onClick,
    refetchMasterData,
    parentStep: step,
    careProgramTypeId: state?.contactCareProgramDetails?.payerCareProgram
      ?.careProgramTypeId as string,
    contactId: contactUUID as string,
    scrollContentViewToTop,
    onAddDummySubStep: (
      step: IContactCareProgramStep,
      shouldAutoSelect?: boolean
    ) => {
      dispatch?.({
        type: ContactCareProgramReducerAction.ADD_SUB_STEP,
        payload: step,
      });
      if (shouldAutoSelect) {
        dispatch?.({
          type: ContactCareProgramReducerAction.SET_SELECTED_STEP_ID,
          payload: step.id,
        });
      }
    },
    selectStep: (stepId: string) => {
      dispatch?.({
        type: ContactCareProgramReducerAction.SET_SELECTED_STEP_ID,
        payload: stepId,
      });
    },
  });

  const hideCarrotIcon = step.subSteps?.length === 0;

  return (
    <>
      <div
        className="sidebar-item-collapsible"
        onClick={onClick}
        {...testID(`sidebar-item-${title}`)}
      >
        <CaretRightOutlined
          style={{fontSize: 12, marginRight: 8, ...(hideCarrotIcon && {color: Colors.Custom.White})}}
          className={isExpanded ? 'rotate-90-cw' : ''}
        />
        <span>{title}</span>
        {shouldShowRequiredIndicator && (
          <Text color={Colors.Custom.AppointmentStatusRed} fontSize={18} marginRight={2}> •</Text>
        )}
        <div className="spacer" />
        {addButtonAction && (
          <div className="hover-pointer">
            <Feather
              name="plus"
              size={16}
              color={Colors.FoldPixel.GRAY200}
              onPress={() => onAddButtonClick(addButtonAction)}
              {...testID(`add-button-${title}`)}
            />
          </div>
        )}
      </div>
      {addActionView && (
        <SideBarAddActionView
          contactAccountLocationId={contactAccountLocationId}
          actionCode={addActionView}
          onCancel={onCancel}
          onAddComplete={onAddComplete}
          step={step}
        />
      )}
    </>
  );
};

export default React.memo(SideBarCollapsibleStep);
