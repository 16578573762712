import {useLazyQuery} from '@apollo/client';
import {notification, Tooltip} from 'antd';
import moment from 'moment';
import {Button, Divider, HStack, Modal, Pressable, Skeleton, Text, useToast, View, VStack} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {BUTTON_TYPE, CONSENT_ERROR, DATE_FORMATS, getInActiveContactError, MLOV_CATEGORY} from '../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {CloudTelephonyQueries, LeadQueries} from '../../../../services';
import {
  getCustomEHRAttributes,
  getLanguages,
  getPatientDetails
} from '../../../../services/CommonService/AidBoxService';
import EmployeeQueries from '../../../../services/Employee/EmployeeQueries';
import {Colors} from '../../../../styles';
import {
  getBirthSexLabel,
  getGenderIdentityLabel,
  getResourceAbilities,
  getSexAtBirthTransformValues
} from '../../../../utils/capabilityUtils';
import {
  getAccountId,
  getGenderFullForm, getSecondaryValue,
  getSourceValue,
  isActiveContact,
  isConsentRequiredAndGiven,
  isContactVerified,
  isEmployerRole,
  isSecondaryContactVerified,
  numericStringMask
} from '../../../../utils/commonUtils';
import {showToast,ToastType} from '../../../../utils/commonViewUtils';
import {getDateStrFromMomentObj} from '../../../../utils/DateUtils';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {capitalizeText} from '../../../common/ContactRelationView/ContactRelationUtils';
import {ContactVerified} from '../../../common/ContactVerfied/ContactVerified';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {
  getFormattedLanguageArray,
  getLanguageFromCode
} from '../../../common/LanguageOptions/LanguageOptions';
import OutboundCallSmsView from '../../../common/OutboundCallSmsView/OutboundCallSmsView';
import BasicInfo from '../../../common/Svg/BasicInfo';
import ContactInfo from '../../../common/Svg/ContactInfo';
import LocationPin from '../../../common/Svg/LocationPin';
import OtherInfo from '../../../common/Svg/OtherInfo';
import {CommonCollapse} from '../../../RightSideContainer/Contacts/TeamMembers/LeftTeamContainer/CommonCollapse';
import {CapabilityResource} from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {IContact, IContactType} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {styles} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsStyles';
import {useToast as toastProvider} from '../../../Toast/ToastProvider';
import {getUSLocaleDate} from '../../MiddleContainer/FormResponseTableView/Helper';
import {
  getExtensionData,
  getFormattedPatientDetails
} from '../../MiddleContainer/PersonDetailsView/DetailTables/DetailTableUtils';
import {PERSON_ACTION_CODES} from '../../PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {Employee} from '../interfaces';
import {
  getAgeFromDate,
  getGenderTransformValue,
  getPracticeLocationNameAndCity
} from './OtherDeatilsUtils';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import { ContactRelationView } from '../../../common/ContactRelationView';
import { getPatientMartialStatus } from '../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateQueryUtils';
import {KEY_ALLOWED_OPERATIONS} from '../../../../constants/FhirConstant';
import {PossibleValue} from '../../../../Interfaces';
import ConversationsQueries from '../../../../services/Conversations/ConversationsQueries';
import LinkedMemberFromNumberView from './LinkedMemberFromNumberView';
import { IEhrFieldList } from '../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/components/CustomEHRAttributes/interface';
import { formatCustomEhrFieldsList, getCustomEHRAttributesFromPatientData } from '../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/components/CustomEHRAttributes/helper';
import { EventBus } from '../../../../utils/EventBus';
import { SUPPORTED_EVENT_CODE } from '../../../../constants/WebSocketConst';
import { useIntl } from 'react-intl';
import { withMiniContactViewHOC } from '../../../MiniContactViewHOC';
import {getCustomFieldsForEntity} from '../../../RightSideContainer/Contacts/CustomField/CustomFieldService';
import {getFormattedCustomAttributesChunkedData, getFormattedCustomAttributesValue} from '../../../RightSideContainer/Contacts/CustomField/CustomFieldUtils';
import {CustomAttributesValue, ICustomToast, IFormattedCustomFieldsData} from '../../../RightSideContainer/Contacts/CustomField/interface';
import {CUSTOM_ATTRIBUTE_TITLE_MAX_DISPLAY_CHAR, CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR, CUSTOM_FIELD_TOAST_ERROR_DURATION, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG} from '../../../RightSideContainer/Contacts/CustomField/CustomFieldConst';
import { RNTwilioPhoneCall } from '../../../../RNTwilioPhone/RNTwilioPhoneCall';
import {isCallsAllowed, isMessageDashboardAllowed} from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import { testID, TestIdentifiers } from '../../../../testUtils';

export type IDetailsPanelReadOnly = {
  contactData: IContact;
  style?: any;
  isDefaultClose?: boolean;
  onNavigateRelationalContact?: (contact: IContact) => void;
  onlyShowInnerContent?: boolean;
  handleEmail?: any;
  onActionPerformed?: (actionCode: string, data?: any) => void;
  onAddOrUpdatePress?: (type: string, data?: unknown) => void,
  handleCloudCall?: (
    actionCode: string,
    contactData: IContact,
    fromNumber: string,
    toNumber?: string
  ) => void;
  onPersonActionPerformed?: (actionCode: string, rawData?: any) => void;
  handleEmailSms?: (actionCode: string, rawData?: any) => void;
  handleEdit?: any;
  hintExtraDetail?: any;
  navigate?: any;
  contactType?: IContactType;
  navigateOrOpenContactIdDrawer?:(contactId?:string, additionalData?:any) => void;

};

export enum BasicInfoFields {
  sexualOrientation = 'sexualOrientation',
  race = 'race',
  ethnicity = 'ethnicity',
}

export const ContactDetailView = (props: IDetailsPanelReadOnly) => {
  const {
    contactData,
    style,
    isDefaultClose,
    onlyShowInnerContent,
    handleEmail,
    onActionPerformed,
    handleCloudCall,
    handleEmailSms,
    onPersonActionPerformed,
    handleEdit,
    onNavigateRelationalContact,
    navigate,
    onAddOrUpdatePress,
  } = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const accountId = getAccountId();
  const [patientDetails, setPatientDetails] = useState<any>({});
  const [employerDetails, setEmployerDetails] = useState({
    name: '',
  });
  const [stateData, setStateData] = useState({
    visible: false,
    visibleSecond: false,
    alertBox: false,
    actionCode: '',
    rawData: {},
    name: '',
    consent: false,
    contacts: [] as any,
    loading: false,
    customAttributesValue: [] as CustomAttributesValue,
  });
  const [ehrAttributesFieldList,setEhrAttributeFieldList] = useState<{
    isLoading:boolean,
    list: IEhrFieldList[]
  }>({
    isLoading: false,
    list: []
  });
  const [language, setLanguage] = useState('');
  const toast = useToast();
  const customToast = toastProvider();
  const isEmployer = isEmployerRole();
  const isCallAllowed = isCallsAllowed();
  const languageCode =
    getLanguageFromContactData(contactData)?.languageCode || '';
  const checkMessageDashboardAllowed = isMessageDashboardAllowed();

  const patientUuid =
    contactData?.patient?.patientId || contactData?.patient?.patientUuid;
  const accountLocationUuid = contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid || '';

  const genderTransformValues = getSexAtBirthTransformValues(
    KEY_ALLOWED_OPERATIONS.GENDER,
    '',
    accountLocationUuid
  );

  const contactActiveStatus = isActiveContact(contactData)
  const secondaryEmail= getSecondaryValue(contactData?.personContact, 'email');
  const secondaryPhone= getSecondaryValue(contactData?.personContact, 'phone');
  const responseHandler = async (response: any) => {
    const data = getFormattedPatientDetails(response.data);
    const {ethnicity, race, sexualOrientation, created} = getExtensionData(
      response.data?.extension
    );
    const maritalStatusCode = getPatientMartialStatus(response.data);
    let maritalStatus = '-';
    if (maritalStatusCode) {
      const capabilityField = patientCapability?.keyAllowedOperations?.maritalStatus || {};
      const maritalStatusField = (capabilityField?.possibleValues || []).find((possibleValue: any) => {
        return possibleValue?.code === maritalStatusCode;
      }) || undefined;
      maritalStatus = maritalStatusField?.display || '-';
    }
    const ehrAttributeFieldResponseList =
    getCustomEHRAttributesFromPatientData(response?.data?.contained);
    setEhrAttributeFieldList((prev) => ({ ...prev, isLoading: true}));
    await getCustomEHRAttributes((res:any) => {
      const formmattedList = formatCustomEhrFieldsList(
        res.data?.expansion?.contains,
        ehrAttributeFieldResponseList
      );
      setEhrAttributeFieldList({list: formmattedList, isLoading: false});
    },(err) => {
      setEhrAttributeFieldList({list: [], isLoading: false});
    }, accountLocationUuid)
    setPatientDetails({...data, ethnicity, race, sexualOrientation, created, maritalStatus});
  };

  const errorHandler = (error: any) => {

  };

  const {name: practiceName} = getPracticeLocationNameAndCity(contactData);

  const [getEmployerByContact] = useLazyQuery(
    EmployeeQueries.getEmployerByContact,
    {
      variables: {
        contactId: contactData?.id,
      },
    }
  );

  const [CheckIfNumberPrimary] = useLazyQuery(
    CloudTelephonyQueries.CheckIfNumberPrimary,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [getMemberByContact] = useLazyQuery(
    ConversationsQueries.getMemberByContact,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getContactById] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',
  })

  const showCustomToast = (toastData: ICustomToast) => {
    customToast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const getEmployerDetails = async () => {
    try {
      const response = await getEmployerByContact();
      const employees: Employee[] = response.data.employees || [];
      const employerName =
        employees.length > 0 ? employees[0]?.employer?.name || '' : '';
      setEmployerDetails((prev) => {
        return {
          ...prev,
          name: employerName,
        };
      });
    } catch (error) {

    }
  };
  const  pronounList = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.PRONOUN
  );
  const selectedPronoun = pronounList?.find((pronoun)=> {
    return pronoun?.id === contactData?.person?.pronounId
  })

  const getCustomFieldsDataForEntity = async () => {
    if (contactData?.uuid) {
      const params = {
        entityId: contactData?.uuid
      }
      try {
        const response = await getCustomFieldsForEntity(params);
        if (response?.data?.length) {
          const customFields = response?.data;
          const formattedCustomFieldsData = getFormattedCustomAttributesValue(customFields);
          const customAttributesChunkedData = getFormattedCustomAttributesChunkedData(formattedCustomFieldsData);
          setStateData((prev)=> {
            return {
              ...prev,
              customAttributesValue: customAttributesChunkedData
            }
          })
        } else {
          setStateData((prev)=> {
            return {
              ...prev,
              customAttributesValue: [],
            }
          })
        }
      } catch (error :any) {
        showCustomToast({
          toastType: ToastType.error,
          message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
          duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
          closeAllPrevToast: true,
        })
      }
    }
  }

  useEffect(()=> {
    getCustomFieldsDataForEntity();
  },[]);

  const getPatientDetailsFromAPI = () => {
    getPatientDetails(
      patientUuid,
      (response) => responseHandler(response),
      (error) => errorHandler(error),
      accountLocationUuid
    )
  };

  useEffect(() => {
    if (patientUuid) {
      getPatientDetails(
        patientUuid,
        (response) => responseHandler(response),
        (error) => errorHandler(error),
        accountLocationUuid,
      );
    }
    getEmployerDetails();
    getLanguages(accountLocationUuid)
      .then((response) => {
        const list = getFormattedLanguageArray(response.data);
        const language = getLanguageFromCode(languageCode, list);
        setLanguage(language);
      })
      .catch((error) => {

      });
      getMembersDataForEmailAndSms()
      const eventBus = EventBus.getEventBusInstance();
      eventBus.addEventListener(
        SUPPORTED_EVENT_CODE.MESSAGE_UPDATED,
        getPatientDetailsFromAPI
      )
      return () => {
        eventBus.removeEventListener(getPatientDetailsFromAPI);
      }
  }, [contactData?.uuid]);

  const getMembersDataForEmailAndSms = async  () => {
    setStateData(prev=> {
      return {
        ...prev,
        loading: true
      }
    })
    let whereObject: any = {
      isDeleted: {_eq: false},
      id: {_neq: contactData.id},
    };
       whereObject = {
        ...whereObject,
        phoneNumber: {_like: contactData.phoneNumber},
       };

      const response = await getMemberByContact({
        variables: {
          where: whereObject
        }
      }).catch(()=> {
        setStateData((prev=> {
          return {
            ...prev,
            loading: false
          }
        }))
      })
      if(response?.data?.contacts?.length) {
        const contacts = response?.data?.contacts;
        setStateData((prev=> {
          return {
            ...prev,
            contacts: contacts,
            loading:false
          }
        }))
      }else {
        setStateData((prev=> {
          return {
            ...prev,
            loading: false
          }
        }))
      }

  }

  const cityName = contactData?.personAddress?.length
    ? contactData?.personAddress[0]?.cities?.name
    : '';
  const zipCode = contactData?.personAddress?.length
    ? contactData?.personAddress[0]?.zipcodes?.code
    : '';

  const age = getAgeFromDate(contactData?.person?.birthDate);
  const isCurrentContactPatient = () => {
    return contactData?.patient?.id;
  };

  const {line1, line2, state} = getAddressDetails(contactData);
  const contactGender = getContactGender(contactData,genderTransformValues);

  const mask = `(###) ###-####`;

  const phoneNumber = contactData.phoneNumber;

  const genderIdentityLabel = getGenderIdentityLabel('', accountLocationUuid);
  const genderAtBirthLabel = getBirthSexLabel('', accountLocationUuid);
  const patientCapability = getResourceAbilities(
    CapabilityResource.patient,
    '',
    accountLocationUuid
  );
  const isSingleEmailAdd =
    patientCapability.keyAllowedOperations?.telecom?.isSingleEmail;
  const isGenderIdentityHidden =
    patientCapability?.keyAllowedOperations?.gender?.isHidden || false;
  const isSexualOrientationHidden = patientCapability?.keyAllowedOperations?.sexualOrientation?.isHidden || false;
  const isRaceHidden = patientCapability?.keyAllowedOperations?.race?.isHidden || false;
  const isEthnicity = patientCapability?.keyAllowedOperations?.ethnicity?.isHidden || false;
  const isEmployerHidden = patientCapability?.keyAllowedOperations?.employer?.isHidden || false;
  const isMartialStatusHidden = patientCapability?.keyAllowedOperations?.maritalStatus?.isHidden || false;
  const bloodGroup = contactData?.person?.bloodGroup;
  if (!contactData) {
    return null;
  }
  const consentCheck = isConsentRequiredAndGiven(contactData)
  const handleActions = (actionCode: string, rowData?: any, isRank2?: boolean) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
            visibleSecond: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.CALL:
        if (isWeb()) {
          if (handleCloudCall) {
            handleCloudCall(
              COMMON_ACTION_CODES.CLOUD_CALL,
              contactData,
              rowData.fromNumber,
              isRank2
                ? getSecondaryValue(contactData?.personContact, 'phone')
                : ''
            );
          }
        } else {
          const callData = {
            toPhoneNumber: phoneNumber,
            fromPhoneNumber: rowData?.fromNumber,
          };
          onPersonActionPerformed?.(COMMON_ACTION_CODES.CALL, callData);
        }
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
            visibleSecond: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.SMS:
        onActionPerformed?.(COMMON_ACTION_CODES.SMS, rowData);
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
            visibleSecond: false,
          };
        });
        break;
    }
  };

  const showErrorNotification = () => {
    const message = getInActiveContactError(contactData);
    notification.error({
      message,
    });
  };

  const handleClose = () =>{
    setStateData((prev)=>{
      return {
        ...prev,
        alertBox: false,
        actionCode: '',
        rawData: {},
        name: '',
        consent: false
      }
    })
  }

  const routeToContactById = async (contactData: any) => {
    if (isWeb()) {
      const type = contactData.contactType
        ? contactData?.contactType?.contactType?.value
        : '';
      const contactId = contactData?.id;
      if (type == 'Customer') {
        props?.navigateOrOpenContactIdDrawer?.(contactId);
      } else if (type == 'Lead') {
        navigate(`/members/prospect/${contactId}`);
      } else {
        navigate(`/members/supporting-memeber/${contactId}`);
      }
    } else {
      const contactResp = await getContactById({
        variables: {
          id: contactData?.id,
        }
      })
      const contact = contactResp?.data?.contact
      onNavigateRelationalContact?.(contact)
    }
  };

  const getContactRelationView = () => {
    return (
      <ContactRelationView
        isDefaultClose={false}
        contactData={contactData}
        style={styles.contactRelationView}
        onAddOrUpdatePress={onAddOrUpdatePress}
      />
    );
  };

  const renderCustomAttributeRow = (customAttribute: IFormattedCustomFieldsData[], index: string) => (
    <HStack key={index}>
      {customAttribute.map((customAttributeValue: IFormattedCustomFieldsData, index: number) => (
        <VStack  key={`${customAttributeValue?.id}_${index}`} style={styles.panelBoxContents}>
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY300}>
            {customAttributeValue?.customAttribute?.label}
          </Text>
          <Tooltip
            title={customAttributeValue?.formattedValue?.length > CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR ? customAttributeValue?.formattedValue : ''}
            placement={'top'}
            destroyTooltipOnHide={true}
          >
            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
              {customAttributeValue?.formattedValue?.length > CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR ? `${customAttributeValue?.formattedValue.slice(0,CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR)}...` : customAttributeValue?.formattedValue}
            </Text>
          </Tooltip>
      </VStack>
      ))}
    </HStack>
  );

  const renderInnerContent = () => {
    return (
      <>
        {contactData ? (
          <View style={styles.listContainer}>
            <VStack style={styles.detailsPanelBox2}>
              <HStack alignItems={'center'}>
                <BasicInfo />
                <Text
                  size={'lgMedium'}
                  style={styles.detailsPanelBox2Text}
                  color={Colors.FoldPixel.GRAY300}
                  {...testID(TestIdentifiers.basicInfo)}
                >
                  Basic Info
                </Text>
              </HStack>
              <View
                style={styles.detailsPanelBox2View}
                backgroundColor={'#ffffff'}
              >
                <HStack>
                  <VStack style={styles.panelBoxContents}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={'name'}
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {contactData.name ? contactData.name : '-'}
                    </Text>
                  </VStack>
                  <VStack style={styles.panelBoxContents}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={'userName'}
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {contactData.contactUser?.foldUsername
                        ? contactData.contactUser?.foldUsername
                        : '-'}
                    </Text>
                  </VStack>
                </HStack>
                <HStack>
                  {!isGenderIdentityHidden ? (
                    <VStack style={styles.panelBoxContents}>
                      <DisplayText
                        extraStyles={{color: Colors.FoldPixel.GRAY300}}
                        textLocalId={isWeb() ? genderIdentityLabel : 'gender'}
                        size={'smRegular'}
                      ></DisplayText>
                      <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                        {contactGender || '-'}
                      </Text>
                    </VStack>
                  ) : (
                    <></>
                  )}
                  <VStack style={styles.panelBoxContents}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={'birthDay'}
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {contactData.person?.birthDate
                        ? getDateStrFromMomentObj(
                            moment(contactData?.person?.birthDate),
                            DATE_FORMATS.CONVERSATION_DATE_PICKER
                          )
                        : '--'}{' '}
                    </Text>
                  </VStack>
                </HStack>
                {bloodGroup ? (
                  <HStack>
                    <VStack style={styles.panelBoxContents}>
                      <DisplayText
                        extraStyles={{color: Colors.FoldPixel.GRAY300}}
                        textLocalId={'bloodGroup'}
                        size={'smRegular'}
                      ></DisplayText>
                      <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                        {bloodGroup?.value || '-'}
                      </Text>
                    </VStack>
                  </HStack>
                ) : (
                  <></>
                )}
                <HStack>
                  <VStack style={styles.panelBoxContents}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={'age'}
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {contactData.person?.birthDate && age !== 0 ? age : '--'}
                    </Text>
                  </VStack>
                  <VStack style={styles.panelBoxContents}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={'language'}
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {language || '-'}
                    </Text>
                  </VStack>
                </HStack>
                <HStack>
                  <VStack style={styles.panelBoxContents}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={'chosenName'}
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {contactData.person?.chosenName || '--'}
                    </Text>
                  </VStack>
                  <VStack style={styles.panelBoxContents}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={'pronoun'}
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {selectedPronoun?.value ? selectedPronoun.value : '--'}
                    </Text>
                  </VStack>
                </HStack>
                <HStack>
                  <VStack style={styles.panelBoxContents}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={
                        isWeb() ? genderAtBirthLabel : 'genderAtBirth'
                      }
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {contactData?.person?.sexAtBirth?.value
                        ? getGenderFullForm(
                            contactData?.person?.sexAtBirth?.value
                          )
                        : '-'}
                    </Text>
                  </VStack>
                  {!isSexualOrientationHidden ? (
                    <VStack style={styles.panelBoxContents}>
                      <DisplayText
                        extraStyles={{color: Colors.FoldPixel.GRAY300}}
                        textLocalId={'sexualOrientation'}
                        size={'smRegular'}
                      ></DisplayText>
                      <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                        {patientDetails?.sexualOrientation || '-'}
                      </Text>
                    </VStack>
                  ) : (
                    <></>
                  )}
                </HStack>
                <HStack>
                  {!isMartialStatusHidden ? (
                    <VStack style={styles.panelBoxContents}>
                      <DisplayText
                        extraStyles={{color: Colors.FoldPixel.GRAY300}}
                        textLocalId={'maritalStatus'}
                        size={'smRegular'}
                      ></DisplayText>
                      <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                        {patientDetails?.maritalStatus || '-'}
                      </Text>
                    </VStack>
                  ) : (
                    <></>
                  )}
                </HStack>
                <HStack>
                  {!isRaceHidden ? (
                    <VStack style={styles.panelBoxContents}>
                      <DisplayText
                        extraStyles={{color: Colors.FoldPixel.GRAY300}}
                        textLocalId={'race'}
                        size={'smRegular'}
                      ></DisplayText>
                      <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                        {patientDetails?.race || '-'}
                      </Text>
                    </VStack>
                  ) : (
                    <></>
                  )}
                  {!isEthnicity ? (
                    <VStack style={styles.panelBoxContents}>
                      <DisplayText
                        extraStyles={{color: Colors.FoldPixel.GRAY300}}
                        textLocalId={'ethnicity'}
                        size={'smRegular'}
                      ></DisplayText>
                      <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                        {patientDetails?.ethnicity || '-'}
                      </Text>
                    </VStack>
                  ) : (
                    <></>
                  )}
                </HStack>
              </View>
            </VStack>

            <VStack style={styles.detailsPanelBox2}>
              <HStack alignItems={'center'}>
                <ContactInfo />
                <Text
                  size={'lgMedium'}
                  style={styles.contactRelationViewText}
                  color={Colors.FoldPixel.GRAY300}
                  {...testID(TestIdentifiers.contactInfo)}
                >
                  Contact Info
                </Text>
              </HStack>
              <View
                style={styles.detailsPanelBox2View}
                backgroundColor={'#ffffff'}
              >
                <HStack>
                  <VStack style={[styles.panelBoxContents, {flex: 0.6}]}>
                    <HStack alignItems={'center'} space={2}>
                      <DisplayText
                        extraStyles={{color: Colors.FoldPixel.GRAY300}}
                        textLocalId={'email'}
                        size={'smRegular'}
                      ></DisplayText>
                      {contactData.email &&
                      isContactVerified(
                        contactData?.personContact || [],
                        'email'
                      ) ? (
                        <ContactVerified label="Verified Email" />
                      ) : (
                        <></>
                      )}
                    </HStack>
                    <Text
                      size={'smMedium'}
                      color={
                        isWeb()
                          ? Colors.secondary['500']
                          : Colors.primary['700']
                      }
                      onPress={() => {
                        if (!checkMessageDashboardAllowed) {
                          return
                        }
                        if (!isEmployer) {
                          if (consentCheck) {
                            if (contactData.email) {
                              if (contactActiveStatus) {
                                onPersonActionPerformed?.(
                                  COMMON_ACTION_CODES.EMAIL
                                );
                                handleEmail?.();
                                handleEmailSms?.(PERSON_ACTION_CODES.EMAIL);
                              } else {
                                showErrorNotification();
                              }
                            }
                          } else {
                            handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
                          }
                        }
                      }}
                    >
                      {contactData.email ? contactData.email : '-'}
                    </Text>
                  </VStack>
                  <VStack style={[styles.panelBoxContents, {flex: 0.4}]}>
                    <HStack space={2} alignItems={'center'}>
                      <DisplayText
                        extraStyles={{color: Colors.FoldPixel.GRAY300}}
                        textLocalId={'phone'}
                        size={'smRegular'}
                      ></DisplayText>
                      {!!phoneNumber &&
                      isContactVerified(
                        contactData?.personContact || [],
                        'phone'
                      ) ? (
                        <ContactVerified label="Verified Number" />
                      ) : (
                        <></>
                      )}
                    </HStack>
                    <OutboundCallSmsView
                      showOnlyCallNumbers={true}
                      isHideSms={!isWeb()}
                      visible={stateData.visible}
                      selectedContactData={contactData}
                      isActiveCall={RNTwilioPhoneCall?.calls?.length > 0}
                      content={
                        <Pressable
                          onPress={() => {
                            if (!isCallAllowed) {
                              return
                            }
                            if (!isEmployer) {
                              if (consentCheck) {
                                if (phoneNumber) {
                                  if (contactActiveStatus) {
                                    setStateData((prev) => {
                                      return {
                                        ...prev,
                                        visible: true,
                                      };
                                    });
                                  } else {
                                    showErrorNotification();
                                  }
                                }
                              } else {
                                if (isWeb()) {
                                  handleEdit?.(
                                    COMMON_ACTION_CODES.CONSENT_WARNING
                                  );
                                } else {
                                  showToast(
                                    toast,
                                    `${CONSENT_ERROR} from Fold dashboard`,
                                    ToastType.info
                                  );
                                }
                              }
                            }
                          }}
                        >
                          <Text
                            style={{
                              textDecorationLine: !!phoneNumber
                                ? 'underline'
                                : 'none',
                            }}
                            size={'smMedium'}
                            color={
                              isWeb()
                                ? Colors.secondary['500']
                                : Colors.primary['700']
                            }
                          >
                            {!!phoneNumber
                              ? numericStringMask(phoneNumber, mask)
                              : '-'}
                          </Text>
                        </Pressable>
                      }
                      handleAction={(actionCode, rawData) => {
                        handleActions(actionCode, rawData);
                      }}
                      onClose={() => {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            visible: false,
                          };
                        });
                      }}
                    />
                  </VStack>
                </HStack>

                <HStack>
                  {!isSingleEmailAdd ? (
                    <VStack style={[styles.panelBoxContents, {flex: 0.6}]}>
                      <HStack alignItems={'center'} space={2}>
                        <DisplayText
                          extraStyles={{color: Colors.FoldPixel.GRAY300}}
                          textLocalId={'secondaryEmail'}
                          size={'smRegular'}
                        ></DisplayText>
                        {!!secondaryEmail &&
                        isSecondaryContactVerified(
                          contactData?.personContact || [],
                          'email'
                        ) ? (
                          <ContactVerified label="Verified Email" />
                        ) : (
                          <></>
                        )}
                      </HStack>
                      <Text
                        size={'smMedium'}
                        color={
                          isWeb()
                            ? Colors.secondary['500']
                            : Colors.primary['700']
                        }
                        onPress={() => {
                          if (!checkMessageDashboardAllowed) {
                            return
                          }
                          if (!isEmployer) {
                            if (secondaryEmail) {
                              if (contactActiveStatus) {
                                onPersonActionPerformed?.(
                                  COMMON_ACTION_CODES.EMAIL
                                );
                                handleEmail?.(true); // true is rank 2 (secondary email)
                                handleEmailSms?.(PERSON_ACTION_CODES.EMAIL);
                              } else {
                                showErrorNotification();
                              }
                            } else {
                              handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
                            }
                          }
                        }}
                      >
                        {secondaryEmail || '-'}
                      </Text>
                    </VStack>
                  ) : (
                    <></>
                  )}
                  <VStack style={[styles.panelBoxContents, {flex: 0.4}]}>
                    <HStack space={2} alignItems={'center'}>
                      <DisplayText
                        extraStyles={{color: Colors.FoldPixel.GRAY300}}
                        textLocalId={'secondaryPhone'}
                        size={'smRegular'}
                      ></DisplayText>
                      {!!secondaryPhone &&
                      isSecondaryContactVerified(
                        contactData?.personContact || [],
                        'phone'
                      ) ? (
                        <ContactVerified label="Verified Number" />
                      ) : (
                        <></>
                      )}
                    </HStack>
                    <OutboundCallSmsView
                      showOnlyCallNumbers={true}
                      isHideSms={!isWeb()}
                      visible={stateData.visibleSecond}
                      selectedContactData={contactData}
                      content={
                        <Pressable
                          onPress={() => {
                            if (!isCallAllowed) {
                              return
                            }
                            if (!isEmployer) {
                              if (secondaryPhone) {
                                if (contactActiveStatus) {
                                  setStateData((prev) => {
                                    return {
                                      ...prev,
                                      visibleSecond: true,
                                    };
                                  });
                                } else {
                                  showErrorNotification();
                                }
                              } else {
                                if (isWeb()) {
                                  handleEdit?.(
                                    COMMON_ACTION_CODES.CONSENT_WARNING
                                  );
                                } else {
                                  showToast(
                                    toast,
                                    `${CONSENT_ERROR} from Fold dashboard`,
                                    ToastType.info
                                  );
                                }
                              }
                            }
                          }}
                        >
                          <Text
                            style={{
                              textDecorationLine: !!secondaryPhone
                                ? 'underline'
                                : 'none',
                            }}
                            size={'smMedium'}
                            color={
                              isWeb()
                                ? Colors.secondary['500']
                                : Colors.primary['700']
                            }
                          >
                            {!!secondaryPhone
                              ? numericStringMask(
                                  secondaryPhone.toString(),
                                  mask
                                )
                              : '-'}
                          </Text>
                        </Pressable>
                      }
                      handleAction={async (actionCode, rawData) => {
                        const checkNumberExistAsPrimary =
                          await CheckIfNumberPrimary({
                            variables: {
                              rank: 1,
                              value: secondaryPhone,
                            },
                          });
                        if (
                          checkNumberExistAsPrimary?.data?.personContacts
                            ?.length
                        ) {
                          const userExistWithThisAsPrimaryNumber =
                            checkNumberExistAsPrimary?.data?.personContacts?.filter(
                              (user: any) => {
                                return (
                                  user?.personContactId !== contactData?.id
                                );
                              }
                            );
                          if (!userExistWithThisAsPrimaryNumber.length) {
                            handleActions(actionCode, rawData, true);
                          } else {
                            if (!stateData?.actionCode) {
                              setStateData((prev) => {
                                return {
                                  ...prev,
                                  alertBox: true,
                                  actionCode: actionCode,
                                  rawData: rawData,
                                  visible: false,
                                  visibleSecond: false,
                                  name: userExistWithThisAsPrimaryNumber[0]
                                    .contact?.name,
                                  consent: userExistWithThisAsPrimaryNumber[0]
                                    .contact?.contactConsents.length
                                    ? true
                                    : false,
                                };
                              });
                            }
                          }
                        } else {
                          handleActions(actionCode, rawData, true); // true is rank 2 (secondary phone)
                        }
                      }}
                      onClose={() => {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            visibleSecond: false,
                          };
                        });
                      }}
                    />
                  </VStack>
                </HStack>
                {stateData.loading ? (
                  <Skeleton.Text lines={1} />
                ) : phoneNumber && stateData?.contacts?.length > 0 ? (
                  <LinkedMemberFromNumberView
                    contacts={stateData.contacts}
                    contactData={contactData}
                    navigate={routeToContactById}
                    contactType={props?.contactType}
                  />
                ) : (
                  <></>
                )}
              </View>
            </VStack>

            {stateData?.customAttributesValue?.length > 0 && (
              <VStack style={styles.detailsPanelBox2}>
                <HStack alignItems={'center'}>
                  <BasicInfo />
                  <Text
                    size={'lgMedium'}
                    style={styles.detailsPanelBox2Text}
                  >
                    Custom Fields
                  </Text>
                </HStack>
                <View
                  style={styles.detailsPanelBox2View}
                  backgroundColor={'#ffffff'}
                >
                  <VStack>
                    {stateData?.customAttributesValue.map(
                      (
                        customAttribute: IFormattedCustomFieldsData[],
                        index: any
                      ) =>
                        renderCustomAttributeRow(
                          customAttribute,
                          customAttribute[0].id
                        )
                    )}
                  </VStack>
                </View>
              </VStack>
            )}

            <VStack style={styles.detailsPanelBox2}>
              <HStack alignItems={'center'}>
                <LocationPin />
                <Text
                  size={'lgMedium'}
                  style={styles.detailsPanelBox2Text}
                  color={Colors.FoldPixel.GRAY300}
                  {...testID(TestIdentifiers.address)}
                >
                  Address
                </Text>
              </HStack>
              <View
                style={styles.detailsPanelBox2View}
                backgroundColor={'#ffffff'}
              >
                <HStack>
                  <VStack style={styles.panelBoxContents}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={'addressLine1'}
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {line1 || '-'}
                    </Text>
                  </VStack>
                  <VStack style={styles.panelBoxContents}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={'addressLine2'}
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {line2 || '-'}
                    </Text>
                  </VStack>
                </HStack>
                <HStack>
                  <VStack style={styles.panelBoxContents}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={'state'}
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {state || '--'}
                    </Text>
                  </VStack>
                  <VStack style={styles.panelBoxContents}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={'city'}
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {cityName ? cityName : '--'}
                    </Text>
                  </VStack>
                </HStack>

                <HStack>
                  <VStack style={styles.panelBoxContents}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={'zipCode'}
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {zipCode ? zipCode : '--'}
                    </Text>
                  </VStack>
                  {practiceName ? (
                    <VStack style={styles.panelBoxContents}>
                      <DisplayText
                        extraStyles={{color: Colors.Custom.Gray700}}
                        textLocalId={'location'}
                        size={'smRegular'}
                      />
                      <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                        {practiceName}
                      </Text>
                    </VStack>
                  ) : null}
                </HStack>
              </View>
            </VStack>

            {getContactRelationView()}

            <VStack style={styles.detailsPanelBox2}>
              <HStack alignItems={'center'}>
                <OtherInfo />
                <Text
                  size={'lgMedium'}
                  style={styles.detailsPanelBox2Text}
                  color={Colors.FoldPixel.GRAY300}
                  {...testID(TestIdentifiers.otherInfo)}
                >
                  Other Info
                </Text>
              </HStack>
              <View
                flexDir={'row'}
                style={styles.detailsPanelBox2View}
                backgroundColor={'#ffffff'}
                w="100%"
                flexWrap={'wrap'}
              >
                <VStack style={styles.panelBoxContents} minW={'50%'}>
                  <DisplayText
                    extraStyles={{color: Colors.FoldPixel.GRAY300}}
                    textLocalId={'source'}
                    size={'smRegular'}
                  ></DisplayText>
                  <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                    {getSourceValue(contactData.source)}
                  </Text>
                </VStack>
                <VStack style={styles.panelBoxContents} minW={'50%'}>
                  <DisplayText
                    extraStyles={{color: Colors.FoldPixel.GRAY300}}
                    textLocalId={'createdOn'}
                    size={'smRegular'}
                  ></DisplayText>
                  <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                    {getUSLocaleDate(contactData?.createdAt) || '-'}
                  </Text>
                </VStack>
                {!isEmployerHidden ? (
                  <VStack style={styles.panelBoxContents} minW={'50%'}>
                    <DisplayText
                      extraStyles={{color: Colors.FoldPixel.GRAY300}}
                      textLocalId={'employer'}
                      size={'smRegular'}
                    ></DisplayText>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                      {employerDetails.name || '-'}
                    </Text>
                  </VStack>
                ) : (
                  <></>
                )}

                {ehrAttributesFieldList.isLoading ? (
                  <Skeleton.Text mx={2} lines={2} />
                ) : ehrAttributesFieldList.list.length > 0 ? (
                  <HStack
                    w="100%"
                    flexWrap={'wrap'}
                    style={styles.panelBoxContents}
                  >
                    <Text
                      fontWeight={700}
                      color={Colors.Custom.Gray400}
                      mb={2}
                      fontSize={12}
                    >
                      {intl
                        .formatMessage({id: 'customEHRFields'})
                        .toUpperCase()}
                    </Text>
                    <Divider />
                    {ehrAttributesFieldList.list.map((field) => (
                      <VStack
                        key={field.key}
                        minW={'50%'}
                        style={styles.panelBoxContents2}
                      >
                        <DisplayText
                          extraStyles={{color: Colors.FoldPixel.GRAY300}}
                          textLocalId={field.label}
                          size={'smRegular'}
                        ></DisplayText>
                        <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                          {field?.display}
                        </Text>
                      </VStack>
                    ))}
                  </HStack>
                ) : (
                  <></>
                )}
              </View>
            </VStack>
            <Modal isOpen={stateData?.alertBox} onClose={handleClose}>
              <Modal.Content style={styles.modalContainer}>
                <Modal.Header>
                  <Text
                    mt={2}
                    style={styles.modalHeader}
                  >
                    {'Confirm'}
                  </Text>
                </Modal.Header>
                <Modal.Body>
                  <Text>
                    <Text></Text>
                    {`${secondaryPhone} number is also set to member ${capitalizeText(
                      stateData?.name
                    )} as primary contact number,`}
                    <Text>
                      {stateData?.consent
                        ? ` so it will send the SMS to the member ${capitalizeText(
                            stateData?.name
                          )}.`
                        : 'but the consent is not given please update consent'}
                    </Text>
                  </Text>
                </Modal.Body>

                <Modal.Footer style={styles.modalFooter}>
                  <Button.Group>
                    <ModalActionBtn
                      btnStype={BUTTON_TYPE.SECONDARY}
                      btnText={'cancel'}
                      onClickAction={() => {
                        handleClose();
                      }}
                    />
                    <ModalActionBtn
                      btnStype={BUTTON_TYPE.PRIMARY}
                      btnText={
                        stateData?.consent ? 'continueToSMS' : 'updateConsent'
                      }
                      onClickAction={() => {
                        if (stateData?.consent) {
                          handleActions(
                            stateData?.actionCode,
                            stateData?.rawData,
                            true
                          );
                        } else {
                          handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
                        }
                        setStateData((prev) => {
                          return {
                            ...prev,
                            alertBox: false,
                            actionCode: '',
                            rawData: {},
                            name: '',
                            consent: false,
                          };
                        });
                      }}
                    />
                  </Button.Group>
                </Modal.Footer>
              </Modal.Content>
            </Modal>
          </View>
        ) : (
          <></>
        )}
      </>
    );
  };
  if (onlyShowInnerContent) {
    return (
      <View
        style={styles.detailsPanelBox3}
        backgroundColor={Colors.Custom.Gray50}
      >
        {renderInnerContent()}
      </View>
    );
  }
  return (
    <>
      <CommonCollapse
        isDefaultClose={isDefaultClose}
        textLocalId={isCurrentContactPatient() ? 'patientDetails' : 'details'}
        style={style ? style : {marginBottom: 0}}
        btnList={[]}
      >
        {renderInnerContent()}
      </CommonCollapse>
    </>
  );
};

export default isWeb() ? withMiniContactViewHOC(ContactDetailView) : ContactDetailView;

export const getAddressDetails = (contactData: IContact) => {
  const line1 =
    contactData?.personAddress?.length > 0
      ? contactData?.personAddress[0]?.line1
      : '';
  const line2 =
    contactData?.personAddress?.length > 0
      ? contactData?.personAddress[0]?.line2
      : '';

  const state =
    contactData?.personAddress?.length > 0
      ? contactData?.personAddress[0]?.states?.name
      : '';

  return {
    line1,
    line2,
    state,
  };
};

export const getContactGender = (contactData: IContact, genderTransformValues: PossibleValue[]) => {
  const genderValue = contactData?.person?.gender
    ? getGenderTransformValue(contactData.person?.gender,genderTransformValues)
    : '';
  return genderValue;
};

export const getLanguageFromContactData = (contactData: any) => {
  const contactLanguages = contactData?.contactLanguages;
  if (contactLanguages?.length === 0) {
    return {
      languageCode: '',
    };
  }
  return contactLanguages
    ? contactLanguages[0]
    : {
        languageCode: '',
      };
};
