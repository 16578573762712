import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import Svg, {Path} from 'react-native-svg';

const LabGoalSvg = (props: ICommonSvgProps) => {
  const {customStrokeColor, size} = props;
  const strokeColor = customStrokeColor || '#6F7A90';

  return (
    <Svg width={size ? size : "16"} height={size ? size : "16"} viewBox="0 0 16 16" fill="none">
      <Path
        d="M4.53387 7.85563L5.38334 7.95043C6.06515 8.02652 6.60337 8.56713 6.67913 9.25195C6.73746 9.77927 7.07359 10.2344 7.55911 10.4434L8.66667 10.9031M8.66667 10.9031L13.4924 6.05598L9.90902 2.4567L2.74215 9.65527C1.75262 10.6492 1.75262 12.2606 2.74215 13.2546C3.73169 14.2485 5.33605 14.2485 6.32558 13.2546L8.66667 10.9031ZM14 6.56578L9.45433 2"
        stroke={strokeColor}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default React.memo(LabGoalSvg);
