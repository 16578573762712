import {useLazyQuery, useMutation} from '@apollo/client';
import {message} from 'antd';
import {Button, CheckIcon, HStack, useToast, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../constants';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {LeadQueries} from '../../../../services';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import {GET_MANAGER_ROLES} from '../../../../services/User/UserQueries';
import {getAccountId, getAccountUUID} from '../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {getContactTypeId, getMlovId, getMlovListByCategory} from '../../../../utils/mlovUtils';
import UserAutoComplete, {IUserSearch} from '../../../common/CalendarWidget/UserAutoComplete/UserAutoComplete';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {ModalActionAntSelect} from '../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {ModalActionInput} from '../../../common/ModalActionCommonComponent/ModalActionInput';
import {ModalActionSelect} from '../../../common/ModalActionCommonComponent/ModalActionSelect';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {IEmployerErrors} from '../interfaces';
import { getDealStatusList } from '../Helper';

const EmployerQuickCreateModal = (props: any) => {
  const {onModalClose} = props;
  const [managers, setManagers] = useState<any[]>([]);
  const toast = useToast();
  const mlovData = useContext(CommonDataContext);
  const [employerDetails, setEmployerDetails] = useState({
    name: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    status: '',
    accountManager: '',
  });
  const accountId = getAccountUUID();
  const tenantId = getAccountId();
  const contactTypeUuid = getContactTypeId('LEAD');

  const [employerDetailsError, setEmployerDetailsError] =
    useState<IEmployerErrors>({});
  const intl = useIntl();

  const [createEmployer] = useMutation(EmployerQueries.createEmployer);
  const [CreateContactTypeEmp] = useMutation(LeadQueries.CreateContactType)
  const [loading, setLoading] = useState(false);
  const dealStatusList = getDealStatusList();

  const [getCareJourneyById] = useLazyQuery(GET_MANAGER_ROLES, {
    fetchPolicy: 'no-cache',
    variables: {
      tenantId: tenantId,
    },
  });

  useEffect(() => {
    getCareJourneyById()
      .then((resp) => {
        if (resp?.data?.users) {
          setManagers(resp?.data?.users);
        }
      })
      .catch((e) => {

      });
  }, []);

  const onSubmitForm = (): void => {
    const errorFields: IEmployerErrors = {};
    if (!employerDetails?.name) {
      errorFields.name = 'required';
    }
    if (!employerDetails?.contactName) {
      errorFields.contactName = 'required';
    }
    if (!employerDetails?.contactEmail) {
      errorFields.contactEmail = 'required';
    } else {
      if (employerDetails?.contactEmail) {
        const emailRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!employerDetails?.contactEmail.match(emailRegex)) {
          errorFields.contactEmail = 'please enter valid email address';
        }
      }
    }
    if (!employerDetails?.contactPhone) {
      errorFields.contactPhone = 'required';
    }
    if (!employerDetails?.status) {
      errorFields.status = 'required';
    }
    // if (!employerDetails?.accountManager) {
    //   errorFields.accountManager = 'required';
    // }
    if (JSON.stringify(errorFields) === '{}') {
      setLoading(true);
      createEmployer({
        variables: {
          employer: {
            name: employerDetails?.name,
            accountUuid: accountId,
            employerDeal: {
              data: [
                {
                  statusId: employerDetails?.status,
                  managerId: employerDetails?.accountManager,
                  accountUuid: accountId,
                  contactEmployee: {
                    data: {
                      accountUuid: accountId,
                      contact: {
                        data: {
                          accountId: tenantId,
                          name: employerDetails?.contactName,
                          email: employerDetails?.contactEmail,
                          phoneNumber: employerDetails?.contactPhone,
                        },
                      },
                    },
                  },
                },
              ],
            },
          },
        },
        onCompleted: () => {
          setLoading(false);
        },
      })
        .then(async (resp) => {
          if (resp?.data?.createEmployer?.id) {
            const contactId = resp?.data?.createEmployer?.employerDeal[0]?.contactEmployee?.contact?.id
            const createContactResp = await CreateContactTypeEmp({
              variables: {
                contactId: contactId,
                typeId: contactTypeUuid
              }
            })
            message.success(intl.formatMessage({id: 'employerAdded'}));
            onModalClose(true);
          }
        })
        .catch((error) => {
          if (
            (error ? error.toString() : '').indexOf(
              'uniq_email_per_account_contact'
            ) != -1
          ) {
            showToast(toast, 'Email already exist', ToastType.error);
          } else {
            showToast(toast, 'Something went wrong!!!', ToastType.error);
          }
          setLoading(false);
        });
    } else {
      setEmployerDetailsError(errorFields);
    }
  };

  return (
    <View marginTop={0} mx={0}>
      <HStack marginBottom={5} flex={1}>
        <HStack justifyContent={'flex-start'} alignItems={'center'}>
          <TitleSubtitleView
            isTitleSubtitleOldView={true}
            isHideCommonButton={true}
            containerStyle={{marginBottom: 15, marginLeft: 0, marginTop: 0}}
            titleLabelId={'quickCreate'}
            subtitleLabelId=""
          />
        </HStack>
        <HStack
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1,
            marginTop: 0,
          }}
        >
          <Button.Group justifyContent={'flex-end'}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onModalClose(false);
                },
                style: {marginRight: 12}
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onSubmitForm();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'create',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </HStack>
      </HStack>
      <ModalActionInput
        fieldIs={'input'}
        label={'companyName'}
        isRequired={true}
        isInvalid={employerDetailsError?.name ? true : false}
        errors={employerDetailsError?.name}
        errorText={employerDetailsError?.name}
        defaultValue={employerDetails?.name}
        value={employerDetails?.name}
        onChangeText={(value: string) => {
          setEmployerDetails({
            ...employerDetails,
            name: value,
          });
        }}
        extraStyle={{flex: 1}}
      />

      <ModalActionInput
        marginTop={4}
        fieldIs={'input'}
        label={'contactName'}
        isRequired={true}
        isInvalid={employerDetailsError?.contactName ? true : false}
        errors={employerDetailsError?.contactName}
        errorText={employerDetailsError?.contactName}
        defaultValue={employerDetails?.contactName}
        value={employerDetails?.contactName}
        onChangeText={(value: string) => {
          setEmployerDetails({
            ...employerDetails,
            contactName: value,
          });
        }}
        extraStyle={{flex: 1}}
      />

      <ModalActionInput
        marginTop={4}
        fieldIs={'input'}
        label={'contactEmail'}
        isRequired={true}
        isInvalid={employerDetailsError?.contactEmail ? true : false}
        errors={employerDetailsError?.contactEmail}
        errorText={employerDetailsError?.contactEmail}
        defaultValue={employerDetails?.contactEmail}
        value={employerDetails?.contactEmail}
        onChangeText={(value: string) => {
          setEmployerDetails({
            ...employerDetails,
            contactEmail: value.trim(),
          });
        }}
        extraStyle={{flex: 1}}
      />

      <ModalActionInput
        marginTop={4}
        fieldIs={'input'}
        label={'contactPhone'}
        isRequired={true}
        isInvalid={employerDetailsError?.contactPhone ? true : false}
        errors={employerDetailsError?.contactPhone}
        errorText={employerDetailsError?.contactPhone}
        defaultValue={employerDetails?.contactPhone}
        value={employerDetails?.contactPhone}
        onChangeText={(value: string) => {
          setEmployerDetails({
            ...employerDetails,
            contactPhone: value,
          });
        }}
        extraStyle={{flex: 1}}
      />

      <ModalActionSelect
        marginTop={4}
        isRequired={true}
        isInvalid={employerDetailsError?.status ? true : false}
        label={'selectDealStatus'}
        selectedValue={employerDetails.status || undefined}
        accessibilityLabel={intl.formatMessage({id: 'selectDealStatus'})}
        placeholder={intl.formatMessage({id: 'selectDealStatus'})}
        endIcon={<CheckIcon size="5" />}
        onValueChange={(itemValue) => {
          setEmployerDetails({
            ...employerDetails,
            status: itemValue,
          });
        }}
        data={dealStatusList}
        selectItemProps={{
          key: 'id',
          label: 'value',
          value: 'id',
        }}
        customStyle={{flex: 1}}
      />

      {/* <ModalActionAntSelect
        marginTop={4}
        isRequired={true}
        isInvalid={employerDetailsError?.accountManager ? true : false}
        label={'selectAccountManager'}
        endIcon={<CheckIcon size="5" />}
        placeholder={intl.formatMessage({id: 'selectAccountManager'})}
        selectedValue={employerDetails.accountManager || undefined}
        accessibilityLabel={intl.formatMessage({
          id: 'selectAccountManager',
        })}
        onChange={(itemValue: any) => {
          setEmployerDetails({
            ...employerDetails,
            accountManager: itemValue.value,
          });
        }}
        data={managers}
        selectItemProps={{
          key: 'uuid',
          label: 'name',
          value: 'uuid',
        }}
        customStyle={{flex: 1}}
      /> */}
      <View marginTop={4}>
        <UserAutoComplete
          labelText={intl.formatMessage({id: 'selectAccountManager'})}
          label={intl.formatMessage({id: 'selectAccountManager'})}
          selectedUserUUID={employerDetails.accountManager}
          allowClear={false}
          // userAutoCompleteType={userAutoCompleteTypeCodes.MENU}
          onChange={(user: IUserSearch) => {
            setEmployerDetails({
              ...employerDetails,
              accountManager: user.value,
            });
          }}
          usersData={managers}
          backgroundColor={'white'}
        />
      </View>


    </View>
  );
};

export default EmployerQuickCreateModal;
