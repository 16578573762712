import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import Svg, {Path} from 'react-native-svg';

const AssessmentGoalSvg = (props: ICommonSvgProps) => {
  const {customStrokeColor, size} = props;
  const strokeColor = customStrokeColor || '#6F7A90';

  return (
    <Svg width={size ? size : "16"} height={size ? size : "16"} viewBox="0 0 16 16" fill="none">
      <Path
        d="M12.12 5.35923L12.429 5.05027C12.9409 4.53837 13.7708 4.53837 14.2827 5.05027C14.7946 5.56217 14.7946 6.39212 14.2827 6.90403L13.9738 7.21299M12.12 5.35923C12.12 5.35923 12.1586 6.01577 12.7379 6.59507C13.3172 7.17437 13.9738 7.21299 13.9738 7.21299M12.12 5.35923L9.27961 8.19964C9.08722 8.39203 8.99103 8.48822 8.9083 8.59429C8.81071 8.7194 8.72705 8.85478 8.65878 8.99802C8.60091 9.11944 8.55789 9.2485 8.47185 9.50661L8.10727 10.6004M13.9738 7.21299L11.1334 10.0534C10.941 10.2458 10.8448 10.342 10.7387 10.4247C10.6136 10.5223 10.4782 10.606 10.335 10.6742C10.2136 10.7321 10.0845 10.7751 9.82639 10.8612L8.73264 11.2257M8.73264 11.2257L8.46528 11.3149C8.33826 11.3572 8.19822 11.3241 8.10354 11.2295C8.00887 11.1348 7.97581 10.9947 8.01815 10.8677L8.10727 10.6004M8.73264 11.2257L8.10727 10.6004M5.33333 8.66634H7M5.33333 5.99967H9.66667M5.33333 11.333H6.33333M13.219 2.11406C12.4379 1.33301 11.1808 1.33301 8.66667 1.33301H7.33333C4.81918 1.33301 3.5621 1.33301 2.78105 2.11406C2 2.89511 2 4.15218 2 6.66634V9.33301C2 11.8472 2 13.1042 2.78105 13.8853C3.5621 14.6663 4.81918 14.6663 7.33333 14.6663H8.66667C11.1808 14.6663 12.4379 14.6663 13.219 13.8853C13.8477 13.2566 13.9703 12.3194 13.9942 10.6663"
        stroke={strokeColor}
        stroke-linecap="round"
      />
    </Svg>
  );
};

export default React.memo(AssessmentGoalSvg);
