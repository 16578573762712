import {useLazyQuery, useMutation} from '@apollo/client';
import {notification} from 'antd';
import {useToast} from 'native-base';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {DISPLAY_DATE_FORMAT, EVENT_NAMES, MLOV_CATEGORY, TASK_TYPES} from '../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../constants/Configs';
import FeatureFlags from '../../constants/FeatureFlags.enums';
import {FHIR_RESOURCE} from '../../constants/FhirConstant';
import {ORDERED_TASK_PRIORITY_CODES_ASC, TASK_PRIORITY_CODES, TASK_STATUS, USER_ROLE_CODES} from '../../constants/MlovConst';
import {CommonDataContext} from '../../context/CommonDataContext';
import {LeadQueries, TaskQueries} from '../../services';
import CareStudioService from '../../services/CommonService/CareStudioService';
import {getDocumentRefByReferenceId} from '../../services/CommonService/OrderService';
import {GET_USER_FOR_TASKS} from '../../services/User/UserQueries';
import {getEhrConfig, getResourceAbilities} from '../../utils/capabilityUtils';
import {CaptureTransaction, TRANSACTION_NAMES} from '../../utils/CaptureTransaction';
import {filterWorkflowUser, getAccountUUID, getBooleanFeatureFlag, getUserActionObjectFromActionCode, getUserData, getUserName, getUserUUID} from '../../utils/commonUtils';
import {showToast, ToastType} from '../../utils/commonViewUtils';
import {getDateStrFromFormat, getDateToMomentISOString, getEndOfDay, getFormattedDate, getNormalTaskDates, isBeforeDate} from '../../utils/DateUtils';
import {EventBus} from '../../utils/EventBus';
import {getMlovListFromCategory} from '../../utils/mlovUtils';
import {isWeb} from '../../utils/platformCheckUtils';
import AddOrUpdateAlertForm from '../common/AddTask/AddEditTaskView/AddOrUpdateAlertForm';
import {ADD_SUBTASK_CONST, getCommentsVariables, getSelectedAccountLocations, getSingleSubTasksVariables, getSubTasksVariables, manageAttachmentsListData, SOURCE_MAP} from '../common/AddTask/AddTaskUtils';
import {isAllMandatoryFieldsFilled} from '../common/AddTask/CommonUtilsForWebAndNative';
import {IAddOrUpdateTasks, IAddOrUpdateTaskState, ISubTasks, ISubTasksRes} from '../common/AddTask/interfaces';
import {ParticipantType} from '../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {TASK_EVENTS} from '../common/CareDashboard/CareDashboardConstants';
import {ITask} from '../common/CareDashboard/CareDashboardInterfaces';
import {TASK_ASSIGNEE_TYPE_CODES} from '../common/CareDashboard/CareDashboardTable/CareDashboardTableHelper';
import {getCompletedTaskStatusId} from '../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import {IUserPatientSearchItem} from '../common/CustomComponents/CustomUserPatientSearch/CustomUserPatientSearch.native';
import {usePermissions} from '../CustomHooks/usePermissions';
import {INotesFormattedDataProps, IPatientNoteCardProps} from '../PersonOmniView/MiddleContainer/interfaces';
import {NoteEntry} from '../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import {getFormattedNoteForCareTimeline} from '../PersonOmniView/MiddleContainer/PatientNotes/PatientNotesHelper';
import {getFormDataFromLeadData} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {USER_ACCESS_PERMISSION} from '../RightSideContainer/UserAccess/UserAccessPermission';
import {insertUserAction} from '../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {MAIN_MENU_CODES} from '../SideMenuBar/SideBarConst';
import useCareGapAlert from './hooks/useCareGapAlert';
import {IMeasureIdentifier, IMeasureSteward} from './interfaces';
import {isDiagnosisGapAlertValid} from './SideCarHelpers/utils';

const AddOrUpdateAlertView = (props: IAddOrUpdateTasks) => {
  const {
    navigation,
    task,
    assignee,
    successMessage,
    extraData,
    member,
    onComplete,
    onCancel,
    onMarkAsComplete,
    fetchAllTypeTask,
    setFormHandler,
    alertType
  } = props;
  const eventBus = EventBus.getEventBusInstance();
  const editTask: ITask | undefined = task;
  const defaultAssignee: IUserPatientSearchItem | undefined = assignee;
  const defaultMember: IUserPatientSearchItem | undefined = member;
  const isEditTask = !!editTask?.id;
  const toast = useToast();
  const intl = useIntl();
  const currentUserId = getUserUUID();
  const currentUserData = getUserData();
  const accountUuid = getAccountUUID();
  const mlovData = React.useContext(CommonDataContext);
  const {accountLocationListWithEHR} = mlovData;
  const careGapTaskTypes = mlovData?.taskAlertTypes?.find((taskType) => taskType.isAlert && taskType.code === TASK_TYPES.CARE_GAPS);
  const diagnosisGapTaskTypes = mlovData?.taskAlertTypes?.find((taskType) => taskType.isAlert && taskType.code === TASK_TYPES.DIAGNOSIS_GAPS);
  const isCareGapAlert = alertType === TASK_TYPES.CARE_GAPS;
  const isDiagnosisGapAlert = alertType === TASK_TYPES.DIAGNOSIS_GAPS;
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const isSidecar = !!mlovData.sidecarContext?.isSidecar;
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, MAIN_MENU_CODES.TASKS);
  const allowedAccountLocations = accountLocationListWithEHR?.filter((location) => {
    const restrictAccountLocationId = props?.restrictPatientOrLeadSelectionTo?.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid;
    const allowedLocation = permissionConfig?.allowedLocationIds?.includes(location?.uuid);
    return props?.restrictPatientOrLeadSelectionTo && restrictAccountLocationId ? restrictAccountLocationId === location?.uuid : allowedLocation;
    // for leads having no location, we are showing all locations of user
  })?.map((location) => {
    return {
      ...location,
      uuid: location?.uuid,
      name: location?.practiceLocation?.name,
    }
  }) || [];

  const timeOutRefs = useRef<NodeJS.Timeout[]>([])
  const careStudioInstance = CareStudioService.getCareStudioServiceInstance();
  const captureTransactionInst = CaptureTransaction.getInstance();
  const fileUploadService = careStudioInstance.fileUploadService;
  const mediaUploadService = careStudioInstance.mediaUploadService;
  // is task created from care journey or form
  const currentUserName = getUserName();
  const userName = extraData?.userName?.length ? extraData?.userName == 'You' ? currentUserName : extraData?.userName : '';
  const titleText = userName.length ? `${userName} message sent on ${getFormattedDate(extraData?.dateTime || '', 'MM/DD/YYYY')}` : '';
  const careGapCategories = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.QUALITY_MEASURE_CATEGORY
  )
  let taskPriorityMlov = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_PRIORITY,
    false
  );
  taskPriorityMlov = taskPriorityMlov?.sort((currentMlov, nextMlov) => {
    const currentMlovScore = ORDERED_TASK_PRIORITY_CODES_ASC.indexOf(
      currentMlov.code
    );
    const nextMlovScore = ORDERED_TASK_PRIORITY_CODES_ASC.indexOf(
      nextMlov.code
    );
    return currentMlovScore - nextMlovScore;
  });
  const defaultTaskPriority = taskPriorityMlov?.find((mlov) => {
    const code = editTask?.priority?.code || TASK_PRIORITY_CODES.MEDIUM;
    if (mlov.code === code) return true;
  });
  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const defaultTaskStatus = taskStatusMlov?.find((mlov) => {
    if (editTask?.statusId) {
      return mlov.id === editTask?.statusId;
    }
    const code = editTask?.taskStatus?.code || TASK_STATUS.OPEN;
    return mlov.code === code;
  });
  const defaultStatusId = taskStatusMlov.find(status => status.code === TASK_STATUS.OPEN)?.id || '';
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const userActionObject = getUserActionObjectFromActionCode('ADD_NEW_TASK');
  const level = userActionObject.level || '';
  const {careGapAlertState, loading: careGapAlertLoading} = useCareGapAlert();
  const getDefaultAssignee = (): IAddOrUpdateTaskState['assignee'] => {
    if (!editTask?.id && extraData?.taskPool?.value) {
      return undefined;
    }
    if (defaultAssignee) {
      return defaultAssignee;
    } else if (editTask?.assigneeUser?.id) {
      return {
        value: editTask.assigneeUser.uuid,
        label: editTask.assigneeUser.name,
        key: editTask.assigneeUser.uuid,
        type: editTask.assigneeTypeCode === 'USER'
          ? ParticipantType.staff
          : editTask.assigneeUser.patient?.patientUuid
            ? ParticipantType.patient
            : ParticipantType.leads,
        details: editTask.assigneeUser,
      };
    }
    return {
      value: currentUserId,
      label: currentUserData.name,
      key: currentUserId,
      type: ParticipantType.staff,
      details: currentUserData,
    };
  };

  const getDefaultMember = () => {
    if (!!editTask?.id && editTask?.contact) {
      return {
        value: editTask.contact.uuid,
        label: editTask.contact.name,
        key: editTask.contact.uuid,
        type: editTask.contact.patient?.patientUuid
          ? ParticipantType.patient
          : ParticipantType.leads,
        details: editTask.contact,
      }
    } else {
      return defaultMember || undefined;
    }
  }

  const getDefaultAssignedBy = () => {
    if (editTask?.assignedBy?.id) {
      return {
        value: editTask.assignedBy.uuid,
        label: editTask.assignedBy.name,
        key: editTask.assignedBy.uuid,
        type: ParticipantType.staff,
        details: editTask.assignedBy,
      };
    }
    return {
      value: currentUserId,
      label: currentUserData.name,
      key: currentUserId,
      type: ParticipantType.staff,
      details: currentUserData,
    };
  };

  const getInitalData = () => {
    const initialState = {
      selectedAccountLocations: getSelectedAccountLocations({
        allowedAccountLocations,
        isMultiTenancyEnabled,
        editTask,
      }),
      title: titleText?.length ? titleText : editTask?.title || '',
      description: editTask?.description || extraData?.description || '',
      userId: extraData?.userId,
      userName: extraData?.userName,
      dateTime: extraData?.dateTime,
      assignee: getDefaultAssignee(),
      assignedBy: getDefaultAssignedBy(),
      isImportant: false,
      showErrors: false,
      showCommentError: false,
      showEditCommentError: false,
      isSaveClick: false,
      priority: {
        key: defaultTaskPriority?.id || '',
        value: defaultTaskPriority?.code || '',
        label: defaultTaskPriority?.value || '',
      },
      taskStatus: {
        key: defaultTaskStatus?.id || '',
        value: defaultTaskStatus?.code || '',
        label: defaultTaskStatus?.value || '',
      },
      dueDate: getDateStrFromFormat(
        editTask?.endDateTime || new Date(),
        DISPLAY_DATE_FORMAT
      ),
      workflowList: editTask?.referenceData?.workflowList || [],
      customer: getDefaultMember(),
      accountUsers: [],
      poolData: [],
      isMemberSelected: false,
      loading: true,
      fileList: editTask?.attachments,
      selectedLabels: undefined,
      subTaskChanged: false,
      isFormValid: false,
      isNeedToScrollDown: false,
    };
    return initialState;
  }
  const [componentState, setComponentState] = useState<IAddOrUpdateTaskState>(getInitalData());
  const resetComponentState = () => {
    setComponentState(getInitalData());
  };

  const ehrConfig = getEhrConfig(
    componentState?.selectedAccountLocations?.[0]?.uuid,
    ''
  );

  // ehrConfig and visitNote configuration use for get visit note data
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    componentState?.selectedAccountLocations?.[0]?.uuid
  );
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;


  const [createOrUpdateTask] = useMutation(TaskQueries.ADD_OR_UPDATE_TASK, {
    onCompleted: () => {
      eventBus.broadcastEvent(EVENT_NAMES.REFRESH_TASK, {
        assigneeId: componentState?.assignee?.value,
        memberContextId: componentState?.selectedContact?.uuid,
      });
    }
  });
  const [createSubTaskByParentTaskId] = useMutation(TaskQueries.ADD_SUB_TASK_BY_PARENT_ID, {
    onCompleted: () => {
      eventBus.broadcastEvent(EVENT_NAMES.NEW_TASK_ADDED, {
        assigneeId: componentState?.assignee?.value,
        memberContextId: componentState?.selectedContact?.uuid,
      });
    }
  });
  const [updateSubTask] = useMutation(TaskQueries.UPDATE_SUB_TASK, {
    onCompleted: (data) => {
      eventBus.broadcastEvent(TASK_EVENTS.SUB_TASK_UPDATED, {task: data?.updateTask});
      eventBus.broadcastEvent(EVENT_NAMES.NEW_TASK_ADDED, {
        assigneeId: componentState?.assignee?.value,
        memberContextId: componentState?.selectedContact?.uuid,
      });
    }
  });
  const [getSubTaskByIds] = useLazyQuery(TaskQueries.GET_SUB_TASK_ALERT, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getContactData] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',
  });

  const isValid = (subTasks?: ISubTasks[]) => {
    const updatedSubTasks = subTasks || componentState?.subTasks;
    return (
      (!isMultiTenancyEnabled || componentState?.selectedAccountLocations?.length) &&
      componentState.title.trim().length > 0 &&
      componentState.priority &&
      componentState?.dueDate &&
      !updatedSubTasks?.some((item) =>
        item?.endDateTime &&
        isBeforeDate(componentState?.dueDate, item?.endDateTime)) &&
      isDiagnosisGapAlertValid(isDiagnosisGapAlert, updatedSubTasks)
    );
  };

  const captureAddOrUpdateTaskTransaction = () => {
    const identifier = task?.id || 'new_task';
    captureTransactionInst.initiateTransaction({
      name: TRANSACTION_NAMES.TASK_ADD_OR_UPDATE,
      identifier: identifier,
    });
    captureTransactionInst.finishTransaction(
      TRANSACTION_NAMES.TASK_ADD_OR_UPDATE,
      identifier
    );
  }

  const createAttachments = async () => {
    const formData = new FormData();
    let files = [
      ...componentState?.fileList || [],
    ];
    files = files.filter(file =>
      (file?.source && !file?.externalAttachmentId) ||
      (!file?.source && !file?.id)
    );

    if (!files?.length) {
      return [];
    }
    const mediaFiles: any[] | undefined = [];
    files.forEach((file: any) => {
      if (file?.source) {
        mediaFiles.push({
          externalAttachmentId: file?.id,
          name: file?.name,
          isPreviewAvailable: true,
          source: file?.source,
          key: '',
          type: file?.type
        });
      } else {
        formData.append('files', file.originFileObj);
      }
    });
    try {
      const promises = [];

      if (formData && formData?.has('files')) {
        const resPromise = fileUploadService.post('', formData, {
          onUploadProgress: (progressEvent) => {
            const percent = Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setComponentState((prev) => ({...prev, progress: percent}));
            if (percent === 100) {
              const timeoutId = setTimeout(
                () => setComponentState((prev) => ({...prev, progress: 0})),
                3000
              );
              timeOutRefs.current.push(timeoutId);
            }
          },
        });
        promises.push(resPromise);
      }
      if (mediaFiles?.length) {
        const externalResPromise = mediaUploadService.post(
          '',
          {params: mediaFiles},
          {}
        );
        promises.push(externalResPromise);
      }
      const results = await Promise.all(promises);
      const attachmentIds = results?.flatMap(result => result?.data?.ids);

      return attachmentIds || [];
    }
    catch (err) {
      setComponentState((prev) => ({...prev, isLoading: false}));
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  }
  const onAddTask = async (data?: IAddOrUpdateTaskState) => {
    setComponentState((prev) => ({
      ...prev,
      isSaveClick: true,
    }))
    let taskData = componentState;
    if (data) {
      taskData = data;
    }
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (!!componentState.localCommentText) {
      setComponentState((prev) => ({
        ...prev,
        showCommentError: true,
        isNeedToScrollDown: !componentState.isNeedToScrollDown
      }))
      return;
    }
    if (!!componentState.localEditCommentText) {
      setComponentState((prev) => ({
        ...prev,
        showEditCommentError: true,
        isNeedToScrollDown: !componentState.isNeedToScrollDown
      }))
      return;
    }

    if (isValid()) {
      if (componentState.fileList?.length) {
        const invalidFile = componentState.fileList.find(file => !(file as any)?.id && (file?.size / (1000 * 1000) > 5));
        if (invalidFile) {
          notification.error({
            message: intl.formatMessage({
              id: 'patientTaskUploadDocumentSupportAllFiles',
            }),
            duration: 2.0,
            placement: 'top'
          });
          return;
        }
      }
      setComponentState((prev) => ({...prev, loading: true}));
      captureAddOrUpdateTaskTransaction();
      const {startDate, endDate} = taskData.dueDate ? getNormalTaskDates(taskData.dueDate) : {startDate: '', endDate: ''};
      let attachmentsData = (componentState?.fileList || [])?.filter(file =>
        (file?.source && file?.externalAttachmentId) ||
        (!file?.source && file?.id)
      );
      attachmentsData = attachmentsData?.map((item: any) => {
        if (item?.id) {
          return {
            isDeleted: item?.isDeleted || false,
            attachmentId: item.attachmentId,
            id: item.id,
          };
        } else {
          return item;
        }
      });
      if (componentState.fileList?.length) {
        const attachmentIds = await createAttachments();
        attachmentIds?.map((attachmentId: string) => {
          attachmentsData.push({
            attachmentId: attachmentId,
          })
        });
      }
      const getTaskLocations = () => {
        // passing isDeleted as true to all the allowed locations other than selected location
        const deletedLocations = editTask?.taskLocations?.filter(allowedLocation => {
          return allowedLocation?.locationId !== taskData?.selectedAccountLocations?.[0]?.uuid
        })?.map(deletedLocation => ({
          id: deletedLocation?.id,
          locationId: deletedLocation?.locationId,
          isDeleted: true
        })) || [];

        const selectedTaskLocations = taskData?.selectedAccountLocations?.map((location) => {
          const matchedLocation = editTask?.taskLocations?.find((taskLocation) => taskLocation?.locationId === location?.uuid);
          return {
            locationId: location?.uuid,
            ...(matchedLocation?.id && {id: matchedLocation?.id}),
          }
        }) || [];

        return !isMultiTenancyEnabled ? (
          []
        ) : [
          ...selectedTaskLocations,
          ...deletedLocations
        ]
      }
      const taskTypeId = alertType === TASK_TYPES.DIAGNOSIS_GAPS ? diagnosisGapTaskTypes?.id : careGapTaskTypes?.id;
      let addTaskVariables: any = {
        title: taskData.title,
        taskTypeId: taskTypeId,
        description: taskData.description,
         // remove as of temporary taskData.assignee?.value || '',
        // assigneeId: taskData.assignee?.value || ''
        assigneeTypeCode: TASK_ASSIGNEE_TYPE_CODES.CARE_TEAM,
        assignedById: taskData.assignedBy?.value || '',
        startDateTime: startDate,
        endDateTime: endDate,
        statusId: componentState.statusId || editTask?.statusId || defaultStatusId,
        priorityId: taskData.priority?.key,
        isEscalated: false,
        contactId: taskData.customer?.value || props?.restrictPatientOrLeadSelectionTo?.key || null,
        id: editTask?.id || undefined,
        attachments: attachmentsData,
        referenceData: {...editTask?.referenceData, workflowList: taskData?.workflowList || []},
        taskLocations: getTaskLocations()
      };
      if (isCareGapAlert && (componentState?.measureIdentifiers?.key ||
          componentState?.measureSteward?.key ||
          componentState?.careGapCategoryId ||
          componentState?.evaluationDate ||
          componentState?.evidence)) {

        const categoryId = careGapCategories.find(
          category => category?.value === componentState?.careGapCategoryId
        )?.id;

        addTaskVariables = {
          ...addTaskVariables,
          taskCareGapDetail: {
            ...(componentState?.taskCareGapDetailId && {
              id: componentState.taskCareGapDetailId
            }),
            ...(componentState?.measureIdentifiers?.label && {
              measureIdentifiersId: componentState.measureIdentifiers.key,
              measureIdentifierName: componentState.measureIdentifiers.label
            }),
            ...(componentState?.measureSteward?.key && {
              measureStewardId: componentState.measureSteward.key
            }),
            ...(componentState?.careGapCategoryId && {
              categoryId: categoryId
            }),
            evaluationDateTime: componentState?.evaluationDate ?
              getDateToMomentISOString(new Date(componentState.evaluationDate)) :
              null,
            ...(componentState?.evidence && {
              evidenceDescription: componentState.evidence
            })
          }
        };
      }
      if (isDiagnosisGapAlert) {
        addTaskVariables = {
          ...addTaskVariables,
          taskDiagnosisGapDetail: {
            ...(componentState?.taskDiagnosisGapDetailId && {
              id: componentState.taskDiagnosisGapDetailId
            }),
            hccCategoryId: componentState?.hccCategoryId,
            evidenceDescription: componentState?.evidence,
          }
        }
      }
      if (!editTask?.id || editTask?.assigneeTypeCode === 'CONTACT') {
        const subTasksVariables = getSubTasksVariables({taskData, taskStatusMlov, taskType: alertType});
        addTaskVariables = {
          ...addTaskVariables,
          subTasks: subTasksVariables,
        }
      }

      if (!editTask?.id) {
        const commentsVariables = getCommentsVariables(taskData);
        addTaskVariables = {
          ...addTaskVariables,
          comments: commentsVariables,
        }
      }

      createOrUpdateTask({
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        variables: {
          data: addTaskVariables,
        },
        onCompleted: async (data) => {
          if (!isEditTask) {
            insertUserAction({
              userId: currentUserId,
              actionCode: 'ADD_NEW_TASK',
              status: 'COMPLETED',
              level: level,
            })
              .catch(() => {

              });
            if (isWeb()) {
              const response: any = await onComplete?.(
                data,
                taskData.taskDisplayCategoryId
              );
              if (!response || response?.isTaskVisible) {
                notification.destroy();
                notification.success({
                  message: intl.formatMessage({
                    id: (successMessage || 'taskAddedMsg'),
                  }),
                  duration: 3.0,
                  placement: 'top'
                });
              }

            }
          } else {
            onComplete?.(data, taskData.taskDisplayCategoryId);
          }

          const newTask = {
            ...data?.addOrUpdateTask,
            assigneeId: componentState.assignee?.value,
            assigneeUser: {
              uuid: componentState.assignee?.value,
              name: componentState.assignee?.label,
              assigneeType: componentState.assignee?.type,
              id: componentState?.assignee?.details?.id,
              ...componentState.assignee
            },
          };
          eventBus.broadcastEvent(isEditTask ? TASK_EVENTS.TASK_UPDATED : TASK_EVENTS.TASK_ADDED, {task: newTask});
          setComponentState((prev) => ({...prev, loading: false}));
          if (isEditTask) {
            notification.success({
              message: intl.formatMessage({
                id: isEditTask ? 'taskUpdatedMsg' : (successMessage || 'taskAddedMsg'),
              }),
              duration: 3.0,
              placement: 'top'
            });
          }
          eventBus.broadcastEvent(EVENT_NAMES.NEW_TASK_ADDED, {assigneeId: taskData?.assignee?.value, memberContextId: taskData?.selectedContact?.uuid});
        },
        onError: () => {
          setComponentState((prev) => ({...prev, loading: false}));
          notification.error({
            message: intl.formatMessage({
              id: 'apiErrorMsg',
            }),
            duration: 3.0,
            placement: 'top'
          });
        },
      });
    } else {
      const message = intl.formatMessage({id: 'mandatoryFieldError'});
      const dateCompareMessage = intl.formatMessage({id: 'TaskSubtaskDueDateError'});
      const compareDate = componentState?.subTasks?.some((item) => item?.endDateTime && isBeforeDate(componentState?.dueDate, item?.endDateTime))
      if (isWeb()) {
        notification.destroy()
        !isAllMandatoryFieldsFilled(componentState, isMultiTenancyEnabled, isDiagnosisGapAlert) && notification.error({
          message,
          placement: 'top'
        })
        compareDate && notification.error({
          message: dateCompareMessage,
          placement: 'top'
        })
      }
    }
  };

  const formHandler = useCallback(() => {
    return onAddTask();
  }, [componentState]);

  useEffect(() => {
    setFormHandler(formHandler);
  }, [setFormHandler, formHandler]);



  const [getAccountUsers] = useLazyQuery(GET_USER_FOR_TASKS, {
    fetchPolicy: 'no-cache',
  });

  const [getLinkNoteWithTask] = useLazyQuery(TaskQueries.GET_TASK_LINK_NOTE, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const getCarePlanLinkedWithTask = getLinkNoteWithTask;

  const getUserFormattedList = (users: any[]) => {
    const filterUsers = filterWorkflowUser(users || [], currentUserId);
    return (filterUsers || []).map((user: any) => {
      return {
        ...user,
        userName: user.name,
        userId: user.uuid,
        email: user.email || '',
      };
    });
  };

  const getPatientNoteViewByResourceData = async (resourceId: any, accountUserList: any[], elationFormData: any, formmatedContactData: any) => {
    let locationUuid = (formmatedContactData || props.personData)?.accountLocationUuid;

    if (isMultiTenancyEnabled && !locationUuid) {
      locationUuid = componentState?.selectedAccountLocations?.[0]?.uuid
    }

    const response = await getDocumentRefByReferenceId(`${resourceId}`, foldVisitNoteWithEncountersEnabled, locationUuid);
    const noteEntry: NoteEntry = {resource: response?.data} as NoteEntry;
    const formatResponseParam: INotesFormattedDataProps = {
      noteResponse: [noteEntry],
      ehrConfig,
      loginUserId: currentUserData?.uuid,
      contextData: mlovData,
      accountUserList: accountUserList || [],
      elationFormData: elationFormData,
      additionalFlags: {
        foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled
      }
    };
    return getFormattedNoteForCareTimeline(formatResponseParam);
  }

  const fetchInitialTaskDetail = async () => {
    try {
      const accountUserParams = {accountUUID: accountUuid, roleCodes: [USER_ROLE_CODES.EMPLOYER]};

      const promiseList = [
        getAccountUsers({variables: accountUserParams}),
      ];
      if (isSidecar) {
        promiseList.push(getContactData({variables: {id: editTask?.contact?.id}}))
      } else {
        promiseList.push(Promise.resolve({data: {contact: {}}}) as any);
      }
      const apiResponse = await Promise.all(promiseList);
      const accountUserResp = apiResponse[0]?.data?.users;
      const accountUsers = getUserFormattedList(accountUserResp || []);
      const contactData = apiResponse[1]?.data?.contact || {};
      const formmatedContactData = isSidecar ? getFormDataFromLeadData(contactData, mlovData) : undefined;

      const isTaskLinkWithNote = editTask?.resourceMap && editTask.resourceMap?.notes || false;
      const isCarePlanContextTask = editTask?.resourceMap && editTask.resourceMap?.carePlan || false;

      let linkPatientNote: IPatientNoteCardProps = {} as IPatientNoteCardProps;
      if (isTaskLinkWithNote) {
        const taskNoteResourceMapParam = {resourceId: editTask?.id, sourceTypeCode: SOURCE_MAP.NOTES};
        const resourceMapResponse = await getLinkNoteWithTask({variables: taskNoteResourceMapParam});
        const resourceMappings = resourceMapResponse?.data?.resourceMappings || [];
        const noteId = resourceMappings?.length ? resourceMappings?.[0]?.sourceId : undefined;
        if (noteId) {
          const notesResponse: IPatientNoteCardProps[] = await getPatientNoteViewByResourceData(noteId, accountUsers, {}, formmatedContactData);
          linkPatientNote = notesResponse?.length ? notesResponse?.[0] : {} as IPatientNoteCardProps;
        }
      }
      let linkedCarePlanId: string | undefined = undefined;
      if (isCarePlanContextTask) {
        const carePlanMappingParam = {resourceId: editTask?.id, sourceTypeCode: SOURCE_MAP.CARE_PLAN};
        const carePlanResourceMapResponse = await getCarePlanLinkedWithTask({variables: carePlanMappingParam});
        const resourceMappings = carePlanResourceMapResponse?.data?.resourceMappings || [];
        linkedCarePlanId = resourceMappings?.length ? resourceMappings?.[0]?.sourceId : undefined;;
      };
      setComponentState(prev => {
        return {
          ...prev,
          loading: false,
          accountUsers,
          labelsLoading: false,
          linkPatientNote: {...linkPatientNote, contactId: editTask?.contact?.id},
          linkedCarePlanId: linkedCarePlanId,
        }
      });
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        labelsLoading: false,
      }));
    }
  }

  useEffect(() => {
    if (!componentState.accountUsers?.length) {
      setComponentState((prev) => ({
        ...prev,
        loading: true,
      }));
      fetchInitialTaskDetail();
    }
  }, []);

  useEffect(() => {
    const valid = !!isValid();
    setComponentState((prev) => ({
      ...prev,
      isFormValid: valid,
    }));
  }, [componentState.title, componentState.priority, componentState.assignedBy, componentState.assignee, componentState.dueDate, componentState.selectedAccountLocations, componentState.statusId]);
  const getDropdownOption = (items: any[] = [], itemId?: string, nameKey = 'value') => {
    const item = items.find(i => i.id === itemId);
    return {
      key: item?.id ?? '',
      value: item?.[nameKey] ?? '',
      label: item?.[nameKey] ?? ''
    };
  };

  const getMeasureIdentifiersById = (measureIdentifiersId: string | undefined, measureIdentifiers: IMeasureIdentifier[]) => {
    const selectedMeasureIdentifier = measureIdentifiers.find(item => item.id === measureIdentifiersId);
    return {
      key: selectedMeasureIdentifier?.id ?? '',
      value: selectedMeasureIdentifier?.measureName ?? '',
      label: selectedMeasureIdentifier?.measureName ?? ''
    }
  }

  const getMeasureStewardsById = (measureStewardsId: string | undefined, measureStewards: IMeasureSteward[]) => {
    const selectedMeasureSteward = measureStewards.find(item => item.id === measureStewardsId);
    return {
      key: selectedMeasureSteward?.id ?? '',
      value: selectedMeasureSteward?.measureStewardName ?? '',
      label: selectedMeasureSteward?.measureStewardName ?? ''
    }
  }
  useEffect(() => {
    const {measureIdentifiers, measureStewards} = careGapAlertState;
    const taskCareGapDetail = editTask?.taskCareGapDetail;
    const taskDiagnosisGapDetail = editTask?.taskDiagnosisGapDetail;

    const selectedCategory = careGapCategories.find((category) =>
      category?.id === editTask?.taskCareGapDetail?.measureIdentifiers?.categoryId
    );
    setComponentState((prev) => {
      return {
        ...prev,
        hccCategoryId: editTask?.taskDiagnosisGapDetail?.hccCategoryId,
        taskDiagnosisGapDetailId: editTask?.taskDiagnosisGapDetail?.id,
        measureIdentifiers: getMeasureIdentifiersById(
          taskCareGapDetail?.measureIdentifiersId,
          measureIdentifiers
        ),
        measureSteward: getMeasureStewardsById(
          taskCareGapDetail?.measureIdentifiers?.measureStewardId,
          measureStewards
        ),
        taskCareGapDetailId: taskCareGapDetail?.id,
        evaluationDate: getDateStrFromFormat(
          taskCareGapDetail?.evaluationDateTime ?? undefined,
          DISPLAY_DATE_FORMAT
        ),
        evidence: taskCareGapDetail?.evidenceDescription || taskDiagnosisGapDetail?.evidenceDescription,
        careGapCategoryId: selectedCategory?.value,
        labelsLoading: prev?.labelsLoading,
        title: titleText?.length ? titleText : editTask?.title ?? '',
        description: editTask?.description ?? extraData?.description ?? '',
        userId: extraData?.userId,
        userName: extraData?.userName,
        dateTime: extraData?.dateTime,
        assignee: getDefaultAssignee(),
        assignedBy: getDefaultAssignedBy(),
        isImportant: false,
        showErrors: false,
        priority: {
          key: defaultTaskPriority?.id ?? '',
          value: defaultTaskPriority?.code ?? '',
          label: defaultTaskPriority?.value ?? '',
        },
        dueDate: getDateStrFromFormat(
          editTask?.endDateTime ?? new Date(),
          DISPLAY_DATE_FORMAT
        ),
        customer: getDefaultMember(),
        accountUsers: [],
        poolData: [],
        isMemberSelected: false,
        selectedContact: editTask?.contact,
        fileList: editTask?.attachments,
        selectedAccountLocations: getSelectedAccountLocations({
          allowedAccountLocations,
          isMultiTenancyEnabled,
          editTask
        }),
      }
    });

    if (isEditTask && props.task?.id && props.task?.subTasks?.length) {
      getSubTask(props.task);
    }
  }, [props.task?.id, props.assignee?.value, careGapAlertLoading]);

  const getSubTask = async (task: ITask) => {
    const subTasksId = task.subTasks?.map((item) => item.id.toString());
    setComponentState((prev) => ({...prev, loading: true}));
    const response = await getSubTaskByIds({
      variables: {
        ids: subTasksId,
      },
    });
    if (response?.data?.getTasks?.tasks?.length) {
      const subTaskRes = response.data.getTasks.tasks;
      const updatedItems = subTaskRes.map((item: ISubTasksRes) => ({
        ...item,
        isChecked: item.statusId === completedStatusId ? true : false,
        assignee: {
          value: item?.assigneeUser?.uuid,
          label: item?.assigneeUser?.name,
          key: item?.assigneeUser?.uuid,
          details: item?.assigneeUser,
        },
        taskDiagnosisGapDetail: item?.taskDiagnosisGapDetail,
      }));
      setComponentState((prev) => {
        return {
          ...prev,
          subTasks: updatedItems,
        };
      });
    }
    setComponentState((prev) => ({...prev, loading: false}));
  };


  const setSubTasksDataState = (data: ISubTasks[]) => {
    setComponentState((prev) => {
      const updatedSubTasks = [...(prev.subTasks || []), ...data]
      const valid = !!isValid(updatedSubTasks);
      return {
        ...prev,
        subTasks: [...(prev.subTasks || []), ...data],
        isFormValid: valid,
      };
    });
  };

  const handleDeleteSubtask = async (data: ISubTasks) => {
    const itemId = data?.id || data.tempId;
    const newArry = componentState.subTasks?.filter(
      (item) => (item?.id || item.tempId) !== itemId
    );

    if (data?.id?.length) {
      setComponentState((prev) => {
        return {
          ...prev,
          subTaskLoading: true,
        };
      });
      //call api
      const updateStatus = await updateSubTask({
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        variables: {
          params: {
            id: data?.id,
            data: {
              isDeleted: true,
            },
          },
        },
      });
      if (updateStatus?.data?.updateTask?.id) {

        notification.success({
          message: 'Deleted successfully',
          duration: 3.0,
          placement: 'top'
        });
      }
    }
    setComponentState((prev) => {
      const valid = !!isValid(newArry);
      return {
        ...prev,
        subTasks: newArry,
        subTaskLoading: false,
        isFormValid: valid,
      };
    });
  };

  const handleMarkCompleteSubtask = async (data: ISubTasks) => {
    const itemId = data?.id || data.tempId;
    const itemIndex =
      componentState.subTasks?.findIndex(
        (item) => (item?.id || item.tempId) === itemId
      ) || 0;
    if (itemIndex !== -1) {
      const tempSubData = componentState.subTasks || [];
      const checkStatus = !tempSubData?.[itemIndex]?.isChecked;

      if (data?.id?.length && data?.assignee?.key) {
        setComponentState((prev) => {
          return {
            ...prev,
            subTaskLoading: true,
          };
        });
        //call api
        let taskStatusId = '';
        if (checkStatus) {
          taskStatusId = completedStatusId;
        } else {
          taskStatusId = defaultStatusId;
        }
        const updateStatus = await updateSubTask({
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          variables: {
            params: {
              id: data?.id,
              data: {
                statusId: taskStatusId,
              },
            },
          },
        });
        if (updateStatus?.data?.updateTask?.id) {
          const updatedItem: ISubTasks = {
            ...tempSubData[itemIndex],
            isChecked: checkStatus,
            statusId: checkStatus ? completedStatusId : defaultStatusId
          };
          tempSubData[itemIndex] = updatedItem;
          setComponentState((prev) => {
            const valid = !!isValid(tempSubData);
            return {
              ...prev,
              subTasks: tempSubData,
              isFormValid: valid,
            };
          });
          notification.success({
            message: intl.formatMessage({
              id: 'subtaskStatusUpdated',
            }),
            duration: 3.0,
            placement: 'top'
          });
        } else {
          notification.error({
            message: intl.formatMessage({
              id: 'apiErrorMsg',
            }),
            duration: 3.0,
            placement: 'top'
          });
        }
      }

      setComponentState((prev) => {
        return {
          ...prev,
          subTaskLoading: false,
        };
      });
    }
  };

  const handleUpdateTitleSubtask = async (subTaskData: ISubTasks) => {
    if (!subTaskData?.title?.length) {
      return;
    }
    const itemId = subTaskData?.id || subTaskData.tempId;
    const itemIndex =
      componentState.subTasks?.findIndex(
        (item) => (item?.id || item.tempId) === itemId
      ) || 0;
    if (itemIndex !== -1) {

      const tempSubData = componentState.subTasks || [];
      tempSubData[itemIndex].title = subTaskData.title;
      tempSubData[itemIndex].taskDiagnosisGapDetail = {
        ...subTaskData.taskDiagnosisGapDetail,
        diagnosisCode: subTaskData.taskDiagnosisGapDetail?.diagnosisCode,
        diagnosisSystemCode: subTaskData.taskDiagnosisGapDetail?.diagnosisSystemCode,
        hccCategoryId: subTaskData.taskDiagnosisGapDetail?.hccCategoryId
      };
      
      if (subTaskData?.id?.length && isEditTask && editTask?.assigneeTypeCode !== 'CONTACT') {
        setComponentState((prev) => {
          return {
            ...prev,
            subTaskLoading: true,
          };
        });
        //call api
        const updateStatus = await updateSubTask({
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          variables: {
            params: {
              id: subTaskData?.id,
              data: {
                title: subTaskData.title,
                taskDiagnosisGapDetail: tempSubData[itemIndex].taskDiagnosisGapDetail,
              },
            },
          },
        });
        if (updateStatus?.data?.updateTask?.id) {
          notification.success({
            message: 'Updated successfully',
            duration: 3.0,
            placement: 'top'
          });
        }
      } else if (!subTaskData?.id && isEditTask && (editTask?.assigneeTypeCode !== 'CONTACT') ) {
        setComponentState((prev) => {
          return {
            ...prev,
            subTaskLoading: true,
          };
        });
        const taskData = componentState;
        const subTasksVariables = getSingleSubTasksVariables(
          taskData,
          subTaskData,
          taskStatusMlov
        );
        const newSubTaskFinalVar = {
          ...subTasksVariables?.[0],
          parentId: editTask.id,
        };

        const newSubTaskRes = await createSubTaskByParentTaskId({
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          variables: {
            data: newSubTaskFinalVar,
          },
        });
        const newSubTaskData = newSubTaskRes?.data?.addOrUpdateTask;
        const updateSubTask = {
          ...subTaskData,
          id: newSubTaskData.id,
          isChecked: newSubTaskData.statusId === completedStatusId ? true : false,
        };
        tempSubData[itemIndex] = updateSubTask;
        setComponentState((prev) => {
          const valid = !!isValid(tempSubData);
          return {
            ...prev,
            subTasks: tempSubData,
            isFormValid: valid,
          };
        });
        eventBus.broadcastEvent(subTaskData.id ? TASK_EVENTS.SUB_TASK_UPDATED : TASK_EVENTS.SUB_TASK_ADDED, {task: subTasksVariables});
        if (newSubTaskData?.id) {
          notification.success({
            message: 'Added successfully',
            duration: 3.0,
            placement: 'top'
          });
        }
      } else {
        subTaskData.taskDiagnosisGapDetail = tempSubData[itemIndex].taskDiagnosisGapDetail;
        tempSubData[itemIndex] = subTaskData;
        setComponentState((prev) => {
          const valid = !!isValid(tempSubData);
          return {
            ...prev,
            subTasks: tempSubData,
            isFormValid: valid,
          };
        });
      }
      setComponentState((prev) => {
        return {
          ...prev,
          subTaskLoading: false,
        };
      });
    }
  };
  const handleUpdateEvidenceSubtask = async (subTaskData: ISubTasks) => {
    const itemId = subTaskData?.id || subTaskData.tempId;
    const itemIndex =
      componentState.subTasks?.findIndex(
        (item) => (item?.id || item.tempId) === itemId
      ) || 0;
    if (itemIndex !== -1) {
      const tempSubData = componentState.subTasks || [];
      tempSubData[itemIndex] = subTaskData;
      if (subTaskData?.id) {
        const updateTaskDiagnosisGapDetail={ 
          ...( subTaskData?.taskDiagnosisGapDetail?.id && {id: subTaskData?.taskDiagnosisGapDetail?.id}),
          evidenceDescription: subTaskData?.taskDiagnosisGapDetail?.evidenceDescription,
          diagnosisCode: subTaskData?.taskDiagnosisGapDetail?.diagnosisCode,
          diagnosisSystemCode: subTaskData?.taskDiagnosisGapDetail?.diagnosisSystemCode,
          hccCategoryId: subTaskData?.taskDiagnosisGapDetail?.hccCategoryId
        }
        setComponentState((prev) => {
          return {
            ...prev,
            subTaskLoading: true,
          };
        });
        //call api
        const updateStatus = await updateSubTask({
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          variables: {
            params: {
              id: subTaskData?.id,
              data: {
                taskDiagnosisGapDetail: updateTaskDiagnosisGapDetail,
              },
            },
          },
        });
        if (updateStatus?.data?.updateTask?.id) {
          notification.success({
            message: 'Updated successfully',
            duration: 3.0,
            placement: 'top'
          });
        }
        setComponentState((prev) => {
          const valid = !!isValid(tempSubData);
          return {
            ...prev,
            subTasks: tempSubData,
            isFormValid: valid,
          };
        });
      } else {
        setComponentState((prev) => {
          const valid = !!isValid(tempSubData);
          return {
            ...prev,
            subTasks: tempSubData,
            isFormValid: valid,
          };
        });
      }
    }
  }
  const handleUpdateChangeAssigneeSubtask = async (data: ISubTasks) => {
    const itemId = data?.id || data.tempId;
    const itemIndex =
      componentState.subTasks?.findIndex(
        (item) => (item?.id || item.tempId) === itemId
      ) || 0;
    if (itemIndex !== -1) {
      const tempSubData = componentState.subTasks || [];
      tempSubData[itemIndex] = data;
      setComponentState((prev) => {
        const valid = !!isValid(tempSubData);
        return {
          ...prev,
          subTasks: tempSubData,
          isFormValid: valid,
        };
      });
      if (data?.id?.length && data?.assignee?.key) {
        const timeoutId = setTimeout(() => {
          setComponentState((prev) => {
            return {
              ...prev,
              subTaskLoading: true,
            };
          });
        }, 10);
        timeOutRefs.current.push(timeoutId);
        //call api
        const updateStatus = await updateSubTask({
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          variables: {
            params: {
              id: data?.id,
              data: {
                assigneeId: data?.assignee?.value,
              },
            },
          },
        });
        if (updateStatus?.data?.updateTask?.id) {
          notification.success({
            message: intl.formatMessage({
              id: 'subtaskAssignedUpdated',
            }),
            duration: 3.0,
            placement: 'top'
          });
        }
      }
      const timeoutId = setTimeout(() => {
        setComponentState((prev) => {
          return {
            ...prev,
            subTaskLoading: false,
          };
        });
      }, 10);
      timeOutRefs.current.push(timeoutId);
    }
  };

  const handleUpdateDueDateSubtask = async (data: ISubTasks) => {
    const itemId = data?.id || data.tempId;
    const itemIndex =
      componentState.subTasks?.findIndex(
        (item) => (item?.id || item.tempId) === itemId
      ) || 0;
    if (itemIndex !== -1) {
      const tempSubData = componentState.subTasks || [];
      tempSubData[itemIndex] = data;
      setComponentState((prev) => {
        const valid = !!isValid(tempSubData);
        return {
          ...prev,
          subTasks: tempSubData,
          isFormValid: valid,
        };
      });

      if (data?.id?.length) {
        setComponentState((prev) => {
          return {
            ...prev,
            subTaskLoading: true,
          };
        });
        //call api
        const endDateTime = getEndOfDay(data?.endDateTime)
        const endDate = getDateToMomentISOString(endDateTime);
        const updateStatus = await updateSubTask({
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          variables: {
            params: {
              id: data?.id,
              data: {
                endDateTime: endDate,
              },
            },
          },
        });
        if (updateStatus?.data?.updateTask?.id) {
          notification.success({
            message: intl.formatMessage({
              id: 'subtaskDueDateUpdated',
            }),
            duration: 3.0,
            placement: 'top'
          });
        }
      }

      setComponentState((prev) => {
        return {
          ...prev,
          subTaskLoading: false,
        };
      });
    }
  };

  const handleSubTaskChange = (actionCode: string, actionData: any) => {
    setComponentState((prev) => {
      return {
        ...prev,
        subTaskChanged: true
      }
    })
    switch (actionCode) {
      case ADD_SUBTASK_CONST.ADD_NEW:
        setSubTasksDataState(actionData)
        break;
      case ADD_SUBTASK_CONST.DELETE:
        handleDeleteSubtask(actionData)
        break;
      case ADD_SUBTASK_CONST.MARK_COMPLETE:
        handleMarkCompleteSubtask(actionData)
        break;
      case ADD_SUBTASK_CONST.UPDATE_TITLE:
        handleUpdateTitleSubtask(actionData)
        break;
      case ADD_SUBTASK_CONST.DUE_DATE:
        handleUpdateDueDateSubtask(actionData)
        break;
      case ADD_SUBTASK_CONST.CHANGE_ASSIGNEE:
        handleUpdateChangeAssigneeSubtask(actionData)
        break;
      case ADD_SUBTASK_CONST.EVIDENCE:
        handleUpdateEvidenceSubtask(actionData)
        break;
      default:
        return;
    }
  };


  return (
    <>
      <AddOrUpdateAlertForm
        loading={
          componentState.loading === true ||
          componentState?.loading === undefined ||
          careGapAlertLoading
        }
        value={componentState}
        measureStewardsAndMeasureIdentifiers={careGapAlertState}
        task={task}
        taskPriorityMlov={taskPriorityMlov}
        onAddTask={onAddTask}
        defaultAssignee={defaultAssignee}
        handleChange={(data: IAddOrUpdateTaskState): void => {
          setComponentState({
            ...data,
          });
        }}
        onCancel={(): void => {
        }}
        accountUsers={componentState.accountUsers}
        taskPools={componentState.taskPools}
        handleSubTaskChange={handleSubTaskChange}
        subTasks={componentState.subTasks}
        subTaskLoading={componentState.subTaskLoading}
        fileList={manageAttachmentsListData(componentState?.fileList || [])}
        fetchAllTypeTask={fetchAllTypeTask}
        linkPatientNote={componentState.linkPatientNote}
        allowedAccountLocations={allowedAccountLocations}
        alertType={alertType}
      />
    </>
  );
};

export default AddOrUpdateAlertView;
