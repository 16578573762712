import Stack from '../LayoutComponents/Stack';
import { Colors } from '../../../styles';
import { InputNumber, Select } from 'antd';
import { IInterventionMlovs, IMlov } from '../../../Interfaces';
import { CaretDownOutlined } from '@ant-design/icons';

interface IDateTimeDurationInputProps {
  value: string | undefined;
  durationUnits: IMlov[] | IInterventionMlovs[];
  selectedDurationUnitId: string | undefined;
  onDurationUnitChange?: (unitId: string) => void;
  onValueChange?: (value: string) => void;
  placeholder?: string
  borderColor?: string
  borderWidth?: number
  suffixIcon?: JSX.Element;
  inputFieldWidth?: number;
  hideInputField?: boolean;
  dropdownPlacement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
}

export const DateTimeDurationInput = (props: IDateTimeDurationInputProps) => {
  const {
    durationUnits,
    selectedDurationUnitId,
    value,
    onValueChange,
    onDurationUnitChange,
    placeholder,
    borderColor,
    borderWidth,
    suffixIcon,
    inputFieldWidth = 32
  } = props;

  const getDurationOptions = () => {
    return durationUnits.map((unit) => {
      return {
        value: unit.id,
        label: unit.value,
      };
    });
  };

  return (
    <Stack
      direction="row"
      style={{
        borderColor: !!props.hideInputField ? 'transparent' : (borderColor || Colors.Custom.PrimaryColor),
        borderWidth: !!props.hideInputField ? 0 : (borderWidth || 0.5),
        backgroundColor: 'white',
        borderRadius: 5,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {props?.hideInputField ?
      <></>
      :
      <InputNumber
        value={value}
        style={{
          width: inputFieldWidth,
          flex: 1,
        }}
        placeholder={placeholder}
        controls={false}
        min={"0"}
        size='small'
        bordered={false}
        onChange={(value) => {
          onValueChange && value && onValueChange(value.toString());
        }}
      />}
      <Select
        style={{
          borderWidth: !!props.hideInputField ? borderWidth || 1 : 0,
          borderColor: borderColor || Colors.Custom.Gray300,
          backgroundColor: !!props.hideInputField ? undefined : 'white',
          borderRadius: !!props.hideInputField ? 4 : 4,
          flex: 1,
        }}
        size='small'
        {...(props?.dropdownPlacement && {placement: props?.dropdownPlacement})}
        {...(suffixIcon && { suffixIcon: suffixIcon })}
        bordered={!!props.hideInputField}
        options={getDurationOptions()}
        value={selectedDurationUnitId}
        onSelect={onDurationUnitChange}
      />
    </Stack>
  );
};
