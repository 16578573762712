import React, { useContext, useState } from 'react';
import { IKeyPointData, useCareProgramAdditionalData } from '../../../CareManagementView/CareManagementViewHooks/useCareProgramAdditionalData';
import { usePersonOmniViewContext } from '../../../../PersonOmniView/PersonOmniView.context';
import { ADDITIONAL_DATA_CODE, AWV_TYPE_CODES, CARE_PROGRAM_VIEW } from '../../../CareManagementBilling/CareManagementConstants';
import { Text } from 'native-base';
import { FlatList, Platform, Pressable, StyleSheet } from 'react-native';
import Stack from '../../../../common/LayoutComponents/Stack';
import { useIntl } from 'react-intl';
import { Colors } from '../../../../../styles';
import AppointmentDetail from '../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail';
import { Divider, Popover } from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import { getMomentObj } from '../../../../../utils/DateUtils';
import { getDiffInMonths } from '../../../../../utils/DateUtils';
import { useContactCareProgramContext } from '../ContactCareProgram.context';
import SelectOptionPopover from './SelectOptionPopover';
import { useKeyPointActions } from '../hooks/useKeyPointActions';
import { APPOINTMENT_TYPE_VISIT_TYPE, MLOV_CATEGORY } from '../../../../../constants/MlovConst';
import { getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { ContactCareProgramComponentStatus } from '../../interface';
import { getAdditionalDataCodes, getDropDownOptions } from '../../../CareManagementView/CareManagementUtils';
import PatientCareProgramConsentView from '../../../../common/CareProgramConsent/PatientCareProgramConsentView';
import { CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE, CARE_PROGRAM_EVENT_CODES } from '../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import DownArrowSvg from '../../../../common/Svg/GlobalActionSvg/DownArrowSvg';
import RightArrowSvg from '../../../../common/Svg/GlobalActionSvg/RightArrowSvg';
import { antStyles } from '../../../CareManagementView/CareManageMentViewStyles';
import { CARE_PROGRAM_EVENTS, useCPEventHandlers } from '../../useCPEventhandler';
import { useCareProgramEditability } from '../hooks/useCareProgramEditability';
import { EventBus } from '../../../../../utils/EventBus';
import { CadenceView } from './Cadence/CadenceView';
import '../ContactCareProgramView.scss';
import { testID } from '../../../../../testUtils';
import { IAwvTypeChangeResult, IComplexityChangeResult } from './Cadence/interfaces';

interface IKeyPointsViewProps {
  contactCareProgramId: string;
  careProgramTypeCode?: string;
  isLoading?: boolean;
}

const CPKeyPointsView = (props: IKeyPointsViewProps) => {
  // props
  const { contactCareProgramId, careProgramTypeCode, isLoading } = props;

  // hooks
  const { data: contactData, formattedData } = usePersonOmniViewContext();
  const {state} = useContactCareProgramContext();
  const { getPatientAdditionalData, loading } = useCareProgramAdditionalData();
  const commonDataContext = useContext(CommonDataContext);
  const { handleComplexityChange, handleAwvTypeChange } = useKeyPointActions();
  const {isAllowedToTakeActions} = useCareProgramEditability();
  const intl = useIntl();
  const {userMap} = state;
  const { sync } = useCPEventHandlers({
    syncCode: `complexity-sync-${contactCareProgramId}`,
    listenCode: `complexity-listen-${contactCareProgramId}`,
    onSync: (eventType, event) => {
      if (eventType === CARE_PROGRAM_EVENT_CODES.COMPLEXITY_CHANGED) {
        onComplexityChange(event?.complexity);
      }
    }
  });

  const { sync: syncAwvType } = useCPEventHandlers({
    syncCode: `awv-type-sync-${contactCareProgramId}`,
    listenCode: `awv-type-listen-${contactCareProgramId}`,
    onSync: (eventType, event) => {
      if (eventType === CARE_PROGRAM_EVENT_CODES.AWV_TYPE_CHANGED) {
        onAwvTypeChange(event?.awvType);
      }
    }
  });

  // states
  const [keyPoints, setKeyPoints] = useState<IKeyPointData[]>([]);
  const [selectedKeyPoint, setSelectedKeyPoint] = useState<IKeyPointData | undefined>(undefined);
  const [activePopover, setActivePopover] = useState<string | undefined>(undefined);
  const [hoveredKeyPoint, setHoveredKeyPoint] = useState<string | undefined>(undefined);

  const isKeyPointsLoading = isLoading || loading ||  state?.status === ContactCareProgramComponentStatus.LOADING;

  // useEffect
  React.useEffect(() => {
    if (!isLoading) {
      const additionalDataCodes = getAdditionalDataCodes(careProgramTypeCode) || [];
      if (additionalDataCodes?.length === 0) {
        return;
      }
      getInitData();
    }
  }, [isLoading]);

  const { complexityOptions, awvTypeOptions } = React.useMemo(() => {
    const complexityList = getMlovListFromCategory(commonDataContext.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_COMPLEXITY) || [];

    const mapToOptions = (list: any[]) => list.map((item: any) => ({
      label: item.value,
      value: item.code
    }));

    return {
      complexityOptions: mapToOptions(complexityList),
      awvTypeOptions: mapToOptions(
        getMlovListFromCategory(commonDataContext.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_AWV_TYPE) || []
      )
    };
  }, [commonDataContext.CARE_STUDIO_MLOV]);


  const onAnyActionTaken = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CARE_PROGRAM_EVENTS.CARE_PROGRAM_ACTION_TAKEN, {
      syncCode: `care-program-action-taken`,
    });
  };

  const onComplexityChange = async (complexity: string, isSendEvent?: boolean): Promise<IComplexityChangeResult> => {
    let result: IComplexityChangeResult | null = null;

    if (!!isSendEvent) {
      result = await handleComplexityChange(
        contactCareProgramId,
        contactData?.uuid,
        complexity,
        state?.contactCareProgramDetails?.statusId || ''
      );
    }

    if (result && result.success && result.complexity) {
      setKeyPoints(prevPoints =>
        prevPoints.map(point =>
          point.code === ADDITIONAL_DATA_CODE.COMPLEXITY
            ? {
                ...point,
                referenceData: result?.complexity,
                display: `• Complexity: ${result?.complexity || ''}`
              }
            : point
        )
      );
    }
    return result || { success: false };
  };

  const syncComplexity = async (result: any) => {
    sync(CARE_PROGRAM_EVENTS.COMPLEXITY_CHANGED as keyof typeof CARE_PROGRAM_EVENTS, {
      complexity: result?.complexity,
      contactCareProgramId
    });
  }

  const syncAwv = async (result: any) => {
    syncAwvType(CARE_PROGRAM_EVENTS.AWV_TYPE_CHANGED as keyof typeof CARE_PROGRAM_EVENTS, {
      awvType: result.awvType,
      contactCareProgramId
    });
  }

  const getTypeChange = async (code: ADDITIONAL_DATA_CODE, value: string) => {
    onAnyActionTaken();
    if(code === ADDITIONAL_DATA_CODE.COMPLEXITY) {
      const result = await onComplexityChange(value, true);
      if (result?.success) {
        syncComplexity(result);
      }
    } else if(code === ADDITIONAL_DATA_CODE.AWV_TYPE) {
      const result = await onAwvTypeChange(value, true);
      if (result?.success) {
        syncAwv(result);
      }
    }
  }

  const onAwvTypeChange = async (awvType: string, isSendEvent?: boolean): Promise<IAwvTypeChangeResult> => {
    const awvTypeList = getMlovListFromCategory(commonDataContext.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_AWV_TYPE) || [];
    const selectedAwvType = awvTypeList.find(item => item.code === awvType);
    const awvTypeCode = selectedAwvType?.id || '';
    let result: IAwvTypeChangeResult | null = null;

    if (!!isSendEvent) {
      result = await handleAwvTypeChange(
        contactCareProgramId,
        awvType,
        awvTypeCode
      );
    }
    if (result && result.success && result.awvType) {
      setKeyPoints(prevPoints => {
        const newPoints = prevPoints.map(point =>
          point.code === ADDITIONAL_DATA_CODE.AWV_TYPE
            ? {
                ...point,
                referenceData: result?.awvType,
                display: `• AWV Type: ${AWV_TYPE_CODES[result?.awvType as keyof typeof AWV_TYPE_CODES] || ''}`
              }
            : point
        );
        return newPoints;
      });
    }
    return result || { success: false };
  };

  const getInitData = async () => {
    try {
      const data: IKeyPointData[] = await getPatientAdditionalData({
        patientId: contactData?.patient?.patientId,
        contactUuid: contactData?.uuid,
        location: formattedData?.accountLocationUuid || '',
        additionalDataCodes: getAdditionalDataCodes(careProgramTypeCode) || [],
        contactCareProgramId: contactCareProgramId,
        userMap: userMap,
      }) as IKeyPointData[];
      setKeyPoints(data);
    } catch (e) {
      setKeyPoints([]);
    }
  };

  const renderPopoverContent = (keyPoint: IKeyPointData) => {
    if (keyPoint.code === ADDITIONAL_DATA_CODE.CHRONIC_CONDITIONS) {
      const chronicConditions = keyPoint.referenceData;
      const popOverContentData = chronicConditions.map((item: any) => ({
        name: item.name,
        duration: item?.onSetDateTime
          ? Math.floor(getDiffInMonths(item?.onSetDateTime, getMomentObj(new Date())))
          : 0
      }));
      return (
        <FlatList
          data={popOverContentData}
          renderItem={({ item }) => (
            <Stack direction="row"
             style={styles.chronicConditionView}>
              <Text style={styles.chronicCondition}>{item.name}</Text>
              <Text
                style ={styles.chronicConditionMonths}
                paddingX={2}
                paddingY={0.25}
              >
                {`${item.duration}M`}
              </Text>
            </Stack>
          )}
          keyExtractor={(item, index) => `${item.name}-${index}`}
          style={{ width: 200 }}
        />
      );
    }
    if(keyPoint.code === ADDITIONAL_DATA_CODE.CADENCE_DATE) {
      return (
        <CadenceView patientAccountLocationId={formattedData?.accountLocationUuid || ''}  contactData={contactData} contactCareProgramId={contactCareProgramId} />
      );
    }
    return null;
  };

  const handleKeyPointPress = (keyPoint: IKeyPointData) => {
    setSelectedKeyPoint(keyPoint);
  };

  const handleClose = async (shouldRefresh?: boolean, code?: ADDITIONAL_DATA_CODE) => {
    if (shouldRefresh) {
      if (code) {
        const data: IKeyPointData[] = await getPatientAdditionalData({
          patientId: contactData?.patient?.patientId,
          contactUuid: contactData?.uuid,
          location: formattedData?.accountLocationUuid || '',
          additionalDataCodes: [code],
          contactCareProgramId: contactCareProgramId
        }) as IKeyPointData[];

        const updatedKeyPoint = data?.[0];
        setKeyPoints((prev) => prev.map(item =>
          item.code === code
            ? updatedKeyPoint
            : item
        ));
        onAnyActionTaken();
      } else {
        await getInitData();
      }
    }
    setSelectedKeyPoint(undefined);
  };

  const renderKeyPoint = ({ item: keyPoint }: { item: IKeyPointData }) => {

    if(keyPoint.code === ADDITIONAL_DATA_CODE.PATIENT_CONSENT) {
      return (
        <PatientCareProgramConsentView
          disableActions={!isAllowedToTakeActions}
          contactId={contactData?.uuid || ''}
          contactCareProgramId={contactCareProgramId}
          subjectTypeCode={
            CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT_CARE_PROGRAM
          }
          displayContent={
            <Stack
              direction="row"
              style={{
                alignItems: 'center',
                backgroundColor:
                  hoveredKeyPoint === keyPoint.code && isAllowedToTakeActions
                    ? Colors.Custom.Gray200
                    : 'transparent',
              }}
            >
              <Text size={'xsMedium'} style={styles.chronicCondition} {...testID(keyPoint.display)}>{keyPoint.display}</Text>
              {isAllowedToTakeActions && <Divider type="vertical" />}
              {isAllowedToTakeActions && <DownArrowSvg />}
            </Stack>
          }
          careProgramConsentData={keyPoint.referenceData}
          onAddOrUpdateConsent={() =>
            handleClose(true, ADDITIONAL_DATA_CODE.PATIENT_CONSENT)
          }
        />
      );
    }

    if (!isAllowedToTakeActions || keyPoint.view === CARE_PROGRAM_VIEW.READ_ONLY) {
      return (
        <Text size={'xsMedium'} style={styles.chronicCondition} {...testID(keyPoint.display)}>{keyPoint.display}</Text>
      );
    }

    const content = (
      <div
        onMouseOver={() => setHoveredKeyPoint(keyPoint.code)}
        onMouseLeave={() => setHoveredKeyPoint(undefined)}
        style={{
          backgroundColor: hoveredKeyPoint === keyPoint.code && isAllowedToTakeActions
            ? Colors.Custom.Gray200
            : 'transparent'
        }}
      >
        <Stack
          direction="row"
          space={2}
          style={{
            ...styles.keyPointContainer,
            ...(isAllowedToTakeActions ? styles.interactiveKeyPoint : {}),
            alignItems: 'center'
          }}
        >
          <Text size={'xsMedium'} style={styles.chronicCondition} {...testID(keyPoint.code)}>{keyPoint.display}</Text>
        </Stack>
      </div>
    );
  if (keyPoint.view === CARE_PROGRAM_VIEW.DROPDOWN) {
    return (
      <Popover
      content={
        <SelectOptionPopover
          currentValue={keyPoint.referenceData}
          options={getDropDownOptions(keyPoint.code, complexityOptions, awvTypeOptions)}
          onSave={async (value) => {
            await getTypeChange(keyPoint.code as ADDITIONAL_DATA_CODE, value);
            setActivePopover(undefined);
          }}
        />
      }
      align={{
        offset: [0, -10],
      }}
      overlayInnerStyle={antStyles.popoverOverlayInnerStyle}
      showArrow={false}
      placement="bottomLeft"
      trigger="hover"
      open={activePopover === keyPoint.code}
      onOpenChange={(visible) => {
        setActivePopover(visible ? keyPoint.code : undefined);
        }}
      >
        <Pressable>
          <Stack direction="row" style={{alignItems: 'center'}}>
            {content}
            <DownArrowSvg />
          </Stack>
        </Pressable>
      </Popover>
    );
  }

    if (keyPoint.view === CARE_PROGRAM_VIEW.PRESSABLE) {
      return (
        <Pressable onPress={() => handleKeyPointPress(keyPoint)}>
          <Stack direction="row" style={{alignItems: 'center'}}>
          {content}
          <Divider type="vertical" />
          <RightArrowSvg />
          </Stack>
        </Pressable>
      );
    }

    if (keyPoint.view === CARE_PROGRAM_VIEW.POPOVER) {
      return (
        <Popover
          overlayClassName={keyPoint?.code === ADDITIONAL_DATA_CODE.CADENCE_DATE ? 'cadence-popover' : ''}
          overlayInnerStyle={{borderRadius: 8, width: keyPoint?.code === ADDITIONAL_DATA_CODE.CADENCE_DATE ? 370 : 230}}
          open={selectedKeyPoint?.code === keyPoint.code}
          onOpenChange={(visible) => visible ? handleKeyPointPress(keyPoint) : handleClose()}
          content={renderPopoverContent(keyPoint)}
          trigger="click"
          align={{
            offset: [0, -10],
          }}
          showArrow={false}
          placement="bottomLeft"
        >
          <Pressable onPress={() => handleKeyPointPress(keyPoint)}>
            <Stack direction="row" style={{alignItems: 'center'}}>
              {content}
              <DownArrowSvg />
            </Stack>
          </Pressable>
        </Popover>
      );
    }

    return content;
  };

  if (!getAdditionalDataCodes(careProgramTypeCode)?.length) {
    return null;
  }

  return (
    <div className="key-points-view">
      <div className="patient-info">
        {isKeyPointsLoading ? (
          <Text style={styles.chronicCondition}>
            {intl.formatMessage({id: 'loadingText'})}
          </Text>
        ) : (
          <FlatList
            data={keyPoints}
            renderItem={renderKeyPoint}
            keyExtractor={(item) => item.code}
            horizontal
            ItemSeparatorComponent={() => (
              <Text style={[styles.separator, {paddingHorizontal: 8, marginTop: -2}]}>
                {`• `}{' '}
              </Text>
            )}
            contentContainerStyle={styles.flatListContainer}
          />
        )}
        {(selectedKeyPoint?.code === ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT ||
          selectedKeyPoint?.code ===
            ADDITIONAL_DATA_CODE.AWV_APPOINTMENT_DATE) && (
          <AppointmentDetail
            event={{detail: selectedKeyPoint?.referenceData}}
            isHideViewDetailedProfile={true}
            isVisible={true}
            onClose={() => {
              setSelectedKeyPoint(undefined);
            }}
            appointmentVisitType={
              selectedKeyPoint?.code ===
              ADDITIONAL_DATA_CODE.AWV_APPOINTMENT_DATE
                ? APPOINTMENT_TYPE_VISIT_TYPE.AWV
                : undefined
            }
            disableSelectSlotBy={
              selectedKeyPoint?.code ===
              ADDITIONAL_DATA_CODE.AWV_APPOINTMENT_DATE
            }
            disablePrimaryUserSelect={
              selectedKeyPoint?.code ===
              ADDITIONAL_DATA_CODE.AWV_APPOINTMENT_DATE
            }
            onlyAtClinicAppointmentEnabled={
              selectedKeyPoint?.code ===
              ADDITIONAL_DATA_CODE.AWV_APPOINTMENT_DATE
            }
            disableAppointmentReccurence={
              selectedKeyPoint?.code ===
              ADDITIONAL_DATA_CODE.AWV_APPOINTMENT_DATE
            }
            isCareProgramAppointment
          />
        )}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  chronicCondition: {
    color: Colors.FoldPixel.GRAY300,
    fontWeight: '500',
    alignSelf: 'flex-start',
    marginRight: 1
  },
  separator: {
    width: 8,
    color: Colors.Custom.Gray500,
  },
  flatListContainer: {
    paddingHorizontal: 8,
  },
  chronicConditionMonths: {
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    backgroundColor: Colors.Custom.Gray100,
    borderRadius: 8,
    fontWeight: '500',
    color: Colors.Custom.Gray500 ,
    alignSelf: 'flex-end'
  },
  chronicConditionView:{
    justifyContent: 'space-between',
    marginBottom: 6,
    display: 'flex'
  },
  keyPointContainer: {
    alignItems: 'center',
    padding: 8,
    borderRadius: 4,
  },
  interactiveKeyPoint: Platform.select({
    web: {
      cursor: 'pointer',
      ':hover': {
        backgroundColor: Colors.Custom.Danger800,
      }
    },
    default: {}
  }) as any,
});

export default CPKeyPointsView;
