import {useCallback} from 'react';
import {useSelectedStepInCareProgram} from './useSelectedStepInCareProgram';
import {CARE_PROGRAM_STEP_TYPE} from '../../../../../../common/MemebersView/constant';
import {useContactCareProgramContext} from '../../../ContactCareProgram.context';
import {ContactCareProgramReducerAction} from '../../../reducer';
import {useMutation} from '@apollo/client';
import {ADD_OR_UPDATE_STEP_ADDITIONAL_ATTRIBUTES} from '../../../../../../../services/CareProgram/CareProgramQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../../constants/Configs';

export const useCareProgramAdditionalAttribute = () => {
  const {state, dispatch} = useContactCareProgramContext();
  const {selectedStep} = useSelectedStepInCareProgram();
  const careProgramStepTypeCode = selectedStep?.careProgramStepType?.code;

  const [addOrUpdateCareProgramAttributes] = useMutation(
    ADD_OR_UPDATE_STEP_ADDITIONAL_ATTRIBUTES,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const handleUpdateCareProgram = useCallback(
    async (
      actionCode?: string,
      actionData?: any,
      isUpdateCareProgramStep?: boolean
    ) => {
      switch (actionCode) {
        case ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STEP_ADDITIONAL_ATTRIBUTES:
          handleUpdateCareProgramStep(actionCode || '', actionData);
          if (isUpdateCareProgramStep && actionData) {
            handleAddOrUpdateCareProgramAttributes(actionData);
          }
          break;
        case ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_COMPLEXITY:
          actionData &&
            dispatch?.({
              type: actionCode as ContactCareProgramReducerAction,
              payload: actionData,
            });
          if (isUpdateCareProgramStep && actionData) {
            handleAddOrUpdateCareProgramAttributes(actionData);
          }
          break;
      }
    },
    [careProgramStepTypeCode]
  );

  const handleUpdateCareProgramStep = useCallback(
    (actionCode: string, actionData: any) => {
      switch (careProgramStepTypeCode) {
        case CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT:
        case CARE_PROGRAM_STEP_TYPE.APPOINTMENT:
        case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
        case CARE_PROGRAM_STEP_TYPE.CARE_MANAGER_APPOINTMENT:
          dispatch?.({
            type: actionCode as ContactCareProgramReducerAction,
            payload: {
              additionalAttribute: actionData,
              subjectTypeCode: 'CARE_PROGRAM_STEP',
            },
          });
          break;
        case CARE_PROGRAM_STEP_TYPE.REFERRAL:
          dispatch?.({
            type: actionCode as ContactCareProgramReducerAction,
            payload: {
              additionalAttribute: actionData,
              subjectTypeCode: 'CARE_PROGRAM_STEP',
            },
          });
          break;
      }
    },
    [careProgramStepTypeCode]
  );

  const handleAddOrUpdateCareProgramAttributes = useCallback(
    async (actionData: any) => {
      const careProgramStepAdditionalAttribute = [
        {
          contactCareProgramId: state.contactCareProgramDetails?.id,
          subjectId: selectedStep?.id,
          subjectTypeCode: 'CARE_PROGRAM_STEP',
          additionalAttributes: [
            {
              attributeKey: actionData.attributeKey,
              attributeValueDataTypeCode: actionData.attributeValueDataTypeCode,
              attributeValue: actionData.attributeValue,
              isDeleted: false,
            },
          ],
        },
      ];

      await addOrUpdateCareProgramAttributes({
        variables: {
          params: {
            careProgramAttributes: careProgramStepAdditionalAttribute,
          },
        },
      });
    },
    []
  );

  return {
    handleUpdateCareProgram,
  };
};
