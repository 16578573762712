import * as React from 'react';
import {
  LayoutAnimation,
  LayoutAnimationConfig,
  NativeModules,
  Platform,
  UIManager,
  View,
} from 'react-native';
import {isIOS, isWeb} from '../../utils/platformCheckUtils';
import {Toast, ToastConfig, ToastInternalConfig} from './ToastComponent';
import {v4 as uuid} from 'uuid';
import { Portal } from 'react-portal';

export type Toast = (options: ToastConfig) => void;

export const ToastContext = React.createContext<Toast>(() => null);

export const useToast = () => React.useContext(ToastContext);

// NOTE: This export is added to identify the toast is from the custom toast provider & not from native base
export const useCustomToast = useToast;


const statusBarHeight = () => {
  if (isWeb()) {
    return 25;
  }
  if (isIOS()) {
    return 45;
  }
  return NativeModules?.StatusBarManager?.HEIGHT || 0;
};

UIManager &&
  UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

export type FullToastConfig = ToastConfig & ToastInternalConfig;

const CustomLayoutConfig: LayoutAnimationConfig = {
  duration: 200,
  create: {
    type: LayoutAnimation.Types.easeInEaseOut,
    property: LayoutAnimation.Properties.opacity,
  },
  update: {
    type: LayoutAnimation.Types.easeInEaseOut,
  },
  delete: {
    type: LayoutAnimation.Types.easeInEaseOut,
    property: LayoutAnimation.Properties.opacity,
  },
};

const ToastProvider: React.FC<Omit<Toast, 'toast'>> = ({children}) => {
  const [toasts, setToasts] = React.useState<FullToastConfig[]>([]);

  const toast = React.useCallback((newToast: ToastConfig) => {
    LayoutAnimation.configureNext(CustomLayoutConfig);
    setToasts((prevToasts) => {
      if (newToast.closeAllPrevToast) {
        return [
          {
            index: prevToasts.length,
            id: uuid(),
            ...newToast,
          },
        ];
      }
      const toasts = [
        ...prevToasts,
        {
          index: prevToasts.length,
          id: uuid(),
          ...newToast,
        },
      ];
      return toasts;
    });
  }, []);

  const hideToast = (id: string) => {
    LayoutAnimation.configureNext(CustomLayoutConfig);
    setToasts((prevToasts) => prevToasts.filter((el) => el.id !== id));
  };

  const offset = statusBarHeight();

  const getToastView = () => {
    return (
      <View
          style={[
            {
              paddingHorizontal: 16,
              left: 0,
              right: 0,
              position: 'absolute',
              marginTop: offset,
              marginHorizontal: 'auto',
              maxWidth: isWeb() ? '50%' : '100%',
              ...(isWeb() && {zIndex: 3000})
            },
          ]}
        >
          {toasts.map((config: ToastConfig & ToastInternalConfig) => {
            return (
              <Toast
                position={config.position}
                key={config.id}
                onClose={(id) => hideToast(id)}
                {...config}
              />
            );
          })}
      </View>
    )
  }

  return (
    <ToastContext.Provider value={toast}>
      {children}
      {isWeb() ?
        <Portal node={document && document.getElementById('foldCustomToast')}>
          {getToastView()}
        </Portal> :
          getToastView()
      }
    </ToastContext.Provider>
  );
};

export default ToastProvider;
