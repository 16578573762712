import {Form, Modal, Tooltip, Select} from 'antd';
import {Button, View, Text, HStack, Divider} from 'native-base';
import {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {StyleSheet, TextInput} from 'react-native';
import {BUTTON_TYPE} from '../../../../constants';
import {Colors} from '../../../../styles/Colors';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {ICreateMemberStatusHistoryParams} from '../../../RightSideContainer/Contacts/MemberStatus/interface';
import {
  getMemberStatusData,
  updateContactStatus,
} from '../../../RightSideContainer/Contacts/MemberStatus/MemberStatusService';
import {getFormattedMemberStatusData} from '../../../RightSideContainer/Contacts/MemberStatus/MemberStatusUtils';
import {
  IContact,
  IMemberStatus,
} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import CaretDownOutlined from '@ant-design/icons/lib/icons/CaretDownOutlined';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import './memberStatusList.css';
import InfoCircleSvg from '../../../common/Svg/InfoCircleSvg';
import { MEMBER_STATUS } from '../../../common/MemebersView/constant';
import { memberStatusListAntDStyles } from './OtherDetailsStyles';

const statusStyles = StyleSheet.create({
  statusContainer: {
    paddingHorizontal: 8,
    marginHorizontal: 2
  },
});

interface MemberStatusListProps {
  contactData?: IContact;
  contactId?: number;
  showMemberStatus?: boolean;
  sideCarDashboardEnabled?: boolean;
}

export type IMemberStatusRef = {
  [index in IMemberStatusFormKeys]: HTMLInputElement;
};

export type IMemberStatusFormKeys = 'name' | 'description' | 'color';

export const MemberStatusList = (props: MemberStatusListProps) => {
  const [stateData, setStateData] = useState({
    memberStatuses: [] as IMemberStatus[],
    isMemberStatusChanged: false,
    updatedStatusId: '',
    oldStatus: props?.contactData?.memberStatus?.name,
    currentStatus: props?.contactData?.memberStatus?.name,
    newStatus: '',
    loading: false,
  });

  const intl = useIntl();
  const [form] = Form.useForm();
  const memberStatusRef = useRef<IMemberStatusRef>({} as any);

  const updateStatus = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    const bodyData: ICreateMemberStatusHistoryParams = {
      body: {
        contactUuid: props?.contactData?.uuid,
        statusId: stateData?.updatedStatusId,
        previousStatusId: props?.contactData?.memberStatus?.id,
        reason: memberStatusRef.current['description'].value,
      },
    };

    try {
      const memberStatus = await updateContactStatus(bodyData);

      setStateData((prev) => ({
        ...prev,
        currentStatus: memberStatus?.data?.name,
        isMemberStatusChanged: false,
        loading: false,
      }));
    } catch (error: any) {
      setStateData((prev) => {
        return {
          ...prev,
          memberStatusData: [],
          isMemberStatusChanged: false,
          loading: false,
        };
      });
    }
  };

  const getMemberStatus = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const params = {
      searchString: '',
      pageSize: 100,
    };
    try {
      const response = await getMemberStatusData(params);
      if (response?.data?.items?.length) {
        const MemberStatus = response?.data?.items;
        let formattedData = getFormattedMemberStatusData(MemberStatus);
        const hasNewName =  stateData?.currentStatus === MEMBER_STATUS.NEW;
        if (!hasNewName) {
          formattedData = formattedData?.filter(item => item?.name !== MEMBER_STATUS.NEW);
        }
        setStateData((prev) => {
          return {
            ...prev,
            memberStatuses: formattedData,
            loading: false,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            memberStatusData: [],
            loading: false,
          };
        });
      }
    } catch (error: any) {
      setStateData((prev) => {
        return {
          ...prev,
          memberStatusData: [],
          loading: false,
        };
      });
    }
  };

  const [isActive, setActive] = useState(true);
  const toggleClass = () => {
    setActive(!isActive);
  };

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 5000);
  };

  useEffect(() => {
    getMemberStatus();
  }, [stateData.currentStatus]);

  return (
    <>
      {stateData.isMemberStatusChanged && (
        <Modal
          title={<DisplayText size={'mdMedium'} extraStyles={{fontWeight: '600'}} textLocalId="changeStatus" textType="Heading" />}
          open={stateData.isMemberStatusChanged}
          footer={null}
          bodyStyle={{
            paddingTop: 12,
            zIndex: 9999999999,
          }}
          closeIcon={<></>}
          maskClosable={false}
          destroyOnClose
        >
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY400}>
            {intl.formatMessage({id: 'memberStatusChange'})}{' '}
            {stateData.oldStatus} to {stateData.newStatus}
          </Text>

          <Form
            className="custom-form"
            form={form}
            layout="vertical"
            labelCol={{
              style: {
                paddingLeft: 3,
              },
            }}
          >
            <TextInput
              multiline
              numberOfLines={6}
              style={{
                borderWidth: 1,
                borderColor: Colors.Custom.Gray200,
                borderRadius: 6,
                width: '100%',
                paddingLeft: 10,
                paddingTop: 10,
              }}
              ref={(ref) =>
                (memberStatusRef.current['description'] = ref as any as any)
              }
              placeholder="Enter Reason For Change (Optional)"
              placeholderTextColor={Colors.Custom.Gray300}
            />
          </Form>
          <Button.Group gap={'0.5'} justifyContent={'flex-end'}>
            <FoldButton
              customProps={{
                btnText: 'Confirm',
                isDisableOnPress: true,
              }}
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress() {
                  updateStatus();
                },
              }}
            />
            <FoldButton
              customProps={{
                btnText: 'Cancel',
              }}
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress() {
                  setStateData((prev) => ({
                    ...prev,
                    isMemberStatusChanged: false,
                  }));
                },
              }}
            />
          </Button.Group>
        </Modal>
      )}
      <View style={statusStyles.statusContainer}>
        <Tooltip
          title="Click to add Patient Status"
          placement="top"
          visible={showTooltip}
          onVisibleChange={(visible) => {
            if (visible) {
              handleMouseEnter();
            }
          }}
        >
          <Select
            allowClear={false}
            showSearch={false}
            labelInValue={true}
            filterOption={false}
            placeholder={'Select Status'}
            className='patient-status-select'
            popupClassName='patient-status-select-popup'
            suffixIcon={
              <HStack alignItems="center" space={1}>
                <Divider height={'14px'} orientation="vertical" />
                <div className='patient-status-select-icon'>
                  <CaretDownOutlined
                    style={memberStatusListAntDStyles.patientStatusSelectIcon}
                    className={isActive ? 'ant-select-suffix' : ''}
                    onClick={toggleClass} // This is added to make the dropdown icon clickable. antd version has this bug
                  />
                </div>
              </HStack>
            }
            value={stateData.currentStatus}
            onChange={(value: any) => {
              setStateData((prevState) => ({
                ...prevState,
                isMemberStatusChanged: true,
                oldStatus: prevState?.currentStatus || props?.contactData?.memberStatus?.name || '',
                newStatus: value?.value,
                updatedStatusId: value?.key,
              }));
            }}
            bordered={false}
            loading={stateData?.loading}
            style={memberStatusListAntDStyles.patientStatusSelect}
          >
            {stateData?.memberStatuses?.map((item) => {
              return (
                <Select.Option
                  key={item.id}
                  value={item.name}
                  className="patient-status-select-option"
                  style={memberStatusListAntDStyles.patientStatusSelectOption}
                >
                  {item.name}
                  <Tooltip
                    title={item?.description || ""}
                    placement="right"
                  >
                    <div className="patient-status-select-tooltip-icon">
                      <InfoCircleSvg
                        size={16}
                        customStrokeColor={Colors.FoldPixel.GRAY300}
                      />
                    </div>
                  </Tooltip>
                </Select.Option>
              );
            })}
          </Select>
        </Tooltip>
      </View>
    </>
  );
};
