import {permitState} from "permit-fe-sdk";
import {useContext} from "react";
import {CommonDataContext, ICommonData} from "../../context/CommonDataContext";
import {getAccountLocationUuidFromRolesResp, getResorceCode} from "../../screens/MainScreen/utils";
import {ACCESS_LEVEL} from "../../constants";
import {isLoggedInUserGlobalAdmin, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner} from "../../utils/commonUtils";


export const usePermissions = (commonDataObject?: ICommonData) => {
  const commonData = commonDataObject ?? useContext(CommonDataContext)
  const userPracticeLocations = commonData.userPracticeLocations || []
  const userAccountLocationUuids = commonData.userAccountLocationUuids
  const accountLocationIds = userAccountLocationUuids?.length
    ? userAccountLocationUuids
    : getAccountLocationUuidFromRolesResp([], userPracticeLocations);
  const allAccountLocationIds = commonData.accountLocationListWithEHR?.map((location)=>location.uuid) || [];
  const isAllowedAllLocation =
    isLoginUserBusinessOwner() ||
    isLoggedInUserGlobalAdmin() ||
    isLoggedInUserWorkFlowOrCustomerSuccess();
  const check = (
    resource: string,
    action: string,
    locationId?: string
  ) => {
    let accessLevel = 'N/A';
    const allowedLocationIds = [];
    let hasGlobalAccess = false;

    const locations = locationId ? [locationId] : [...accountLocationIds, 'null'];

    for (const location of locations) {
      const currentResource =  getResorceCode({
        actionCode: action,
        accountLocationId: location,
        sectionCode: resource
      })
      const hasAccess = permitState?.check(action, currentResource, {});
      if (hasAccess) {
        if(location !== 'null') {
          allowedLocationIds.push(location);
        }

        if (location === 'null') {
          hasGlobalAccess = true;
        }
      }
    }

    if (hasGlobalAccess) {
      accessLevel = ACCESS_LEVEL.GLOBAL;
    } else if (allowedLocationIds.length > 0) {
      accessLevel = ACCESS_LEVEL.LOCATION;
    }
    return {
      hasAccess: isAllowedAllLocation ? true : allowedLocationIds.length > 0 || hasGlobalAccess ? true : false ,
      accessLevel: accessLevel,
      allowedLocationIds: isAllowedAllLocation ? allAccountLocationIds : allowedLocationIds,
    };
  };

  return { check };
};
