import React from 'react';
import { Path, Svg } from "react-native-svg";
import { View } from 'react-native';
import { interventionSvgStyles } from '../InterventionSvgStyles';

const TaskIconSvg = () => {

    return (
        <View style={interventionSvgStyles.leftSvgStyle}>
            <Svg
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 22 21"
                stroke="#6F7A90"
                strokeWidth="0.5"
            >
                <Path
                    fill="#6F7A90"
                    d="M5.362 13.955a.5.5 0 10-.724.69l.724-.69zm.78 1.545l-.361.345a.5.5 0 00.724 0l-.362-.345zm3.22-2.655a.5.5 0 10-.724-.69l.724.69zm-4-5.89a.5.5 0 00-.724.69l.724-.69zm.78 1.545l-.361.345a.5.5 0 00.724 0L6.143 8.5zm3.22-2.655a.5.5 0 00-.724-.69l.724.69zM12 7a.5.5 0 000 1V7zm5 1a.5.5 0 000-1v1zm-5 6a.5.5 0 000 1v-1zm5 1a.5.5 0 000-1v1zm-6 5c-2.371 0-4.1-.001-5.42-.179-1.306-.175-2.138-.515-2.762-1.139l-.707.707c.84.84 1.919 1.233 3.336 1.424C6.85 21 8.657 21 11 21v-1zM.5 10.5c0 2.343-.001 4.15.188 5.553.19 1.417.582 2.495 1.423 3.336l.707-.707c-.624-.624-.964-1.456-1.14-2.762C1.502 14.6 1.5 12.87 1.5 10.5h-1zm20 0c0 2.371-.001 4.1-.179 5.42-.175 1.306-.515 2.138-1.139 2.762l.707.707c.84-.84 1.233-1.919 1.424-3.336.188-1.403.187-3.21.187-5.553h-1zM11 21c2.343 0 4.15.001 5.553-.188 1.417-.19 2.495-.582 3.336-1.423l-.707-.707c-.624.624-1.456.964-2.762 1.14C15.1 19.998 13.37 20 11 20v1zm0-20c2.371 0 4.1.001 5.42.179 1.306.175 2.138.515 2.762 1.139l.707-.707C19.05.77 17.97.378 16.553.188 15.15-.002 13.343 0 11 0v1zm10.5 9.5c0-2.343.001-4.15-.188-5.553-.19-1.417-.582-2.495-1.423-3.336l-.707.707c.624.624.964 1.456 1.14 2.762.177 1.32.178 3.049.178 5.42h1zM11 0C8.657 0 6.85-.001 5.447.188 4.03.378 2.952.77 2.111 1.61l.707.707c.624-.624 1.456-.964 2.762-1.14C6.9 1.002 8.63 1 11 1V0zM1.5 10.5c0-2.371.001-4.1.179-5.42.175-1.306.515-2.138 1.139-2.762l-.707-.707C1.27 2.45.878 3.53.688 4.947.498 6.35.5 8.157.5 10.5h1zm3.138 4.145l1.143 1.2.724-.69-1.143-1.2-.724.69zm1.867 1.2l2.857-3-.724-.69-2.857 3 .724.69zm-1.867-8.2l1.143 1.2.724-.69-1.143-1.2-.724.69zm1.867 1.2l2.857-3-.724-.69-2.857 3 .724.69zM12 8h5V7h-5v1zm0 7h5v-1h-5v1z"
                ></Path>
            </Svg>
        </View>
    );
};

export default TaskIconSvg;
