import React, { useEffect, useState } from 'react';
import {Pressable, Text, StyleSheet} from 'react-native';
import Stack from '../../../../common/LayoutComponents/Stack';
import {Colors} from '../../../../../styles/Colors';
import LogTimeSvg from '../../../../common/Svg/LogTimeSvg';
import {useIntl} from 'react-intl';
import {Drawer, Skeleton} from 'antd';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {BUTTON_TYPE} from '../../../../../constants/StringConst';
import ManualTimeLoggingDrawer from './ManualTimeLoggingDrawer';
import Stopwatch, { TimerState } from '../../../../common/Stopwatch/Stopwatch';
import UseTimeLogForBilling from '../../../../CustomHooks/UseTimeLogForBilling';
import { useToast } from 'native-base';
import { SAVE_RECORDED_TIME_FOR_BILLING } from '../../../../../services/CareProgram/CareProgramQueries';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { useMutation } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import ProviderActivityAndOutreachComponent from '../../../CareManagementBilling/CareProgramBIllingComponent/ProviderOutreachAndActivity/ProviderOutreachAndActivity';
import { EventBus } from '../../../../../utils/EventBus';
import { CARE_PROGRAM_EVENTS } from '../../../ContactCareProgram/useCPEventhandler';


export interface ITimeLoggingComponentState {
  openManualTimeLogDrawer: boolean;
  generateBillAfterTimeLog: boolean;
  recordedTime: { minutes: number; seconds: number};
  openActivityDrawer: boolean;
  isLoggedTimeCleared?: boolean;
  timerState: TimerState;
}

export interface ITimeLoggingComponentProps {
  contactUuid: string;
  contactCareProgramId?: string;
  careProgramStartDate?: string;
  onClose?: () => void;
}

const TimeLoggingComponent = (props: ITimeLoggingComponentProps) => {
  const intl = useIntl();
  const toast = useToast();
  const [timeLoggingComponentState, setTimeLoggingComponent] =
    useState<ITimeLoggingComponentState>({
      openManualTimeLogDrawer: false,
      recordedTime: {minutes: 0, seconds: 0},
      openActivityDrawer: false,
      isLoggedTimeCleared: false,
      timerState: TimerState.RUNNING,
      generateBillAfterTimeLog: false,
    });
  const [loadingState, setLoadingState] = useState({
    loadingPreviousRecordedTime: false,
  });
  const {
    contactCareProgramId,
    previousRecordedTime,
    timeLoggingComponentLoaders,
  } = UseTimeLogForBilling({
    contactCareProgramId: props?.contactCareProgramId,
  });

  const [saveRecordedTime] = useMutation(SAVE_RECORDED_TIME_FOR_BILLING, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    onCompleted: (data) => {
      const eventBus = EventBus.getEventBusInstance();
      eventBus.broadcastEvent(CARE_PROGRAM_EVENTS.UPDATE_RECORDED_TIME, {
        seconds: data.logTimerActivityMinutes.timerSeconds
      });
    },
    onError: () => {
      showToast(toast, 'Error in saving recorded time', ToastType.error, 4000);
    },
  });

  const isManualDrawerOpen = timeLoggingComponentState.openManualTimeLogDrawer;
  const loadingComponent = timeLoggingComponentLoaders.previousRecordedTimeLoading || loadingState.loadingPreviousRecordedTime;


  useEffect(() => {
    if (previousRecordedTime) {
      setLoadingState((prev) => {
        return {
          ...prev,
          loadingPreviousRecordedTime: true,
        };
      });
      const isPreviouslyRecordedTime =
        previousRecordedTime.minutes > 0 || previousRecordedTime.seconds > 0;
      setTimeLoggingComponent((prev) => {
        return {
          ...prev,
          recordedTime: {
            minutes: previousRecordedTime.minutes,
            seconds: previousRecordedTime.seconds,
          },
          timerState: TimerState.RUNNING ,
        };
      });
      setLoadingState((prev) => {
        return {
          ...prev,
          loadingPreviousRecordedTime: false,
          timerState: TimerState.RUNNING
        };
      });
    }
  }, [previousRecordedTime]);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(CARE_PROGRAM_EVENTS.OPEN_TIME_LOGGING_DRAWER, handleOpenTimeLoggingDrawer);
    return () => {
      eventBus.removeEventListener(handleOpenTimeLoggingDrawer);
    };
  }, []);

  const handleOpenTimeLoggingDrawer = () => {
    setTimeLoggingComponent((prev) => {
      return {
        ...prev,
        generateBillAfterTimeLog: true,
        openManualTimeLogDrawer: true,
      };
    });
  };

  const saveRecordedSeconds = (minutes?: number, seconds?: number, timerReset?: boolean) => {
    const time =  (minutes || timeLoggingComponentState.recordedTime.minutes) * 60 +
    (seconds || timeLoggingComponentState.recordedTime.seconds)
    const previousTime = (previousRecordedTime?.minutes || 0) * 60 + (previousRecordedTime?.seconds || 0);
    if (contactCareProgramId && time !== previousTime) {
      const differenceInTime = time > previousTime ? time - previousTime : time;
      saveRecordedTime({
        variables: {
          params: {
            contactCareProgramId: contactCareProgramId,
            ...(!timerReset && {timerNoOfSeconds: differenceInTime}),
            ...(timerReset && {timerResetFlag: true})
          },
        },
      });
    }
  };

  const handleStop = (minutes: number, seconds: number) => {
    if (minutes === 0 && seconds === 0) {
      return;
    }
    saveRecordedSeconds(minutes, seconds);
    setTimeLoggingComponent((prev) => ({
      ...prev,
      recordedTime: { minutes, seconds },
    }));
  };

  const handleReset = (isLocalReset?: boolean) => {
    setTimeLoggingComponent((prev) => ({
      ...prev,
      recordedTime: { minutes: 0, seconds: 0 },
      timerState: TimerState.INITIAL,
      isLoggedTimeCleared: true,
    }));
      saveRecordedSeconds(0, 0, true);
  };

  const onCloseOfActivityDrawer = () => {
    setTimeLoggingComponent((prev) => {
      return {
        ...prev,
        openActivityDrawer: false,
      };
    });
  };

    const manualLogTimeElement = (
      <Pressable
        onPress={() => {
          setTimeLoggingComponent((prev) => {
            return {
              ...prev,
              openManualTimeLogDrawer: true,
            };
          });
        }}
      >
        <Stack
          style={styles.manualLogStack}
          space={4}
          direction={'row'}
        >
          <LogTimeSvg />
          <Text
            style={styles.manualLogText}
          >
            {intl.formatMessage({id: 'logTimeManually'})}
          </Text>
        </Stack>
      </Pressable>
    );

    const onLoggedTimePress = () => {
      setTimeLoggingComponent((prev) => {
        return {
          ...prev,
          openManualTimeLogDrawer: true,
        };
      });
    }


    const handleTimerStateChange = (timerState: TimerState, time?:{minutes: number, seconds: number}) => {
      setTimeLoggingComponent((prev) => {
        return {
          ...prev,
          timerState,
          isLoggedTimeCleared: false,
        };
      });
      switch (timerState) {
        case TimerState.STOPPED:
          handleStop(time?.minutes || 0, time?.seconds || 0);
          break;

        case TimerState.INITIAL:
          const isManualReset = time?.minutes === 0 && time?.seconds === 0;
          handleReset(!isManualReset);
          break;

        case TimerState.RUNNING:
        case TimerState.PAUSED:
        default:
          break;
      }
    }

    const stopWatchElement = (
      <Stopwatch
        defaultTimerState={timeLoggingComponentState.timerState}
        isLoggedTimeCleared={timeLoggingComponentState?.isLoggedTimeCleared}
        previousRecordedTime={previousRecordedTime}
        onTimerStateChange={handleTimerStateChange}
        defaultTime={timeLoggingComponentState.recordedTime}
        syncCode={contactCareProgramId}
        onLoggedTimePress={onLoggedTimePress}
        onLogTime={onLoggedTimePress}
      />
    )

  const getTimerElement = () => {
    if (loadingComponent ) {
      return (
        <Skeleton.Input
          active
          style={{
            width: 100,
            borderRadius: 8
          }}
        />
      );
    }
    const isManualLogTimeElementVisible = ![TimerState.RUNNING, TimerState.PAUSED, TimerState.STOPPED].includes(timeLoggingComponentState.timerState);
    return (
      <Stack direction={'row'} style={{alignItems: 'center'}}>
        {isManualLogTimeElementVisible && manualLogTimeElement}
        {isManualLogTimeElementVisible && <Text style={styles.pointText}>•</Text>}
        {stopWatchElement}
      </Stack>
    );
  }

  const handleTimeLogSave = () => {
    if (timeLoggingComponentState.generateBillAfterTimeLog) {
      const eventBus = EventBus.getEventBusInstance();
      eventBus.broadcastEvent(CARE_PROGRAM_EVENTS.GENERATE_BILLING_DATA, {
        contactCareProgramId: contactCareProgramId,
      });
      setTimeLoggingComponent((prev) => {
        return {
          ...prev,
          generateBillAfterTimeLog: false,
        };
      });
      handleReset(true);
    }
  }

  return !loadingComponent && !contactCareProgramId ? (
    <></>
  ) : (
    <>
      {getTimerElement()}
      {isManualDrawerOpen && (
        <ManualTimeLoggingDrawer
          careProgramStartDate={props?.careProgramStartDate}
          onClose={(
            isSave?: boolean,
            totalBillingMinutes?: number,
            timerNoOfSeconds?: number
          ) => {
            if (isSave) {
              handleTimeLogSave();
            }
            setTimeLoggingComponent((prev) => {
              return {
                ...prev,
                openManualTimeLogDrawer: false,
                ...(isSave && {
                  timerState: TimerState.INITIAL,
                })
              };
            });
            props.onClose && props.onClose();
          }}
          recordedTime={timeLoggingComponentState.recordedTime}
          contactCareProgramId={contactCareProgramId}
        />
      )}
      {timeLoggingComponentState?.openActivityDrawer && (
        <Drawer
          destroyOnClose
          placement="right"
          open={true}
          width={'30%'}
          onClose={onCloseOfActivityDrawer}
          closable
          title={
            <ModalActionTitle
              title={'ccmActivity'}
              titleColor={''}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: 'close',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: onCloseOfActivityDrawer,
                },
              ]}
            />
          }
        >
          <ProviderActivityAndOutreachComponent
            showActivityFilter={true}
            contactCareProgramId={contactCareProgramId}
            isTimeTrackingView={true}
          />
        </Drawer>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  loggedTimeStack: {
    padding: 8,
    alignItems: 'center',
    borderRadius: 4,
    width: 'fit-content',
  },
  loggedTimeButton: {
    flexDirection: 'row',
  },
  loggedTimeText: {
    color: Colors.FoldPixel.PRIMARY300,
    marginHorizontal: 3,
  },
  resetText: {
    fontWeight: '500',
    fontSize: 14,
    color: Colors.FoldPixel.GRAY300,
  },
  pointText: {
    fontWeight: '700',
    fontSize: 18,
    color: Colors.FoldPixel.GRAY300,
  },
  pauseText: {
    fontWeight: '500',
    fontSize: 14,
    color: Colors.FoldPixel.GRAY300,
  },
  componentStack: {
    padding: 8,
  },
  activityTimerText: {
    fontWeight: '400',
    fontSize: 14,
    color: Colors.FoldPixel.GRAY400,
  },
  billableTimeText: {
    fontWeight: '400',
    fontSize: 14,
    color: Colors.FoldPixel.PRIMARY300,
  },
  manualLogStack: {
    padding: 8,
    alignItems: 'center',
    width: 'fit-content',
  },
  manualLogText: {
    fontWeight: '500',
    fontSize: 14,
    color: Colors.FoldPixel.GRAY300,
  },
  containerView: {
    backgroundColor: Colors.Custom.ContainerBGColor,
    borderRadius: 8,
    shadowColor: 'rgba(16, 24, 40, 0.06)',
    shadowOffset: {width: 1, height: 2},
    shadowRadius: 0,
    paddingHorizontal: 4,
    paddingVertical: 8,
    marginTop: 12,
  },
});


export default TimeLoggingComponent;
