import React from 'react';
import {View} from 'react-native';
import {MessageActionID} from '../../MessageAction/MessageActionView';
import CreateNoteSvg from '../../Svg/CreateNoteSvg';
import CreateTaskSvg from '../../Svg/CreateTaskSvg';
import EmailForwardSvg from '../../Svg/EmailForwardSvg';
import EmailReplyAllSvg from '../../Svg/EmailReplyAllSvg';
import EmailReplySvg from '../../Svg/EmailReplySvg';
import InfoSvg from '../../Svg/InfoSvg';
import MessageDeleteSvg from '../../Svg/MessageDeleteSvg';
import MessageEditSvg from '../../Svg/MessageEditSvg';
import ReplySvg from '../../Svg/ReplySvg';
import ShareSvg from '../../Svg/ShareSvg';
import {Colors} from '../../../../styles';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { TestIdentifiers, testID } from '../../../../testUtils';
import AssignMessageTypeSvg from '../../Svg/PersonActionSvgIcons/AssignMessageTypeSvg';
import MarkAsUnreadSvg from '../../Svg/PersonActionSvgIcons/MarkAsUnreadSvg';

export const getSVG = (actionId: MessageActionID) => {
  switch (actionId) {
    case MessageActionID.reply:
      return <ReplySvg height={20} width={20} customStrokeColor={Colors.FoldPixel.GRAY400}/>;
      break;
    case MessageActionID.edit:
      return <MessageEditSvg height={18} width={18} customStrokeColor={Colors.FoldPixel.GRAY400}/>;
      break;
    case MessageActionID.delete:
      return <MessageDeleteSvg height={18} width={18} customStrokeColor={Colors.FoldPixel.GRAY400}/>;
      break;
    case MessageActionID.share:
      return <ShareSvg height={18} width={18} customStrokeColor={Colors.FoldPixel.GRAY400}/>;
      break;
    case MessageActionID.createNote:
      return <CreateNoteSvg height={18} width={18} customStrokeColor={Colors.FoldPixel.GRAY400}/>;
      break;
    case MessageActionID.createTask:
      return <CreateTaskSvg height={18} width={18} customStrokeColor={Colors.FoldPixel.GRAY400}/>;
      break;
    case MessageActionID.info:
      return <InfoSvg customDimension='20' customColor={Colors.FoldPixel.GRAY400}/>;
      break;
    case MessageActionID.markAsReadUnread:
      return (
        <View
          {...testID(TestIdentifiers.showPasswordBtn)}
        >
          <MarkAsUnreadSvg height={18} width={18} customStrokeColor={Colors.FoldPixel.GRAY400} />
        </View>
      );
      break;
    case MessageActionID.assignType:
      return (
        <View
          style={{
            alignItems: 'center',
            height: 18,
            width: 18,
          }}
          {...testID('image')}
        >
          <AssignMessageTypeSvg height={18} width={18} customStrokeColor={Colors.FoldPixel.GRAY400} />
        </View>
      );
    case MessageActionID.emailReply:
      return <ReplySvg height={18} width={18} customStrokeColor={Colors.FoldPixel.GRAY400}/>;

    case MessageActionID.replyAll:
      return <EmailReplyAllSvg height={18} width={18} customStrokeColor={Colors.FoldPixel.GRAY400}/>;

    case MessageActionID.forward:
      return <EmailForwardSvg height={18} width={18} customStrokeColor={Colors.FoldPixel.GRAY400}/>;

    default:
      return <View />;
      break;
  }
};

export const getDisplayText = (actionId: MessageActionID) => {
  switch (actionId) {
    case MessageActionID.reply:
      return 'Reply';
      break;
    case MessageActionID.edit:
      return 'Edit';
      break;
    case MessageActionID.delete:
      return 'Delete';
      break;
    case MessageActionID.share:
      return 'Share';
      break;
    case MessageActionID.createNote:
      return 'Create Note';
      break;
    case MessageActionID.createTask:
      return 'Create Task';
      break;
    case MessageActionID.info:
      return 'Info';
      break;
    case MessageActionID.markAsReadUnread:
      return 'Mark as Unread';
      break;
    case MessageActionID.assignType:
      return 'Assign Message Type';
    case MessageActionID.emailReply:
      return 'Reply';
    case MessageActionID.replyAll:
      return 'Reply All';
    case MessageActionID.forward:
      return 'Forward';
    default:
      return '';
      break;
  }
};
