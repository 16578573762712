import {Button, Drawer, Skeleton} from 'antd';
import {BUTTON_TYPE, MLOV_CATEGORY} from '../../constants';
import {Colors} from '../../styles/Colors';
import {ModalActionTitle} from '../common/ModalActionTitle/ModalActionTitle';
import Stack from '../common/LayoutComponents/Stack';
import {Text} from 'react-native';
import {useIntl} from 'react-intl';
import {IMlov} from '../../Interfaces';
import {useContext, useState} from 'react';
import RadioButton from '../CommonComponents/RadioButton';
import { FormControl, TextArea } from 'native-base';
import { DisplayText } from '../common/DisplayText/DisplayText';
import { IAlertActionReasons } from './interfaces';
import { getMlovListFromCategory, getMlovObjectFromId, getMlovValueFromId } from '../../utils/mlovUtils';
import { CommonDataContext } from '../../context/CommonDataContext';
import { LeftOutlined } from '@ant-design/icons';

interface IAlertActionReasonView {
  reasonsList: IAlertActionReasons[];
  onCancelView: () => void;
  onSave: (selectedReason?: IAlertActionReasons, note?: string) => void;
  saveLoading: boolean;
  drawerTitle: string;
  reasonsLoading: boolean;
  isNoteRequired?: boolean;
  drawerSubTitleTextId?: string;
}

interface IComponentStateAlertActionReasonView {
  note: string;
  selectedReason?: IAlertActionReasons;
}

const AlertActionReasonView = (props: IAlertActionReasonView) => {
  const {reasonsList, onCancelView, onSave, saveLoading, drawerTitle, reasonsLoading, isNoteRequired, drawerSubTitleTextId} = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const isSideCarContext = !!mlovData.sidecarContext?.isSidecar;
  const taskAlertPreferenceReasonsMlovs = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_ALERT_DISPLAY_PREFERENCE_REASONS
  )
  const [componentState, setComponentState] =
    useState<IComponentStateAlertActionReasonView>({note: ''});

  const renderReasonsView = (
    <Stack direction={'column'} space={16}>
      <Text
        style={{
          fontWeight: '500',
          fontSize: 16,
          color: Colors.FoldPixel.GRAY400,
        }}
      >
        {intl.formatMessage({id: `${drawerSubTitleTextId}`})}
      </Text>
      {reasonsList.map((reason) => {
        const reasonMlov = getMlovObjectFromId(
          reason.taskAlertDisplayPreferenceReasonId,
          taskAlertPreferenceReasonsMlovs
        );
        const titleReasonText = reasonMlov?.value;
        return (
          <Stack direction={'row'} space={8} style={{alignItems: 'center'}}>
            <RadioButton
              isChecked={componentState?.selectedReason?.id === reason.id}
              onChange={() =>
                setComponentState((prev) => ({
                  ...prev,
                  selectedReason: reason,
                }))
              }
            />
            <Text
              style={{
                width: 'max-content',
                color: Colors.FoldPixel.GRAY300,
                marginTop: '1px',
              }}
            >
              <Text
                style={{color: Colors.FoldPixel.GRAY400, fontWeight: '500'}}
              >
                {titleReasonText}
              </Text>
            </Text>
          </Stack>
        );
      })}
      <FormControl >
        <FormControl.Label >
          <DisplayText
            size={'smMedium'}
            extraStyles={{
              fontWeight: '500',
              fontSize: 16,
              color: Colors.FoldPixel.GRAY400,
            }}
            textLocalId="singleNote"
          />
        </FormControl.Label>
        <TextArea
          multiline
          defaultValue=""
          variant={'outline'}
          value={componentState.note}
          style={{
            color: Colors.Custom.TextColor,
            fontSize: 18,
          }}
          placeholder={'Enter Note'}
          placeholderTextColor={Colors.Custom.AlertsDescriptionColor}
          maxH={176}
          autoComplete="off"
          onChangeText={(plainString) => {
            setComponentState((prev) => ({
              ...prev,
              note: plainString,
            }));
          }}
        />
      </FormControl>
    </Stack>
  );

  const backBtn = () => ({
    show: true,
    id: 1,
    btnText: 'cancel',
    textColor: Colors.Custom.mainSecondaryBrown,
    variant: BUTTON_TYPE.SECONDARY,
    isTransBtn: false,
    onClick: () => {
      onCancelView();
    },
  });
  
  return (
    <Drawer open={true} width={isSideCarContext ? '100%' : '40%'} title={
      <ModalActionTitle
      title={drawerTitle}
      titleColor={''}
      leftBackButton={isSideCarContext ? <Button onClick={() => onCancelView()} type="text" icon={<LeftOutlined />}/> : undefined}
      buttonList={[
          {...backBtn(), show: !isSideCarContext},
          {
            show: true,
            id: 3,
            btnText: 'save',
            textColor: Colors.Custom.mainPrimaryPurple,
            variant: BUTTON_TYPE.PRIMARY,
            isLoading: saveLoading,
            isDisabled: isNoteRequired
              ? !componentState?.selectedReason?.id || !componentState.note
              : !componentState?.selectedReason?.id && !componentState?.note,
            isTransBtn: false,
            onClick: () => {
              onSave(componentState?.selectedReason, componentState.note);
            },
          },
        ]}
      />
    }>
      {reasonsLoading ? <Skeleton /> : renderReasonsView}
    </Drawer>
  );
};

export default AlertActionReasonView;
