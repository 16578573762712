import {CARE_PROGRAM_TYPE_CODES} from '../../../../../constants/MlovConst';
import {IStepsLog} from '../../../../../services/ContactCareProgram/interface';
import {CARE_PROGRAM_STEP_TYPE} from '../../../../common/MemebersView/constant';
import {
  ButtonType,
  IFoldButtonV2Props,
} from '../../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import {AttributeKey, IContactCareProgramStep} from '../../interface';
import {StepAction, useCareProgramStepActions} from '../hooks/useCareProgramStepActions';
import BillSvg from '../../../../common/Svg/GlobalActionSvg/BillSvg';
import {CareProgramStatus} from '../hooks/useCareProgramStatus';
import { useCareProgramEditability } from '../hooks/useCareProgramEditability';
import { useContactCareProgramContext } from '../ContactCareProgram.context';

interface ActionList extends IFoldButtonV2Props {
  code: StepAction;
}

export const getContentHeaderActionList = (props: {
  activeStep: IContactCareProgramStep;
  selectedStepLog: IStepsLog;
  selectedStepId: string;
  careProgramStepStatus: {
    done: string;
    skip: string;
  };
  activeStepCode: string;
  careProgramTypeCode: string;
  careProgramStatusId: string;
  careProgramStatus: CareProgramStatus;
  isPrevFormInstanceExists: boolean;
}): ActionList[] => {
  const {
    activeStep,
    selectedStepLog,
    selectedStepId,
    careProgramTypeCode,
    careProgramStepStatus,
    activeStepCode,
    careProgramStatus,
    careProgramStatusId,
    isPrevFormInstanceExists,
  } = props;
  const {isAllowedToTakeActions} = useCareProgramEditability();
  const list: ActionList[] = [];
  
  const isCCMCareProgram = careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.CCM;
  const isECMCareProgram = careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.ECM;
  const isCHFCareProgram = careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.CHF;

  const isBillingStep = activeStepCode === CARE_PROGRAM_STEP_TYPE.BILLING;
  const isReferralStep = activeStepCode === CARE_PROGRAM_STEP_TYPE.REFERRAL;
  const isOutreachStep = [
    CARE_PROGRAM_STEP_TYPE.OUTREACH,
    CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH,
    CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY,
    CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY,
    CARE_PROGRAM_STEP_TYPE.CARE_MANAGER_APPOINTMENT,
    CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT
  ].includes(activeStepCode);

  const disableSkipActions = activeStep?.careProgramStepAdditionalAttributes?.find(
    attribute => attribute?.attributeKey === AttributeKey.DISABLED_EXTRA_STEP_ACTIONS
  )?.attributeValue?.valueJson?.includes(StepAction.SKIP) || false;

  const isAssessmentView: boolean = [
    CARE_PROGRAM_STEP_TYPE.ASSESSMENT
  ].includes(activeStepCode);

  const isReadOnlyView =
    (selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.done ||
      selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.skip);

  const isCareProgramCompleted =
    careProgramStatus.completed === careProgramStatusId;

  const showStatusActions =
  isAllowedToTakeActions &&
    !isBillingStep &&
    !isReadOnlyView &&
    !isOutreachStep;

  const showSendClaim =
    isAllowedToTakeActions &&
    isBillingStep &&
    (!isReadOnlyView || isCCMCareProgram || isECMCareProgram || isCHFCareProgram) &&
    !isCareProgramCompleted;

  const showGenerateBill =
    isAllowedToTakeActions &&
    isBillingStep &&
    !isCareProgramCompleted &&
    (isCCMCareProgram || isECMCareProgram || isCHFCareProgram);

  const isRequiredStep = activeStep?.isRequired;

  const canShowSkip = (showStatusActions && !isRequiredStep && !disableSkipActions)

  const canShowReviewed = showStatusActions
  const canShowEdit = (isAssessmentView || isReferralStep) && isAllowedToTakeActions && !isCareProgramCompleted && (!canShowSkip && !canShowReviewed);

  if (isReadOnlyView && !showSendClaim && !showGenerateBill && !canShowEdit) {
    return [];
  }

  if (canShowSkip) {
    list.push({
      label: 'Skip',
      buttonType: ButtonType.secondary,
      code: StepAction.SKIP,
    });
  }

  if (canShowReviewed) {
    list.push({
      label: 'Review',
      buttonType: ButtonType.tertiary,
      code: StepAction.REVIEW,
      isDisabled: isPrevFormInstanceExists,
    });
  }

  if (canShowEdit) {
    list.push({
      label: 'Edit',
      buttonType: ButtonType.tertiary,
      code: StepAction.EDIT,
    });
  }

  if (showGenerateBill) {
    list.push({
      label: 'Generate Bill',
      buttonType: ButtonType.tertiary,
      code: StepAction.GENERATE_BILL,
      leftIcon: <BillSvg />,
    });
  }

  if (showSendClaim) {
    list.push({
      label: 'Send Claim',
      buttonType: ButtonType.primary,
      code: StepAction.SEND_CLAIM,
    });
  }

  return list;
};
