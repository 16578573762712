import { Path, Svg } from "react-native-svg";

export const AddBlockIconSvg = () => {
  return (
    <Svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <Path
        d="M19.1663 12.0002C19.1663 15.6821 16.1816 18.6668 12.4997 18.6668C8.81778 18.6668 5.83301 15.6821 5.83301 12.0002C5.83301 8.31826 8.81778 5.3335 12.4997 5.3335M12.4997 9.3335V12.0002L14.1663 13.6668M18.3497 6.15016L15.3163 9.18344M19.1663 7.66683C19.1663 8.95549 18.1217 10.0002 16.833 10.0002C15.5443 10.0002 14.4997 8.95549 14.4997 7.66683C14.4997 6.37817 15.5443 5.3335 16.833 5.3335C18.1217 5.3335 19.1663 6.37817 19.1663 7.66683Z"
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
