import React, { createContext, useContext, useReducer, Dispatch } from 'react';
import { patientDashboardReducer, initialPatientDashboardState } from './patientDashboardReducer';
import { DayOptimizerState, DayOptimizerContextType, DayOptimizerAction } from './interfaces';
import { isCalculatePriorityNumberEnabled } from '../../utils/commonUtils';
import { DayOptimizerColumnCode } from '../../components/common/AppDashboard/DayOptimizer/DayOptimizerConstants';

export const DayOptimizerContext = createContext({} as DayOptimizerContextType);

export const useDayOptimizer = () => {
  const context = useContext(DayOptimizerContext);
  if (!context) {
    throw new Error('useDayOptimizer must be used within a DayOptimizerProvider');
  }
  return context;
};

export const DayOptimizerProvider = ({ children }: { children: React.ReactNode }) => {
  const [patientDashboardState, patientDashboardDispatch] = useReducer(patientDashboardReducer, initialPatientDashboardState);
  const isCalculatePriorityNumberFlagEnabled = isCalculatePriorityNumberEnabled();
  const hideFlagBasedColumnsOnFlag =  () => {
    const columns: DayOptimizerColumnCode[] = [];
    if (!isCalculatePriorityNumberFlagEnabled) {
      columns.push(DayOptimizerColumnCode.PRIORITY);
    }
    return columns;
  };

  const getFilteredColumnsList = () => {
    const columns = patientDashboardState?.columnShuffleDropDownList?.filter((column: any) => {
      if (hideFlagBasedColumnsOnFlag().includes(column?.columnCode)) { 
        return false;
      }
      return true;
    })
    return columns;
  }

  const state: DayOptimizerState = {
    patientDashboard: {
      ...patientDashboardState,
      columnShuffleDropDownList: getFilteredColumnsList()
    }
  };

  const dispatch = (action: DayOptimizerAction) => {
    patientDashboardDispatch(action);
  };

  const value: DayOptimizerContextType = {
    state,
    dispatch
  };
  return (
    <DayOptimizerContext.Provider value={value}>
      {children}
    </DayOptimizerContext.Provider>
  );
};
