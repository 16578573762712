import React, { useState } from 'react';
import { Colors } from '../../../../../styles';
import { TaskStyles } from './styles';
import { FormControl, HStack, View, VStack, Text, Pressable } from 'native-base';
import FoldButtonV2, { ButtonType } from '../../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import { useIntl } from 'react-intl';
import { RichTextEditor, TEXT_ONLY_MODULES } from '../../../RichTextEditor/RichTextEditor';
import { DisplayText } from '../../../DisplayText/DisplayText';
import { Popover, Upload,Tooltip } from 'antd';
import { IMediaLibraryData } from '../../../../RightSideContainer/ContentManagement/MediaLibrary/interfaces';
import MediaSelectFolder from '../../../Svg/MediaSelectFolder';
import AttachmentsItemList from '../AttachmentsItemList';
import { manageAttachmentsListData, uniqueArray } from '../../AddTaskUtils';
import { IAddOrUpdateTaskState } from '../../interfaces';
import { UPLOAD_ACCEPTED_FILE_TYPES } from '../../../../PersonOmniView/LeftContainer/OtherDetails/PatientDocuments/UploadPatientDocument';
import Feather from 'react-native-vector-icons/Feather';
import Icon from 'react-native-vector-icons/Feather';
import TrashBin2Icon from '../../../../../assets/Icons/TrashBin2Icon';

interface AddOrUpdateEvidenceViewProps {
  value: string;
  handleSave: (value: string) => void;
  handleCancel: () => void;
  fileList?: any[];
  handleChange?: (value: any) => void;
  task?: IAddOrUpdateTaskState;
}

const AddOrUpdateEvidenceView: React.FC<AddOrUpdateEvidenceViewProps> = ({
  value,
  handleSave,
  handleCancel,
  fileList,
  handleChange,
  task = {} as IAddOrUpdateTaskState
}): JSX.Element => {
  const intl = useIntl();
  const fileListWithoutDeleted = fileList?.filter(item => !item.isDeleted);
  const [componentState, setComponentState] = useState<{
    showMediaDrawer: boolean;
    showAttachmentsPopover: boolean;
    selectedMedia: IMediaLibraryData;
    evidenceText: string;
  }>({
    showMediaDrawer: false,
    showAttachmentsPopover: false,
    selectedMedia: {} as IMediaLibraryData,
    evidenceText: value
  });

  const onHandleSave = (): void => {
    handleSave(componentState.evidenceText);
  };

  const isSaveDisabled: boolean = !componentState.evidenceText?.trim() || componentState.evidenceText?.trim() === '';

  return (
    <View style={{ flex: 1, paddingHorizontal: 28 }}>
      <View>
        <FormControl isRequired={false}>
          <HStack alignItems={'center'} flex={1}>
            <FormControl.Label marginLeft={0} flex={1}>
              <DisplayText
                size={'smMedium'}
                extraStyles={{
                  color: Colors.Custom.alertsDescriptionColor,
                  fontWeight: 500,
                  fontSize: 14,
                }}
                textLocalId={'evidence'}
              />
            </FormControl.Label>
          </HStack>
          <VStack>
            <View flex={1}>
              <RichTextEditor
                placeholder={intl.formatMessage({ id: 'enterEvidentialSummary' })}
                style={{
                  borderRadius: fileListWithoutDeleted?.length ? 0 : 4,
                  borderColor: Colors.FoldPixel.GRAY150,
                  height: 100,
                }}
                modules={TEXT_ONLY_MODULES}
                valueStr={componentState.evidenceText}
                hideBottomBorder={fileListWithoutDeleted?.length ? true : false}
                onChangesValue={(data: string) => {
                  setComponentState((prev) => ({
                    ...prev,
                    evidenceText: data,
                  }));
                }}
              />
              <Popover
                overlayInnerStyle={{
                  borderRadius: 12,
                  padding: 0,
                  width: 200,
                }}
                trigger="click"
                placement="bottomLeft"
                open={componentState.showAttachmentsPopover}
                onOpenChange={(newOpen: boolean) => {
                  if (!newOpen) {
                    setComponentState((prev) => ({
                      ...prev,
                      showAttachmentsPopover: newOpen,
                    }));
                  }
                }}
                showArrow={false}
                overlayStyle={{ borderRadius: 4 }}
                content={
                  <VStack space={3}>
                    <Pressable
                      onPress={() =>
                        setComponentState((prev) => ({
                          ...prev,
                          showMediaDrawer: true,
                          showAttachmentsPopover: false,
                        }))
                      }
                    >
                      <HStack space={3} alignItems={'center'}>
                        <MediaSelectFolder />
                        <Text fontWeight={400}>
                          {intl.formatMessage({ id: 'selectFromMedia' })}
                        </Text>
                      </HStack>
                    </Pressable>
                    <Upload
                      multiple={true}
                      beforeUpload={() => false}
                      maxCount={10}
                      onChange={(info) => {
                        setComponentState((prev) => ({
                          ...prev,
                          showAttachmentsPopover: false,
                        }));
                        const tempFileList = [...info.fileList];
                        if (fileList?.length) {
                          handleChange?.({
                            ...task,
                            fileList: uniqueArray([
                              ...(fileList || []),
                              ...tempFileList,
                            ]),
                          });
                        } else {
                          handleChange?.({
                            ...task,
                            fileList: tempFileList,
                          });
                        }
                      }}
                      fileList={[]}
                      className="msg-attachment"
                      accept={UPLOAD_ACCEPTED_FILE_TYPES}
                    >
                      <HStack space={3} alignItems={'center'}>
                        <Feather
                          name="upload"
                          size={18}
                          color={Colors.Custom.Gray500}
                        />
                        <Text fontWeight={400}>
                          {intl.formatMessage({ id: 'uploadNew' })}
                        </Text>
                      </HStack>
                    </Upload>
                  </VStack>
                }
              >
                <Pressable
                onPress={() =>
                  setComponentState((prev) => {
                    return {
                      ...prev,
                      showAttachmentsPopover: true,
                    };
                  })
                }
                position={'absolute'}
                top={3}
                right={'16px'}
                flexDir={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
              >
                <Tooltip
                  title={intl.formatMessage({
                    id: 'taskAttachmentUploadSupportAndSize',
                  })}
                >
                  <Icon
                    name="paperclip"
                    size={18}
                    color={Colors.FoldPixel.GRAY400}
                    style={{marginRight: 4}}
                  />
                </Tooltip>
              </Pressable>
              </Popover>
            </View>
            {(fileListWithoutDeleted?.length || 0) > 0 && (
              <AttachmentsItemList
                handleChange={handleChange}
                value={task}
                fileList={manageAttachmentsListData(fileList || [])}
              />
            )}
          </VStack>
        </FormControl>
      </View>
      <View style={TaskStyles.buttonContainer}>
        <HStack space={2}>
          <FoldButtonV2
            key="comment"
            label={intl.formatMessage({ id: 'save' })}
            buttonType={ButtonType.primary}
            isDisabled={isSaveDisabled}
            onPress={onHandleSave}
          />
          <FoldButtonV2
            key="cancel"
            label={intl.formatMessage({ id: 'cancel' })}
            buttonType={ButtonType.secondary}
            onPress={handleCancel}
          />
        </HStack>
      </View>
    </View>
  );
};

export default AddOrUpdateEvidenceView;