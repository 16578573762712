import {Dimensions, StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const addOrUpdateAlertStyles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
  },
  scrollView: {
    height: Dimensions.get('window').height - 154,
  },
  saveCareGapTaskButton: {
    paddingHorizontal: 8,
    paddingVertical: 7.5,
    backgroundColor: Colors.FoldPixel.PRIMARY100,
    borderRadius: 4,
    borderWidth: .5,
    borderColor: Colors.FoldPixel.PRIMARY200,
    borderStyle: 'solid',
  },
  dropdownButton: {
    padding: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.FoldPixel.PRIMARY300,
    backgroundColor: Colors.Custom.White,
  },
  declineOption: {
    padding: 2,
    minWidth: 100,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.Custom.Gray50,
    },
  }
});
