import React from 'react';
import Svg, { Circle, RadialGradient, Defs, LinearGradient, Stop, Path } from 'react-native-svg';

const MedicationEmptyStateSvg = () => {
  return (
    <Svg width="102" height="102" viewBox="0 0 102 102" fill="none" >
    <Circle cx="51" cy="51" r="50" stroke="url(#paint0_radial_43_6875)" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="1.46 1.46"/>
    <Circle cx="51.0007" cy="50.9997" r="42.6667" stroke="url(#paint1_radial_43_6875)" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="1.46 1.46"/>
    <Path d="M15.8545 51.1016C15.8545 31.7716 31.5245 16.1016 50.8545 16.1016C70.1845 16.1016 85.8545 31.7716 85.8545 51.1016C85.8545 70.4315 70.1845 86.1016 50.8545 86.1016C31.5245 86.1016 15.8545 70.4315 15.8545 51.1016Z" fill="url(#paint2_linear_43_6875)"/>
    <Path d="M58.6753 43.324C58.6753 43.324 57.8115 47.8052 52.8079 52.8088C47.8044 57.8124 43.3239 58.6754 43.3239 58.6754M63.7924 63.7925C59.5532 68.0317 52.6802 68.0317 48.441 63.7925L38.2067 53.5583C33.9676 49.3191 33.9675 42.446 38.2067 38.2068C42.4459 33.9677 49.319 33.9677 53.5581 38.2068L63.7924 48.4411C68.0316 52.6803 68.0316 59.5534 63.7924 63.7925Z" stroke="#8A94A8" stroke-width="1.59722" stroke-linecap="round"/>
    <Defs>
    <RadialGradient id="paint0_radial_43_6875" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.0001 73.6082) rotate(-90.639) scale(78.4672)">
    <Stop stop-color="#A1AABA"/>
    <Stop offset="1" stop-color="#E4E7EC"/>
    </RadialGradient>
    <RadialGradient id="paint1_radial_43_6875" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.0007 70.292) rotate(-90.639) scale(66.9587)">
    <Stop stop-color="#A1AABA"/>
    <Stop offset="1" stop-color="#E4E7EC"/>
    </RadialGradient>
    <LinearGradient id="paint2_linear_43_6875" x1="26.0002" y1="24" x2="74.0002" y2="84.75" gradientUnits="userSpaceOnUse">
    <Stop stop-color="#F9FAFC"/>
    <Stop offset="1" stop-color="#F1F2F3"/>
    </LinearGradient>
    </Defs>
    </Svg>
    
  );
};

export default MedicationEmptyStateSvg;
