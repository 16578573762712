import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import React, {useEffect, useState} from 'react';
import {ISearchFieldProps} from '../../../Interfaces';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../styles';
import {Icon, Text, VStack} from 'native-base';
import { useLazyQuery } from '@apollo/client';
import { GET_BARRIERS } from '../../../services/Components/ComponentQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { IBarrier } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Barriers/interfaces';
import TestIdentifiers from '../../../testUtils/TestIdentifiers';
import { testID } from '../../../testUtils/Utils';
export interface IHealthComponentItem {
    title: string;
    id?: string;
    tenantId?: string;
}

export enum AdditionalHealthComponentSearchType {
  Barrier = 'Barrier',
}

interface IAdditionalHealthComponentSearchProps extends ISearchFieldProps {
  searchType: AdditionalHealthComponentSearchType;
  placeholder?: string;
  disabled?: boolean;
  addNewOptionEnabled?: boolean;
  clearOnSelection?: boolean;
  additionalHeaders?: {[index: string]: any};
  skipAPICall?: boolean;
  enablePaginationOnScroll?: boolean;
  showAdditionalDetails?: boolean;
  isInvalid?: boolean;
  filterBarriers?: IBarrier[]
}

interface IHealthComponentState {
  loading: boolean;
  searchValue: string;
  healthComponentData: IHealthComponentItem[];
  selectedValue?: IHealthComponentItem;
  offset: number;
  pageSize: number;
  dataFinished: boolean;
}

const AdditionalHealthComponentSearch = (props: IAdditionalHealthComponentSearchProps) => {
  const defaultOffset = 0;
  const defaultPageSize = 25;
  const addNewPrefix = "Add "
  const {value, isShowError, searchType, isInvalid, onChange} = props;
  const [componentState, setComponentState] = useState<IHealthComponentState>({
    loading: false,
    searchValue: '',
    healthComponentData: [],
    selectedValue: undefined,
    offset: defaultOffset,
    pageSize: defaultPageSize,
    dataFinished: false,
  })

  const [
    getBarriers,
    getBarriersQuery,
  ] = useLazyQuery<{
    getBarriers: { total: number, barriers: {id: string, title: string, tenantId: string}[] },
  }>(GET_BARRIERS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

const searchValue = (searchString: string, forceTrigger?: boolean) => {
    const offset = defaultOffset;
    const pageSize = defaultPageSize;
    setComponentState((prev) => ({
      ...prev,
      searchValue: searchString,
      offset: offset,
      pageSize: pageSize,
      dataFinished: false,
      healthComponentData: [],
    }));
    if (props.skipAPICall) {
      const list: IHealthComponentItem[] = [];
      if (props.addNewOptionEnabled) {
        list.push({
          title: searchString.trim(),
          id: ''
        });
      }
      setComponentState((prev) => ({...prev, healthComponentData: list}));
    } else if (searchString.length > 1 || forceTrigger) {
      callAPI(searchString, offset, pageSize);
    }
  };

  const callAPI = (searchString: string, offset: number, pageSize: number) => {
    setComponentState((prev) => ({...prev, loading: true}));
    getBarriers({
      variables: {
        searchTerm: searchString,
        limit: pageSize,
        offset: offset,
      }
    })
      ?.then((response) => {
        let list: IHealthComponentItem[] = [];
        const totalCount = response?.data?.getBarriers?.total || 0;
        if (response?.data?.getBarriers?.barriers?.length) {
          list = response?.data?.getBarriers?.barriers;
        }
        setComponentState((prev) => {
          let finalList = prev.healthComponentData;
          const fetchedCount = list.length;
          if (props.addNewOptionEnabled && finalList?.length === 1 && !finalList?.[0]?.id) {
            finalList.splice(0, 1);
          }
          finalList = offset !== 0 ? [...finalList, ...list] : list;

          let filteredListTiles : any[] = [];
          if (props.filterBarriers && props.filterBarriers.length > 0) {
            filteredListTiles = props.filterBarriers.map((barrier) =>
              barrier.title?.toLocaleLowerCase()
            );
            finalList = finalList.filter(
              (barrier) =>
                !filteredListTiles.includes(barrier.title?.toLocaleLowerCase())
            );
          }

          if (
            props.addNewOptionEnabled &&
            finalList?.length === 0 &&
            !filteredListTiles.includes(
              searchString.trim()?.toLocaleLowerCase()
            )
          ) {
            finalList.push({
              title: searchString.trim(),
              id: '',
            });
          }
          return {
            ...prev,
            loading: false,
            dataFinished: fetchedCount === 0 || totalCount >= finalList?.length,
            offset: offset,
            healthComponentData: finalList,
            originalData: finalList,
          };
        });
      })
      .catch((error) => {
        setComponentState((prev) => ({...prev, loading: false}));
      });
  }

  const getDataFromId = (id: string) => {
    const matchedData = componentState.healthComponentData.filter((item) => {
      return item?.id === id;
    });
    if (matchedData.length > 0) {
      return {
        title: matchedData[0].title,
        id: matchedData[0]?.id,
        tenantId: matchedData[0]?.tenantId
      };
    }
  };

  const onOptionScroll = (event: any) => {
    const target = event.target
    if (
        props.enablePaginationOnScroll &&
        !componentState.loading &&
        !componentState.dataFinished &&
        target.scrollTop + target.offsetHeight === target.scrollHeight
      ) {
      const offset = componentState.offset + componentState.pageSize;
      setComponentState((prev) => ({...prev, loading: true}));
      target.scrollTo(0, target.scrollHeight);
      callAPI(componentState.searchValue, offset, componentState.pageSize);
    }
  };

  const checkIdMissingInData = () => {
    return componentState.healthComponentData.length === 1 && componentState.healthComponentData.some((item) => !item?.id)
  }

  const getSelectedValue = () => {
    if (props?.clearOnSelection) {
      return componentState.selectedValue
        ? {
            label: <Text>{componentState.selectedValue?.title}</Text>,
            value: componentState.selectedValue?.id,
            key: componentState.selectedValue?.id,
          }
        : undefined;
    } else {
      return value ? value.title : undefined;
    }
  }

  return (
    <Select
      size="large"
      showSearch
      {...testID(TestIdentifiers.searchBarrier)}
      allowClear
      suffixIcon={
        !componentState.searchValue && !props.skipAPICall ? (
          <Icon
            color={Colors.Custom.Gray500}
            as={AntIcon}
            name={'search1'}
            size="4"
          />
        ) : null
      }
      status={isInvalid ? 'error' : undefined}
      filterOption={false}
      disabled={props.disabled}
      value={getSelectedValue()}
      onSearch={debounce(searchValue, 500)}
      onChange={(value: any[], data: any) => {
        if (data?.label) {
          let selectedValue: IHealthComponentItem | undefined;
          setComponentState((prev) => ({...prev, searchValue: data.label}));
          if (data?.value) {
            selectedValue = getDataFromId(data.value);
          } else {
            selectedValue = {
              title: data?.label,
              id: '',
              tenantId: '',
            };
          }
          onChange(selectedValue);
          if (props.clearOnSelection) {
            setComponentState((prev) => ({...prev, selectedValue: selectedValue, healthComponentData: []}));
            setTimeout(() => {
              setComponentState((prev) => ({
                ...prev,
                selectedValue: undefined,
              }));
            }, 500);
          }
        }
         else {
          onChange(undefined);
          setComponentState((prev) => ({...prev, searchValue: ''}));
        }
      }}
      placeholder={props.placeholder || 'Search'}
      loading={componentState.loading}
      notFoundContent={componentState.loading && <Spin size="small" />}
      style={{height: '40px'}}
      className={isShowError && !value ? 'pami-search field-error' : 'pami-search'}
      onPopupScroll={onOptionScroll}
      optionLabelProp={checkIdMissingInData() ? "label" : undefined}
    >
      {componentState.healthComponentData.map((component, index) => {
        return (
          <Select.Option key={`${component.title}_${index}`} value={component?.id} label={component.title}>
              <VStack>
                <Text>
                  {props.addNewOptionEnabled && !component?.id ? `${addNewPrefix}${component?.title}` : `${component?.title}`}
                </Text>
              </VStack>
          </Select.Option>
        );
      })}
      {componentState.healthComponentData.length && componentState.loading && (
        <Select.Option key={`${props.searchType}_loading`} disabled>
          <Spin size="small" />
        </Select.Option>
      )}
    </Select>
  );
};

export default AdditionalHealthComponentSearch;
