import {FlatList, Pressable, StyleSheet, Text, View} from 'react-native';
import Stack from '../../../../../common/LayoutComponents/Stack';
import BellSvg from '../../../../../../assets/Icons/BellSvg';
import CalendarCheckedIcon from '../../../../../../assets/Icons/CalendarCheckedIcon';
import {Colors} from '../../../../../../styles';
import {getFormattedDate} from '../../../../../../utils/DateUtils';
import {DATE_FORMATS, MLOV_CATEGORY} from '../../../../../../constants';
import {IAppointmentDetail} from '../../../../../common/CalendarWidget/CalendarWidgetInterfaces';
import SingleUserIcon from '../../../../../../assets/Icons/SingleUserIcon';
import NoDataFoundWithCustomIcon from '../../../../../common/NoDataFound/NoDataFoundWithCustomIcon';
import {CalendarIconSvgV2} from '../../../../../common/Svg/CalendarIconSvgV2';
import {Skeleton} from 'antd';
import {getMlovListFromCategory} from '../../../../../../utils/mlovUtils';
import {useContext} from 'react';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';

interface ICadenceListViewProps {
  contactUuid: string;
  onAppointmentSelected: (appointment: IAppointmentDetail) => void;
  appointments: IAppointmentDetail[];
  isAppointmentListLoading?: boolean;
}

export const CadenceListView = (props: ICadenceListViewProps) => {
  const {appointments, isAppointmentListLoading, onAppointmentSelected} = props;
  const contextData = useContext(CommonDataContext);
  const careProgramTypesList =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_TYPES
    ) || [];

  const renderIconView = (item: any) => {
    return (
      <Stack
        direction="row"
        style={{
          padding: 8,
          backgroundColor: Colors.FoldPixel.GRAY50,
          borderRadius: 8,
          borderWidth: 0.5,
          borderColor: Colors.FoldPixel.GRAY200,
        }}
      >
        {item.isBlockedInterval ? <BellSvg size={18}/> : <CalendarIconSvgV2 size={16}/>}
      </Stack>
    );
  };

  const renderListItem = (item: IAppointmentDetail) => {
    const userName = item.participants.find((participant: any) => {
      return participant?.user && participant?.user?.uuid;
    })?.user?.name;

    const careProgramTypeId =
      item.contactCareProgram?.payerCareProgram?.careProgramTypeId;
    const careProgramType = careProgramTypesList.find(
      (careProgramType: any) => careProgramType.id === careProgramTypeId
    );

    return (
      <Pressable
        onPress={() => {
          onAppointmentSelected(item);
        }}
      >
        <Stack
          direction="row"
          style={{
            paddingVertical: 8,
            paddingHorizontal: 12,
            alignItems: 'center',
          }}
        >
          {renderIconView(item)}
          <Stack direction="column" style={{marginLeft: 8}}>
            <Text style={styles.titleText}>{item.name}</Text>
            <Stack direction="row" style={{alignItems: 'center'}}>
              <Text style={styles.dateText}>
                {getFormattedDate(
                  item.startDateTime,
                  DATE_FORMATS.MM_DD_YY_HH_MM_A_DATE_TIME_FORMAT
                )}
              </Text>

              {careProgramType && (
                <>
                  <Text style={styles.dateText}>{' • '}</Text>
                  <Text style={[styles.dateText, {marginLeft: 4}]}>
                    {careProgramType.value}
                  </Text>
                </>
              )}

              <Text style={styles.dateText}>{' • '}</Text>
              <SingleUserIcon></SingleUserIcon>
              <Text style={[styles.dateText, {marginLeft: 4}]}>{userName}</Text>
            </Stack>
          </Stack>
        </Stack>
      </Pressable>
    );
  };

  return (
    <>
      <FlatList
        data={appointments}
        keyExtractor={(item) => item.id}
        renderItem={({item}) => renderListItem(item)}
        ListEmptyComponent={
          isAppointmentListLoading ? (
            <View style={{padding: 16}}>
              <Skeleton active />
            </View>
          ) : (
            <Stack direction="column" style={{paddingTop: 16}}>
              <NoDataFoundWithCustomIcon
                icon={<CalendarIconSvgV2 />}
                iconMarginBottom={4}
                displayString="noUpcomingAppointments"
                textStyle={{
                  color: Colors.FoldPixel.GRAY200,
                  width: '300px',
                  fontWeight: '500',
                }}
              />
            </Stack>
          )
        }
      />
    </>
  );
};

const styles = StyleSheet.create({
  titleText: {
    color: Colors.FoldPixel.GRAY400,
    fontSize: 14,
  },
  dateText: {
    color: Colors.FoldPixel.GRAY200,
    fontSize: 12,
  },
});
