import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
  CarePlanHeaderActions,
  CarePlanScreenContext,
  IAddOrUpdateCarePlanProps,
  ICarePlanDetailsProps,
  ICarePlanDetailsState,
  ICarePlanProps,
} from '../interfaces';
import Header from './Header';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {Colors} from '../../../../../styles';
import {BUTTON_TYPE,DATE_FORMATS, DISPLAY_SLASH_DATE_FORMAT, MLOV_CATEGORY, PERSON_TYPES} from '../../../../../constants';
import {Button, Modal, Popover, Tooltip} from 'antd';
import {
  Box,
  View,
  Text,
  VStack,
  HStack,
  Skeleton,
  IconButton,
  FlatList,
  Pressable,
  Divider,
  Spinner,
  ScrollView,
  Badge,
} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {useIntl} from 'react-intl';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import FormsQueries, {
  GET_CARE_PLAN_FORMS,
} from '../../../../../services/Forms/FormsQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import CarePlanTemplatesView from './CarePlanTemplatesView';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {
  getAccountUUID,
  getFoldAccessToken,
  getUserUUID,
} from '../../../../../utils/commonUtils';
import {FormContext, FormViewType} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {FHForm} from '../../../../RightSideContainer/Forms/FHFormio';
import ReactToPrint from 'react-to-print';
import {PrintContactContent} from '../../../../common/PrintContactContent/PrintContactContent';
import {createTaskForReviewer, getCarePlanTitle, getPostDataForCarePlan, getSubmittedResponseFromComponents, setComponentsSelectedValues, mergeComponents, setDefaultComponentValues, updateReviewTaskStatus, validateCarePlanPostData} from './utils';
import {COMPONENTS_TO_MERGE, CONTAINER_COMPONENTS, CarePlanViewType} from '../CarePlanConstants';
import {ADD_OR_UPDATE_CARE_PLAN} from '../../../../../services/CarePlan/CarePlanQueries';
import {useToast} from '../../../../Toast/ToastProvider';
import {ToastType} from '../../../../../utils/commonViewUtils';
import { getMlovByCategory, getMlovIdFromCode, getMlovIdFromCodeCategory, getMlovListByCategoryCode, getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { CARE_PLAN_SOURCE_TYPE_CODES, CARE_PLAN_STATUS_CODES } from '../../../../../constants/MlovConst';
import SignIcon from '../../../../../assets/Icons/SignIcon';
import { title } from 'process';
import { NoCarePlan } from './NoCarePlan';
import Stack from '../../../../common/LayoutComponents/Stack';
import { forEachExtensiveFormComponent } from '../../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {v4 as uuidv4} from 'uuid';
import FormResponseView from '../../../../common/FormResponseView/FormResponseView';
import ProviderSelectForReview from './ProviderSelectForReview';
import {TaskQueries} from '../../../../../services';
import {IMlov} from '../../../../../Interfaces';
import {getDefaultPriorityId, getDefaultTaskStatusId} from '../../../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import OverrideAntdModal from '../../../../RightSideContainer/ContentManagement/OverrideModal/OverrideAntdModal';
import ConfirmAssignInReviewCarePlan from './ConfirmAssignInReviewCarePlan';
import {getCompletedTaskStatusId} from '../../../../common/Tasks/TasksUtils/TasksUtils';
import { cloneDeep, isEqual, set } from 'lodash';
import CarePlanDiff from '../CarePlanDiff/CarePlanDiff';
import FoldButtonV2, { ButtonType } from './FoldButtonV2';
import DeleteSvg from '../../../../common/Svg/DeleteSvg';
import CrossIconSvg from '../../../../common/Svg/CrossIconSvg';
import {SidecarPatientInfoBanner} from '../../../../SideCar/SidecarPatientInfoBanner';
import {SidecarPatientDataContext} from '../../../../SideCar/SidecarTaskDrawer';
import {LeftOutlined} from '@ant-design/icons';
import CarePlanReviewIcon from '../../../../../assets/Icons/CarePlanReviewIcon';
import AssessmentSvg from '../../../../common/Svg/CareProgramSteps/AssessmentSvg';
import ClipBoardWithHeartSvg from '../../../../common/Svg/ClipBoardWithHeartSvg';
import { testID, TestIdentifiers } from '../../../../../testUtils';
import ReadMsgSvg from '../../../../common/Svg/ReadMsgSvg';
import ReviewChatSquareAddSvg from '../../../../common/Svg/CarePlanSvgs/ReviewChatSquareAddSvg';
import PenSvg from '../../../../common/Svg/PenSvg';
import EyeIcon from '../../../../../assets/Icons/EyeIcon';
import { SelectCarePlanTemplateFallback } from './SelectCarePlanTemplateFallback';
import useEHRCapabilities from '../../../../../screens/BusinessStudio/useEHRCapabilities';
import './AddOrUpdateCarePlanDetails.css';
import CarePlanPublishPlaneSvg from '../../../../common/Svg/CarePlanSvgs/CarePlanPublishPlaneSvg';
import CarePlanDraftEmptyFileSvg from '../../../../common/Svg/CarePlanSvgs/CarePlanDraftEmptyFileSvg';
import CarePlanDraftFileSvg from '../../../../common/Svg/CarePlanSvgs/CarePlanDraftFileSvg';
import { StyleSheet } from 'react-native';
import ChevronDownSvg from '../../../../common/Svg/ChevronDownSvg';
import { getPriorityIcon, getStatusBadge } from '../../../../TaskCard/TaskCardHelper';
import ArrowRightDiagonalNewSvg from '../../../../common/Svg/ArrowRightDiagonalNewSvg';
import AddOrUpdateTask from '../../../../common/AddTask/AddOrUpdateTask';
import { ParticipantType } from '../../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { getDateStrFromFormat, getFormattedDate } from '../../../../../utils/DateUtils';
import { useContactCareProgramContext } from '../../../../RightSideContainer/ContactCareProgram/ContactCareProgramView/ContactCareProgram.context';
import useCareProgramStepStatus from '../../../../RightSideContainer/ContactCareProgram/ContactCareProgramView/hooks/useCareProgramStepStatus';
import { ContactCareProgramReducerAction } from '../../../../RightSideContainer/ContactCareProgram/ContactCareProgramView/reducer';
import { CARE_PROGRAM_STEP_TYPE } from '../../../../common/MemebersView/constant';
import { useCareProgramContentHeaderAction } from '../../../../RightSideContainer/ContactCareProgram/ContactCareProgramView/MainContentView/useCareProgramContentHeaderAction';
import { StepAction } from '../../../../RightSideContainer/ContactCareProgram/ContactCareProgramView/hooks/useCareProgramStepActions';
import CustomConfirmationModal from '../../../../common/CustomConfirmationModal/CustomConfirmationModal';
import WarningSvgV2 from '../../../../common/Svg/WarningSvgV2';

const AddOrUpdateCarePlanDetails = (props: IAddOrUpdateCarePlanProps) => {
  const {carePlanSourceName, getCarePlanSourceName, loadingCarePlanSourceName, onAutomationNavigation, disableAutomationNavigation} = props;
  const toast = useToast();
  const intl = useIntl();
  const contextData = useContext(CommonDataContext);
  const isSidecar = contextData?.sidecarContext?.isSidecar;
  const sidecarPatientContextData = useContext(SidecarPatientDataContext);
  const DEFAULT_CARE_PLAN_FORM = 'DEFAULT_CARE_PLAN_FORM';
  const {
    state: {contactCareProgramDetails},
    dispatch
  } = useContactCareProgramContext();
  const {
    handleHeaderStatusActions,
  } = useCareProgramContentHeaderAction();
  const {careProgramStepStatus} = useCareProgramStepStatus();
  const completedStatusId = careProgramStepStatus.done;
  const accountUUID = getAccountUUID();
  const userUUID = getUserUUID();
  const patientId =
    props.personData?.patientId || props.personData?.patientUuid;
  const accountLocationUuid = props.personData?.accountLocationUuid;
  const ehrCapabilities = useEHRCapabilities({locationId: accountLocationUuid});
  const foldAccessToken = getFoldAccessToken();
  const [submitData, setSubmitData] = useState<any>({});
  const [addOrUpdateCarePlan] = useMutation(ADD_OR_UPDATE_CARE_PLAN, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });
  const [createTask] = useMutation(TaskQueries.ADD_OR_UPDATE_TASK);
  const [updateTask] = useMutation(TaskQueries.UPDATE_TASK);
  const [oldResponse] = useState(cloneDeep(props.submittedResponse));
  const [componentState, setComponentState] = useState<ICarePlanDetailsState>({
    formList: [],
    components: props.formResponseComponents || [],
    loadingFormIds: [],
    selectedFormIds: [],
    startRenderPrintComponent: false,
    formNamesMap: {},
    id: undefined,
    statusId: props.carePlanDetails?.statusId || '',
    isEditCarePlan: false,
    title: '',
    formIds: [],
    submittedResponse: {},
    showSubmittedCarePlanAssessmentFormDrawer: false,
    showProviderSelectionForReview: false,
    selectedProviderId: '',
    confirmInReviewAssignCarePlan: false,
    diffView: undefined,
    viewTypeLoading: false,
    isCarePlanTemplatesModalOpen: false,
    carePlanDetailsLoading: true,
    showTaskDrawer: false,
  });
  const [viewType, setViewType] = useState<CarePlanViewType>(CarePlanViewType.edit);
  const [isNavigatingToCarePlan, setIsNavigatingToCarePlan] = useState(false);

  const carePlanStatusMlovs =
  getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV || {},
    MLOV_CATEGORY.CARE_PLAN_STATUS
  ) || [];

  const activeCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.ACTIVE,
  );

  const draftCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.DRAFT,
  );

  const inReviewCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.IN_REVIEW,
  );

  const isAssigned = useMemo(
    () => props.carePlanDetails?.statusId === activeCarePlanStatusId,
    [props.carePlanDetails?.statusId]
  );

  const isInReview = useMemo(
    () => props.carePlanDetails?.statusId === inReviewCarePlanStatusId,
    [props.carePlanDetails?.statusId]
  );

  const isInDraft = useMemo(
    () => props.carePlanDetails?.statusId === draftCarePlanStatusId,
    [props.carePlanDetails?.statusId]
  );

  const hideCancelButton = isInDraft || isInReview;


  const taskPriorityList: IMlov[] = getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_PRIORITY,
    false
  );
  const taskStatusList: IMlov[] = getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS
  ) || [];

  const defaultTaskStatusId: string = useMemo(
    () => getDefaultTaskStatusId(taskStatusList),
    [taskStatusList.length]
  );

  const carePlanSourceTypeMlovs = getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV || {},
    MLOV_CATEGORY.CARE_PLAN_SOURCE
  ) || [];

  const automationSourceTypeId = getMlovIdFromCode(
    carePlanSourceTypeMlovs,
    CARE_PLAN_SOURCE_TYPE_CODES.AUTOMATION
  );

  const manualSourceTypeId = getMlovIdFromCode(
    carePlanSourceTypeMlovs,
    CARE_PLAN_SOURCE_TYPE_CODES.MANUAL
  );

  const completedTaskStatusId = getCompletedTaskStatusId(taskStatusList);
  const isDraft = props.carePlanDetails?.statusId === draftCarePlanStatusId;


  const defaultTaskPriorityId = useMemo(
    () => getDefaultPriorityId(taskPriorityList),
    [taskPriorityList.length]
  );

  useEffect(()=> {
    getCarePlanSourceName();
    if (props.isCreatingCarePlan && !props.carePlanDetails?.id) {
      setComponentState((prev) => ({
        ...prev,
        isCarePlanTemplatesModalOpen: true,
        carePlanDetailsLoading: false,
      }));
    }
    if(props.carePlanDetails?.id){
      setComponentState((prev) => ({
        ...prev,
        id: props.carePlanDetails?.id,
        formIds: props.carePlanDetails?.formId ? [props.carePlanDetails?.formId] : [],
        selectedFormIds: props.carePlanDetails?.selectedFormIds ? props.carePlanDetails?.selectedFormIds : [],
        title: props.carePlanDetails?.title || '',
        isEditCarePlan: true,
        submittedResponse: getSubmittedResponseFromComponents(componentState.components, props.contactUuid),
        statusId: props.carePlanDetails?.statusId,
        carePlanDetailsLoading: false,
      }));
    }
  }, [props.carePlanDetails, props.isCreatingCarePlan])

  const handleFormChange = useCallback((data: any) => {
    setSubmitData(data);
  }, []);

  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  // THIS IS HANDLED FOR SAVE BUTTON ONLY
  const [saveBtnLoading, setSaveBtnLoading] = useState<boolean>(false);

  const onSaveCarePlan = async (statusId: string, params:{
    reviewerId?: string,
    updateRelatedTask?: boolean,
    skipLoading?: boolean,
    closeDrawer?: boolean,
    isSaveInReview?: boolean
  }) => {
    const {reviewerId, skipLoading, closeDrawer, isSaveInReview} = params;
    const isSendForReview = statusId === inReviewCarePlanStatusId && reviewerId;
    const isActiveCarePlan = statusId === activeCarePlanStatusId;

    const carePlanPostData = {
      ...componentState,
      statusId: statusId
    }

    const validationErrors = validateCarePlanPostData(carePlanPostData, componentState?.isEditCarePlan);

    if(validationErrors?.length && validationErrors?.length > 0){
      toast({
        message: validationErrors[0].errorMessage,
        toastType: ToastType.error,
      });
      return;
    }
    if (!skipLoading) {
      setSaveLoading(true);
    }
    try {
      const carePlanData = getPostDataForCarePlan(carePlanPostData, submitData, props.contactUuid, !isAssigned, manualSourceTypeId);
      const updatedCarePlanData = {
        ...carePlanData,
       contactCareProgramId: contactCareProgramDetails?.id
      }
      const response = await addOrUpdateCarePlan({
        variables: {
          params: updatedCarePlanData,
        },
      });
      if (isActiveCarePlan && response?.data?.addOrUpdateContactCarePlan) {
        const updatedStepsLog = contactCareProgramDetails?.stepsLog?.find((step: any) => {
          return step.careProgramStep?.careProgramStepType?.code === CARE_PROGRAM_STEP_TYPE.CARE_PLAN
        });
        if (updatedStepsLog?.careProgramStepStatusId != completedStatusId) {
          handleHeaderStatusActions(StepAction.COMPLETE);
        }
      }
      if (isSendForReview) {
        const taskResponse = await createTaskForReviewer({
          carePlanId: response.data.addOrUpdateContactCarePlan.carePlanId,
          reviewerId: reviewerId,
          priorityId: defaultTaskPriorityId,
          statusId: defaultTaskStatusId,
          contactUuid: props.contactUuid,
          loginUserId: userUUID,
          title: `Care Plan Review`,
          taskPromise: createTask,
        });

        if (!taskResponse) {
          toast({
            message: 'Something went wrong while sending care plan for review',
            toastType: ToastType.error,
          });
          setSaveLoading(false);
          return;
        }
      }

      if (params?.updateRelatedTask && !!props.carePlanReviewTaskId)  {
        const { success } = await updateReviewTaskStatus({
          taskId: props.carePlanReviewTaskId,
          taskPromise: updateTask,
          statusId: completedTaskStatusId,
        })

        if (!success) {
          toast({
            message: 'Something went wrong while updating related task',
            toastType: ToastType.error,
          });
          setSaveLoading(false);
        }
      }

      if (response.data.addOrUpdateContactCarePlan.carePlanId) {
        setComponentState((prev) => ({
          ...prev,
          id: response.data.addOrUpdateContactCarePlan.carePlanId,
        }));

        let msg = '';

        switch (statusId) {
          case activeCarePlanStatusId:
            msg = 'carePlanAssignedSuccessMsg';
            break;
          case draftCarePlanStatusId:
            msg = 'carePlanSavedSuccessMsg';
            break;
          case inReviewCarePlanStatusId:
            if (isSaveInReview) {
              msg = 'carePlanSavedSuccessMsg';
            } else {
              msg = 'carePlanSentForReviewSuccessMsg';
            }
            break;
        }

        toast({
          message: intl.formatMessage({
            id: msg,
          }),
          toastType: ToastType.success,
        });

        if (closeDrawer) {
          props.onCancelClick?.();
          setSaveLoading(false);
          setSaveBtnLoading(false);
          return;
        }

        props.onCarePlanAdded?.();
      }
      setSaveLoading(false);
      setSaveBtnLoading(false);
    } catch (error) {
      setSaveLoading(false);
      setSaveBtnLoading(false);
      toast({
        message: intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        toastType: ToastType.error,
      });
    }
  };

  const onActionPerformed = (action: CarePlanHeaderActions) => {
    switch (action) {
      case CarePlanHeaderActions.ASSIGNED_TO_PATIENT:
        if (isInReview) {
          setComponentState((prev) => ({
            ...prev,
            confirmInReviewAssignCarePlan: true,
          }));
        } else {
          onSaveCarePlan(activeCarePlanStatusId, {});
        }
        break;
      case CarePlanHeaderActions.SAVE_AS_DRAFT:
        onSaveCarePlan(draftCarePlanStatusId, {});
        break;
      case CarePlanHeaderActions.DOWNLOAD:
        break;
      case CarePlanHeaderActions.PRINT:
        setComponentState((prev) => ({
          ...prev,
          startRenderPrintComponent: true,
        }));
        break;
      case CarePlanHeaderActions.SEND_FOR_REVIEW:
        setComponentState((prev) => ({
          ...prev,
          showProviderSelectionForReview: true,
        }));
        break;
      case CarePlanHeaderActions.PREVIEW_CHANGES:
        setComponentState((prev) => {
          const oldComponents = cloneDeep(prev.components || []);
          const newComponents = cloneDeep(prev.components || []);
          forEachExtensiveFormComponent(oldComponents, (item: any) => {
            item.selectedValue = oldResponse?.data?.[item.key];
          });
          forEachExtensiveFormComponent(newComponents, (item: any) => {
            item.selectedValue = submitData?.data?.[item.key];
          });
          forEachExtensiveFormComponent(prev.components, (item: any) => {
            item.selectedValue = submitData?.data?.[item.key];
          });

          return {
            ...prev,
            diffView: {
              oldComponents: oldComponents,
              newComponents: newComponents,
            },
            submittedResponse: {data: cloneDeep(submitData?.data || {})},
          };
        });
        setViewType(CarePlanViewType.preview);
        break;

      case CarePlanHeaderActions.EDIT_CARE_PLAN:
        setComponentState((prev) => {
          return {
            ...prev,
            diffView: undefined,
          };
        });
        setViewType(CarePlanViewType.edit);
        break;
      case CarePlanHeaderActions.SAVE_CARE_PLAN_IN_REVIEW:
        setSaveBtnLoading(true);
        onSaveCarePlan(inReviewCarePlanStatusId, {
          updateRelatedTask: false,
          reviewerId: undefined,
          skipLoading: true,
          closeDrawer: true,
          isSaveInReview: true,
        });
        break;
    }
  };

  const onCloseSendForReview = useCallback(() => {
    setComponentState((prev) => ({
      ...prev,
      showProviderSelectionForReview: false,
    }));
  },[])

  const [getFormById] = useLazyQuery(FormsQueries.GET_FORM_BY_ID, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const carePlanForms = useQuery(GET_CARE_PLAN_FORMS, {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
      fetchPolicy: 'no-cache',
    },
  });
  const defaultCarePlanFormId = carePlanForms?.data?.forms?.find((form: any) => form.code === DEFAULT_CARE_PLAN_FORM)?.id;

  const [intakeOptions] = useState({
    foldAccessToken,
    accountUUID,
    patientId,
    contactId: props.contactUuid,
    foldContactId: props.contactId,
    backgroundSaveEnabled: false,
    ehrCapabilities: ehrCapabilities,
    accountLocationUuid: props.accountLocationUuid,
    formContext: FormContext.patientCarePlan,
    MLOV: contextData.MLOV,
    CARE_STUDIO_MLOV: contextData.CARE_STUDIO_MLOV,
    CLOUD_TELEPHONY_MLOV: contextData.CLOUD_TELEPHONY_MLOV,
    userSettings: contextData.userSettings,
    userData: contextData.userData,
    showDeleteButton: !isAssigned,
    sidecarContext: contextData.sidecarContext,
    contactCarePlanId: props.carePlanDetails?.id,
    onDeleteClick: (component: any) => {
      let submitData: any
      setSubmitData((prev: any) => {
        submitData = prev
        return {
          ...prev
        }
      })
      setComponentState((prev) => {
        const formChanges = submitData.data
        const updatedStates = setComponentsSelectedValues(prev.components, formChanges)
        const filteredComponents = removeComponent(updatedStates, component);
        return {
          ...prev,
          components: filteredComponents
        }
      })
    },
    carePlanDetailsStartDateTime: props?.carePlanDetails?.startDateTime,
  });

  const isCarePlanDetailsChanged = () => {
    if (!submitData?.data) {
      return false;
    }
    return !isEqual(oldResponse?.data, submitData?.data);
  }

  const removeComponent = (components: any[], component: any) => {
    let found = false;
    const filteredComponents = components.filter((filteredComponent) => {
      if (filteredComponent.key === component.key) {
        found = true;
        return false;
      }
      return true;
    });

    if (found) {
      return filteredComponents;
    }

    filteredComponents.map((filteredComponent) => {
      if (filteredComponent.components?.length > 0) {
        filteredComponent.components = removeComponent(filteredComponent.components, component);
      }
      return filteredComponent;
    });

    return filteredComponents;
  }

  const getMergedFormComponents = (newComponents: any[]) => {
    const oldComponents = componentState.components;
    const oldComponentTypes = oldComponents.map((item) => item.type);

    newComponents.forEach((item) => {
      if (
        oldComponentTypes.includes(item.type) &&
        COMPONENTS_TO_MERGE.includes(item.type)
      ) {
        const matched = oldComponents.find(
          (component) => component.type === item.type
        );
        if (matched) {
          mergeComponents(matched, item);
        }
      } else {
        oldComponents.push(item);
      }
    });

    const uniqueComponentKeys : string[] = [];

    forEachExtensiveFormComponent(oldComponents || [], (component) => {
      if(!CONTAINER_COMPONENTS.includes(component.key)){
        if(uniqueComponentKeys.includes(component.key)){
          const newKey = `${component.key}_${uuidv4()}`;
          component.key = newKey;
          uniqueComponentKeys.push(newKey);
        }
        else {
          uniqueComponentKeys.push(component.key);
        }
      }
    });

    return oldComponents.filter((item) => item.key !== 'submit');
  };

  const onTemplateSelect = async (formId: string) => {
    try {
      setComponentState((prev) => ({
        ...prev,
        loadingFormIds: [...prev.loadingFormIds, formId],
        selectedFormIds: [...prev.selectedFormIds, formId],
      }));
      const formResponse = await getFormById({
        variables: {
          id: formId,
        },
      });
      const components = setDefaultComponentValues(formResponse.data.form?.components || []);
      const mergedComponents = getMergedFormComponents(components);

      setComponentState((prev) => {
        return {
          ...prev,
          components: mergedComponents,
          loadingFormIds: prev.loadingFormIds.filter((item) => item !== formId),
          formNamesMap: {
            ...prev.formNamesMap,
            [formId]: formResponse.data.form?.name,
          },
        };
      });
    } catch {
      setComponentState((prev) => {
        return {
          ...prev,
          loadingFormIds: prev.loadingFormIds.filter((item) => item !== formId),
          selectedFormIds: prev.selectedFormIds.filter(
            (item) => item !== formId
          ),
        };
      });
    }
  };

  const onSelectProviderForReview = (providerId: string) => {
    onCloseSendForReview();
    onSaveCarePlan(inReviewCarePlanStatusId, {
      reviewerId: providerId,
    });
  }

  const formattedCarePlanCreatedOn = useMemo(() => {
    return getDateStrFromFormat(props?.carePlanDetails?.createdOn, DISPLAY_SLASH_DATE_FORMAT)
  }, [props?.carePlanDetails?.createdOn]);


  const getHeaderSubTitle = (statusId?: string) => {
    if (componentState.carePlanDetailsLoading) {
      return <Skeleton w={20} h={2} rounded={'sm'} mt={1} />;
    }
    let msg = '';
    switch (statusId) {
      case activeCarePlanStatusId:
        return (
          <HStack alignItems={'center'}>
            <ReadMsgSvg />
            <Text fontSize={12} color={'#12B76A'} ml={1}>
              {intl.formatMessage({id: 'assignedToPatient'})}
            </Text>
          </HStack>
        )
      case draftCarePlanStatusId:
        return (
          <Text fontSize={12} color={Colors.FoldPixel.GRAY250}>
            {loadingCarePlanSourceName ? (
              <Skeleton.Text lines={1} width={200} />
            ) : (
              `Created by ${carePlanSourceName} on ${formattedCarePlanCreatedOn}`
            )}
          </Text>
        )
      case inReviewCarePlanStatusId:
        return (
          <HStack alignItems={'center'}>
            <ReviewChatSquareAddSvg customStrokeColor={Colors.Custom.orangeMessageTopBar} size={14} />
            <Text fontSize={12} style={styles.inReviewText}>
            {intl.formatMessage({id: 'inReview'})}
            </Text>
            <Text fontSize={12} style={styles.assigneeText}>
              {` • ${props?.carePlanTask?.assigneeUser?.name}`}
            </Text>
            {props?.carePlanTask?.startDateTime ? (
              <Text
                fontSize={12}
                style={styles.assigneeText}
              >{` • Sent for Review on: ${getFormattedDate(
                props?.carePlanTask?.startDateTime,
                DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
              )}`}</Text>
            ) : (
              <></>
            )}
          </HStack>
        );
      default:
        msg = '';
    }
    return msg;
  }

  const renderActionMenu = () => {
    const actions: any[] = [];
    actions.push({
      btnText: intl.formatMessage({
        id: 'signNShare',
      }),
      action: CarePlanHeaderActions.ASSIGNED_TO_PATIENT,
      isDisabled: !componentState.selectedFormIds.length,
      icon: <CarePlanPublishPlaneSvg size={14} />,
    });
    if (!isInReview) {
      actions.push({
        btnText: intl.formatMessage({
          id: 'saveAsDraft',
        }),
        action: CarePlanHeaderActions.SAVE_AS_DRAFT,
        isDisabled: !componentState.selectedFormIds.length,
        icon: <CarePlanDraftEmptyFileSvg size={14} />,
      });
    }

    if (!isAssigned && !isInReview) {
      actions.push({
        btnText: intl.formatMessage({
          id: 'sendForReview',
        }),
        action: CarePlanHeaderActions.SEND_FOR_REVIEW,
        isDisabled: !componentState.selectedFormIds.length,
        icon: <ReviewChatSquareAddSvg customStrokeColor={Colors.Custom.GRAY350} size={14} />,
      });
    }

    return (
      <FlatList
        data={actions}
        renderItem={({item, index}) => (
          <>
            <Pressable
              disabled={item.isDisabled}
              flexDirection="row"
              alignItems="center"
              my={1.5}
              onPress={() => onActionPerformed(item.action)}
            >
              {item.icon}
              <Text
                color={Colors.Custom.Gray700}
                fontSize={12}
                fontWeight="400"
                marginLeft={item?.icon ? 1.5 : undefined}
                {...testID(item.btnText)}
              >
                {item.btnText}
              </Text>
            </Pressable>
          </>
        )}
      />
    );
  };
  const renderActionMenuForReviewState = () => {
    const actions: any[] = [];
    actions.push({
      btnText: intl.formatMessage({
        id: 'saveChanges',
      }),
      action: CarePlanHeaderActions.SAVE_CARE_PLAN_IN_REVIEW,
      isDisabled: false,
    });
    actions.push({
      btnText: intl.formatMessage({
        id: 'signNShare',
      }),
      action: CarePlanHeaderActions.ASSIGNED_TO_PATIENT,
      isDisabled: false,
    });

    return (
      <FlatList
        data={actions}
        renderItem={({item, index}) => (
          <>
            <Pressable
              disabled={item.isDisabled}
              flexDirection="row"
              alignItems="center"
              my={1.5}
              onPress={() => onActionPerformed(item.action)}
              {...testID('CarePlanReviewSaveButton')}
            >
              {item.icon}
              <Text
                color={Colors.Custom.Gray500}
                fontSize={12}
                fontWeight="400"
                marginLeft={item?.icon ? 4 : undefined}
              >
                {item.btnText}
              </Text>
            </Pressable>
            {index !== actions.length - 1 && <Divider />}
          </>
        )}
      />
    );
  };

  const renderShowAssessmentButton = () => {
    return (
      <Tooltip
        title={intl.formatMessage({id: 'showAssessment' })}
      >
        <Pressable
          isDisabled={componentState.viewTypeLoading}
          onPress={() => {
            setComponentState((prev) => {
              return {
                ...prev,
                showSubmittedCarePlanAssessmentFormDrawer:
                  !prev.showSubmittedCarePlanAssessmentFormDrawer,
              };
            });
          }}
          {...testID('ShowAssessmentButton')}
        >
          <AssessmentSvg />
        </Pressable>
      </Tooltip>
    );
  };

  const renderCarePlanTemplatesButton = () => {
    const count = carePlanForms?.loading ? 0 : componentState.selectedFormIds.filter((item) => item !== defaultCarePlanFormId).length;
    return (
        <Tooltip title={intl.formatMessage({id: 'carePlanTemplates' })}>
          <VStack pr={count ? 3 : 0}>
            {count > 0 ? (
              <Badge
                rounded={'md'}
                mb={-2.5}
                mr={-2.5}
                mt={count > 0 ? -3 : 0}
                zIndex={100}
                variant="solid"
                backgroundColor={Colors.Custom.badgeColorOrange}
                alignSelf="flex-end"
                size={'smMedium'}
                px={1.5}
                py={0.3}
                _text={{
                  fontSize: 10,
                  fontWeight: 'bold',
                }}
              >
                {count}
              </Badge>) :
              <></>
            }
            <Pressable
              isDisabled={componentState.viewTypeLoading}
              onPress={() => {
                setComponentState((prev) => ({
                  ...prev,
                  isCarePlanTemplatesModalOpen: !componentState?.isCarePlanTemplatesModalOpen,
                }));
              }}
              {...testID('CarePlanTemplatesButton')}
            >
              <ClipBoardWithHeartSvg />
            </Pressable>
          </VStack>
        </Tooltip>
    );
  };


  const getHeaderActions = () => {
    if (isPreview) {
      return (
        <>
          <Tooltip title={intl.formatMessage({id: 'edit' })}>
            <Pressable
              isDisabled={saveLoading || componentState.viewTypeLoading}
              onPress={() => {
                onActionPerformed(CarePlanHeaderActions.EDIT_CARE_PLAN);
              }}
              {...testID('CarePlanEditButton')}
            >
              <PenSvg customStrokeColor={Colors.Custom.GRAY350} size={18} />
            </Pressable>
          </Tooltip>
        </>
      );
    }
    return (
      <>
        {props?.loadingCarePlanAssessmentFormName ? (
          <Spinner />
        ) : (
          !!props?.submittedCarePlanAssessmentForm
            ?.submittedCarePlanAssessmentFormId && (
            <>
              {renderShowAssessmentButton()}
              <View
                backgroundColor={Colors.Custom.Gray500}
                width={0.2}
                height={6}
                marginX={1}
              />
            </>
          )
        )}
        <>
          {renderCarePlanTemplatesButton()}
          <View
            backgroundColor={Colors.Custom.Gray500}
            width={0.2}
            height={6}
            marginX={1}
          />
        </>

        {isAssigned && !isPreview && (
          <>
            <Tooltip title={intl.formatMessage({id: 'previewChanges' })}>
              <Pressable
                isDisabled={componentState.viewTypeLoading}
                onPress={() => {
                  onActionPerformed(CarePlanHeaderActions.PREVIEW_CHANGES);
                }}
                {...testID('CarePlanPreviewChangesButton')}
              >
                <EyeIcon strokeWidth={1} />
              </Pressable>
            </Tooltip>
            <View
              backgroundColor={Colors.Custom.Gray500}
              width={0.2}
              height={6}
              marginX={1}
            />
          </>
        )}
        {isInReview && (
            <FoldButton
              nativeProps={{
                disabled: saveLoading || componentState.viewTypeLoading || saveBtnLoading,
                isLoading: saveBtnLoading || componentState.viewTypeLoading,
                variant: BUTTON_TYPE.PRIMARY,
                backgroundColor: Colors.Custom.PrimaryColor100,
                borderColor: Colors.Custom.Primary200,
                height: '32px',
                borderRadius: 4,
                borderWidth: 0.5,
                fontSize: 14,
                fontWeight: '500',
                onPress: () => {
                  onActionPerformed(CarePlanHeaderActions.SAVE_CARE_PLAN_IN_REVIEW);
                },
                rightIcon:
                  saveLoading || componentState.viewTypeLoading ? (
                    <Spinner size="sm" color={Colors.Custom.mainPrimaryPurple} />
                  ) : (
                    <Popover
                      overlayClassName="custom-popover-styles"
                      showArrow={true}
                      destroyTooltipOnHide
                      placement="bottomRight"
                      content={renderActionMenuForReviewState}
                      trigger="click"
                      overlayInnerStyle={{
                        borderRadius: 16,
                        width: 140,
                        padding: 0,
                      }}
                    >
                      <Box>
                        <ChevronDownSvg
                          customStrokeColor={Colors.Custom.Primary300}
                          size={12}
                        />
                      </Box>
                    </Popover>
                  ),
                style: {minWidth: 'auto'},
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'saveChanges',
                }),
                textColor: Colors.Custom.Primary300,
                withRightBorder: true,
                isDisableOnPress: true,
              }}
            />
        )}
        {componentState.carePlanDetailsLoading && (
          <Skeleton width="20" height="8" />
        )}
        {!componentState.carePlanDetailsLoading && !isAssigned && !isInReview && (
          <FoldButton
            nativeProps={{
              disabled: saveLoading || componentState.viewTypeLoading || !componentState.selectedFormIds.length,
              backgroundColor: Colors.Custom.PrimaryColor100,
              borderColor: Colors.Custom.Primary200,
              variant: BUTTON_TYPE.PRIMARY,
              height: '32px',
              borderRadius: 4,
              borderWidth: 0.5,
              fontSize: 14,
              fontWeight: '500',
              onPress: () => {
                onActionPerformed(CarePlanHeaderActions.ASSIGNED_TO_PATIENT);
              },
              rightIcon:
                saveLoading || componentState.viewTypeLoading ? (
                  <Spinner size="sm" color={Colors.Custom.mainPrimaryPurple} />
                ) : (
                  <Popover
                    overlayClassName="custom-popover-styles care-plan-action-buttons-popover"
                    showArrow={true}
                    destroyTooltipOnHide
                    placement="bottomRight"
                    content={renderActionMenu}
                    trigger="click"
                    overlayInnerStyle={{
                      borderRadius: 10,
                      width: 140,
                      padding: 0,
                    }}
                  >
                    <Box>
                      <ChevronDownSvg
                        customStrokeColor={Colors.Custom.Primary300}
                        size={12}
                      />
                    </Box>
                  </Popover>
                ),
              style: {minWidth: 'auto'},
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'signNShare',
              }),
              textColor: Colors.Custom.Primary300,
              withRightBorder: true,
              isDisableOnPress: true,
            }}
          />
        )}
        {(!componentState.carePlanDetailsLoading && isAssigned) ? (
          <FoldButtonV2
            key='PublishButton'
            label={intl.formatMessage({id: 'signNShare'})}
            buttonType={ButtonType.primary}
            isDisabled={
              saveLoading || componentState.viewTypeLoading || saveBtnLoading || !componentState.selectedFormIds.length
            }
            isLoading={saveLoading || componentState.viewTypeLoading}
            onPress={ () => {
              onActionPerformed(CarePlanHeaderActions.ASSIGNED_TO_PATIENT);
            }}
          />
        ) : (
          <></>
        )}
        {!hideCancelButton && !isSidecar && (
        <>
          <View backgroundColor={Colors.Custom.Gray500} width={0.2} height={6} marginX={1} />
          <Pressable key={'CrossButton'} onPress={() => props.onCancelClick?.()} isDisabled={saveLoading}>
            <CrossIconSvg
              size={18}
            />
          </Pressable>
        </>
        )}
      </>
    );
  };

  const handleCreateCarePlanClick = () => {
    setIsNavigatingToCarePlan(true);
  }

  const componentLoading = carePlanForms.loading || componentState.viewTypeLoading;
  const isPreview = viewType === CarePlanViewType.preview;

  const componentRef = useRef(null);
  const printRef: any = useRef(null);
  const showPatientContextBanner = !!isSidecar && !!sidecarPatientContextData?.id;

  const resetTaskDetails = useCallback(() => {
    setComponentState((prev) => ({
      ...prev,
      showTaskDrawer: false,
    }));
    if (props?.carePlanTask?.id) {
      props?.onTaskUpdate?.(props?.carePlanTask?.id);
    }
  }, [setComponentState, props?.carePlanTask?.id, props?.onTaskUpdate]);

  const getNewTaskMember = useMemo(() => {
    return {
      value: props.personData?.uuid,
      label: props.personData?.name,
      key: props.personData?.uuid,
      type: props.personData?.contactType === PERSON_TYPES.LEAD ? ParticipantType.leads : ParticipantType.patient,
      contactData: props?.personData,
      data: props?.personData,
      details: props?.personData,
    };
  }, [props?.personData]);

  const getCarePlanTaskView = () => {
    return (
      <Stack direction="column" space={2} style={{marginBottom: 4}}>
        <Text style={styles.relatedTaskHeaderText}>
          {intl.formatMessage({id: 'relatedTask'})}
        </Text>
        <Stack direction="row" style={styles.relatedTaskContainer}>
          <Stack
            direction="row"
            style={{
              alignItems: 'center',
              flex: .8
            }}
          >
            <View mr={2} ml={2} alignItems={'center'}>
              {getPriorityIcon(props?.carePlanTask?.priority, true)}
            </View>
            <Text style={styles.relatedTaskText}>
              {props?.carePlanTask?.title}
            </Text>
          </Stack>
          <Stack direction="row" space={4} style={styles.statusBadgeWrapper}>
            <View style={styles.statusBadgeWrapper}>
              {getStatusBadge(props?.carePlanTask?.status)}
            </View>
            <Pressable
              onPress={() => {
                setComponentState((prev) => ({
                  ...prev,
                  showTaskDrawer: true,
                }));
              }}
              marginLeft={5}
              marginRight={5}
              style={styles.statusBadgeWrapper}
            >
              <ArrowRightDiagonalNewSvg />
            </Pressable>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  return (
    <>
       {showPatientContextBanner&&
        <HStack flex={1} paddingY={2} mx={4}>
          <SidecarPatientInfoBanner
            handleCreateCarePlanClick={handleCreateCarePlanClick}
            contactData={sidecarPatientContextData}  />
        </HStack>
       }
      <Header
        carePlanSourceName={carePlanSourceName}
        loadingCarePlanSourceName={loadingCarePlanSourceName}
        getCarePlanSourceName={getCarePlanSourceName}
        carePlanCreatedOn={props?.carePlanDetails?.createdOn || ''}
        prefixAction={
          props?.isCompactView || isSidecar ? (
            <Button onClick={() =>  props?.onCancelClick?.()} type="text" icon={<LeftOutlined />}/>
          ) : undefined
        }
        context={CarePlanScreenContext.EDIT_CARE_PLAN}
        headerAction={getHeaderActions()}
        title={intl.formatMessage({
          id: componentState.isEditCarePlan
            ? 'modifyCarePlan'
            : 'addNewCarePlan',
        })}
        subTitle={getHeaderSubTitle(componentState.statusId)}
        subTitleStyle={{
          color: isInReview
            ? Colors.Custom.orangeMessageTopBar
            : Colors.Custom.ErrorColor,
        }}
        automationSourceTypeId={automationSourceTypeId}
        carePlanSourceTypeId={props?.carePlanDetails?.sourceTypeId || ''}
        sourceId={props?.carePlanDetails?.sourceId}
        isInDraftState={Boolean(isInDraft)}
        onAutomationNavigation={onAutomationNavigation}
        disableAutomationNavigation={disableAutomationNavigation}
      />
      {componentLoading ? (
        <VStack m={4} space={4}>
          <HStack flexWrap={'wrap'} space={4} mb={2}>
            <Skeleton w={20} h={6} rounded={'full'} />
            <Skeleton w={20} h={6} rounded={'full'} />
            <Skeleton w={30} h={6} rounded={'full'} />
            <Skeleton w={40} h={6} rounded={'full'} />
          </HStack>
          <Skeleton.Text lines={4} />
          <Skeleton.Text lines={2} />
          <Skeleton.Text lines={3} />
          <Skeleton.Text lines={1} />
        </VStack>
      ) : componentState.components.length === 0 ? (
        <SelectCarePlanTemplateFallback
          title={intl.formatMessage({id: 'selectCarePlanTemplateToStart'})}
        />
      ) : (
        <Stack direction="column" style={styles.fullFlex}>
          {componentState.components.length > 0 && (
            <ScrollView
              mx={4}
              py={4}
              height={props?.isCompactView ? 600 : undefined}
            >
              {props?.carePlanTask?.id && (
                getCarePlanTaskView()
              )}
              {componentState.components.length > 0 && (
                <div
                  className="care-plan-styles"
                  style={isPreview ? {display: 'none'} : {}}
                >
                  <FHForm
                    canUpdateOnChangeRef
                    isPreviewMode={false}
                    optionData={intakeOptions}
                    components={componentState.components}
                    submittedResponse={componentState.submittedResponse}
                    onChange={handleFormChange}
                    readOnly={false}
                  />
                </div>
              )}
              {componentState.components.length > 0 && !!isPreview && (
                <CarePlanDiff
                  oldData={componentState.diffView?.oldComponents || []}
                  newData={componentState.diffView?.newComponents || []}
                  components={componentState.components || []}
                  carePlanDetails={props.carePlanDetails}
                  locationId={accountLocationUuid}
                />
              )}
              {/* <ReactToPrint
                // documentTitle={getDocumentTitle()}
                trigger={() => <></>}
                content={() => componentRef.current}
                ref={printRef}
                onAfterPrint={() => {
                  setComponentState((prev) => ({
                    ...prev,
                    startRenderPrintComponent: false,
                  }));
                }}
                print={(target) => {
                  return new Promise<any>((resolve, reject) => {
                    if (target.contentDocument) {
                      target.contentDocument.title = 'Care Plan';
                    }
                    target?.contentWindow?.print?.();
                    resolve(true);
                  });
                }}
                removeAfterPrint={true}
              /> */}
              {componentState.startRenderPrintComponent && (
                <div style={reactStyles.displayNone}>
                  <PrintContactContent
                    triggerPrint={componentState.startRenderPrintComponent}
                    formattedContactData={props.personData}
                    showPreviewInDrawer={false}
                    isLoading={false}
                    enablePatientDemographics={false}
                    isOpen={false}
                    onClose={() => {
                      setComponentState((prev) => ({
                        ...prev,
                        startRenderPrintComponent: false,
                      }));
                    }}
                  >
                    <div className={`note-preview-styles custom-form-styles`}>
                      <FHForm
                        submittedResponse={submitData}
                        isPreviewMode={true}
                        optionData={intakeOptions}
                        components={componentState.components}
                        readOnly={false}
                      />
                    </div>
                  </PrintContactContent>
                </div>
              )}
            </ScrollView>
          )}
        </Stack>
      )}
      {componentState?.isCarePlanTemplatesModalOpen && (
        <Modal
          closable={false}
          centered
          open={componentState?.isCarePlanTemplatesModalOpen}
          footer={false}
          bodyStyle={{maxHeight: 700, paddingBottom: 10 }}
          onCancel={() => {
            setComponentState((prev) => ({
              ...prev,
              isCarePlanTemplatesModalOpen: false,
            }));
            
            if (componentState.selectedFormIds?.length === 0 && props.isCreatingCarePlan) {
              props?.onCancelClick?.(true);
            }
          }}
          width={650}
          className={'rule-template-modal'}
        >
          <CarePlanTemplatesView
            selectedTemplateIds={carePlanForms?.loading ? [] : componentState.selectedFormIds?.filter((id) => id !== defaultCarePlanFormId)}
            templateList={carePlanForms?.data?.forms?.filter((form: any) => form.id !== defaultCarePlanFormId) || []}
            onCheckTemplate={(templatId) => onTemplateSelect(templatId)}
            loadingTemplateIds={componentState.loadingFormIds}
            onSubmitTemplates={() => {
              setComponentState((prev) => ({
                ...prev,
                isCarePlanTemplatesModalOpen: false,
              }))
            }}
          />
        </Modal>
      )}
      {componentState.showProviderSelectionForReview && (
        <Modal
          closable={false}
          onCancel={onCloseSendForReview}
          footer={null}
          bodyStyle={{
            maxHeight: '70vh',
            paddingBottom: 10,
          }}
          open
        >
          <ProviderSelectForReview
            selectedProviderId=""
            onChange={onSelectProviderForReview}
            onCancel={onCloseSendForReview}
            contactId={props.contactUuid}
          />
        </Modal>
      )}
      {componentState.confirmInReviewAssignCarePlan && (
        <CustomConfirmationModal
          isOpen={componentState.confirmInReviewAssignCarePlan}
          headerText={'assignCareProgramPatientText'}
          message={'assignCareProgramPatientMsg'}
          customCancelBtnText={'Cancel'}
          customOkBtnText={'Assign'}
          width={350}
          onCancel={() =>
            setComponentState((prev) => ({
              ...prev,
              confirmInReviewAssignCarePlan: false,
            }))
          }
          onConfirm={() => {
            setComponentState((prev) => ({
              ...prev,
              confirmInReviewAssignCarePlan: false,
            }));
            onSaveCarePlan(activeCarePlanStatusId, {
              updateRelatedTask: true,
            });
          }}
          customIcon={<WarningSvgV2 strokeColor={Colors.Custom.crossIconColor} />}
        />
      )}
      {!!props?.submittedCarePlanAssessmentForm
        ?.submittedCarePlanAssessmentFormId &&
        !!props?.submittedCarePlanAssessmentForm
          ?.submittedCarePlanAssessmentFormName &&
        !!componentState?.showSubmittedCarePlanAssessmentFormDrawer && (
          <FormResponseView
            titleText={'Assessment'}
            mask={false}
            hideResponseList={true}
            drawerWidth={isSidecar ? '100%' : '28%'}
            onClose={() => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  showSubmittedCarePlanAssessmentFormDrawer: false,
                };
              });
            }}
            contactId={props.contactUuid || ''}
            formId={
              props?.submittedCarePlanAssessmentForm
                ?.submittedCarePlanAssessmentFormId
            }
            formName={
              props?.submittedCarePlanAssessmentForm
                ?.submittedCarePlanAssessmentFormName
            }
          />
        )}
      {componentState?.showTaskDrawer && (
        <AddOrUpdateTask
          key="editTask"
          task={props?.carePlanTask}
          isVisible={componentState?.showTaskDrawer}
          onCancel={resetTaskDetails}
          onComplete={resetTaskDetails}
          restrictPatientOrLeadSelectionTo={getNewTaskMember as any}
          personData={props.personData}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  inReviewText: {
    color: Colors.Custom.orangeMessageTopBar,
    marginLeft: 2,
  },
  assigneeText: {
    color: Colors.Custom.Gray400,
  },
  chevronDownIcon: {
    paddingHorizontal: 10,
    alignSelf: 'center',
  },
  fullFlex: {
    flex: 1,
  },
  relatedTaskHeaderText: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY400,
  },
  relatedTaskContainer: {
    borderWidth: 0.5,
    borderColor: Colors.Custom.Gray400,
    borderRadius: 4,
    padding: 4,
    backgroundColor: Colors.Custom.White,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 4,
  },
  relatedTaskText: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.Custom.GRAY350,
  },
  statusBadgeWrapper: {
    alignItems: 'center',
  },
});

const reactStyles: Record<string, React.CSSProperties> = {
  displayNone: {
    display: 'none'
  }
};

export default AddOrUpdateCarePlanDetails;
