import {useContext, useEffect, useState} from 'react';
import {
  ICareProgramAppointmentParams,
  ICareProgramAppointmentState,
} from '../interface';
import {CommonDataContext} from '../../../../../../../../context/CommonDataContext';
import {IFormCommonData} from '../../../CustomWrapper/CustomWrapper';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../../../constants/Configs';
import {GET_APPOINTMENT_BY_ID, GET_FUTURE_APPOINTMENT_DATA} from '../../../../../../../../services/Appointment/AppointmentQueries';
import {useLazyQuery} from '@apollo/client';
import {
  APPOINTMENT_PARTICIPANT_TYPE_CODES,
  CARE_TEAM_MEMBER_TYPE,
  MLOV_CATEGORY,
  VIRTUAL_ASSIGNEE_TYPE_CODES,
} from '../../../../../../../../constants/MlovConst';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovCodeFromId,
  getMlovValueFromId,
} from '../../../../../../../../utils/mlovUtils';
import {GET_ACCOUNT_LOCATION_BY_ID} from '../../../../../../../../services/Location/UserPracticeLocationQueries';
import UserQueries from '../../../../../../../../services/User/UserQueries';
import {ADDITIONAL_DATA_CODE} from '../../../../../../CareManagementBilling/CareManagementConstants';
import {
  getAppointmentAttributeIdFromSelectedStep,
  getAppointmentDateAndTime,
  getAppointmentIdFromSelectedStep,
  getPrimaryUserFromAppointment,
} from '../CareProgramAppointmentUtils';
import {cloneDeep} from 'lodash';
import { ADDITIONAL_ATTRIBUTE_KEY, CARE_PROGRAM_STEP_TYPE } from '../../../../../../../common/MemebersView/constant';
import { ContactCareProgramReducerAction } from '../../../../../../ContactCareProgram/ContactCareProgramView/reducer';
import { useCareProgramDashboardData } from '../../../../../../CareManagementView/CareManagementViewHooks/useCareProgramDashboardData';
import { addTimeToDate } from '../../../../../../../../utils/DateUtils';
import { getWhereConditionForUpcomingAppointmentData } from '../../../../../../CareManagementView/CareManagementUtils';
import { IKeyPointData } from '../../../../../../CareManagementView/CareManagementViewHooks/useCareProgramAdditionalData';
import { usePersonOmniViewContext } from '../../../../../../../PersonOmniView/PersonOmniView.context';

export const useCareProgramAppointments = (
  params: ICareProgramAppointmentParams
) => {
  const {component, onChange, handleUpdateCareProgram} = params;
  const [careProgramAppointmentState, setCareProgramAppointmentState] =
    useState<ICareProgramAppointmentState>({
      loadingStatus: 'loaded',
      showAppointmentDetailDrawer: false,
      showAppointmentBookingDrawer: false,
      showAppointmentListDrawer: false,
      appointmentStatus: '',
      appointmentData: null,
      primaryUser: null,
      appointmentLocation: null,
      appointmentDateAndTime: {
        appointmentDate: '',
        startTime: '',
        endTime: '',
      },
      primaryUserRole: '',
      pcpUserData: null,
      careManagerUserData: null,
      isPcpAppointment: false,
      isCareManagerAppointment: false,
      futureAppointmentList: [],
      futureAppointmentData: null
    });

  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const apppointmentByCode = contextData.careProgramData?.selectedStep?.careProgramStepType?.code;
  const isPcpAppointment = apppointmentByCode === CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT  || apppointmentByCode === CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY ? true : false;
  const isCareManagerAppointment = apppointmentByCode === CARE_PROGRAM_STEP_TYPE.CARE_MANAGER_APPOINTMENT ? true : false;
  const selectedStep = contextData.careProgramData?.selectedStep;
  const careProgramId = contextData.careProgramData?.careProgramId;

  const appointmentId =
    getAppointmentIdFromSelectedStep(selectedStep) ||
    component?.selectedValue?.appointmentId ||
    careProgramAppointmentState?.appointmentData?.id;
  const appointmentAttributeId = getAppointmentAttributeIdFromSelectedStep(selectedStep);
  const contactId = contextData?.contactId;
  const careProgramTypeId =
    contextData.careProgramData?.careProgramTypeId || '';

  const {getPatientAdditionalData} = useCareProgramDashboardData();

  const appointmentParticipantType =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_TYPE
    ) || [];

  const appointmentStatusList =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

  const careProgramTypesList =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_TYPES
    ) || [];

  const careProgramTypeCode = getMlovCodeFromId(
    careProgramTypesList,
    careProgramTypeId
  );

  const primaryUserId = getMlovIdFromCode(
    appointmentParticipantType,
    APPOINTMENT_PARTICIPANT_TYPE_CODES.PRIMARY_USER
  );

  const [getAppointmentById] = useLazyQuery(GET_APPOINTMENT_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getFutureAppointmentData] = useLazyQuery(GET_FUTURE_APPOINTMENT_DATA, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
});

  const [getAccountLocationById] = useLazyQuery(GET_ACCOUNT_LOCATION_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  const [getUserDataById] = useLazyQuery(UserQueries.GET_CONTACTS_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  const [getUserDataWithAccountLocationIdByUserUuid] = useLazyQuery(
    UserQueries.GET_USER_DATA_WITH_ACCOUNT_LOCATION_ID_BY_USER_UUID,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getAccountLocationData = async (locationId: string) => {
    const response = await getAccountLocationById({
      variables: {accountLocationIds: [locationId]},
    });
    return response.data?.accountLocations?.[0]?.practiceLocation;
  };

  const handleAppointmentBookingDrawerClose = (value: boolean) => {
    setCareProgramAppointmentState((prev) => {
      return {
        ...prev,
        showAppointmentBookingDrawer: value,
      };
    });
  };

  const handleAppointmentDetailDrawerClose = (value: boolean) => {
    setCareProgramAppointmentState((prev) => {
      return {
        ...prev,
        showAppointmentDetailDrawer: value,
      };
    });
  };

  const handleAppointmentListDrawerClose = (value: boolean) => {
    setCareProgramAppointmentState((prev) => {
      return {
        ...prev,
        showAppointmentListDrawer: value,
      };
    });
  };

  const onAppointmentBooking = async (appointmentData: any) => {
    if (!appointmentData?.id) return;
    setCareProgramAppointmentState((prev) => {
      return {
        ...prev,
        loadingStatus: 'loading',
      };
    });
    const careProgramStepAdditionalAttribute = {
      attributeKey: ADDITIONAL_ATTRIBUTE_KEY.APPOINTMENT_ID,
      attributeValueDataTypeCode: 'string',
      attributeValue: {
        valueString: appointmentData?.id,
      },
      subjectId: selectedStep?.id,
      subjectTypeCode: 'CARE_PROGRAM_STEP',
    };
    handleUpdateCareProgram(ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STEP_ADDITIONAL_ATTRIBUTES,careProgramStepAdditionalAttribute);
    handleAppointmentSummary(appointmentData);
    handleAppointmentBookingDrawerClose(false);
    handleAppointmentDetailDrawerClose(false);
    if (onChange) {
      onChange(cloneDeep({appointmentId: appointmentData?.id}));
    }
  };

  const getPrimaryUserRole = async (userId: number) => {
    const response = await getUserDataById({
      variables: {userId},
    });
    const userRoles = response?.data?.user?.userRoles;
    const formattedUserRoles = `${userRoles?.[0]?.userRole?.roleName}${
      userRoles?.length > 1 ? `+ ${userRoles?.length - 1} more` : ''
    }`;
    return formattedUserRoles;
  };

  const handleAppointmentSummary = async (appointmentData: any) => {
    if (!appointmentData?.id) {
      setCareProgramAppointmentState((prev) => {
        return {
          ...prev,
          loadingStatus: 'loaded',
        };
      });
      return;
    }
    try {
      const primaryUser = getPrimaryUserFromAppointment(
        appointmentData,
        primaryUserId
      );
      const appointmentStatus = getMlovValueFromId(
        appointmentStatusList,
        appointmentData?.statusId
      );
      const appointmentDateAndTime = getAppointmentDateAndTime(appointmentData);

      const promises = [];
      if (primaryUser?.id) {
        promises.push(getPrimaryUserRole(primaryUser.id));
      }
      if (appointmentData?.accountLocationId) {
        promises.push(
          getAccountLocationData(appointmentData?.accountLocationId)
        );
      }
      const userData = isPcpAppointment
        ? careProgramAppointmentState?.pcpUserData
        : careProgramAppointmentState?.careManagerUserData;
      if (userData?.uuid) {
        fetchUpcomingAppointments(userData, appointmentData?.id);
      }

      const [primaryUserRole, accountLocationData] = promises.length
        ? await Promise.all(promises)
        : [];

      setCareProgramAppointmentState((prev) => {
        return {
          ...prev,
          appointmentData: appointmentData,
          primaryUser,
          appointmentLocation: accountLocationData,
          appointmentDateAndTime,
          primaryUserRole,
          appointmentStatus,
          loadingStatus: 'loaded',
        };
      });
    } catch (error) {
      setCareProgramAppointmentState((prev) => {
        return {
          ...prev,
          loadingStatus: 'loaded',
        };
      });
    }
  };

  const validateData = () => {
    // Add your validation logic here
    return {
      isValid: true,
      message: '',
    };
  };

  const fetchPCPData = async () => {
    try {
      const pcpData = isPcpAppointment && (await getPatientAdditionalData({
        contactUuid: contactId || '',
        additionalDataCodes: [ADDITIONAL_DATA_CODE.PCP_AND_CARE_MANAGER],
      })) as any;
      const pcpUserId = pcpData?.[0]?.referenceData?.userId;

      const careManagerData = isCareManagerAppointment && (await getPatientAdditionalData({
        contactUuid: contactId || '',
        additionalDataCodes: [ADDITIONAL_DATA_CODE.CARE_MANAGER],
      })) as any;
      const careManagerUserId = careManagerData?.[0]?.referenceData?.userId;

      let userData : any;
      if(careManagerUserId && isCareManagerAppointment){
        userData = await getUserDataWithAccountLocationIdByUserUuid({
          variables: {userUuid: careManagerUserId},
        });
        setCareProgramAppointmentState((prev) => {
          return {
            ...prev,
            careManagerUserData: userData?.data?.users?.[0],
            loadingStatus: 'loaded',
          };
        });

      }
      if (pcpUserId && isPcpAppointment) {
         userData = await getUserDataWithAccountLocationIdByUserUuid({
          variables: {userUuid: pcpUserId},
        });
        setCareProgramAppointmentState((prev) => {
          return {
            ...prev,
            pcpUserData: userData?.data?.users?.[0],
            loadingStatus: 'loaded',
          };
        });
      }
      await fetchUpcomingAppointments(userData?.data?.users?.[0], appointmentId);
      setCareProgramAppointmentState((prev) => {
        return {
          ...prev,
          loadingStatus: 'loaded',
        };
      });
    } catch (error) {
      setCareProgramAppointmentState((prev) => {
        return {
          ...prev,
          loadingStatus: 'loaded',
        };
      });
    }
  };

  const fetchUpcomingAppointments = async (
    userData: any,
    appointmentId?: string
  ) => {
    try {
      const startDateTime = addTimeToDate(new Date(), -1, 'WEEK').toISOString();
      const endDateTime = addTimeToDate(new Date(), 1, 'MONTH').toISOString();
      if (userData?.uuid && contactId) {
        const whereCondition = getWhereConditionForUpcomingAppointmentData(
          userData?.uuid,
          contactId,
          appointmentStatusList,
          startDateTime,
          endDateTime,
          VIRTUAL_ASSIGNEE_TYPE_CODES.CLINIC,
          careProgramTypeCode == 'AWV' ? careProgramTypeCode : 'ROUTINE'
        );
        const response = await getFutureAppointmentData({
          variables: {whereCondition: whereCondition},
        });
        if (
          response?.data &&
          response?.data?.appointments &&
          response?.data?.appointments?.length
        ) {
          const updatedAppointments = response?.data?.appointments?.map(
            (appointment: any) => ({
              ...appointment,
              participants: appointment?.participants?.map(
                (participant: any) => {
                  if (participant?.userId === userData?.uuid) {
                    return {
                      ...participant,
                      user: userData,
                    };
                  }
                  return participant;
                }
              ),
            })
          );
          const filterCareProgramAppointments =
            updatedAppointments?.filter(
              (appointment: any) => appointment?.id !== appointmentId
            ) || [];
          setCareProgramAppointmentState((prev) => {
            return {
              ...prev,
              futureAppointmentList: filterCareProgramAppointments,
            };
          });
        } else {
          setCareProgramAppointmentState((prev) => {
            return {
              ...prev,
              futureAppointmentList: [],
            };
          });
        }
      }
    } catch (error) {
      setCareProgramAppointmentState((prev) => {
        return {
          ...prev,
          futureAppointmentList: [],
        };
      });
    }
  };

  const fetchInitialCareProgramAppointments = async (appointmentId: string) => {
    if (!appointmentId) return;
    try {
      setCareProgramAppointmentState((prev) => {
        return {
          ...prev,
          loadingStatus: 'loading',
        };
      });
      const {data} = await getAppointmentById({
        variables: {appointmentId},
      });

      const appointmentData = data?.appointments?.[0];
      handleAppointmentSummary(appointmentData);
    } catch (error) {
      setCareProgramAppointmentState((prev) => {
        return {
          ...prev,
          loadingStatus: 'loaded',
        };
      });
    }
  };

  const fetchInitialData = async () => {
    setCareProgramAppointmentState((prev) => {
      return {
        ...prev,
        loadingStatus: 'loading',
        isPcpAppointment,
        isCareManagerAppointment
      };
    });
    if (appointmentId) {
      fetchInitialCareProgramAppointments(appointmentId);
    }
      await fetchPCPData();
  };

  useEffect(() => {
    fetchInitialData();
  }, [selectedStep?.id]);

  return {
    careProgramAppointmentState,
    careProgramTypeCode,
    handleAppointmentBookingDrawerClose,
    handleAppointmentDetailDrawerClose,
    handleAppointmentListDrawerClose,
    handleAppointmentSummary,
    onAppointmentBooking,
    validateData,
    fetchInitialData,
    careProgramId,
    selectedStep,
    appointmentAttributeId,
    isPcpAppointment,
    isCareManagerAppointment,
    fetchUpcomingAppointments
  };
};
