import {View} from "react-native";
import {Colors} from "../../../../styles";
import {ModalActionInput} from "../../ModalActionCommonComponent/ModalActionInput";
import {Form, Select, Tooltip} from "antd";
import {testID} from "../../../../testUtils";
import {FormControl} from "native-base";
import {DisplayText} from "../../DisplayText/DisplayText";
import {getMlovListFromCategory} from "../../../../utils/mlovUtils";
import {useContext, useEffect, useState} from "react";
import {CommonDataContext} from "../../../../context/CommonDataContext";
import {MLOV_CATEGORY} from "../../../../constants/MlovConst";
import {IAddOrUpdateTaskState} from "../interfaces";

export const DiagnosisGapHccCodeTitleView = (props: {
  handleChange: (value: any) => void;
  value: IAddOrUpdateTaskState;
})=> {
  const mlovData = useContext(CommonDataContext);
  const categories = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.HCC_CATEGORY
  )?.map(item => ({
    value: item.id,
    label: item.code,
    name: item.value
  })).sort((a, b) => {
    const aNum = parseInt(a.label.replace('HCC', ''));
    const bNum = parseInt(b.label.replace('HCC', ''));
    return aNum - bNum;
  });
  
  const [hccCategoriesList, setHccCategoriesList] = useState<{
    value: string;
    label: string; 
    name: string;
  }[]>(categories);


  return <>
    <FormControl
      isRequired={true}
      isInvalid={false}
      {...testID('DiagnosisGapHccCode')}
    >
      <FormControl.Label marginLeft={0} {...testID('HCC Category Code')}>
        <DisplayText
          size={'smMedium'}
          extraStyles={{
            color: Colors.Custom.alertsDescriptionColor,
            fontWeight: 400,
            fontSize: 14,
          }}
          textLocalId={'HCC Category Code'}
        />
      </FormControl.Label>
      <Select
        placeholder="Select Code"
        style={{
          display: 'block'
        }}
        defaultValue={props?.value.hccCategoryId}
        showSearch={true}
        filterOption={(input: any, option: any) => {
          return (option?.label?.toLowerCase().includes(input.toLowerCase()));
        }}
        onChange={(value) => {
          const selectedHccCategory = categories.find(item => item.value === value);
          props?.handleChange({
            ...props?.value, 
            hccCategoryId: value,
            title: selectedHccCategory?.name
          });
        }}
        options={categories}
      />
    </FormControl>
    <View style={{
      marginTop: 14
    }}>
      <ModalActionInput
        maxLength={300}
        fieldIs={'input'}
        label={'HCC Category Name'}
        placeholder={'Enter Name'}
        leftMargin={'0'}
        isRequired={true}
        isInvalid={props?.value.showErrors && props?.value.title.trim().length===0}
        value={props?.value.title}
        onChangeText={(text: any) => {
          props?.handleChange({...props?.value,title: text});
        }}
        extraStyle={{flex: 1,fontColor: Colors.Custom.alertsDescriptionColor}}
        borderRadius={4}
        borderColor={Colors.FoldPixel.GRAY150}
      />
    </View>
  </>
}