import React, {useContext} from 'react';
import {DatePicker} from 'antd';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {DATE_FORMATS} from '../../../../../../../constants';
import {antdStyles, styles} from './AddPatientInformationVerificationStyle';
import {FormControl, Input, Spinner, Icon, View} from 'native-base';
import {Colors} from '../../../../../../../styles';
import {getMomentObj, isFutureDate} from '../../../../../../../utils/DateUtils';
import {PatientInformationVerificationField} from '../../CustomComponentUtils';
import {Linking, Text} from 'react-native';
import InfoCircleSvg from '../../../../../../common/Svg/InfoCircleSvg';
import {
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import {WIDGET_TYPES} from '../../../../FormsConstants';
import {FormError} from '../../CustomWrapper/CustomComponentHelper';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import {useIntl} from 'react-intl';
import {replaceHashValueToString} from '../../../../../../../utils/commonUtils';
import {useAddPatientInformationVerification} from './useAddPatientInformationVerification';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';

const AddPatientInformationVerification: React.FC<IFormComponentProps> = (
  props
) => {
  const intl = useIntl();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isBuilderPreviewMode = !!props.options?.isBuilderPreviewMode;
  const isPrintForm = !!props.options?.isPrintForm;
  const isPreviewMode = !!contextData.isPreviewMode || false;
  const disableForm = isPreviewMode || props.disabled || isBuilderPreviewMode || isPrintForm || false;
  const {
    componentValue,
    componentState,
    handleChange,
    isInvalid,
    validateData,
    checkPatientInfoVerificationSuccess,
  } = useAddPatientInformationVerification({
    component: props.component,
    validateRef: props.validateRef,
    options: props.options,
    onChange: props.onChange,
    previewMode: disableForm,
  });

  props.validateRef.current = validateData;
  if (
    props.options?.widgetType !== WIDGET_TYPES.PATIENT_INFORMATION_FORM &&
    props?.options?.isBuilderPreviewMode !== undefined &&
    !props?.options?.isBuilderPreviewMode
  ) {
    return <FormComponentError error={FormError.configurationDataAPIFail} />;
  }

  return (
    <Stack direction="column">
      {isPreviewMode && props?.component?.label && (
        <Stack direction="column" style={{paddingVertical: 8}}>
          <Text>{props?.component?.label}</Text>
        </Stack>
      )}
      <Stack direction="column" style={styles.container}>
        <Stack direction="column">
          <DisplayText
            textLocalId="patientIdentifierMessage"
            extraStyles={{color: Colors.Custom.Gray500, fontSize: 14}}
          />
        </Stack>

        <Stack direction="column" space={24} style={{padding: '20px'}}>
          <Stack direction="row" space={24} style={styles.rowContainer}>
            <FormControl
              isRequired={true}
              style={{flex: 1}}
              isInvalid={isInvalid(
                PatientInformationVerificationField.FIRST_NAME,
                componentValue.firstName
              )}
            >
              <FormControl.Label>
                <DisplayText
                  textLocalId="firstName"
                  extraStyles={{color: Colors.Custom.Gray500, fontSize: 14}}
                />
              </FormControl.Label>
              <Input
                _focus={{borderColor: Colors.Custom.Gray300}}
                placeholder="Enter first name"
                isDisabled={disableForm}
                value={componentValue.firstName}
                height={9}
                style={styles.input}
                onChangeText={(value) => {
                  handleChange(
                    PatientInformationVerificationField.FIRST_NAME,
                    value
                  );
                }}
              />
            </FormControl>

            <FormControl
              isRequired={true}
              style={{flex: 1}}
              isInvalid={isInvalid(
                PatientInformationVerificationField.LAST_NAME,
                componentValue.lastName
              )}
            >
              <FormControl.Label>
                <DisplayText
                  textLocalId="lastName"
                  extraStyles={{color: Colors.Custom.Gray500, fontSize: 14}}
                />
              </FormControl.Label>
              <Input
                _focus={{borderColor: Colors.Custom.Gray300}}
                placeholder="Enter last name"
                isDisabled={disableForm}
                value={componentValue.lastName}
                height={9}
                style={styles.input}
                onChangeText={(value) => {
                  handleChange(
                    PatientInformationVerificationField.LAST_NAME,
                    value
                  );
                }}
              />
            </FormControl>
          </Stack>

          <Stack direction="row" space={24} style={styles.rowContainer}>
            <FormControl
              isRequired={true}
              style={{flex: 1}}
              isInvalid={isInvalid(
                PatientInformationVerificationField.DATE_OF_BIRTH,
                componentValue.dateOfBirth
              )}
            >
              <FormControl.Label>
                <DisplayText
                  textLocalId="birthDate"
                  extraStyles={{color: Colors.Custom.Gray500, fontSize: 14}}
                />
              </FormControl.Label>
              <DatePicker
                style={antdStyles.datePicker}
                format={DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT}
                disabled={disableForm}
                value={
                  componentValue.dateOfBirth
                    ? getMomentObj(componentValue.dateOfBirth)
                    : undefined
                }
                disabledDate={(current: any) => {
                  return current && isFutureDate(current);
                }}
                inputReadOnly
                placeholder="Select date"
                onChange={(dates: any, formatString: string) => {
                  handleChange(
                    PatientInformationVerificationField.DATE_OF_BIRTH,
                    formatString
                  );
                }}
              />
            </FormControl>

            <FormControl
              isRequired={true}
              style={{flex: 1}}
              isInvalid={isInvalid(
                PatientInformationVerificationField.ZIP_CODE,
                componentValue.zipCode
              )}
            >
              <FormControl.Label>
                <DisplayText
                  textLocalId="zipCode"
                  extraStyles={{color: Colors.Custom.Gray500, fontSize: 14}}
                />
              </FormControl.Label>
              <Input
                _focus={{borderColor: Colors.Custom.Gray300}}
                placeholder="Enter zip code"
                maxLength={5}
                isDisabled={disableForm}
                value={componentValue.zipCode}
                height={9}
                style={styles.input}
                onChangeText={(value) => {
                  const numericValue = value.replace(/[^0-9]/g, '');
                  handleChange(
                    PatientInformationVerificationField.ZIP_CODE,
                    numericValue
                  );
                }}
              />
            </FormControl>
          </Stack>

          {componentState.isApiLoading ? (
            <Stack direction="row">
              <Spinner />
              <DisplayText
                extraStyles={styles.loadingText}
                textLocalId="patientIdentificationLoadingMsg"
              />
            </Stack>
          ) : checkPatientInfoVerificationSuccess() ? (
            <Stack direction="row" style={styles.alignItemsCenter}>
              <Icon
                as={MaterialIcon}
                name="check-circle"
                size="4"
                marginRight={1}
                color={Colors.Custom.SuccessColor}
              />
              <DisplayText
                extraStyles={{color: Colors.Custom.SuccessColor}}
                textLocalId="patientIdentifiedSuccessMsg"
              />
            </Stack>
          ) : (
            componentState.showErrors && (
              <Stack
                direction="row"
                space={8}
                style={{
                  padding: 8,
                  backgroundColor: '#FEF3F2',
                  borderRadius: 4,
                  alignItems: 'center',
                }}
              >
                <InfoCircleSvg
                  height={16}
                  size={16}
                  customStrokeColor={Colors.Custom.ErrorColor}
                />
                <Text style={{color: Colors.Custom.Gray500, fontSize: 14}}>
                  {intl.formatMessage({
                    id: componentState.accountConfig?.contactNumber
                      ? 'patientNotFoundMessageWithContactNumber'
                      : 'patientNotFoundMessage',
                  })}
                  {componentState.accountConfig?.contactNumber && (
                    <>
                      <Text
                        style={{textDecorationLine: 'underline'}}
                        onPress={() =>
                          Linking.openURL(
                            `tel:${componentState.accountConfig?.contactNumber}`
                          )
                        }
                      >
                        {componentState.accountConfig.contactNumber}
                      </Text>
                      <Text
                        style={{color: Colors.Custom.Gray500, fontSize: 14}}
                      >
                        {' for assistance'}
                      </Text>
                    </>
                  )}
                </Text>
              </Stack>
            )
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default React.memo(AddPatientInformationVerification);
