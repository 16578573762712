import {
  HStack,
  Icon,
  Pressable,
  View,
  Text,
  IconButton,
  Spacer,
  useToast,
  Spinner,
  FlatList,
  useMediaQuery,
} from 'native-base';
import React, {useState} from 'react';
import {Colors} from '../../../../styles/Colors';
import {Tooltip, UploadFile} from 'antd';
import {IAttachmentComponentState, IAttachmentsItem} from '../interfaces';
import {isWeb} from '../../../../utils/platformCheckUtils';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {WORD_DOC_MIME_TYPES} from '../../DocumentViewer/DocumentViewerHelper';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {getBase64} from '../../../PersonOmniView/LeftContainer/OtherDetails/PatientDocuments/UploadPatientDocument';
import {IMediaLibraryData} from '../../../RightSideContainer/ContentManagement/MediaLibrary/interfaces';
import {RcFile} from 'antd/lib/upload';
import {ToastType, showToast} from '../../../../utils/commonViewUtils';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {DocumentViewer} from '../../DocumentViewer/DocumentViewer';
import CareStudioService from '../../../../services/CommonService/CareStudioService';
import {SMALL_WINDOW} from '../../../../constants';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { StyleSheet } from 'react-native';

const AttachmentsItemList = (props: IAttachmentsItem) => {
  const {fileList, handleChange, value, isViewOnly} = props;
  const toast = useToast();
  const numColumns = 3;
  const careStudioInstance = CareStudioService.getCareStudioServiceInstance();
  const [isSmallWindow] = useMediaQuery([
    { maxWidth: SMALL_WINDOW },
  ]);
  const isSmallScreen = isSmallWindow;
  const [componentState, setComponentState] =
    useState<IAttachmentComponentState>({
      progress: 0,
      isLoading: false,
      showErrors: false,
      isPreviewVisible: false,
      previewTitle: undefined,
      isFileLoading: {},
    });

  const handlePreview = async (file: UploadFile) => {
    if (componentState.isFileLoading[(file as any).uid]) {
      return;
    }

    let fileUrl = file?.url || '';

    if (
      (file as any).id &&
      (!(file as any).blobUrl ||
        WORD_DOC_MIME_TYPES.includes(file.type as string))
    ) {
     await downloadFileAsBlob(file);
    }

    if (
      !(file as any).id &&
      file?.type &&
      WORD_DOC_MIME_TYPES.includes(file?.type)
    ) {
       await convertToPdf(file);
    }

    const fileMediaData: IMediaLibraryData = {
      ...file,
      lastUpdated: file.lastModified?.toString() || '',
      mime: file.type as string,
      name: file.name,
      size: file?.size as number,
    };

    if (!(file as any).blobUrl && !file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    if (file.type && !fileUrl) {
      fileUrl =
        (file as any).blobUrl ||
        file.preview ||
        URL.createObjectURL(file.originFileObj as any);
    }

    setComponentState((prev) => ({
      ...prev,
      isPreviewVisible: true,
      previewTitle:
        file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
      ...(file.type &&
        fileUrl && {
          previewObj: {
            url: fileUrl || '',
            type: file.type as string,
            fileMediaData: fileMediaData,
          },
        }),
    }));
  }

  const handlePreviewCancel = (value = false) => {
    setComponentState((prev) => ({
      ...prev,
      previewObj: undefined,
      isPreviewVisible: value,
      previewTitle: undefined,
    }));
  }

  const downloadFileAsBlob = async (file: any, downloadFile = false) => {
    const attachmentId: string = (file as any).id;
     const service = careStudioInstance.careStudioAxiosService;
    setComponentState((prev) => {
      prev.isFileLoading[file.uid] = true;
      return {...prev, isFileLoading: prev.isFileLoading};
    });

    try {
        if (!file.blobUrl || WORD_DOC_MIME_TYPES.includes(file.type)) {
          const response = await service.get(`/attachment/${attachmentId}/file?isPreview=${downloadFile === false}`, {
            responseType: 'blob',
          });
          file.blob = response.data;
          file.blobUrl = URL.createObjectURL(file.blob);
          file.preview = await getBase64(response.data);
        }

      if (downloadFile) {
        if (isWeb()) {
          const link = document.createElement('a');
          link.href = file.blobUrl;
          link.download = file.name;
          link.click();
        } else {
          showToast(toast, 'Unable to download file', ToastType.error);
        }
      }

      setComponentState((prev) => {
        prev.isFileLoading[file.uid] = false;
        return {...prev, isFileLoading: prev.isFileLoading};
      });
    } catch (error) {

      showToast(toast, 'Error while opening file', ToastType.error);
      setComponentState((prev) => {
        prev.isFileLoading[file.uid] = false;
        return {...prev, isFileLoading: prev.isFileLoading};
      });
    }
  };

  const convertToPdf = async (file: any) => {
    const service = careStudioInstance.careStudioAxiosService;
    setComponentState((prev) => {
      prev.isFileLoading[file.uid] = true;
      return { ...prev, isFileLoading: prev.isFileLoading };
    });

    try {
      const formData = new FormData();
      formData.append('file', file.originFileObj);
      const response = await service.post(
        `/attachment/convert-to-pdf`,
        formData,
        {
          responseType: 'blob',
        }
      );

      file.blob = response.data;
      file.blobUrl = URL.createObjectURL(file.blob);
      file.preview = await getBase64(response.data);

      setComponentState((prev) => {
        prev.isFileLoading[file.uid] = false;
        return { ...prev, isFileLoading: prev.isFileLoading };
      });
    } catch (error) {

      showToast(toast, 'Error while opening file', ToastType.error);
      setComponentState((prev) => {
        prev.isFileLoading[file.uid] = false;
        return { ...prev, isFileLoading: prev.isFileLoading };
      });
    }
  };

  const getIconByFileType = (documentType?: string) => {
    if (documentType === 'application/pdf') {
      return (
        <MaterialCommunityIcons
          name="file-pdf-box"
          size={16}
          color={Colors.FoldPixel.PRIMARY300}
        />
      );
    } else if (documentType === 'image/gif') {
      return (
        <MaterialCommunityIcons
        name="file-gif-box"
        size={16}
        color={Colors.FoldPixel.PRIMARY300}
      />
      )
    } else if (documentType?.startsWith('image/')) {
      return (
        <MaterialCommunityIcons
          name="file-image"
          size={16}
          color={Colors.FoldPixel.PRIMARY300}
        />
      );
    } else if (documentType && WORD_DOC_MIME_TYPES.includes(documentType)) {
      return (
        <MaterialCommunityIcons
          name="file-word"
          size={16}
          color={Colors.FoldPixel.PRIMARY300}
        />
      );
    } else if (documentType && documentType.startsWith('audio/')) {
      return (
        <MaterialIcons
          name="audiotrack"
          size={16}
          color={Colors.FoldPixel.PRIMARY300}
        />
      );
    } else if (documentType && documentType.startsWith('video/')) {
      return (
        <MaterialCommunityIcons
          name="video"
          size={16}
          color={Colors.FoldPixel.PRIMARY300}
        />
      );
    }
    return (
      <Ionicons name="document-text" size={16} color={Colors.FoldPixel.PRIMARY300} />
    );
  };

  const renderItem = ({item, index}: {item: any; index: number}) => {
    if (item?.isDeleted) {
      return <></>;
    }
    return (
      <View
        key={index}
        alignItems={'center'}
        justifyContent={'center'}
        height={8}
        width={202}
        backgroundColor={Colors.FoldPixel.PRIMARY100}
        borderRadius={6}
        mr={2}
      >
        <Pressable
          width={'100%'}
          onPress={() => {
            const file = {
              ...item,
              id: item.attachmentId,
              uid: item.attachmentId,
              name: item.name,
              type: item.type,
              status: 'done',
            } as any;
            handlePreview(file);
          }}
        >
          <HStack
            width={'100%'}
            space={2}
            marginRight={3}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <View width={8} alignItems={'center'} justifyContent={'center'}>
              {getIconByFileType(item.type)}
            </View>

            <Text color={Colors.FoldPixel.PRIMARY300} fontWeight={500} size={isWeb() ? 'smMedium' : 'sm'} noOfLines={1}>
              {item.name}
            </Text>

            <Spacer />

            {!componentState.isLoading && (
              <HStack>
                {componentState.isFileLoading[item.uid] && (
                  <Spinner size="sm" />
                )}

                {!isViewOnly && (
                  <Tooltip title="Delete Document">
                    <IconButton
                      width={8}
                      colorScheme="gray"
                      variant="ghost"
                      onPress={() => {
                        const newFileList = [...(fileList || [])];
                        if (newFileList?.[index]?.id) {
                          newFileList[index] = {
                            ...newFileList?.[index],
                            isDeleted: true,
                          };
                        } else {
                          newFileList?.splice(index, 1);
                        }
                        handleChange?.({
                          ...value,
                          fileList: newFileList,
                        });
                      }}
                      icon={
                        <Icon
                          as={AntIcon}
                          name="close"
                          size={4}
                          color={Colors.FoldPixel.PRIMARY300}
                        />
                      }
                    />
                  </Tooltip>
                )}
              </HStack>
            )}
          </HStack>
        </Pressable>
      </View>
    );
  };

  return (
    <View
      width={'100%'}
      flex={1}
      borderColor={Colors.Custom.Gray200}
      borderTopWidth={0}
      borderLeftWidth={!isViewOnly ? 1 : 0}
      borderRightWidth={!isViewOnly ? 1 : 0}
      borderBottomWidth={!isViewOnly ? 1 : 0}
      borderBottomLeftRadius={4}
      borderBottomRightRadius={4}
      borderTopRightRadius={0}
      borderTopLeftRadius={0}
      paddingLeft={isViewOnly ? 0 : 2}
      paddingRight={2}
      paddingY={2}
      style={styles.flexWrapContainer}
          >
      <FlatList
        data={fileList}
        // THIS IS DONE TO MAKE SURE THAT THE FLATLIST IS RESPONSIVE AND THE ITEMS ARE WRAPPED AS PER THE WIDTH OF THE CONTAINER
        contentContainerStyle={{flexDirection : "row", flexWrap : "wrap"}}
        flexWrap={'wrap'}
        maxW={'100%'}
        numColumns={props?.disableStrictColumns ? undefined : numColumns}
        renderItem={renderItem}
        keyExtractor={(item, index) => index.toString()}
      />
      {componentState.previewTitle &&
        componentState.previewObj?.type &&
        componentState.isPreviewVisible && (
          <DocumentViewer
            fileName={componentState.previewTitle}
            fileType={componentState.previewObj.type}
            onClose={handlePreviewCancel}
            fileUrl={componentState.previewObj.url}
            isOpen={true}
            mediaPlayerHeight={'75%'}
            mediaPlayerWidth={'75%'}
          />
        )}
    </View>
  );
};

const styles = StyleSheet.create({
  flexWrapContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export default AttachmentsItemList;
