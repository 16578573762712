import React, { useContext, useEffect, useState } from 'react';
import { HStack, Text, VStack, View } from 'native-base';
import {Checkbox, Radio} from 'antd';
import {getMlovCodeFromId} from '../../../../../../utils/mlovUtils';
import {CARE_PROGRAM_STEP_TYPE, CONSENT_TYPE_CODES, MLOV_CATEGORY} from '../../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {FormContext, IFormCommonData, IFormComponentProps} from '../CustomWrapper/CustomWrapper';
import {useLazyQuery} from '@apollo/client';
import MlovQueries from '../../../../../../services/Mlov/MlovQueries';
import {CARESTUDIO_APOLLO_CONTEXT, CARESTUDIO_PROXY_TO_CRM_CONTEXT} from '../../../../../../constants/Configs';
import {getEhrConfig} from '../../../../../PersonOmniView/MiddleContainer/CareTimeline/CareTimelineUtils';
import {CONSENT_CODE, CONSENT_CONST, PREFERRED_CHANNEL_CODE} from '../../../../../../constants';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {FormError} from '../CustomWrapper/CustomComponentHelper';
import {getEmailContactObjFromList, getPhoneContactObjFromList, unmaskPhoneNumber} from '../../../../../../utils/commonUtils';
import ContactsQueries from '../../../../../../services/Contacts/ContactsQueries';
import FormComponentLoader from '../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {cloneDeep} from 'lodash';
import DetailPreview from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import FormComponentError from '../CustomWrapper/FormComponentError/FormComponentError';
import {v4 as uuidv4} from 'uuid';
import {useIntl} from 'react-intl';
import { IFormValidationOutput } from '../CustomWrapper/interfaces';
import { IMlov } from '../../../../../../Interfaces';
import ContactCareProgram from '../../../../../../services/ContactCareProgram/ContactCareProgram';

export interface IPatientConsentAndCommunicationValue {
  consentIds: any[];
  preferredMode?: {
    preferredModeId: string,
    preferredModeValue: string
  };
  consents?: {[index: string]: boolean};
  consentType?: string;
}
interface IConsentAndPreferredCommunicationState {
  showErrors: boolean;
  loading: boolean;
  formError?: FormError;
}

export const ConsentAndPreferredCommunication = (props: IFormComponentProps) => {
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const consentType = props.component.consentType || CONSENT_TYPE_CODES.COMMUNICATION;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare != undefined &&  props?.component?.allowToShare === false ? false : true;
  const intl = useIntl()
  const [stateData, setStateData] = useState({
    preferredCommunicationType: [] as any,
    communicationConsentType: [] as any,
    personContactTypeList: [] as any,
    accountConfigData: [] as any,
    isUnChecked: false as boolean,
    email: '' as string,
    phone: '' as string,
    secondaryEmail: '' as string,
    secondaryPhone: '' as string,
    consentChk: false as boolean,
    careProgramTypes: [] as IMlov[],
    careProgramSteps: [] as IMlov[],
    consentAlreadyGiven: false,
  })
  const isCarePlanContext = props.options.formContext === FormContext.patientCarePlan;
  const [selectedConsent, setConsentList] = useState<
    {
      consentToCall: boolean;
      consentToText: boolean;
      consentToEmail: boolean;
    }>({
      consentToCall: false,
      consentToText: false,
      consentToEmail: false,
  });
  const [componentState, setComponentState] =
    useState<IConsentAndPreferredCommunicationState>({
      showErrors: false,
      loading: false,
      formError: contextData.formError,
    });
  const [componentValue, setComponentValue] =
    useState<IPatientConsentAndCommunicationValue>(
      props.component?.selectedValue || {}
    );
  const currentEHR = contextData.capabilities?.ehrName || '';
  const ehrConfig = getEhrConfig(currentEHR);
  const showSingleConsent = !ehrConfig.isAthena || consentType !== CONSENT_TYPE_CODES.COMMUNICATION;

  const [getMlovList] = useLazyQuery(MlovQueries.GET_MLOVS_BY_CATEGORY, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
      headers: contextData.headers,
    },
  });
  const [getAccountMlovList] = useLazyQuery(MlovQueries.GET_ACCOUNT_MLOVS_BY_CATEGORY, {
    fetchPolicy: 'no-cache',
    context: {
      headers: contextData.headers,
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });
  const [getCareProgramConsentStep] = useLazyQuery(ContactCareProgram.GET_STEP_STATUS_OF_LATEST_CARE_PROGRAM, {
    fetchPolicy: 'no-cache',
    context: {
      headers: contextData.headers,
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });
  const [getContactDetails] = useLazyQuery(
    ContactsQueries.GET_CONTACT_CONSENT_AND_COMMUNICATION_BY_CONTACT_UUID,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        headers: contextData.headers,
      },
    }
  );

  const deSelectRadio = () => {
    const isEmailOrPhoneExist =
    stateData?.email ||
    stateData?.secondaryEmail ||
    stateData?.secondaryPhone ||
    stateData?.phone
        ? true
        : false;
    return isEmailOrPhoneExist;
  };

  const isRadioDisable = (item: any) => {
    let isDisable = false;
    if (
      (item.code == PREFERRED_CHANNEL_CODE.VOICE ||
        item.code == PREFERRED_CHANNEL_CODE.SMS) &&
      !stateData?.phone &&
      !stateData?.secondaryPhone
    ) {
      isDisable = true;
    }
    if (
      item.code == PREFERRED_CHANNEL_CODE.EMAIL &&
      !stateData?.email &&
      !stateData?.secondaryEmail
    ) {
      isDisable = true;
    }
    return isDisable;
  };

  const setDefaultContactConsent =  (contactConsents: any | [],communicationConsentType: any) => {
    const  memberConsent = {
      consentToCall: false,
      consentToText: false,
      consentToEmail: false,
    };
    if (contextData?.contactId) {
      (contactConsents || []).forEach((consent: any) => {
        const consentCode = getMlovCodeFromId(communicationConsentType, consent?.consentId);
        switch (consentCode) {
          case CONSENT_CONST.consentToCall:
            memberConsent.consentToCall = true;
            break;
          case CONSENT_CONST.consentToEmail:
            memberConsent.consentToEmail = true;
            break;
          case CONSENT_CONST.consentToText:
            memberConsent.consentToText = true;
            break;
        }
      });
    } else {
      memberConsent.consentToCall = true;
      memberConsent.consentToText = true;
      memberConsent.consentToEmail = true;
    }
    setConsentList(prev => {
      return {
        ...prev,
        consentToCall: memberConsent.consentToCall,
        consentToText: memberConsent.consentToText,
        consentToEmail: memberConsent.consentToEmail,
      }
    });
  }

  const fetchInitialData = async () => {
    if (contextData.isBuilderMode) {
      return;
    }
    if (consentType === CONSENT_TYPE_CODES.COMMUNICATION) {
      fetchCommunicationConsentInitialData();
    }
    else {
      fetchCareProgramConsentInitialData();
    }
  }

  const fetchCareProgramConsentInitialData = async () => {
    try {
      const contactId = contextData?.contactId;
      const tenantId = contextData?.tenantId;
      if (!tenantId || !contactId) {
        return;
      }

      const mlovData = await getAccountMlovList({
        variables: {
          categories: [MLOV_CATEGORY.CARE_PROGRAM_TYPES, MLOV_CATEGORY. CARE_PROGRAM_STEP_TYPE],
        },
      });
      const careProgramTypes: IMlov[] = (mlovData?.data?.accountMlovsDeprecated || []).filter((mlov: any) => mlov?.category?.category === MLOV_CATEGORY.CARE_PROGRAM_TYPES && (!mlov.tenantId || mlov.tenantId === tenantId));
      const careProgramSteps: IMlov[] = (mlovData?.data?.accountMlovsDeprecated || []).filter((mlov: any) => mlov?.category?.category === MLOV_CATEGORY. CARE_PROGRAM_STEP_TYPE && (!mlov.tenantId || mlov.tenantId === tenantId));
      setStateData((prev)=>{
        return {
          ...prev,
          careProgramTypes,
          careProgramSteps,
        }
      });
      const careProgramType = careProgramTypes.find(item => item.code === consentType);
      const consentStepType = careProgramSteps.find(item => item.code === CARE_PROGRAM_STEP_TYPE.CONSENT);

      if(isCarePlanContext){
        if (
          (consentType === CONSENT_TYPE_CODES.CCM &&
            componentValue.consents?.CCM === true) ||
          (consentType === CONSENT_TYPE_CODES.TCM &&
            componentValue.consents?.TCM === true) ||
          (consentType === CONSENT_TYPE_CODES.ECM &&
            componentValue.consents?.ECM === true)
        ) {
          setStateData((prev) => ({
            ...prev,
            consentChk: true,
          }));
        }
      }
      else if (careProgramType?.id && consentStepType?.id) {
        setComponentState((prev)=>{
          return {
            ...prev,
            loading: true
          }
        });
        const response = await getCareProgramConsentStep({
          variables: {
            params: {
              careProgramStepTypeId: consentStepType?.id,
              contactId: contactId,
              careProgramTypeId: careProgramType?.id
            }
          }
        });
        const hasConsent = response.data.getStepLatestContactCareProgram?.consentLog?.hasConsent || false;
        setComponentState((prev)=>{
          return {
            ...prev,
            loading: false,
          }
        });
        setStateData((prev)=>{
          return {
            ...prev,
            consentChk: hasConsent,
            consentAlreadyGiven: hasConsent,
          }
        });
        setComponentValue((prev) => {
          const consents: {[index: string]: boolean} = {};
          consents[consentType] = hasConsent;
          return {
            ...prev,
            consents,
          }
        });
      }
    } catch {
      setComponentState((prev)=>{
        return {
          ...prev,
          loading: false
        }
      });
    }
  }

  const fetchCommunicationConsentInitialData = async () => {
    let personContactTypeList: any[] = [];
    let communicationConsentType: any[] = [];
    const mlovData = await getMlovList({
      variables: {
        categories: [MLOV_CATEGORY.PREFERRED_COMMUNICATION_CHANNEL, MLOV_CATEGORY.CONSENT, MLOV_CATEGORY.PERSON_CONTACT_TYPE],
      },
    })
    if (mlovData?.data?.mlovs?.length) {
      personContactTypeList = (mlovData?.data?.mlovs || []).filter((mlov: any) => mlov?.category?.category === MLOV_CATEGORY.PERSON_CONTACT_TYPE);
      communicationConsentType = (mlovData?.data?.mlovs || []).filter((mlov: any) => mlov?.category?.category === MLOV_CATEGORY.CONSENT);
      setStateData((prev)=>{
        return {
          ...prev,
          preferredCommunicationType: (mlovData?.data?.mlovs || []).filter((mlov: any) => mlov?.category?.category === MLOV_CATEGORY.PREFERRED_COMMUNICATION_CHANNEL),
          communicationConsentType: communicationConsentType,
          personContactTypeList: personContactTypeList,
        }
      })
    }
    const contactId = contextData?.contactId;
    if (contactId && !isCarePlanContext) {
      setComponentState((prev)=>{
        return {
          ...prev,
          loading: true
        }
      })
      getExistingData(contactId, communicationConsentType, personContactTypeList);
    } else {
      if (props?.component?.selectedValue) {
        setDefaultValues()
      }
      setComponentState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const mapConsentValue = (consentData: any[])=> {
        return consentData?.map((consentObj)=>{
      return stateData?.communicationConsentType?.find((consent:any)=>{
        return consent?.id === consentObj?.id;
      })
    })
  }

  const updateConsentIds = (isChecked: boolean, type: string)=> {
          setComponentValue((prev)=>{
        const consentId = stateData?.communicationConsentType.filter((consent:any)=>{
          return consent.code === type
        })
        let consentIds: any[] = prev?.consentIds;
        if (consentId?.length > 0) {
          if (isChecked) {
            consentIds = [...(prev?.consentIds || []), ...consentId]
          } else {
              consentIds = prev?.consentIds.filter((consent)=>{
              return consent?.id !== consentId[0].id;
            })
          }
        }
        return {
          ...prev,
          consentIds: mapConsentValue(consentIds)
      }
    })
  }

  const onEmailConsentChange = (isChecked: boolean) => {
    setConsentList(prev => {
      return {
        ...prev,
        consentToEmail: isChecked,
      };
    });
  };

  const onTextConsentChange = (isChecked: boolean) => {
    setConsentList(prev => {
      return {
        ...prev,
        consentToText: isChecked,
      };
    });
  };

  const onCallConsentChange = (isChecked: boolean) => {
    setConsentList(prev => {
      return {
        ...prev,
        consentToCall: isChecked,
      };
    });
  };

  const renderConsentToEmail = () => {
    return (
      <HStack flex={1} marginTop={2} key={'consentToEmail'}>
        <Checkbox
          checked={selectedConsent.consentToEmail}
          value={'consentToEmail'}
          onChange={(e) => {
          const isChecked = e.target.checked;
          onEmailConsentChange(isChecked);
          updateConsentIds(isChecked, 'EMAIL')
        }}>
          <Text fontSize={14} fontWeight={400}>
            {` Consent To Email`}
          </Text>
        </Checkbox>
      </HStack>
    );
  };

  const renderConsentToText = () => {
    return (
      <HStack flex={1} marginTop={2} key={'consentToText'}>
        <Checkbox
          checked={selectedConsent.consentToText}
          value={'consentToText'}
          onChange={(e) => {
          const isChecked = e.target.checked;
          onTextConsentChange(isChecked);
          updateConsentIds(isChecked, 'SMS')
        }}>
          <Text fontSize={14} fontWeight={400}>
            {` Consent To Text`}
          </Text>
        </Checkbox>
      </HStack>
    );
  };

  const renderConsentToCall = () => {
    return (
      <HStack flex={1} marginTop={2} key={'consentToCall'}>
        <Checkbox
          checked={selectedConsent.consentToCall}
          value={'consentToCall'}
          onChange={(e) => {
          const isChecked = e.target.checked;
          onCallConsentChange(isChecked);
          updateConsentIds(isChecked, 'VOICE')
        }}>
          <Text fontSize={14} fontWeight={400}>
            {` Consent To Call`}
          </Text>
        </Checkbox>
      </HStack>
    );
  }

  const getExistingData = (contactId: string, communicationConsentType: IMlov[], personContactTypeList: any) => {
    getContactDetails({
      variables: {
        contactId,
      },
    })
      .then((response) => {
        if (response?.data?.contacts?.length) {
          const detail = response.data.contacts[0];
          const emailData = detail?.personContact?.find((contact: any) => {
            return contact?.personContactType?.code === 'email';
          });
          const phoneData = detail?.personContact?.find((contact: any) => {
            return contact?.personContactType?.code === 'phone';
          });
          const contactList = response?.data?.personContact || [];
          const secondaryEmailObj = getEmailContactObjFromList(
            contactList,
            personContactTypeList,
            2
          );
          const secondaryPhoneObj = getPhoneContactObjFromList(
            contactList,
            personContactTypeList,
            2
          );
          const secondaryEmail = secondaryEmailObj ? secondaryEmailObj?.value : '';
          const secondaryPhone = secondaryPhoneObj ? secondaryPhoneObj?.value : '';
          const consent = detail?.contactConsents?.length ? true : false
          const consentIds = detail?.contactConsents || [];
          const preferredMode = detail?.contactPreferredCommunicationChannel[0]
          setStateData((prev)=>{
            return {
              ...prev,
              email: emailData?.value || detail.email,
              phone: unmaskPhoneNumber(
                phoneData?.value || detail.phoneNumber || ''
              ),
              secondaryEmail: secondaryEmail,
              secondaryPhone: secondaryPhone,
              consentChk: consent,
            }
          })
          setDefaultContactConsent(detail?.contactConsents|| [],communicationConsentType);
          setComponentValue((prev)=>{
            return {
              ...prev,
              consentIds: consentIds.map((item: any) => {
                return communicationConsentType.find(type => type.id === item.consentId);
              })?.filter((item: IMlov) => !!item),
              preferredMode: preferredMode
            }
          });
        }
        setComponentState((prev) => ({
          ...prev,
          loading: false,
          formError: !response?.data?.contacts
            ? FormError.existingDataAPIFail
            : prev.formError,
        }));
      })
      .catch((error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          formError: FormError.existingDataAPIFail,
        }));
      });
  };

  const getConsentText = (key: string)=> {
    if (key === CONSENT_CODE.VOICE) {
      return CONSENT_CODE.CALL
    } else if (key === CONSENT_CODE.SMS) {
      return CONSENT_CODE.TEXT
    }
    return key;
  }

  const getMemberConsentDisplayText = () => {
    switch(consentType) {
      case CONSENT_TYPE_CODES.COMMUNICATION: return 'memberFacingConsents';
      case CONSENT_TYPE_CODES.CCM: return 'memberFacingCCMConsent';
      case CONSENT_TYPE_CODES.TCM: return 'memberFacingTCMConsent';
      case CONSENT_TYPE_CODES.ECM: return 'memberFacingECMConsent';
      case CONSENT_TYPE_CODES.CARE_PROGRAM: return 'memberFacingCareProgramConsent';
      default: return 'memberFacingConsents';
    }
  }

  const getPreviewData = ()=> {
    const previewData: {
      id: string;
      key: string;
      value?: string;
      code: string;
    }[] = [];
    if (componentValue.preferredMode?.preferredModeId) {
      previewData.push({
        id: uuidv4(),
        key: `${intl.formatMessage({id: 'preferredCommunicationMode'})}`,
        value: componentValue.preferredMode?.preferredModeValue,
        code: 'communication'
      })
    }
    if (showSingleConsent) {
      const previewItem: {
        id: string;
        key: string;
        code: string;
        value?: any;
      } = {
        id: uuidv4(),
        key: intl.formatMessage({id: getMemberConsentDisplayText()}),
        code: 'consent',
        value: componentValue?.consents?.[componentValue?.consentType || ''] ? true : false
      };
      previewData.push(previewItem)
    } else {
      componentValue?.consentIds?.forEach((consentId)=>{
        const key = consentId?.value
        const previewItem: {
          id: string;
          key: string;
          code: string;
          value?: any;
        } = {
          id: uuidv4(),
          key: `Consent To ${getConsentText(key)}`,
          code: 'consent',
          value: true
        };
        previewData.push(previewItem)
      })
    }
    return previewData;
  }

  useEffect(()=>{
    fetchInitialData();
  }, [])

  useEffect(()=>{
    if (consentType !== componentValue.consentType) {
      setComponentValue((prev) => ({...prev, consentType}));
    }
  }, [consentType])

  useEffect(() => {
    props.onChange(cloneDeep(componentValue));
  }, [componentValue]);

  const validateData = (currentData: IPatientConsentAndCommunicationValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return {isValid: true, message: ''};
    }
    if (props.component?.validate?.required) {
      setComponentState((prev) => ({...prev, showErrors: true}));
      let isValid = true;
      isValid &&= !!currentData?.consentIds?.length;
      return { isValid: isValid, message: !isValid ? `${props.component.label}: Please fill all the mandatory fields` : '' };
    }
    return {isValid: true, message: ''};
  };

  props.validateRef.current = validateData;

  const setDefaultValues = ()=> {
    if (!ehrConfig.isAthena && componentValue?.consentIds?.length) {
      setStateData((prev)=>{
        return {
          ...prev,
          consentChk: true
        }
      })
    } else {
      componentValue?.consentIds?.forEach((consentId)=>{
        if(consentId?.code === PREFERRED_CHANNEL_CODE.EMAIL) {
          setConsentList(prev => {
            return {
              ...prev,
              consentToEmail: true,
            };
          });
        } else if (consentId?.code === PREFERRED_CHANNEL_CODE.VOICE){
          setConsentList(prev => {
            return {
              ...prev,
              consentToCall: true,
            };
          });
        } else if (consentId?.code === PREFERRED_CHANNEL_CODE.SMS) {
          setConsentList(prev => {
            return {
              ...prev,
              consentToText: true,
            };
          });
        }
      })
    }
  }

  if (isPreviewMode) {
    const data = getPreviewData();
    if (data?.length) {
      return (
        <div className={` ${isAllowToShare ? '' : 'disallow-to-share'}`}>
          {!componentState.formError && (
            <>
              <DetailPreview titleLocalId={props.component.label}>
                <>
                  {data?.map((item)=>{
                    return (
                      <div className="page-break">
                        {item?.code === 'communication' ? <>
                          {false && <HStack mb={1} key={item.id} flex={1}>
                            <Text flex={3}>{item.key}</Text>
                            <Text flex={7}>
                              {item.value}
                            </Text>
                          </HStack>}
                        </> :
                        <HStack space={2}>
                          {/* <AntDesign
                            size={20}
                            name={'checkcircleo'}
                            color={Colors.Custom.PrimaryColor}
                          /> */}
                          <Text flex={3}>{item.key} - {item.value ? 'Yes' : 'No'}</Text>
                        </HStack>}
                      </div>
                    )
                  })}
                </>
              </DetailPreview>
            </>
          )}
        </div>
      );
    }
    return <DetailPreview titleLocalId={props.component.label}>
      <div className="page-break">
        <Text>No consent given</Text>
      </div>
    </DetailPreview>;
  }

  return (
    <>
      {componentState.loading && <FormComponentLoader />}
      {!componentState.loading && componentState.formError && (
        <FormComponentError error={componentState.formError} />
      )}
      {!componentState.loading && <VStack mt={2} space={4}>
        <VStack space={2}>
          {false && <Text flex={1} size={'smMedium'} fontWeight={400}>
            {`${intl.formatMessage({id: 'preferredCommunicationMode'})}`}
          </Text>}
         {false && <View flex={1}>
            <Radio.Group
              value={
                deSelectRadio() && componentValue?.preferredMode
                  ? componentValue?.preferredMode?.preferredModeId
                  : null
              }
              name="PreferredChannel"
              onChange={(nextValue: any) => {
                setComponentValue((prev: any) => {
                  const selectedMode = stateData?.preferredCommunicationType.filter((communicationType: any)=>{
                    return communicationType?.id === nextValue?.target?.value
                  })
                  return {
                    ...prev,
                    preferredMode: {
                      preferredModeId: selectedMode[0]?.id,
                      preferredModeValue: selectedMode[0]?.value,
                    },
                  };
                });
              }}
            >
              {stateData?.preferredCommunicationType?.map((item: any) => {
                  return (
                    <Radio
                      checked={stateData?.isUnChecked}
                      disabled={isRadioDisable(item)}
                      key={item.id}
                      value={item.id}
                    >
                      {item.value === 'Voice' ? 'Phone' : item.value}
                    </Radio>
                  );
                })}
            </Radio.Group>
          </View>}
        </VStack>
        <VStack mb={2} space={2}>
          {showSingleConsent && (
            <HStack>
              <Checkbox
                checked={stateData?.consentChk}
                value={'consents'}
                disabled={stateData.consentAlreadyGiven}
                onChange={(e) => {
                  const isConsentGiven = !stateData?.consentChk
                  if (consentType === CONSENT_TYPE_CODES.COMMUNICATION) {
                    let consentIds: any[] = [];
                    if (isConsentGiven) {
                      consentIds = stateData?.communicationConsentType || [];
                    } else {
                      consentIds = []
                    }
                    setComponentValue((prev)=>{
                      return {
                        ...prev,
                        consentIds: mapConsentValue(consentIds)
                      }
                    })
                  } else {
                    setComponentValue((prev)=>{
                      const consents = { [consentType]: isConsentGiven };
                      return { ...prev, consents, };
                    });
                  }
                  setStateData((prev)=>{
                    return {
                      ...prev,
                      consentChk: isConsentGiven,
                    }
                  })
              }}>
                <DisplayText
                  size={'smMedium'}
                  extraStyles={{
                    fontWeight: 400,
                  }}
                  textLocalId={getMemberConsentDisplayText()}
                />
              </Checkbox>
            </HStack>
          )}
          {ehrConfig.isAthena && (!consentType || consentType === CONSENT_TYPE_CODES.COMMUNICATION) && (
            <View marginTop={2}>
              {renderConsentToEmail()}
              {renderConsentToText()}
              {renderConsentToCall()}
            </View>
          )}
        </VStack>
      </VStack>}
    </>
  );
}

export default ConsentAndPreferredCommunication;
