import {Popover, Divider} from 'antd';
import React from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {Text} from 'native-base';
import Stack from '../LayoutComponents/Stack';
import CheckGreenCircleSvg from '../Svg/CheckGreenCircleSvg';
import {antStyles} from '../../RightSideContainer/CareManagementView/CareManageMentViewStyles';
import {Colors} from '../../../styles';
import ArrowRightFillSvg from '../Svg/ArrowRightFillSvg';
import CareProgramConsent from './CareProgramConsent';
import {useIntl} from 'react-intl';
import CheckCircleSvg from '../Svg/CheckCircleSvg';
import {
  IConsentPopoverState,
  IPatientCareProgramConsentViewProps,
} from './interface';
import {useCareProgramConsent} from './CustomHooks/useCareProgramConsent';
import {CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE} from '../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import QuestionCircleSvg from '../Svg/QuestionCircleSvg';
import {ToastType} from '../../../utils/commonViewUtils';
import {showToast} from '../../../utils/commonViewUtils';
import {useCustomToast} from '../../Toast/ToastProvider';
import CustomConfirmationModal from '../CustomConfirmationModal/CustomConfirmationModal';
import WarningSvgV2 from '../Svg/WarningSvgV2';
import {getDateStrFromFormat} from '../../../utils/DateUtils';
import {DATE_FORMATS} from '../../../constants/StringConst';
import {CARE_PROGRAM_CONSENT_TYPE} from '../../../constants/MlovConst';
import {getMlovIdFromCode} from '../../../utils/mlovUtils';
import {getMlovListByCategory} from '../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../constants/MlovConst';
import DeclinedCircleSvg from '../Svg/DeclinedCircleSvg';
import RevokeConsentReasonList from './RevokeConsentReasonList';
import { EventBus } from '../../../utils/EventBus';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../constants/WebSocketConst';

const PatientCareProgramConsentView = (
  props: IPatientCareProgramConsentViewProps
) => {
  const intl = useIntl();
  const customToast = useCustomToast();
  const [state, setState] = React.useState<IConsentPopoverState>({
    visiblePopover: false,
    showConsentDrawer: false,
    showRevokeConfirmation: false,
    selectedConsentTypeId: undefined,
    hasConsent: undefined,
    updatedOn: undefined,
    revokeReasonId: undefined,
    revokeImpactDate: undefined,
  });

  const consentTypeList = getMlovListByCategory(
    MLOV_CATEGORY.CONTACT_CARE_PROGRAM_CONSENT_TYPE
  );
  const declineConsentTypeId = getMlovIdFromCode(
    consentTypeList,
    CARE_PROGRAM_CONSENT_TYPE.DECLINED
  );

  const isConsentDeclined =
    props.careProgramConsentData?.typeId === declineConsentTypeId;

  const subjectId =
    props.subjectTypeCode ===
      CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT_CARE_PROGRAM &&
    props?.contactCareProgramId
      ? props?.contactCareProgramId
      : props?.contactId;

  const subjectTypeCode =
    props.subjectTypeCode ===
    CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT_CARE_PROGRAM
      ? CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT_CARE_PROGRAM
      : CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT;

  const {getCareProgramConsentData, addOrUpdateConsent} =
    useCareProgramConsent();

  const handleVisibleChange = (visible: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        visiblePopover: visible,
      };
    });
  };

  const handleConsentDrawer = (visible: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        showConsentDrawer: visible,
        visiblePopover: false,
      };
    });
  };

  const fetchConsentData = async () => {
    try {
      const response = await getCareProgramConsentData({
        subjectId: subjectId,
        subjectTypeCode: subjectTypeCode,
      });
      setState((prev) => {
        return {
          ...prev,
          selectedConsentTypeId: response?.typeId,
          hasConsent: response?.hasConsent,
          updatedOn: response?.updatedOn,
        };
      });
    } catch (error) {
      showToast(
        customToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
        3000,
        true
      );
    }
  };

  const fetchInitialConsentData = async () => {
    if (props.careProgramConsentData?.id) {
      setState((prev) => {
        return {
          ...prev,
          selectedConsentTypeId: props.careProgramConsentData?.typeId,
          hasConsent: props.careProgramConsentData?.hasConsent,
          updatedOn: props.careProgramConsentData?.updatedOn,
          revokeImpactDate: props.careProgramConsentData?.revokeImpactDate,
        };
      });
    } else {
      fetchConsentData();
    }
  };
  const onRemoveReasonSelect  = (revokeReasonId: string) => {
    setState((prev) => {
      return {
        ...prev,
        revokeReasonId: revokeReasonId,
      };
    });
  }
  
  const modalSubContent = React.useMemo(() => (
    <RevokeConsentReasonList 
      onRemoveReasonSelect={onRemoveReasonSelect} 
    />
  ), [onRemoveReasonSelect]);

  const revokeConsent = async () => {
    try {
      handleConfirmationModal(false);
      const response = await addOrUpdateConsent({
        subjectId: subjectId,
        subjectTypeCode: subjectTypeCode,
        consentTypeId: state.selectedConsentTypeId || '',
        hasConsent: false,
        revokeReasonId: state.revokeReasonId,
      });
      if (response?.data) {
        setState((prev) => {
          return {
            ...prev,
            hasConsent: false,
          };
        });
      }
      if (props.onAddOrUpdateConsent) {
        props.onAddOrUpdateConsent();
      }
      showToast(
        customToast,
        intl.formatMessage({id: 'consentRevokeSuccessMsg'}),
        ToastType.success,
        3000,
        true
      );
    } catch (error) {
      showToast(
        customToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
        3000,
        true
      );
    }
  };

  const handleConfirmationModal = (value: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        showRevokeConfirmation: value,
        visiblePopover: false,
        revokeReasonId: undefined,
      };
    });
  };

  const getUpdatedOnText = (updatedOn: string) => {
    return `${
      state.hasConsent
        ? 'Received on'
        : state.selectedConsentTypeId === declineConsentTypeId
        ? 'Declined on'
        : 'Revoked on'
    } ${getDateStrFromFormat(updatedOn, DATE_FORMATS.REPORT_DATE_FORMAT)}`;
  };
  
  const getRevokeImpactDateText = (revokeImpactDate: string) => {
    return `${intl.formatMessage({
      id: 'revocationEffectiveFrom',
    })} ${getDateStrFromFormat(
      revokeImpactDate,
      DATE_FORMATS.REPORT_DATE_FORMAT
    )}`;
  };

  const handleConsentUpdate = (event: any) => {
    if (event?.contactId === props.contactId) {
      fetchConsentData();
    }
  };

  React.useEffect(() => {
    fetchInitialConsentData();
  }, [props.contactCareProgramId, props.contactId, state.visiblePopover]);

  React.useEffect(()=> {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(CONVERSATION_LOCAL_EVENT_CODES.CARE_PROGRAM_CONSENT_UPDATED, handleConsentUpdate);
    return () => {
      eventBus.removeEventListener(handleConsentUpdate);
    };
  }, [props.contactId])

  const content = (
    <Stack direction="row" style={styles.container}>
      <Stack direction="column">
        <Stack direction="row" style={styles.titleContainer}>
          <Text style={styles.titleText}>
            {intl.formatMessage({id: 'consentMessage'})}
          </Text>
          {state.selectedConsentTypeId === declineConsentTypeId ? (
            <DeclinedCircleSvg />
          ) : (
            <CheckGreenCircleSvg
              customStrokeColor={
                state.hasConsent
                  ? Colors.Custom.SuccessColor
                  : Colors.Custom.Gray200
              }
            />
          )}
        </Stack>
        {state.updatedOn && (
          <Text style={styles.timestamp}>
            {getUpdatedOnText(state.updatedOn)}
          </Text>
        )}
        {state.revokeImpactDate &&
          state.hasConsent !== undefined &&
          !state.hasConsent && (
            <Text style={styles.timestamp}>
              {getRevokeImpactDateText(state.revokeImpactDate)}
            </Text>
          )}
      </Stack>

      <Stack direction="row" style={styles.rightContent}>
        <Pressable
          onPress={() => handleConsentDrawer(!state.showConsentDrawer)}
        >
          <Stack direction="row" style={styles.viewButton}>
            <Text style={styles.viewText}>
              {intl.formatMessage({
                id: state.hasConsent ? 'view' : 'getConsent',
              })}
            </Text>
            <ArrowRightFillSvg
              customStrokeColor={Colors.Custom.Primary300}
              width={8}
              height={8}
            />
          </Stack>
        </Pressable>
        {state.hasConsent && (
          <>
            <Divider
              type="vertical"
              orientationMargin={8}
              style={antStyles.dividerStyle}
            />
            <Pressable onPress={() => handleConfirmationModal(true)}>
              <Text style={styles.revokeText}>
                {intl.formatMessage({id: 'revoke'})}
              </Text>
            </Pressable>
          </>
        )}
      </Stack>
    </Stack>
  );

  return (
    <View>
      <Popover
        content={content}
        overlayClassName="contact-popover"
        trigger="click"
        placement="bottomLeft"
        visible={state.visiblePopover}
        onVisibleChange={() => handleVisibleChange(false)}
        showArrow={false}
        align={{
          offset: [0, -10],
        }}
        overlayInnerStyle={antStyles.popoverOverlayInnerStyle}
      >
        <Pressable disabled={props.disableActions} onPress={() => handleVisibleChange(true)}>
          {props.displayContent ? (
            props.displayContent
          ) : (
            <Stack direction="row" style={styles.triggerContent}>
              <Text style={styles.triggerText}>
                {intl.formatMessage({id: 'consent'})}
              </Text>
              {state.hasConsent ? <CheckCircleSvg /> : <QuestionCircleSvg />}
            </Stack>
          )}
        </Pressable>
      </Popover>
      {state.showConsentDrawer && (
        <CareProgramConsent
          isVisible={state.showConsentDrawer}
          contactId={props.contactId}
          contactCareProgramId={props.contactCareProgramId}
          onClose={() => handleConsentDrawer(false)}
          subjectTypeCode={props.subjectTypeCode}
          onAddOrUpdateConsent={() => {
            fetchConsentData();
            if (props.onAddOrUpdateConsent) {
              props.onAddOrUpdateConsent();
            }
          }}
          isSidecarContext={props.isSidecarContext}
        />
      )}
      {state.showRevokeConfirmation && (
        <CustomConfirmationModal
          isOpen={state.showRevokeConfirmation}
          headerText={'revokeConsentHeaderText'}
          message={'revokeConsentMsg'}
          customCancelBtnText={'Discard'}
          customOkBtnText={'Revoke Consent'}
          width={350}
          customIcon={
            <WarningSvgV2 strokeColor={Colors.Custom.crossIconColor} />
          }
          onConfirm={() => {
            revokeConsent();
          }}
          revokeReasonId={state.revokeReasonId}
          disabled={!state.revokeReasonId}
          modalSubContent={modalSubContent}
          onCancel={() => {
            handleConfirmationModal(false);
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 300,
  },
  titleContainer: {
    alignItems: 'center',
  },
  titleText: {
    fontSize: 14,
    color: Colors.Custom.Gray500,
    paddingRight: 4,
  },
  timestamp: {
    fontSize: 12,
    color: Colors.Custom.Gray400,
  },
  rightContent: {
    alignItems: 'center',
    paddingLeft: 16,
  },
  viewButton: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
  },
  viewText: {
    fontSize: 12,
    color: Colors.Custom.Primary300,
  },
  revokeText: {
    fontSize: 12,
    color: Colors.Custom.crossIconColor,
  },
  triggerContent: {
    alignItems: 'center',
    height: 20,
    backgroundColor: Colors.Custom.Gray100,
    paddingHorizontal: 8,
    marginRight: 12,
    marginTop: 4,
    borderRadius: 4,
  },
  triggerText: {
    color: Colors.Custom.Gray500,
    fontSize: 12,
    fontWeight: '400',
    paddingRight: 4,
  },
});

export default React.memo(PatientCareProgramConsentView);
