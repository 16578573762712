import React from 'react';
import {styles, reactStyles} from './styles';
import {Text} from 'react-native';
import DotIconSvg from '../../../../../Svg/DotIconSvg';
import {Pressable} from 'native-base';
import {Colors} from '../../../../../../../styles/Colors';
import AngleDownSvg from '../../../../../Svg/DayOptimization/AngleDownSvg';
import AngleUpSvg from '../../../../../Svg/DayOptimization/AngleUpSvg';
import Divider from '../../../../../CustomDrawer/Divider';
import {IEventContainerProps} from '../../../interfaces';

import {v4} from 'uuid';
import useEventContainer from './useEventContainer';
import { testID, TestIdentifiers } from '../../../../../../../testUtils';

const EventContainer = (props: IEventContainerProps) => {
  const {
    showNotification,
    getRibbonIcon,
    getRibbonDescription,
    getRibbonActionButtonsList,
  } = useEventContainer(props);

  let eventContainerClasses = '';
  if (props?.isFirst && showNotification) {
    eventContainerClasses += ' newNotification';
  }
  if (!props?.ribbonOpen && props?.isFirst) {
    eventContainerClasses += ' bg-highlight';
  } else {
    eventContainerClasses += ' bg-white';
  }

  return (
    <div
      className={`event-container ${eventContainerClasses}`}
    >
      <div
        className={`event-container-controller ${
          props?.isFirst ? '' : 'invisible'
        }`}
      >
        <Pressable
          onPress={props?.toogleRibbonOpen}
          style={styles.eventContainer}
          {...testID(TestIdentifiers.DO_eventShowHideToggleBtn)}
        >
          {props?.totalEvents > 1 && (props?.ribbonOpen ? <AngleUpSvg /> : <AngleDownSvg />)}
          <span className="event-container-badge">
            <Text
              style={{
                fontSize: 10,
                fontWeight: '500',
                color: Colors.Custom.White,
                lineHeight: 12,
              }}
              {...testID(TestIdentifiers.DO_eventTotalCountBadge)}
            >
              {props?.totalEvents}
            </Text>
          </span>
        </Pressable>
      </div>
      {props?.isFirst ? (
        <Divider
          height={16}
          width={0.5}
          marginHorizontal={0}
          backgroundColor={Colors.FoldPixel.GRAY150}
        />
      ) : (
        <span style={reactStyles.w0p5}></span>
      )}

      <div className="event-item">
        <div className="event-item-content">
          {getRibbonIcon()}
          <Text 
            style={styles.text14gray300fw400} 
            {...testID(TestIdentifiers.DO_eventDescription)}
          >
            {getRibbonDescription()}
          </Text>
          {/* <DotIconSvg width={6} /> */}
        </div>

        <div className="event-item-controlls">
          {getRibbonActionButtonsList().map((button, index, buttonsList) => (
            <React.Fragment key={`dayOpRibbonActionButton-${v4()}`}>
              {button}
              {index < buttonsList.length - 1 && (
                <Divider
                  height={16}
                  width={0.5}
                  marginHorizontal={0}
                  backgroundColor={Colors.FoldPixel.GRAY150}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventContainer;
