import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../../styles';

export const styles = StyleSheet.create({
  width100: {
    width: '100%',
  },
  paddingVertical12PaddingHorizontal12: {
    paddingVertical: 12,
    paddingHorizontal: 12,
  },
  height40: {
    height: '40px',
  },
  noMedicationsText: {
    marginTop: 16,
    marginBottom: 8,
    fontSize: 16,
    color: Colors.FoldPixel.GRAY300,
    textAlign: 'center',
  },
  flex1: {
    flex: 1,
  },
  continueButton: {
    height: 32,
    borderColor: Colors.Custom.Gray300,
    borderWidth: 0.5,
    marginRight: 8,
    padding: 8,
  },
  stopButton: {
    height: 32,
    backgroundColor: Colors.FoldPixel.PRIMARY100,
    padding: 8,
    borderWidth: 0.5,
    borderColor: Colors.Custom.PrimaryColor200,
  },
  noteContainer: {
    backgroundColor: Colors.FoldPixel.GRAY50,
    borderColor: Colors.FoldPixel.GRAY100,
    borderWidth: 0.5,
    paddingLeft: 4,
    paddingRight: 6,
    paddingVertical: 2,
    borderRadius: 4,
    width: 'fit-content',
    marginTop: 8,
  },
  subHeader: {
    fontSize: 12,
    color: Colors.FoldPixel.GRAY200,
    fontWeight: '400',
  },
  noteText: {
    fontSize: 12,
    color: Colors.FoldPixel.GRAY200,
    fontStyle: 'italic',
    fontWeight: '400',
  },
  addNoteIconButton: {
    padding: 0,
    height: 20,
    width: 90,
  },
  listViewContainer: {
    borderColor: Colors.Custom.Gray300,
    borderWidth: 0.5,
    borderRadius: 8,
    overflow: 'hidden',
    marginVertical: 12,
  },
  justifyContentFlexStart: {
    justifyContent: 'flex-start',
  },
  alignItemsCenter: {alignItems: 'center'},
  padding12: {padding: 12},
  opacity05: {opacity: 0.5, padding: 12},
  flex1MarginRight12: {flex: 1, marginRight: 12},
  padding8margin0: {padding: 8, margin: 0},
  flex1PaddingHorizontal12PaddingTop12: {flex: 1, paddingHorizontal: 12},
  addNewMedicationButton: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: 4,
    gap: 4,
  },
  addNewMedicationButtonText: {
    color: Colors.Custom.Primary300,
    fontSize: 14,
    fontWeight: '500',
  },
  addNewMedicationButtonDisabled: {
    color: Colors.Custom.primaryLight,
  },
});

export const reactStyles: Record<string, React.CSSProperties> = {
  datePickerStyle: {
    height: '40px',
    borderRadius: 4
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  disabled: {
    display: 'none'
  }
}
