import { useLazyQuery } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { GET_CARE_PROGRAM_BILLABLE_CODES } from '../../../../../CareManagementBilling/BillingQueries';
import { useContactCareProgramContext } from '../../../ContactCareProgram.context';
import { IContactCareProgramBillableActivity } from './BillingInterface';
import { getMlovIdFromCode, getMlovListFromCategory, getMlovValueFromId } from '../../../../../../../utils/mlovUtils';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import { MLOV_CATEGORY } from '../../../../../../../constants';
import { BILLING_ACTIVITY_STATUS } from '../../../../../../../constants/MlovConst';
import { getMonthRangeFromDate } from '../../../../../../../utils/DateUtils';
import UserQueries from '../../../../../../../services/User/UserQueries';
import { useCPEventHandlers } from '../../../../useCPEventhandler';
import { CARE_PROGRAM_EVENT_CODES } from '../../../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import { getRoundedValue } from '../../../../../../../utils/commonUtils';

interface IContactCareProgramBillingHookState {
  attributesAndCodes: IContactCareProgramBillableActivity[];
  totalBilling: number;
  careManagerName: string;
}


export const useContactCareProgramBilling = (params: {
  selectedDate?: string;
  showOnlyCompleted?: boolean;
}) => {
  // Constants
  const {state} = useContactCareProgramContext();
  const contextData = useContext(CommonDataContext);
  const billableActivityStatusMlov = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CONTACT_CARE_PROGRAM_BILLABLE_ACTIVITY_STATUS) || [];
  const pendingStatusId = getMlovIdFromCode(billableActivityStatusMlov, BILLING_ACTIVITY_STATUS.PENDING);
  const completedStatusId = getMlovIdFromCode(billableActivityStatusMlov, BILLING_ACTIVITY_STATUS.COMPLETED);

  // States
  const [hookState, setHookState] = useState<IContactCareProgramBillingHookState>({
    attributesAndCodes: [],
    totalBilling: 0,
    careManagerName:''
  });

  // Hooks

  const [getUsersByUserUuidsAPI] = useLazyQuery(UserQueries.GetUsersByUserUuids, {
    fetchPolicy: 'no-cache',
  });

  const [getBillAttributeAndCodes, { loading: attributeAndCodesLoading }] = useLazyQuery(GET_CARE_PROGRAM_BILLABLE_CODES, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onCompleted: async (data) => {
      const billableActivities: IContactCareProgramBillableActivity[] = data?.contactCareProgramBillableActivities || [];
      let totalBilling = 0;
      billableActivities.forEach((activity) => {
        const amountValue = activity.billableActivityCptCodes.reduce((acc, curr) => acc + curr.careProgramCptCode.amount, 0);
        activity.totalDollars = getRoundedValue(amountValue);
        activity.durationInMinutes =  activity.billableActivityCptCodes.reduce((acc, curr) => acc + curr.careProgramCptCode.durationInMinutes, 0);
        activity.cptCodes = activity.billableActivityCptCodes.map((cpt) => cpt.careProgramCptCode.cptCode);
        activity.status = getMlovValueFromId(billableActivityStatusMlov, activity.statusId);
        activity.performedByUserId = activity.performedByUserId;
        totalBilling += activity.totalDollars;
        if (activity?.billableActivityEntities?.[0]?.modifiers) {
          activity.modifiers = activity.billableActivityEntities[0].modifiers;
        }
      });

      const performedByUserIds = [...new Set(billableActivities.map((activity) => activity.performedByUserId))];
      const performedByUserResponse = await getUsersByUserUuidsAPI({
        variables: {
          userUuids: performedByUserIds
        }
      });
      const careManagerName = performedByUserResponse?.data?.accountUsers?.[0]?.user?.name ?? '-';
      const updatedBillableActivities = billableActivities.map((activity) => {
        const performedByUser =
          performedByUserResponse?.data?.accountUsers?.find(
            (data: any) => data?.user?.uuid === activity?.performedByUserId
          );
        if (performedByUser?.user?.id) {
          activity.performedByUser = performedByUser?.user;
        }
        return activity;
      });
      setHookState((prev) => {
        return {
          ...prev,
          attributesAndCodes: updatedBillableActivities,
          totalBilling: getRoundedValue(totalBilling),
          careManagerName: careManagerName 
        }
      })
    }
  });


  const refreshBillingData = useCallback(() => {
    getBillAttributeAndCodes({
      variables: {
        contactCareProgramId: state.contactCareProgramDetails?.id,
        selectedDate: params.selectedDate,
        showOnlyCompleted: params.showOnlyCompleted
      }
    });
  }, [state.contactCareProgramDetails?.id, params.selectedDate, params.showOnlyCompleted]);

  useCPEventHandlers({
    listenCode: `billing-listen-${state.contactCareProgramDetails?.id}`,
    syncCode: `billing-sync-${state.contactCareProgramDetails?.id}`,
    onSync: (eventType) => {
      if (eventType === CARE_PROGRAM_EVENT_CODES.BILLING_DATA_CHANGED) {
        refreshBillingData();
      }
    }
  });

  // Side Effects
  useEffect(() => {
    if (state.contactCareProgramDetails?.id) {
      const monthRange = getMonthRangeFromDate(params.selectedDate);
      getBillAttributeAndCodes({
        variables: {
          where: {
            isDeleted: {
              _eq: false
            },
            statusId: {
              _in: params.showOnlyCompleted ? [completedStatusId] : [pendingStatusId, completedStatusId]
            },
            contactCareProgramId: {
              _eq: state.contactCareProgramDetails?.id
            },
            timestamp: {
              _gte: monthRange.startDate,
              _lte: monthRange.endDate
            }
          }
        }
      });
    }
  }, [state.contactCareProgramDetails?.id, params.selectedDate]);

  return {
    hookState,
    attributeAndCodesLoading,
  }

};
