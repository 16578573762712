import { useContext, useEffect } from "react";
import { useState } from "react";
import { CommonDataContext } from "../../../../../context/CommonDataContext";
import { useLazyQuery } from "@apollo/client";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../../constants/Configs";
import { CARE_PROGRAM_TYPES } from "../../../../../constants/MlovConst";
import ContactCareProgram from "../../../../../services/ContactCareProgram/ContactCareProgram";
import { getMlovIdFromCode, getMlovListFromCategory } from "../../../../../utils/mlovUtils";
import { MLOV_CATEGORY } from "../../../../../constants/MlovConst";
import { getWhereConditionForCareProgram } from "../../../CareManagementView/CareManagementUtils";
import { useCareProgramStatus } from "./useCareProgramStatus";


interface UseActiveCareProgramTypesProps {
  contactUuid?: string;
}

export const useActiveCareProgramTypes = ({ contactUuid }: UseActiveCareProgramTypesProps) => {
  const [careProgramTypesMlov, setCareProgramTypesMlov] = useState<any[]>([]);
  const [activeCareProgramData, setActiveCareProgramData] = useState<any[]>([]);
  const mlovData = useContext(CommonDataContext);
  const {careProgramStatus} = useCareProgramStatus();

  const [getContactCarePrograms] = useLazyQuery(
    ContactCareProgram.GET_CONTACT_CARE_PROGRAM_LIST_BY_CARE_PROGRAM_TYPE_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const fetchActiveCareProgramTypes = async () => {
    try {
      let programTypes = getMlovListFromCategory(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.CARE_PROGRAM_TYPES
      ) || [];

      programTypes = programTypes.filter((mlov) => {
        if (mlov.code === CARE_PROGRAM_TYPES.ECM) {
          return false;
        }
        return true;
      });

      if (contactUuid) {
        const careProgramWhereCondition = getWhereConditionForCareProgram(
          contactUuid,
          [],
          '',
          [
            careProgramStatus.inProgress,
            careProgramStatus.newStatus
          ],
        );
        const response = await getContactCarePrograms({
          variables: {
            careProgramWhereCondition: careProgramWhereCondition,
          },
        });
        const activePrograms = response?.data?.contactCarePrograms || [];
        const formattedProgramsData = activePrograms.map((program: any) => {
          return {
            careProgramTypeId: program.payerCareProgram.careProgramTypeId,
            contactCareProgramId: program.id,
            contactCareProgramAssigneeId: program?.assigneeId,
          };
        });
        setActiveCareProgramData(formattedProgramsData);

        programTypes = programTypes.filter((type) =>
          activePrograms.some((program: any) => program.payerCareProgram.careProgramTypeId === type.id)
        );
      }
      setCareProgramTypesMlov(programTypes);
    } catch (error) {
      setCareProgramTypesMlov([]);
    }
  };

  const getContactCareProgramId = (careProgramTypeId: string) => {
    return activeCareProgramData.find(
      (program: any) => program?.careProgramTypeId === careProgramTypeId
    )?.contactCareProgramId;
  };

  const getContactCareProgramAssigneeId = (careProgramTypeId: string) => {
    return activeCareProgramData.find(
      (program: any) => program?.careProgramTypeId === careProgramTypeId
    )?.contactCareProgramAssigneeId;
  };

  useEffect(() => {
    fetchActiveCareProgramTypes();
  }, [contactUuid, mlovData.CARE_STUDIO_MLOV]);

  return {
    careProgramTypesMlov,
    activeCareProgramData,
    getContactCareProgramId,
    getContactCareProgramAssigneeId,
    refetchCareProgramTypes: fetchActiveCareProgramTypes
  };
};
