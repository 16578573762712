import { useLazyQuery, useMutation } from '@apollo/client';
import { Alert, Drawer, Modal, notification, Radio, Select } from 'antd';
import { Observation, QuestionnaireItem } from 'fhir/r4';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import {
  Box, Button, Checkbox, CheckIcon,
  Divider,
  FormControl,
  HStack,
  Image,
  Pressable,
  ScrollView, Spinner,
  Text,
  TextArea,
  useMediaQuery,
  useToast,
  View,
  VStack
} from 'native-base';
import { useContext, useEffect, useRef, useState } from 'react';
import { default as AntIcon } from 'react-native-vector-icons/AntDesign';
import { useNavigate } from 'react-router-dom';
import { Consent } from '../../../../../../components/common/Consent/Consent';
import {
  BUTTON_TYPE,
  CONSENT_CONST,
  CONSENT_ERROR,
  CUSTOM_AUTOMATION_URL,
  DISPLAY_DATE_FORMAT,
  ENTITY_EVENTS_TITLE_SUBTITLE,
  ENTITY_EVENTS_TYPE,
  HTTP_ERROR_CODE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MLOV_CATEGORY,
  PERSON_TYPES,
  PREFERRED_CHANNEL_CODE,
  RIGHT_SIDE_CONTAINER_CODE,
  SEXUAL_ORIENTATION_OTHER_CODE
} from '../../../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../../../constants/ActionConst';
import { FHIR_RESOURCE, KEY_ALLOWED_OPERATIONS, RESOURCE_BLOCK_MESSAGE_ID } from '../../../../../../constants/FhirConstant';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { FlowType } from '../../../../../../context/WorkflowContext';
import { IMlov } from '../../../../../../Interfaces/CommonInterfaces';
import { ConvertContacts, PatientQueries } from '../../../../../../services';
import { CreatePatient, createPatientUsingIntegration } from '../../../../../../services/CommonService/AidBoxService';
import EmployeeQueries from '../../../../../../services/Employee/EmployeeQueries';
import { Colors } from '../../../../../../styles';
import {
  checkAbilityAccess,
  checkAccountConfigAbilityAccess,
  getAccountConfigResourceAbilities,
  getEHRName,
  getOrgAbilites,
  getPossibleValuesByOperationName,
  getResourceAbilities
} from '../../../../../../utils/capabilityUtils';
import {
  getAccountId,
  getAccountUUID,
  getUserId,
  getUserUUID,
  isContactConsentRequired,
  isEnableMarriageAnniversaryDate,
  isContactVerified,
  isEmployerRole,
  numericStringMask,
  isInsuranceEnable,
  getCaslAbility
} from '../../../../../../utils/commonUtils';
import {isCurrentDateInFutureComparedToOther, isFutureDate} from '../../../../../../utils/DateUtils';
import { getContactTypeId, getMlovCodeFromId, getMlovId, getMlovIdFromCode, getMlovListByCategoryCode, getMlovListFromCategory } from '../../../../../../utils/mlovUtils';
import { isWeb } from '../../../../../../utils/platformCheckUtils';
import { getWorkflowSelectedList } from '../../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import WorkFlowListByEntityEvents from '../../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/WorkFlowListByEntityEvents';
import LocationAutoComplete, { autoCompleteTypeCodes, ILocationSearch } from '../../../../../common/CalendarWidget/LocationAutoComplete/LocationAutoComplete';
import { CityAutoComplete } from '../../../../../common/City';
import CodeSearch from '../../../../../common/CodeSearch/CodeSearch';
import { CodeSearchType } from '../../../../../common/CodeSearch/CodeSearchConstants';
import { capitalizeText } from '../../../../../common/ContactRelationView/ContactRelationUtils';
import { DisplayText } from '../../../../../common/DisplayText/DisplayText';
import EmployerSelectOrAdd from '../../../../../common/EmployerSelectOrAdd/EmployerSelectOrAdd';
import { LanguageOptions } from '../../../../../common/LanguageOptions';
import { ModalActionCheckbox } from '../../../../../common/ModalActionCommonComponent/ModalActionCheckBox';
import { ModalActionDatePicker } from '../../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import { ModalActionInput } from '../../../../../common/ModalActionCommonComponent/ModalActionInput';
import { ModalActionSelect } from '../../../../../common/ModalActionCommonComponent/ModalActionSelect';
import { ModalActionSubTitle } from '../../../../../common/ModalActionTitle/ModalActionSubTitle';
import { ModalActionTitle } from '../../../../../common/ModalActionTitle/ModalActionTitle';
import { PractitionerSearch } from '../../../../../common/PractitionerSearch';
import { StateAutoComplete } from '../../../../../common/State';
import InfoSvg from '../../../../../common/Svg/InfoSvg';
import { WorkflowDrawer } from '../../../../../common/WorkflowDrawer';
import { ZipCodeAutoComplete } from '../../../../../common/ZipCodeComponent';
import { IZipCodeData } from '../../../../../common/ZipCodeComponent/interfaces';
import { getEhrConfig } from '../../../../../PersonOmniView/MiddleContainer/CareTimeline/CareTimelineUtils';
import { MAIN_MENU_CODES } from '../../../../../SideMenuBar/SideBarConst';
import HeightWeightInput from '../../../../../Vitals/HeightWeightInput';
import { CapabilityResource } from '../../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { USER_ACCESS_PERMISSION } from '../../../../UserAccess/UserAccessPermission';
import { CONTACT_ACTION_CODES } from '../../../ContactsConst';
import { checkMandatoryFields, getMissingFields } from '../../../ContactsUtils';
import ConfirmationModal from '../../../Visitor/ConvertContactsList/ConfirmationModal/ConfirmationModal';
import { ConvertLeadList } from '../../ConvertLeadList';
import { AddOrUpdateLead } from './AddOrUpdateLead';
import './AddOrUpdateLeadCss.css';
import { checkIfAgeIsLessThan18, getFormDataFromLeadData, getSelectedConsentMlov, isHeightOrWeightPending, sendPatientAppInvite } from './AddOrUpdateUtils';
import { InputStyle } from '../LeadViewStyle';
import { useIntl } from 'react-intl';
import CustomEHRAttributes from './components/CustomEHRAttributes/CustomEHRAttributes';
import { IEhrFieldList } from './components/CustomEHRAttributes/interface';
import ConfirmOnExitOrReload from '../../../../../common/ConfirmExitWindow/ConfirmOnExitOrReload';
import { ModalActionAntSelect } from '../../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import { withMiniContactViewHOC } from '../../../../../MiniContactViewHOC';
import { ToastType, showPopupNotification, showToast } from '../../../../../../utils/commonViewUtils';
import {AddCustomFieldContainer} from '../../../CustomField/AddCustomFieldContainer';
import {CUSTOM_ATTRIBUTE_DATA_SOURCE, ENTITY_TYPE} from '../../../CustomField/CustomFieldConst';
import {IFormattedCustomFieldsData} from '../../../CustomField/interface';
import {GET_CONSUMER_CUSTOM_CONTACT_TYPE} from '../../../CustomField/CustomAttributeQueries';
import { ILabelTag, ISelectedConsent } from '../../interfaces';
import HorizantalLineSvg from '../../../../../common/Svg/HorizantalLineSvg';
import TwoIconSvg from '../../../../../common/Svg/TwoIconSvg';
import OneIconSvg from '../../../../../common/Svg/OneIconSvg';
import InsuranceView from '../../../../../Insurance';
import { validateContactOrPatientDetails } from './AddOrUpdateQueryUtils';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import TagSearchAndSelect from './TagSearchAndSelect';
import FoldPermitCan from '../../../../../CommonComponents/FoldPermitCan/FoldPermitCan';
import useEHRCapabilities from '../../../../../../screens/BusinessStudio/useEHRCapabilities';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import { testID, TestIdentifiers } from '../../../../../../testUtils';
import { placeholder } from 'jodit/esm/plugins/placeholder/placeholder';
import { color } from 'jodit/esm/plugins/color/color';

interface IAddOrUpdateLeadView {
  isAddressLine1Required?: boolean;
  isMobileNumberRequired?: boolean;
  checkVitalsForLessThan18Age?: boolean;
  customFieldResponse?: QuestionnaireItem[];
  updateCustomFields?: (updatedFieldList: IEhrFieldList[]) => void;
  setInsuranceDataForRequestBody?: any;
  setSecInsuranceDataForRequestBody?: any;
  setTerInsuranceDataForRequestBody?:any
  isPractitionerDisable?: boolean;
}

 const AddOrUpdateLeadViewComponent = (props: any & IAddOrUpdateLeadView) => {
  const {
    isPatientCreationFlow,
    leadFormData,
    setLeadFormData,
    personTypeContact,
    showMore,
    showModal,
    onCloseDrawer,
    isSubmitting,
    formStatus,
    styles,
    alerts,
    errors,
    alertDuplicates,
    checkForExistingContactWithSameEmail,
    shouldCreateUsingIntegration,
    bloodGroupList,
    isGenderAtBirthRequired,
    genderAtBirthArray,
    isPractitionerRequired,
    practitionerLabel,
    personGender,
    mask,
    sendInvite,
    setSendInvite,
    labelData,
    selectedTagData,
    setFinalTagList,
    setSelectedTags,
    handleTagRemove,
    defaultPracticeLocation,
    practiceLocationObject,
    employers,
    setShowMore,
    isLegalSexRequired,
    headerName,
    isGenderIdentityHidden,
    genderAtBirthLabel,
    genderIdentityLabel,
    addressLine1MaxChar,
    addressLine2MaxChar,
    onPersonTypeChange,
    isShowAddContactTypeOption,
    isMobileNumberRequired,
    isPhoneOrEmailRequired,
    isAddressLine1Required,
    fieldsConfig,
    fieldKeys,
    handleVitalChange,
    checkVitalsForLessThan18Age,
    pronounList,
    isFetchingContactDetails,
    isEmployerHidden,
    isLocationDisabled,
    isLocationRequired,
    locationFieldLabel,
    canUpdateLocationOnZipCode,
    isRaceRequired,
    isRaceMultiple,
    isEthnicityRequired,
    isEthnicityMultiple,
    isSexualOrientationHidden,
    isMartialStatusHidden,
    isRaceHidden,
    isEthnicityHidden,
    isMartialStatusRequired,
    isSexualOrientationRequired,
    customFieldResponse,
    updateCustomFields,
    contactTypeId,
    personTypeList,
    customFieldsError,
    onCustomAttributeDetailsChange,
    setInsuranceDataForRequestBody,
    setSecInsuranceDataForRequestBody,
    setTerInsuranceDataForRequestBody,
    isMEDEnabled,
    isPractitionerDisable
  } = props;
  const accountId = getAccountId()
  const accountUuid = getAccountUUID()
  const navigate = useNavigate();
  const toast = useToast();
  const userId = getUserId();
  const userUUID = getUserUUID()
  const intl = useIntl();
  const [workflowDrawerOpen, setWorkflowDrawerOpen] = useState(false);
  const [isBasicDetailsOpen, setIsBasicDetailsOpen] = useState(true);
  const [isAddInsuranceOpen, setIsAddInsuranceOpen] = useState(false);
  const patientCapability = getResourceAbilities(
    CapabilityResource.patient,
    leadFormData?.locationGroupId,
    ''
  );
  const isSingleEmailAdd =
    patientCapability.keyAllowedOperations?.telecom?.isSingleEmail;
  const hintCapability = getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;
  const isContactCustomer = (alerts.sameEmailExistsAlert || alerts.sameNumberExistsAlert)?.includes('Patient')
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const userSettings = commonData.userSettings;
  const allowInsurance = isInsuranceEnable(userSettings) || true;
  const ehrCapabilities = useEHRCapabilities({
    locationGroupId: leadFormData?.locationGroupId,
  });
  const currentEHR: any = getEHRName('', leadFormData?.locationGroupId);
  const ehrConfig = getEhrConfig(currentEHR);
  const IS_ENABLE_MARRIAGE_ANNIVERSARY_DATE = isEnableMarriageAnniversaryDate()
  const consentMlovList: IMlov[] = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.CONSENT
  ) || [];
  const personGenderList: any = getMlovListByCategoryCode(
    MLOV_CATEGORY.SEX_AT_BIRTH
  ) || [];
  const patientLocationUuid =
    leadFormData?.locationUuid || defaultPracticeLocation;
    const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const isShowAdditionalInfo =
    (patientLocationUuid && isPatientCreationFlow) ||
    (!isPatientCreationFlow && isMsoEnabled && patientLocationUuid) ||
    (!isPatientCreationFlow && !isMsoEnabled);

  

  const scrollRef = useRef<HTMLElement>();

  const ref_inputEmail = useRef<HTMLDivElement>(null);
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const preferredCommunicationType = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.PREFERRED_COMMUNICATION_CHANNEL
  );
  const finalWidth = isSideCarContext
    ? '100%'
    : isIPadScreen || isIPadMiniScreen
    ? '75%'
    : 736;

  const canCreatePatient = checkAccountConfigAbilityAccess(FHIR_RESOURCE.PATIENT, 'canCreate')?.isEnabled;

  // const finalWidth = 736;
  // const format = /[a-zA-Z!@#$%^&*_+\=\[\]{};':"\\|,.<>\/?]+/;
  const getHeaderText = () => {
    if (leadFormData.leadId) {
      if (props?.invocationCode === COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER) {
        return 'convertToPatient'
      } else if (personTypeContact === PERSON_TYPES.LEAD) {
        return 'updateLead';
      } else if (personTypeContact === PERSON_TYPES.CUSTOMER) {
        return 'updateCustomer';
      } else {
        return 'updateContact';
      }
    } else {
      if (personTypeContact === PERSON_TYPES.LEAD) {
        return 'addContact';
      } else if (personTypeContact === PERSON_TYPES.CUSTOMER) {
        return 'addCustomer';
      }
      {
        return 'addContact';
      }
    }
  };

  const [GetCustomerCustomContactType] = useLazyQuery(GET_CONSUMER_CUSTOM_CONTACT_TYPE);


  const isConsentRequired = isContactConsentRequired()

  const pronounCapabilityValues = getPossibleValuesByOperationName(
    KEY_ALLOWED_OPERATIONS.PRONOUN,
    leadFormData?.locationGroupId,
    ''
  );
  let pronounDataList = cloneDeep(pronounList) || [];
  if (pronounCapabilityValues?.length && pronounDataList?.length) {
    pronounDataList = pronounDataList.filter((pronoun: any) => {
      return pronounCapabilityValues.some(capabilityPronoun => {
        return pronoun?.code === capabilityPronoun?.code;
      })
    });
  }

  useEffect(()=> {
    if(scrollRef?.current && alerts.duplicateAlert) {
      scrollRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [alerts.duplicateAlert])

  const setDefaultContactConsent =  (contactConsents: any | []) => {
    const  memberConsent = {
      consentToCall: false,
      consentToText: false,
      consentToEmail: false,
    };
    if (leadFormData.leadId) {
      (contactConsents || []).forEach((consent: any) => {
        const consentCode = getMlovCodeFromId(consentMlovList, consent?.consentId);
        switch (consentCode) {
          case CONSENT_CONST.consentToCall:
            memberConsent.consentToCall = true;
            break;
          case CONSENT_CONST.consentToEmail:
            memberConsent.consentToEmail = true;
            break;
          case CONSENT_CONST.consentToText:
            memberConsent.consentToText = true;
            break;
        }
      });
    } else {
      memberConsent.consentToCall = true;
      memberConsent.consentToText = true;
      memberConsent.consentToEmail = true;
    }
    setConsentList(prev => {
      return {
        ...prev,
        consentToCall: memberConsent.consentToCall,
        consentToText: memberConsent.consentToText,
        consentToEmail: memberConsent.consentToEmail,
      }
    });
    onConsentChangeHandler(memberConsent);
  }
  const isEmployer = isEmployerRole();
  const [getEmployeeConsent, {loading: getConsentLoading}] = useLazyQuery(EmployeeQueries.CONTACT_CONSENT);
  const [consentChk, setConsentChk] = useState(false);
  const [memberConsentCheck, setMemberConsentCheck] = useState(false);
  const [selectedConsent, setConsentList] = useState<ISelectedConsent>({
      consentToCall: false,
      consentToText: false,
      consentToEmail: false,
    });


  const setConsentCheck = async () => {
    if (leadFormData.leadId) {
      let response =  undefined;
      if (leadFormData?.contactData?.uuid) {
        response = await getEmployeeConsent({
          fetchPolicy: 'no-cache',
          variables: {
            contactUuid: leadFormData?.contactData?.uuid,
          },
        });
        setDefaultContactConsent(response?.data?.contactConsents || []);
      }
      if (response?.data?.contactConsents?.length || leadFormData?.contactConsents?.length) {
        props?.setConsentChanges('', true);
        return true;
      } else if (props?.showConsentError) {
        const message = CONSENT_ERROR;
        notification.error({
          message,
        });
      }
      props?.setConsentChanges('consent', false);
      return false;
    } else {
      setDefaultContactConsent([]);
      props?.setConsentChanges('consent', true);
      return true;
    }
  };
  const getConsent = async () => {
    const consent = await setConsentCheck();
    setConsentChk(consent);
  };

  const getAccountLocationUuid = (locationId: string) => {
    const accountLocation = practiceLocationObject.find((item: any) => {
      return (item.locationUuid === locationId);
    });

    if (!accountLocation) {
      return leadFormData?.contactData?.contactPracticeLocations?.[0]
        ?.accountLocation?.uuid;
    }

    return accountLocation?.accountLocationUuid;
  };

  useEffect(() => {
    getConsent();

  }, []);

  const [stateData, setStateData] = useState({
    showSecondaryContact: false,
    isUnChecked: false,
    zipCodeId: leadFormData?.zipCodeId,
    customFieldContactTypeId: '',
    showAddUpdateMemberWarning: false,
  });
  const [zipCodeChange, setZipCodeChange] = useState(leadFormData.zipCode);

  const showAdditionalInfo = () => {
    if (getHeaderText() === 'addCustomer' || getHeaderText() === 'addContact') {
      if (showMore) {
        return true;
      }
    } else {
      return true;
    }
  };

  const legalSexArray =
    getPossibleValuesByOperationName(
      KEY_ALLOWED_OPERATIONS.LEGAL_SEX,
      leadFormData?.locationGroupId,
      ''
    ) || [];

  const getPracticeLocations = () => {
    return (
      <LocationAutoComplete
        isRequired={isLocationRequired}
        isDisabled={isLocationDisabled}
        // labelText="Location"
        height={40}
        autoCompleteType={autoCompleteTypeCodes.DEFAULT}
        selectedLocationUUID={leadFormData?.locationUuid
          ? leadFormData?.locationUuid
          : defaultPracticeLocation}
        allowClear={false}
        onChange={(location: ILocationSearch) => {
          const accountLocation = practiceLocationObject.find((item: any) => {
            return (item.locationUuid === location.value);
          });
          setLeadFormData((prev: any) => {
            return {...prev, locationUuid: location.value};
          });
          alertDuplicates({
            firstName: leadFormData.firstName,
            lastName: leadFormData.lastName,
            birthDate: leadFormData.birthDate,
            locationId: accountLocation?.accountLocationUuid
          });
        }}
        accountLocations={practiceLocationObject}
        labelText={locationFieldLabel || "Select Location"}
        isInvalid={errors.location}
        errors={
          shouldCreateUsingIntegration &&
          isLocationRequired &&
          errors.location
        }
        errorText={errors.location}
      />
    );
  };

  const setPracticeLocation = () =>{
    // THIS SHOULD NOT FIRE WHEN ZIP HAS NOT BEEN SELECTED BY USER
    if (!canUpdateLocationOnZipCode || !stateData?.zipCodeId?.length) {
      return;
    }
    const location = practiceLocationObject?.filter((location: any)=>{
      return location?.zipcodeUuid === stateData?.zipCodeId
    })
    if (location.length) {
      const locationGroupId = practiceLocationObject?.find(
        (locationObj: any) =>
          locationObj?.locationUuid === location[0]?.locationUuid
      )?.locationGroupId;
      
      setLeadFormData((prev: any) => {
        return {...prev, locationUuid: location[0]?.locationUuid, locationGroupId: locationGroupId, practitionerId: null};
      });
    }
  }

  const fetchCustomerCustomContactType = async () => {
    try {
      const customerCustomContactTypeID = await GetCustomerCustomContactType({
        variables: {
          code: 'CUSTOMER'
        }
      });
      if (customerCustomContactTypeID?.data?.customContactTypes?.length) {
        const id = customerCustomContactTypeID?.data?.customContactTypes?.[0]?.id;
        if (id) {
          setStateData((prev) => {
            return {
              ...prev,
              customFieldContactTypeId: id,
            }
          })
        }
      }
    } catch (error: any) {
      notification.error({
        message: error.response.data.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: 4.0,
        placement: 'topRight'
      });
    }
  }

  useEffect(() => {
    if (leadFormData?.contactType?.id) {
      setStateData((prev) => {
        return {
          ...prev,
          customFieldContactTypeId: leadFormData?.contactType?.id
        }
      })
    } else if (personTypeContact === PERSON_TYPES.CUSTOMER) {
      fetchCustomerCustomContactType();
    }
  },[leadFormData?.contactType?.id])

  useEffect(()=>{
    setPracticeLocation()
  },[stateData?.zipCodeId])

  useEffect(()=> {
    const locationGroupId = practiceLocationObject?.find(
      (locationObj: any) => locationObj?.locationUuid === leadFormData?.locationUuid
    )?.locationGroupId;
    if (locationGroupId) {
      setLeadFormData((prev: any) => {
        return {...prev, locationGroupId: locationGroupId};
      });
    }  
  }, [leadFormData?.locationUuid, practiceLocationObject?.length])

  const deSelectRadio = () => {
    const isEmailOrPhoneExist =
      leadFormData.email ||
        leadFormData.secondaryEmail ||
        leadFormData.secondaryPhone ||
        leadFormData.phone
        ? true
        : false;
    return isEmailOrPhoneExist;
  };
  const isRadioDisable = (item: any) => {
    let isDisable = false;
    if (
      (item.code == PREFERRED_CHANNEL_CODE.VOICE ||
        item.code == PREFERRED_CHANNEL_CODE.SMS) &&
      !leadFormData.phone &&
      !leadFormData.secondaryPhone
    ) {
      isDisable = true;
    }
    if (
      item.code == PREFERRED_CHANNEL_CODE.EMAIL &&
      !leadFormData.email &&
      !leadFormData.secondaryEmail
    ) {
      isDisable = true;
    }
    return isDisable;
  };

  const shouldCreateInCanvas =
    getResourceAbilities(
      FHIR_RESOURCE.PATIENT,
      leadFormData?.locationGroupId,
      ''
    )?.createPatientUsingIntegration || false;
  const {
    firstName,
    lastName,
    middleName,
    city,
    state,
    phoneNumber,
    addressLine1,
    addressLine2,
  } = fieldsConfig;
  const [selectedRowData,setSelectedRowData] = useState({} as any)
  const [convertLeadModal, setConvertLeadModal] = useState(false);
  const [actionType, setActionType] = useState('');
  const [selectLeadViewCode, setSelectLeadViewCode] = useState({
    code: RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW,
    invocationCode: '',
  });
  const [observations, setObservations] = useState<Observation[]>([]);
  const [alert, setAlert] = useState('');
  const [patientUuid, setPatientUuid] = useState<string | undefined>('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const contactData =  alerts?.contactData

  const [allMemberState, setAllMemberState] = useState({
    contactTypeUuid: contactData?.contactType?.contactType?.id    ,
    contactType: contactData?.contactType?.contactType?.code,
    addContactType: contactData?.contactType?.contactType?.code,
    addContactTypeUuid: contactData?.contactType?.contactType?.id,
    practiceLocations: {},
    customHtml: '',
  });
  const [CreatePatientWithContactId] = useMutation(
    PatientQueries.CreatePatientWithContactId
  );
  const [UpdateContactTypeByPatientUuid] = useMutation(
    ConvertContacts.UpdateContactTypeByPatientUuid
  );
  const setViewCode = (code: string) => {
    setSelectLeadViewCode((prev) => {
      return {
        ...prev,
        code: code,
      };
    });
  };
  const setViewInvocationCode = (type: string) => {
    setSelectLeadViewCode((prev) => {
      return {
        ...prev,
        invocationCode: type,
      };
    });
  };
  const handlePatientContactMap = (contactData: any, patientUuid: any) => {
    CreatePatientWithContactId({
      variables: {
        accountId: accountId,
        patientUuid: patientUuid,
        contactId: contactData.leadId,
      },
    })
      .then(() => {
        onViewChangeActionPerformed(
          RIGHT_SIDE_CONTAINER_CODE.ADDED_OR_UPDATED_CONTACT
        );
      })
      .catch((err) => {
      });
  };
  const handleConvertToCustomer = (id: any, convertToType: string) => {
    const typeId = getContactTypeId(convertToType);
    UpdateContactTypeByPatientUuid({
      variables: {
        id: id,
        typeId: typeId,
      },
    }).then((response) => {
      onViewChangeActionPerformed(COMMON_ACTION_CODES.ADDED_OR_UPDATED);
    });
  };
  const sendPatientAppInvitation = (formattedData: any) => {
    if (formattedData.contactUuid || formattedData.contactUUID) {
      if (sendInvite) {
        sendPatientAppInvite({
          email: formattedData.email,
          accountId,
          accountUuid,
          contactUuid: formattedData.contactUuid || formattedData.contactUUID,
        })
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            showToast(
              toast,
              intl.formatMessage({id: 'patientAppInvitationError'}),
              ToastType.error,
            );
            return err;
          });
      }
    } else {
      showToast(
        toast,
        intl.formatMessage({id: 'patientAppInvitationError'}),
        ToastType.error,
      );
    }
  };
  const handleCreatePatientInEHR = (formattedData: any) => {
    const typeId = getContactTypeId(
      PERSON_TYPES.CUSTOMER
    );
    if (shouldCreateInCanvas) {
      createPatientUsingIntegration({
        patientData: {...formattedData, typeId: typeId},
        sendInvite: sendInvite,
        addTagObjectList: [],
        userId: userId,
        userUuid: userUUID,
        successHandler(response) {
          handleConvertToCustomer(
            formattedData.personTypeId,
            PERSON_TYPES.CUSTOMER
          );
        },
        errorHandler(error) {
          if (error.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED){
            showToast(
              toast,
              intl.formatMessage({id: RESOURCE_BLOCK_MESSAGE_ID}),
              ToastType.error,
            );
          } else {
            showToast(
              toast,
              intl.formatMessage({id: 'apiErrorMsg'}),
              ToastType.error,
            );
          }
        },
      });
    } else {
      CreatePatient(
        formattedData,
        (res: any) => {
          const resId = res?.data?.id;
          handleConvertToCustomer(
            formattedData.personTypeId,
            PERSON_TYPES.CUSTOMER
          );
          sendPatientAppInvitation(formattedData);
          handlePatientContactMap(formattedData, resId);
        },
        (error: any) => {
        }
      );
    }
  };
  const onViewChangeActionPerformed = (actionCode:string , rowData?:any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER:
        setSelectedRowData({...rowData, contactData: rowData,singleLeadData: rowData});
        const formattedData = getFormDataFromLeadData(
          rowData.singleLeadData || rowData,
          commonData
        );
        if (
          checkMandatoryFields(formattedData, PERSON_TYPES.CUSTOMER, {
            isGenderAtBirthRequired: isGenderAtBirthRequired || false,
            isPractitionerRequired: isPractitionerRequired || false,
            isAddressLine1Required: isAddressLine1Required || false,
            isMobileNumberRequired: isMobileNumberRequired || false,
            checkVitalsForLessThan18Age: false,
            isPhoneOrEmailRequired,
            isLocationRequired: isLocationRequired || false,
          })
        ) {
          setConvertLeadModal(true);
          setActionType(CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER);
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_CONVERT_CUSTOMER);
        } else {
          const alert = getMissingFields(formattedData, {
            isGenderAtBirthRequired: isGenderAtBirthRequired || false,
            isPractitionerRequired: isPractitionerRequired || false,
            genderAtBirthLabel,
            genderIdentityLabel,
            isAddressLine1Required: isAddressLine1Required || false,
            isMobileNumberRequired:isMobileNumberRequired || false,
            checkVitalsForLessThan18Age: false,
            isPhoneOrEmailRequired: isPhoneOrEmailRequired,
            isLocationRequired: isLocationRequired || false,

          }).message;
          setAlert(alert);
          setViewInvocationCode(COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER);
          setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW);
        }
        break;
        case COMMON_ACTION_CODES.ADDED_OR_UPDATED:
          // setViewCode(RIGHT_SIDE_CONTAINER_CODE.ADDED_OR_UPDATED_CONTACT);
          if (
            rowData?.invocationCode === COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER &&
            rowData?.leadFormData
          ) {
            onViewChangeActionPerformed(COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER, {
              singleLeadData: rowData.leadFormData.singleLeadData,
            });
          } else {
            setSelectedRowData({});
            setViewCode(RIGHT_SIDE_CONTAINER_CODE.ADDED_OR_UPDATED_CONTACT);
            props.handleChange?.(RIGHT_SIDE_CONTAINER_CODE.ADDED_OR_UPDATED_CONTACT,{})
          }
          break;
        default:
          setViewCode('')
        break;
    }
  }

  const renderSexualOrientationField = () => {
    const capabilityField = patientCapability?.keyAllowedOperations?.sexualOrientation || undefined;
    if (isSexualOrientationHidden) {
      return (<></>);
    }
    const possibleValues = getPossibleValuesByOperationName(
      KEY_ALLOWED_OPERATIONS.SEXUAL_ORIENTATION,
      leadFormData?.locationGroupId,
      ''
    );
    return (
      <VStack marginTop={5}>
        <ModalActionSelect
          label={'sexualOrientation'}
          errors={errors.sexualOrientation}
          errorText={errors.sexualOrientation}
          isRequired={isSexualOrientationRequired}
          selectedValue={leadFormData.sexualOrientation}
          placeholder="Select Sexual Orientation"
          endIcon={<CheckIcon size="5" />}
          onValueChange={(itemValue: any) => {
            setLeadFormData({
              ...leadFormData,
              sexualOrientation: itemValue || '',
            });
          }}
          data={possibleValues || []}
          selectItemProps={{
            key: 'code',
            label: 'display',
            value: 'code',
          }}
          customStyle={{
            flex: 0.5,
            placeHolderFontsize: InputStyle.placeHolderFontsize,
            placeHolderFontWeight:
              InputStyle.placeHolderFontWeight,
            minHeight: 40,
          }}
        />
        {leadFormData.sexualOrientation === SEXUAL_ORIENTATION_OTHER_CODE && (
          <TextArea
            {...(capabilityField?.maxCharLimit ? {maxLength: capabilityField.maxCharLimit} : {})}
            marginTop={5}
            numberOfLines={3}
            autoComplete={'off'}
            placeholder="Please describe"
            placeholderTextColor={InputStyle.placeholderTextColor}
            value={leadFormData.sexualOrientationText}
            onChangeText={(value) => {
              setLeadFormData({
                ...leadFormData,
                sexualOrientationText: value || '',
              });
            }}
          />
        )}
      </VStack>
    )
  };
  const renderMartialStatusField = (): JSX.Element => {
    if (isMartialStatusHidden) {
      return (<></>);
    }
    const possibleValues = getPossibleValuesByOperationName(
      KEY_ALLOWED_OPERATIONS.MARITAL_STATUS,
      leadFormData?.locationGroupId,
      ''
    );
    return (
      <VStack marginTop={5}>
        <ModalActionSelect
          label={'maritalStatus'}
          errors={errors.maritalStatus}
          errorText={errors.maritalStatus}
          isRequired={isMartialStatusRequired}
          selectedValue={leadFormData.maritalStatus}
          placeholder="Select Marital Status"
          endIcon={<CheckIcon size="5" />}
          onValueChange={(itemValue: any) => {
            setLeadFormData({
              ...leadFormData,
              maritalStatus: itemValue || '',
            });
          }}
          data={possibleValues || []}
          selectItemProps={{
            key: 'code',
            label: 'display',
            value: 'code',
          }}
          customStyle={{
            flex: 0.5,
            placeHolderFontsize: InputStyle.placeHolderFontsize,
            placeHolderFontWeight:
              InputStyle.placeHolderFontWeight,
            minHeight: 40,
          }}
        />
      </VStack>
    );
  };

  const renderRaceAndEthnicityField = (isRequiredFieldSection: boolean) => {
    const canShowRace = !isRaceHidden && (isRequiredFieldSection ? isRaceRequired : !isRaceRequired);
    const canShowEthnicity = !isEthnicityHidden && (isRequiredFieldSection ? isEthnicityRequired : !isEthnicityRequired);

    if (!canShowRace && !canShowEthnicity) {
      return <></>;
    }
    let raceValue;
    if (isRaceMultiple) {
      raceValue = leadFormData.race?.length ? leadFormData.race.map((item: any) => item.display) : [];
    } else {
      raceValue = leadFormData.race?.length ? leadFormData.race?.[0]?.display : undefined;
    }
    let ethnicityValue;
    if (isEthnicityMultiple) {
      ethnicityValue = leadFormData.ethnicity?.length ? leadFormData.ethnicity.map((item: any) => item.display) : [];
    } else {
      ethnicityValue = leadFormData.ethnicity?.length ? leadFormData.ethnicity?.[0]?.display : undefined;
    }
    return (
      <HStack mt={5} space={10} style={styles.rowStack}>
        {canShowRace && (
          <View flex={1}>
            <CodeSearch
              value={raceValue}
              searchType={CodeSearchType.race}
              label={'Race'}
              pageSize={50}
              allowUserMultiSelect={isRaceMultiple}
              isRequired={isRaceRequired}
              isInvalid={!!errors.race}
              errorMessage={errors.race}
              placeholder="Search Race"
              isShowError={false}
              onChange={(selectedRace) => {
                setLeadFormData({
                  ...leadFormData,
                  race: selectedRace,
                });
              }}
              locationId={getAccountLocationUuid(patientLocationUuid)}
            />
          </View>
        )}
        {canShowEthnicity && (
          <View flex={1}>
            <CodeSearch
              value={ethnicityValue}
              searchType={CodeSearchType.ethnicity}
              label={'Ethnicity'}
              pageSize={50}
              allowUserMultiSelect={isEthnicityMultiple}
              isRequired={isEthnicityRequired}
              isInvalid={!!errors.ethnicity}
              errorMessage={errors.ethnicity}
              placeholder="Select Ethnicity"
              isShowError={false}
              onChange={(selectedEthnicity) => {
                setLeadFormData({
                  ...leadFormData,
                  ethnicity: selectedEthnicity,
                });
              }}
              locationId={getAccountLocationUuid(patientLocationUuid)}
            />
          </View>
        )}
      </HStack>
    );
  }

  const renderLegalSexField = ():JSX.Element => {
    return (
      <ModalActionSelect
        label={'legalSex'}
        errors={errors.legalSex}
        errorText={errors.legalSex}
        isRequired={isLegalSexRequired}
        selectedValue={leadFormData.legalSex}
        accessibilityLabel="Legal Sex"
        placeholder="Legal Sex"
        endIcon={<CheckIcon size="5" />}
        onValueChange={(itemValue: any) => {
          const selectedLegalSex = legalSexArray.find((item) => {
            return item.code == itemValue;
          });
          setLeadFormData({
            ...leadFormData,
            legalSex: selectedLegalSex?.code || '',
          });
        }}
        data={legalSexArray}
        selectItemProps={{
          key: 'code',
          label: 'display',
          value: 'code',
        }}
        customStyle={{
          flex: 1,
          placeHolderFontsize: InputStyle.placeHolderFontsize,
          placeHolderFontWeight:
            InputStyle.placeHolderFontWeight,
          minHeight: 40,
        }}
      />
    );
  };

  const onConsentChangeHandler = (selectedConsent: ISelectedConsent) => {
    const consentIds = getSelectedConsentMlov(selectedConsent, consentMlovList) || [];
    if (props?.setConsentList && typeof props.setConsentList === 'function') {
      props.setConsentList(consentIds);
    }
  };

  const onEmailConsentChange = (isChecked: boolean) => {
    setConsentList(prev => {
      const contactConsent = cloneDeep(prev);
      contactConsent.consentToEmail = isChecked;
      onConsentChangeHandler(contactConsent);
      return {
        ...prev,
        consentToEmail: isChecked,
      };
    });
  };

  const onTextConsentChange = (isChecked: boolean) => {
    setConsentList(prev => {
      const contactConsent = cloneDeep(prev);
      contactConsent.consentToText = isChecked;
      onConsentChangeHandler(contactConsent);
      return {
        ...prev,
        consentToText: isChecked,
      };
    });
  };

  const onCallConsentChange = (isChecked: boolean) => {
    setConsentList(prev => {
      const contactConsent = cloneDeep(prev);
      contactConsent.consentToCall = isChecked;
      onConsentChangeHandler(contactConsent);
      return {
        ...prev,
        consentToCall: isChecked,
      };
    });
  };

  const handleModalChange = (value: boolean) => {
    setStateData((prev) => {
      return {
        ...prev,
        showAddUpdateMemberWarning: value
      }
    })
  }

  const renderConsentToEmail = () => {
    return (
      <HStack flex={1} marginTop={2} key={'consentToEmail'}>
        { isWeb() ?
          <>
            <ModalActionCheckbox
              isChecked={selectedConsent.consentToEmail}
              onChange={(isChecked: boolean) => {
                onEmailConsentChange(isChecked);
              }}
              value={'consentToEmail'}
            />
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY400}>
              {` Consent To Email`}
            </Text>
          </> :
          <>
            <Checkbox
              colorScheme={'purple'}
              value={`consentToEmail`}
              style={{
                display: 'flex',
                marginLeft: 2,
                marginBottom: 10,
              }}
              isChecked={selectedConsent.consentToEmail}
              onChange={(isChecked: boolean) => {
                onEmailConsentChange(isChecked);
              }}
            >
              <Text fontSize={14} fontWeight={400}> {` Consent To Email`} </Text>
            </Checkbox>
          </>}
      </HStack>
    );
  };

  const renderConsentToText = () => {
    return (
      <HStack flex={1} marginTop={2} key={'consentToText'}>
        { isWeb() ?
          <>
            <ModalActionCheckbox
              isChecked={selectedConsent.consentToText}
              onChange={(isChecked: boolean) => {
                onTextConsentChange(isChecked);
              }}
              value={'consentToText'}
            />
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY400}>
              {` Consent To Text`}
            </Text>
          </> :
          <>
            <Checkbox
              colorScheme={'purple'}
              value={`consentToText`}
              style={{
                display: 'flex',
                marginLeft: 2,
                marginBottom: 10,
              }}
              isChecked={selectedConsent.consentToText}
              onChange={(isChecked: boolean) => {
                onTextConsentChange(isChecked);
              }}
            >
              <Text fontSize={14} fontWeight={400}> {` Consent To Text`} </Text>
            </Checkbox>
          </>}
      </HStack>
    );
  };

  const renderConsentToCall = () => {
    return (
      <HStack flex={1} marginTop={2} key={'consentToCall'}>
        { isWeb() ?
          <>
            <ModalActionCheckbox
              isChecked={selectedConsent.consentToCall}
              onChange={(isChecked: boolean) => {
                onCallConsentChange(isChecked);
              }}
              value={'consentToCall'}
            />
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY400}>
              {` Consent To Call`}
            </Text>
          </> :
          <>
            <Checkbox
              colorScheme={'purple'}
              value={`consentToCall`}
              style={{
                display: 'flex',
                marginLeft: 2,
                marginBottom: 10,
              }}
              isChecked={selectedConsent.consentToCall}
              onChange={(isChecked: boolean) => {
                onCallConsentChange(isChecked);
              }}
            >
              <Text fontSize={14} fontWeight={400}> {` Consent To Call`} </Text>
            </Checkbox>
          </>}
      </HStack>
    );
  }

  const getFormattedPersonTypeList = () => {
    return personTypeList.map((obj: any) => {
      return {
        id: obj.id,
        code: obj.code || PERSON_TYPES.LEAD,
        value:
          obj.code === PERSON_TYPES.LEAD
            ? 'Lead'
            : obj.code === PERSON_TYPES.CUSTOMER
            ? 'Patient'
            : capitalizeText(obj.value),
      };
    });
  };

  const filterContactType = (obj: any) => {
    if (leadFormData?.savedContactType?.code === PERSON_TYPES.OTHER) {
      return [PERSON_TYPES.CUSTOMER].indexOf(obj.code) === -1
    }
    return [PERSON_TYPES.CUSTOMER, PERSON_TYPES.OTHER].indexOf(obj.code) === -1;
  }

  return (
    <ConfirmOnExitOrReload>
    <>
      <Drawer
        visible={showModal}
        width={finalWidth}
        mask={isSideCarContext ? false : true}
        closable={false}
        // onClose={onCloseDrawer}
        title={
          <ModalActionTitle
          title={headerName ? headerName : getHeaderText()}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: isSideCarContext ? 'back' : 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                handleModalChange(true);
              },
            },
            {
              show: true,
              id: 2,
              btnText: leadFormData.leadId ? 'update' : 'add',
              textColor: Colors.Custom.mainPrimaryPurple,
              isLoading: isSubmitting,
              isDisabled: isSubmitting,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                onConsentChangeHandler(selectedConsent);
                setTimeout(() => {
                  props.onSubmit();
                }, 100)
              },
            },
          ]}
        />
        }
      >
        <ScrollView ref={scrollRef}>
          {(formStatus.loading || getConsentLoading) ? (
            <Spinner size="lg" style={styles.spinnerStyle} />
          ) : (
            <></>
          )}
            <>
              {(allowInsurance) && (!leadFormData.leadId && personTypeContact === PERSON_TYPES.CUSTOMER) && <HStack alignItems={'center'} paddingBottom={6}>
                <Pressable onPress={() => {
                  setIsBasicDetailsOpen(true); setIsAddInsuranceOpen(false);
                }}>
                  <HStack>
                    <OneIconSvg />
                    <Text style={{ marginLeft: 6, marginRight: 6, color: Colors.Custom.mainPrimaryPurple }}>Basic Details</Text>
                  </HStack>
                </Pressable >
                <HorizantalLineSvg />
                <Pressable onPress={() => {
                  const { validate, errors } = validateContactOrPatientDetails(leadFormData, personTypeContact, {
                    isGenderAtBirthRequired,
                    isPractitionerRequired,
                    isAddressLine1Required,
                    isMobileNumberRequired,
                    checkVitalsForLessThan18Age: checkVitalsForLessThan18Age,
                    fieldKeys,
                    isFaxNumberRequired: false,
                    isPatientCreationFlow: props.isPatientCreationFlow,
                    shouldPerformPhoneNumberValidation: isMEDEnabled,
                    isPhoneOrEmailRequired: isPhoneOrEmailRequired,
                    isLegalSexRequired: isLegalSexRequired,
                    isLocationRequired: isLocationRequired,
                    isSexualOrientationRequired: isSexualOrientationRequired,
                    isMartialStatusRequired: isMartialStatusRequired,
                    isRaceRequired: isRaceRequired,
                    isRaceMultiple: isRaceMultiple,
                    isEthnicityRequired: isEthnicityRequired,
                    isEthnicityMultiple: isEthnicityMultiple,
                  });
                  if (!validate) {
                    notification.error({
                      message: 'Please fill all mandatory details',
                      duration: 4.0,
                      placement: 'top'
                    })
                    return;
                  }
                  else { setIsAddInsuranceOpen(true); setIsBasicDetailsOpen(false) }
                }}>
                  <HStack marginLeft={2}>
                    <TwoIconSvg />
                    <Text style={{ marginLeft: 6, color: Colors.Custom.mainPrimaryPurple }}>Add Insurance</Text>
                  </HStack>
                </Pressable>
              </HStack>
              }
          {isBasicDetailsOpen &&
          <>
          {props?.alert && (
              <Alert message={props?.alert} type="error" />
            )}
            {alerts.apiError && (
              <Alert message={alerts.apiError} type="error" />
            )}
            {alerts.duplicateAlert && (
              <Alert
                message={'Patient with same name and birth date already exists'}
                type="error"
              />
            )}
            {!getConsentLoading && !consentChk && isConsentRequired && <Alert message={CONSENT_ERROR} type="error" />}

            <ScrollView style={styles.scrollViewStyle}>
              <VStack ml={0} mr={0}>
                {isShowAddContactTypeOption ? (
                  <>
                    <ModalActionSubTitle
                      subTitle={'Add Family Members as'}
                    />
                    <HStack space={10} style={styles.rowStack}>
                      <Select
                        style={{flex: 1}}
                        value={contactTypeId}
                        onChange={(value) => {
                          const selectedObj = personTypeList.find((item: any) => {
                            return item.id === value;
                          });
                          setLeadFormData({
                            ...leadFormData,
                            contactType: selectedObj,
                            contactTypeId: value,
                          });
                          if (onPersonTypeChange) {
                            onPersonTypeChange(selectedObj);
                          }
                        }}
                      >
                        {personTypeList?.filter((item: any)=> {
                            if(!canCreatePatient){
                              return item.code !== PERSON_TYPES.CUSTOMER
                            }
                            else {
                              return true
                            }
                          }
                          ).map((item: any) => {
                          return (
                            <Select.Option key={item.id} value={item.id}>
                              {item.code === PERSON_TYPES.LEAD
                                ? 'Lead'
                                : item.code === PERSON_TYPES.CUSTOMER
                                ? 'Patient'
                                : capitalizeText(item.value)}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </HStack>
                  </>
                ) : (
                    personTypeContact !== PERSON_TYPES.CUSTOMER ? (
                      <>
                        {props?.invocationCode !== COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER && <View>
                          <ModalActionAntSelect
                            label={'Contact Type'}
                            isRequired={true}
                            value={leadFormData?.contactType?.id || ''}
                            placeholder={`Select Contact Type`}
                            onChange={(itemValue: any) => {
                              const selectedObj = personTypeList.find((item: any) => {
                                return item.id === itemValue;
                              });
                              if (selectedObj) {
                                setLeadFormData({
                                  ...leadFormData,
                                  contactType: selectedObj,
                                  contactTypeId: itemValue,
                                });
                              }
                              if (onPersonTypeChange) {
                                onPersonTypeChange(selectedObj);
                              }
                            }}
                            isInvalid={errors[fieldKeys?.contactType]}
                            errors={errors[fieldKeys?.contactType]}
                            errorText={errors[fieldKeys?.contactType]}
                            data={
                              getFormattedPersonTypeList().filter((obj: any) => {
                                return filterContactType(obj);
                              })
                            }
                            optionProps={{
                              key: 'id',
                              label: 'value',
                              value: 'id',
                            }}
                            customStyle={{
                              flex: 1,
                              placeHolderFontsize: InputStyle.placeHolderFontsize,
                              placeHolderFontWeight:
                                InputStyle.placeHolderFontWeight,
                              minHeight: 45,
                              width :'100%',
                              labelBottomMargin:0
                            }}
                            style={{
                              flex: 1,
                            }}
                          />
                          {props?.contactTypeLoading && <View style={{
                            position: 'absolute',
                            top: 38,
                            right: 24,
                          }}>
                            <Spinner/>
                          </View>}
                        </View>}
                      </>
                    ): <></>
                )}
                <ModalActionSubTitle marginTop={isShowAddContactTypeOption || personTypeContact !== PERSON_TYPES.CUSTOMER ? 4 : 0} subTitle={'Basic Info'} />
                <VStack>
                  <HStack space={10} style={styles.rowStack}>
                    <ModalActionInput
                      fieldIs={'input'}
                      label={'firstName'}
                      placeholder={'Enter First Name'}
                      isRequired={true}
                      changeBackground={true}
                      // isInvalid={errors.firstName}
                      maxLength={firstName?.maxCharLimit}
                      isInvalid={errors[fieldKeys?.firstName]}
                      value={
                        // leadFormData.firstName
                        //   ? leadFormData.firstName
                        //   : leadFormData.name
                        leadFormData[fieldKeys?.firstName] || ''
                        // ? leadFormData[fieldKeys?.firstName]
                        // : leadFormData.name
                      }
                      errors={errors[fieldKeys?.firstName]}
                      errorText={errors[fieldKeys?.firstName]}
                      onChangeText={(value: any) => {
                        setLeadFormData({
                          ...leadFormData,
                          firstName: value,
                        });
                        alertDuplicates({
                          firstName: value ? value.trim() : value,
                          lastName: leadFormData.lastName,
                          birthDate: leadFormData.birthDate,
                          locationId: patientLocationUuid
                        });
                        onConsentChangeHandler(selectedConsent);
                      }}
                      extraStyle={{
                        flex: 1.0,
                        placeHolderFontsize: InputStyle.placeHolderFontsize,
                        placeHolderFontWeight:
                          InputStyle.placeHolderFontWeight,
                      }}
                      notAllowEmoji={true}
                    />

                    <ModalActionInput
                      fieldIs={'input'}
                      label={'middleName'}
                      placeholder={'Enter Middle Name'}
                      // value={leadFormData.middleName}
                      maxLength={middleName?.maxCharLimit}
                      isRequired={middleName?.isRequired || false}
                      value={leadFormData[fieldKeys?.middleName]}
                      onChangeText={(value: any) => {
                        setLeadFormData({
                          ...leadFormData,
                          middleName: value,
                        });
                        true;
                      }}
                      extraStyle={{
                        flex: 1.0,
                        placeHolderFontsize: InputStyle.placeHolderFontsize,
                        placeHolderFontWeight:
                          InputStyle.placeHolderFontWeight,
                      }}
                      notAllowEmoji={true}
                    />
                  </HStack>

                  <HStack mt={5} space={10} style={styles.rowStack}>
                    <ModalActionInput
                      fieldIs={'input'}
                      label={'lastName'}
                      placeholder="Enter Last Name"
                      isRequired={true}
                      changeBackground={true}
                      // isInvalid={errors.lastName}
                      // value={leadFormData.lastName}
                      // errors={errors.lastName}
                      // errorText={errors.lastName}
                      maxLength={lastName?.maxCharLimit}
                      // isRequired={lastName?.isRequired || true}
                      isInvalid={errors[fieldKeys?.lastName]}
                      value={leadFormData[fieldKeys?.lastName]}
                      errors={errors[fieldKeys?.lastName]}
                      errorText={errors[fieldKeys?.lastName]}
                      onChangeText={(value: any) => {
                        setLeadFormData({
                          ...leadFormData,
                          lastName: value,
                        });
                        alertDuplicates({
                          lastName: value ? value.trim() : value,
                          firstName: leadFormData.firstName,
                          birthDate: leadFormData.birthDate,
                          locationId: patientLocationUuid,
                        });
                      }}
                      extraStyle={{
                        flex: 1.0,
                        placeHolderFontsize: InputStyle.placeHolderFontsize,
                        placeHolderFontWeight:
                          InputStyle.placeHolderFontWeight,
                      }}
                      notAllowEmoji={true}
                    />

                    <ModalActionDatePicker
                      className="date-picker-background-color"
                      disabledDate={
                        props.deceasedDateOfCurrentPatient
                          ? (current: Date) => {
                              return (
                                current &&
                                isCurrentDateInFutureComparedToOther(
                                  current,
                                  props.deceasedDateOfCurrentPatient
                                )
                              );
                            }
                          : (current: any) => {
                              return current && isFutureDate(current);
                            }
                      }
                      changeBackground={true}
                      isRequired={
                        personTypeContact === PERSON_TYPES.CUSTOMER ||
                        props.invocationCode ===
                        COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER
                          ? true
                          : false
                      }
                      label={'dateOfBirth'}
                      defaultValue={
                        leadFormData.birthDate
                          ? moment(leadFormData.birthDate)
                          : undefined
                      }
                      format={DISPLAY_DATE_FORMAT}
                      placeholder={DISPLAY_DATE_FORMAT}
                      isInvalid={errors.birthdate}
                      value={
                        leadFormData.birthDate
                          ? moment(leadFormData.birthDate)
                          : undefined
                      }
                      errors={errors.birthdate}
                      errorsText={errors.birthdate}
                      onChange={(value: any) => {
                        ref_inputEmail.current?.focus();
                        setLeadFormData({
                          ...leadFormData,
                          birthDate: value,
                        });
                        alertDuplicates({
                          birthDate: value,
                          firstName: leadFormData.firstName,
                          lastName: leadFormData.lastName,
                          locationId: patientLocationUuid,
                        });
                      }}
                      customStyle={{
                        height: 36,
                        borderRadius: 4,
                        borderColor: Colors.Custom.BorderColor,
                        placeHolderFontsize: InputStyle.placeHolderFontsize,
                        placeHolderFontWeight:
                          InputStyle.placeHolderFontWeight,
                      }}
                      extraStyle={{
                        flex: 1.0,
                      }}
                    />
                  </HStack>
                  <HStack mt={5} space={10} style={styles.rowStack}>
                    <View  w='47%' >
                    <ModalActionAntSelect
                      label={'Blood Group'}
                      isRequired={false}
                      value={leadFormData?.bloodGroupId}
                      placeholder={`Select Blood Group`}
                      allowClear={true}
                      onChange={(itemValue: any) => {
                        setLeadFormData({
                          ...leadFormData,
                          bloodGroupId: itemValue,
                        });
                      }}
                      data={bloodGroupList}
                      optionProps={{
                        key: 'id',
                        label: 'value',
                        value: 'id',
                      }}
                      // errors={
                      //   shouldCreateUsingIntegration &&
                      //   isGenderAtBirthRequired &&
                      //   errors.sexAtBirth
                      // }
                      // errorText={errors.sexAtBirth}
                      customStyle={{
                        flex: 1,
                        placeHolderFontsize: InputStyle.placeHolderFontsize,
                        placeHolderFontWeight:
                          InputStyle.placeHolderFontWeight,
                        minHeight: 45,
                        width :'100%',
                       labelBottomMargin:0
                      }}
                      style={{
                      flex: 1,
                    }}
                    />
                    </View>
                    <ModalActionInput
                      fieldIs={'input'}
                      label={'chosenName'}
                      placeholder={'Enter Chosen Name'}
                      isRequired={false}
                      value={leadFormData.chosenName || ''}
                      onChangeText={(value: any) => {
                        setLeadFormData({
                          ...leadFormData,
                          chosenName: value,
                        });
                      }}
                      extraStyle={{
                        flex: 1,
                        placeHolderFontsize: InputStyle.placeHolderFontsize,
                        placeHolderFontWeight:
                          InputStyle.placeHolderFontWeight,
                      }}
                      notAllowEmoji={true}
                    />
                  </HStack>
                  <HStack mt={5} space={10} style={styles.rowStack} flex={1}>
                    {isLocationRequired ? (
                      <View flex={1}>{getPracticeLocations()}</View>
                    ) : null}
                    {(shouldCreateUsingIntegration ||
                      props.invocationCode ===
                        COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER) ? (
                      <PractitionerSearch
                        isRequired={isPractitionerRequired}
                        label={practitionerLabel}
                        disable={isPractitionerDisable}
                        isInvalid={errors.practitioner}
                        errors={
                          shouldCreateUsingIntegration &&
                          isPractitionerRequired &&
                          errors.practitioner
                        }
                        errorText={errors.practitioner}
                        selectedPractitioner={leadFormData.practitionerId}
                        accountLocationUuid={getAccountLocationUuid(patientLocationUuid)}
                        onSelectPractitioner={(id: string | number) => {
                          setLeadFormData((prev: any) => {
                            return {
                              ...prev,
                              practitionerId: id,
                            };
                          });
                        }}
                      />
                    ) : (
                      null
                    )}
                  </HStack>
                  {/* pppppppppp */}
                  <HStack mt={5} space={10} style={styles.rowStack}  >
                    {(shouldCreateUsingIntegration ||
                      props.invocationCode ===
                        COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER) && (
                          <View w='47%'>
                      <ModalActionSelect
                        label={genderAtBirthLabel}
                        isRequired={isGenderAtBirthRequired}
                        changeBackground={true}
                        isInvalid={errors.sexAtBirth}
                        selectedValue={leadFormData?.sexAtBirthCode || ''}
                        accessibilityLabel={genderAtBirthLabel}
                        placeholder={`Select ${genderAtBirthLabel}`}
                        endIcon={<CheckIcon size="5" />}
                        onValueChange={(itemValue: any) => {
                          const selectedSexAtBirth = personGenderList.find((item: any)=> {
                            return item.code === itemValue
                          });

                          setLeadFormData({
                            ...leadFormData,
                            sexAtBirth: itemValue,
                            sexAtBirthCode: itemValue,
                            sexAtBirthId: selectedSexAtBirth.id
                          });
                        }}
                        data={genderAtBirthArray}
                        selectItemProps={{
                          key: 'code',
                          label: 'display',
                          value: 'code',
                        }}
                        errors={
                          shouldCreateUsingIntegration &&
                          isGenderAtBirthRequired &&
                          errors.sexAtBirth
                        }
                        errorText={errors.sexAtBirth}
                        customStyle={{
                          flex: 1,
                          placeHolderFontsize: InputStyle.placeHolderFontsize,
                        placeHolderFontWeight:
                          InputStyle.placeHolderFontWeight,
                          minHeight: 40,
                        }}
                      />
                      </View>
                    )}
                    <View w='47%'>
                    <ModalActionAntSelect
                      label={'pronoun'}
                      isRequired={false}
                      value={leadFormData.pronounId}
                      placeholder={`Select Pronoun`}
                      onChange={(itemValue: any) => {
                        const selectedPronoun = pronounDataList.find(
                          (pronoun: any) => {
                            return pronoun.id == itemValue;
                          }
                        );
                        setLeadFormData({
                          ...leadFormData,
                          pronounId: itemValue,
                          pronounName: selectedPronoun?.code,
                        });
                      }}
                      allowClear={true}
                      data={pronounDataList}
                      optionProps={{
                        key: 'id',
                        label: 'value',
                        value: 'id',
                      }}
                      customStyle={{
                        flex: 0.5,
                        placeHolderFontsize: InputStyle.placeHolderFontsize,
                        placeHolderFontWeight:
                          InputStyle.placeHolderFontWeight,
                        minHeight: 40,
                        width :'100%',
                        labelBottomMargin:0
                      }}
                      style={{
                        flex:1
                      }}
                    />
                    </View>

                  </HStack>
                  { IS_ENABLE_MARRIAGE_ANNIVERSARY_DATE && personTypeContact === PERSON_TYPES.CUSTOMER ? <HStack mt={5} space={10} style={styles.rowStack}>
                    <HStack flex={0.5} style={styles.rowStack}>
                      <ModalActionDatePicker
                          className="date-picker-background-color"
                          disabledDate={(current: any) => {
                            return current && isFutureDate(current);
                          }}
                          changeBackground={true}
                          isRequired={false
                          }
                          label={'marriageAnniversaryDate'}
                          defaultValue={
                            leadFormData.marriageAnniversaryDate
                              ? moment(leadFormData.marriageAnniversaryDate)
                              : undefined
                          }
                          format={DISPLAY_DATE_FORMAT}
                          placeholder={DISPLAY_DATE_FORMAT}
                          isInvalid={errors.marriageAnniversaryDate}
                          value={
                            leadFormData.marriageAnniversaryDate
                              ? moment(leadFormData.marriageAnniversaryDate)
                              : undefined
                          }
                          errors={errors.marriageAnniversaryDate}
                          errorsText={errors.marriageAnniversaryDate}
                          onChange={(value: any) => {
                            ref_inputEmail.current?.focus();
                            setLeadFormData({
                              ...leadFormData,
                              marriageAnniversaryDate: value,
                            });
                          }}
                          customStyle={{
                            height: 36,
                            borderRadius: 4,
                            borderColor: Colors.Custom.BorderColor,
                            placeHolderFontsize: InputStyle.placeHolderFontsize,
                            placeHolderFontWeight:
                              InputStyle.placeHolderFontWeight,
                          }}
                          extraStyle={{
                            flex: 1.0,
                          }}
                        />
                    </HStack>
                    <HStack flex={0.5} style={styles.rowStack}></HStack>
                  </HStack> : <></> }
                  {isLegalSexRequired &&
                    legalSexArray &&
                    legalSexArray.length > 0 && (
                      <HStack mt={5} space={10} style={styles.rowStack}>
                        {renderLegalSexField()}
                      </HStack>
                    )}

                  {isSexualOrientationRequired && (
                    renderSexualOrientationField()
                  )}
                  {isMartialStatusRequired && (
                    renderMartialStatusField()
                  )}
                  {renderRaceAndEthnicityField(true)}
                  <View flex={1}>
                    <View
                      flex={1}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                      }}
                    >
                      <ModalActionSubTitle
                        marginTop={8}
                        hideDivider={true}
                        subTitle={'Contact Info'}
                      />
                    </View>
                    <Divider width={'100%'} my={4} />
                  </View>
                  {alerts?.contactId ? (
                    <View
                      style={{
                        backgroundColor: '#F9FAFB',
                        paddingHorizontal: 14,
                        paddingVertical: 11,
                        borderRadius: 6,
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginBottom: 16,
                      }}
                    >
                      <View
                        style={{flexDirection: 'row', alignItems: 'center'}}
                      >
                        <InfoSvg
                          customDimension={'15'}
                          customColor={Colors.error['600']}
                        />
                        <Text
                          size={'smMedium'}
                          style={{color: Colors.error['600'], marginLeft: 10}}
                        >
                          {alerts.sameEmailExistsAlert ||
                            alerts.sameNumberExistsAlert}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginLeft: 16,
                        }}
                      >
                        <Pressable
                          style={{marginRight: 12}}
                          onPress={() => {
                            if (isContactCustomer) {
                              props?.navigateOrOpenContactIdDrawer?.(alerts?.contactId);
                            } else {
                              navigate(
                                `/members/prospect/${alerts?.contactId}`
                              );
                            }
                          }}
                        >
                          <Text
                            size={'smBold'}
                            color={Colors.Custom.mainPrimaryPurple}
                          >
                            View {isContactCustomer ? 'Patient' : 'Prospect'}
                          </Text>
                        </Pressable>
                        {contactData?.contactType?.contactType?.code &&
                        contactData?.contactType?.contactType?.code !=
                          PERSON_TYPES.CUSTOMER ? (
                          <Pressable
                            onPress={() => {
                              onViewChangeActionPerformed(
                                COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER,
                                contactData
                              );
                            }}
                          >
                            <Text
                              size={'smBold'}
                              color={Colors.Custom.mainPrimaryPurple}
                            >
                              Convert to Member
                            </Text>
                          </Pressable>
                        ) : (
                          <></>
                        )}
                      </View>
                    </View>
                  ) : (
                    <></>
                  )}
                  <HStack space={10} style={[styles.rowStack, {width: '100%'}]}>
                    <ModalActionInput
                      ref={ref_inputEmail}
                      changeBackground={true}
                      fieldIs={'input'}
                      label={'Email'}
                      placeholder={'Enter Email Address'}
                      isRequired={false}
                      isInvalid={errors.email}
                      value={leadFormData.email}
                      errors={errors.email}
                      errorText={errors.email}
                      onChangeText={(value: any) => {
                        const tempContactData = {...leadFormData};
                        const emailObj = {...tempContactData.emailObj};
                        emailObj.value = value ? value.trim() : value;
                        setLeadFormData({
                          ...tempContactData,
                          emailObj: emailObj,
                          email: value ? value.trim() : value,
                        });
                      }}
                      extraStyle={{
                        flex: 1.0,
                        placeHolderFontsize: InputStyle.placeHolderFontsize,
                        placeHolderFontWeight:
                          InputStyle.placeHolderFontWeight,
                      }}
                      // onBlur={() => {
                      //   checkForExistingContactWithSameEmail(
                      //     leadFormData.email,
                      //     leadFormData.phone,
                      //     'PRIMARY'
                      //   );
                      // }}
                      isVerified={isContactVerified(
                        leadFormData?.personContact || [],
                        'email'
                      )}
                    />
                    <ModalActionInput
                      changeBackground={true}
                      fieldIs={'input'}
                      label={'phoneNumber'}
                      placeholder={'Enter Mobile No.'}
                      maxLength={phoneNumber?.maxCharLimit}
                      isRequired={isMobileNumberRequired}
                      isInvalid={errors.mobileNumber}
                      value={numericStringMask(leadFormData.phone, mask)}
                      errors={
                        errors.mobileNumber ? errors.mobileNumber : errors.email
                      }
                      errorText={errors.mobileNumber}
                      onChangeText={(value: any) => {
                        const updatedValue = value?.replace(/[()-\s]/g, '');
                        if (updatedValue?.length <= 10) {
                          const tempContactData = {...leadFormData};
                          const phoneObj = {...tempContactData.phoneObj};
                          phoneObj.value = value;
                          setLeadFormData((prev: any) => {
                            return {
                              ...prev,
                              phone: value,
                              phoneObj: phoneObj,
                            };
                          });
                        }
                      }}
                      extraStyle={{
                        flex: 1,
                        placeHolderFontsize: InputStyle.placeHolderFontsize,
                        placeHolderFontWeight:
                          InputStyle.placeHolderFontWeight,
                      }}
                      // onBlur={() => {
                      //   checkForExistingContactWithSameEmail(
                      //     leadFormData.email,
                      //     leadFormData.phone,
                      //     'PRIMARY'
                      //   );
                      // }}
                      isVerified={isContactVerified(
                        leadFormData?.personContact || [],
                        'phone'
                      )}
                      // maxLength={10}
                      valueType={'PhoneNumber'}
                    />
                  </HStack>

                  {leadFormData?.leadId || stateData.showSecondaryContact ? (
                    <HStack
                      mt={5}
                      space={10}
                      style={[styles.rowStack, {width: '100%'}]}
                    >
                      {!isSingleEmailAdd ? (
                        <ModalActionInput
                          fieldIs={'input'}
                          label={'secondaryEmail'}
                          placeholder={'Enter Email Address'}
                          isRequired={false}
                          value={leadFormData.secondaryEmail}
                          isInvalid={errors.secondaryEmail}
                          errors={errors.secondaryEmail}
                          errorText={errors.secondaryEmail}
                          onChangeText={(value: any) => {
                            const tempContactData = {...leadFormData};
                            const secondaryEmailObj = {
                              ...tempContactData.secondaryEmailObj,
                            };
                            secondaryEmailObj.value = value
                              ? value.trim()
                              : value;
                            setLeadFormData((prev: any) => {
                              return {
                                ...prev,
                                secondaryEmailObj: secondaryEmailObj,
                                secondaryEmail: value ? value.trim() : value,
                              };
                            });
                          }}
                          extraStyle={{
                            flex: 1.0,
                            placeHolderFontsize: InputStyle.placeHolderFontsize,
                            placeHolderFontWeight: InputStyle.placeHolderFontWeight,
                          }}
                          alert={alerts.sameSecondEmailExistsAlert}
                          // onBlur={() => {
                          //   checkForExistingContactWithSameEmail(
                          //     leadFormData.secondaryEmail,
                          //     leadFormData.secondaryPhone
                          //   );
                          // }}
                        />
                      ) : (
                        <></>
                      )}
                      <ModalActionInput
                        fieldIs={'input'}
                        label={'secondaryPhoneNumber'}
                        placeholder={'Enter Mobile No.'}
                        isRequired={false}
                        isInvalid={errors.secondaryMobileNumber}
                        errors={errors.secondaryMobileNumber}
                        errorText={errors.secondaryMobileNumber}
                        value={numericStringMask(
                          leadFormData.secondaryPhone,
                          mask
                        )}
                        onChangeText={(value: any) => {
                          const updatedValue = value?.replace(/[()-\s]/g, '');
                          if (updatedValue?.length <= 10) {
                            const tempContactData = {...leadFormData};
                            const secondaryPhoneObj = {
                              ...tempContactData.secondaryPhoneObj,
                            };
                            secondaryPhoneObj.value = value;
                            setLeadFormData((prev: any) => {
                              return {
                                ...prev,
                                secondaryPhone: value,
                                secondaryPhoneObj: secondaryPhoneObj,
                              };
                            });
                          }
                        }}
                        extraStyle={{
                          flex: 1,
                          placeHolderFontsize: InputStyle.placeHolderFontsize,
                          placeHolderFontWeight: InputStyle.placeHolderFontWeight,
                        }}
                        alert={alerts.sameSecondNumberExistsAlert}
                        // onBlur={() => {
                        //   checkForExistingContactWithSameEmail(
                        //     leadFormData.secondaryEmail,
                        //     leadFormData.secondaryPhone
                        //   );
                        // }}
                        // maxLength={10}
                        valueType={'PhoneNumber'}
                      />
                    </HStack>
                  ) : (
                    <></>
                  )}

                  {!leadFormData.leadId ? (
                    <Pressable
                      onPress={() => {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            showSecondaryContact:
                              !stateData.showSecondaryContact,
                          };
                        });
                      }}
                      {...testID(
                        stateData.showSecondaryContact
                          ? TestIdentifiers.hideAlternateContact
                          : TestIdentifiers.addAlternateContact
                      )}
                    >
                      <HStack
                        marginTop={4}
                        alignSelf={'flex-start'}
                        alignItems={'center'}
                      >
                        {stateData.showSecondaryContact ? (
                          <AntIcon
                            style={{
                              marginTop: 4,
                            }}
                            name="delete"
                            color={'#825AC7'}
                            size={14}
                          />
                        ) : (
                          <AntIcon
                            style={{
                              marginTop: 4,
                            }}
                            name="plus"
                            color={'#825AC7'}
                            size={14}
                          />
                        )}
                        <Text ml={2} fontWeight={500} color={'#825AC7'}>
                          {stateData.showSecondaryContact
                            ? 'Hide Alternate Contact Info '
                            : 'Add Alternate Contact Info'}
                        </Text>
                      </HStack>
                    </Pressable>
                  ) : (
                    <></>
                  )}

                  <Divider my={6} />
                  <HStack space={10} style={styles.rowStack}>
                    <Text flex={1} size={'smMedium'} fontWeight={400}>
                      {`Preferred communication mode`}
                    </Text>
                    <View flex={1}>
                      <Radio.Group
                        // colorScheme={'secondary'}
                        value={
                          deSelectRadio() && leadFormData.preferredModeId
                            ? leadFormData.preferredModeId
                            : null
                        }
                        name="PreferredChannel"
                        onChange={(nextValue: any) => {
                          setLeadFormData((prev: any) => {
                            return {
                              ...prev,
                              preferredModeId: nextValue?.target?.value,
                            };
                          });
                        }}
                        // style={{
                        //   display: 'flex',
                        //   alignItems: 'center',
                        //   marginTop: 14,
                        //   marginBottom: 14,
                        //   marginLeft: 4,
                        // }}
                      >
                        {preferredCommunicationType?.map((item) => {
                          return (
                            <Radio
                              id={`radio-${item.value}`}
                              checked={stateData.isUnChecked}
                              disabled={isRadioDisable(item)}
                              key={item.id}
                              value={item.id}
                              style={{color: Colors.FoldPixel.GRAY400}}
                            >
                              {item.value === 'Voice' ? 'Phone' : item.value}
                            </Radio>
                          );
                        })}
                      </Radio.Group>
                    </View>
                  </HStack>
                  <Divider my={6} />

                  {personTypeContact === PERSON_TYPES.CUSTOMER &&
                  !isEmployerRole() &&
                  !leadFormData.leadId ? (
                    <HStack>
                      {/* <Checkbox
                      key={'send_invite'}
                      onChange={(isSelected) => {
                        setSendInvite(isSelected);
                        setLeadFormData((prev: any) => {
                          return {
                            ...prev,
                            sendInvite: isSelected,
                          };
                        });
                      }}
                      isDisabled={props.loggedInTime ? true : false}
                      defaultIsChecked={true}
                      accessibilityLabel={'send_invite'}
                      value={'send_invite'}
                      marginRight={5}
                    >

                    </Checkbox> */}
                      <Text
                        size={'smMedium'}
                        style={{
                          color: Colors.Custom.Gray900,
                          fontWeight: '400',
                        }}
                      >
                        Patient app invitation will be sent automatically
                        through automation, please
                        <Pressable
                          onPress={() => {
                            setWorkflowDrawerOpen(true);
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.info['500'],
                              fontWeight: '400',
                              textDecorationLine: 'underline',
                            }}
                            size={'smMedium'}
                          >
                            {' '}
                            Check your automation
                          </Text>{' '}
                        </Pressable>{' '}
                        if not created for patient invitation.
                      </Text>
                    </HStack>
                  ) : (
                    <></>
                  )}

                  <VStack mb={2} space={2}>
                    {!ehrConfig.isAthena && (
                      <Consent
                        isChecked={consentChk}
                        setConsent={() => {
                          setConsentChk(!consentChk);
                          props?.setConsentChanges('consent', !consentChk);
                        }}
                        textLocaleId={'consents'}
                      />
                    )}
                    {ehrConfig.isAthena && (
                      <View marginTop={2}>
                        {renderConsentToEmail()}
                        {renderConsentToText()}
                        {renderConsentToCall()}
                      </View>
                    )}
                  </VStack>

                  {/* </Radio.Group> */}

                  <Divider width={'100%'} mt={3} />
                  <ModalActionSubTitle
                    marginTop={8}
                    hideDivider={false}
                    subTitle={'Address'}
                  />
                  <HStack space={10} style={styles.rowStack} marginLeft={0}>
                    <ZipCodeAutoComplete
                      isRequired={
                        personTypeContact === PERSON_TYPES.CUSTOMER ||
                        props.invocationCode ===
                          COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER
                          ? true
                          : false
                      }
                      changeBackground={true}
                      flex={1}
                      name={zipCodeChange || leadFormData.zipCode}
                      isInvalid={errors.zipcode}
                      errors={errors}
                      errorText={errors.zipcode}
                      onAutoCompleteChange={(selectedZipCode: IZipCodeData) => {
                        setLeadFormData((prev: any) => {
                          return {
                            ...prev,
                            zipCodeId:
                              selectedZipCode?.id || selectedZipCode?.zipCodeId,
                            stateId: selectedZipCode?.stateId,
                            stateName:
                              selectedZipCode?.zipcodeState?.name ||
                              selectedZipCode?.stateName,
                            cityId: selectedZipCode?.cityId,
                            cityName:
                              selectedZipCode?.zipcodeCity?.name ||
                              selectedZipCode?.cityName,
                            abbr: selectedZipCode?.zipcodeState?.abbr || '',
                            zipCode:
                              selectedZipCode?.code || selectedZipCode?.zipCode,
                          };
                        });
                        setStateData((prev) => {
                          return {
                            ...prev,
                            zipCodeId:
                              selectedZipCode?.id || selectedZipCode?.zipCodeId,
                          };
                        });
                      }}
                      onValueChange={(value: string) => {
                        setZipCodeChange(value);
                      }}
                      showAdd={true}
                    />
                    {isAddressLine1Required && (
                      <StateAutoComplete
                        name={leadFormData.stateName}
                        isRequired={state?.isRequired || false}
                        isTabDisabled={true}
                        onAutoCompleteChange={(selectedId, abbr) => {
                          setLeadFormData({
                            ...leadFormData,
                            stateId: selectedId,
                            abbr: abbr || '',
                          });
                        }}
                        flex={1}
                      />
                    )}
                  </HStack>
                  {isAddressLine1Required && (
                    <>
                      <HStack mt={5} space={10} style={styles.rowStack}>
                        <CityAutoComplete
                          flex={1}
                          name={leadFormData.cityName}
                          isRequired={city?.isRequired || false}
                          isTabDisabled={true}
                          onAutoCompleteChange={(selectedId, cityName) => {
                            setLeadFormData({
                              ...leadFormData,
                              cityId: selectedId,
                              cityName: cityName || '',
                            });
                          }}
                        />
                        {/* {practiceLocationObject.length > 1 ? (
                          <View flex={1}>{getPracticeLocations()}</View>
                        ) : (
                          null
                        )} */}
                      </HStack>
                      <VStack mt={5} space={5} style={styles.rowStack}>
                        <ModalActionInput
                          fieldIs={'input'}
                          label={'addressLine1'}
                          placeholder={intl.formatMessage({id: 'enterTextHere'})}
                          maxLength={addressLine1MaxChar}
                          isRequired={isAddressLine1Required}
                          isInvalid={errors[fieldKeys?.addressLine1]}
                          errors={errors[fieldKeys?.addressLine1]}
                          errorText={errors[fieldKeys?.addressLine1]}
                          value={leadFormData?.line1 || ''}
                          onChangeText={(value: any) => {
                            setLeadFormData({...leadFormData, line1: value});
                          }}
                          extraStyle={{
                            flex: 1,
                            placeHolderFontsize: InputStyle.placeHolderFontsize,
                            placeHolderFontWeight:
                              InputStyle.placeHolderFontWeight,
                          }}
                        />

                        <ModalActionInput
                          fieldIs={'input'}
                          label={'addressLine2'}
                          placeholder={intl.formatMessage({id: 'enterTextHere'})}
                          maxLength={addressLine2MaxChar}
                          isRequired={false}
                          value={leadFormData?.line2 || ''}
                          onChangeText={(value: any) => {
                            setLeadFormData({...leadFormData, line2: value});
                          }}
                          extraStyle={{
                            flex: 1,
                            placeHolderFontsize: InputStyle.placeHolderFontsize,
                            placeHolderFontWeight:
                              InputStyle.placeHolderFontWeight,
                            }}
                        />
                      </VStack>
                    </>
                  )}

                  {checkVitalsForLessThan18Age &&
                  checkIfAgeIsLessThan18(leadFormData.birthDate) &&
                  isHeightOrWeightPending(
                    leadFormData.vitalObservations || []
                  ) ? (
                    <Box mt={5}>
                      <HeightWeightInput
                        isRequired={true}
                        vitals={leadFormData.vitalObservations || []}
                        errors={errors.vitalObservations}
                        onChange={(vital, value) => {
                          handleVitalChange(vital, value);
                        }}
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                </VStack>
                <View>
                  <AddCustomFieldContainer
                    entityType={ENTITY_TYPE.CONTACT}
                    entityId={leadFormData.contactUUID}
                    contactTypeId={stateData?.customFieldContactTypeId}
                    dataSource={CUSTOM_ATTRIBUTE_DATA_SOURCE.FOLD_APP}
                    showAddMore={true}
                    showTitle={true}
                    onDetailChange={(customAttributesValue: IFormattedCustomFieldsData[])=> {
                      onCustomAttributeDetailsChange?.(customAttributesValue);
                    }}
                    customFieldsError={customFieldsError}
                  />
                </View>

                {showAdditionalInfo() && (
                  <View>
                    <ModalActionSubTitle
                      marginTop={8}
                      subTitle={'Additional Info'}
                    />
                    <HStack mt={5} space={10} style={styles.rowStack}>
                      {!isGenderIdentityHidden && (
                        <ModalActionSelect
                          label={genderIdentityLabel}
                          isRequired={false}
                          selectedValue={leadFormData.genderId}
                          accessibilityLabel={genderIdentityLabel}
                          placeholder={`Select ${genderIdentityLabel}`}
                          endIcon={<CheckIcon size="5" />}
                          onValueChange={(itemValue: any) => {
                            const selectedGender = personGender.find(
                              (gender: any) => {
                                return gender.id == itemValue;
                              }
                            );
                            setLeadFormData({
                              ...leadFormData,
                              genderId: itemValue,
                              genderName: selectedGender?.code,
                            });
                          }}
                          data={personGender}
                          selectItemProps={{
                            key: 'id',
                            label: 'value',
                            value: 'id',
                          }}
                          customStyle={{
                            flex: 1,
                            placeHolderFontsize: InputStyle.placeHolderFontsize,
                            placeHolderFontWeight:
                              InputStyle.placeHolderFontWeight,
                            minHeight: 40,
                          }}
                        />
                      )}
                      {/* <View flex={1}> */}
                      <LanguageOptions
                        customStyle={{flex: 1}}
                        code={
                          leadFormData?.contactLanguages?.languageCode || ''
                        }
                        onSelect={(code) => {
                          setLeadFormData((prev: any) => {
                            return {
                              ...prev,
                              contactLanguages: {
                                ...leadFormData.contactLanguages,
                                languageCode: code,
                              },
                            };
                          });
                        }}
                        locationId={getAccountLocationUuid(patientLocationUuid)}
                      />
                      {/* </View> */}
                    </HStack>

                    {legalSexArray &&
                      legalSexArray.length > 0 &&
                      !isLegalSexRequired && (
                        <HStack mt={5} space={10} style={styles.rowStack}>
                          {renderLegalSexField()}
                        </HStack>
                      )}

                    {!isSexualOrientationRequired && (
                      renderSexualOrientationField()
                    )}
                    {!isMartialStatusRequired && (
                      renderMartialStatusField()
                    )}
                    {renderRaceAndEthnicityField(false)}
                    {/* <Divider mt={8} mb={4} /> */}

                    {leadFormData?.personContactList?.length > 0 && false ? (
                      <HStack mt={5} space={10} style={styles.rowStack}>
                        <Select
                          style={{flex: 1}}
                        >
                          v
                          {leadFormData.personContactList?.map((item: any) => {
                            return (
                              <Select.Option key={item.id} value={item.value}>
                                {item.value}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </HStack>
                    ) : (
                      <></>
                    )}
                    <Box my={1}/>
                    {!isAddressLine1Required && (
                      <>
                        <HStack mt={5} space={10} style={styles.rowStack}>
                          <ModalActionInput
                            fieldIs={'input'}
                            label={'addressLine1'}
                            placeholder={intl.formatMessage({id: 'enterTextHere'})}
                            maxLength={addressLine1MaxChar}
                            isRequired={false}
                            value={leadFormData?.line1 || ''}
                            onChangeText={(value: any) => {
                              setLeadFormData({...leadFormData, line1: value});
                            }}
                            extraStyle={{
                              flex: 1,
                              placeHolderFontsize: InputStyle.placeHolderFontsize,
                              placeHolderFontWeight:
                                InputStyle.placeHolderFontWeight,
                              }}
                          />

                          <ModalActionInput
                            fieldIs={'input'}
                            label={'addressLine2'}
                            placeholder={intl.formatMessage({id: 'enterTextHere'})}
                            maxLength={addressLine2MaxChar}
                            isRequired={false}
                            value={leadFormData?.line2 || ''}
                            onChangeText={(value: any) => {
                              setLeadFormData({...leadFormData, line2: value});
                            }}
                            extraStyle={{
                              flex: 1,
                              placeHolderFontsize: InputStyle.placeHolderFontsize,
                              placeHolderFontWeight:
                                InputStyle.placeHolderFontWeight,
                            }}
                          />
                        </HStack>
                        <HStack mt={5} space={10} style={styles.rowStack}>
                          <StateAutoComplete
                            name={leadFormData.stateName}
                            onAutoCompleteChange={(selectedId, abbr) => {
                              setLeadFormData({
                                ...leadFormData,
                                stateId: selectedId,
                                abbr: abbr || '',
                              });
                            }}
                            flex={1}
                          />

                          <CityAutoComplete
                            flex={1}
                            name={leadFormData.cityName}
                            onAutoCompleteChange={(selectedId, cityName) => {
                              setLeadFormData({
                                ...leadFormData,
                                cityId: selectedId,
                                cityName: cityName || '',
                              });
                            }}
                          />
                        </HStack>
                        <ModalActionSubTitle marginTop={4} marginBottom={4} />
                      </>
                    )}
                    {!isEmployerRole() ? (
                      <>
                        <HStack space={10} alignContent={'center'}>
                          {!leadFormData.leadId && (
                            <VStack flex={1}>
                              <View>
                                <TagSearchAndSelect
                                  selectedTagLabels={selectedTagData}
                                  handleChange={(tag: any) => {
                                    const updatedTag = [
                                      ...selectedTagData.tag,
                                      tag?.[0]?.id,
                                    ];
                                    setFinalTagList(updatedTag);
                                    setSelectedTags((prev: any) => {
                                      return {
                                        ...prev,
                                        selectedTags: [
                                          ...selectedTagData.selectedTags,
                                          tag?.[0],
                                        ],
                                        tag: updatedTag,
                                      };
                                    });

                                    setLeadFormData((prev: any) => {
                                      return {
                                        ...prev,
                                        contactTags: updatedTag,
                                      };
                                    });
                                  }}
                                  handleDeselect={(value: any) => {
                                    const selectedTag =
                                      selectedTagData.selectedTags.filter(
                                        (tag: any) => {
                                          return (
                                            tag.id !== parseInt(value)
                                          );
                                        }
                                      );
                                    const tag =
                                      selectedTagData.tag.filter(
                                        (tag: any) => {
                                          return tag !== parseInt(value);
                                        }
                                      );

                                    setSelectedTags((prev: any) => {
                                      return {
                                        ...prev,
                                      selectedTags: selectedTag,
                                      tag: tag,
                                      };
                                    });

                                    setLeadFormData((prev: any) => {
                                      return {
                                        ...prev,
                                      contactTags: tag,
                                      };
                                    });
                                  }}
                                  onClear={() => {
                                    setSelectedTags((prev: any) => {
                                      return {
                                        ...prev,
                                      selectedTags: [],
                                      tag: [],
                                      };
                                    });
                                    setLeadFormData((prev: any) => {
                                      return {
                                        ...prev,
                                      contactTags: [],
                                      };
                                    });
                                  }}
                                />
                              </View>
                            </VStack>
                          )}
                        </HStack>
                        {ehrConfig.isAthena &&
                          (personTypeContact === PERSON_TYPES.CUSTOMER ||
                            props.invocationCode ===
                            COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER
                          ) &&
                          <CustomEHRAttributes
                            leadId ={leadFormData?.leadId}
                            updateEhrAttributes={updateCustomFields}
                            prevResponse={customFieldResponse}
                          />
                    }
                        <HStack space={10} style={styles.rowStack}>
                          {/* {personTypeContact === PERSON_TYPES.CUSTOMER &&
                          !props.isLocationRequired && (
                            <View marginTop={4} flex={1}>
                              <LocationAutoComplete
                                // labelText="Location"
                                autoCompleteType={autoCompleteTypeCodes.DEFAULT}
                                minWidth={320}
                                height={40}
                                selectedLocationUUID={leadFormData?.locationUuid
                                  ? leadFormData?.locationUuid
                                  : defaultPracticeLocation}
                                allowClear={false}
                                onChange={(location: ILocationSearch) => {
                                  // props.onLocationSelect(location);
                                  setLeadFormData((prev: any) => {
                                    return {...prev, locationUuid: location.value};
                                  });
                                }}
                                accountLocations={practiceLocationObject}
                                labelText="Select Location"
                              />
                            </View>
                          )} */}
                          {isPatientCreationFlow && !openInHint && !isEmployerHidden && (
                            <View marginTop={4} flex={1}>
                              <EmployerSelectOrAdd
                                value={leadFormData?.employerName}
                                selectedId={leadFormData?.employerId}
                                onChange={(itemValue: string) => {
                                  setLeadFormData({
                                    ...leadFormData,
                                    employerId: itemValue,
                                  });
                                }}
                                customStyle={{flex: 1}}
                                locationId={getAccountLocationUuid(patientLocationUuid)}
                              />
                            </View>
                          )}
                          {!isLocationRequired && (
                            <View marginTop={4} flex={1}>
                              {getPracticeLocations()}
                            </View>
                          )}
                        </HStack>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <View mt={8}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 20,
                    }}
                  >
                    <ModalActionCheckbox
                      isChecked={leadFormData.isWeeklyCheckin}
                      onChange={() => {
                        setLeadFormData((prev: any) => {
                          return {
                            ...prev,
                            isWeeklyCheckin:
                              !leadFormData.isWeeklyCheckin,
                          };
                        });
                      }}
                      value={'isWeeklyCheckin'}
                    />
                    <DisplayText
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray500, fontWeight: 400}}
                      textLocalId="addnote"
                    />
                  </View> */}
                  </View>
                )}

                {!isEmployerRole() &&
                  !openInHint &&
                  !isEmployerHidden &&
                  !leadFormData?.leadId && <Divider width={'100%'} my={4} />}

                {
                  (getHeaderText() === 'addCustomer' || getHeaderText() === 'addContact') && (
                  <View my={3}>
                        <Pressable
                            onPress={() => {
                              if (isShowAdditionalInfo) {
                                setShowMore(!showMore);
                              } else {
                                showPopupNotification(
                                  intl.formatMessage({id: 'error'}),
                                  'Please Select Location Id',
                                  'error'
                                );
                              }
                            }}
                            {...testID(
                              showMore
                                ? TestIdentifiers.hideAdditionalInfo
                                : TestIdentifiers.showAdditionalInfo
                            )}
                          >
                      <HStack alignSelf={'flex-start'} alignItems={'center'}>
                        <Text fontWeight={500} color={'#825AC7'}>
                          {showMore
                            ? 'Hide additional info  '
                            : 'Show additional info  '}
                        </Text>
                        {showMore ? (
                          <AntIcon
                            style={{
                              marginTop: 4,
                            }}
                            name="up"
                            color={'#825AC7'}
                            size={14}
                          />
                        ) : (
                          <AntIcon
                            style={{
                              marginTop: 4,
                            }}
                            name="down"
                            color={'#825AC7'}
                            size={14}
                          />
                        )}
                      </HStack>
                    </Pressable>
                  </View>
                )}

                {isEmployerRole() ? (
                  <></>
                ) : (
                  <>
                    <FormControl style={[styles.formElement]}>
                      <FormControl.Label pl={0} style={styles.formLabel}>
                        <DisplayText
                          size={'smRegular'}
                          extraStyles={{
                            color: Colors.FoldPixel.GRAY250,
                            fontWeight: 400,
                          }}
                          textLocalId="addnote"
                        />
                      </FormControl.Label>
                      <TextArea
                        borderRadius={8}
                        size={'intNormal'}
                        height={'60px'}
                        value={leadFormData.note}
                        placeholder={intl.formatMessage({id: 'enterTextHere'})}
                        {...testID(TestIdentifiers.enterText)}
                        placeholderTextColor={InputStyle.placeholderTextColor}
                        fontSize={InputStyle.placeHolderFontsize}
                        onChangeText={(value) =>
                          setLeadFormData({...leadFormData, note: value})
                        }
                      />
                    </FormControl>
                  </>
                )}

                {!leadFormData.leadId && personTypeContact === PERSON_TYPES.CUSTOMER &&
                  !isEmployer && (
                    <FoldPermitCan 
                      resource={MAIN_MENU_CODES.AUTOMATION}
                      action={USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code}
                      component={<View mt={2}>
                      <ModalActionSubTitle
                        marginTop={8}
                        subTitle={'Automation'}
                      />
                      <WorkFlowListByEntityEvents
                        addUrl={CUSTOM_AUTOMATION_URL.ADD_PATIENT}
                        title={
                          ENTITY_EVENTS_TITLE_SUBTITLE.ADD_OR_UPDATE_LEAD_PATIENT_TITLE
                        }
                        subtitle={
                          ENTITY_EVENTS_TITLE_SUBTITLE.ADD_OR_UPDATE_LEAD_PATIENT_SUB_TITLE
                        }
                        entityEventList={ENTITY_EVENTS_TYPE.ADD_OR_UPDATE_LEAD}
                        onValueChanage={(eventEntityList: any) => {
                          setLeadFormData({
                            ...leadFormData,
                            workflow_meta_data: {
                              workflowList:
                                getWorkflowSelectedList(eventEntityList),
                            },
                          });
                        }}
                      ></WorkFlowListByEntityEvents>
                    </View>}
                      />
                  )}
              </VStack>
            </ScrollView>
            </>
           }
              {(allowInsurance) && isAddInsuranceOpen &&
                <InsuranceView
                  addPatientView={true}
                  setInsuranceDataForRequestBody={setInsuranceDataForRequestBody}
                  setSecInsuranceDataForRequestBody={setSecInsuranceDataForRequestBody}
                  setTerInsuranceDataForRequestBody={setTerInsuranceDataForRequestBody}
                />
              }
          </>
        </ScrollView>
      </Drawer>
      {workflowDrawerOpen && (
        <WorkflowDrawer
          isOpen={workflowDrawerOpen}
          onClose={() => {
            setWorkflowDrawerOpen(false);
          }}
          flowType={FlowType.patients}
        />
      )}
      {selectLeadViewCode.code ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW && (
        <AddOrUpdateLead
          practiceLocationObj={allMemberState.practiceLocations}
          {...selectedRowData}
          invocationCode={selectLeadViewCode.invocationCode}
          onFormCompleteAction={(actionCode: string, actionData: any) => {
            onViewChangeActionPerformed(actionCode, actionData);
          }}
          personType={
            selectedRowData.id
              ? allMemberState.contactType
              : allMemberState.addContactType
          }
          personTypeUuid={
            selectedRowData.id
              ? allMemberState.contactTypeUuid
              : allMemberState.addContactTypeUuid
          }
          alert={alert}
          isEmployeeView={true}
          showConsentError={selectedRowData?.showConsentError}
          observationsUpdated={(observations: Observation[]) => {
            setObservations([...observations]);
          }}
        />
      )}

      {selectLeadViewCode.code ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_CONVERT_CUSTOMER && (
        <>
          <ConvertLeadList
            isOpen={convertLeadModal}
            onClose={() => {
              setConvertLeadModal(false);
            }}
            onSave={(patientUuid) => {
              setConfirmationModalOpen(true);
              setPatientUuid(patientUuid);
              setActionType(CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER);
            }}
            personType={PERSON_TYPES.CUSTOMER}
            contactData={selectedRowData}
            onCreatePatient={() => {
              setConfirmationModalOpen(true);
              setActionType(CONTACT_ACTION_CODES.CREATE_PATIENT);
            }}
            locationUuid={patientLocationUuid}
            isChecked={(checked) => {
              setSendInvite(checked);
            }}
          />
          <ConfirmationModal
            isModalOpen={confirmationModalOpen}
            onModalClose={() => setConfirmationModalOpen(false)}
            btnList={[
              {
                btnText: 'Confirm',
                onBtnPress: () => {
                  let formattedData = getFormDataFromLeadData(
                    selectedRowData?.contactData?.singleLeadData,
                    commonData
                  );
                  formattedData = {
                    ...formattedData,
                    vitalObservations: observations || [],
                  };
                  if (actionType === CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER) {
                    handlePatientContactMap(formattedData, patientUuid);
                    handleConvertToCustomer(
                      formattedData.personTypeId,
                      PERSON_TYPES.CUSTOMER
                    );
                    sendPatientAppInvitation(formattedData);
                  } else if (
                    actionType === CONTACT_ACTION_CODES.CREATE_PATIENT
                  ) {
                    handleCreatePatientInEHR(formattedData);
                  }
                  setConfirmationModalOpen(false);
                  setConvertLeadModal(false);
                },
              },
              {
                btnText: 'Cancel',
                onBtnPress: () => {
                  setConfirmationModalOpen(false);
                },
                btnColorScheme: 'dark',
                btnTextStyle: {color: 'white'},
              },
            ]}
            actionType={actionType}
          />
        </>
      )}
        <Modal title="Confirmation"  open={stateData?.showAddUpdateMemberWarning}
          onCancel={() => {
            handleModalChange(false);
          }}
          footer={null}
        >
          <View marginX={4}>
            <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}}  textLocalId={'addUpdateLeadCancelMsg'} />
            <View marginBottom={6}></View>
            <Button.Group justifyContent={'flex-end'}>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    handleModalChange(false);
                    onConsentChangeHandler(selectedConsent);
                    setTimeout(() => {
                      props?.onSubmit();
                    },100)
                  },
                }}
                customProps={{
                  btnText: 'Save',
                  withRightBorder: false,
                }}
              ></FoldButton>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    handleModalChange(false);
                    props.onCancelClick();
                  },
                }}
                customProps={{
                  btnText: 'Close',
                  withRightBorder: false,
                }}
              ></FoldButton>
            </Button.Group>
          </View>
        </Modal>
    </>
    </ConfirmOnExitOrReload>
  );
};

export const AddOrUpdateLeadView = withMiniContactViewHOC(AddOrUpdateLeadViewComponent)
