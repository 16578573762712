import {useContext, useEffect, useState} from 'react';
import {getMlovListFromCategory} from '../../../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {MLOV_CATEGORY} from '../../../../../../constants';
import {addTimeToDate, getDateObject} from '../../../../../../utils/DateUtils';
import {AppointmentQueries} from '../../../../../../services';
import {useLazyQuery} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {IAppointmentDetail} from '../../../../../common/CalendarWidget/CalendarWidgetInterfaces';
import { getUpcomingAppointmentStatusIds } from '../../../../Forms/FHFormio/CustomComponents/CareProgramAppointments/CareProgramAppointmentComponent/CareProgramAppointmentUtils';

interface UseCadenceParams {
  contactUuid: string;
  isFetchCadence?: boolean;
}

interface IUseCadenceState {
  isAddReminderCadenceVisible?: boolean;
  isAddCadenceDrawerOpen?: boolean;
  selectedAppointment?: IAppointmentDetail | null;
  isEditCadenceDrawerOpen?: boolean;
  appointments: IAppointmentDetail[];
  isAppointmentListLoading?: boolean;
  isCareProgramAppointmentsLoading?: boolean;
}

export const useCadence = (params: UseCadenceParams) => {
  const commonContextData = useContext(CommonDataContext);
  const appointmentStatusList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

  const [componentState, setComponentState] = useState<IUseCadenceState>({
    appointments: [],
    isAppointmentListLoading: true,
  });

  const getAppointmentFetchParams = () => {
    const appointmentStatusIds = getUpcomingAppointmentStatusIds(appointmentStatusList);
    return {
      params: {
        startDateTime: getDateObject(new Date()).toISOString(),
        statusIds: appointmentStatusIds,
        contactIdsOrInContextOfContactIds: [params.contactUuid],
        limit: 5,
        offset: 0,
        orderBy: {
          startDateTime: 'asc',
        },
      },
    };
  };

  const [getPatientAppointments] = useLazyQuery(
    AppointmentQueries.GET_BOOKED_APPOINTMENTS_BY_PAGINATION,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (data) => {
        if (data && data.appointments && data.appointments.length) {
          setComponentState((prev) => ({
            ...prev,
            appointments: data.appointments,
            isAppointmentListLoading: false,
          }));
        } else {
          setComponentState((prev) => ({
            ...prev,
            isAppointmentListLoading: false,
          }));
        }
      },
      onError: (err) => {
        setComponentState((prev) => ({
          ...prev,
          isAppointmentListLoading: false,
        }));
      },
    }
  );

  const [getPatientCareProgramAppointments] = useLazyQuery(
    AppointmentQueries.GET_BOOKED_APPOINTMENTS_BY_PAGINATION,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  useEffect(() => {
    if (params.isFetchCadence) {
      fetchAppointments();
    }
  }, []);

  const onComponentStateChange = (newState: Partial<IUseCadenceState>) => {
    setComponentState((prev) => ({
      ...prev,
      ...newState,
    }));
  };

  const fetchAppointments = () => {
    getPatientAppointments({
      variables: getAppointmentFetchParams(),
    });
  };

  const isUpcomingCareProgramAppointmentsExist = async (contactCareProgramId: string) => {
    try {
    const appointmentStatusIds = getUpcomingAppointmentStatusIds(appointmentStatusList);
    setComponentState((prev) => ({
      ...prev,
      isCareProgramAppointmentsLoading: true,
    }));
    const patientCareProgramAppointments = await getPatientCareProgramAppointments({
      variables: {
        params: {
          startDateTime: getDateObject(new Date()).toISOString(),
          statusIds: appointmentStatusIds,
          contactIdsOrInContextOfContactIds: [params.contactUuid],
          contactCareProgramIds: [contactCareProgramId],
        },
      },
    });

    setComponentState((prev) => ({
      ...prev,
      isCareProgramAppointmentsLoading: false,
    }));

    if (patientCareProgramAppointments?.data?.appointments?.length > 0) {
      return true;
    }
    else {
      return false;
    }
    } catch (error) {
      console.error('Error fetching patient care program appointments:', error);
      return false;
    }
  };

  return {
    appointments: componentState.appointments,
    isAppointmentListLoading: componentState.isAppointmentListLoading,
    isAddCadenceDrawerOpen: componentState.isAddCadenceDrawerOpen,
    isEditCadenceDrawerOpen: componentState.isEditCadenceDrawerOpen,
    isAddReminderCadenceVisible: componentState.isAddReminderCadenceVisible,
    selectedAppointment: componentState.selectedAppointment,
    onComponentStateChange: onComponentStateChange,
    fetchAppointments: fetchAppointments,
    isUpcomingCareProgramAppointmentsExist: isUpcomingCareProgramAppointmentsExist,
    isCareProgramAppointmentsLoading: componentState.isCareProgramAppointmentsLoading
  };
};
