import React, {useEffect} from 'react';
import {IInputElement} from './FlowNodeInterface';
import {FormControl, Text} from 'native-base';
import {Select as AntSelect, Spin} from 'antd';
import {CaretDownOutlined} from '@ant-design/icons';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {
  getWorkflowContexts,
  insertWorkflowContext,
} from '../../Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {reactStyles} from '../../workflowStyles';
import {Colors} from '../../../../../styles';
import {debounce} from 'lodash';

interface IWorkflowContext {
  id: string;
  context: string;
}

const ExecutionContext = (props: IInputElement) => {
  const [state, setState] = React.useState({
    contextList: props.value?.id ? [props.value] : ([] as IWorkflowContext[]),
    warningMessage: '',
    context: '',
    isFetching: false,
    isSaving: false,
  });

  const tenantId = getAccountUUID();

  const showAddContext =
    state.contextList.length === 0 && (state.context || '').trim().length > 0;

  const onSearch = (searchText?: string) => {
    setState((prevState) => ({
      ...prevState,
      context: searchText || '',
    }));
    getWorkflowContextValues(searchText);
  };

  const getWorkflowContextValues = async (
    searchText?: string,
    selectedId?: string
  ) => {
    setState((prevState) => ({
      ...prevState,
      isFetching: true,
    }));
    const workflowContexts: IWorkflowContext[] = await getWorkflowContexts({
      tenantId,
      searchText: `%${searchText || ''}%`,
    });
    const selectedContext = workflowContexts.find(
      (context) => context.id === selectedId
    );
    setState((prevState) => ({
      ...prevState,
      contextList: workflowContexts,
      isFetching: false,
      isSaving: false,
    }));
    if (selectedContext?.id) {
      props.onChange(selectedContext);
    }
  };

  const createWorkflowContext = async (context: string) => {
    context = context.trim().replace(/[^a-zA-Z0-9-_\s]/g, '');
    if (!context) {
      setState((prevState) => ({
        ...prevState,
        warningMessage: 'Context cannot be empty',
      }));
      return;
    }

    const contextExists = state.contextList.some(
      (existingContext) =>
        existingContext.context.toLowerCase() === context.toLowerCase()
    );

    if (contextExists) {
      setState((prevState) => ({
        ...prevState,
        warningMessage: 'Context already exists',
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      isSaving: true,
      context: '',
      warningMessage: '',
    }));
    const response = await insertWorkflowContext({tenantId, context});
    getWorkflowContextValues(undefined, response.id);
  };

  useEffect(() => {
    getWorkflowContextValues();
  }, []);

  return (
    <FormControl isInvalid={props.isShowError && !props.value?.id}>
      <AntSelect
        suffixIcon={<CaretDownOutlined style={reactStyles.colorGray300} />}
        status={
          props.isShowError &&
          (props.value?.id === undefined || props.value?.id === '')
            ? 'error'
            : ''
        }
        allowClear={true}
        onClear={() => {
          props.onChange(undefined, undefined);
        }}
        {...props.elementProperty}
        placeholder={props.elementProperty?.placeholder || 'Select Option'}
        value={
          props.value?.id !== undefined && props.value?.id !== ''
            ? props.value?.id
            : undefined
        }
        showSearch={true}
        notFoundContent={
          state.isFetching ? (
            <Spin />
          ) : showAddContext ? (
            <Text
              onPress={() => createWorkflowContext(state.context)}
              color={Colors.FoldPixel.PRIMARY300}
            >
              {`Add "${state.context}" as context`}
            </Text>
          ) : null
        }
        onSearch={debounce(onSearch, 300)}
        filterOption={false}
        onChange={(itemValue) => {
          setState((prevState) => ({
            ...prevState,
            context: '',
          }));
          if (itemValue) {
            const selectedContext = state.contextList.find(
              (context) => context.id === itemValue
            );
            props.onChange(selectedContext);
          }
        }}
      >
        {state.contextList?.map((data) => {
          return (
            <AntSelect.Option
              value={data.id}
              key={data.id}
              title={data.context}
            >
              {data.context}
            </AntSelect.Option>
          );
        })}
      </AntSelect>
      {state.warningMessage && (
        <div style={reactStyles.colorErrorMarginTop10}>
          {state.warningMessage}
        </div>
      )}
    </FormControl>
  );
};

export default ExecutionContext;
