import { Stack, ScrollView } from 'native-base';
import React, { useState } from 'react';
import InsightsReferralOrdersView from '../../../../../SideCar/components/InsightsReferralOrdersView';
import { usePersonOmniViewContext } from '../../../../../PersonOmniView/PersonOmniView.context';
import { useContactCareProgramContext } from '../../ContactCareProgram.context';
import useCareProgramStepStatus from '../../hooks/useCareProgramStepStatus';
import { DISABLE_STATUS_CODES } from '../../../../../../constants/MlovConst';
import { useDischargeRefferals } from './hooks/useDischargeRefferals';



const DischargeRefferals: React.FC = () => {
  const { state } = useContactCareProgramContext();
  const {formattedData: formattedContactData} = usePersonOmniViewContext();
  const [showInsightsReferralOrders, setShowInsightsReferralOrders] = useState(false);
  const {careProgramStepStatus} = useCareProgramStepStatus();
  const selectedStepLog = state.contactCareProgramDetails?.stepsLog.find(stepLog => stepLog.careProgramStepId === state.selectedStepId);
  const isReadOnlyView = selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.done || selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.skip;
  const {handleSaveReferralOrder, referralOrderIds} = useDischargeRefferals();

  const isDisabled = DISABLE_STATUS_CODES.includes(state.contactCareProgramDetails?.status?.code || '');
  return (
    <ScrollView style={{marginHorizontal: 24, marginBottom: 86}}>
    <Stack direction="column" mt={4} px={20}>
       <InsightsReferralOrdersView
          formattedContactData={formattedContactData}
          contactData={formattedContactData?.contactData}
          onBack={() => {
            setShowInsightsReferralOrders(false);
        }}
          isReadOnlyView={isReadOnlyView}
          isCareManagementView={true}
          onSaveReferralOrder={handleSaveReferralOrder}
          referralOrderIds={referralOrderIds || []}
          canFetchCareProgramReferrals={true}
          isDisabled={isDisabled}
          stepId={state?.selectedStepId}
        />
    </Stack>
    </ScrollView>
  );
};

export default DischargeRefferals;
