import {useCallback, useContext, useEffect, useState} from 'react';
import {
  AddButtonAction, IContactCareProgramStep
} from '../../../../interface';
import {canShowAddButtonWithAction, DUMMY_OUTREACH_STEP_ID} from '../../../ContactCareProgramView.utils';
import {CARE_PROGRAM_STEP_TYPE} from '../../../../../../common/MemebersView/constant';
import {
  getMlovListFromCategory,
  getMlovObjectFromCode
} from '../../../../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {MLOV_CATEGORY} from '../../../../../../../constants';
import {useSelectedCareProgramStatus} from '../../../hooks/useSelectedCareProgramStatus';
import {CONTACT_CARE_PROGRAM_STATUS_CODES} from '../../../../../../../constants/MlovConst';
import { EventBus } from '../../../../../../../utils/EventBus';
import { CARE_PROGRAM_EVENTS } from '../../../../useCPEventhandler';

export const useAddStepAction = (params: {
  isExpanded: boolean;
  onClick: () => void;
  refetchMasterData: () => void;
  parentStep: IContactCareProgramStep;
  careProgramTypeId: string;
  contactId: string;
  onAddDummySubStep?: (
    step: IContactCareProgramStep,
    shouldAutoSelect?: boolean
  ) => void;
  scrollContentViewToTop?: () => void;
  selectStep?: (stepId: string) => void;
}) => {
  const contextData = useContext(CommonDataContext);
  const {programStatusCode} = useSelectedCareProgramStatus();

  const isCareProgramActive = [CONTACT_CARE_PROGRAM_STATUS_CODES.NEW, CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS].includes(programStatusCode)
  const careProgramStepTypeList =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_STEP_TYPE
    ) || [];

  const {
    isExpanded,
    onClick,
    refetchMasterData,
    parentStep,
    onAddDummySubStep,
    scrollContentViewToTop,
    selectStep,
  } = params;

  const [addActionView, setAddActionView] = useState<
    AddButtonAction | undefined
  >(undefined);
  const onAddDummySubStepForOutreach = (step: IContactCareProgramStep) => {
    if (parentStep?.subSteps?.length === 0) {
      const mlovObject = getMlovObjectFromCode(
      CARE_PROGRAM_STEP_TYPE.OUTREACH,
      careProgramStepTypeList
    );
      if (!mlovObject) {
        return;
      }
      onAddDummySubStep?.(
        {
          parentStepId: step.id,
          title: 'Outreach',
          isRequired: step.isRequired,
          careProgramStepType: mlovObject,
          sequenceNumber: parentStep.sequenceNumber,
          // This is done bcz child step display sequence number starts from 2
          displaySequenceNumber: 2,
          careProgramStepAdditionalAttributes: [],
          careProgramSubSteps: [],
          id: DUMMY_OUTREACH_STEP_ID,
        },
        true
      );
    } else if (parentStep?.subSteps?.[0]?.careProgramStepType?.code === CARE_PROGRAM_STEP_TYPE.OUTREACH) {
      selectStep?.(parentStep?.subSteps?.[0]?.id || '');
      scrollContentViewToTop?.();
    }
  };

  const onAddButtonClick = async (action: AddButtonAction) => {
    if (!isExpanded) {
      onClick();
    }

    switch (action) {
      case AddButtonAction.BILLING:
        // TODO: add new step
        break;
      case AddButtonAction.ADD_OUTREACH:
        // TODO: add new step
        onAddDummySubStepForOutreach(parentStep);
        break;
      default:
        // For view actions
        setAddActionView(action);
    }
  };

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    const handleOutreachEvent = (event: any) => {
      if (event.syncCode === 'record-as-outreach') {
        onAddButtonClick(AddButtonAction.ADD_OUTREACH);
      }
    };
    
    eventBus.addEventListener(
      CARE_PROGRAM_EVENTS.RECORD_AS_OUTREACH,
      handleOutreachEvent
    );

    return () => {
      eventBus.removeEventListener(handleOutreachEvent);
    };
  }, [onAddButtonClick]);


  const addButtonAction = isCareProgramActive ? canShowAddButtonWithAction(parentStep) : undefined;

  const onAddComplete = () => {
    setAddActionView(undefined);
    refetchMasterData?.();
  };

  const onCancel = useCallback(() => {
    setAddActionView(undefined);
  }, []);

  return {
    addButtonAction,
    addActionView,
    onAddButtonClick,
    onAddComplete,
    onCancel,
  };
};
